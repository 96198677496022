const wellRoundedDivision2 = {
  id: 'wellRoundedDivision2',
  name: 'Well Rounded Division 2: E-Notation',
  linkTo: "/quizzes/wellRoundedDivision2",
  description: "How fast can you divide big numbers? You can do this one with Eeees!",
  halfOrderENumpad: true,
  timeLimit: 90,
  randomize: true,
  challenge: true,
  questions: 
  [
    {
        "question": "What is 1E+08 divided by 3E+03?",
        "writeInAnswer": 30000,
        "furtherInfo": {
            "explanation": "What is 1E+08 divided by 3E+03?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+04."
        }
    },
    {
        "question": "What is 3E+06 divided by 3E+03?",
        "writeInAnswer": 1000,
        "furtherInfo": {
            "explanation": "What is 3E+06 divided by 3E+03?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
        }
    },
    {
        "question": "What is 1E+09 divided by 3E+01?",
        "writeInAnswer": 30000000,
        "furtherInfo": {
            "explanation": "What is 1E+09 divided by 3E+01?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+07."
        }
    },
    {
        "question": "What is 1E+07 divided by 1E+04?",
        "writeInAnswer": 1000,
        "furtherInfo": {
            "explanation": "What is 1E+07 divided by 1E+04?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
        }
    },
    {
        "question": "What is 1E+02 divided by 3E+01?",
        "writeInAnswer": 3,
        "furtherInfo": {
            "explanation": "What is 1E+02 divided by 3E+01?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
        }
    },
    {
        "question": "What is 3E+10 divided by 1E+04?",
        "writeInAnswer": 3000000,
        "furtherInfo": {
            "explanation": "What is 3E+10 divided by 1E+04?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+06."
        }
    },
    {
        "question": "What is 1E+09 divided by 1E+06?",
        "writeInAnswer": 1000,
        "furtherInfo": {
            "explanation": "What is 1E+09 divided by 1E+06?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
        }
    },
    {
        "question": "What is 3E+04 divided by 3E+02?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 3E+04 divided by 3E+02?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 3E+04 divided by 3E+01?",
        "writeInAnswer": 1000,
        "furtherInfo": {
            "explanation": "What is 3E+04 divided by 3E+01?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
        }
    },
    {
        "question": "What is 3E+11 divided by 1E+00?",
        "writeInAnswer": 300000000000,
        "furtherInfo": {
            "explanation": "What is 3E+11 divided by 1E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+11."
        }
    },
    {
        "question": "What is 1E+04 divided by 3E+02?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 1E+04 divided by 3E+02?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+01."
        }
    },
    {
        "question": "What is 3E+12 divided by 3E+05?",
        "writeInAnswer": 10000000,
        "furtherInfo": {
            "explanation": "What is 3E+12 divided by 3E+05?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+07."
        }
    },
    {
        "question": "What is 1E+07 divided by 1E+00?",
        "writeInAnswer": 10000000,
        "furtherInfo": {
            "explanation": "What is 1E+07 divided by 1E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+07."
        }
    },
    {
        "question": "What is 3E+12 divided by 3E+08?",
        "writeInAnswer": 10000,
        "furtherInfo": {
            "explanation": "What is 3E+12 divided by 3E+08?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+04."
        }
    },
    {
        "question": "What is 1E+07 divided by 1E+05?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 1E+07 divided by 1E+05?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 1E+09 divided by 1E+02?",
        "writeInAnswer": 10000000,
        "furtherInfo": {
            "explanation": "What is 1E+09 divided by 1E+02?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+07."
        }
    },
    {
        "question": "What is 1E+06 divided by 1E+01?",
        "writeInAnswer": 100000,
        "furtherInfo": {
            "explanation": "What is 1E+06 divided by 1E+01?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+05."
        }
    },
    {
        "question": "What is 3E+01 divided by 3E+00?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 3E+01 divided by 3E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 1E+10 divided by 3E+04?",
        "writeInAnswer": 300000,
        "furtherInfo": {
            "explanation": "What is 1E+10 divided by 3E+04?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+05."
        }
    },
    {
        "question": "What is 1E+02 divided by 1E+00?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 1E+02 divided by 1E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 1E+05 divided by 3E+00?",
        "writeInAnswer": 30000,
        "furtherInfo": {
            "explanation": "What is 1E+05 divided by 3E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+04."
        }
    },
    {
        "question": "What is 1E+11 divided by 3E+08?",
        "writeInAnswer": 300,
        "furtherInfo": {
            "explanation": "What is 1E+11 divided by 3E+08?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+02."
        }
    },
    {
        "question": "What is 3E+02 divided by 1E+01?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 3E+02 divided by 1E+01?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
        }
    },
    {
        "question": "What is 3E+07 divided by 1E+06?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 3E+07 divided by 1E+06?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
        }
    },
    {
        "question": "What is 1E+09 divided by 3E+05?",
        "writeInAnswer": 3000,
        "furtherInfo": {
            "explanation": "What is 1E+09 divided by 3E+05?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+03."
        }
    },
    {
        "question": "What is 3E+08 divided by 1E+03?",
        "writeInAnswer": 300000,
        "furtherInfo": {
            "explanation": "What is 3E+08 divided by 1E+03?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+05."
        }
    },
    {
        "question": "What is 1E+05 divided by 1E+01?",
        "writeInAnswer": 10000,
        "furtherInfo": {
            "explanation": "What is 1E+05 divided by 1E+01?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+04."
        }
    },
    {
        "question": "What is 1E+03 divided by 1E+00?",
        "writeInAnswer": 1000,
        "furtherInfo": {
            "explanation": "What is 1E+03 divided by 1E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
        }
    },
    {
        "question": "What is 1E+02 divided by 3E+01?",
        "writeInAnswer": 3,
        "furtherInfo": {
            "explanation": "What is 1E+02 divided by 3E+01?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
        }
    },
    {
        "question": "What is 1E+10 divided by 1E+03?",
        "writeInAnswer": 10000000,
        "furtherInfo": {
            "explanation": "What is 1E+10 divided by 1E+03?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+07."
        }
    },
    {
        "question": "What is 3E+09 divided by 1E+00?",
        "writeInAnswer": 3000000000,
        "furtherInfo": {
            "explanation": "What is 3E+09 divided by 1E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+09."
        }
    },
    {
        "question": "What is 1E+06 divided by 1E+03?",
        "writeInAnswer": 1000,
        "furtherInfo": {
            "explanation": "What is 1E+06 divided by 1E+03?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
        }
    },
    {
        "question": "What is 1E+06 divided by 3E+04?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 1E+06 divided by 3E+04?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+01."
        }
    },
    {
        "question": "What is 1E+06 divided by 1E+02?",
        "writeInAnswer": 10000,
        "furtherInfo": {
            "explanation": "What is 1E+06 divided by 1E+02?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+04."
        }
    },
    {
        "question": "What is 1E+01 divided by 3E+00?",
        "writeInAnswer": 3,
        "furtherInfo": {
            "explanation": "What is 1E+01 divided by 3E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
        }
    },
    {
        "question": "What is 3E+02 divided by 1E+00?",
        "writeInAnswer": 300,
        "furtherInfo": {
            "explanation": "What is 3E+02 divided by 1E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+02."
        }
    },
    {
        "question": "What is 1E+05 divided by 3E+03?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 1E+05 divided by 3E+03?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+01."
        }
    },
    {
        "question": "What is 3E+06 divided by 3E+00?",
        "writeInAnswer": 1000000,
        "furtherInfo": {
            "explanation": "What is 3E+06 divided by 3E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+06."
        }
    },
    {
        "question": "What is 3E+09 divided by 3E+07?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 3E+09 divided by 3E+07?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 3E+12 divided by 1E+05?",
        "writeInAnswer": 30000000,
        "furtherInfo": {
            "explanation": "What is 3E+12 divided by 1E+05?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+07."
        }
    },
    {
        "question": "What is 1E+01 divided by 1E+00?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 1E+01 divided by 1E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 3E+02 divided by 1E+01?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 3E+02 divided by 1E+01?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
        }
    },
    {
        "question": "What is 3E+03 divided by 3E+02?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 3E+03 divided by 3E+02?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 1E+07 divided by 3E+00?",
        "writeInAnswer": 3000000,
        "furtherInfo": {
            "explanation": "What is 1E+07 divided by 3E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+06."
        }
    },
    {
        "question": "What is 3E+01 divided by 3E+00?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 3E+01 divided by 3E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 1E+05 divided by 1E+02?",
        "writeInAnswer": 1000,
        "furtherInfo": {
            "explanation": "What is 1E+05 divided by 1E+02?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
        }
    },
    {
        "question": "What is 3E+09 divided by 3E+06?",
        "writeInAnswer": 1000,
        "furtherInfo": {
            "explanation": "What is 3E+09 divided by 3E+06?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
        }
    },
    {
        "question": "What is 3E+09 divided by 1E+01?",
        "writeInAnswer": 300000000,
        "furtherInfo": {
            "explanation": "What is 3E+09 divided by 1E+01?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+08."
        }
    },
    {
        "question": "What is 1E+10 divided by 1E+02?",
        "writeInAnswer": 100000000,
        "furtherInfo": {
            "explanation": "What is 1E+10 divided by 1E+02?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+08."
        }
    },
    {
        "question": "What is 1E+03 divided by 3E+01?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 1E+03 divided by 3E+01?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+01."
        }
    },
    {
        "question": "What is 3E+07 divided by 3E+06?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 3E+07 divided by 3E+06?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 3E+11 divided by 1E+10?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 3E+11 divided by 1E+10?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
        }
    },
    {
        "question": "What is 3E+08 divided by 3E+06?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 3E+08 divided by 3E+06?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 3E+04 divided by 1E+01?",
        "writeInAnswer": 3000,
        "furtherInfo": {
            "explanation": "What is 3E+04 divided by 1E+01?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+03."
        }
    },
    {
        "question": "What is 3E+01 divided by 3E+00?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 3E+01 divided by 3E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 3E+07 divided by 1E+00?",
        "writeInAnswer": 30000000,
        "furtherInfo": {
            "explanation": "What is 3E+07 divided by 1E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+07."
        }
    },
    {
        "question": "What is 1E+11 divided by 3E+03?",
        "writeInAnswer": 30000000,
        "furtherInfo": {
            "explanation": "What is 1E+11 divided by 3E+03?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+07."
        }
    },
    {
        "question": "What is 1E+01 divided by 3E+00?",
        "writeInAnswer": 3,
        "furtherInfo": {
            "explanation": "What is 1E+01 divided by 3E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
        }
    },
    {
        "question": "What is 3E+09 divided by 3E+07?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 3E+09 divided by 3E+07?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 1E+07 divided by 1E+05?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 1E+07 divided by 1E+05?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 1E+13 divided by 1E+03?",
        "writeInAnswer": 10000000000,
        "furtherInfo": {
            "explanation": "What is 1E+13 divided by 1E+03?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+10."
        }
    },
    {
        "question": "What is 3E+04 divided by 1E+00?",
        "writeInAnswer": 30000,
        "furtherInfo": {
            "explanation": "What is 3E+04 divided by 1E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+04."
        }
    },
    {
        "question": "What is 1E+04 divided by 3E+02?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 1E+04 divided by 3E+02?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+01."
        }
    },
    {
        "question": "What is 3E+02 divided by 3E+01?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 3E+02 divided by 3E+01?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 3E+08 divided by 1E+06?",
        "writeInAnswer": 300,
        "furtherInfo": {
            "explanation": "What is 3E+08 divided by 1E+06?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+02."
        }
    },
    {
        "question": "What is 3E+11 divided by 3E+08?",
        "writeInAnswer": 1000,
        "furtherInfo": {
            "explanation": "What is 3E+11 divided by 3E+08?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
        }
    },
    {
        "question": "What is 1E+07 divided by 3E+05?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 1E+07 divided by 3E+05?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+01."
        }
    },
    {
        "question": "What is 1E+09 divided by 1E+04?",
        "writeInAnswer": 100000,
        "furtherInfo": {
            "explanation": "What is 1E+09 divided by 1E+04?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+05."
        }
    },
    {
        "question": "What is 1E+13 divided by 3E+10?",
        "writeInAnswer": 300,
        "furtherInfo": {
            "explanation": "What is 1E+13 divided by 3E+10?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+02."
        }
    },
    {
        "question": "What is 3E+10 divided by 1E+04?",
        "writeInAnswer": 3000000,
        "furtherInfo": {
            "explanation": "What is 3E+10 divided by 1E+04?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+06."
        }
    },
    {
        "question": "What is 1E+06 divided by 3E+01?",
        "writeInAnswer": 30000,
        "furtherInfo": {
            "explanation": "What is 1E+06 divided by 3E+01?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+04."
        }
    },
    {
        "question": "What is 1E+09 divided by 1E+03?",
        "writeInAnswer": 1000000,
        "furtherInfo": {
            "explanation": "What is 1E+09 divided by 1E+03?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+06."
        }
    },
    {
        "question": "What is 3E+05 divided by 1E+04?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 3E+05 divided by 1E+04?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
        }
    },
    {
        "question": "What is 3E+05 divided by 1E+00?",
        "writeInAnswer": 300000,
        "furtherInfo": {
            "explanation": "What is 3E+05 divided by 1E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+05."
        }
    },
    {
        "question": "What is 3E+13 divided by 1E+07?",
        "writeInAnswer": 3000000,
        "furtherInfo": {
            "explanation": "What is 3E+13 divided by 1E+07?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+06."
        }
    },
    {
        "question": "What is 3E+01 divided by 1E+00?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 3E+01 divided by 1E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
        }
    },
    {
        "question": "What is 1E+02 divided by 1E+01?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 1E+02 divided by 1E+01?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 1E+13 divided by 3E+05?",
        "writeInAnswer": 30000000,
        "furtherInfo": {
            "explanation": "What is 1E+13 divided by 3E+05?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+07."
        }
    },
    {
        "question": "What is 3E+01 divided by 3E+00?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 3E+01 divided by 3E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 1E+13 divided by 3E+03?",
        "writeInAnswer": 3000000000,
        "furtherInfo": {
            "explanation": "What is 1E+13 divided by 3E+03?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+09."
        }
    },
    {
        "question": "What is 1E+07 divided by 1E+05?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 1E+07 divided by 1E+05?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 1E+12 divided by 3E+08?",
        "writeInAnswer": 3000,
        "furtherInfo": {
            "explanation": "What is 1E+12 divided by 3E+08?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+03."
        }
    },
    {
        "question": "What is 3E+02 divided by 1E+01?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 3E+02 divided by 1E+01?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
        }
    },
    {
        "question": "What is 3E+09 divided by 3E+08?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 3E+09 divided by 3E+08?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 3E+08 divided by 1E+07?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 3E+08 divided by 1E+07?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
        }
    },
    {
        "question": "What is 3E+12 divided by 3E+08?",
        "writeInAnswer": 10000,
        "furtherInfo": {
            "explanation": "What is 3E+12 divided by 3E+08?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+04."
        }
    },
    {
        "question": "What is 1E+01 divided by 1E+00?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 1E+01 divided by 1E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 1E+05 divided by 3E+03?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 1E+05 divided by 3E+03?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+01."
        }
    },
    {
        "question": "What is 3E+07 divided by 3E+01?",
        "writeInAnswer": 1000000,
        "furtherInfo": {
            "explanation": "What is 3E+07 divided by 3E+01?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+06."
        }
    },
    {
        "question": "What is 1E+10 divided by 1E+07?",
        "writeInAnswer": 1000,
        "furtherInfo": {
            "explanation": "What is 1E+10 divided by 1E+07?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
        }
    },
    {
        "question": "What is 3E+13 divided by 3E+11?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 3E+13 divided by 3E+11?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 3E+10 divided by 1E+01?",
        "writeInAnswer": 3000000000,
        "furtherInfo": {
            "explanation": "What is 3E+10 divided by 1E+01?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+09."
        }
    },
    {
        "question": "What is 1E+01 divided by 1E+00?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 1E+01 divided by 1E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 3E+02 divided by 3E+00?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 3E+02 divided by 3E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 1E+05 divided by 3E+00?",
        "writeInAnswer": 30000,
        "furtherInfo": {
            "explanation": "What is 1E+05 divided by 3E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+04."
        }
    },
    {
        "question": "What is 1E+10 divided by 1E+01?",
        "writeInAnswer": 1000000000,
        "furtherInfo": {
            "explanation": "What is 1E+10 divided by 1E+01?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+09."
        }
    },
    {
        "question": "What is 1E+07 divided by 3E+06?",
        "writeInAnswer": 3,
        "furtherInfo": {
            "explanation": "What is 1E+07 divided by 3E+06?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
        }
    },
    {
        "question": "What is 3E+07 divided by 3E+00?",
        "writeInAnswer": 10000000,
        "furtherInfo": {
            "explanation": "What is 3E+07 divided by 3E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+07."
        }
    },
    {
        "question": "What is 3E+01 divided by 3E+00?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 3E+01 divided by 3E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 1E+08 divided by 3E+01?",
        "writeInAnswer": 3000000,
        "furtherInfo": {
            "explanation": "What is 1E+08 divided by 3E+01?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+06."
        }
    },
    {
        "question": "What is 1E+05 divided by 1E+02?",
        "writeInAnswer": 1000,
        "furtherInfo": {
            "explanation": "What is 1E+05 divided by 1E+02?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
        }
    },
    {
        "question": "What is 1E+10 divided by 3E+04?",
        "writeInAnswer": 300000,
        "furtherInfo": {
            "explanation": "What is 1E+10 divided by 3E+04?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+05."
        }
    },
    {
        "question": "What is 1E+03 divided by 3E+00?",
        "writeInAnswer": 300,
        "furtherInfo": {
            "explanation": "What is 1E+03 divided by 3E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+02."
        }
    },
    {
        "question": "What is 1E+10 divided by 3E+05?",
        "writeInAnswer": 30000,
        "furtherInfo": {
            "explanation": "What is 1E+10 divided by 3E+05?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+04."
        }
    },
    {
        "question": "What is 3E+03 divided by 3E+02?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 3E+03 divided by 3E+02?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 1E+07 divided by 3E+04?",
        "writeInAnswer": 300,
        "furtherInfo": {
            "explanation": "What is 1E+07 divided by 3E+04?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+02."
        }
    },
    {
        "question": "What is 3E+12 divided by 3E+02?",
        "writeInAnswer": 10000000000,
        "furtherInfo": {
            "explanation": "What is 3E+12 divided by 3E+02?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+10."
        }
    },
    {
        "question": "What is 3E+11 divided by 3E+04?",
        "writeInAnswer": 10000000,
        "furtherInfo": {
            "explanation": "What is 3E+11 divided by 3E+04?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+07."
        }
    },
    {
        "question": "What is 1E+01 divided by 1E+00?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 1E+01 divided by 1E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 3E+08 divided by 3E+06?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 3E+08 divided by 3E+06?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 1E+10 divided by 3E+04?",
        "writeInAnswer": 300000,
        "furtherInfo": {
            "explanation": "What is 1E+10 divided by 3E+04?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+05."
        }
    },
    {
        "question": "What is 1E+07 divided by 1E+04?",
        "writeInAnswer": 1000,
        "furtherInfo": {
            "explanation": "What is 1E+07 divided by 1E+04?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
        }
    },
    {
        "question": "What is 1E+01 divided by 3E+00?",
        "writeInAnswer": 3,
        "furtherInfo": {
            "explanation": "What is 1E+01 divided by 3E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
        }
    },
    {
        "question": "What is 3E+04 divided by 3E+01?",
        "writeInAnswer": 1000,
        "furtherInfo": {
            "explanation": "What is 3E+04 divided by 3E+01?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
        }
    },
    {
        "question": "What is 3E+06 divided by 3E+05?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 3E+06 divided by 3E+05?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 3E+06 divided by 3E+03?",
        "writeInAnswer": 1000,
        "furtherInfo": {
            "explanation": "What is 3E+06 divided by 3E+03?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
        }
    },
    {
        "question": "What is 1E+03 divided by 3E+01?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 1E+03 divided by 3E+01?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+01."
        }
    },
    {
        "question": "What is 3E+03 divided by 3E+02?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 3E+03 divided by 3E+02?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 1E+04 divided by 1E+03?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 1E+04 divided by 1E+03?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 3E+05 divided by 3E+00?",
        "writeInAnswer": 100000,
        "furtherInfo": {
            "explanation": "What is 3E+05 divided by 3E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+05."
        }
    },
    {
        "question": "What is 3E+13 divided by 3E+06?",
        "writeInAnswer": 10000000,
        "furtherInfo": {
            "explanation": "What is 3E+13 divided by 3E+06?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+07."
        }
    },
    {
        "question": "What is 1E+09 divided by 1E+02?",
        "writeInAnswer": 10000000,
        "furtherInfo": {
            "explanation": "What is 1E+09 divided by 1E+02?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+07."
        }
    },
    {
        "question": "What is 3E+03 divided by 1E+01?",
        "writeInAnswer": 300,
        "furtherInfo": {
            "explanation": "What is 3E+03 divided by 1E+01?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+02."
        }
    },
    {
        "question": "What is 1E+12 divided by 1E+05?",
        "writeInAnswer": 10000000,
        "furtherInfo": {
            "explanation": "What is 1E+12 divided by 1E+05?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+07."
        }
    },
    {
        "question": "What is 1E+11 divided by 1E+06?",
        "writeInAnswer": 100000,
        "furtherInfo": {
            "explanation": "What is 1E+11 divided by 1E+06?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+05."
        }
    },
    {
        "question": "What is 1E+04 divided by 3E+00?",
        "writeInAnswer": 3000,
        "furtherInfo": {
            "explanation": "What is 1E+04 divided by 3E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+03."
        }
    },
    {
        "question": "What is 3E+07 divided by 3E+03?",
        "writeInAnswer": 10000,
        "furtherInfo": {
            "explanation": "What is 3E+07 divided by 3E+03?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+04."
        }
    },
    {
        "question": "What is 3E+02 divided by 3E+00?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 3E+02 divided by 3E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 1E+08 divided by 1E+00?",
        "writeInAnswer": 100000000,
        "furtherInfo": {
            "explanation": "What is 1E+08 divided by 1E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+08."
        }
    },
    {
        "question": "What is 3E+04 divided by 1E+03?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 3E+04 divided by 1E+03?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
        }
    },
    {
        "question": "What is 3E+02 divided by 3E+00?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 3E+02 divided by 3E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 3E+10 divided by 3E+07?",
        "writeInAnswer": 1000,
        "furtherInfo": {
            "explanation": "What is 3E+10 divided by 3E+07?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
        }
    },
    {
        "question": "What is 1E+02 divided by 1E+01?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 1E+02 divided by 1E+01?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 3E+02 divided by 3E+01?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 3E+02 divided by 3E+01?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 3E+08 divided by 3E+00?",
        "writeInAnswer": 100000000,
        "furtherInfo": {
            "explanation": "What is 3E+08 divided by 3E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+08."
        }
    },
    {
        "question": "What is 3E+02 divided by 3E+01?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 3E+02 divided by 3E+01?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 1E+02 divided by 3E+01?",
        "writeInAnswer": 3,
        "furtherInfo": {
            "explanation": "What is 1E+02 divided by 3E+01?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
        }
    },
    {
        "question": "What is 1E+06 divided by 1E+01?",
        "writeInAnswer": 100000,
        "furtherInfo": {
            "explanation": "What is 1E+06 divided by 1E+01?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+05."
        }
    },
    {
        "question": "What is 1E+06 divided by 1E+03?",
        "writeInAnswer": 1000,
        "furtherInfo": {
            "explanation": "What is 1E+06 divided by 1E+03?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
        }
    },
    {
        "question": "What is 3E+09 divided by 1E+01?",
        "writeInAnswer": 300000000,
        "furtherInfo": {
            "explanation": "What is 3E+09 divided by 1E+01?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+08."
        }
    },
    {
        "question": "What is 1E+08 divided by 3E+02?",
        "writeInAnswer": 300000,
        "furtherInfo": {
            "explanation": "What is 1E+08 divided by 3E+02?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+05."
        }
    },
    {
        "question": "What is 1E+03 divided by 1E+02?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 1E+03 divided by 1E+02?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 3E+10 divided by 3E+01?",
        "writeInAnswer": 1000000000,
        "furtherInfo": {
            "explanation": "What is 3E+10 divided by 3E+01?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+09."
        }
    },
    {
        "question": "What is 1E+03 divided by 3E+01?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 1E+03 divided by 3E+01?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+01."
        }
    },
    {
        "question": "What is 3E+04 divided by 1E+01?",
        "writeInAnswer": 3000,
        "furtherInfo": {
            "explanation": "What is 3E+04 divided by 1E+01?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+03."
        }
    },
    {
        "question": "What is 1E+12 divided by 3E+08?",
        "writeInAnswer": 3000,
        "furtherInfo": {
            "explanation": "What is 1E+12 divided by 3E+08?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+03."
        }
    },
    {
        "question": "What is 1E+12 divided by 3E+09?",
        "writeInAnswer": 300,
        "furtherInfo": {
            "explanation": "What is 1E+12 divided by 3E+09?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+02."
        }
    },
    {
        "question": "What is 3E+12 divided by 3E+05?",
        "writeInAnswer": 10000000,
        "furtherInfo": {
            "explanation": "What is 3E+12 divided by 3E+05?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+07."
        }
    },
    {
        "question": "What is 3E+10 divided by 3E+08?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 3E+10 divided by 3E+08?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 3E+05 divided by 3E+04?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 3E+05 divided by 3E+04?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 3E+04 divided by 3E+01?",
        "writeInAnswer": 1000,
        "furtherInfo": {
            "explanation": "What is 3E+04 divided by 3E+01?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
        }
    },
    {
        "question": "What is 1E+04 divided by 3E+03?",
        "writeInAnswer": 3,
        "furtherInfo": {
            "explanation": "What is 1E+04 divided by 3E+03?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
        }
    },
    {
        "question": "What is 1E+10 divided by 3E+05?",
        "writeInAnswer": 30000,
        "furtherInfo": {
            "explanation": "What is 1E+10 divided by 3E+05?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+04."
        }
    },
    {
        "question": "What is 1E+01 divided by 1E+00?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 1E+01 divided by 1E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 3E+12 divided by 1E+05?",
        "writeInAnswer": 30000000,
        "furtherInfo": {
            "explanation": "What is 3E+12 divided by 1E+05?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+07."
        }
    },
    {
        "question": "What is 1E+01 divided by 3E+00?",
        "writeInAnswer": 3,
        "furtherInfo": {
            "explanation": "What is 1E+01 divided by 3E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
        }
    },
    {
        "question": "What is 3E+07 divided by 3E+04?",
        "writeInAnswer": 1000,
        "furtherInfo": {
            "explanation": "What is 3E+07 divided by 3E+04?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
        }
    },
    {
        "question": "What is 3E+02 divided by 1E+01?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 3E+02 divided by 1E+01?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
        }
    },
    {
        "question": "What is 3E+05 divided by 1E+03?",
        "writeInAnswer": 300,
        "furtherInfo": {
            "explanation": "What is 3E+05 divided by 1E+03?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+02."
        }
    },
    {
        "question": "What is 1E+04 divided by 3E+03?",
        "writeInAnswer": 3,
        "furtherInfo": {
            "explanation": "What is 1E+04 divided by 3E+03?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
        }
    },
    {
        "question": "What is 3E+06 divided by 1E+00?",
        "writeInAnswer": 3000000,
        "furtherInfo": {
            "explanation": "What is 3E+06 divided by 1E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+06."
        }
    },
    {
        "question": "What is 1E+11 divided by 1E+01?",
        "writeInAnswer": 10000000000,
        "furtherInfo": {
            "explanation": "What is 1E+11 divided by 1E+01?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+10."
        }
    },
    {
        "question": "What is 1E+07 divided by 1E+00?",
        "writeInAnswer": 10000000,
        "furtherInfo": {
            "explanation": "What is 1E+07 divided by 1E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+07."
        }
    },
    {
        "question": "What is 1E+05 divided by 3E+02?",
        "writeInAnswer": 300,
        "furtherInfo": {
            "explanation": "What is 1E+05 divided by 3E+02?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+02."
        }
    },
    {
        "question": "What is 1E+13 divided by 3E+06?",
        "writeInAnswer": 3000000,
        "furtherInfo": {
            "explanation": "What is 1E+13 divided by 3E+06?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+06."
        }
    },
    {
        "question": "What is 1E+03 divided by 3E+00?",
        "writeInAnswer": 300,
        "furtherInfo": {
            "explanation": "What is 1E+03 divided by 3E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+02."
        }
    },
    {
        "question": "What is 3E+03 divided by 3E+02?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 3E+03 divided by 3E+02?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 3E+02 divided by 1E+01?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 3E+02 divided by 1E+01?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
        }
    },
    {
        "question": "What is 3E+04 divided by 1E+02?",
        "writeInAnswer": 300,
        "furtherInfo": {
            "explanation": "What is 3E+04 divided by 1E+02?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+02."
        }
    },
    {
        "question": "What is 3E+11 divided by 3E+04?",
        "writeInAnswer": 10000000,
        "furtherInfo": {
            "explanation": "What is 3E+11 divided by 3E+04?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+07."
        }
    },
    {
        "question": "What is 3E+09 divided by 3E+03?",
        "writeInAnswer": 1000000,
        "furtherInfo": {
            "explanation": "What is 3E+09 divided by 3E+03?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+06."
        }
    },
    {
        "question": "What is 1E+11 divided by 3E+05?",
        "writeInAnswer": 300000,
        "furtherInfo": {
            "explanation": "What is 1E+11 divided by 3E+05?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+05."
        }
    },
    {
        "question": "What is 3E+04 divided by 3E+00?",
        "writeInAnswer": 10000,
        "furtherInfo": {
            "explanation": "What is 3E+04 divided by 3E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+04."
        }
    },
    {
        "question": "What is 3E+03 divided by 1E+00?",
        "writeInAnswer": 3000,
        "furtherInfo": {
            "explanation": "What is 3E+03 divided by 1E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+03."
        }
    },
    {
        "question": "What is 1E+08 divided by 1E+01?",
        "writeInAnswer": 10000000,
        "furtherInfo": {
            "explanation": "What is 1E+08 divided by 1E+01?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+07."
        }
    },
    {
        "question": "What is 1E+07 divided by 3E+05?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 1E+07 divided by 3E+05?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+01."
        }
    },
    {
        "question": "What is 1E+13 divided by 3E+12?",
        "writeInAnswer": 3,
        "furtherInfo": {
            "explanation": "What is 1E+13 divided by 3E+12?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
        }
    },
    {
        "question": "What is 1E+11 divided by 1E+10?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 1E+11 divided by 1E+10?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 3E+01 divided by 1E+00?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 3E+01 divided by 1E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
        }
    },
    {
        "question": "What is 3E+07 divided by 1E+01?",
        "writeInAnswer": 3000000,
        "furtherInfo": {
            "explanation": "What is 3E+07 divided by 1E+01?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+06."
        }
    },
    {
        "question": "What is 1E+10 divided by 3E+08?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 1E+10 divided by 3E+08?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+01."
        }
    },
    {
        "question": "What is 1E+03 divided by 1E+02?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 1E+03 divided by 1E+02?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 1E+08 divided by 1E+01?",
        "writeInAnswer": 10000000,
        "furtherInfo": {
            "explanation": "What is 1E+08 divided by 1E+01?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+07."
        }
    },
    {
        "question": "What is 1E+05 divided by 3E+01?",
        "writeInAnswer": 3000,
        "furtherInfo": {
            "explanation": "What is 1E+05 divided by 3E+01?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+03."
        }
    },
    {
        "question": "What is 3E+10 divided by 1E+00?",
        "writeInAnswer": 30000000000,
        "furtherInfo": {
            "explanation": "What is 3E+10 divided by 1E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+10."
        }
    },
    {
        "question": "What is 3E+13 divided by 1E+09?",
        "writeInAnswer": 30000,
        "furtherInfo": {
            "explanation": "What is 3E+13 divided by 1E+09?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+04."
        }
    },
    {
        "question": "What is 1E+13 divided by 3E+07?",
        "writeInAnswer": 300000,
        "furtherInfo": {
            "explanation": "What is 1E+13 divided by 3E+07?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+05."
        }
    },
    {
        "question": "What is 3E+04 divided by 3E+02?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 3E+04 divided by 3E+02?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 1E+11 divided by 3E+02?",
        "writeInAnswer": 300000000,
        "furtherInfo": {
            "explanation": "What is 1E+11 divided by 3E+02?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+08."
        }
    },
    {
        "question": "What is 1E+10 divided by 3E+01?",
        "writeInAnswer": 300000000,
        "furtherInfo": {
            "explanation": "What is 1E+10 divided by 3E+01?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+08."
        }
    },
    {
        "question": "What is 3E+05 divided by 3E+01?",
        "writeInAnswer": 10000,
        "furtherInfo": {
            "explanation": "What is 3E+05 divided by 3E+01?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+04."
        }
    },
    {
        "question": "What is 3E+07 divided by 1E+06?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 3E+07 divided by 1E+06?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
        }
    },
    {
        "question": "What is 3E+07 divided by 3E+02?",
        "writeInAnswer": 100000,
        "furtherInfo": {
            "explanation": "What is 3E+07 divided by 3E+02?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+05."
        }
    },
    {
        "question": "What is 3E+10 divided by 1E+06?",
        "writeInAnswer": 30000,
        "furtherInfo": {
            "explanation": "What is 3E+10 divided by 1E+06?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+04."
        }
    },
    {
        "question": "What is 3E+09 divided by 3E+03?",
        "writeInAnswer": 1000000,
        "furtherInfo": {
            "explanation": "What is 3E+09 divided by 3E+03?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+06."
        }
    },
    {
        "question": "What is 1E+07 divided by 1E+03?",
        "writeInAnswer": 10000,
        "furtherInfo": {
            "explanation": "What is 1E+07 divided by 1E+03?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+04."
        }
    },
    {
        "question": "What is 3E+08 divided by 1E+06?",
        "writeInAnswer": 300,
        "furtherInfo": {
            "explanation": "What is 3E+08 divided by 1E+06?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+02."
        }
    },
    {
        "question": "What is 1E+06 divided by 3E+02?",
        "writeInAnswer": 3000,
        "furtherInfo": {
            "explanation": "What is 1E+06 divided by 3E+02?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+03."
        }
    },
    {
        "question": "What is 1E+09 divided by 1E+08?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 1E+09 divided by 1E+08?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 3E+06 divided by 1E+02?",
        "writeInAnswer": 30000,
        "furtherInfo": {
            "explanation": "What is 3E+06 divided by 1E+02?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+04."
        }
    },
    {
        "question": "What is 1E+12 divided by 3E+06?",
        "writeInAnswer": 300000,
        "furtherInfo": {
            "explanation": "What is 1E+12 divided by 3E+06?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+05."
        }
    },
    {
        "question": "What is 3E+09 divided by 1E+08?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 3E+09 divided by 1E+08?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
        }
    },
    {
        "question": "What is 1E+05 divided by 1E+02?",
        "writeInAnswer": 1000,
        "furtherInfo": {
            "explanation": "What is 1E+05 divided by 1E+02?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
        }
    },
    {
        "question": "What is 3E+03 divided by 1E+01?",
        "writeInAnswer": 300,
        "furtherInfo": {
            "explanation": "What is 3E+03 divided by 1E+01?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+02."
        }
    },
    {
        "question": "What is 1E+04 divided by 1E+00?",
        "writeInAnswer": 10000,
        "furtherInfo": {
            "explanation": "What is 1E+04 divided by 1E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+04."
        }
    },
    {
        "question": "What is 1E+13 divided by 3E+12?",
        "writeInAnswer": 3,
        "furtherInfo": {
            "explanation": "What is 1E+13 divided by 3E+12?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
        }
    },
    {
        "question": "What is 3E+11 divided by 1E+07?",
        "writeInAnswer": 30000,
        "furtherInfo": {
            "explanation": "What is 3E+11 divided by 1E+07?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+04."
        }
    },
    {
        "question": "What is 1E+08 divided by 1E+07?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 1E+08 divided by 1E+07?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 3E+02 divided by 1E+01?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 3E+02 divided by 1E+01?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
        }
    },
    {
        "question": "What is 1E+01 divided by 1E+00?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 1E+01 divided by 1E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 3E+01 divided by 3E+00?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 3E+01 divided by 3E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 3E+09 divided by 3E+03?",
        "writeInAnswer": 1000000,
        "furtherInfo": {
            "explanation": "What is 3E+09 divided by 3E+03?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+06."
        }
    },
    {
        "question": "What is 1E+01 divided by 3E+00?",
        "writeInAnswer": 3,
        "furtherInfo": {
            "explanation": "What is 1E+01 divided by 3E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
        }
    },
    {
        "question": "What is 1E+04 divided by 3E+02?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 1E+04 divided by 3E+02?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+01."
        }
    },
    {
        "question": "What is 3E+06 divided by 3E+02?",
        "writeInAnswer": 10000,
        "furtherInfo": {
            "explanation": "What is 3E+06 divided by 3E+02?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+04."
        }
    },
    {
        "question": "What is 1E+13 divided by 1E+11?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 1E+13 divided by 1E+11?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 3E+02 divided by 1E+00?",
        "writeInAnswer": 300,
        "furtherInfo": {
            "explanation": "What is 3E+02 divided by 1E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+02."
        }
    },
    {
        "question": "What is 3E+01 divided by 1E+00?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 3E+01 divided by 1E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
        }
    },
    {
        "question": "What is 1E+03 divided by 1E+00?",
        "writeInAnswer": 1000,
        "furtherInfo": {
            "explanation": "What is 1E+03 divided by 1E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
        }
    },
    {
        "question": "What is 3E+03 divided by 3E+01?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 3E+03 divided by 3E+01?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 3E+13 divided by 1E+11?",
        "writeInAnswer": 300,
        "furtherInfo": {
            "explanation": "What is 3E+13 divided by 1E+11?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+02."
        }
    },
    {
        "question": "What is 3E+04 divided by 1E+01?",
        "writeInAnswer": 3000,
        "furtherInfo": {
            "explanation": "What is 3E+04 divided by 1E+01?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+03."
        }
    },
    {
        "question": "What is 3E+06 divided by 3E+05?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 3E+06 divided by 3E+05?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 1E+06 divided by 1E+00?",
        "writeInAnswer": 1000000,
        "furtherInfo": {
            "explanation": "What is 1E+06 divided by 1E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+06."
        }
    },
    {
        "question": "What is 3E+08 divided by 3E+06?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 3E+08 divided by 3E+06?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 1E+01 divided by 1E+00?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 1E+01 divided by 1E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 1E+02 divided by 3E+00?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 1E+02 divided by 3E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+01."
        }
    },
    {
        "question": "What is 1E+09 divided by 1E+08?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 1E+09 divided by 1E+08?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 1E+07 divided by 1E+06?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 1E+07 divided by 1E+06?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 1E+06 divided by 1E+01?",
        "writeInAnswer": 100000,
        "furtherInfo": {
            "explanation": "What is 1E+06 divided by 1E+01?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+05."
        }
    },
    {
        "question": "What is 3E+01 divided by 1E+00?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 3E+01 divided by 1E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
        }
    },
    {
        "question": "What is 1E+13 divided by 3E+05?",
        "writeInAnswer": 30000000,
        "furtherInfo": {
            "explanation": "What is 1E+13 divided by 3E+05?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+07."
        }
    },
    {
        "question": "What is 1E+02 divided by 3E+00?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 1E+02 divided by 3E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+01."
        }
    },
    {
        "question": "What is 1E+09 divided by 3E+04?",
        "writeInAnswer": 30000,
        "furtherInfo": {
            "explanation": "What is 1E+09 divided by 3E+04?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+04."
        }
    },
    {
        "question": "What is 3E+04 divided by 1E+00?",
        "writeInAnswer": 30000,
        "furtherInfo": {
            "explanation": "What is 3E+04 divided by 1E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+04."
        }
    },
    {
        "question": "What is 1E+08 divided by 1E+07?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 1E+08 divided by 1E+07?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 1E+10 divided by 1E+09?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 1E+10 divided by 1E+09?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 1E+05 divided by 3E+04?",
        "writeInAnswer": 3,
        "furtherInfo": {
            "explanation": "What is 1E+05 divided by 3E+04?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
        }
    },
    {
        "question": "What is 1E+01 divided by 3E+00?",
        "writeInAnswer": 3,
        "furtherInfo": {
            "explanation": "What is 1E+01 divided by 3E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
        }
    },
    {
        "question": "What is 1E+09 divided by 3E+08?",
        "writeInAnswer": 3,
        "furtherInfo": {
            "explanation": "What is 1E+09 divided by 3E+08?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
        }
    },
    {
        "question": "What is 3E+08 divided by 3E+05?",
        "writeInAnswer": 1000,
        "furtherInfo": {
            "explanation": "What is 3E+08 divided by 3E+05?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
        }
    },
    {
        "question": "What is 3E+13 divided by 3E+00?",
        "writeInAnswer": 10000000000000,
        "furtherInfo": {
            "explanation": "What is 3E+13 divided by 3E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+13."
        }
    },
    {
        "question": "What is 3E+12 divided by 3E+05?",
        "writeInAnswer": 10000000,
        "furtherInfo": {
            "explanation": "What is 3E+12 divided by 3E+05?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+07."
        }
    },
    {
        "question": "What is 1E+03 divided by 1E+00?",
        "writeInAnswer": 1000,
        "furtherInfo": {
            "explanation": "What is 1E+03 divided by 1E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
        }
    },
    {
        "question": "What is 3E+11 divided by 1E+03?",
        "writeInAnswer": 300000000,
        "furtherInfo": {
            "explanation": "What is 3E+11 divided by 1E+03?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+08."
        }
    },
    {
        "question": "What is 1E+08 divided by 3E+02?",
        "writeInAnswer": 300000,
        "furtherInfo": {
            "explanation": "What is 1E+08 divided by 3E+02?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+05."
        }
    },
    {
        "question": "What is 1E+03 divided by 3E+02?",
        "writeInAnswer": 3,
        "furtherInfo": {
            "explanation": "What is 1E+03 divided by 3E+02?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
        }
    },
    {
        "question": "What is 1E+03 divided by 3E+01?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 1E+03 divided by 3E+01?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+01."
        }
    },
    {
        "question": "What is 3E+04 divided by 3E+03?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 3E+04 divided by 3E+03?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 1E+12 divided by 1E+11?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 1E+12 divided by 1E+11?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 1E+12 divided by 1E+11?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 1E+12 divided by 1E+11?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 3E+12 divided by 3E+10?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 3E+12 divided by 3E+10?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 3E+10 divided by 1E+06?",
        "writeInAnswer": 30000,
        "furtherInfo": {
            "explanation": "What is 3E+10 divided by 1E+06?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+04."
        }
    },
    {
        "question": "What is 1E+06 divided by 1E+05?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 1E+06 divided by 1E+05?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 3E+05 divided by 3E+01?",
        "writeInAnswer": 10000,
        "furtherInfo": {
            "explanation": "What is 3E+05 divided by 3E+01?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+04."
        }
    },
    {
        "question": "What is 3E+09 divided by 3E+00?",
        "writeInAnswer": 1000000000,
        "furtherInfo": {
            "explanation": "What is 3E+09 divided by 3E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+09."
        }
    },
    {
        "question": "What is 3E+03 divided by 1E+01?",
        "writeInAnswer": 300,
        "furtherInfo": {
            "explanation": "What is 3E+03 divided by 1E+01?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+02."
        }
    },
    {
        "question": "What is 1E+06 divided by 1E+00?",
        "writeInAnswer": 1000000,
        "furtherInfo": {
            "explanation": "What is 1E+06 divided by 1E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+06."
        }
    },
    {
        "question": "What is 1E+03 divided by 3E+02?",
        "writeInAnswer": 3,
        "furtherInfo": {
            "explanation": "What is 1E+03 divided by 3E+02?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
        }
    },
    {
        "question": "What is 1E+02 divided by 1E+01?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 1E+02 divided by 1E+01?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 1E+11 divided by 1E+00?",
        "writeInAnswer": 100000000000,
        "furtherInfo": {
            "explanation": "What is 1E+11 divided by 1E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+11."
        }
    },
    {
        "question": "What is 3E+04 divided by 3E+01?",
        "writeInAnswer": 1000,
        "furtherInfo": {
            "explanation": "What is 3E+04 divided by 3E+01?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
        }
    },
    {
        "question": "What is 1E+07 divided by 3E+05?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 1E+07 divided by 3E+05?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+01."
        }
    },
    {
        "question": "What is 1E+04 divided by 1E+01?",
        "writeInAnswer": 1000,
        "furtherInfo": {
            "explanation": "What is 1E+04 divided by 1E+01?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
        }
    },
    {
        "question": "What is 1E+13 divided by 3E+02?",
        "writeInAnswer": 30000000000,
        "furtherInfo": {
            "explanation": "What is 1E+13 divided by 3E+02?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+10."
        }
    },
    {
        "question": "What is 1E+07 divided by 1E+06?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 1E+07 divided by 1E+06?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 3E+12 divided by 3E+10?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 3E+12 divided by 3E+10?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 1E+04 divided by 3E+00?",
        "writeInAnswer": 3000,
        "furtherInfo": {
            "explanation": "What is 1E+04 divided by 3E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+03."
        }
    },
    {
        "question": "What is 3E+04 divided by 3E+00?",
        "writeInAnswer": 10000,
        "furtherInfo": {
            "explanation": "What is 3E+04 divided by 3E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+04."
        }
    },
    {
        "question": "What is 3E+05 divided by 3E+02?",
        "writeInAnswer": 1000,
        "furtherInfo": {
            "explanation": "What is 3E+05 divided by 3E+02?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
        }
    },
    {
        "question": "What is 3E+01 divided by 3E+00?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 3E+01 divided by 3E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 3E+12 divided by 3E+04?",
        "writeInAnswer": 100000000,
        "furtherInfo": {
            "explanation": "What is 3E+12 divided by 3E+04?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+08."
        }
    },
    {
        "question": "What is 3E+10 divided by 3E+02?",
        "writeInAnswer": 100000000,
        "furtherInfo": {
            "explanation": "What is 3E+10 divided by 3E+02?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+08."
        }
    },
    {
        "question": "What is 1E+01 divided by 3E+00?",
        "writeInAnswer": 3,
        "furtherInfo": {
            "explanation": "What is 1E+01 divided by 3E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
        }
    },
    {
        "question": "What is 3E+04 divided by 3E+02?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 3E+04 divided by 3E+02?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 3E+11 divided by 1E+05?",
        "writeInAnswer": 3000000,
        "furtherInfo": {
            "explanation": "What is 3E+11 divided by 1E+05?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+06."
        }
    },
    {
        "question": "What is 1E+09 divided by 3E+04?",
        "writeInAnswer": 30000,
        "furtherInfo": {
            "explanation": "What is 1E+09 divided by 3E+04?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+04."
        }
    },
    {
        "question": "What is 1E+13 divided by 1E+02?",
        "writeInAnswer": 100000000000,
        "furtherInfo": {
            "explanation": "What is 1E+13 divided by 1E+02?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+11."
        }
    },
    {
        "question": "What is 1E+11 divided by 1E+06?",
        "writeInAnswer": 100000,
        "furtherInfo": {
            "explanation": "What is 1E+11 divided by 1E+06?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+05."
        }
    },
    {
        "question": "What is 1E+10 divided by 3E+05?",
        "writeInAnswer": 30000,
        "furtherInfo": {
            "explanation": "What is 1E+10 divided by 3E+05?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+04."
        }
    },
    {
        "question": "What is 1E+06 divided by 1E+03?",
        "writeInAnswer": 1000,
        "furtherInfo": {
            "explanation": "What is 1E+06 divided by 1E+03?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
        }
    },
    {
        "question": "What is 1E+07 divided by 3E+04?",
        "writeInAnswer": 300,
        "furtherInfo": {
            "explanation": "What is 1E+07 divided by 3E+04?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+02."
        }
    },
    {
        "question": "What is 1E+08 divided by 3E+06?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 1E+08 divided by 3E+06?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+01."
        }
    },
    {
        "question": "What is 3E+04 divided by 1E+03?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 3E+04 divided by 1E+03?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
        }
    },
    {
        "question": "What is 3E+03 divided by 3E+00?",
        "writeInAnswer": 1000,
        "furtherInfo": {
            "explanation": "What is 3E+03 divided by 3E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
        }
    },
    {
        "question": "What is 3E+09 divided by 1E+07?",
        "writeInAnswer": 300,
        "furtherInfo": {
            "explanation": "What is 3E+09 divided by 1E+07?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+02."
        }
    },
    {
        "question": "What is 1E+04 divided by 3E+02?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 1E+04 divided by 3E+02?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+01."
        }
    },
    {
        "question": "What is 3E+09 divided by 3E+08?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 3E+09 divided by 3E+08?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 3E+08 divided by 3E+07?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 3E+08 divided by 3E+07?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 1E+09 divided by 1E+05?",
        "writeInAnswer": 10000,
        "furtherInfo": {
            "explanation": "What is 1E+09 divided by 1E+05?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+04."
        }
    },
    {
        "question": "What is 1E+03 divided by 1E+00?",
        "writeInAnswer": 1000,
        "furtherInfo": {
            "explanation": "What is 1E+03 divided by 1E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
        }
    },
    {
        "question": "What is 1E+04 divided by 1E+01?",
        "writeInAnswer": 1000,
        "furtherInfo": {
            "explanation": "What is 1E+04 divided by 1E+01?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
        }
    },
    {
        "question": "What is 3E+03 divided by 1E+00?",
        "writeInAnswer": 3000,
        "furtherInfo": {
            "explanation": "What is 3E+03 divided by 1E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+03."
        }
    },
    {
        "question": "What is 1E+05 divided by 3E+00?",
        "writeInAnswer": 30000,
        "furtherInfo": {
            "explanation": "What is 1E+05 divided by 3E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+04."
        }
    },
    {
        "question": "What is 1E+05 divided by 1E+04?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 1E+05 divided by 1E+04?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 3E+09 divided by 3E+05?",
        "writeInAnswer": 10000,
        "furtherInfo": {
            "explanation": "What is 3E+09 divided by 3E+05?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+04."
        }
    },
    {
        "question": "What is 1E+08 divided by 3E+02?",
        "writeInAnswer": 300000,
        "furtherInfo": {
            "explanation": "What is 1E+08 divided by 3E+02?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+05."
        }
    },
    {
        "question": "What is 1E+04 divided by 3E+03?",
        "writeInAnswer": 3,
        "furtherInfo": {
            "explanation": "What is 1E+04 divided by 3E+03?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
        }
    },
    {
        "question": "What is 1E+01 divided by 3E+00?",
        "writeInAnswer": 3,
        "furtherInfo": {
            "explanation": "What is 1E+01 divided by 3E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
        }
    },
    {
        "question": "What is 3E+08 divided by 3E+00?",
        "writeInAnswer": 100000000,
        "furtherInfo": {
            "explanation": "What is 3E+08 divided by 3E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+08."
        }
    },
    {
        "question": "What is 1E+09 divided by 3E+08?",
        "writeInAnswer": 3,
        "furtherInfo": {
            "explanation": "What is 1E+09 divided by 3E+08?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
        }
    },
    {
        "question": "What is 1E+02 divided by 1E+01?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 1E+02 divided by 1E+01?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 1E+10 divided by 3E+02?",
        "writeInAnswer": 30000000,
        "furtherInfo": {
            "explanation": "What is 1E+10 divided by 3E+02?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+07."
        }
    },
    {
        "question": "What is 3E+12 divided by 1E+00?",
        "writeInAnswer": 3000000000000,
        "furtherInfo": {
            "explanation": "What is 3E+12 divided by 1E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+12."
        }
    },
    {
        "question": "What is 3E+13 divided by 3E+08?",
        "writeInAnswer": 100000,
        "furtherInfo": {
            "explanation": "What is 3E+13 divided by 3E+08?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+05."
        }
    },
    {
        "question": "What is 1E+11 divided by 1E+05?",
        "writeInAnswer": 1000000,
        "furtherInfo": {
            "explanation": "What is 1E+11 divided by 1E+05?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+06."
        }
    },
    {
        "question": "What is 3E+13 divided by 3E+06?",
        "writeInAnswer": 10000000,
        "furtherInfo": {
            "explanation": "What is 3E+13 divided by 3E+06?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+07."
        }
    },
    {
        "question": "What is 3E+08 divided by 1E+02?",
        "writeInAnswer": 3000000,
        "furtherInfo": {
            "explanation": "What is 3E+08 divided by 1E+02?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+06."
        }
    },
    {
        "question": "What is 1E+05 divided by 3E+04?",
        "writeInAnswer": 3,
        "furtherInfo": {
            "explanation": "What is 1E+05 divided by 3E+04?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
        }
    },
    {
        "question": "What is 3E+04 divided by 1E+01?",
        "writeInAnswer": 3000,
        "furtherInfo": {
            "explanation": "What is 3E+04 divided by 1E+01?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+03."
        }
    },
    {
        "question": "What is 3E+13 divided by 1E+11?",
        "writeInAnswer": 300,
        "furtherInfo": {
            "explanation": "What is 3E+13 divided by 1E+11?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+02."
        }
    },
    {
        "question": "What is 3E+03 divided by 3E+01?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 3E+03 divided by 3E+01?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 3E+10 divided by 3E+04?",
        "writeInAnswer": 1000000,
        "furtherInfo": {
            "explanation": "What is 3E+10 divided by 3E+04?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+06."
        }
    },
    {
        "question": "What is 3E+13 divided by 3E+03?",
        "writeInAnswer": 10000000000,
        "furtherInfo": {
            "explanation": "What is 3E+13 divided by 3E+03?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+10."
        }
    },
    {
        "question": "What is 3E+01 divided by 3E+00?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 3E+01 divided by 3E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 3E+11 divided by 1E+10?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 3E+11 divided by 1E+10?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
        }
    },
    {
        "question": "What is 1E+04 divided by 3E+03?",
        "writeInAnswer": 3,
        "furtherInfo": {
            "explanation": "What is 1E+04 divided by 3E+03?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
        }
    },
    {
        "question": "What is 1E+10 divided by 1E+03?",
        "writeInAnswer": 10000000,
        "furtherInfo": {
            "explanation": "What is 1E+10 divided by 1E+03?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+07."
        }
    },
    {
        "question": "What is 1E+02 divided by 1E+00?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 1E+02 divided by 1E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 3E+08 divided by 3E+01?",
        "writeInAnswer": 10000000,
        "furtherInfo": {
            "explanation": "What is 3E+08 divided by 3E+01?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+07."
        }
    },
    {
        "question": "What is 1E+05 divided by 3E+03?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 1E+05 divided by 3E+03?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+01."
        }
    },
    {
        "question": "What is 1E+12 divided by 3E+07?",
        "writeInAnswer": 30000,
        "furtherInfo": {
            "explanation": "What is 1E+12 divided by 3E+07?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+04."
        }
    },
    {
        "question": "What is 1E+10 divided by 3E+03?",
        "writeInAnswer": 3000000,
        "furtherInfo": {
            "explanation": "What is 1E+10 divided by 3E+03?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+06."
        }
    },
    {
        "question": "What is 3E+03 divided by 1E+02?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 3E+03 divided by 1E+02?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
        }
    },
    {
        "question": "What is 3E+10 divided by 1E+05?",
        "writeInAnswer": 300000,
        "furtherInfo": {
            "explanation": "What is 3E+10 divided by 1E+05?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+05."
        }
    },
    {
        "question": "What is 3E+10 divided by 1E+02?",
        "writeInAnswer": 300000000,
        "furtherInfo": {
            "explanation": "What is 3E+10 divided by 1E+02?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+08."
        }
    },
    {
        "question": "What is 1E+05 divided by 1E+00?",
        "writeInAnswer": 100000,
        "furtherInfo": {
            "explanation": "What is 1E+05 divided by 1E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+05."
        }
    },
    {
        "question": "What is 1E+08 divided by 1E+00?",
        "writeInAnswer": 100000000,
        "furtherInfo": {
            "explanation": "What is 1E+08 divided by 1E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+08."
        }
    },
    {
        "question": "What is 1E+02 divided by 3E+00?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 1E+02 divided by 3E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+01."
        }
    },
    {
        "question": "What is 3E+08 divided by 1E+05?",
        "writeInAnswer": 3000,
        "furtherInfo": {
            "explanation": "What is 3E+08 divided by 1E+05?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+03."
        }
    },
    {
        "question": "What is 1E+10 divided by 3E+04?",
        "writeInAnswer": 300000,
        "furtherInfo": {
            "explanation": "What is 1E+10 divided by 3E+04?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+05."
        }
    },
    {
        "question": "What is 3E+01 divided by 1E+00?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 3E+01 divided by 1E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
        }
    },
    {
        "question": "What is 1E+01 divided by 1E+00?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 1E+01 divided by 1E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 1E+01 divided by 3E+00?",
        "writeInAnswer": 3,
        "furtherInfo": {
            "explanation": "What is 1E+01 divided by 3E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
        }
    },
    {
        "question": "What is 1E+13 divided by 3E+00?",
        "writeInAnswer": 3000000000000,
        "furtherInfo": {
            "explanation": "What is 1E+13 divided by 3E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+12."
        }
    },
    {
        "question": "What is 3E+06 divided by 3E+05?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 3E+06 divided by 3E+05?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 1E+11 divided by 1E+09?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 1E+11 divided by 1E+09?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 1E+04 divided by 3E+03?",
        "writeInAnswer": 3,
        "furtherInfo": {
            "explanation": "What is 1E+04 divided by 3E+03?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
        }
    },
    {
        "question": "What is 1E+08 divided by 3E+05?",
        "writeInAnswer": 300,
        "furtherInfo": {
            "explanation": "What is 1E+08 divided by 3E+05?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+02."
        }
    },
    {
        "question": "What is 3E+12 divided by 3E+11?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 3E+12 divided by 3E+11?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 1E+03 divided by 1E+02?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 1E+03 divided by 1E+02?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 1E+05 divided by 1E+00?",
        "writeInAnswer": 100000,
        "furtherInfo": {
            "explanation": "What is 1E+05 divided by 1E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+05."
        }
    },
    {
        "question": "What is 3E+13 divided by 3E+11?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 3E+13 divided by 3E+11?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 3E+09 divided by 1E+05?",
        "writeInAnswer": 30000,
        "furtherInfo": {
            "explanation": "What is 3E+09 divided by 1E+05?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+04."
        }
    },
    {
        "question": "What is 3E+06 divided by 1E+05?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 3E+06 divided by 1E+05?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
        }
    },
    {
        "question": "What is 3E+01 divided by 3E+00?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 3E+01 divided by 3E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 1E+10 divided by 1E+05?",
        "writeInAnswer": 100000,
        "furtherInfo": {
            "explanation": "What is 1E+10 divided by 1E+05?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+05."
        }
    },
    {
        "question": "What is 1E+12 divided by 3E+01?",
        "writeInAnswer": 30000000000,
        "furtherInfo": {
            "explanation": "What is 1E+12 divided by 3E+01?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+10."
        }
    },
    {
        "question": "What is 3E+10 divided by 3E+08?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 3E+10 divided by 3E+08?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 3E+06 divided by 1E+03?",
        "writeInAnswer": 3000,
        "furtherInfo": {
            "explanation": "What is 3E+06 divided by 1E+03?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+03."
        }
    },
    {
        "question": "What is 1E+06 divided by 1E+00?",
        "writeInAnswer": 1000000,
        "furtherInfo": {
            "explanation": "What is 1E+06 divided by 1E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+06."
        }
    },
    {
        "question": "What is 1E+13 divided by 1E+03?",
        "writeInAnswer": 10000000000,
        "furtherInfo": {
            "explanation": "What is 1E+13 divided by 1E+03?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+10."
        }
    },
    {
        "question": "What is 1E+02 divided by 1E+01?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 1E+02 divided by 1E+01?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 3E+12 divided by 3E+08?",
        "writeInAnswer": 10000,
        "furtherInfo": {
            "explanation": "What is 3E+12 divided by 3E+08?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+04."
        }
    },
    {
        "question": "What is 3E+11 divided by 3E+05?",
        "writeInAnswer": 1000000,
        "furtherInfo": {
            "explanation": "What is 3E+11 divided by 3E+05?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+06."
        }
    },
    {
        "question": "What is 3E+05 divided by 1E+03?",
        "writeInAnswer": 300,
        "furtherInfo": {
            "explanation": "What is 3E+05 divided by 1E+03?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+02."
        }
    },
    {
        "question": "What is 3E+11 divided by 3E+06?",
        "writeInAnswer": 100000,
        "furtherInfo": {
            "explanation": "What is 3E+11 divided by 3E+06?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+05."
        }
    },
    {
        "question": "What is 3E+12 divided by 3E+07?",
        "writeInAnswer": 100000,
        "furtherInfo": {
            "explanation": "What is 3E+12 divided by 3E+07?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+05."
        }
    },
    {
        "question": "What is 3E+03 divided by 1E+00?",
        "writeInAnswer": 3000,
        "furtherInfo": {
            "explanation": "What is 3E+03 divided by 1E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+03."
        }
    },
    {
        "question": "What is 3E+12 divided by 3E+02?",
        "writeInAnswer": 10000000000,
        "furtherInfo": {
            "explanation": "What is 3E+12 divided by 3E+02?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+10."
        }
    },
    {
        "question": "What is 3E+08 divided by 3E+03?",
        "writeInAnswer": 100000,
        "furtherInfo": {
            "explanation": "What is 3E+08 divided by 3E+03?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+05."
        }
    },
    {
        "question": "What is 3E+01 divided by 1E+00?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 3E+01 divided by 1E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
        }
    },
    {
        "question": "What is 1E+09 divided by 3E+02?",
        "writeInAnswer": 3000000,
        "furtherInfo": {
            "explanation": "What is 1E+09 divided by 3E+02?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+06."
        }
    },
    {
        "question": "What is 3E+05 divided by 3E+04?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 3E+05 divided by 3E+04?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 1E+06 divided by 3E+01?",
        "writeInAnswer": 30000,
        "furtherInfo": {
            "explanation": "What is 1E+06 divided by 3E+01?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+04."
        }
    },
    {
        "question": "What is 3E+09 divided by 1E+02?",
        "writeInAnswer": 30000000,
        "furtherInfo": {
            "explanation": "What is 3E+09 divided by 1E+02?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+07."
        }
    },
    {
        "question": "What is 3E+08 divided by 3E+05?",
        "writeInAnswer": 1000,
        "furtherInfo": {
            "explanation": "What is 3E+08 divided by 3E+05?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
        }
    },
    {
        "question": "What is 3E+02 divided by 3E+00?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 3E+02 divided by 3E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 3E+10 divided by 1E+09?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 3E+10 divided by 1E+09?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
        }
    },
    {
        "question": "What is 3E+09 divided by 3E+07?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 3E+09 divided by 3E+07?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 1E+02 divided by 1E+00?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 1E+02 divided by 1E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 3E+01 divided by 1E+00?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 3E+01 divided by 1E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
        }
    },
    {
        "question": "What is 3E+13 divided by 1E+07?",
        "writeInAnswer": 3000000,
        "furtherInfo": {
            "explanation": "What is 3E+13 divided by 1E+07?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+06."
        }
    },
    {
        "question": "What is 1E+04 divided by 3E+03?",
        "writeInAnswer": 3,
        "furtherInfo": {
            "explanation": "What is 1E+04 divided by 3E+03?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
        }
    },
    {
        "question": "What is 1E+10 divided by 3E+07?",
        "writeInAnswer": 300,
        "furtherInfo": {
            "explanation": "What is 1E+10 divided by 3E+07?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+02."
        }
    },
    {
        "question": "What is 3E+10 divided by 3E+09?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 3E+10 divided by 3E+09?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 1E+02 divided by 3E+01?",
        "writeInAnswer": 3,
        "furtherInfo": {
            "explanation": "What is 1E+02 divided by 3E+01?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
        }
    },
    {
        "question": "What is 3E+04 divided by 1E+01?",
        "writeInAnswer": 3000,
        "furtherInfo": {
            "explanation": "What is 3E+04 divided by 1E+01?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+03."
        }
    },
    {
        "question": "What is 1E+11 divided by 3E+05?",
        "writeInAnswer": 300000,
        "furtherInfo": {
            "explanation": "What is 1E+11 divided by 3E+05?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+05."
        }
    },
    {
        "question": "What is 3E+08 divided by 1E+02?",
        "writeInAnswer": 3000000,
        "furtherInfo": {
            "explanation": "What is 3E+08 divided by 1E+02?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+06."
        }
    },
    {
        "question": "What is 1E+04 divided by 3E+00?",
        "writeInAnswer": 3000,
        "furtherInfo": {
            "explanation": "What is 1E+04 divided by 3E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+03."
        }
    },
    {
        "question": "What is 1E+07 divided by 3E+02?",
        "writeInAnswer": 30000,
        "furtherInfo": {
            "explanation": "What is 1E+07 divided by 3E+02?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+04."
        }
    },
    {
        "question": "What is 1E+04 divided by 1E+01?",
        "writeInAnswer": 1000,
        "furtherInfo": {
            "explanation": "What is 1E+04 divided by 1E+01?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
        }
    },
    {
        "question": "What is 1E+13 divided by 1E+00?",
        "writeInAnswer": 10000000000000,
        "furtherInfo": {
            "explanation": "What is 1E+13 divided by 1E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+13."
        }
    },
    {
        "question": "What is 3E+10 divided by 1E+07?",
        "writeInAnswer": 3000,
        "furtherInfo": {
            "explanation": "What is 3E+10 divided by 1E+07?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+03."
        }
    },
    {
        "question": "What is 1E+07 divided by 3E+00?",
        "writeInAnswer": 3000000,
        "furtherInfo": {
            "explanation": "What is 1E+07 divided by 3E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+06."
        }
    },
    {
        "question": "What is 3E+05 divided by 1E+01?",
        "writeInAnswer": 30000,
        "furtherInfo": {
            "explanation": "What is 3E+05 divided by 1E+01?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+04."
        }
    },
    {
        "question": "What is 3E+03 divided by 3E+02?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 3E+03 divided by 3E+02?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 3E+10 divided by 3E+05?",
        "writeInAnswer": 100000,
        "furtherInfo": {
            "explanation": "What is 3E+10 divided by 3E+05?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+05."
        }
    },
    {
        "question": "What is 3E+11 divided by 3E+05?",
        "writeInAnswer": 1000000,
        "furtherInfo": {
            "explanation": "What is 3E+11 divided by 3E+05?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+06."
        }
    },
    {
        "question": "What is 1E+05 divided by 3E+00?",
        "writeInAnswer": 30000,
        "furtherInfo": {
            "explanation": "What is 1E+05 divided by 3E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+04."
        }
    },
    {
        "question": "What is 3E+11 divided by 3E+10?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 3E+11 divided by 3E+10?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 1E+09 divided by 3E+08?",
        "writeInAnswer": 3,
        "furtherInfo": {
            "explanation": "What is 1E+09 divided by 3E+08?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
        }
    },
    {
        "question": "What is 3E+12 divided by 1E+04?",
        "writeInAnswer": 300000000,
        "furtherInfo": {
            "explanation": "What is 3E+12 divided by 1E+04?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+08."
        }
    },
    {
        "question": "What is 1E+09 divided by 1E+02?",
        "writeInAnswer": 10000000,
        "furtherInfo": {
            "explanation": "What is 1E+09 divided by 1E+02?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+07."
        }
    },
    {
        "question": "What is 1E+05 divided by 1E+00?",
        "writeInAnswer": 100000,
        "furtherInfo": {
            "explanation": "What is 1E+05 divided by 1E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+05."
        }
    },
    {
        "question": "What is 3E+02 divided by 3E+00?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 3E+02 divided by 3E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 3E+13 divided by 3E+02?",
        "writeInAnswer": 100000000000,
        "furtherInfo": {
            "explanation": "What is 3E+13 divided by 3E+02?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+11."
        }
    },
    {
        "question": "What is 1E+02 divided by 3E+01?",
        "writeInAnswer": 3,
        "furtherInfo": {
            "explanation": "What is 1E+02 divided by 3E+01?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
        }
    },
    {
        "question": "What is 3E+07 divided by 3E+04?",
        "writeInAnswer": 1000,
        "furtherInfo": {
            "explanation": "What is 3E+07 divided by 3E+04?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
        }
    },
    {
        "question": "What is 3E+04 divided by 3E+00?",
        "writeInAnswer": 10000,
        "furtherInfo": {
            "explanation": "What is 3E+04 divided by 3E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+04."
        }
    },
    {
        "question": "What is 3E+01 divided by 3E+00?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 3E+01 divided by 3E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 3E+06 divided by 3E+03?",
        "writeInAnswer": 1000,
        "furtherInfo": {
            "explanation": "What is 3E+06 divided by 3E+03?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
        }
    },
    {
        "question": "What is 1E+01 divided by 3E+00?",
        "writeInAnswer": 3,
        "furtherInfo": {
            "explanation": "What is 1E+01 divided by 3E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
        }
    },
    {
        "question": "What is 1E+13 divided by 3E+08?",
        "writeInAnswer": 30000,
        "furtherInfo": {
            "explanation": "What is 1E+13 divided by 3E+08?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+04."
        }
    },
    {
        "question": "What is 3E+04 divided by 3E+02?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 3E+04 divided by 3E+02?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 1E+10 divided by 1E+06?",
        "writeInAnswer": 10000,
        "furtherInfo": {
            "explanation": "What is 1E+10 divided by 1E+06?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+04."
        }
    },
    {
        "question": "What is 3E+06 divided by 1E+04?",
        "writeInAnswer": 300,
        "furtherInfo": {
            "explanation": "What is 3E+06 divided by 1E+04?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+02."
        }
    },
    {
        "question": "What is 3E+06 divided by 1E+05?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 3E+06 divided by 1E+05?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
        }
    },
    {
        "question": "What is 3E+08 divided by 1E+07?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 3E+08 divided by 1E+07?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
        }
    },
    {
        "question": "What is 3E+12 divided by 3E+07?",
        "writeInAnswer": 100000,
        "furtherInfo": {
            "explanation": "What is 3E+12 divided by 3E+07?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+05."
        }
    },
    {
        "question": "What is 3E+06 divided by 3E+00?",
        "writeInAnswer": 1000000,
        "furtherInfo": {
            "explanation": "What is 3E+06 divided by 3E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+06."
        }
    },
    {
        "question": "What is 3E+04 divided by 1E+00?",
        "writeInAnswer": 30000,
        "furtherInfo": {
            "explanation": "What is 3E+04 divided by 1E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+04."
        }
    },
    {
        "question": "What is 1E+10 divided by 3E+01?",
        "writeInAnswer": 300000000,
        "furtherInfo": {
            "explanation": "What is 1E+10 divided by 3E+01?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+08."
        }
    },
    {
        "question": "What is 3E+08 divided by 3E+00?",
        "writeInAnswer": 100000000,
        "furtherInfo": {
            "explanation": "What is 3E+08 divided by 3E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+08."
        }
    },
    {
        "question": "What is 1E+13 divided by 1E+11?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 1E+13 divided by 1E+11?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 1E+10 divided by 3E+08?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 1E+10 divided by 3E+08?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+01."
        }
    },
    {
        "question": "What is 1E+10 divided by 1E+04?",
        "writeInAnswer": 1000000,
        "furtherInfo": {
            "explanation": "What is 1E+10 divided by 1E+04?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+06."
        }
    },
    {
        "question": "What is 1E+03 divided by 3E+02?",
        "writeInAnswer": 3,
        "furtherInfo": {
            "explanation": "What is 1E+03 divided by 3E+02?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
        }
    },
    {
        "question": "What is 1E+09 divided by 3E+04?",
        "writeInAnswer": 30000,
        "furtherInfo": {
            "explanation": "What is 1E+09 divided by 3E+04?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+04."
        }
    },
    {
        "question": "What is 1E+09 divided by 1E+08?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 1E+09 divided by 1E+08?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 1E+04 divided by 1E+02?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 1E+04 divided by 1E+02?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 1E+08 divided by 3E+04?",
        "writeInAnswer": 3000,
        "furtherInfo": {
            "explanation": "What is 1E+08 divided by 3E+04?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+03."
        }
    },
    {
        "question": "What is 3E+11 divided by 1E+04?",
        "writeInAnswer": 30000000,
        "furtherInfo": {
            "explanation": "What is 3E+11 divided by 1E+04?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+07."
        }
    },
    {
        "question": "What is 1E+07 divided by 1E+02?",
        "writeInAnswer": 100000,
        "furtherInfo": {
            "explanation": "What is 1E+07 divided by 1E+02?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+05."
        }
    },
    {
        "question": "What is 1E+06 divided by 1E+04?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 1E+06 divided by 1E+04?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 1E+09 divided by 3E+07?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 1E+09 divided by 3E+07?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+01."
        }
    },
    {
        "question": "What is 3E+02 divided by 3E+00?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 3E+02 divided by 3E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 1E+12 divided by 3E+06?",
        "writeInAnswer": 300000,
        "furtherInfo": {
            "explanation": "What is 1E+12 divided by 3E+06?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+05."
        }
    },
    {
        "question": "What is 3E+13 divided by 1E+01?",
        "writeInAnswer": 3000000000000,
        "furtherInfo": {
            "explanation": "What is 3E+13 divided by 1E+01?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+12."
        }
    },
    {
        "question": "What is 1E+03 divided by 1E+00?",
        "writeInAnswer": 1000,
        "furtherInfo": {
            "explanation": "What is 1E+03 divided by 1E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
        }
    },
    {
        "question": "What is 1E+03 divided by 1E+02?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 1E+03 divided by 1E+02?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 3E+12 divided by 1E+04?",
        "writeInAnswer": 300000000,
        "furtherInfo": {
            "explanation": "What is 3E+12 divided by 1E+04?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+08."
        }
    },
    {
        "question": "What is 3E+13 divided by 1E+11?",
        "writeInAnswer": 300,
        "furtherInfo": {
            "explanation": "What is 3E+13 divided by 1E+11?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+02."
        }
    },
    {
        "question": "What is 3E+03 divided by 3E+02?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 3E+03 divided by 3E+02?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 1E+05 divided by 3E+02?",
        "writeInAnswer": 300,
        "furtherInfo": {
            "explanation": "What is 1E+05 divided by 3E+02?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+02."
        }
    },
    {
        "question": "What is 3E+06 divided by 1E+00?",
        "writeInAnswer": 3000000,
        "furtherInfo": {
            "explanation": "What is 3E+06 divided by 1E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+06."
        }
    },
    {
        "question": "What is 3E+13 divided by 1E+10?",
        "writeInAnswer": 3000,
        "furtherInfo": {
            "explanation": "What is 3E+13 divided by 1E+10?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+03."
        }
    },
    {
        "question": "What is 3E+07 divided by 1E+04?",
        "writeInAnswer": 3000,
        "furtherInfo": {
            "explanation": "What is 3E+07 divided by 1E+04?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+03."
        }
    },
    {
        "question": "What is 1E+08 divided by 1E+05?",
        "writeInAnswer": 1000,
        "furtherInfo": {
            "explanation": "What is 1E+08 divided by 1E+05?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
        }
    },
    {
        "question": "What is 3E+13 divided by 1E+03?",
        "writeInAnswer": 30000000000,
        "furtherInfo": {
            "explanation": "What is 3E+13 divided by 1E+03?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+10."
        }
    },
    {
        "question": "What is 3E+13 divided by 1E+04?",
        "writeInAnswer": 3000000000,
        "furtherInfo": {
            "explanation": "What is 3E+13 divided by 1E+04?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+09."
        }
    },
    {
        "question": "What is 1E+09 divided by 1E+08?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 1E+09 divided by 1E+08?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 3E+12 divided by 3E+03?",
        "writeInAnswer": 1000000000,
        "furtherInfo": {
            "explanation": "What is 3E+12 divided by 3E+03?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+09."
        }
    },
    {
        "question": "What is 3E+08 divided by 3E+06?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 3E+08 divided by 3E+06?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 1E+03 divided by 1E+01?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 1E+03 divided by 1E+01?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 1E+11 divided by 1E+08?",
        "writeInAnswer": 1000,
        "furtherInfo": {
            "explanation": "What is 1E+11 divided by 1E+08?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
        }
    },
    {
        "question": "What is 3E+06 divided by 3E+03?",
        "writeInAnswer": 1000,
        "furtherInfo": {
            "explanation": "What is 3E+06 divided by 3E+03?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
        }
    },
    {
        "question": "What is 3E+10 divided by 1E+07?",
        "writeInAnswer": 3000,
        "furtherInfo": {
            "explanation": "What is 3E+10 divided by 1E+07?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+03."
        }
    },
    {
        "question": "What is 1E+04 divided by 3E+01?",
        "writeInAnswer": 300,
        "furtherInfo": {
            "explanation": "What is 1E+04 divided by 3E+01?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+02."
        }
    },
    {
        "question": "What is 1E+05 divided by 3E+03?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 1E+05 divided by 3E+03?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+01."
        }
    },
    {
        "question": "What is 3E+01 divided by 1E+00?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 3E+01 divided by 1E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
        }
    },
    {
        "question": "What is 3E+07 divided by 3E+00?",
        "writeInAnswer": 10000000,
        "furtherInfo": {
            "explanation": "What is 3E+07 divided by 3E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+07."
        }
    },
    {
        "question": "What is 1E+04 divided by 3E+01?",
        "writeInAnswer": 300,
        "furtherInfo": {
            "explanation": "What is 1E+04 divided by 3E+01?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+02."
        }
    },
    {
        "question": "What is 1E+01 divided by 3E+00?",
        "writeInAnswer": 3,
        "furtherInfo": {
            "explanation": "What is 1E+01 divided by 3E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
        }
    },
    {
        "question": "What is 1E+01 divided by 1E+00?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 1E+01 divided by 1E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 3E+06 divided by 3E+00?",
        "writeInAnswer": 1000000,
        "furtherInfo": {
            "explanation": "What is 3E+06 divided by 3E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+06."
        }
    },
    {
        "question": "What is 1E+12 divided by 1E+01?",
        "writeInAnswer": 100000000000,
        "furtherInfo": {
            "explanation": "What is 1E+12 divided by 1E+01?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+11."
        }
    },
    {
        "question": "What is 3E+01 divided by 1E+00?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 3E+01 divided by 1E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
        }
    },
    {
        "question": "What is 3E+13 divided by 3E+05?",
        "writeInAnswer": 100000000,
        "furtherInfo": {
            "explanation": "What is 3E+13 divided by 3E+05?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+08."
        }
    },
    {
        "question": "What is 3E+02 divided by 3E+00?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 3E+02 divided by 3E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 1E+10 divided by 3E+04?",
        "writeInAnswer": 300000,
        "furtherInfo": {
            "explanation": "What is 1E+10 divided by 3E+04?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+05."
        }
    },
    {
        "question": "What is 1E+11 divided by 3E+10?",
        "writeInAnswer": 3,
        "furtherInfo": {
            "explanation": "What is 1E+11 divided by 3E+10?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
        }
    },
    {
        "question": "What is 3E+02 divided by 3E+00?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 3E+02 divided by 3E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 1E+13 divided by 1E+08?",
        "writeInAnswer": 100000,
        "furtherInfo": {
            "explanation": "What is 1E+13 divided by 1E+08?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+05."
        }
    },
    {
        "question": "What is 1E+05 divided by 3E+01?",
        "writeInAnswer": 3000,
        "furtherInfo": {
            "explanation": "What is 1E+05 divided by 3E+01?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+03."
        }
    },
    {
        "question": "What is 3E+10 divided by 3E+04?",
        "writeInAnswer": 1000000,
        "furtherInfo": {
            "explanation": "What is 3E+10 divided by 3E+04?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+06."
        }
    },
    {
        "question": "What is 1E+06 divided by 1E+03?",
        "writeInAnswer": 1000,
        "furtherInfo": {
            "explanation": "What is 1E+06 divided by 1E+03?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
        }
    },
    {
        "question": "What is 3E+09 divided by 3E+01?",
        "writeInAnswer": 100000000,
        "furtherInfo": {
            "explanation": "What is 3E+09 divided by 3E+01?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+08."
        }
    },
    {
        "question": "What is 3E+02 divided by 1E+00?",
        "writeInAnswer": 300,
        "furtherInfo": {
            "explanation": "What is 3E+02 divided by 1E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+02."
        }
    },
    {
        "question": "What is 3E+12 divided by 3E+07?",
        "writeInAnswer": 100000,
        "furtherInfo": {
            "explanation": "What is 3E+12 divided by 3E+07?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+05."
        }
    },
    {
        "question": "What is 1E+09 divided by 3E+04?",
        "writeInAnswer": 30000,
        "furtherInfo": {
            "explanation": "What is 1E+09 divided by 3E+04?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+04."
        }
    },
    {
        "question": "What is 1E+08 divided by 1E+03?",
        "writeInAnswer": 100000,
        "furtherInfo": {
            "explanation": "What is 1E+08 divided by 1E+03?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+05."
        }
    },
    {
        "question": "What is 3E+04 divided by 3E+01?",
        "writeInAnswer": 1000,
        "furtherInfo": {
            "explanation": "What is 3E+04 divided by 3E+01?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
        }
    },
    {
        "question": "What is 3E+01 divided by 1E+00?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 3E+01 divided by 1E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
        }
    },
    {
        "question": "What is 1E+01 divided by 3E+00?",
        "writeInAnswer": 3,
        "furtherInfo": {
            "explanation": "What is 1E+01 divided by 3E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
        }
    },
    {
        "question": "What is 1E+07 divided by 1E+03?",
        "writeInAnswer": 10000,
        "furtherInfo": {
            "explanation": "What is 1E+07 divided by 1E+03?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+04."
        }
    },
    {
        "question": "What is 3E+12 divided by 1E+07?",
        "writeInAnswer": 300000,
        "furtherInfo": {
            "explanation": "What is 3E+12 divided by 1E+07?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+05."
        }
    },
    {
        "question": "What is 3E+12 divided by 1E+10?",
        "writeInAnswer": 300,
        "furtherInfo": {
            "explanation": "What is 3E+12 divided by 1E+10?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+02."
        }
    },
    {
        "question": "What is 3E+06 divided by 3E+05?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 3E+06 divided by 3E+05?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 1E+05 divided by 3E+00?",
        "writeInAnswer": 30000,
        "furtherInfo": {
            "explanation": "What is 1E+05 divided by 3E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+04."
        }
    },
    {
        "question": "What is 1E+07 divided by 3E+00?",
        "writeInAnswer": 3000000,
        "furtherInfo": {
            "explanation": "What is 1E+07 divided by 3E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+06."
        }
    },
    {
        "question": "What is 3E+01 divided by 1E+00?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 3E+01 divided by 1E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
        }
    },
    {
        "question": "What is 1E+11 divided by 3E+03?",
        "writeInAnswer": 30000000,
        "furtherInfo": {
            "explanation": "What is 1E+11 divided by 3E+03?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+07."
        }
    },
    {
        "question": "What is 1E+04 divided by 1E+00?",
        "writeInAnswer": 10000,
        "furtherInfo": {
            "explanation": "What is 1E+04 divided by 1E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+04."
        }
    },
    {
        "question": "What is 1E+01 divided by 3E+00?",
        "writeInAnswer": 3,
        "furtherInfo": {
            "explanation": "What is 1E+01 divided by 3E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
        }
    },
    {
        "question": "What is 3E+04 divided by 3E+02?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 3E+04 divided by 3E+02?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 1E+05 divided by 1E+02?",
        "writeInAnswer": 1000,
        "furtherInfo": {
            "explanation": "What is 1E+05 divided by 1E+02?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
        }
    },
    {
        "question": "What is 1E+11 divided by 1E+05?",
        "writeInAnswer": 1000000,
        "furtherInfo": {
            "explanation": "What is 1E+11 divided by 1E+05?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+06."
        }
    },
    {
        "question": "What is 3E+07 divided by 1E+01?",
        "writeInAnswer": 3000000,
        "furtherInfo": {
            "explanation": "What is 3E+07 divided by 1E+01?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+06."
        }
    },
    {
        "question": "What is 3E+10 divided by 1E+09?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 3E+10 divided by 1E+09?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
        }
    },
    {
        "question": "What is 3E+04 divided by 3E+00?",
        "writeInAnswer": 10000,
        "furtherInfo": {
            "explanation": "What is 3E+04 divided by 3E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+04."
        }
    },
    {
        "question": "What is 3E+12 divided by 1E+05?",
        "writeInAnswer": 30000000,
        "furtherInfo": {
            "explanation": "What is 3E+12 divided by 1E+05?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+07."
        }
    },
    {
        "question": "What is 3E+01 divided by 1E+00?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 3E+01 divided by 1E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
        }
    },
    {
        "question": "What is 3E+10 divided by 1E+09?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 3E+10 divided by 1E+09?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
        }
    },
    {
        "question": "What is 1E+06 divided by 1E+04?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 1E+06 divided by 1E+04?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 1E+13 divided by 3E+09?",
        "writeInAnswer": 3000,
        "furtherInfo": {
            "explanation": "What is 1E+13 divided by 3E+09?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+03."
        }
    },
    {
        "question": "What is 3E+02 divided by 1E+00?",
        "writeInAnswer": 300,
        "furtherInfo": {
            "explanation": "What is 3E+02 divided by 1E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+02."
        }
    },
    {
        "question": "What is 1E+05 divided by 3E+01?",
        "writeInAnswer": 3000,
        "furtherInfo": {
            "explanation": "What is 1E+05 divided by 3E+01?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+03."
        }
    },
    {
        "question": "What is 3E+10 divided by 1E+06?",
        "writeInAnswer": 30000,
        "furtherInfo": {
            "explanation": "What is 3E+10 divided by 1E+06?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+04."
        }
    },
    {
        "question": "What is 3E+05 divided by 3E+03?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 3E+05 divided by 3E+03?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 1E+04 divided by 3E+02?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 1E+04 divided by 3E+02?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+01."
        }
    },
    {
        "question": "What is 3E+04 divided by 3E+00?",
        "writeInAnswer": 10000,
        "furtherInfo": {
            "explanation": "What is 3E+04 divided by 3E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+04."
        }
    },
    {
        "question": "What is 1E+10 divided by 3E+06?",
        "writeInAnswer": 3000,
        "furtherInfo": {
            "explanation": "What is 1E+10 divided by 3E+06?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+03."
        }
    },
    {
        "question": "What is 1E+13 divided by 1E+01?",
        "writeInAnswer": 1000000000000,
        "furtherInfo": {
            "explanation": "What is 1E+13 divided by 1E+01?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+12."
        }
    },
    {
        "question": "What is 3E+03 divided by 3E+01?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 3E+03 divided by 3E+01?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 3E+07 divided by 1E+06?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 3E+07 divided by 1E+06?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
        }
    },
    {
        "question": "What is 3E+01 divided by 3E+00?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 3E+01 divided by 3E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 1E+03 divided by 1E+02?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 1E+03 divided by 1E+02?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 3E+04 divided by 1E+02?",
        "writeInAnswer": 300,
        "furtherInfo": {
            "explanation": "What is 3E+04 divided by 1E+02?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+02."
        }
    },
    {
        "question": "What is 3E+03 divided by 3E+02?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 3E+03 divided by 3E+02?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 1E+05 divided by 3E+01?",
        "writeInAnswer": 3000,
        "furtherInfo": {
            "explanation": "What is 1E+05 divided by 3E+01?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+03."
        }
    },
    {
        "question": "What is 1E+02 divided by 1E+00?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 1E+02 divided by 1E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 3E+04 divided by 1E+01?",
        "writeInAnswer": 3000,
        "furtherInfo": {
            "explanation": "What is 3E+04 divided by 1E+01?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+03."
        }
    },
    {
        "question": "What is 1E+03 divided by 3E+02?",
        "writeInAnswer": 3,
        "furtherInfo": {
            "explanation": "What is 1E+03 divided by 3E+02?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
        }
    },
    {
        "question": "What is 3E+11 divided by 1E+02?",
        "writeInAnswer": 3000000000,
        "furtherInfo": {
            "explanation": "What is 3E+11 divided by 1E+02?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+09."
        }
    },
    {
        "question": "What is 1E+02 divided by 1E+00?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 1E+02 divided by 1E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 3E+02 divided by 1E+00?",
        "writeInAnswer": 300,
        "furtherInfo": {
            "explanation": "What is 3E+02 divided by 1E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+02."
        }
    },
    {
        "question": "What is 3E+13 divided by 3E+12?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 3E+13 divided by 3E+12?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 1E+11 divided by 3E+07?",
        "writeInAnswer": 3000,
        "furtherInfo": {
            "explanation": "What is 1E+11 divided by 3E+07?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+03."
        }
    },
    {
        "question": "What is 3E+11 divided by 1E+06?",
        "writeInAnswer": 300000,
        "furtherInfo": {
            "explanation": "What is 3E+11 divided by 1E+06?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+05."
        }
    },
    {
        "question": "What is 3E+07 divided by 3E+00?",
        "writeInAnswer": 10000000,
        "furtherInfo": {
            "explanation": "What is 3E+07 divided by 3E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+07."
        }
    },
    {
        "question": "What is 3E+05 divided by 1E+01?",
        "writeInAnswer": 30000,
        "furtherInfo": {
            "explanation": "What is 3E+05 divided by 1E+01?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+04."
        }
    },
    {
        "question": "What is 1E+06 divided by 1E+01?",
        "writeInAnswer": 100000,
        "furtherInfo": {
            "explanation": "What is 1E+06 divided by 1E+01?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+05."
        }
    },
    {
        "question": "What is 1E+06 divided by 3E+04?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 1E+06 divided by 3E+04?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+01."
        }
    },
    {
        "question": "What is 3E+12 divided by 1E+11?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 3E+12 divided by 1E+11?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
        }
    },
    {
        "question": "What is 1E+13 divided by 3E+05?",
        "writeInAnswer": 30000000,
        "furtherInfo": {
            "explanation": "What is 1E+13 divided by 3E+05?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+07."
        }
    },
    {
        "question": "What is 3E+05 divided by 3E+04?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 3E+05 divided by 3E+04?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 1E+13 divided by 3E+04?",
        "writeInAnswer": 300000000,
        "furtherInfo": {
            "explanation": "What is 1E+13 divided by 3E+04?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+08."
        }
    },
    {
        "question": "What is 3E+08 divided by 1E+04?",
        "writeInAnswer": 30000,
        "furtherInfo": {
            "explanation": "What is 3E+08 divided by 1E+04?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+04."
        }
    },
    {
        "question": "What is 1E+07 divided by 3E+03?",
        "writeInAnswer": 3000,
        "furtherInfo": {
            "explanation": "What is 1E+07 divided by 3E+03?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+03."
        }
    },
    {
        "question": "What is 1E+05 divided by 1E+00?",
        "writeInAnswer": 100000,
        "furtherInfo": {
            "explanation": "What is 1E+05 divided by 1E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+05."
        }
    },
    {
        "question": "What is 1E+09 divided by 3E+06?",
        "writeInAnswer": 300,
        "furtherInfo": {
            "explanation": "What is 1E+09 divided by 3E+06?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+02."
        }
    },
    {
        "question": "What is 1E+12 divided by 3E+09?",
        "writeInAnswer": 300,
        "furtherInfo": {
            "explanation": "What is 1E+12 divided by 3E+09?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+02."
        }
    },
    {
        "question": "What is 1E+07 divided by 1E+04?",
        "writeInAnswer": 1000,
        "furtherInfo": {
            "explanation": "What is 1E+07 divided by 1E+04?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
        }
    },
    {
        "question": "What is 1E+03 divided by 3E+00?",
        "writeInAnswer": 300,
        "furtherInfo": {
            "explanation": "What is 1E+03 divided by 3E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+02."
        }
    },
    {
        "question": "What is 1E+01 divided by 3E+00?",
        "writeInAnswer": 3,
        "furtherInfo": {
            "explanation": "What is 1E+01 divided by 3E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
        }
    },
    {
        "question": "What is 1E+08 divided by 1E+00?",
        "writeInAnswer": 100000000,
        "furtherInfo": {
            "explanation": "What is 1E+08 divided by 1E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+08."
        }
    },
    {
        "question": "What is 1E+02 divided by 1E+01?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 1E+02 divided by 1E+01?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 3E+10 divided by 3E+09?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 3E+10 divided by 3E+09?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 1E+02 divided by 1E+00?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 1E+02 divided by 1E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 1E+13 divided by 1E+08?",
        "writeInAnswer": 100000,
        "furtherInfo": {
            "explanation": "What is 1E+13 divided by 1E+08?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+05."
        }
    },
    {
        "question": "What is 1E+13 divided by 1E+06?",
        "writeInAnswer": 10000000,
        "furtherInfo": {
            "explanation": "What is 1E+13 divided by 1E+06?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+07."
        }
    },
    {
        "question": "What is 3E+10 divided by 1E+06?",
        "writeInAnswer": 30000,
        "furtherInfo": {
            "explanation": "What is 3E+10 divided by 1E+06?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+04."
        }
    },
    {
        "question": "What is 3E+05 divided by 3E+02?",
        "writeInAnswer": 1000,
        "furtherInfo": {
            "explanation": "What is 3E+05 divided by 3E+02?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
        }
    },
    {
        "question": "What is 3E+02 divided by 1E+00?",
        "writeInAnswer": 300,
        "furtherInfo": {
            "explanation": "What is 3E+02 divided by 1E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+02."
        }
    },
    {
        "question": "What is 3E+07 divided by 3E+01?",
        "writeInAnswer": 1000000,
        "furtherInfo": {
            "explanation": "What is 3E+07 divided by 3E+01?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+06."
        }
    },
    {
        "question": "What is 3E+04 divided by 1E+03?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 3E+04 divided by 1E+03?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
        }
    },
    {
        "question": "What is 3E+10 divided by 3E+00?",
        "writeInAnswer": 10000000000,
        "furtherInfo": {
            "explanation": "What is 3E+10 divided by 3E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+10."
        }
    },
    {
        "question": "What is 3E+01 divided by 3E+00?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 3E+01 divided by 3E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 3E+04 divided by 3E+02?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 3E+04 divided by 3E+02?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 1E+08 divided by 1E+05?",
        "writeInAnswer": 1000,
        "furtherInfo": {
            "explanation": "What is 1E+08 divided by 1E+05?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
        }
    },
    {
        "question": "What is 1E+08 divided by 3E+03?",
        "writeInAnswer": 30000,
        "furtherInfo": {
            "explanation": "What is 1E+08 divided by 3E+03?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+04."
        }
    },
    {
        "question": "What is 3E+08 divided by 1E+02?",
        "writeInAnswer": 3000000,
        "furtherInfo": {
            "explanation": "What is 3E+08 divided by 1E+02?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+06."
        }
    },
    {
        "question": "What is 1E+13 divided by 1E+04?",
        "writeInAnswer": 1000000000,
        "furtherInfo": {
            "explanation": "What is 1E+13 divided by 1E+04?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+09."
        }
    },
    {
        "question": "What is 3E+02 divided by 3E+00?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 3E+02 divided by 3E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 3E+06 divided by 3E+03?",
        "writeInAnswer": 1000,
        "furtherInfo": {
            "explanation": "What is 3E+06 divided by 3E+03?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
        }
    },
    {
        "question": "What is 1E+03 divided by 3E+00?",
        "writeInAnswer": 300,
        "furtherInfo": {
            "explanation": "What is 1E+03 divided by 3E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+02."
        }
    },
    {
        "question": "What is 1E+07 divided by 3E+04?",
        "writeInAnswer": 300,
        "furtherInfo": {
            "explanation": "What is 1E+07 divided by 3E+04?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+02."
        }
    },
    {
        "question": "What is 1E+05 divided by 1E+04?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 1E+05 divided by 1E+04?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 1E+11 divided by 3E+05?",
        "writeInAnswer": 300000,
        "furtherInfo": {
            "explanation": "What is 1E+11 divided by 3E+05?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+05."
        }
    },
    {
        "question": "What is 1E+13 divided by 1E+04?",
        "writeInAnswer": 1000000000,
        "furtherInfo": {
            "explanation": "What is 1E+13 divided by 1E+04?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+09."
        }
    },
    {
        "question": "What is 3E+12 divided by 1E+11?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 3E+12 divided by 1E+11?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
        }
    },
    {
        "question": "What is 3E+01 divided by 3E+00?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 3E+01 divided by 3E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 1E+08 divided by 1E+00?",
        "writeInAnswer": 100000000,
        "furtherInfo": {
            "explanation": "What is 1E+08 divided by 1E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+08."
        }
    },
    {
        "question": "What is 1E+13 divided by 1E+04?",
        "writeInAnswer": 1000000000,
        "furtherInfo": {
            "explanation": "What is 1E+13 divided by 1E+04?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+09."
        }
    },
    {
        "question": "What is 1E+11 divided by 3E+09?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 1E+11 divided by 3E+09?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+01."
        }
    },
    {
        "question": "What is 3E+06 divided by 3E+03?",
        "writeInAnswer": 1000,
        "furtherInfo": {
            "explanation": "What is 3E+06 divided by 3E+03?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
        }
    },
    {
        "question": "What is 1E+05 divided by 3E+03?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 1E+05 divided by 3E+03?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+01."
        }
    },
    {
        "question": "What is 1E+04 divided by 1E+00?",
        "writeInAnswer": 10000,
        "furtherInfo": {
            "explanation": "What is 1E+04 divided by 1E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+04."
        }
    },
    {
        "question": "What is 3E+08 divided by 3E+07?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 3E+08 divided by 3E+07?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 1E+11 divided by 1E+06?",
        "writeInAnswer": 100000,
        "furtherInfo": {
            "explanation": "What is 1E+11 divided by 1E+06?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+05."
        }
    },
    {
        "question": "What is 3E+01 divided by 1E+00?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 3E+01 divided by 1E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
        }
    },
    {
        "question": "What is 1E+07 divided by 3E+06?",
        "writeInAnswer": 3,
        "furtherInfo": {
            "explanation": "What is 1E+07 divided by 3E+06?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
        }
    },
    {
        "question": "What is 3E+05 divided by 1E+01?",
        "writeInAnswer": 30000,
        "furtherInfo": {
            "explanation": "What is 3E+05 divided by 1E+01?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+04."
        }
    },
    {
        "question": "What is 1E+07 divided by 1E+04?",
        "writeInAnswer": 1000,
        "furtherInfo": {
            "explanation": "What is 1E+07 divided by 1E+04?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
        }
    },
    {
        "question": "What is 3E+10 divided by 1E+00?",
        "writeInAnswer": 30000000000,
        "furtherInfo": {
            "explanation": "What is 3E+10 divided by 1E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+10."
        }
    },
    {
        "question": "What is 3E+10 divided by 1E+06?",
        "writeInAnswer": 30000,
        "furtherInfo": {
            "explanation": "What is 3E+10 divided by 1E+06?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+04."
        }
    },
    {
        "question": "What is 1E+02 divided by 1E+01?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 1E+02 divided by 1E+01?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 3E+05 divided by 3E+00?",
        "writeInAnswer": 100000,
        "furtherInfo": {
            "explanation": "What is 3E+05 divided by 3E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+05."
        }
    },
    {
        "question": "What is 1E+05 divided by 1E+04?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 1E+05 divided by 1E+04?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 1E+06 divided by 1E+03?",
        "writeInAnswer": 1000,
        "furtherInfo": {
            "explanation": "What is 1E+06 divided by 1E+03?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
        }
    },
    {
        "question": "What is 1E+08 divided by 3E+06?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 1E+08 divided by 3E+06?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+01."
        }
    },
    {
        "question": "What is 1E+03 divided by 1E+01?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 1E+03 divided by 1E+01?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 3E+13 divided by 3E+08?",
        "writeInAnswer": 100000,
        "furtherInfo": {
            "explanation": "What is 3E+13 divided by 3E+08?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+05."
        }
    },
    {
        "question": "What is 3E+07 divided by 3E+03?",
        "writeInAnswer": 10000,
        "furtherInfo": {
            "explanation": "What is 3E+07 divided by 3E+03?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+04."
        }
    },
    {
        "question": "What is 1E+01 divided by 1E+00?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 1E+01 divided by 1E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 3E+13 divided by 3E+08?",
        "writeInAnswer": 100000,
        "furtherInfo": {
            "explanation": "What is 3E+13 divided by 3E+08?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+05."
        }
    },
    {
        "question": "What is 3E+03 divided by 3E+00?",
        "writeInAnswer": 1000,
        "furtherInfo": {
            "explanation": "What is 3E+03 divided by 3E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
        }
    },
    {
        "question": "What is 1E+02 divided by 1E+01?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 1E+02 divided by 1E+01?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 3E+08 divided by 1E+01?",
        "writeInAnswer": 30000000,
        "furtherInfo": {
            "explanation": "What is 3E+08 divided by 1E+01?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+07."
        }
    },
    {
        "question": "What is 3E+12 divided by 1E+00?",
        "writeInAnswer": 3000000000000,
        "furtherInfo": {
            "explanation": "What is 3E+12 divided by 1E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+12."
        }
    },
    {
        "question": "What is 1E+10 divided by 1E+04?",
        "writeInAnswer": 1000000,
        "furtherInfo": {
            "explanation": "What is 1E+10 divided by 1E+04?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+06."
        }
    },
    {
        "question": "What is 3E+12 divided by 3E+02?",
        "writeInAnswer": 10000000000,
        "furtherInfo": {
            "explanation": "What is 3E+12 divided by 3E+02?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+10."
        }
    },
    {
        "question": "What is 1E+09 divided by 3E+03?",
        "writeInAnswer": 300000,
        "furtherInfo": {
            "explanation": "What is 1E+09 divided by 3E+03?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+05."
        }
    },
    {
        "question": "What is 1E+11 divided by 3E+05?",
        "writeInAnswer": 300000,
        "furtherInfo": {
            "explanation": "What is 1E+11 divided by 3E+05?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+05."
        }
    },
    {
        "question": "What is 1E+04 divided by 3E+02?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 1E+04 divided by 3E+02?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+01."
        }
    },
    {
        "question": "What is 1E+11 divided by 1E+07?",
        "writeInAnswer": 10000,
        "furtherInfo": {
            "explanation": "What is 1E+11 divided by 1E+07?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+04."
        }
    },
    {
        "question": "What is 3E+08 divided by 1E+04?",
        "writeInAnswer": 30000,
        "furtherInfo": {
            "explanation": "What is 3E+08 divided by 1E+04?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+04."
        }
    },
    {
        "question": "What is 3E+09 divided by 3E+03?",
        "writeInAnswer": 1000000,
        "furtherInfo": {
            "explanation": "What is 3E+09 divided by 3E+03?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+06."
        }
    },
    {
        "question": "What is 1E+01 divided by 3E+00?",
        "writeInAnswer": 3,
        "furtherInfo": {
            "explanation": "What is 1E+01 divided by 3E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
        }
    },
    {
        "question": "What is 3E+06 divided by 3E+02?",
        "writeInAnswer": 10000,
        "furtherInfo": {
            "explanation": "What is 3E+06 divided by 3E+02?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+04."
        }
    },
    {
        "question": "What is 1E+02 divided by 3E+00?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 1E+02 divided by 3E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+01."
        }
    },
    {
        "question": "What is 1E+04 divided by 3E+03?",
        "writeInAnswer": 3,
        "furtherInfo": {
            "explanation": "What is 1E+04 divided by 3E+03?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
        }
    },
    {
        "question": "What is 1E+02 divided by 1E+01?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 1E+02 divided by 1E+01?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 3E+10 divided by 1E+04?",
        "writeInAnswer": 3000000,
        "furtherInfo": {
            "explanation": "What is 3E+10 divided by 1E+04?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+06."
        }
    },
    {
        "question": "What is 1E+06 divided by 3E+04?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 1E+06 divided by 3E+04?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+01."
        }
    },
    {
        "question": "What is 1E+09 divided by 1E+05?",
        "writeInAnswer": 10000,
        "furtherInfo": {
            "explanation": "What is 1E+09 divided by 1E+05?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+04."
        }
    },
    {
        "question": "What is 1E+07 divided by 3E+00?",
        "writeInAnswer": 3000000,
        "furtherInfo": {
            "explanation": "What is 1E+07 divided by 3E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+06."
        }
    },
    {
        "question": "What is 3E+03 divided by 3E+02?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 3E+03 divided by 3E+02?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 3E+07 divided by 1E+04?",
        "writeInAnswer": 3000,
        "furtherInfo": {
            "explanation": "What is 3E+07 divided by 1E+04?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+03."
        }
    },
    {
        "question": "What is 3E+06 divided by 1E+01?",
        "writeInAnswer": 300000,
        "furtherInfo": {
            "explanation": "What is 3E+06 divided by 1E+01?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+05."
        }
    },
    {
        "question": "What is 1E+10 divided by 3E+04?",
        "writeInAnswer": 300000,
        "furtherInfo": {
            "explanation": "What is 1E+10 divided by 3E+04?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+05."
        }
    },
    {
        "question": "What is 3E+03 divided by 3E+02?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 3E+03 divided by 3E+02?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 3E+08 divided by 3E+03?",
        "writeInAnswer": 100000,
        "furtherInfo": {
            "explanation": "What is 3E+08 divided by 3E+03?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+05."
        }
    },
    {
        "question": "What is 3E+09 divided by 3E+02?",
        "writeInAnswer": 10000000,
        "furtherInfo": {
            "explanation": "What is 3E+09 divided by 3E+02?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+07."
        }
    },
    {
        "question": "What is 3E+04 divided by 1E+03?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 3E+04 divided by 1E+03?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
        }
    },
    {
        "question": "What is 1E+03 divided by 1E+00?",
        "writeInAnswer": 1000,
        "furtherInfo": {
            "explanation": "What is 1E+03 divided by 1E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
        }
    },
    {
        "question": "What is 3E+09 divided by 1E+01?",
        "writeInAnswer": 300000000,
        "furtherInfo": {
            "explanation": "What is 3E+09 divided by 1E+01?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+08."
        }
    },
    {
        "question": "What is 3E+09 divided by 1E+05?",
        "writeInAnswer": 30000,
        "furtherInfo": {
            "explanation": "What is 3E+09 divided by 1E+05?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+04."
        }
    },
    {
        "question": "What is 1E+06 divided by 3E+00?",
        "writeInAnswer": 300000,
        "furtherInfo": {
            "explanation": "What is 1E+06 divided by 3E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+05."
        }
    },
    {
        "question": "What is 3E+04 divided by 1E+01?",
        "writeInAnswer": 3000,
        "furtherInfo": {
            "explanation": "What is 3E+04 divided by 1E+01?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+03."
        }
    },
    {
        "question": "What is 1E+09 divided by 1E+05?",
        "writeInAnswer": 10000,
        "furtherInfo": {
            "explanation": "What is 1E+09 divided by 1E+05?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+04."
        }
    },
    {
        "question": "What is 1E+05 divided by 3E+02?",
        "writeInAnswer": 300,
        "furtherInfo": {
            "explanation": "What is 1E+05 divided by 3E+02?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+02."
        }
    },
    {
        "question": "What is 3E+03 divided by 1E+02?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 3E+03 divided by 1E+02?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
        }
    },
    {
        "question": "What is 1E+09 divided by 3E+01?",
        "writeInAnswer": 30000000,
        "furtherInfo": {
            "explanation": "What is 1E+09 divided by 3E+01?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+07."
        }
    },
    {
        "question": "What is 1E+06 divided by 3E+01?",
        "writeInAnswer": 30000,
        "furtherInfo": {
            "explanation": "What is 1E+06 divided by 3E+01?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+04."
        }
    },
    {
        "question": "What is 1E+09 divided by 3E+05?",
        "writeInAnswer": 3000,
        "furtherInfo": {
            "explanation": "What is 1E+09 divided by 3E+05?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+03."
        }
    },
    {
        "question": "What is 1E+09 divided by 1E+00?",
        "writeInAnswer": 1000000000,
        "furtherInfo": {
            "explanation": "What is 1E+09 divided by 1E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+09."
        }
    },
    {
        "question": "What is 1E+12 divided by 3E+11?",
        "writeInAnswer": 3,
        "furtherInfo": {
            "explanation": "What is 1E+12 divided by 3E+11?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
        }
    },
    {
        "question": "What is 1E+10 divided by 1E+09?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 1E+10 divided by 1E+09?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 1E+12 divided by 1E+08?",
        "writeInAnswer": 10000,
        "furtherInfo": {
            "explanation": "What is 1E+12 divided by 1E+08?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+04."
        }
    },
    {
        "question": "What is 3E+11 divided by 1E+00?",
        "writeInAnswer": 300000000000,
        "furtherInfo": {
            "explanation": "What is 3E+11 divided by 1E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+11."
        }
    },
    {
        "question": "What is 1E+08 divided by 3E+04?",
        "writeInAnswer": 3000,
        "furtherInfo": {
            "explanation": "What is 1E+08 divided by 3E+04?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+03."
        }
    },
    {
        "question": "What is 3E+04 divided by 3E+01?",
        "writeInAnswer": 1000,
        "furtherInfo": {
            "explanation": "What is 3E+04 divided by 3E+01?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
        }
    },
    {
        "question": "What is 3E+09 divided by 1E+08?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 3E+09 divided by 1E+08?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
        }
    },
    {
        "question": "What is 3E+07 divided by 1E+00?",
        "writeInAnswer": 30000000,
        "furtherInfo": {
            "explanation": "What is 3E+07 divided by 1E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+07."
        }
    },
    {
        "question": "What is 1E+11 divided by 3E+02?",
        "writeInAnswer": 300000000,
        "furtherInfo": {
            "explanation": "What is 1E+11 divided by 3E+02?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+08."
        }
    },
    {
        "question": "What is 3E+02 divided by 1E+00?",
        "writeInAnswer": 300,
        "furtherInfo": {
            "explanation": "What is 3E+02 divided by 1E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+02."
        }
    },
    {
        "question": "What is 1E+02 divided by 1E+00?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 1E+02 divided by 1E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 1E+01 divided by 3E+00?",
        "writeInAnswer": 3,
        "furtherInfo": {
            "explanation": "What is 1E+01 divided by 3E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
        }
    },
    {
        "question": "What is 3E+06 divided by 1E+00?",
        "writeInAnswer": 3000000,
        "furtherInfo": {
            "explanation": "What is 3E+06 divided by 1E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+06."
        }
    },
    {
        "question": "What is 3E+08 divided by 1E+04?",
        "writeInAnswer": 30000,
        "furtherInfo": {
            "explanation": "What is 3E+08 divided by 1E+04?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+04."
        }
    },
    {
        "question": "What is 1E+11 divided by 3E+09?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 1E+11 divided by 3E+09?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+01."
        }
    },
    {
        "question": "What is 1E+01 divided by 3E+00?",
        "writeInAnswer": 3,
        "furtherInfo": {
            "explanation": "What is 1E+01 divided by 3E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
        }
    },
    {
        "question": "What is 3E+03 divided by 3E+00?",
        "writeInAnswer": 1000,
        "furtherInfo": {
            "explanation": "What is 3E+03 divided by 3E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
        }
    },
    {
        "question": "What is 3E+10 divided by 1E+06?",
        "writeInAnswer": 30000,
        "furtherInfo": {
            "explanation": "What is 3E+10 divided by 1E+06?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+04."
        }
    },
    {
        "question": "What is 3E+08 divided by 3E+06?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 3E+08 divided by 3E+06?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 3E+03 divided by 3E+00?",
        "writeInAnswer": 1000,
        "furtherInfo": {
            "explanation": "What is 3E+03 divided by 3E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
        }
    },
    {
        "question": "What is 1E+02 divided by 1E+00?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 1E+02 divided by 1E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 1E+13 divided by 3E+10?",
        "writeInAnswer": 300,
        "furtherInfo": {
            "explanation": "What is 1E+13 divided by 3E+10?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+02."
        }
    },
    {
        "question": "What is 3E+08 divided by 3E+03?",
        "writeInAnswer": 100000,
        "furtherInfo": {
            "explanation": "What is 3E+08 divided by 3E+03?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+05."
        }
    },
    {
        "question": "What is 3E+11 divided by 3E+04?",
        "writeInAnswer": 10000000,
        "furtherInfo": {
            "explanation": "What is 3E+11 divided by 3E+04?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+07."
        }
    },
    {
        "question": "What is 3E+08 divided by 3E+00?",
        "writeInAnswer": 100000000,
        "furtherInfo": {
            "explanation": "What is 3E+08 divided by 3E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+08."
        }
    },
    {
        "question": "What is 1E+04 divided by 3E+02?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 1E+04 divided by 3E+02?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+01."
        }
    },
    {
        "question": "What is 1E+07 divided by 1E+04?",
        "writeInAnswer": 1000,
        "furtherInfo": {
            "explanation": "What is 1E+07 divided by 1E+04?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
        }
    },
    {
        "question": "What is 3E+02 divided by 3E+01?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 3E+02 divided by 3E+01?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 3E+01 divided by 1E+00?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 3E+01 divided by 1E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
        }
    },
    {
        "question": "What is 1E+10 divided by 3E+01?",
        "writeInAnswer": 300000000,
        "furtherInfo": {
            "explanation": "What is 1E+10 divided by 3E+01?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+08."
        }
    },
    {
        "question": "What is 3E+12 divided by 3E+06?",
        "writeInAnswer": 1000000,
        "furtherInfo": {
            "explanation": "What is 3E+12 divided by 3E+06?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+06."
        }
    },
    {
        "question": "What is 1E+12 divided by 3E+10?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 1E+12 divided by 3E+10?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+01."
        }
    },
    {
        "question": "What is 3E+05 divided by 3E+00?",
        "writeInAnswer": 100000,
        "furtherInfo": {
            "explanation": "What is 3E+05 divided by 3E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+05."
        }
    },
    {
        "question": "What is 3E+08 divided by 1E+01?",
        "writeInAnswer": 30000000,
        "furtherInfo": {
            "explanation": "What is 3E+08 divided by 1E+01?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+07."
        }
    },
    {
        "question": "What is 1E+07 divided by 3E+02?",
        "writeInAnswer": 30000,
        "furtherInfo": {
            "explanation": "What is 1E+07 divided by 3E+02?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+04."
        }
    },
    {
        "question": "What is 1E+10 divided by 3E+06?",
        "writeInAnswer": 3000,
        "furtherInfo": {
            "explanation": "What is 1E+10 divided by 3E+06?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+03."
        }
    },
    {
        "question": "What is 1E+10 divided by 3E+00?",
        "writeInAnswer": 3000000000,
        "furtherInfo": {
            "explanation": "What is 1E+10 divided by 3E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+09."
        }
    },
    {
        "question": "What is 1E+09 divided by 3E+06?",
        "writeInAnswer": 300,
        "furtherInfo": {
            "explanation": "What is 1E+09 divided by 3E+06?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+02."
        }
    },
    {
        "question": "What is 1E+11 divided by 1E+03?",
        "writeInAnswer": 100000000,
        "furtherInfo": {
            "explanation": "What is 1E+11 divided by 1E+03?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+08."
        }
    },
    {
        "question": "What is 3E+12 divided by 3E+09?",
        "writeInAnswer": 1000,
        "furtherInfo": {
            "explanation": "What is 3E+12 divided by 3E+09?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
        }
    },
    {
        "question": "What is 3E+01 divided by 3E+00?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 3E+01 divided by 3E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 1E+08 divided by 3E+07?",
        "writeInAnswer": 3,
        "furtherInfo": {
            "explanation": "What is 1E+08 divided by 3E+07?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
        }
    },
    {
        "question": "What is 1E+13 divided by 3E+09?",
        "writeInAnswer": 3000,
        "furtherInfo": {
            "explanation": "What is 1E+13 divided by 3E+09?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+03."
        }
    },
    {
        "question": "What is 1E+10 divided by 1E+03?",
        "writeInAnswer": 10000000,
        "furtherInfo": {
            "explanation": "What is 1E+10 divided by 1E+03?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+07."
        }
    },
    {
        "question": "What is 1E+10 divided by 1E+03?",
        "writeInAnswer": 10000000,
        "furtherInfo": {
            "explanation": "What is 1E+10 divided by 1E+03?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+07."
        }
    },
    {
        "question": "What is 1E+03 divided by 3E+01?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 1E+03 divided by 3E+01?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+01."
        }
    },
    {
        "question": "What is 1E+02 divided by 3E+01?",
        "writeInAnswer": 3,
        "furtherInfo": {
            "explanation": "What is 1E+02 divided by 3E+01?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
        }
    },
    {
        "question": "What is 3E+11 divided by 1E+10?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 3E+11 divided by 1E+10?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
        }
    },
    {
        "question": "What is 3E+06 divided by 3E+02?",
        "writeInAnswer": 10000,
        "furtherInfo": {
            "explanation": "What is 3E+06 divided by 3E+02?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+04."
        }
    },
    {
        "question": "What is 3E+07 divided by 3E+04?",
        "writeInAnswer": 1000,
        "furtherInfo": {
            "explanation": "What is 3E+07 divided by 3E+04?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
        }
    },
    {
        "question": "What is 1E+02 divided by 3E+01?",
        "writeInAnswer": 3,
        "furtherInfo": {
            "explanation": "What is 1E+02 divided by 3E+01?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
        }
    },
    {
        "question": "What is 1E+10 divided by 3E+08?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 1E+10 divided by 3E+08?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+01."
        }
    },
    {
        "question": "What is 3E+03 divided by 3E+02?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 3E+03 divided by 3E+02?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 1E+09 divided by 3E+03?",
        "writeInAnswer": 300000,
        "furtherInfo": {
            "explanation": "What is 1E+09 divided by 3E+03?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+05."
        }
    },
    {
        "question": "What is 1E+11 divided by 3E+06?",
        "writeInAnswer": 30000,
        "furtherInfo": {
            "explanation": "What is 1E+11 divided by 3E+06?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+04."
        }
    },
    {
        "question": "What is 3E+05 divided by 1E+00?",
        "writeInAnswer": 300000,
        "furtherInfo": {
            "explanation": "What is 3E+05 divided by 1E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+05."
        }
    },
    {
        "question": "What is 1E+12 divided by 3E+08?",
        "writeInAnswer": 3000,
        "furtherInfo": {
            "explanation": "What is 1E+12 divided by 3E+08?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+03."
        }
    },
    {
        "question": "What is 3E+13 divided by 1E+01?",
        "writeInAnswer": 3000000000000,
        "furtherInfo": {
            "explanation": "What is 3E+13 divided by 1E+01?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+12."
        }
    },
    {
        "question": "What is 3E+06 divided by 1E+00?",
        "writeInAnswer": 3000000,
        "furtherInfo": {
            "explanation": "What is 3E+06 divided by 1E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+06."
        }
    },
    {
        "question": "What is 1E+09 divided by 3E+06?",
        "writeInAnswer": 300,
        "furtherInfo": {
            "explanation": "What is 1E+09 divided by 3E+06?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+02."
        }
    },
    {
        "question": "What is 3E+13 divided by 3E+09?",
        "writeInAnswer": 10000,
        "furtherInfo": {
            "explanation": "What is 3E+13 divided by 3E+09?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+04."
        }
    },
    {
        "question": "What is 1E+12 divided by 3E+02?",
        "writeInAnswer": 3000000000,
        "furtherInfo": {
            "explanation": "What is 1E+12 divided by 3E+02?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+09."
        }
    },
    {
        "question": "What is 1E+01 divided by 3E+00?",
        "writeInAnswer": 3,
        "furtherInfo": {
            "explanation": "What is 1E+01 divided by 3E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
        }
    },
    {
        "question": "What is 1E+01 divided by 1E+00?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 1E+01 divided by 1E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 1E+03 divided by 3E+00?",
        "writeInAnswer": 300,
        "furtherInfo": {
            "explanation": "What is 1E+03 divided by 3E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+02."
        }
    },
    {
        "question": "What is 1E+01 divided by 1E+00?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 1E+01 divided by 1E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 3E+13 divided by 1E+08?",
        "writeInAnswer": 300000,
        "furtherInfo": {
            "explanation": "What is 3E+13 divided by 1E+08?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+05."
        }
    },
    {
        "question": "What is 1E+13 divided by 3E+06?",
        "writeInAnswer": 3000000,
        "furtherInfo": {
            "explanation": "What is 1E+13 divided by 3E+06?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+06."
        }
    },
    {
        "question": "What is 3E+03 divided by 1E+00?",
        "writeInAnswer": 3000,
        "furtherInfo": {
            "explanation": "What is 3E+03 divided by 1E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+03."
        }
    },
    {
        "question": "What is 1E+07 divided by 3E+04?",
        "writeInAnswer": 300,
        "furtherInfo": {
            "explanation": "What is 1E+07 divided by 3E+04?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+02."
        }
    },
    {
        "question": "What is 3E+02 divided by 3E+00?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 3E+02 divided by 3E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 3E+07 divided by 3E+06?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 3E+07 divided by 3E+06?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 1E+13 divided by 1E+12?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 1E+13 divided by 1E+12?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 1E+05 divided by 1E+04?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 1E+05 divided by 1E+04?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 1E+03 divided by 3E+00?",
        "writeInAnswer": 300,
        "furtherInfo": {
            "explanation": "What is 1E+03 divided by 3E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+02."
        }
    },
    {
        "question": "What is 3E+01 divided by 1E+00?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 3E+01 divided by 1E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
        }
    },
    {
        "question": "What is 3E+03 divided by 1E+02?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 3E+03 divided by 1E+02?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
        }
    },
    {
        "question": "What is 3E+07 divided by 3E+06?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 3E+07 divided by 3E+06?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 1E+12 divided by 1E+07?",
        "writeInAnswer": 100000,
        "furtherInfo": {
            "explanation": "What is 1E+12 divided by 1E+07?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+05."
        }
    },
    {
        "question": "What is 3E+10 divided by 1E+08?",
        "writeInAnswer": 300,
        "furtherInfo": {
            "explanation": "What is 3E+10 divided by 1E+08?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+02."
        }
    },
    {
        "question": "What is 3E+02 divided by 3E+00?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 3E+02 divided by 3E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 1E+03 divided by 1E+00?",
        "writeInAnswer": 1000,
        "furtherInfo": {
            "explanation": "What is 1E+03 divided by 1E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
        }
    },
    {
        "question": "What is 3E+13 divided by 1E+12?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 3E+13 divided by 1E+12?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
        }
    },
    {
        "question": "What is 3E+04 divided by 1E+03?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 3E+04 divided by 1E+03?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
        }
    },
    {
        "question": "What is 1E+08 divided by 3E+07?",
        "writeInAnswer": 3,
        "furtherInfo": {
            "explanation": "What is 1E+08 divided by 3E+07?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
        }
    },
    {
        "question": "What is 1E+05 divided by 1E+02?",
        "writeInAnswer": 1000,
        "furtherInfo": {
            "explanation": "What is 1E+05 divided by 1E+02?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
        }
    },
    {
        "question": "What is 1E+04 divided by 1E+00?",
        "writeInAnswer": 10000,
        "furtherInfo": {
            "explanation": "What is 1E+04 divided by 1E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+04."
        }
    },
    {
        "question": "What is 1E+10 divided by 1E+00?",
        "writeInAnswer": 10000000000,
        "furtherInfo": {
            "explanation": "What is 1E+10 divided by 1E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+10."
        }
    },
    {
        "question": "What is 1E+01 divided by 1E+00?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 1E+01 divided by 1E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 3E+09 divided by 3E+02?",
        "writeInAnswer": 10000000,
        "furtherInfo": {
            "explanation": "What is 3E+09 divided by 3E+02?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+07."
        }
    },
    {
        "question": "What is 1E+04 divided by 3E+01?",
        "writeInAnswer": 300,
        "furtherInfo": {
            "explanation": "What is 1E+04 divided by 3E+01?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+02."
        }
    },
    {
        "question": "What is 3E+08 divided by 1E+02?",
        "writeInAnswer": 3000000,
        "furtherInfo": {
            "explanation": "What is 3E+08 divided by 1E+02?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+06."
        }
    },
    {
        "question": "What is 1E+09 divided by 1E+04?",
        "writeInAnswer": 100000,
        "furtherInfo": {
            "explanation": "What is 1E+09 divided by 1E+04?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+05."
        }
    },
    {
        "question": "What is 1E+10 divided by 1E+02?",
        "writeInAnswer": 100000000,
        "furtherInfo": {
            "explanation": "What is 1E+10 divided by 1E+02?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+08."
        }
    },
    {
        "question": "What is 1E+13 divided by 3E+08?",
        "writeInAnswer": 30000,
        "furtherInfo": {
            "explanation": "What is 1E+13 divided by 3E+08?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+04."
        }
    },
    {
        "question": "What is 3E+05 divided by 3E+04?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 3E+05 divided by 3E+04?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 1E+13 divided by 1E+03?",
        "writeInAnswer": 10000000000,
        "furtherInfo": {
            "explanation": "What is 1E+13 divided by 1E+03?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+10."
        }
    },
    {
        "question": "What is 3E+02 divided by 1E+01?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 3E+02 divided by 1E+01?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
        }
    },
    {
        "question": "What is 1E+01 divided by 3E+00?",
        "writeInAnswer": 3,
        "furtherInfo": {
            "explanation": "What is 1E+01 divided by 3E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
        }
    },
    {
        "question": "What is 1E+01 divided by 3E+00?",
        "writeInAnswer": 3,
        "furtherInfo": {
            "explanation": "What is 1E+01 divided by 3E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
        }
    },
    {
        "question": "What is 3E+04 divided by 1E+03?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 3E+04 divided by 1E+03?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
        }
    },
    {
        "question": "What is 1E+09 divided by 1E+07?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 1E+09 divided by 1E+07?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 1E+04 divided by 3E+01?",
        "writeInAnswer": 300,
        "furtherInfo": {
            "explanation": "What is 1E+04 divided by 3E+01?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+02."
        }
    },
    {
        "question": "What is 3E+12 divided by 3E+02?",
        "writeInAnswer": 10000000000,
        "furtherInfo": {
            "explanation": "What is 3E+12 divided by 3E+02?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+10."
        }
    },
    {
        "question": "What is 3E+05 divided by 3E+03?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 3E+05 divided by 3E+03?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 1E+02 divided by 1E+00?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 1E+02 divided by 1E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 3E+06 divided by 3E+04?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 3E+06 divided by 3E+04?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 1E+11 divided by 3E+00?",
        "writeInAnswer": 30000000000,
        "furtherInfo": {
            "explanation": "What is 1E+11 divided by 3E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+10."
        }
    },
    {
        "question": "What is 3E+09 divided by 1E+05?",
        "writeInAnswer": 30000,
        "furtherInfo": {
            "explanation": "What is 3E+09 divided by 1E+05?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+04."
        }
    },
    {
        "question": "What is 1E+03 divided by 3E+02?",
        "writeInAnswer": 3,
        "furtherInfo": {
            "explanation": "What is 1E+03 divided by 3E+02?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
        }
    },
    {
        "question": "What is 1E+13 divided by 3E+03?",
        "writeInAnswer": 3000000000,
        "furtherInfo": {
            "explanation": "What is 1E+13 divided by 3E+03?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+09."
        }
    },
    {
        "question": "What is 1E+02 divided by 1E+00?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 1E+02 divided by 1E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 1E+12 divided by 1E+08?",
        "writeInAnswer": 10000,
        "furtherInfo": {
            "explanation": "What is 1E+12 divided by 1E+08?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+04."
        }
    },
    {
        "question": "What is 3E+04 divided by 1E+02?",
        "writeInAnswer": 300,
        "furtherInfo": {
            "explanation": "What is 3E+04 divided by 1E+02?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+02."
        }
    },
    {
        "question": "What is 3E+12 divided by 1E+03?",
        "writeInAnswer": 3000000000,
        "furtherInfo": {
            "explanation": "What is 3E+12 divided by 1E+03?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+09."
        }
    },
    {
        "question": "What is 1E+06 divided by 1E+05?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 1E+06 divided by 1E+05?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 1E+12 divided by 3E+06?",
        "writeInAnswer": 300000,
        "furtherInfo": {
            "explanation": "What is 1E+12 divided by 3E+06?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+05."
        }
    },
    {
        "question": "What is 3E+06 divided by 1E+03?",
        "writeInAnswer": 3000,
        "furtherInfo": {
            "explanation": "What is 3E+06 divided by 1E+03?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+03."
        }
    },
    {
        "question": "What is 3E+13 divided by 3E+09?",
        "writeInAnswer": 10000,
        "furtherInfo": {
            "explanation": "What is 3E+13 divided by 3E+09?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+04."
        }
    },
    {
        "question": "What is 3E+04 divided by 3E+02?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 3E+04 divided by 3E+02?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 3E+08 divided by 3E+02?",
        "writeInAnswer": 1000000,
        "furtherInfo": {
            "explanation": "What is 3E+08 divided by 3E+02?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+06."
        }
    },
    {
        "question": "What is 3E+09 divided by 1E+08?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 3E+09 divided by 1E+08?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
        }
    },
    {
        "question": "What is 1E+07 divided by 1E+00?",
        "writeInAnswer": 10000000,
        "furtherInfo": {
            "explanation": "What is 1E+07 divided by 1E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+07."
        }
    },
    {
        "question": "What is 1E+13 divided by 3E+09?",
        "writeInAnswer": 3000,
        "furtherInfo": {
            "explanation": "What is 1E+13 divided by 3E+09?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+03."
        }
    },
    {
        "question": "What is 3E+08 divided by 3E+05?",
        "writeInAnswer": 1000,
        "furtherInfo": {
            "explanation": "What is 3E+08 divided by 3E+05?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
        }
    },
    {
        "question": "What is 1E+10 divided by 3E+04?",
        "writeInAnswer": 300000,
        "furtherInfo": {
            "explanation": "What is 1E+10 divided by 3E+04?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+05."
        }
    },
    {
        "question": "What is 3E+06 divided by 3E+01?",
        "writeInAnswer": 100000,
        "furtherInfo": {
            "explanation": "What is 3E+06 divided by 3E+01?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+05."
        }
    },
    {
        "question": "What is 1E+06 divided by 1E+04?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 1E+06 divided by 1E+04?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 3E+03 divided by 1E+00?",
        "writeInAnswer": 3000,
        "furtherInfo": {
            "explanation": "What is 3E+03 divided by 1E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+03."
        }
    },
    {
        "question": "What is 3E+10 divided by 3E+08?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 3E+10 divided by 3E+08?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 3E+10 divided by 1E+09?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 3E+10 divided by 1E+09?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
        }
    },
    {
        "question": "What is 3E+05 divided by 3E+01?",
        "writeInAnswer": 10000,
        "furtherInfo": {
            "explanation": "What is 3E+05 divided by 3E+01?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+04."
        }
    },
    {
        "question": "What is 3E+11 divided by 3E+04?",
        "writeInAnswer": 10000000,
        "furtherInfo": {
            "explanation": "What is 3E+11 divided by 3E+04?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+07."
        }
    },
    {
        "question": "What is 3E+06 divided by 1E+02?",
        "writeInAnswer": 30000,
        "furtherInfo": {
            "explanation": "What is 3E+06 divided by 1E+02?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+04."
        }
    },
    {
        "question": "What is 3E+01 divided by 3E+00?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 3E+01 divided by 3E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 1E+10 divided by 1E+08?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 1E+10 divided by 1E+08?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 1E+01 divided by 3E+00?",
        "writeInAnswer": 3,
        "furtherInfo": {
            "explanation": "What is 1E+01 divided by 3E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
        }
    },
    {
        "question": "What is 1E+13 divided by 3E+00?",
        "writeInAnswer": 3000000000000,
        "furtherInfo": {
            "explanation": "What is 1E+13 divided by 3E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+12."
        }
    },
    {
        "question": "What is 3E+13 divided by 3E+12?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 3E+13 divided by 3E+12?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 1E+04 divided by 3E+00?",
        "writeInAnswer": 3000,
        "furtherInfo": {
            "explanation": "What is 1E+04 divided by 3E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+03."
        }
    },
    {
        "question": "What is 1E+05 divided by 3E+04?",
        "writeInAnswer": 3,
        "furtherInfo": {
            "explanation": "What is 1E+05 divided by 3E+04?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
        }
    },
    {
        "question": "What is 1E+13 divided by 3E+05?",
        "writeInAnswer": 30000000,
        "furtherInfo": {
            "explanation": "What is 1E+13 divided by 3E+05?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+07."
        }
    },
    {
        "question": "What is 3E+11 divided by 3E+05?",
        "writeInAnswer": 1000000,
        "furtherInfo": {
            "explanation": "What is 3E+11 divided by 3E+05?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+06."
        }
    },
    {
        "question": "What is 1E+12 divided by 1E+06?",
        "writeInAnswer": 1000000,
        "furtherInfo": {
            "explanation": "What is 1E+12 divided by 1E+06?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+06."
        }
    },
    {
        "question": "What is 3E+06 divided by 3E+00?",
        "writeInAnswer": 1000000,
        "furtherInfo": {
            "explanation": "What is 3E+06 divided by 3E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+06."
        }
    },
    {
        "question": "What is 3E+01 divided by 3E+00?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 3E+01 divided by 3E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 3E+03 divided by 3E+00?",
        "writeInAnswer": 1000,
        "furtherInfo": {
            "explanation": "What is 3E+03 divided by 3E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
        }
    },
    {
        "question": "What is 3E+07 divided by 3E+00?",
        "writeInAnswer": 10000000,
        "furtherInfo": {
            "explanation": "What is 3E+07 divided by 3E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+07."
        }
    },
    {
        "question": "What is 1E+05 divided by 1E+03?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 1E+05 divided by 1E+03?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 1E+06 divided by 1E+02?",
        "writeInAnswer": 10000,
        "furtherInfo": {
            "explanation": "What is 1E+06 divided by 1E+02?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+04."
        }
    },
    {
        "question": "What is 1E+11 divided by 3E+08?",
        "writeInAnswer": 300,
        "furtherInfo": {
            "explanation": "What is 1E+11 divided by 3E+08?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+02."
        }
    },
    {
        "question": "What is 1E+07 divided by 3E+04?",
        "writeInAnswer": 300,
        "furtherInfo": {
            "explanation": "What is 1E+07 divided by 3E+04?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+02."
        }
    },
    {
        "question": "What is 1E+01 divided by 3E+00?",
        "writeInAnswer": 3,
        "furtherInfo": {
            "explanation": "What is 1E+01 divided by 3E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
        }
    },
    {
        "question": "What is 1E+05 divided by 3E+04?",
        "writeInAnswer": 3,
        "furtherInfo": {
            "explanation": "What is 1E+05 divided by 3E+04?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
        }
    },
    {
        "question": "What is 1E+08 divided by 1E+00?",
        "writeInAnswer": 100000000,
        "furtherInfo": {
            "explanation": "What is 1E+08 divided by 1E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+08."
        }
    },
    {
        "question": "What is 3E+01 divided by 3E+00?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 3E+01 divided by 3E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 3E+12 divided by 3E+01?",
        "writeInAnswer": 100000000000,
        "furtherInfo": {
            "explanation": "What is 3E+12 divided by 3E+01?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+11."
        }
    },
    {
        "question": "What is 1E+13 divided by 3E+06?",
        "writeInAnswer": 3000000,
        "furtherInfo": {
            "explanation": "What is 1E+13 divided by 3E+06?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+06."
        }
    },
    {
        "question": "What is 3E+10 divided by 1E+06?",
        "writeInAnswer": 30000,
        "furtherInfo": {
            "explanation": "What is 3E+10 divided by 1E+06?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+04."
        }
    },
    {
        "question": "What is 1E+11 divided by 1E+02?",
        "writeInAnswer": 1000000000,
        "furtherInfo": {
            "explanation": "What is 1E+11 divided by 1E+02?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+09."
        }
    },
    {
        "question": "What is 3E+04 divided by 3E+02?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 3E+04 divided by 3E+02?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 1E+04 divided by 3E+02?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 1E+04 divided by 3E+02?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+01."
        }
    },
    {
        "question": "What is 1E+03 divided by 3E+01?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 1E+03 divided by 3E+01?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+01."
        }
    },
    {
        "question": "What is 1E+02 divided by 3E+01?",
        "writeInAnswer": 3,
        "furtherInfo": {
            "explanation": "What is 1E+02 divided by 3E+01?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
        }
    },
    {
        "question": "What is 3E+04 divided by 3E+02?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 3E+04 divided by 3E+02?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 1E+13 divided by 1E+00?",
        "writeInAnswer": 10000000000000,
        "furtherInfo": {
            "explanation": "What is 1E+13 divided by 1E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+13."
        }
    },
    {
        "question": "What is 3E+10 divided by 1E+00?",
        "writeInAnswer": 30000000000,
        "furtherInfo": {
            "explanation": "What is 3E+10 divided by 1E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+10."
        }
    },
    {
        "question": "What is 3E+08 divided by 3E+04?",
        "writeInAnswer": 10000,
        "furtherInfo": {
            "explanation": "What is 3E+08 divided by 3E+04?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+04."
        }
    },
    {
        "question": "What is 3E+10 divided by 3E+04?",
        "writeInAnswer": 1000000,
        "furtherInfo": {
            "explanation": "What is 3E+10 divided by 3E+04?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+06."
        }
    },
    {
        "question": "What is 3E+13 divided by 3E+05?",
        "writeInAnswer": 100000000,
        "furtherInfo": {
            "explanation": "What is 3E+13 divided by 3E+05?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+08."
        }
    },
    {
        "question": "What is 1E+05 divided by 3E+02?",
        "writeInAnswer": 300,
        "furtherInfo": {
            "explanation": "What is 1E+05 divided by 3E+02?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+02."
        }
    },
    {
        "question": "What is 3E+01 divided by 1E+00?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 3E+01 divided by 1E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
        }
    },
    {
        "question": "What is 3E+06 divided by 1E+01?",
        "writeInAnswer": 300000,
        "furtherInfo": {
            "explanation": "What is 3E+06 divided by 1E+01?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+05."
        }
    },
    {
        "question": "What is 3E+11 divided by 1E+00?",
        "writeInAnswer": 300000000000,
        "furtherInfo": {
            "explanation": "What is 3E+11 divided by 1E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+11."
        }
    },
    {
        "question": "What is 3E+13 divided by 3E+03?",
        "writeInAnswer": 10000000000,
        "furtherInfo": {
            "explanation": "What is 3E+13 divided by 3E+03?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+10."
        }
    },
    {
        "question": "What is 1E+02 divided by 1E+00?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 1E+02 divided by 1E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 1E+10 divided by 3E+01?",
        "writeInAnswer": 300000000,
        "furtherInfo": {
            "explanation": "What is 1E+10 divided by 3E+01?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+08."
        }
    },
    {
        "question": "What is 1E+11 divided by 1E+02?",
        "writeInAnswer": 1000000000,
        "furtherInfo": {
            "explanation": "What is 1E+11 divided by 1E+02?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+09."
        }
    },
    {
        "question": "What is 1E+03 divided by 1E+01?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 1E+03 divided by 1E+01?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 1E+01 divided by 3E+00?",
        "writeInAnswer": 3,
        "furtherInfo": {
            "explanation": "What is 1E+01 divided by 3E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
        }
    },
    {
        "question": "What is 1E+08 divided by 3E+00?",
        "writeInAnswer": 30000000,
        "furtherInfo": {
            "explanation": "What is 1E+08 divided by 3E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+07."
        }
    },
    {
        "question": "What is 3E+07 divided by 3E+02?",
        "writeInAnswer": 100000,
        "furtherInfo": {
            "explanation": "What is 3E+07 divided by 3E+02?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+05."
        }
    },
    {
        "question": "What is 3E+09 divided by 1E+00?",
        "writeInAnswer": 3000000000,
        "furtherInfo": {
            "explanation": "What is 3E+09 divided by 1E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+09."
        }
    },
    {
        "question": "What is 3E+04 divided by 3E+03?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 3E+04 divided by 3E+03?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 1E+09 divided by 1E+03?",
        "writeInAnswer": 1000000,
        "furtherInfo": {
            "explanation": "What is 1E+09 divided by 1E+03?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+06."
        }
    },
    {
        "question": "What is 3E+03 divided by 3E+00?",
        "writeInAnswer": 1000,
        "furtherInfo": {
            "explanation": "What is 3E+03 divided by 3E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
        }
    },
    {
        "question": "What is 3E+05 divided by 3E+01?",
        "writeInAnswer": 10000,
        "furtherInfo": {
            "explanation": "What is 3E+05 divided by 3E+01?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+04."
        }
    },
    {
        "question": "What is 1E+07 divided by 1E+03?",
        "writeInAnswer": 10000,
        "furtherInfo": {
            "explanation": "What is 1E+07 divided by 1E+03?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+04."
        }
    },
    {
        "question": "What is 1E+10 divided by 3E+03?",
        "writeInAnswer": 3000000,
        "furtherInfo": {
            "explanation": "What is 1E+10 divided by 3E+03?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+06."
        }
    },
    {
        "question": "What is 3E+01 divided by 3E+00?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 3E+01 divided by 3E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 1E+13 divided by 1E+11?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 1E+13 divided by 1E+11?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 3E+02 divided by 3E+00?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 3E+02 divided by 3E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 1E+07 divided by 3E+00?",
        "writeInAnswer": 3000000,
        "furtherInfo": {
            "explanation": "What is 1E+07 divided by 3E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+06."
        }
    },
    {
        "question": "What is 3E+13 divided by 3E+03?",
        "writeInAnswer": 10000000000,
        "furtherInfo": {
            "explanation": "What is 3E+13 divided by 3E+03?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+10."
        }
    },
    {
        "question": "What is 1E+09 divided by 1E+02?",
        "writeInAnswer": 10000000,
        "furtherInfo": {
            "explanation": "What is 1E+09 divided by 1E+02?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+07."
        }
    },
    {
        "question": "What is 3E+02 divided by 3E+01?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 3E+02 divided by 3E+01?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 3E+05 divided by 3E+01?",
        "writeInAnswer": 10000,
        "furtherInfo": {
            "explanation": "What is 3E+05 divided by 3E+01?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+04."
        }
    },
    {
        "question": "What is 1E+05 divided by 3E+03?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 1E+05 divided by 3E+03?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+01."
        }
    },
    {
        "question": "What is 3E+01 divided by 3E+00?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 3E+01 divided by 3E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 1E+09 divided by 1E+01?",
        "writeInAnswer": 100000000,
        "furtherInfo": {
            "explanation": "What is 1E+09 divided by 1E+01?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+08."
        }
    },
    {
        "question": "What is 3E+10 divided by 3E+06?",
        "writeInAnswer": 10000,
        "furtherInfo": {
            "explanation": "What is 3E+10 divided by 3E+06?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+04."
        }
    },
    {
        "question": "What is 1E+12 divided by 3E+00?",
        "writeInAnswer": 300000000000,
        "furtherInfo": {
            "explanation": "What is 1E+12 divided by 3E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+11."
        }
    },
    {
        "question": "What is 1E+08 divided by 3E+06?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 1E+08 divided by 3E+06?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+01."
        }
    },
    {
        "question": "What is 1E+13 divided by 3E+09?",
        "writeInAnswer": 3000,
        "furtherInfo": {
            "explanation": "What is 1E+13 divided by 3E+09?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+03."
        }
    },
    {
        "question": "What is 1E+13 divided by 1E+04?",
        "writeInAnswer": 1000000000,
        "furtherInfo": {
            "explanation": "What is 1E+13 divided by 1E+04?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+09."
        }
    },
    {
        "question": "What is 1E+01 divided by 1E+00?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 1E+01 divided by 1E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 1E+05 divided by 3E+01?",
        "writeInAnswer": 3000,
        "furtherInfo": {
            "explanation": "What is 1E+05 divided by 3E+01?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+03."
        }
    },
    {
        "question": "What is 3E+01 divided by 1E+00?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 3E+01 divided by 1E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
        }
    },
    {
        "question": "What is 1E+08 divided by 3E+05?",
        "writeInAnswer": 300,
        "furtherInfo": {
            "explanation": "What is 1E+08 divided by 3E+05?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+02."
        }
    },
    {
        "question": "What is 3E+10 divided by 1E+09?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 3E+10 divided by 1E+09?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
        }
    },
    {
        "question": "What is 3E+12 divided by 3E+08?",
        "writeInAnswer": 10000,
        "furtherInfo": {
            "explanation": "What is 3E+12 divided by 3E+08?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+04."
        }
    },
    {
        "question": "What is 3E+11 divided by 3E+01?",
        "writeInAnswer": 10000000000,
        "furtherInfo": {
            "explanation": "What is 3E+11 divided by 3E+01?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+10."
        }
    },
    {
        "question": "What is 3E+09 divided by 1E+01?",
        "writeInAnswer": 300000000,
        "furtherInfo": {
            "explanation": "What is 3E+09 divided by 1E+01?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+08."
        }
    },
    {
        "question": "What is 1E+03 divided by 3E+00?",
        "writeInAnswer": 300,
        "furtherInfo": {
            "explanation": "What is 1E+03 divided by 3E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+02."
        }
    },
    {
        "question": "What is 1E+04 divided by 3E+02?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 1E+04 divided by 3E+02?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+01."
        }
    },
    {
        "question": "What is 3E+01 divided by 1E+00?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 3E+01 divided by 1E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
        }
    },
    {
        "question": "What is 1E+01 divided by 3E+00?",
        "writeInAnswer": 3,
        "furtherInfo": {
            "explanation": "What is 1E+01 divided by 3E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
        }
    },
    {
        "question": "What is 3E+09 divided by 3E+00?",
        "writeInAnswer": 1000000000,
        "furtherInfo": {
            "explanation": "What is 3E+09 divided by 3E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+09."
        }
    },
    {
        "question": "What is 1E+09 divided by 1E+07?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 1E+09 divided by 1E+07?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 1E+07 divided by 1E+03?",
        "writeInAnswer": 10000,
        "furtherInfo": {
            "explanation": "What is 1E+07 divided by 1E+03?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+04."
        }
    },
    {
        "question": "What is 3E+05 divided by 3E+04?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 3E+05 divided by 3E+04?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 1E+05 divided by 3E+03?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 1E+05 divided by 3E+03?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+01."
        }
    },
    {
        "question": "What is 1E+06 divided by 1E+04?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 1E+06 divided by 1E+04?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 1E+06 divided by 1E+02?",
        "writeInAnswer": 10000,
        "furtherInfo": {
            "explanation": "What is 1E+06 divided by 1E+02?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+04."
        }
    },
    {
        "question": "What is 3E+04 divided by 1E+02?",
        "writeInAnswer": 300,
        "furtherInfo": {
            "explanation": "What is 3E+04 divided by 1E+02?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+02."
        }
    },
    {
        "question": "What is 3E+11 divided by 1E+02?",
        "writeInAnswer": 3000000000,
        "furtherInfo": {
            "explanation": "What is 3E+11 divided by 1E+02?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+09."
        }
    },
    {
        "question": "What is 1E+10 divided by 1E+04?",
        "writeInAnswer": 1000000,
        "furtherInfo": {
            "explanation": "What is 1E+10 divided by 1E+04?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+06."
        }
    },
    {
        "question": "What is 3E+13 divided by 1E+10?",
        "writeInAnswer": 3000,
        "furtherInfo": {
            "explanation": "What is 3E+13 divided by 1E+10?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+03."
        }
    },
    {
        "question": "What is 3E+09 divided by 1E+03?",
        "writeInAnswer": 3000000,
        "furtherInfo": {
            "explanation": "What is 3E+09 divided by 1E+03?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+06."
        }
    },
    {
        "question": "What is 1E+05 divided by 1E+03?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 1E+05 divided by 1E+03?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 1E+04 divided by 3E+03?",
        "writeInAnswer": 3,
        "furtherInfo": {
            "explanation": "What is 1E+04 divided by 3E+03?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
        }
    },
    {
        "question": "What is 1E+06 divided by 3E+04?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 1E+06 divided by 3E+04?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+01."
        }
    },
    {
        "question": "What is 1E+05 divided by 3E+01?",
        "writeInAnswer": 3000,
        "furtherInfo": {
            "explanation": "What is 1E+05 divided by 3E+01?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+03."
        }
    },
    {
        "question": "What is 3E+07 divided by 1E+01?",
        "writeInAnswer": 3000000,
        "furtherInfo": {
            "explanation": "What is 3E+07 divided by 1E+01?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+06."
        }
    },
    {
        "question": "What is 1E+09 divided by 1E+02?",
        "writeInAnswer": 10000000,
        "furtherInfo": {
            "explanation": "What is 1E+09 divided by 1E+02?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+07."
        }
    },
    {
        "question": "What is 1E+09 divided by 3E+05?",
        "writeInAnswer": 3000,
        "furtherInfo": {
            "explanation": "What is 1E+09 divided by 3E+05?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+03."
        }
    },
    {
        "question": "What is 3E+06 divided by 1E+00?",
        "writeInAnswer": 3000000,
        "furtherInfo": {
            "explanation": "What is 3E+06 divided by 1E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+06."
        }
    },
    {
        "question": "What is 3E+09 divided by 1E+00?",
        "writeInAnswer": 3000000000,
        "furtherInfo": {
            "explanation": "What is 3E+09 divided by 1E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+09."
        }
    },
    {
        "question": "What is 3E+13 divided by 3E+04?",
        "writeInAnswer": 1000000000,
        "furtherInfo": {
            "explanation": "What is 3E+13 divided by 3E+04?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+09."
        }
    },
    {
        "question": "What is 1E+11 divided by 3E+03?",
        "writeInAnswer": 30000000,
        "furtherInfo": {
            "explanation": "What is 1E+11 divided by 3E+03?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+07."
        }
    },
    {
        "question": "What is 1E+10 divided by 3E+03?",
        "writeInAnswer": 3000000,
        "furtherInfo": {
            "explanation": "What is 1E+10 divided by 3E+03?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+06."
        }
    },
    {
        "question": "What is 1E+05 divided by 3E+00?",
        "writeInAnswer": 30000,
        "furtherInfo": {
            "explanation": "What is 1E+05 divided by 3E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+04."
        }
    },
    {
        "question": "What is 1E+07 divided by 3E+05?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 1E+07 divided by 3E+05?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+01."
        }
    },
    {
        "question": "What is 1E+13 divided by 3E+03?",
        "writeInAnswer": 3000000000,
        "furtherInfo": {
            "explanation": "What is 1E+13 divided by 3E+03?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+09."
        }
    },
    {
        "question": "What is 1E+04 divided by 1E+03?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 1E+04 divided by 1E+03?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 3E+12 divided by 3E+06?",
        "writeInAnswer": 1000000,
        "furtherInfo": {
            "explanation": "What is 3E+12 divided by 3E+06?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+06."
        }
    },
    {
        "question": "What is 1E+13 divided by 1E+05?",
        "writeInAnswer": 100000000,
        "furtherInfo": {
            "explanation": "What is 1E+13 divided by 1E+05?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+08."
        }
    },
    {
        "question": "What is 1E+08 divided by 3E+04?",
        "writeInAnswer": 3000,
        "furtherInfo": {
            "explanation": "What is 1E+08 divided by 3E+04?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+03."
        }
    },
    {
        "question": "What is 3E+08 divided by 1E+04?",
        "writeInAnswer": 30000,
        "furtherInfo": {
            "explanation": "What is 3E+08 divided by 1E+04?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+04."
        }
    },
    {
        "question": "What is 1E+01 divided by 1E+00?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 1E+01 divided by 1E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 1E+05 divided by 3E+00?",
        "writeInAnswer": 30000,
        "furtherInfo": {
            "explanation": "What is 1E+05 divided by 3E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+04."
        }
    },
    {
        "question": "What is 1E+12 divided by 1E+05?",
        "writeInAnswer": 10000000,
        "furtherInfo": {
            "explanation": "What is 1E+12 divided by 1E+05?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+07."
        }
    },
    {
        "question": "What is 1E+09 divided by 1E+00?",
        "writeInAnswer": 1000000000,
        "furtherInfo": {
            "explanation": "What is 1E+09 divided by 1E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+09."
        }
    },
    {
        "question": "What is 1E+12 divided by 1E+03?",
        "writeInAnswer": 1000000000,
        "furtherInfo": {
            "explanation": "What is 1E+12 divided by 1E+03?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+09."
        }
    },
    {
        "question": "What is 3E+12 divided by 3E+01?",
        "writeInAnswer": 100000000000,
        "furtherInfo": {
            "explanation": "What is 3E+12 divided by 3E+01?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+11."
        }
    },
    {
        "question": "What is 3E+06 divided by 1E+01?",
        "writeInAnswer": 300000,
        "furtherInfo": {
            "explanation": "What is 3E+06 divided by 1E+01?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+05."
        }
    },
    {
        "question": "What is 1E+08 divided by 1E+07?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 1E+08 divided by 1E+07?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 3E+13 divided by 1E+09?",
        "writeInAnswer": 30000,
        "furtherInfo": {
            "explanation": "What is 3E+13 divided by 1E+09?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+04."
        }
    },
    {
        "question": "What is 3E+05 divided by 1E+02?",
        "writeInAnswer": 3000,
        "furtherInfo": {
            "explanation": "What is 3E+05 divided by 1E+02?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+03."
        }
    },
    {
        "question": "What is 3E+11 divided by 1E+07?",
        "writeInAnswer": 30000,
        "furtherInfo": {
            "explanation": "What is 3E+11 divided by 1E+07?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+04."
        }
    },
    {
        "question": "What is 3E+12 divided by 3E+00?",
        "writeInAnswer": 1000000000000,
        "furtherInfo": {
            "explanation": "What is 3E+12 divided by 3E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+12."
        }
    },
    {
        "question": "What is 3E+06 divided by 1E+05?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 3E+06 divided by 1E+05?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
        }
    },
    {
        "question": "What is 3E+02 divided by 3E+00?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 3E+02 divided by 3E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 1E+03 divided by 1E+01?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 1E+03 divided by 1E+01?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 1E+05 divided by 1E+00?",
        "writeInAnswer": 100000,
        "furtherInfo": {
            "explanation": "What is 1E+05 divided by 1E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+05."
        }
    },
    {
        "question": "What is 3E+11 divided by 3E+06?",
        "writeInAnswer": 100000,
        "furtherInfo": {
            "explanation": "What is 3E+11 divided by 3E+06?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+05."
        }
    },
    {
        "question": "What is 1E+09 divided by 3E+03?",
        "writeInAnswer": 300000,
        "furtherInfo": {
            "explanation": "What is 1E+09 divided by 3E+03?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+05."
        }
    },
    {
        "question": "What is 3E+01 divided by 3E+00?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 3E+01 divided by 3E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 3E+02 divided by 3E+00?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 3E+02 divided by 3E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 3E+03 divided by 1E+02?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 3E+03 divided by 1E+02?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
        }
    },
    {
        "question": "What is 1E+13 divided by 3E+10?",
        "writeInAnswer": 300,
        "furtherInfo": {
            "explanation": "What is 1E+13 divided by 3E+10?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+02."
        }
    },
    {
        "question": "What is 1E+02 divided by 3E+01?",
        "writeInAnswer": 3,
        "furtherInfo": {
            "explanation": "What is 1E+02 divided by 3E+01?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
        }
    },
    {
        "question": "What is 1E+08 divided by 1E+03?",
        "writeInAnswer": 100000,
        "furtherInfo": {
            "explanation": "What is 1E+08 divided by 1E+03?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+05."
        }
    },
    {
        "question": "What is 1E+11 divided by 1E+10?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 1E+11 divided by 1E+10?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 1E+08 divided by 3E+02?",
        "writeInAnswer": 300000,
        "furtherInfo": {
            "explanation": "What is 1E+08 divided by 3E+02?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+05."
        }
    },
    {
        "question": "What is 1E+05 divided by 3E+01?",
        "writeInAnswer": 3000,
        "furtherInfo": {
            "explanation": "What is 1E+05 divided by 3E+01?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+03."
        }
    },
    {
        "question": "What is 3E+09 divided by 1E+08?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 3E+09 divided by 1E+08?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
        }
    },
    {
        "question": "What is 3E+05 divided by 1E+03?",
        "writeInAnswer": 300,
        "furtherInfo": {
            "explanation": "What is 3E+05 divided by 1E+03?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+02."
        }
    },
    {
        "question": "What is 1E+04 divided by 3E+03?",
        "writeInAnswer": 3,
        "furtherInfo": {
            "explanation": "What is 1E+04 divided by 3E+03?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
        }
    },
    {
        "question": "What is 3E+04 divided by 1E+00?",
        "writeInAnswer": 30000,
        "furtherInfo": {
            "explanation": "What is 3E+04 divided by 1E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+04."
        }
    },
    {
        "question": "What is 3E+01 divided by 3E+00?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 3E+01 divided by 3E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 3E+09 divided by 1E+04?",
        "writeInAnswer": 300000,
        "furtherInfo": {
            "explanation": "What is 3E+09 divided by 1E+04?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+05."
        }
    },
    {
        "question": "What is 1E+12 divided by 3E+04?",
        "writeInAnswer": 30000000,
        "furtherInfo": {
            "explanation": "What is 1E+12 divided by 3E+04?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+07."
        }
    },
    {
        "question": "What is 3E+01 divided by 3E+00?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 3E+01 divided by 3E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 1E+08 divided by 1E+01?",
        "writeInAnswer": 10000000,
        "furtherInfo": {
            "explanation": "What is 1E+08 divided by 1E+01?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+07."
        }
    },
    {
        "question": "What is 1E+02 divided by 3E+01?",
        "writeInAnswer": 3,
        "furtherInfo": {
            "explanation": "What is 1E+02 divided by 3E+01?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
        }
    },
    {
        "question": "What is 3E+09 divided by 3E+02?",
        "writeInAnswer": 10000000,
        "furtherInfo": {
            "explanation": "What is 3E+09 divided by 3E+02?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+07."
        }
    },
    {
        "question": "What is 3E+05 divided by 3E+01?",
        "writeInAnswer": 10000,
        "furtherInfo": {
            "explanation": "What is 3E+05 divided by 3E+01?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+04."
        }
    },
    {
        "question": "What is 3E+04 divided by 3E+01?",
        "writeInAnswer": 1000,
        "furtherInfo": {
            "explanation": "What is 3E+04 divided by 3E+01?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
        }
    },
    {
        "question": "What is 3E+02 divided by 3E+00?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 3E+02 divided by 3E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 1E+11 divided by 1E+00?",
        "writeInAnswer": 100000000000,
        "furtherInfo": {
            "explanation": "What is 1E+11 divided by 1E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+11."
        }
    },
    {
        "question": "What is 1E+02 divided by 1E+00?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 1E+02 divided by 1E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 1E+05 divided by 3E+03?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 1E+05 divided by 3E+03?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+01."
        }
    },
    {
        "question": "What is 1E+10 divided by 1E+09?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 1E+10 divided by 1E+09?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 3E+12 divided by 3E+11?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 3E+12 divided by 3E+11?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 1E+07 divided by 1E+05?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 1E+07 divided by 1E+05?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 3E+04 divided by 3E+01?",
        "writeInAnswer": 1000,
        "furtherInfo": {
            "explanation": "What is 3E+04 divided by 3E+01?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
        }
    },
    {
        "question": "What is 1E+06 divided by 3E+05?",
        "writeInAnswer": 3,
        "furtherInfo": {
            "explanation": "What is 1E+06 divided by 3E+05?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
        }
    },
    {
        "question": "What is 3E+05 divided by 1E+02?",
        "writeInAnswer": 3000,
        "furtherInfo": {
            "explanation": "What is 3E+05 divided by 1E+02?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+03."
        }
    },
    {
        "question": "What is 1E+09 divided by 3E+08?",
        "writeInAnswer": 3,
        "furtherInfo": {
            "explanation": "What is 1E+09 divided by 3E+08?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
        }
    },
    {
        "question": "What is 3E+11 divided by 1E+03?",
        "writeInAnswer": 300000000,
        "furtherInfo": {
            "explanation": "What is 3E+11 divided by 1E+03?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+08."
        }
    },
    {
        "question": "What is 3E+13 divided by 3E+05?",
        "writeInAnswer": 100000000,
        "furtherInfo": {
            "explanation": "What is 3E+13 divided by 3E+05?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+08."
        }
    },
    {
        "question": "What is 1E+05 divided by 1E+00?",
        "writeInAnswer": 100000,
        "furtherInfo": {
            "explanation": "What is 1E+05 divided by 1E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+05."
        }
    },
    {
        "question": "What is 3E+11 divided by 1E+09?",
        "writeInAnswer": 300,
        "furtherInfo": {
            "explanation": "What is 3E+11 divided by 1E+09?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+02."
        }
    },
    {
        "question": "What is 1E+11 divided by 3E+03?",
        "writeInAnswer": 30000000,
        "furtherInfo": {
            "explanation": "What is 1E+11 divided by 3E+03?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+07."
        }
    },
    {
        "question": "What is 1E+07 divided by 3E+06?",
        "writeInAnswer": 3,
        "furtherInfo": {
            "explanation": "What is 1E+07 divided by 3E+06?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
        }
    },
    {
        "question": "What is 3E+11 divided by 3E+00?",
        "writeInAnswer": 100000000000,
        "furtherInfo": {
            "explanation": "What is 3E+11 divided by 3E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+11."
        }
    },
    {
        "question": "What is 3E+02 divided by 1E+01?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 3E+02 divided by 1E+01?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
        }
    },
    {
        "question": "What is 1E+10 divided by 3E+04?",
        "writeInAnswer": 300000,
        "furtherInfo": {
            "explanation": "What is 1E+10 divided by 3E+04?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+05."
        }
    },
    {
        "question": "What is 1E+09 divided by 1E+02?",
        "writeInAnswer": 10000000,
        "furtherInfo": {
            "explanation": "What is 1E+09 divided by 1E+02?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+07."
        }
    },
    {
        "question": "What is 1E+06 divided by 3E+03?",
        "writeInAnswer": 300,
        "furtherInfo": {
            "explanation": "What is 1E+06 divided by 3E+03?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+02."
        }
    },
    {
        "question": "What is 1E+09 divided by 1E+07?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 1E+09 divided by 1E+07?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 3E+02 divided by 1E+00?",
        "writeInAnswer": 300,
        "furtherInfo": {
            "explanation": "What is 3E+02 divided by 1E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+02."
        }
    },
    {
        "question": "What is 1E+09 divided by 1E+03?",
        "writeInAnswer": 1000000,
        "furtherInfo": {
            "explanation": "What is 1E+09 divided by 1E+03?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+06."
        }
    },
    {
        "question": "What is 1E+02 divided by 1E+00?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 1E+02 divided by 1E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 3E+07 divided by 3E+04?",
        "writeInAnswer": 1000,
        "furtherInfo": {
            "explanation": "What is 3E+07 divided by 3E+04?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
        }
    },
    {
        "question": "What is 1E+07 divided by 1E+02?",
        "writeInAnswer": 100000,
        "furtherInfo": {
            "explanation": "What is 1E+07 divided by 1E+02?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+05."
        }
    },
    {
        "question": "What is 1E+07 divided by 1E+03?",
        "writeInAnswer": 10000,
        "furtherInfo": {
            "explanation": "What is 1E+07 divided by 1E+03?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+04."
        }
    },
    {
        "question": "What is 3E+07 divided by 1E+05?",
        "writeInAnswer": 300,
        "furtherInfo": {
            "explanation": "What is 3E+07 divided by 1E+05?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+02."
        }
    },
    {
        "question": "What is 1E+08 divided by 3E+00?",
        "writeInAnswer": 30000000,
        "furtherInfo": {
            "explanation": "What is 1E+08 divided by 3E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+07."
        }
    },
    {
        "question": "What is 3E+10 divided by 1E+09?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 3E+10 divided by 1E+09?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
        }
    },
    {
        "question": "What is 1E+08 divided by 3E+05?",
        "writeInAnswer": 300,
        "furtherInfo": {
            "explanation": "What is 1E+08 divided by 3E+05?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+02."
        }
    },
    {
        "question": "What is 1E+11 divided by 3E+01?",
        "writeInAnswer": 3000000000,
        "furtherInfo": {
            "explanation": "What is 1E+11 divided by 3E+01?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+09."
        }
    },
    {
        "question": "What is 3E+07 divided by 3E+00?",
        "writeInAnswer": 10000000,
        "furtherInfo": {
            "explanation": "What is 3E+07 divided by 3E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+07."
        }
    },
    {
        "question": "What is 1E+08 divided by 3E+05?",
        "writeInAnswer": 300,
        "furtherInfo": {
            "explanation": "What is 1E+08 divided by 3E+05?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+02."
        }
    },
    {
        "question": "What is 3E+13 divided by 3E+07?",
        "writeInAnswer": 1000000,
        "furtherInfo": {
            "explanation": "What is 3E+13 divided by 3E+07?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+06."
        }
    },
    {
        "question": "What is 3E+03 divided by 1E+02?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 3E+03 divided by 1E+02?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
        }
    },
    {
        "question": "What is 1E+11 divided by 1E+04?",
        "writeInAnswer": 10000000,
        "furtherInfo": {
            "explanation": "What is 1E+11 divided by 1E+04?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+07."
        }
    },
    {
        "question": "What is 1E+06 divided by 1E+05?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 1E+06 divided by 1E+05?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 1E+06 divided by 3E+04?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 1E+06 divided by 3E+04?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+01."
        }
    },
    {
        "question": "What is 3E+10 divided by 1E+00?",
        "writeInAnswer": 30000000000,
        "furtherInfo": {
            "explanation": "What is 3E+10 divided by 1E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+10."
        }
    },
    {
        "question": "What is 3E+08 divided by 1E+04?",
        "writeInAnswer": 30000,
        "furtherInfo": {
            "explanation": "What is 3E+08 divided by 1E+04?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+04."
        }
    },
    {
        "question": "What is 1E+11 divided by 1E+04?",
        "writeInAnswer": 10000000,
        "furtherInfo": {
            "explanation": "What is 1E+11 divided by 1E+04?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+07."
        }
    },
    {
        "question": "What is 3E+06 divided by 1E+04?",
        "writeInAnswer": 300,
        "furtherInfo": {
            "explanation": "What is 3E+06 divided by 1E+04?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+02."
        }
    },
    {
        "question": "What is 3E+01 divided by 3E+00?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 3E+01 divided by 3E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 3E+07 divided by 1E+06?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 3E+07 divided by 1E+06?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
        }
    },
    {
        "question": "What is 3E+13 divided by 3E+04?",
        "writeInAnswer": 1000000000,
        "furtherInfo": {
            "explanation": "What is 3E+13 divided by 3E+04?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+09."
        }
    },
    {
        "question": "What is 1E+13 divided by 1E+00?",
        "writeInAnswer": 10000000000000,
        "furtherInfo": {
            "explanation": "What is 1E+13 divided by 1E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+13."
        }
    },
    {
        "question": "What is 3E+06 divided by 3E+04?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 3E+06 divided by 3E+04?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 1E+11 divided by 3E+08?",
        "writeInAnswer": 300,
        "furtherInfo": {
            "explanation": "What is 1E+11 divided by 3E+08?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+02."
        }
    },
    {
        "question": "What is 3E+07 divided by 1E+00?",
        "writeInAnswer": 30000000,
        "furtherInfo": {
            "explanation": "What is 3E+07 divided by 1E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+07."
        }
    },
    {
        "question": "What is 1E+06 divided by 3E+01?",
        "writeInAnswer": 30000,
        "furtherInfo": {
            "explanation": "What is 1E+06 divided by 3E+01?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+04."
        }
    },
    {
        "question": "What is 3E+06 divided by 1E+01?",
        "writeInAnswer": 300000,
        "furtherInfo": {
            "explanation": "What is 3E+06 divided by 1E+01?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+05."
        }
    },
    {
        "question": "What is 3E+13 divided by 3E+07?",
        "writeInAnswer": 1000000,
        "furtherInfo": {
            "explanation": "What is 3E+13 divided by 3E+07?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+06."
        }
    },
    {
        "question": "What is 3E+07 divided by 1E+03?",
        "writeInAnswer": 30000,
        "furtherInfo": {
            "explanation": "What is 3E+07 divided by 1E+03?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+04."
        }
    },
    {
        "question": "What is 1E+11 divided by 1E+04?",
        "writeInAnswer": 10000000,
        "furtherInfo": {
            "explanation": "What is 1E+11 divided by 1E+04?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+07."
        }
    },
    {
        "question": "What is 3E+12 divided by 3E+02?",
        "writeInAnswer": 10000000000,
        "furtherInfo": {
            "explanation": "What is 3E+12 divided by 3E+02?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+10."
        }
    },
    {
        "question": "What is 3E+11 divided by 1E+04?",
        "writeInAnswer": 30000000,
        "furtherInfo": {
            "explanation": "What is 3E+11 divided by 1E+04?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+07."
        }
    },
    {
        "question": "What is 1E+08 divided by 3E+01?",
        "writeInAnswer": 3000000,
        "furtherInfo": {
            "explanation": "What is 1E+08 divided by 3E+01?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+06."
        }
    },
    {
        "question": "What is 1E+01 divided by 3E+00?",
        "writeInAnswer": 3,
        "furtherInfo": {
            "explanation": "What is 1E+01 divided by 3E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
        }
    },
    {
        "question": "What is 3E+05 divided by 1E+04?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 3E+05 divided by 1E+04?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
        }
    },
    {
        "question": "What is 1E+08 divided by 1E+02?",
        "writeInAnswer": 1000000,
        "furtherInfo": {
            "explanation": "What is 1E+08 divided by 1E+02?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+06."
        }
    },
    {
        "question": "What is 1E+13 divided by 3E+04?",
        "writeInAnswer": 300000000,
        "furtherInfo": {
            "explanation": "What is 1E+13 divided by 3E+04?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+08."
        }
    },
    {
        "question": "What is 1E+02 divided by 1E+00?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 1E+02 divided by 1E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 3E+09 divided by 3E+07?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 3E+09 divided by 3E+07?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 3E+07 divided by 3E+04?",
        "writeInAnswer": 1000,
        "furtherInfo": {
            "explanation": "What is 3E+07 divided by 3E+04?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
        }
    },
    {
        "question": "What is 3E+08 divided by 3E+04?",
        "writeInAnswer": 10000,
        "furtherInfo": {
            "explanation": "What is 3E+08 divided by 3E+04?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+04."
        }
    },
    {
        "question": "What is 3E+04 divided by 1E+00?",
        "writeInAnswer": 30000,
        "furtherInfo": {
            "explanation": "What is 3E+04 divided by 1E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+04."
        }
    },
    {
        "question": "What is 3E+02 divided by 3E+01?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 3E+02 divided by 3E+01?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 3E+02 divided by 1E+01?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 3E+02 divided by 1E+01?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
        }
    },
    {
        "question": "What is 1E+09 divided by 1E+06?",
        "writeInAnswer": 1000,
        "furtherInfo": {
            "explanation": "What is 1E+09 divided by 1E+06?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
        }
    },
    {
        "question": "What is 1E+06 divided by 3E+05?",
        "writeInAnswer": 3,
        "furtherInfo": {
            "explanation": "What is 1E+06 divided by 3E+05?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
        }
    },
    {
        "question": "What is 1E+13 divided by 1E+06?",
        "writeInAnswer": 10000000,
        "furtherInfo": {
            "explanation": "What is 1E+13 divided by 1E+06?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+07."
        }
    },
    {
        "question": "What is 3E+10 divided by 1E+07?",
        "writeInAnswer": 3000,
        "furtherInfo": {
            "explanation": "What is 3E+10 divided by 1E+07?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+03."
        }
    },
    {
        "question": "What is 1E+10 divided by 3E+09?",
        "writeInAnswer": 3,
        "furtherInfo": {
            "explanation": "What is 1E+10 divided by 3E+09?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
        }
    },
    {
        "question": "What is 3E+10 divided by 1E+03?",
        "writeInAnswer": 30000000,
        "furtherInfo": {
            "explanation": "What is 3E+10 divided by 1E+03?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+07."
        }
    },
    {
        "question": "What is 1E+03 divided by 1E+01?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 1E+03 divided by 1E+01?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 3E+02 divided by 3E+00?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 3E+02 divided by 3E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 3E+03 divided by 1E+02?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 3E+03 divided by 1E+02?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
        }
    },
    {
        "question": "What is 3E+06 divided by 1E+04?",
        "writeInAnswer": 300,
        "furtherInfo": {
            "explanation": "What is 3E+06 divided by 1E+04?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+02."
        }
    },
    {
        "question": "What is 3E+08 divided by 3E+00?",
        "writeInAnswer": 100000000,
        "furtherInfo": {
            "explanation": "What is 3E+08 divided by 3E+00?<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+08."
        }
    }
  ],
};

export default wellRoundedDivision2;