import isAlphanumeric from "validator/lib/isAlphanumeric";

const validTextCaption = () => {
  return "Alphanumeric characters only. No spaces, and/or special characters other than '_' allowed.";
};

const isValidUsername = (username) => {
  return isAlphanumeric(username, "en-US", {
    ignore: "_",
  });
};

export { isValidUsername, validTextCaption };
export default isValidUsername;
