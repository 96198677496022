import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  button: {
    border: "1px solid #fff",
    borderRadius: 30,
    fontSize: 24,
    paddingTop: theme.spacing(1.2),
    paddingBottom: theme.spacing(1.2),
    marginBottom: theme.spacing(5),
    width: 225,
    textDecoration: "none",
    textAlign: "center",
    backgroundColor: ({ color }) => theme.palette[color || "primary"].light,
    color: ({ color }) => theme.palette[color || "primary"].contrastText,
    fontWeight: 600,
    transition:"all 0.4s ease",

   "&:hover":{
    color: ({color}) => theme.palette[color || "primary"].light,
    backgroundColor:({color}) => theme.palette[color || "primary"].contrastText
   },
   "&:active": {
    backgroundColor:({color}) => color==="white" ? "rgb(240, 240, 240)": "rgba(255, 255, 255, 0.5)",
    boxShadow: "0 1px #666",
    transform: "translateY(4px)"
  }
  },
}));

const LandingPageButton = ({ to, children, theme, color }) => {
  const classes = useStyles({ color });

  return (
    <Link to={to} className={classes.button}>
      {children}
    </Link>
  );
};

export default LandingPageButton;
