 const goldQuiz = {
    name: 'Gold!',
    id: 'le2li1KjDGxk89l2mgT5',
    linkTo: "/quizzes/goldQuiz",
    explainerOn: true,
    description: 'Beautiful and malleable, gold has been regarded as the most precious of metals since ancient times. Coveted by kings, queens, and pawn shop owners, it remains in high demand today! Do you have the knowledge it takes to go for the gold?',
    questions: [
        { // 1
            question: "How many metric tons of gold have been mined? (as of 2019)",
            choices: ['4.7e16 metric tons', '8.8e8 metric tons', '1.9e5 metric tons', '3.2e-2 metric tons'],
            answerIdx: 2,    
            furtherInfo: {
                explanation: "As of 2019, 1.9e5 (or 190,000) metric tons of gold have been mined",
                sources: [
                    {
                        source: 'World Gold Council',
                        url: 'https://www.gold.org/about-gold/gold-supply/gold-mining/how-much-gold',
                    }
                ],
            },
        },
        { // 2
            question: "What is the value of all the gold in the world? (Based on 5/19/2021 prices of $1,871 per ounce)",
            choices: [
                '$5.8e9',
                '$1.2e3',
                '$7.7e15',
                '$9.4e12'
            ],
            answerIdx: 3,    
            furtherInfo: {
                explanation: "The approximate value of all the gold in the world today is $9.4e12, or $9.4 trillion USD.",
                sources: [
                    {
                        source: 'JM Bullion',
                        url: 'https://www.jmbullion.com/charts/gold-price/',
                    }
                ],
            },
        },
        { // 3
            question: "The average human body contains _________ milligrams (mg) of gold.",
            choices: ['4e-2 mg', '2e-1 mg', '7e5 mg', '1e-3 mg'],
            answerIdx: 1,    
            furtherInfo: {
                explanation: "The average human body contains 2e-1 (or 0.2) milligrams of gold",
                sources: [
                    {
                        source: 'UK Bullion',
                        url: 'https://www.ukbullion.com/learning-centre/articles/interesting-facts-about-gold',
                    }
                ],
            },
        },
        { // 4
            question: "How many metric tons of gold does the U.S. have in reserve?",
            choices: ['5.7e9','9.9e9', '8.1e3', '3.4e-1'],
            answerIdx: 2,    
            furtherInfo: {
                explanation: "The U.S. has 8.1e3 (or 8,100) metric tons of gold in reserve.",
                sources: [
                    {
                        source: "U.S. Treasury Fiscal Data",
                        url: 'https://fiscaldata.treasury.gov/datasets/status-report-government-gold-reserve/u-s-treasury-owned-gold',
                    }
                ],
            },
        },
        { // 5
            question: "What is the majority of gold used for in the world?",
            choices: [
                'Investments',
                'Industrial use',
                'Medicine',
                'Jewelry'
            ],
            answerIdx: 3,    
            furtherInfo: {
                explanation: "The majority of the world’s gold is used for jewelry.",
                sources: [
                    {
                        source: 'Geology.com',
                        url: 'https://geology.com/minerals/gold/uses-of-gold.shtml',
                    }
                ],
            },
        },
        { // 6
            question:'Where were gold coins first used as money?',
            choices: [
                'Egypt',
                'Lydia',
                'Assyria',
                'Babylonia'
            ],
            answerIdx: 1,    
            furtherInfo: {
                explanation: "Lydia, in modern day Turkey, was the first to use gold coins as money -- around 600 BCE.",
                sources: [
                    {
                        source: 'rg.ancients.info',
                        url: 'http://rg.ancients.info/lion/article.html',
                    }
                ],
            },
        },
        { // 7
            question: "What year did the United States abandon the Gold Standard?",
            choices: [
                '1971',
                '1953',
                '1977',
                '1932'
            ],
            answerIdx: 0,    
            furtherInfo: {
                explanation: "The United States abandoned the Gold Standard in 1971.",
                sources: [
                    {
                        source: 'The History Channel',
                        url: 'https://www.history.com/this-day-in-history/fdr-takes-united-states-off-gold-standard',
                    }
                ],
            },
        },
        { // 8
            question: 'Which karat (K) rating denotes the highest purity of gold (99.9%)?',
            choices: [
                '30K',
                '100K',
                '24K',
                '1K'
            ],
            answerIdx: 2,    
            furtherInfo: {
                explanation: "24K denotes the highest purity of gold: 99.9%. Karats (or Carats) measure of the purity of gold, with each karat representing 1/24 of the whole.",
                sources: [
                    {
                        source: 'World Gold Council',
                        url: 'https://www.gold.org/about-gold/about-gold-jewellery',
                    },
                    {
                        source: 'Britannica',
                        url: 'https://www.britannica.com/technology/karat',
                    },
                ],
            },
        },
        { // 9
            question: 'Which of the following attributes about gold is true?',
            choices: [
                'Rust-proof',
                'Most malleable metal',
                'Tasteless',
                'Heat & electrical conductor',
                'All of the above'
            ],
            answerIdx: 4,
            furtherInfo: {
                explanation: "All of these are true of gold: it is rust proof, the most malleable metal, tasteless, conducts heat and electricity.",
                sources: [
                    {
                        source: 'UK Bullion',
                        url: 'https://www.ukbullion.com/learning-centre/articles/interesting-facts-about-gold',
                    }
                ],
            },
        },
          
    ],

};

export default goldQuiz;