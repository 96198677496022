const yogaQuiz = {
    id: 'yogaQuiz',
    name: 'What is Yoga?',
    linkTo: "/quizzes/yogaQuiz",
    description: 'Test your balance and awareness... about yoga',
    questions: [
        {
            question: "The word Yoga means:",
            choices: ['Oneness', 'Flexible', 'Guru'],
            answerIdx: 0,    
            furtherInfo: {
            },
        },
        {
            question: "Yoga was developed by the beliefs of?",
            choices: ['Hinduism', 'Buddhism', 'Jainism', 'All of the above'],
            answerIdx: 3,    
            furtherInfo: {
            },
        },
        {
            question: "Energy flow or life force is called?",
            choices: ['Flex', 'Prana', 'Citta'],
            answerIdx: 1,    
            furtherInfo: {
            },
        },
        {
            question: "Hatha Yoga is the principle of?",
            choices: ['Form', 'Wisdom', 'Devotion'],
            answerIdx: 0,    
            furtherInfo: {
            },
        },
        {
            question: "A wheel of energy or vortex running through the spine to the top of the head is called?",
            choices: ['Plexes', 'Force', 'Chakras'],
            answerIdx: 0,    
            furtherInfo: {
            },
        },
        {
            question: "The channels in which energy flows through the body are called?",
            choices: ['Powerlines', 'Nadis', 'Force Rails'],
            answerIdx: 1,    
            furtherInfo: {
            },
        },
        {
            question: "The third eye chakra that connects to intuition through meditation is called?",
            choices: ['Vishuddha', 'Manipura', 'Ajna'],
            answerIdx: 2,    
            furtherInfo: {
            },
        },
        {
            question: "The practice of awakening the coiled energy in the body is called?",
            choices: ['Kundalini Yoga', 'Jain Yoga', 'Astanga'],
            answerIdx: 0,    
            furtherInfo: {
            },
        },
        {
            question: "Body postures in Yoga exercises are called?",
            choices: ['Asanas', 'Poses', 'Forms'],
            answerIdx: 0,    
            furtherInfo: {
            },
        },
        {
            question: "How many different asanas are there in Yoga?",
            choices: ['16', '60', '200'],
            answerIdx: 2,    
            furtherInfo: {
            },
        },
    ],

};

export default yogaQuiz;
