 const disneylandQuiz = {
    id: 'disneylandQuiz',
    name: 'Disneyland',
    linkTo: "/quizzes/disneylandQuiz",
    description: 'How well do you know Disneyland?',
    questions: [
        {
            question: "What year did Disneyland open its first park in Anaheim, California?",
            choices: ['1960', '1942', '1955'],
            answerIdx: 2,    
            furtherInfo: {
            },
        },
        {
            question: "Main Street, U.S.A is inspired by Walt Disney's hometown city of?",
            choices: ['Marceline, Missouri', 'Kansas City, Missouri', 'Flint, Michigan'],
            answerIdx: 0,    
            furtherInfo: {
            },
        },
        {
            question: "What year did Space Mountain originally open?",
            choices: ['1980', '1977', '1960'],
            answerIdx: 1,    
            furtherInfo: {
            },
        },
        {
            question: "How many feral cats roam the park at night?",
            choices: ['200', '150', '25'],
            answerIdx: 0,    
            furtherInfo: {
            },
        },
        {
            question: "What is the code for someone throwing up in the park?",
            choices: ['Code Puke', 'Code V', 'Code Gross'],
            answerIdx: 1,    
            furtherInfo: {
            },
        },
        {
            question: "It's a Small World ride originally debuted at?",
            choices: ['Montreal Expo 67', 'International Exhibition', "New York World's Fair"],
            answerIdx: 2,    
            furtherInfo: {
            },
        },
        {
            question: "How much did it cost to build Disneyland in 1955?",
            choices: ['100 million', '50 million', '17 million'],
            answerIdx: 2,    
            furtherInfo: {
            },
        },
        {
            question: "How many hidden Mickeys are located within the park?",
            choices: ['500', '1000', '800'],
            answerIdx: 1,    
            furtherInfo: {
            },
        },
        {
            question: "What is George Lucas' cameo name in Star Tours?",
            choices: ['Mr. Egroeg Sacual', 'Master Lucas', 'Jedi George'],
            answerIdx: 0,    
            furtherInfo: {
            },
        },
        {
            question: 'Who voiced the droid RX-24 "Captain Rex" in Star Tours?',
            choices: ['Paul Reubens', 'Mark Hamill', 'Will Smith'],
            answerIdx: 0,    
            furtherInfo: {
            },
        },
    ],

};

export default disneylandQuiz;
