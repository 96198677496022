 const logicalFallacies = {
    name: 'Logical Fallacies',
    id: 'QCY5WR5LNQHNS4w78P94',
    linkTo: "/quizzes/logicalFallacies",
    explainerOn: true,
    description: "How logical a thinker are you? Many of the following arguments contain a logical fallacy. Can you spot them?",
    questions: [
        {
            //question 1//
            question: "“My opponent is an ugly, badly-dressed idiot, and clearly unfit for office.”",
            choices: ["This is a logical argument.", "This is a fallacy."],
            answerIdx: 1,    
            furtherInfo: {
                explanation: (
                    `<b>This is a fallacy.</b><br /><br />The opponent’s appearance has nothing to do with her ability to carry out the office’s duties, making this a type of fallacy known as an <i>ad hominem attack</i> which uses unrelated personal attacks to undermine an opponent’s argument.`
                ),
                sources: [
                    {
                        source: 'Logically Fallacious',
                        url: 'https://www.logicallyfallacious.com/logicalfallacies/Ad-Hominem-Abusive',
                    },
                ],
            },
        },
        {
            //question 2//
            question: "“Evolution says fish can just evolve into monkeys. That’s ridiculous!”",
            choices: ["This is a fallacy.", "This is a logical argument."],
            answerIdx: 0,    
            furtherInfo: {
                explanation: (
                    `<b>This is a fallacy.</b><br /><br />As no one arguing for the veracity of evolution thinks this is how the process works, this is a type of fallacy known as an <i>appeal to ridicule</i> in which an opponent claims an argument is ridiculous and therefore not worth entertaining.`
                ),
                sources: [
                    {
                        source: 'Logically Fallacious',
                        url: 'https://www.logicallyfallacious.com/logicalfallacies/Appeal-to-Ridicule',
                    },
                ],
            },
        },
        {
            //question 3//
            question: "“I don’t know whether extraterrestrials exist, so I neither believe nor disbelieve that they do.”",
            choices: ["This is a logical statement.", "This is a fallacy."],
            answerIdx: 0,    
            furtherInfo: {
                explanation: (
                    `<b>This is a logical statement.</b><br /><br />'I don’t know' is the neutral ground between the claim 'they exist' and the claim 'they do not exist.'`
                ),
                sources: [
                    {
                        source: 'Logically Fallacious',
                        url: 'https://www.logicallyfallacious.com/logicalfallacies/search',
                    },
                ],
            },
        },
        {
            //question 4//
            question: "“Nobody’s proven that ghosts can’t exist, so you have to assume that hauntings are technically possible.”",
            choices: ["This is a logical argument.", "This is a fallacy."],
            answerIdx: 1,    
            furtherInfo: {
                explanation: (
                    `<b>This is a fallacy.</b><br /><br />Since no one has proven that ghosts <i>exist</i>, either, this is a fallacy known as a <i>burden of proof argument</i>. It puts the burden on the other party to disprove a claim.`
                ),
                sources: [
                    {
                        source: 'Logically Fallacious',
                        url: 'https://www.logicallyfallacious.com/logicalfallacies/Shifting-of-the-Burden-of-Proof',
                    },
                ],
            },
        },
        {
            //question 5//
            question: "“Everyone in Wheatville is a farmer. Arjun is from Wheatville. Therefore, Arjun is a farmer.”",
            choices: ["This is a fallacy.", "This is a logical argument."],
            answerIdx: 1,    
            furtherInfo: {
                explanation: (
                    `<b>This is a logical argument.</b><br /><br />If everyone in Wheatville is a farmer, then Arjun must be as well.`
                ),
                sources: [
                    {
                        source: 'Logically Fallacious',
                        url: 'https://www.logicallyfallacious.com/logicalfallacies/search',
                    },
                ],
            },
        },
        {
            //question 6//
            question: "“If Dr. Brown said climate change is a hoax, then it’s a hoax. She’s a tenured history professor, after all, and she says the earth naturally goes through warming cycles.”",
            choices: ["This is a logical argument.", "This is a fallacy."],
            answerIdx: 1,    
            furtherInfo: {
                explanation: (
                    `<b>This is a fallacy.</b><br /><br />Since Dr. Brown’s field of expertise is irrelevant to the study of climate change, this is a fallacy known as an <i>appeal to authority</i>.`
                ),
                sources: [
                    {
                        source: 'Logically Fallacious',
                        url: 'https://www.logicallyfallacious.com/logicalfallacies/Appeal-to-Authority',
                    },
                ],
            },
        },
        {
            //question 7//
            question: "“But, Dad, you skipped class all the time as a kid; how can you punish me for doing it?”",
            choices: ["This is a logical argument.", "This is a fallacy."],
            answerIdx: 1,    
            furtherInfo: {
                explanation: (
                    `<b>This is a fallacy.</b><br /><br />Since Dad’s guilt does not absolve me of my guilt, this is a fallacy known as <i>tu quoque</i>, which is returning criticism with criticism to invalidate an argument.`
                ),
                sources: [
                    {
                        source: 'Logically Fallacious',
                        url: 'https://www.logicallyfallacious.com/logicalfallacies/Ad-Hominem-Tu-quoque',
                    },
                ],
            },
        },
        {
            //question 8//
            question: "“Jane, how can you complain about the sexual objectification of female superheroes in comic books when male superheroes are objectified, too? I mean, look at Superman’s muscles.”",
            choices: ["This is a fallacy.", "This is a logical argument."],
            answerIdx: 0,    
            furtherInfo: {
                explanation: (
                    `<b>This is a fallacy.</b><br /><br />In my attempt to equate characters drawn with overly sexualized bodies to characters drawn with overly powerful bodies I am using a fallacy known as <i>false equivalence</i>.`
                ),
                sources: [
                    {
                        source: 'Logically Fallacious',
                        url: 'https://www.logicallyfallacious.com/logicalfallacies/False-Equivalence',
                    },
                ],
            },
        },
        {
            //question 9//
            question: "“The defendant was found ‘not guilty.’ Therefore, he is innocent.”",
            choices: ["This is a fallacy.", "This is a logical argument."],
            answerIdx: 0,    
            furtherInfo: {
                explanation: (
                    `<b>This is a fallacy.</b><br /><br />Though the jury did not find enough evidence to convict him, this does not mean the defendant is innocent, making this a fallacy known as <i>alleged certainty</i>, in which a statement makes a conclusion appear certain when it is in fact not.`
                ),
                sources: [
                    {
                        source: 'Logically Fallacious',
                        url: 'https://www.logicallyfallacious.com/logicalfallacies/Alleged-Certainty',
                    },
                ],
            },
        },
        {
            //question 10//
            question: "“Every time I walk by the abandoned house I get the creeps. Something bad is happening in there.”",
            choices: ["This is a logical argument.", "This is a fallacy."],
            answerIdx: 1,    
            furtherInfo: {
                explanation: (
                    `<b>This is a fallacy.</b><br /><br />Since feelings do not represent facts, this is a fallacy known as an <i>appeal to intuition</i> in which ‘gut feeling’ replaces reasonable evaluation.`
                ),
                sources: [
                    {
                        source: 'Logically Fallacious',
                        url: 'https://www.logicallyfallacious.com/logicalfallacies/Appeal-to-Intuition',
                    },
                ],
            },
        },
    ],

};

export default logicalFallacies;