const minecraftQuiz = {
  name: "Minecraft Quiz",
  id: 'S8rvPRaXp7miwSMTEDxz',
  linkTo: "/quizzes/minecraftQuiz",
  explainerOn: true,
  subheading: `How tall is a Minecraft block? How long is a Minecraft day? Can you answer our toughest Minecraft questions?`,
  description: `Minecraft is one of the best selling games of all time, second only to Tetris. It seems like everyone is playing it, including me. There’s even a school in Sweden where Minecraft is a required part of the curriculum! How complete is <i>your</i> Minecraft education?`,
  questions: [
    { // Q-1
      question: "How long did it take to create the first full version of Minecraft?",
      choices: ['6 Days', '60 Days', '6 Months', '6 Years'],
      answerIdx: 0,
      furtherInfo: {
        explanation: (
          `The very first version of Minecraft only took 6 days to create! It was called Cave Game Tech Test, and was a weird jigsaw world made from cobblestone and grass. It took another two years before a bigger, more interesting version was ready for release to the public.`
        ),
        sources: [
          {
            source: "Web Tribunal Blog",
            url: 'https://hostingtribunal.com/blog/minecraft-statistics/',
          },
        ],
      },
    },
    { // Q-2
      question: "In Minecraft, how far away do hostile mobs spawn?",
      choices: ['6 blocks', '12 blocks', '24 blocks', '48 blocks'],
      answerIdx: 2,
      furtherInfo: {
        explanation: (
          `Hostile mobs, like zombies, spawn 24 blocks away from a player.`
        ),
        sources: [
          {
            source: "World Analysis",
            url: 'https://worldanalysis.net/minecraft-how-many-blocks-away-do-mobs-spawn/',
          },
        ],
      },
    },
    { // Q-3 
      question: "How big would a single Minecraft block be in the real world?",
      choices: ['1 cubic foot', '1 cubic meter', '3 cubic feet', '1 cubic inch'],
      answerIdx: 1,
      furtherInfo: {
        explanation: (
          `In the real world, a single Minecraft block would be 1 cubic meter. That’s a cube 1 meter long on each of its sides. Do you know approximately how many feet a meter is?`
        ),
        sources: [
          {
            source: "Minecraft Wiki",
            url: `https://minecraft.fandom.com/wiki/Tutorials/Units_of_measure#:~:text=Minecraft's%20worlds%20are%20a%20voxel,1m3)%20in%20real%20life.`,
          },
        ],
      },
    },
    { // Q-4
      question: "Is a large Minecraft world bigger than Earth?",
      choices: ['Yes', 'No'],
      answerIdx: 0,
      furtherInfo: {
        explanation: (
          `Yes! A Minecraft world is not just larger than Earth, it is <i>much</i> larger. The diameter of the Earth is around 12,700 kilometers. Minecraft is flat, but if you traveled from one edge of the map to another on a large Minecraft world it would measure 64,000 kilometers. But comparing diameter, the distance straight across a round object and the length traveled across a flat object seems unfair, right? So what about total surface area? Minecraft wins there, too. The Earth's total surface area is about 510 million square kilometers, whereas Minecraft's comes in at over 4 billion square kilometers.`
        ),
        sources: [
          {
            source: "SVG.com",
            url: 'https://www.svg.com/162612/is-the-minecraft-world-bigger-than-earth/',
          },
        ],
      },
    },
    { // Q-5
      question: "How long is one Minecraft day in real time?",
      choices: ['24 hours', '2 hours', '24 minutes', '20 minutes'],
      answerIdx: 3,
      furtherInfo: {
        explanation: (
          `One full day/night cycle in Minecraft takes 20 minutes of real world time. How long does that make a Minecraft hour?`
        ),
        sources: [
          {
            source: "Minecraft Wiki",
            url: 'https://minecraft.fandom.com/wiki/Daylight_cycle',
          },
        ],
      },
    },
    { // Q-6
      question: "If one Minecraft day is 20 minutes long, how many full Minecraft days can fit into 1 real world day?",
      choices: ['36', '72', '220', '1,440'],
      answerIdx: 1,
      furtherInfo: {
        explanation: (
          `First, we have to figure out how many minutes are in a day, but that's pretty easy. Just multiply the number of minutes in an hour, 60, by how many hours there are in a day, 24. 60 x 24 = 1,440. Then, we divide 1,440 by 20, the number of minutes a full Minecraft day takes. 1,440 ÷ 20 = 72. That's 72 Minecraft days in one real world day; over two months!`
        ),
        sources: [
          {
            source: "Minecraft Wiki",
            url: `https://minecraft.fandom.com/wiki/Tutorials/Units_of_measure#:~:text=Minecraft's%20worlds%20are%20a%20voxel,1m3)%20in%20real%20life.`,
          },
        ],
      },
    },
    { // Q-7
      question: "How many lines of code is Minecraft?",
      choices: ['500', '40 thousand', '500 thousand', '4 billion'],
      answerIdx: 2,
      furtherInfo: {
        explanation: (
          `Minecraft's splash screen, that screen you see when you first open the game, once jokingly claimed the game contained over 4 billion lines of code. The Minecraft splash screen has also claimed to contain 150% hyperbole. In this case it's more like 8,000% hyperbole. Minecraft has about 500,000 lines of code.`
        ),
        sources: [
          {
            source: "Candid Technology",
            url: 'https://candid.technology/how-many-lines-of-code-are-in-minecraft/',
          },
        ],
      },
    },
    { // Q-8
      question: "How many people play Minecraft?",
      choices: ['10,000', '500,000', '112,000,000', '480,000,000'],
      answerIdx: 3,
      furtherInfo: {
        explanation: (
          `If you combine both paid and free users worldwide, over 480 million people play Minecraft.`
        ),
        sources: [
          {
            source: "Compare Camp",
            url: 'https://comparecamp.com/minecraft-statistics/',
          },
        ],
      },
    },
    { // Q-9
      question: "If Minecraft were a country and its population was its players, would Minecraft have a bigger population than the United States?",
      choices: ['No', 'Yes'],
      answerIdx: 1,
      furtherInfo: {
        explanation: (
          `Yes! Remember, Minecraft's 'population' is over 480 million people. The population of the United States is just over 330 million people, so Minecraft's 'population' is quite a bit larger.`
        ),
        sources: [
          {
            source: "Compare Camp",
            url: 'https://comparecamp.com/minecraft-statistics/',
          },
        ],
      },
    },
    { // Q-10
      question: "About how many times larger is the population of Minecraft (480 million) than the population of the United States (330 million)? Remember, it's okay to estimate!",
      choices: ['1.5 times', '2.5 times', '3 times', '3.5 times'],
      answerIdx: 0,
      furtherInfo: {
        explanation: (
          `The population of Minecraft is about 1.5 times larger than the population of the United States. 480,000,000 and 330,000,000  are large numbers, but luckily we don't need all those zeroes to figure this out! Since both numbers are in the millions, we can drop the ‘million’ part and just divide 480 by 330 to come up with an accurate answer. 480 ÷ 330 = 1.45 which, of course, we round up to 1.5. Would we get the same answer if we dropped the last zeroes and just divided 48 by 33? Yep! There’s a math trick that makes working with big numbers WAAAAAAY easier.`
        ),
        sources: [
          {
            source: "Worldometer",
            url: 'https://www.worldometers.info/world-population/us-population/',
          },
        ],
      },
    },
  ],

};

export default minecraftQuiz;