import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";

import Avatar from "@material-ui/core/Avatar";

const useStyles = makeStyles((theme) => ({
  avatarList: {
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      flexBasis: "33.3%",
      cursor: "pointer",
    },
  },
  button: {
    textAlign: "center",
    padding: 0,
    backgroundColor: "transparent",
    display: "flex",
    justifyContent: "center",
    border: 0,
    "& > *": {
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        height: "100%",
      },
      [theme.breakpoints.up("md")]: {
        width: "60%",
        height: "100%",
      },
    },
  },
}));

const ProfileAvatar = ({ src, alt, onClick, classes }) => {
  const handleClick = () => {
    if (onClick) {
      onClick(src);
    }
  };

  return (
    <button onClick={handleClick} aria-label={alt} className={classes.button}>
      <Avatar src={src} alt={alt} />
    </button>
  );
};

const useDefaultAvatars = (storage) => {
  const [avatars, setAvatars] = useState([]);
  useEffect(function getDefaultAvatarsFromStorage() {
    const storageRef = storage.ref();
    const defaultAvatarsRef = storageRef.child("defaultAvatars");
    const avatarUrls = [];

    defaultAvatarsRef.listAll().then((res) => {
      const itemsLen = res.items.length;
      res.items.forEach(async (itemRef) => {
        const downloadUrl = await itemRef.getDownloadURL();
        avatarUrls.push(downloadUrl);

        if (avatarUrls.length === itemsLen) {
          setAvatars(avatarUrls);
        }
      });
    });
  }, [storage]);
  return avatars;
};

export default ({ storage, onAvatarClick }) => {
  const classes = useStyles();
  const avatars = useDefaultAvatars(storage);

  const renderAvatars = avatars.map((avatar, i) => (
    <ProfileAvatar
      src={avatar}
      key={i}
      alt={`default avatar${i}`}
      onClick={onAvatarClick}
      classes={classes}
    />
  ));

  return <div className={classes.avatarList}>{renderAvatars}</div>;
};
