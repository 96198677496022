 const cartoonQuiz = {
    id: 'cartoonQuiz',
    name: 'Saturday Morning Cartoons',
    linkTo: "/quizzes/cartoonQuiz",
    description: 'How well do you know your Saturday Morning Cartoons?',
    questions: [
        {
            question: "George Jetson's boss on the Jetsons is named?",
            choices: ['Cosmo Spacely', 'Spencer Cogswell', 'Orbitty'],
            answerIdx: 0,    
            furtherInfo: {
            },
        },
        {
            question: "Alvin and the Chipmunks first starred in this show?",
            choices: ['The Chipmunks show', 'The Alvin Show', 'Alvin, Simon, and Theodore'],
            answerIdx: 1,    
            furtherInfo: {
            },
        },
        {
            question: "The show Jonny Quest first aired on ABC in what year?",
            choices: ['1968', '1964', '1962'],
            answerIdx: 1,    
            furtherInfo: {
            },
        },
        {
            question: "The Smurfs lived in a magical world called Le Pays Maudit meaning?",
            choices: ['The blue people', 'The Cursed land', 'The big Mushrooms'],
            answerIdx: 1,    
            furtherInfo: {
            },
        },
        {
            question: "What park did Yogi Bear live?",
            choices: ['Jellystone Park', 'Acne Park', 'Yogi Park'],
            answerIdx: 0,    
            furtherInfo: {
            },
        },
        {
            question: "Huey, Dewey, and Louie's Uncle is?",
            choices: ['Lanchpad McQuack', 'Scrooge McDuck', 'Bubba Duck'],
            answerIdx: 1,    
            furtherInfo: {
            },
        },
        {
            question: "The animated series M.A.S.K is an acronym for?",
            choices: ['Mission American Spies Kommand', 'Mobile Armored Space Kadets', 'Mobile Armored Strike Kommand'],
            answerIdx: 2,    
            furtherInfo: {
            },
        },
    ],

};

export default cartoonQuiz;
