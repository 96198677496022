import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    background: "linear-gradient(180deg, #07BBF4 0%, #3124FB 100%)",
  },
}));

export default ({ children }) => {
  const classes = useStyles();

  return <div className={classes.container}>{children}</div>;
};
