 const fungiQuiz = {
    name: "Is there Fungus Among Us?",
    id: "fungiQuiz",
    linkTo: "/quizzes/fungiQuiz",
    description: `This one's humongous, and all about Fungus!`,
    questions: [
        {
            question: "Scientists who study Fungi are called?",
            choices: ['Toadites', 'Stamets Fun Guys', 'Mycologists'],
            answerIdx: 2,    
            furtherInfo: {
            },
        },
        {
            question: "The top of the mushroom is called?",
            choices: ['Cap', 'Hat', 'Puffball'],
            answerIdx: 0,    
            furtherInfo: {
            },
        },
        {
            question: "Mushrooms are made up of?",
            choices: ['90% Water', '90% Rubber', '90% Salt'],
            answerIdx: 0,    
            furtherInfo: {
            },
        },
        {
            question: "Over 30 species of mushrooms can glow in the dark from a chemical reaction called?",
            choices: ['Bioluminescence', 'Glow juice', 'Indiglow'],
            answerIdx: 0,    
            furtherInfo: {
            },
        },
        {
            question: "Fish and Mushrooms both have?",
            choices: ['Gills', 'Scales', 'Tails'],
            answerIdx: 0,    
            furtherInfo: {
            },
        },
        {
            question: "The largest mushroom in the world is?",
            choices: ['6 feet', '100 yards', '2,000 acres'],
            answerIdx: 2,    
            furtherInfo: {
            },
        },
        {
            question: "Which mushroom has antiviral properties?",
            choices: ['Shiitake', 'Toadstool', 'Amanita Muscaria'],
            answerIdx: 0,    
            furtherInfo: {
            },
        },
        {
            question: "Genetically mushrooms are closer to?",
            choices: ['Plants', 'Animals', 'Humans'],
            answerIdx: 2,    
            furtherInfo: {
            },
        },
        {
            question: "The vegetative part of the mushroom has a mass branching network that is called?",
            choices: ['Biomass', 'Mycelium', 'Fruiting Bodies'],
            answerIdx: 1,    
            furtherInfo: {
            },
        },
        {
            question: "More than _____ of the earth you walk on is mycelium?",
            choices: ['20%', '40%', '80%'],
            answerIdx: 1,    
            furtherInfo: {
            },
        },
    ],

};

export default fungiQuiz;
