 const hongKongHousingCrisis = {
    name: "Hong Kong's Housing Crisis",
    id: 'k3A2CcTd7svMThVUKYS2',
    linkTo: "/quizzes/hongKongHousingCrisis",
    explainerOn: true,
    description: "Hong Kong is known as one of the wealthiest cities in the world. 97 billionaires call the city home, and 1 in 7 residents are millionaires. However, beneath the modern spires and the sparkling facade is a growing crisis.",
    questions: [
        {
            //question 1//
            question: "What is the average price of a home in Hong Kong in USD?",
            choices: ['$1,300', '$13,000', '$130,000', '$1,300,000'],
            answerIdx: 3,    
            furtherInfo: {
                explanation: (
                    `The average price of a home is $1.3 million, making Hong Kong the most expensive housing market in the world.`
                ),
                sources: [
                    {
                        source: "Cheah, J. (2021). Hong Kong's Housing Crisis - USC Economics Review. Retrieved 6 July 2021",
                        url: 'https://usceconreview.com/2020/11/15/hong-kongs-housing-crisis/',
                    },
                ],
            },
        },
        {
            //question 2//
           question: "Approximately how many times more than the median household income is the average housing price in Hong Kong?",
            choices: ['2x', '21x', '210x', '2,100x'],
            answerIdx: 1,    
            furtherInfo: {
                explanation: (
                    `The average price of a home in Hong Kong is approximately 21x the median gross annual household income.`
                ),
                sources: [
                    {
                        source: "Cheah, J. (2021). Hong Kong's Housing Crisis - USC Economics Review. Retrieved 6 July 2021",
                        url: 'https://usceconreview.com/2020/11/15/hong-kongs-housing-crisis/',
                    },
                ],
            },
        },
        {
            //question 3//
            question: "How long is the waiting period for public housing in Hong Kong?",
            choices: ['6.5 months', '1.5 years', '5.5 years', '10.5 years'],
            answerIdx: 2,    
            furtherInfo: {
                explanation: (
                    `The average wait time for public housing in Hong Kong is 5.5 years.`
                ),
                sources: [
                    {
                        source: "Cheah, J. (2021). Hong Kong's Housing Crisis - USC Economics Review. Retrieved 6 July 2021",
                        url: 'https://usceconreview.com/2020/11/15/hong-kongs-housing-crisis/',
                    },
                ],
            },
        },
        {
            //question 4//
            question: "What percent of Hong Kong’s population lives in public housing?",
            choices: ['5%', '25%', '50%', '75%'],
            answerIdx: 2,    
            furtherInfo: {
                explanation: (
                    `50% of Hong Kong’s population lives in public housing.`
                ),
                sources: [
                    {
                        source: "Cheah, J. (2021). Hong Kong's Housing Crisis - USC Economics Review. Retrieved 6 July 2021",
                        url: 'https://usceconreview.com/2020/11/15/hong-kongs-housing-crisis/',
                    },
                ],
            },
        },
        {
            //question 5//
            question: "How many people per square kilometer live in Kwun Tong, Hong Kong’s most congested area?",
            choices: ['57', '570', '5,700', '57,000'],
            answerIdx: 3,    
            furtherInfo: {
                explanation: (
                    `In Hong Kong’s most congested area, Kwun Tong, the population density is approximately 57,000 people per square kilometer.`
                ),
                sources: [
                    {
                        source: "Cheah, J. (2021). Hong Kong's Housing Crisis - USC Economics Review. Retrieved 6 July 2021",
                        url: 'https://usceconreview.com/2020/11/15/hong-kongs-housing-crisis/',
                    },
                ],
            },
        },
        {
            //question 6//
            question: "What is the average price per square foot of housing in Hong Kong in USD?",
            choices: ['$32', '$320', '$3,200', '$32,000'],
            answerIdx: 2,    
            furtherInfo: {
                explanation: (
                    `Housing property in Hong Kong costs approximately $3,200 per square foot.`
                ),
                sources: [
                    {
                        source: "Cheah, J. (2021). Hong Kong's Housing Crisis - USC Economics Review. Retrieved 6 July 2021",
                        url: 'https://usceconreview.com/2020/11/15/hong-kongs-housing-crisis/',
                    },
                ],
            },
        },
        {
            //question 7//
            question: "A spot in a subdivided apartment, often called ‘coffin cubicles’, can cost how much a month in USD?",
            choices: ['$18', '$180', '$1,800', '$18,000'],
            answerIdx: 1,    
            furtherInfo: {
                explanation: (
                    `A spot in a subdivided apartment in Hong Kong can cost $180 a month.`
                ),
                sources: [
                    {
                        source: "Cheah, J. (2021). Hong Kong's Housing Crisis - USC Economics Review. Retrieved 6 July 2021",
                        url: 'https://usceconreview.com/2020/11/15/hong-kongs-housing-crisis/',
                    },
                ],
            },
        },
        {
            //question 8//
            question: "How many square feet are these subdivided apartments?",
            choices: ['20', '200', '2,000', '20,000'],
            answerIdx: 0,    
            furtherInfo: {
                explanation: (
                    `A subdivided apartment, one of the infamous ‘coffin cubicles,’ is no more than 20 sq ft, often stacked one on top of the other.`
                ),
                sources: [
                    {
                        source: "Cheah, J. (2021). Hong Kong's Housing Crisis - USC Economics Review. Retrieved 6 July 2021",
                        url: 'https://usceconreview.com/2020/11/15/hong-kongs-housing-crisis/',
                    },
                ],
            },
        },
        {
            //question 9//
            question: "How many residents of Hong Kong live in subdivided apartments?",
            choices: ['200', '2,000', '20,000', '200,000'],
            answerIdx: 3,    
            furtherInfo: {
                explanation: (
                    `There are at least 200,000 residents living in subdivided apartments in Hong Kong.`
                ),
                sources: [
                    {
                        source: "Cheah, J. (2021). Hong Kong's Housing Crisis - USC Economics Review. Retrieved 6 July 2021",
                        url: 'https://usceconreview.com/2020/11/15/hong-kongs-housing-crisis/',
                    },
                ],
            },
        },
        {
            //question 10//
            question: "The government’s current plan to solve Hong Kong’s housing crisis involves building 4,200 acres of artificial islands that would hold how many apartments?",
            choices: ['2,600', '26,000', '260,000', '2,600,000'],
            answerIdx: 2,    
            furtherInfo: {
                explanation: (
                    `The proposed 4,200 acres of artificial islands would hold 260,000 apartments, 70% of which would be earmarked for public housing.`
                ),
                sources: [
                    {
                        source: 'The Borgen Project',
                        url: 'https://borgenproject.org/hong-kongs-housing-crisis/',
                    }
                ],
            },
        },
    ],

};

export default hongKongHousingCrisis;