const homePrices = {
  name: 'Home Prices',
  id: 'iBH2Ujd93HUQlCcJtesA',
  linkTo: '/quizzes/home-prices',
  explainerOn: true,
  subheading: `Is a home on the range still within our range?`,  
  description: `Owning a home has long been considered part and parcel of the <i>American Dream</i>. But with prices continuing to rise, for many people it's becoming more like a pipe dream. How much does this piece of the American ideal really cost?`,
  questions: [
      {
          //question 1//
          question: "Home price sales have been on the rise. In Jan 1975, the average sale price for a new home in the US was $39,500. In Jan 2021, it was $412,100. How much did the average new home price increase over this period?",
          choices: ['$372', '$3,726', '$37,260', '$372,600'],
          answerIdx: 3,    
          furtherInfo: {
              explanation: (
                  `Between 1975 and 2021, the average sale price for new homes rose by <b>$372,600</b>.<br/><br/>($412,100 - $39,500 = $372,600)`
              ),
              sources: [
                  {
                      source: 'Investopedia',
                      url: 'https://www.investopedia.com/articles/mortages-real-estate/11/the-truth-about-the-real-estate-market.asp',
                  },
              ],
          },
      },
      {
          //question 2//
          question: "Given this increase (from $39,500 to $412,100), by what <i>percentage</i> did the average sale price for a new home increase (from 1975 to 2021) in the US?",
          choices: ['9%', '94%', '943%', '9,430%'],
          answerIdx: 2,    
          furtherInfo: {
              explanation: (
                  `Between 1975 and 2021, the cost of a new home increased by <b>943%</b>.<br/><br/>(412,100/39,500) - 1 = 9.43 = <b>943% increase</b>`
              ),
              sources: [
                  {
                      source: 'Investopedia',
                      url: 'https://www.investopedia.com/articles/mortages-real-estate/11/the-truth-about-the-real-estate-market.asp',
                  },
              ],
          },
      },
      {
          //question 3//
          question: "In the US Northeast, the average sale price for a new home in Jan 1975 was $47,300, and in Oct 2019 it was $526,900. How much did the average sale price for a new home increase?",
          choices: ['$479', '$4,796', '$47,960', '$479,600'],
          answerIdx: 0,    
          furtherInfo: {
              explanation: (
                  `From 1975 to 2019, the average price of a new home in the Northeastern U.S. rose by <b>$479,600</b>.<br/><br/>($526,900 - $47,300 = $479,600)`
              ),
              sources: [
                  {
                      source: 'Investopedia',
                      url: 'https://www.investopedia.com/articles/mortages-real-estate/11/the-truth-about-the-real-estate-market.asp',
                  },
              ],
          },
      },
      {
          //question 4//
          question: "Given this increase (from $47,300 to $526,900), by <i>approximately what percentage</i> did the average sale price for a new home increase (from 1975 to 2021) in the US Northeast?",
          choices: ['10%', '101%', '1,014%', '10,140%'],
          answerIdx: 1,    
          furtherInfo: {
              explanation: (
                  `Between 1975 and 2019, the average cost of a new home in the US Northeast increased by approximately <b>1,000%</b>.<br/>(526,900/47,300) - 1 = 10.14. That's an approximate 1,000% increase.`
              ),
              sources: [
                  {
                      source: 'Investopedia',
                      url: 'https://www.investopedia.com/articles/mortages-real-estate/11/the-truth-about-the-real-estate-market.asp',
                  },
              ],
          },
      },
      {
          //question 5//
          question: "In the US South, the average sale price for a new home in Jan 1975 was $38,000, and in Oct 2019 it was $340,700. <i>Approximately</i> how much did the average sale price for a new home increase?",
          choices: ['$300', '$3,000', '$30,000', '$300,000'],
          answerIdx: 3,    
          furtherInfo: {
              explanation: (
                  `From 1975 to 2019, the average average price of a new home in the US South rose by <i>approximately</i> $300,000.<br/><br/>340,700 - 38,000 = $302,700, or approximately <b>$300,000</b>.`
              ),
              sources: [
                  {
                      source: 'Investopedia',
                      url: 'https://www.investopedia.com/articles/mortages-real-estate/11/the-truth-about-the-real-estate-market.asp',
                  },
              ],
          },
      },
      {
          //question 6//
          question: "Given this increase (from $47,300 to $526,900), by <i>approximately what percentage</i> did the average sale price for a new home increase (from 1975 to 2019) in the US South?",
          choices: ['8%', '80%', '800%', '8,000%'],
          answerIdx: 2,    
          furtherInfo: {
              explanation: (
                  `Between 1975 and 2019, the average cost of a new home in the US South increased by approximately 800%.<br/><br/>(340700/38000) - 1 = 7.97, or approximately <b>800%</b> increase.`
              ),
              sources: [
                  {
                      source: 'Investopedia',
                      url: 'https://www.investopedia.com/articles/mortages-real-estate/11/the-truth-about-the-real-estate-market.asp',
                  },
              ],
          },
      },
      {
          //question 7//
          question: "In the US West: the average sale price for a new home in Jan 1975 was $42,300, and in Oct 2019 it was $477,700. How much did the average sale price for a new home increase?",
          choices: ['$4,354', '$43,540', '$435,400', '$4,354,000'],
          answerIdx: 1,    
          furtherInfo: {
              explanation: (
                  `From 1975 to 2019, the average price of a new home in the US West rose by <b>$435,400</b>.<br/><br/>$477,700 - $42,300 = $435,400`
              ),
              sources: [
                  {
                      source: 'Investopedia',
                      url: 'https://www.investopedia.com/articles/mortages-real-estate/11/the-truth-about-the-real-estate-market.asp',
                  },
              ],
          },
      },
      {
          //question 8//
          question: "Given this increase (from $42,300 to $477,700), by  <i>approximately what percentage</i> did the average sale price for a new home increase (from 1975 to 2019) in the US West?",
          choices: ['1%', '10%', '102%', '1,029%'],
          answerIdx: 0,    
          furtherInfo: {
              explanation: (
                  `Between 1975 and 2019, the average cost of a new home in the US West increased by approximately 1,000%.<br/><br/>(477,700/42,300) - 1 = 10.29 = approximately a <b>1,000%</b> increase.`
              ),
              sources: [
                  {
                      source: 'Investopedia',
                      url: 'https://www.investopedia.com/articles/mortages-real-estate/11/the-truth-about-the-real-estate-market.asp',
                  },
              ],
          },
      },
      {
          //question 9//
          question: "Average home sale prices in the US have increased by 943% from 1975 to 2021. If this trajectory were to continue, how much would a home that sold for $500,000 in 2021 sell for in 2070?",
          choices: ['$5,215', '$52,150', '$521,500', '$5,215,000'],
          answerIdx: 3,    
          furtherInfo: {
              explanation: (
                  `If this trajectory were to continue, a home sold in 2021 for $500,000 would cost $5,215,000 in 2070.<br/><br/>($500,000 * 9.43) + $500,000 = <b>$5,215,000</b>.`
              ),
              sources: [
                  {
                      source: 'Economic Research Federal Reserve Bank of St. Louis',
                      url: 'https://fred.stlouisfed.org/series/ASPNHSUS',
                  },
              ],
          },
      },
  ],
};

export default homePrices;