import React from "react";
import Typography from "@material-ui/core/Typography";

const Score = ({ score, variant = "h3", className }) => {
  return (
    <div className={className}>
      <Typography variant={variant} component="span">
        Score: {score.toLocaleString()}
      </Typography>
    </div>
  );
};

export default Score;
