 const cicadasAndBroodX = {
    name: 'Cicadas and Brood X',
    id: 'mkmzbLHQWBsLtoEPP12r',
    linkTo: "/quizzes/cicadasAndBroodX",
    explainerOn: true,
    description: 'While cicadas are largely harmless, the emergence of billions of insects from the ground can seem like something out of a Biblical plague. Brood X is among the largest populations of periodical cicadas, emerging from underground every seventeen years. While a Brood may contain many different species, they all appear at the same time following the same life cycle and this means a Brood’s population can be enormous. Brood X is underway in 2021.',
    questions: [
        {
            question: "How many distinct populations, known as Broods, of periodical cicadas are currently known?",
            choices: ['4', '15', '50', '150'],
            answerIdx: 1,    
            furtherInfo: {
                explanation: (
                    `There are 15 populations of periodical cicadas currently known, all of which are called a Brood followed by a roman numeral.`
                ),
                sources: [
                    {
                        source: 'Cooley, J. (2017, February 16). General Periodical Cicada Information. Cicadas.',
                        url: 'https://cicadas.uconn.edu/',
                    }
                ],
            },
        },
        {
            question: "How many species of periodical cicadas exist?",
            choices: ['7', '17', '70', '700'],
            answerIdx: 0,    
            furtherInfo: {
                explanation: (
                    `Seven species of periodical cicadas exist.`
                ),
                sources: [
                    {
                        source: 'Cooley, J. (2017, February 16). General Periodical Cicada Information. Cicadas.',
                        url: 'https://cicadas.uconn.edu/',
                    }
                ],
            },
        },
        {
            question: "How many of the periodical cicada species have a 13-year cycle?",
            choices: ['4', '14', '40', '400'],
            answerIdx: 0,    
            furtherInfo: {
                explanation: (
                    `Four of the seven species of periodical cicadas have a 13-year cycle.`
                ),
                sources: [
                    {
                        source: 'Cooley, J. (2017, February 16). General Periodical Cicada Information. Cicadas.',
                        url: 'https://cicadas.uconn.edu/',
                    }
                ],
            },
        },
        {
            question: "How many of the periodical cicada species, like those who compose Brood X, have a 17-year cycle?",
            choices: ['3', '13', '30', '300'],
            answerIdx: 0,    
            furtherInfo: {
                explanation: (
                    `Three of the seven species of periodical cicada have a 17-year cycle.`
                ),
                sources: [
                    {
                        source: 'Cooley, J. (2017, February 16). General Periodical Cicada Information. Cicadas.',
                        url: 'https://cicadas.uconn.edu/',
                    }
                ],
            },
        },
        {
            question: "How many degrees Fahrenheit must the soil reach for cicadas to emerge?",
            choices: ['24', '64', '84', '104'],
            answerIdx: 1,    
            furtherInfo: {
                explanation: (
                    `The soil must reach a temperature of 64 degrees Fahrenheit to trigger a cicada emergence.`
                ),
                sources: [
                    {
                        source: 'Cooley, J. (2017, February 16). General Periodical Cicada Information. Cicadas.',
                        url: 'https://cicadas.uconn.edu/',
                    },
                ],
            },
        },
        {
            question: "A female cicada may lay how many eggs?",
            choices: ['6', '16', '60', '600'],
            answerIdx: 3,    
            furtherInfo: {
                explanation: (
                    `A single female cicada may lay up to 600 eggs.`
                ),
                sources: [
                    {
                        source: 'Cooley, J. (2017, February 16). General Periodical Cicada Information. Cicadas.',
                        url: 'https://cicadas.uconn.edu/',
                    },
                ],
            },
        },
        {
            question: "Periodical cicadas may achieve population densities as high as _________ per acre.",
            choices: ['150', '1,500', '150,000', '1,500,000'],
            answerIdx: 3,    
            furtherInfo: {
                explanation: (
                    `Periodical cicadas may reach a population density of up to 1.5 million per acre.`
                ),
                sources: [
                    {
                        source: 'Cooley, J. (2017, February 16). General Periodical Cicada Information. Cicadas.',
                        url: 'https://cicadas.uconn.edu/',
                    },
                ],
            },
        },
        {
            question: "Across how many states does Brood X appear?",
            choices: ['4', '14', '24', '44'],
            answerIdx: 2,    
            furtherInfo: {
                explanation: (
                    `Brood X appears across fourteen states as well as the District of Columbia.`
                ),
                sources: [
                    {
                        source: 'Cicadas 2021: Billions of Brood X bugs set to emerge in eastern US. (n.d.)',
                        url: 'https://www.msn.com/en-us/news/us/cicadas-2021-billions-of-brood-x-bugs-set-to-emerge-in-eastern-us/ar-AAKiquc',
                    },
                ],
            },
        },
        {
            question: "How many people in the United States will have the opportunity to interact with Brood X in 2021?",
            choices: ['3,500', '35,000', '350,000', '35,000,000'],
            answerIdx: 3,    
            furtherInfo: {
                explanation: (
                    `In 2021 approximately 35 million people will have the opportunity to interact with Brood X.`
                ),
                sources: [
                    {
                        source: 'Cicadas 2021: Billions of Brood X bugs set to emerge in eastern US. (n.d.)',
                        url: 'https://www.msn.com/en-us/news/us/cicadas-2021-billions-of-brood-x-bugs-set-to-emerge-in-eastern-us/ar-AAKiquc',
                    },
                ],
            },
        },
    ],

};

export default cicadasAndBroodX;