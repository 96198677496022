const globalSeedVault = {
    name: 'The Svalbard Global Seed Vault',
    id: 'OyNWlcLT1OLlKMa2eXnr',
    linkTo: "/quizzes/globalSeedVault",
    explainerOn: true,
    description: 'On the remote island of Svalbard, halfway between mainland Norway and the North pole, lies the Svalbard Global Seed Vault. A genebank, deep inside a mountain, where the natural ice and permafrost will ensure its deposits stay frozen even in the face of total power failure, keeping the world’s largest collection of food crop diversity safe.',
    questions: [
        {
            //question 1//
            question: "How many genebanks holding collections of food crops exist worldwide?",
            choices: ['170', '1,700', '17,000', '170,000'],
            answerIdx: 1,    
            furtherInfo: {
                explanation: (
                    `There are 1,700 genebanks with collections of food crops in the world today, but most of them are incredibly vulnerable not only to catastrophe but to the mundane problem of a lack of funding.`
                ),
                sources: [
                    {
                        source: 'Svalbard Global Seed Vault. Crop Trust. (2021, April 14)',
                        url: 'https://www.croptrust.org/our-work/svalbard-global-seed-vault/',
                    },
                ],
            },
        },
        {
            //question 2//
            question: "Approximately how far into the mountain is the Seed Vault, in feet?",
            choices: ['3', '30', '300', '3,000'],
            answerIdx: 2,    
            furtherInfo: {
                explanation: (
                    `Though the entrance is visible rising from the side of the mountain, the actual vault is over 300 feet inside the mountain.`
                ),
                sources: [
                    {
                        source: 'Svalbard Global Seed Vault. Crop Trust. (2021, April 14)',
                        url: 'https://www.croptrust.org/our-work/svalbard-global-seed-vault/',
                    },
                ],
            },
        },
        {
            //question 3//
            question: "How many varieties of crops is the Seed Vault capable of holding?",
            choices: ['4,500', '45,000', '450,000', '4,500,000'],
            answerIdx: 3,    
            furtherInfo: {
                explanation: (
                    `The Seed vault has the capacity to hold 4.5 million varieties, each sample of which contains 500 individual seeds.`
                ),
                sources: [
                    {
                        source: 'Svalbard Global Seed Vault. Crop Trust. (2021, April 14)',
                        url: 'https://www.croptrust.org/our-work/svalbard-global-seed-vault/',
                    },
                ],
            },
        },
        {
            //question 4//
            question: "The Seed Vault is capable of holding how many individual seeds?",
            choices: ['250,000', '25,000,000', '250,000,000', '2,500,000,000'],
            answerIdx: 3,    
            furtherInfo: {
                explanation: (
                    `At five hundred individual seeds per sample, the Seed Vault has a top capacity of approximately 2.5 billion seeds.`
                ),
                sources: [
                    {
                        source: 'Svalbard Global Seed Vault. Crop Trust. (2021, April 14)',
                        url: 'https://www.croptrust.org/our-work/svalbard-global-seed-vault/',
                    },
                ],
            },
        },
        {
            //question 5//
            question: "Approximately how many samples does the Seed Vault currently house?",
            choices: ['100', '1,000', '1,000,000', '1,000,000,000'],
            answerIdx: 2,    
            furtherInfo: {
                explanation: (
                    `The Seed Vault currently holds just over 1 million samples, approximately 22% of its total capacity.`
                ),
                sources: [
                    {
                        source: 'Svalbard Global Seed Vault. Crop Trust. (2021, April 14)',
                        url: 'https://www.croptrust.org/our-work/svalbard-global-seed-vault/',
                    },
                ],
            },
        },
        {
            //question 6//
            question: "At what temperature, in degrees Celsius, does the Seed Vault keep its samples?",
            choices: ['18', '0', '-18', '-180'],
            answerIdx: 2,    
            furtherInfo: {
                explanation: (
                    `Samples at the Seed Vault are kept at -18 degrees celsius to keep the seeds viable for as long as possible.`
                ),
                sources: [
                    {
                        source: 'Svalbard Global Seed Vault. Crop Trust. (2021, April 14)',
                        url: 'https://www.croptrust.org/our-work/svalbard-global-seed-vault/',
                    },
                ],
            },
        },
        {
            //question 7//
            question: "Approximately how many species of crops are currently housed in the Seed Vault?",
            choices: ['600', '6,000', '60,000', '600,000'],
            answerIdx: 1,    
            furtherInfo: {
                explanation: (
                    `The Seed Vault currently holds approximately 6,000 plant species.`
                ),
                sources: [
                    {
                        source: 'Svalbard Global Seed Vault. Crop Trust. (2021, April 14)',
                        url: 'https://www.croptrust.org/our-work/svalbard-global-seed-vault/',
                    },
                ],
            },
        },
        {
            //question 8//
            question: "Approximately how many plant genera are represented in the Seed Vault",
            choices: ['100', '1,100', '11,000', '100,000'],
            answerIdx: 1,    
            furtherInfo: {
                explanation: (
                    `There are approximately 1,100 plant genera currently represented in the Seed Vault.`
                ),
                sources: [
                    {
                        source: 'Svalbard Global Seed Vault. Crop Trust. (2021, April 14)',
                        url: 'https://www.croptrust.org/our-work/svalbard-global-seed-vault/',
                    },
                ],
            },
        },
        {
            //question 9//
            question: "How many depositors have contributed to the Seed Vault to date?",
            choices: ['87', '870', '8,700', '87,000'],
            answerIdx: 0,    
            furtherInfo: {
                explanation: (
                    `To date, 87 depositors have contributed to the Seed Vault.`
                ),
                sources: [
                    {
                        source: 'The Nordic Genetic Resource Centre (NordGen)',
                        url: 'https://seedvault.nordgen.org/',
                    },
                ],
            },
        },
        {
            //question 10//
            question: "How much, in US dollars, did the Seed Vault cost to build?",
            choices: ['$1,000', '$100,000', '$10,000,000', '$1,000,000,000'],
            answerIdx: 2,    
            furtherInfo: {
                explanation: (
                    `The Svalbard Global Seed Vault initially cost $10 million to build. Its recent technical upgrade cost an additional $24 million.`
                ),
                sources: [
                    {
                        source: 'The Nordic Genetic Resource Centre (NordGen)',
                        url: 'https://seedvault.nordgen.org/',
                    },
                ],
            },
        },
    ],
};

export default globalSeedVault;