 const movieQuizThree = {
    id: 'movieQuizThree',
    name: 'Hollywood Movies: Part 3',
    linkTo: "/quizzes/movieQuizThree",
    description: 'The concluding chapter to our big screen trilogy!',
    questions: [
        {
            question: "Guillermo del Toro directed this 2013 movie with massive robots called Jaegers?",
            choices: ['Transformers', 'Voltron', 'Pacific Rim'],
            answerIdx: 2,    
            furtherInfo: {
            },
        },
        {
            question: "What 2001 movie starring Jake Gyllenhall contained time travel and a rabbit named “Frank”?",
            choices: ['Donnie Darko', 'Zodiac', 'Nightcrawler'],
            answerIdx: 0,    
            furtherInfo: {
            },
        },
        {
            question: "What 2001 film directed by Steven Spielberg is set in a futuristic post-climate change society?",
            choices: ['War of the Worlds', 'A.I. Artificial Intelligence', 'Minority Report'],
            answerIdx: 1,    
            furtherInfo: {
            },
        },
        {
            question: "What 2009 film “Moon” was directed by Duncan Jones, who is the son of?",
            choices: ['Tom Jones', 'Tommy Lee Jones', 'David Bowie'],
            answerIdx: 2,    
            furtherInfo: {
            },
        },
        {
            question: "The 2014 film “Edge of Tomorrow” was adapted from what novel?",
            choices: ['All You Need Is Kill', 'A Sound Of Thunder', 'Die Another Day'],
            answerIdx: 0,    
            furtherInfo: {
            },
        },
        {
            question: "What film did Scarlett Johansson play the voice of a phone?",
            choices: ['Lost In Translation', 'Her', 'Under the Skin'],
            answerIdx: 1,    
            furtherInfo: {
            },
        },
        {
            question: "What 2006 film starring Clive Owen is set in a future where infertility is creating human extinction?",
            choices: ['Children Of Men', 'Valerian and the City of a Thousand Planets', 'Gemini Man'],
            answerIdx: 0,    
            furtherInfo: {
            },
        },
        {
            question: "Who sang the song “Goldeneye” for the James Bond film “Goldeneye”?",
            choices: ['Billie Eilish', 'Madonna', 'Tina Turner'],
            answerIdx: 2,    
            furtherInfo: {
            },
        },
        {
            question: `Fill in the blank: “Oh, Jerry, don’t let’s ask for the moon. _________ .”`,
            choices: ['When we have Netflix.', 'Until we land on it.', 'We have the stars.'],
            answerIdx: 2,    
            furtherInfo: {
            },
        },
        {
            question: "What is the highest grossing film of all time?",
            choices: ['Avengers: Endgame', 'Avatar', 'Titanic'],
            answerIdx: 0,    
            furtherInfo: {
            },
        },
    ],

};

export default movieQuizThree;
