const greatApproximations = {
	name: 'Math: Great Approximations',
	id: 'greatApproximations',
	linkTo: "/quizzes/greatApproximations",
	explainerOn: true,
	description: "You don't need to be precise, just get within 20%!",
	questions: [
		{ // Q-1
			question: "What is 2.0234 + 3 1/97? Don't waste time on useless precision, just get it close!",
			writeInAnswer: 5,
			correctRange: [4, 6],
			furtherInfo: {
				explanation: (
`## Explanation

The exact answer is <b>5</b>, but 4 and 6 are within 20%.

No extra credit wasting time on details!`
				)
			}
		},
		{ // Q-2
			question: "A circle has a diameter (distance <i>across</i> the circle) and a circumference (distance <i>around</i> the circle). What's the ratio of circumference / diameter?",
            choices: ['π', '3', '22/7', '3.14159265359', '√10', 'All of the above'],
            answerIdx: 5,
			furtherInfo: {
				explanation: (
`Answer: All of the above.

The math answer is π because that is the name we chose to answer this eternal question.  

All the rest are estimates "within 20%" we said is satisfactory precision in this quiz.

Sometimes less precise answers are good to know because they are quicker to think about.`
				)
			}
		}, 
		{ // Q-3
			question: "You want to use some rope to mark the boundaries of a round playing field that is 7 furlongs across.  How many furlongs of rope do you need to make the circle?",
			choices: [11, 22, 44, 88, 176],
			answerIdx: 1,
			furtherInfo: {
				explanation: (
`## Explanation

Answer: <b>22</b>

You don't even need to know how long a furlong is!

Pi is about 22/7 so it becomes 22/7 * 7, which is 22.

Sometimes knowing fractions makes things easier than decimals!`
				)
			}

		}, 
		{ // Q-4
			question: "What is 10,000 * 100,000?",
			writeInAnswer: 1e9,
			furtherInfo: {
				explanation: (
`## Explanation

Standard: 10,000 * 100,000 = 1,000,000,000

Easier: 10^4 x 10^5 = 10^9

<b>Super Easy</b>: 1E4 * 1E5 = <b>1E9</b>`
				)
			}

		}, 
		{ // Q-5
			question: "What is 172382 * 173483? If you only need to get within +-20%, can you do it in your head quickly?",
			writeInAnswer: 3e10,
			correctRange: [2.4e10, 3.6e10],
			furtherInfo: {
				explanation: (
`## Explanation

What is 172382 * 173483? First, reduce this to 1.7e5 x 1.7e5.

You get something close to <b>3e10</b>, which is close to the real answer of 29,905,346,506.
`
				)
			}

		}, 
		{ // Q-6
			question: "What is 2298742983729 * 23495879384759387798459? Don't work too hard. Which of the answers is closest?",
			choices: ['2298742983729', '23495879384759387798459', '229874298372923495879384759387798459', '2938742983729023495879384759387798459729387429837290234958793847593877984597', '663874298372902349587938475938779845972938742983729023495879384759387798459729387429837290234958793847593877984597'],
			answerIdx: 2,
			furtherInfo: {
				explanation: (
`## Explanation
A 1-inch long number times a 1.5-inch long number will be a 2.5-inch long number!

In this case, a 13-digit number times a 23-digit number will be either a 36 or 37-digit number.

Only one answer choice is close to 36 digits: <b>229874298372923495879384759387798459</b>.

The actual answer is less than a 37 digit number because the product of the mantissas multiplied (2.29 * 2.34) is less than 10.

Therefore, it can't roll over in to another digit.
`
				)
			}

		},
	],

};

export default greatApproximations;
