const dd6makingItHappen = {
  name: "Drawdown 6: Making it Happen",
  id: "aXq2w4iWj7IAjEo1whvL",
  linkTo: "/quizzes/dd6makingItHappen",
  explainerOn: true,
  subheading: `We have all the solutions we need -- Let's make it happen!`,
  description: `We have all the solutions we need to stop climate change. The challenge now is to make it happen. The question is: How?`,
  questions: [
    {
      //question 1//
      question: `What is the most important variable when talking about climate change?`,
      choices: ["Space", "Money", "Time", "Innovation"],
      answerIdx: 2,
      furtherInfo: {
        explanation: `Time is the most important variable when talking about climate change. The best climate change solution is the one we can implement now.`,
        sources: [
          {
            source: "Project Drawdown",
            url:
              "https://drawdown.org/climate-solutions-101/unit-6-how-to-make-it-happen",
          },
        ],
      },
    },
    {
      //question 2//
      question: `What are “accelerators”?`,
      choices: [
        "Factors that help climate solutions move forward quicker and with a wider scope",
        "Factors that increase the rate of climate change",
        "Factors that increase global carbon emissions",
        "Factors that help to slow the rate of climate change",
      ],
      answerIdx: 0,
      furtherInfo: {
        explanation: `Accelerators are factors that help climate solutions move forward quicker and with a wider scope.`,
        sources: [
          {
            source: "Project Drawdown",
            url:
              "https://drawdown.org/climate-solutions-101/unit-6-how-to-make-it-happen",
          },
        ],
      },
    },
    {
      //question 3//
      question: `What is an example of an “accelerator”?`,
      choices: [
        "Shifting capital",
        "Changing policies",
        "Shaping culture",
        "All of the above",
      ],
      answerIdx: 3,
      furtherInfo: {
        explanation: `Shifting capital, changing policies, and shaping culture are all examples of accelerators. Moving money into climate solutions, changing policies in ways that protect the environment and promote climate friendly infrastructure, and shaping our cultural values are all ways to move us toward drawdown faster.`,
        sources: [
          {
            source: "Project Drawdown",
            url:
              "https://drawdown.org/climate-solutions-101/unit-6-how-to-make-it-happen",
          },
        ],
      },
    },
    {
      //question 4//
      question: `The total amount of money saved by implementing climate solutions is _____ times more than their cost.`,
      choices: ["2-3", "4-5", "6-7", "9-10"],
      answerIdx: 1,
      furtherInfo: {
        explanation: `The total amount of money saved by implementing climate solutions is 4 to 5 times more than their cost.`,
        sources: [
          {
            source: "Project Drawdown",
            url:
              "https://drawdown.org/climate-solutions-101/unit-6-how-to-make-it-happen",
          },
        ],
      },
    },
    {
      //question 5//
      question: `What is the cheapest method of producing power?`,
      choices: ["Solar", "Wind", "Coal", "Both A and B"],
      answerIdx: 3,
      furtherInfo: {
        explanation: `Solar and wind are by far the cheapest methods of producing electricity, as well as being carbon free.`,
        sources: [
          {
            source: "Project Drawdown",
            url:
              "https://drawdown.org/climate-solutions-101/unit-6-how-to-make-it-happen",
          },
        ],
      },
    },
    {
      //question 6//
      question: `What is the ‘First Wave’ of climate solutions we should implement?`,
      choices: [
        "Deploying cost-effective solutions",
        "Cultural change",
        "Long-term infrastructure change",
        "Innovative new technologies",
      ],
      answerIdx: 0,
      furtherInfo: {
        explanation: `The ‘First Wave’ we should implement is deploying cost-effective solutions that already exist.`,
        sources: [
          {
            source: "Project Drawdown",
            url:
              "https://drawdown.org/climate-solutions-101/unit-6-how-to-make-it-happen",
          },
        ],
      },
    },
    {
      //question 7//
      question: `What is an example of a cost-effective climate solution available right now?`,
      choices: [
        "Energy-efficiency",
        "Stopping methane leaks",
        "Switching to renewable energy sources",
        "All of the above",
      ],
      answerIdx: 3,
      furtherInfo: {
        explanation: `Increasing energy-efficiency, stopping methane leaks, and switching to renewable energy sources are all examples of cost-effective climate solutions available right now.`,
        sources: [
          {
            source: "Project Drawdown",
            url:
              "https://drawdown.org/climate-solutions-101/unit-6-how-to-make-it-happen",
          },
        ],
      },
    },
    {
      //question 8//
      question: `What is the ‘Second Wave’ of climate solutions we should implement?`,
      choices: [
        "Deploying cost-effective solutions",
        "Cultural change",
        "Long-term infrastructure change",
        "Innovative new technologies",
      ],
      answerIdx: 2,
      furtherInfo: {
        explanation: `The ‘Second Wave’ of climate solutions are long-term infrastructure changes.`,
        sources: [
          {
            source: "Project Drawdown",
            url:
              "https://drawdown.org/climate-solutions-101/unit-6-how-to-make-it-happen",
          },
        ],
      },
    },
    {
      //question 9//
      question: `What is an example of a long-term infrastructure change we will need to start implementing now to see fruition in the future?`,
      choices: [
        "Protecting carbon sinks",
        "Retrofitting old buildings",
        "Changing diets",
        "Information campaigns",
      ],
      answerIdx: 1,
      furtherInfo: {
        explanation: `Retrofitting old buildings is one example of the long-term infrastructure changes we will need. This is also an example of a climate-solution with co-benefits as retrofitting these buildings will create jobs that cannot be automated, accomplished by AI, or shipped overseas.`,
        sources: [
          {
            source: "Project Drawdown",
            url:
              "https://drawdown.org/climate-solutions-101/unit-6-how-to-make-it-happen",
          },
        ],
      },
    },
    {
      //question 10//
      question: `What is the ‘Third Wave’ of climate solutions we should implement?`,
      choices: [
        "Deploying cost-effective solutions",
        "Cultural change",
        "Long-term infrastructure change",
        "Innovative new technologies",
      ],
      answerIdx: 3,
      furtherInfo: {
        explanation: `The ‘Third Wave’ of climate solutions will be innovative new technologies. We don’t know exactly what these technologies will look like, but there are many talented scientists, engineers, and every day people working to make them possible.`,
        sources: [
          {
            source: "Project Drawdown",
            url:
              "https://drawdown.org/climate-solutions-101/unit-6-how-to-make-it-happen",
          },
        ],
      },
    },
  ],
};

export default dd6makingItHappen;
