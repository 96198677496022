import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Slider from "@material-ui/core/Slider";

const mantissaBtnSize = 65;
const eSliderBtnSize = 30;

const useStyles = makeStyles(theme => ({
  mainContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  numpadContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: 300,
    height: 400,
    backgroundColor: '#526794',
    border: '2px solid #ffffff',
    borderRadius: 5,
    padding: 10,
    marginBottom: 15,
  },
  numDisplay: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '35%',
    backgroundColor: '#ffffff',
    padding: 12,
    borderRadius: '10%',
    marginBottom: 10,
  },
  eNotation: {
    fontSize: 36,
    fontWeight: "bold",
    color: "#3f51b5",
  },
  scientificNotation: {
    fontSize: 18,
  },
  numberWordNotation: {
    fontSize: 16,
    color: "#8C728C",
  },
  standardNotation: {
    color: "#3f51b5",
  },
  keypadContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    height: '100%',
    '& > div': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: '32%',
      height: '100%',
      color: '#ffffff',
      borderRadius: '5%',     
    }
  },
  submitButton: {
    width: '32%',
    height: '100%',
    border: '2px solid #ffffff',
    fontSize: '18px',
    cursor: 'pointer',
    transition: 'background-color 0.1s',
    backgroundColor: 'transparent',
    borderRadius: '5%',
    color: "#fff",
    '&:active': {
      backgroundColor: "#5990de", // https://www.color-hex.com/color/63a1f7
    }
  },
  divB: {
    backgroundColor: '#526794',
    border: '2px solid #fff',
  },
  divC: {
    backgroundColor: '#526794',
    border: '2px solid #fff',
  },
  button: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#ffffff',
    borderRadius: '50%',
    margin: 10,
  },
  mantissaButton: {
    width: mantissaBtnSize,
    height: mantissaBtnSize,
    fontSize: '36px',
    border: '2px solid #ffffff',
    transition: 'background-color 0.1s',
    backgroundColor: "transparent",
    cursor: 'pointer',
    "&:active": {
      backgroundColor: '#7c9adf', // https://www.color-hex.com/color/8aacf8
    }
  },
  activeMantissaButton: {
    backgroundColor: '#3f51b5',
  },
  eSliderButton: {
    width: eSliderBtnSize,
    height: eSliderBtnSize,
    fontSize: '20px',
    backgroundColor: 'transparent',
    border: '1px solid #ffffff',   
    cursor: 'pointer',
    transition: 'background-color 0.1s',
    "&:active": {
      backgroundColor: '#7c9adf', // https://www.color-hex.com/color/8aacf8
    }
  },
  eSlider: {
    marginLeft: 15,
    color: "#fff",
    "& > *": {
      color: "#fff",
    }
  }
}));

function valuetext(value) {
  return value;
}

const marks = [
  {
    value: 0, // 0
    label: "0",
  },
  {
    value: 7, // 1
    label: "",
  },
  {
    value: 14, // 2
    label: "",
  },
  {
    value: 21, // 3
    label: "",
  },
  {
    value: 29, // 4
    label: "",
  },
  {
    value: 36, // 5
    label: "5",
  },
  {
    value: 43, // 6
    label: "",
  },
  {
    value: 50, // 7
    label: "",
  },
  {
    value: 57, // 8
    label: "",
  },
  {
    value: 64, // 9
    label: "",
  },
  {
    value: 71, // 10
    label: "10",
  },
  {
    value: 79, // 11
    label: "",
  },
  {
    value: 86, // 12
    label: "",
  },
  {
    value: 93, // 13
    label: "",
  },
  {
    value: 100, // 14
    label: "14",
  },
];

// written to process integers up to 14 digits in length
function numToString(num) {
  const numStr = num.toString();
  const numStrLen = numStr.length;
  const numGroups = ["Thousand", "Million", "Billion", "Trillion"];
  const underThousand = (numStr) => {
    const numTable = {"1": "one", "3": "three", "10": "ten", "30": "thirty", "100": "one hundred", "300": "three hundred"};
    return (parseInt(numStr, 10) >= 1000 | !numTable.hasOwnProperty(numStr))
      ? numStr
      : numTable[numStr];
  }
  let numGroup =
    numStrLen < 4
      ? ""
      : numStrLen < 7
      ? numGroups[0]
      : numStrLen < 10
      ? numGroups[1]
      : numStrLen < 13
      ? numGroups[2]
      : numGroups[3];

  let mantissa =
    numStrLen <= 3
      ? numStr
      : numStrLen % 3 === 0
      ? numStr.slice(0, 3)
      : numStr.slice(0, numStrLen % 3);

  if (numStrLen <= 3) {
    return underThousand(mantissa);
  }
  return mantissa + " " + numGroup;
}

const HalfOrderENumpad = ({onFinish}) => {
    const [mantissa, setMantissa] = useState(1);
    const [sliderVal, setSliderVal] = useState(0);
    const classes = useStyles();
    const sliderStep = 7.14 // Number(parseFloat(100/14).toFixed(2));
    const toExponent = (val) => Math.ceil((val / 100) * 14);

    const onClick1Btn = () => {
      setMantissa(1);
    };

    const onClick3Btn = () => {
      setMantissa(3);
    };

    const onClickTimes3Btn = () => {
      if (mantissa === 1) {
        setMantissa(3);
      } else {
        if (sliderVal < (100 - sliderStep)) {
          setMantissa(1);
          setSliderVal(sliderVal + sliderStep);
        }
      }
    };

    const onClickDividedBy3Btn = () => {
      if (mantissa === 1) {
        if (sliderVal > 0) {
          setMantissa(3);
          setSliderVal(sliderVal - sliderStep);
        }
      } else {
        setMantissa(1);
      }
    };

    const onClickSubmit = () => {
      let exp = toExponent(sliderVal);
      let eValue = mantissa * Math.pow(10, exp);
      return onFinish(eValue);
    };

    const handleChange = (event, newValue) => {
      setSliderVal(newValue);
    };

    return (
      <div className={classes.mainContainer}>
        <div className={classes.numpadContainer}>
          <div className={classes.numDisplay}>

            {/* E Notation */}
            <span className={classes.eNotation}>{mantissa} E {toExponent(sliderVal)}</span>

            {/* scientific notation */}
            <span className={classes.scientificNotation}>
              {mantissa} x 10<sup>{toExponent(sliderVal)}</sup>
            </span>

            {/* word form */}
            <span className={classes.numberWordNotation}>
              {numToString(
                mantissa * Math.pow(10, toExponent(sliderVal))
              )}
            </span>

            {/* numeric form (i.e., standard notation) */}
            <div className={classes.standardNotation}>
              {(
                mantissa * Math.pow(10, toExponent(sliderVal))
              ).toLocaleString()}
            </div>

          </div>
          <div className={classes.keypadContainer}>
            <button
              className={classes.submitButton}
              onClick={onClickSubmit}
            >
              Submit
            </button>

            <div className={classes.divB}>
              <button
                className={clsx(classes.button, classes.mantissaButton, mantissa === 1 && classes.activeMantissaButton)}
                onClick={onClick1Btn}
              >
                1
              </button>
              <button
                className={clsx(classes.button, classes.mantissaButton, mantissa === 3 && classes.activeMantissaButton)}
                onClick={onClick3Btn}
              >
                3
              </button>
            </div>

            <div className={classes.divC}>
              <button
                className={`${classes.button} ${classes.eSliderButton}`}
                onClick={onClickTimes3Btn}
              >
                *3
              </button>

              <Slider
                className={classes.eSlider}
                onChange={handleChange}
                orientation="vertical"
                defaultValue={0}
                value={sliderVal}
                step={sliderStep}
                aria-labelledby="vertical-slider"
                getAriaValueText={valuetext}
                marks={marks}
              />

              <button
                className={`${classes.button} ${classes.eSliderButton}`}
                onClick={onClickDividedBy3Btn}
              >
                /3
              </button>
            </div>

          </div>
        </div>
      </div>
    )
  }
  export default HalfOrderENumpad;