 const mythologyTruthQuiz = {
    id: "mythologyTruthQuiz",
    name: "Mythological Truths",
    linkTo: "/quizzes/mythologyTruthQuiz",
    description: 'Truth behind mythology!',
    questions: [
        {
            question: `What did ancient people misidentify as a bird-headed lion, likely giving rise to tales of the griffin?`,
            choices: ['A lion, likely sick with mange, that attacked settlements along a popular trade route.', 'Two teenage lions suffering from disease and severe jaw injury.', 'The fossils of quetzalcoatlus, an extinct flying pterosaur.', 'The fossils of protoceratops, a kind of dinosaur.'],
            answerIdx: 3,    
            furtherInfo: {
            },
        },
        {
            question: "True or false? Both ‘unicorn’ and ’leviathan’ are probably real animals, exaggerated after travelers attempted to describe these creatures to an unfamiliar audience. (Rhinoceros and crocodile, specifically)",
            choices: ['True', 'False'],
            answerIdx: 0,    
            furtherInfo: {
            },
        },
        {
            question: "The western european dragon may have been inspired by a monitor lizard. If that is true, how is it thought that this lizard-dragon came to breathe fire?",
            choices: ['Artists copied each other’s depiction of the lizard in “St. George and the Dragon,” but since most of them had never seen a monitor lizard errors started creeping into the copies: first a forked tongue, then a forked forked tongue, then a forked-forked-forked-forked tongue that looked like flames, which the next copier mistook for actual flames.', 'The monk who first recorded contact with the lizard was bitten by it, and described the venom as “setting a fire upon my limbs.', 'To scare off potential gold thieves, miners claimed the knocking sounds sometimes heard deep underground were caused by an enormous beast whose breath turned intruders to ash.'],
            answerIdx: 0,    
            furtherInfo: {
            },
        },
        {
            question: "True or false? Aboriginal oral history goes back into the Stone Age. Many of the monsters featured in their stories, like the bunyip, are thought to have been inspired by real animals. (The bunyip was probably Diprotodon, a giant wombat)",
            choices: ['True', 'False'],
            answerIdx: 0,    
            furtherInfo: {
            },
        },
        {
            question: "If tales of the legendary Greek cyclops were fueled by ancients uncovering a strange skeleton, what animal did it probably belong to?",
            choices: ['Neanderthal', 'Dwarf elephant', 'Hippo', 'Gorilla'],
            answerIdx: 1,    
            furtherInfo: {
            },
        },
        {
            question: "What is thought to have started the chupacabra legend?",
            choices: ['U.S. poachers', 'Mange-infected coyotes', 'The movie Species', 'Vampire bats'],
            answerIdx: 2,    
            furtherInfo: {
            },
        },
        {
            question: "The Chinese dragon was probably NOT inspired by which of the following?",
            choices: ['The merging of tribal totems.', 'Lightning.', 'Cloud formations.', 'Dinosaur fossils.'],
            answerIdx: 2,    
            furtherInfo: {
            },
        },
        {
            question: "The werewolf is a worldwide monster. Which of the following WILL NOT turn you into a werewolf, according to legend?",
            choices: ['Enjoy yourself as a particularly sinful woman.', 'Wear a wolf pelt.', 'Be a laborer during the day, and rob graves at night.', 'Drink a tea made from ergot and wolf bones.'],
            answerIdx: 3,    
            furtherInfo: {
            },
        },
        {
            question: "Which legendary monster was probably inspired by the Japanese giant salamander?",
            choices: ['Teke Teke', 'Kappa', 'Gigan', 'Akaname'],
            answerIdx: 1,    
            furtherInfo: {
            },
        },
        {
            question: "Garlic was long considered a powerful ward against creatures such as vampires and werewolves. What probably inspired claims of garlic’s magical protection?",
            choices: ['In real life, garlic actually does have protective qualities. It’s a natural antimicrobial, and can be used to treat wounds as well as reduce cholesterol levels.', 'Garlic has a very potent flavor even in small doses, and would render a person’s taste and even smell unpalatable to supernatural monsters.', 'The segmented lobes in garlic were thought to represent the levels of Heaven.'],
            answerIdx: 0,    
            furtherInfo: {
            },
        },
        
    ],

};

export default mythologyTruthQuiz;
