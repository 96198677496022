import React from "react";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { useStore } from "../contexts/store";
import useSound from 'use-sound';
import { playSound } from "../utils/sound";
import { Typography, Card, CardMedia, CardContent, Tooltip } from "@material-ui/core";
import StarRoundedIcon from '@material-ui/icons/StarRounded';
import { Link } from "react-router-dom";
import SelectSound from "../assets/sounds/select.mp3";
import { sendPageView } from "../utils/google_analytics";

const useStyles = makeStyles(theme => ({
  cardContent: {
    backgroundColor: "#001eff",
    textDecoration: "none",
    textAlign: "center",
    height: 50,
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },
  cardTitle: {
    color: "#fff",
  },
  card: {
    textDecoration: "none",
    position: "relative",
  },
  star: {
    position: "absolute",
    top: 8,
    right: 8,
    color: "#eee600",
    '&:hover': {color: "#efcc00"},
    fontSize: "2.5rem",
    filter: "drop-shadow(0px 0px 1px #001eff)",
  },
}));

const LightTooltip = withStyles((theme) => ({
  arrow: {
    color: theme.palette.common.white,
  },
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

export default function QuizThumbnail({
  title,
  thumbnailSrc,
  linkTo,
  challenge,
  children,
}) {
  const [{soundOn}] = useStore();

  const classes = useStyles();
  const [playSelectSound] = useSound(SelectSound);

  function handleOnClick() {
    playSound(soundOn, playSelectSound)
    sendPageView(linkTo);
  }

  // Aria label on Link component fix accessiblity issue of anchor tag. See the link below
  // https://stackoverflow.com/questions/51683761/how-to-fix-lighthouse-links-do-not-have-a-discernible-name
  return (
    <Link
      to={linkTo || "#"}
      onClick={handleOnClick}
      className={classes.card}
      aria-label={title}
    >
      {challenge && (
        <LightTooltip title="Timed Challenge" arrow>
          <StarRoundedIcon className={classes.star} />
        </LightTooltip>
      )}
      <Card>
        <CardMedia
          image={thumbnailSrc}
          alt={title}
          title={title}
          component="img"
          height="150"
        />
        <CardContent className={classes.cardContent}>
          <Typography variant="h6" component="h1" className={classes.cardTitle}>
            {title}
          </Typography>
          {children}
        </CardContent>
      </Card>
    </Link>
  );
}
