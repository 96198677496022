 const nsfFunding = {
    id: "nsfFunding",
    name: `How much do we fund Science?`,
    linkTo: "/quizzes/nsfFunding",
    description: `How much funding do we provide the National Science Foundation (NSF)?`,
    questions: [
        { // Q1
            question: "What’s the National Science Foundation’s (NSF's) total budget?",
            choices: ['$8 million', '$800 million', '$8 billion', '$800 billion'],
            answerIdx: 2,    
            furtherInfo: {},
        },
        { // Q2
            question: "How much was the U.S.’s total research and development expenditures in 2018, including public and private?",
            choices: ['$180 billion', '$580 billion', '$1.8 trillion', '$5.8 trillion'],
            answerIdx: 1,    
            furtherInfo: {},
        },
        { // Q3
            question: "The National Science Foundation supports what percentage of research conducted by U.S. colleges and researchers?",
            choices: ['2.5%', '25%', '50%', '75%'],
            answerIdx: 1,    
            furtherInfo: {},
        },
        { // Q4
            question: "In 2015, science was what percent of U.S. discretionary government spending?",
            choices: [`3%`, `13%`, `23%`, `33%`],
            answerIdx: 0,    
            furtherInfo: {},
        },
        { // Q5
            question: "In 2019, how much did Apple Inc. spend on research and development (R&D) compared to the U.S. federal government?",
            choices: [`3x less`, `Within a few million $USD of the federal government`, `Within a few billion $USD of the federal government`, `3x more`],
            answerIdx: 3,    
            furtherInfo: {},
        },
        { // Q6
            question: `Which country currently spends the most on research?`,
            choices: ['The U.S.', 'China', 'Japan', 'North Korea'],
            answerIdx: 1,    
            furtherInfo: {},
        },
        { // Q7
            question: `Which country spends the most on research compared to its GDP?`,
            choices: [`Republic of Korea`, `Germany`, `China`, `Brazil`],
            answerIdx: 0,    
            furtherInfo: {},
        },
        { // Q8
            question: `How much does the average U.S. taxpayer contribute to the NSF each year?`,
            choices: ['$5', '$55', '$550', '$5,500'],
            answerIdx: 1,    
            furtherInfo: {},
        },
        { // Q9
            question: `How much do Americans spend: on cosmetic plastic surgery, vs. on the NSF?`,
            choices: ['2x less', 'The same amount', '2x more', '3x more'],
            answerIdx: 2,    
            furtherInfo: {},
        },
        { // Q10
            question: `How much do Americans spend: on advertising, vs. on the NSF?`,
            choices: ['11x more', '31x more', '51x more', '71x more'],
            answerIdx: 3,    
            furtherInfo: {},
        },
    ],
};

export default nsfFunding;
