const internetSpeed = {
  name: 'Internet Speed',
  id: 'oPFGc8qbnGF3SCgJWx6P',
  linkTo: "/quizzes/internetSpeed",
  explainerOn: true,
  subheading: `The internet makes the world go round, but how fast your digital world is revolving depends entirely on the speed of your internet connection.`,
  description: `How fast did this page load? Are you looking at it on your computer or on your phone? We depend on the internet for many vital tasks (like taking online quizzes), but often our access to the internet -- and how fast that internet connection is -- relies on factors beyond our control. Which countries have the fastest internet speeds, and which have the slowest?`,
  questions: [
      {
          //question 1//
          question: `Topping out at approximately 260 megabits per second (Mbps), which country has the highest overall fixed broadband internet speed?`,
          choices: ['The United States', 'Singapore', 'Finland', 'Japan'],
          answerIdx: 1,
          furtherInfo: {
              explanation: (
                  `Singapore currently has the highest available fixed broadband internet speeds, at approximately 260 Mbps.`
              ),
              sources: [
                  {
                      source: 'Visual Capitalist',
                      url: 'https://www.visualcapitalist.com/mapped-the-fastest-and-slowest-internet-speeds-in-the-world/?utm_source=Visual+Capitalist+Infographics+%28All%29&utm_campaign=8aeccf2e4e-EMAIL_COPY_01&utm_medium=email&utm_term=0_31b4d09e8a-8aeccf2e4e-45131207',
                  },
              ],
        },
      },
      {
          //question 2//
          question: `At 3.5 megabits per second, which country has the slowest overall fixed broadband internet speed?`,
          choices: ['Afghanistan', 'The United States', 'Mali', 'Cuba'],
          answerIdx: 3,    
          furtherInfo: {
              explanation: (
                  `Cuba currently has the slowest overall fixed broadband internet speed at 3.5 Mbps.`
              ),
              sources: [
                  {
                      source: 'Visual Capitalist',
                      url: 'https://www.visualcapitalist.com/mapped-the-fastest-and-slowest-internet-speeds-in-the-world/?utm_source=Visual+Capitalist+Infographics+%28All%29&utm_campaign=8aeccf2e4e-EMAIL_COPY_01&utm_medium=email&utm_term=0_31b4d09e8a-8aeccf2e4e-45131207',
                  },
              ],
        },
      },
      {
          //question 3//
          question: `Coming in at number 12, the United States' fixed broadband internet speed is approximately 77% that of Singapore's. This means U.S. internet speed is approximately how many megabits per second?`,
          choices: ['200 Mbps', '160 Mbps', '130 Mbps', '26 Mbps'],
          answerIdx: 0,    
          furtherInfo: {
              explanation: (
                  `At approximately 77% of Singapore's 260 Mbps speed, the United States' overall fixed broadband speed is about 200 megabits per second.`
              ),
              sources: [
                  {
                      source: 'Visual Capitalist',
                      url: 'https://www.visualcapitalist.com/mapped-the-fastest-and-slowest-internet-speeds-in-the-world/?utm_source=Visual+Capitalist+Infographics+%28All%29&utm_campaign=8aeccf2e4e-EMAIL_COPY_01&utm_medium=email&utm_term=0_31b4d09e8a-8aeccf2e4e-45131207',
                  },
              ],
        },
      },
      {
          //question 4//
          question: `Mobile internet speeds are ranked separately from fixed broadband speeds because _____ .`,
          choices: ['Mobile internet is utilized by a different set of devices', 'Mobile internet uses towers to transmit wirelessly', 'Mobile internet tends to be faster overall', 'Mobile internet tends to be slower overall'],
          answerIdx: 1,    
          furtherInfo: {
              explanation: (
                  `Mobile internet speeds are ranked separately from fixed broadband speeds because mobile internet relies on towers to be transmitted wirelessly as opposed to fixed cables.`
              ),
              sources: [
                  {
                      source: 'Visual Capitalist',
                      url: 'https://www.visualcapitalist.com/mapped-the-fastest-and-slowest-internet-speeds-in-the-world/?utm_source=Visual+Capitalist+Infographics+%28All%29&utm_campaign=8aeccf2e4e-EMAIL_COPY_01&utm_medium=email&utm_term=0_31b4d09e8a-8aeccf2e4e-45131207',
                  },
              ],
        },
      },
      {
          //question 5//
          question: `Which country has the highest <i>mobile</i> internet speed?`,
          choices: ['The United Arab Emirates', 'Canada', 'China', 'France'],
          answerIdx: 0,    
          furtherInfo: {
              explanation: (
                  `The United Arab Emirates has the highest mobile internet speeds, at approximately 196 megabits per second.`
              ),
              sources: [
                  {
                      source: 'Visual Capitalist',
                      url: 'https://www.visualcapitalist.com/mapped-the-fastest-and-slowest-internet-speeds-in-the-world/?utm_source=Visual+Capitalist+Infographics+%28All%29&utm_campaign=8aeccf2e4e-EMAIL_COPY_01&utm_medium=email&utm_term=0_31b4d09e8a-8aeccf2e4e-45131207',
                  },
              ],
        },
      },
      {
          //question 6//
          question: `Which country has the slowest mobile internet speed?`,
          choices: ['The United States', 'Afghanistan', 'Mali', 'Cuba'],
          answerIdx: 1,
          furtherInfo: {
              explanation: (
                  `At just 7 megabits per second, Afghanistan has the slowest overall mobile internet speed.`
              ),
              sources: [
                  {
                      source: 'Visual Capitalist',
                      url: 'https://www.visualcapitalist.com/mapped-the-fastest-and-slowest-internet-speeds-in-the-world/?utm_source=Visual+Capitalist+Infographics+%28All%29&utm_campaign=8aeccf2e4e-EMAIL_COPY_01&utm_medium=email&utm_term=0_31b4d09e8a-8aeccf2e4e-45131207',
                  },
              ],
        },
      },
      {
          //question 7//
          question: `How many times faster is Afghanistan's fixed broadband internet speed (approximately 9 Mbps) than its mobile internet speed (7 Mbps)?`,
          choices: ['0.13', '1.3', '2.3', '5.3'],
          answerIdx: 1,    
          furtherInfo: {
              explanation: (
                  `Afghanistan's fixed broadband internet speed is about 9 megabits per second, approximately 1.3 times faster than its mobile internet speed of about 7 Mbps.`
              ),
              sources: [
                  {
                      source: 'Visual Capitalist',
                      url: 'https://www.visualcapitalist.com/mapped-the-fastest-and-slowest-internet-speeds-in-the-world/?utm_source=Visual+Capitalist+Infographics+%28All%29&utm_campaign=8aeccf2e4e-EMAIL_COPY_01&utm_medium=email&utm_term=0_31b4d09e8a-8aeccf2e4e-45131207',
                  },
              ],
        },
      },
      {
          //question 8//
          question: `The United Arab Emirates' mobile internet speed of 196 Mbps is approximately 2 times faster than the United States' mobile internet speed. About how fast is the United States' mobile internet?`,
          choices: ['96 Mbps', '66 Mbps', '36 Mbps', '16 Mbps'],
          answerIdx: 0,    
          furtherInfo: {
              explanation: (
                  `At approximately 96 megabits per second, the United States' mobile internet speed is about half that of the United Arab Emirates.`
              ),
              sources: [
                  {
                      source: 'Visual Capitalist',
                      url: 'https://www.visualcapitalist.com/mapped-the-fastest-and-slowest-internet-speeds-in-the-world/?utm_source=Visual+Capitalist+Infographics+%28All%29&utm_campaign=8aeccf2e4e-EMAIL_COPY_01&utm_medium=email&utm_term=0_31b4d09e8a-8aeccf2e4e-45131207',
                  },
              ],
        },
      },
      {
          //question 9//
          question: `Infrastructure plays a large role in how fast, or slow, your country's internet speed is. What key factor contributes to Singapore's high fixed broadband internet speed?`,
          choices: ['Government investment', "Singapore's small size", 'Its connection to submarine cables', 'All of the Above'],
          answerIdx: 3,    
          furtherInfo: {
              explanation: (
                  `Several factors contribute to Singapore's fast internet speed: its connection to the submarine fiber-optic cables that are responsible for transmitting 97% of the world's data; its small size (the entire country is about the size of Austin, Texas), which makes it less costly to install and maintain digital infrastructure across the nation; and the Singaporean government's strategic investment in digital infrastructure.`
              ),
              sources: [
                  {
                      source: 'Visual Capitalist',
                      url: 'https://www.visualcapitalist.com/mapped-the-fastest-and-slowest-internet-speeds-in-the-world/?utm_source=Visual+Capitalist+Infographics+%28All%29&utm_campaign=8aeccf2e4e-EMAIL_COPY_01&utm_medium=email&utm_term=0_31b4d09e8a-8aeccf2e4e-45131207',
                  },
              ],
        },
      },
      {
          //question 10//
          question: `What is a key factor contributing to Cuba's slow internet speed?`,
          choices: ['Limited government funding', 'Cuba is connected to only one submarine cable', 'Both A and B', 'Its large size'],
          answerIdx: 2,    
          furtherInfo: {
              explanation: (
                  `Two of the main factors contributing to Cuba's slow internet speed are: the lack of government funding; and the fact that while most countries are connected to several of the submarine fiber-optic cables that transmit the world's data, Cuba is only connected to one.`
              ),
              sources: [
                  {
                      source: 'Visual Capitalist',
                      url: 'https://www.visualcapitalist.com/mapped-the-fastest-and-slowest-internet-speeds-in-the-world/?utm_source=Visual+Capitalist+Infographics+%28All%29&utm_campaign=8aeccf2e4e-EMAIL_COPY_01&utm_medium=email&utm_term=0_31b4d09e8a-8aeccf2e4e-45131207',
                  },
              ],
          },
      },
  ],
};

export default internetSpeed;
