import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Countdown from "./Countdown";
import { sendViewQuiz, sendPlayQuiz } from "../utils/google_analytics";
import ReactMarkdown from "react-markdown";
import "../assets/designs/preview.css";
import { NavbarData as Navbar } from "./Navbar";
import BottomNav from "./BottomNav";
import useWindowDimensions from "../utils/useWindowDimensions";
import { getTopScoreForQuizForUser } from "../firestore/quiz";
import StarRoundedIcon from "@material-ui/icons/StarRounded";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useDeviceDetect from "../hooks/useDeviceDetect";
import FeedbackButton from "./FeedbackButton";

const PreviewData = (props) => {
  const { firestore, user, quizId, ...previewProps } = props;
  const [bestScore, setBestScore] = useState("-");
  const [gettingBestScore, setGettingBestScore] = useState(false);
  const [gotBestScore, setGotBestScore] = useState(false);

  useEffect(function getBestScoreForPreview() {
    const getBestScore = async () => {
      const { uid } = user.authUser;
      setGettingBestScore(true);
      const docSnapshot = await getTopScoreForQuizForUser(
        firestore,
        quizId,
        uid
      );

      if (docSnapshot.exists) {
        const { score } = docSnapshot.data();
        setBestScore(score);
      }

      setGotBestScore(true);
      setGettingBestScore(false);
    };

    if (
      !gettingBestScore &&
      !gotBestScore &&
      user.authUser &&
      user.authUser.uid
    ) {
      getBestScore();
    }
  });

  return <Preview bestScore={bestScore} {...previewProps} />;
};

function Preview(props) {
  const [showCountdown, setShowCountdown] = React.useState(false);
  const { width } = useWindowDimensions();
  const {isMobile} = useDeviceDetect()
  const {
    description,
    onLaunch,
    subDescription,
    numberOfQuestions,
    countdownTime,
    timeLimit,
    name,
    linkTo,
    bestScore = "0000",
    isChallengeQuiz,
  } = props;

  useEffect(function registerQuizView() {
    sendViewQuiz(linkTo);
  });

  const handleLaunchClick = () => {
    sendPlayQuiz(name);
    if (countdownTime) {
      setShowCountdown(true);
    } else {
      onLaunch();
    }
  };

  const renderScore = (score) =>
    score === "-" ? score : score.toLocaleString();

  return showCountdown ? (
    <Countdown onCountdownFinish={onLaunch} countdownTime={countdownTime} />
  ) : (
    <div>
      <FeedbackButton />
      <Navbar filter={false} showArrow={true} fromPreview={true}/>
      <div className="quiz-preview-container">
        <div className="quiz-preview-main">
          <span className="quiz-preview-main-title">
            {" "}
            <ReactMarkdown source={name} />
          </span>
          <div className="quiz-preview-main-instructions">
            <p dangerouslySetInnerHTML={{ __html: description }}></p>
            <ReactMarkdown source={subDescription} />
            {timeLimit ? (
              // <div style={{display: "flex", justifyContent: "center"}}>
              <div>
                {isChallengeQuiz && <StarRoundedIcon />}
                <ReactMarkdown
                  source={`Challenge Round: **${timeLimit}** seconds`}
                />
                <FontAwesomeIcon icon="clock" style={{marginLeft: 10, color: "white", fontSize: "1.4rem", marginTop: 2}} />
              </div>
            ) : (
              <ReactMarkdown
                source={`Number of questions: **${numberOfQuestions}**`}
              />
            )}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <div className="quiz-preview-main-bestscore">
              <span style={{ fontSize: "30px" }}>{renderScore(bestScore)}</span>
              <span>Your Top Score</span>
            </div>
            <button
              onClick={handleLaunchClick}
              className="quiz-preview-main-buttons quiz-preview-main-launchbutton"
            >
              Play
            </button>
            <Link
              to="/quizzes"
              className="quiz-preview-main-buttons quiz-preview-main-homebutton"
            >
              Exit
            </Link>
          </div>
        </div>
      </div>
      {(width <= 1024 && isMobile) ? <BottomNav /> : ""}
    </div>
  );
}

export { PreviewData };
export default Preview;

Preview.propTypes = {
  description: PropTypes.string,
  onLaunch: PropTypes.func,
};
