const theWorthOfCopper = { 
  name: "The Worth of Copper", 
  id: '97J11L2mVWRCrvLccpdB', 
  linkTo: "/quizzes/theWorthOfCopper", 
  explainerOn: true, 
  subheading: `It's more than small change: copper means faster smartphones and cleaner energy.`,
  description: `Though it has historically been synonymous with cheap and humble materials, copper is on the rise. It may not be long before this small change mineral is worth its weight in gold.`, 
  questions: [
      { // Q-1
          question: "Humans have been using copper for approximately how many years?",
          choices: ['1,000', '10,000', '100,000', '1,000,000'],
          answerIdx: 1,
          furtherInfo: {
              explanation: (
                  `Copper was the first metal to be used by humans, around 10,000 years ago, and continues to be one of the most versatile and sought-after metals today.`
              ),
              sources: [
                  {
                      source: "Visual Capitalist",
                      url: 'https://www.visualcapitalist.com/visualizing-coppers-role-in-a-low-carbon-economy/?utm_source=Visual+Capitalist+Infographics+%28All%29&utm_campaign=3aa0814979-Mailchimp_Oct_10_2021_SD&utm_medium=email&utm_term=0_31b4d09e8a-3aa0814979-45131207',
                  },
              ],
          },
      },
      { // Q-2
          question: "What property of copper makes it such a useful and sought after metal?",
          choices: ['Ductility', 'Thermal Efficiency', 'Conductivity', 'All of the Above'],
          answerIdx: 3,    
          furtherInfo: {
              explanation: (
                  `Copper has many notable properties, including ductility, meaning that it is easily molded into a variety of shapes, thermal efficiency, and superior conductivity.`
              ),
              sources: [
                {
                  source: "Visual Capitalist",
                  url: 'https://www.visualcapitalist.com/visualizing-coppers-role-in-a-low-carbon-economy/?utm_source=Visual+Capitalist+Infographics+%28All%29&utm_campaign=3aa0814979-Mailchimp_Oct_10_2021_SD&utm_medium=email&utm_term=0_31b4d09e8a-3aa0814979-45131207',
                },
              ],
          },
      },
      { // Q-3 
        question: "What is copper needed for that makes a critical resource?",
        choices: ['Pennies', 'Electro-plating', 'Batteries', 'Insulation'],
        answerIdx: 2,    
        furtherInfo: {
            explanation: (
                `Copper is a critical resource in part for its role in creating energy storage, or batteries.`
            ),
            sources: [
              {
                source: "Visual Capitalist",
                url: 'https://www.visualcapitalist.com/visualizing-coppers-role-in-a-low-carbon-economy/?utm_source=Visual+Capitalist+Infographics+%28All%29&utm_campaign=3aa0814979-Mailchimp_Oct_10_2021_SD&utm_medium=email&utm_term=0_31b4d09e8a-3aa0814979-45131207',
              },
            ],
        },
      },
      { // Q-4
        question: "Copper is the most needed mineral for clean energy tech.",
        choices: ['True', 'False'],
        answerIdx: 0,    
        furtherInfo: {
            explanation: (
                `This is true. Copper is needed for almost all clean energy technology to function.`
            ),
            sources: [
              {
                source: "Visual Capitalist",
                url: 'https://www.visualcapitalist.com/visualizing-coppers-role-in-a-low-carbon-economy/?utm_source=Visual+Capitalist+Infographics+%28All%29&utm_campaign=3aa0814979-Mailchimp_Oct_10_2021_SD&utm_medium=email&utm_term=0_31b4d09e8a-3aa0814979-45131207',
              },
            ],
        },
      },
      { // Q-5
        question: "Electric vehicles (EVs) use how many more times copper than a standard internal combustion engine vehicle?",
        choices: ['1.5x', '2x', '4x', '8x'],
        answerIdx: 2,    
        furtherInfo: {
            explanation: (
                `EVs use approximately 4 times as much copper as combustion engine vehicles. A fully electric vehicle can use up to a mile of copper wiring alone.`
            ),
            sources: [
              {
                source: "Visual Capitalist",
                url: 'https://www.visualcapitalist.com/visualizing-coppers-role-in-a-low-carbon-economy/?utm_source=Visual+Capitalist+Infographics+%28All%29&utm_campaign=3aa0814979-Mailchimp_Oct_10_2021_SD&utm_medium=email&utm_term=0_31b4d09e8a-3aa0814979-45131207',
              },
            ],
        },
      },
      { // Q-6
        question: "Renewable energy systems can use up to how many times more copper than traditional energy systems?",
        choices: ['24x', '12x', '6x', '3x'],
        answerIdx: 1,    
        furtherInfo: {
            explanation: (
                `Clean energy systems, like wind and solar power, can use up to 12 times more copper than traditional energy systems.`
            ),
            sources: [
              {
                source: "Visual Capitalist",
                url: 'https://www.visualcapitalist.com/visualizing-coppers-role-in-a-low-carbon-economy/?utm_source=Visual+Capitalist+Infographics+%28All%29&utm_campaign=3aa0814979-Mailchimp_Oct_10_2021_SD&utm_medium=email&utm_term=0_31b4d09e8a-3aa0814979-45131207',
              },
            ],
        },
      },
      { // Q-7
        question: "What other technology is copper also necessary for?",
        choices: ['5G Networks', 'Consumer Goods', 'Coinage', 'Pharmaceuticals'],
        answerIdx: 0,    
        furtherInfo: {
            explanation: (
                `Copper is also in high demand for the creation and maintenance of 5G networks, because of its use in several components of 5G devices.`
            ),
            sources: [
              {
                source: "Visual Capitalist",
                url: 'https://www.visualcapitalist.com/visualizing-coppers-role-in-a-low-carbon-economy/?utm_source=Visual+Capitalist+Infographics+%28All%29&utm_campaign=3aa0814979-Mailchimp_Oct_10_2021_SD&utm_medium=email&utm_term=0_31b4d09e8a-3aa0814979-45131207',
              },
            ],
        },
      },
      { // Q-8
        question: "How many tons of copper will we need for low-carbon technologies by 2030?",
        choices: ['5,400', '54,000', '540,000', '5,400,000'],
        answerIdx: 3,    
        furtherInfo: {
            explanation: (
                `Goldman-Sachs estimates that the low-carbon technology demand will reach 5.4 million tons by 2030. For comparison, copper demand in 2021 was around 1 million tons.`
            ),
            sources: [
              {
                source: "Visual Capitalist",
                url: 'https://www.visualcapitalist.com/visualizing-coppers-role-in-a-low-carbon-economy/?utm_source=Visual+Capitalist+Infographics+%28All%29&utm_campaign=3aa0814979-Mailchimp_Oct_10_2021_SD&utm_medium=email&utm_term=0_31b4d09e8a-3aa0814979-45131207',
              },
            ],
        },
      },
      { // Q-9
        question: "Copper is also_____.",
        choices: ['Stain-resistant', 'Antimicrobial', 'Non-recyclable', 'Brittle'],
        answerIdx: 1,    
        furtherInfo: {
            explanation: (
                `Another notable property of copper is that it is naturally antimicrobial. Unlike glass, plastic, and stainless steel, many disease-producing organisms die within hours on copper surfaces; including Covid-19.`
            ),
            sources: [
              {
                source: "Geology.com",
                url: 'https://geology.com/usgs/uses-of-copper/',
              },
            ],
        },
      },
      { // Q-10
        question: "Where is most copper mined?",
        choices: ['South Africa', 'The United States', 'Chile', 'China'],
        answerIdx: 2,    
        furtherInfo: {
            explanation: (
                `In 2000 Chile overtook the US as the world's lead copper producer. Today Chile, and other Andean regions, continue to produce the most copper.`
            ),
            sources: [
              {
                source: "Geology.com",
                url: 'https://geology.com/usgs/uses-of-copper/',
              },
            ],
        },
      },
  ],

};

export default theWorthOfCopper;