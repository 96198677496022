const drugOrPlanet = {
    name: 'Prescription Drug or Fictional Planet?',
    id: 'g5OvrWBSyZhPCyLG8inV',
    linkTo: "/quizzes/drugOrPlanet",
    explainerOn: true,
    subheading: `Both seem like a selection of alphabet soup, but can you tell if this bizarre name refers to a fictional planet or a prescription drug?`,
    description: `The FDA recently approved Pfizer's Covid-19 vaccine under the name Comirnaty. Astonishingly, this name was not randomly chosen but rather the end product of a very expensive marketing process. Still, it's not the weirdest name we've seen come out of the pharmaceutical industry. We looked at other recently FDA-approved drugs, and in honor of Comirnaty we want to know: can you tell the difference between the names of prescription drugs and fictional planets?`,
    questions: [
        {
            //question 1//
            question: `Vaxneuvance`,
            choices: ['Prescription Drug', 'Fictional Planet'],
            answerIdx: 0,
            furtherInfo: {
                explanation: (
                    `Prescription Drug! Vaxneuvance, also known as pneumococcal 15-valent conjugate vaccine, is a vaccine produced by Merck for the prevention of disease caused by Streptococcus pneumoniae in adults.`
                ),
                sources: [
                    {
                        source: 'Drugs.com',
                        url: 'https://www.drugs.com/vaxneuvance.html',
                    },
                ],
            },
        },
        {
            //question 2//
            question: `Gallifrey`,
            choices: ['Prescription Drug', 'Fictional Planet'],
            answerIdx: 1,
            furtherInfo: {
                explanation: (
                    `Fictional Planet! Gallifrey is the orange-skyed home of Time Lords in the classic British science-fiction show Dr. Who.`
                ),
                sources: [
                    {
                        source: 'Star Name Registry',
                        url: 'https://star-name-registry.com/blog/item/top-15-fictional-planets-in-science-fiction',
                    },
                ],
            },
        },
        {
            //question 3//
            question: `Solaris`,
            choices: ['Prescription Drug', 'Fictional Planet'],
            answerIdx: 1,
            furtherInfo: {
                explanation: (
                    `Fictional Planet! Solaris is the titular oceanic planet of the novel by Polish writer Stanisław Lem. Not to spoil the book or its two film adaptations, but we highly suggest a course of therapy before scheduling your visit to Solaris.`
                ),
                sources: [
                    {
                        source: 'Good Reads',
                        url: 'https://www.goodreads.com/book/show/95558.Solaris',
                    },
                ],
            },
        },
        {
            //question 4//
            question: `Astepro`,
            choices: ['Prescription Drug', 'Fictional Planet'],
            answerIdx: 0,
            furtherInfo: {
                explanation: (
                    `Prescription Drug! Astepro, or azelastine, is a nasal antihistamine spray produced by Bayer for the treatment of seasonal allergies.`
                ),
                sources: [
                    {
                        source: 'Drugs.com',
                        url: 'https://www.drugs.com/history/astepro-allergy.html',
                    },
                ],
            },
        },
        {
            //question 5//
            question: `Arda`,
            choices: ['Prescription Drug', 'Fictional Planet'],
            answerIdx: 1,
            furtherInfo: {
                explanation: (
                    `Fictional Planet! Arda is the name J. R. R. Tolkien gave the planet on which Middle Earth resides, and where the events of  The Hobbit and The Lord of the Rings trilogy take place. If you really want to visit the Shire, however, New Zealand might be closer.`
                ),
                sources: [
                    {
                        source: 'Lord of the Rings Wiki',
                        url: 'https://lotr.fandom.com/wiki/Arda',
                    },
                ],
            },
        },
        {
            //question 6//
            question: `Skytrofa`,
            choices: ['Prescription Drug', 'Fictional Planet'],
            answerIdx: 0,
            furtherInfo: {
                explanation: (
                    `Prescription Drug! Skytrofa, or lonapegsomatropin-tcgd, is a human growth hormone produced by Ascendis Pharma for the treatment of pediatric human growth hormone deficiency.`
                ),
                sources: [
                    {
                        source: 'Drugs.com',
                        url: 'https://www.drugs.com/history/skytrofa.html',
                    },
                ],
            },
        },
        {
            //question 7//
            question: `Caprica`,
            choices: ['Prescription Drug', 'Fictional Planet'],
            answerIdx: 1,
            furtherInfo: {
                explanation: (
                    `Fictional Planet! As the capital of the twelve colonies of Kobol, Caprica is the seat of power in Battlestar Galactica. Described as a blue-green planet with a perfect climate, this planet of elites was so interesting to the series writers it got its own spin-off.`
                ),
                sources: [
                    {
                        source: 'Battlestar Galactica Wiki',
                        url: 'https://galactica.fandom.com/wiki/Caprica',
                    },
                ],
            },
        },
        {
            //question 8//
            question: `Rezurock`,
            choices: ['Prescription Drug', 'Fictional Planet'],
            answerIdx: 0,
            furtherInfo: {
                explanation: (
                    `Explanation: Prescription Drug! Rezurock, or belumosudil, is a tablet produced by Kadmon Holdings for the treatment of chronic graft-versus-host disease in patients 12 years or older.`
                ),
                sources: [
                    {
                        source: 'Drugs.com',
                        url: 'https://www.drugs.com/rezurock.html',
                    },
                ],
            },
        },
        {
            //question 9//
            question: `Korsuva`,
            choices: ['Prescription Drug', 'Fictional Planet'],
            answerIdx: 0,
            furtherInfo: {
                explanation: (
                    `Prescription Drug! Korsuva, or difelikefalin, is an injection produced by Cara Therapeutics. It treats chronic kidney disease-associated pruritus, which is a debilitating, systemic itchiness often experienced by patients undergoing dialysis.`
                ),
                sources: [
                    {
                        source: 'Drugs.com',
                        url: 'https://www.drugs.com/history/korsuva.html',
                    },
                ],
            },
        },
        {
            //question 10//
            question: `Beylix`,
            choices: ['Prescription Drug', 'Fictional Planet'],
            answerIdx: 1,
            furtherInfo: {
                explanation: (
                    `Fictional Planet! Beylix is a small planet from the Firefly universe. Its main claim to fame, as far as viewers are concerned anyway, is that Jayne Cobb once held up a bank there.`
                ),
                sources: [
                    {
                        source: 'Firefly Wiki',
                        url: 'https://firefly.fandom.com/wiki/Beylix',
                    },
                ],
            },
        },
    ],
};

export default drugOrPlanet;
