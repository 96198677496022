 const forestQuiz = {
    name: 'Forests: If they fall, does anyone hear it?',
    id: 'YDRfBwnaegpTcavXRej9',
    linkTo: "/quizzes/forestQuiz",
    explainerOn: true,
    description: "Forests are areas of land dominated by trees. But what do they actually do, and would you miss them if they were gone? How much do you know about the most predominant terrestrial ecosystem on Earth?",
    questions: [
        {
            //question 1//
            question: "How much carbon do forests sequester each year?",
            choices: ['7.6 billion metric tons', '5.4 billion metric tons', '8.2 billion metric tons', '6.5 billion metric tons'],
            answerIdx: 0,    
            furtherInfo: {
                explanation: (
                    `Each year forests sequester 7.6 billion metric tons of carbon.`
                ),
                sources: [
                    {
                        source: 'World Resources Institute',
                        url: 'https://www.wri.org/blog/2021/01/forests-carbon-emissions-sink-flux#:~:text=In%20other%20words%2C%20forests%20provide,the%20United%20States%20emits%20annually.',
                    },
                ],
            },
        },
        {
            //question 2//
            question: "Approximately how many recreational visits are made to protected forest areas each year?",
            choices: ['10 billion', '5 billion', '8 billion', '3 billion'],
            answerIdx: 2,    
            furtherInfo: {
                explanation: (
                    `Approximately 8 billion recreational visits are made to forested areas every year.`
                ),
                sources: [
                    {
                        source: 'Food and Agriculture Organization of the United Nations',
                        url: 'http://www.fao.org/state-of-forests/en/',
                    },
                ],
            },
        },
        {
            //question 3//
            question: "What was the global rate of deforestation between 2015-2020?",
            choices: ['7 million hectares/year', '16 million hectares/year', '21 million hectares/year', '10 million hectares/year'],
            answerIdx: 3,    
            furtherInfo: {
                explanation: (
                    `Between 2015 and 2020 the global rate of deforestation was 10 million hectares/year.`
                ),
                sources: [
                    {
                        source: 'Our World in Data',
                        url: 'https://ourworldindata.org/deforestation#:~:text=Globally%20we%20deforest%20around%20ten,deforestation%20occurs%20in%20the%20tropics.',
                    },
                ],
            },
        },
        {
            //question 4//
            question: "How much land area is covered by forests as of 2020?",
            choices: ['22%', '43%', '31%', '19%'],
            answerIdx: 2,    
            furtherInfo: {
                explanation: (
                    `As of 2020, 31% of the world’s land area is covered in forest.`
                ),
                sources: [
                    {
                        source: 'Food and Agriculture Organization of the United Nations',
                        url: 'http://www.fao.org/state-of-forests/en/',
                    },
                ],
            },
        },
        {
            //question 5//
            question: "Approximately how many hectares of the Earth’s forests are protected?",
            choices: ['1.3 billion', '0.7 billion', '0.2 billion', '2.1 billion'],
            answerIdx: 1,    
            furtherInfo: {
                explanation: (
                    `0.7 billion out of 4.06 billion hectares total are protected. About 18%`
                ),
                sources: [
                    {
                        source: 'Food and Agriculture Organization of the United Nations',
                        url: 'http://www.fao.org/state-of-forests/en/',
                    },
                ],
            },
        },
        {
            //question 6//
            question: "The Amazon is the largest forest in the world, approximately how much land does it cover (in hectares)?",
            choices: ['550 million', '230 million', '470 million', '620 million'],
            answerIdx: 0,    
            furtherInfo: {
                explanation: (
                    `The largest forest in the world, the Amazon, covers 550 million hectares, which is over 230 million more hectares than the Congo Rainforest, which is the second largest forest.`
                ),
                sources: [
                    {
                        source: 'Science Magazine',
                        url: 'https://www.sciencemag.org/news/2015/03/amazon-rainforest-ability-soak-carbon-dioxide-falling',
                    },
                ],
            },
        },
        {
            //question 7//
            question: "What percentage of total (known) species rely on forest ecosystems?",
            choices: ['65%', '80%', '91%', '72%'],
            answerIdx: 1,    
            furtherInfo: {
                explanation: (
                    `80% of the known species on Earth today rely on forest ecosystems.`
                ),
                sources: [
                    {
                        source: 'World Wildlife Organization',
                        url: 'https://www.worldwildlife.org/habitats/forest-habitat#:~:text=Unique%20Biodiversity,to%20more%20than%201%2C000%20species.',
                    },
                ],
            },
        },
        {
            //question 8//
            question: "On average, how much oxygen does one tree produce each year?",
            choices: ['120 pounds', '330 pounds', '260 pounds', '419 pounds'],
            answerIdx: 2,    
            furtherInfo: {
                explanation: (
                    `A single tree produces 260 pound of oxygen each year on average.`
                ),
                sources: [
                    {
                        source: 'ThoughtCo',
                        url: 'https://www.thoughtco.com/how-much-oxygen-does-one-tree-produce-606785',
                    },
                ],
            },
        },
        {
            //question 9//
            question: "How much forest have we lost since 1990 (hectares)?",
            choices: ['420 million', '750 million', '260 million', '570 million'],
            answerIdx: 0,    
            furtherInfo: {
                explanation: (
                    `Since 1990 we have lost 420 million hectares of forest.`
                ),
                sources: [
                    {
                        source: 'Food and Agriculture Organization of the United Nations',
                        url: 'http://www.fao.org/state-of-forests/en/',
                    },
                ],
            },
        },
        {
            //question 10//
            question: "More than half of all forest area can be found in _____ countries.",
            choices: ['5', '3', '9', '2'],
            answerIdx: 0,    
            furtherInfo: {
                explanation: (
                    `More than half of all forest areas can be found in 5 countries.`
                ),
                sources: [
                    {
                        source: 'World Economic Forum',
                        url: 'https://www.weforum.org/agenda/2020/06/un-deforestation-reforestation-climate-change/#:~:text=More%20than%20half%20(54%25),needs%20can%20be%20a%20challenge.',
                    },
                ],
            },
        },
    ],

};

export default forestQuiz;