const autism = {
    name: 'Autism',  
    id: 'PRqbatb5NUk9xjr3R2VW',
    linkTo: "/quizzes/autism",
    explainerOn: true,
    description: "With increasing media representation and a wealth of scientific information being shared, autism awareness is at an all time high. However, a lot of misconceptions and misinformation remain. How informed are you about autism?",
    questions: [
        { // 1
            question: "Vaccines can cause autism in children.",
            choices: ['A.) True', 'B.) False', 'C.) Really? This has been debunked so many times it’s not even funny.', 'D.) Both B and C'],
            answerIdx: 3,
            furtherInfo: {
                explanation: "Both B and C are true. Vaccines <i>do not</i> cause autism, and there is a very, very large body of proof debunking this false claim.",
                sources: [
                    {
                        source: 'Scientific American',
                        url: 'https://www.scientificamerican.com/article/how-big-data-are-unlocking-the-mysteries-of-autism/',
                    }
                ],
            },
        },
        { // 2
            question: "Autism is:",
            choices: [
                'A GENETIC condition that causes challenges with social interactions, restricted interests, and/or repetitive behaviors.',
                'An ACQUIRED condition that causes challenges with social interactions, restricted interests, and/or repetitive behaviors.',
                'A spectrum of hundreds of conditions, but individuals share core challenges with social interactions, restricted interests, and/or repetitive behaviors.',
                'None of the above.'
            ],
            answerIdx: 2,    
            furtherInfo: {
                explanation: "Autism is a spectrum of hundreds of conditions, but individuals share core challenges with social interactions, restricted interests, and/or repetitive behaviors.",
                sources: [
                    {
                       source: 'Scientific American',
                        url: 'https://www.scientificamerican.com/article/how-big-data-are-unlocking-the-mysteries-of-autism/',
                    }
                ],
            },
        },
        { // 3
            question: "Autism isn’t always a disability people ‘suffer’ from. Many individuals on the spectrum overcome their main social challenges, and then experience autism as gifts of exceptional intelligence, artistic skills, memory, innovation, attention to detail, and a tendency toward honesty and loyalty.",
            choices: ['True', 'False'],
            answerIdx: 0,    
            furtherInfo: {
                explanation: "This is true. Autism is not always a disability people suffer from. Individuals on the spectrum often come to experience autism as gifts of exceptional intelligence, artistic skill, memory, innovation, attention to detail, and a tendency to honesty and loyalty.",
                sources: [
                    {
                        source: 'Best Practice Middletown Autism',
                        url: 'https://best-practice.middletownautism.com/what-is-autism/strengths-and-skills-in-students-with-autism/',
                    }
                ],
            },
        },
        { // 4
            question: "To date, how many genes related to autism have been identified?",
            choices: ['383','150', '71', '914'],
            answerIdx: 1,    
            furtherInfo: {
                explanation: "To date, 150 genes have been identified that relate to autism.",
                sources: [
                    {
                       source: 'Scientific American',
                        url: 'https://www.scientificamerican.com/article/how-big-data-are-unlocking-the-mysteries-of-autism/',
                    }
                ],
            },
        },
        { // 5
            question: "Sometimes another genetic condition causes autism as a side effect. For example, untreated PKU (a metabolic problem that leads to a buildup of amino acid in the blood) can cause:",
            choices: [
                'Seizures, behavioral problems, mental disability, lighter skin.',
                'Movement problems, inability to speak, and savantism.',
                'Obsessive behavior, impulsivity, and an overly large head.',
                'All of the above.'
            ],
            answerIdx: 0,    
            furtherInfo: {
                explanation: "PKU can cause seizures, behavioral problems, mental disability, as well as lighter skin.",
                sources: [
                    {
                        Source: 'Scientific American',
                        url: 'https://www.scientificamerican.com/article/how-big-data-are-unlocking-the-mysteries-of-autism/',
                    }
                ],
            },
        },
        { // 6
            question:'SPARK is the world’s largest autism research study to date. What is their goal?',
            choices: [
                'To speed up research and advance understanding in order to improve lives.',
                'To advocate for individuals on the spectrum against schools and workplaces that are not providing adequate support.',
                'To prevent autism through genetic manipulation.',
                'To get autism nationally recognized as a protected condition, so insurance companies cannot refuse to cover needed support.'
            ],
            answerIdx: 0,    
            furtherInfo: {
                explanation: "SPARK’s goal is to speed up research and advance understanding about autism in order to improve lies.",
                sources: [
                    {
                        Source: 'Scientific American',
                        url: 'https://www.scientificamerican.com/article/how-big-data-are-unlocking-the-mysteries-of-autism/',
                    }
                ],
            },
        },
        { // 7
            question: "Autism is diagnosed 4x more in males than in females. This is partially due to:",
            choices: [
                'Females are better at imitating social skills, even if the skills did not come naturally.',
                `Females often engage in repetitive behavior/ restricted interests that are not noticed by society (e.g. cuticle picking, obsession with self-help books, vs. more "masculine" interests such as knowing all the names of all the roller coasters in the world).`,
                'There may be a higher genetic threshold for autism to appear in females than males. (Meaning: more genetic differences are required before autism presents in females.)',
                'All of the above.'
            ],
            answerIdx: 3,    
            furtherInfo: {
                explanation: "All of these are factors in why autism is diagnosed 4x more in males than in females.",
                sources: [
                    {
                       source: 'Psych Central',
                        url: 'https://psychcentral.com/pro/child-therapist/2019/11/comparison-of-males-and-females-with-asd-gender-differences-in-people-with-autism-spectrum-disorder#Impact-of-IQ',
                    },
                    {
                        source: 'National Institutes of Health (NIH)',
                        url: 'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5925757/'
                    }
                ],
            },
        },
        { // 8
            question: 'Aspergers is a separate condition but related to autism.',
            choices: ['True', 'False'],
            answerIdx: 1,    
            furtherInfo: {
                explanation: "This is false. Aspergers is a recognized form of autism.",
                sources: [
                    {
                        source: 'Healthline',
                        url: 'https://www.healthline.com/health/aspergers-symptoms-in-adults#symptoms',
                    }
                ],
            },
        },
        { // 9
            question: 'Because the autism spectrum is a collection of conditions, an individual may look like which of the following?',
            choices: [
                'A kind neurosurgeon who has an exceptional ability to focus but no interest in small talk and few friends.',
                'An unemployed young woman who made very high marks in school, is obsessed with a favorite fictional character, and gets very upset if her daily schedule is disrupted.',
                'A child who doesn’t speak unless he has to, won’t make eye contact with anyone, and gets overwhelmed in social situations.',
                'All of the above, or none of the above.',
            ],
            answerIdx: 3,    
            furtherInfo: {
                explanation: "An individual with autism may look like any or none of these examples.",
                sources: [
                    {
                        Source: 'Scientific American',
                        url: 'https://www.scientificamerican.com/article/how-big-data-are-unlocking-the-mysteries-of-autism/',
                    }
                ],
            },
        },
          { // 10
            question: 'Autism spectrum can be diagnosed as early as _________ , or as late as _________ .',
            choices: ['2 years old, never', '18 months, 25', '8 years old, 35', '6 weeks, never'],
            answerIdx: 0,    
            furtherInfo: {
                explanation: "Autism spectrum can be diagnosed as early as 2 years old, or as late as never.",
                sources: [
                    {
                        Source: 'Scientific American',
                        url: 'https://www.scientificamerican.com/article/how-big-data-are-unlocking-the-mysteries-of-autism/',
                    }
                ],
            },
        },
    ],

};

export default autism;