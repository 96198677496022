 const scienceQuiz = {
    id: "scienceQuiz",
    name: "Science Potpourri",
    linkTo: "/quizzes/scienceQuiz",
    description: 'Test yourself on some basic science!',
    questions: [
        {
            question: "Based on our best understanding, approximately how hot is the center of the Earth?",
            choices: ['About as hot as the North Pole', 'About as hot as the ground at sea level', 'About as hot as lava', 'About as hot as the sun'],
            answerIdx: 3,    
            furtherInfo: {
            },
        },
        {
            question: "Which is smallest?",
            choices: ['Electrons', 'Cells', 'Molecules', 'Atoms'],
            answerIdx: 0,    
            furtherInfo: {
            },
        },
        {
            question: "True or False? A baby’s sex is determined by hormone levels in the womb.",
            choices: ['True', 'False'],
            answerIdx: 1,    
            furtherInfo: {
            },
        },
        {
            question: "Where did the human race come from?",
            choices: ['North America', 'Europe', 'Africa', 'Asia'],
            answerIdx: 2,    
            furtherInfo: {
            },
        },
        {
            question: "On which planet would you weigh most?",
            choices: ['Mars', 'Earth', 'Jupiter', 'Neptune'],
            answerIdx: 2,    
            furtherInfo: {
            },
        },
        {
            question: "True or False? Stegosaurus lived closer to T. rex than T. rex lived to humans.",
            choices: ['True', 'False'],
            answerIdx: 1,    
            furtherInfo: {
            },
        },
        {
            question: "True or False? Antibiotics don’t work against viruses.",
            choices: ['True', 'False'],
            answerIdx: 0,    
            furtherInfo: {
            },
        },
        {
            question: "How much of Earth’s oxygen is made by the Amazon rainforest?",
            choices: ['20%', '12%', '2%', '0.2%'],
            answerIdx: 0,    
            furtherInfo: {
            },
        },
        {
            question: "True or False? The moon is slowly moving away from Earth.",
            choices: ['True', 'False'],
            answerIdx: 0,    
            furtherInfo: {
            },
        },
        {
            question: "Which planet rotates on its side?",
            choices: ['Mercury', 'Earth', 'Jupiter', 'Uranus'], // ANSWER MIGHT NOT BE CORRECT NEEDS REVIEW
            answerIdx: 0,    
            furtherInfo: {
            },
        },
        
        
    ],

};

export default scienceQuiz;
