 const musicTrivia60 = {
    id: "musicTrivia60",
    name: "60's Music Trivia",
    linkTo: "/quizzes/musicTrivia60",
    description: 'Music Trivia from the 60s!',
    questions: [
        {
            question: "Who sang the hit song 'It's Now Or Never'?",
            choices: ['Jim Reeves', 'Elvis Presley', 'Roy Orbison'],
            answerIdx: 1,    
            furtherInfo: {
            },
        },
        {
            question: 'This artist was a civil-rights activist, singer and songwriter with hits such as "A Change Is Gonna Come", "Cupid", and "Wonderful World".',
            choices: ['Ray Charles', 'Louis armstrong', 'Sam Cooke'],
            answerIdx: 2,    
            furtherInfo: {
            },
        },
        {
            question: 'The 1964 hit "The House of the Rising Sun" was recorded by what British rock group?',
            choices: ['The Animals', 'The Monkeys', 'The Beatles'],
            answerIdx: 0,    
            furtherInfo: {
            },
        },
        {
            question: 'Diana Ross sang lead vocals for what group?',
            choices: ['The Shangri-Las', 'The Supremes', 'The Chiffons'],
            answerIdx: 1,    
            furtherInfo: {
            },
        },
        {
            question: 'This artist was part of the "Rat Pack" and known as "The King of Cool"',
            choices: ['Dean Martin', 'James Dean', 'Elvis Presley'],
            answerIdx: 0,    
            furtherInfo: {
            },
        },
        {
            question: 'The Crystals recorded this hit song in 1962?',
            choices: ["He's A Rebel", "He's So Fine", 'Walk Like A Man'],
            answerIdx: 0,    
            furtherInfo: {
            },
        },
        {
            question: '"You\'ve Lost That Lovin Feelin" by the Righteous Brothers was produced by?',
            choices: ['Phil Spector', 'Dave Clark', 'Berry Gordy Jr'],
            answerIdx: 0,    
            furtherInfo: {
            },
        },
        {
            question: 'What group recorded the hit song "My Girl" for Motown records in 1964?',
            choices: ['The Drifters', 'The Jackson Five', 'The Temptations'],
            answerIdx: 2,    
            furtherInfo: {
            },
        },
        {
            question: 'Simon & Garfunkel\'s song "Mrs. Robinson" was made famous from what 1967 film?',
            choices: ['The Graduate', 'Harold and Maude', 'Bonnie and Clyde'],
            answerIdx: 0,    
            furtherInfo: {
            },
        },
        {
            question: '"Leaving on a Jet Plane" recorded by Peter, Paul and Mary was written by?',
            choices: ['The Doors', 'John Denver', 'Donovan'],
            answerIdx: 1,    
            furtherInfo: {
            },
        },
    ],

};

export default musicTrivia60;
