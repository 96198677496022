 const voyagerJourneys = {
    name: 'The Voyager Journeys',
    id: 'y5NDhjiBOoTMOXtRGFKE',
    linkTo: "/quizzes/voyagerJourneys",
    explainerOn: true,
    description: "The Voyager Mission, consisting of two probes, now represents the furthest human-made objects from Earth. How familiar are you with this decades-long, groundbreaking mission?",
    questions: [
        {
            //question 1//
            question: "For how many years has the Voyager Mission been ongoing?",
            choices: ['4', '43', '430', '4,300'],
            answerIdx: 1,    
            furtherInfo: {
                explanation: (
                    `With both probes launched in late 1977, the Voyager Mission has been ongoing for 43 years.`
                ),
                sources: [
                    {
                        source: 'NASA. Voyager - Mission Status. NASA',
                        url: 'https://voyager.jpl.nasa.gov/mission/status/',
                    },
                ],
            },
        },
        {
            //question 2//
           question: "How many AUs (astronomical units) from Earth is Voyager 1?",
            choices: ['152', '1,520', '15,200', '152,000'],
            answerIdx: 0,    
            furtherInfo: {
                explanation: (
                    `Voyager 1 is 152 Astronomical Units from Earth`
                ),
                sources: [
                    {
                        source: 'NASA. Voyager - Mission Status. NASA',
                        url: 'https://voyager.jpl.nasa.gov/mission/status/',
                    },
                ],
            },
        },
        {
            //question 3//
            question: "Voyager 1 is how many miles from Earth?",
            choices: ['141,000', '1,410,000', '14,100,000', '14,100,000,000'],
            answerIdx: 3,    
            furtherInfo: {
                explanation: (
                    `Voyager 1’s 152 AUs from Earth translates to 14,100,000,000 miles.`
                ),
                sources: [
                    {
                        source: 'NASA. Voyager - Mission Status. NASA',
                        url: 'https://voyager.jpl.nasa.gov/mission/status/',
                    },
                ],
            },
        },
        {
            //question 4//
            question: "How many AUs from Earth is Voyager 2?",
            choices: ['127', '1,270', '12,700', '127,000'],
            answerIdx: 0,    
            furtherInfo: {
                explanation: (
                    `Voyager 2 is 127 Astronomical Units from Earth.`
                ),
                sources: [
                    {
                        source: 'NASA. Voyager - Mission Status. NASA',
                        url: 'https://voyager.jpl.nasa.gov/mission/status/',
                    },
                ],
            },
        },
        {
            //question 5//
            question: "Voyager 2 is how many miles from Earth?",
            choices: ['118,000', '1,180,000', '11,800,000', '11,800,000,000'],
            answerIdx: 3,    
            furtherInfo: {
                explanation: (
                    `Voyager 2’s 127 AUs from Earth translates to 11,800,000,000 miles.`
                ),
                sources: [
                    {
                        source: 'NASA. Voyager - Mission Status. NASA',
                        url: 'https://voyager.jpl.nasa.gov/mission/status/',
                    },
                ],
            },
        },
        {
            //question 6//
            question: "Voyager 1 is how many miles from the sun?",
            choices: ['142,000', '1,420,000', '14,200,000', '14,200,000,000'],
            answerIdx: 3,    
            furtherInfo: {
                explanation: (
                    `Voyager 1 is 14,200,000,000 miles from the Sun.`
                ),
                sources: [
                    {
                        source: 'NASA. Voyager - Mission Status. NASA',
                        url: 'https://voyager.jpl.nasa.gov/mission/status/',
                    },
                ],
            },
        },
        {
            //question 7//
            question: "Voyager 2 is how many miles from the sun?",
            choices: ['118,100', '1,118,000', '118,100,000', '11,810,000,000'],
            answerIdx: 3,    
            furtherInfo: {
                explanation: (
                    `Voyager 2 is 11,810,000,000 miles from the Sun.`
                ),
                sources: [
                    {
                        source: 'NASA. Voyager - Mission Status. NASA',
                        url: 'https://voyager.jpl.nasa.gov/mission/status/',
                    },
                ],
            },
        },
        {
            //question 8//
            question: "What is the velocity of Voyager 1 in miles per hour?",
            choices: ['380', '3,800', '38,000', '380,000'],
            answerIdx: 2,    
            furtherInfo: {
                explanation: (
                    `Voyager 1 is traveling through space at 38,000 miles per hour.`
                ),
                sources: [
                    {
                        source: 'NASA. Voyager - Mission Status. NASA',
                        url: 'https://voyager.jpl.nasa.gov/mission/status/',
                    },
                ],
            },
        },
        {
            //question 9//
            question: "What is the velocity of Voyager 2 in miles per hour?",
            choices: ['340', '3,400', '34,000', '340,000'],
            answerIdx: 2,    
            furtherInfo: {
                explanation: (
                    `Slightly slower than its counterpart, Voyager 2 is traveling through space at 34,000 miles per hour.`
                ),
                sources: [
                    {
                        source: 'NASA. Voyager - Mission Status. NASA',
                        url: 'https://voyager.jpl.nasa.gov/mission/status/',
                    },
                ],
            },
        },
        {
            //question 10//
            question: "Approximately how many years will it take Voyager 1, the farthest human-made object from Earth, to reach the inner edge of the Oort Cloud?",
            choices: ['3', '30', '300', '3,000'],
            answerIdx: 2,    
            furtherInfo: {
                explanation: (
                    `It will take Voyager 1 an additional 300 years, long after it has gone dark, to reach just the inner edge of the Oort Cloud.`
                ),
                sources: [
                    {
                        source: 'NASA. Voyager - Mission Status. NASA',
                        url: 'https://voyager.jpl.nasa.gov/mission/status/',
                    },
                ],
            },
        },
    ],

};

export default voyagerJourneys;