 const conservationKenya = {
    name: 'Conservation and Community in Kenya',
    id: 'EOOGonmrUcrrECBAmgVq',
    linkTo: "/quizzes/conservationKenya",
    explainerOn: true,
    description: 'Wildlife conservation has always been about preserving and promoting endangered animals. The Northern Rangelands Trust in Kenya has found that wildlife conservation can also be about preserving and promoting communities.',
    questions: [
        {
            //question 1//
            question: "How many community conservancies does the Northern Rangelands Trust currently support?",
            choices: ['3', '39', '390', '3,900'],
            answerIdx: 1,    
            furtherInfo: {
                explanation: (
                    `The NRT currently supports 39 community conservancies. `
                ),
                sources: [
                    {
                        source: 'Northern Rangelands Trust',
                        url: 'https://www.nrt-kenya.org/',
                    },
                ],
            },
        },
        {
            //question 2//
            question: "How many square miles do the NRT’s conservancies cover?",
            choices: ['26', '260', '2,600', '26,000'],
            answerIdx: 3,    
            furtherInfo: {
                explanation: (
                    `The NRT’s 39 conservancies cover 26,000 square miles.`
                ),
                sources: [
                    {
                        source: 'Northern Rangelands Trust',
                        url: 'https://www.nrt-kenya.org/',
                    },
                ],
            },
        },
        {
            //question 3//
            question: "How many hectares of land have been rehabilitated by the NRT?",
            choices: ['39', '390', '3,900', '39,000'],
            answerIdx: 2,    
            furtherInfo: {
                explanation: (
                    `3,900 hectares, 9,600 acres, of degraded land previously used for grazing have been rehabilitated by community conservancies, reducing competition between local groups for grazing land.`
                ),
                sources: [
                    {
                        source: 'Northern Rangelands Trust',
                        url: 'https://www.nrt-kenya.org/',
                    },
                ],
            },
        },
        {
            //question 4//
            question: "How many mangrove seedlings have been planted by the NRT?",
            choices: ['49', '499', '4,990', '49,990'],
            answerIdx: 3,    
            furtherInfo: {
                explanation: (
                    `49,900 mangrove seelings have been planted by the NRT in Kenya’s coastal region, to which the mangrove is native.`
                ),
                sources: [
                    {
                        source: 'Northern Rangelands Trust',
                        url: 'https://www.nrt-kenya.org/',
                    },
                ],
            },
        },
        {
            //question 5//
            question: "What percentage of the world population of hirola, a critically endangered type of elk, live in NRT conservancies?",
            choices: ['5%', '25%', '50%', '75%'],
            answerIdx: 1,    
            furtherInfo: {
                explanation: (
                    `25% of the world’s hirola population lives in NRT conservancies. The hirola is the most endangered elk in the world, with a population of only 500 individuals.`
                ),
                sources: [
                    {
                        source: 'Northern Rangelands Trust',
                        url: 'https://www.nrt-kenya.org/',
                    },
                ],
            },
        },
        {
            //question 6//
            question: "How many people are currently employed by the NRT?",
            choices: ['136', '1,360', '13,600', '136,000'],
            answerIdx: 1,    
            furtherInfo: {
                explanation: (
                    `There are 1,360 people now employed by the NRT, including 870 conservancy rangers, some of whom used to be poachers.`
                ),
                sources: [
                    {
                        source: 'Northern Rangelands Trust',
                        url: 'https://www.nrt-kenya.org/',
                    },
                ],
            },
        },
        {
            //question 7//
            question: "How many indigenous groups take part in NRT community conservancies?",
            choices: ['18', '180', '1,800', '18,000'],
            answerIdx: 0,    
            furtherInfo: {
                explanation: (
                    `18 distinct indigenous groups in Kenya now run community conservancies.`
                ),
                sources: [
                    {
                        source: 'Northern Rangelands Trust',
                        url: 'https://www.nrt-kenya.org/',
                    },
                ],
            },
        },
        {
            //question 8//
            question: "How many people are currently benefitting from community programs established by the NRT?",
            choices: ['694', '6,940', '69,400', '694,000'],
            answerIdx: 2,    
            furtherInfo: {
                explanation: (
                    `69,400 people are currently benefiting from community programs established by the NRT, including water, health, and education programs as well as support for community run businesses.`
                ),
                sources: [
                    {
                        source: 'Northern Rangelands Trust',
                        url: 'https://www.nrt-kenya.org/',
                    },
                ],
            },
        },
        {
            //question 9//
            question: "How many elephants were poached for ivory in NRT conservancies in 2020?",
            choices: ['0', '10', '100', '1,000'],
            answerIdx: 0,    
            furtherInfo: {
                explanation: (
                    `No elephants were poached for ivory on any of the NRT conservancies in 2020, down from a high of 103 in 2012.`
                ),
                sources: [
                    {
                        source: 'Northern Rangelands Trust',
                        url: 'https://www.nrt-kenya.org/',
                    },
                ],
            },
        },
        {
            //question 10//
            question: "How many US dollars did tourism generate in Kenya in 2019?",
            choices: ['150,000', '1,500,000', '15,000,000', '1,500,000,000'],
            answerIdx: 3,    
            furtherInfo: {
                explanation: (
                    `In 2019, before the pandemic shut down international travel, Kenya saw $1.5 billion in tourism income which, thanks to efforts by the NRT, more communities are able to benefit from, making poaching less lucrative than conserving the local wildlife.`
                ),
                sources: [
                    {
                        source: 'Statista',
                        url: 'https://www.statista.com/statistics/1140037/tourism-earnings-from-international-arrivals-in-kenya/',
                    },
                ],
            },
        },
    ],

};

export default conservationKenya;