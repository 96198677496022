import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";

const boxCss = {
  backgroundColor: "#465cff",
  borderRadius: "15px",
  border: "1px solid white",
};

const useStyles = makeStyles((theme) => ({
  answerChoice: {
    display: "flex",
    "& > *": boxCss,
    "& :hover": {
      backgroundColor: "#798fff",
      transition: "all 0.9s",
    },
  },
  answerAreaContainer: {
    backgroundColor: "linear-gradient(180deg, #07BBF4 0%, #3124FB 100%)",
  },
  answerArea: {
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    width: "fit-content",
    margin: "0 auto",
  },
  answerAreaPaddingBottom: {
    [theme.breakpoints.down("xs")]: {
      paddingBottom: "100px",
    },
  },
  selected: {
    display: "flex",
    "& > *": Object.assign(
      {
        color: "#fff", // (appearance) => appearance.selectedText,
        maxWidth: 800,
        margin: "0 auto",
        cursor: "pointer",
      },

      boxCss
    ),
    "& > button": {
      backgroundColor: "#798fff",
    },
  },
  markerPos: {
    position: "relative",
    width: "100%",
    maxWidth: 800,
    margin: "0 auto",
    color: "#fff",
    cursor: "pointer",
  },
  correctMarker: {
    position: "absolute",
    top: "10px",
    right: "10px",
    width: "20%",
    maxHeight: "40%",
    objectFit: "contain",
    opacity: 1,
  },
  choiceText: {
    wordWrap: "break-word",
    padding: theme.spacing(2),
    fontFamily: "Open Sans",
  },
  correctAnswer: {
    border: "5px solid #1AFF00",
  },
  incorrectAnswer: {
    border: "5px solid red",
  },
  correctIcon: {
    position: "absolute",
    right: "16px",
    fontSize: "26px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "20px",
      marginTop: "2px",
    },
  },
  wrongIcon: {
    position: "absolute",
    right: "16px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "18px",
      marginTop: "2px",
    },
  },
}));

export default function MultipleChoice(props) {
  const {
    appearance,
    choices,
    correctAnswerIdx,
    answerStyle,
    onAnswer,
  } = props;
  const [answerIdx, setAnswerIdx] = React.useState(-1);
  const classes = useStyles(appearance);

  function answerChoiceSize() {
    if (answerStyle === "vertical") {
      return 12;
    }
    return 6;
  }

  function answerTextSize() {
    if (answerStyle === "vertical") {
      return "h5";
    }
    return "h6";
  }

  function finished() {
    return answerIdx >= 0;
  }

  const handleSelect = (choiceIdx) => (event) => {
    setAnswerIdx(choiceIdx);

    setTimeout(() => {
      onAnswer(choiceIdx);
    }, 1000);
  };

  return (
    <div className={classes.answerAreaContainer}>
      <Grid
        container
        className={
          props.quizLength > 10
            ? `${classes.answerArea} ${classes.answerAreaPaddingBottom}`
            : classes.answerArea
        }
        justify="center"
        spacing={1}
        alignItems="stretch"
      >
        {choices.map((choice, idx) => {
          return (
            <Grid
              key={idx}
              item
              xs={answerChoiceSize()}
              className={`${
                answerIdx === idx ? classes.selected : classes.answerChoice
              }`}
            >
              <button
                onClick={handleSelect(idx)}
                className={`${classes.markerPos} ${
                  finished() && idx === correctAnswerIdx
                    ? classes.correctAnswer
                    : ""
                } ${
                  finished() &&
                  answerIdx === idx &&
                  answerIdx !== correctAnswerIdx
                    ? classes.incorrectAnswer
                    : ""
                }`}
              >
                <Typography
                  component="div"
                  variant={answerTextSize()}
                  align="center"
                  className={classes.choiceText}
                >
                  {choice}

                  {finished() &&
                  answerIdx === idx &&
                  idx === correctAnswerIdx ? (
                    <span
                      role="img"
                      aria-label="icon"
                      className={classes.correctIcon}
                    >
                      ✅
                    </span>
                  ) : finished() &&
                    answerIdx === idx &&
                    idx !== correctAnswerIdx ? (
                    <span
                      role="img"
                      aria-label="icon"
                      className={classes.wrongIcon}
                    >
                      ❌
                    </span>
                  ) : (
                    ""
                  )}
                </Typography>
              </button>
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
}
