import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  logoText: {
    fontFamily: "hotel-solid,sans-serif",
    color: "#fff",
    marginTop: 0,
    marginBottom: 0,
    fontSize: 40,
    "&:hover":{
      color:"rgb(216,216,216)"
    }
  },
}));

export default () => {
  const classes = useStyles();

  return <h1 className={classes.logoText}>QUIZICIST</h1>;
};
