import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import { Button } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";

function getModalStyle() {
  return {
    top: `${50}%`,
    left: `${50}%`,
    transform: `translate(-${50}%, -${50}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 600,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    [theme.breakpoints.down("sm")]: {
      width: 550,
    },
    [theme.breakpoints.down("xs")]: {
      width: 300,
    },
    "& h2": {
      textAlign: "center",
    },
  },
  buttons: {
    display: "flex",
    flexDirection: "column",
    "& button": {
      width: "100%",
      margin: "5px",
      borderRadius: "20px",
      fontWeight: 600,
      textTransform: "capitalize",
      fontSize: "16px",
    },
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      flexWrap: "wrap",
    },
  },
  closeBtn: {
    position: "absolute",
    right: "20px",
    cursor: "pointer",
  },
  loginBtn: {
    backgroundColor: "#3d2aff",
    color: "white",
    "&:hover": {
      textDecoration: "none",
      backgroundColor: "#3d2aff",
    },
  },
  skipBtn: {
    backgroundColor: "#6198fa",
    color: "white",
    "&:hover": {
      textDecoration: "none",
      backgroundColor: "#6198fa",
    },
  },
  signupBtn: {
    backgroundColor: "white",
    color: "#3d2aff",
    border: "2px solid #3d2aff",
    "&:hover": {
      textDecoration: "none",
      backgroundColor: "white",
    },
  },
}));

function OutroAuthPopup({ visible, setVisible, text }) {
  const history = useHistory();
  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = useState(getModalStyle);

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <ClearIcon
        className={classes.closeBtn}
        onClick={() => setVisible(false)}
      />
      <h2>{text}</h2>
      <div className={classes.buttons}>
        <Link
          to={{
            pathname: "/signin",
            state: { prevPath: history.location.pathname },
          }}
          style={{ textDecoration: "none" }}
        >
          <Button className={classes.loginBtn}>Log In</Button>
        </Link>
        <Link
          to={{
            pathname: "/signup",
            state: { prevPath: history.location.pathname },
          }}
          style={{ textDecoration: "none" }}
        >
          <Button className={classes.signupBtn}>Create Account</Button>
        </Link>
        <Button onClick={() => setVisible(false)} className={classes.skipBtn}>
          Skip
        </Button>
      </div>
    </div>
  );

  return (
    <Modal
      open={visible}
      onClose={() => setVisible(false)}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      {body}
    </Modal>
  );
}

export default OutroAuthPopup;
