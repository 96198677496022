 const lifespanOfCivilizations = {
    name: 'The Lifespan of Civilizations',
    id: 'nQkfXZdC4oqlc0A2dO4N',
    linkTo: "/quizzes/lifespanOfCivilizations",
    explainerOn: true,
    description: "With climate change, global pandemics, and widespread protests it can feel like the end of civilization as we know it.  This amalgamation of societies we call Western Civilization certainly wouldn't be the first to come and go. But here are the numbers to provide some much-needed perspective.",
    questions: [
        {
            //question 1//
            question: "How long, in years, is the average lifespan of a civilization?",
            choices: ['36', '336', '3,360', '33,600'],
            answerIdx: 1,    
            furtherInfo: {
                explanation: (
                    `The average civilization lasts 336 years.`
                ),
                sources: [
                    {
                        source: 'BBC',
                        url: 'https://www.bbc.com/future/article/20190218-the-lifespans-of-ancient-civilisations-compared',
                    },
                ],
            },
        },
        {
            //question 2//
            question: "How many years did the Aksumite Empire last?",
            choices: ['110', '1,100', '11,000', '110,000'],
            answerIdx: 1,    
            furtherInfo: {
                explanation: (
                    `Aksumite Empire, centered in Northern Ethiopia, lasted 1,100 years (100BCE-1000CE).`
                ),
                sources: [
                    {
                        source: 'Larryfreeman. (2008, August 2). How Long Did the Empires of Ancient Civilizations Last? Owlcation',
                        url: 'https://owlcation.com/humanities/How-long-do-empires-last',
                    },
                ],
            },
        },
        {
            //question 3//
            question: "How long, in years, did the Olmec civilization last?",
            choices: ['800', '8,000', '80,000', '800,000'],
            answerIdx: 0,    
            furtherInfo: {
                explanation: (
                    `The Olmec civilization, located in what is now Mexico, lasted 800 years (1200-400BCE).`
                ),
                sources: [
                    {
                        source: 'Larryfreeman. (2008, August 2). How Long Did the Empires of Ancient Civilizations Last? Owlcation',
                        url: 'https://owlcation.com/humanities/How-long-do-empires-last',
                    },
                ],
            },
        },
        {
            //question 4//
            question: "How long, in years, did the civilization centered around Carthage last?",
            choices: ['44', '442', '4,420', '44,200'],
            answerIdx: 1,    
            furtherInfo: {
                explanation: (
                    `The civilization of Carthage, based in North Africa, lasted 442 years`
                ),
                sources: [
                    {
                        source: 'Larryfreeman. (2008, August 2). How Long Did the Empires of Ancient Civilizations Last? Owlcation',
                        url: 'https://owlcation.com/humanities/How-long-do-empires-last',
                    },
                ],
            },
        },
        {
            //question 5//
            question: "How many years did the Kingdom of Armenia reign?",
            choices: ['442', '4,420', '44,200', '442,000'],
            answerIdx: 0,    
            furtherInfo: {
                explanation: (
                    `The Kingdom of Armenia lasted 442 years (190BCE-252CE).`
                ),
                sources: [
                    {
                        source: 'Larryfreeman. (2008, August 2). How Long Did the Empires of Ancient Civilizations Last? Owlcation',
                        url: 'https://owlcation.com/humanities/How-long-do-empires-last',
                    },
                ],
            },
        },
        {
            //question 6//
            question: "How many years did the Three Kingdoms of Korea last?",
            choices: ['72', '725', '7,250', '72,500'],
            answerIdx: 1,    
            furtherInfo: {
                explanation: (
                    `The Three Kingdoms of Korea lasted for 725 years (57BCE-668BCE).`
                ),
                sources: [
                    {
                        source: 'Larryfreeman. (2008, August 2). How Long Did the Empires of Ancient Civilizations Last? Owlcation',
                        url: 'https://owlcation.com/humanities/How-long-do-empires-last',
                    },
                ],
            },
        },
        {
            //question 7//
            question: "How many years did the Classical Greek civilization which shaped so much of Western thought last?",
            choices: ['350', '3,500', '35,000', '350,000'],
            answerIdx: 0,    
            furtherInfo: {
                explanation: (
                    `The Classical Greek civilization lasted for 350 years (776-323BCE).`
                ),
                sources: [
                    {
                        source: 'Larryfreeman. (2008, August 2). How Long Did the Empires of Ancient Civilizations Last? Owlcation',
                        url: 'https://owlcation.com/humanities/How-long-do-empires-last',
                    },
                ],
            },
        },
        {
            //question 8//
            question: "For how many years did the Roman Empire dominate Europe and the Mediterranean?",
            choices: ['5', '52', '520', '5,200'],
            answerIdx: 2,    
            furtherInfo: {
                explanation: (
                    `The Roman Empire, distinct from the Roman Republic, lasted 520 years (44BCE-476CE).`
                ),
                sources: [
                    {
                        source: 'Larryfreeman. (2008, August 2). How Long Did the Empires of Ancient Civilizations Last? Owlcation',
                        url: 'https://owlcation.com/humanities/How-long-do-empires-last',
                    },
                ],
            },
        },
        {
            //question 9//
            question: "How long did the Old Kingdom of Egypt last?",
            choices: ['240', '2,400', '24,000', '240,000'],
            answerIdx: 0,    
            furtherInfo: {
                explanation: (
                    `Egypt’s first recognizable civilization, The Old Kingdom, lasted 550 years (2686-2134BCE).`
                ),
                sources: [
                    {
                        source: 'Larryfreeman. (2008, August 2). How Long Did the Empires of Ancient Civilizations Last? Owlcation',
                        url: 'https://owlcation.com/humanities/How-long-do-empires-last',
                    },
                ],
            },
        },
        {
            //question 10//
            question: "For how many years did the Qin Dynasty last?",
            choices: ['14', '140', '1,400', '14,000'],
            answerIdx: 0,    
            furtherInfo: {
                explanation: (
                    `Stretching the term ‘dynasty’ the Qin Dynasty lasted just 14 years (221-207BCE).`
                ),
                sources: [
                    {
                        source: 'Larryfreeman. (2008, August 2). How Long Did the Empires of Ancient Civilizations Last? Owlcation',
                        url: 'https://owlcation.com/humanities/How-long-do-empires-last',
                    },
                ],
            },
        },
    ],

};

export default lifespanOfCivilizations;