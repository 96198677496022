const aFigureOfSpeech = {
  name: "A Figure of Speech",
  id: 'XyD76Wgr3OirqMJx3KKF',
  linkTo: "/quizzes/ aFigureOfSpeech",
  explainerOn: true,
  subheading: `Metaphors, and Similes, and Alliteration, oh my! How familiar are you with the English language's figures of speech?`,
  description: `Have you ever been told 'Don't have a cow,' or said 'I could eat a horse?' It's just a figure of speech. How well do you know them?`,
  questions: [
    { // Q-1
      question: "There are actually two types of figures of speech in English. What are they?",
      choices: ['Schemes and Tropes', 'Metaphors and Similes', 'Grammar and Spelling', 'Intuitive and Factual'],
      answerIdx: 0,
      furtherInfo: {
        explanation: (
          `English has two major types of stylistic choices -- figures of speech, called Schemes and Tropes. Schemes affect the structure of your sentences, such as alliteration, and can make text read more smoothly or even be more persuasive. Tropes, however, are linguistic tricks that deviate from the literal meaning of words to make your writing more complex and interesting.`
        ),
        sources: [
          {
            source: "Visual Capitalist",
            url: 'https://www.visualcapitalist.com/figures-of-speech-40-ways-to-improve-your-writing/',
          },
        ],
      },
    },
    { // Q-2
      question: "Referencing one thing to imply another is known as what?",
      choices: ['Personification', 'Simile', 'Metaphor', 'Irony'],
      answerIdx: 2,
      furtherInfo: {
        explanation: (
          `Referencing one thing to imply another is a linguistic trope known as a metaphor. Ex: "All the world's a stage," - As You Like It, William Shakespeare.`
        ),
        sources: [
          {
            source: "Visual Capitalist",
            url: 'https://www.visualcapitalist.com/figures-of-speech-40-ways-to-improve-your-writing/',
          },
        ],
      },
    },
    { // Q-3 
      question: "'Trevor's Tremendous Trees' is an example of which linguistic scheme?",
      choices: ['Chiasmus', 'Alliteration', 'Parallelism', 'Assonance'],
      answerIdx: 1,
      furtherInfo: {
        explanation: (
          `This is an example of alliteration, which is a linguistic scheme where consonant sounds are repeated in two or more words. Ex: "Peter Piper picked a peck of pickled peppers."`
        ),
        sources: [
          {
            source: "Visual Capitalist",
            url: 'https://www.visualcapitalist.com/figures-of-speech-40-ways-to-improve-your-writing/',
          },
        ],
      },
    },
    { // Q-4
      question: "Attributing human traits to inanimate objects is known as______.",
      choices: ['Personification', 'Auxesis', 'Metonymy', 'Irony'],
      answerIdx: 0,
      furtherInfo: {
        explanation: (
          `When your cat makes that hilariously disgruntled face, or your laptop is stubbornly refusing to run that program correctly, you are probably practicing Personification and attributing human traits (disgruntled and stubborn) to non-human or inanimate objects. Ex: "You start to freeze as horror looks you right between the eyes" - Thriller, Michael Jackson`
        ),
        sources: [
          {
            source: "Visual Capitalist",
            url: 'https://www.visualcapitalist.com/figures-of-speech-40-ways-to-improve-your-writing/',
          },
        ],
      },
    },
    { // Q-5
      question: `When Shakespeare wrote "Friends, Romans, Countrymen, lend me your ears." he was using which linguistic trope?`,
      choices: ['Paradox', 'Oxymoron', 'Onomatopoeia', 'Synecdoche'],
      answerIdx: 3,
      furtherInfo: {
        explanation: (
          `Shakespeare's famous opening line from Marc Anotony's speech in Julius Caesar is an example of Synecdoche; a trope in which a whole is used to mean a part or a part is used to mean the whole. Here, Marc Antony uses 'ears' to mean the whole of the men who make up his audience.`
        ),
        sources: [
          {
            source: "Visual Capitalist",
            url: 'https://www.visualcapitalist.com/figures-of-speech-40-ways-to-improve-your-writing/',
          },
        ],
      },
    },
    { // Q-6
      question: "'Fleece for Peace' is an example of which linguistic scheme?",
      choices: ['Polyptoton', 'Assonance', 'Parallelism', 'Alliteration'],
      answerIdx: 1,
      furtherInfo: {
        explanation: (
          `This is an example of Assonance, which is a scheme that uses repeated vowel sounds.`
        ),
        sources: [
          {
            source: "Visual Capitalist",
            url: 'https://www.visualcapitalist.com/figures-of-speech-40-ways-to-improve-your-writing/',
          },
        ],
      },
    },
    { // Q-7
      question: "When you over exaggerate for effect you are using______.",
      choices: ['Litotes', 'Anthimeria', 'Hyperbole', 'Similes'],
      answerIdx: 2,
      furtherInfo: {
        explanation: (
          `When you say you could eat a horse or otherwise over exaggerate for effect you are using the linguistic trope known as Hyperbole. Ex: "It's a slow burg–I spent a couple of weeks there one day." - The People, Yes, Carl Sandburg`
        ),
        sources: [
          {
            source: "Visual Capitalist",
            url: 'https://www.visualcapitalist.com/figures-of-speech-40-ways-to-improve-your-writing/',
          },
        ],
      },
    },
    { // Q-8
      question: "Omitting conjunctions between a series of words is a linguistic scheme known as what?",
      choices: ['Syllepsis', 'Apposition', 'Ellipsis', 'Brachylogia'],
      answerIdx: 3,
      furtherInfo: {
        explanation: (
          `Omitting conjunctions between words is known as Brachylogia. Ex. "...and that government of the people, by the people, for the people, shall not perish from the earth." - Abraham Lincoln`
        ),
        sources: [
          {
            source: "Visual Capitalist",
            url: 'https://www.visualcapitalist.com/figures-of-speech-40-ways-to-improve-your-writing/',
          },
        ],
      },
    },
    { // Q-9
      question: "Using words of similar sound but differing meaning (<i>dye</i> vs <i>die</i>) is an example of which linguistic trope?",
      choices: ['Irony', 'Paronomasia', 'Metonymy', 'Periphrasis'],
      answerIdx: 1,
      furtherInfo: {
        explanation: (
          `Paronomasia is a trope in which words of similar sound but different meaning are substituted for each other, and is the foundation of puns. Ex. On a scientist's door: Gone Fission.`
        ),
        sources: [
          {
            source: "Visual Capitalist",
            url: 'https://www.visualcapitalist.com/figures-of-speech-40-ways-to-improve-your-writing/',
          },
        ],
      },
    },
    { // Q-10
      question: `"Perfectly wretched" is an example of an _________.`,
      choices: ['Anaphora', 'Antithesis', 'Ellipsis', 'Oxymoron'],
      answerIdx: 3,
      furtherInfo: {
        explanation: (
          `"Perfectly wretched" is an example of an Oxymoron. An oxymoron is a linguistic trope in which two opposing terms are placed next to each other.`
        ),
        sources: [
          {
            source: "Visual Capitalist",
            url: 'https://www.visualcapitalist.com/figures-of-speech-40-ways-to-improve-your-writing/',
          },
        ],
      },
    },
  ],

};

export default aFigureOfSpeech;