 const bangaloresWells = {
    name: "Bangalore's Wells",
    id: 'XrnEM4hLSeoitcz7EzPN',
    linkTo: "/quizzes/bangaloresWells",
    explainerOn: true,
    description: 'Bangalore is one of the fastest-growing cities in Asia, but it doesn’t have the water supply to keep up. However, the traditional well-digging technique of the mannu vaddars may be the answer to this city’s modern problem.',
    questions: [
        {
            //question 1//
            question: "The Cauvery River currently supplies most of Bangalore’s water. How far is it from the city, in miles, at its closest point?",
            choices: ['6', '16', '63', '630'],
            answerIdx: 2,    
            furtherInfo: {
                explanation: (
                    `Bangalore outgrew its closest river, leaving it to ship in water from the Cauvery River which flows 63 miles south of Bangalore at its closest point.`
                ),
                sources: [
                    {
                        source: 'BBC',
                        url: 'https://www.bbc.com/future/article/20201006-india-why-bangalore-is-digging-a-million-wells',
                    },
                ],
            },
        },
        {
            //question 2//
            question: "How many liters of water does the Cauvery River supply to Bangalore daily?",
            choices: ['145,000', '1,450,000', '14,500,000', '1,450,000,000'],
            answerIdx: 3,    
            furtherInfo: {
                explanation: (
                    `1,450,000,000 liters of water are shipped to Bangalore from the Cauvery River every day.`
                ),
                sources: [
                    {
                        source: 'BBC',
                        url: 'https://www.bbc.com/future/article/20201006-india-why-bangalore-is-digging-a-million-wells',
                    },
                ],
            },
        },
        {
            //question 3//
            question: "On average, how many feet of rainfall does Bangalore see yearly?",
            choices: ['3', '13', '30', '130'],
            answerIdx: 0,    
            furtherInfo: {
                explanation: (
                    `Bangalore receives approximately 3 feet in annual rainfall, but due to an increase in paved surfaces, most rainfall fails to penetrate deep enough into the ground to be accessible by deep borewell.`
                ),
                sources: [
                    {
                        source: 'BBC',
                        url: 'https://www.bbc.com/future/article/20201006-india-why-bangalore-is-digging-a-million-wells',
                    },
                ],
            },
        },
        {
            //question 4//
            question: "How many deep borewells (narrow, vertical holes fitted with a pipe to draw up water) does Bangalore currently have?",
            choices: ['4,000', '40,000', '400,000', '4,000,000'],
            answerIdx: 2,    
            furtherInfo: {
                explanation: (
                    `Bangalore has 400,000 authorized deep borewells for accessing groundwater, though most are dry due to overexploitation.`
                ),
                sources: [
                    {
                        source: 'India Water Portal',
                        url: 'https://www.indiawaterportal.org/faqs/site-selection-and-commissioning-of-borewells',
                    },
                ],
            },
        },
        {
            //question 5//
            question: "How far down, in feet, is a deep aquifer of the type accessed by a deep borewell?",
            choices: ['20', '200', '2,000', '20,000'],
            answerIdx: 1,    
            furtherInfo: {
                explanation: (
                    `Formations of rock or soil that act as containers for groundwater***, known as deep aquifers, are 200 feet underground on average.`
                ),
                sources: [
                    {
                        source: 'Urban Waters',
                        url: 'http://bengaluru.urbanwaters.in/aquifers-100/',
                    },
                ],
            },
        },
        {
            //question 6//
            question: "How many open wells, which are both wider and shallower than deep borewells, does Bangalore currently have?",
            choices: ['100', '1,000', '10,000', '100,000'],
            answerIdx: 2,    
            furtherInfo: {
                explanation: (
                    `Bangalore currently has approximately 10,000 open wells dug by mannu vaddars, traditional Indian well diggers whose craft has been passed down generationally in their families for centuries.`
                ),
                sources: [
                    {
                        source: 'BBC',
                        url: 'https://www.bbc.com/future/article/20201006-india-why-bangalore-is-digging-a-million-wells',
                    },
                ],
            },
        },
        {
            //question 7//
            question: "Shallow aquifers may be as little as _____ feet underground.",
            choices: ['10', '100', '200', '300'],
            answerIdx: 0,    
            furtherInfo: {
                explanation: (
                    `Shallow aquifers can be as little as 10 feet underground, making them far easier to access than their deeper counterparts and easily filled by rainwater that does not penetrate as deeply.`
                ),
                sources: [
                    {
                        source: 'BBC',
                        url: 'https://www.bbc.com/future/article/20201006-india-why-bangalore-is-digging-a-million-wells',
                    },
                ],
            },
        },
        {
            //question 8//
            question: "How much is the average daily wage of a well-digger in US dollars?",
            choices: ['$6.40', '$16.40', '$164.00', '$1,640.00'],
            answerIdx: 1,    
            furtherInfo: {
                explanation: (
                    `At over twice the pay of an average male urban worker in Bangalore, a well-digger makes $16.40 a day on average.`
                ),
                sources: [
                    {
                        source: 'BBC',
                        url: 'https://www.bbc.com/future/article/20201006-india-why-bangalore-is-digging-a-million-wells',
                    },
                ],
            },
        },
        {
            //question 9//
            question: "How many open wells would the Biome Environmental Trust like to see dug in Bangalore?",
            choices: ['10,000', '100,000', '1,000,000', '10,000,000'],
            answerIdx: 2,    
            furtherInfo: {
                explanation: (
                    `The target for Bangalore is to dig 1,000,000 open wells to access groundwater in shallow aquifers.`
                ),
                sources: [
                    {
                        source: 'BBC',
                        url: 'https://www.bbc.com/future/article/20201006-india-why-bangalore-is-digging-a-million-wells',
                    },
                ],
            },
        },
        {
            //question 10//
            question: "How much water would the target one million wells produce daily, in liters?",
            choices: ['140,000', '1,400,000', '14,000,000', '1,400,000,000'],
            answerIdx: 3,    
            furtherInfo: {
                explanation: (
                    `Replacing all but 50 million liters of the water currently sourced from the Cauvery River, one million open wells would provide 1,400,000,000 liters of water daily.`
                ),
                sources: [
                    {
                        source: 'BBC',
                        url: 'https://www.bbc.com/future/article/20201006-india-why-bangalore-is-digging-a-million-wells',
                    },
                ],
            },
        },
    ],

};

export default bangaloresWells;