 const ceoPayRatios = {
    name: 'CEO Pay Ratios',
    id: 'snWbkbXBHZhx1ktwLuTR',
    linkTo: "/quizzes/ceoPayRatios",
    explainerOn: true,
    description: 'Amidst the current economic upheaval, the issue of wage disparity between the average worker and the executives of the companies they work for is more salient than ever. But between sliding pay scales and non-cash compensation, that disparity can be hard to quantify. What, in hard numbers, does the difference between a CEO and an average employee look like?',
    questions: [
        {
            //question 1//
            question: "Between 1978 and 2018 CEO compensation in the US rose by 904%: by how much did compensation for the average worker rise? (used in SM)" ,
            choices: ['12%', '22%', '52%', '112%'],
            answerIdx: 0,    
            furtherInfo: {
                explanation: (
                    `Between 1978 and 2018 compensation for the average employee rose by 12%, which is 892% less than it rose for CEOs during those four decades.`
                ),
                sources: [
                    {
                        source: 'Statista',
                        url: 'https://www.statista.com/chart/19036/ceo-and-private-sector-worker-compensation/',
                    },
                ],
            },
        },
        {
            //question 2//
            question: "How much is the median annual wage for employees of the Starbucks Corporation?",
            choices: ['$28,000', '$280,000', '$2,800,000', '$28,000,000'],
            answerIdx: 0,    
            furtherInfo: {
                explanation: (
                    `The average employee of the Starbucks Corporation makes $28,000 a year.`
                ),
                sources: [
                    {
                        source: 'CEO Pay Ratios - Full List. PayScale',
                        url: 'https://www.payscale.com/data-packages/ceo-pay/full-list.',
                    },
                ],
            },
        },
        {
            //question 3//
            question: "Approximately how much is the annual cash compensation for the CEO of the Starbucks Corporation?",
            choices: ['$6,000', '$60,000', '$600,000', '$6,000,000'],
            answerIdx: 3,    
            furtherInfo: {
                explanation: (
                    `The approximate annual cash compensation for the CEO of the Starbucks Corporation is $6 million, which makes up 28% of their total annual compensation.`
                ),
                sources: [
                    {
                        source: 'CEO Pay Ratios - Full List. PayScale',
                        url: 'https://www.payscale.com/data-packages/ceo-pay/full-list.',
                    },
                ],
            },
        },
        {
            //question 4//
            question: "What is the pay ratio between the average employee of the Starbucks Corporation, $28,000, and the CEO of the same, $6 million?",
            choices: ['2:1', '20:1', '204:1', '2,040:1'],
            answerIdx: 2,    
            furtherInfo: {
                explanation: (
                    `At $28,000 to $6 million the pay ratio between the average employee of the Starbucks Corporation and the CEO of the same is 204:1.`
                ),
                sources: [
                    {
                        source: 'CEO Pay Ratios - Full List. PayScale',
                        url: 'https://www.payscale.com/data-packages/ceo-pay/full-list.',
                    },
                ],
            },
        },
        {
            //question 5//
            question: "How much is the median annual wage for employees of Wal Mart Stores Incorporated?",
            choices: ['$15,000', '$25,000', '$250,000', '$2,500,000'],
            answerIdx: 1,    
            furtherInfo: {
                explanation: (
                    `The average employee of Wal Mart Stores Incorporated makes $25,000 a year.`
                ),
                sources: [
                    {
                        source: 'CEO Pay Ratios - Full List. PayScale',
                        url: 'https://www.payscale.com/data-packages/ceo-pay/full-list.',
                    },
                ],
            },
        },
        {
            //question 6//
            question: "How much is the annual cash compensation for the CEO of Wal Mart Stores Incorporated?",
            choices: ['$50,000', '$500,000', '$5,000,000', '$50,000,000'],
            answerIdx: 2,    
            furtherInfo: {
                explanation: (
                    `The annual cash compensation of the CEO of Wal Mart Stores Incorporated is $5 million, which makes up 26% of their total annual compensation.`
                ),
                sources: [
                    {
                        source: 'CEO Pay Ratios - Full List. PayScale',
                        url: 'https://www.payscale.com/data-packages/ceo-pay/full-list.',
                    },
                ],
            },
        },
        {
            //question 7//
            question: "What is the pay ratio between the average employee of Wal Mart Stores Incorporated, $25,000, and the CEO of the same, $5 million?",
            choices: ['20:1', '209:1', '2,090:1', '20,900:1'],
            answerIdx: 1,    
            furtherInfo: {
                explanation: (
                    `At $25,000 to $5 million the pay ratio between the average employee and the CEO of Wal Mart Stores Incorporated is 209:1.`
                ),
                sources: [
                    {
                        source: 'CEO Pay Ratios - Full List. PayScale',
                        url: 'https://www.payscale.com/data-packages/ceo-pay/full-list.',
                    },
                ],
            },
        },
        {
            //question 8//
            question: "How much is the median annual wage for employees of the CVS Health Corporation?",
            choices: ['$18,000', '$28,000', '$280,000', '$2,800,000'],
            answerIdx: 1,    
            furtherInfo: {
                explanation: (
                    `The average employee of the CVS Health Corporation makes $28,000 a year.`
                ),
                sources: [
                    {
                        source: 'CEO Pay Ratios - Full List. PayScale',
                        url: 'https://www.payscale.com/data-packages/ceo-pay/full-list.',
                    },
                ],
            },
        },
        {
            //question 9//
            question: "How much is the annual cash compensation for the CEO of the CVS Health Corporation?",
            choices: ['$12,000', '$120,000', '$1,200,000', '$12,000,000'],
            answerIdx: 3,    
            furtherInfo: {
                explanation: (
                    `The annual cash compensation for the CEO of the CVS Health Corporation is $12 million, which makes up 56% of their total annual compensation.`
                ),
                sources: [
                    {
                        source: 'CEO Pay Ratios - Full List. PayScale',
                        url: 'https://www.payscale.com/data-packages/ceo-pay/full-list.',
                    },
                ],
            },
        },
        {
            //question 10//
            question: "What is the pay ratio between the average employee of the CVS Health Corporation, $28,000, and the CEO of the same, $12 million?",
            choices: ['4,340:1', '434:1', '43:1', '4:1'],
            answerIdx: 1,    
            furtherInfo: {
                explanation: (
                    `At $28,000 to $12 million, the pay ratio between the average employee and CEO of the CVS Health Corporation is 434:1`
                ),
                sources: [
                    {
                        source: 'CEO Pay Ratios - Full List. PayScale',
                        url: 'https://www.payscale.com/data-packages/ceo-pay/full-list.',
                    },
                ],
            },
        },
    ],

};

export default ceoPayRatios;