 const pharmaceuticalRacketeering = {
    name: 'Pharmaceutical Racketeering',
    id: 'jrbwKWSYMsOkIiPYsp9o',
    linkTo: "/quizzes/pharmaceuticalRacketeering",
    explainerOn: true,
    description: "A brand-new, innovative way to clean up crime: make racketeering legal if rich companies do it. From calling West Virginians 'pilbillies' to yanking in billions from selling both the disease and the cure, pharmaceutical businesses in the United States are at war with their customers' health, wallets, and dignity. Opioid abuse has now reached crisis levels, with synthetic opioids (fentanyl and tramadol) now overtaking even prescription opioids (Methadone, Oxycodone AKA Oxy, and Hydrocodone), and heroin as the leading cause of death through opioid overdose.",
    questions: [
        {
            //question 1//
            question: "True or false: Opioid companies strategically extended opioid prescriptions into populations that did not need them, in order to reap massive profits.",
            choices: ['True', 'False'],
            answerIdx: 0,    
            furtherInfo: {
                explanation: (
                    `This is true. Opioids are a class of drugs that work on the opioid receptors in nerves, causing both pain relief and euphoria. A 2020 article in the Journal of Bioethical Inquiry explained that opioids were originally prescribed to terminal cancer patients, but companies pushed doctors to prescribe high doses to non-cancer patients despite a lack of evidence that doing so would benefit acute or chronic pain. Regular use can lead to addiction, overdose, and death. These companies downplayed the risks of addiction, and branded doctors who were concerned about addiction as “opiophobic.” The companies then blamed addiction on patients and doctors.`
                ),
                sources: [
                    {
                        source: 'US National Library of Medicine National Institutes of Health',
                        url: 'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC7357445/',
                    },
                ],
            },
        },
        {
            //question 2//
           question: "Of the U.S. pharmacies located in areas with high opioid-related deaths, what percent of them refuse to dispense buprenorphine (a lifesaving medicine used to treat opioid addiction)?",
            choices: ['3%', '10%', '12%', '20%'],
            answerIdx: 3,    
            furtherInfo: {
                explanation: (
                    `Buprenorphine is shockingly hard to get ahold of, even though one surveyed pharmacy had enough opioids in stock that there could be 90 pills for each of the state's (WV's) residents, including every child.`
                ),
                sources: [
                    {
                        source: 'Pharmacy Times',
                        url: 'https://www.pharmacytimes.com/view/study-1-in-5-pharmacies-blocks-access-to-addiction-treatment-buprenorphine',
                    },
                ],
            },
        },
        {
            //question 3//
            question: "Up to what percent of people prescribed an opioid for chronic pain become addicted?",
            choices: ['5%', '8%', '12%', '21%'],
            answerIdx: 2,    
            furtherInfo: {
                explanation: (
                    `According to the Mayo clinic, opioids are safest when used for three days or less. Unfortunately, the duration prescribed to patients is often much longer. Long prescription usage, combined with certain genetic and psychological factors, can greatly increase your risk of addiction.`
                ),
                sources: [
                    {
                        source: 'National Institute on Drug Abuse',
                        url: 'https://www.drugabuse.gov/drug-topics/opioids/opioid-overdose-crisis',
                    },
                ],
            },
        },
        {
            //question 4//
            question: "By 2010 in Ontario, Canada, opioid overdoses accounted for nearly 1 out of every _________ deaths among 25-34 year olds.",
            choices: ['8', '80', '100', '1,000'],
            answerIdx: 0,    
            furtherInfo: {
                explanation: (
                    `1 out of every 8 deaths among Ontarians ages 25-34, in 2010, were caused by opioid overdoses.`
                ),
                sources: [
                    {
                        source: 'US National Library of Medicine National Institutes of Health',
                        url: 'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4171750/',
                    },
                ],
            },
        },
        {
            //question 5//
            question: "In 2019 alone, how many U.S. Americans died from opioid overdoses?",
            choices: ['50', '50,000', '5,000', '5,000,000'],
            answerIdx: 1,    
            furtherInfo: {
                explanation: (
                    `50,000 U.S. Americans died from opioid overdoses in 2019, a number that skyrocketed to around 90,000 during the pandemic.`
                ),
                sources: [
                    {
                        source: 'CDC',
                        url: 'https://www.cdc.gov/opioids/data/index.html',
                    },
                ],
            },
        },
        {
            //question 6//
            question: "In the U.S., medications that treat opioid addiction cost an average of up to $2,800 per month. If 1.27 million people receive these treatments, what are pharmaceutical companies' monthly profits?",
            choices: ['$3.5 million', '$30.5 million', '$3.5 billion', '$30.5 billion'],
            answerIdx: 2,    
            furtherInfo: {
                explanation: (
                    `Medication that treats opioid addiction can be administered at two different price scales, depending on whether it is prescribed as an out-patient or in-patient treatment. The upper limit for both scales averages to $2,800. $2,800 * 1.27 million people = $3.5 billion in profits per month.`
                ),
                sources: [
                    {
                        source: 'U.S. Department of Health and Human Services',
                        url: 'https://www.hhs.gov/opioids/about-the-epidemic/opioid-crisis-statistics/index.html',
                    },
                ],
            },
        },
        {
            //question 7//
            question: "NY State Attorney General Letitia James sued numerous opioid manufacturers and distributors, resulting in settlements for how much (as of July, 2021)?",
            choices: ['$4.73 million', '$47.3 million', '$473 million','$4.73 billion'],
            answerIdx: 3,    
            furtherInfo: {
                explanation: (
                    `As part of her success, NY State A.G. Letitia James forced Johnson & Johnson to exit the opioid business at a national level along with settling for $230 million, as part of an “all-in” settlement under which J&J would ultimately pay as much as $5 billion. Perdue Pharma declared bankruptcy, and settled for $4.5 billion.`
                ),
                sources: [
                    {
                        source: 'NY Post',
                        url: 'https://nypost.com/2021/06/26/new-york-ag-reaches-230m-opioids-settlement-with-johnson-johnson/',
                    },
                ],
            },
        },
        {
            //question 8//
            question: "If a company makes $4 billion per year selling opioids, and $3.5 billion per month selling medications treating opioid addiction, what are the company's total annual profits from just opioid-related medication?",
            choices: ['$4.6 million', '$46 million', '$4.6 billion', '$46 billion'],
            answerIdx: 3,    
            furtherInfo: {
                explanation: (
                    `($3.5 billion * 12 months) + 4 billion = $46 billion annual profits from opioids and opioid addiction medication alone.`
                ),
                sources: [
                    {
                        source: 'The Atlantic',
                        url: 'https://www.theatlantic.com/business/archive/2017/09/opioid-crisis-responsibility-profits/538938/',
                    },
                ],
            },
        },
        {
            //question 9//
            question: "True or false: the opioid crisis has cost the U.S. several trillion dollars so far.",
            choices: ['True', 'False'],
            answerIdx: 0,    
            furtherInfo: {
                explanation: (
                    `The opioid crisis has ravaged many communities, resulting in deaths, productivity declines, the increased need for Child Protective Services, and hospitalizations. In 2019, the Council of Economic Advisers (CEA) estimated the U.S. cost of the opioid crisis to be $2.5 Trillion over just a 4 year period.`
                ),
                sources: [
                    {
                        source: 'US National Library of Medicine National Institutes of Health',
                        url: 'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC7357445/',
                    },
                ],
            },
        },
        {
            //question 10//
            question: "If it costs $30 to make one EpiPen, but Mylan distributes two-packs to patients for $650, what are Mylan's profits in one year from selling EpiPens to 3.2 million patients?",
            choices: ['$1.88 million in one year', '$188 million in one year', '$1.88 billion in one year', '$188 billion in one year'],
            answerIdx: 2,    
            furtherInfo: {
                explanation: (
                    `EpiPens are auto-injectable devices that deliver the drug epinephrine, used to treat life-threatening allergic reactions. Patients at risk of anaphylactic shock are advised to carry an EpiPen at all times. Despite this, almost half the patients surveyed did not have one during their worst allergic reaction--even though the vast majority had been hospitalized for an allergy in the past. ($650 for a two-pack - $60 for the manufacturing cost of two pens) * 3.2 million patients = $1.88 billion in profit.`
                ),
                sources: [
                    {
                        source: 'Bustle',
                        url: 'https://www.bustle.com/articles/180800-how-many-people-use-epipens-in-america-mylans-price-increase-is-taking-advantage-of-its-users',
                    },
                ],
            },
        },
        {
            // question 11//
            question: "After years of litigation, the companies responsible for hiking EpiPen's price by 400% proposed a $345 million settlement. If their annual (net) profits from EpiPens alone was $1.88 billion, what percentage of just a single year's EpiPen profits could cover the settlement amount?",
            choices: ['1.8%', '18.4%', '184.6%', '1,846.8%'],
            answerIdx: 1,    
            furtherInfo: {
                explanation: (
                    `Investigators found that EpiPens cost $30 to make, and the medicine inside them is a generic drug that costs less than a dollar. In 2007, EpiPens sold for $50. That same year, Mylan purchased the product's rights and slowly raised the sales price to $650 for a two-pack.`
                ),
                sources: [
                    {
                        source: 'Kansas City Public Radio',
                        url: 'https://www.kcur.org/news/2021-07-15/pfizer-proposes-345-million-settlement-in-kansas-city-kansas-litigation-over-epipen-price-hikes',
                    },
                ],
            },
        },
    ],

};

export default pharmaceuticalRacketeering;