const fallaciouslyYours = { 
  name: "Fallaciously Yours", 
  id: 'D8O6dA7FycFh9IUcSWSg', 
  linkTo: "/quizzes/fallaciouslyYours", 
  explainerOn: true,
  subheading: `Did that study really come to that conclusion or is the article skewing the information for readership? Fallacies in data pose as facts. Can you spot them?`, 
  description: `More and more often our news is presented with a hefty dose of data analysis, but the analysis we receive isn't always fair and unbiased. Sometimes, the data itself is gathered in a misleading way. That is when it becomes critical to tell fallacy from fact. Can you spot a data fallacy when you see one?`, 
  questions: [
      { // Q-1
          question: "Carefully selecting data to support your claim creates what type of data fallacy?",
          choices: ['False Equivalence', 'Cherry Picking', 'Gerrymandering', 'This is not a fallacy'],
          answerIdx: 1,
          furtherInfo: {
              explanation: (
                  ``
              ),
              sources: [
                  {
                      source: "Visual Capitalist",
                      url: 'https://www.visualcapitalist.com/here-are-15-common-data-fallacies-to-avoid/?utm_source=Visual+Capitalist+Infographics+%28All%29&utm_campaign=21450911c9-EMAIL&utm_medium=email&utm_term=0_31b4d09e8a-21450911c9-45131207',
                  },
              ],
          },
      },
      { // Q-2
          question: "When you repeatedly test new hypotheses against the same set of data, without acknowledging that any correlation is most likely the result of chance, you are engaging in the fallacy of ______.",
          choices: ['Data Dredging', 'Red Herring', 'Hawthorne Effect', 'Overfitting'],
          answerIdx: 0,    
          furtherInfo: {
              explanation: (
                  ``
              ),
              sources: [
                {
                  source: "Visual Capitalist",
                  url: 'https://www.visualcapitalist.com/here-are-15-common-data-fallacies-to-avoid/?utm_source=Visual+Capitalist+Infographics+%28All%29&utm_campaign=21450911c9-EMAIL&utm_medium=email&utm_term=0_31b4d09e8a-21450911c9-45131207',
                },
              ],
          },
      },
      { // Q-3 
        question: "“100% of humans who drink water die, therefore water is fatal to humans” is an example of what kind of data fallacy?",
        choices: ['False Authority', 'False Dichotomy', 'False Causality', 'False Attribution'],
        answerIdx: 2,    
        furtherInfo: {
            explanation: (
                `This is the fallacy of False Causality, best combated by repeating the phrase "correlation does not equal causation" to yourself when you see data that claims to represent a cause and effect relationship. My example was pretty obviously false, but this fallacy goes unnoticed more than you might think.`
            ),
            sources: [
              {
                source: "Visual Capitalist",
                url: 'https://www.visualcapitalist.com/here-are-15-common-data-fallacies-to-avoid/?utm_source=Visual+Capitalist+Infographics+%28All%29&utm_campaign=21450911c9-EMAIL&utm_medium=email&utm_term=0_31b4d09e8a-21450911c9-45131207',
              },
            ],
        },
      },
      { // Q-4
        question: "Introducing an incentive that winds up producing the opposite of the intended result is an example of what type of fallacy?",
        choices: ['Feedback Fallacy', 'Inconsistent Comparison', 'Kettle Logic', 'Cobra Effect'],
        answerIdx: 3,    
        furtherInfo: {
            explanation: (
                `This is called the Cobra Effect, or Perverse Incentive, and it's a bit roundabout. The name comes from an anecdote in which the British instituted a bounty for dead cobras in India in an effort to curb the population of the deadly snake. However, this instead led to locals breeding cobras to profit from the system. When the program was shut down, the farmed cobras were released into the wild, increasing the overall population, and producing the exact opposite result that the bounty was meant to achieve.`
            ),
            sources: [
              {
                source: "Visual Capitalist",
                url: 'https://www.visualcapitalist.com/here-are-15-common-data-fallacies-to-avoid/?utm_source=Visual+Capitalist+Infographics+%28All%29&utm_campaign=21450911c9-EMAIL&utm_medium=email&utm_term=0_31b4d09e8a-21450911c9-45131207',
              },
            ],
        },
      },
      { // Q-5
        question: "A new study finds that all women love cats, but a closer look at the data reveals that the survey was taken at the International Conference for Women who Love Cats, meaning this study has fallen into what data fallacy?",
        choices: ['Availability Bias', 'Sampling Bias', 'False Authority', 'Blind Loyalty'],
        answerIdx: 1,    
        furtherInfo: {
            explanation: (
                `This is a pretty ridiculous example of Sampling Bias. Any time a conclusion is drawn from a data set that does not fully represent the population it is meant to understand, you are running head first into sampling bias.`
            ),
            sources: [
              {
                source: "Visual Capitalist",
                url: 'https://www.visualcapitalist.com/here-are-15-common-data-fallacies-to-avoid/?utm_source=Visual+Capitalist+Infographics+%28All%29&utm_campaign=21450911c9-EMAIL&utm_medium=email&utm_term=0_31b4d09e8a-21450911c9-45131207',
              },
            ],
        },
      },
      { // Q-6
        question: "The belief that because something has not happened yet it is more likely to happen in the future is what type of data fallacy?",
        choices: ["Gambler's Fallacy", 'Magical Thinking', 'Regression Bias', 'Red Herring'],
        answerIdx: 0,    
        furtherInfo: {
            explanation: (
                `This is known as the Gambler's Fallacy and it works in reverse, too. Just because the roulette wheel has yet to land on black doesn't mean it's more likely to on your next spin.`
            ),
            sources: [
              {
                source: "Visual Capitalist",
                url: 'https://www.visualcapitalist.com/here-are-15-common-data-fallacies-to-avoid/?utm_source=Visual+Capitalist+Infographics+%28All%29&utm_campaign=21450911c9-EMAIL&utm_medium=email&utm_term=0_31b4d09e8a-21450911c9-45131207',
              },
            ],
        },
      },
      { // Q-7
        question: "Manipulating the geographical boundaries used to group data creates what type of fallacy?",
        choices: ['Overfitting', 'Furtive Fallacy', 'Wrong Direction', 'Gerrymandering'],
        answerIdx: 3,    
        furtherInfo: {
            explanation: (
                `This is called Gerrymandering, and is probably most famous for its use by political parties to sway election results.`
            ),
            sources: [
              {
                source: "Visual Capitalist",
                url: 'https://www.visualcapitalist.com/here-are-15-common-data-fallacies-to-avoid/?utm_source=Visual+Capitalist+Infographics+%28All%29&utm_campaign=21450911c9-EMAIL&utm_medium=email&utm_term=0_31b4d09e8a-21450911c9-45131207',
              },
            ],
        },
      },
      { // Q-8
        question: "Interesting research findings are more likely to be published resulting in what fallacy?",
        choices: ['Continuum Fallacy', 'False Analogy', 'Publication Bias', 'Chronological Snobbery'],
        answerIdx: 2,    
        furtherInfo: {
            explanation: (
                `This is called Publication Bias, and since it ensures that interesting or unusual research is more likely to be published, it can warp our view of reality. An example is the frequency of published research indicating that certain factors increase your likelihood of developing a disease, compared with the infrequent publication of studies that show no correlation between those factors and risk of disease. The result is that readers overestimate how risky those factors actually are toward their health.`
            ),
            sources: [
              {
                source: "Visual Capitalist",
                url: 'https://www.visualcapitalist.com/here-are-15-common-data-fallacies-to-avoid/?utm_source=Visual+Capitalist+Infographics+%28All%29&utm_campaign=21450911c9-EMAIL&utm_medium=email&utm_term=0_31b4d09e8a-21450911c9-45131207',
              },
            ],
        },
      },
      { // Q-9
        question: "Conclusions drawn from an incomplete set of data because that data has survived the selection criteria are falling prey to what data fallacy?",
        choices: ['Projection Fallacy', 'Survivorship Bias', 'Bandwagon Fallacy', 'Attention Bias'],
        answerIdx: 1,    
        furtherInfo: {
            explanation: (
                `This is called Survivorship Bias, and happens when you draw conclusions from winner, or survivor, testimony because the testimony of those that lost or failed to survive is unavailable. If you want to become a successful entrepreneur you may study the lives and habits of entrepreneurs who have achieved success, however you are missing an entire set of data from those entrepreneurs who did not become successful.`
            ),
            sources: [
              {
                source: "Visual Capitalist",
                url: 'https://www.visualcapitalist.com/here-are-15-common-data-fallacies-to-avoid/?utm_source=Visual+Capitalist+Infographics+%28All%29&utm_campaign=21450911c9-EMAIL&utm_medium=email&utm_term=0_31b4d09e8a-21450911c9-45131207',
              },
            ],
        },
      },
      { // Q-10
        question: "Relying solely on quantitative factors and ignoring all pertinent qualitative factors is a hallmark of which data fallacy?",
        choices: ['McNamara Fallacy', 'Bulverism', 'Genetic Fallacy', 'Overwhelming Exception'],
        answerIdx: 0,    
        furtherInfo: {
            explanation: (
                `This is known as the McNamara Fallacy, named after Robert McNamara who infamously used the hard data metric of enemy body count to determine success in the Vietnam War.`
            ),
            sources: [
              {
                source: "Visual Capitalist",
                url: 'https://www.visualcapitalist.com/here-are-15-common-data-fallacies-to-avoid/?utm_source=Visual+Capitalist+Infographics+%28All%29&utm_campaign=21450911c9-EMAIL&utm_medium=email&utm_term=0_31b4d09e8a-21450911c9-45131207',
              },
            ],
        },
      },
  ],

};

export default fallaciouslyYours;