const americanInfrastructure = {
    name: 'The State of American Infrastructure',
    id: 'FLS4NvqTs3nRhBAvTbme',
    linkTo: "/quizzes/americanInfrastructure",
    explainerOn: true,
    description: "With President Biden’s massive plan to invest $2 trillion into American infrastructure one can’t help wondering: is such an investment necessary? According to the World Economic Forum, the U.S. is ranked 13th in its transport and utility infrastructure; a list which China tops. But what do these figures mean for the everyday lives of Americans?",
    questions: [
        {
            //question 1//
            question: "The American Society of Civil Engineers has been issuing report cards on American infrastructure since 1998. What grade did it receive in 2020?",
            choices: ['B-', 'C+', 'C-', 'D+'],
            answerIdx: 2,    
            furtherInfo: {
                explanation: (
                    `In 2020 American infrastructure received a grade of C-minus from the ASCE, which was actually an improvement over the past two decades of Ds.`
                ),
                sources: [
                    {
                        source: 'American Society of Civil Engineers',
                        url: 'https://infrastructurereportcard.org/',
                    },
                ],
            },
        },
        {
            //question 2//
           question: "Of the 17 categories that make up the ASCE’s report card, in how many of them is America still receiving grades in the Ds?",
            choices: ['2', '6', '11', '15'],
            answerIdx: 2,    
            furtherInfo: {
                explanation: (
                    `17 are averaged to create the ASCE’s overall grade, and of those 17 categories, 11 still have grades in the D range.`
                ),
                sources: [
                    {
                        source: 'American Society of Civil Engineers',
                        url: 'https://infrastructurereportcard.org/',
                    },
                ],
            },
        },
        {
            //question 3//
            question: "In the U.S. there is a break in a water main every 2 minutes wasting how many gallons of treated water each day?",
            choices: ['6,000', '600,000', '60,000,000', '6,000,000,000'],
            answerIdx: 3,    
            furtherInfo: {
                explanation: (
                    `Each day 6 billion gallons of treated water are lost due to breaks in water mains, which is enough to fill over 9,000 swimming pools.`
                ),
                sources: [
                    {
                        source: 'American Society of Civil Engineers',
                        url: 'https://infrastructurereportcard.org/',
                    },
                ],
            },
        },
        {
            //question 4//
            question: "What percent of U.S. public roadways are rated in ‘poor’ or ‘mediocre’ condition?",
            choices: ['3%', '23%', '43%', '73%'],
            answerIdx: 2,    
            furtherInfo: {
                explanation: (
                    `43% of public roadways in the U.S. are in ‘poor’ or ‘mediocre’ condition.`
                ),
                sources: [
                    {
                        source: 'American Society of Civil Engineers',
                        url: 'https://infrastructurereportcard.org/',
                    },
                ],
            },
        },
        {
            //question 5//
            question: "How many trips are taken daily across bridges deemed structurally deficient in the U.S.?",
            choices: ['178,000,000', '17,800,000', '178,000', '17,800'],
            answerIdx: 0,    
            furtherInfo: {
                explanation: (
                    `On average in the U.S., 178 million trips are taken across the 46,000 bridges deemed structurally deficient every day.`
                ),
                sources: [
                    {
                        source: 'American Society of Civil Engineers: Overview of Bridges',
                        url: 'https://infrastructurereportcard.org/cat-item/bridges/',
                    },
                ],
            },
        },
        {
            //question 6//
            question: "How much in deferred maintenance backlog are local parks across the U.S. currently facing?",
            choices: ['$600,000,000,000', '$60,000,000,000', '$600,000,000', '$60,000,000'],
            answerIdx: 1,    
            furtherInfo: {
                explanation: (
                    `Local parks currently face a $60 billion maintenance backlog, while state parks face a $5.6 billion backlog.`
                ),
                sources: [
                    {
                        source: 'American Society of Civil Engineers: Public Parks Overview',
                        url: 'https://infrastructurereportcard.org/cat-item/public-parks/',
                    },
                ],
            },
        },
        {
            //question 7//
            question: "There are approximately how many public sites contaminated with hazardous materials where cleanup activities are either incomplete or have yet to start?",
            choices: ['13', '130', '1,300', '13,000'],
            answerIdx: 2,    
            furtherInfo: {
                explanation: (
                    `There are approximately 1,300 contaminated public sites where cleanup activities are either incomplete or have not been started, which is roughly the same number as four years ago.`
                ),
                sources: [
                    {
                        source: 'American Society of Civil Engineers: Hazardous Waste Overview',
                        url: 'https://infrastructurereportcard.org/cat-item/hazardous-waste/',
                    },
                ],
            },
        },
        {
            //question 8//
            question: "Over the next 20 years, how much loss could the U.S. see in exports due to aging or insufficient infrastructure?",
            choices: ['$2,400,000,000,000', '$240,000,000,000', '$2,400,000,000', '$240,000,000'],
            answerIdx: 0,    
            furtherInfo: {
                explanation: (
                    `According to the ASCE, the U.S. could lose $2.4 trillion in exports over the next 20 years if its infrastructure is not improved.`
                ),
                sources: [
                    {
                        source: 'American Society of Civil Engineers',
                        url: 'https://infrastructurereportcard.org/',
                    },
                ],
            },
        },
        {
            //question 9//
            question: "How much loss in its gross domestic product could the U.S. see by 2039 if the problems in its infrastructure are not addressed?",
            choices: ['$10,000', '$10,000,000', '$10,000,000,000', '$10,000,000,000,000'],
            answerIdx: 3,    
            furtherInfo: {
                explanation: (
                    `The ASCE estimates the U.S. could see a loss of $10 trillion in its GDP by 2039 if its infrastructure is not improved.`
                ),
                sources: [
                    {
                        source: 'American Society of Civil Engineers',
                        url: 'https://infrastructurereportcard.org/',
                    },
                ],
            },
        },
        {
            //question 10//
            question: "If the problems in U.S. infrastructure are not addressed, how much disposable income will be lost, per person, each year between now and 2039?",
            choices: ['$33', '$330', '$3,300', '$33,000'],
            answerIdx: 2,    
            furtherInfo: {
                explanation: (
                    `Between now and 2039, the ASCE estimates an average of $3,300 in disposable income will be lost each year due to poorly functioning infrastructure.`
                ),
                sources: [
                    {
                        source: 'American Society of Civil Engineers',
                        url: 'https://infrastructurereportcard.org/',
                    },
                ],
            },
        },
    ],

};

export default americanInfrastructure;