import React, { useState, useEffect } from "react";
import "../assets/designs/myScore.css";
import quizList from "../quizList";
import { NavbarData as Navbar } from "./Navbar";
import BottomNav from "./BottomNav";
import Replay from "../assets/icons/Quizicist Icons/Replay-01.svg";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import RibbonIcon from "../assets/icons/Quizicist Icons/Ribbon-01.svg";
import useWindowDimensions from "../utils/useWindowDimensions";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Star from "../assets/icons/Quizicist Icons/star.svg";
import Flag from "../assets/icons/Quizicist Icons/flag.svg";
import { XYPlot, LineSeries, MarkSeries, LabelSeries, Hint } from "react-vis";
import { Link } from "react-router-dom";
import ProfilePic from "./profile/ProfilePic";

import {
  getTotalPointsForUser,
  getQuizScoresForUser,
  getQuizScoreByQuizIdAndUser,
} from "../firestore/user";
import {
  getUsersWithTopScoresForQuiz,
  getTopScoreForQuizForUser,
  getRankFromScore,
  getOneHigherScore,
  getOneLowerScore,
  getTopScoresForScore,
} from "../firestore/quiz";
import { useFirestore, useUser } from "reactfire";
import useDeviceDetect from "../hooks/useDeviceDetect";
import FeedbackButton from "./FeedbackButton";

const MyScoreData = () => {
  const challengeQuizzes = quizList.filter((quiz) => quiz.challenge);
  const firestore = useFirestore();
  const user = useUser();

  const [totalPoints, setTotalPoints] = useState(0);
  const [gettingTotalPoints, setGettingTotalPoints] = useState(false);
  useEffect(
    function fetchTotalPoints() {
      if (!firestore || !user || !user.data || gettingTotalPoints) {
        return;
      }

      const { uid } = user.data;

      const getTotalPoints = async () => {
        const newTotalPts = await getTotalPointsForUser(firestore, uid);
        setTotalPoints(newTotalPts);
      };

      setGettingTotalPoints(true);
      getTotalPoints();
    },
    [firestore, user, gettingTotalPoints]
  );

  const [quizzes, setQuizzes] = useState([]);
  const [gettingQuizzes, setGettingQuizzes] = useState(false);
  useEffect(
    function fetchRecentQuizzes() {
      if (!firestore || !user || !user.data || gettingQuizzes) {
        return;
      }
      const { uid } = user.data;

      const getRecentQuizzes = async () => {
        const quizScores = await getQuizScoresForUser(firestore, uid, {
          limit: 10,
        });
        setQuizzes(quizScores.docs.map((doc) => doc.data()));
      };

      setGettingQuizzes(true);
      getRecentQuizzes();
    },
    [firestore, user, gettingQuizzes]
  );

  const [selectedTopScoreQuizId, setSelectedTopScoreQuizId] = useState(
    "oneThousandOrNot"
  );
  const [top3Scorers, setTop3Scorers] = useState([]);
  const [gettingTop3Scorers, setGettingTop3Scorers] = useState(false);
  useEffect(
    function fetchTop3Scorers() {
      if (gettingTop3Scorers) {
        return;
      }
      setGettingTop3Scorers(true);

      const getTopScorers = async () => {
        const top3ScorersData = await getUsersWithTopScoresForQuiz(
          firestore,
          selectedTopScoreQuizId
        );
        setTop3Scorers(top3ScorersData);
      };

      getTopScorers();
    },
    [firestore, selectedTopScoreQuizId, gettingTop3Scorers]
  );

  const [userRankings, setUserRankings] = useState([]);
  const [gettingRankings, setGettingRankings] = useState(false);
  useEffect(
    function fetchUserRankings() {
      if (!firestore || !user || !user.data || gettingRankings) {
        return;
      }

      const newRankings = [];
      const { uid } = user.data;

      const getRankingData = async () => {
        const topScoreQuerySnapshot = await getTopScoreForQuizForUser(
          firestore,
          selectedTopScoreQuizId,
          uid
        );
        const userTopScore = topScoreQuerySnapshot.data();
        if (!userTopScore) {
          setUserRankings([]);
          return;
        }

        const userRank = await getRankFromScore(
          firestore,
          selectedTopScoreQuizId,
          userTopScore.score
        );

        // If the user is ranked in top 3, we just display the top 3 scores only
        if ([1, 2, 3].includes(userRank)) {
          // TODO What if there is more than one user with the same rank?
          return;
        }
        userTopScore.rank = userRank;
        newRankings.push(userTopScore);

        // NOTE Not an exact ranking. Doesn't account for users that have the same
        // score as current user. Just trys to find a user that has a higher score
        // than current user.
        let higherScore;
        if (userRank > 4) {
          higherScore = await getOneHigherScore(
            firestore,
            selectedTopScoreQuizId,
            userTopScore.score
          );
        }

        if (higherScore) {
          const higherScoreSnapshot = await getTopScoresForScore(
            firestore,
            selectedTopScoreQuizId,
            higherScore,
            { limit: 1 }
          );
          if (!higherScoreSnapshot.empty) {
            const higherScorer = higherScoreSnapshot.docs[0].data();
            higherScorer.rank = await getRankFromScore(
              firestore,
              selectedTopScoreQuizId,
              higherScore
            );
            newRankings.unshift(higherScorer);
          }
        }

        // NOTE Same notes for higher score implementation apply here
        const lowerScore = await getOneLowerScore(
          firestore,
          selectedTopScoreQuizId,
          userTopScore.score
        );

        if (lowerScore) {
          const lowerScoreSnapshot = await getTopScoresForScore(
            firestore,
            selectedTopScoreQuizId,
            lowerScore,
            { limit: 1 }
          );

          if (!lowerScoreSnapshot.empty) {
            const lowerScorer = lowerScoreSnapshot.docs[0].data();
            lowerScorer.rank = await getRankFromScore(
              firestore,
              selectedTopScoreQuizId,
              lowerScore
            );
            newRankings.push(lowerScorer);
          }
        }

        setUserRankings(newRankings);
      };

      setGettingRankings(true);
      getRankingData();
    },
    [firestore, user, gettingRankings, selectedTopScoreQuizId]
  );

  const handleSelectTopScoreQuiz = (quizId) => {
    setSelectedTopScoreQuizId(quizId);
    setGettingRankings(false);
    setGettingTop3Scorers(false);
  };

  const [quizHistoryScores, setQuizHistoryScores] = useState([]);
  const handleSelectQuiz = (quizId) => {
    const { uid } = user.data;
    getQuizScoresForUser(firestore, uid, quizId);

    const getQuizHistory = async () => {
      const querySnapshot = await getQuizScoreByQuizIdAndUser(
        firestore,
        uid,
        quizId
      );
      const newQuizHistoryScores = querySnapshot.docs.map((doc) => doc.data());
      setQuizHistoryScores(newQuizHistoryScores.reverse());
    };

    getQuizHistory();
  };

  return (
    <MyScore
      score={totalPoints}
      quizzes={quizzes}
      onSelectQuiz={handleSelectQuiz}
      selectedQuizHistory={quizHistoryScores}
      challengeQuizzes={challengeQuizzes}
      selectedTopScoreQuizId={selectedTopScoreQuizId}
      onSelectTopScoreQuiz={handleSelectTopScoreQuiz}
      top3Scorers={top3Scorers}
      userRankings={userRankings}
    />
  );
};

function MyScore({
  score,
  quizzes,
  onSelectQuiz,
  selectedQuizHistory,
  challengeQuizzes,
  selectedTopScoreQuizId,
  onSelectTopScoreQuiz,
  top3Scorers,
  userRankings,
}) {
  const { width } = useWindowDimensions();
  const {isMobile} = useDeviceDetect();
  const [current, setCurrent] = useState("score");
  // eslint-disable-next-line
  const [myAchivements, setMyAchivements] = useState([
    { text: "Player 1st Quiz" },
    { text: "Player 1st Quiz" },
    { text: "Player 1st Quiz" },
    { text: "Player 1st Quiz" },
    { text: "Player 1st Quiz" },
    { text: "Player 1st Quiz" },
    { text: "Player 1st Quiz" },
    { text: "Player 1st Quiz" },
    { text: "Player 1st Quiz" },
    { text: "Player 1st Quiz" },
    { text: "Player 1st Quiz" },
    { text: "Player 1st Quiz" },
    { text: "Player 1st Quiz" },
  ]);
  const [selectedQuizName, setSelectedQuizName] = useState("");
  const [value, setValue] = useState(null);

  const _forgetValue = () => {
    setValue(null);
  };

  const _rememberValue = (value) => {
    setValue(value);
  };

  const getScores = () => (
    <>

      <div className="myscore-box-totalscore">
        <span>{score.toLocaleString()}</span>
        <span>TOTAL POINTS EARNED</span>
      </div>
      <div className="myscore-recents">
        <span>RECENT QUIZZES TAKEN</span>
        <div className="myscore-recents-list">
          {quizzes.map((q) => (
            <div
              key={q.createdAt}
              className="myscore-recents-list-item"
              style={{ cursor: "pointer" }}
            >
              <span
                onClick={() => {
                  setSelectedQuizName(q.quizName);
                  onSelectQuiz(q.quizId);
                  setCurrent("showCurrentAnswer");
                }}
              >
                <span>{q.quizName}</span>
                <span>{q.score.toLocaleString()}pts</span>
              </span>
              {/* <Link to={`/quizzes/${q.quizId}`}> */}
              <Link to={q.linkTo}>
                <img
                  src={Replay}
                  alt="replay icon"
                  height="30px"
                  style={{ marginLeft: "5px" }}
                />
              </Link>
            </div>
          ))}
        </div>
      </div>
    </>
  );

  const firstPlace = top3Scorers[0] || {};
  const secondPlace = top3Scorers[1] || {};
  const thirdPlace = top3Scorers[2] || {};
  const renderTopScores = (score) => score && score.toLocaleString();
  const topScore = () => (
    <>
      <Select
        labelId="demo-simple-select-outlined-label"
        id="demo-simple-select-outlined"
        onChange={(e) => onSelectTopScoreQuiz(e.target.value)}
        label="names"
        value={selectedTopScoreQuizId}
        style={{ width: "100%", marginTop: "10px" }}
      >
        {challengeQuizzes.map((quiz) => (
          <MenuItem key={quiz.id} value={quiz.id}>
            {quiz.name}
          </MenuItem>
        ))}
      </Select>
      <div className="topscore-ranks">
        <div className="topscore-ranks-second">
          <div className="topscore-user-photo">
            <ProfilePic
              src={secondPlace.userPhotoURL}
              alt="2nd-place"
              size={15}
            />
          </div>
          <div
            className="topscore-ranks-third-pst"
            style={{ position: "relative" }}
          >
            <img
              src={Flag}
              style={{
                height: "40px",
                position: "absolute",
                top: "0",
                left: "0",
              }}
              alt=""
            />
            <span
              style={{
                fontSize: "12px",
                fontWeight: "500",
                height: "40px",
                position: "absolute",
                top: "10px",
                left: "4px",
                color: "#1379f7",
              }}
            >
              2nd
            </span>
            <span>{renderTopScores(secondPlace.score)} pts</span>
            <span>{secondPlace.userUsername}</span>
          </div>
        </div>
        <div className="topscore-first">
          <div className="topscore-user-photo">
            <ProfilePic
              src={firstPlace.userPhotoURL}
              alt="1st-place"
              size={15}
            />
          </div>
          <div
            className="topscore-ranks-third-pst"
            style={{ position: "relative" }}
          >
            <img
              src={Star}
              style={{ height: "55px", position: "absolute", top: "0" }}
              alt=""
            />
            <span
              style={{
                fontSize: "12px",
                fontWeight: "500",
                position: "absolute",
                top: "18px",
                color: "#1379f7",
              }}
            >
              1st
            </span>
            <span>{renderTopScores(firstPlace.score)} pts</span>
            <span>{firstPlace.userUsername}</span>
          </div>
        </div>
        <div className="topscore-ranks-third">
          <div className="topscore-user-photo">
            <ProfilePic
              src={thirdPlace.userPhotoURL}
              alt="3rd-place"
              size={15}
            />
          </div>
          <div
            className="topscore-ranks-third-pst"
            style={{ position: "relative" }}
          >
            <img
              src={Flag}
              style={{
                height: "40px",
                position: "absolute",
                top: "0",
                left: "0",
              }}
              alt=""
            />
            <span
              style={{
                fontSize: "12px",
                fontWeight: "500",
                height: "40px",
                position: "absolute",
                top: "10px",
                left: "4px",
                color: "#1379f7",
              }}
            >
              3rd
            </span>
            <span>{renderTopScores(thirdPlace.score)} pts</span>
            <span>{thirdPlace.userUsername}</span>
          </div>
        </div>
      </div>
      <div className="topscore-list">
        {userRankings.map((userRank) => (
          <div key={userRank.rank} className="topscore-list-items">
            <div className="topscore-list-item-ranks">{userRank.rank}th</div>
            <span
              style={{
                display: "flex",
                alignItems: "center",
                marginLeft: "40px",
              }}
            >
              {" "}
              <ProfilePic
                src={userRank.userPhotoURL}
                alt={`${userRank.rank}th-place`}
                size={5}
              />
              <div style={{ marginLeft: 10 }}>{userRank.userUsername}</div>
            </span>
            <span>{userRank.score} pts</span>
          </div>
        ))}
      </div>
    </>
  );
  const achivementScore = () => (
    <>
      <div className="achivementScore-heading">QUIZ ACHIEVEMENTS</div>
      <div className="achivementScore-list">
        {myAchivements.map((i) => (
          <div className="achivement-unlocked-box">
            <img
              src={RibbonIcon}
              alt=""
              className="achivement-unlocked-box-icon"
            />
            <span className="achivement-unlocked-box-text">{i.text}</span>
          </div>
        ))}
      </div>
    </>
  );

  const selectedQuizAnswer = () => (
    <div style={{ textAlign: "center" }}>
      <ArrowBackIosIcon
        onClick={() => setCurrent("score")}
        style={{
          color: "#fff",
          fontSize: "30px",
          float: "left",
          cursor: "pointer",
        }}
      />
      <h3
        style={{
          fontWeight: "500",
          color: "#fff",
          padding: 0,
          fontSize: "22px",
        }}
      >
        {selectedQuizName}
      </h3>
      <div
        className="myscore-box-totalscore"
        style={{ backgroundColor: "#1379f7", border: "2px solid #fff" }}
      >
        <p>Progress Report</p>
        <XYPlot width={width < 700 ? 280 : 460} height={250}>
          <LabelSeries
            animation
            allowOffsetToBeReversed
            data={selectedQuizHistory.map((f, i) => ({
              x: i,
              y: f.score,
              label:
                i === 0
                  ? `${f.score}`
                  : i === selectedQuizHistory.length - 1
                  ? `${f.score}`
                  : "",
              yOffset: -6,
              xOffset: 6,
            }))}
          />
          <LineSeries
            curve={null}
            data={selectedQuizHistory.map((f, i) => ({
              x: i,
              y: f.score,
            }))}
            opacity={1}
            stroke="#fff"
            strokeDasharray=""
            strokeStyle="solid"
            style={{}}
          />
          <MarkSeries
            data={selectedQuizHistory.map((f, i) => ({
              x: i,
              y: f.score,
            }))}
            stroke="#fff"
            fill="#fff"
            onValueMouseOver={_rememberValue}
            onValueMouseOut={_forgetValue}
          />
          {value ? (
            <Hint value={value}>
              <div
                style={{
                  backgroundColor: "#68b1f9",
                  color: "#fff",
                  padding: "5px",
                  borderRadius: "10px",
                }}
              >
                <div>Points: {value.y}</div>
              </div>
            </Hint>
          ) : null}
        </XYPlot>
      </div>
      <div className="myscore-recents" style={{ textAlign: "left" }}>
        <span style={{ textTransform: "uppercase" }}>Recent quizzes taken</span>
        <div className="myscore-recents-list">
          {/* reason to use slice is to make copy of array before reversing it */}
          {selectedQuizHistory
            .slice(0)
            .reverse()
            .map((q) => (
              <div key={q.createdAt} className="myscore-recents-list-item">
                <span>{q.quizName}</span>
                <span style={{ display: "flex", alignItems: "center" }}>
                  <span>{q.score}pts</span>
                </span>
              </div>
            ))}
        </div>
      </div>
    </div>
  );

  return (
    <>
      <FeedbackButton />
      <Navbar showArrow={true} />
      <div className="myscore-page">
        <div className="myscore-box">
          <div className="myscore-box-topbar">
            <button
              onClick={() => setCurrent("score")}
              style={{
                backgroundColor:
                  current === "score"
                    ? "rgba(255, 255, 255, 0.418)"
                    : "transparent",
              }}
            >
              My Scores
            </button>
            <button
              onClick={() => setCurrent("top")}
              style={{
                backgroundColor:
                  current === "top"
                    ? "rgba(255, 255, 255, 0.418)"
                    : "transparent",
              }}
            >
              Top Scores
            </button>
          </div>
          {/* scores */}
          {current === "score" && getScores()}
          {current === "showCurrentAnswer" && selectedQuizAnswer()}
          {current === "top" && topScore()}
          {current === "achievements" && achivementScore()}
        </div>
        {(width <= 1024 && isMobile) ? <BottomNav /> : ""}
      </div>
    </>
  );
}

export default MyScoreData;
