const trackingId = "G-12EKT24085";

function sendAnalyticsEvent(action, options) {
  if (typeof window !== `undefined` && window.gtag) {
    const gtag = window.gtag;
    gtag("event", action, options);
  }
}

function sendPageView(pagePath) {
  if (typeof window !== `undefined` && window.gtag) {
    const gtag = window.gtag;
    gtag("config", trackingId, { page_path: pagePath || window.location.pathname });
  }
}

function sendViewQuiz(quizUrl) {
  sendAnalyticsEvent("view_quiz", {
    event_category: "engagement",
    event_label: quizUrl,
  });
}

function sendPlayQuiz(quizName) {
  sendAnalyticsEvent("play_quiz", {
    event_category: "engagement",
    event_label: quizName,
  });
}

function sendFinishQuiz(quizName) {
  sendAnalyticsEvent("finish_quiz", {
    event_category: "engagement",
    event_label: quizName,
  });
}

function sendShareQuiz(quizName) {
  sendAnalyticsEvent("share_quiz", {
    event_category: "engagement",
    event_label: quizName,
  });
}

export {
  sendAnalyticsEvent,
  sendViewQuiz,
  sendPlayQuiz,
  sendFinishQuiz,
  sendShareQuiz,
  sendPageView,
};
export default sendAnalyticsEvent;
