import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import useWindowDimensions from "../utils/useWindowDimensions";
import { Box } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    background:
      "radial-gradient(circle, #07BBF4 0%, #08B8F5 0.5%, #3124FB 100%)",
    minHeight: ({ windowHeight }) => windowHeight,
  },
  flexbox: {
    minHeight: "100vh",
  },
  countdownText: {
    color: "#fff",
    fontSize: "25vh",
  },
}));

const Countdown = ({ onCountdownFinish, countdownTime }) => {
  const [countdown, setCountdown] = React.useState(countdownTime || 3);
  const { height } = useWindowDimensions();

  const classes = useStyles({
    windowHeight: height,
  });

  useEffect(function setupCountdownInterval() {
    let interval = null;

    interval = setInterval(() => {
      setCountdown((seconds) => seconds - 1);
    }, 1000); // milliseconds

    return () => clearInterval(interval);
  }, []);

  useEffect(function teardownCountdown() {
    if (countdown <= 0) {
      onCountdownFinish();
    }
  }, [countdown, onCountdownFinish]);

  return (
    <div className={classes.root}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        className={classes.flexbox}
      >
        <span className={classes.countdownText}>{countdown}</span>
      </Box>
    </div>
  );
};

export default Countdown;
