const apolloProgram = {
    name: 'The Apollo Program: in 10 Numbers',
    id: 'Do8YFnDhRjXHL50x7uzV',
    linkTo: "/quizzes/apolloProgram",
    explainerOn: true,
    description: 'A political challenge to the Russians, a marvel of scientific achievement, a testament to human curiosity; the Apollo program was many things to many people. It lives on in our collective memory, encapsulating a sense of wonder about the universe often lost to modern audiences. Looking back, what do these historic missions look like in numbers?',
    questions: [
        {
            //question 1//
            question: "How many seeds were carried into space by the Apollo missions?",
            choices: ['5', '50', '500', '5,000'],
            answerIdx: 2,    
            furtherInfo: {
                explanation: (
                    `500 seeds were flown to the Moon on Apollo 14; trees planted from the seeds of this mission became known as Moon Trees.`
                ),
                sources: [
                    {
                        source: 'BBC',
                        url: 'https://www.bbc.com/future/article/20190716-apollo-in-50-numbers-oddities',
                    },
                ],
            },
        },
        {
            //question 2//
            question: "Approximately how many images of Earthrise were taken by the Apollo missions?",
            choices: ['240', '2,400', '24,000', '240,000'],
            answerIdx: 1,    
            furtherInfo: {
                explanation: (
                    `2,400 images of Earthrise by the Apollo missions.`
                ),
                sources: [
                    {
                        source: 'BBC',
                        url: 'https://www.bbc.com/future/article/20190725-apollo-in-50-numbers-the-full-list',
                    },
                ],
            },
        },
        {
            //question 3//
            question: "At approximately what distance, in miles, from Earth was the iconic Blue Marble image taken?",
            choices: ['280', '2,800', '28,000', '280,000'],
            answerIdx: 2,    
            furtherInfo: {
                explanation: (
                    `The Blue Marble image was taken 28,000 miles from Earth.`
                ),
                sources: [
                    {
                        source: 'BBC',
                        url: 'https://www.bbc.com/future/article/20190725-apollo-in-50-numbers-the-full-list',
                    },
                ],
            },
        },
        {
            //question 4//
            question: "What was the cost of Neil Armstrong’s spacesuit?",
            choices: ['$1,000', '$10,000', '$100,000', '$1,000,000'],
            answerIdx: 2,    
            furtherInfo: {
                explanation: (
                    `Neil Armstrong’s spacesuit cost $100,000`
                ),
                sources: [
                    {
                        source: 'BBC',
                        url: 'https://www.bbc.com/future/article/20190725-apollo-in-50-numbers-the-full-list',
                    },
                ],
            },
        },
        {
            //question 5//
            question: "In miles, what is the approximate distance from Earth to the Moon?",
            choices: ['240', '2,400', '24,000', '240,000'],
            answerIdx: 3,    
            furtherInfo: {
                explanation: (
                    `The Moon is approximately 240,000 miles from Earth.`
                ),
                sources: [
                    {
                        source: 'BBC',
                        url: 'https://www.bbc.com/future/article/20190725-apollo-in-50-numbers-the-full-list',
                    },
                ],
            },
        },
        {
            //question 6//
            question: "Approximately how large was the total Apollo workforce?",
            choices: ['4,000', '40,000', '400,000', '4,000,000'],
            answerIdx: 2,    
            furtherInfo: {
                explanation: (
                    `At its height, the Apollo workforce encompassed approximately 400,000 workers.`
                ),
                sources: [
                    {
                        source: 'BBC',
                        url: 'https://www.bbc.com/future/article/20190725-apollo-in-50-numbers-the-full-list',
                    },
                ],
            },
        },
        {
            //question 7//
            question: "In Newtons, how much thrust did the Saturn V rocket have at lift-off?",
            choices: ['35,000', '350,000', '3,500,000', '35,000,000'],
            answerIdx: 3,    
            furtherInfo: {
                explanation: (
                    `During lift-off, the thrust of the Saturn V rocket measured at 35,000,000 Newtons, the absolute unit of force in the International System of Units.`
                ),
                sources: [
                    {
                        source: 'BBC',
                        url: 'https://www.bbc.com/future/article/20190725-apollo-in-50-numbers-the-full-list',
                    },
                ],
            },
        },
        {
            //question 8//
            question: "What was the cost of the lunar lander program?",
            choices: ['$388,000', '$3,880,000', '$38,800,000', '$388,000,000'],
            answerIdx: 3,    
            furtherInfo: {
                explanation: (
                    `The Grumman Corporation was contracted to build the lunar landers for $388,000,000.`
                ),
                sources: [
                    {
                        source: 'BBC',
                        url: 'https://www.bbc.com/future/article/20190712-apollo-in-50-numbers-the-cost',
                    },
                ],
            },
        },
        {
            //question 9//
            question: "Globally, how many viewers watched Neil Armstrong take his Small Step on television?",
            choices: ['600,000', '6,000,000', '60,000,000', '600,000,000'],
            answerIdx: 3,    
            furtherInfo: {
                explanation: (
                    `Approximately 600,000,000 people (out of a global population of 3.5 billion)`
                ),
                sources: [
                    {
                        source: 'Population Pyramid',
                        url: 'https://www.populationpyramid.net/world/1969/',
                    },
                ],
            },
        },
        {
            //question 10//
            question: "What was the total approximate cost of the Apollo program?",
            choices: ['$2,500,000', '$25,000,000', '$250,000,000', '$25,000,000,000'],
            answerIdx: 3,    
            furtherInfo: {
                explanation: (
                    `The total approximate cost of the Apollo program was $25,000,000,000 in 1969, the equivalent of which today would be $175,000,000,000.`
                ),
                sources: [
                    {
                        source: 'BBC',
                        url: 'https://www.bbc.com/future/article/20190712-apollo-in-50-numbers-the-cost',
                    },
                ],
            },
        },
    ],

};

export default apolloProgram;