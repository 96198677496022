const turkeyTalk = {
  name: "Turkey Talk", 
  id: 'KDCYcMtHAlLWuup9mChy', 
  linkTo: "/quizzes/turkeyTalk",
  explainerOn: true, 
  subheading: `Turkey is the dish of choice for Thanksgiving in the United States. But what's the word on this feathery native of North American?`,
  description: `In the United States, the third Thursday of every November is Thanksgiving. And for those who celebrate, turkey remains the most popular main course. Whether deep-fried or stuffed and oven-baked, this bird remains a holiday tradition. But how much do you know about turkey?`,
  questions: [
      { // Q-1
          question: "Benjamin Franklin advocated for the turkey to be the national bird of the United States.",
          choices: ['True', 'False'],
          answerIdx: 1,
          furtherInfo: {
              explanation: (
                  `This is false! While Franklin opposed the bald eagle as the national bird, calling it a "bird of bad moral character" and applauded the turkey as a "bird of courage," Franklin never actually advocated for the turkey to be named the national bird of the United States.`
              ),
              sources: [
                  {
                      source: "Audubon Society",
                      url: 'https://www.audubon.org/news/9-fun-facts-about-turkeys',
                  },
              ],
          },
      },
      { // Q-2
          question: "What is a male turkey called?",
          choices: ['a bull', 'a rooster', 'a tom', 'a male turkey'],
          answerIdx: 2,    
          furtherInfo: {
              explanation: (
                  `A male turkey is called a tom.`
              ),
              sources: [
                {
                  source: "U.S. Fish and Wildlife",
                  url: 'https://www.fws.gov/refuges/features/WildFacts.html',
                },
              ],
          },
      },
      { // Q-3 
        question: "How much does the average wild turkey weigh?",
        choices: ['20 lbs', '40 lbs', '60 lbs', '80 lbs'],
        answerIdx: 0,    
        furtherInfo: {
            explanation: (
                `The average wild turkey weighs about 20 pounds.`
            ),
            sources: [
              {
                source: "",
                url: '',
              },
            ],
        },
      },
      { // Q-4
        question: "Approximately how many wild turkeys are in North America today?",
        choices: ['6,000', '60,000', '600,000', '6,000,000'],
        answerIdx: 3,    
        furtherInfo: {
            explanation: (
                `There are approximately 6 million wild turkeys in North America today, which is a marked success for conservationists since at the beginning of the 20th century the wild turkey population had dipped below 200,000.`
            ),
            sources: [
              {
                source: "Washington Post",
                url: 'https://www.washingtonpost.com/news/animalia/wp/2017/11/22/heres-the-deal-with-all-those-turkeys-terrorizing-the-suburbs/',
              },
            ],
        },
      },
      { // Q-5
        question: "When Western colonists first arrived in North America, it is estimated that there were approximately 10 million wild turkeys, which is how many times more than the current population of about 6 million?",
        choices: ['1.7', '7.1', '17', '170'],
        answerIdx: 0,    
        furtherInfo: {
            explanation: (
                `The historic turkey population of approximately 10 million is about 1.7 times higher than the modern turkey population of 6 million.`
            ),
            sources: [
              {
                source: "Bloomberg",
                url: 'https://www.bloomberg.com/news/articles/2019-11-26/turkeys-in-your-neighborhood-get-used-to-it',
              },
            ],
        },
      },
      { // Q-6
        question: "Turkeys are found and hunted in every state except ______ ?",
        choices: ['Hawaii', 'Alaska', 'Arizona', 'Puerto Rico'],
        answerIdx: 1,    
        furtherInfo: {
            explanation: (
                `Today turkeys are found, and hunted, in every state except Alaska.`
            ),
            sources: [
              {
                source: "Washington Post",
                url: 'https://www.washingtonpost.com/news/animalia/wp/2017/11/22/heres-the-deal-with-all-those-turkeys-terrorizing-the-suburbs/',
              },
            ],
        },
      },
      { // Q-7
        question: "Wild turkeys can fly approximately how many miles per hour?",
        choices: ['20', '40', '60', '80'],
        answerIdx: 2,    
        furtherInfo: {
            explanation: (
                `They may look rotund but turkeys are surprisingly fast and can fly at speeds of up to 60 miles per hour.`
            ),
            sources: [
              {
                source: "Audubon Society",
                url: 'https://www.audubon.org/news/9-fun-facts-about-turkeys',
              },
            ],
        },
      },
      { // Q-8
        question: "How can you scare away a wild turkey if you happen to meet one?",
        choices: ['Yell', 'Squirt it with a water gun', 'Shoo it off with a broom', 'All of the Above'],
        answerIdx: 3,    
        furtherInfo: {
            explanation: (
                `Turkeys are social birds, and when they make themselves at home in human environments instead of viewing us as predators, they tend to integrate us into their pecking order. If a wild turkey is making a nuisance of itself you can assert your dominance and scare it off in a variety of ways, including yelling, squirting it with a water gun, and shooing it off with a broom.`
            ),
            sources: [
              {
                source: "Washington Post",
                url: 'https://www.washingtonpost.com/news/animalia/wp/2017/11/22/heres-the-deal-with-all-those-turkeys-terrorizing-the-suburbs/',
              },
            ],
        },
      },
      { // Q-9
        question: "What can you tell from a turkey's droppings?",
        choices: ['Sex', 'Age', 'Both A and B', 'Habitat range'],
        answerIdx: 2,    
        furtherInfo: {
            explanation: (
                `You can tell a lot from a turkey's droppings, like its age and sex. A male's droppings have a distinctive 'J' shape while a female's are spiral-shaped. In both cases, the larger the diameter, the older the bird.`
            ),
            sources: [
              {
                source: "U.S. Fish and Wildlife",
                url: 'https://www.fws.gov/refuges/features/WildFacts.html',
              },
            ],
        },
      },
      { // Q-10
        question: "An adult turkey has approximately how many feathers?",
        choices: ['150', '500', '1,500', '5,500'],
        answerIdx: 3,    
        furtherInfo: {
            explanation: (
                `On average, an adult turkey has around 5,500 feathers.`
            ),
            sources: [
              {
                source: "U.S. Fish and Wildlife",
                url: 'https://www.fws.gov/refuges/features/WildFacts.html',
              },
            ],
        },
      },
  ],

};

export default turkeyTalk;