const languageOnTheInternet = { 
  name: "Language on the Internet", 
  id: 'hKz3WV85C9HeSSuPhwwR', 
  linkTo: "/quizzes/languageOnTheInternet", 
  explainerOn: true, 
  subheading: `The internet has become our great communicator, but what languages does it speak? Who has the most access to digital content in their native tongue?`,
  description: `This webpage is in English. American English, if you want to be precise, because that is the language I speak (and write). Chances are, you will visit another webpage after this; what language will that one be in? Probably English. Even if <i>you</i> are not a native English speaker, the chances are still pretty good that a lot of the places you visit on the internet are in English. Do you know what other languages are most common in this shared digital space?`, 
  questions: [
      { // Q-1
          question: "Of the top 10 million websites on the internet, what percent are in English?",
          choices: ['20%', '40%', '60%', '80%'],
          answerIdx: 2,
          furtherInfo: {
              explanation: (
                  `Of the top 10 million websites, a staggering 60% are in English.`
              ),
              sources: [
                  {
                      source: "Visual Capitalist",
                      url: 'https://www.visualcapitalist.com/the-most-used-languages-on-the-internet/?utm_source=Visual+Capitalist+Infographics+%28All%29&utm_campaign=8aeccf2e4e-EMAIL_COPY_01&utm_medium=email&utm_term=0_31b4d09e8a-8aeccf2e4e-45131207',
                  },
              ],
          },
      },
      { // Q-2
          question: "What percent of the world's population speaks English?",
          choices: ['16%', '32%', '64%', '98%'],
          answerIdx: 0,    
          furtherInfo: {
              explanation: (
                  `About 16% of the world's 7.8 billion people identify as native English speakers. That's 1.2 billion people worldwide.`
              ),
              sources: [
                {
                  source: "Visual Capitalist",
                  url: 'https://www.visualcapitalist.com/the-most-used-languages-on-the-internet/?utm_source=Visual+Capitalist+Infographics+%28All%29&utm_campaign=8aeccf2e4e-EMAIL_COPY_01&utm_medium=email&utm_term=0_31b4d09e8a-8aeccf2e4e-45131207',
                },
              ],
          },
      },
      { // Q-3 
        question: "The percentage of English language websites (60%) is how much greater than the percentage of English speakers (16%)?",
        choices: ['1x', '2x', '4x', '8x'],
        answerIdx: 2,    
        furtherInfo: {
            explanation: (
                `Though only 16% of the world's population identifies as native English speakers, an astounding 60% of the top websites are in English. That means the percentage of English language websites is 4x higher than the percentage of native English speakers.`
            ),
            sources: [
              {
                source: "Visual Capitalist",
                url: 'https://www.visualcapitalist.com/the-most-used-languages-on-the-internet/?utm_source=Visual+Capitalist+Infographics+%28All%29&utm_campaign=8aeccf2e4e-EMAIL_COPY_01&utm_medium=email&utm_term=0_31b4d09e8a-8aeccf2e4e-45131207',
              },
            ],
        },
      },
      { // Q-4
        question: "What percent of the top 10 million websites are in Chinese?",
        choices: ['1.4%', '10%', '25%', '75%'],
        answerIdx: 0,    
        furtherInfo: {
            explanation: (
                `Only 1.4% of the top websites are in simplified Chinese, which is used both by Mandarin and Cantonese speakers.`
            ),
            sources: [
              {
                source: "Visual Capitalist",
                url: 'https://www.visualcapitalist.com/the-most-used-languages-on-the-internet/?utm_source=Visual+Capitalist+Infographics+%28All%29&utm_campaign=8aeccf2e4e-EMAIL_COPY_01&utm_medium=email&utm_term=0_31b4d09e8a-8aeccf2e4e-45131207',
              },
            ],
        },
      },
      { // Q-5
        question: "What percent of the world's population speaks Chinese?",
        choices: ['4%', '14%', '24%', '54%'],
        answerIdx: 1,    
        furtherInfo: {
            explanation: (
                `14% of the world's population identifies as native Chinese speakers (both Mandarin and Cantonese are included in this number). That's just over 1 billion people.`
            ),
            sources: [
              {
                source: "Visual Capitalist",
                url: 'https://www.visualcapitalist.com/the-most-used-languages-on-the-internet/?utm_source=Visual+Capitalist+Infographics+%28All%29&utm_campaign=8aeccf2e4e-EMAIL_COPY_01&utm_medium=email&utm_term=0_31b4d09e8a-8aeccf2e4e-45131207',
              },
            ],
        },
      },
      { // Q-6
        question: "The percentage of global Chinese speakers (14%) is how many times greater than the percentage of Chinese language websites (1.4%)?",
        choices: ['1x', '2.5x', '5x', '10x'],
        answerIdx: 3,    
        furtherInfo: {
            explanation: (
                `The percentage of global native Chinese speakers (aganin, both Mandarin and Cantonese) outstrips the percentage of Chinese-language websites by 10x.`
            ),
            sources: [
              {
                source: "Visual Capitalist",
                url: 'https://www.visualcapitalist.com/the-most-used-languages-on-the-internet/?utm_source=Visual+Capitalist+Infographics+%28All%29&utm_campaign=8aeccf2e4e-EMAIL_COPY_01&utm_medium=email&utm_term=0_31b4d09e8a-8aeccf2e4e-45131207',
              },
            ],
        },
      },
      { // Q-7
        question: "Arabic is the sixth most spoken language on Earth, but what percent of the top 10 million websites are in Arabic?",
        choices: ['5.1%', '2.1%', '1.1%', '0.1%'],
        answerIdx: 2,    
        furtherInfo: {
            explanation: (
                `Though Arabic is the sixth most spoken language on Earth, approximately 3.5% of the global population, only 1.1% of the top websites are in Arabic.`
            ),
            sources: [
              {
                source: "Visual Capitalist",
                url: 'https://www.visualcapitalist.com/the-most-used-languages-on-the-internet/?utm_source=Visual+Capitalist+Infographics+%28All%29&utm_campaign=8aeccf2e4e-EMAIL_COPY_01&utm_medium=email&utm_term=0_31b4d09e8a-8aeccf2e4e-45131207',
              },
            ],
        },
      },
      { // Q-8
        question: "After English, what language has the highest percentage of websites?",
        choices: ['Spanish', 'Hindi', 'Japanese', 'Russian'],
        answerIdx: 3,    
        furtherInfo: {
            explanation: (
                `After English, Russian lays claim to the highest percentage of the top websites. This is attributed to the immense popularity of Runet -- a Russian language internet community -- which has over 100 million users.`
            ),
            sources: [
              {
                source: "Visual Capitalist",
                url: 'https://www.visualcapitalist.com/the-most-used-languages-on-the-internet/?utm_source=Visual+Capitalist+Infographics+%28All%29&utm_campaign=8aeccf2e4e-EMAIL_COPY_01&utm_medium=email&utm_term=0_31b4d09e8a-8aeccf2e4e-45131207',
              },
            ],
        },
      },
      { // Q-9
        question: "What percentage of the top websites are in Russian?",
        choices: ['5.5%', '8.5%', '28.5%', '35.8%'],
        answerIdx: 1,    
        furtherInfo: {
            explanation: (
                `Though it is the second highest percentage, only 8.5% of the top 10 million websites are in Russian, lagging behind English's 16%.`
            ),
            sources: [
              {
                source: "Visual Capitalist",
                url: 'https://www.visualcapitalist.com/the-most-used-languages-on-the-internet/?utm_source=Visual+Capitalist+Infographics+%28All%29&utm_campaign=8aeccf2e4e-EMAIL_COPY_01&utm_medium=email&utm_term=0_31b4d09e8a-8aeccf2e4e-45131207',
              },
            ],
        },
      },
      { // Q-10
        question: "Which country has the highest number of internet users?",
        choices: ['Japan', 'The United States', 'China', 'Russia'],
        answerIdx: 2,    
        furtherInfo: {
            explanation: (
                `Globally, China boasts the highest number of internet users which makes it especially interesting that the number of Chinese-language websites is so comparatively low.`
            ),
            sources: [
              {
                source: "Visual Capitalist",
                url: 'https://www.visualcapitalist.com/the-most-used-languages-on-the-internet/?utm_source=Visual+Capitalist+Infographics+%28All%29&utm_campaign=8aeccf2e4e-EMAIL_COPY_01&utm_medium=email&utm_term=0_31b4d09e8a-8aeccf2e4e-45131207',
              },
            ],
        },
      },
  ],

};

export default languageOnTheInternet;