import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import useWindowDimensions from "../../utils/useWindowDimensions";

const useStyles = makeStyles((theme) => ({
  inputGroup: {
    marginBottom: theme.spacing(2),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "75%",
    },
  },
  input: {
    width: "100%",
    fontSize: 24,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    borderRadius: 6,
    borderColor: "#fff",
    borderStyle: "solid",
    color: "#fff",
    backgroundColor: "rgba(255,255,255,0.35)",
  },
  label: {
    marginBottom: theme.spacing(1),
    fontWeight: 600,
    fontSize: 20,
  },
  form: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    color: "#fff",
    minHeight: ({ windowHeight }) => windowHeight,
  },
  submitButton: {
    alignSelf: "center",
    marginTop: "auto",
    marginBottom: theme.spacing(10),
    [theme.breakpoints.up("sm")]: {
      marginBottom: theme.spacing(25),
    },
    // button css
    "& > *": {
      cursor: "pointer",
      appearance: "none",
      backgroundColor: "rgba(255,255,255,0.35)",
      borderRadius: 7,
      borderWidth: 2,
      borderStyle: "solid",
      padding: theme.spacing(1),
      color: "#fff",
      minWidth: 150,
      borderColor: "#fff",
      fontSize: 24,
    },
  },
}));

const Form = ({ onSubmit, inputs, submitValue }) => {
  const { height } = useWindowDimensions();
  const classes = useStyles({
    windowHeight: height,
  });

  const inputsJsx = inputs.map((input) => (
    <label key={input.label} className={classes.inputGroup}>
      <div className={classes.label}>{input.label}</div>
      <div>
        <input
          className={classes.input}
          type={input.type}
          value={input.value}
          onChange={input.onChange}
        />
        {input.caption && <div>{input.caption}</div>}
      </div>
    </label>
  ));

  return (
    <form onSubmit={onSubmit} className={classes.form}>
      {inputsJsx}
      <div className={classes.submitButton}>
        <input type="submit" value={submitValue || "Save"} />
      </div>
    </form>
  );
};

Form.propTypes = {
  onSubmit: PropTypes.func,
  inputs: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      onChange: PropTypes.func,
    })
  ),
  submitValue: PropTypes.string,
};

export default Form;
