 const sustainableSkyscrapers = {
    name: 'Hong Kong’s Sustainable Skyscrapers',
    id: 'UBePQ05e4JCXIjXuY7y9',
    linkTo: "/quizzes/sustainableSkyscrapers",
    explainerOn: true,
    description: "Hong Kong is one of the world's megacities, filled with millions of people and thousands of high rises. But Hong Kong has ambitions of a 0 carbon future and a growing green building industry to achieve it.",
    questions: [
        {
            //question 1//
            question: "Approximately how many buildings are within Hong Kong?",
            choices: ['420', '4,200', '42,000', '420,000'],
            answerIdx: 2,    
            furtherInfo: {
                explanation: (
                    `Hong Kong is made up of approximately 42,000 buildings.`
                ),
                sources: [
                    {
                        source: 'BBC. Keegan, M. (2021). The city of sustainable skyscrapers. Retrieved 29 June 2021',
                        url: 'https://www.bbc.com/future/article/20210209-hong-kong-the-worlds-greenest-skyscrapers',
                    },
                ],
            },
        },
        {
            //question 2//
           question: "How many of Hong Kong’s buildings are high rises?",
            choices: ['8', '80', '800', '8,000'],
            answerIdx: 3,    
            furtherInfo: {
                explanation: (
                    `Hong Kong is home to approximately 8,000 high rises.`
                ),
                sources: [
                    {
                        source: 'BBC. Keegan, M. (2021). The city of sustainable skyscrapers. Retrieved 29 June 2021',
                        url: 'https://www.bbc.com/future/article/20210209-hong-kong-the-worlds-greenest-skyscrapers',
                    },
                ],
            },
        },
        {
            //question 3//
            question: "How many of Hong Kong’s skyscrapers exceed 328ft in height?",
            choices: ['15', '150', '1,500', '15,000'],
            answerIdx: 2,    
            furtherInfo: {
                explanation: (
                    `1,500 of Hong Kong’s skyscrapers exceed 328ft in height.`
                ),
                sources: [
                    {
                        source: 'BBC. Keegan, M. (2021). The city of sustainable skyscrapers. Retrieved 29 June 2021',
                        url: 'https://www.bbc.com/future/article/20210209-hong-kong-the-worlds-greenest-skyscrapers',
                    },
                ],
            },
        },
        {
            //question 4//
            question: "How tall is Hong Kong’s highest building in feet?",
            choices: ['159', '1,590', '15,900', '159,000'],
            answerIdx: 1,    
            furtherInfo: {
                explanation: (
                    `Hong Kong’s highest building, the International Commerce Center, is an impressive 1,590 feet tall.`
                ),
                sources: [
                    {
                        source: 'BBC. Keegan, M. (2021). The city of sustainable skyscrapers. Retrieved 29 June 2021',
                        url: 'https://www.bbc.com/future/article/20210209-hong-kong-the-worlds-greenest-skyscrapers',
                    },
                ],
            },
        },
        {
            //question 5//
            question: "Since 2012, how many kilowatts of energy has the International Commerce Center building conserved?",
            choices: ['15,000 kWh', '150,000 kWh', '1,500,000 kWh', '15,000,000 kWh'],
            answerIdx: 3,    
            furtherInfo: {
                explanation: (
                    `Since 2012 the ICC building has conserved 15 million kWh of electricity.`
                ),
                sources: [
                    {
                        source: 'BBC. Keegan, M. (2021). The city of sustainable skyscrapers. Retrieved 29 June 2021',
                        url: 'https://www.bbc.com/future/article/20210209-hong-kong-the-worlds-greenest-skyscrapers',
                    },
                ],
            },
        },
        {
            //question 6//
            question: "How many metric tons of carbon emissions has ICC reduced since 2012?",
            choices: ['1,000', '10,000', '100,000', '1,000,000'],
            answerIdx: 1,    
            furtherInfo: {
                explanation: (
                    `Since 2012 the ICC building has reduced its carbon emissions by 10,000 metric tons.`
                ),
                sources: [
                    {
                        source: 'BBC. Keegan, M. (2021). The city of sustainable skyscrapers. Retrieved 29 June 2021',
                        url: 'https://www.bbc.com/future/article/20210209-hong-kong-the-worlds-greenest-skyscrapers',
                    },
                ],
            },
        },
        {
            //question 7//
            question: "Another one of Hong Kong’s top skyscrapers, how much does One Taikoo Place save on electricity in one year in USD?",
            choices: ['$387', '$3,870', '$38,700', '$387,000'],
            answerIdx: 3,    
            furtherInfo: {
                explanation: (
                    `One Taikoo Place saves approximately $387,000 in electricity in a year.`
                ),
                sources: [
                    {
                        source: 'BBC. Keegan, M. (2021). The city of sustainable skyscrapers. Retrieved 29 June 2021',
                        url: 'https://www.bbc.com/future/article/20210209-hong-kong-the-worlds-greenest-skyscrapers',
                    },
                ],
            },
        },
        {
            //question 8//
            question: "By how many metric tons did the China Resources Building reduce its CO2 emissions?",
            choices: ['13', '137', '1,370', '13,700'],
            answerIdx: 2,    
            furtherInfo: {
                explanation: (
                    `The China Resources Building, another of Hong Kong’s skyscrapers, reduced its C02 emissions by 1,370 metric tons.`
                ),
                sources: [
                    {
                        source: 'BBC. Keegan, M. (2021). The city of sustainable skyscrapers. Retrieved 29 June 2021',
                        url: 'https://www.bbc.com/future/article/20210209-hong-kong-the-worlds-greenest-skyscrapers',
                    },
                ],
            },
        },
        {
            //question 9//
            question: "Embodied carbon can represent up to what percentage of a building’s lifetime emissions?",
            choices: ['5%', '25%', '50%', '75%'],
            answerIdx: 2,    
            furtherInfo: {
                explanation: (
                    `The embodied carbon of a building -- all the carbon emitted in producing the materials and construction of the building -- can represent up to 50% of a building’s lifetime emissions.`
                ),
                sources: [
                    {
                        source: 'BBC. Keegan, M. (2021). The city of sustainable skyscrapers. Retrieved 29 June 2021',
                        url: 'https://www.bbc.com/future/article/20210209-hong-kong-the-worlds-greenest-skyscrapers',
                    },
                ],
            },
        },
        {
            //question 10//
            question: "By what year does Hong Kong plan to be 0 carbon?",
            choices: ['2050', '2070', '2100', '2110'],
            answerIdx: 0,    
            furtherInfo: {
                explanation: (
                    `The city of Hong Kong is aiming for net 0 carbon emissions by 2050.`
                ),
                sources: [
                    {
                        source: 'BBC. Keegan, M. (2021). The city of sustainable skyscrapers. Retrieved 29 June 2021',
                        url: 'https://www.bbc.com/future/article/20210209-hong-kong-the-worlds-greenest-skyscrapers',
                    },
                ],
            },
        },
    ],

};

export default sustainableSkyscrapers;