import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useStore } from "../contexts/store";

const useStyles = makeStyles((theme) => ({
  fab: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: theme.spacing(5),
    height: theme.spacing(5),
    padding: 0,
    fontSize: 16,
    backgroundColor: "transparent",
    border: "none",
    borderRadius: "50%",
    textAlign: "center",
    cursor:"pointer",
    "&:hover":{
      backgroundColor:"rgb(215,215,215)",
    },
  },
  on: {
    backgroundColor: "#eee",
    color: "black",

  },
  off: {
    backgroundColor: "darkgray",
    color: "black",

  },
}));

export default function SoundToggle() {
  const classes = useStyles();
  const [{ soundOn }, dispatch] = useStore();

  function doToggle() {
    dispatch({ type: "toggleSound" });
  }

  return (
    <button
      aria-label="toggle-sound"
      className={clsx(classes.fab, {
        [classes.on]: soundOn,
        [classes.off]: !soundOn,
      })}
      onClick={doToggle}
    >
      {soundOn ? (
        <FontAwesomeIcon icon="volume-up" />
      ) : (
        <FontAwesomeIcon icon="volume-off" />
      )}
    </button>
  );
}
