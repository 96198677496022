import React, { useState } from "react";
import ReSignIn from "../ReSignIn";

export default ({ children }) => {
  const [isReauthenticated, setIsReauthenticated] = useState(false);
  const signInSuccessWithAuthResult = (authResult) => {
    setIsReauthenticated(true);
    return false; // disable redirect
  };

  return isReauthenticated ? (
    children
  ) : (
    <ReSignIn signInSuccessWithAuthResult={signInSuccessWithAuthResult} />
  );
};
