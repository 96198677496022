const psychologicalMarketing = {
  name: "Psychological Marketing",
  id: 'B58MG821Eg9zV9hTFJ3j',
  linkTo: "/quizzes/psychologicalMarketing",
  explainerOn: true,
  subheading: `Are you a smart spender? Do you watch the sales and hunt for bargains? Those tactics may not be as helpful as you think.`,
  description: `BOGO or 50% off? Should you buy online or take a trip to the store? Do you really want that vintage monitor or is the nostalgia factor kicking in? We all want to be smart spenders, but marketers have several tried and tested tactics for getting us to spend more. How savvy of a shopper are you really?`,
  questions: [
    { // Q-1
      question: "I guarantee you've seen this one before: are consumers more likely to buy something for $3 or $2.99?",
      choices: ['$3', '$2.99'],
      answerIdx: 1,
      furtherInfo: {
        explanation: (
          `The difference is only one cent, but consumers are far more likely to pay $2.99 than $3, and marketers know it. No matter how aware of the tactic we are, we still subconsciously latch onto the '2' in $2.99 and perceive the price as lower than it actually is.`
        ),
        sources: [
          {
            source: "Visual Capitalist",
            url: 'https://www.visualcapitalist.com/29-psychological-tricks-to-make-you-buy-more/?utm_source=Visual+Capitalist+Infographics+%28All%29&utm_campaign=9d79537960-EMAIL&utm_medium=email&utm_term=0_31b4d09e8a-9d79537960-45131207',
          },
        ],
      },
    },
    { // Q-2
      question: "What is another tactic for making a larger price seem smaller?",
      choices: ['Giving a small daily equivalence', 'Showing installment payments', 'Removing the comma', 'All of the above'],
      answerIdx: 3,
      furtherInfo: {
        explanation: (
          `These are all tactics for making the price for large ticket items seem smaller; giving the price in terms of daily cups of coffee, monthly installments, or simply ditching the comma in 4 figure and above prices are all proven methods for enticing consumers to spend.`
        ),
        sources: [
          {
            source: "Visual Capitalist",
            url: 'https://www.visualcapitalist.com/29-psychological-tricks-to-make-you-buy-more/?utm_source=Visual+Capitalist+Infographics+%28All%29&utm_campaign=9d79537960-EMAIL&utm_medium=email&utm_term=0_31b4d09e8a-9d79537960-45131207',
          },
        ],
      },
    },
    { // Q-3 
      question: "Men are more likely to buy products with prices displayed in what color?",
      choices: ['Red', 'Green', 'Blue', 'Yellow'],
      answerIdx: 0,
      furtherInfo: {
        explanation: (
          `Studies have found that men often use color as a sort of visual shorthand, and when it comes to pricing red means discount.`
        ),
        sources: [
          {
            source: "Visual Capitalist",
            url: 'https://www.visualcapitalist.com/29-psychological-tricks-to-make-you-buy-more/?utm_source=Visual+Capitalist+Infographics+%28All%29&utm_campaign=9d79537960-EMAIL&utm_medium=email&utm_term=0_31b4d09e8a-9d79537960-45131207',
          },
        ],
      },
    },
    { // Q-4
      question: "A store layout that takes you on a winding route through all their products before leading you to the register is utilizing what marketing strategy?",
      choices: ['Retail Confusion', 'The Maze Effect', 'The Gruen Transfer', 'Visual Exhaustion'],
      answerIdx: 2,
      furtherInfo: {
        explanation: (
          `This is called the Gruen Transfer, and perhaps the most well-known example of it in action is the circuitous route through an IKEA store. The idea, of course, is to expose consumers to more merchandise and thus encourage them to spend more.`
        ),
        sources: [
          {
            source: "Visual Capitalist",
            url: 'https://www.visualcapitalist.com/29-psychological-tricks-to-make-you-buy-more/?utm_source=Visual+Capitalist+Infographics+%28All%29&utm_campaign=9d79537960-EMAIL&utm_medium=email&utm_term=0_31b4d09e8a-9d79537960-45131207',
          },
        ],
      },
    },
    { // Q-5
      question: "Speaking of store layouts, where are supermarkets most likely to stock their staples?",
      choices: ['In the front', 'In the back', 'Mid-store', 'To the far right or left'],
      answerIdx: 1,
      furtherInfo: {
        explanation: (
          `Supermarkets are more likely to stock staples in the back of the store to force shoppers to walk through as many sections as possible. Costco is particularly notorious for this, placing electronics, clothing, and home goods between the entrance and their produce section.`
        ),
        sources: [
          {
            source: "Visual Capitalist",
            url: 'https://www.visualcapitalist.com/29-psychological-tricks-to-make-you-buy-more/?utm_source=Visual+Capitalist+Infographics+%28All%29&utm_campaign=9d79537960-EMAIL&utm_medium=email&utm_term=0_31b4d09e8a-9d79537960-45131207',
          },
        ],
      },
    },
    { // Q-6
      question: "Which sale event benefits consumers the most?",
      choices: ['Phase-out discounts', 'BOGO', 'Instant markdowns', '50% off'],
      answerIdx: 3,
      furtherInfo: {
        explanation: (
          `50% off sales consistently benefit consumers the most out of any sale event, however, these have all but been replaced by BOGO (Buy One Get One) sales where the prices are adjusted beforehand to cover the cost of the 'free' item.`
        ),
        sources: [
          {
            source: "Visual Capitalist",
            url: 'https://www.visualcapitalist.com/29-psychological-tricks-to-make-you-buy-more/?utm_source=Visual+Capitalist+Infographics+%28All%29&utm_campaign=9d79537960-EMAIL&utm_medium=email&utm_term=0_31b4d09e8a-9d79537960-45131207',
          },
        ],
      },
    },
    { // Q-7
      question: "Placing one significantly higher-priced item on a menu is a sales tactic utilizing what?",
      choices: ['Red Herring Effect', 'Price Masking', 'Arbitrary Coherence', 'Fallacious Spending'],
      answerIdx: 2,
      furtherInfo: {
        explanation: (
          `This is called arbitrary coherence. Placing a $50 dish on a menu of $30 entrees makes buyers more likely to buy a $30 plate.`
        ),
        sources: [
          {
            source: "Visual Capitalist",
            url: 'https://www.visualcapitalist.com/29-psychological-tricks-to-make-you-buy-more/?utm_source=Visual+Capitalist+Infographics+%28All%29&utm_campaign=9d79537960-EMAIL&utm_medium=email&utm_term=0_31b4d09e8a-9d79537960-45131207',
          },
        ],
      },
    },
    { // Q-8
      question: "What type of music do stores tend to play?",
      choices: ['Slow, calm music', 'Fast, energetic music', 'Complex music', 'Repetitive music'],
      answerIdx: 0,
      furtherInfo: {
        explanation: (
          `Retail stores are most likely to play slow, calm music to entice buyers to stay longer. Restaurants, however, are more likely to play fast, energetic music to encourage patrons to literally get up and go, clearing their tables sooner rather than later.`
        ),
        sources: [
          {
            source: "Visual Capitalist",
            url: 'https://www.visualcapitalist.com/29-psychological-tricks-to-make-you-buy-more/?utm_source=Visual+Capitalist+Infographics+%28All%29&utm_campaign=9d79537960-EMAIL&utm_medium=email&utm_term=0_31b4d09e8a-9d79537960-45131207',
          },
        ],
      },
    },
    { // Q-9
      question: "Proudly stamping 'organic' and 'fair trade' on a package of coffee beans may be less of an ethical statement and more a tactic designed to________.",
      choices: ['make the coffee seem more expensive to produce', 'utilize current buzzwords', 'indicate prestige', 'appeal to consumer ethics'],
      answerIdx: 0,
      furtherInfo: {
        explanation: (
          `This is an example of a tactic designed to make a product seem more expensive to produce, and thus worth spending more money on.`
        ),
        sources: [
          {
            source: "Visual Capitalist",
            url: 'https://www.visualcapitalist.com/29-psychological-tricks-to-make-you-buy-more/?utm_source=Visual+Capitalist+Infographics+%28All%29&utm_campaign=9d79537960-EMAIL&utm_medium=email&utm_term=0_31b4d09e8a-9d79537960-45131207',
          },
        ],
      },
    },
    { // Q-10
      question: "How can you avoid falling for these tactics?",
      choices: ['Be aware', 'Be numerate', 'Be thoughtful', 'All of the above'],
      answerIdx: 3,
      furtherInfo: {
        explanation: (
          `To avoid falling for these tactics, you need, first, to be aware of them, second, to be numerate--to avoid being fooled by numbers you need to be able to use them!--and, third, to be thoughtful. Take your time, think about what you're buying, and maybe do a quick price comparison or some simple arithmetic to make sure that deal is really as good as it seems.`
        ),
        sources: [
          {
            source: "Visual Capitalist",
            url: 'https://www.visualcapitalist.com/29-psychological-tricks-to-make-you-buy-more/?utm_source=Visual+Capitalist+Infographics+%28All%29&utm_campaign=9d79537960-EMAIL&utm_medium=email&utm_term=0_31b4d09e8a-9d79537960-45131207',
          },
        ],
      },
    },
  ],

};

export default psychologicalMarketing;