 const bogotaACityInTransit = {
    id: 'PlWYPEkDRLa0Aej90n6A',
    name: 'Bogotá: A City in Transit',
    linkTo: "/quizzes/bogotaACityInTransit",
    explainerOn: true,
    description: "Cities around the world are experiencing rapid growth, often reaching populations in the millions. One of the questions haunting these megacities is what to do about the traffic. How is Bogotá, Colombia, addressing this issue?",
    questions: [
        { // 1
            question: "The city of Bogotá has an approximate population of how many million?",
            choices: ['1 million', '3 million', '5 million', '7 million'],
            answerIdx: 3,    
            furtherInfo: {
                explanation: "The population of Bogotá, Colombia, is approximately 7 million.",
                sources: [
                    {
                        source: 'Wikipedia',
                        url: 'https://en.wikipedia.org/wiki/Bogot%C3%A1',
                    },
                    {
                        source: 'Dane.gov.co',
                        url: 'https://www.dane.gov.co/files/varios/informacion-capital-DANE-2019.pdf',
                    }
                ],
            },
        },
        { // 2
            question: "Bogotá has an approximate <i>metropolitan</i> population (which includes the city proper, plus its surrounding areas) of how many million?",
            choices: ['7 million', '9 million', '11 million', '13 million'],
            answerIdx: 2,    
            furtherInfo: {
                explanation: "Bogotá has a <i>metropolitan</i> population of approximately 11 million.",
                sources: [
                    {
                        source: 'The Economist',
                        url: 'https://www.economist.com/node/21642053',
                    },                   
                ],
            },
        },
        { // 3
            question: "In Bogotá, a higher share of trips happen by bicycle than in any other Latin American city. What percent of trips are made on bicycle in Bogotá?",
            choices: ['3%', '7%', '14%', '21%'],
            answerIdx: 1,
            furtherInfo: {
                explanation: "7% of trips in Bogota are made by bicycle, the highest percentage of any Latin American city.",
                sources: [
                    {
                        source: 'Bloomberg',
                        url: 'https://www.bloomberg.com/graphics/2021-cities-climate-solutions/?sref=FtHoQDx1',
                    }
                ],
            },
        },
        { // 4
            question: "In 2011, bicycle commuting was used for 475,000 daily trips in Bogotá. In 9 years, this number increased by how many percent?",
            choices: ['15%', '50%', '85%', '120%'],
            answerIdx: 2,
            furtherInfo: {
                explanation: "From 2011 to 2020, bicycle commuting increased from 475,000 daily trips to 880,000. That's an increase of over 85%.",
                sources: [
                    {
                        source: 'ScienceDirect',
                        url: 'https://www.sciencedirect.com/science/article/abs/pii/S2214367X21000181',
                    }
                ],
            },
        },

        { // 5
            question: "To reduce congestion and pollution from traffic, how many new kilometers of bike lanes does Bogotá plan to build over the next four years?",
            choices: ['40 km', '100 km', '180 km', '280 km'],
            answerIdx: 3,
            furtherInfo: {
                explanation: "Over the next four years, Bogotá intends to build 280 kilometers of new bike lanes to reduce congestion and pollution from traffic.",
                sources: [
                    {
                        source: 'Bloomberg',
                        url: 'https://www.bloomberg.com/graphics/2021-cities-climate-solutions/?sref=FtHoQDx1',
                    }
                ],
            },
        },
        { // 6
            question: "Bogotá has 550 km of existing bike lanes. How much of an increase would an additional 280 km represent?",
            choices: ['20%', '35%', '50%', '65%'],
            answerIdx: 2,
            furtherInfo: {
                explanation: "Bogotá’s planned addition of 280 kilometers of new bike lanes will increase its existing 550 kilometers of bike lanes by 50%.",
                sources: [
                    {
                        source: 'Bloomberg',
                        url: 'https://www.bloomberg.com/graphics/2021-cities-climate-solutions/?sref=FtHoQDx1',
                    }
                ],
            },
        },
        { // 7
            question: "Bogotá’s rapid transit system is one of the world’s most heavily used. How many passengers ride this bus system each day?",
            choices: ['0.4 million', '1.4 million', '2.4 million', '3.4 million'],
            answerIdx: 2,    
            furtherInfo: {
                explanation: "2.4 million passengers ride the bus in Bogota daily.",
                sources: [
                    {
                        source: 'Bloomberg',
                        url: 'https://www.bloomberg.com/graphics/2021-cities-climate-solutions/?sref=FtHoQDx1',
                    }
                ],
            },
        },
        { // 8
            question: "When is Bogotá’s whole public transit system—including its nearly 1,500 buses— on track to be fully electric?",
            choices: ['2022', '2024', '2026', '2028'],
            answerIdx: 0,
            furtherInfo: {
                explanation: "Bogotá’s entire public transit system, including its nearly 1,500 busses, is on track to be fully electric by 2022.", // TODO
                sources: [
                    {
                        source: 'Bloomberg',
                        url: 'https://www.bloomberg.com/graphics/2021-cities-climate-solutions/?sref=FtHoQDx1',
                    }
                ],
            },
        },
    ],

};

export default bogotaACityInTransit;