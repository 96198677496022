import React, { useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import ReactMarkdown from "react-markdown";
import { makeStyles, ThemeProvider } from "@material-ui/core/styles";
import { Container, Box } from "@material-ui/core";
import Timer from "./Timer";
import Score from "./Score";
import Result from "./Result";
import SourceList from "./SourceList";
import Btn from "../Btn";
import QuestionIndex from "./QuestionIndex";
import { CssBaseline } from "@material-ui/core";

import explainerImage from "../../assets/deprecated/intro_background.0a46a507.png";
import FeedbackButton from "../FeedbackButton";

const useStyles = makeStyles((theme) => ({
  explain: {
    background: `transparent url(${explainerImage}) 0% 0% no-repeat padding-box`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
  explainText: {
    fontSize: "24px",
    [theme.breakpoints.down("xs")]: {
      fontSize: 18,
    },
    "& > p": {
      textAlign: "center",
      [theme.breakpoints.down("xs")]: {
        textAlign: "left",
      },
    },
  },
  questionText: {
    padding: theme.spacing(5),
    fontSize: "24px",
    fontWeight: 600,
    fontFamily: "Helvetica Neue",
    "& > h3": {
      textAlign: "center",
      fontWeight: 400,
    },
    "& > h4": {
      fontFamily: "Open Sans",
      textAlign: "center",
      fontWeight: "fontWeightMedium",
    },
  },
  sourceHeader: {
    fontFamily: "Helvetica Neue",
    fontSize: "24px",
    fontWeight: "600",
    [theme.breakpoints.down("xs")]: {
      fontSize: 18,
    },
  },
  action: {
    margin: theme.spacing(2),
  },
  explanationBox: {
    width: "90%",
    margin: theme.spacing(2),
    padding: theme.spacing(3),
    backgroundColor: "#9EC9F2",
    border: "2px solid #fff",
    borderRadius: "25px",
  },
  nextButton: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: 30,
    paddingRight: 30,
    backgroundColor: "#fff",
    borderRadius: 50,
    marginBottom: theme.spacing(2),
  },
  nextButtonText: {
    color: "#001EFF",
  },
  bottomButtonNoExplanation: {
    position: "absolute",
    bottom: 0,
  },
  timerContainer: {
    paddingTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  linkStyle: {
    textDecoration: "none",
    color: "#001EFF",
    fontSize: "24px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "18px",
    },
  },
  questionIndexBox: {
    textAlign: "center",
    width: "90%",
    margin: "0 theme.spacing(2)",
    padding: "0 theme.spacing(3)",
  },
  ChallengeQuizScoreText: {
    color: "#fff",
    [theme.breakpoints.down("xs")]: {
      "& > *": {
        fontSize: 24,
      },
    },
  },
}));

export default function Explainer(props) {
  const {
    onNext,
    explanation,
    sources,
    correct,
    setTimerisActive,
    secondsLeft,
    score,
    theme,
    choices,
    correctAnswerIdx,
    answerIdx,
    question,
    isChallengeQuiz,
    activeStep,
    quizLength,
  } = props;
  const classes = useStyles();

  useEffect(function stopTimer() {
    setTimerisActive(false);
  });

  function handleNext(event) {
    onNext();
  }

  return (
    <Container className={classes.result}>
      <FeedbackButton />
      {secondsLeft > 0 ? (
        <Container className={classes.timerContainer}>
          <Box display="flex" justifyContent="space-between">
            <Timer secondsLeft={secondsLeft} />
            <Score score={score} className={classes.ChallengeQuizScoreText} />
          </Box>
        </Container>
      ) : null}
      <Box display="flex" flexDirection="column" alignItems="center">
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Result correct={correct} />
          {!isChallengeQuiz && (
            <div className={classes.questionIndexBox}>
              <QuestionIndex index={activeStep + 1} quizLength={quizLength} />
            </div>
          )}
          {explanation ? (
            <ExplanationContent
              explanation={explanation}
              sources={sources}
              correct={correct}
              classes={classes}
            />
          ) : (
            <NoExplanationContent
              choices={choices}
              answerIdx={answerIdx}
              correctAnswerIdx={correctAnswerIdx}
              correct={correct}
              classes={classes}
              question={question}
            />
          )}
        </ThemeProvider>
        <div>
          <Btn onClick={handleNext} css={{ backgroundColor: "#fff" }}>
            <Typography
              variant="h4"
              component="span"
              className={classes.nextButtonText}
            >
              {!isChallengeQuiz
                ? activeStep + 1 === quizLength
                  ? "FINISH"
                  : "NEXT"
                : "NEXT"}
            </Typography>
          </Btn>
        </div>
      </Box>
    </Container>
  );
}

const ExplanationContent = ({
  explanation,
  sources,
  classes,
  correct
}) => {
  return (
    <div
      className={classes.explanationBox}
      style={{
        border:
          correct
            ? "5px solid #1AFF00"
            : "2px solid red",
      }}
    >
      <div className={classes.explainText}>
        <ReactMarkdown
          parserOptions={{ pedantic: true }}
          escapeHtml={false}
          source={explanation}
        />
      </div>
      {sources && (
        <div>
          <Typography align="left" className={classes.sourceHeader}>
            Sources:
          </Typography>
          <SourceList sourceData={sources} linkStyle={classes.linkStyle} />
        </div>
      )}
    </div>
  );
};

const NoExplanationContent = ({
  choices,
  answerIdx,
  correctAnswerIdx,
  classes,
  question,
  correct
}) => {
  let text;
  if (answerIdx === correctAnswerIdx) {
    text = <p>Good job, you got it right!</p>;
  } else {
    text = (
      <div
        style={{ 
          textAlign: "center", 
          width: "fit-content", 
          margin: "0 auto",    
        
        }}
      >
        <ReactMarkdown
          parserOptions={{ pedantic: true }}
          escapeHtml={false}
          source={question}
        />
        <p>
          Your Answer: <b>{choices[answerIdx]}</b>{" "}
        </p>
        <p>
          The Correct Answer: <b>{choices[correctAnswerIdx]}</b>
        </p>
      </div>
    );
  }

  return (
    <div className={classes.explanationBox} style={{
      border: correct
      ? "5px solid #1AFF00"
      : "2px solid red" 
    }}>
      <div className={classes.explainText}>{text}</div>
    </div>
  );
};
