import React, { useState } from "react";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import firebase from "firebase/app";
import "firebase/auth";
import { useAuth, useUser } from "reactfire";
import { Link, useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import CenteredContainer from "./CenteredContainer";
import SetUsername from "./profile/SetUsername";
import Logo from "./Logo";
import ArrowBackIosSharpIcon from '@material-ui/icons/ArrowBackIosSharp';


const useStyles = makeStyles((theme) => ({
  backButton:{
    color:"#fff",
    position:"absolute",
    left:"50px",
    top:"25px",
    zIndex:1,
    cursor:"pointer",
    "& :hover":{
      color:"rgba(255,255,255,0.7)"
    },
    [theme.breakpoints.down("xs")]: {
      fontSize:"25px",
      left:"15px",
      top:"32px",
    }
  },
  backArrow:{
    fontSize:"40px",
    [theme.breakpoints.down("xs")]: {
      fontSize:"25px",
    }
  },
  linkStyle:{
   fontSize:"18px",
   textAlign:"center",
   textDecoration:"none",
   color:"#fff",
   marginTop:"10px",
   [theme.breakpoints.down("xs")]: {
    fontSize: "16px",
  },
  },
  topNav:{
    position:"fixed",
    textAlign:"center",
    width:"100%",
    padding:"20px 0",
  
  },
  topNavLogo:{
    display:"inline-block",
    marginTop:"5px"
  },
  infoText:{
    fontSize:"25px",
    color:"#fff",
    textAlign:"center",
    marginTop:"0",
     [theme.breakpoints.down("xs")]: {
       fontSize: "18px",
       marginTop:"5px",
       padding:"0 10px"
     },
  },
  infoHeading:{
    fontSize:"46px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "26px",
      margin: "5px",
    },
  },
  routerLink:{
   color:"#fff",
   marginLeft:"5px"
  }
}));

const initialUIConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: "popup",
  signInOptions: [
    {
      provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
      requireDisplayName: false,
    },
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
  ],
};

function SignInWithSetUsername({ ...signInProps }) {
  const [showUsername, setShowUsername] = useState(false);
  const [userUid, setUserUid] = useState("");

  const history = useHistory();

  const handleSuccessfulSignIn = ({ user, additionalUserInfo }) => {
    if (additionalUserInfo.isNewUser) {
      setShowUsername(true);
      setUserUid(user.uid);
      // history.goBack();
      return false;
    }
    // history.goBack();
    return false;
  };

  const defaultUIConfig = {
    callbacks: {
      signInSuccessWithAuthResult: handleSuccessfulSignIn,
    },
  };

  return showUsername ? (
    <SetUsername userUid={userUid} history={history} />
  ) : (
    <SignIn uiConfig={defaultUIConfig} {...signInProps} />
  );
}

function SignIn({ signUp, uiConfig, showSignOut, isReauthenticate }) {
  const classes = useStyles();
  const history = useHistory();
  const { data: user } = useUser();
  const auth = useAuth();
  uiConfig = Object.assign(initialUIConfig, uiConfig);

  if (user && !isReauthenticate) {
    history.goBack();
    return <div></div>;
  }


  const goBackButtonPress = () => {
    if(history.location.state && history.location.state.prevPath){
      history.push(history.location.state.prevPath);
    }else{
      history.push("/quizzes")
    }
  }

  return (
    <>
    <div className={classes.topNav}>
    <span className={classes.backButton} onClick={() => goBackButtonPress()}>
    <ArrowBackIosSharpIcon className={classes.backArrow}/>
    </span>
      <Link to="/" style={{ textDecoration: "none" }}>
        <span className={classes.topNavLogo}>
        <Logo />
        </span>
      </Link>
      </div>
      <CenteredContainer>
        <>
        <h1 className={classes.infoHeading}>
        {!isReauthenticate && `${signUp ? "SIGN UP" : "LOG IN"}`}
        </h1>
        <p className={classes.infoText}>
            {isReauthenticate
              ? "Please reauthenticate to make account changes"
              : `Please ${
                  signUp ? "sign up" : "log in"
                } with any of the following:`}
          </p>
          <StyledFirebaseAuth  uiConfig={uiConfig} firebaseAuth={auth} />
          {isReauthenticate ? "" :  signUp ?
          <span className={classes.linkStyle}>
               Already have an accout?
               <Link 
              //  to="/signin" 
               to={{pathname: "/signin", state: { prevPath: history.location.state && history.location.state.prevPath}}} 
               className={classes.routerLink}>
                  Please login.
               </Link> 
              </span>
               :
              <span className={classes.linkStyle}>Dont have an account? 
               <Link 
                 to={{pathname: '/signup', state: { prevPath: history.location.state && history.location.state.prevPath}}} 
                 className={classes.routerLink}>
                 Please signup.
                </Link>
              </span>
          }
        </>
      </CenteredContainer>
    </>
  );
}

export { SignInWithSetUsername };
export default SignIn;
