const wellRoundedDivision5 = {
  id: 'wellRoundedDivision5',
  name: 'Well Rounded Division 5: E-Notation / Words',
  linkTo: "/quizzes/wellRoundedDivision5",
  description: "How fast can you divide big numbers? This one mixes E-Notation and Words.",
  halfOrderENumpad: true,
  timeLimit: 90,
  randomize: true,
  challenge: true,
  questions:
  [
    {
        "question": "What is 1E+13 divided by ten billion?",
        "writeInAnswer": 1000,
        "furtherInfo": {
            "explanation": "What is 1E+13 divided by ten billion?<br /><br />Rephrase it as: 1E13 / 1E10.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
        }
    },
    {
        "question": "What is 3E+08 divided by one?",
        "writeInAnswer": 300000000,
        "furtherInfo": {
            "explanation": "What is 3E+08 divided by one?<br /><br />Rephrase it as: 3E08 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+08."
        }
    },
    {
        "question": "What is 3E+11 divided by three million?",
        "writeInAnswer": 100000,
        "furtherInfo": {
            "explanation": "What is 3E+11 divided by three million?<br /><br />Rephrase it as: 3E11 / 3E06.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+05."
        }
    },
    {
        "question": "What is 3E+13 divided by one hundred?",
        "writeInAnswer": 300000000000,
        "furtherInfo": {
            "explanation": "What is 3E+13 divided by one hundred?<br /><br />Rephrase it as: 3E13 / 1E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+11."
        }
    },
    {
        "question": "What is 3E+09 divided by three hundred?",
        "writeInAnswer": 10000000,
        "furtherInfo": {
            "explanation": "What is 3E+09 divided by three hundred?<br /><br />Rephrase it as: 3E09 / 3E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+07."
        }
    },
    {
        "question": "What is 3E+06 divided by three hundred?",
        "writeInAnswer": 10000,
        "furtherInfo": {
            "explanation": "What is 3E+06 divided by three hundred?<br /><br />Rephrase it as: 3E06 / 3E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+04."
        }
    },
    {
        "question": "What is 1E+02 divided by thirty?",
        "writeInAnswer": 3,
        "furtherInfo": {
            "explanation": "What is 1E+02 divided by thirty?<br /><br />Rephrase it as: 1E02 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
        }
    },
    {
        "question": "What is 3E+05 divided by one?",
        "writeInAnswer": 300000,
        "furtherInfo": {
            "explanation": "What is 3E+05 divided by one?<br /><br />Rephrase it as: 3E05 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+05."
        }
    },
    {
        "question": "What is 1E+03 divided by three?",
        "writeInAnswer": 300,
        "furtherInfo": {
            "explanation": "What is 1E+03 divided by three?<br /><br />Rephrase it as: 1E03 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+02."
        }
    },
    {
        "question": "What is 3E+12 divided by three hundred billion?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 3E+12 divided by three hundred billion?<br /><br />Rephrase it as: 3E12 / 3E11.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 3E+02 divided by thirty?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 3E+02 divided by thirty?<br /><br />Rephrase it as: 3E02 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 1E+09 divided by three thousand?",
        "writeInAnswer": 300000,
        "furtherInfo": {
            "explanation": "What is 1E+09 divided by three thousand?<br /><br />Rephrase it as: 1E09 / 3E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+05."
        }
    },
    {
        "question": "What is 1E+08 divided by one?",
        "writeInAnswer": 100000000,
        "furtherInfo": {
            "explanation": "What is 1E+08 divided by one?<br /><br />Rephrase it as: 1E08 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+08."
        }
    },
    {
        "question": "What is 3E+04 divided by ten?",
        "writeInAnswer": 3000,
        "furtherInfo": {
            "explanation": "What is 3E+04 divided by ten?<br /><br />Rephrase it as: 3E04 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+03."
        }
    },
    {
        "question": "What is 3E+02 divided by three?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 3E+02 divided by three?<br /><br />Rephrase it as: 3E02 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 1E+01 divided by one?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 1E+01 divided by one?<br /><br />Rephrase it as: 1E01 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 1E+02 divided by ten?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 1E+02 divided by ten?<br /><br />Rephrase it as: 1E02 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 3E+06 divided by thirty thousand?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 3E+06 divided by thirty thousand?<br /><br />Rephrase it as: 3E06 / 3E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 1E+08 divided by three?",
        "writeInAnswer": 30000000,
        "furtherInfo": {
            "explanation": "What is 1E+08 divided by three?<br /><br />Rephrase it as: 1E08 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+07."
        }
    },
    {
        "question": "What is 3E+04 divided by thirty?",
        "writeInAnswer": 1000,
        "furtherInfo": {
            "explanation": "What is 3E+04 divided by thirty?<br /><br />Rephrase it as: 3E04 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
        }
    },
    {
        "question": "What is 1E+12 divided by one hundred billion?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 1E+12 divided by one hundred billion?<br /><br />Rephrase it as: 1E12 / 1E11.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 3E+01 divided by one?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 3E+01 divided by one?<br /><br />Rephrase it as: 3E01 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
        }
    },
    {
        "question": "What is 1E+11 divided by thirty billion?",
        "writeInAnswer": 3,
        "furtherInfo": {
            "explanation": "What is 1E+11 divided by thirty billion?<br /><br />Rephrase it as: 1E11 / 3E10.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
        }
    },
    {
        "question": "What is 3E+12 divided by one hundred billion?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 3E+12 divided by one hundred billion?<br /><br />Rephrase it as: 3E12 / 1E11.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
        }
    },
    {
        "question": "What is 3E+02 divided by one?",
        "writeInAnswer": 300,
        "furtherInfo": {
            "explanation": "What is 3E+02 divided by one?<br /><br />Rephrase it as: 3E02 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+02."
        }
    },
    {
        "question": "What is 1E+04 divided by three?",
        "writeInAnswer": 3000,
        "furtherInfo": {
            "explanation": "What is 1E+04 divided by three?<br /><br />Rephrase it as: 1E04 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+03."
        }
    },
    {
        "question": "What is 3E+02 divided by thirty?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 3E+02 divided by thirty?<br /><br />Rephrase it as: 3E02 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 1E+05 divided by one?",
        "writeInAnswer": 100000,
        "furtherInfo": {
            "explanation": "What is 1E+05 divided by one?<br /><br />Rephrase it as: 1E05 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+05."
        }
    },
    {
        "question": "What is 1E+07 divided by three thousand?",
        "writeInAnswer": 3000,
        "furtherInfo": {
            "explanation": "What is 1E+07 divided by three thousand?<br /><br />Rephrase it as: 1E07 / 3E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+03."
        }
    },
    {
        "question": "What is 3E+10 divided by one thousand?",
        "writeInAnswer": 30000000,
        "furtherInfo": {
            "explanation": "What is 3E+10 divided by one thousand?<br /><br />Rephrase it as: 3E10 / 1E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+07."
        }
    },
    {
        "question": "What is 3E+08 divided by thirty thousand?",
        "writeInAnswer": 10000,
        "furtherInfo": {
            "explanation": "What is 3E+08 divided by thirty thousand?<br /><br />Rephrase it as: 3E08 / 3E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+04."
        }
    },
    {
        "question": "What is 3E+05 divided by one thousand?",
        "writeInAnswer": 300,
        "furtherInfo": {
            "explanation": "What is 3E+05 divided by one thousand?<br /><br />Rephrase it as: 3E05 / 1E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+02."
        }
    },
    {
        "question": "What is 1E+13 divided by thirty?",
        "writeInAnswer": 300000000000,
        "furtherInfo": {
            "explanation": "What is 1E+13 divided by thirty?<br /><br />Rephrase it as: 1E13 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+11."
        }
    },
    {
        "question": "What is 1E+03 divided by one hundred?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 1E+03 divided by one hundred?<br /><br />Rephrase it as: 1E03 / 1E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 1E+12 divided by one hundred million?",
        "writeInAnswer": 10000,
        "furtherInfo": {
            "explanation": "What is 1E+12 divided by one hundred million?<br /><br />Rephrase it as: 1E12 / 1E08.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+04."
        }
    },
    {
        "question": "What is 3E+11 divided by one?",
        "writeInAnswer": 300000000000,
        "furtherInfo": {
            "explanation": "What is 3E+11 divided by one?<br /><br />Rephrase it as: 3E11 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+11."
        }
    },
    {
        "question": "What is 1E+02 divided by one?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 1E+02 divided by one?<br /><br />Rephrase it as: 1E02 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 1E+10 divided by thirty?",
        "writeInAnswer": 300000000,
        "furtherInfo": {
            "explanation": "What is 1E+10 divided by thirty?<br /><br />Rephrase it as: 1E10 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+08."
        }
    },
    {
        "question": "What is 3E+13 divided by three hundred?",
        "writeInAnswer": 100000000000,
        "furtherInfo": {
            "explanation": "What is 3E+13 divided by three hundred?<br /><br />Rephrase it as: 3E13 / 3E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+11."
        }
    },
    {
        "question": "What is 3E+10 divided by one billion?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 3E+10 divided by one billion?<br /><br />Rephrase it as: 3E10 / 1E09.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
        }
    },
    {
        "question": "What is 1E+03 divided by three hundred?",
        "writeInAnswer": 3,
        "furtherInfo": {
            "explanation": "What is 1E+03 divided by three hundred?<br /><br />Rephrase it as: 1E03 / 3E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
        }
    },
    {
        "question": "What is 1E+13 divided by thirty?",
        "writeInAnswer": 300000000000,
        "furtherInfo": {
            "explanation": "What is 1E+13 divided by thirty?<br /><br />Rephrase it as: 1E13 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+11."
        }
    },
    {
        "question": "What is 1E+02 divided by thirty?",
        "writeInAnswer": 3,
        "furtherInfo": {
            "explanation": "What is 1E+02 divided by thirty?<br /><br />Rephrase it as: 1E02 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
        }
    },
    {
        "question": "What is 1E+13 divided by ten?",
        "writeInAnswer": 1000000000000,
        "furtherInfo": {
            "explanation": "What is 1E+13 divided by ten?<br /><br />Rephrase it as: 1E13 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+12."
        }
    },
    {
        "question": "What is 1E+02 divided by ten?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 1E+02 divided by ten?<br /><br />Rephrase it as: 1E02 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 1E+02 divided by three?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 1E+02 divided by three?<br /><br />Rephrase it as: 1E02 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+01."
        }
    },
    {
        "question": "What is 3E+01 divided by three?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 3E+01 divided by three?<br /><br />Rephrase it as: 3E01 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 3E+07 divided by thirty?",
        "writeInAnswer": 1000000,
        "furtherInfo": {
            "explanation": "What is 3E+07 divided by thirty?<br /><br />Rephrase it as: 3E07 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+06."
        }
    },
    {
        "question": "What is 1E+09 divided by one?",
        "writeInAnswer": 1000000000,
        "furtherInfo": {
            "explanation": "What is 1E+09 divided by one?<br /><br />Rephrase it as: 1E09 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+09."
        }
    },
    {
        "question": "What is 1E+10 divided by thirty million?",
        "writeInAnswer": 300,
        "furtherInfo": {
            "explanation": "What is 1E+10 divided by thirty million?<br /><br />Rephrase it as: 1E10 / 3E07.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+02."
        }
    },
    {
        "question": "What is 1E+11 divided by one hundred million?",
        "writeInAnswer": 1000,
        "furtherInfo": {
            "explanation": "What is 1E+11 divided by one hundred million?<br /><br />Rephrase it as: 1E11 / 1E08.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
        }
    },
    {
        "question": "What is 3E+09 divided by one thousand?",
        "writeInAnswer": 3000000,
        "furtherInfo": {
            "explanation": "What is 3E+09 divided by one thousand?<br /><br />Rephrase it as: 3E09 / 1E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+06."
        }
    },
    {
        "question": "What is 3E+11 divided by three million?",
        "writeInAnswer": 100000,
        "furtherInfo": {
            "explanation": "What is 3E+11 divided by three million?<br /><br />Rephrase it as: 3E11 / 3E06.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+05."
        }
    },
    {
        "question": "What is 1E+13 divided by three hundred billion?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 1E+13 divided by three hundred billion?<br /><br />Rephrase it as: 1E13 / 3E11.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+01."
        }
    },
    {
        "question": "What is 3E+02 divided by ten?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 3E+02 divided by ten?<br /><br />Rephrase it as: 3E02 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
        }
    },
    {
        "question": "What is 1E+13 divided by three hundred million?",
        "writeInAnswer": 30000,
        "furtherInfo": {
            "explanation": "What is 1E+13 divided by three hundred million?<br /><br />Rephrase it as: 1E13 / 3E08.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+04."
        }
    },
    {
        "question": "What is 1E+01 divided by three?",
        "writeInAnswer": 3,
        "furtherInfo": {
            "explanation": "What is 1E+01 divided by three?<br /><br />Rephrase it as: 1E01 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
        }
    },
    {
        "question": "What is 3E+02 divided by ten?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 3E+02 divided by ten?<br /><br />Rephrase it as: 3E02 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
        }
    },
    {
        "question": "What is 3E+07 divided by three million?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 3E+07 divided by three million?<br /><br />Rephrase it as: 3E07 / 3E06.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 1E+04 divided by one?",
        "writeInAnswer": 10000,
        "furtherInfo": {
            "explanation": "What is 1E+04 divided by one?<br /><br />Rephrase it as: 1E04 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+04."
        }
    },
    {
        "question": "What is 1E+06 divided by one thousand?",
        "writeInAnswer": 1000,
        "furtherInfo": {
            "explanation": "What is 1E+06 divided by one thousand?<br /><br />Rephrase it as: 1E06 / 1E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
        }
    },
    {
        "question": "What is 1E+11 divided by three hundred?",
        "writeInAnswer": 300000000,
        "furtherInfo": {
            "explanation": "What is 1E+11 divided by three hundred?<br /><br />Rephrase it as: 1E11 / 3E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+08."
        }
    },
    {
        "question": "What is 3E+04 divided by thirty?",
        "writeInAnswer": 1000,
        "furtherInfo": {
            "explanation": "What is 3E+04 divided by thirty?<br /><br />Rephrase it as: 3E04 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
        }
    },
    {
        "question": "What is 1E+04 divided by three thousand?",
        "writeInAnswer": 3,
        "furtherInfo": {
            "explanation": "What is 1E+04 divided by three thousand?<br /><br />Rephrase it as: 1E04 / 3E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
        }
    },
    {
        "question": "What is 3E+03 divided by three?",
        "writeInAnswer": 1000,
        "furtherInfo": {
            "explanation": "What is 3E+03 divided by three?<br /><br />Rephrase it as: 3E03 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
        }
    },
    {
        "question": "What is 3E+13 divided by one hundred billion?",
        "writeInAnswer": 300,
        "furtherInfo": {
            "explanation": "What is 3E+13 divided by one hundred billion?<br /><br />Rephrase it as: 3E13 / 1E11.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+02."
        }
    },
    {
        "question": "What is 3E+13 divided by one?",
        "writeInAnswer": 30000000000000,
        "furtherInfo": {
            "explanation": "What is 3E+13 divided by one?<br /><br />Rephrase it as: 3E13 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+13."
        }
    },
    {
        "question": "What is 1E+06 divided by thirty thousand?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 1E+06 divided by thirty thousand?<br /><br />Rephrase it as: 1E06 / 3E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+01."
        }
    },
    {
        "question": "What is 3E+12 divided by ten billion?",
        "writeInAnswer": 300,
        "furtherInfo": {
            "explanation": "What is 3E+12 divided by ten billion?<br /><br />Rephrase it as: 3E12 / 1E10.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+02."
        }
    },
    {
        "question": "What is 1E+05 divided by one?",
        "writeInAnswer": 100000,
        "furtherInfo": {
            "explanation": "What is 1E+05 divided by one?<br /><br />Rephrase it as: 1E05 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+05."
        }
    },
    {
        "question": "What is 3E+01 divided by one?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 3E+01 divided by one?<br /><br />Rephrase it as: 3E01 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
        }
    },
    {
        "question": "What is 3E+06 divided by one hundred?",
        "writeInAnswer": 30000,
        "furtherInfo": {
            "explanation": "What is 3E+06 divided by one hundred?<br /><br />Rephrase it as: 3E06 / 1E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+04."
        }
    },
    {
        "question": "What is 3E+10 divided by one thousand?",
        "writeInAnswer": 30000000,
        "furtherInfo": {
            "explanation": "What is 3E+10 divided by one thousand?<br /><br />Rephrase it as: 3E10 / 1E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+07."
        }
    },
    {
        "question": "What is 1E+11 divided by three million?",
        "writeInAnswer": 30000,
        "furtherInfo": {
            "explanation": "What is 1E+11 divided by three million?<br /><br />Rephrase it as: 1E11 / 3E06.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+04."
        }
    },
    {
        "question": "What is 1E+08 divided by three thousand?",
        "writeInAnswer": 30000,
        "furtherInfo": {
            "explanation": "What is 1E+08 divided by three thousand?<br /><br />Rephrase it as: 1E08 / 3E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+04."
        }
    },
    {
        "question": "What is 1E+09 divided by ten million?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 1E+09 divided by ten million?<br /><br />Rephrase it as: 1E09 / 1E07.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 1E+01 divided by one?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 1E+01 divided by one?<br /><br />Rephrase it as: 1E01 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 3E+10 divided by one?",
        "writeInAnswer": 30000000000,
        "furtherInfo": {
            "explanation": "What is 3E+10 divided by one?<br /><br />Rephrase it as: 3E10 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+10."
        }
    },
    {
        "question": "What is 1E+08 divided by thirty?",
        "writeInAnswer": 3000000,
        "furtherInfo": {
            "explanation": "What is 1E+08 divided by thirty?<br /><br />Rephrase it as: 1E08 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+06."
        }
    },
    {
        "question": "What is 1E+10 divided by ten thousand?",
        "writeInAnswer": 1000000,
        "furtherInfo": {
            "explanation": "What is 1E+10 divided by ten thousand?<br /><br />Rephrase it as: 1E10 / 1E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+06."
        }
    },
    {
        "question": "What is 1E+01 divided by three?",
        "writeInAnswer": 3,
        "furtherInfo": {
            "explanation": "What is 1E+01 divided by three?<br /><br />Rephrase it as: 1E01 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
        }
    },
    {
        "question": "What is 3E+06 divided by ten?",
        "writeInAnswer": 300000,
        "furtherInfo": {
            "explanation": "What is 3E+06 divided by ten?<br /><br />Rephrase it as: 3E06 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+05."
        }
    },
    {
        "question": "What is 1E+04 divided by three hundred?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 1E+04 divided by three hundred?<br /><br />Rephrase it as: 1E04 / 3E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+01."
        }
    },
    {
        "question": "What is 3E+13 divided by three hundred billion?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 3E+13 divided by three hundred billion?<br /><br />Rephrase it as: 3E13 / 3E11.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 1E+01 divided by one?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 1E+01 divided by one?<br /><br />Rephrase it as: 1E01 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 3E+11 divided by one billion?",
        "writeInAnswer": 300,
        "furtherInfo": {
            "explanation": "What is 3E+11 divided by one billion?<br /><br />Rephrase it as: 3E11 / 1E09.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+02."
        }
    },
    {
        "question": "What is 1E+09 divided by thirty thousand?",
        "writeInAnswer": 30000,
        "furtherInfo": {
            "explanation": "What is 1E+09 divided by thirty thousand?<br /><br />Rephrase it as: 1E09 / 3E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+04."
        }
    },
    {
        "question": "What is 3E+09 divided by thirty?",
        "writeInAnswer": 100000000,
        "furtherInfo": {
            "explanation": "What is 3E+09 divided by thirty?<br /><br />Rephrase it as: 3E09 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+08."
        }
    },
    {
        "question": "What is 1E+11 divided by one hundred million?",
        "writeInAnswer": 1000,
        "furtherInfo": {
            "explanation": "What is 1E+11 divided by one hundred million?<br /><br />Rephrase it as: 1E11 / 1E08.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
        }
    },
    {
        "question": "What is 3E+01 divided by three?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 3E+01 divided by three?<br /><br />Rephrase it as: 3E01 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 3E+06 divided by one hundred?",
        "writeInAnswer": 30000,
        "furtherInfo": {
            "explanation": "What is 3E+06 divided by one hundred?<br /><br />Rephrase it as: 3E06 / 1E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+04."
        }
    },
    {
        "question": "What is 3E+07 divided by one million?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 3E+07 divided by one million?<br /><br />Rephrase it as: 3E07 / 1E06.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
        }
    },
    {
        "question": "What is 3E+01 divided by three?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 3E+01 divided by three?<br /><br />Rephrase it as: 3E01 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 3E+09 divided by three million?",
        "writeInAnswer": 1000,
        "furtherInfo": {
            "explanation": "What is 3E+09 divided by three million?<br /><br />Rephrase it as: 3E09 / 3E06.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
        }
    },
    {
        "question": "What is 3E+08 divided by three million?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 3E+08 divided by three million?<br /><br />Rephrase it as: 3E08 / 3E06.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 1E+03 divided by ten?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 1E+03 divided by ten?<br /><br />Rephrase it as: 1E03 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 1E+11 divided by thirty million?",
        "writeInAnswer": 3000,
        "furtherInfo": {
            "explanation": "What is 1E+11 divided by thirty million?<br /><br />Rephrase it as: 1E11 / 3E07.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+03."
        }
    },
    {
        "question": "What is 3E+04 divided by one hundred?",
        "writeInAnswer": 300,
        "furtherInfo": {
            "explanation": "What is 3E+04 divided by one hundred?<br /><br />Rephrase it as: 3E04 / 1E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+02."
        }
    },
    {
        "question": "What is 1E+11 divided by one hundred?",
        "writeInAnswer": 1000000000,
        "furtherInfo": {
            "explanation": "What is 1E+11 divided by one hundred?<br /><br />Rephrase it as: 1E11 / 1E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+09."
        }
    },
    {
        "question": "What is 1E+07 divided by three?",
        "writeInAnswer": 3000000,
        "furtherInfo": {
            "explanation": "What is 1E+07 divided by three?<br /><br />Rephrase it as: 1E07 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+06."
        }
    },
    {
        "question": "What is 1E+03 divided by one hundred?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 1E+03 divided by one hundred?<br /><br />Rephrase it as: 1E03 / 1E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 1E+10 divided by ten?",
        "writeInAnswer": 1000000000,
        "furtherInfo": {
            "explanation": "What is 1E+10 divided by ten?<br /><br />Rephrase it as: 1E10 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+09."
        }
    },
    {
        "question": "What is 1E+12 divided by three hundred?",
        "writeInAnswer": 3000000000,
        "furtherInfo": {
            "explanation": "What is 1E+12 divided by three hundred?<br /><br />Rephrase it as: 1E12 / 3E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+09."
        }
    },
    {
        "question": "What is 1E+01 divided by three?",
        "writeInAnswer": 3,
        "furtherInfo": {
            "explanation": "What is 1E+01 divided by three?<br /><br />Rephrase it as: 1E01 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
        }
    },
    {
        "question": "What is 3E+11 divided by three hundred thousand?",
        "writeInAnswer": 1000000,
        "furtherInfo": {
            "explanation": "What is 3E+11 divided by three hundred thousand?<br /><br />Rephrase it as: 3E11 / 3E05.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+06."
        }
    },
    {
        "question": "What is 1E+04 divided by three hundred?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 1E+04 divided by three hundred?<br /><br />Rephrase it as: 1E04 / 3E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+01."
        }
    },
    {
        "question": "What is 1E+05 divided by three hundred?",
        "writeInAnswer": 300,
        "furtherInfo": {
            "explanation": "What is 1E+05 divided by three hundred?<br /><br />Rephrase it as: 1E05 / 3E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+02."
        }
    },
    {
        "question": "What is 3E+09 divided by one hundred?",
        "writeInAnswer": 30000000,
        "furtherInfo": {
            "explanation": "What is 3E+09 divided by one hundred?<br /><br />Rephrase it as: 3E09 / 1E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+07."
        }
    },
    {
        "question": "What is 3E+03 divided by ten?",
        "writeInAnswer": 300,
        "furtherInfo": {
            "explanation": "What is 3E+03 divided by ten?<br /><br />Rephrase it as: 3E03 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+02."
        }
    },
    {
        "question": "What is 3E+04 divided by thirty?",
        "writeInAnswer": 1000,
        "furtherInfo": {
            "explanation": "What is 3E+04 divided by thirty?<br /><br />Rephrase it as: 3E04 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
        }
    },
    {
        "question": "What is 1E+13 divided by one thousand?",
        "writeInAnswer": 10000000000,
        "furtherInfo": {
            "explanation": "What is 1E+13 divided by one thousand?<br /><br />Rephrase it as: 1E13 / 1E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+10."
        }
    },
    {
        "question": "What is 1E+01 divided by one?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 1E+01 divided by one?<br /><br />Rephrase it as: 1E01 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 3E+10 divided by ten?",
        "writeInAnswer": 3000000000,
        "furtherInfo": {
            "explanation": "What is 3E+10 divided by ten?<br /><br />Rephrase it as: 3E10 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+09."
        }
    },
    {
        "question": "What is 3E+02 divided by one?",
        "writeInAnswer": 300,
        "furtherInfo": {
            "explanation": "What is 3E+02 divided by one?<br /><br />Rephrase it as: 3E02 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+02."
        }
    },
    {
        "question": "What is 1E+04 divided by one thousand?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 1E+04 divided by one thousand?<br /><br />Rephrase it as: 1E04 / 1E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 1E+09 divided by three million?",
        "writeInAnswer": 300,
        "furtherInfo": {
            "explanation": "What is 1E+09 divided by three million?<br /><br />Rephrase it as: 1E09 / 3E06.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+02."
        }
    },
    {
        "question": "What is 3E+02 divided by ten?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 3E+02 divided by ten?<br /><br />Rephrase it as: 3E02 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
        }
    },
    {
        "question": "What is 1E+12 divided by three hundred million?",
        "writeInAnswer": 3000,
        "furtherInfo": {
            "explanation": "What is 1E+12 divided by three hundred million?<br /><br />Rephrase it as: 1E12 / 3E08.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+03."
        }
    },
    {
        "question": "What is 1E+11 divided by ten?",
        "writeInAnswer": 10000000000,
        "furtherInfo": {
            "explanation": "What is 1E+11 divided by ten?<br /><br />Rephrase it as: 1E11 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+10."
        }
    },
    {
        "question": "What is 1E+11 divided by one thousand?",
        "writeInAnswer": 100000000,
        "furtherInfo": {
            "explanation": "What is 1E+11 divided by one thousand?<br /><br />Rephrase it as: 1E11 / 1E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+08."
        }
    },
    {
        "question": "What is 3E+11 divided by one million?",
        "writeInAnswer": 300000,
        "furtherInfo": {
            "explanation": "What is 3E+11 divided by one million?<br /><br />Rephrase it as: 3E11 / 1E06.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+05."
        }
    },
    {
        "question": "What is 1E+06 divided by ten?",
        "writeInAnswer": 100000,
        "furtherInfo": {
            "explanation": "What is 1E+06 divided by ten?<br /><br />Rephrase it as: 1E06 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+05."
        }
    },
    {
        "question": "What is 1E+09 divided by one?",
        "writeInAnswer": 1000000000,
        "furtherInfo": {
            "explanation": "What is 1E+09 divided by one?<br /><br />Rephrase it as: 1E09 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+09."
        }
    },
    {
        "question": "What is 3E+10 divided by one hundred million?",
        "writeInAnswer": 300,
        "furtherInfo": {
            "explanation": "What is 3E+10 divided by one hundred million?<br /><br />Rephrase it as: 3E10 / 1E08.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+02."
        }
    },
    {
        "question": "What is 1E+10 divided by one hundred?",
        "writeInAnswer": 100000000,
        "furtherInfo": {
            "explanation": "What is 1E+10 divided by one hundred?<br /><br />Rephrase it as: 1E10 / 1E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+08."
        }
    },
    {
        "question": "What is 3E+13 divided by thirty billion?",
        "writeInAnswer": 1000,
        "furtherInfo": {
            "explanation": "What is 3E+13 divided by thirty billion?<br /><br />Rephrase it as: 3E13 / 3E10.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
        }
    },
    {
        "question": "What is 3E+11 divided by thirty million?",
        "writeInAnswer": 10000,
        "furtherInfo": {
            "explanation": "What is 3E+11 divided by thirty million?<br /><br />Rephrase it as: 3E11 / 3E07.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+04."
        }
    },
    {
        "question": "What is 1E+09 divided by one hundred million?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 1E+09 divided by one hundred million?<br /><br />Rephrase it as: 1E09 / 1E08.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 1E+13 divided by one hundred million?",
        "writeInAnswer": 100000,
        "furtherInfo": {
            "explanation": "What is 1E+13 divided by one hundred million?<br /><br />Rephrase it as: 1E13 / 1E08.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+05."
        }
    },
    {
        "question": "What is 1E+13 divided by three?",
        "writeInAnswer": 3000000000000,
        "furtherInfo": {
            "explanation": "What is 1E+13 divided by three?<br /><br />Rephrase it as: 1E13 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+12."
        }
    },
    {
        "question": "What is 1E+09 divided by three thousand?",
        "writeInAnswer": 300000,
        "furtherInfo": {
            "explanation": "What is 1E+09 divided by three thousand?<br /><br />Rephrase it as: 1E09 / 3E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+05."
        }
    },
    {
        "question": "What is 3E+09 divided by thirty thousand?",
        "writeInAnswer": 100000,
        "furtherInfo": {
            "explanation": "What is 3E+09 divided by thirty thousand?<br /><br />Rephrase it as: 3E09 / 3E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+05."
        }
    },
    {
        "question": "What is 3E+13 divided by one billion?",
        "writeInAnswer": 30000,
        "furtherInfo": {
            "explanation": "What is 3E+13 divided by one billion?<br /><br />Rephrase it as: 3E13 / 1E09.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+04."
        }
    },
    {
        "question": "What is 1E+12 divided by one?",
        "writeInAnswer": 1000000000000,
        "furtherInfo": {
            "explanation": "What is 1E+12 divided by one?<br /><br />Rephrase it as: 1E12 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+12."
        }
    },
    {
        "question": "What is 1E+04 divided by three?",
        "writeInAnswer": 3000,
        "furtherInfo": {
            "explanation": "What is 1E+04 divided by three?<br /><br />Rephrase it as: 1E04 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+03."
        }
    },
    {
        "question": "What is 3E+11 divided by thirty billion?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 3E+11 divided by thirty billion?<br /><br />Rephrase it as: 3E11 / 3E10.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 3E+12 divided by three?",
        "writeInAnswer": 1000000000000,
        "furtherInfo": {
            "explanation": "What is 3E+12 divided by three?<br /><br />Rephrase it as: 3E12 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+12."
        }
    },
    {
        "question": "What is 1E+08 divided by ten?",
        "writeInAnswer": 10000000,
        "furtherInfo": {
            "explanation": "What is 1E+08 divided by ten?<br /><br />Rephrase it as: 1E08 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+07."
        }
    },
    {
        "question": "What is 1E+12 divided by one?",
        "writeInAnswer": 1000000000000,
        "furtherInfo": {
            "explanation": "What is 1E+12 divided by one?<br /><br />Rephrase it as: 1E12 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+12."
        }
    },
    {
        "question": "What is 1E+12 divided by three million?",
        "writeInAnswer": 300000,
        "furtherInfo": {
            "explanation": "What is 1E+12 divided by three million?<br /><br />Rephrase it as: 1E12 / 3E06.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+05."
        }
    },
    {
        "question": "What is 3E+13 divided by one trillion?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 3E+13 divided by one trillion?<br /><br />Rephrase it as: 3E13 / 1E12.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
        }
    },
    {
        "question": "What is 1E+03 divided by three?",
        "writeInAnswer": 300,
        "furtherInfo": {
            "explanation": "What is 1E+03 divided by three?<br /><br />Rephrase it as: 1E03 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+02."
        }
    },
    {
        "question": "What is 1E+13 divided by one hundred billion?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 1E+13 divided by one hundred billion?<br /><br />Rephrase it as: 1E13 / 1E11.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 1E+11 divided by one hundred?",
        "writeInAnswer": 1000000000,
        "furtherInfo": {
            "explanation": "What is 1E+11 divided by one hundred?<br /><br />Rephrase it as: 1E11 / 1E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+09."
        }
    },
    {
        "question": "What is 1E+05 divided by one hundred?",
        "writeInAnswer": 1000,
        "furtherInfo": {
            "explanation": "What is 1E+05 divided by one hundred?<br /><br />Rephrase it as: 1E05 / 1E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
        }
    },
    {
        "question": "What is 1E+13 divided by three hundred?",
        "writeInAnswer": 30000000000,
        "furtherInfo": {
            "explanation": "What is 1E+13 divided by three hundred?<br /><br />Rephrase it as: 1E13 / 3E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+10."
        }
    },
    {
        "question": "What is 1E+12 divided by three?",
        "writeInAnswer": 300000000000,
        "furtherInfo": {
            "explanation": "What is 1E+12 divided by three?<br /><br />Rephrase it as: 1E12 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+11."
        }
    },
    {
        "question": "What is 1E+09 divided by one thousand?",
        "writeInAnswer": 1000000,
        "furtherInfo": {
            "explanation": "What is 1E+09 divided by one thousand?<br /><br />Rephrase it as: 1E09 / 1E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+06."
        }
    },
    {
        "question": "What is 3E+07 divided by one hundred?",
        "writeInAnswer": 300000,
        "furtherInfo": {
            "explanation": "What is 3E+07 divided by one hundred?<br /><br />Rephrase it as: 3E07 / 1E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+05."
        }
    },
    {
        "question": "What is 1E+11 divided by ten thousand?",
        "writeInAnswer": 10000000,
        "furtherInfo": {
            "explanation": "What is 1E+11 divided by ten thousand?<br /><br />Rephrase it as: 1E11 / 1E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+07."
        }
    },
    {
        "question": "What is 1E+07 divided by three?",
        "writeInAnswer": 3000000,
        "furtherInfo": {
            "explanation": "What is 1E+07 divided by three?<br /><br />Rephrase it as: 1E07 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+06."
        }
    },
    {
        "question": "What is 3E+02 divided by three?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 3E+02 divided by three?<br /><br />Rephrase it as: 3E02 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 1E+12 divided by one hundred?",
        "writeInAnswer": 10000000000,
        "furtherInfo": {
            "explanation": "What is 1E+12 divided by one hundred?<br /><br />Rephrase it as: 1E12 / 1E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+10."
        }
    },
    {
        "question": "What is 3E+07 divided by ten thousand?",
        "writeInAnswer": 3000,
        "furtherInfo": {
            "explanation": "What is 3E+07 divided by ten thousand?<br /><br />Rephrase it as: 3E07 / 1E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+03."
        }
    },
    {
        "question": "What is 1E+05 divided by ten?",
        "writeInAnswer": 10000,
        "furtherInfo": {
            "explanation": "What is 1E+05 divided by ten?<br /><br />Rephrase it as: 1E05 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+04."
        }
    },
    {
        "question": "What is 1E+05 divided by ten thousand?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 1E+05 divided by ten thousand?<br /><br />Rephrase it as: 1E05 / 1E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 3E+05 divided by three?",
        "writeInAnswer": 100000,
        "furtherInfo": {
            "explanation": "What is 3E+05 divided by three?<br /><br />Rephrase it as: 3E05 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+05."
        }
    },
    {
        "question": "What is 1E+10 divided by three hundred?",
        "writeInAnswer": 30000000,
        "furtherInfo": {
            "explanation": "What is 1E+10 divided by three hundred?<br /><br />Rephrase it as: 1E10 / 3E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+07."
        }
    },
    {
        "question": "What is 1E+05 divided by three thousand?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 1E+05 divided by three thousand?<br /><br />Rephrase it as: 1E05 / 3E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+01."
        }
    },
    {
        "question": "What is 3E+08 divided by three?",
        "writeInAnswer": 100000000,
        "furtherInfo": {
            "explanation": "What is 3E+08 divided by three?<br /><br />Rephrase it as: 3E08 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+08."
        }
    },
    {
        "question": "What is 1E+04 divided by three?",
        "writeInAnswer": 3000,
        "furtherInfo": {
            "explanation": "What is 1E+04 divided by three?<br /><br />Rephrase it as: 1E04 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+03."
        }
    },
    {
        "question": "What is 1E+06 divided by three?",
        "writeInAnswer": 300000,
        "furtherInfo": {
            "explanation": "What is 1E+06 divided by three?<br /><br />Rephrase it as: 1E06 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+05."
        }
    },
    {
        "question": "What is 3E+09 divided by ten thousand?",
        "writeInAnswer": 300000,
        "furtherInfo": {
            "explanation": "What is 3E+09 divided by ten thousand?<br /><br />Rephrase it as: 3E09 / 1E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+05."
        }
    },
    {
        "question": "What is 3E+08 divided by ten thousand?",
        "writeInAnswer": 30000,
        "furtherInfo": {
            "explanation": "What is 3E+08 divided by ten thousand?<br /><br />Rephrase it as: 3E08 / 1E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+04."
        }
    },
    {
        "question": "What is 3E+01 divided by three?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 3E+01 divided by three?<br /><br />Rephrase it as: 3E01 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 1E+12 divided by thirty billion?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 1E+12 divided by thirty billion?<br /><br />Rephrase it as: 1E12 / 3E10.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+01."
        }
    },
    {
        "question": "What is 1E+13 divided by one hundred?",
        "writeInAnswer": 100000000000,
        "furtherInfo": {
            "explanation": "What is 1E+13 divided by one hundred?<br /><br />Rephrase it as: 1E13 / 1E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+11."
        }
    },
    {
        "question": "What is 3E+02 divided by thirty?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 3E+02 divided by thirty?<br /><br />Rephrase it as: 3E02 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 1E+02 divided by thirty?",
        "writeInAnswer": 3,
        "furtherInfo": {
            "explanation": "What is 1E+02 divided by thirty?<br /><br />Rephrase it as: 1E02 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
        }
    },
    {
        "question": "What is 1E+01 divided by three?",
        "writeInAnswer": 3,
        "furtherInfo": {
            "explanation": "What is 1E+01 divided by three?<br /><br />Rephrase it as: 1E01 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
        }
    },
    {
        "question": "What is 1E+13 divided by ten?",
        "writeInAnswer": 1000000000000,
        "furtherInfo": {
            "explanation": "What is 1E+13 divided by ten?<br /><br />Rephrase it as: 1E13 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+12."
        }
    },
    {
        "question": "What is 3E+07 divided by three hundred?",
        "writeInAnswer": 100000,
        "furtherInfo": {
            "explanation": "What is 3E+07 divided by three hundred?<br /><br />Rephrase it as: 3E07 / 3E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+05."
        }
    },
    {
        "question": "What is 1E+13 divided by three?",
        "writeInAnswer": 3000000000000,
        "furtherInfo": {
            "explanation": "What is 1E+13 divided by three?<br /><br />Rephrase it as: 1E13 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+12."
        }
    },
    {
        "question": "What is 3E+03 divided by three?",
        "writeInAnswer": 1000,
        "furtherInfo": {
            "explanation": "What is 3E+03 divided by three?<br /><br />Rephrase it as: 3E03 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
        }
    },
    {
        "question": "What is 3E+02 divided by three?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 3E+02 divided by three?<br /><br />Rephrase it as: 3E02 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 1E+09 divided by ten thousand?",
        "writeInAnswer": 100000,
        "furtherInfo": {
            "explanation": "What is 1E+09 divided by ten thousand?<br /><br />Rephrase it as: 1E09 / 1E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+05."
        }
    },
    {
        "question": "What is 1E+13 divided by one million?",
        "writeInAnswer": 10000000,
        "furtherInfo": {
            "explanation": "What is 1E+13 divided by one million?<br /><br />Rephrase it as: 1E13 / 1E06.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+07."
        }
    },
    {
        "question": "What is 3E+05 divided by three?",
        "writeInAnswer": 100000,
        "furtherInfo": {
            "explanation": "What is 3E+05 divided by three?<br /><br />Rephrase it as: 3E05 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+05."
        }
    },
    {
        "question": "What is 3E+09 divided by thirty?",
        "writeInAnswer": 100000000,
        "furtherInfo": {
            "explanation": "What is 3E+09 divided by thirty?<br /><br />Rephrase it as: 3E09 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+08."
        }
    },
    {
        "question": "What is 1E+02 divided by thirty?",
        "writeInAnswer": 3,
        "furtherInfo": {
            "explanation": "What is 1E+02 divided by thirty?<br /><br />Rephrase it as: 1E02 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
        }
    },
    {
        "question": "What is 3E+05 divided by one thousand?",
        "writeInAnswer": 300,
        "furtherInfo": {
            "explanation": "What is 3E+05 divided by one thousand?<br /><br />Rephrase it as: 3E05 / 1E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+02."
        }
    },
    {
        "question": "What is 3E+07 divided by one hundred thousand?",
        "writeInAnswer": 300,
        "furtherInfo": {
            "explanation": "What is 3E+07 divided by one hundred thousand?<br /><br />Rephrase it as: 3E07 / 1E05.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+02."
        }
    },
    {
        "question": "What is 3E+07 divided by thirty?",
        "writeInAnswer": 1000000,
        "furtherInfo": {
            "explanation": "What is 3E+07 divided by thirty?<br /><br />Rephrase it as: 3E07 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+06."
        }
    },
    {
        "question": "What is 3E+11 divided by one hundred thousand?",
        "writeInAnswer": 3000000,
        "furtherInfo": {
            "explanation": "What is 3E+11 divided by one hundred thousand?<br /><br />Rephrase it as: 3E11 / 1E05.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+06."
        }
    },
    {
        "question": "What is 3E+08 divided by three thousand?",
        "writeInAnswer": 100000,
        "furtherInfo": {
            "explanation": "What is 3E+08 divided by three thousand?<br /><br />Rephrase it as: 3E08 / 3E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+05."
        }
    },
    {
        "question": "What is 1E+03 divided by three hundred?",
        "writeInAnswer": 3,
        "furtherInfo": {
            "explanation": "What is 1E+03 divided by three hundred?<br /><br />Rephrase it as: 1E03 / 3E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
        }
    },
    {
        "question": "What is 1E+01 divided by one?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 1E+01 divided by one?<br /><br />Rephrase it as: 1E01 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 3E+08 divided by one hundred thousand?",
        "writeInAnswer": 3000,
        "furtherInfo": {
            "explanation": "What is 3E+08 divided by one hundred thousand?<br /><br />Rephrase it as: 3E08 / 1E05.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+03."
        }
    },
    {
        "question": "What is 3E+05 divided by thirty thousand?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 3E+05 divided by thirty thousand?<br /><br />Rephrase it as: 3E05 / 3E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 1E+08 divided by one million?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 1E+08 divided by one million?<br /><br />Rephrase it as: 1E08 / 1E06.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 1E+04 divided by three hundred?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 1E+04 divided by three hundred?<br /><br />Rephrase it as: 1E04 / 3E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+01."
        }
    },
    {
        "question": "What is 1E+13 divided by one million?",
        "writeInAnswer": 10000000,
        "furtherInfo": {
            "explanation": "What is 1E+13 divided by one million?<br /><br />Rephrase it as: 1E13 / 1E06.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+07."
        }
    },
    {
        "question": "What is 3E+02 divided by three?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 3E+02 divided by three?<br /><br />Rephrase it as: 3E02 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 3E+06 divided by three hundred thousand?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 3E+06 divided by three hundred thousand?<br /><br />Rephrase it as: 3E06 / 3E05.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 1E+12 divided by one?",
        "writeInAnswer": 1000000000000,
        "furtherInfo": {
            "explanation": "What is 1E+12 divided by one?<br /><br />Rephrase it as: 1E12 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+12."
        }
    },
    {
        "question": "What is 1E+11 divided by thirty thousand?",
        "writeInAnswer": 3000000,
        "furtherInfo": {
            "explanation": "What is 1E+11 divided by thirty thousand?<br /><br />Rephrase it as: 1E11 / 3E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+06."
        }
    },
    {
        "question": "What is 1E+02 divided by three?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 1E+02 divided by three?<br /><br />Rephrase it as: 1E02 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+01."
        }
    },
    {
        "question": "What is 3E+02 divided by three?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 3E+02 divided by three?<br /><br />Rephrase it as: 3E02 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 3E+12 divided by one hundred thousand?",
        "writeInAnswer": 30000000,
        "furtherInfo": {
            "explanation": "What is 3E+12 divided by one hundred thousand?<br /><br />Rephrase it as: 3E12 / 1E05.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+07."
        }
    },
    {
        "question": "What is 3E+03 divided by one hundred?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 3E+03 divided by one hundred?<br /><br />Rephrase it as: 3E03 / 1E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
        }
    },
    {
        "question": "What is 1E+08 divided by thirty thousand?",
        "writeInAnswer": 3000,
        "furtherInfo": {
            "explanation": "What is 1E+08 divided by thirty thousand?<br /><br />Rephrase it as: 1E08 / 3E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+03."
        }
    },
    {
        "question": "What is 3E+03 divided by three?",
        "writeInAnswer": 1000,
        "furtherInfo": {
            "explanation": "What is 3E+03 divided by three?<br /><br />Rephrase it as: 3E03 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
        }
    },
    {
        "question": "What is 1E+09 divided by ten thousand?",
        "writeInAnswer": 100000,
        "furtherInfo": {
            "explanation": "What is 1E+09 divided by ten thousand?<br /><br />Rephrase it as: 1E09 / 1E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+05."
        }
    },
    {
        "question": "What is 1E+03 divided by three?",
        "writeInAnswer": 300,
        "furtherInfo": {
            "explanation": "What is 1E+03 divided by three?<br /><br />Rephrase it as: 1E03 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+02."
        }
    },
    {
        "question": "What is 3E+05 divided by three hundred?",
        "writeInAnswer": 1000,
        "furtherInfo": {
            "explanation": "What is 3E+05 divided by three hundred?<br /><br />Rephrase it as: 3E05 / 3E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
        }
    },
    {
        "question": "What is 3E+06 divided by thirty?",
        "writeInAnswer": 100000,
        "furtherInfo": {
            "explanation": "What is 3E+06 divided by thirty?<br /><br />Rephrase it as: 3E06 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+05."
        }
    },
    {
        "question": "What is 3E+07 divided by three?",
        "writeInAnswer": 10000000,
        "furtherInfo": {
            "explanation": "What is 3E+07 divided by three?<br /><br />Rephrase it as: 3E07 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+07."
        }
    },
    {
        "question": "What is 3E+03 divided by one hundred?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 3E+03 divided by one hundred?<br /><br />Rephrase it as: 3E03 / 1E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
        }
    },
    {
        "question": "What is 3E+13 divided by three thousand?",
        "writeInAnswer": 10000000000,
        "furtherInfo": {
            "explanation": "What is 3E+13 divided by three thousand?<br /><br />Rephrase it as: 3E13 / 3E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+10."
        }
    },
    {
        "question": "What is 1E+02 divided by three?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 1E+02 divided by three?<br /><br />Rephrase it as: 1E02 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+01."
        }
    },
    {
        "question": "What is 3E+06 divided by three hundred thousand?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 3E+06 divided by three hundred thousand?<br /><br />Rephrase it as: 3E06 / 3E05.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 3E+09 divided by thirty million?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 3E+09 divided by thirty million?<br /><br />Rephrase it as: 3E09 / 3E07.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 3E+03 divided by thirty?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 3E+03 divided by thirty?<br /><br />Rephrase it as: 3E03 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 3E+07 divided by thirty?",
        "writeInAnswer": 1000000,
        "furtherInfo": {
            "explanation": "What is 3E+07 divided by thirty?<br /><br />Rephrase it as: 3E07 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+06."
        }
    },
    {
        "question": "What is 1E+11 divided by thirty million?",
        "writeInAnswer": 3000,
        "furtherInfo": {
            "explanation": "What is 1E+11 divided by thirty million?<br /><br />Rephrase it as: 1E11 / 3E07.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+03."
        }
    },
    {
        "question": "What is 3E+09 divided by one hundred?",
        "writeInAnswer": 30000000,
        "furtherInfo": {
            "explanation": "What is 3E+09 divided by one hundred?<br /><br />Rephrase it as: 3E09 / 1E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+07."
        }
    },
    {
        "question": "What is 1E+13 divided by one trillion?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 1E+13 divided by one trillion?<br /><br />Rephrase it as: 1E13 / 1E12.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 3E+09 divided by one million?",
        "writeInAnswer": 3000,
        "furtherInfo": {
            "explanation": "What is 3E+09 divided by one million?<br /><br />Rephrase it as: 3E09 / 1E06.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+03."
        }
    },
    {
        "question": "What is 3E+09 divided by one?",
        "writeInAnswer": 3000000000,
        "furtherInfo": {
            "explanation": "What is 3E+09 divided by one?<br /><br />Rephrase it as: 3E09 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+09."
        }
    },
    {
        "question": "What is 3E+05 divided by ten thousand?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 3E+05 divided by ten thousand?<br /><br />Rephrase it as: 3E05 / 1E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
        }
    },
    {
        "question": "What is 1E+10 divided by one hundred?",
        "writeInAnswer": 100000000,
        "furtherInfo": {
            "explanation": "What is 1E+10 divided by one hundred?<br /><br />Rephrase it as: 1E10 / 1E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+08."
        }
    },
    {
        "question": "What is 1E+08 divided by ten thousand?",
        "writeInAnswer": 10000,
        "furtherInfo": {
            "explanation": "What is 1E+08 divided by ten thousand?<br /><br />Rephrase it as: 1E08 / 1E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+04."
        }
    },
    {
        "question": "What is 3E+10 divided by three thousand?",
        "writeInAnswer": 10000000,
        "furtherInfo": {
            "explanation": "What is 3E+10 divided by three thousand?<br /><br />Rephrase it as: 3E10 / 3E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+07."
        }
    },
    {
        "question": "What is 3E+01 divided by three?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 3E+01 divided by three?<br /><br />Rephrase it as: 3E01 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 3E+01 divided by three?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 3E+01 divided by three?<br /><br />Rephrase it as: 3E01 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 3E+11 divided by one?",
        "writeInAnswer": 300000000000,
        "furtherInfo": {
            "explanation": "What is 3E+11 divided by one?<br /><br />Rephrase it as: 3E11 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+11."
        }
    },
    {
        "question": "What is 1E+03 divided by one hundred?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 1E+03 divided by one hundred?<br /><br />Rephrase it as: 1E03 / 1E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 3E+11 divided by three hundred million?",
        "writeInAnswer": 1000,
        "furtherInfo": {
            "explanation": "What is 3E+11 divided by three hundred million?<br /><br />Rephrase it as: 3E11 / 3E08.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
        }
    },
    {
        "question": "What is 1E+08 divided by one hundred thousand?",
        "writeInAnswer": 1000,
        "furtherInfo": {
            "explanation": "What is 1E+08 divided by one hundred thousand?<br /><br />Rephrase it as: 1E08 / 1E05.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
        }
    },
    {
        "question": "What is 3E+08 divided by thirty million?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 3E+08 divided by thirty million?<br /><br />Rephrase it as: 3E08 / 3E07.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 1E+01 divided by one?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 1E+01 divided by one?<br /><br />Rephrase it as: 1E01 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 3E+01 divided by three?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 3E+01 divided by three?<br /><br />Rephrase it as: 3E01 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 1E+04 divided by three?",
        "writeInAnswer": 3000,
        "furtherInfo": {
            "explanation": "What is 1E+04 divided by three?<br /><br />Rephrase it as: 1E04 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+03."
        }
    },
    {
        "question": "What is 1E+05 divided by one hundred?",
        "writeInAnswer": 1000,
        "furtherInfo": {
            "explanation": "What is 1E+05 divided by one hundred?<br /><br />Rephrase it as: 1E05 / 1E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
        }
    },
    {
        "question": "What is 3E+08 divided by ten thousand?",
        "writeInAnswer": 30000,
        "furtherInfo": {
            "explanation": "What is 3E+08 divided by ten thousand?<br /><br />Rephrase it as: 3E08 / 1E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+04."
        }
    },
    {
        "question": "What is 1E+10 divided by thirty thousand?",
        "writeInAnswer": 300000,
        "furtherInfo": {
            "explanation": "What is 1E+10 divided by thirty thousand?<br /><br />Rephrase it as: 1E10 / 3E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+05."
        }
    },
    {
        "question": "What is 1E+03 divided by thirty?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 1E+03 divided by thirty?<br /><br />Rephrase it as: 1E03 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+01."
        }
    },
    {
        "question": "What is 1E+02 divided by three?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 1E+02 divided by three?<br /><br />Rephrase it as: 1E02 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+01."
        }
    },
    {
        "question": "What is 3E+03 divided by three?",
        "writeInAnswer": 1000,
        "furtherInfo": {
            "explanation": "What is 3E+03 divided by three?<br /><br />Rephrase it as: 3E03 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
        }
    },
    {
        "question": "What is 3E+02 divided by one?",
        "writeInAnswer": 300,
        "furtherInfo": {
            "explanation": "What is 3E+02 divided by one?<br /><br />Rephrase it as: 3E02 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+02."
        }
    },
    {
        "question": "What is 3E+04 divided by one hundred?",
        "writeInAnswer": 300,
        "furtherInfo": {
            "explanation": "What is 3E+04 divided by one hundred?<br /><br />Rephrase it as: 3E04 / 1E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+02."
        }
    },
    {
        "question": "What is 3E+12 divided by thirty billion?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 3E+12 divided by thirty billion?<br /><br />Rephrase it as: 3E12 / 3E10.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 3E+04 divided by three hundred?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 3E+04 divided by three hundred?<br /><br />Rephrase it as: 3E04 / 3E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 3E+08 divided by three thousand?",
        "writeInAnswer": 100000,
        "furtherInfo": {
            "explanation": "What is 3E+08 divided by three thousand?<br /><br />Rephrase it as: 3E08 / 3E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+05."
        }
    },
    {
        "question": "What is 1E+11 divided by three?",
        "writeInAnswer": 30000000000,
        "furtherInfo": {
            "explanation": "What is 1E+11 divided by three?<br /><br />Rephrase it as: 1E11 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+10."
        }
    },
    {
        "question": "What is 3E+03 divided by ten?",
        "writeInAnswer": 300,
        "furtherInfo": {
            "explanation": "What is 3E+03 divided by ten?<br /><br />Rephrase it as: 3E03 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+02."
        }
    },
    {
        "question": "What is 1E+08 divided by three thousand?",
        "writeInAnswer": 30000,
        "furtherInfo": {
            "explanation": "What is 1E+08 divided by three thousand?<br /><br />Rephrase it as: 1E08 / 3E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+04."
        }
    },
    {
        "question": "What is 1E+07 divided by one million?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 1E+07 divided by one million?<br /><br />Rephrase it as: 1E07 / 1E06.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 1E+09 divided by ten million?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 1E+09 divided by ten million?<br /><br />Rephrase it as: 1E09 / 1E07.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 1E+09 divided by thirty?",
        "writeInAnswer": 30000000,
        "furtherInfo": {
            "explanation": "What is 1E+09 divided by thirty?<br /><br />Rephrase it as: 1E09 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+07."
        }
    },
    {
        "question": "What is 3E+02 divided by one?",
        "writeInAnswer": 300,
        "furtherInfo": {
            "explanation": "What is 3E+02 divided by one?<br /><br />Rephrase it as: 3E02 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+02."
        }
    },
    {
        "question": "What is 3E+01 divided by three?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 3E+01 divided by three?<br /><br />Rephrase it as: 3E01 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 1E+04 divided by one hundred?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 1E+04 divided by one hundred?<br /><br />Rephrase it as: 1E04 / 1E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 1E+01 divided by one?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 1E+01 divided by one?<br /><br />Rephrase it as: 1E01 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 1E+10 divided by ten thousand?",
        "writeInAnswer": 1000000,
        "furtherInfo": {
            "explanation": "What is 1E+10 divided by ten thousand?<br /><br />Rephrase it as: 1E10 / 1E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+06."
        }
    },
    {
        "question": "What is 3E+04 divided by ten?",
        "writeInAnswer": 3000,
        "furtherInfo": {
            "explanation": "What is 3E+04 divided by ten?<br /><br />Rephrase it as: 3E04 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+03."
        }
    },
    {
        "question": "What is 3E+01 divided by one?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 3E+01 divided by one?<br /><br />Rephrase it as: 3E01 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
        }
    },
    {
        "question": "What is 3E+01 divided by three?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 3E+01 divided by three?<br /><br />Rephrase it as: 3E01 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 3E+12 divided by three hundred million?",
        "writeInAnswer": 10000,
        "furtherInfo": {
            "explanation": "What is 3E+12 divided by three hundred million?<br /><br />Rephrase it as: 3E12 / 3E08.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+04."
        }
    },
    {
        "question": "What is 1E+04 divided by three?",
        "writeInAnswer": 3000,
        "furtherInfo": {
            "explanation": "What is 1E+04 divided by three?<br /><br />Rephrase it as: 1E04 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+03."
        }
    },
    {
        "question": "What is 1E+07 divided by one million?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 1E+07 divided by one million?<br /><br />Rephrase it as: 1E07 / 1E06.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 1E+11 divided by ten?",
        "writeInAnswer": 10000000000,
        "furtherInfo": {
            "explanation": "What is 1E+11 divided by ten?<br /><br />Rephrase it as: 1E11 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+10."
        }
    },
    {
        "question": "What is 3E+10 divided by ten thousand?",
        "writeInAnswer": 3000000,
        "furtherInfo": {
            "explanation": "What is 3E+10 divided by ten thousand?<br /><br />Rephrase it as: 3E10 / 1E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+06."
        }
    },
    {
        "question": "What is 1E+10 divided by one million?",
        "writeInAnswer": 10000,
        "furtherInfo": {
            "explanation": "What is 1E+10 divided by one million?<br /><br />Rephrase it as: 1E10 / 1E06.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+04."
        }
    },
    {
        "question": "What is 1E+05 divided by thirty?",
        "writeInAnswer": 3000,
        "furtherInfo": {
            "explanation": "What is 1E+05 divided by thirty?<br /><br />Rephrase it as: 1E05 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+03."
        }
    },
    {
        "question": "What is 3E+04 divided by ten?",
        "writeInAnswer": 3000,
        "furtherInfo": {
            "explanation": "What is 3E+04 divided by ten?<br /><br />Rephrase it as: 3E04 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+03."
        }
    },
    {
        "question": "What is 1E+12 divided by ten?",
        "writeInAnswer": 100000000000,
        "furtherInfo": {
            "explanation": "What is 1E+12 divided by ten?<br /><br />Rephrase it as: 1E12 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+11."
        }
    },
    {
        "question": "What is 1E+04 divided by three?",
        "writeInAnswer": 3000,
        "furtherInfo": {
            "explanation": "What is 1E+04 divided by three?<br /><br />Rephrase it as: 1E04 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+03."
        }
    },
    {
        "question": "What is 1E+12 divided by ten thousand?",
        "writeInAnswer": 100000000,
        "furtherInfo": {
            "explanation": "What is 1E+12 divided by ten thousand?<br /><br />Rephrase it as: 1E12 / 1E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+08."
        }
    },
    {
        "question": "What is 3E+06 divided by ten thousand?",
        "writeInAnswer": 300,
        "furtherInfo": {
            "explanation": "What is 3E+06 divided by ten thousand?<br /><br />Rephrase it as: 3E06 / 1E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+02."
        }
    },
    {
        "question": "What is 1E+05 divided by three?",
        "writeInAnswer": 30000,
        "furtherInfo": {
            "explanation": "What is 1E+05 divided by three?<br /><br />Rephrase it as: 1E05 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+04."
        }
    },
    {
        "question": "What is 3E+07 divided by one thousand?",
        "writeInAnswer": 30000,
        "furtherInfo": {
            "explanation": "What is 3E+07 divided by one thousand?<br /><br />Rephrase it as: 3E07 / 1E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+04."
        }
    },
    {
        "question": "What is 1E+02 divided by ten?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 1E+02 divided by ten?<br /><br />Rephrase it as: 1E02 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 3E+02 divided by one?",
        "writeInAnswer": 300,
        "furtherInfo": {
            "explanation": "What is 3E+02 divided by one?<br /><br />Rephrase it as: 3E02 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+02."
        }
    },
    {
        "question": "What is 1E+04 divided by ten?",
        "writeInAnswer": 1000,
        "furtherInfo": {
            "explanation": "What is 1E+04 divided by ten?<br /><br />Rephrase it as: 1E04 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
        }
    },
    {
        "question": "What is 3E+13 divided by three hundred million?",
        "writeInAnswer": 100000,
        "furtherInfo": {
            "explanation": "What is 3E+13 divided by three hundred million?<br /><br />Rephrase it as: 3E13 / 3E08.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+05."
        }
    },
    {
        "question": "What is 1E+09 divided by one hundred million?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 1E+09 divided by one hundred million?<br /><br />Rephrase it as: 1E09 / 1E08.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 3E+01 divided by one?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 3E+01 divided by one?<br /><br />Rephrase it as: 3E01 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
        }
    },
    {
        "question": "What is 1E+13 divided by three billion?",
        "writeInAnswer": 3000,
        "furtherInfo": {
            "explanation": "What is 1E+13 divided by three billion?<br /><br />Rephrase it as: 1E13 / 3E09.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+03."
        }
    },
    {
        "question": "What is 3E+07 divided by one?",
        "writeInAnswer": 30000000,
        "furtherInfo": {
            "explanation": "What is 3E+07 divided by one?<br /><br />Rephrase it as: 3E07 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+07."
        }
    },
    {
        "question": "What is 3E+08 divided by ten thousand?",
        "writeInAnswer": 30000,
        "furtherInfo": {
            "explanation": "What is 3E+08 divided by ten thousand?<br /><br />Rephrase it as: 3E08 / 1E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+04."
        }
    },
    {
        "question": "What is 1E+08 divided by three million?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 1E+08 divided by three million?<br /><br />Rephrase it as: 1E08 / 3E06.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+01."
        }
    },
    {
        "question": "What is 3E+07 divided by one thousand?",
        "writeInAnswer": 30000,
        "furtherInfo": {
            "explanation": "What is 3E+07 divided by one thousand?<br /><br />Rephrase it as: 3E07 / 1E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+04."
        }
    },
    {
        "question": "What is 1E+04 divided by one thousand?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 1E+04 divided by one thousand?<br /><br />Rephrase it as: 1E04 / 1E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 3E+07 divided by three?",
        "writeInAnswer": 10000000,
        "furtherInfo": {
            "explanation": "What is 3E+07 divided by three?<br /><br />Rephrase it as: 3E07 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+07."
        }
    },
    {
        "question": "What is 3E+13 divided by thirty thousand?",
        "writeInAnswer": 1000000000,
        "furtherInfo": {
            "explanation": "What is 3E+13 divided by thirty thousand?<br /><br />Rephrase it as: 3E13 / 3E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+09."
        }
    },
    {
        "question": "What is 3E+07 divided by ten thousand?",
        "writeInAnswer": 3000,
        "furtherInfo": {
            "explanation": "What is 3E+07 divided by ten thousand?<br /><br />Rephrase it as: 3E07 / 1E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+03."
        }
    },
    {
        "question": "What is 1E+07 divided by one hundred?",
        "writeInAnswer": 100000,
        "furtherInfo": {
            "explanation": "What is 1E+07 divided by one hundred?<br /><br />Rephrase it as: 1E07 / 1E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+05."
        }
    },
    {
        "question": "What is 3E+11 divided by thirty million?",
        "writeInAnswer": 10000,
        "furtherInfo": {
            "explanation": "What is 3E+11 divided by thirty million?<br /><br />Rephrase it as: 3E11 / 3E07.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+04."
        }
    },
    {
        "question": "What is 1E+12 divided by one million?",
        "writeInAnswer": 1000000,
        "furtherInfo": {
            "explanation": "What is 1E+12 divided by one million?<br /><br />Rephrase it as: 1E12 / 1E06.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+06."
        }
    },
    {
        "question": "What is 1E+01 divided by three?",
        "writeInAnswer": 3,
        "furtherInfo": {
            "explanation": "What is 1E+01 divided by three?<br /><br />Rephrase it as: 1E01 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
        }
    },
    {
        "question": "What is 3E+11 divided by ten?",
        "writeInAnswer": 30000000000,
        "furtherInfo": {
            "explanation": "What is 3E+11 divided by ten?<br /><br />Rephrase it as: 3E11 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+10."
        }
    },
    {
        "question": "What is 3E+03 divided by three?",
        "writeInAnswer": 1000,
        "furtherInfo": {
            "explanation": "What is 3E+03 divided by three?<br /><br />Rephrase it as: 3E03 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
        }
    },
    {
        "question": "What is 1E+11 divided by ten?",
        "writeInAnswer": 10000000000,
        "furtherInfo": {
            "explanation": "What is 1E+11 divided by ten?<br /><br />Rephrase it as: 1E11 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+10."
        }
    },
    {
        "question": "What is 3E+02 divided by three?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 3E+02 divided by three?<br /><br />Rephrase it as: 3E02 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 1E+07 divided by thirty?",
        "writeInAnswer": 300000,
        "furtherInfo": {
            "explanation": "What is 1E+07 divided by thirty?<br /><br />Rephrase it as: 1E07 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+05."
        }
    },
    {
        "question": "What is 1E+02 divided by three?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 1E+02 divided by three?<br /><br />Rephrase it as: 1E02 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+01."
        }
    },
    {
        "question": "What is 1E+05 divided by three thousand?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 1E+05 divided by three thousand?<br /><br />Rephrase it as: 1E05 / 3E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+01."
        }
    },
    {
        "question": "What is 3E+13 divided by three million?",
        "writeInAnswer": 10000000,
        "furtherInfo": {
            "explanation": "What is 3E+13 divided by three million?<br /><br />Rephrase it as: 3E13 / 3E06.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+07."
        }
    },
    {
        "question": "What is 1E+01 divided by three?",
        "writeInAnswer": 3,
        "furtherInfo": {
            "explanation": "What is 1E+01 divided by three?<br /><br />Rephrase it as: 1E01 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
        }
    },
    {
        "question": "What is 3E+12 divided by thirty billion?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 3E+12 divided by thirty billion?<br /><br />Rephrase it as: 3E12 / 3E10.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 1E+03 divided by three?",
        "writeInAnswer": 300,
        "furtherInfo": {
            "explanation": "What is 1E+03 divided by three?<br /><br />Rephrase it as: 1E03 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+02."
        }
    },
    {
        "question": "What is 3E+09 divided by three hundred?",
        "writeInAnswer": 10000000,
        "furtherInfo": {
            "explanation": "What is 3E+09 divided by three hundred?<br /><br />Rephrase it as: 3E09 / 3E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+07."
        }
    },
    {
        "question": "What is 1E+02 divided by one?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 1E+02 divided by one?<br /><br />Rephrase it as: 1E02 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 3E+02 divided by ten?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 3E+02 divided by ten?<br /><br />Rephrase it as: 3E02 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
        }
    },
    {
        "question": "What is 1E+10 divided by three thousand?",
        "writeInAnswer": 3000000,
        "furtherInfo": {
            "explanation": "What is 1E+10 divided by three thousand?<br /><br />Rephrase it as: 1E10 / 3E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+06."
        }
    },
    {
        "question": "What is 3E+02 divided by thirty?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 3E+02 divided by thirty?<br /><br />Rephrase it as: 3E02 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 3E+01 divided by one?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 3E+01 divided by one?<br /><br />Rephrase it as: 3E01 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
        }
    },
    {
        "question": "What is 3E+03 divided by three?",
        "writeInAnswer": 1000,
        "furtherInfo": {
            "explanation": "What is 3E+03 divided by three?<br /><br />Rephrase it as: 3E03 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
        }
    },
    {
        "question": "What is 1E+09 divided by three hundred thousand?",
        "writeInAnswer": 3000,
        "furtherInfo": {
            "explanation": "What is 1E+09 divided by three hundred thousand?<br /><br />Rephrase it as: 1E09 / 3E05.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+03."
        }
    },
    {
        "question": "What is 3E+05 divided by thirty thousand?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 3E+05 divided by thirty thousand?<br /><br />Rephrase it as: 3E05 / 3E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 3E+01 divided by three?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 3E+01 divided by three?<br /><br />Rephrase it as: 3E01 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 3E+11 divided by one million?",
        "writeInAnswer": 300000,
        "furtherInfo": {
            "explanation": "What is 3E+11 divided by one million?<br /><br />Rephrase it as: 3E11 / 1E06.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+05."
        }
    },
    {
        "question": "What is 3E+11 divided by three billion?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 3E+11 divided by three billion?<br /><br />Rephrase it as: 3E11 / 3E09.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 1E+01 divided by one?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 1E+01 divided by one?<br /><br />Rephrase it as: 1E01 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 1E+08 divided by three hundred?",
        "writeInAnswer": 300000,
        "furtherInfo": {
            "explanation": "What is 1E+08 divided by three hundred?<br /><br />Rephrase it as: 1E08 / 3E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+05."
        }
    },
    {
        "question": "What is 1E+13 divided by one?",
        "writeInAnswer": 10000000000000,
        "furtherInfo": {
            "explanation": "What is 1E+13 divided by one?<br /><br />Rephrase it as: 1E13 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+13."
        }
    },
    {
        "question": "What is 1E+10 divided by ten?",
        "writeInAnswer": 1000000000,
        "furtherInfo": {
            "explanation": "What is 1E+10 divided by ten?<br /><br />Rephrase it as: 1E10 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+09."
        }
    },
    {
        "question": "What is 1E+05 divided by one?",
        "writeInAnswer": 100000,
        "furtherInfo": {
            "explanation": "What is 1E+05 divided by one?<br /><br />Rephrase it as: 1E05 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+05."
        }
    },
    {
        "question": "What is 3E+04 divided by three hundred?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 3E+04 divided by three hundred?<br /><br />Rephrase it as: 3E04 / 3E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 3E+03 divided by thirty?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 3E+03 divided by thirty?<br /><br />Rephrase it as: 3E03 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 1E+04 divided by three thousand?",
        "writeInAnswer": 3,
        "furtherInfo": {
            "explanation": "What is 1E+04 divided by three thousand?<br /><br />Rephrase it as: 1E04 / 3E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
        }
    },
    {
        "question": "What is 1E+06 divided by one?",
        "writeInAnswer": 1000000,
        "furtherInfo": {
            "explanation": "What is 1E+06 divided by one?<br /><br />Rephrase it as: 1E06 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+06."
        }
    },
    {
        "question": "What is 3E+10 divided by thirty thousand?",
        "writeInAnswer": 1000000,
        "furtherInfo": {
            "explanation": "What is 3E+10 divided by thirty thousand?<br /><br />Rephrase it as: 3E10 / 3E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+06."
        }
    },
    {
        "question": "What is 3E+11 divided by thirty thousand?",
        "writeInAnswer": 10000000,
        "furtherInfo": {
            "explanation": "What is 3E+11 divided by thirty thousand?<br /><br />Rephrase it as: 3E11 / 3E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+07."
        }
    },
    {
        "question": "What is 1E+01 divided by one?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 1E+01 divided by one?<br /><br />Rephrase it as: 1E01 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 1E+07 divided by three million?",
        "writeInAnswer": 3,
        "furtherInfo": {
            "explanation": "What is 1E+07 divided by three million?<br /><br />Rephrase it as: 1E07 / 3E06.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
        }
    },
    {
        "question": "What is 3E+04 divided by one hundred?",
        "writeInAnswer": 300,
        "furtherInfo": {
            "explanation": "What is 3E+04 divided by one hundred?<br /><br />Rephrase it as: 3E04 / 1E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+02."
        }
    },
    {
        "question": "What is 3E+05 divided by ten?",
        "writeInAnswer": 30000,
        "furtherInfo": {
            "explanation": "What is 3E+05 divided by ten?<br /><br />Rephrase it as: 3E05 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+04."
        }
    },
    {
        "question": "What is 1E+01 divided by one?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 1E+01 divided by one?<br /><br />Rephrase it as: 1E01 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 1E+06 divided by three?",
        "writeInAnswer": 300000,
        "furtherInfo": {
            "explanation": "What is 1E+06 divided by three?<br /><br />Rephrase it as: 1E06 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+05."
        }
    },
    {
        "question": "What is 3E+06 divided by three hundred?",
        "writeInAnswer": 10000,
        "furtherInfo": {
            "explanation": "What is 3E+06 divided by three hundred?<br /><br />Rephrase it as: 3E06 / 3E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+04."
        }
    },
    {
        "question": "What is 3E+04 divided by one hundred?",
        "writeInAnswer": 300,
        "furtherInfo": {
            "explanation": "What is 3E+04 divided by one hundred?<br /><br />Rephrase it as: 3E04 / 1E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+02."
        }
    },
    {
        "question": "What is 3E+12 divided by three?",
        "writeInAnswer": 1000000000000,
        "furtherInfo": {
            "explanation": "What is 3E+12 divided by three?<br /><br />Rephrase it as: 3E12 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+12."
        }
    },
    {
        "question": "What is 3E+12 divided by three hundred thousand?",
        "writeInAnswer": 10000000,
        "furtherInfo": {
            "explanation": "What is 3E+12 divided by three hundred thousand?<br /><br />Rephrase it as: 3E12 / 3E05.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+07."
        }
    },
    {
        "question": "What is 1E+10 divided by one hundred?",
        "writeInAnswer": 100000000,
        "furtherInfo": {
            "explanation": "What is 1E+10 divided by one hundred?<br /><br />Rephrase it as: 1E10 / 1E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+08."
        }
    },
    {
        "question": "What is 1E+10 divided by three hundred?",
        "writeInAnswer": 30000000,
        "furtherInfo": {
            "explanation": "What is 1E+10 divided by three hundred?<br /><br />Rephrase it as: 1E10 / 3E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+07."
        }
    },
    {
        "question": "What is 3E+13 divided by one trillion?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 3E+13 divided by one trillion?<br /><br />Rephrase it as: 3E13 / 1E12.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
        }
    },
    {
        "question": "What is 1E+11 divided by three hundred thousand?",
        "writeInAnswer": 300000,
        "furtherInfo": {
            "explanation": "What is 1E+11 divided by three hundred thousand?<br /><br />Rephrase it as: 1E11 / 3E05.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+05."
        }
    },
    {
        "question": "What is 1E+07 divided by three thousand?",
        "writeInAnswer": 3000,
        "furtherInfo": {
            "explanation": "What is 1E+07 divided by three thousand?<br /><br />Rephrase it as: 1E07 / 3E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+03."
        }
    },
    {
        "question": "What is 3E+07 divided by one hundred?",
        "writeInAnswer": 300000,
        "furtherInfo": {
            "explanation": "What is 3E+07 divided by one hundred?<br /><br />Rephrase it as: 3E07 / 1E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+05."
        }
    },
    {
        "question": "What is 3E+04 divided by thirty?",
        "writeInAnswer": 1000,
        "furtherInfo": {
            "explanation": "What is 3E+04 divided by thirty?<br /><br />Rephrase it as: 3E04 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
        }
    },
    {
        "question": "What is 1E+08 divided by ten thousand?",
        "writeInAnswer": 10000,
        "furtherInfo": {
            "explanation": "What is 1E+08 divided by ten thousand?<br /><br />Rephrase it as: 1E08 / 1E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+04."
        }
    },
    {
        "question": "What is 3E+07 divided by three?",
        "writeInAnswer": 10000000,
        "furtherInfo": {
            "explanation": "What is 3E+07 divided by three?<br /><br />Rephrase it as: 3E07 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+07."
        }
    },
    {
        "question": "What is 3E+05 divided by three?",
        "writeInAnswer": 100000,
        "furtherInfo": {
            "explanation": "What is 3E+05 divided by three?<br /><br />Rephrase it as: 3E05 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+05."
        }
    },
    {
        "question": "What is 3E+09 divided by thirty thousand?",
        "writeInAnswer": 100000,
        "furtherInfo": {
            "explanation": "What is 3E+09 divided by thirty thousand?<br /><br />Rephrase it as: 3E09 / 3E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+05."
        }
    },
    {
        "question": "What is 3E+03 divided by one hundred?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 3E+03 divided by one hundred?<br /><br />Rephrase it as: 3E03 / 1E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
        }
    },
    {
        "question": "What is 1E+12 divided by three hundred?",
        "writeInAnswer": 3000000000,
        "furtherInfo": {
            "explanation": "What is 1E+12 divided by three hundred?<br /><br />Rephrase it as: 1E12 / 3E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+09."
        }
    },
    {
        "question": "What is 3E+05 divided by three?",
        "writeInAnswer": 100000,
        "furtherInfo": {
            "explanation": "What is 3E+05 divided by three?<br /><br />Rephrase it as: 3E05 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+05."
        }
    },
    {
        "question": "What is 3E+07 divided by one hundred?",
        "writeInAnswer": 300000,
        "furtherInfo": {
            "explanation": "What is 3E+07 divided by one hundred?<br /><br />Rephrase it as: 3E07 / 1E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+05."
        }
    },
    {
        "question": "What is 3E+10 divided by three million?",
        "writeInAnswer": 10000,
        "furtherInfo": {
            "explanation": "What is 3E+10 divided by three million?<br /><br />Rephrase it as: 3E10 / 3E06.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+04."
        }
    },
    {
        "question": "What is 1E+12 divided by one billion?",
        "writeInAnswer": 1000,
        "furtherInfo": {
            "explanation": "What is 1E+12 divided by one billion?<br /><br />Rephrase it as: 1E12 / 1E09.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
        }
    },
    {
        "question": "What is 1E+04 divided by one hundred?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 1E+04 divided by one hundred?<br /><br />Rephrase it as: 1E04 / 1E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 3E+06 divided by three thousand?",
        "writeInAnswer": 1000,
        "furtherInfo": {
            "explanation": "What is 3E+06 divided by three thousand?<br /><br />Rephrase it as: 3E06 / 3E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
        }
    },
    {
        "question": "What is 1E+05 divided by one hundred?",
        "writeInAnswer": 1000,
        "furtherInfo": {
            "explanation": "What is 1E+05 divided by one hundred?<br /><br />Rephrase it as: 1E05 / 1E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
        }
    },
    {
        "question": "What is 1E+10 divided by three hundred million?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 1E+10 divided by three hundred million?<br /><br />Rephrase it as: 1E10 / 3E08.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+01."
        }
    },
    {
        "question": "What is 1E+11 divided by thirty billion?",
        "writeInAnswer": 3,
        "furtherInfo": {
            "explanation": "What is 1E+11 divided by thirty billion?<br /><br />Rephrase it as: 1E11 / 3E10.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
        }
    },
    {
        "question": "What is 1E+01 divided by one?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 1E+01 divided by one?<br /><br />Rephrase it as: 1E01 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 3E+02 divided by one?",
        "writeInAnswer": 300,
        "furtherInfo": {
            "explanation": "What is 3E+02 divided by one?<br /><br />Rephrase it as: 3E02 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+02."
        }
    },
    {
        "question": "What is 1E+03 divided by thirty?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 1E+03 divided by thirty?<br /><br />Rephrase it as: 1E03 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+01."
        }
    },
    {
        "question": "What is 1E+04 divided by ten?",
        "writeInAnswer": 1000,
        "furtherInfo": {
            "explanation": "What is 1E+04 divided by ten?<br /><br />Rephrase it as: 1E04 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
        }
    },
    {
        "question": "What is 3E+07 divided by three thousand?",
        "writeInAnswer": 10000,
        "furtherInfo": {
            "explanation": "What is 3E+07 divided by three thousand?<br /><br />Rephrase it as: 3E07 / 3E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+04."
        }
    },
    {
        "question": "What is 3E+11 divided by ten million?",
        "writeInAnswer": 30000,
        "furtherInfo": {
            "explanation": "What is 3E+11 divided by ten million?<br /><br />Rephrase it as: 3E11 / 1E07.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+04."
        }
    },
    {
        "question": "What is 3E+11 divided by thirty thousand?",
        "writeInAnswer": 10000000,
        "furtherInfo": {
            "explanation": "What is 3E+11 divided by thirty thousand?<br /><br />Rephrase it as: 3E11 / 3E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+07."
        }
    },
    {
        "question": "What is 3E+11 divided by one million?",
        "writeInAnswer": 300000,
        "furtherInfo": {
            "explanation": "What is 3E+11 divided by one million?<br /><br />Rephrase it as: 3E11 / 1E06.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+05."
        }
    },
    {
        "question": "What is 1E+02 divided by one?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 1E+02 divided by one?<br /><br />Rephrase it as: 1E02 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 3E+11 divided by thirty billion?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 3E+11 divided by thirty billion?<br /><br />Rephrase it as: 3E11 / 3E10.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 1E+11 divided by three billion?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 1E+11 divided by three billion?<br /><br />Rephrase it as: 1E11 / 3E09.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+01."
        }
    },
    {
        "question": "What is 1E+08 divided by thirty thousand?",
        "writeInAnswer": 3000,
        "furtherInfo": {
            "explanation": "What is 1E+08 divided by thirty thousand?<br /><br />Rephrase it as: 1E08 / 3E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+03."
        }
    },
    {
        "question": "What is 3E+05 divided by one thousand?",
        "writeInAnswer": 300,
        "furtherInfo": {
            "explanation": "What is 3E+05 divided by one thousand?<br /><br />Rephrase it as: 3E05 / 1E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+02."
        }
    },
    {
        "question": "What is 3E+04 divided by three?",
        "writeInAnswer": 10000,
        "furtherInfo": {
            "explanation": "What is 3E+04 divided by three?<br /><br />Rephrase it as: 3E04 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+04."
        }
    },
    {
        "question": "What is 1E+05 divided by thirty?",
        "writeInAnswer": 3000,
        "furtherInfo": {
            "explanation": "What is 1E+05 divided by thirty?<br /><br />Rephrase it as: 1E05 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+03."
        }
    },
    {
        "question": "What is 1E+08 divided by one hundred thousand?",
        "writeInAnswer": 1000,
        "furtherInfo": {
            "explanation": "What is 1E+08 divided by one hundred thousand?<br /><br />Rephrase it as: 1E08 / 1E05.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
        }
    },
    {
        "question": "What is 3E+08 divided by three?",
        "writeInAnswer": 100000000,
        "furtherInfo": {
            "explanation": "What is 3E+08 divided by three?<br /><br />Rephrase it as: 3E08 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+08."
        }
    },
    {
        "question": "What is 1E+02 divided by one?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 1E+02 divided by one?<br /><br />Rephrase it as: 1E02 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 1E+11 divided by three thousand?",
        "writeInAnswer": 30000000,
        "furtherInfo": {
            "explanation": "What is 1E+11 divided by three thousand?<br /><br />Rephrase it as: 1E11 / 3E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+07."
        }
    },
    {
        "question": "What is 3E+06 divided by one thousand?",
        "writeInAnswer": 3000,
        "furtherInfo": {
            "explanation": "What is 3E+06 divided by one thousand?<br /><br />Rephrase it as: 3E06 / 1E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+03."
        }
    },
    {
        "question": "What is 1E+06 divided by thirty?",
        "writeInAnswer": 30000,
        "furtherInfo": {
            "explanation": "What is 1E+06 divided by thirty?<br /><br />Rephrase it as: 1E06 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+04."
        }
    },
    {
        "question": "What is 1E+09 divided by one?",
        "writeInAnswer": 1000000000,
        "furtherInfo": {
            "explanation": "What is 1E+09 divided by one?<br /><br />Rephrase it as: 1E09 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+09."
        }
    },
    {
        "question": "What is 1E+04 divided by thirty?",
        "writeInAnswer": 300,
        "furtherInfo": {
            "explanation": "What is 1E+04 divided by thirty?<br /><br />Rephrase it as: 1E04 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+02."
        }
    },
    {
        "question": "What is 3E+11 divided by three million?",
        "writeInAnswer": 100000,
        "furtherInfo": {
            "explanation": "What is 3E+11 divided by three million?<br /><br />Rephrase it as: 3E11 / 3E06.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+05."
        }
    },
    {
        "question": "What is 3E+13 divided by thirty million?",
        "writeInAnswer": 1000000,
        "furtherInfo": {
            "explanation": "What is 3E+13 divided by thirty million?<br /><br />Rephrase it as: 3E13 / 3E07.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+06."
        }
    },
    {
        "question": "What is 3E+03 divided by three?",
        "writeInAnswer": 1000,
        "furtherInfo": {
            "explanation": "What is 3E+03 divided by three?<br /><br />Rephrase it as: 3E03 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
        }
    },
    {
        "question": "What is 3E+12 divided by three hundred?",
        "writeInAnswer": 10000000000,
        "furtherInfo": {
            "explanation": "What is 3E+12 divided by three hundred?<br /><br />Rephrase it as: 3E12 / 3E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+10."
        }
    },
    {
        "question": "What is 3E+02 divided by ten?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 3E+02 divided by ten?<br /><br />Rephrase it as: 3E02 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
        }
    },
    {
        "question": "What is 3E+10 divided by thirty million?",
        "writeInAnswer": 1000,
        "furtherInfo": {
            "explanation": "What is 3E+10 divided by thirty million?<br /><br />Rephrase it as: 3E10 / 3E07.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
        }
    },
    {
        "question": "What is 1E+01 divided by one?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 1E+01 divided by one?<br /><br />Rephrase it as: 1E01 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 1E+08 divided by thirty?",
        "writeInAnswer": 3000000,
        "furtherInfo": {
            "explanation": "What is 1E+08 divided by thirty?<br /><br />Rephrase it as: 1E08 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+06."
        }
    },
    {
        "question": "What is 1E+05 divided by three hundred?",
        "writeInAnswer": 300,
        "furtherInfo": {
            "explanation": "What is 1E+05 divided by three hundred?<br /><br />Rephrase it as: 1E05 / 3E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+02."
        }
    },
    {
        "question": "What is 3E+09 divided by three hundred?",
        "writeInAnswer": 10000000,
        "furtherInfo": {
            "explanation": "What is 3E+09 divided by three hundred?<br /><br />Rephrase it as: 3E09 / 3E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+07."
        }
    },
    {
        "question": "What is 1E+09 divided by thirty million?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 1E+09 divided by thirty million?<br /><br />Rephrase it as: 1E09 / 3E07.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+01."
        }
    },
    {
        "question": "What is 1E+01 divided by three?",
        "writeInAnswer": 3,
        "furtherInfo": {
            "explanation": "What is 1E+01 divided by three?<br /><br />Rephrase it as: 1E01 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
        }
    },
    {
        "question": "What is 3E+02 divided by thirty?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 3E+02 divided by thirty?<br /><br />Rephrase it as: 3E02 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 1E+02 divided by three?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 1E+02 divided by three?<br /><br />Rephrase it as: 1E02 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+01."
        }
    },
    {
        "question": "What is 3E+03 divided by three hundred?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 3E+03 divided by three hundred?<br /><br />Rephrase it as: 3E03 / 3E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 3E+05 divided by one hundred?",
        "writeInAnswer": 3000,
        "furtherInfo": {
            "explanation": "What is 3E+05 divided by one hundred?<br /><br />Rephrase it as: 3E05 / 1E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+03."
        }
    },
    {
        "question": "What is 1E+13 divided by one?",
        "writeInAnswer": 10000000000000,
        "furtherInfo": {
            "explanation": "What is 1E+13 divided by one?<br /><br />Rephrase it as: 1E13 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+13."
        }
    },
    {
        "question": "What is 3E+02 divided by ten?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 3E+02 divided by ten?<br /><br />Rephrase it as: 3E02 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
        }
    },
    {
        "question": "What is 1E+02 divided by three?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 1E+02 divided by three?<br /><br />Rephrase it as: 1E02 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+01."
        }
    },
    {
        "question": "What is 3E+04 divided by thirty?",
        "writeInAnswer": 1000,
        "furtherInfo": {
            "explanation": "What is 3E+04 divided by thirty?<br /><br />Rephrase it as: 3E04 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
        }
    },
    {
        "question": "What is 1E+06 divided by thirty thousand?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 1E+06 divided by thirty thousand?<br /><br />Rephrase it as: 1E06 / 3E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+01."
        }
    },
    {
        "question": "What is 1E+08 divided by one million?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 1E+08 divided by one million?<br /><br />Rephrase it as: 1E08 / 1E06.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 3E+02 divided by three?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 3E+02 divided by three?<br /><br />Rephrase it as: 3E02 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 1E+01 divided by one?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 1E+01 divided by one?<br /><br />Rephrase it as: 1E01 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 1E+07 divided by one?",
        "writeInAnswer": 10000000,
        "furtherInfo": {
            "explanation": "What is 1E+07 divided by one?<br /><br />Rephrase it as: 1E07 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+07."
        }
    },
    {
        "question": "What is 1E+13 divided by one million?",
        "writeInAnswer": 10000000,
        "furtherInfo": {
            "explanation": "What is 1E+13 divided by one million?<br /><br />Rephrase it as: 1E13 / 1E06.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+07."
        }
    },
    {
        "question": "What is 1E+10 divided by ten thousand?",
        "writeInAnswer": 1000000,
        "furtherInfo": {
            "explanation": "What is 1E+10 divided by ten thousand?<br /><br />Rephrase it as: 1E10 / 1E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+06."
        }
    },
    {
        "question": "What is 3E+07 divided by three hundred?",
        "writeInAnswer": 100000,
        "furtherInfo": {
            "explanation": "What is 3E+07 divided by three hundred?<br /><br />Rephrase it as: 3E07 / 3E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+05."
        }
    },
    {
        "question": "What is 3E+10 divided by one hundred thousand?",
        "writeInAnswer": 300000,
        "furtherInfo": {
            "explanation": "What is 3E+10 divided by one hundred thousand?<br /><br />Rephrase it as: 3E10 / 1E05.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+05."
        }
    },
    {
        "question": "What is 1E+13 divided by three billion?",
        "writeInAnswer": 3000,
        "furtherInfo": {
            "explanation": "What is 1E+13 divided by three billion?<br /><br />Rephrase it as: 1E13 / 3E09.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+03."
        }
    },
    {
        "question": "What is 1E+07 divided by one hundred thousand?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 1E+07 divided by one hundred thousand?<br /><br />Rephrase it as: 1E07 / 1E05.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 3E+08 divided by one thousand?",
        "writeInAnswer": 300000,
        "furtherInfo": {
            "explanation": "What is 3E+08 divided by one thousand?<br /><br />Rephrase it as: 3E08 / 1E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+05."
        }
    },
    {
        "question": "What is 1E+04 divided by one hundred?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 1E+04 divided by one hundred?<br /><br />Rephrase it as: 1E04 / 1E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 1E+08 divided by three hundred thousand?",
        "writeInAnswer": 300,
        "furtherInfo": {
            "explanation": "What is 1E+08 divided by three hundred thousand?<br /><br />Rephrase it as: 1E08 / 3E05.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+02."
        }
    },
    {
        "question": "What is 3E+12 divided by ten thousand?",
        "writeInAnswer": 300000000,
        "furtherInfo": {
            "explanation": "What is 3E+12 divided by ten thousand?<br /><br />Rephrase it as: 3E12 / 1E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+08."
        }
    },
    {
        "question": "What is 1E+05 divided by one?",
        "writeInAnswer": 100000,
        "furtherInfo": {
            "explanation": "What is 1E+05 divided by one?<br /><br />Rephrase it as: 1E05 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+05."
        }
    },
    {
        "question": "What is 1E+01 divided by three?",
        "writeInAnswer": 3,
        "furtherInfo": {
            "explanation": "What is 1E+01 divided by three?<br /><br />Rephrase it as: 1E01 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
        }
    },
    {
        "question": "What is 1E+06 divided by one hundred thousand?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 1E+06 divided by one hundred thousand?<br /><br />Rephrase it as: 1E06 / 1E05.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 1E+08 divided by one?",
        "writeInAnswer": 100000000,
        "furtherInfo": {
            "explanation": "What is 1E+08 divided by one?<br /><br />Rephrase it as: 1E08 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+08."
        }
    },
    {
        "question": "What is 3E+11 divided by three?",
        "writeInAnswer": 100000000000,
        "furtherInfo": {
            "explanation": "What is 3E+11 divided by three?<br /><br />Rephrase it as: 3E11 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+11."
        }
    },
    {
        "question": "What is 3E+04 divided by one hundred?",
        "writeInAnswer": 300,
        "furtherInfo": {
            "explanation": "What is 3E+04 divided by one hundred?<br /><br />Rephrase it as: 3E04 / 1E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+02."
        }
    },
    {
        "question": "What is 3E+01 divided by three?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 3E+01 divided by three?<br /><br />Rephrase it as: 3E01 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 3E+03 divided by ten?",
        "writeInAnswer": 300,
        "furtherInfo": {
            "explanation": "What is 3E+03 divided by ten?<br /><br />Rephrase it as: 3E03 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+02."
        }
    },
    {
        "question": "What is 3E+01 divided by one?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 3E+01 divided by one?<br /><br />Rephrase it as: 3E01 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
        }
    },
    {
        "question": "What is 3E+01 divided by three?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 3E+01 divided by three?<br /><br />Rephrase it as: 3E01 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 3E+13 divided by one hundred billion?",
        "writeInAnswer": 300,
        "furtherInfo": {
            "explanation": "What is 3E+13 divided by one hundred billion?<br /><br />Rephrase it as: 3E13 / 1E11.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+02."
        }
    },
    {
        "question": "What is 1E+11 divided by ten?",
        "writeInAnswer": 10000000000,
        "furtherInfo": {
            "explanation": "What is 1E+11 divided by ten?<br /><br />Rephrase it as: 1E11 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+10."
        }
    },
    {
        "question": "What is 3E+11 divided by ten million?",
        "writeInAnswer": 30000,
        "furtherInfo": {
            "explanation": "What is 3E+11 divided by ten million?<br /><br />Rephrase it as: 3E11 / 1E07.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+04."
        }
    },
    {
        "question": "What is 1E+06 divided by three?",
        "writeInAnswer": 300000,
        "furtherInfo": {
            "explanation": "What is 1E+06 divided by three?<br /><br />Rephrase it as: 1E06 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+05."
        }
    },
    {
        "question": "What is 1E+10 divided by thirty thousand?",
        "writeInAnswer": 300000,
        "furtherInfo": {
            "explanation": "What is 1E+10 divided by thirty thousand?<br /><br />Rephrase it as: 1E10 / 3E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+05."
        }
    },
    {
        "question": "What is 3E+02 divided by thirty?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 3E+02 divided by thirty?<br /><br />Rephrase it as: 3E02 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 1E+02 divided by one?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 1E+02 divided by one?<br /><br />Rephrase it as: 1E02 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 1E+10 divided by three?",
        "writeInAnswer": 3000000000,
        "furtherInfo": {
            "explanation": "What is 1E+10 divided by three?<br /><br />Rephrase it as: 1E10 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+09."
        }
    },
    {
        "question": "What is 1E+02 divided by one?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 1E+02 divided by one?<br /><br />Rephrase it as: 1E02 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 1E+06 divided by three?",
        "writeInAnswer": 300000,
        "furtherInfo": {
            "explanation": "What is 1E+06 divided by three?<br /><br />Rephrase it as: 1E06 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+05."
        }
    },
    {
        "question": "What is 1E+01 divided by one?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 1E+01 divided by one?<br /><br />Rephrase it as: 1E01 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 1E+03 divided by one?",
        "writeInAnswer": 1000,
        "furtherInfo": {
            "explanation": "What is 1E+03 divided by one?<br /><br />Rephrase it as: 1E03 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
        }
    },
    {
        "question": "What is 1E+02 divided by three?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 1E+02 divided by three?<br /><br />Rephrase it as: 1E02 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+01."
        }
    },
    {
        "question": "What is 3E+04 divided by three thousand?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 3E+04 divided by three thousand?<br /><br />Rephrase it as: 3E04 / 3E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 3E+03 divided by one?",
        "writeInAnswer": 3000,
        "furtherInfo": {
            "explanation": "What is 3E+03 divided by one?<br /><br />Rephrase it as: 3E03 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+03."
        }
    },
    {
        "question": "What is 1E+08 divided by three?",
        "writeInAnswer": 30000000,
        "furtherInfo": {
            "explanation": "What is 1E+08 divided by three?<br /><br />Rephrase it as: 1E08 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+07."
        }
    },
    {
        "question": "What is 3E+08 divided by thirty million?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 3E+08 divided by thirty million?<br /><br />Rephrase it as: 3E08 / 3E07.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 3E+07 divided by three hundred?",
        "writeInAnswer": 100000,
        "furtherInfo": {
            "explanation": "What is 3E+07 divided by three hundred?<br /><br />Rephrase it as: 3E07 / 3E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+05."
        }
    },
    {
        "question": "What is 3E+02 divided by three?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 3E+02 divided by three?<br /><br />Rephrase it as: 3E02 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 1E+08 divided by one million?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 1E+08 divided by one million?<br /><br />Rephrase it as: 1E08 / 1E06.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 1E+03 divided by one hundred?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 1E+03 divided by one hundred?<br /><br />Rephrase it as: 1E03 / 1E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 1E+12 divided by three hundred?",
        "writeInAnswer": 3000000000,
        "furtherInfo": {
            "explanation": "What is 1E+12 divided by three hundred?<br /><br />Rephrase it as: 1E12 / 3E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+09."
        }
    },
    {
        "question": "What is 1E+11 divided by one hundred?",
        "writeInAnswer": 1000000000,
        "furtherInfo": {
            "explanation": "What is 1E+11 divided by one hundred?<br /><br />Rephrase it as: 1E11 / 1E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+09."
        }
    },
    {
        "question": "What is 1E+06 divided by thirty?",
        "writeInAnswer": 30000,
        "furtherInfo": {
            "explanation": "What is 1E+06 divided by thirty?<br /><br />Rephrase it as: 1E06 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+04."
        }
    },
    {
        "question": "What is 3E+12 divided by ten billion?",
        "writeInAnswer": 300,
        "furtherInfo": {
            "explanation": "What is 3E+12 divided by ten billion?<br /><br />Rephrase it as: 3E12 / 1E10.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+02."
        }
    },
    {
        "question": "What is 1E+06 divided by one hundred thousand?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 1E+06 divided by one hundred thousand?<br /><br />Rephrase it as: 1E06 / 1E05.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 3E+11 divided by three billion?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 3E+11 divided by three billion?<br /><br />Rephrase it as: 3E11 / 3E09.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 3E+11 divided by three hundred million?",
        "writeInAnswer": 1000,
        "furtherInfo": {
            "explanation": "What is 3E+11 divided by three hundred million?<br /><br />Rephrase it as: 3E11 / 3E08.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
        }
    },
    {
        "question": "What is 1E+13 divided by thirty million?",
        "writeInAnswer": 300000,
        "furtherInfo": {
            "explanation": "What is 1E+13 divided by thirty million?<br /><br />Rephrase it as: 1E13 / 3E07.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+05."
        }
    },
    {
        "question": "What is 3E+06 divided by one thousand?",
        "writeInAnswer": 3000,
        "furtherInfo": {
            "explanation": "What is 3E+06 divided by one thousand?<br /><br />Rephrase it as: 3E06 / 1E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+03."
        }
    },
    {
        "question": "What is 1E+05 divided by one thousand?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 1E+05 divided by one thousand?<br /><br />Rephrase it as: 1E05 / 1E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 1E+01 divided by three?",
        "writeInAnswer": 3,
        "furtherInfo": {
            "explanation": "What is 1E+01 divided by three?<br /><br />Rephrase it as: 1E01 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
        }
    },
    {
        "question": "What is 3E+09 divided by three?",
        "writeInAnswer": 1000000000,
        "furtherInfo": {
            "explanation": "What is 3E+09 divided by three?<br /><br />Rephrase it as: 3E09 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+09."
        }
    },
    {
        "question": "What is 3E+13 divided by three hundred thousand?",
        "writeInAnswer": 100000000,
        "furtherInfo": {
            "explanation": "What is 3E+13 divided by three hundred thousand?<br /><br />Rephrase it as: 3E13 / 3E05.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+08."
        }
    },
    {
        "question": "What is 1E+06 divided by one thousand?",
        "writeInAnswer": 1000,
        "furtherInfo": {
            "explanation": "What is 1E+06 divided by one thousand?<br /><br />Rephrase it as: 1E06 / 1E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
        }
    },
    {
        "question": "What is 1E+12 divided by thirty million?",
        "writeInAnswer": 30000,
        "furtherInfo": {
            "explanation": "What is 1E+12 divided by thirty million?<br /><br />Rephrase it as: 1E12 / 3E07.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+04."
        }
    },
    {
        "question": "What is 1E+06 divided by one thousand?",
        "writeInAnswer": 1000,
        "furtherInfo": {
            "explanation": "What is 1E+06 divided by one thousand?<br /><br />Rephrase it as: 1E06 / 1E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
        }
    },
    {
        "question": "What is 1E+03 divided by ten?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 1E+03 divided by ten?<br /><br />Rephrase it as: 1E03 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 1E+05 divided by ten?",
        "writeInAnswer": 10000,
        "furtherInfo": {
            "explanation": "What is 1E+05 divided by ten?<br /><br />Rephrase it as: 1E05 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+04."
        }
    },
    {
        "question": "What is 3E+13 divided by thirty?",
        "writeInAnswer": 1000000000000,
        "furtherInfo": {
            "explanation": "What is 3E+13 divided by thirty?<br /><br />Rephrase it as: 3E13 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+12."
        }
    },
    {
        "question": "What is 1E+09 divided by one?",
        "writeInAnswer": 1000000000,
        "furtherInfo": {
            "explanation": "What is 1E+09 divided by one?<br /><br />Rephrase it as: 1E09 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+09."
        }
    },
    {
        "question": "What is 1E+03 divided by one hundred?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 1E+03 divided by one hundred?<br /><br />Rephrase it as: 1E03 / 1E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 3E+01 divided by one?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 3E+01 divided by one?<br /><br />Rephrase it as: 3E01 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
        }
    },
    {
        "question": "What is 3E+13 divided by ten billion?",
        "writeInAnswer": 3000,
        "furtherInfo": {
            "explanation": "What is 3E+13 divided by ten billion?<br /><br />Rephrase it as: 3E13 / 1E10.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+03."
        }
    },
    {
        "question": "What is 3E+05 divided by three?",
        "writeInAnswer": 100000,
        "furtherInfo": {
            "explanation": "What is 3E+05 divided by three?<br /><br />Rephrase it as: 3E05 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+05."
        }
    },
    {
        "question": "What is 3E+10 divided by thirty thousand?",
        "writeInAnswer": 1000000,
        "furtherInfo": {
            "explanation": "What is 3E+10 divided by thirty thousand?<br /><br />Rephrase it as: 3E10 / 3E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+06."
        }
    },
    {
        "question": "What is 3E+02 divided by ten?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 3E+02 divided by ten?<br /><br />Rephrase it as: 3E02 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
        }
    },
    {
        "question": "What is 3E+04 divided by ten?",
        "writeInAnswer": 3000,
        "furtherInfo": {
            "explanation": "What is 3E+04 divided by ten?<br /><br />Rephrase it as: 3E04 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+03."
        }
    },
    {
        "question": "What is 3E+07 divided by one hundred thousand?",
        "writeInAnswer": 300,
        "furtherInfo": {
            "explanation": "What is 3E+07 divided by one hundred thousand?<br /><br />Rephrase it as: 3E07 / 1E05.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+02."
        }
    },
    {
        "question": "What is 3E+10 divided by three hundred thousand?",
        "writeInAnswer": 100000,
        "furtherInfo": {
            "explanation": "What is 3E+10 divided by three hundred thousand?<br /><br />Rephrase it as: 3E10 / 3E05.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+05."
        }
    },
    {
        "question": "What is 3E+05 divided by thirty?",
        "writeInAnswer": 10000,
        "furtherInfo": {
            "explanation": "What is 3E+05 divided by thirty?<br /><br />Rephrase it as: 3E05 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+04."
        }
    },
    {
        "question": "What is 3E+09 divided by three hundred?",
        "writeInAnswer": 10000000,
        "furtherInfo": {
            "explanation": "What is 3E+09 divided by three hundred?<br /><br />Rephrase it as: 3E09 / 3E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+07."
        }
    },
    {
        "question": "What is 1E+12 divided by three hundred million?",
        "writeInAnswer": 3000,
        "furtherInfo": {
            "explanation": "What is 1E+12 divided by three hundred million?<br /><br />Rephrase it as: 1E12 / 3E08.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+03."
        }
    },
    {
        "question": "What is 1E+02 divided by three?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 1E+02 divided by three?<br /><br />Rephrase it as: 1E02 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+01."
        }
    },
    {
        "question": "What is 1E+05 divided by thirty thousand?",
        "writeInAnswer": 3,
        "furtherInfo": {
            "explanation": "What is 1E+05 divided by thirty thousand?<br /><br />Rephrase it as: 1E05 / 3E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
        }
    },
    {
        "question": "What is 1E+07 divided by one thousand?",
        "writeInAnswer": 10000,
        "furtherInfo": {
            "explanation": "What is 1E+07 divided by one thousand?<br /><br />Rephrase it as: 1E07 / 1E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+04."
        }
    },
    {
        "question": "What is 1E+11 divided by one thousand?",
        "writeInAnswer": 100000000,
        "furtherInfo": {
            "explanation": "What is 1E+11 divided by one thousand?<br /><br />Rephrase it as: 1E11 / 1E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+08."
        }
    },
    {
        "question": "What is 3E+07 divided by thirty thousand?",
        "writeInAnswer": 1000,
        "furtherInfo": {
            "explanation": "What is 3E+07 divided by thirty thousand?<br /><br />Rephrase it as: 3E07 / 3E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
        }
    },
    {
        "question": "What is 3E+10 divided by three hundred million?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 3E+10 divided by three hundred million?<br /><br />Rephrase it as: 3E10 / 3E08.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 3E+13 divided by one trillion?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 3E+13 divided by one trillion?<br /><br />Rephrase it as: 3E13 / 1E12.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
        }
    },
    {
        "question": "What is 3E+01 divided by one?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 3E+01 divided by one?<br /><br />Rephrase it as: 3E01 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
        }
    },
    {
        "question": "What is 3E+06 divided by one?",
        "writeInAnswer": 3000000,
        "furtherInfo": {
            "explanation": "What is 3E+06 divided by one?<br /><br />Rephrase it as: 3E06 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+06."
        }
    },
    {
        "question": "What is 1E+09 divided by three thousand?",
        "writeInAnswer": 300000,
        "furtherInfo": {
            "explanation": "What is 1E+09 divided by three thousand?<br /><br />Rephrase it as: 1E09 / 3E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+05."
        }
    },
    {
        "question": "What is 1E+07 divided by ten?",
        "writeInAnswer": 1000000,
        "furtherInfo": {
            "explanation": "What is 1E+07 divided by ten?<br /><br />Rephrase it as: 1E07 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+06."
        }
    },
    {
        "question": "What is 3E+06 divided by three thousand?",
        "writeInAnswer": 1000,
        "furtherInfo": {
            "explanation": "What is 3E+06 divided by three thousand?<br /><br />Rephrase it as: 3E06 / 3E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
        }
    },
    {
        "question": "What is 1E+01 divided by one?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 1E+01 divided by one?<br /><br />Rephrase it as: 1E01 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 1E+05 divided by thirty?",
        "writeInAnswer": 3000,
        "furtherInfo": {
            "explanation": "What is 1E+05 divided by thirty?<br /><br />Rephrase it as: 1E05 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+03."
        }
    },
    {
        "question": "What is 3E+08 divided by one?",
        "writeInAnswer": 300000000,
        "furtherInfo": {
            "explanation": "What is 3E+08 divided by one?<br /><br />Rephrase it as: 3E08 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+08."
        }
    },
    {
        "question": "What is 3E+02 divided by thirty?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 3E+02 divided by thirty?<br /><br />Rephrase it as: 3E02 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 1E+10 divided by three million?",
        "writeInAnswer": 3000,
        "furtherInfo": {
            "explanation": "What is 1E+10 divided by three million?<br /><br />Rephrase it as: 1E10 / 3E06.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+03."
        }
    },
    {
        "question": "What is 1E+05 divided by three?",
        "writeInAnswer": 30000,
        "furtherInfo": {
            "explanation": "What is 1E+05 divided by three?<br /><br />Rephrase it as: 1E05 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+04."
        }
    },
    {
        "question": "What is 3E+10 divided by thirty thousand?",
        "writeInAnswer": 1000000,
        "furtherInfo": {
            "explanation": "What is 3E+10 divided by thirty thousand?<br /><br />Rephrase it as: 3E10 / 3E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+06."
        }
    },
    {
        "question": "What is 3E+08 divided by thirty million?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 3E+08 divided by thirty million?<br /><br />Rephrase it as: 3E08 / 3E07.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 1E+02 divided by ten?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 1E+02 divided by ten?<br /><br />Rephrase it as: 1E02 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 1E+11 divided by ten billion?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 1E+11 divided by ten billion?<br /><br />Rephrase it as: 1E11 / 1E10.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 3E+05 divided by three hundred?",
        "writeInAnswer": 1000,
        "furtherInfo": {
            "explanation": "What is 3E+05 divided by three hundred?<br /><br />Rephrase it as: 3E05 / 3E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
        }
    },
    {
        "question": "What is 1E+12 divided by thirty thousand?",
        "writeInAnswer": 30000000,
        "furtherInfo": {
            "explanation": "What is 1E+12 divided by thirty thousand?<br /><br />Rephrase it as: 1E12 / 3E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+07."
        }
    },
    {
        "question": "What is 1E+12 divided by three hundred million?",
        "writeInAnswer": 3000,
        "furtherInfo": {
            "explanation": "What is 1E+12 divided by three hundred million?<br /><br />Rephrase it as: 1E12 / 3E08.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+03."
        }
    },
    {
        "question": "What is 3E+13 divided by one hundred?",
        "writeInAnswer": 300000000000,
        "furtherInfo": {
            "explanation": "What is 3E+13 divided by one hundred?<br /><br />Rephrase it as: 3E13 / 1E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+11."
        }
    },
    {
        "question": "What is 3E+06 divided by ten thousand?",
        "writeInAnswer": 300,
        "furtherInfo": {
            "explanation": "What is 3E+06 divided by ten thousand?<br /><br />Rephrase it as: 3E06 / 1E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+02."
        }
    },
    {
        "question": "What is 1E+01 divided by one?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 1E+01 divided by one?<br /><br />Rephrase it as: 1E01 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 3E+05 divided by thirty?",
        "writeInAnswer": 10000,
        "furtherInfo": {
            "explanation": "What is 3E+05 divided by thirty?<br /><br />Rephrase it as: 3E05 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+04."
        }
    },
    {
        "question": "What is 3E+13 divided by one hundred billion?",
        "writeInAnswer": 300,
        "furtherInfo": {
            "explanation": "What is 3E+13 divided by one hundred billion?<br /><br />Rephrase it as: 3E13 / 1E11.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+02."
        }
    },
    {
        "question": "What is 3E+08 divided by three?",
        "writeInAnswer": 100000000,
        "furtherInfo": {
            "explanation": "What is 3E+08 divided by three?<br /><br />Rephrase it as: 3E08 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+08."
        }
    },
    {
        "question": "What is 1E+10 divided by one hundred million?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 1E+10 divided by one hundred million?<br /><br />Rephrase it as: 1E10 / 1E08.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 1E+04 divided by three thousand?",
        "writeInAnswer": 3,
        "furtherInfo": {
            "explanation": "What is 1E+04 divided by three thousand?<br /><br />Rephrase it as: 1E04 / 3E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
        }
    },
    {
        "question": "What is 1E+11 divided by three hundred million?",
        "writeInAnswer": 300,
        "furtherInfo": {
            "explanation": "What is 1E+11 divided by three hundred million?<br /><br />Rephrase it as: 1E11 / 3E08.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+02."
        }
    },
    {
        "question": "What is 3E+06 divided by three thousand?",
        "writeInAnswer": 1000,
        "furtherInfo": {
            "explanation": "What is 3E+06 divided by three thousand?<br /><br />Rephrase it as: 3E06 / 3E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
        }
    },
    {
        "question": "What is 3E+11 divided by three hundred million?",
        "writeInAnswer": 1000,
        "furtherInfo": {
            "explanation": "What is 3E+11 divided by three hundred million?<br /><br />Rephrase it as: 3E11 / 3E08.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
        }
    },
    {
        "question": "What is 3E+01 divided by one?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 3E+01 divided by one?<br /><br />Rephrase it as: 3E01 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
        }
    },
    {
        "question": "What is 1E+07 divided by thirty thousand?",
        "writeInAnswer": 300,
        "furtherInfo": {
            "explanation": "What is 1E+07 divided by thirty thousand?<br /><br />Rephrase it as: 1E07 / 3E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+02."
        }
    },
    {
        "question": "What is 1E+08 divided by ten?",
        "writeInAnswer": 10000000,
        "furtherInfo": {
            "explanation": "What is 1E+08 divided by ten?<br /><br />Rephrase it as: 1E08 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+07."
        }
    },
    {
        "question": "What is 3E+10 divided by thirty?",
        "writeInAnswer": 1000000000,
        "furtherInfo": {
            "explanation": "What is 3E+10 divided by thirty?<br /><br />Rephrase it as: 3E10 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+09."
        }
    },
    {
        "question": "What is 3E+08 divided by ten million?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 3E+08 divided by ten million?<br /><br />Rephrase it as: 3E08 / 1E07.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
        }
    },
    {
        "question": "What is 3E+11 divided by ten thousand?",
        "writeInAnswer": 30000000,
        "furtherInfo": {
            "explanation": "What is 3E+11 divided by ten thousand?<br /><br />Rephrase it as: 3E11 / 1E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+07."
        }
    },
    {
        "question": "What is 1E+05 divided by ten?",
        "writeInAnswer": 10000,
        "furtherInfo": {
            "explanation": "What is 1E+05 divided by ten?<br /><br />Rephrase it as: 1E05 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+04."
        }
    },
    {
        "question": "What is 1E+13 divided by three billion?",
        "writeInAnswer": 3000,
        "furtherInfo": {
            "explanation": "What is 1E+13 divided by three billion?<br /><br />Rephrase it as: 1E13 / 3E09.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+03."
        }
    },
    {
        "question": "What is 1E+13 divided by one million?",
        "writeInAnswer": 10000000,
        "furtherInfo": {
            "explanation": "What is 1E+13 divided by one million?<br /><br />Rephrase it as: 1E13 / 1E06.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+07."
        }
    },
    {
        "question": "What is 1E+08 divided by three thousand?",
        "writeInAnswer": 30000,
        "furtherInfo": {
            "explanation": "What is 1E+08 divided by three thousand?<br /><br />Rephrase it as: 1E08 / 3E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+04."
        }
    },
    {
        "question": "What is 3E+04 divided by three hundred?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 3E+04 divided by three hundred?<br /><br />Rephrase it as: 3E04 / 3E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 3E+09 divided by ten thousand?",
        "writeInAnswer": 300000,
        "furtherInfo": {
            "explanation": "What is 3E+09 divided by ten thousand?<br /><br />Rephrase it as: 3E09 / 1E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+05."
        }
    },
    {
        "question": "What is 1E+06 divided by three hundred thousand?",
        "writeInAnswer": 3,
        "furtherInfo": {
            "explanation": "What is 1E+06 divided by three hundred thousand?<br /><br />Rephrase it as: 1E06 / 3E05.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
        }
    },
    {
        "question": "What is 3E+12 divided by one hundred thousand?",
        "writeInAnswer": 30000000,
        "furtherInfo": {
            "explanation": "What is 3E+12 divided by one hundred thousand?<br /><br />Rephrase it as: 3E12 / 1E05.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+07."
        }
    },
    {
        "question": "What is 3E+04 divided by three?",
        "writeInAnswer": 10000,
        "furtherInfo": {
            "explanation": "What is 3E+04 divided by three?<br /><br />Rephrase it as: 3E04 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+04."
        }
    },
    {
        "question": "What is 1E+09 divided by thirty?",
        "writeInAnswer": 30000000,
        "furtherInfo": {
            "explanation": "What is 1E+09 divided by thirty?<br /><br />Rephrase it as: 1E09 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+07."
        }
    },
    {
        "question": "What is 1E+08 divided by ten million?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 1E+08 divided by ten million?<br /><br />Rephrase it as: 1E08 / 1E07.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 3E+10 divided by three?",
        "writeInAnswer": 10000000000,
        "furtherInfo": {
            "explanation": "What is 3E+10 divided by three?<br /><br />Rephrase it as: 3E10 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+10."
        }
    },
    {
        "question": "What is 1E+05 divided by one thousand?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 1E+05 divided by one thousand?<br /><br />Rephrase it as: 1E05 / 1E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 3E+01 divided by three?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 3E+01 divided by three?<br /><br />Rephrase it as: 3E01 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 1E+02 divided by one?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 1E+02 divided by one?<br /><br />Rephrase it as: 1E02 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 1E+11 divided by one billion?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 1E+11 divided by one billion?<br /><br />Rephrase it as: 1E11 / 1E09.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 1E+12 divided by one hundred?",
        "writeInAnswer": 10000000000,
        "furtherInfo": {
            "explanation": "What is 1E+12 divided by one hundred?<br /><br />Rephrase it as: 1E12 / 1E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+10."
        }
    },
    {
        "question": "What is 3E+09 divided by three?",
        "writeInAnswer": 1000000000,
        "furtherInfo": {
            "explanation": "What is 3E+09 divided by three?<br /><br />Rephrase it as: 3E09 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+09."
        }
    },
    {
        "question": "What is 3E+04 divided by ten?",
        "writeInAnswer": 3000,
        "furtherInfo": {
            "explanation": "What is 3E+04 divided by ten?<br /><br />Rephrase it as: 3E04 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+03."
        }
    },
    {
        "question": "What is 3E+09 divided by ten thousand?",
        "writeInAnswer": 300000,
        "furtherInfo": {
            "explanation": "What is 3E+09 divided by ten thousand?<br /><br />Rephrase it as: 3E09 / 1E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+05."
        }
    },
    {
        "question": "What is 1E+13 divided by one hundred thousand?",
        "writeInAnswer": 100000000,
        "furtherInfo": {
            "explanation": "What is 1E+13 divided by one hundred thousand?<br /><br />Rephrase it as: 1E13 / 1E05.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+08."
        }
    },
    {
        "question": "What is 3E+10 divided by ten?",
        "writeInAnswer": 3000000000,
        "furtherInfo": {
            "explanation": "What is 3E+10 divided by ten?<br /><br />Rephrase it as: 3E10 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+09."
        }
    },
    {
        "question": "What is 1E+04 divided by one hundred?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 1E+04 divided by one hundred?<br /><br />Rephrase it as: 1E04 / 1E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 3E+06 divided by three hundred thousand?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 3E+06 divided by three hundred thousand?<br /><br />Rephrase it as: 3E06 / 3E05.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 1E+02 divided by thirty?",
        "writeInAnswer": 3,
        "furtherInfo": {
            "explanation": "What is 1E+02 divided by thirty?<br /><br />Rephrase it as: 1E02 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
        }
    },
    {
        "question": "What is 3E+03 divided by one?",
        "writeInAnswer": 3000,
        "furtherInfo": {
            "explanation": "What is 3E+03 divided by one?<br /><br />Rephrase it as: 3E03 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+03."
        }
    },
    {
        "question": "What is 1E+05 divided by ten?",
        "writeInAnswer": 10000,
        "furtherInfo": {
            "explanation": "What is 1E+05 divided by ten?<br /><br />Rephrase it as: 1E05 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+04."
        }
    },
    {
        "question": "What is 1E+02 divided by one?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 1E+02 divided by one?<br /><br />Rephrase it as: 1E02 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 3E+06 divided by three thousand?",
        "writeInAnswer": 1000,
        "furtherInfo": {
            "explanation": "What is 3E+06 divided by three thousand?<br /><br />Rephrase it as: 3E06 / 3E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
        }
    },
    {
        "question": "What is 3E+11 divided by thirty billion?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 3E+11 divided by thirty billion?<br /><br />Rephrase it as: 3E11 / 3E10.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 1E+13 divided by three?",
        "writeInAnswer": 3000000000000,
        "furtherInfo": {
            "explanation": "What is 1E+13 divided by three?<br /><br />Rephrase it as: 1E13 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+12."
        }
    },
    {
        "question": "What is 1E+03 divided by three hundred?",
        "writeInAnswer": 3,
        "furtherInfo": {
            "explanation": "What is 1E+03 divided by three hundred?<br /><br />Rephrase it as: 1E03 / 3E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
        }
    },
    {
        "question": "What is 1E+02 divided by three?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 1E+02 divided by three?<br /><br />Rephrase it as: 1E02 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+01."
        }
    },
    {
        "question": "What is 3E+08 divided by three thousand?",
        "writeInAnswer": 100000,
        "furtherInfo": {
            "explanation": "What is 3E+08 divided by three thousand?<br /><br />Rephrase it as: 3E08 / 3E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+05."
        }
    },
    {
        "question": "What is 1E+02 divided by three?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 1E+02 divided by three?<br /><br />Rephrase it as: 1E02 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+01."
        }
    },
    {
        "question": "What is 1E+08 divided by thirty million?",
        "writeInAnswer": 3,
        "furtherInfo": {
            "explanation": "What is 1E+08 divided by thirty million?<br /><br />Rephrase it as: 1E08 / 3E07.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
        }
    },
    {
        "question": "What is 1E+03 divided by thirty?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 1E+03 divided by thirty?<br /><br />Rephrase it as: 1E03 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+01."
        }
    },
    {
        "question": "What is 3E+01 divided by three?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 3E+01 divided by three?<br /><br />Rephrase it as: 3E01 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 3E+13 divided by one hundred million?",
        "writeInAnswer": 300000,
        "furtherInfo": {
            "explanation": "What is 3E+13 divided by one hundred million?<br /><br />Rephrase it as: 3E13 / 1E08.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+05."
        }
    },
    {
        "question": "What is 1E+02 divided by thirty?",
        "writeInAnswer": 3,
        "furtherInfo": {
            "explanation": "What is 1E+02 divided by thirty?<br /><br />Rephrase it as: 1E02 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
        }
    },
    {
        "question": "What is 1E+04 divided by three hundred?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 1E+04 divided by three hundred?<br /><br />Rephrase it as: 1E04 / 3E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+01."
        }
    },
    {
        "question": "What is 1E+12 divided by three?",
        "writeInAnswer": 300000000000,
        "furtherInfo": {
            "explanation": "What is 1E+12 divided by three?<br /><br />Rephrase it as: 1E12 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+11."
        }
    },
    {
        "question": "What is 1E+05 divided by thirty?",
        "writeInAnswer": 3000,
        "furtherInfo": {
            "explanation": "What is 1E+05 divided by thirty?<br /><br />Rephrase it as: 1E05 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+03."
        }
    },
    {
        "question": "What is 1E+03 divided by three hundred?",
        "writeInAnswer": 3,
        "furtherInfo": {
            "explanation": "What is 1E+03 divided by three hundred?<br /><br />Rephrase it as: 1E03 / 3E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
        }
    },
    {
        "question": "What is 1E+06 divided by three hundred thousand?",
        "writeInAnswer": 3,
        "furtherInfo": {
            "explanation": "What is 1E+06 divided by three hundred thousand?<br /><br />Rephrase it as: 1E06 / 3E05.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
        }
    },
    {
        "question": "What is 3E+08 divided by three hundred?",
        "writeInAnswer": 1000000,
        "furtherInfo": {
            "explanation": "What is 3E+08 divided by three hundred?<br /><br />Rephrase it as: 3E08 / 3E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+06."
        }
    },
    {
        "question": "What is 1E+10 divided by one?",
        "writeInAnswer": 10000000000,
        "furtherInfo": {
            "explanation": "What is 1E+10 divided by one?<br /><br />Rephrase it as: 1E10 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+10."
        }
    },
    {
        "question": "What is 1E+11 divided by three thousand?",
        "writeInAnswer": 30000000,
        "furtherInfo": {
            "explanation": "What is 1E+11 divided by three thousand?<br /><br />Rephrase it as: 1E11 / 3E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+07."
        }
    },
    {
        "question": "What is 3E+06 divided by three hundred?",
        "writeInAnswer": 10000,
        "furtherInfo": {
            "explanation": "What is 3E+06 divided by three hundred?<br /><br />Rephrase it as: 3E06 / 3E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+04."
        }
    },
    {
        "question": "What is 1E+01 divided by one?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 1E+01 divided by one?<br /><br />Rephrase it as: 1E01 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 1E+11 divided by ten billion?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 1E+11 divided by ten billion?<br /><br />Rephrase it as: 1E11 / 1E10.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 3E+01 divided by three?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 3E+01 divided by three?<br /><br />Rephrase it as: 3E01 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 3E+07 divided by one hundred thousand?",
        "writeInAnswer": 300,
        "furtherInfo": {
            "explanation": "What is 3E+07 divided by one hundred thousand?<br /><br />Rephrase it as: 3E07 / 1E05.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+02."
        }
    },
    {
        "question": "What is 3E+08 divided by ten million?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 3E+08 divided by ten million?<br /><br />Rephrase it as: 3E08 / 1E07.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
        }
    },
    {
        "question": "What is 3E+02 divided by thirty?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 3E+02 divided by thirty?<br /><br />Rephrase it as: 3E02 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 3E+07 divided by one million?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 3E+07 divided by one million?<br /><br />Rephrase it as: 3E07 / 1E06.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
        }
    },
    {
        "question": "What is 1E+07 divided by ten thousand?",
        "writeInAnswer": 1000,
        "furtherInfo": {
            "explanation": "What is 1E+07 divided by ten thousand?<br /><br />Rephrase it as: 1E07 / 1E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
        }
    },
    {
        "question": "What is 3E+09 divided by one?",
        "writeInAnswer": 3000000000,
        "furtherInfo": {
            "explanation": "What is 3E+09 divided by one?<br /><br />Rephrase it as: 3E09 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+09."
        }
    },
    {
        "question": "What is 3E+09 divided by thirty thousand?",
        "writeInAnswer": 100000,
        "furtherInfo": {
            "explanation": "What is 3E+09 divided by thirty thousand?<br /><br />Rephrase it as: 3E09 / 3E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+05."
        }
    },
    {
        "question": "What is 3E+12 divided by ten million?",
        "writeInAnswer": 300000,
        "furtherInfo": {
            "explanation": "What is 3E+12 divided by ten million?<br /><br />Rephrase it as: 3E12 / 1E07.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+05."
        }
    },
    {
        "question": "What is 3E+05 divided by one thousand?",
        "writeInAnswer": 300,
        "furtherInfo": {
            "explanation": "What is 3E+05 divided by one thousand?<br /><br />Rephrase it as: 3E05 / 1E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+02."
        }
    },
    {
        "question": "What is 1E+13 divided by three hundred million?",
        "writeInAnswer": 30000,
        "furtherInfo": {
            "explanation": "What is 1E+13 divided by three hundred million?<br /><br />Rephrase it as: 1E13 / 3E08.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+04."
        }
    },
    {
        "question": "What is 3E+07 divided by three hundred thousand?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 3E+07 divided by three hundred thousand?<br /><br />Rephrase it as: 3E07 / 3E05.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 3E+07 divided by thirty?",
        "writeInAnswer": 1000000,
        "furtherInfo": {
            "explanation": "What is 3E+07 divided by thirty?<br /><br />Rephrase it as: 3E07 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+06."
        }
    },
    {
        "question": "What is 3E+05 divided by one?",
        "writeInAnswer": 300000,
        "furtherInfo": {
            "explanation": "What is 3E+05 divided by one?<br /><br />Rephrase it as: 3E05 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+05."
        }
    },
    {
        "question": "What is 1E+11 divided by one thousand?",
        "writeInAnswer": 100000000,
        "furtherInfo": {
            "explanation": "What is 1E+11 divided by one thousand?<br /><br />Rephrase it as: 1E11 / 1E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+08."
        }
    },
    {
        "question": "What is 3E+05 divided by ten thousand?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 3E+05 divided by ten thousand?<br /><br />Rephrase it as: 3E05 / 1E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
        }
    },
    {
        "question": "What is 3E+10 divided by one billion?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 3E+10 divided by one billion?<br /><br />Rephrase it as: 3E10 / 1E09.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
        }
    },
    {
        "question": "What is 1E+13 divided by ten thousand?",
        "writeInAnswer": 1000000000,
        "furtherInfo": {
            "explanation": "What is 1E+13 divided by ten thousand?<br /><br />Rephrase it as: 1E13 / 1E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+09."
        }
    },
    {
        "question": "What is 1E+11 divided by three hundred thousand?",
        "writeInAnswer": 300000,
        "furtherInfo": {
            "explanation": "What is 1E+11 divided by three hundred thousand?<br /><br />Rephrase it as: 1E11 / 3E05.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+05."
        }
    },
    {
        "question": "What is 1E+01 divided by one?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 1E+01 divided by one?<br /><br />Rephrase it as: 1E01 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 1E+08 divided by ten thousand?",
        "writeInAnswer": 10000,
        "furtherInfo": {
            "explanation": "What is 1E+08 divided by ten thousand?<br /><br />Rephrase it as: 1E08 / 1E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+04."
        }
    },
    {
        "question": "What is 1E+06 divided by three thousand?",
        "writeInAnswer": 300,
        "furtherInfo": {
            "explanation": "What is 1E+06 divided by three thousand?<br /><br />Rephrase it as: 1E06 / 3E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+02."
        }
    },
    {
        "question": "What is 3E+12 divided by one thousand?",
        "writeInAnswer": 3000000000,
        "furtherInfo": {
            "explanation": "What is 3E+12 divided by one thousand?<br /><br />Rephrase it as: 3E12 / 1E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+09."
        }
    },
    {
        "question": "What is 3E+09 divided by one hundred thousand?",
        "writeInAnswer": 30000,
        "furtherInfo": {
            "explanation": "What is 3E+09 divided by one hundred thousand?<br /><br />Rephrase it as: 3E09 / 1E05.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+04."
        }
    },
    {
        "question": "What is 1E+12 divided by one billion?",
        "writeInAnswer": 1000,
        "furtherInfo": {
            "explanation": "What is 1E+12 divided by one billion?<br /><br />Rephrase it as: 1E12 / 1E09.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
        }
    },
    {
        "question": "What is 1E+10 divided by three hundred thousand?",
        "writeInAnswer": 30000,
        "furtherInfo": {
            "explanation": "What is 1E+10 divided by three hundred thousand?<br /><br />Rephrase it as: 1E10 / 3E05.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+04."
        }
    },
    {
        "question": "What is 3E+07 divided by one million?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 3E+07 divided by one million?<br /><br />Rephrase it as: 3E07 / 1E06.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
        }
    },
    {
        "question": "What is 1E+05 divided by three thousand?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 1E+05 divided by three thousand?<br /><br />Rephrase it as: 1E05 / 3E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+01."
        }
    },
    {
        "question": "What is 1E+13 divided by three thousand?",
        "writeInAnswer": 3000000000,
        "furtherInfo": {
            "explanation": "What is 1E+13 divided by three thousand?<br /><br />Rephrase it as: 1E13 / 3E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+09."
        }
    },
    {
        "question": "What is 3E+11 divided by ten?",
        "writeInAnswer": 30000000000,
        "furtherInfo": {
            "explanation": "What is 3E+11 divided by ten?<br /><br />Rephrase it as: 3E11 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+10."
        }
    },
    {
        "question": "What is 3E+02 divided by three?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 3E+02 divided by three?<br /><br />Rephrase it as: 3E02 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 1E+11 divided by thirty million?",
        "writeInAnswer": 3000,
        "furtherInfo": {
            "explanation": "What is 1E+11 divided by thirty million?<br /><br />Rephrase it as: 1E11 / 3E07.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+03."
        }
    },
    {
        "question": "What is 1E+03 divided by ten?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 1E+03 divided by ten?<br /><br />Rephrase it as: 1E03 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 1E+01 divided by one?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 1E+01 divided by one?<br /><br />Rephrase it as: 1E01 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 1E+12 divided by ten?",
        "writeInAnswer": 100000000000,
        "furtherInfo": {
            "explanation": "What is 1E+12 divided by ten?<br /><br />Rephrase it as: 1E12 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+11."
        }
    },
    {
        "question": "What is 3E+02 divided by one?",
        "writeInAnswer": 300,
        "furtherInfo": {
            "explanation": "What is 3E+02 divided by one?<br /><br />Rephrase it as: 3E02 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+02."
        }
    },
    {
        "question": "What is 1E+13 divided by ten?",
        "writeInAnswer": 1000000000000,
        "furtherInfo": {
            "explanation": "What is 1E+13 divided by ten?<br /><br />Rephrase it as: 1E13 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+12."
        }
    },
    {
        "question": "What is 1E+05 divided by three thousand?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 1E+05 divided by three thousand?<br /><br />Rephrase it as: 1E05 / 3E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+01."
        }
    },
    {
        "question": "What is 3E+11 divided by three?",
        "writeInAnswer": 100000000000,
        "furtherInfo": {
            "explanation": "What is 3E+11 divided by three?<br /><br />Rephrase it as: 3E11 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+11."
        }
    },
    {
        "question": "What is 3E+07 divided by three million?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 3E+07 divided by three million?<br /><br />Rephrase it as: 3E07 / 3E06.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 3E+10 divided by three thousand?",
        "writeInAnswer": 10000000,
        "furtherInfo": {
            "explanation": "What is 3E+10 divided by three thousand?<br /><br />Rephrase it as: 3E10 / 3E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+07."
        }
    },
    {
        "question": "What is 1E+07 divided by one thousand?",
        "writeInAnswer": 10000,
        "furtherInfo": {
            "explanation": "What is 1E+07 divided by one thousand?<br /><br />Rephrase it as: 1E07 / 1E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+04."
        }
    },
    {
        "question": "What is 3E+04 divided by ten?",
        "writeInAnswer": 3000,
        "furtherInfo": {
            "explanation": "What is 3E+04 divided by ten?<br /><br />Rephrase it as: 3E04 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+03."
        }
    },
    {
        "question": "What is 3E+04 divided by thirty?",
        "writeInAnswer": 1000,
        "furtherInfo": {
            "explanation": "What is 3E+04 divided by thirty?<br /><br />Rephrase it as: 3E04 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
        }
    },
    {
        "question": "What is 1E+05 divided by thirty?",
        "writeInAnswer": 3000,
        "furtherInfo": {
            "explanation": "What is 1E+05 divided by thirty?<br /><br />Rephrase it as: 1E05 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+03."
        }
    },
    {
        "question": "What is 3E+11 divided by one million?",
        "writeInAnswer": 300000,
        "furtherInfo": {
            "explanation": "What is 3E+11 divided by one million?<br /><br />Rephrase it as: 3E11 / 1E06.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+05."
        }
    },
    {
        "question": "What is 1E+08 divided by ten million?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 1E+08 divided by ten million?<br /><br />Rephrase it as: 1E08 / 1E07.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 3E+05 divided by three thousand?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 3E+05 divided by three thousand?<br /><br />Rephrase it as: 3E05 / 3E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 3E+01 divided by one?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 3E+01 divided by one?<br /><br />Rephrase it as: 3E01 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
        }
    },
    {
        "question": "What is 3E+08 divided by ten million?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 3E+08 divided by ten million?<br /><br />Rephrase it as: 3E08 / 1E07.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
        }
    },
    {
        "question": "What is 3E+01 divided by one?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 3E+01 divided by one?<br /><br />Rephrase it as: 3E01 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
        }
    },
    {
        "question": "What is 1E+10 divided by one hundred?",
        "writeInAnswer": 100000000,
        "furtherInfo": {
            "explanation": "What is 1E+10 divided by one hundred?<br /><br />Rephrase it as: 1E10 / 1E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+08."
        }
    },
    {
        "question": "What is 1E+08 divided by thirty thousand?",
        "writeInAnswer": 3000,
        "furtherInfo": {
            "explanation": "What is 1E+08 divided by thirty thousand?<br /><br />Rephrase it as: 1E08 / 3E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+03."
        }
    },
    {
        "question": "What is 3E+11 divided by ten million?",
        "writeInAnswer": 30000,
        "furtherInfo": {
            "explanation": "What is 3E+11 divided by ten million?<br /><br />Rephrase it as: 3E11 / 1E07.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+04."
        }
    },
    {
        "question": "What is 1E+03 divided by thirty?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 1E+03 divided by thirty?<br /><br />Rephrase it as: 1E03 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+01."
        }
    },
    {
        "question": "What is 3E+01 divided by one?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 3E+01 divided by one?<br /><br />Rephrase it as: 3E01 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
        }
    },
    {
        "question": "What is 3E+03 divided by one hundred?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 3E+03 divided by one hundred?<br /><br />Rephrase it as: 3E03 / 1E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
        }
    },
    {
        "question": "What is 1E+07 divided by ten?",
        "writeInAnswer": 1000000,
        "furtherInfo": {
            "explanation": "What is 1E+07 divided by ten?<br /><br />Rephrase it as: 1E07 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+06."
        }
    },
    {
        "question": "What is 3E+09 divided by one hundred thousand?",
        "writeInAnswer": 30000,
        "furtherInfo": {
            "explanation": "What is 3E+09 divided by one hundred thousand?<br /><br />Rephrase it as: 3E09 / 1E05.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+04."
        }
    },
    {
        "question": "What is 1E+12 divided by thirty?",
        "writeInAnswer": 30000000000,
        "furtherInfo": {
            "explanation": "What is 1E+12 divided by thirty?<br /><br />Rephrase it as: 1E12 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+10."
        }
    },
    {
        "question": "What is 1E+08 divided by three thousand?",
        "writeInAnswer": 30000,
        "furtherInfo": {
            "explanation": "What is 1E+08 divided by three thousand?<br /><br />Rephrase it as: 1E08 / 3E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+04."
        }
    },
    {
        "question": "What is 3E+10 divided by one million?",
        "writeInAnswer": 30000,
        "furtherInfo": {
            "explanation": "What is 3E+10 divided by one million?<br /><br />Rephrase it as: 3E10 / 1E06.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+04."
        }
    },
    {
        "question": "What is 1E+05 divided by thirty?",
        "writeInAnswer": 3000,
        "furtherInfo": {
            "explanation": "What is 1E+05 divided by thirty?<br /><br />Rephrase it as: 1E05 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+03."
        }
    },
    {
        "question": "What is 1E+12 divided by three hundred?",
        "writeInAnswer": 3000000000,
        "furtherInfo": {
            "explanation": "What is 1E+12 divided by three hundred?<br /><br />Rephrase it as: 1E12 / 3E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+09."
        }
    },
    {
        "question": "What is 3E+04 divided by three thousand?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 3E+04 divided by three thousand?<br /><br />Rephrase it as: 3E04 / 3E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 1E+08 divided by three?",
        "writeInAnswer": 30000000,
        "furtherInfo": {
            "explanation": "What is 1E+08 divided by three?<br /><br />Rephrase it as: 1E08 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+07."
        }
    },
    {
        "question": "What is 3E+11 divided by ten?",
        "writeInAnswer": 30000000000,
        "furtherInfo": {
            "explanation": "What is 3E+11 divided by ten?<br /><br />Rephrase it as: 3E11 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+10."
        }
    },
    {
        "question": "What is 3E+07 divided by one million?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 3E+07 divided by one million?<br /><br />Rephrase it as: 3E07 / 1E06.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
        }
    },
    {
        "question": "What is 3E+03 divided by three hundred?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 3E+03 divided by three hundred?<br /><br />Rephrase it as: 3E03 / 3E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 3E+07 divided by one thousand?",
        "writeInAnswer": 30000,
        "furtherInfo": {
            "explanation": "What is 3E+07 divided by one thousand?<br /><br />Rephrase it as: 3E07 / 1E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+04."
        }
    },
    {
        "question": "What is 1E+10 divided by one hundred thousand?",
        "writeInAnswer": 100000,
        "furtherInfo": {
            "explanation": "What is 1E+10 divided by one hundred thousand?<br /><br />Rephrase it as: 1E10 / 1E05.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+05."
        }
    },
    {
        "question": "What is 1E+13 divided by one hundred billion?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 1E+13 divided by one hundred billion?<br /><br />Rephrase it as: 1E13 / 1E11.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 3E+12 divided by one hundred thousand?",
        "writeInAnswer": 30000000,
        "furtherInfo": {
            "explanation": "What is 3E+12 divided by one hundred thousand?<br /><br />Rephrase it as: 3E12 / 1E05.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+07."
        }
    },
    {
        "question": "What is 3E+03 divided by ten?",
        "writeInAnswer": 300,
        "furtherInfo": {
            "explanation": "What is 3E+03 divided by ten?<br /><br />Rephrase it as: 3E03 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+02."
        }
    },
    {
        "question": "What is 3E+05 divided by three hundred?",
        "writeInAnswer": 1000,
        "furtherInfo": {
            "explanation": "What is 3E+05 divided by three hundred?<br /><br />Rephrase it as: 3E05 / 3E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
        }
    },
    {
        "question": "What is 1E+03 divided by one?",
        "writeInAnswer": 1000,
        "furtherInfo": {
            "explanation": "What is 1E+03 divided by one?<br /><br />Rephrase it as: 1E03 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
        }
    },
    {
        "question": "What is 3E+01 divided by one?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 3E+01 divided by one?<br /><br />Rephrase it as: 3E01 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
        }
    },
    {
        "question": "What is 1E+11 divided by thirty million?",
        "writeInAnswer": 3000,
        "furtherInfo": {
            "explanation": "What is 1E+11 divided by thirty million?<br /><br />Rephrase it as: 1E11 / 3E07.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+03."
        }
    },
    {
        "question": "What is 1E+10 divided by one billion?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 1E+10 divided by one billion?<br /><br />Rephrase it as: 1E10 / 1E09.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 1E+10 divided by one million?",
        "writeInAnswer": 10000,
        "furtherInfo": {
            "explanation": "What is 1E+10 divided by one million?<br /><br />Rephrase it as: 1E10 / 1E06.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+04."
        }
    },
    {
        "question": "What is 3E+08 divided by one hundred?",
        "writeInAnswer": 3000000,
        "furtherInfo": {
            "explanation": "What is 3E+08 divided by one hundred?<br /><br />Rephrase it as: 3E08 / 1E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+06."
        }
    },
    {
        "question": "What is 1E+06 divided by ten thousand?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 1E+06 divided by ten thousand?<br /><br />Rephrase it as: 1E06 / 1E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 1E+10 divided by ten?",
        "writeInAnswer": 1000000000,
        "furtherInfo": {
            "explanation": "What is 1E+10 divided by ten?<br /><br />Rephrase it as: 1E10 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+09."
        }
    },
    {
        "question": "What is 1E+06 divided by three?",
        "writeInAnswer": 300000,
        "furtherInfo": {
            "explanation": "What is 1E+06 divided by three?<br /><br />Rephrase it as: 1E06 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+05."
        }
    },
    {
        "question": "What is 3E+09 divided by three thousand?",
        "writeInAnswer": 1000000,
        "furtherInfo": {
            "explanation": "What is 3E+09 divided by three thousand?<br /><br />Rephrase it as: 3E09 / 3E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+06."
        }
    },
    {
        "question": "What is 3E+02 divided by ten?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 3E+02 divided by ten?<br /><br />Rephrase it as: 3E02 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
        }
    },
    {
        "question": "What is 3E+08 divided by thirty million?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 3E+08 divided by thirty million?<br /><br />Rephrase it as: 3E08 / 3E07.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 1E+04 divided by one?",
        "writeInAnswer": 10000,
        "furtherInfo": {
            "explanation": "What is 1E+04 divided by one?<br /><br />Rephrase it as: 1E04 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+04."
        }
    },
    {
        "question": "What is 1E+13 divided by ten million?",
        "writeInAnswer": 1000000,
        "furtherInfo": {
            "explanation": "What is 1E+13 divided by ten million?<br /><br />Rephrase it as: 1E13 / 1E07.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+06."
        }
    },
    {
        "question": "What is 3E+09 divided by thirty?",
        "writeInAnswer": 100000000,
        "furtherInfo": {
            "explanation": "What is 3E+09 divided by thirty?<br /><br />Rephrase it as: 3E09 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+08."
        }
    },
    {
        "question": "What is 1E+08 divided by three?",
        "writeInAnswer": 30000000,
        "furtherInfo": {
            "explanation": "What is 1E+08 divided by three?<br /><br />Rephrase it as: 1E08 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+07."
        }
    },
    {
        "question": "What is 3E+08 divided by thirty thousand?",
        "writeInAnswer": 10000,
        "furtherInfo": {
            "explanation": "What is 3E+08 divided by thirty thousand?<br /><br />Rephrase it as: 3E08 / 3E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+04."
        }
    },
    {
        "question": "What is 1E+02 divided by thirty?",
        "writeInAnswer": 3,
        "furtherInfo": {
            "explanation": "What is 1E+02 divided by thirty?<br /><br />Rephrase it as: 1E02 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
        }
    },
    {
        "question": "What is 1E+06 divided by ten?",
        "writeInAnswer": 100000,
        "furtherInfo": {
            "explanation": "What is 1E+06 divided by ten?<br /><br />Rephrase it as: 1E06 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+05."
        }
    },
    {
        "question": "What is 3E+12 divided by one hundred billion?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 3E+12 divided by one hundred billion?<br /><br />Rephrase it as: 3E12 / 1E11.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
        }
    },
    {
        "question": "What is 3E+08 divided by three hundred thousand?",
        "writeInAnswer": 1000,
        "furtherInfo": {
            "explanation": "What is 3E+08 divided by three hundred thousand?<br /><br />Rephrase it as: 3E08 / 3E05.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
        }
    },
    {
        "question": "What is 3E+13 divided by three billion?",
        "writeInAnswer": 10000,
        "furtherInfo": {
            "explanation": "What is 3E+13 divided by three billion?<br /><br />Rephrase it as: 3E13 / 3E09.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+04."
        }
    },
    {
        "question": "What is 3E+12 divided by ten thousand?",
        "writeInAnswer": 300000000,
        "furtherInfo": {
            "explanation": "What is 3E+12 divided by ten thousand?<br /><br />Rephrase it as: 3E12 / 1E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+08."
        }
    },
    {
        "question": "What is 1E+06 divided by three hundred?",
        "writeInAnswer": 3000,
        "furtherInfo": {
            "explanation": "What is 1E+06 divided by three hundred?<br /><br />Rephrase it as: 1E06 / 3E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+03."
        }
    },
    {
        "question": "What is 3E+06 divided by one thousand?",
        "writeInAnswer": 3000,
        "furtherInfo": {
            "explanation": "What is 3E+06 divided by one thousand?<br /><br />Rephrase it as: 3E06 / 1E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+03."
        }
    },
    {
        "question": "What is 3E+09 divided by ten million?",
        "writeInAnswer": 300,
        "furtherInfo": {
            "explanation": "What is 3E+09 divided by ten million?<br /><br />Rephrase it as: 3E09 / 1E07.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+02."
        }
    },
    {
        "question": "What is 1E+08 divided by one hundred?",
        "writeInAnswer": 1000000,
        "furtherInfo": {
            "explanation": "What is 1E+08 divided by one hundred?<br /><br />Rephrase it as: 1E08 / 1E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+06."
        }
    },
    {
        "question": "What is 1E+04 divided by thirty?",
        "writeInAnswer": 300,
        "furtherInfo": {
            "explanation": "What is 1E+04 divided by thirty?<br /><br />Rephrase it as: 1E04 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+02."
        }
    },
    {
        "question": "What is 3E+05 divided by one thousand?",
        "writeInAnswer": 300,
        "furtherInfo": {
            "explanation": "What is 3E+05 divided by one thousand?<br /><br />Rephrase it as: 3E05 / 1E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+02."
        }
    },
    {
        "question": "What is 1E+12 divided by thirty?",
        "writeInAnswer": 30000000000,
        "furtherInfo": {
            "explanation": "What is 1E+12 divided by thirty?<br /><br />Rephrase it as: 1E12 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+10."
        }
    },
    {
        "question": "What is 3E+05 divided by three hundred?",
        "writeInAnswer": 1000,
        "furtherInfo": {
            "explanation": "What is 3E+05 divided by three hundred?<br /><br />Rephrase it as: 3E05 / 3E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
        }
    },
    {
        "question": "What is 3E+02 divided by one?",
        "writeInAnswer": 300,
        "furtherInfo": {
            "explanation": "What is 3E+02 divided by one?<br /><br />Rephrase it as: 3E02 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+02."
        }
    },
    {
        "question": "What is 1E+11 divided by one million?",
        "writeInAnswer": 100000,
        "furtherInfo": {
            "explanation": "What is 1E+11 divided by one million?<br /><br />Rephrase it as: 1E11 / 1E06.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+05."
        }
    },
    {
        "question": "What is 1E+08 divided by thirty thousand?",
        "writeInAnswer": 3000,
        "furtherInfo": {
            "explanation": "What is 1E+08 divided by thirty thousand?<br /><br />Rephrase it as: 1E08 / 3E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+03."
        }
    },
    {
        "question": "What is 1E+01 divided by three?",
        "writeInAnswer": 3,
        "furtherInfo": {
            "explanation": "What is 1E+01 divided by three?<br /><br />Rephrase it as: 1E01 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
        }
    },
    {
        "question": "What is 3E+03 divided by three hundred?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 3E+03 divided by three hundred?<br /><br />Rephrase it as: 3E03 / 3E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 3E+08 divided by one hundred?",
        "writeInAnswer": 3000000,
        "furtherInfo": {
            "explanation": "What is 3E+08 divided by one hundred?<br /><br />Rephrase it as: 3E08 / 1E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+06."
        }
    },
    {
        "question": "What is 3E+13 divided by three billion?",
        "writeInAnswer": 10000,
        "furtherInfo": {
            "explanation": "What is 3E+13 divided by three billion?<br /><br />Rephrase it as: 3E13 / 3E09.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+04."
        }
    },
    {
        "question": "What is 3E+08 divided by one thousand?",
        "writeInAnswer": 300000,
        "furtherInfo": {
            "explanation": "What is 3E+08 divided by one thousand?<br /><br />Rephrase it as: 3E08 / 1E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+05."
        }
    },
    {
        "question": "What is 1E+07 divided by three hundred thousand?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 1E+07 divided by three hundred thousand?<br /><br />Rephrase it as: 1E07 / 3E05.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+01."
        }
    },
    {
        "question": "What is 1E+12 divided by three?",
        "writeInAnswer": 300000000000,
        "furtherInfo": {
            "explanation": "What is 1E+12 divided by three?<br /><br />Rephrase it as: 1E12 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+11."
        }
    },
    {
        "question": "What is 3E+06 divided by one?",
        "writeInAnswer": 3000000,
        "furtherInfo": {
            "explanation": "What is 3E+06 divided by one?<br /><br />Rephrase it as: 3E06 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+06."
        }
    },
    {
        "question": "What is 3E+10 divided by ten million?",
        "writeInAnswer": 3000,
        "furtherInfo": {
            "explanation": "What is 3E+10 divided by ten million?<br /><br />Rephrase it as: 3E10 / 1E07.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+03."
        }
    },
    {
        "question": "What is 3E+05 divided by ten thousand?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 3E+05 divided by ten thousand?<br /><br />Rephrase it as: 3E05 / 1E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
        }
    },
    {
        "question": "What is 3E+03 divided by one hundred?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 3E+03 divided by one hundred?<br /><br />Rephrase it as: 3E03 / 1E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
        }
    },
    {
        "question": "What is 1E+11 divided by ten billion?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 1E+11 divided by ten billion?<br /><br />Rephrase it as: 1E11 / 1E10.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 3E+07 divided by one million?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 3E+07 divided by one million?<br /><br />Rephrase it as: 3E07 / 1E06.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
        }
    },
    {
        "question": "What is 1E+07 divided by three?",
        "writeInAnswer": 3000000,
        "furtherInfo": {
            "explanation": "What is 1E+07 divided by three?<br /><br />Rephrase it as: 1E07 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+06."
        }
    },
    {
        "question": "What is 1E+08 divided by ten million?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 1E+08 divided by ten million?<br /><br />Rephrase it as: 1E08 / 1E07.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 1E+13 divided by ten billion?",
        "writeInAnswer": 1000,
        "furtherInfo": {
            "explanation": "What is 1E+13 divided by ten billion?<br /><br />Rephrase it as: 1E13 / 1E10.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
        }
    },
    {
        "question": "What is 1E+08 divided by one million?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 1E+08 divided by one million?<br /><br />Rephrase it as: 1E08 / 1E06.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 3E+02 divided by ten?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 3E+02 divided by ten?<br /><br />Rephrase it as: 3E02 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
        }
    },
    {
        "question": "What is 1E+10 divided by one billion?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 1E+10 divided by one billion?<br /><br />Rephrase it as: 1E10 / 1E09.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 1E+04 divided by three?",
        "writeInAnswer": 3000,
        "furtherInfo": {
            "explanation": "What is 1E+04 divided by three?<br /><br />Rephrase it as: 1E04 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+03."
        }
    },
    {
        "question": "What is 3E+09 divided by one million?",
        "writeInAnswer": 3000,
        "furtherInfo": {
            "explanation": "What is 3E+09 divided by one million?<br /><br />Rephrase it as: 3E09 / 1E06.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+03."
        }
    },
    {
        "question": "What is 3E+06 divided by three thousand?",
        "writeInAnswer": 1000,
        "furtherInfo": {
            "explanation": "What is 3E+06 divided by three thousand?<br /><br />Rephrase it as: 3E06 / 3E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
        }
    },
    {
        "question": "What is 3E+11 divided by thirty billion?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 3E+11 divided by thirty billion?<br /><br />Rephrase it as: 3E11 / 3E10.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 3E+11 divided by ten million?",
        "writeInAnswer": 30000,
        "furtherInfo": {
            "explanation": "What is 3E+11 divided by ten million?<br /><br />Rephrase it as: 3E11 / 1E07.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+04."
        }
    },
    {
        "question": "What is 3E+06 divided by three hundred thousand?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 3E+06 divided by three hundred thousand?<br /><br />Rephrase it as: 3E06 / 3E05.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 3E+07 divided by one million?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 3E+07 divided by one million?<br /><br />Rephrase it as: 3E07 / 1E06.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
        }
    },
    {
        "question": "What is 1E+12 divided by three thousand?",
        "writeInAnswer": 300000000,
        "furtherInfo": {
            "explanation": "What is 1E+12 divided by three thousand?<br /><br />Rephrase it as: 1E12 / 3E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+08."
        }
    },
    {
        "question": "What is 3E+11 divided by three million?",
        "writeInAnswer": 100000,
        "furtherInfo": {
            "explanation": "What is 3E+11 divided by three million?<br /><br />Rephrase it as: 3E11 / 3E06.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+05."
        }
    },
    {
        "question": "What is 1E+10 divided by three hundred million?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 1E+10 divided by three hundred million?<br /><br />Rephrase it as: 1E10 / 3E08.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+01."
        }
    },
    {
        "question": "What is 3E+11 divided by three hundred thousand?",
        "writeInAnswer": 1000000,
        "furtherInfo": {
            "explanation": "What is 3E+11 divided by three hundred thousand?<br /><br />Rephrase it as: 3E11 / 3E05.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+06."
        }
    },
    {
        "question": "What is 3E+13 divided by ten thousand?",
        "writeInAnswer": 3000000000,
        "furtherInfo": {
            "explanation": "What is 3E+13 divided by ten thousand?<br /><br />Rephrase it as: 3E13 / 1E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+09."
        }
    },
    {
        "question": "What is 3E+13 divided by three million?",
        "writeInAnswer": 10000000,
        "furtherInfo": {
            "explanation": "What is 3E+13 divided by three million?<br /><br />Rephrase it as: 3E13 / 3E06.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+07."
        }
    },
    {
        "question": "What is 1E+12 divided by three hundred?",
        "writeInAnswer": 3000000000,
        "furtherInfo": {
            "explanation": "What is 1E+12 divided by three hundred?<br /><br />Rephrase it as: 1E12 / 3E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+09."
        }
    },
    {
        "question": "What is 1E+05 divided by one hundred?",
        "writeInAnswer": 1000,
        "furtherInfo": {
            "explanation": "What is 1E+05 divided by one hundred?<br /><br />Rephrase it as: 1E05 / 1E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
        }
    },
    {
        "question": "What is 3E+04 divided by one hundred?",
        "writeInAnswer": 300,
        "furtherInfo": {
            "explanation": "What is 3E+04 divided by one hundred?<br /><br />Rephrase it as: 3E04 / 1E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+02."
        }
    },
    {
        "question": "What is 3E+08 divided by three thousand?",
        "writeInAnswer": 100000,
        "furtherInfo": {
            "explanation": "What is 3E+08 divided by three thousand?<br /><br />Rephrase it as: 3E08 / 3E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+05."
        }
    },
    {
        "question": "What is 3E+13 divided by one million?",
        "writeInAnswer": 30000000,
        "furtherInfo": {
            "explanation": "What is 3E+13 divided by one million?<br /><br />Rephrase it as: 3E13 / 1E06.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+07."
        }
    },
    {
        "question": "What is 3E+07 divided by three?",
        "writeInAnswer": 10000000,
        "furtherInfo": {
            "explanation": "What is 3E+07 divided by three?<br /><br />Rephrase it as: 3E07 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+07."
        }
    },
    {
        "question": "What is 3E+08 divided by three thousand?",
        "writeInAnswer": 100000,
        "furtherInfo": {
            "explanation": "What is 3E+08 divided by three thousand?<br /><br />Rephrase it as: 3E08 / 3E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+05."
        }
    },
    {
        "question": "What is 3E+06 divided by thirty?",
        "writeInAnswer": 100000,
        "furtherInfo": {
            "explanation": "What is 3E+06 divided by thirty?<br /><br />Rephrase it as: 3E06 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+05."
        }
    },
    {
        "question": "What is 1E+03 divided by one hundred?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 1E+03 divided by one hundred?<br /><br />Rephrase it as: 1E03 / 1E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 1E+10 divided by ten?",
        "writeInAnswer": 1000000000,
        "furtherInfo": {
            "explanation": "What is 1E+10 divided by ten?<br /><br />Rephrase it as: 1E10 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+09."
        }
    },
    {
        "question": "What is 3E+10 divided by three hundred thousand?",
        "writeInAnswer": 100000,
        "furtherInfo": {
            "explanation": "What is 3E+10 divided by three hundred thousand?<br /><br />Rephrase it as: 3E10 / 3E05.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+05."
        }
    },
    {
        "question": "What is 1E+08 divided by thirty thousand?",
        "writeInAnswer": 3000,
        "furtherInfo": {
            "explanation": "What is 1E+08 divided by thirty thousand?<br /><br />Rephrase it as: 1E08 / 3E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+03."
        }
    },
    {
        "question": "What is 3E+08 divided by thirty million?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 3E+08 divided by thirty million?<br /><br />Rephrase it as: 3E08 / 3E07.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 3E+02 divided by one?",
        "writeInAnswer": 300,
        "furtherInfo": {
            "explanation": "What is 3E+02 divided by one?<br /><br />Rephrase it as: 3E02 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+02."
        }
    },
    {
        "question": "What is 1E+13 divided by one hundred thousand?",
        "writeInAnswer": 100000000,
        "furtherInfo": {
            "explanation": "What is 1E+13 divided by one hundred thousand?<br /><br />Rephrase it as: 1E13 / 1E05.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+08."
        }
    },
    {
        "question": "What is 1E+09 divided by ten million?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 1E+09 divided by ten million?<br /><br />Rephrase it as: 1E09 / 1E07.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 1E+05 divided by three thousand?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 1E+05 divided by three thousand?<br /><br />Rephrase it as: 1E05 / 3E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+01."
        }
    },
    {
        "question": "What is 1E+08 divided by three million?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 1E+08 divided by three million?<br /><br />Rephrase it as: 1E08 / 3E06.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+01."
        }
    },
    {
        "question": "What is 3E+02 divided by thirty?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 3E+02 divided by thirty?<br /><br />Rephrase it as: 3E02 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 1E+05 divided by thirty?",
        "writeInAnswer": 3000,
        "furtherInfo": {
            "explanation": "What is 1E+05 divided by thirty?<br /><br />Rephrase it as: 1E05 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+03."
        }
    },
    {
        "question": "What is 3E+01 divided by three?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 3E+01 divided by three?<br /><br />Rephrase it as: 3E01 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 1E+13 divided by three thousand?",
        "writeInAnswer": 3000000000,
        "furtherInfo": {
            "explanation": "What is 1E+13 divided by three thousand?<br /><br />Rephrase it as: 1E13 / 3E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+09."
        }
    },
    {
        "question": "What is 1E+04 divided by ten?",
        "writeInAnswer": 1000,
        "furtherInfo": {
            "explanation": "What is 1E+04 divided by ten?<br /><br />Rephrase it as: 1E04 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
        }
    },
    {
        "question": "What is 1E+03 divided by one hundred?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 1E+03 divided by one hundred?<br /><br />Rephrase it as: 1E03 / 1E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 3E+05 divided by one thousand?",
        "writeInAnswer": 300,
        "furtherInfo": {
            "explanation": "What is 3E+05 divided by one thousand?<br /><br />Rephrase it as: 3E05 / 1E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+02."
        }
    },
    {
        "question": "What is 3E+06 divided by ten thousand?",
        "writeInAnswer": 300,
        "furtherInfo": {
            "explanation": "What is 3E+06 divided by ten thousand?<br /><br />Rephrase it as: 3E06 / 1E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+02."
        }
    },
    {
        "question": "What is 3E+12 divided by three thousand?",
        "writeInAnswer": 1000000000,
        "furtherInfo": {
            "explanation": "What is 3E+12 divided by three thousand?<br /><br />Rephrase it as: 3E12 / 3E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+09."
        }
    },
    {
        "question": "What is 3E+05 divided by ten thousand?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 3E+05 divided by ten thousand?<br /><br />Rephrase it as: 3E05 / 1E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
        }
    },
    {
        "question": "What is 1E+11 divided by thirty?",
        "writeInAnswer": 3000000000,
        "furtherInfo": {
            "explanation": "What is 1E+11 divided by thirty?<br /><br />Rephrase it as: 1E11 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+09."
        }
    },
    {
        "question": "What is 3E+07 divided by thirty?",
        "writeInAnswer": 1000000,
        "furtherInfo": {
            "explanation": "What is 3E+07 divided by thirty?<br /><br />Rephrase it as: 3E07 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+06."
        }
    },
    {
        "question": "What is 1E+05 divided by ten?",
        "writeInAnswer": 10000,
        "furtherInfo": {
            "explanation": "What is 1E+05 divided by ten?<br /><br />Rephrase it as: 1E05 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+04."
        }
    },
    {
        "question": "What is 1E+04 divided by three thousand?",
        "writeInAnswer": 3,
        "furtherInfo": {
            "explanation": "What is 1E+04 divided by three thousand?<br /><br />Rephrase it as: 1E04 / 3E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
        }
    },
    {
        "question": "What is 3E+08 divided by three million?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 3E+08 divided by three million?<br /><br />Rephrase it as: 3E08 / 3E06.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 1E+07 divided by one?",
        "writeInAnswer": 10000000,
        "furtherInfo": {
            "explanation": "What is 1E+07 divided by one?<br /><br />Rephrase it as: 1E07 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+07."
        }
    },
    {
        "question": "What is 3E+03 divided by ten?",
        "writeInAnswer": 300,
        "furtherInfo": {
            "explanation": "What is 3E+03 divided by ten?<br /><br />Rephrase it as: 3E03 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+02."
        }
    },
    {
        "question": "What is 1E+11 divided by thirty?",
        "writeInAnswer": 3000000000,
        "furtherInfo": {
            "explanation": "What is 1E+11 divided by thirty?<br /><br />Rephrase it as: 1E11 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+09."
        }
    },
    {
        "question": "What is 1E+11 divided by thirty million?",
        "writeInAnswer": 3000,
        "furtherInfo": {
            "explanation": "What is 1E+11 divided by thirty million?<br /><br />Rephrase it as: 1E11 / 3E07.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+03."
        }
    },
    {
        "question": "What is 3E+13 divided by three hundred thousand?",
        "writeInAnswer": 100000000,
        "furtherInfo": {
            "explanation": "What is 3E+13 divided by three hundred thousand?<br /><br />Rephrase it as: 3E13 / 3E05.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+08."
        }
    },
    {
        "question": "What is 1E+07 divided by thirty?",
        "writeInAnswer": 300000,
        "furtherInfo": {
            "explanation": "What is 1E+07 divided by thirty?<br /><br />Rephrase it as: 1E07 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+05."
        }
    },
    {
        "question": "What is 3E+13 divided by three hundred million?",
        "writeInAnswer": 100000,
        "furtherInfo": {
            "explanation": "What is 3E+13 divided by three hundred million?<br /><br />Rephrase it as: 3E13 / 3E08.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+05."
        }
    },
    {
        "question": "What is 1E+07 divided by ten thousand?",
        "writeInAnswer": 1000,
        "furtherInfo": {
            "explanation": "What is 1E+07 divided by ten thousand?<br /><br />Rephrase it as: 1E07 / 1E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
        }
    },
    {
        "question": "What is 1E+12 divided by one?",
        "writeInAnswer": 1000000000000,
        "furtherInfo": {
            "explanation": "What is 1E+12 divided by one?<br /><br />Rephrase it as: 1E12 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+12."
        }
    },
    {
        "question": "What is 3E+06 divided by one hundred thousand?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 3E+06 divided by one hundred thousand?<br /><br />Rephrase it as: 3E06 / 1E05.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
        }
    },
    {
        "question": "What is 3E+09 divided by three hundred?",
        "writeInAnswer": 10000000,
        "furtherInfo": {
            "explanation": "What is 3E+09 divided by three hundred?<br /><br />Rephrase it as: 3E09 / 3E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+07."
        }
    },
    {
        "question": "What is 1E+02 divided by one?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 1E+02 divided by one?<br /><br />Rephrase it as: 1E02 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 3E+08 divided by three thousand?",
        "writeInAnswer": 100000,
        "furtherInfo": {
            "explanation": "What is 3E+08 divided by three thousand?<br /><br />Rephrase it as: 3E08 / 3E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+05."
        }
    },
    {
        "question": "What is 1E+13 divided by thirty thousand?",
        "writeInAnswer": 300000000,
        "furtherInfo": {
            "explanation": "What is 1E+13 divided by thirty thousand?<br /><br />Rephrase it as: 1E13 / 3E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+08."
        }
    },
    {
        "question": "What is 1E+13 divided by three?",
        "writeInAnswer": 3000000000000,
        "furtherInfo": {
            "explanation": "What is 1E+13 divided by three?<br /><br />Rephrase it as: 1E13 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+12."
        }
    },
    {
        "question": "What is 3E+10 divided by thirty?",
        "writeInAnswer": 1000000000,
        "furtherInfo": {
            "explanation": "What is 3E+10 divided by thirty?<br /><br />Rephrase it as: 3E10 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+09."
        }
    },
    {
        "question": "What is 1E+02 divided by one?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 1E+02 divided by one?<br /><br />Rephrase it as: 1E02 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 3E+04 divided by one thousand?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 3E+04 divided by one thousand?<br /><br />Rephrase it as: 3E04 / 1E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
        }
    },
    {
        "question": "What is 1E+02 divided by ten?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 1E+02 divided by ten?<br /><br />Rephrase it as: 1E02 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 1E+10 divided by one million?",
        "writeInAnswer": 10000,
        "furtherInfo": {
            "explanation": "What is 1E+10 divided by one million?<br /><br />Rephrase it as: 1E10 / 1E06.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+04."
        }
    },
    {
        "question": "What is 1E+01 divided by one?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 1E+01 divided by one?<br /><br />Rephrase it as: 1E01 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 1E+10 divided by one?",
        "writeInAnswer": 10000000000,
        "furtherInfo": {
            "explanation": "What is 1E+10 divided by one?<br /><br />Rephrase it as: 1E10 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+10."
        }
    },
    {
        "question": "What is 1E+03 divided by thirty?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 1E+03 divided by thirty?<br /><br />Rephrase it as: 1E03 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+01."
        }
    },
    {
        "question": "What is 1E+04 divided by three thousand?",
        "writeInAnswer": 3,
        "furtherInfo": {
            "explanation": "What is 1E+04 divided by three thousand?<br /><br />Rephrase it as: 1E04 / 3E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
        }
    },
    {
        "question": "What is 1E+02 divided by one?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 1E+02 divided by one?<br /><br />Rephrase it as: 1E02 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 1E+07 divided by one million?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 1E+07 divided by one million?<br /><br />Rephrase it as: 1E07 / 1E06.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 1E+07 divided by one thousand?",
        "writeInAnswer": 10000,
        "furtherInfo": {
            "explanation": "What is 1E+07 divided by one thousand?<br /><br />Rephrase it as: 1E07 / 1E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+04."
        }
    },
    {
        "question": "What is 1E+02 divided by one?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 1E+02 divided by one?<br /><br />Rephrase it as: 1E02 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 3E+03 divided by three?",
        "writeInAnswer": 1000,
        "furtherInfo": {
            "explanation": "What is 3E+03 divided by three?<br /><br />Rephrase it as: 3E03 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
        }
    },
    {
        "question": "What is 3E+04 divided by one thousand?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 3E+04 divided by one thousand?<br /><br />Rephrase it as: 3E04 / 1E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
        }
    },
    {
        "question": "What is 1E+10 divided by three hundred thousand?",
        "writeInAnswer": 30000,
        "furtherInfo": {
            "explanation": "What is 1E+10 divided by three hundred thousand?<br /><br />Rephrase it as: 1E10 / 3E05.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+04."
        }
    },
    {
        "question": "What is 1E+06 divided by one thousand?",
        "writeInAnswer": 1000,
        "furtherInfo": {
            "explanation": "What is 1E+06 divided by one thousand?<br /><br />Rephrase it as: 1E06 / 1E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
        }
    },
    {
        "question": "What is 1E+04 divided by one?",
        "writeInAnswer": 10000,
        "furtherInfo": {
            "explanation": "What is 1E+04 divided by one?<br /><br />Rephrase it as: 1E04 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+04."
        }
    },
    {
        "question": "What is 3E+06 divided by one hundred?",
        "writeInAnswer": 30000,
        "furtherInfo": {
            "explanation": "What is 3E+06 divided by one hundred?<br /><br />Rephrase it as: 3E06 / 1E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+04."
        }
    },
    {
        "question": "What is 1E+06 divided by one hundred thousand?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 1E+06 divided by one hundred thousand?<br /><br />Rephrase it as: 1E06 / 1E05.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 3E+09 divided by one thousand?",
        "writeInAnswer": 3000000,
        "furtherInfo": {
            "explanation": "What is 3E+09 divided by one thousand?<br /><br />Rephrase it as: 3E09 / 1E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+06."
        }
    },
    {
        "question": "What is 1E+10 divided by three hundred?",
        "writeInAnswer": 30000000,
        "furtherInfo": {
            "explanation": "What is 1E+10 divided by three hundred?<br /><br />Rephrase it as: 1E10 / 3E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+07."
        }
    },
    {
        "question": "What is 1E+12 divided by three million?",
        "writeInAnswer": 300000,
        "furtherInfo": {
            "explanation": "What is 1E+12 divided by three million?<br /><br />Rephrase it as: 1E12 / 3E06.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+05."
        }
    },
    {
        "question": "What is 1E+01 divided by one?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 1E+01 divided by one?<br /><br />Rephrase it as: 1E01 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 1E+05 divided by thirty thousand?",
        "writeInAnswer": 3,
        "furtherInfo": {
            "explanation": "What is 1E+05 divided by thirty thousand?<br /><br />Rephrase it as: 1E05 / 3E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
        }
    },
    {
        "question": "What is 1E+04 divided by three?",
        "writeInAnswer": 3000,
        "furtherInfo": {
            "explanation": "What is 1E+04 divided by three?<br /><br />Rephrase it as: 1E04 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+03."
        }
    },
    {
        "question": "What is 3E+05 divided by ten?",
        "writeInAnswer": 30000,
        "furtherInfo": {
            "explanation": "What is 3E+05 divided by ten?<br /><br />Rephrase it as: 3E05 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+04."
        }
    },
    {
        "question": "What is 3E+12 divided by one thousand?",
        "writeInAnswer": 3000000000,
        "furtherInfo": {
            "explanation": "What is 3E+12 divided by one thousand?<br /><br />Rephrase it as: 3E12 / 1E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+09."
        }
    },
    {
        "question": "What is 1E+04 divided by ten?",
        "writeInAnswer": 1000,
        "furtherInfo": {
            "explanation": "What is 1E+04 divided by ten?<br /><br />Rephrase it as: 1E04 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
        }
    },
    {
        "question": "What is 1E+02 divided by one?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 1E+02 divided by one?<br /><br />Rephrase it as: 1E02 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 3E+08 divided by three thousand?",
        "writeInAnswer": 100000,
        "furtherInfo": {
            "explanation": "What is 3E+08 divided by three thousand?<br /><br />Rephrase it as: 3E08 / 3E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+05."
        }
    },
    {
        "question": "What is 3E+13 divided by ten billion?",
        "writeInAnswer": 3000,
        "furtherInfo": {
            "explanation": "What is 3E+13 divided by ten billion?<br /><br />Rephrase it as: 3E13 / 1E10.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+03."
        }
    },
    {
        "question": "What is 3E+03 divided by three?",
        "writeInAnswer": 1000,
        "furtherInfo": {
            "explanation": "What is 3E+03 divided by three?<br /><br />Rephrase it as: 3E03 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
        }
    },
    {
        "question": "What is 3E+09 divided by one thousand?",
        "writeInAnswer": 3000000,
        "furtherInfo": {
            "explanation": "What is 3E+09 divided by one thousand?<br /><br />Rephrase it as: 3E09 / 1E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+06."
        }
    },
    {
        "question": "What is 1E+07 divided by one?",
        "writeInAnswer": 10000000,
        "furtherInfo": {
            "explanation": "What is 1E+07 divided by one?<br /><br />Rephrase it as: 1E07 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+07."
        }
    },
    {
        "question": "What is 1E+01 divided by one?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 1E+01 divided by one?<br /><br />Rephrase it as: 1E01 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 1E+09 divided by one million?",
        "writeInAnswer": 1000,
        "furtherInfo": {
            "explanation": "What is 1E+09 divided by one million?<br /><br />Rephrase it as: 1E09 / 1E06.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
        }
    },
    {
        "question": "What is 3E+11 divided by ten million?",
        "writeInAnswer": 30000,
        "furtherInfo": {
            "explanation": "What is 3E+11 divided by ten million?<br /><br />Rephrase it as: 3E11 / 1E07.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+04."
        }
    },
    {
        "question": "What is 1E+02 divided by three?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 1E+02 divided by three?<br /><br />Rephrase it as: 1E02 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+01."
        }
    },
    {
        "question": "What is 1E+05 divided by thirty?",
        "writeInAnswer": 3000,
        "furtherInfo": {
            "explanation": "What is 1E+05 divided by thirty?<br /><br />Rephrase it as: 1E05 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+03."
        }
    },
    {
        "question": "What is 3E+04 divided by ten?",
        "writeInAnswer": 3000,
        "furtherInfo": {
            "explanation": "What is 3E+04 divided by ten?<br /><br />Rephrase it as: 3E04 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+03."
        }
    },
    {
        "question": "What is 3E+04 divided by thirty?",
        "writeInAnswer": 1000,
        "furtherInfo": {
            "explanation": "What is 3E+04 divided by thirty?<br /><br />Rephrase it as: 3E04 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
        }
    },
    {
        "question": "What is 1E+08 divided by three hundred thousand?",
        "writeInAnswer": 300,
        "furtherInfo": {
            "explanation": "What is 1E+08 divided by three hundred thousand?<br /><br />Rephrase it as: 1E08 / 3E05.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+02."
        }
    },
    {
        "question": "What is 3E+02 divided by thirty?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 3E+02 divided by thirty?<br /><br />Rephrase it as: 3E02 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 1E+09 divided by three hundred?",
        "writeInAnswer": 3000000,
        "furtherInfo": {
            "explanation": "What is 1E+09 divided by three hundred?<br /><br />Rephrase it as: 1E09 / 3E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+06."
        }
    },
    {
        "question": "What is 1E+11 divided by three billion?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 1E+11 divided by three billion?<br /><br />Rephrase it as: 1E11 / 3E09.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+01."
        }
    },
    {
        "question": "What is 3E+08 divided by one thousand?",
        "writeInAnswer": 300000,
        "furtherInfo": {
            "explanation": "What is 3E+08 divided by one thousand?<br /><br />Rephrase it as: 3E08 / 1E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+05."
        }
    },
    {
        "question": "What is 3E+11 divided by one thousand?",
        "writeInAnswer": 300000000,
        "furtherInfo": {
            "explanation": "What is 3E+11 divided by one thousand?<br /><br />Rephrase it as: 3E11 / 1E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+08."
        }
    },
    {
        "question": "What is 1E+12 divided by one?",
        "writeInAnswer": 1000000000000,
        "furtherInfo": {
            "explanation": "What is 1E+12 divided by one?<br /><br />Rephrase it as: 1E12 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+12."
        }
    },
    {
        "question": "What is 1E+09 divided by ten?",
        "writeInAnswer": 100000000,
        "furtherInfo": {
            "explanation": "What is 1E+09 divided by ten?<br /><br />Rephrase it as: 1E09 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+08."
        }
    },
    {
        "question": "What is 1E+09 divided by one hundred?",
        "writeInAnswer": 10000000,
        "furtherInfo": {
            "explanation": "What is 1E+09 divided by one hundred?<br /><br />Rephrase it as: 1E09 / 1E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+07."
        }
    },
    {
        "question": "What is 1E+08 divided by ten million?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 1E+08 divided by ten million?<br /><br />Rephrase it as: 1E08 / 1E07.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 1E+13 divided by three?",
        "writeInAnswer": 3000000000000,
        "furtherInfo": {
            "explanation": "What is 1E+13 divided by three?<br /><br />Rephrase it as: 1E13 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+12."
        }
    },
    {
        "question": "What is 1E+12 divided by thirty thousand?",
        "writeInAnswer": 30000000,
        "furtherInfo": {
            "explanation": "What is 1E+12 divided by thirty thousand?<br /><br />Rephrase it as: 1E12 / 3E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+07."
        }
    },
    {
        "question": "What is 3E+10 divided by three?",
        "writeInAnswer": 10000000000,
        "furtherInfo": {
            "explanation": "What is 3E+10 divided by three?<br /><br />Rephrase it as: 3E10 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+10."
        }
    },
    {
        "question": "What is 1E+13 divided by ten?",
        "writeInAnswer": 1000000000000,
        "furtherInfo": {
            "explanation": "What is 1E+13 divided by ten?<br /><br />Rephrase it as: 1E13 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+12."
        }
    },
    {
        "question": "What is 1E+08 divided by three million?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 1E+08 divided by three million?<br /><br />Rephrase it as: 1E08 / 3E06.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+01."
        }
    },
    {
        "question": "What is 3E+13 divided by three trillion?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 3E+13 divided by three trillion?<br /><br />Rephrase it as: 3E13 / 3E12.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 3E+11 divided by thirty billion?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 3E+11 divided by thirty billion?<br /><br />Rephrase it as: 3E11 / 3E10.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 3E+04 divided by three?",
        "writeInAnswer": 10000,
        "furtherInfo": {
            "explanation": "What is 3E+04 divided by three?<br /><br />Rephrase it as: 3E04 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+04."
        }
    },
    {
        "question": "What is 3E+10 divided by ten thousand?",
        "writeInAnswer": 3000000,
        "furtherInfo": {
            "explanation": "What is 3E+10 divided by ten thousand?<br /><br />Rephrase it as: 3E10 / 1E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+06."
        }
    },
    {
        "question": "What is 3E+05 divided by three thousand?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 3E+05 divided by three thousand?<br /><br />Rephrase it as: 3E05 / 3E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 1E+12 divided by one hundred million?",
        "writeInAnswer": 10000,
        "furtherInfo": {
            "explanation": "What is 1E+12 divided by one hundred million?<br /><br />Rephrase it as: 1E12 / 1E08.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+04."
        }
    },
    {
        "question": "What is 1E+01 divided by three?",
        "writeInAnswer": 3,
        "furtherInfo": {
            "explanation": "What is 1E+01 divided by three?<br /><br />Rephrase it as: 1E01 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
        }
    },
    {
        "question": "What is 1E+11 divided by one billion?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 1E+11 divided by one billion?<br /><br />Rephrase it as: 1E11 / 1E09.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 3E+09 divided by three hundred million?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 3E+09 divided by three hundred million?<br /><br />Rephrase it as: 3E09 / 3E08.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 1E+01 divided by one?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 1E+01 divided by one?<br /><br />Rephrase it as: 1E01 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 1E+07 divided by thirty?",
        "writeInAnswer": 300000,
        "furtherInfo": {
            "explanation": "What is 1E+07 divided by thirty?<br /><br />Rephrase it as: 1E07 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+05."
        }
    },
    {
        "question": "What is 1E+03 divided by one hundred?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 1E+03 divided by one hundred?<br /><br />Rephrase it as: 1E03 / 1E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 1E+10 divided by one million?",
        "writeInAnswer": 10000,
        "furtherInfo": {
            "explanation": "What is 1E+10 divided by one million?<br /><br />Rephrase it as: 1E10 / 1E06.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+04."
        }
    },
    {
        "question": "What is 3E+07 divided by three million?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 3E+07 divided by three million?<br /><br />Rephrase it as: 3E07 / 3E06.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 3E+04 divided by one?",
        "writeInAnswer": 30000,
        "furtherInfo": {
            "explanation": "What is 3E+04 divided by one?<br /><br />Rephrase it as: 3E04 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+04."
        }
    },
    {
        "question": "What is 3E+13 divided by thirty?",
        "writeInAnswer": 1000000000000,
        "furtherInfo": {
            "explanation": "What is 3E+13 divided by thirty?<br /><br />Rephrase it as: 3E13 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+12."
        }
    },
    {
        "question": "What is 3E+11 divided by ten billion?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 3E+11 divided by ten billion?<br /><br />Rephrase it as: 3E11 / 1E10.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
        }
    },
    {
        "question": "What is 1E+10 divided by one hundred million?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 1E+10 divided by one hundred million?<br /><br />Rephrase it as: 1E10 / 1E08.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 3E+08 divided by thirty?",
        "writeInAnswer": 10000000,
        "furtherInfo": {
            "explanation": "What is 3E+08 divided by thirty?<br /><br />Rephrase it as: 3E08 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+07."
        }
    },
    {
        "question": "What is 1E+08 divided by three?",
        "writeInAnswer": 30000000,
        "furtherInfo": {
            "explanation": "What is 1E+08 divided by three?<br /><br />Rephrase it as: 1E08 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+07."
        }
    },
    {
        "question": "What is 3E+06 divided by three hundred thousand?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 3E+06 divided by three hundred thousand?<br /><br />Rephrase it as: 3E06 / 3E05.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 1E+05 divided by thirty thousand?",
        "writeInAnswer": 3,
        "furtherInfo": {
            "explanation": "What is 1E+05 divided by thirty thousand?<br /><br />Rephrase it as: 1E05 / 3E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
        }
    },
    {
        "question": "What is 1E+06 divided by one thousand?",
        "writeInAnswer": 1000,
        "furtherInfo": {
            "explanation": "What is 1E+06 divided by one thousand?<br /><br />Rephrase it as: 1E06 / 1E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
        }
    },
    {
        "question": "What is 1E+09 divided by one thousand?",
        "writeInAnswer": 1000000,
        "furtherInfo": {
            "explanation": "What is 1E+09 divided by one thousand?<br /><br />Rephrase it as: 1E09 / 1E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+06."
        }
    },
    {
        "question": "What is 3E+07 divided by thirty thousand?",
        "writeInAnswer": 1000,
        "furtherInfo": {
            "explanation": "What is 3E+07 divided by thirty thousand?<br /><br />Rephrase it as: 3E07 / 3E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
        }
    },
    {
        "question": "What is 1E+05 divided by thirty?",
        "writeInAnswer": 3000,
        "furtherInfo": {
            "explanation": "What is 1E+05 divided by thirty?<br /><br />Rephrase it as: 1E05 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+03."
        }
    },
    {
        "question": "What is 3E+04 divided by three thousand?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 3E+04 divided by three thousand?<br /><br />Rephrase it as: 3E04 / 3E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 1E+03 divided by ten?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 1E+03 divided by ten?<br /><br />Rephrase it as: 1E03 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 1E+09 divided by one hundred?",
        "writeInAnswer": 10000000,
        "furtherInfo": {
            "explanation": "What is 1E+09 divided by one hundred?<br /><br />Rephrase it as: 1E09 / 1E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+07."
        }
    },
    {
        "question": "What is 1E+07 divided by one million?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 1E+07 divided by one million?<br /><br />Rephrase it as: 1E07 / 1E06.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 1E+12 divided by one hundred million?",
        "writeInAnswer": 10000,
        "furtherInfo": {
            "explanation": "What is 1E+12 divided by one hundred million?<br /><br />Rephrase it as: 1E12 / 1E08.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+04."
        }
    },
    {
        "question": "What is 3E+06 divided by one?",
        "writeInAnswer": 3000000,
        "furtherInfo": {
            "explanation": "What is 3E+06 divided by one?<br /><br />Rephrase it as: 3E06 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+06."
        }
    },
    {
        "question": "What is 3E+05 divided by one hundred?",
        "writeInAnswer": 3000,
        "furtherInfo": {
            "explanation": "What is 3E+05 divided by one hundred?<br /><br />Rephrase it as: 3E05 / 1E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+03."
        }
    },
    {
        "question": "What is 1E+02 divided by ten?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 1E+02 divided by ten?<br /><br />Rephrase it as: 1E02 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 1E+02 divided by three?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 1E+02 divided by three?<br /><br />Rephrase it as: 1E02 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+01."
        }
    },
    {
        "question": "What is 3E+10 divided by one hundred?",
        "writeInAnswer": 300000000,
        "furtherInfo": {
            "explanation": "What is 3E+10 divided by one hundred?<br /><br />Rephrase it as: 3E10 / 1E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+08."
        }
    },
    {
        "question": "What is 1E+12 divided by three thousand?",
        "writeInAnswer": 300000000,
        "furtherInfo": {
            "explanation": "What is 1E+12 divided by three thousand?<br /><br />Rephrase it as: 1E12 / 3E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+08."
        }
    },
    {
        "question": "What is 1E+08 divided by ten?",
        "writeInAnswer": 10000000,
        "furtherInfo": {
            "explanation": "What is 1E+08 divided by ten?<br /><br />Rephrase it as: 1E08 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+07."
        }
    },
    {
        "question": "What is 1E+02 divided by three?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 1E+02 divided by three?<br /><br />Rephrase it as: 1E02 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+01."
        }
    },
    {
        "question": "What is 1E+10 divided by one thousand?",
        "writeInAnswer": 10000000,
        "furtherInfo": {
            "explanation": "What is 1E+10 divided by one thousand?<br /><br />Rephrase it as: 1E10 / 1E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+07."
        }
    },
    {
        "question": "What is 1E+10 divided by one hundred thousand?",
        "writeInAnswer": 100000,
        "furtherInfo": {
            "explanation": "What is 1E+10 divided by one hundred thousand?<br /><br />Rephrase it as: 1E10 / 1E05.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+05."
        }
    },
    {
        "question": "What is 1E+12 divided by three billion?",
        "writeInAnswer": 300,
        "furtherInfo": {
            "explanation": "What is 1E+12 divided by three billion?<br /><br />Rephrase it as: 1E12 / 3E09.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+02."
        }
    },
    {
        "question": "What is 3E+02 divided by one?",
        "writeInAnswer": 300,
        "furtherInfo": {
            "explanation": "What is 3E+02 divided by one?<br /><br />Rephrase it as: 3E02 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+02."
        }
    },
    {
        "question": "What is 3E+05 divided by ten?",
        "writeInAnswer": 30000,
        "furtherInfo": {
            "explanation": "What is 3E+05 divided by ten?<br /><br />Rephrase it as: 3E05 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+04."
        }
    },
    {
        "question": "What is 1E+01 divided by one?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 1E+01 divided by one?<br /><br />Rephrase it as: 1E01 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 3E+10 divided by one million?",
        "writeInAnswer": 30000,
        "furtherInfo": {
            "explanation": "What is 3E+10 divided by one million?<br /><br />Rephrase it as: 3E10 / 1E06.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+04."
        }
    },
    {
        "question": "What is 3E+12 divided by thirty thousand?",
        "writeInAnswer": 100000000,
        "furtherInfo": {
            "explanation": "What is 3E+12 divided by thirty thousand?<br /><br />Rephrase it as: 3E12 / 3E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+08."
        }
    },
    {
        "question": "What is 3E+06 divided by ten thousand?",
        "writeInAnswer": 300,
        "furtherInfo": {
            "explanation": "What is 3E+06 divided by ten thousand?<br /><br />Rephrase it as: 3E06 / 1E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+02."
        }
    },
    {
        "question": "What is 3E+04 divided by one?",
        "writeInAnswer": 30000,
        "furtherInfo": {
            "explanation": "What is 3E+04 divided by one?<br /><br />Rephrase it as: 3E04 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+04."
        }
    },
    {
        "question": "What is 1E+01 divided by one?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 1E+01 divided by one?<br /><br />Rephrase it as: 1E01 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 3E+01 divided by three?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 3E+01 divided by three?<br /><br />Rephrase it as: 3E01 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 1E+09 divided by one thousand?",
        "writeInAnswer": 1000000,
        "furtherInfo": {
            "explanation": "What is 1E+09 divided by one thousand?<br /><br />Rephrase it as: 1E09 / 1E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+06."
        }
    },
    {
        "question": "What is 1E+08 divided by thirty?",
        "writeInAnswer": 3000000,
        "furtherInfo": {
            "explanation": "What is 1E+08 divided by thirty?<br /><br />Rephrase it as: 1E08 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+06."
        }
    },
    {
        "question": "What is 1E+07 divided by one million?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 1E+07 divided by one million?<br /><br />Rephrase it as: 1E07 / 1E06.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 1E+05 divided by thirty thousand?",
        "writeInAnswer": 3,
        "furtherInfo": {
            "explanation": "What is 1E+05 divided by thirty thousand?<br /><br />Rephrase it as: 1E05 / 3E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
        }
    },
    {
        "question": "What is 3E+02 divided by three?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 3E+02 divided by three?<br /><br />Rephrase it as: 3E02 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 1E+12 divided by one hundred billion?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 1E+12 divided by one hundred billion?<br /><br />Rephrase it as: 1E12 / 1E11.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 3E+12 divided by one million?",
        "writeInAnswer": 3000000,
        "furtherInfo": {
            "explanation": "What is 3E+12 divided by one million?<br /><br />Rephrase it as: 3E12 / 1E06.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+06."
        }
    },
    {
        "question": "What is 1E+10 divided by one hundred?",
        "writeInAnswer": 100000000,
        "furtherInfo": {
            "explanation": "What is 1E+10 divided by one hundred?<br /><br />Rephrase it as: 1E10 / 1E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+08."
        }
    },
    {
        "question": "What is 3E+12 divided by one hundred billion?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 3E+12 divided by one hundred billion?<br /><br />Rephrase it as: 3E12 / 1E11.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
        }
    },
    {
        "question": "What is 3E+06 divided by one hundred thousand?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 3E+06 divided by one hundred thousand?<br /><br />Rephrase it as: 3E06 / 1E05.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
        }
    },
    {
        "question": "What is 1E+10 divided by three million?",
        "writeInAnswer": 3000,
        "furtherInfo": {
            "explanation": "What is 1E+10 divided by three million?<br /><br />Rephrase it as: 1E10 / 3E06.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+03."
        }
    },
    {
        "question": "What is 3E+07 divided by thirty thousand?",
        "writeInAnswer": 1000,
        "furtherInfo": {
            "explanation": "What is 3E+07 divided by thirty thousand?<br /><br />Rephrase it as: 3E07 / 3E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
        }
    },
    {
        "question": "What is 3E+12 divided by one thousand?",
        "writeInAnswer": 3000000000,
        "furtherInfo": {
            "explanation": "What is 3E+12 divided by one thousand?<br /><br />Rephrase it as: 3E12 / 1E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+09."
        }
    },
    {
        "question": "What is 1E+03 divided by one?",
        "writeInAnswer": 1000,
        "furtherInfo": {
            "explanation": "What is 1E+03 divided by one?<br /><br />Rephrase it as: 1E03 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
        }
    },
    {
        "question": "What is 3E+05 divided by three thousand?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 3E+05 divided by three thousand?<br /><br />Rephrase it as: 3E05 / 3E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 3E+11 divided by one thousand?",
        "writeInAnswer": 300000000,
        "furtherInfo": {
            "explanation": "What is 3E+11 divided by one thousand?<br /><br />Rephrase it as: 3E11 / 1E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+08."
        }
    },
    {
        "question": "What is 1E+01 divided by one?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 1E+01 divided by one?<br /><br />Rephrase it as: 1E01 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 1E+05 divided by one?",
        "writeInAnswer": 100000,
        "furtherInfo": {
            "explanation": "What is 1E+05 divided by one?<br /><br />Rephrase it as: 1E05 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+05."
        }
    },
    {
        "question": "What is 3E+01 divided by one?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 3E+01 divided by one?<br /><br />Rephrase it as: 3E01 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
        }
    },
    {
        "question": "What is 3E+13 divided by ten thousand?",
        "writeInAnswer": 3000000000,
        "furtherInfo": {
            "explanation": "What is 3E+13 divided by ten thousand?<br /><br />Rephrase it as: 3E13 / 1E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+09."
        }
    },
    {
        "question": "What is 3E+11 divided by three thousand?",
        "writeInAnswer": 100000000,
        "furtherInfo": {
            "explanation": "What is 3E+11 divided by three thousand?<br /><br />Rephrase it as: 3E11 / 3E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+08."
        }
    },
    {
        "question": "What is 3E+06 divided by one hundred thousand?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 3E+06 divided by one hundred thousand?<br /><br />Rephrase it as: 3E06 / 1E05.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
        }
    },
    {
        "question": "What is 3E+03 divided by ten?",
        "writeInAnswer": 300,
        "furtherInfo": {
            "explanation": "What is 3E+03 divided by ten?<br /><br />Rephrase it as: 3E03 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+02."
        }
    },
    {
        "question": "What is 1E+11 divided by three hundred?",
        "writeInAnswer": 300000000,
        "furtherInfo": {
            "explanation": "What is 1E+11 divided by three hundred?<br /><br />Rephrase it as: 1E11 / 3E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+08."
        }
    },
    {
        "question": "What is 1E+13 divided by ten?",
        "writeInAnswer": 1000000000000,
        "furtherInfo": {
            "explanation": "What is 1E+13 divided by ten?<br /><br />Rephrase it as: 1E13 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+12."
        }
    },
    {
        "question": "What is 1E+09 divided by one million?",
        "writeInAnswer": 1000,
        "furtherInfo": {
            "explanation": "What is 1E+09 divided by one million?<br /><br />Rephrase it as: 1E09 / 1E06.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
        }
    },
    {
        "question": "What is 3E+10 divided by three hundred thousand?",
        "writeInAnswer": 100000,
        "furtherInfo": {
            "explanation": "What is 3E+10 divided by three hundred thousand?<br /><br />Rephrase it as: 3E10 / 3E05.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+05."
        }
    },
    {
        "question": "What is 1E+09 divided by thirty?",
        "writeInAnswer": 30000000,
        "furtherInfo": {
            "explanation": "What is 1E+09 divided by thirty?<br /><br />Rephrase it as: 1E09 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+07."
        }
    },
    {
        "question": "What is 1E+04 divided by ten?",
        "writeInAnswer": 1000,
        "furtherInfo": {
            "explanation": "What is 1E+04 divided by ten?<br /><br />Rephrase it as: 1E04 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
        }
    },
    {
        "question": "What is 3E+06 divided by one thousand?",
        "writeInAnswer": 3000,
        "furtherInfo": {
            "explanation": "What is 3E+06 divided by one thousand?<br /><br />Rephrase it as: 3E06 / 1E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+03."
        }
    },
    {
        "question": "What is 3E+12 divided by three billion?",
        "writeInAnswer": 1000,
        "furtherInfo": {
            "explanation": "What is 3E+12 divided by three billion?<br /><br />Rephrase it as: 3E12 / 3E09.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
        }
    },
    {
        "question": "What is 3E+07 divided by ten?",
        "writeInAnswer": 3000000,
        "furtherInfo": {
            "explanation": "What is 3E+07 divided by ten?<br /><br />Rephrase it as: 3E07 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+06."
        }
    },
    {
        "question": "What is 3E+04 divided by one hundred?",
        "writeInAnswer": 300,
        "furtherInfo": {
            "explanation": "What is 3E+04 divided by one hundred?<br /><br />Rephrase it as: 3E04 / 1E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+02."
        }
    },
    {
        "question": "What is 1E+07 divided by three?",
        "writeInAnswer": 3000000,
        "furtherInfo": {
            "explanation": "What is 1E+07 divided by three?<br /><br />Rephrase it as: 1E07 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+06."
        }
    },
    {
        "question": "What is 1E+02 divided by thirty?",
        "writeInAnswer": 3,
        "furtherInfo": {
            "explanation": "What is 1E+02 divided by thirty?<br /><br />Rephrase it as: 1E02 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
        }
    },
    {
        "question": "What is 3E+02 divided by thirty?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 3E+02 divided by thirty?<br /><br />Rephrase it as: 3E02 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 1E+05 divided by one hundred?",
        "writeInAnswer": 1000,
        "furtherInfo": {
            "explanation": "What is 1E+05 divided by one hundred?<br /><br />Rephrase it as: 1E05 / 1E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
        }
    },
    {
        "question": "What is 1E+01 divided by one?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 1E+01 divided by one?<br /><br />Rephrase it as: 1E01 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 1E+09 divided by three?",
        "writeInAnswer": 300000000,
        "furtherInfo": {
            "explanation": "What is 1E+09 divided by three?<br /><br />Rephrase it as: 1E09 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+08."
        }
    },
    {
        "question": "What is 1E+01 divided by three?",
        "writeInAnswer": 3,
        "furtherInfo": {
            "explanation": "What is 1E+01 divided by three?<br /><br />Rephrase it as: 1E01 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
        }
    },
    {
        "question": "What is 3E+01 divided by one?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 3E+01 divided by one?<br /><br />Rephrase it as: 3E01 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
        }
    },
    {
        "question": "What is 1E+12 divided by three million?",
        "writeInAnswer": 300000,
        "furtherInfo": {
            "explanation": "What is 1E+12 divided by three million?<br /><br />Rephrase it as: 1E12 / 3E06.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+05."
        }
    },
    {
        "question": "What is 1E+02 divided by thirty?",
        "writeInAnswer": 3,
        "furtherInfo": {
            "explanation": "What is 1E+02 divided by thirty?<br /><br />Rephrase it as: 1E02 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
        }
    },
    {
        "question": "What is 3E+04 divided by three?",
        "writeInAnswer": 10000,
        "furtherInfo": {
            "explanation": "What is 3E+04 divided by three?<br /><br />Rephrase it as: 3E04 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+04."
        }
    },
    {
        "question": "What is 1E+02 divided by one?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 1E+02 divided by one?<br /><br />Rephrase it as: 1E02 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 3E+05 divided by one thousand?",
        "writeInAnswer": 300,
        "furtherInfo": {
            "explanation": "What is 3E+05 divided by one thousand?<br /><br />Rephrase it as: 3E05 / 1E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+02."
        }
    },
    {
        "question": "What is 3E+11 divided by three?",
        "writeInAnswer": 100000000000,
        "furtherInfo": {
            "explanation": "What is 3E+11 divided by three?<br /><br />Rephrase it as: 3E11 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+11."
        }
    },
    {
        "question": "What is 3E+02 divided by ten?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 3E+02 divided by ten?<br /><br />Rephrase it as: 3E02 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
        }
    },
    {
        "question": "What is 1E+02 divided by thirty?",
        "writeInAnswer": 3,
        "furtherInfo": {
            "explanation": "What is 1E+02 divided by thirty?<br /><br />Rephrase it as: 1E02 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
        }
    },
    {
        "question": "What is 1E+08 divided by one thousand?",
        "writeInAnswer": 100000,
        "furtherInfo": {
            "explanation": "What is 1E+08 divided by one thousand?<br /><br />Rephrase it as: 1E08 / 1E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+05."
        }
    },
    {
        "question": "What is 3E+04 divided by three thousand?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 3E+04 divided by three thousand?<br /><br />Rephrase it as: 3E04 / 3E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 1E+07 divided by three thousand?",
        "writeInAnswer": 3000,
        "furtherInfo": {
            "explanation": "What is 1E+07 divided by three thousand?<br /><br />Rephrase it as: 1E07 / 3E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+03."
        }
    },
    {
        "question": "What is 3E+07 divided by three thousand?",
        "writeInAnswer": 10000,
        "furtherInfo": {
            "explanation": "What is 3E+07 divided by three thousand?<br /><br />Rephrase it as: 3E07 / 3E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+04."
        }
    },
    {
        "question": "What is 1E+04 divided by ten?",
        "writeInAnswer": 1000,
        "furtherInfo": {
            "explanation": "What is 1E+04 divided by ten?<br /><br />Rephrase it as: 1E04 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
        }
    },
    {
        "question": "What is 3E+10 divided by three hundred million?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 3E+10 divided by three hundred million?<br /><br />Rephrase it as: 3E10 / 3E08.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 1E+09 divided by three hundred?",
        "writeInAnswer": 3000000,
        "furtherInfo": {
            "explanation": "What is 1E+09 divided by three hundred?<br /><br />Rephrase it as: 1E09 / 3E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+06."
        }
    },
    {
        "question": "What is 1E+05 divided by thirty?",
        "writeInAnswer": 3000,
        "furtherInfo": {
            "explanation": "What is 1E+05 divided by thirty?<br /><br />Rephrase it as: 1E05 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+03."
        }
    },
    {
        "question": "What is 1E+08 divided by three?",
        "writeInAnswer": 30000000,
        "furtherInfo": {
            "explanation": "What is 1E+08 divided by three?<br /><br />Rephrase it as: 1E08 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+07."
        }
    },
    {
        "question": "What is 3E+13 divided by ten?",
        "writeInAnswer": 3000000000000,
        "furtherInfo": {
            "explanation": "What is 3E+13 divided by ten?<br /><br />Rephrase it as: 3E13 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+12."
        }
    },
    {
        "question": "What is 3E+01 divided by one?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 3E+01 divided by one?<br /><br />Rephrase it as: 3E01 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
        }
    },
    {
        "question": "What is 3E+05 divided by one?",
        "writeInAnswer": 300000,
        "furtherInfo": {
            "explanation": "What is 3E+05 divided by one?<br /><br />Rephrase it as: 3E05 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+05."
        }
    },
    {
        "question": "What is 3E+03 divided by ten?",
        "writeInAnswer": 300,
        "furtherInfo": {
            "explanation": "What is 3E+03 divided by ten?<br /><br />Rephrase it as: 3E03 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+02."
        }
    },
    {
        "question": "What is 3E+08 divided by three hundred?",
        "writeInAnswer": 1000000,
        "furtherInfo": {
            "explanation": "What is 3E+08 divided by three hundred?<br /><br />Rephrase it as: 3E08 / 3E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+06."
        }
    },
    {
        "question": "What is 1E+03 divided by three?",
        "writeInAnswer": 300,
        "furtherInfo": {
            "explanation": "What is 1E+03 divided by three?<br /><br />Rephrase it as: 1E03 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+02."
        }
    },
    {
        "question": "What is 3E+03 divided by ten?",
        "writeInAnswer": 300,
        "furtherInfo": {
            "explanation": "What is 3E+03 divided by ten?<br /><br />Rephrase it as: 3E03 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+02."
        }
    },
    {
        "question": "What is 3E+07 divided by one thousand?",
        "writeInAnswer": 30000,
        "furtherInfo": {
            "explanation": "What is 3E+07 divided by one thousand?<br /><br />Rephrase it as: 3E07 / 1E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+04."
        }
    },
    {
        "question": "What is 1E+05 divided by one?",
        "writeInAnswer": 100000,
        "furtherInfo": {
            "explanation": "What is 1E+05 divided by one?<br /><br />Rephrase it as: 1E05 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+05."
        }
    },
    {
        "question": "What is 3E+13 divided by one thousand?",
        "writeInAnswer": 30000000000,
        "furtherInfo": {
            "explanation": "What is 3E+13 divided by one thousand?<br /><br />Rephrase it as: 3E13 / 1E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+10."
        }
    },
    {
        "question": "What is 1E+10 divided by three million?",
        "writeInAnswer": 3000,
        "furtherInfo": {
            "explanation": "What is 1E+10 divided by three million?<br /><br />Rephrase it as: 1E10 / 3E06.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+03."
        }
    },
    {
        "question": "What is 1E+12 divided by three?",
        "writeInAnswer": 300000000000,
        "furtherInfo": {
            "explanation": "What is 1E+12 divided by three?<br /><br />Rephrase it as: 1E12 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+11."
        }
    },
    {
        "question": "What is 1E+11 divided by thirty?",
        "writeInAnswer": 3000000000,
        "furtherInfo": {
            "explanation": "What is 1E+11 divided by thirty?<br /><br />Rephrase it as: 1E11 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+09."
        }
    },
    {
        "question": "What is 3E+06 divided by ten?",
        "writeInAnswer": 300000,
        "furtherInfo": {
            "explanation": "What is 3E+06 divided by ten?<br /><br />Rephrase it as: 3E06 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+05."
        }
    },
    {
        "question": "What is 1E+06 divided by one thousand?",
        "writeInAnswer": 1000,
        "furtherInfo": {
            "explanation": "What is 1E+06 divided by one thousand?<br /><br />Rephrase it as: 1E06 / 1E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
        }
    },
    {
        "question": "What is 3E+04 divided by three?",
        "writeInAnswer": 10000,
        "furtherInfo": {
            "explanation": "What is 3E+04 divided by three?<br /><br />Rephrase it as: 3E04 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+04."
        }
    },
    {
        "question": "What is 3E+02 divided by ten?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 3E+02 divided by ten?<br /><br />Rephrase it as: 3E02 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
        }
    },
    {
        "question": "What is 3E+07 divided by one hundred?",
        "writeInAnswer": 300000,
        "furtherInfo": {
            "explanation": "What is 3E+07 divided by one hundred?<br /><br />Rephrase it as: 3E07 / 1E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+05."
        }
    },
    {
        "question": "What is 3E+07 divided by three?",
        "writeInAnswer": 10000000,
        "furtherInfo": {
            "explanation": "What is 3E+07 divided by three?<br /><br />Rephrase it as: 3E07 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+07."
        }
    },
    {
        "question": "What is 1E+13 divided by ten?",
        "writeInAnswer": 1000000000000,
        "furtherInfo": {
            "explanation": "What is 1E+13 divided by ten?<br /><br />Rephrase it as: 1E13 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+12."
        }
    },
    {
        "question": "What is 1E+10 divided by one?",
        "writeInAnswer": 10000000000,
        "furtherInfo": {
            "explanation": "What is 1E+10 divided by one?<br /><br />Rephrase it as: 1E10 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+10."
        }
    },
    {
        "question": "What is 3E+07 divided by thirty?",
        "writeInAnswer": 1000000,
        "furtherInfo": {
            "explanation": "What is 3E+07 divided by thirty?<br /><br />Rephrase it as: 3E07 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+06."
        }
    },
    {
        "question": "What is 3E+12 divided by one hundred million?",
        "writeInAnswer": 30000,
        "furtherInfo": {
            "explanation": "What is 3E+12 divided by one hundred million?<br /><br />Rephrase it as: 3E12 / 1E08.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+04."
        }
    },
    {
        "question": "What is 1E+09 divided by three hundred million?",
        "writeInAnswer": 3,
        "furtherInfo": {
            "explanation": "What is 1E+09 divided by three hundred million?<br /><br />Rephrase it as: 1E09 / 3E08.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
        }
    },
    {
        "question": "What is 3E+07 divided by one hundred?",
        "writeInAnswer": 300000,
        "furtherInfo": {
            "explanation": "What is 3E+07 divided by one hundred?<br /><br />Rephrase it as: 3E07 / 1E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+05."
        }
    },
    {
        "question": "What is 3E+10 divided by thirty million?",
        "writeInAnswer": 1000,
        "furtherInfo": {
            "explanation": "What is 3E+10 divided by thirty million?<br /><br />Rephrase it as: 3E10 / 3E07.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
        }
    },
    {
        "question": "What is 3E+04 divided by thirty?",
        "writeInAnswer": 1000,
        "furtherInfo": {
            "explanation": "What is 3E+04 divided by thirty?<br /><br />Rephrase it as: 3E04 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
        }
    },
    {
        "question": "What is 3E+07 divided by three hundred thousand?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 3E+07 divided by three hundred thousand?<br /><br />Rephrase it as: 3E07 / 3E05.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 3E+02 divided by one?",
        "writeInAnswer": 300,
        "furtherInfo": {
            "explanation": "What is 3E+02 divided by one?<br /><br />Rephrase it as: 3E02 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+02."
        }
    },
    {
        "question": "What is 1E+05 divided by one?",
        "writeInAnswer": 100000,
        "furtherInfo": {
            "explanation": "What is 1E+05 divided by one?<br /><br />Rephrase it as: 1E05 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+05."
        }
    },
    {
        "question": "What is 1E+08 divided by one thousand?",
        "writeInAnswer": 100000,
        "furtherInfo": {
            "explanation": "What is 1E+08 divided by one thousand?<br /><br />Rephrase it as: 1E08 / 1E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+05."
        }
    },
    {
        "question": "What is 3E+03 divided by one?",
        "writeInAnswer": 3000,
        "furtherInfo": {
            "explanation": "What is 3E+03 divided by one?<br /><br />Rephrase it as: 3E03 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+03."
        }
    },
    {
        "question": "What is 3E+08 divided by one?",
        "writeInAnswer": 300000000,
        "furtherInfo": {
            "explanation": "What is 3E+08 divided by one?<br /><br />Rephrase it as: 3E08 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+08."
        }
    },
    {
        "question": "What is 1E+01 divided by three?",
        "writeInAnswer": 3,
        "furtherInfo": {
            "explanation": "What is 1E+01 divided by three?<br /><br />Rephrase it as: 1E01 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
        }
    },
    {
        "question": "What is 1E+02 divided by three?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 1E+02 divided by three?<br /><br />Rephrase it as: 1E02 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+01."
        }
    },
    {
        "question": "What is 3E+12 divided by three hundred thousand?",
        "writeInAnswer": 10000000,
        "furtherInfo": {
            "explanation": "What is 3E+12 divided by three hundred thousand?<br /><br />Rephrase it as: 3E12 / 3E05.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+07."
        }
    },
    {
        "question": "What is 1E+10 divided by ten?",
        "writeInAnswer": 1000000000,
        "furtherInfo": {
            "explanation": "What is 1E+10 divided by ten?<br /><br />Rephrase it as: 1E10 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+09."
        }
    },
    {
        "question": "What is 3E+05 divided by thirty?",
        "writeInAnswer": 10000,
        "furtherInfo": {
            "explanation": "What is 3E+05 divided by thirty?<br /><br />Rephrase it as: 3E05 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+04."
        }
    },
    {
        "question": "What is 1E+03 divided by three hundred?",
        "writeInAnswer": 3,
        "furtherInfo": {
            "explanation": "What is 1E+03 divided by three hundred?<br /><br />Rephrase it as: 1E03 / 3E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
        }
    },
    {
        "question": "What is 3E+04 divided by three?",
        "writeInAnswer": 10000,
        "furtherInfo": {
            "explanation": "What is 3E+04 divided by three?<br /><br />Rephrase it as: 3E04 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+04."
        }
    },
    {
        "question": "What is 1E+09 divided by ten thousand?",
        "writeInAnswer": 100000,
        "furtherInfo": {
            "explanation": "What is 1E+09 divided by ten thousand?<br /><br />Rephrase it as: 1E09 / 1E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+05."
        }
    },
    {
        "question": "What is 1E+09 divided by one hundred?",
        "writeInAnswer": 10000000,
        "furtherInfo": {
            "explanation": "What is 1E+09 divided by one hundred?<br /><br />Rephrase it as: 1E09 / 1E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+07."
        }
    },
    {
        "question": "What is 1E+08 divided by three hundred thousand?",
        "writeInAnswer": 300,
        "furtherInfo": {
            "explanation": "What is 1E+08 divided by three hundred thousand?<br /><br />Rephrase it as: 1E08 / 3E05.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+02."
        }
    },
    {
        "question": "What is 3E+12 divided by ten thousand?",
        "writeInAnswer": 300000000,
        "furtherInfo": {
            "explanation": "What is 3E+12 divided by ten thousand?<br /><br />Rephrase it as: 3E12 / 1E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+08."
        }
    },
    {
        "question": "What is 3E+13 divided by one million?",
        "writeInAnswer": 30000000,
        "furtherInfo": {
            "explanation": "What is 3E+13 divided by one million?<br /><br />Rephrase it as: 3E13 / 1E06.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+07."
        }
    },
    {
        "question": "What is 3E+05 divided by ten?",
        "writeInAnswer": 30000,
        "furtherInfo": {
            "explanation": "What is 3E+05 divided by ten?<br /><br />Rephrase it as: 3E05 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+04."
        }
    },
    {
        "question": "What is 3E+02 divided by three?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 3E+02 divided by three?<br /><br />Rephrase it as: 3E02 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 1E+06 divided by one thousand?",
        "writeInAnswer": 1000,
        "furtherInfo": {
            "explanation": "What is 1E+06 divided by one thousand?<br /><br />Rephrase it as: 1E06 / 1E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
        }
    },
    {
        "question": "What is 1E+08 divided by ten?",
        "writeInAnswer": 10000000,
        "furtherInfo": {
            "explanation": "What is 1E+08 divided by ten?<br /><br />Rephrase it as: 1E08 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+07."
        }
    },
    {
        "question": "What is 1E+07 divided by three?",
        "writeInAnswer": 3000000,
        "furtherInfo": {
            "explanation": "What is 1E+07 divided by three?<br /><br />Rephrase it as: 1E07 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+06."
        }
    },
    {
        "question": "What is 3E+12 divided by ten thousand?",
        "writeInAnswer": 300000000,
        "furtherInfo": {
            "explanation": "What is 3E+12 divided by ten thousand?<br /><br />Rephrase it as: 3E12 / 1E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+08."
        }
    },
    {
        "question": "What is 3E+09 divided by three hundred million?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 3E+09 divided by three hundred million?<br /><br />Rephrase it as: 3E09 / 3E08.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 1E+04 divided by one?",
        "writeInAnswer": 10000,
        "furtherInfo": {
            "explanation": "What is 1E+04 divided by one?<br /><br />Rephrase it as: 1E04 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+04."
        }
    },
    {
        "question": "What is 3E+06 divided by ten?",
        "writeInAnswer": 300000,
        "furtherInfo": {
            "explanation": "What is 3E+06 divided by ten?<br /><br />Rephrase it as: 3E06 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+05."
        }
    },
    {
        "question": "What is 3E+11 divided by three hundred million?",
        "writeInAnswer": 1000,
        "furtherInfo": {
            "explanation": "What is 3E+11 divided by three hundred million?<br /><br />Rephrase it as: 3E11 / 3E08.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
        }
    },
    {
        "question": "What is 3E+04 divided by ten?",
        "writeInAnswer": 3000,
        "furtherInfo": {
            "explanation": "What is 3E+04 divided by ten?<br /><br />Rephrase it as: 3E04 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+03."
        }
    },
    {
        "question": "What is 1E+05 divided by three?",
        "writeInAnswer": 30000,
        "furtherInfo": {
            "explanation": "What is 1E+05 divided by three?<br /><br />Rephrase it as: 1E05 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+04."
        }
    },
    {
        "question": "What is 3E+05 divided by thirty thousand?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 3E+05 divided by thirty thousand?<br /><br />Rephrase it as: 3E05 / 3E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 3E+09 divided by ten?",
        "writeInAnswer": 300000000,
        "furtherInfo": {
            "explanation": "What is 3E+09 divided by ten?<br /><br />Rephrase it as: 3E09 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+08."
        }
    },
    {
        "question": "What is 3E+05 divided by ten thousand?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 3E+05 divided by ten thousand?<br /><br />Rephrase it as: 3E05 / 1E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
        }
    },
    {
        "question": "What is 3E+03 divided by one hundred?",
        "writeInAnswer": 30,
        "furtherInfo": {
            "explanation": "What is 3E+03 divided by one hundred?<br /><br />Rephrase it as: 3E03 / 1E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
        }
    },
    {
        "question": "What is 3E+09 divided by three?",
        "writeInAnswer": 1000000000,
        "furtherInfo": {
            "explanation": "What is 3E+09 divided by three?<br /><br />Rephrase it as: 3E09 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+09."
        }
    },
    {
        "question": "What is 1E+06 divided by one hundred thousand?",
        "writeInAnswer": 10,
        "furtherInfo": {
            "explanation": "What is 1E+06 divided by one hundred thousand?<br /><br />Rephrase it as: 1E06 / 1E05.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
        }
    },
    {
        "question": "What is 1E+10 divided by ten?",
        "writeInAnswer": 1000000000,
        "furtherInfo": {
            "explanation": "What is 1E+10 divided by ten?<br /><br />Rephrase it as: 1E10 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+09."
        }
    },
    {
        "question": "What is 1E+04 divided by one hundred?",
        "writeInAnswer": 100,
        "furtherInfo": {
            "explanation": "What is 1E+04 divided by one hundred?<br /><br />Rephrase it as: 1E04 / 1E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
        }
    },
    {
        "question": "What is 1E+08 divided by thirty million?",
        "writeInAnswer": 3,
        "furtherInfo": {
            "explanation": "What is 1E+08 divided by thirty million?<br /><br />Rephrase it as: 1E08 / 3E07.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
        }
    },
    {
        "question": "What is 1E+12 divided by thirty?",
        "writeInAnswer": 30000000000,
        "furtherInfo": {
            "explanation": "What is 1E+12 divided by thirty?<br /><br />Rephrase it as: 1E12 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+10."
        }
    },
    {
        "question": "What is 1E+08 divided by one?",
        "writeInAnswer": 100000000,
        "furtherInfo": {
            "explanation": "What is 1E+08 divided by one?<br /><br />Rephrase it as: 1E08 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+08."
        }
    },
    {
        "question": "What is 3E+06 divided by thirty?",
        "writeInAnswer": 100000,
        "furtherInfo": {
            "explanation": "What is 3E+06 divided by thirty?<br /><br />Rephrase it as: 3E06 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+05."
        }
    },
    {
        "question": "What is 3E+07 divided by ten thousand?",
        "writeInAnswer": 3000,
        "furtherInfo": {
            "explanation": "What is 3E+07 divided by ten thousand?<br /><br />Rephrase it as: 3E07 / 1E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+03."
        }
    },
    {
        "question": "What is 1E+08 divided by one thousand?",
        "writeInAnswer": 100000,
        "furtherInfo": {
            "explanation": "What is 1E+08 divided by one thousand?<br /><br />Rephrase it as: 1E08 / 1E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+05."
        }
    },
    {
        "question": "What is 3E+07 divided by one thousand?",
        "writeInAnswer": 30000,
        "furtherInfo": {
            "explanation": "What is 3E+07 divided by one thousand?<br /><br />Rephrase it as: 3E07 / 1E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+04."
        }
    },
    {
        "question": "What is 1E+08 divided by one hundred?",
        "writeInAnswer": 1000000,
        "furtherInfo": {
            "explanation": "What is 1E+08 divided by one hundred?<br /><br />Rephrase it as: 1E08 / 1E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+06."
        }
    },
    {
        "question": "What is 3E+03 divided by one?",
        "writeInAnswer": 3000,
        "furtherInfo": {
            "explanation": "What is 3E+03 divided by one?<br /><br />Rephrase it as: 3E03 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+03."
        }
    }
  ],
};

export default wellRoundedDivision5;