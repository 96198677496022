// *** DO NOT EDIT! This file was automatically generated by <repo-root>/tools/tenThousandOrNot. View the README file in that directory for build instructions.

const oneThousandOrNot = {
  id: "oneThousandOrNot",
  name: 'One Thousand Or Not?',
  linkTo: "/quizzes/oneThousandOrNot", 
  timeLimit: 90, // seconds
  countdownTime: 3, // seconds
  randomize: true,
  challenge: true,
  explainerOn: true,
  description: `How fast can you spot: what equals ONE THOUSAND, and what does NOT?`,
  questions: [
  
    
    {
      question: `
 Does this equal one thousand?
<br />
<b> 10 * 10 * 10
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one thousand?
<br />
<b> 1,000 * 1
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one thousand?
<br />
<b> 100 * 10
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one thousand?
<br />
<b> 10 * 100
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one thousand?
<br />
<b> 1 * 1,000
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one thousand?
<br />
<b> 10,000 / 10
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one thousand?
<br />
<b> 10<sup>4</sup> / 10<sup>1</sup>
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one thousand?
<br />
<b> 10<sup>3</sup> / 10<sup>0</sup>
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one thousand?
<br />
<b> 100,000 / 100
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one thousand?
<br />
<b> 57,000 / 57
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one thousand?
<br />
<b> 573,000 / 573
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one thousand?
<br />
<b> 5 * 200
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one thousand?
<br />
<b> √(1000) * √(1000)
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one thousand?
<br />
<b> a million divided by 1,000
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one thousand?
<br />
<b> 10<sup>6</sup> / 10<sup>3</sup>
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one thousand?
<br />
<b> 1 billion / 1 million
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one thousand?
<br />
<b> 10<sup>9</sup> / 10<sup>6</sup>
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one thousand?
<br />
<b> a trillion divided by a billion
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one thousand?
<br />
<b> 10<sup>12</sup> / 10<sup>9</sup>
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one thousand?
<br />
<b> a quadrillion divided by a trillion
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one thousand?
<br />
<b> 10<sup>15</sup> / 10<sup>12</sup>
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one thousand?
<br />
<b> 10<sup>300</sup> / 10<sup>297</sup>
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one thousand?
<br />
<b> 1,000,000 - 999,000
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one thousand?
<br />
<b> 7 * 1,000 / 7
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one thousand?
<br />
<b> 100 * 10<sup>2</sup> / 10
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one thousand?
<br />
<b> 6 / 0.006
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one thousand?
<br />
<b> 9,870,000 / 9,870
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one thousand?
<br />
<b> 10,286 / 10.286
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one thousand?
<br />
<b> (seconds in 900 minutes) / (seconds in 0.9 minutes)
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one thousand?
<br />
<b> 3 * 10<sup>(-1)</sup> / 0.0003
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one thousand?
<br />
<b> 550,000 / 50 - 10,000
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one thousand?
<br />
<b> 250,000 * 40 / 10<sup>4</sup>
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one thousand?
<br />
<b> 10<sup>99</sup> / 10<sup>96</sup>
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one thousand?
<br />
<b> 10 * 3 * 10 * 3 * 10 / (10 - 1)
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one thousand?
<br />
<b> 6,000 * 30 / (9 * 20)
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one thousand?
<br />
<b> 0.8 / 0.0008
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one thousand?
<br />
<b> (630 / 63) * 10<sup>2</sup>
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one thousand?
<br />
<b> (50 – 40) * 100
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one thousand?
<br />
<b> (2,000 / 10) * (1,000 / 200)
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one thousand?
<br />
<b> 40 * 60 * 10<sup>2</sup> / 240
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one thousand?
<br />
<b> 1 * 10 * 100 * 1,000 * 0.001
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one thousand?
<br />
<b> 650 + (3500 / 10<sup>1</sup>)
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one thousand?
<br />
<b> 1 Trillion / 10<sup>9</sup>
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one thousand?
<br />
<b> (1000 – 300) * 10 / 7
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one thousand?
<br />
<b> (36 * 1,000) / (36 * 100) * 100
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one thousand?
<br />
<b> (290 Million – 285 Million) / 5 Thousand
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one thousand?
<br />
<b> 40 * 10 + 20 * 30
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one thousand?
<br />
<b> 5<sup>3</sup> * 2<sup>3</sup>
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one thousand?
<br />
<b> (123 / 1.23) * (10<sup>0</sup> + 3<sup>2</sup>)
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one thousand?
<br />
<b> 10<sup>(-6)</sup> / 10<sup>(-9)</sup>
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one thousand?
<br />
<b> (Half of 700 * 100) / 35
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one thousand?
<br />
<b> 7,200 / 7.2
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one thousand?
<br />
<b> 7.5 / 0.0075
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one thousand?
<br />
<b> 900 + 99 + 1
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one thousand?
<br />
<b> 10<sup>(3 * 3)</sup> * 10<sup>(2 * -3)</sup>
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one thousand?
<br />
<b> (1 Million + 2 Million) / 3 Thousand
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one thousand?
<br />
<b> 325,042 / 325.042
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one thousand?
<br />
<b> (5 * 10<sup>6</sup> – 4 * 10<sup>6</sup>) * 0.001
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one thousand?
<br />
<b> (6,000 * 30) / 180
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one thousand?
<br />
<b> 1,989 – (10<sup>3</sup> - 11)
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one thousand?
<br />
<b> (10<sup>3</sup> / 50) * 50
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one thousand?
<br />
<b> 10 Billion / (1 Hundred * 100 Thousand)
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one thousand?
<br />
<b> 100 Trillion / 100 Billion
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one thousand?
<br />
<b> 999 Billion / 999 Million
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one thousand?
<br />
<b> 3 Quadrillion * 10<sup>3</sup> / 3 Quadrillion
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one thousand?
<br />
<b> (10<sup>6</sup>)<sup>(1/2)</sup>
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one thousand?
<br />
<b> 10 * 10 * 10 * 10
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 1, // No
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one thousand?
<br />
<b> 100<sup>2</sup>
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 1, // No
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one thousand?
<br />
<b> 1 Billion / 10 Million
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 1, // No
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one thousand?
<br />
<b> 1 Billion / 100 Thousand
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 1, // No
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one thousand?
<br />
<b> 70 * 1,000 / 700
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 1, // No
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one thousand?
<br />
<b> 10<sup>12</sup> / (10<sup>4</sup> * 10<sup>2</sup>)
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 1, // No
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one thousand?
<br />
<b> 10<sup>9</sup> / 10<sup>(-6)</sup>
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 1, // No
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one thousand?
<br />
<b> 1,000,000 - 99,000
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 1, // No
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one thousand?
<br />
<b> 60 / 0.006
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 1, // No
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one thousand?
<br />
<b> (29 Million – 28 Million) / 10 Thousand
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 1, // No
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one thousand?
<br />
<b> (9,000 * 4,000) / (3.6 * 10<sup>3</sup>)
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 1, // No
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one thousand?
<br />
<b> (Half of 700 * 100) / 70
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 1, // No
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one thousand?
<br />
<b> (10<sup>6</sup> / 10<sup>4</sup>) / 10<sup>0</sup>
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 1, // No
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one thousand?
<br />
<b> (10<sup>6</sup>)<sup>(-1/2)</sup>
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 1, // No
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one thousand?
<br />
<b> (50,000 / 50) * 10<sup>1</sup>
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 1, // No
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one thousand?
<br />
<b> (4.9 Million / 49 Thousand) * 10<sup>2</sup>
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 1, // No
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one thousand?
<br />
<b> 1,250,000 / 0.125
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 1, // No
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one thousand?
<br />
<b> 10,286 / 102.86
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 1, // No
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one thousand?
<br />
<b> 1 * 10 * 100 * 1,000 * 10<sup>(-2)</sup>
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 1, // No
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one thousand?
<br />
<b> 1 billion / 10<sup>3</sup>
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 1, // No
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one thousand?
<br />
<b> 10<sup>55</sup> * 10<sup>(-60)</sup> * 10<sup>11</sup>
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 1, // No
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one thousand?
<br />
<b> 10<sup>1</sup> * 10<sup>3</sup>
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 1, // No
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one thousand?
<br />
<b> 55,000 / 50
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 1, // No
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one thousand?
<br />
<b> 1,010,000 - 1,001,000
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 1, // No
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one thousand?
<br />
<b> 100<sup>2</sup> * 100<sup>2</sup> / 100<sup>2</sup>
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 1, // No
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one thousand?
<br />
<b> 3.6 million – 3.59 million
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 1, // No
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one thousand?
<br />
<b> (10<sup>3</sup> + 10<sup>3</sup>) / 10<sup>3</sup>
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 1, // No
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one thousand?
<br />
<b> 6,000 * 5,000 / 3,000
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 1, // No
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one thousand?
<br />
<b> (100<sup>2</sup> + 10<sup>4</sup>) / 2
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 1, // No
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one thousand?
<br />
<b> 75,000 * 10<sup>(-1)</sup> / 75
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 1, // No
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one thousand?
<br />
<b> 10<sup>2</sup> + 10<sup>1</sup>
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 1, // No
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one thousand?
<br />
<b> 10<sup>0</sup> * 10<sup>(-3)</sup> * (10<sup>3</sup>)<sup>3</sup>
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 1, // No
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one thousand?
<br />
<b> 2.5 * 10<sup>3</sup> * 10<sup>0</sup> * 4
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 1, // No
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one thousand?
<br />
<b> 7.354 * (10<sup>0</sup> / 7.354) * 100<sup>2</sup>
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 1, // No
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
  ],

};

export default oneThousandOrNot;
