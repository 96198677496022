 const unitedStatesPoverty = {
    name: 'So you think you know U.S. poverty?',
    id: 'VZslyB15gy9K9brQUdzw',
    linkTo: "/quizzes/unitedStatesPoverty",
    explainerOn: true,
    description: "With the pandemic taking an enormous economic toll, poverty has been in the news more than ever. Setting aside buzzwords and headlines, do you know the truth about poverty in the U.S.?",
    questions: [
        {
            //question 1//
            question: "What percentage of U.S. Americans either live in poverty or are one traffic ticket (or other unforeseen problem) away from falling into poverty?",
            choices: ['13%', '23%', '33%', '43%'],
            answerIdx: 3,    
            furtherInfo: {
                explanation: (
                    `43% of U.S. Americans either live in poverty or are one unforeseen expense away from falling into poverty.`
                ),
                sources: [
                    {
                        source: 'The Guardian',
                        url: 'https://www.theguardian.com/environment/2021/apr/28/our-unequal-earth-food-insecurity-aid-corporate',
                    },
                ],
            },
        },
        {
            //question 2//
           question: "How many families in the U.S. cannot afford enough nutritious food?",
            choices: ['1 in 8', '1 in 80', '1 in 800', '1 in 8,000'],
            answerIdx: 0,    
            furtherInfo: {
                explanation: (
                    `1 in 8 families in the U.S. cannot afford enough nutritious food.`
                ),
                sources: [
                    {
                        source: 'The Guardian',
                        url: 'https://www.theguardian.com/environment/2021/apr/28/our-unequal-earth-food-insecurity-aid-corporate',
                    },
                ],
            },
        },
        {
            //question 3//
            question: "In a recession, how many families experience food insecurity?",
            choices: ['1 in 5', '1 in 10', '1 in 15', '1 in 20'],
            answerIdx: 0,    
            furtherInfo: {
                explanation: (
                    `During a recession 1 in 5 families experience food insecurity.`
                ),
                sources: [
                    {
                        source: 'The Guardian',
                        url: 'https://www.theguardian.com/environment/2021/apr/28/our-unequal-earth-food-insecurity-aid-corporate',
                    },
                ],
            },
        },
        {
            //question 4//
            question: "Food insecurity can cause lifelong damage to not only a child’s health, but what else?",
            choices: ['Happiness', 'Emotional well being and self-control', 'Education and employment potential', 'Physical fitness'],
            answerIdx: 2,    
            furtherInfo: {
                explanation: (
                    `Food insecurity in childhood can not only damage a child’s health but their education and employment potential.`
                ),
                sources: [
                    {
                        source: 'The Guardian',
                        url: 'https://www.theguardian.com/environment/2021/apr/28/our-unequal-earth-food-insecurity-aid-corporate',
                    },
                ],
            },
        },
        {
            //question 5//
            question: " _________ of all food-insecure people are not considered ‘poor enough’ to receive government aid.",
            choices: ['Half', 'One third', 'One fourth', 'One fifth'],
            answerIdx: 1,    
            furtherInfo: {
                explanation: (
                    `One third of all food-insecure people are not considered ‘poor enough’ to receive government aid.`
                ),
                sources: [
                    {
                        source: 'The Guardian',
                        url: 'https://www.theguardian.com/environment/2021/apr/28/our-unequal-earth-food-insecurity-aid-corporate',
                    },
                ],
            },
        },
        {
            //question 6//
            question: "Before the pandemic, what percentage of families receiving food stamps had at least one employed member?",
            choices: ['20%', '40%', '60%', '80%'],
            answerIdx: 3,    
            furtherInfo: {
                explanation: (
                    `Before the 2020 pandemic, 80% of families receiving food stamps had at least one employed member.`
                ),
                sources: [
                    {
                        source: 'The Guardian',
                        url: 'https://www.theguardian.com/environment/2021/apr/28/our-unequal-earth-food-insecurity-aid-corporate',
                    },
                ],
            },
        },
        {
            //question 7//
            question: "Before the pandemic, what percentage of families receiving food stamps had at least two employed members?",
            choices: ['11%', '31%', '51%', '71%'],
            answerIdx: 1,    
            furtherInfo: {
                explanation: (
                    `Before the 2020 pandemic, 31% of families receiving food stamps had at least two employed members.`
                ),
                sources: [
                    {
                        source: 'The Guardian',
                        url: 'https://www.theguardian.com/environment/2021/apr/28/our-unequal-earth-food-insecurity-aid-corporate',
                    },
                ],
            },
        },
        {
            //question 8//
            question: "True or False: Keeping wages so low that employees need food stamps to survive means that corporations reap huge profits while costing taxpayers billions in welfare programs.",
            choices: ['True', 'False'],
            answerIdx: 0,    
            furtherInfo: {
                explanation: (
                    `This is true. Keeping wages so low employees need food stamps to survive allows corporations to keep huge profit margins while costing taxpayers billions in social programs.`
                ),
                sources: [
                    {
                        source: 'The Guardian',
                        url: 'https://www.theguardian.com/environment/2021/apr/28/our-unequal-earth-food-insecurity-aid-corporate',
                    },
                ],
            },
        },
        {
            //question 9//
            question: "Research shows that full-time workers earning $15/ hour can afford a two bedroom apartment in _________ states.",
            choices: ['Only 4', 'Only 13', 'Only 20', 'All 50'],
            answerIdx: 0,    
            furtherInfo: {
                explanation: (
                    `Research shows that full-time workers earning $15/hour can afford a two bedroom apartment in only 4 states.`
                ),
                sources: [
                    {
                        source: 'The Guardian',
                        url: 'https://www.theguardian.com/environment/2021/apr/28/our-unequal-earth-food-insecurity-aid-corporate',
                    },
                ],
            },
        },
    ],

};

export default unitedStatesPoverty;