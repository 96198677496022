 const infinitePlastics = {
    name: 'Infinite Plastics',
    id: 'LqEBnCm1WCC9J9nDThIv',
    linkTo: "/quizzes/infinitePlastics",
    explainerOn: true,
    description: 'Plastics permeate every aspect of our world now, and the majority of all the plastic ever produced remains in our ecosystem today. It is cheap to produce, durable, resistant to rust and erosion, and remarkably useful. In what has been dubbed “the next grand challenge for polymer chemistry” there is an emerging industry looking to create a circular plastic economy. But exactly how much plastic already exists to feed into this new system?',
    questions: [
        {
            //question 1//
            question: "What percentage of all plastic produced to date has been recycled?",
            choices: ['0.9%', '9%', '29%', '59%'],
            answerIdx: 1,    
            furtherInfo: {
                explanation: (
                    `Just 9% of all plastic produced to date has been recycled.`
                ),
                sources: [
                    {
                        source: "BBC. The world's first 'infinite' plastic. BBC Future.",
                        url: 'https://www.bbc.com/future/article/20210510-how-to-recycle-any-plastic',
                    },
                ],
            },
        },
        {
            //question 2//
            question: "How many metric tons of plastic have been produced to date?",
            choices: ['830,000', '8,300,000', '830,000,000', '8,300,000,000'],
            answerIdx: 3,    
            furtherInfo: {
                explanation: (
                    `8.3 billion metric tons of plastic have been produced to date, that’s over 18 trillion pounds.`
                ),
                sources: [
                    {
                        source: "BBC. The world's first 'infinite' plastic. BBC Future.",
                        url: 'https://www.bbc.com/future/article/20210510-how-to-recycle-any-plastic',
                    },
                ],
            },
        },
        {
            //question 3//
            question: "How many metric tons of plastic are produced annually?",
            choices: ['380,000', '3,800,000', '38,000,000', '380,000,000'],
            answerIdx: 3,    
            furtherInfo: {
                explanation: (
                    `380 million metric tons are produced annually. That’s 100 times the weight of the entire blue whale population.`
                ),
                sources: [
                    {
                        source: "BBC. The world's first 'infinite' plastic. BBC Future.",
                        url: 'https://www.bbc.com/future/article/20210510-how-to-recycle-any-plastic',
                    },
                ],
            },
        },
        {
            //question 4//
            question: "How many metric tons of plastic end up as waste products annually?",
            choices: ['26,000', '260,000', '260,000,000', '260,000,000,000'],
            answerIdx: 2,    
            furtherInfo: {
                explanation: (
                    `260 million metric tons of plastic waste are produced annually; that’s 550 billion pounds.`
                ),
                sources: [
                    {
                        source: "McKinsey & Company Consulting",
                        url: 'https://www.mckinsey.com/industries/chemicals/our-insights/no-time-to-waste-what-plastics-recycling-could-offer#',
                    },
                ],
            },
        },
        {
            //question 5//
            question: "Of those 260 million tons of plastic waste, approximately how many tons are recycled into new plastics annually?",
            choices: ['41,600', '416,000', '4,160,000', '41,600,000'],
            answerIdx: 3,    
            furtherInfo: {
                explanation: (
                    `41.6 million metric tons of plastic waste, just 16% of the total plastic waste produced, are recycled into new plastics.`
                ),
                sources: [
                    {
                        source: "BBC. The world's first 'infinite' plastic. BBC Future.",
                        url: 'https://www.bbc.com/future/article/20210510-how-to-recycle-any-plastic',
                    },
                ],
            },
        },
        {
            //question 6//
            question: "How many metric tons of plastic waste go to landfills annually?",
            choices: ['10,400', '104,000', '10,400,000', '104,000,000'],
            answerIdx: 3,    
            furtherInfo: {
                explanation: (
                    `104 million metric tons of plastic waste is sent to landfills every year, which is 40% of the annual production of plastic waste.`
                ),
                sources: [
                    {
                        source: "BBC. The world's first 'infinite' plastic. BBC Future.",
                        url: 'https://www.bbc.com/future/article/20210510-how-to-recycle-any-plastic',
                    },
                ],
            },
        },
        {
            //question 7//
            question: "How many metric tons of plastic are incinerated annually?",
            choices: ['650,000', '65,000,000', '650,000,000', '6,500,000,000'],
            answerIdx: 1,    
            furtherInfo: {
                explanation: (
                    `65 million metric tons of plastic waste, 25% of the world’s annual plastic waste, is incinerated every year.`
                ),
                sources: [
                    {
                        source: "BBC. The world's first 'infinite' plastic. BBC Future.",
                        url: 'https://www.bbc.com/future/article/20210510-how-to-recycle-any-plastic',
                    },
                ],
            },
        },
        {
            //question 8//
            question: "How many tons of plastic waste are dumped annually?",
            choices: ['494,000', '4,940,000', '49,400,000', '494,000,000'],
            answerIdx: 2,    
            furtherInfo: {
                explanation: (
                    `49.4 million metric tons of plastic waste are dumped, which is 19% of the world’s plastic annual waste, and 3% more than is recycled.`
                ),
                sources: [
                    {
                        source: "BBC. The world's first 'infinite' plastic. BBC Future.",
                        url: 'https://www.bbc.com/future/article/20210510-how-to-recycle-any-plastic',
                    },
                ],
            },
        },
        {
            //question 9//
            question: "At our current rate, how many metric tons of plastic are estimated to be in landfills by the year 2050?",
            choices: ['1,200', '12,000', '120,000', '1,200,000'],
            answerIdx: 1,    
            furtherInfo: {
                explanation: (
                    `An estimated 12,000 metric tons of plastic waste will be in landfills by 2050.`
                ),
                sources: [
                    {
                        source: "BBC. The world's first 'infinite' plastic. BBC Future.",
                        url: 'https://www.bbc.com/future/article/20210510-how-to-recycle-any-plastic',
                    },
                ],
            },
        },
        {
            //question 10//
            question: "What is the conversion rate of hydrothermal chemical recycling, a new method for recycling plastics?",
            choices: ['29%', '59%', '79%', '99%'],
            answerIdx: 3,    
            furtherInfo: {
                explanation: (
                    `One of the most efficient of the new chemical recycling processes, the conversion rate of hydrothermal chemical recycling is an astounding 99% for all types of plastic without the degradation in quality that is a hallmark of traditional plastic recycling methods.`
                ),
                sources: [
                    {
                        source: "BBC. The world's first 'infinite' plastic. BBC Future.",
                        url: 'https://www.bbc.com/future/article/20210510-how-to-recycle-any-plastic',
                    },
                ],
            },
        },
    ],

};

export default infinitePlastics;