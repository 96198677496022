const slimeTime = { 
  name: "Slime Time", 
  id: 'zMlwfkJ9JFXrQ6KeRjJg', 
  linkTo: "/quizzes/slimeTime", 
  explainerOn: true, 
  subheading: `Slime: Nature made it first and best. Test your knowledge of all things slimy.`,
  description: `Have you ever made slime out of glue? The slimiest things weren't made in a bowl at your kitchen table or even laboratory. The slimiest things were made by nature. How much do <i>you</i> know about slime?`, 
  questions: [
      { // Q-1
          question: "You may not know it, but your body produces slime of its own in the form of snot! What does snot do?",
          choices: ['Protects us from dust', 'Protects us from pollen', 'Protects us from viruses', 'All of the above'],
          answerIdx: 3,
          furtherInfo: {
              explanation: (
                  `All of the above! The snot in your nose isn't just for making boogers, it protects you from dust, pollen, and even viruses that might try to make their way into your body through your nose.`
              ),
              sources: [
                  {
                      source: "SciShow" ,
                      url: 'https://youtu.be/4T6qazQp_1g',
                  },
              ],
          },
      },
      { // Q-2
          question: "What is the slimiest creature of all time?",
          choices: ['The Hagfish', 'The Gray Tree Frog', 'The Black Spotted Eel', 'The Cave Salamander'],
          answerIdx: 0,    
          furtherInfo: {
              explanation: (
                  `Most slime experts agree: the slimiest creature of all time is the hagfish! Hagfish slime can expand up to 10,000 times its original size and the more you struggle to get out of it, the more viscous it becomes.`
              ),
              sources: [
                {
                  source: "Popular Science" ,
                  url: 'https://www.popsci.com/hagfish-slime-expands/',
                },
              ],
          },
      },
      { // Q-3 
        question: "In nature, slime is made mostly out of proteins called mucins and lots and lots of water. How much of hagfish slime is actually seawater?",
        choices: ['50.443%', '75.221%', '99.996%', '100%'],
        answerIdx: 2,    
        furtherInfo: {
            explanation: (
                `Hagfish slime is 99.996% seawater, that means that hagfish can make a truly prodigious amount of slime while only producing a tiny amount of mucins.`
            ),
            sources: [
              {
                source: "SciShow" ,
                url: 'https://youtu.be/4T6qazQp_1g',
              },
            ],
        },
      },
      { // Q-4
        question: "You'd think being the slimiest creature ever would cause some problems for the hagfish, but they have an unusual way of escaping their own slime? What is it?",
        choices: ['Hagfish are immune to the effects of their own slime.', 'Hagfish tie themselves in knots and scrape the slime off.', 'Hagfish rub themselves against rocks to get rid of the slime', 'Tiny, slime eating parasites make their homes on the back of hagfish.'],
        answerIdx: 1,    
        furtherInfo: {
            explanation: (
                `To avoid getting stuck in their own slime, hagfish literally tie themselves in a knot and scrape the slime off their own body.`
            ),
            sources: [
              {
                source: "SciShow" ,
                url: 'https://youtu.be/4T6qazQp_1g',
              },
            ],
        },
      },
      { // Q-5
        question: "Violet Sea Snails use slime for what unusual purpose?",
        choices: ['To gross out fish.', 'To distract predators.', 'To mate with other snails.', 'To float on the surface of the ocean.'],
        answerIdx: 3,    
        furtherInfo: {
            explanation: (
                `Violet Sea Snails are pelagic, which means they live on the surface of the ocean, which they're able to do by making slime boats and hanging upside down from them.`
            ),
            sources: [
              {
                source: "SciShow" ,
                url: 'https://youtu.be/4T6qazQp_1g',
              },
            ],
        },
      },
      { // Q-6
        question: "The Giant Larvacean is famous for what slimy feature?",
        choices: ['Its slimy tail.', 'The slimy eggs it lays.', 'Its giant snot palace.', 'Its long snot trails.'],
        answerIdx: 2,    
        furtherInfo: {
            explanation: (
                `The Giant Larvacean is a small sea creature that is famous for the giant snot palaces they make to live in. Each snot palace is an elaborate maze of chambers and corridors that the larvacean uses to filter out food from sea water. The snot palace is so complex that the movement of the water through the chambers hides the actual location of the larvacean inside.`
            ),
            sources: [
              {
                source: "SciShow" ,
                url: 'https://youtu.be/4T6qazQp_1g',
              },
            ],
        },
      },
      { // Q-7
        question: "A Giant Larvacean's snot palace can be up to 1 meter wide, while the animal itself is about 10 centimeters long. How many times bigger is its snot palace than the larvacean, itself?",
        choices: ['10x', '20x', '40x', '80x'],
        answerIdx: 0,    
        furtherInfo: {
            explanation: (
                `The snot palace is 10 times larger than the larvacean inside. There are 100 centimeters in a meter, so if you divide 100 centimeters by 10 centimeters, you find that the snot palace is 10 times larger. Or, if you like, that the larvacean is 10 times smaller.`
            ),
            sources: [
              {
                source: "SciShow" ,
                url: 'https://youtu.be/4T6qazQp_1g',
              },
            ],
        },
      },
      { // Q-8
        question: "Molds don't just come in the fuzzy variety at the back of the fridge, there are also several species of slime molds. What's the real name of one species of slime mold?",
        choices: ['Super Slimy Slime Mold', 'Dog Vomit Slime Mold', 'Do Not Touch Slime Mold', 'Purple Poison Slime Mold'],
        answerIdx: 1,    
        furtherInfo: {
            explanation: (
                `The Dog Vomit Slime Mold is a real species of slime mold! This slime mold is bright yellow, slimy and, well, looks like dog vomit.`
            ),
            sources: [
              {
                source: "SciShow" ,
                url: 'https://youtu.be/4T6qazQp_1g',
              },
            ],
        },
      },
      { // Q-9
        question: "What is octopus ink made out of?",
        choices: ['Melanin and Mucus', 'Ink', 'Feces and Mucus', 'Plant Particles and Mucus'],
        answerIdx: 0,    
        furtherInfo: {
            explanation: (
                `Octopus ink is made out of melanin, which is the same thing that gives your skin color, and, of course, mucus. The slimy nature of this ink helps it stay put and not be immediately diluted in the surrounding seawater.`
            ),
            sources: [
              {
                source: "SciShow" ,
                url: 'https://youtu.be/4T6qazQp_1g',
              },
            ],
        },
      },
      { // Q-10
        question: "Some octopus ink is even phagomemetic, which means it:",
        choices: ['Remembers previous shapes', 'Restrains predators', 'Is poisonous', 'Tastes like food'],
        answerIdx: 3,    
        furtherInfo: {
            explanation: (
                `Some octopus ink is phagomemetic, which means it tastes like food. The octopus can expel its ink and trick a predator into eating some tasty slime while the octopus swims away.`
            ),
            sources: [
              {
                source: "SciShow" ,
                url: 'https://youtu.be/4T6qazQp_1g',
              },
            ],
        },
      },
  ],

};

export default slimeTime;