 const timeZones = {
    id: 'timeZones',
    name: 'Time Zone Conversions',
    linkTo: "/quizzes/timeZones",
    explainerOn: true,
    randomize: true,
    quizLengthSpec: 8,
    subheading: 'A quiz for the international jet set and the WFH Zoom set.',
    description: `<strong>What time is it, really?</strong> Scientists and weather observatories use a universal time scale called Universal Time Coordinated (UTC), based on the 0° longitude meridian located in Greenwich, England. The local time anywhere in the world can be represented as UTC + (plus) or - (minus) a certain number of hours (increasing in either direction, the farther you get from Greenwich). All possible time zones fall across the spectrum of a 24 hour day, from UTC -12 to UTC +12, with UTC itself in the middle, like zero. New York, for example, is UTC -5, whereas Moscow is UTC +3. So, what time is it?`,
    questions: [
        { // Q-1
            question: "If a flight departs Miami, Florida (UTC -5), at 11 AM local time, and lasts for 6 hours, what will the local time be when it lands in Los Angeles, California (UTC -8)?",
            choices: ['2 PM', '5 PM', '8 PM', '11 PM'],
            answerIdx: 0,
            furtherInfo: {
                explanation: (
                    `A 6 hour flight leaving from Miami at 11 AM local time will land in Los Angeles at 2 PM local time. (UTC -8 - UTC -5 = -3 hrs. 6 hrs (flight time) -3 hrs = +3 hrs. The flight would land at (11 AM + 3 hrs =) 2 PM Los Angeles local time.)`
                ),
                // sources: [ { source: "", url: '' } ],
            },
        },
        { // Q-2
            question: "Majid in London (UTC +0) has requested a call at 6 PM, his local time. What would the local time need to be in Islamabad, Pakistan (UTC +5), in order to accommodate his schedule?",
            choices: ['1 AM', '11 AM', '1 PM', '11 PM'],
            answerIdx: 3,
            furtherInfo: {
                explanation: `To meet at Majid's preferred time of 6 PM in London, England, the meeting would have to take place at 11 PM in Islamabad, Pakistan. (6 PM + 5 hrs = 11 PM)`,
                // sources: [ { source: "", url: '' } ],
            },
        },
        { // Q-3
            question: "Alex (9:50 PM local time) wants to call Jim (1-hour behind). Jim doesn't want to be disturbed after 9 PM, his local time. Can Alex call Jim at this hour?",
            choices: ['Yeah, he can', `No, doesn't look good`],
            answerIdx: 0,    
            furtherInfo: {
                explanation: `It's cutting it a bit close, but since 9:50 PM - 1hr = 8:50 PM, Alex can reach Jim without disturbing him after his local time of 9 PM.`,
                // sources: [ { source: "", url: '' } ],
            },
        },
        { // Q-4
            question: "5 PM in UTC -5 is the same as 11 PM in UTC -11:",
            choices: ['True', 'False'],
            answerIdx: 1,
            furtherInfo: {
                explanation: `This is false because the answer should be 11 AM, not 11 PM. The difference between UTC -5 and UTC -11 is -6 hrs; 5 PM - 6 hrs is 11 AM.`,
                // sources: [ { source: "", url: '' } ],
            },
        },
        { // Q-5
            question: `A train leaves San Francisco, California (UTC -8) at 5 AM local time and arrives at 12 AM local time in Richmond, Virginia (UTC -5). The train will reach Virginia in:`,
            choices: ['6 hours', '16 hours', '26 hours', '36 hours'],
            answerIdx: 1,    
            furtherInfo: {
                explanation: `5 AM in UTC -8 is 8 AM in UTC -5 (5AM +3 hrs). The time from 8 AM to midnight in UTC -5 is 16 hours.`,
                // sources: [ { source: "", url: '' } ],
            },
        },
        { // Q-6
            question: "If it strikes 12 AM in UTC -11, what time is that in UTC -8?",
            choices: ['6 AM', '3 PM', '9 PM', '3 AM'],
            answerIdx: 3,
            furtherInfo: {
                explanation: `12 AM in UTC -11 is 3 AM in UTC -8.
                (The difference between UTC -11 and UTC -8 is 3 hrs; 12 AM + 3 hrs = 3 AM)`,
                // sources: [ { source: "", url: '' } ],
            },
        },
        { // Q-7
            question: `Ray catches a flight from New York (UTC -5) at 4 AM local time, headed to Moscow (UTC +3). The total flight time is 10 hours. What will the local time be in Moscow when the plane lands?`,
            choices: ['6 AM', '10 AM', '6 PM', '10 PM'],
            answerIdx: 3,
            furtherInfo: {
                explanation: `The difference between UTC -5 and UTC +3 is +8 hrs, so 4 AM in UTC -5 is 12 PM (4 +8 hrs) in UTC +3. If the flight takes off in local Moscow time at 12 PM, and is 10 hours long, it will arrive at 10 PM.`,
                // sources: [ { source: "", url: '' } ],
            },
        },
        { // Q-8
            question: `Sam, Alex, and Max live in time zones UTC -7, UTC -3, and UTC -5 respectively. Can they meet up at a time that falls between 12 PM & 6 PM for everyone?`,
            choices: ['Yes', 'No'],
            answerIdx: 0,    
            furtherInfo: {
                explanation: `Yes, they can find a time that works for all of them. This is because their time zones span 4 hours (from: UTC -7 to UTC -3), which fits within the 6 hour window for their meeting (from: 12 PM to 6 PM). So, for example, if it's 12 PM local time for Sam, it will be 4 PM local time for Alex and 2 PM local time for Max.`,
                // sources: [ { source: "", url: '' } ],
            },
        },
        { // Q-9
            question: `A flight takes off from Karachi, Pakistan (UTC +5) at 9 PM, and lands 3 hours later in Istanbul, Turkey (UTC +2), at 9 PM local time. What time was it in Istanbul during take-off?`,
            choices: ['12 PM', '3 PM', '6 PM', '12 AM'],
            answerIdx: 2,
            furtherInfo: {
                explanation: `If a 3 hour flight landed at 9 PM local time, it took off at 6 PM according to the same local time.`,
                // sources: [ { source: "", url: '' } ],
            },
        },
        { // Q-10
            question: `If it's 6 AM in San Francisco, California (UTC -8), it will be 1 AM in Rio de Janeiro, Brazil (UTC -3).`,
            choices: ['True', 'False'],
            answerIdx: 1,    
            furtherInfo: {
                explanation: `This is false. 6 AM in San Francisco (UTC -8) is 11 AM in Rio de Janeiro (UTC -3). 8 - 3 = 5; 6 AM + 5hrs = 11 AM.`,
                // sources: [ { source: "", url: '' } ],
            },
        },
        { // Q-11
            question: `If Will departs at 3 AM (UTC -8), will he be able to reach his destination at 9 AM (UTC -7) in 5 hours?`,
            choices: ['Yes', 'No'],
            answerIdx: 0,
            furtherInfo: {
                explanation: `The answer is yes. (3 AM + 5hrs = 8 AM; 8 - 7 = 1; 8 AM + 1hr = 9 AM)`,
                // sources: [ { source: "", url: '' } ],
            },
        },
        { // Q-12
            question: `If the clock strikes 6 PM in New York (UTC -5), then a destination that is 6 hours behind will have the UTC time of:`,
            choices: ['UTC -11', 'UTC -2', 'UTC +2', 'UTC +11'],
            answerIdx: 0,    
            furtherInfo: {
                explanation: `A destination 6 hours behind New York (UTC -5) will have a UTC time of UTC -11 (-5 - 6 = -11).`,
                // sources: [ { source: "", url: '' } ],
            },
        },
        { // Q-13
            question: `Aiza lives in Berlin, Germany (UTC +1) while her sister lives in Seoul, South Korea (UTC +9). What time is it in Seoul when the clock strikes 12 AM in Berlin?`,
            choices: ['3 AM', '8 AM', '3 PM', '8 PM'],
            answerIdx: 1,
            furtherInfo: {
                explanation: `Since UTC +1 and UTC +9 are 8 hrs apart, It will be 8 AM in Seoul when it strikes 12 AM in Berlin.`,
                // sources: [ { source: "", url: '' } ],
            },
        },
        { // Q-14
            question: "Ann lives in the UTC +6 time zone and is 6 hours ahead of Joss. What time zone does Joss live in?",
            choices: ['UTC -12', 'UTC -6', 'UTC', 'UTC +12'],
            answerIdx: 2,
            furtherInfo: {
                explanation: `If Ann in UTC +6 is 6 hours ahead of Joss, then Joss lives in the UTC timezone.`,
                // sources: [ { source: "", url: '' } ],
            },
        },
        { // Q-15
            question: `If Nash and Mona's local times are 5 PM and 8 PM, respectively, which of the following is true about them?`,
            choices: [
                `Their time zones are UTC +3 and UTC -2`,
                `Nash is 3 hours ahead of Mona`,
                `Mona is 3 hours behind Nash`,
                `None of these`
            ],
            answerIdx: 3,    
            furtherInfo: {
                explanation: `None of these are correct (If Nash and Mona's local times are 5 PM and 8 PM,Mona is 3 hours ahead of Nash).`,
                // sources: [ { source: "", url: '' } ],
            },
        },
        { // Q-16
            question: `Joe wants to celebrate New Year's Eve twice. If he celebrates it once in Sydney, Australia (UTC +10), where can he celebrate it again?`,
            choices: ['UTC +10', 'UTC +12', 'UTC -10', 'None of these'],
            answerIdx: 2,
            furtherInfo: {
                explanation: `If Joe wants to celebrate New Year's Eve a second time, after celebrating first in Sydney (UTC +10), he can fly to Hawaii, in the UTC -10 time zone. While the 2 time zones are 20 hours apart, a nonstop flight from Sydney to Honolulu is only 9 hrs, 30 min.`,
                // sources: [ { source: "", url: '' } ],
            },
        },
        { // Q-17
            question: `If Rey departs at 10 PM local time from New York (UTC -5), on a 6 hour flight to Mumbai, India (UTC +5), then what time is it in Mumbai when Rey lands?`,
            choices: ['2 AM', '4 AM', '2 PM', '4 PM'],
            answerIdx: 2,
            furtherInfo: {
                explanation: `UTC -5 is 10 hrs behind UTC +5, so 10 PM in New York is 8 AM in Mumbai. After a 6 hour flight, it would be 2 PM local time in Mumbai when Rey's plane is landing (8 AM + 6 hrs is 2 PM).`,
                // sources: [ { source: "", url: '' } ],
            },
        },
        { // Q-18
            question: "You can experience the longest period of daylight in which of the following cases?",
            choices: [
                'Traveling from UTC +1 to UTC -5',
                'Traveling from UTC -5 to UTC +1',
                'Traveling from UTC -7 to UTC -1',
                'None of these'
            ],
            answerIdx: 2,    
            furtherInfo: {
                explanation: `You can experience the longest period of daylight by traveling from UTC -7 to UTC -1.`,
                // sources: [ { source: "", url: '' } ],
            },
        },
        { // Q-19
            question: "Which of the following is true?",
            choices: [
                'UTC -1 is behind UTC +1',
                'UTC -3 is ahead of UTC -1',
                'UTC -7 is behind UTC +10',
                'Both A & C'
            ],
            answerIdx: 3,
            furtherInfo: {
                explanation: `Both A and C are true. The time zone with the smaller number is behind the time zone with the larger number; UTC -1 is behind UTC +1, and UTC -7 is behind UTC +10.`,
                // sources: [ { source: "", url: '' } ],
            },
        },

    ],

};

export default timeZones;
