 const costOfPrelude = {
    name: "The cost of Shell's Prelude LNG facility",
    id: '4c6rp9dJ6kNYhX3dV0uy',
    linkTo: "/quizzes/costOfPrelude",
    explainerOn: true,
    description: `Prelude is Royal Dutch Shell's massive floating Liquefied Natural Gas (LNG) plant. It offers an innovative way of accessing small pockets of undersea natural gas that would otherwise remain untapped. While certainly a feat of engineering, this mammoth barge also carries a lot of hidden costs. It's worth taking a look while pondering what it's actually a prelude to?`,
    questions: [
        {
            question: "Liquefied Natural Gas, or LNG, produces fewer emissions than black coal when burned, but by how much?",
            choices: ['4%', '24%', '40%', '74%'],
            answerIdx: 2,    
            furtherInfo: {
                explanation: (
                    `LNG produces 40% fewer emissions than black coal when burned, however such figures fail to take into account the environmental cost of mining methods for LNG, such as fracking.`
                ),
                sources: [
                    {
                        source: "Q+A-How do emissions from LNG and coal compare?",
                        url: 'https://www.reuters.com/article/australia-lng-emissions-idUSL3E7FS0HG20110510',
                    },
                ],
            },
        },
        {
            question: "How many metric tons does Prelude weigh?",
            choices: ['6,000', '60,000', '600,000', '6,000,000'],
            answerIdx: 2,    
            furtherInfo: {
                explanation: (
                    `At 600,000 metric tons, Prelude weighs six times as much as an aircraft carrier.`
                ),
                sources: [
                    {
                        source: "Prelude Floating Liquefied Natural Gas Facility",
                        url: 'https://www.ship-technology.com/projects/prelude-floating-liquefied-natural-gas-flng/',
                    },
                ],
            },
        },
        {
            question: "How long is Prelude in feet?",
            choices: ['150', '1,500', '15,000', '150,000'],
            answerIdx: 1,    
            furtherInfo: {
                explanation: (
                    `Prelude is 1,500 feet long`
                ),
                sources: [
                    {
                        source: "Prelude Floating Liquefied Natural Gas Facility",
                        url: 'https://www.ship-technology.com/projects/prelude-floating-liquefied-natural-gas-flng/',
                    },
                ],
            },
        },
        {
            question: "How wide is Prelude in feet?",
            choices: ['240', '2,400', '24,000', '240,000'],
            answerIdx: 0,    
            furtherInfo: {
                explanation: (
                    `Prelude is 240 feet wide`
                ),
                sources: [
                    {
                        source: "Prelude Floating Liquefied Natural Gas Facility",
                        url: 'https://www.ship-technology.com/projects/prelude-floating-liquefied-natural-gas-flng/',
                    },
                ],
            },
        },
        {
            question: "How many liters of water per hour does Prelude use to cool the natural gas it processes?",
            choices: ['5,000', '50,000', '500,000', '50,000,000'],
            answerIdx: 3,    
            furtherInfo: {
                explanation: (
                    `Prelude moves 50 million liters of seawater per hour to cool its natural gas.`
                ),
                sources: [
                    {
                        source: "Milne, P. (2020, March 26). Shell's Prelude LNG is a carbon disaster. Boiling Cold",
                        url: 'https://www.boilingcold.com.au/shells-prelude-lng-carbon-disaster/',
                    },
                ],
            },
        },
        {
            question: "How much did Prelude cost to build in US dollars?",
            choices: ['$12,000,000', '$120,000,000', '$12,000,000,000', '$120,000,000,000'],
            answerIdx: 2,    
            furtherInfo: {
                explanation: (
                    `While Shell has never released the exact cost of Prelude’s construction, it is estimated that it cost at least 12 billion US dollars, and up to 17 billion dollars.`
                ),
                sources: [
                    {
                        source: "Shell’s $12 Billion Prelude LNG Barge Drifts Toward A Finale",
                        url: 'https://www.forbes.com/sites/timtreadgold/2021/04/26/shells-12-billion-prelude-lng-barge-drifts-toward-a-finale/',
                    },
                ],
            },
        },
        {
            question: "What is Prelude’s top capacity for processing LNG annually, in metric tons?",
            choices: ['3,600', '36,000', '360,000', '3,600,000'],
            answerIdx: 3,    
            furtherInfo: {
                explanation: (
                    `Prelude is capable of processing 3.6 million metric tons of LNG every year.`
                ),
                sources: [
                    {
                        source: "Milne, P. (2020, March 26). Shell's Prelude LNG is a carbon disaster. Boiling Cold",
                        url: 'https://www.boilingcold.com.au/shells-prelude-lng-carbon-disaster/',
                    },
                ],
            },
        },
        {
            question: "Prelude is permitted to emit how many metric tons of carbon emissions a year while processing LNG?",
            choices: ['270,000', '2,700,000', '27,000,000', '270,000,000'],
            answerIdx: 1,    
            furtherInfo: {
                explanation: (
                    `In pursuit of processing its top capacity of 3.6 million metric tons of LNG, Prelude is allowed to emit 2.7 million metric tons of carbon emissions.`
                ),
                sources: [
                    {
                        source: "Milne, P. (2020, March 26). Shell's Prelude LNG is a carbon disaster. Boiling Cold",
                        url: 'https://www.boilingcold.com.au/shells-prelude-lng-carbon-disaster/',
                    },
                ],
            },
        },
        {
            question: "Between December, 2018 and June 2019, how many metric tons of carbon emissions did Prelude emit?",
            choices: ['2,320', '23,200', '232,000', '2,320,000'],
            answerIdx: 3,    
            furtherInfo: {
                explanation: (
                    `In just seven months, between December 2018 and January 2019, Prelude emitted 2.32 million metric tons of carbon emissions, during which time it produced just one shipment of LNG. That’s approximately 86% of its total annual allowable carbon emissions.`
                ),
                sources: [
                    {
                        source: "Milne, P. (2020, March 26). Shell's Prelude LNG is a carbon disaster. Boiling Cold",
                        url: 'https://www.boilingcold.com.au/shells-prelude-lng-carbon-disaster/',
                    },
                ],
            },
        },
        {
            question: "Taking into consideration not only the carbon emissions of Prelude itself, but the emissions produced while transporting the LNG, how many metric tons of carbon emissions were emitted per metric ton of LNG?",
            choices: ['0.2', '8', '28', '280'],
            answerIdx: 2,    
            furtherInfo: {
                explanation: (
                    `In 2019 the carbon intensity of LNG processed by Prelude was 28 metric tons of carbon emission emitted per 1 metric ton of LNG processed and shipped.`
                ),
                sources: [
                    {
                        source: "Milne, P. (2020, March 26). Shell's Prelude LNG is a carbon disaster. Boiling Cold",
                        url: 'https://www.boilingcold.com.au/shells-prelude-lng-carbon-disaster/',
                    },
                ],
            },
        },
    ],

};

export default costOfPrelude;