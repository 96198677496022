const combatingCarbonEmissions = {
  name: 'Combating Carbon Emissions',
  id: 'ORyAIFNbioZbOBTIbyig',
  linkTo: "/quizzes/combatingCarbonEmissions",
  explainerOn: true,
  subheading: `Fighting climate change is the great challenge before us. But how much CO₂ is being emitted into the atmosphere, and how do we go about turning things around?`,
  description: `Combating climate change is the great challenge of our time. It is common knowledge that much of this battle has to do with our CO₂ emissions. But, it can be difficult to grasp the actual scale of those emissions, and what it will take to disrupt climate change on a meaningful scale.`,
  questions: [
      {
          //question 1//
          question: "If every country met its emissions reduction goal pledged in the 2015 Paris Agreement, that alone would be enough to limit planetwide warming to 1.5⁰C.",
          choices: ['True', 'False'],
          answerIdx: 1,    
          furtherInfo: {
              explanation: (
                  `<b>This is unfortunately false.</b> Many countries are currently failing to meet their emissions reduction pledge, but even if every country were on track that still would not be enough to limit global warming to 1.5⁰C.`
              ),
              sources: [
                  {
                      source: "IEEE Spectrum",
                      url: 'https://spectrum.ieee.org/engineers-you-can-disrupt-climate-change',
                  },
              ],
          },
      },
      {
        //question 2
        question: "On Earth Day 2021 (April 22, 2021), what was the level of CO₂ in the atmosphere, as measured in parts per million (ppm)?",
        choices: ['4 ppm', '41 ppm', '417 ppm', '4,170 ppm'],
        answerIdx: 2,    
        furtherInfo: {
            explanation: (
                `On Earth Day 2021, atmospheric carbon measured at 417 ppm.`
            ),
            sources: [
                {
                  source: "IEEE Spectrum",
                  url: 'https://spectrum.ieee.org/engineers-you-can-disrupt-climate-change',
                },
            ],
        },
    },
      {
          //question 3
          question: "What level would atmospheric carbon need to be at to even partially reverse climate change?",
          choices: ['3 ppm', '35 ppm', '350 ppm', '3,500 ppm'],
          answerIdx: 1,    
          furtherInfo: {
              explanation: (
                  `To even partially reverse climate change, we would need to bring atmospheric carbon down from 417 ppm to 350 ppm.`
              ),
              sources: [
                {
                  source: "IEEE Spectrum",
                  url: 'https://spectrum.ieee.org/engineers-you-can-disrupt-climate-change',
                },
              ],
          },
      },
      {
        //question 4
        question: "How many gigatonnes (Gt) of CO₂ do humans currently emit each year?",
        choices: ['4 Gt', '40 Gt', '400 Gt', '4,000 Gt'],
        answerIdx: 1,    
        furtherInfo: {
            explanation: (
                `Currently, we are emitting 40 gigatonnes of CO₂ each year. For reference, 1 gigatonne is equal to 1 billion metric tons, which is approximately equivalent to 5.5 million blue whales.\n\n40 x 5.5 = 220\n\n So, every year we emit the equivalent of 220 million blue whales’ worth of CO₂ into the atmosphere.`
            ),
            sources: [
                {
                  source: "IEEE Spectrum",
                  url: 'https://spectrum.ieee.org/engineers-you-can-disrupt-climate-change',
                },                
                {
                    source: "Energy Education: University of Calgary",
                    url: 'https://energyeducation.ca/encyclopedia/Gigatonne',
                },
            ],
        },
    },
      {
          //question 5//
          question: "For our atmospheric carbon level to reach 350 ppm, how many gigatonnes (Gt) of CO₂ must be removed from the atmosphere over the next century?",
          choices: ['2 Gt', '20 Gt', '200 Gt', '2,000 Gt'],
          answerIdx: 3,    
          furtherInfo: {
              explanation: (
                  `To reach an atmospheric carbon level of 350 ppm, over the next century we would need to remove 2,000 gigatonnes of CO₂ from the atmosphere, or 4 billion International Space Stations’ worth.`
              ),
              sources: [
                {
                  source: "IEEE Spectrum",
                  url: 'https://spectrum.ieee.org/engineers-you-can-disrupt-climate-change',
                },
              ],
          },
      },
      {
          //question 6//
          question: "To remove a total of 2,000Gt of CO₂ from the atmosphere over the next century, how many gigatonnes would need to be removed and sequestered per year?",
          choices: ['2 Gt', '25 Gt', '250 Gt', '2,500 Gt'],
          answerIdx: 1,    
          furtherInfo: {
              explanation: (
                  `25 gigatonnes of CO₂ would need to be removed per year, over the next century, to reach the goal of removing a total of 2,000 gigatonnes. 25 gigatonnes of CO₂ is equal to the weight of approximately 75 million Boeing 747 jets.`
              ),
              sources: [
                {
                  source: "IEEE Spectrum",
                  url: 'https://spectrum.ieee.org/engineers-you-can-disrupt-climate-change',
                },
              ],
          },
      },
      {
          //question 7//
          question: "Carbon sequestration is the storing of carbon in forests, soil, geological formations, and the ocean.",
          choices: ['True', 'False'],
          answerIdx: 0,    
          furtherInfo: {
              explanation: (
                  `This is true. Nature already sequesters approximately 40% of all atmospheric carbon, and many scientists and engineers are working on ways to assist this process.`
              ),
              sources: [
                {
                  source: "Project Drawdown",
                  url: "https://drawdown.org/drawdown-framework"
                },
                {
                  source: "IEEE Spectrum",
                  url: 'https://spectrum.ieee.org/engineers-you-can-disrupt-climate-change',
                },
              ],
          },
      },
      {
          //question 8//
          question: "Electricity production produces approximately what percent of total global heat-trapping emissions?",
          choices: ['5%', '25%', '75%', '95%'],
          answerIdx: 2,    
          furtherInfo: {
              explanation: (
                  `Globally, the production of electricity generates approximately 25% of total heat-trapping emissions, which makes it one of the largest sectors needing carbon-free solutions.`
              ),
              sources: [
                {
                  source: "Project Drawdown",
                  url: "https://drawdown.org/sectors/electricity"
                },
                {
                  source: "IEEE Spectrum",
                  url: 'https://spectrum.ieee.org/engineers-you-can-disrupt-climate-change',
                },
              ],
          },
      },
      {
          //question 9//
          question: "Approximately how much energy do humans use each year?",
          choices: ['1,000 joules', '10,000 joules', '100,000 joules', '1 zettajoule'],
          answerIdx: 3,    
          furtherInfo: {
              explanation: (
                  `Globally, humans use approximately 1 zettajoule of energy a year. 1 zettajoule is equal to 1 sextillion (10²¹) joules.`
              ),
              sources: [
                {
                  source: "IEEE Spectrum",
                  url: 'https://spectrum.ieee.org/engineers-you-can-disrupt-climate-change',
                },
              ],
          },
      },
      {
          //question 10//
          question: "To supply 1 zettajoule of energy entirely through solar power, what percent of the Earth’s surface would need to be covered in solar panels?",
          choices: ['1.6%', '15.6%', '50.6%', '75.6%'],
          answerIdx: 0,    
          furtherInfo: {
              explanation: (
                  `To provide 1 zettajoule of energy entirely through solar power we would need to cover 1.6% of the Earth’s surface with solar panels. For contrast, agriculture currently takes up 40% of the Earth’s surface.`
              ),
              sources: [
                {
                  source: "IEEE Spectrum",
                  url: 'https://spectrum.ieee.org/engineers-you-can-disrupt-climate-change',
                },
              ],
          },
      },
  ],

};

export default combatingCarbonEmissions;