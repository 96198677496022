const historyOfTheUniverse = {
  name: 'The History of the Universe',
  id: 'othNs8980Xfkk5JpwnOW',
  linkTo: "/quizzes/historyOfTheUniverse",
  explainerOn: true,
  subheading: `How much do <i>you</i> know about the history of the universe?`,
  description: `Vast and ever-expanding; the universe burst into life in one explosive instant that created everything we ever have and ever will know. The mechanism behind the big bang may remain unknown, but we've learned a lot about the early days of the universe. How much do <i>you</i> know about the history of the universe?`,
  questions: [
      {
          //question 1//
          question: `How many years old is the universe?`,
          choices: ['13,800', '13,800,000', '13,800,000,000', '13,800,000,000,000'],
          answerIdx: 2,    
          furtherInfo: {
              explanation: (
                  `13.8 billion years ago, the universe as we know it was born from a vast explosion that created space and time and all matter and energy that exists today.`
              ),
              sources: [
                  {
                      source: 'Universe Today',
                      url: 'https://www.universetoday.com/123701/timeline-of-the-universe-from-the-big-bang-to-the-death-of-our-sun/',
                  },
              ],
        },
      },
      {
          //question 2//
          question: `How many years ago was Methuselah, the oldest known star, formed?`,
          choices: ['136,500', '1,365,000', '13,650,000', '13,650,000,000'],
          answerIdx: 3,    
          furtherInfo: {
              explanation: (
                  `Methuselah is a low mass star with only about 85% of the mass of our sun, that formed approximately 13.65 billion years ago; quite early in the history of our universe.`
              ),
              sources: [
                  {
                      source: 'Houston Astronomical Society',
                      url: 'https://www.astronomyhouston.org/newsletters/guidestar/shallow-sky-object-month-methuselah-star-%E2%80%93-oldest-star-milkway-0',
                  },
              ],
        },
      },
      {
          //question 3//
          question: `Lasting from 13.6 billion years ago to 12.9 billion years ago, how many years did the Reionization Era of the universe last?`,
          choices: ['700,000', '700,000,000', '700,000,000,000', '700,000,000,000,000'],
          answerIdx: 1,    
          furtherInfo: {
              explanation: (
                  `Referring to the time period where most of the neutral hydrogen in the universe was reionized by increasing radiation from the first massive stars thus decreasing the opacity of the universe, the Reionization Era lasted approximately 700 million years.`
              ),
              sources: [
                  {
                      source: 'NASA James Webb Space Telescope',
                      url: 'https://jwst.nasa.gov/content/science/firstLight.html',
                  },
              ],
        },
      },
      {
          //question 4//
          question: `How many years ago did the first galaxies form?`,
          choices: ['132,000', '13,200,000', '132,000,000', '13,200,000,000'],
          answerIdx: 3,    
          furtherInfo: {
              explanation: (
                  `The first galaxies formed approximately 13.2 billion years ago, 600 million years after the birth of the universe.`
              ),
              sources: [
                  {
                      source: 'Universe Today',
                      url: 'https://www.universetoday.com/123701/timeline-of-the-universe-from-the-big-bang-to-the-death-of-our-sun/',
                  },
              ],
        },
      },
      {
          //question 5//
          question: `How many years ago did the Milky Way form?`,
          choices: ['12,600,000,000', '126,000,000,000', '12,600,000,000,000', '126,000,000,000,000'],
          answerIdx: 0,    
          furtherInfo: {
              explanation: (
                  `Our galaxy, the Milky Way, formed 12.6 billion years ago.`
              ),
              sources: [
                  {
                      source: 'Universe Today',
                      url: 'https://www.universetoday.com/123701/timeline-of-the-universe-from-the-big-bang-to-the-death-of-our-sun/',
                  },
              ],
        },
      },
      {
          //question 6//
          question: `How many years ago did the universe first become habitable?`,
          choices: ['104,000', '10,400,000', '10,400,000,000', '10,400,000,000,000'],
          answerIdx: 2,    
          furtherInfo: {
              explanation: (
                  `10.4 billion years ago, 3.4 billion years after the big bang, the first life-friendly star systems emerged and the universe became habitable.`
              ),
              sources: [
                  {
                      source: 'Universe Today',
                      url: 'https://www.universetoday.com/123701/timeline-of-the-universe-from-the-big-bang-to-the-death-of-our-sun/',
                  },
              ],
        },
      },
      {
          //question 7//
          question: `How many years ago did the Milky Way start to spiralize?`,
          choices: ['650,000,000', '6,500,000,000', '65,000,000,000', '650,000,000,000'],
          answerIdx: 1,    
          furtherInfo: {
              explanation: (
                  `The Milky Way began to gain its recognizable spiral shape 6.5 billion years ago.`
              ),
              sources: [
                  {
                      source: 'Universe Today',
                      url: 'https://www.universetoday.com/123701/timeline-of-the-universe-from-the-big-bang-to-the-death-of-our-sun/',
                  },
              ],
        },
      },
      {
          //question 8//
          question: `How many years ago did the Sun form?`,
          choices: ['4,570,000,000', '45,700,000,000', '457,000,000,000', '4,570,000,000,000'],
          answerIdx: 0,    
          furtherInfo: {
              explanation: (
                  `Our sun formed approximately 4.57 billion years ago.`
              ),
              sources: [
                  {
                      source: 'Universe Today',
                      url: 'https://www.universetoday.com/123701/timeline-of-the-universe-from-the-big-bang-to-the-death-of-our-sun/',
                  },
              ],
        },
      },
      {
          //question 9//
          question: `How many years ago did the Earth form?`,
          choices: ['456,000,000', '4,560,000,000', '45,600,000,000', '456,000,000,000'],
          answerIdx: 1,    
          furtherInfo: {
              explanation: (
                  `A proto-Earth formed around 4.56 billion years ago, though it did not yet have the features we would recognize as our planet today.`
              ),
              sources: [
                  {
                      source: 'Universe Today',
                      url: 'https://www.universetoday.com/123701/timeline-of-the-universe-from-the-big-bang-to-the-death-of-our-sun/',
                  },
              ],
        },
      },
      {
          //question 10//
          question: `How many years ago did life on Earth first emerge?`,
          choices: ['420,000', '42,000,000', '4,200,000,000', '42,000,000,000'],
          answerIdx: 2,    
          furtherInfo: {
              explanation: (
                  `The earliest estimates put the emergence of life on Earth at 4.2 billion years ago, though late estimates put the emergence of life on Earth at 3.9 billion years ago. These first signs of life were little more than simple cells, but they were nevertheless the first steps on the road to us.`
              ),
              sources: [
                  {
                      source: 'Universe Todayw',
                      url: 'https://www.universetoday.com/123701/timeline-of-the-universe-from-the-big-bang-to-the-death-of-our-sun/',
                  },
              ],
          },
      },
  ],
};

export default historyOfTheUniverse;
