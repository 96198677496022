const aGlobalWarning = {
    name: 'A Global Warning',
    id: 'cv4PEYyjdkx7m14m7DhJ',
    linkTo: "/quizzes/aGlobalWarning",
    explainerOn: true,
    description: "How informed are <i>you</i> about Global Warming?",
    questions: [
        { // 1
            question: "The 2015 Paris Agreement on climate change aspires to limit global warming to how many degrees Celsius above pre-industrial levels",
            choices: ['1°C', '1.5°C', '2°C', '2.5°'],
            answerIdx: 1,    
            furtherInfo: {
                explanation: "The 2015 Paris Agreement aspires to limit global warming to 1.5°C above pre-industrial levels.",
                sources: [
                    {
                        source: 'New Scientist',
                        url: 'https://www.newscientist.com/article/mg25033310-800-climate-emergency-the-new-science-showing-its-make-or-break-time/#ixzz6sh0GV9zk',
                    }
                ],
            },
        },
        { // 2
            question: "How much above pre-industrial levels is the world today?",
            choices: ['1°C', '1.5°C', '2°C', '2.5°'],
            answerIdx: 1,    
            furtherInfo: {
                explanation: "Today, the world is already 1.5°C above its pre-industrial levels.",
                sources: [
                    {
                        source: 'New Scientist',
                        url: 'https://www.newscientist.com/article/mg25033310-800-climate-emergency-the-new-science-showing-its-make-or-break-time/#ixzz6sh0GV9zk',
                    }
                ],
            },
        },
        { // 3
            question: "In 1970, global CO2 emissions were about 40 million tonnes per day. How many tonnes of CO2 are being emitted today?",
            choices: ['60 million', '80 million', '100 million', '120 million'],
            answerIdx: 2,    
            furtherInfo: {
                explanation: "Today, 100 million tonnes of CO2 emissions are emitted per day.",
                sources: [
                    {
                        source: 'New Scientist',
                        url: 'https://www.newscientist.com/article/mg25033310-800-climate-emergency-the-new-science-showing-its-make-or-break-time/#ixzz6sh0GV9zk',
                    }
                ],
            },
        },
        { // 4
            question: "Tonnes are the same as tons.",
            choices: ['True', 'False'],
            answerIdx: 1,    
            furtherInfo: {
                explanation: "False. Tonnes and tons are pretty close in mass, but different. A <i>tonne</i> is a metric ton, which is 1,000 kilograms (approx. 2,200 US pounds). A <i>ton</i> in the US is 2,000 pounds.",
                sources: [
                    {
                        source: 'Pediaa',
                        url: 'https://pediaa.com/difference-between-ton-and-metric-ton/',
                    }
                ],
            },
        },
        { // 5
            question: "Which year(s) are used to represent pre-industrial temperature levels in the IPCC Special Report on Global Warming?",
            choices: [
                '1700 - 1750',
                '1750 - 1800',
                '1800 - 1850',
                '1850 - 1900'
            ],
            answerIdx: 3,
            furtherInfo: {
                explanation: "The IPCC Special Report on Global Warming uses the years 1850-1900 to represent pre-industrial temperature levels.",
                sources: [
                    {
                        source: 'IPCC SR15 FAQs',
                        url: 'https://www.ipcc.ch/site/assets/uploads/sites/2/2018/12/SR15_FAQ_Low_Res.pdf',
                    }
                ],
            },
        },
        { // 6
            question: "What is the current amount of Carbon dioxide (CO2) in the atmosphere, as measured in parts per million (ppm)?",
            choices: ['217', '317', '417', '517'],
            answerIdx: 2,    
            furtherInfo: {
                explanation: "The current amount of CO2 in the atmosphere is at 417ppm.",
                sources: [
                    {
                        source: 'Carbon Brief',
                        url: 'https://www.carbonbrief.org/met-office-atmospheric-co2-now-hitting-50-higher-than-pre-industrial-levels',
                    },
                    {
                        source: 'Met Office',
                        url: 'https://www.carbonbrief.org/wp-content/uploads/2021/03/Global-atmospheric-CO2-concentrations-from-1700-to-2021.jpg'
                    }
                ],
            },
        },
        { // 7
            question: "What was the amount of CO2 in the atmosphere in the pre-industrial era, as measured in parts per million (ppm)?",
            choices: ['78', '178', '278', '378'],
            answerIdx: 2,    
            furtherInfo: {
                explanation: "During the pre-industrial area, the amount of CO2 in the atmosphere was 278 ppm.",
                sources: [
                    {
                        source: 'Carbon Brief',
                        url: 'https://www.carbonbrief.org/met-office-atmospheric-co2-now-hitting-50-higher-than-pre-industrial-levels',
                    },
                    {
                        source: 'Met Office',
                        url: 'https://www.carbonbrief.org/wp-content/uploads/2021/03/Global-atmospheric-CO2-concentrations-from-1700-to-2021.jpg'
                    }
                ],
            },
        },
        { // 8
            question: "How much has the amount of CO2 in the atmosphere increased, compared to the pre-industrial era?",
            choices: ['30%', '50%', '70%', '90%'],
            answerIdx: 1,    
            furtherInfo: {
                explanation: "The amount of CO2 in the atmosphere has increased by 50%, compared to the pre-industrial era.",
                sources: [
                    {
                        source: 'Carbon Brief',
                        url: 'https://www.carbonbrief.org/met-office-atmospheric-co2-now-hitting-50-higher-than-pre-industrial-levels',
                    },
                    {
                        source: 'Met Office',
                        url: 'https://www.carbonbrief.org/wp-content/uploads/2021/03/Global-atmospheric-CO2-concentrations-from-1700-to-2021.jpg'
                    }
                ],
            },
        },
        { // 9
            question: "If every country met the Paris Agreement limits, designed to keep warming at 1.5°C, and did so right now, what would warming stop at?",
            choices: ['1.1°C', '1.6°C', '2.1°C', '2.6°C'],
            answerIdx: 3,    
            furtherInfo: {
                explanation: "If every country met the Paris Agreement limits, designed to keep warming at 1.5°C, and did so immediately, warming would stop at 2.6°C.",
                sources: [
                    {
                        source: 'New Scientist',
                        url: 'https://www.newscientist.com/article/mg25033310-800-climate-emergency-the-new-science-showing-its-make-or-break-time/#ixzz6sh0GV9zk',   
                    }
                ],
            },
        },
    ],

};

export default aGlobalWarning;