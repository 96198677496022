/*
 * users collection
 */

/**
 * get users collection ref
 * @param {Object} firestore - Firestore library loaded from firebase or Reactfire
 */
const getUsersRef = (firestore) => {
  return firestore.collection("users");
};

/**
 * get user using user uid
 * @param {Object} firestore - Firestore library loaded from firebase or Reactfire
 * @param {string} uid -  the user id generated from Firebase authentication
 * @returns {Promise}
 */
const getUserByUid = (firestore, uid) => {
  return getUserRefByUid(firestore, uid).get(); // promise
};

/**
 * get user reference in Firestore using user uid
 * @param {Object} firestore - Firestore library loaded from firebase or Reactfire
 * @param {string} uid -  the user id generated from Firebase authentication
 * @returns {DocumentReference} - See https://firebase.google.com/docs/reference/js/firebase.firestore.DocumentReference
 */
const getUserRefByUid = (firestore, uid) => {
  return getUsersRef(firestore).doc(uid);
};

/**
 * Sets a user at a give uid
 * @param {Object} firestore - Firestore library loaded from firebase or Reactfire
 * @param {string} uid -  the user id generated from Firebase authentication
 * @param {Object} fields - the attributes/keys to be set for the user doc
 * @returns {Promise}
 */
const setUser = (firestore, uid, fields) => {
  const userDocRef = getUserRefByUid(firestore, uid);
  return userDocRef.set(fields); // promise
};

/**
 * Update specified fields for a user
 * @param {Object} firestore - Firestore library loaded from firebase or Reactfire
 * @param {string} uid -  the user id generated from Firebase authentication
 * @param {Object} fields - the attributes/keys to be set for the user doc
 * @returns {Promise}
 */
const updateUserFields = (firestore, uid, fields) => {
  const userDocRef = getUserRefByUid(firestore, uid);
  const updatedFields = Object.assign(fields, {
    updatedAt: Date.now(),
  });
  return userDocRef.set(updatedFields, { merge: true }); // promise
};

/**
 * Update username for a user
 * @param {Object} firestore - Firestore library loaded from firebase or Reactfire
 * @param {string} uid -  the user id generated from Firebase authentication
 * @param {string} username - the username of the user
 * @returns {Promise}
 */
const updateUsername = (firestore, uid, username) => {
  return updateUserFields(firestore, uid, {
    username,
  }); // promise
};

/**
 * Update photoURL for a user
 * @param {Object} firestore - Firestore library loaded from firebase or Reactfire
 * @param {string} uid -  the user id generated from Firebase authentication
 * @param {string} photoURL - the photoURL of a user's profile pic
 * @returns {Promise}
 */
const updatePhotoUrl = (firestore, uid, photoURL) => {
  return updateUserFields(firestore, uid, {
    photoURL,
  }); // promise
};

/**
 * Create a new user with given username
 * @param {Object} firestore - Firestore library loaded from firebase or Reactfire
 * @param {string} uid -  the user id generated from Firebase authentication
 * @param {string} username - the username of a user
 * @returns {Promise}
 */
const createNewUserWithUsername = (firestore, uid, username) => {
  return setUser(firestore, uid, {
    username,
    createdAt: Date.now(),
    updatedAt: Date.now(),
  }); // promise
};

/**
 * Async function that checks if a username is unique
 * @param {Object} firestore - Firestore library loaded from firebase or Reactfire
 * @param {string} username - the possible username of a user
 * @returns {boolean}
 */
const isUniqueUsername = async (firestore, username) => {
  const docSnapshot = await getUsersRef(firestore)
    .where("username", "==", username)
    .limit(1)
    .get();
  return docSnapshot.empty;
};

/*
 * quizScores subcollection
 */

/**
 * Add a user's score to quizScores subcollection
 * @param {Object} firestore - Firestore library loaded from firebase or Reactfire
 * @param {string} uid -  the user id generated from Firebase authentication
 * @param {Object} quizScore - the quiz score
 * @param {string} quizScore.quizId - the id of the quiz
 * @param {string} quizScore.quizName - the name of the quiz
 * @param {number} quizScore.score - the score the user received on the quiz
 * @returns {Promise}
 */
const addQuizScoreForUser = (firestore, uid, { score, quizName, quizId, linkTo }) => {
  const userRef = getUserRefByUid(firestore, uid);
  return userRef.collection("quizScores").add({
    quizId,
    quizName,
    score,
    linkTo,
    createdAt: Date.now(),
    updatedAt: Date.now(),
  });
};

/**
 * Gets recent quiz scores for a user with a default limit
 * @param {Object} firestore - Firestore library loaded from firebase or Reactfire
 * @param {string} uid -  the user id generated from Firebase authentication
 * @param {Object} options - options object
 * @param {number} options.limit - number of quiz scores to return
 * @param {string} options.sortOrder - sort order for scores
 * @returns {Promise}
 */
const getQuizScoresForUser = (
  firestore,
  uid,
  { limit, sortOrder, orderBy } = {}
) => {
  const userRef = getUserRefByUid(firestore, uid);
  limit = limit || 5;
  orderBy = orderBy || "createdAt";
  sortOrder = sortOrder || "desc";

  return userRef
    .collection("quizScores")
    .orderBy(orderBy, sortOrder)
    .limit(limit)
    .get();
};

/**
 * Gets quiz scores for a certain quiz for a user with a default limit
 * @param {Object} firestore - Firestore library loaded from firebase or Reactfire
 * @param {string} uid -  the user id generated from Firebase authentication
 * @param {string} quizid -  the user id generated from Firebase authentication
 * @param {Object} options - options object
 * @param {number} options.limit - number of quiz scores to return
 * @returns {Promise}
 */
const getQuizScoreByQuizIdAndUser = (
  firestore,
  uid,
  quizId,
  { limit } = {}
) => {
  const userRef = getUserRefByUid(firestore, uid);
  const orderBy = "createdAt";
  const sortOrder = "desc";
  limit = limit || 5;

  // query requires an index be built on ["score", and "quizId"]
  // See https://console.firebase.google.com/project/quizicist-dev/firestore/indexes
  return userRef
    .collection("quizScores")
    .where("quizId", "==", quizId)
    .orderBy(orderBy, sortOrder)
    .limit(limit)
    .get();
};

/**
 * Async fn that gets the total points a user has earned from every quiz they played
 * @param {Object} firestore - Firestore library loaded from firebase or Reactfire
 * @param {string} uid -  the user id generated from Firebase authentication
 * @returns {Promise<Number>} Promise object that returns a sum of all the scores
 */
const getTotalPointsForUser = async (firestore, uid) => {
  const userRef = getUserRefByUid(firestore, uid);
  const queryResult = await userRef.collection("quizScores").get();

  let sum = 0;
  queryResult.forEach((doc) => (sum += doc.data().score));
  return sum;
};

/**
 * Add a user's achievement to achievements subcollection
 * @param {Object} firestore - Firestore library loaded from firebase or Reactfire
 * @param {string} uid -  the user id generated from Firebase authentication
 * @param {Object} achievement - the quiz score
 * @param {string} achievement.id - the id of the achievement
 * @param {string} achievement.description - a description of the achievement
 * @returns {Promise}
 */
const addAchievementForUser = (firestore, uid, { id, description }) => {
  const userRef = getUserRefByUid(firestore, uid);
  return userRef.collection("achievements").doc(id).set({
    description,
    createdAt: Date.now(),
    updatedAt: Date.now(),
  });
};

/**
 * Get a user's achievements to achievements subcollection
 * @param {Object} firestore - Firestore library loaded from firebase or Reactfire
 * @param {string} uid -  the user id generated from Firebase authentication
 * @returns {Promise}
 */
const getAchievementsForUser = (firestore, uid) => {
  const userRef = getUserRefByUid(firestore, uid);
  return userRef.collection("achievements").orderBy("updatedAt", "desc").get();
};

export {
  getUserByUid,
  updatePhotoUrl,
  updateUsername,
  createNewUserWithUsername,
  isUniqueUsername,
  addQuizScoreForUser,
  getQuizScoresForUser,
  getQuizScoreByQuizIdAndUser,
  getTotalPointsForUser,
  addAchievementForUser,
  getAchievementsForUser,
};
