import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import useWindowDimensions from "../utils/useWindowDimensions";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    minHeight: ({ windowHeight }) => windowHeight,
    background:
      "radial-gradient(circle at 100% 0, #07BBF4 0%, #08B8F5 0.5%, #3124FB 100%)",
    color: "#fff",
  },
}));

export default ({ children }) => {
  const { height } = useWindowDimensions();
  const classes = useStyles({
    windowHeight: height,
  });

  return <div className={classes.container}>{children}</div>;
};
