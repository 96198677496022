 const starWarsQuiz = {
    id: "starWarsQuiz",
    name: "Star Wars Trivia",
    linkTo: "/quizzes/starWarsQuiz",
    description: `"Try Not. Do or do not. There is no Try."<br />-- Master Yoda<br />`,
    questions: [
        {
            question: "What order did the Emperor give to kill the Jedi?",
            choices: ['Order 66', 'Order Jedi Down', 'Order Unlimited Power'],
            answerIdx: 0,    
            furtherInfo: {
            },
        },
        {
            question: "Who shot first: Han or Greedo?",
            choices: ['Greedo', 'Han', 'George Lucas'],
            answerIdx: 0,    
            furtherInfo: {
            },
        },
        {
            question: "Jar Jar Binks is what kind of species?",
            choices: ['Ewok', 'Gungan', 'Wookie'],
            answerIdx: 1,    
            furtherInfo: {
            },
        },
        {
            question: "What is Finn’s Stormtrooper name?",
            choices: ['FN-2188', 'FN-2287', 'FN-2187'],
            answerIdx: 2,    
            furtherInfo: {
            },
        },
        {
            question: "The Millennium Falcon did the Kessel Run in how many parsecs?",
            choices: ['12', '13', '14'],
            answerIdx: 0,    
            furtherInfo: {
            },
        },
        {
            question: "What planet did the Death Star destroy?",
            choices: ['Yavin', 'Bespin', 'Alderaan'],
            answerIdx: 2,    
            furtherInfo: {
            },
        },
        {
            question: "Which Golden Girl had a part in the Star War Holiday Special?",
            choices: ['Betty White', 'Bea Authur', 'Rue McClanahan'],
            answerIdx: 1,    
            furtherInfo: {
            },
        },
        {
            question: "What was Queen Amidala’s name?",
            choices: ['Mara Jade', 'Jana', 'Padme'],
            answerIdx: 2,    
            furtherInfo: {
            },
        },
        {
            question: "What creature does Luke Skywalker ride in Empire Strikes Back?",
            choices: ['Tauntaun', 'Wampa', 'Dewback'],
            answerIdx: 0,    
            furtherInfo: {
            },
        },
        {
            question: "Which actor and puppeteer voiced Yoda?",
            choices: ['Jim Henson', 'Kirk Thatcher', 'Frank Oz'],
            answerIdx: 2,    
            furtherInfo: {
            },
        },
    ],

};

export default starWarsQuiz;
