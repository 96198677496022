 const musicTrivia90 = {
    id: "musicTrivia90",
    name: "90's Music Trivia ",
    linkTo: "/quizzes/musicTrivia90",
    description: 'You, you, you oughtta know!',
    questions: [
        {
            question: 'Which artist had a "Vision of Love" and the most hits of the 90\'s?',
            choices: ['Mariah Carey', 'Billy Idol', 'Wilson Phillips'],
            answerIdx: 0,    
            furtherInfo: {
            },
        },
        {
            question: 'What is Vanilla Ice\'s real name?',
            choices: ['Bob Sugar', 'Robert Matthew Van Winkle', 'Stanley Kirk Burrell'],
            answerIdx: 1,    
            furtherInfo: {
            },
        },
        {
            question: 'Who would do any for love, (But they won\'t do that)?',
            choices: ['UB40', 'Meatloaf', 'Snow'],
            answerIdx: 1,    
            furtherInfo: {
            },
        },
        {
            question: 'Which group wore their pants backwards?',
            choices: ['R. Kelly', 'Ini Kamoze', 'Kris Kross'],
            answerIdx: 2,    
            furtherInfo: {
            },
        },
        {
            question: 'Who recorded the song "This Is How We Do It"?',
            choices: ['Seal', 'Montell Jordan', 'All-4-One'],
            answerIdx: 1,    
            furtherInfo: {
            },
        },
        {
            question: 'Which artist sang about a "Genie In a Bottle"?',
            choices: ['TLC', 'K-Ci & Jojo', 'Christina Aguilera'],
            answerIdx: 2,    
            furtherInfo: {
            },
        },
        {
            question: 'Which artist recorded "I\'m To Sexy"?',
            choices: ['George Michael', 'Mr. Big', 'Right Said Fred'],
            answerIdx: 2,    
            furtherInfo: {
            },
        },
        {
            question: 'What was the hit song from the film "The Bodyguard"?',
            choices: ['I Will Always Love You', "Don't Go Breaking My Heart", 'The Power Of Love'],
            answerIdx: 0,    
            furtherInfo: {
            },
        },
        {
            question: `Fill in the blank: "Kiss From a _________ ."`,
            choices: ['Creep', 'Rose', 'Angel Of Mine'],
            answerIdx: 1,    
            furtherInfo: {
            },
        },
        {
            question: `Fill in the blank: "Have You Ever Really Loved A _________ ."`,
            choices: ['Fantasy', 'Heartbreaker', 'Woman'],
            answerIdx: 2,    
            furtherInfo: {
            },
        },
    ],

};

export default musicTrivia90;
