
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faUsers,
  faHeadSideCough,
  faLungsVirus,
  faSkullCrossbones,
  faVial,
  faChartLine,
  faBed,
  faHospitalUser,
  faAmbulance,
  faHeartbeat,
  faProcedures,
  faPoll,
  faChevronLeft,
  faChevronRight,
  faVolumeUp,
  faVolumeOff,
  faClock
} from '@fortawesome/free-solid-svg-icons';

export default function registerIcons() {
  library.add(faUsers,
    faHeadSideCough,
    faLungsVirus,
    faSkullCrossbones,
    faVial,
    faChartLine,
    faBed,
    faHospitalUser,
    faAmbulance,
    faHeartbeat,
    faProcedures,
    faPoll,
    faChevronLeft,
    faChevronRight,
    faVolumeUp,
    faVolumeOff,
    faClock
  );
}
