 const urbanEnergy = {
    name: 'Urban Energy',
    id: 'p07wEO8N2e2InNsAxJCn',
    linkTo: "/quizzes/urbanEnergy",
    explainerOn: true,
    description: 'Cities are the epicenter of modern life, but they aren’t kind to the environment. What have urban planners come up with to help combat some of the environmental impact of cities?', // to do
    questions: [
        { // 1
            question: "What fraction of the global energy supply is consumed by cities?",
            choices: ['1/3', '1/2', '2/3', '3/4'],
            answerIdx: 2,    
            furtherInfo: {
                explanation: "Cities consume 2/3 of the global energy supply.",
                sources: [
                    {
                        source: 'Bloomberg',
                        url: 'https://www.bloomberg.com/graphics/2021-cities-climate-solutions/?sref=FtHoQDx1',
                    }
                ],
            },
        },
        { // 2
            question: "What fraction of the world’s greenhouse-gas emissions are generated by cities?",
            choices: ['1/2', '2/3', '3/4', '4/5'],
            answerIdx: 2,    
            furtherInfo: {
                explanation: "3/4 of the world’s greenhouse-gas emissions are generated by cities.",
                sources: [
                    {
                        source: 'Bloomberg',
                        url: 'https://www.bloomberg.com/graphics/2021-cities-climate-solutions/?sref=FtHoQDx1',
                    }
                ],
            },
        },
        { // 3
            question: "Buildings account for what percent of a city’s total carbon emissions, on average?",
            choices: ['20%', '30%', '40%', '50%'],
            answerIdx: 3,    
            furtherInfo: {
                explanation: "On average, buildings account for 50% of a city’s total carbon emissions.",
                sources: [
                    {
                        source: 'Bloomberg',
                        url: 'https://www.bloomberg.com/graphics/2021-cities-climate-solutions/?sref=FtHoQDx1',
                    }
                ],
            },
        },
        { // 4
            question: "An average building's heating and cooling systems are responsible for more than half of its energy use.",
            choices: ['True', 'False'],
            answerIdx: 0,    
            furtherInfo: {
                explanation: "The heating and cooling systems make up more than half of an average building's energy use.",
                sources: [
                    {
                        source: 'Bloomberg',
                        url: 'https://www.bloomberg.com/graphics/2021-cities-climate-solutions/?sref=FtHoQDx1',
                    }
                ],
            },
        },
        { // 5
            question: "Within what radius are many urban planners and designers working to accommodate all of their residents' needs?",
            choices: ['15 minutes', '30 minutes', '45 minutes', '60 minutes'],
            answerIdx: 0,    
            furtherInfo: {
                explanation: "Many urban planners and designers are working to accommodate all of their residents’ needs within a 15 minute radius.",
                sources: [
                    {
                        source: 'Bloomberg',
                        url: 'https://www.bloomberg.com/graphics/2021-cities-climate-solutions/?sref=FtHoQDx1',
                    }
                ],
            },
        },
        { // 6
            question: "What is the main problem 'The 15 minute City' (giving residents access to everything they need within fifteen minutes of their front doors) trying to address?",
            choices: ['alienation', 'crime', 'air pollution', 'a need for green spaces'],
            answerIdx: 2,    
            furtherInfo: {
                explanation: `The main problem “The 15 Minute City” is trying to address is air pollution generated by traffic.`,
                sources: [
                    {
                        source: 'Bloomberg',
                        url: 'https://www.bloomberg.com/graphics/2021-cities-climate-solutions/?sref=FtHoQDx1',
                    }
                ],
            },
        },
        { // 7
            question: "About how many European cities have created low-emission zones, in which air polluting vehicles are restricted or regulated?",
            choices: ['100', '150', '200', '250'],
            answerIdx: 3,    
            furtherInfo: {
                explanation: "250 European cities have created low-emission zones in which air polluting vehicles are restricted or regulated.",
                sources: [
                    {
                        source: 'Bloomberg',
                        url: 'https://www.bloomberg.com/graphics/2021-cities-climate-solutions/?sref=FtHoQDx1',
                    }
                ],
            },
        },
        { // 8
            question: "How much did concentrations of fine particulate matter decrease in Lisbon, Portugal, from 2009 to 2016, after they took measures to limit traffic in the city center?",
            choices: ['15%', '30%', '45%', '60%'],
            answerIdx: 1,    
            furtherInfo: {
                explanation: "From 2009 to 2016, the concentration of fine particulate matter in Lisbon decreased by 30% after they took measures to limit traffic in the city center.",
                sources: [
                    {
                        source: 'Bloomberg',
                        url: 'https://www.bloomberg.com/graphics/2021-cities-climate-solutions/?sref=FtHoQDx1',
                    }
                ],
            },
        },
        { // 9
            question: "At present, which city has gone the furthest towards realizing 'The 15 Minute City'?",
            choices: ['Amsterdam', 'Copenhagen', 'Lisbon', 'Paris'],
            answerIdx: 3,    
            furtherInfo: {
                explanation: `At present, Paris has gone furthest toward realizing "The 15 Minute City.”`,
                sources: [
                    {
                        source: 'Bloomberg',
                        url: 'https://www.bloomberg.com/graphics/2021-cities-climate-solutions/?sref=FtHoQDx1',
                    }
                ],
            },
        },
    ],

};

export default urbanEnergy;