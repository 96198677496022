import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import useLoadedAuthUser from "../../hooks/useLoadedAuthUser";
import useNotificationSystem from "../../hooks/useNotificationSystem.js";

import Form from "./Form";
import AuthCheck from "../AuthCheckWrapper";
import Reauthenticate from "./Reauthenticate";
import ProfileContainer from "./Container";
import Header from "./Header";
import BackgroundContainer from "./BackgroundContainer";

const useStyles = makeStyles((theme) => ({
  deleteText: {
    marginBottom: theme.spacing(1),
  },
}));

// Email updating requires re-authentication before updating
export default () => {
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const { addNotification } = useNotificationSystem();

  const user = useLoadedAuthUser();

  if (!user) {
    return null;
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    if (user.email === email) {
      user
        .delete()
        .then(() => {
          window.location.replace("/");
        })
        .catch(() => {
          addNotification({
            message: "Could not delete user",
            level: "error",
          });
        });
    } else {
      addNotification({
        message: "Incorrect email",
        level: "error",
      });
    }
  };

  const inputs = [
    {
      key: "Email",
      label: "Email",
      type: "email",
      value: email,
      onChange: (e) => setEmail(e.target.value),
    },
  ];

  return (
    <AuthCheck>
      <Reauthenticate>
        <BackgroundContainer>
          <Header title="Delete account" />
          <ProfileContainer>
            <p>
              Please reenter your email "{user.email}" to delete your account
            </p>
            <Form
              classes={classes}
              onSubmit={handleSubmit}
              inputs={inputs}
              submitValue="Delete Account"
            />
          </ProfileContainer>
        </BackgroundContainer>
      </Reauthenticate>
    </AuthCheck>
  );
};
