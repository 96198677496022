import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    borderBottom: "1px solid #979797",
    color: "#fff",
  },
  backBtnCtr: {
    position: "absolute",
    left: 0,
    [theme.breakpoints.up("lg")]: {
      left: "40%",
    },
  },
  backBtn: {
    color: "#fff",
    marginLeft: theme.spacing(2),
    fontSize: 18,
  },
  title: {
    textAlign: "center",
  },
}));

const Header = ({ title, hideBackBtn }) => {
  const classes = useStyles();

  return (
    <div className={classes.header}>
      {!hideBackBtn && (
        <div className={classes.backBtnCtr}>
          <Link to="/profile" aria-label="Profile">
            <FontAwesomeIcon icon="chevron-left" className={classes.backBtn} />
          </Link>
        </div>
      )}
      <div className={classes.title}>
        <Typography variant="h5" component="h1">
          {title}
        </Typography>
      </div>
    </div>
  );
};

Header.propTypes = {
  title: PropTypes.string,
};

export default Header;
