 const musicQuiz70 = {
    id: 'musicQuiz70',
    name: "70's Music Trivia",
    linkTo: "/quizzes/musicQuiz70",
    description: 'Take our Quiz over Troubled Water',
    questions: [
        {
            question: "Which rock group recorded the song Barracuda?",
            choices: ['Blondie', 'Heart', 'Fleetwood Mac'],
            answerIdx: 1,    
            furtherInfo: {
            },
        },
        {
            question: 'Which Canadian rock band sang about an "American Woman"?',
            choices: ['Three Dog Night', 'Neil Diamond', 'The Guess Who'],
            answerIdx: 2,    
            furtherInfo: {
            },
        },
        {
            question: 'Who was the artist that recorded the song "Maggie May"?',
            choices: ['Janis Joplin', 'Rod Stewart', 'George Harrison'],
            answerIdx: 1,    
            furtherInfo: {
            },
        },
        {
            question: 'Who recorded the theme song from the film "Shaft"?',
            choices: ['Isaac Hayes', 'Chis Isaac', 'Samuel L. Jackson'],
            answerIdx: 0,    
            furtherInfo: {
            },
        },
        {
            question: 'The 1972 song "Lean on Me" was written and recorded by what artist?',
            choices: ['Mac Davis', 'Stevie Wonder', 'Bill Withers'],
            answerIdx: 2,    
            furtherInfo: {
            },
        },
        {
            question: 'Which former Beatles member formed the band "Wings"?',
            choices: ['Ringo Star', 'John Lennon', 'Paul McCartney'],
            answerIdx: 2,    
            furtherInfo: {
            },
        },
        {
            question: 'Which artist recorded the song "I Am Woman"?',
            choices: ['Helen Reddy', 'Tina Turner', 'Whitney Houston'],
            answerIdx: 0,    
            furtherInfo: {
            },
        },
        {
            question: 'The song "Night Fever" was written and performed by what artist?',
            choices: ['Donna Summer', 'Bee Gees', 'Commodores'],
            answerIdx: 1,    
            furtherInfo: {
            },
        },
        {
            question: 'The song that stayed at the top of the charts for weeks and weeks in the 70\'s is:',
            choices: [`Bee Gees' "Night Fever"`, `Rod Stewart's "Tonight's the Night"`, `Debby Boone's "You Light Up My Life"`],
            answerIdx: 2,    
            furtherInfo: {
            },
        },
        {
            question: 'Who sang the song "You Make Me Feel Like Dancing"?',
            choices: ['Andy Gibb', 'The Bee Gees', 'Leo Sayer'],
            answerIdx: 2,    
            furtherInfo: {
            },
        },
    ],

};

export default musicQuiz70;
