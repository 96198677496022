import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Stepper, Step, StepLabel, withStyles, StepConnector } from '@material-ui/core';

const useDotStyles = makeStyles(theme => ({
  baseDot: {
    width: 13,
    height: 12,
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    borderRadius: '50%',
    opacity: 1,
  },
  activeDot: {
    border:"2px solid #000000",
    height:"22px",
    width:"22px"
  },
  correctDot: {
    background: '#1AFF00 0% 0% no-repeat padding-box',
    border: '3px solid #1AFF00',
  },
  incorrectDot: {
    background: '#FF0000 0% 0% no-repeat padding-box',
    border: '3px solid #FF0000',
  }
}));

function ProgressDot(props) {
  const {active, completed, question} = props;
  const classes = useDotStyles();

  return <div className={clsx(classes.baseDot, {
    [classes.activeDot]: active,
    [classes.correctDot]: completed && question.correct,
    [classes.incorrectDot]: completed && !question.correct,
  })} />
}

const InvisibleConnector = withStyles({
  line: {
    width: 0,
  },
})(StepConnector);

const useStyles = makeStyles(theme => ({
  stepperSection: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    padding: '15px 0 0 0',
  },
  stepperBg: {
    backgroundColor: 'transparent',
    padding:"24px 0"
  },
  step: {
    paddingRight: 0
  }
}));

export default function Progress(props) {
  const {
    questions,
    idx,
  } = props;
  const classes = useStyles();

  return (
    <div className={classes.stepperSection}>
      <Stepper activeStep={idx} connector={<InvisibleConnector />} className={classes.stepperBg}>
        {
          questions.map((q) => (
            <Step key={q} classes={{horizontal: classes.step}}>
              <StepLabel StepIconComponent={ProgressDot} StepIconProps={{question: q}} />
            </Step>
          ))
        }
      </Stepper>
    </div>
  );
}