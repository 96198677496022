 const famousMistranslations = {
    id: 'famousMistranslations',
    name: 'Famous Mistranslations',
    linkTo: "/quizzes/famousMistranslations",
    explainerOn: true,
    description: 'Dive into some great mistranslations that may leave you convinced... that accurate communication is often boring and overrated!',
    questions: [
        { // 1
            question: "When 19th century astronomer Giovanni Schiaparelli discovered ‘canali’ in the martian surface, the whole world ran with the idea of intelligent canal-building martians. What did the Italian astronomer really mean by canali?",
            choices: [
                'Complex canals, built by an intelligence species.',
                'Simple canals, which may or may not have been built by martians.',
                'Channels between the light and dark areas he could see on the martian surface.',
                'A grad student misheard him asking for cannolis.' 
            ],
            answerIdx: 2,    
            furtherInfo: {
                explanation: "Though Schiaparelli used <i>canali</i> to mean channels between the light and dark areas he observed on the martian surface, this famous mistranslation inspired generations of science fiction, including _War of the Worlds_ by H. G. Wells.",
                sources: [
                    {
                        source: 'BBC',
                        url: 'https://www.bbc.com/culture/article/20150202-the-greatest-mistranslations-ever',
                    }
                ],
            },
        },
        { // 2
            question: "In 1977, U.S. President Jimmy Carter told the Polish people he “desired them carnally” -- at least, according to his interpreter. What did Carter really say?",
            choices: [
                'Carter wanted to learn about the Polish people’s desires for the future.',
                'Carter desired improved relations between the U.S. and Poland.',
                'Carter was happy to be in Poland.',
                'This is what Carter said.'
            ],
            answerIdx: 0,    
            furtherInfo: {
                explanation: "Far from intending to make illicit passes at the Polish people, President Carter wanted to learn about the Polish people's desires for the future. ",
                sources: [
                    {
                       source: 'BBC',
                        url: 'https://www.bbc.com/culture/article/20150202-the-greatest-mistranslations-ever',
                    }
                ],
            },
        },
        { // 3
            question: "That same trip, President Carter told the Polish he had “left the U.S., never to return” -- again, according to his interpreter. What did Carter really say?",
            choices: [
                'Carter would be in Poland for a week.',
                'Carter left the United States that morning.',
                'Carter had made a joke, but his translator said it into the microphone.',
                'This is what Carter said.'
            ],
            answerIdx: 1,    
            furtherInfo: {
                explanation: "President Carter stated that he had left the United States that morning.",
                sources: [
                    {
                        source: 'BBC',
                        url: 'https://www.bbc.com/culture/article/20150202-the-greatest-mistranslations-ever',
                    }
                ],
            },
        },
        { // 4
            question: "That same trip <i>again</i>, President Carter was met with silence while giving a toast at a banquet. He’d made sure to use a different interpreter, but what happened this time?",
            choices: [
                'The new interpreter mistranslated Carter’s speech into: “I did not enjoy my visit.”',
                'The new interpreter mistranslated Carter’s speech into: “I am ashamed of my flatulence.”',
                'The new interpreter was excellent; Carter was simply not good at making speeches.',
                'The new interpreter did not speak English, so didn’t translate anything Carter said.'
            ],
            answerIdx: 3,    
            furtherInfo: {
                explanation: "Astoundingly, the new interpreter for President Carter did not actually speak English and so did not translate a single word he said.",
                sources: [
                    {
                        source: 'BBC',
                        url: 'https://www.bbc.com/culture/article/20150202-the-greatest-mistranslations-ever',
                    }
                ],
            },
        },
        { // 5
            question: "During the Cold War Soviet premier Nikita Khrushchev told Western ambassadors, “We will bury you.” What did Khrushchev mean?",
            choices: [
                'The USSR will destroy the United States.',
                'Your own working class will revolt against you capitalists.',
                'Leave us alone, or things will end badly for you.',
                'He wanted a nap, but sadly he was using President Carter’s interpreters.'
            ],
            answerIdx: 1,    
            furtherInfo: {
                explanation: `Khrushchev’s aggressive sounding statement “We will bury you” helped to further freeze out the relationship between the US and the Soviets, what Khrushchev meant was closer to ‘Your own working class will revolt against you capitalists’.`,
                sources: [
                    {
                        source: 'BBC',
                        url: 'https://www.bbc.com/culture/article/20150202-the-greatest-mistranslations-ever',
                    }
                ],
            },
        },
        { // 6
            question:'One more from the political scene: President Kennedy is (in)famous for telling the people of Berlin, “I am a jelly doughnut.” What happened?',
            choices: [
                'This was a mistranslation of “I am a citizen of Berlin."',
                'This was a mistranslation of “Be proud you are citizens of Berlin.”',
                'This is an urban legend. Berliner IS a word for jelly doughnut, but not the word used in and around Berlin.',
                'This is an urban legend, based on Kennedy’s love for jelly doughnuts.'
            ],
            answerIdx: 2,    
            furtherInfo: {
                explanation: "Though it makes for a delightful anecdote, this one is an urban legend. While Berliner <i>is</i> a word for jelly doughnut, it is not the word used in and around Berlin.",
                sources: [
                    {
                        source: 'Smithsonianmag.com',
                        url: 'https://www.smithsonianmag.com/smart-news/why-does-everybody-think-jfk-said-im-jelly-donut-180963779/',
                    }
                ],
            },
        },
        { // 7
            question: "Donkey Kong got its name when Shigeru Miyamoto, the game’s designer, wanted to name it 'stupid monkey' and his translation dictionary claimed ‘donkey’ meant idiot in English.",
            choices: ['True', 'False'],
            answerIdx: 0,    
            furtherInfo: {
                explanation: "This is true; the iconic game franchise owes its name to Miyamoto’s dictionary erroneously claiming that ‘donkey’ was a word for idiot in English.",
                sources: [
                    {
                       source: 'WIRED',
                        url: 'https://www.wired.com/2016/10/miyamoto-donkey-kong-secrets/',
                    }
                ],
            },
        },
        { // 8
            question: 'Ford’s “Every car has a high-quality body” was mistranslated in Belgium as “We want bedroom photos of cars.”',
            choices: ['True', 'False'],
            answerIdx: 1,    
            furtherInfo: {
                explanation: `False. While Ford was not soliciting racy car photos, the Belgian translation of this slogan <i>did</i> read “Every car has a high-quality corpse”.`,
                sources: [
                    {
                        source: 'Medium',
                        url: 'https://medium.com/@ganeshasiagian/8-funniest-foreign-ad-translation-mistakes-b9f8a73a4773',
                    }
                ],
            },
        },
        { // 9
            question: `Coors Beer’s “Turn it loose” was mistranslated into Spanish as “Suffer from diarrhea."`,
            choices: ['True', 'False'],
            answerIdx: 0,    
            furtherInfo: {
                explanation: "Unfortunately, true -- the mistranslation, that is.",
                sources: [
                    {
                        source: 'Medium',
                        url: 'https://medium.com/@ganeshasiagian/8-funniest-foreign-ad-translation-mistakes-b9f8a73a4773',
                    }
                ],
            },
        },
          { // 10
            question: 'Pepsi’s “Come alive with Pepsi!” was mistranslated into Chinese as “Pepsi brings your ancestors back from the dead!"',
            choices: ['True', 'False'],
            answerIdx: 0,    
            furtherInfo: {
                explanation: `This one is true; in China, Pepsi’s “Come alive with Pepsi!” slogan was translated as “Pepsi brings your ancestors back from the dead!”`,
                sources: [
                    {
                        source: 'Medium',
                        url: 'https://medium.com/@ganeshasiagian/8-funniest-foreign-ad-translation-mistakes-b9f8a73a4773',
                    }
                ],
            },
        },
    ],

};

export default famousMistranslations;