import React from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const useStyles = makeStyles((theme) => ({
  settingLink: {
    marginBottom: theme.spacing(2),
    cursor: "pointer",
    "& > *": {
      display: "flex",
      justifyContent: "space-between",
      color: "#fff",
      textDecoration: "none",
      fontSize: 18,
    },
  },
  button: {
    width: "100%",
    backgroundColor: "transparent",
    padding: 0,
    border: 0,
    display: "flex",
    justifyContent: "space-between",
  },
  chevron: {
    color: "#fff",
  },
}));

const LinkContent = ({ children }) => {
  const classes = useStyles();

  return (
    <>
      <span>{children}</span>
      <FontAwesomeIcon icon="chevron-right" className={classes.chevron} />
    </>
  );
};

export default ({ to, onClick, href, children }) => {
  const classes = useStyles();

  return (
    <div className={classes.settingLink}>
      {onClick ? (
        <button className={classes.button} onClick={onClick}>
          <LinkContent>{children}</LinkContent>
        </button>
      ) : href ? (
        <a href={href} target="_blank" rel="noopener noreferrer">
          <LinkContent>{children}</LinkContent>
        </a>
      ) : (
        <Link to={to}>
          <LinkContent>{children}</LinkContent>
        </Link>
      )}
    </div>
  );
};
