import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useAuth } from "reactfire";
import useLoadedAuthUser from "../../hooks/useLoadedAuthUser";
import { useHistory, Link } from "react-router-dom";
import useWindowDimensions from "../../utils/useWindowDimensions";
import BackButton from "../BackButton";
import ProfilePic from "./ProfilePic";
import AuthCheck from "../AuthCheckWrapper";
import EditPhotoDropdown from "./EditPhoto";
import SettingLink from "./SettingLink";
import BackgroundContainer from "./BackgroundContainer";
import Logo from "../Logo";
import BottomNav from "../BottomNav";
import useDeviceDetect from "../../hooks/useDeviceDetect";

const useStyles = makeStyles((theme) => ({
  container: Object.assign({
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    minHeight: ({ windowHeight }) => windowHeight,
    color: "#fff",
    paddingTop: "2vh",
    "& > *": {
      marginBottom: theme.spacing(1),
    },
  }),

  profilePicGroup: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginTop: theme.spacing(10), // NOTE Temporary for demo before header was created
    marginBottom: theme.spacing(5),
  },
  profilePicBtn: {
    border: "transparent",
    background: "none",
    color: "#d8d8d8",
    fontSize: 14,
    cursor: "pointer",
  },
  loggedInText: {
    color: "#d8d8d8",
    fontSize: 14,
  },
  settingLinks: {
    width: "75vw",
    [theme.breakpoints.up("sm")]: {
      width: "50vw",
    },
  },

  logoContainer: {
    marginTop: "5px",
    alignSelf: "flex-start",
    [theme.breakpoints.up("lg")]: {
      alignSelf: "center",
    },
    [theme.breakpoints.down("md")]: {
      marginLeft: theme.spacing(2),
    },
    "& > div >*": {
      textDecoration: "none",
    },
  },
  backButton: {
    position: "absolute",
    left: "50px",
    top: "20px",
    zIndex: 1,
    "& > button > svg": {
      fontSize: "40px",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "35px",
      position: "inherit",
      paddingRight: "10px",
      "& > button > svg": {
        fontSize: "35px",
      },
    },
  },
}));

export default () => {
  const { width, height } = useWindowDimensions();
  const {isMobile} = useDeviceDetect();
  const [windowHeight, setWindowHeight] = useState(height);
  const classes = useStyles({
    windowHeight: windowHeight,
  });
  const [showDropdown, setShowDropdown] = useState(false);
  const [photoURL, setPhotoURL] = useState("");
  const auth = useAuth();
  const user = useLoadedAuthUser((user) => setPhotoURL(user.photoURL));

  const getLoginProvider = () => {
    if (user) {
      const { providerData } = user;
      return providerData[0].providerId;
    }
    return "";
  };
  const loginProvider = getLoginProvider();
  const isPasswordLogin = loginProvider === "password";

  const toggleDropdown = ({ resetPhotoURL } = {}) => {
    const bottomNavHeight = 75; // px, see bottomNavbar.css
    setWindowHeight(height + bottomNavHeight);
    setShowDropdown(!showDropdown);

    if (resetPhotoURL) {
      setPhotoURL(user.photoURL);
    }
  };

  const history = useHistory();
  const handleSignOutClick = () => {
    auth.signOut().then(() => history.push("/"));
  };

  const backButtonOnKeyDown = (e) => {
    if (e.keycode === 13) {
      history.goBack();
    }
  };

  return (
    <AuthCheck>
      <BackgroundContainer>
        <div className={classes.container}>
          <div className={classes.logoContainer}>
            <div style={{display:"flex", alignItems:"center"}}>
              <div className={classes.backButton}>
                <BackButton
                  onClick={() => history.goBack()}
                  onKeyDown={backButtonOnKeyDown}
                />
              </div>
              <Link to="/quizzes">
                <Logo />
              </Link>
            </div>
          </div>
          <div className={classes.profilePicGroup}>
            <ProfilePic src={photoURL} />
            {isPasswordLogin ? (
              <button
                className={classes.profilePicBtn}
                onClick={toggleDropdown}
              >
                Change Profile Image
              </button>
            ) : (
              <div className={classes.loggedInText}>
                Logged in using {loginProvider} account
              </div>
            )}
          </div>
          {showDropdown ? (
            <EditPhotoDropdown
              setPhotoURL={setPhotoURL}
              photoURL={photoURL}
              user={user}
              onClose={toggleDropdown}
            />
          ) : (
            <div className={classes.settingLinks}>
              <SettingLink to="/profile/name">Name</SettingLink>

              {isPasswordLogin && (
                <>
                  <SettingLink to="/profile/change_password">
                    Change Password
                  </SettingLink>
                  <SettingLink to="/profile/email">Email</SettingLink>
                </>
              )}
              <SettingLink to="/profile/delete_account">
                Delete Account
              </SettingLink>
              <SettingLink href="https://form.asana.com?k=eMP6ShmOE9Crn34IOy7b9A&d=366847836913442">
                Report A Problem
              </SettingLink>
              <SettingLink onClick={handleSignOutClick}>Sign Out</SettingLink>
            </div>
          )}
        </div>
        {(width <= 1025 && isMobile) && <BottomNav />}
      </BackgroundContainer>
    </AuthCheck>
  );
};
