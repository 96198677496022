 const everyBreathQuiz = {
    name: 'Every Breath You Take',
    id: "everyBreathQuiz",
    linkTo: "/quizzes/everyBreathQuiz",
    explainerOn: true,
    description: `How much do you know about breathing -- something you've been doing your whole life?`,
    questions: [
        {
            question: "How many breaths does the average healthy adult take in a minute?",
            choices: ['3 To 5 ', '6 To 8 ', '12 To 16 ', '30 To 35'],
            answerIdx: 2,    
            furtherInfo: {
                explanation: (
                    `The average adult breaths an average of 12 to 16 breaths a minute`
                ),
                sources: [
                    {
                        source: 'Quora',
                        url: 'https://www.quora.com/What-is-the-average-number-of-Breaths-in-an-average-lifetime',
                    }
                ],
            },
        },
        {
            question: "How many breaths does an average adult take in a day?",
            choices: ['200', '2000', '20,000', '2E5'],
            answerIdx: 2,    
            furtherInfo: {
                explanation: (
                    `According to the US EPA, an average person takes between 17,000 and 23,000 breaths a day or about 20,000 breaths a day.`
                ),
                sources: [
                    {
                        source: 'US EPA',
                        url: 'https://blog.epa.gov/2014/04/28/how-many-breaths-do-you-take-each-day/',
                    }
                ],
            },
        },
        {
            question: "How many breaths do we take over our lifetime?",
            choices: ['6E6', '6E7', '6E8', '6E9'],
            answerIdx: 2,    
            furtherInfo: {
                explanation: (
                    `The average person takes about 600 million of 6E8 breaths in their lifetimes.`
                ),
                sources: [
                    {
                        source: 'Quora',
                        url: 'https://www.quora.com/What-is-the-average-number-of-Breaths-in-an-average-lifetime',
                    }
                ],
            },
        },
        {
            question: "How many molecules of gas is in each breath?",
            choices: ['2.5E15', '2.5E18', '2.5E21', '2.5E24'],
            answerIdx: 3,    
            furtherInfo: {
                explanation: (
                    `At standard room temperature and pressure, you’re breathing in roughly 2.5E24 or 25 sextillion molecules every time you take a breath.`
                ),
                sources: [
                    {
                        source: 'National Geographic',
                        url: 'https://www.nationalgeographic.com/news/2017/08/air-gas-caesar-last-breath-sam-kean/',
                    }
                ],
            },
        
        },
        {
            question: "Oxygen is needed for us and animals to live but only constitutes a fraction of the air we breathe. What percentage of our air is composed of oxygen?",
            choices: ['11%', '21%', '41%', '81%'],
            answerIdx: 1,    
            furtherInfo: {
                explanation: (
                    `Oxygen makes up around one-fifth or more precisely 21% of the air we breathe. Most of the air is composed of nitrogen at 78%.`
                ),
                sources: [
                    {
                        source: 'Wikipedia',
                        url: 'https://en.wikipedia.org/wiki/Atmosphere_of_Earth',
                    }
                ],
            },
        
        },
        {
            question: "Oxygen is converted into carbon dioxide when humans and animals breathe out. What is the percentage of carbon dioxide in the air that you breathe out?",
            choices: ['0.04%', '0.4%', '4%', '40%'],
            answerIdx: 2,    
            furtherInfo: {
                explanation: (
                    `About a fifth of the oxygen you breathe in gets converted into carbon dioxide. As a result, the content of the air that you breathe out is 4% carbon dioxide.`
                ),
                sources: [
                    {
                        source: 'UCSB',
                        url: 'http://scienceline.ucsb.edu/getkey.php?key=5389#:~:text=Answer%201%3A,~0.04%25%20of%20the%20atmosphere.',
                    }
                ],
            },
        
        },
        {
            question: "In addition to carbon dioxide, you also exhale small amounts of water. Throughout the day, how many ounces of water do you exhale?",
            choices: ['3', '7', '14', '21'],
            answerIdx: 2,    
            furtherInfo: {
                explanation: (
                    `You exhale about 14 ounces (0.4 liters) of water each day. If you exercise, more water will be exhaled.`
                ),
                sources: [
                    {
                        source: 'PHN',
                        url: 'https://pulmonaryhypertensionnews.com/2017/12/20/8-fun-facts-lungs/',
                    }
                ],
            },
        
        },
        {
            question: "What is the world's record on breath held underwater?",
            choices: ['3 Min', '6 Min', '12 Min', '24 Min'],
            answerIdx: 3,    
            furtherInfo: {
                explanation: (
                    `The record for the longest breath held underwater is 24 minutes and 3 seconds which was made in 2016 by Spanish diver Aleix Segura Vendrell.`
                ),
                sources: [
                    {
                        source: 'Guinness World Records',
                        url: 'https://www.guinnessworldrecords.com/world-records/longest-time-breath-held-voluntarily-(male)',
                    }
                ],
            },
        
        },
        {
            question: "There are germs floating in the air and they are invisible. Almost all of them are harmless and do not causes diseases; however, you are constantly breathing them in. On an average day, about how many of these microorganism are you breathing in?",
            choices: ['100', '1,000', '10,000', '100,000'],
            answerIdx: 3,    
            furtherInfo: {
                explanation: (
                    `You breath in between 100,000 and 1,000,000 microorganisms each day.`
                ),
                sources: [
                    {
                        source: 'Straits Times',
                        url: 'https://www.straitstimes.com/singapore/scientists-say-we-breathe-in-1-million-micro-organisms-including-invisible-fungi-every-day',
                    }
                ],
            },
        
        },
        {
            question: "Maya Angelou says “Life is not measured by how many breaths we take, but by the moments that take our breath away”.",
            choices: ['True', 'False'],
            answerIdx: 0,    
            furtherInfo: {
            },
        
        },
    ],

};

export default everyBreathQuiz;
