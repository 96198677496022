 const heartyNumbers = {
    name: 'Matters of the Heart',
    id: 'Sf03leaXWwqeWqlEqXD4',
    linkTo: "/quizzes/heartyNumbers",
    explainerOn: true,
    description: "We all rely on our hearts, but how much do you really know about that muscle beating in your chest?",
    questions: [
        { // 1
            question: "How many times does the human heart beat in a lifetime?",
            choices: ['2.5 million', '25 million', '2.5 billion', '250 billion'],
            answerIdx: 2,    
            furtherInfo: {
                explanation: "The human heart beats around 2.5 billion times in an average lifetime.",
                sources: [
                    {
                        source: 'PBS',
                        url: 'https://www.pbs.org/wgbh/nova/heart/heartfacts.html',
                    }
                ],
            },
        },
        { // 2
            question: "How many gallons of blood flow through your heart each day?",
            choices: ['2 gallons', '20 gallons', '200 gallons', '2,000 gallons'],
            answerIdx: 3,    
            furtherInfo: {
                explanation: "Each day, 2,000 gallons of blood flow through your heart.",
                sources: [
                    {
                        source: 'Healthline',
                        url: 'https://www.healthline.com/health/fun-facts-about-the-heart',
                    }
                ],
            },
        },
        { // 3
            question: "How many miles of blood vessels are in an average human?",
            choices: ['6 miles', '60 miles', '600 miles', '60,000 miles'],
            answerIdx: 3,    
            furtherInfo: {
                explanation: "There are 60,000 miles of blood vessels in an average human.",
                sources: [
                    {
                        source: 'Cleaveland Clinic',
                        url: 'https://health.clevelandclinic.org/facts-about-the-heart/',
                    }
                ],
            },
        },
        { // 4
            question: "How much blood is in an average human body?",
            choices: ['0.6 quarts', '6.0 quarts', '60 quarts', '600 quarts'],
            answerIdx: 1,    
            furtherInfo: {
                explanation: "The average human body contains around 6 quarts of blood.",
                sources: [
                    {
                        source: 'PBS',
                        url: 'https://www.pbs.org/wgbh/nova/heart/heartfacts.html',
                    }
                ],
            },
        },
        { // 5
            question: "A human heart usually beats between 60 and 100 beats per minute (bpm), What is the fastest heart rate recorded in a human?",
            choices: ['180 bpm', '280 bpm', '380 bpm', '480 bpm'],
            answerIdx: 2,    
            furtherInfo: {
                explanation: "The fastest heart rate recorded in a human is 380 beats per minute.",
                sources: [
                    {
                        source: 'PBS',
                        url: 'https://www.pbs.org/wgbh/nova/heart/heartfacts.html',
                    }
                ],
            },
        },
        { // 6
            question: "Heart disease is the leading cause of death in the US. How many Americans die from a heart related illness each year?",
            choices: ['6,500', '65,000', '650,000', '6,500,000'],
            answerIdx: 2,    
            furtherInfo: {
                explanation: "Approximately 650,000 Americans die from a heart related illness each year.",
                sources: [
                    {
                        source: 'CDC',
                        url: 'https://www.cdc.gov/heartdisease/facts.htm',
                    }
                ],
            },
        },
        { // 7
            question: "How much does heart disease cost America in total each year?",
            choices: ['$200 million', '$2 billion', '$20 billion', '$200 billion'],
            answerIdx: 3,    
            furtherInfo: {
                explanation: "Each year heart disease costs America $200 billion.",
                sources: [
                    {
                        source: 'CDC',
                        url: 'https://www.cdc.gov/heartdisease/facts.htm',
                    }
                ],
            },
        },
        { // 8
            question: "The animal with the largest heart is the blue whale. On average, how much does that heart weigh?",
            choices: ['4 pounds', '40 pounds', '400 pounds', '4,000 pounds'],
            answerIdx: 2,    
            furtherInfo: {
                explanation: "On average, the heart of a blue whale weighs 400 pounds.",
                sources: [
                    {
                        source: 'College of Veterinary Medicine, NC State Univ.',
                        url: 'https://cvm.ncsu.edu/10-amazing-animal-heart-facts/',
                    }
                ],
            },
        },
        { // 9
            question: "The pygmy shrew is a mammal with the fastest heart rate in the world. How fast does it beat?",
            choices: ['300 bpm', '600 bpm', '900 bpm', '1,200 bpm'],
            answerIdx: 3,    
            furtherInfo: {
                explanation: "A pygmy shrew’s heart beats at 900 beats per minute!",
                sources: [
                    {
                        source: 'National Geographic',
                        url: 'https://www.nationalgeographic.com/animals/article/160213-animals-science-hearts-valentines-day-giraffes',
                    }
                ],
            },
        },
    ],

};

export default heartyNumbers;