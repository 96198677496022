 const californiaHighSpeedRail = {
    name: "California's High Speed Rail",
    id: 'zhu3EtbPolxjcVM813pQ',
    linkTo: "/quizzes/californiaHighSpeedRail",
    explainerOn: true,
    description: "California’s High-Speed Rail Project has been in the works for a decade, but delayed for a myriad of reasons. It may now be seeing new life with President Biden’s emphasis on investment in infrastructure. What will the California High-Speed Rail cost, and is it worth it?",
    questions: [
        {
            //question 1//
            question: "Originally proposed to connect San Francisco, California with Los Angeles, California, how long would a trip between the two cities take on the high-speed rail line?",
            choices: ['40 minutes', '1 hour, 40 minutes', '2 hours, 40 minutes', '3 hours, 40 minutes'],
            answerIdx: 2,    
            furtherInfo: {
                explanation: (
                    `Reaching speeds over 200mph, traveling between San Francisco and Los Angeles on the high-speed rail line would take 2 hours and 40 minutes, which compares favorably to the 6 hour drive time as well as the hour-long short-haul flight between the cities.`
                ),
                sources: [
                    {
                        source: 'LA Times',
                        url: 'https://www.latimes.com/california/newsletter/2021-06-14/california-bullet-train-update-essential-california',
                    },
                ],
            },
        },
        {
            //question 2//
           question: "What was the originally proposed length of the high-speed rail track?",
            choices: ['80 mi', '800 mi', '8,000 mi', '80,000 mi'],
            answerIdx: 1,    
            furtherInfo: {
                explanation: (
                    `The original proposal for the high-speed rail called for approximately 400 miles of track going each way for 800 miles of track in total.`
                ),
                sources: [
                    {
                        source: 'California High-Speed Rail Authority',
                        url: 'https://hsr.ca.gov/high-speed-rail-in-california/project-sections/',
                    },
                ],
            },
        },
        {
            //question 3//
            question: "What was the original proposed cost of the entire High-Speed Rail Project?",
            choices: ['$3,300,000', '$33,000,000', '$33,000,000,000', '$330,000,000,000'],
            answerIdx: 2,    
            furtherInfo: {
                explanation: (
                    `The original proposed cost for the California High-Speed Rail Project was $33 billion.`
                ),
                sources: [
                    {
                        source: 'LA Times',
                        url: 'https://www.latimes.com/california/newsletter/2021-06-14/california-bullet-train-update-essential-california',
                    },
                ],
            },
        },
        {
            //question 4//
            question: "As of 2020, what is the current projected cost to complete the high-speed rail?",
            choices: ['$9,980,000', '$99,800,000', '$998,000,000', '$99,800,000,000'],
            answerIdx: 3,    
            furtherInfo: {
                explanation: (
                    `As of 2020 the projected cost for the completion of the high-speed rail was $99.8 billion, with a projected completion date of 2040 (note: this is also a revision from the original proposed completion date of 2029).`
                ),
                sources: [
                    {
                        source: 'LA Times',
                        url: 'https://www.latimes.com/california/newsletter/2021-06-14/california-bullet-train-update-essential-california',
                    },
                ],
            },
        },
        {
            //question 5//
            question: "If the $99.8 billion cost of the High-Speed Rail Project were spread equally across all Californians, approximately 40 million, about how much would each Californian pay?",
            choices: ['$2,500', '$5,000', '$10,000', '$20,000'],
            answerIdx: 0,    
            furtherInfo: {
                explanation: (
                    `If the $99.8 billion price tag of the high-speed rail were spread across all 40 million Californians, each individual would pay about $2,500 ($100 billion / 40 million California residents = $2,500 per resident).`
                ),
                sources: [
                    {
                        source: 'US Census',
                        url: 'https://www.census.gov/quickfacts/CA',
                    },
                ],
            },
        },
        {
            //question 6//
            question: "About how many miles of track are currently being worked on as Phase 1 of the High-Speed Rail Project?",
            choices: ['6 mi', '16 mi', '160 mi', '1,600 mi'],
            answerIdx: 2,    
            furtherInfo: {
                explanation: (
                    `Currently about 160 miles of track connecting Bakersfield to Merced are being worked on as Phase 1 of the project.`
                ),
                sources: [
                    {
                        source: 'LA Times',
                        url: 'https://www.latimes.com/california/newsletter/2021-06-14/california-bullet-train-update-essential-california',
                    },
                ],
            },
        },
        {
            //question 7//
            question: "When completed, approximately how many metric tons of CO2 emissions could the high-speed rail save each year?",
            choices: ['2,000', '20,000', '200,000', '2,000,000'],
            answerIdx: 3,    
            furtherInfo: {
                explanation: (
                    `When completed, the high-speed rail could save 2 million metric tons of CO2 emissions, which is equivalent to taking 400,000 cars off the road.`
                ),
                sources: [
                    {
                        source: 'California High-Speed Rail Authority',
                        url: 'https://hsr.ca.gov/high-speed-rail-in-california/',
                    },
                ],
            },
        },
        {
            //question 8//
            question: "What percentage of the approximately 200,000 tons of construction-related waste generated by the High-Speed Rail Project is recycled?",
            choices: ['7%', '27%', '57%', '97%'],
            answerIdx: 3,    
            furtherInfo: {
                explanation: (
                    `97% of all construction-related waste generated by the project is recycled, with only 3% going to landfills.`
                ),
                sources: [
                    {
                        source: 'Environmental and Energy Study Institute',
                        url: 'https://www.eesi.org/papers/view/fact-sheet-high-speed-rail-development-worldwide',
                    },
                ],
            },
        },
        {
            //question 9//
            question: "From 2006 to 2020, the High-Speed Rail Project generated approximately how much economic activity?",
            choices: ['$11,400,000', '$114,000,000', '$11,400,000,000', '$114,000,000,000'],
            answerIdx: 2,    
            furtherInfo: {
                explanation: (
                    `From 2006 to 2020, the California High-Speed Rail project generated up to $11.4 billion in total economic activity.`
                ),
                sources: [
                    {
                        source: 'California High-Speed Rail Authority',
                        url: 'https://hsr.ca.gov/wp-content/uploads/2021/04/Economic_Impact.pdf',
                    },
                ],
            },
        },
        {
            //question 10//
            question: "What percent of the total project expenditure to date has taken place in disadvantaged communities throughout California?",
            choices: ['25%', '55%', '75%', '95%'],
            answerIdx: 1,    
            furtherInfo: {
                explanation: (
                    `To date, 55% of the High-Speed Rail Project’s total expenditure has taken place in disadvantaged communities in California.`
                ),
                sources: [
                    {
                        source: 'California High-Speed Rail Authority',
                        url: 'https://hsr.ca.gov/wp-content/uploads/2021/04/Economic_Impact.pdf',
                    },
                ],
            },
        },
        {
            //question 11//
            question: "When did the world’s first high-speed railway begin operations?",
            choices: ['1964', '1984', '2004', '2014'],
            answerIdx: 0,    
            furtherInfo: {
                explanation: (
                    `The first high-speed railway, Japan’s Shinkansen or ‘bullet train’, began operations in 1964. The next public high-speed rail to be operational was France’s line between Paris and Lyon, which opened 17 years later in 1981.`
                ),
                sources: [
                    {
                        source: 'Environmental and Energy Study Institute',
                        url: 'https://www.eesi.org/papers/view/fact-sheet-high-speed-rail-development-worldwide',
                    },
                ],
            },
        },
        {
            //question 12//
            question: "China’s Beijing-Shanghai High-Speed Rail Line, which covers 820 miles, cost how much to build?",
            choices: ['$250,000,000', '$2,500,000,000', '$25,000,000,000', '$250,000,000,000'],
            answerIdx: 2,    
            furtherInfo: {
                explanation: (
                    `The Beijing-Shanghai High-Speed Rail line, which opened in 2011 and spans 820 miles, cost approximately $25 billion to build. That’s a cost of about $30 million per mile.`
                ),
                sources: [
                    {
                        source: 'Railway Technology',
                        url: 'https://www.railway-technology.com/projects/beijing/',
                    },
                ],
            },
        },
        {
            //question 13//
            question: "At approximately $100 billion to build, and stretching across 400 miles, about how much is the price of the California High-Speed Rail Project per mile?",
            choices: ['$25,000,000', '$250,000,000', '$2,500,000,000', '$25,000,000,000'],
            answerIdx: 1,    
            furtherInfo: {
                explanation: (
                    `The California High-Speed Rail Project is on pace to cost about $250 million per mile (100 billion/400 = 250 million).`
                ),
                sources: [
                    {
                        source: 'California High-Speed Rail Authority',
                        url: 'https://hsr.ca.gov/high-speed-rail-in-california/',
                    },
                ],
            },
        },
    ],

};

export default californiaHighSpeedRail;