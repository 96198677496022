const secondLanguage = { 
  name: "Second Language", 
  id: 'uRp2xjMKRewxbrahK3Xc', 
  linkTo: "/quizzes/secondLanguage", 
  explainerOn: true, 
  subheading: `Do you speak a second language? What about your country? You probably know what the most common primary language is in your country, but what about the second?`,
  description: `Primary or second? What's the difference? As you probably know, a person's primary language is the language they grow up speaking and are most comfortable with, also commonly known as their native language. A second language is one a person learns in addition to their primary language. Around the globe, English is the most widely spoken second language. This quiz, however, is not going to ask you about the most commonly spoken second languages (the answer would almost uniformly be English, which would be boring). Instead, we are going to look at the second most common primary languages. Do you know what your country's second language is?`,
  questions: [
      { // Q-1
          question: "What is the second most common primary language in the United States?",
          choices: ['Russian', 'Chinese', 'Spanish', 'English'],
          answerIdx: 2,
          furtherInfo: {
              explanation: (
                  `After English, the second most common primary language in the U.S. is Spanish.`
              ),
              sources: [
                  {
                      source: "Visual Capitalist",
                      url: 'https://www.visualcapitalist.com/mapped-second-primary-languages-around-the-world/?utm_source=Visual+Capitalist+Infographics+%28All%29&utm_campaign=b0bb8829cd-EMAIL&utm_medium=email&utm_term=0_31b4d09e8a-b0bb8829cd-45131207',
                  },
              ],
          },
      },
      { // Q-2
          question: "What is the second most common primary language in Canada?",
          choices: ['French', 'English', 'Spanish', 'Gaelic'],
          answerIdx: 0,    
          furtherInfo: {
              explanation: (
                  `Canada famously has a large French-speaking population, so it is no surprise the second most common primary language in Canada is French.`
              ),
              sources: [
                {
                  source: "Visual Capitalist",
                  url: 'https://www.visualcapitalist.com/mapped-second-primary-languages-around-the-world/?utm_source=Visual+Capitalist+Infographics+%28All%29&utm_campaign=b0bb8829cd-EMAIL&utm_medium=email&utm_term=0_31b4d09e8a-b0bb8829cd-45131207',
                },
              ],
          },
      },
      { // Q-3 
        question: "What is the second most common primary language in the United Kingdom?",
        choices: ['Punjabi', 'Gaelic', 'French', 'Polish'],
        answerIdx: 3,    
        furtherInfo: {
            explanation: (
                `You may have, like me, been inclined to say French here as well, but the second most common primary language in the U.K. is actually Polish. This is largely due to the influx of Polish immigrants the U.K. saw during and after WWII.`
            ),
            sources: [
              {
                source: "Wikipedia",
                url: 'https://en.wikipedia.org/wiki/Poles_in_the_United_Kingdom#Post_World_War_II',
              },
            ],
        },
      },
      { // Q-4
        question: "What is the second most common primary language in Russia?",
        choices: ['Hungarian', 'Tatar', 'English', 'Mandarin'],
        answerIdx: 1,    
        furtherInfo: {
            explanation: (
                `The second most common primary language in Russia is Tatar, which is the native language of the indigenous Tatar people who live mainly in west-central Russia.`
            ),
            sources: [
              {
                source: "Britannica",
                url: 'https://www.britannica.com/topic/Tatar',
              },
            ],
        },
      },
      { // Q-5
        question: "What is the second most common primary language in Mexico?",
        choices: ['Portuguese', 'English', 'German', 'Nahuatl'],
        answerIdx: 3,    
        furtherInfo: {
            explanation: (
                `The second most common primary language in Mexico is Nahuatl. Nahuatl, also spelled Nawatl, is the Uto-Aztecan language spoken historically by the Aztec and Toltec civilizations of Mexico. Today, it is still spoken by over 1 million people.`
            ),
            sources: [
              {
                source: "Britannica",
                url: 'https://www.britannica.com/topic/Nahuatl-language',
              },
            ],
        },
      },
      { // Q-6
        question: "What is the second most common primary language in Saudi Arabia?",
        choices: ['Tagalog', 'Turkish', 'Kurdish', 'English'],
        answerIdx: 0,    
        furtherInfo: {
            explanation: (
                `The second most common primary language in Saudi Arabia is Tagalog, which is the primary language of the Philippines. It has migrated to Saudi Arabia with Filipino and other Malaysian overseas workers.`
            ),
            sources: [
              {
                source: "Britannica",
                url: 'https://www.britannica.com/topic/Tagalog-language',
              },
            ],
        },
      },
      { // Q-7
        question: "What is the second most common primary language in Australia?",
        choices: ['German', 'Portuguese', 'Mandarin', 'Maori'],
        answerIdx: 2,    
        furtherInfo: {
            explanation: (
                `The second most common language in Australia is Mandarin Chinese.`
            ),
            sources: [
              {
                source: "Visual Capitalist",
                url: 'https://www.visualcapitalist.com/mapped-second-primary-languages-around-the-world/?utm_source=Visual+Capitalist+Infographics+%28All%29&utm_campaign=b0bb8829cd-EMAIL&utm_medium=email&utm_term=0_31b4d09e8a-b0bb8829cd-45131207',
              },
            ],
        },
      },
      { // Q-8
        question: "What is the second most common primary language in Ethiopia?",
        choices: ['Italian', 'Amharic', 'Swahili', 'Danish'],
        answerIdx: 1,    
        furtherInfo: {
            explanation: (
                `26% of Ethiopians speak Amharic as their primary language, making it the second most common primary language in the country. Ethiopia has no official spoken language but the largest percentage of the population (35%) speak Oromo. Amharic is what is known as a Semitic language, while Oromo belongs to the Afro-Asiatic family of languages.`
            ),
            sources: [
              {
                source: "Britannica",
                url: 'https://www.britannica.com/place/Ethiopia/Ethnic-groups-and-languages',
              },
            ],
        },
      },
      { // Q-9
        question: "What is the second most common primary language in Spain?",
        choices: ['Catalan', 'English', 'French', 'Mandarin'],
        answerIdx: 0,    
        furtherInfo: {
            explanation: (
                `The second most common primary language in Spain is Catalan. It is spoken chiefly in Catalonia, Valencia, and Andorra, and was historically the official language of the Kingdom of Aragon in the 12th century.`
            ),
            sources: [
              {
                source: "Britannica",
                url: 'https://www.britannica.com/summary/Catalan-language',
              },
            ],
        },
      },
      { // Q-10
        question: "Globally, what is the most common second primary language?",
        choices: ['Russian', 'English', 'Cantonese', 'Arabic'],
        answerIdx: 1,    
        furtherInfo: {
            explanation: (
                `As I said in the introduction, English is the most common second language--and also the most common <i>second primary</i> language across the globe.`
            ),
            sources: [
              {
                source: "Visual Capitalist",
                url: 'https://www.visualcapitalist.com/mapped-second-primary-languages-around-the-world/?utm_source=Visual+Capitalist+Infographics+%28All%29&utm_campaign=b0bb8829cd-EMAIL&utm_medium=email&utm_term=0_31b4d09e8a-b0bb8829cd-45131207',
              },
            ],
        },
      },
  ],

};

export default secondLanguage;