const dd2StoppingClimateChange = {
  name: 'Drawdown 2: Stopping Climate Change',
  id: 'DxNgoA3pqoUGwr3z06ch',
  linkTo: "/quizzes/dd2-stopping-climate-change",
  explainerOn: true,
  subheading: 'Only <i>you</i> can stop climate change',
  description: "Project Drawdown’s mission is to combat and reverse climate change as quickly, safely, and equitably as possible. But there are some facts we need to face before we can join in this mission.",
  questions: [
      {
          //question 1//
          question: "Drawdown is the moment when greenhouse gas (GHG) emissions stop increasing, and start decreasing.",
          choices: ['True', 'False'],
          answerIdx: 0,    
          furtherInfo: {
              explanation: (
                  `<b>This is true.</b> Drawdown is the moment in the future when GHG emissions stabilize, then start decreasing, which is when we would start to reverse climate change.`
              ),
              sources: [
                  {
                      source: 'Project Drawdown',
                      url: 'https://drawdown.org/climate-solutions-101/unit-2-how-to-stop-climate-change',
                  },
              ],
          },
      },
      {
          //question 2//
         question: "Greenhouse gasses are gasses used as pesticides in greenhouses.",
          choices: ['True', 'False'],
          answerIdx: 1,    
          furtherInfo: {
              explanation: (
                  `<b>This is false.</b> Greenhouse gasses are gasses that, when emitted into the atmosphere, trap more of the sun’s heat, warming the planet and amplifying the natural greenhouse effect of our atmosphere.`
              ),
              sources: [
                  {
                      source: 'Project Drawdown',
                      url: 'https://drawdown.org/climate-solutions-101/unit-2-how-to-stop-climate-change',
                  },
              ],
          },
      },
      {
          //question 3//
          question: "Greenhouse gasses that are produced by humans are known as <i>anthropogenic greenhouse gasses</i>.",
          choices: ['True', 'False'],
          answerIdx: 0,    
          furtherInfo: {
              explanation: (
                  `<b>This is true.</b> <i>Anthropogenic greenhouse gasses</i> are, very simply, greenhouse gasses that have a <i>human</i> cause rather than a natural one.`
              ),
              sources: [
                  {
                      source: 'Project Drawdown',
                      url: 'https://drawdown.org/climate-solutions-101/unit-2-how-to-stop-climate-change',
                  },
              ],
          },
      },
      {
          //question 4//
          question: "How many degrees <i>colder</i> was Earth during the last ice age?",
          choices: ['1⁰C', '2⁰C', '3⁰C', '4⁰C'],
          answerIdx: 2,    
          furtherInfo: {
              explanation: (
                  `During the last ice age, Earth was <i>only 3⁰C colder</i> than normal. But that 3 degree difference effectively turned Earth into a different planet, as far as human habitation goes.`
              ),
              sources: [
                  {
                      source: 'Project Drawdown',
                      url: 'https://drawdown.org/climate-solutions-101/unit-2-how-to-stop-climate-change',
                  },
              ],
          },
      },
      {
          //question 5//
          question: "How much have we <i>warmed</i> the planet so far?",
          choices: ['1⁰C', '2⁰C', '3⁰C', '4⁰C'],
          answerIdx: 0,    
          furtherInfo: {
              explanation: (
                  `We humans have already warmed the planet by 1⁰C.`
              ),
              sources: [
                  {
                      source: 'Project Drawdown',
                      url: 'https://drawdown.org/climate-solutions-101/unit-2-how-to-stop-climate-change',
                  },
              ],
          },
      },
      {
          //question 6//
          question: "<strong>Fossil fuels</strong> account for <i>what percent</i> of GHG emissions, worldwide? ",
          choices: ['12%', '32%', '62%', '92%'],
          answerIdx: 2,    
          furtherInfo: {
              explanation: (
                  `Fossil fuels, like petroleum, account for <b>62%</b> of greenhouse gas emissions, worldwide.`
              ),
              sources: [
                  {
                      source: 'Project Drawdown',
                      url: 'https://drawdown.org/climate-solutions-101/unit-2-how-to-stop-climate-change',
                  },
              ],
          },
      },
      {
          //question 7//
          question: "<strong>Deforestation</strong> accounts for <i>what percent</i> of global CO₂ emissions?",
          choices: ['11%', '21%', '51%', '71%'],
          answerIdx: 0,    
          furtherInfo: {
              explanation: (
                  `Deforestation accounts for <b>11%</b> of global CO₂ emissions.`
              ),
              sources: [
                  {
                      source: 'Project Drawdown',
                      url: 'https://drawdown.org/climate-solutions-101/unit-2-how-to-stop-climate-change',
                  },
              ],
          },
      },
      {
          //question 8//
          question: "Processes that store carbon are known as <i>what</i>?",
          choices: ['carbon converters', 'carbon relocators', 'carbon collectors', 'carbon sinks'],
          answerIdx: 3,    
          furtherInfo: {
              explanation: (
                  `Processes that store carbon are known as <b>carbon sinks</b>. There are two categories of natural carbon sinks: <i>land sinks</i> and <i>ocean sinks</i>.`
              ),
              sources: [
                  {
                      source: 'Project Drawdown',
                      url: 'https://drawdown.org/climate-solutions-101/unit-2-how-to-stop-climate-change',
                  },
              ],
          },
      },
      {
          //question 9//
          question: "How many gigatonnes (Gt) of carbon dioxide equivalent do we release into the atmosphere each year?",
          choices: ['2Gt', '52Gt', '152Gt', '1,520Gt'],
          answerIdx: 1,    
          furtherInfo: {
              explanation: (
                  `We release approximately 52 gigatonnes of CO₂ equivalent into the atmosphere each year, which is 52 billion metric tons.`
              ),
              sources: [
                  {
                      source: 'Project Drawdown',
                      url: 'https://drawdown.org/climate-solutions-101/unit-2-how-to-stop-climate-change',
                  },
              ],
          },
      },
      {
          //question 10//
          question: "If approximately 7.5 billion humans release 52 gigatonnes (52 billion metric tons) of CO₂ equivalent into the atmosphere each year, about how many metric tons, per person, per year, is that?",
          choices: ['7', '70', '700', '7,000'],
          answerIdx: 0,    
          furtherInfo: {
              explanation: (
                  `If the emissions were spread equally across the entire human population, each person would be responsible for approximately 7 tons of CO₂ equivalent emissions each year. (52 / 7.5 = 6.9)`
              ),
              sources: [
                  {
                      source: 'Project Drawdown',
                      url: 'https://drawdown.org/climate-solutions-101/unit-2-how-to-stop-climate-change',
                  },
              ],
          },
      },
  ],
};

export default dd2StoppingClimateChange;