 const wasteByTheNumbers = {
    name: 'Waste: by the Numbers',
    id: 'roAPL2gD9k8y1i6XHSNa',    
    linkTo: "/quizzes/wasteByTheNumbers",
    explainerOn: true,
    description: "Do things simply disappear once they're tossed out? (Think again). Waste is a big problem in modern society, and it’s getting bigger. What are the numbers behind America’s trash?",
    questions: [
        { // 1
            question: "How much waste does the average American produce in a day (2018 numbers)?",
            choices: ['5 ounces', '5 pounds', '50 pounds', '150 pounds',],
            answerIdx: 1,
            furtherInfo: {
                explanation: "The average American produces 4.9 (about 5) pounds of waste daily.",
                sources: [
                    {
                        source: 'EPA',
                        url: 'https://www.epa.gov/facts-and-figures-about-materials-waste-and-recycling/national-overview-facts-and-figures-materials',
                    }
                ],
            },
        },
        { // 2
            question: "Approximately what percentage of plastic is actually recycled?",
            choices: ['10%', '30%', '50%', '70%' ],
            answerIdx: 0,
            furtherInfo: {
                explanation: "Only 9% of plastics (i.e., approximately 10%) are actually recycled.",
                sources: [
                    {
                        source: 'Green Matters',
                        url: 'https://www.greenmatters.com/p/what-percent-recycling-actually-gets-recycled#:~:text=In%202017%2C%20only%2094.2%20million,start%20to%20look%20more%20unsettling',
                    }
                ],
            },
        },
        { // 3
            question: "What percentage of methane emissions in the United States come from landfills?",
            choices: ['5%', '15%', '55%', '75%'],
            answerIdx: 1,
            furtherInfo: {
                explanation: "15% of methane emissions in the United States come from Municipal solid waste (MSW) landfills.",
                sources: [
                    {
                        source: 'EPA',
                        url: 'https://www.epa.gov/lmop/basic-information-about-landfill-gas',
                    }
                ],
            },
        },
        { // 4
            question: "How much food is wasted globally, in tons, each year?",
            choices: ['130,000 tons', '13,000,000 tons', '130,000,000 tons', '1,300,000,000 tons'],
            answerIdx: 3,    
            furtherInfo: {
                explanation: "<i>1.3 billion tons</i> (1,300,000,000, or 1.3E9 tons) of food are wasted globally each year.",
                sources: [
                    {
                        source: 'Reset',
                        url: 'https://en.reset.org/knowledge/global-food-waste-and-its-environmental-impact-09122018',
                    }
                ],
            },
        },
        { // 5
            question: "What was the approximate size, in tons, of one year of waste from the United States in 2018?",
            choices: ['300,000 tons', '3,000,000 tons', '300,000,000 tons', '3,000,000,000 tons'],
            answerIdx: 2,
            furtherInfo: {
                explanation: "The collected waste of the United States in 2018 was approximately 300 million tons (300,000,000, or 3E8 tons).",
                sources: [
                    {
                        source: 'EPA',
                        url: 'https://www.epa.gov/facts-and-figures-about-materials-waste-and-recycling/national-overview-facts-and-figures-materials#:~:text=The%20Current%20National%20Picture,-EPA%20began%20collecting&text=The%20total%20generation%20of%20municipal,pounds%20per%20person%20per%20day',
                    }
                ],
            },
        },
        { // 6
            question: "How much plastic waste gets into the ocean each year?",
            choices: ['8,000 tons', '80,000 tons', '8,000,000 tons', '80,000,000 tons'],
            answerIdx: 2,    
            furtherInfo: {
                explanation: "8 million tons (8,000,000 tons) of plastic waste winds up in the ocean every year.",
                sources: [
                    {
                        source: 'International Union for Conservation of Nature (IUCN)',
                        url: 'https://www.iucn.org/resources/issues-briefs/marine-plastics#:~:text=At%20least%208%20million%20tons,causes%20severe%20injuries%20and%20deaths',
                    }
                ],
            },
        },
        { // 7
            question: "What’s the size of the Pacific Garbage Patch (in square kilometers, or km2)?",
            choices: [`1,600,000 km2`, `160,000 km2`, `16,000 km2`, `1,600 km2`],
            answerIdx: 0,    
            furtherInfo: {
                explanation: `The Pacific Garbage Patch is 1.6 million square kilometers (1,600,000 km2).`,
                sources: [
                    {
                        source: 'The Ocean Cleanup',
                        url: 'https://theoceancleanup.com/great-pacific-garbage-patch/#:~:text=The%20GPGP%20covers%20an%20estimated,times%20the%20size%20of%20France.&text=To%20formulate%20this%20number%2C%20the,elaborate%20sampling%20method%20ever%20coordinated',
                    }
                ],
            },
        },
        { // 8
            question: "How many landfills in the United States are completely full?",
            choices: ['100 landfills', '1,000 landfills', '10,000 landfills', '100,000 landfills'],
            answerIdx: 2,
            furtherInfo: {
                explanation: "There are 10,000 filled landfills in the United States today.",
                sources: [
                    {
                        source: 'Zero Waste America',
                        url: 'http://www.zerowasteamerica.org/landfills.htm#:~:text=The%20U.S.%20has%203%2C091%20active,to%20the%20Environmental%20Protection%20Agency',
                    }
                ],
            },
        },
        { // 9
            question: "How many Eiffel Towers could you build from the world’s e-waste from 2016 (which was about 44.7 million metric tons)?",
            choices: ['450 Eiffel Towers', '4,500 Eiffel Towers', '45,000 Eiffel Towers', '450,000 Eiffel Towers'],
            answerIdx: 1,
            furtherInfo: {
                explanation: "From 2016’s global e-waste, which was approximately 44.7 million (44,700,000) metric tons, you could build 4,500 Eiffel Towers.",
                sources: [
                    {
                        source: 'Collections at UNU',
                        url: 'https://collections.unu.edu/eserv/UNU:6341/Global-E-waste_Monitor_2017__electronic_single_pages_.pdf',
                    }
                ],
            },
        },
        { // 10
            question: "How long does it take for glass to decompose in a landfill?",
            choices: ['1,000 years', '10,000 years', '100,000 years', '1,000,000 years'],
            answerIdx: 3,
            furtherInfo: {
                explanation: "It takes glass about 1 million (1,000,000) years to decompose in a landfill.",
                sources: [
                    {
                        source: 'Northeast Illinois Council',
                        url: 'http://storage.neic.org/event/docs/1129/how_long_does_it_take_garbage_to_decompose.pdf',
                    }
                ],
            },
        },
    ],

};

export default wasteByTheNumbers;