// *** DO NOT EDIT! This file was automatically generated by <repo-root>/tools/tenThousandOrNot. View the README file in that directory for build instructions.

const oneMillionOrNot = {
  id: "oneMillionOrNot",
  name: 'One Million Or Not?',
  linkTo: "/quizzes/oneMillionOrNot",
  timeLimit: 90, // seconds
  countdownTime: 3, // seconds
  randomize: true,
  challenge: true,
  explainerOn: true,
  description: `Before the timer reaches naught: choose what is ONE MILLION, and what is NOT!`,
  questions: [
  
    
    {
      question: `
 Does this equal one million?
<br />
<b> 10,000 * 10<sup>2</sup></b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one million?
<br />
<b> 10<sup>6</sup></b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one million?
<br />
<b> 10<sup>3</sup> * 10<sup>3</sup></b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one million?
<br />
<b> 10<sup>6</sup> / 10<sup>0</sup></b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one million?
<br />
<b> 100 * 100 * 100</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one million?
<br />
<b> 50,000 / 0.05</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one million?
<br />
<b> 5,000 * 5,000 / 25</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one million?
<br />
<b> (100,000 + 900,000) / 10<sup>0</sup></b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one million?
<br />
<b> 800 * 10<sup>4</sup> / 8</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one million?
<br />
<b> 100 * 10<sup>5</sup> / 10</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one million?
<br />
<b> 95,000 / 0.095</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one million?
<br />
<b> 20,000,000 / 20</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one million?
<br />
<b> 5,550,000 / 5.55</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one million?
<br />
<b> 9,475,000 / 9.475</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one million?
<br />
<b> (999,999 +1 ) / 10<sup>0</sup></b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one million?
<br />
<b> 16,000 * 600 / 9.6</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one million?
<br />
<b> 453,534,654 / 453.534654</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one million?
<br />
<b> 1 million / 10<sup>0</sup></b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one million?
<br />
<b> 1 billion / 10<sup>3</sup></b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one million?
<br />
<b> 1 / .000001</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one million?
<br />
<b> (4,200,000 – 4,000,000) * 5</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one million?
<br />
<b> 10<sup>60</sup> / 10<sup>54</sup></b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one million?
<br />
<b> 10<sup>65</sup> * 10<sup>(-70)</sup> * 10<sup>11</sup></b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one million?
<br />
<b> (10<sup>7</sup> / 1,000) * (100 / 10<sup>0</sup>)</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one million?
<br />
<b> 10<sup>5</sup> / 10<sup>(-1)</sup></b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one million?
<br />
<b> 1,999,999 – 999,999</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one million?
<br />
<b> (750,000 – 500,000) * 4</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one million?
<br />
<b> 700,000 * 2 / 1.4</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one million?
<br />
<b> 90 * 10<sup>5</sup> – 8,000 * 10<sup>3</sup></b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one million?
<br />
<b> (Half of 1 billion) / 500</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one million?
<br />
<b> Quarter of a million * 4</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one million?
<br />
<b> (1,000,000 – 100,000) / 0.9</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one million?
<br />
<b> 9,600 * 10<sup>4</sup> / 96</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one million?
<br />
<b> 2.5 * 10<sup>7</sup> * 4 / 10<sup>2</sup></b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one million?
<br />
<b> (5,400,000 – 500,000) / 4.9</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one million?
<br />
<b> 10 million * 10<sup>(-1)</sup></b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one million?
<br />
<b> 150 * 10<sup>5</sup> / 15 * 10<sup>0</sup></b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one million?
<br />
<b> 15,000 * 6 * 10<sup>1</sup> + 100,000</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one million?
<br />
<b> (1,000,000 * 10<sup>2</sup>) / (50 * 2<sup>1</sup>)</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one million?
<br />
<b> (12 * 50,000) + (20 * 20,000)</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one million?
<br />
<b> 540,000 + 10 * 46,000</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one million?
<br />
<b> 33,000 * 10<sup>2</sup> / (1.1 * 3)</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one million?
<br />
<b> 5 * 2 * 10<sup>5</sup></b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one million?
<br />
<b> (45 * 2 * 10 + 100) * 10<sup>(5 - 2)</sup></b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one million?
<br />
<b> 70 * 10<sup>(-3)</sup> * 10<sup>7</sup> / 0.7</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one million?
<br />
<b> Seconds in 10,000 minutes + 400,000 seconds</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one million?
<br />
<b> 7,300 + 73,000 + 730,000 + 189,700</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one million?
<br />
<b> 10<sup>450</sup> * 10<sup>100</sup> / (10<sup>550</sup> * 10<sup>(- 6)</sup>)</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one million?
<br />
<b> 10,000 + 100,000 + 890,000</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one million?
<br />
<b> (500,000 + 750,000) / 1.25</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one million?
<br />
<b> 100,000 + 1,100,000 – 20 * 10<sup>4</sup></b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one million?
<br />
<b> 19,600<sup>(1/2)</sup> * 10<sup>5</sup> / 14</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one million?
<br />
<b> (700,000 – 600,000) * 10<sup>1</sup></b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one million?
<br />
<b> 27 * 10<sup>6</sup> – 26 * 10<sup>6</sup></b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one million?
<br />
<b> 74.5 * 10<sup>6</sup> – 73.5 * 10<sup>6</sup></b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one million?
<br />
<b> (27,500,000 – 27,000,000) * 10<sup>2</sup> / (25 * 2)</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one million?
<br />
<b> (1,250,000 – 1,000,000) * 4</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one million?
<br />
<b> (7,510 / 7.51) * 10<sup>3</sup></b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one million?
<br />
<b> 66 * 10<sup>26</sup> / (11 * 6 * 10<sup>20</sup>)</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one million?
<br />
<b> 8 * 10<sup>7</sup> / (2<sup>3</sup> * 10<sup>1</sup>)</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one million?
<br />
<b> 3,000 * 10,000 / (15 * 2<sup>1</sup>)</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one million?
<br />
<b> 10<sup>6</sup> / 3<sup>0</sup></b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one million?
<br />
<b> 12 * 8 * 10<sup>4</sup> + 40,000</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one million?
<br />
<b> 230 * 4 / 0.00092</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one million?
<br />
<b> 4<sup>2</sup> * 5<sup>2</sup> * 2 * 10<sup>3</sup> + 200,000</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one million?
<br />
<b> (31 * 3) / 10<sup>(- 4)</sup> + (35,000 * 2)</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one million?
<br />
<b> 10<sup>5</sup> / 10<sup>(-2)</sup></b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 1, // No
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one million?
<br />
<b> 11,000,000 – 1,000,000</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 1, // No
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one million?
<br />
<b> 1,640,000 – 64,000</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 1, // No
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one million?
<br />
<b> 10<sup>500</sup> – 10<sup>494</sup></b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 1, // No
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one million?
<br />
<b> 10<sup>9</sup> / 10<sup>4</sup></b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 1, // No
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one million?
<br />
<b> 10<sup>(-12)</sup> / 10<sup>18</sup></b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 1, // No
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one million?
<br />
<b> 10<sup>(-40)</sup> / 10<sup>(-47)</sup></b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 1, // No
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one million?
<br />
<b> 540,000 + 6,400,000</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 1, // No
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one million?
<br />
<b> 7.6 * 10<sup>7</sup> + 2.4 * 10<sup>7</sup></b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 1, // No
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one million?
<br />
<b> (550,000 + 550,000) / 11</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 1, // No
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one million?
<br />
<b> (600,000 + 500,000) – 110,000</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 1, // No
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one million?
<br />
<b> 340,000 + 680,000</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 1, // No
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one million?
<br />
<b> 87 * 10<sup>5</sup> + 13 * 10<sup>6</sup></b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 1, // No
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one million?
<br />
<b> 27.5 * 10<sup>7</sup> + 72.5 * 10<sup>7</sup></b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 1, // No
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one million?
<br />
<b> 10<sup>56</sup> / 10<sup>49</sup></b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 1, // No
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one million?
<br />
<b> 1 billion – 99 million</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 1, // No
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one million?
<br />
<b> 12,100,100 – 2,100,100</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 1, // No
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one million?
<br />
<b> 1.5 * 10<sup>6</sup> / 15</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 1, // No
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one million?
<br />
<b> 995,000 + 50,000</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 1, // No
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one million?
<br />
<b> 100 * 10,000 / 10<sup>(-2)</sup></b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 1, // No
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one million?
<br />
<b> (6,400 * 10<sup>4</sup>) + (46 * 10<sup>6</sup>) – (110 * 10<sup>6</sup>)</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 1, // No
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one million?
<br />
<b> 456 / 45.6 * 10<sup>6</sup></b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 1, // No
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one million?
<br />
<b> (866 – 766) * 10<sup>6</sup></b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 1, // No
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one million?
<br />
<b> 43 * 10<sup>6</sup> + 57 * 10<sup>6</sup></b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 1, // No
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one million?
<br />
<b> 10<sup>65</sup> / 10<sup>(-59)</sup></b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 1, // No
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one million?
<br />
<b> 2<sup>0</sup> * 10<sup>7</sup></b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 1, // No
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one million?
<br />
<b> 5<sup>0</sup> * 6<sup>0</sup> * 7<sup>0</sup> * 100<sup>4</sup></b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 1, // No
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one million?
<br />
<b> 10,000,000 – 900,000</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 1, // No
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one million?
<br />
<b> 1 trillion – 999 billion</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 1, // No
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one million?
<br />
<b> 1 billion – 99 million</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 1, // No
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one million?
<br />
<b> 1/100th part of one billion</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 1, // No
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one million?
<br />
<b> 10,000 * 10,000</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 1, // No
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one million?
<br />
<b> 8,888,888 – 7,777,777</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 1, // No
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
    
    {
      question: `
 Does this equal one million?
<br />
<b> 10<sup>56</sup> * 10<sup>60</sup> / 10<sup>100</sup></b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 1, // No
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
  ],
};

export default oneMillionOrNot;
