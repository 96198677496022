 const octopusDreams = {
    name: 'Octopus Dreams',
    id: 'sXDMT3MVy6Yj8Z6w2Zp7',
    linkTo: "/quizzes/octopusDreams",
    explainerOn: true,
    description: `Shakespeare once wrote “To sleep, perchance to dream,” and in a startling turn of events, research shows that his timeless words could have just as easily been addressed to an octopus.`,
    questions: [
        { // 1
            question: "When it comes to sleep, fruit flies, octopuses, birds, and humans all dream.",
            choices: ["True", 'False'],
            answerIdx: 0,    
            furtherInfo: {
                explanation: "True! Humans, birds, octopuses, and even fruit flies dream while they sleep.",
                sources: [
                    {
                        source: 'Wired',
                        url: 'https://www.wired.com/story/what-octopus-dreams-tell-us-about-the-evolution-of-sleep/#intcid=_wired-homepage-right-rail_eaf82fdb-8317-4f36-a1d8-29a33138a169_popular4-1',
                    }
                ],
            },
        },
        { // 2
            question: "In humans, the steep stage where we experience heightened brain activity is called what?",
            choices: ['Retained Elevated Memory', 'Route Elevated Memory', 'Rapid Eye Movement', 'Repair Elasticity Microsleep'],
            answerIdx: 2,    
            furtherInfo: {
                explanation: "The sleep stage where humans experience heightened brain activity is called Rapid Eye Movement, or REM sleep.",
                sources: [
                    {
                        source: 'Wired',
                        url: 'https://www.wired.com/story/what-octopus-dreams-tell-us-about-the-evolution-of-sleep/#intcid=_wired-homepage-right-rail_eaf82fdb-8317-4f36-a1d8-29a33138a169_popular4-1',
                    }
                ],
            },
        },
        { // 3
            question: "Researchers first hypothesized that octopuses dream because they observed what?",
            choices: [
                'The animals twitching during low activity, or ‘sleep’ periods.',
                'An octopus, “Frank,” recorded with intense neural activity throughout its body, and then fleeing when awakened, hinting that it may have suffered a nightmare.',
                'Some octopuses seemed confused by the lack of food or other stimuli in research areas that had always been empty, hinting that they may have dreamt up their existence.',
                'The octopuses turning fantastic colors and patterns that had nothing to do with their environment, hinting that they were color-changing in response to dreams.'
            ],
            answerIdx: 3,    
            furtherInfo: {
                explanation: "Researchers first hypothesized that octopuses dream because they observed octopuses turning fantastic colors and patterns that had nothing to do with their environment, hinting that they were color changing in response to dreams.",
                sources: [
                    {
                        source: 'Wired',
                        url: 'https://www.wired.com/story/what-octopus-dreams-tell-us-about-the-evolution-of-sleep/#intcid=_wired-homepage-right-rail_eaf82fdb-8317-4f36-a1d8-29a33138a169_popular4-1',
                    }
                ],
            },
        },
        { // 4
            question: "Figuring out when cuttlefish are asleep proved challenging for researchers because cuttlefish:",
            choices: [
                'Don’t have consistent arousal (sleep vs awake) thresholds.',
                'Don’t have large enough brain-to-body size, meaning it’s difficult to detect neural patterns against the background electrical impulses that stimulate muscle contraction.',
                'Do not sleep.',
                'Hide while inactive.'
            ],
            answerIdx: 0,    
            furtherInfo: {
                explanation: "Researchers found it challenging to figure out when cuttlefish are asleep because cuttlefish do not have consistent arousal (sleep vs. awake) thresholds.",
                sources: [
                    {
                        source: 'Wired',
                        url: 'https://www.wired.com/story/what-octopus-dreams-tell-us-about-the-evolution-of-sleep/#intcid=_wired-homepage-right-rail_eaf82fdb-8317-4f36-a1d8-29a33138a169_popular4-1',
                    }
                ],
            },
        },
        { // 5
            question: "Humans experience a long REM sleep cycle, about an hour, which gives us what?",
            choices: [
                'Complex dreams full of characters, plot twists, and symbolism.',
                'Complex sleep cycles that sometimes cross the boundary into wakefulness, leading to sleep paralysis and sleepwalking.',
                'Superior working memory during dreams.',
                'Trick question. The duration of REM sleep doesn’t affect sleep or memory.'
                ],
            answerIdx: 0,    
            furtherInfo: {
                explanation: "Humans experience a long REM sleep cycle, about an hour, which gives us complex dreams full of characters, plot twists, and symbolism.",
                sources: [
                    {
                        source: 'Wired',
                        url: 'https://www.wired.com/story/what-octopus-dreams-tell-us-about-the-evolution-of-sleep/#intcid=_wired-homepage-right-rail_eaf82fdb-8317-4f36-a1d8-29a33138a169_popular4-1',
                    }
                ],
            },
        },
        { // 6
            question: "Octopus REM sleep seems to be less than a minute long. This means that, unlike humans’ vast and complex dreams, octopus dreams may be more like GIFs than full movies.",
            choices: ['True', 'False'],
            answerIdx: 0,    
            furtherInfo: {
                explanation: "This is true. The REM cycle of octopuses seems to be less than a minute, most likely making their dreams much less complex and perhaps more like GIFs than full movies.",
                sources: [
                    {
                        source: 'Wired',
                        url: 'https://www.wired.com/story/what-octopus-dreams-tell-us-about-the-evolution-of-sleep/#intcid=_wired-homepage-right-rail_eaf82fdb-8317-4f36-a1d8-29a33138a169_popular4-1',
                    }
                ],
            },
        },
        { // 7
            question: "Sleeping can be dangerous for an organism because it leaves one vulnerable to predation. This implies that sleep serves some evolutionary advantage. What’s a possible advantage?",
            choices: [
                'Sleep allows the brain to move memories from short-term to long-term memories.',
                'Sleep allows different brain regions to communicate, strengthening neural connections or making creative leaps.',
                'Both A and B.',
                'Neither A nor B.'
            ],
            answerIdx: 2,    
            furtherInfo: {
                explanation: "Both A and B are true. Possible advantages of sleep include: allowing the brain to move memories from short to long-term memory; and allowing different regions to communicate, strengthening neural connections.",
                sources: [
                    {
                        source: 'Wired',
                        url: 'https://www.wired.com/story/what-octopus-dreams-tell-us-about-the-evolution-of-sleep/#intcid=_wired-homepage-right-rail_eaf82fdb-8317-4f36-a1d8-29a33138a169_popular4-1',
                    }
                ],
            },
        },
        { // 8
            question: "One flaw in studies looking for sleep in nonhuman animals is that:",
            choices: [
                'We need large sample sizes to determine accuracy, and this is difficult to arrange with certain aquatic species and delicate invertebrates.',
                'By observing the specimens we are changing their behavior and can’t get an accurate estimate of which animals sleep, for how long, or in what sleep cycles.',
                'We look for active sleep in nonhuman animals based on what we understand about our own, and this may not be what sleep looks like in all animals who experience sleep.',
                'We are assuming that animal nervous systems work like human nervous systems.'
            ],
            answerIdx: 2,    
            furtherInfo: {
                explanation: "One flaw in studies looking for sleep in nonhuman animals is that we look for active sleep in other animals based on what we understand about our own sleep cycle, which may be inaccurate to other species.",
                sources: [
                    {
                        source: 'Wired',
                        url: 'https://www.wired.com/story/what-octopus-dreams-tell-us-about-the-evolution-of-sleep/#intcid=_wired-homepage-right-rail_eaf82fdb-8317-4f36-a1d8-29a33138a169_popular4-1',
                    }
                ],
            },
        },
    ],

};

export default octopusDreams;