 const californiasBigBatteries = {
    name: "California's Big Batteries",
    id: 'HksKHJykrRtXeewPjlBi',
    linkTo: "/quizzes/californiasBigBatteries",
    explainerOn: true,
    description: "The future of power is renewable, but renewable sources of electricity rely on battery storage to provide steady power. California is the current global leader in large, utility-scale batteries that are key to achieving carbon-free electricity.",
    questions: [
        {
            //question 1//
            question: "How many megawatts will be stored in the lithium-ion battery being installed in the Moss Landing Power Plant on the Northern California Coast?",
            choices: ['3', '30', '300', '3,000'],
            answerIdx: 2,    
            furtherInfo: {
                explanation: (
                    `The Moss Landing Power Plant, once a gas-fired generator, is set to house a 300-megawatt battery with a second 100-megawatt battery to be installed as well.`
                ),
                sources: [
                    {
                        source: 'Katz, C. (2021). The batteries that could make fossil fuels obsolete. Retrieved 30 June 2021',
                        url: 'https://www.bbc.com/future/article/20201217-renewable-power-the-worlds-largest-battery',
                    },
                ],
            },
        },
        {
            //question 2//
           question: "256 Tesla megapack batteries are set to start feeding approximately how many megawatts into California’s electrical grid?",
            choices: ['18', '183', '1,830', '18,300'],
            answerIdx: 1,    
            furtherInfo: {
                explanation: (
                    `256 Tesla megapack batteries will feed approximately 183-megawatts of power into California’s electrical grid.`
                ),
                sources: [
                    {
                        source: 'Katz, C. (2021). The batteries that could make fossil fuels obsolete. Retrieved 30 June 2021',
                        url: 'https://www.bbc.com/future/article/20201217-renewable-power-the-worlds-largest-battery',
                    },
                ],
            },
        },
        {
            //question 3//
            question: "How many megawatts can San Diego, California’s lithium-ion battery store?",
            choices: ['2', '25', '250', '2,500'],
            answerIdx: 2,    
            furtherInfo: {
                explanation: (
                    `San Diego’s battery project came online in 2021 with the capacity to store up to 250-megawatts.`
                ),
                sources: [
                    {
                        source: 'Katz, C. (2021). The batteries that could make fossil fuels obsolete. Retrieved 30 June 2021',
                        url: 'https://www.bbc.com/future/article/20201217-renewable-power-the-worlds-largest-battery',
                    },
                ],
            },
        },
        {
            //question 4//
            question: "How many megawatts will the lithium-ion battery being constructed near San Francisco, California be capable of storing?",
            choices: ['150', '1,500', '15,000', '150,000'],
            answerIdx: 0,    
            furtherInfo: {
                explanation: (
                    `San Francisco’s battery project will have a top capacity of 150-megawatts.`
                ),
                sources: [
                    {
                        source: 'Katz, C. (2021). The batteries that could make fossil fuels obsolete. Retrieved 30 June 2021',
                        url: 'https://www.bbc.com/future/article/20201217-renewable-power-the-worlds-largest-battery',
                    },
                ],
            },
        },
        {
            //question 5//
            question: "How many megawatts will the battery project nearing completion in Long Beach, California be able to store?",
            choices: ['10', '100', '1,000', '10,000'],
            answerIdx: 1,    
            furtherInfo: {
                explanation: (
                    `Long Beach’s battery will be able to store 100-megawatts.`
                ),
                sources: [
                    {
                        source: 'Katz, C. (2021). The batteries that could make fossil fuels obsolete. Retrieved 30 June 2021',
                        url: 'https://www.bbc.com/future/article/20201217-renewable-power-the-worlds-largest-battery',
                    },
                ],
            },
        },
        {
            //question 6//
            question: "By what year does California plan to achieve a carbon-free electrical grid?",
            choices: ['2021', '2045', '2075', '2100'],
            answerIdx: 1,    
            furtherInfo: {
                explanation: (
                    `California aims to have a carbon-free electrical grid by 2045.`
                ),
                sources: [
                    {
                        source: 'Katz, C. (2021). The batteries that could make fossil fuels obsolete. Retrieved 30 June 2021',
                        url: 'https://www.bbc.com/future/article/20201217-renewable-power-the-worlds-largest-battery',
                    },
                ],
            },
        },
        {
            //question 7//
            question: "How many megawatts of battery storage has California installed so far?",
            choices: ['500', '5,000', '50,000', '500,000'],
            answerIdx: 0,    
            furtherInfo: {
                explanation: (
                    `Currently, California has installed 500-megawatts of battery storage.`
                ),
                sources: [
                    {
                        source: 'Katz, C. (2021). The batteries that could make fossil fuels obsolete. Retrieved 30 June 2021',
                        url: 'https://www.bbc.com/future/article/20201217-renewable-power-the-worlds-largest-battery',
                    },
                ],
            },
        },
        {
            //question 8//
            question: "At what threshold can utility-scale batteries start to replace small to medium-sized gas generators?",
            choices: ['2 megawatts', '20 megawatts', '200 megawatts', '2,000 megawatts'],
            answerIdx: 2,    
            furtherInfo: {
                explanation: (
                    `At 200-megawatts, utility-scale batteries can replace small to medium-sized gas generators.`
                ),
                sources: [
                    {
                        source: 'Katz, C. (2021). The batteries that could make fossil fuels obsolete. Retrieved 30 June 2021',
                        url: 'https://www.bbc.com/future/article/20201217-renewable-power-the-worlds-largest-battery',
                    },
                ],
            },
        },
        {
            //question 9//
            question: "The United State’s utility battery power is expected to rise from 1.2 gigawatts in 2020 to how many gigawatts in 2075?",
            choices: ['2.5', '7.5', '10.5', '20.5'],
            answerIdx: 1,    
            furtherInfo: {
                explanation: (
                    `Utility battery power in the United States is expected to reach 7.5 gigawatts, or 7,500 megawatts, by 2075.`
                ),
                sources: [
                    {
                        source: 'Katz, C. (2021). The batteries that could make fossil fuels obsolete. Retrieved 30 June 2021',
                        url: 'https://www.bbc.com/future/article/20201217-renewable-power-the-worlds-largest-battery',
                    },
                ],
            },
        },
        {
            //question 10//
            question: "Experts expect global battery storage to reach how much within the next decade?",
            choices: ['900 megawatts', '100 gigawatts', '500 gigawatts', '1.2 terawatts'],
            answerIdx: 3,    
            furtherInfo: {
                explanation: (
                    `Within the decade, global battery storage is expected to reach 1.2 terawatts, which is equal to 1,200 gigawatts, and 1.2 million megawatts.`
                ),
                sources: [
                    {
                        source: 'Katz, C. (2021). The batteries that could make fossil fuels obsolete. Retrieved 30 June 2021',
                        url: 'https://www.bbc.com/future/article/20201217-renewable-power-the-worlds-largest-battery',
                    },
                ],
            },
        },
    ],

};

export default californiasBigBatteries;