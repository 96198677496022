 const greenLithium = {
    name: 'Green Lithium',
    id: 'pXMnICpghGKrU2fJFSrc',
    linkTo: "/quizzes/greenLithium",
    explainerOn: true,
    description: "All our efforts to combat global warming rely on growing sources of green energy, and most green energy relies on lithium batteries to store and ensure a steady supply of power. But the key ingredient of these batteries, lithium, has a historically high environmental toll.",
    questions: [
        {
            //question 1//
            question: "Hard rock mining for lithium releases how many metric tons (mt) of CO2 for every metric ton of lithium mined?",
            choices: ['5mt', '15mt', '25mt', '55mt'],
            answerIdx: 1,    
            furtherInfo: {
                explanation: (
                    `Hard rock mining emits 15 metric tons of CO2 for every metric ton of lithium mined.`
                ),
                sources: [
                    {
                        source: "Early, C. (2021). The new 'gold rush' for green lithium. Retrieved 29 June 2021",
                        url: 'https://www.bbc.com/future/article/20201124-how-geothermal-lithium-could-revolutionise-green-energy',
                    },
                ],
            },
        },
        {
            //question 2//
           question: "How many cubic meters (m³) of water does hard rock mining use per metric ton of lithium mined?",
            choices: ['7m³', '17m³', '170m³', '1,700m³'],
            answerIdx: 2,    
            furtherInfo: {
                explanation: (
                    `Hard rock mining uses 170m³ of water per metric ton of lithium.`
                ),
                sources: [
                    {
                        source: "Early, C. (2021). The new 'gold rush' for green lithium. Retrieved 29 June 2021",
                        url: 'https://www.bbc.com/future/article/20201124-how-geothermal-lithium-could-revolutionise-green-energy',
                    },
                ],
            },
        },
        {
            //question 3//
            question: "Approximately how many square meters (m²) of land does hard rock mining use to produce 1 metric ton of lithium?",
            choices: ['5m²', '50m²', '500m²', '5,000m²'],
            answerIdx: 2,    
            furtherInfo: {
                explanation: (
                    `Hard rock mining uses about 500m² of land per metric ton of lithium.`
                ),
                sources: [
                    {
                        source: "Early, C. (2021). The new 'gold rush' for green lithium. Retrieved 29 June 2021",
                        url: 'https://www.bbc.com/future/article/20201124-how-geothermal-lithium-could-revolutionise-green-energy',
                    },
                ],
            },
        },
        {
            //question 4//
            question: "Extracting one metric ton of lithium from underground reservoirs emits how many metric tons (mt) of CO2?",
            choices: ['5mt', '15mt', '25mt', '55mt'],
            answerIdx: 0,    
            furtherInfo: {
                explanation: (
                    `5 metric tons of CO2 are emitted for every metric ton of lithium extracted from underground reservoirs.`
                ),
                sources: [
                    {
                        source: "Early, C. (2021). The new 'gold rush' for green lithium. Retrieved 29 June 2021",
                        url: 'https://www.bbc.com/future/article/20201124-how-geothermal-lithium-could-revolutionise-green-energy',
                    },
                ],
            },
        },
        {
            //question 5//
            question: "Approximately how many cubic meters (m³) of water are used per metric ton of lithium extracted from underground reservoirs?",
            choices: ['50m³', '500m³', '5,000m³', '50,000m³'],
            answerIdx: 1,    
            furtherInfo: {
                explanation: (
                    `About 500m³ of water are used per metric ton of lithium extracted from underground reservoirs.`
                ),
                sources: [
                    {
                        source: "Early, C. (2021). The new 'gold rush' for green lithium. Retrieved 29 June 2021",
                        url: 'https://www.bbc.com/future/article/20201124-how-geothermal-lithium-could-revolutionise-green-energy',
                    },
                ],
            },
        },
        {
            //question 6//
            question: "Approximately how many square meters (m²) of land are used per 1 metric ton of lithium extracted from underground reservoirs?",
            choices: ['3m²', '30m²', '300m²', '3,000m²'],
            answerIdx: 3,    
            furtherInfo: {
                explanation: (
                    `About 3,000m² of land is used per metric ton of lithium extracted from underground reservoirs.`
                ),
                sources: [
                    {
                        source: "Early, C. (2021). The new 'gold rush' for green lithium. Retrieved 29 June 2021",
                        url: 'https://www.bbc.com/future/article/20201124-how-geothermal-lithium-could-revolutionise-green-energy',
                    },
                ],
            },
        },
        {
            //question 7//
            question: "Approximately how many kilograms of CO2 are produced per metric ton of lithium extracted from geothermal waters?",
            choices: ['0kg', '10kg', '100kg', '1,000kg'],
            answerIdx: 0,    
            furtherInfo: {
                explanation: (
                    `About 0kg of CO2 are produced per metric ton of lithium extracted from geothermal water.`
                ),
                sources: [
                    {
                        source: "Early, C. (2021). The new 'gold rush' for green lithium. Retrieved 29 June 2021",
                        url: 'https://www.bbc.com/future/article/20201124-how-geothermal-lithium-could-revolutionise-green-energy',
                    },
                ],
            },
        },
        {
            //question 8//
            question: "How many cubic meters (m³) of water are used per metric ton of lithium extracted from geothermal waters?",
            choices: ['3m³', '30m³', '300m³', '3,000m³'],
            answerIdx: 0,    
            furtherInfo: {
                explanation: (
                    `Extracting lithium from geothermal waters uses 3m³ of water per metric ton of lithium.`
                ),
                sources: [
                    {
                        source: "Early, C. (2021). The new 'gold rush' for green lithium. Retrieved 29 June 2021",
                        url: 'https://www.bbc.com/future/article/20201124-how-geothermal-lithium-could-revolutionise-green-energy',
                    },
                ],
            },
        },
        {
            //question 9//
            question: "How many square meters (m²) of land are used per metric ton of lithium extracted from geothermal waters?",
            choices: ['1m²', '10m²', '100m²', '1,000m²'],
            answerIdx: 0,    
            furtherInfo: {
                explanation: (
                    `About 1m² of land is used per metric ton of lithium extracted from geothermal waters.`
                ),
                sources: [
                    {
                        source: "Early, C. (2021). The new 'gold rush' for green lithium. Retrieved 29 June 2021",
                        url: 'https://www.bbc.com/future/article/20201124-how-geothermal-lithium-could-revolutionise-green-energy',
                    },
                ],
            },
        },
        {
            //question 10//
            question: "The Salton Sea in California, the second-largest geothermal field in the US, could provide up to how much of the world’s lithium demand?",
            choices: ['4%', '14%', '40%', '94%'],
            answerIdx: 2,    
            furtherInfo: {
                explanation: (
                    `At a projected 600,000 metric tons of lithium a year, the Salton Sea geothermal field could provide up to 40% of the world’s lithium demand.`
                ),
                sources: [
                    {
                        source: "Early, C. (2021). The new 'gold rush' for green lithium. Retrieved 29 June 2021",
                        url: 'https://www.bbc.com/future/article/20201124-how-geothermal-lithium-could-revolutionise-green-energy',
                    },
                ],
            },
        },
    ],

};

export default greenLithium;