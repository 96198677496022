 const californiaDrought = {
    name: 'California Drought',
    id: 'ep8PrjyBoKqninT3ZWhd',
    linkTo: "/quizzes/californiaDrought",
    explainerOn: true,
    description: 'Climate change is becoming more and more evident wherever you look. But perhaps one of the most salient examples is California’s droughts.',
    questions: [
        {
            //question 1//
            question: "Due to groundwater wells, up to how many feet per year does the San Joaquin Valley sink in some places?",
            choices: ['0.2 feet', '2 feet', '12 feet', '22 feet'],
            answerIdx: 1,    
            furtherInfo: {
                explanation: (
                    `Every year, the San Joaquin Valley sinks up to 2 feet in some places due to groundwater wells.`
                ),
                sources: [
                    {
                        source: 'The Los Angeles Times',
                        url: 'https://www.latimes.com/environment/story/2021-04-26/as-drought-deepens-california-growers-see-grim-futurequin?campaign_id=49&emc=edit_ca_20210430&instance_id=29997&nl=california-today&regi_id=76520528&segment_id=56901&te=1&user_id=8571d7f9b5a0bf2e85f5708cfa920700',
                    },
                ],
            },
        },
        {
            //question 2//
            question: "How much money is set aside each year for safe drinking water projects in California due to Senate Bill 200 (Safe and Affordable Drinking Water Fund)?",
            choices: ['$130,000', '$1,300,000', '$130,000,000', '$1,300,000,000'],
            answerIdx: 2,    
            furtherInfo: {
                explanation: (
                    `Senate Bill 200 set aside $130 million every year for safe drinking water projects in California.`
                ),
                sources: [
                    {
                        source: 'The Los Angeles Times',
                        url: 'https://www.latimes.com/environment/story/2021-04-26/as-drought-deepens-california-growers-see-grim-futurequin?campaign_id=49&emc=edit_ca_20210430&instance_id=29997&nl=california-today&regi_id=76520528&segment_id=56901&te=1&user_id=8571d7f9b5a0bf2e85f5708cfa920700',
                    },
                ],
            },
        },
        {
            //question 3//
            question: "How many trees died because of California’s last drought, in 2012-2016?",
            choices: ['163,000,000', '16,300,000', '163,000', '16,300'],
            answerIdx: 0,    
            furtherInfo: {
                explanation: (
                    `As a result of California’s 2012-2016 drought 163 million trees died.`
                ),
                sources: [
                    {
                        source: 'The Guardian',
                        url: 'https://www.theguardian.com/us-news/2021/apr/29/california-drought-climate-dust-bowl',
                    },
                ],
            },
        },
        {
            //question 4//
            question: "What percentage of California’s water supply is used for agriculture?",
            choices: ['60%', '73%', '80%', '92%'],
            answerIdx: 2,    
            furtherInfo: {
                explanation: (
                    `80% of California’s water supply is used for agriculture.`
                ),
                sources: [
                    {
                        source: 'The New York Times',
                        url: 'https://www.nytimes.com/2021/04/30/us/california-drought-water.html',
                    },
                ],
            },
        },
        {
            //question 5//
            question: "What was the duration of California’s longest drought (so far)?",
            choices: ['37 weeks', '176 weeks', '237 weeks', '376 weeks'],
            answerIdx: 3,    
            furtherInfo: {
                explanation: (
                    `California’s longest drought to date was 376 weeks, just over seven years.`
                ),
                sources: [
                    {
                        source: 'National Integrated Drought Information System',
                        url: 'https://www.drought.gov/states/california',
                    },
                ],
            },
        },
        {
            //question 6//
            question: "How big is California’s largest watershed, Sacramento River watershed?",
            choices: ['270 sq mi', '2,700 sq mi', '27,000 sq mi', '270,000 sq mi'],
            answerIdx: 2,    
            furtherInfo: {
                explanation: (
                    `The Sacramento River watershed is California’s largest watershed at 27,000 square miles.`
                ),
                sources: [
                    {
                        source: 'Regional San',
                        url: 'https://www.regionalsan.com/sacramentos-watershed#:~:text=The%20Sacramento%20River%20watershed%20encompasses,to%20the%20San%20Francisco%20Bay.',
                    },
                ],
            },
        },
        {
            //question 7//
            question: "How many well-dependent households faced water shortages during the 2012-2016 drought?",
            choices: ['260', '2,600', '26,000', '260,000'],
            answerIdx: 1,    
            furtherInfo: {
                explanation: (
                    `During the 2012-2016 drought in California 2,600 households dependent on well water faced water shortages.`
                ),
                sources: [
                    {
                        source: 'Public Policy Institute of California',
                        url: 'https://www.ppic.org/publication/droughts-in-california/',
                    },
                ],
            },
        },
        {
            //question 8//
            question: "How many freshwater species found in California are endangered or vulnerable?",
            choices: ['24', '240', '2,400', '24,000'],
            answerIdx: 1,    
            furtherInfo: {
                explanation: (
                    `240 of California’s freshwater species are currently classified as either endangered or vulnerable.`
                ),
                sources: [
                    {
                        source: 'Public Policy Institute of California',
                        url: 'https://www.ppic.org/publication/droughts-in-california/',
                    },
                ],
            },
        },
        {
            //question 9//
            question: "How much does snow and ice account for California’s water supply?",
            choices: ['3%', '30%', '53%', '73%'],
            answerIdx: 1,    
            furtherInfo: {
                explanation: (
                    `Mountain snow and ice account for 30% of California’s water supply.`
                ),
                sources: [
                    {
                        source: 'Public Policy Institute of California',
                        url: 'https://www.ppic.org/publication/droughts-in-california/',
                    },
                ],
            },
        },
        {
            //question 10//
            question: "What was the estimated statewide economic cost of the last drought in 2012-2016?",
            choices: ['$220,000', '$22,000,000', '$2,200,000,000', '$22,000,000,000'],
            answerIdx: 2,    
            furtherInfo: {
                explanation: (
                    `The estimated statewide economic cost of the 2012-2016 drought was $2.2 billion.`
                ),
                sources: [
                    {
                        source: 'University of California Davis',
                        url: 'https://watershed.ucdavis.edu/files/biblio/DroughtReport_23July2014_0.pdf',
                    },
                ],
            },
        },
    ],

};

export default californiaDrought;