import React, { useRef, useEffect } from "react";
import NotificationSystem from "react-notification-system";
import Quiz from "./components/Quiz";
import { makeStyles } from "@material-ui/core/styles";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { GenTheme } from "./components/quizConfig";
import QuizicistIntro from "./components/QuizicistIntro";
import { useStore } from "./contexts/store";
import { SignInWithSetUsername as SignIn } from "./components/SignIn";
import SignUp from "./components/SignUp";
import Landing from "./components/Landing";
import ProfileUserAccount from "./components/profile/UserAccount";
import ProfileName from "./components/profile/Name";
import Email from "./components/profile/Email";
import ChangePassword from "./components/profile/ChangePassword";
import DeleteAccount from "./components/profile/DeleteAccount";
import MyScore from "./components/MyScore";
import Review from "./components/Review";
import { OutroData as Outro } from "./components/Outro";
import "./assets/designs/overide.css";

// non-challenge quizzes
import aFigureOfSpeech from "./quizdata/aFigureOfSpeech";
import americanInfrastructure from "./quizdata/americanInfrastructure";
import apolloProgram from "./quizdata/apolloProgram";
import architecturalScaling from "./quizdata/architecturalScaling";
import asteroidSurvival from "./quizdata/asteroidSurvival";
import autism from "./quizdata/autism";
import bangaloresWells from "./quizdata/bangaloresWells";
import bidensChildcarePlan from "./quizdata/bidensChildcarePlan";
import bidensInfrastructurePlan from "./quizdata/bidensInfrastructurePlan";
import bigNumberMath from "./quizdata/bigNumberMath";
import bigVsLittle from "./quizdata/bigVsLittle";
import blm2020ProtestNumbers from "./quizdata/blm2020ProtestNumbers";
import bogotaACityInTransit from "./quizdata/bogotaACityInTransit";
import boneQuiz from "./quizdata/bonesQuiz";
import brainHealth from "./quizdata/brainHealth";
import californiaDrought from "./quizdata/californiaDrought";
import californiaHighSpeedRail from "./quizdata/californiaHighSpeedRail";
import californiasBigBatteries from "./quizdata/californiasBigBatteries";
import californiasRedwoods from "./quizdata/californiaRedwoods";
import cartoonQuiz from "./quizdata/cartoonQuiz";
import ceoPayRatios from "./quizdata/ceoPayRatios";
import chemicalDisasters from "./quizdata/chemicalDisasters";
import chinasUyghurCamps from "./quizdata/chinasUyghurCamps";
import cicadasAndBroodX from "./quizdata/cicadasAndBroodX";
import coffeeProduction from "./quizdata/coffeeProduction";
import collegeTuition from "./quizdata/collegeTuition";
import combatingCarbonEmissions from "./quizdata/combatingCarbonEmissions";
import concreteCoastlines from "./quizdata/concreteCoastlines";
import conservationKenya from "./quizdata/conservationKenya";
import costOfPrelude from "./quizdata/costOfPrelude";
import currencyExchange from "./quizdata/currencyExchange";
import currentEvents from "./quizdata/currentEvents";
import dd1SettingTheStage from "./quizdata/dd1SettingTheStage";
import dd2StoppingClimateChange from "./quizdata/dd2StoppingClimateChange";
import dd3ReducingSources from "./quizdata/dd3ReducingSources";
import dd4SupportingSinks from "./quizdata/dd4SupportingSinks";
import dd5PuttingItAllTogether from "./quizdata/dd5PuttingItAllTogether";
import dd6makingItHappen from "./quizdata/dd6MakingItHappen";
import disneylandQuiz from "./quizdata/disneylandQuiz";
import drugOrPlanet from "./quizdata/drugOrPlanet";
import dwarfPlanets from "./quizdata/dwarfPlanets";
import engineeringClimateSolutions from "./quizdata/engineeringClimateSolutions";
import environmentalRacism from "./quizdata/environmentalRacism";
import everyBreathQuiz from "./quizdata/everyBreathQuiz";
import f35PriceTag from "./quizdata/f35PriceTag.js";
import fallaciouslyYours from "./quizdata/fallaciouslyYours";
import famousMistranslations from "./quizdata/famousMistranslations";
import fightingMalaria from "./quizdata/fightingMalaria";
import forestQuiz from "./quizdata/forestQuiz";
import fungiQuiz from "./quizdata/fungiQuiz";
import gameOfThronesQuiz from "./quizdata/gameOfThronesQuiz";
import globalSeedVault from "./quizdata/globalSeedVault";
import aGlobalWarning from "./quizdata/aGlobalWarning";
import goingViral from "./quizdata/goingViral";
import goldQuiz from "./quizdata/goldQuiz";
import greatApproximations from "./quizdata/greatApproximations";
import greenLithium from "./quizdata/greenLithium";
import heartyNumbers from "./quizdata/heartyNumbers";
import historyOfTheUniverse from "./quizdata/historyOfTheUniverse";
import homePrices from "./quizdata/homePrices";
import hongKongHousingCrisis from "./quizdata/hongKongHousingCrisis";
import hongKongZhuhaiMacauBridge from "./quizdata/hongKongZhuhaiMacauBridge";
import indiasSolarCanals from "./quizdata/indiasSolarCanals";
import infinitePlastics from "./quizdata/infinitePlastics";
import internetSpeed from "./quizdata/internetSpeed";
import interstellarTravelers from "./quizdata/interstellarTravelers";
import isItScience from "./quizdata/isItScience";
import languageOnTheInternet from "./quizdata/languageOnTheInternet";
import lifeOnTheInternet from "./quizdata/lifeOnTheInternet";
import lifespanOfCivilizations from "./quizdata/lifespanOfCivilizations";
import logicalFallacies from "./quizdata/logicalFallacies";
import marsInNumbers from "./quizdata/marsInNumbers";
import minecraftQuiz from "./quizdata/minecraftQuiz";
import modernAlchemy from "./quizdata/modernAlchemy";
import monstrousOrigins from "./quizdata/monstrousOrigins";
import movieQuiz from "./quizdata/movieQuiz";
import movieQuizTwo from "./quizdata/movieQuizTwo";
import movieQuizThree from "./quizdata/movieQuizThree";
import musicTrivia60 from "./quizdata/musicTrivia60";
import musicQuiz70 from "./quizdata/musicQuiz70";
import musicTrivia80 from "./quizdata/musicTrivia80";
import musicTrivia90 from "./quizdata/musicTrivia90";
import mythologyTruthQuiz from "./quizdata/mythologyTruthQuiz";
import n95Masks from "./quizdata/n95Masks";
import neanderthals from "./quizdata/neanderthals";
import needForCaffeine from "./quizdata/needForCaffeine";
import nonViolentProtests from "./quizdata/nonViolentProtests";
import nsfFunding from "./quizdata/nsfFunding";
import octopusDreams from "./quizdata/octopusDreams";
import pandemicsByNumbers from "./quizdata/pandemicsByNumbers";
import pharmaceuticalRacketeering from "./quizdata/pharmaceuticalRacketeering";
import plantOrSpread from "./quizdata/plantOrSpread";
import policingAndGeorgeFloyd from "./quizdata/policingAndGeorgeFloyd";
import psychedelicIndustry from "./quizdata/psychedelicIndustry";
import psychologicalMarketing from "./quizdata/psychologicalMarketing";
import revengeBedtimeProcrastination from "./quizdata/revengeBedtimeProcrastination";
import scienceQuiz from "./quizdata/scienceQuiz";
import secondLanguage from "./quizdata/secondLanguage";
import shanghaiTower from "./quizdata/shanghaiTower";
import slimeTime from "./quizdata/slimeTime";
import starWarsQuiz from "./quizdata/starWarsQuiz";
import studentLoans from "./quizdata/studentLoans";
import sustainableSkyscrapers from "./quizdata/sustainableSkyscrapers";
import theCostOfWar from "./quizdata/theCostOfWar";
import theTaliban from "./quizdata/theTaliban";
import theWorthOfCopper from "./quizdata/theWorthOfCopper";
import timeZones from "./quizdata/timeZones";
import tolkienQuiz from "./quizdata/tolkienQuiz";
import turkeyTalk from "./quizdata/turkeyTalk";
import unitedStatesPoverty from "./quizdata/unitedStatesPoverty";
import urbanEnergy from "./quizdata/urbanEnergy";
import volcanicMines from "./quizdata/volcanicMines";
import voyagerJourneys from "./quizdata/voyagerJourneys";
import wasteByTheNumbers from "./quizdata/wasteByTheNumbers";
import weirdAnimals from "./quizdata/weirdAnimals";
import whatColorIsFire from "./quizdata/whatColorIsFire";
import yogaQuiz from "./quizdata/yogaQuiz";

// challenge quizzes
import oneMillionOrNot from "./quizdata/oneMillionOrNot";
import oneThousandOrNot from "./quizdata/oneThousandOrNot";
import tenThousandOrNot from "./quizdata/tenThousandOrNot";
import wellRoundedDivision1 from "./quizdata/wellRoundedDivision1";
import wellRoundedDivision2 from "./quizdata/wellRoundedDivision2";
import wellRoundedDivision4 from "./quizdata/wellRoundedDivision4";
import wellRoundedDivision5 from "./quizdata/wellRoundedDivision5";
import wellRoundedDivision3 from "./quizdata/wellRoundedDivision3";


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

function App() {
  const classes = useStyles();
  const theme = GenTheme();
  const notificationSystem = useRef(null);
  const [state, dispatch] = useStore();

  useEffect(function setupNotificationSystem() {
    if (!state.notificationSystem) {
      dispatch({ type: "setNotificationSystem", payload: notificationSystem });
    }
  });

  return (
    <>
      <Router>
        <Switch>
          <>
            <Route exact path="/">
              <Landing theme={theme} />
            </Route>
            <Route exact path="/quizzes" component={QuizicistIntro} />

            <Route exact path="/my-scores" component={MyScore} />
            <Route exact path="/signin">
              <SignIn showSignOut={true} />
            </Route>
            <Route exact path="/quizzes/:quizName/outro">
              <Outro />
            </Route>
            <Route exact path="/signup">
              <SignUp />
            </Route>
            <Route exact path="/profile/name">
              <ProfileName />
            </Route>
            <Route exact path="/profile/email">
              <Email />
            </Route>
            <Route exact path="/profile/change_password">
              <ChangePassword />
            </Route>
            <Route exact path="/profile/delete_account">
              <DeleteAccount />
            </Route>
            <Route exact path="/profile">
              <ProfileUserAccount />
            </Route>
            <Route exact path="/review">
              <Review />
            </Route>
            <Route exact path="/quizzes/oneThousandOrNot" className={classes.root}>
              <Quiz quizData={oneThousandOrNot} theme={theme} />
            </Route>

            <Route exact path="/quizzes/tenThousandOrNot" className={classes.root}>
              <Quiz quizData={tenThousandOrNot} theme={theme} />
            </Route>

            <Route exact path="/quizzes/oneMillionOrNot" className={classes.root}>
              <Quiz quizData={oneMillionOrNot} theme={theme} />
            </Route>

            <Route exact path="/quizzes/n95masks" className={classes.root}>
              <Quiz quizData={n95Masks} theme={theme} />
            </Route>

            <Route exact path="/quizzes/enotation" className={classes.root}>
              <Quiz quizData={bigNumberMath} theme={theme} />
            </Route>

            <Route exact path="/quizzes/greatApproximations" className={classes.root}>
              <Quiz quizData={greatApproximations} theme={theme} />
            </Route>

            <Route exact path="/quizzes/currentevents" className={classes.root}>
              <Quiz quizData={currentEvents} theme={theme} />
            </Route>

            <Route exact path="/quizzes/isItScience" className={classes.root}>
              <Quiz quizData={isItScience} theme={theme} />
            </Route>

            <Route exact path="/quizzes/currencyExchange" className={classes.root}>
              <Quiz quizData={currencyExchange} theme={theme} />
            </Route>

            <Route exact path="/quizzes/goingViral" className={classes.root}>
              <Quiz quizData={goingViral} theme={theme} />
            </Route>

            <Route exact path="/quizzes/everyBreathQuiz" className={classes.root}>
              <Quiz quizData={everyBreathQuiz} theme={theme} />
            </Route>

            <Route exact path="/quizzes/scienceQuiz" className={classes.root}>
              <Quiz quizData={scienceQuiz} theme={theme} />
            </Route>

            <Route exact path="/quizzes/policingAndGeorgeFloyd" className={classes.root}>
              <Quiz quizData={policingAndGeorgeFloyd} theme={theme} />
            </Route>

            <Route exact path="/quizzes/starWarsQuiz" className={classes.root}>
              <Quiz quizData={starWarsQuiz} theme={theme} />
            </Route>

            <Route exact path="/quizzes/timeZones" className={classes.root}>
              <Quiz quizData={timeZones} theme={theme} />
            </Route>

            <Route exact path="/quizzes/architecturalScaling" className={classes.root}>
              <Quiz quizData={architecturalScaling} theme={theme} />
            </Route>

            <Route exact path="/quizzes/fungiQuiz" className={classes.root}>
              <Quiz quizData={fungiQuiz} theme={theme} />
            </Route>

            <Route exact path="/quizzes/mythologyTruthQuiz" className={classes.root}>
              <Quiz quizData={mythologyTruthQuiz} theme={theme} />
            </Route>

            <Route exact path="/quizzes/gameOfThronesQuiz" className={classes.root}>
              <Quiz quizData={gameOfThronesQuiz} theme={theme} />
            </Route>

            <Route exact path="/quizzes/yogaQuiz" className={classes.root}>
              <Quiz quizData={yogaQuiz} theme={theme} />
            </Route>

            <Route exact path="/quizzes/cartoonQuiz" className={classes.root}>
              <Quiz quizData={cartoonQuiz} theme={theme} />
            </Route>

            <Route exact path="/quizzes/disneylandQuiz" className={classes.root}>
              <Quiz quizData={disneylandQuiz} theme={theme} />
            </Route>

            <Route exact path="/quizzes/tolkienQuiz" className={classes.root}>
              <Quiz quizData={tolkienQuiz} theme={theme} />
            </Route>

            <Route exact path="/quizzes/movieQuiz" className={classes.root}>
              <Quiz quizData={movieQuiz} theme={theme} />
            </Route>

            <Route exact path="/quizzes/musicTrivia60" className={classes.root}>
              <Quiz quizData={musicTrivia60} theme={theme} />
            </Route>

            <Route exact path="/quizzes/musicQuiz70" className={classes.root}>
              <Quiz quizData={musicQuiz70} theme={theme} />
            </Route>

            <Route exact path="/quizzes/movieQuizTwo" className={classes.root}>
              <Quiz quizData={movieQuizTwo} theme={theme} />
            </Route>

            <Route exact path="/quizzes/musicTrivia80" className={classes.root}>
              <Quiz quizData={musicTrivia80} theme={theme} />
            </Route>

            <Route exact path="/quizzes/musicTrivia90" className={classes.root}>
              <Quiz quizData={musicTrivia90} theme={theme} />
            </Route>

            <Route exact path="/quizzes/movieQuizThree" className={classes.root}>
              <Quiz quizData={movieQuizThree} theme={theme} />
            </Route>

            <Route exact path="/quizzes/f35PriceTag" className={classes.root}>
              <Quiz quizData={f35PriceTag} theme={theme} />
            </Route>

            <Route exact path="/quizzes/nsfFunding" className={classes.root}>
              <Quiz quizData={nsfFunding} theme={theme} />
            </Route>

            <Route exact path="/quizzes/wellRoundedDivision1" className={classes.root}>
              <Quiz quizData={wellRoundedDivision1} theme={theme} />
            </Route>

            <Route exact path="/quizzes/wellRoundedDivision2" className={classes.root}>
              <Quiz quizData={wellRoundedDivision2} theme={theme} />
            </Route>

            <Route exact path="/quizzes/wellRoundedDivision4" className={classes.root}>
              <Quiz quizData={wellRoundedDivision4} theme={theme} />
            </Route>

            <Route exact path="/quizzes/wellRoundedDivision5" className={classes.root}>
              <Quiz quizData={wellRoundedDivision5} theme={theme} />
            </Route>

            <Route exact path="/quizzes/wellRoundedDivision3" className={classes.root}>
              <Quiz quizData={wellRoundedDivision3} theme={theme} />
            </Route>

            {/* Quizicist 2.0: new additions */}
            <Route exact path="/quizzes/bogotaACityInTransit" className={classes.root}>
              <Quiz quizData={bogotaACityInTransit} theme={theme} />
            </Route>
            <Route exact path="/quizzes/aGlobalWarning" className={classes.root}>
              <Quiz quizData={aGlobalWarning} theme={theme} />
            </Route>
            <Route exact path="/quizzes/heartyNumbers" className={classes.root}>
              <Quiz quizData={heartyNumbers} theme={theme} />
            </Route>
            <Route exact path="/quizzes/octopusDreams" className={classes.root}>
              <Quiz quizData={octopusDreams} theme={theme} />
            </Route>
            <Route exact path="/quizzes/wasteByTheNumbers" className={classes.root}>
              <Quiz quizData={wasteByTheNumbers} theme={theme} />
            </Route>
            <Route exact path="/quizzes/autism" className={classes.root}>
              <Quiz quizData={autism} theme={theme} />
            </Route>
            <Route exact path="/quizzes/famousMistranslations" className={classes.root}>
              <Quiz quizData={famousMistranslations} theme={theme} />
            </Route>
            <Route exact path="/quizzes/fightingMalaria" className={classes.root}>
              <Quiz quizData={fightingMalaria} theme={theme} />
            </Route>
            <Route exact path="/quizzes/goldQuiz" className={classes.root}>
              <Quiz quizData={goldQuiz} theme={theme} />
            </Route>
            <Route exact path="/quizzes/urbanEnergy" className={classes.root}>
              <Quiz quizData={urbanEnergy} theme={theme} />
            </Route>
            <Route exact path="/quizzes/weirdAnimals" className={classes.root}>
              <Quiz quizData={weirdAnimals} theme={theme} />
            </Route>
            <Route exact path="/quizzes/whatColorIsFire" className={classes.root}>
              <Quiz quizData={whatColorIsFire} theme={theme} />
            </Route>
            <Route exact path="/quizzes/americanInfrastructure" className={classes.root}>
              <Quiz quizData={americanInfrastructure} theme={theme} />
            </Route>
            <Route exact path="/quizzes/bidensChildcarePlan" className={classes.root}>
              <Quiz quizData={bidensChildcarePlan} theme={theme} />
            </Route>
            <Route exact path="/quizzes/bidensInfrastructurePlan" className={classes.root}>
              <Quiz quizData={bidensInfrastructurePlan} theme={theme} />
            </Route>
            <Route exact path="/quizzes/blm2020ProtestNumbers" className={classes.root}>
              <Quiz quizData={blm2020ProtestNumbers} theme={theme} />
            </Route>
            <Route exact path="/quizzes/californiaDrought" className={classes.root}>
              <Quiz quizData={californiaDrought} theme={theme} />
            </Route>
            <Route exact path="/quizzes/cicadasAndBroodX" className={classes.root}>
              <Quiz quizData={cicadasAndBroodX} theme={theme} />
            </Route>
            <Route exact path="/quizzes/costOfPrelude" className={classes.root}>
              <Quiz quizData={costOfPrelude} theme={theme} />
            </Route>
            <Route exact path="/quizzes/environmentalRacism" className={classes.root}>
              <Quiz quizData={environmentalRacism} theme={theme} />
            </Route>
            <Route exact path="/quizzes/forestQuiz" className={classes.root}>
              <Quiz quizData={forestQuiz} theme={theme} />
            </Route>
            <Route exact path="/quizzes/nonViolentProtests" className={classes.root}>
              <Quiz quizData={nonViolentProtests} theme={theme} />
            </Route>

            <Route exact path="/quizzes/apolloProgram" className={classes.root}>
              <Quiz quizData={apolloProgram} theme={theme} />
            </Route>
            <Route exact path="/quizzes/bangaloresWells" className={classes.root}>
              <Quiz quizData={bangaloresWells} theme={theme} />
            </Route>
            <Route exact path="/quizzes/ceoPayRatios" className={classes.root}>
              <Quiz quizData={ceoPayRatios} theme={theme} />
            </Route>
            <Route exact path="/quizzes/concreteCoastlines" className={classes.root}>
              <Quiz quizData={concreteCoastlines} theme={theme} />
            </Route>
            <Route exact path="/quizzes/conservationKenya" className={classes.root}>
              <Quiz quizData={conservationKenya} theme={theme} />
            </Route>
            <Route exact path="/quizzes/hongKongZhuhaiMacauBridge" className={classes.root}>
              <Quiz quizData={hongKongZhuhaiMacauBridge} theme={theme} />
            </Route>
            <Route exact path="/quizzes/indiasSolarCanals" className={classes.root}>
              <Quiz quizData={indiasSolarCanals} theme={theme} />
            </Route>
            <Route exact path="/quizzes/infinitePlastics" className={classes.root}>
              <Quiz quizData={infinitePlastics} theme={theme} />
            </Route>

            <Route exact path="/quizzes/interstellarTravelers" className={classes.root}>
              <Quiz quizData={interstellarTravelers} theme={theme} />
            </Route>
            <Route exact path="/quizzes/lifespanOfCivilizations" className={classes.root}>
              <Quiz quizData={lifespanOfCivilizations} theme={theme} />
            </Route>
            <Route exact path="/quizzes/marsInNumbers" className={classes.root}>
              <Quiz quizData={marsInNumbers} theme={theme} />
            </Route>
            <Route exact path="/quizzes/modernAlchemy" className={classes.root}>
              <Quiz quizData={modernAlchemy} theme={theme} />
            </Route>
            <Route exact path="/quizzes/neanderthals" className={classes.root}>
              <Quiz quizData={neanderthals} theme={theme} />
            </Route>
            <Route exact path="/quizzes/shanghaiTower" className={classes.root}>
              <Quiz quizData={shanghaiTower} theme={theme} />
            </Route>
            <Route exact path="/quizzes/globalSeedVault" className={classes.root}>
              <Quiz quizData={globalSeedVault} theme={theme} />
            </Route>
            <Route exact path="/quizzes/voyagerJourneys" className={classes.root}>
              <Quiz quizData={voyagerJourneys} theme={theme} />
            </Route>
            <Route exact path="/quizzes/plantOrSpread" className={classes.root}>
              <Quiz quizData={plantOrSpread} theme={theme} />
            </Route>
            <Route exact path="/quizzes/pharmaceuticalRacketeering" className={classes.root}>
              <Quiz quizData={pharmaceuticalRacketeering} theme={theme} />
            </Route>
            <Route exact path="/quizzes/sustainableSkyscrapers" className={classes.root}>
              <Quiz quizData={sustainableSkyscrapers} theme={theme} />
            </Route>
            <Route exact path="/quizzes/hongKongHousingCrisis" className={classes.root}>
              <Quiz quizData={hongKongHousingCrisis} theme={theme} />
            </Route>
            <Route exact path="/quizzes/asteroidSurvival" className={classes.root}>
              <Quiz quizData={asteroidSurvival} theme={theme} />
            </Route>
            <Route exact path="/quizzes/brainHealth" className={classes.root}>
              <Quiz quizData={brainHealth} theme={theme} />
            </Route>
            <Route exact path="/quizzes/revengeBedtimeProcrastination" className={classes.root}>
              <Quiz quizData={revengeBedtimeProcrastination} theme={theme} />
            </Route>
            <Route exact path="/quizzes/logicalFallacies" className={classes.root}>
              <Quiz quizData={logicalFallacies} theme={theme} />
            </Route>
            <Route exact path="/quizzes/greenLithium" className={classes.root}>
              <Quiz quizData={greenLithium} theme={theme} />
            </Route>
            <Route exact path="/quizzes/unitedStatesPoverty" className={classes.root}>
              <Quiz quizData={unitedStatesPoverty} theme={theme} />
            </Route>
            <Route exact path="/quizzes/collegeTuition" className={classes.root}>
              <Quiz quizData={collegeTuition} theme={theme} />
            </Route>
            <Route exact path="/quizzes/californiaHighSpeedRail" className={classes.root}>
              <Quiz quizData={californiaHighSpeedRail} theme={theme} />
            </Route>
            <Route exact path="/quizzes/californiasBigBatteries" className={classes.root}>
              <Quiz quizData={californiasBigBatteries} theme={theme} />
            </Route>
            <Route exact path="/quizzes/californiasRedwoods" className={classes.root}>
              <Quiz quizData={californiasRedwoods} theme={theme} />
            </Route>
            <Route exact path="/quizzes/studentLoans" className={classes.root}>
              <Quiz quizData={studentLoans} theme={theme} />
            </Route>
            <Route exact path="/quizzes/combatingCarbonEmissions" className={classes.root}>
              <Quiz quizData={combatingCarbonEmissions} theme={theme} />
            </Route>
            <Route exact path="/quizzes/engineeringClimateSolutions" className={classes.root}>
              <Quiz quizData={engineeringClimateSolutions} theme={theme} />
            </Route>
            <Route exact path="/quizzes/home-prices" className={classes.root}>
              <Quiz quizData={homePrices} theme={theme} />
            </Route>
            <Route exact path="/quizzes/chemical-disasters" className={classes.root}>
              <Quiz quizData={chemicalDisasters} theme={theme} />
            </Route>
            <Route exact path="/quizzes/dd1-setting-the-stage" className={classes.root}>
              <Quiz quizData={dd1SettingTheStage} theme={theme} />
            </Route>
            <Route exact path="/quizzes/dd2-stopping-climate-change" className={classes.root}>
              <Quiz quizData={dd2StoppingClimateChange} theme={theme} />
            </Route>
            <Route exact path="/quizzes/dd3-reducing-sources" className={classes.root}>
              <Quiz quizData={dd3ReducingSources} theme={theme} />
            </Route>
            <Route exact path="/quizzes/dd4-supporting-sinks" className={classes.root}>
              <Quiz quizData={dd4SupportingSinks} theme={theme} />
            </Route>
            <Route exact path="/quizzes/dd5-putting-it-all-together" className={classes.root}>
              <Quiz quizData={dd5PuttingItAllTogether} theme={theme} />
            </Route>
            <Route exact path="/quizzes/dd6makingItHappen" className={classes.root}>
              <Quiz quizData={dd6makingItHappen} theme={theme} />
            </Route>
            <Route exact path="/quizzes/theCostOfWar" className={classes.root}>
              <Quiz quizData={theCostOfWar} theme={theme} />
            </Route>
            <Route exact path="/quizzes/theTaliban" className={classes.root}>
              <Quiz quizData={theTaliban} theme={theme} />
            </Route>
            <Route exact path="/quizzes/pandemicsByNumbers" className={classes.root}>
              <Quiz quizData={pandemicsByNumbers} theme={theme} />
            </Route>

            <Route exact path="/quizzes/monstrousOrigins" className={classes.root}>
              <Quiz quizData={monstrousOrigins} theme={theme} />
            </Route>
            <Route exact path="/quizzes/needForCaffeine" className={classes.root}>
              <Quiz quizData={needForCaffeine} theme={theme} />
            </Route>
            <Route exact path="/quizzes/coffeeProduction" className={classes.root}>
              <Quiz quizData={coffeeProduction} theme={theme} />
            </Route>
            <Route exact path="/quizzes/lifeOnTheInternet" className={classes.root}>
              <Quiz quizData={lifeOnTheInternet} theme={theme} />
            </Route>
            <Route exact path="/quizzes/internetSpeed" className={classes.root}>
              <Quiz quizData={internetSpeed} theme={theme} />
            </Route>
            <Route exact path="/quizzes/boneQuiz" className={classes.root}>
              <Quiz quizData={boneQuiz} theme={theme} />
            </Route>
            <Route exact path="/quizzes/chinasUyghurCamps" className={classes.root}>
              <Quiz quizData={chinasUyghurCamps} theme={theme} />
            </Route>
            <Route exact path="/quizzes/volcanicMines" className={classes.root}>
              <Quiz quizData={volcanicMines} theme={theme} />
            </Route>
            <Route exact path="/quizzes/historyOfTheUniverse" className={classes.root}>
              <Quiz quizData={historyOfTheUniverse} theme={theme} />
            </Route>
            <Route exact path="/quizzes/drugOrPlanet" className={classes.root}>
              <Quiz quizData={drugOrPlanet} theme={theme} />
            </Route>
            <Route exact path="/quizzes/fallaciouslyYours" className={classes.root}>
              <Quiz quizData={fallaciouslyYours} theme={theme} />
            </Route>
            <Route exact path="/quizzes/turkeyTalk" className={classes.root}>
              <Quiz quizData={turkeyTalk} theme={theme} />
            </Route>
            <Route exact path="/quizzes/psychologicalMarketing" className={classes.root}>
              <Quiz quizData={psychologicalMarketing} theme={theme} />
            </Route>
            <Route exact path="/quizzes/theWorthOfCopper" className={classes.root}>
              <Quiz quizData={theWorthOfCopper} theme={theme} />
            </Route>
            <Route exact path="/quizzes/languageOnTheInternet" className={classes.root}>
              <Quiz quizData={languageOnTheInternet} theme={theme} />
            </Route>
            <Route exact path="/quizzes/psychedelicIndustry" className={classes.root}>
              <Quiz quizData={psychedelicIndustry} theme={theme} />
            </Route>
            <Route exact path="/quizzes/secondLanguage" className={classes.root}>
              <Quiz quizData={secondLanguage} theme={theme} />
            </Route>
            <Route exact path="/quizzes/aFigureOfSpeech" className={classes.root}>
              <Quiz quizData={aFigureOfSpeech} theme={theme} />
            </Route>
            <Route exact path="/quizzes/dwarfPlanets" className={classes.root}>
              <Quiz quizData={dwarfPlanets} theme={theme} />
            </Route>
            <Route exact path="/quizzes/minecraftQuiz" className={classes.root}>
              <Quiz quizData={minecraftQuiz} theme={theme} />
            </Route>
            <Route exact path="/quizzes/bigVsLittle" className ={classes.root}>
              <Quiz quizData={bigVsLittle} theme={theme} />
            </Route>
            <Route exact path="/quizzes/slimeTime" className ={classes.root}>
              <Quiz quizData={slimeTime} theme={theme} />
            </Route>

          </>
        </Switch>
      </Router>
      <NotificationSystem ref={notificationSystem} />
    </>
  );
}

export default App;
