 const policingAndGeorgeFloyd = {
    id: "policingAndGeorgeFloyd",
    name: 'Policing and George Floyd',
    linkTo: "/quizzes/policingAndGeorgeFloyd",
    explainerOn: true,
    description: `A quiz drawn from Harper's Index (August, 2020)`,
    questions: [
        {
            question: "Percentage by which streaming of N.W.A’s “F*** Tha Police” increased in the week following George Floyd’s death:",
            choices: ['23%', '123%', '272%', '299%'],
            answerIdx: 2,    
            furtherInfo: {
                sources: [
                    {
                        source: 'Alpha Data (NYC)',
                        url: 'https://harpers.org/archive/2020/08/',
                    },
                ],
            },
        },
        {
            question: "Factor by which the Minneapolis police used force more often against black people than white people over the past five years:",
            choices: ['2', '3', '5', '7'],
            answerIdx: 3,    
            furtherInfo: {
                sources: [
                    {
                        source: 'Open Minneapolis',
                        url: 'https://harpers.org/archive/2020/08/',
                    },
                ],                
            },
        },
        {
            question: "Percentage of Minneapolis police officers who live in Minneapolis:",
            choices: ['7', '19', '48', '60'],
            answerIdx: 0,    
            furtherInfo: {
                sources: [
                    {
                        source: 'Star Tribune (Minneapolis)',
                        url: 'https://harpers.org/archive/2020/08/',
                    },
                ],                
            },
        },
        {
            question: "Percentage of white Americans who had an unfavorable view of the police before police brutality protests began in May, 2020:",
            choices: ['6', '18', '20', '28'],
            answerIdx: 1,    
            furtherInfo: {
                sources: [
                    {
                        source: 'Democracy Fund (Washington)',
                        url: 'https://harpers.org/archive/2020/08/',
                    },
                ],                
            },
        },
        {
            question: "Percentage of white Americans who had an unfavorable view of the police a week later, in the middle of May, 2020:",
            choices: ['12', '19', '24', '31'],
            answerIdx: 3,    
            furtherInfo: {
                sources: [
                    {
                        source: 'Democracy Fund (Washington)',
                        url: 'https://harpers.org/archive/2020/08/',
                    },
                ], 
            },
        },
        {
            question: "Percent of Americans who would be comfortable with the military being deployed to their community in response to police brutality protests:",
            choices: ['10%', '20%', '30%', '40%'],
            answerIdx: 3,    
            furtherInfo: {
                sources: [
                    {
                        source: 'YouGov Direct (NYC)',
                        url: 'https://harpers.org/archive/2020/08/',
                    },
                ], 
            },
        },      
        {
            question: "Estimated cost of outfitting a police officer with riot-control gear:",
            choices: ['$200', '$580', '$790', '$900'],
            answerIdx: 2,    
            furtherInfo: {
                sources: [
                    {
                        source: 'Sirchie (Youngsville, N.C.)',
                        url: 'https://harpers.org/archive/2020/08/',
                    },
                ], 
            },
        },
        {
            question: "Estimated cost of outfitting a hospital worker with standard personal protective equipment:",
            choices: ['$11', '$41', '$71', '$101'],
            answerIdx: 0,    
            furtherInfo: {
                sources: [
                    {
                        source: 'Society for Healthcare Organization Procurement Professionals (Suffern, N.Y.)',
                        url: 'https://harpers.org/archive/2020/08/',
                    },
                ], 
            },
        },                      
    ],

};

export default policingAndGeorgeFloyd;
