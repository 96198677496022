// Non-Challenge Quizzes -- Image thumbnail imports -- Alphabetized
import aFigureOfSpeechThumb from "./assets/quizThumbnails/AFigureOfSpeech.jpg";
import americanInfrastructureThumb from "./assets/quizThumbnails/AmericanInfrastructure.jpg";
import apolloProgramThumb from "./assets/quizThumbnails/ApolloProgram.jpg";
import architecturalScalingThumb from "./assets/quizThumbnails/ArchitecturalScalingThumb.jpg";
import asteroidSurvivalThumb from "./assets/quizThumbnails/AsteroidSurvival.jpg";
import autismThumb from "./assets/quizThumbnails/AutismThumb.jpg";
import bangaloresWellsThumb from "./assets/quizThumbnails/BangaloresWells.jpg";
import bidensChildcarePlanThumb from "./assets/quizThumbnails/BidensChildcarePlan.jpg";
import bidensInfrastructurePlanThumb from "./assets/quizThumbnails/BidensInfrastructurePlan.jpg";
import bigNumbersInTheNewsThumb from "./assets/quizThumbnails/BigNumbersInTheNewsThumb.jpg";
import bigVsLittleThumb from "./assets/quizThumbnails/BigVsLittle.jpg";
import blm2020ProtestNumbersThumb from "./assets/quizThumbnails/BLM2020ProtestNumbers.jpg";
import bogotaACityInTransitThumb from "./assets/quizThumbnails/BogotaACityInTransit.jpg";
import boneQuizThumb from "./assets/quizThumbnails/BonesQuiz.jpg";
import brainHealthThumb from "./assets/quizThumbnails/BrainHealth.jpg";
import ceoPayRatiosThumb from "./assets/quizThumbnails/CEOPayRatios.jpg";
import californiaDroughtThumb from "./assets/quizThumbnails/CaliforniaDrought.jpg";
import californiaHighSpeedRailThumb from "./assets/quizThumbnails/CaliforniaHighSpeedRail.jpg";
import californiasBigBatteriesThumb from "./assets/quizThumbnails/CaliforniasBigBatteries.jpg";
import californiasRedwoodsThumb from "./assets/quizThumbnails/CaliforniasRedwoods.jpg";
import chemicalDisastersThumb from "./assets/quizThumbnails/ChemicalDisasters.jpg";
import chinasUyghurCampsThumb from "./assets/quizThumbnails/ChinasUyghurCamps.jpg";
import cicadasAndBroodXThumb from "./assets/quizThumbnails/CicadasAndBroodX.jpg";
import coffeeProductionThumb from "./assets/quizThumbnails/CoffeeProduction.jpg";
import collegeTuitionThumb from "./assets/quizThumbnails/CollegeTuition.jpg";
import combatingCarbonEmissionsThumb from "./assets/quizThumbnails/CombatingCarbonEmissions.jpg"
import concreteCoastlinesThumb from "./assets/quizThumbnails/ConcreteCoastlines.jpg";
import conservationKenyaThumb from "./assets/quizThumbnails/ConservationKenya.jpg";
import costOfPreludeThumb from "./assets/quizThumbnails/CostOfPrelude.jpg";
import currencyExchangeThumb from "./assets/quizThumbnails/CurrencyExchangeThumb.jpg";
import dd1SettingTheStageThumb from "./assets/quizThumbnails/Drawdown1SettingTheStage.jpg";
import dd2StoppingClimateChangeThumb from "./assets/quizThumbnails/Drawdown2StoppingClimateChange.jpg";
import dd3ReducingSourcesThumb from "./assets/quizThumbnails/Drawdown3ReducingSources.jpg";
import dd4SupportingSinksThumb from "./assets/quizThumbnails/Drawdown4SupportingSinks.jpg";
import dd5PuttingItAllTogetherThumb from "./assets/quizThumbnails/Drawdown5PuttingItAllTogether.jpg";
import disneylandQuizThumb from "./assets/quizThumbnails/DisneylandQuizThumb.jpg";
import drugOrPlanetThumb from "./assets/quizThumbnails/DrugOrPlanet.jpg";
import dd6makingItHappenThumb from "./assets/quizThumbnails/Drawdown6MakingItHappen.jpg";
import dwarfPlanetsThumb from "./assets/quizThumbnails/DwarfPlanets.jpg";
import engineeringClimateSolutionsThumb from "./assets/quizThumbnails/EngineeringClimateSolutions.jpg";
import environmentalRacismThumb from "./assets/quizThumbnails/EnvironmentalRacism.jpg";
import everyBreathQuizThumb from "./assets/quizThumbnails/EveryBreathYouTake.jpg";
import f35PriceTagThumb from "./assets/quizThumbnails/F35Pricetag.jpg";
import fallaciouslyYoursThumb from "./assets/quizThumbnails/FallaciouslyYours.jpg";
import famousMistranslationsThumb from "./assets/quizThumbnails/FamousMistranslations.jpg";
import fightingMalariaThumb from "./assets/quizThumbnails/FightingMalaria.jpg";
import forestQuizThumb from "./assets/quizThumbnails/ForestQuiz.jpg";
import fungiQuizThumb from "./assets/quizThumbnails/FungiThumb.jpg";
import gameOfThronesQuizThumb from "./assets/quizThumbnails/GameOfThronesThumb.jpg";
import goingViralThumb from "./assets/quizThumbnails/GoingViral.jpg";
import goldQuizThumb from "./assets/quizThumbnails/GoldThumb.jpg";
import aGlobalWarningThumb from "./assets/quizThumbnails/AGlobalWarningThumb.jpg";
import globalSeedVaultThumb from "./assets/quizThumbnails/GlobalSeedVault.jpg";
import greatApproximationsThumb from "./assets/quizThumbnails/GreatApproximationsThumb.jpg";
import greenLithiumThumb from "./assets/quizThumbnails/GreenLithium.jpg";
import heartyNumbersThumb from "./assets/quizThumbnails/HeartyNumbers.jpg";
import historyOfTheUniverseThumb from "./assets/quizThumbnails/HistoryOfTheUniverse.jpg";
import homePricesThumb from "./assets/quizThumbnails/HomePrices.jpg";
import hongKongHousingCrisisThumb from "./assets/quizThumbnails/HongKongHousingCrisis.jpg";
import hongKongZhuhaiMacauBridgeThumb from "./assets/quizThumbnails/HongKongZhuhaiMacauBridge.jpg";
import indiasSolarCanalsThumb from "./assets/quizThumbnails/IndiasSolarCanals.jpg";
import infinitePlasticsThumb from "./assets/quizThumbnails/InfinitePlastics.jpg";
import InternetSpeedThumb from "./assets/quizThumbnails/InternetSpeed.jpg";
import interstellarTravelersThumb from "./assets/quizThumbnails/InterstellarTravelers.jpg";
import languageOnTheInternetThumb from "./assets/quizThumbnails/LanguageOnTheInternet.jpg";
import lifeOnTheInternetThumb from "./assets/quizThumbnails/LifeOnTheInternet2020.jpg";
import lifespanOfCivilizationsThumb from "./assets/quizThumbnails/LifespanOfCivilizations.jpg";
import logicalFallaciesThumb from "./assets/quizThumbnails/LogicalFallacies.jpg";
import marsInNumbersThumb from "./assets/quizThumbnails/MarsInNumbers.jpg";
import minecraftQuizThumb from "./assets/quizThumbnails/Minecraft.jpg";
import modernAlchemyThumb from "./assets/quizThumbnails/ModernAlchemy.jpg";
import monstrousOriginsThumb from "./assets/quizThumbnails/MonstrousOrigins.jpg";
import movieQuizThumb from "./assets/quizThumbnails/HollywoodMovies1Thumb.jpg";
import movieQuizTwoThumb from "./assets/quizThumbnails/HollywoodMovies2Thumb.jpg";
import movieQuizThreeThumb from "./assets/quizThumbnails/HollywoodMovies3Thumb.jpg";
import musicTrivia60Thumb from "./assets/quizThumbnails/60sMusicThumb.jpg";
import musicQuiz70Thumb from "./assets/quizThumbnails/70sMusicThumb.jpg";
import musicTrivia80Thumb from "./assets/quizThumbnails/80sMusicThumb.jpg";
import musicTrivia90Thumb from "./assets/quizThumbnails/90sMusicThumb.jpg";
import mythologicalTruthsThumb from "./assets/quizThumbnails/MythologicalTruthsThumb.jpg";
import n95Thumb from "./assets/quizThumbnails/N95.jpg";
import neanderthalsThumb from "./assets/quizThumbnails/Neanderthals.jpg";
import needForCaffeineThumb from "./assets/quizThumbnails/NeedForCaffeine.jpg";
import nonViolentProtestsThumb from "./assets/quizThumbnails/NonViolentProtests.jpg";
import nsfMolecularhThumb from "./assets/quizThumbnails/FundingTheNSF.jpg";
import octopusDreamsThumb from "./assets/quizThumbnails/OctopusDreamsThumb.jpg";
import pandemicsByNumbersThumb from "./assets/quizThumbnails/PandemicsByNumbers.jpg";
import pharmaRacketeeringThumb from "./assets/quizThumbnails/PharmaRacketeering.jpg";
import plantOrSpreadThumb from "./assets/quizThumbnails/PlantOrSpread.jpg";
import policingThumb from "./assets/quizThumbnails/PolicingThumb.jpg";
import psychedelicIndustryThumb from "./assets/quizThumbnails/PsychedelicIndustry.jpg";
import psychologicalMarketingThumb from "./assets/quizThumbnails/PsychologicalMarketing.jpg"
import revengeBedtimeProcrastinationThumb from "./assets/quizThumbnails/RevengeBedtimeProcrastination.jpg";
import saturdayMorningCartoonsThumb from "./assets/quizThumbnails/SaturdayMorningCartoonsThumb.jpg";
import scienceQuizThumb from "./assets/quizThumbnails/SciencePotpourriThumb.jpg";
import scientificNotationThumb from "./assets/quizThumbnails/WorkingWithSciNotation.jpg";
import secondLanguageThumb from "./assets/quizThumbnails/SecondLanguage.jpg";
import shanghaiTowerThumb from "./assets/quizThumbnails/ShanghaiTower.jpg";
import slimeTimeThumb from "./assets/quizThumbnails/SlimeTime.jpg";
import starWarsQuizThumb from "./assets/quizThumbnails/StarWarsQuizThumb.jpg";
import studentLoansThumb from "./assets/quizThumbnails/StudentLoans.jpg";
import sustainableSkyscrapersThumb from "./assets/quizThumbnails/SustainableSkyscrapers.jpg";
import theCostOfWarThumb from "./assets/quizThumbnails/TheCostOfWar.jpg";
import theTalibanThumb from "./assets/quizThumbnails/TheTaliban.jpg";
import theWorthOfCopperThumb from "./assets/quizThumbnails/Copper.jpg";
import timeZonesThumb from "./assets/quizThumbnails/TimeZonesQuizThumb.jpg";
import tolkienQuizThumb from "./assets/quizThumbnails/TolkienQuizThumb.jpg";
import turkeyTalkThumb from "./assets/quizThumbnails/TurkeyTalk.jpg";
import unitedStatesPovertyThumb from "./assets/quizThumbnails/UnitedStatesPoverty.jpg";
import urbanEnergyThumb from "./assets/quizThumbnails/UrbanEnergy.jpg";
import volcanicMinesThumb from "./assets/quizThumbnails/VolcanicMines.jpg";
import voyagerJourneysThumb from "./assets/quizThumbnails/VoyagerJourneys.jpg";
import whatIsScienceThumb from "./assets/quizThumbnails/WhatIsScienceThumb.jpg";
import wasteByTheNumbersThumb from "./assets/quizThumbnails/WasteByTheNumbersThumb.jpg";
import weirdAnimalsThumb from "./assets/quizThumbnails/WeirdAnimals.jpg";
import whatColorIsFireThumb from "./assets/quizThumbnails/WhatColorIsFire.jpg";
import yogaQuizThumb from "./assets/quizThumbnails/WhatIsYogaThumb.jpg";

// Challenge Quizzes -- Image thumbnail imports -- Alphabetized
import oneThousandThumb from "./assets/quizThumbnails/OneThousandOrNot.jpg";
import tenThousandThumb from "./assets/quizThumbnails/TenThousandOrNotThumb.jpg";
import oneMillionThumb from "./assets/quizThumbnails/OneMillionOrNot.jpg";
import wellRoundedDivision1Thumb from "./assets/quizThumbnails/WRDivision1NumsNums.jpg";
import wellRoundedDivision2Thumb from "./assets/quizThumbnails/WRDivision2ENotation.jpg";
import wellRoundedDivision3Thumb from "./assets/quizThumbnails/WRDivision3WordsWords.jpg";
import wellRoundedDivision4Thumb from "./assets/quizThumbnails/WRDivision4WordsE.jpg";
import wellRoundedDivision5Thumb from "./assets/quizThumbnails/WRDivision5EWords.jpg";


// *** IMPORTANT: The order of quizzes below is DELIBERATE -- do not change ***
// *** (This is the quiz tile render order) ***
export default [
  /********* PINNED AT TOP ********/
  {
    id: "enotation",
    name: "Using Scientific Notation",
    linkTo: "/quizzes/enotation",
    thumbnailSrc: scientificNotationThumb,
  },
  {
    id: "oneThousandOrNot",
    name: "One Thousand or Not?",
    linkTo: "/quizzes/oneThousandOrNot",
    challenge: true,
    thumbnailSrc: oneThousandThumb,
  },
  {
    id: "tenThousandOrNot",
    name: "Ten Thousand or Not?",
    challenge: true,
    linkTo: "/quizzes/tenThousandOrNot",
    thumbnailSrc: tenThousandThumb,
  },
  {
    id: "oneMillionOrNot",
    name: "One Million or Not?",
    challenge: true,
    linkTo: "/quizzes/oneMillionOrNot",
    thumbnailSrc: oneMillionThumb,
  },
  {
    id: "wellRoundedDivision1",
    name: "Well Rounded Division 1: Numbers with commas",
    challenge: true,
    linkTo: "/quizzes/wellRoundedDivision1",
    thumbnailSrc: wellRoundedDivision1Thumb,
  },
  {
    id: "wellRoundedDivision2",
    name: "Well Rounded Division 2: E-Notation",
    challenge: true,
    linkTo: "/quizzes/wellRoundedDivision2",
    thumbnailSrc: wellRoundedDivision2Thumb,
  },
  {
    id: "wellRoundedDivision3",
    name: "Well Rounded Division 3: Words / Words",
    challenge: true,
    linkTo: "/quizzes/wellRoundedDivision3",
    thumbnailSrc: wellRoundedDivision3Thumb,
  },
  {
    id: "wellRoundedDivision4",
    name: "Well Rounded Division 4: Words / E-Notation",
    challenge: true,
    linkTo: "/quizzes/wellRoundedDivision4",
    thumbnailSrc: wellRoundedDivision4Thumb,
  },
  {
    id: "wellRoundedDivision5",
    name: "Well Rounded Division 5: E-Notation / Words",
    challenge: true,
    linkTo: "/quizzes/wellRoundedDivision5",
    thumbnailSrc: wellRoundedDivision5Thumb,
  },
  /********* JC's list: frontend/src/quizList *********/
  // ---- (JC, ADD NEW QUIZ OBJECTS BELOW) ------




  /********* NEWER and Time-Sensitive or Topical ********/
  {
    name: "Slime Time",
    id: "zMlwfkJ9JFXrQ6KeRjJg",
    linkTo: "/quizzes/slimeTime",
    thumbnailSrc: slimeTimeThumb,
  },
  {
    name: "Big Vs Little",
    id: "eRv8C2PpuUgoNj1Ns9Bl",
    linkTo: "/quizzes/bigVsLittle",
    thumbnailSrc: bigVsLittleThumb,
  },
  {
    name: "Minecraft Quiz",
    id: "S8rvPRaXp7miwSMTEDxz",
    linkTo: "/quizzes/minecraftQuiz",
    thumbnailSrc: minecraftQuizThumb,
  },
  {
    name: "Dwarf Planets",
    id: "Wim7reWVhwaERclAUut1",
    linkTo: "/quizzes/dwarfPlanets",
    thumbnailSrc: dwarfPlanetsThumb,
  },
  {
    id: "f35PriceTag",
    name: "How much is an F-35 jet?",
    linkTo: "/quizzes/f35PriceTag",
    thumbnailSrc: f35PriceTagThumb,
  },  
  {
    id: "currencyExchange",
    name: "Currency Exchange",
    linkTo: "/quizzes/currencyExchange",
    thumbnailSrc: currencyExchangeThumb,
  },
  {
    id: "timeZones",
    name: "Time Zone Conversions",
    linkTo: "/quizzes/timeZones",
    thumbnailSrc: timeZonesThumb,
  },
  {
    id: "architecturalScaling",
    name: "Architectural Scaling",
    linkTo: "/quizzes/architecturalScaling",
    thumbnailSrc: architecturalScalingThumb,
  },  
  {
    name: "A Figure of Speech",
    id: "XyD76Wgr3OirqMJx3KKF",
    linkTo: "/quizzes/aFigureOfSpeech",
    thumbnailSrc: aFigureOfSpeechThumb,
  },
  {
    name: "Second Language",
    id: "uRp2xjMKRewxbrahK3Xc",
    linkTo: "/quizzes/secondLanguage",
    thumbnailSrc: secondLanguageThumb,
  },
  {
    name: "Psychedelic Industry",
    id: "bB5AyS37Bd8fSVCuXIzf",
    linkTo: "/quizzes/psychedelicIndustry",
    thumbnailSrc: psychedelicIndustryThumb,
  },
  {
    name: "Language on the Internet",
    id: "hKz3WV85C9HeSSuPhwwR",
    linkTo: "/quizzes/languageOnTheInternet",
    thumbnailSrc: languageOnTheInternetThumb,
  },
  {
    name: "The Worth of Copper",
    id: "97J11L2mVWRCrvLccpdB",
    linkTo: "/quizzes/theWorthOfCopper",
    thumbnailSrc: theWorthOfCopperThumb,
  },
  {
    name: "Psychological Marketing",
    id: "B58MG821Eg9zV9hTFJ3j",
    linkTo: "/quizzes/psychologicalMarketing",
    thumbnailSrc: psychologicalMarketingThumb,
  },
  {
    name: "Fallaciously Yours",
    id: "D8O6dA7FycFh9IUcSWSg",
    linkTo: "/quizzes/fallaciouslyYours",
    thumbnailSrc: fallaciouslyYoursThumb,
  },
  {
    name: "Prescription Drug or Fictional Planet?",
    id: "g5OvrWBSyZhPCyLG8inV",
    linkTo: "/quizzes/drugOrPlanet",
    thumbnailSrc: drugOrPlanetThumb,
  },
  {
    name: "The Need for Caffeine",
    id: "GnRuEzvTbLZfjSLN0Zqq",
    linkTo: "/quizzes/needForCaffeine",
    thumbnailSrc: needForCaffeineThumb,
  },
  {
    name: "Coffee Production",
    id: "wotux2iFLb9LDk2jdtvN",
    linkTo: "/quizzes/coffeeProduction",
    thumbnailSrc: coffeeProductionThumb,
  },
  {
    name: "Life on the Internet in 2020",
    id: "qfayEbEfC3wFCTWDuFbS",
    linkTo: "/quizzes/lifeOnTheInternet",
    thumbnailSrc: lifeOnTheInternetThumb,
  },
  {
    name: "Internet Speed",
    id: "oPFGc8qbnGF3SCgJWx6P",
    linkTo: "/quizzes/internetSpeed",
    thumbnailSrc: InternetSpeedThumb,
  },
  {
    name: "Bone Quiz",
    id: "bNB68UeKuys8bizP55nY",
    linkTo: "/quizzes/boneQuiz",
    thumbnailSrc: boneQuizThumb,
  },
  {
    name: "China's Uyghur Camps",
    id: "rHx0BZn2WID7VLGaXIzK",
    linkTo: "/quizzes/chinasUyghurCamps",
    thumbnailSrc: chinasUyghurCampsThumb,
  },
  {
    name: "Volcanic Mines",
    id: "YhGgIzGSnn7pcSF92V36",
    linkTo: "/quizzes/volcanicMines",
    thumbnailSrc: volcanicMinesThumb,
  },
  {
    name: "The History of the Universe",
    id: "othNs8980Xfkk5JpwnOW",
    linkTo: "/quizzes/historyOfTheUniverse",
    thumbnailSrc: historyOfTheUniverseThumb,
  },
  {
    name: "The Cost of War",
    id: "YbuFrVLBZJDEe6eoQFbe",
    linkTo: "/quizzes/theCostOfWar",
    thumbnailSrc: theCostOfWarThumb,
  },
  {
    name: "The Taliban",
    id: "Px4lXFrYhRApiO9ufDq2",
    linkTo: "/quizzes/theTaliban",
    thumbnailSrc: theTalibanThumb,
  },
  {
    name: "Pandemics By Numbers",
    id: "0rtKu1ynPNQv7KiRZdE7",
    linkTo: "/quizzes/pandemicsByNumbers",
    thumbnailSrc: pandemicsByNumbersThumb,
  },
  {
    name: "Drawdown 1: Setting the Stage",
    id: "LIjHNU0GZI6v1F5pvszg",
    linkTo: "/quizzes/dd1-setting-the-stage",
    thumbnailSrc: dd1SettingTheStageThumb,
  },
  {
    name: "Drawdown 2: Stopping Climate Change",
    id: "DxNgoA3pqoUGwr3z06ch",
    linkTo: "/quizzes/dd2-stopping-climate-change",
    thumbnailSrc: dd2StoppingClimateChangeThumb,
  },
  {
    name: "Drawdown 3: Reducing Sources",
    id: "F7bMlbK4Xha1JLUof5bd",
    linkTo: "/quizzes/dd3-reducing-sources",
    thumbnailSrc: dd3ReducingSourcesThumb,
  },
  {
    name: "Drawdown 4: Supporting Sinks",
    id: "9oEng1ABaTQyXFdRfnPA",
    linkTo: "/quizzes/dd4-supporting-sinks",
    thumbnailSrc: dd4SupportingSinksThumb,
  },
  {
    name: "Drawdown 5: Putting it All Together",
    id: "wQWOVJAry7K8g2OwCYfT",
    linkTo: "/quizzes/dd5-putting-it-all-together",
    thumbnailSrc: dd5PuttingItAllTogetherThumb,
  },
  {
    name: "Drawdown 6: Making it Happen",
    id: "aXq2w4iWj7IAjEo1whvL",
    linkTo: "/quizzes/dd6makingItHappen",
    thumbnailSrc: dd6makingItHappenThumb,
  },

  {
    name: "Combating Carbon Emissions",
    id: "ORyAIFNbioZbOBTIbyig",
    linkTo: "/quizzes/combatingCarbonEmissions",
    thumbnailSrc: combatingCarbonEmissionsThumb,
  },
  {
    name: "Engineering Climate Solutions",
    id: "xCFNkB7KzbSpJmHFPEt8",
    linkTo: "/quizzes/engineeringClimateSolutions",
    thumbnailSrc: engineeringClimateSolutionsThumb,
  },
  {
    name: "Pharmaceutical Racketeering",
    id: "jrbwKWSYMsOkIiPYsp9o",
    linkTo: "/quizzes/pharmaceuticalRacketeering",
    thumbnailSrc: pharmaRacketeeringThumb,
  },
  {
    name: "Chemical Disasters",
    id: "SYgzNYxJWUczpdix1Yd8",
    linkTo: "/quizzes/chemical-disasters",
    thumbnailSrc: chemicalDisastersThumb,
  },
  {
    name: "The State of American Infrastructure",
    id: "FLS4NvqTs3nRhBAvTbme",
    linkTo: "/quizzes/americanInfrastructure",
    thumbnailSrc: americanInfrastructureThumb,
  },
  {
    name: "Biden's Infrastructure Plan",
    id: "ZhHvXwnSmgUw0A0gUmVc",
    linkTo: "/quizzes/bidensInfrastructurePlan",
    thumbnailSrc: bidensInfrastructurePlanThumb,
  },
  {
    name: "Biden's Childcare Plan",
    id: "VQmRP0hM0lgdfOxv0S9w",
    linkTo: "/quizzes/bidensChildcarePlan",
    thumbnailSrc: bidensChildcarePlanThumb,
  },
  {
    name: "California's High Speed Rail",
    id: "zhu3EtbPolxjcVM813pQ",
    linkTo: "/quizzes/californiaHighSpeedRail",
    thumbnailSrc: californiaHighSpeedRailThumb,
  },
  {
    name: "California's Big Batteries",
    id: "HksKHJykrRtXeewPjlBi",
    linkTo: "/quizzes/californiasBigBatteries",
    thumbnailSrc: californiasBigBatteriesThumb,
  },
  {
    name: "Green Lithium",
    id: "pXMnICpghGKrU2fJFSrc",
    linkTo: "/quizzes/greenLithium",
    thumbnailSrc: greenLithiumThumb,
  },
  {
    name: "Hong Kong-Zhuhai-Macau Bridge",
    id: "PNdbK9fuhoeOBTVBARwc",
    linkTo: "/quizzes/hongKongZhuhaiMacauBridge",
    thumbnailSrc: hongKongZhuhaiMacauBridgeThumb,
  },
  {
    name: "The Shanghai Tower in Numbers",
    id: "kAnH5yVDcPyYG4ZxmuEt",
    linkTo: "/quizzes/shanghaiTower",
    thumbnailSrc: shanghaiTowerThumb,
  },
  {
    name: "Sustainable Skyscrapers",
    id: "UBePQ05e4JCXIjXuY7y9",
    linkTo: "/quizzes/sustainableSkyscrapers",
    thumbnailSrc: sustainableSkyscrapersThumb,
  },
  {
    name: "Hong Kong Housing Crisis",
    id: "k3A2CcTd7svMThVUKYS2",
    linkTo: "/quizzes/hongKongHousingCrisis",
    thumbnailSrc: hongKongHousingCrisisThumb,
  },
  {
    name: "Poverty in the U.S.",
    id: "VZslyB15gy9K9brQUdzw",
    linkTo: "/quizzes/unitedStatesPoverty",
    thumbnailSrc: unitedStatesPovertyThumb,
  },
  {
    name: "Home Prices",
    id: "iBH2Ujd93HUQlCcJtesA",
    linkTo: "/quizzes/home-prices",
    thumbnailSrc: homePricesThumb,
  },
  {
    name: "The cost of Shell's Prelude LNG facility",
    id: "4c6rp9dJ6kNYhX3dV0uy",
    linkTo: "/quizzes/costOfPrelude",
    thumbnailSrc: costOfPreludeThumb,
  },
  {
    name: "CEO Pay Ratios",
    id: "snWbkbXBHZhx1ktwLuTR",
    linkTo: "/quizzes/ceoPayRatios",
    thumbnailSrc: ceoPayRatiosThumb,
  },
  {
    name: "Air Pollution & Environmental Racism",
    id: "Iw193r74ABANGSTOdkkU",
    linkTo: "/quizzes/environmentalRacism",
    thumbnailSrc: environmentalRacismThumb,
  },
  {
    name: "College Tuition",
    id: "2dI5XU8XLbpjIPftL1XR",
    linkTo: "/quizzes/collegeTuition",
    thumbnailSrc: collegeTuitionThumb,
  },
  {
    name: "Student Loans",
    id: "okbFFbrBck6nF91EfDNl",
    linkTo: "/quizzes/studentLoans",
    thumbnailSrc: studentLoansThumb,
  },
  {
    name: "California Drought",
    id: "ep8PrjyBoKqninT3ZWhd",
    linkTo: "/quizzes/californiaDrought",
    thumbnailSrc: californiaDroughtThumb,
  },
  {
    name: "Cicadas and Brood X",
    id: "mkmzbLHQWBsLtoEPP12r",
    linkTo: "/quizzes/cicadasAndBroodX",
    thumbnailSrc: cicadasAndBroodXThumb,
  },

  /********* NEWER but LESS Time-Sensitive ********/

  {
    name: "Asteroid Survival",
    id: "lp6nwTaDIacoq0kvjv87",
    linkTo: "/quizzes/asteroidSurvival",
    thumbnailSrc: asteroidSurvivalThumb,
  },
  {
    name: "Brain Health",
    id: "lFHyolRZRBpoKmBcAYP6",
    linkTo: "/quizzes/brainHealth",
    thumbnailSrc: brainHealthThumb,
  },
  {
    name: "Revenge Bedtime Procrastination",
    id: "HbGkvlZ7WznOtgh1J1QW",
    linkTo: "/quizzes/revengeBedtimeProcrastination",
    thumbnailSrc: revengeBedtimeProcrastinationThumb,
  },
  {
    name: "Logical Fallacies",
    id: "QCY5WR5LNQHNS4w78P94",
    linkTo: "/quizzes/logicalFallacies",
    thumbnailSrc: logicalFallaciesThumb,
  },
  {
    name: "California's Redwoods",
    id: "zvBPiEYt8KWMlMAJk4Nd",
    linkTo: "/quizzes/californiasRedwoods",
    thumbnailSrc: californiasRedwoodsThumb,
  },
  {
    name: "Forests: If they fall, does anyone hear it?",
    id: "YDRfBwnaegpTcavXRej9",
    linkTo: "/quizzes/forestQuiz",
    thumbnailSrc: forestQuizThumb,
  },
  {
    name: "The Numbers of Non-Violent Protest",
    id: "HvmgHKirn2Uz0aoIlIpy",
    linkTo: "/quizzes/nonViolentProtests",
    thumbnailSrc: nonViolentProtestsThumb,
  },
  {
    name: "BLM 2020 Protest Numbers",
    id: "mml0vZ7cwAFYPNUy3MeD",
    linkTo: "/quizzes/blm2020ProtestNumbers",
    thumbnailSrc: blm2020ProtestNumbersThumb,
  },

  /********* NEWER but Evergreen ********/
  {
    name: "The Apollo Program: in 10 Numbers",
    id: "Do8YFnDhRjXHL50x7uzV",
    linkTo: "/quizzes/apolloProgram",
    thumbnailSrc: apolloProgramThumb,
  },
  {
    name: "Bangalore's Wells",
    id: "XrnEM4hLSeoitcz7EzPN",
    linkTo: "/quizzes/bangaloresWells",
    thumbnailSrc: bangaloresWellsThumb,
  },
  {
    name: "Concrete Coastlines",
    id: "QFwuEeVGHU8yDt3IcKL0",
    linkTo: "/quizzes/concreteCoastlines",
    thumbnailSrc: concreteCoastlinesThumb,
  },
  {
    name: "Conservation and Community in Kenya",
    id: "EOOGonmrUcrrECBAmgVq",
    linkTo: "/quizzes/conservationKenya",
    thumbnailSrc: conservationKenyaThumb,
  },
  {
    name: "India's Solar Canals",
    id: "ovSbS7apW13YNhWMxY9o",
    linkTo: "/quizzes/indiasSolarCanals",
    thumbnailSrc: indiasSolarCanalsThumb,
  },
  {
    name: "Infinite Plastics",
    id: "LqEBnCm1WCC9J9nDThIv",
    linkTo: "/quizzes/infinitePlastics",
    thumbnailSrc: infinitePlasticsThumb,
  },
  {
    name: "The Svalbard Global Seed Vault",
    id: "OyNWlcLT1OLlKMa2eXnr",
    linkTo: "/quizzes/globalSeedVault",
    thumbnailSrc: globalSeedVaultThumb,
  },
  {
    name: "To Plant or To Spread?",
    id: "Rm0ORxpW8rexfRF5XZ70",
    linkTo: "/quizzes/plantOrSpread",
    thumbnailSrc: plantOrSpreadThumb,
  },
  {
    name: "Interstellar Travelers",
    id: "Stl8cKemSV5E9IHpMIDY",
    linkTo: "/quizzes/interstellarTravelers",
    thumbnailSrc: interstellarTravelersThumb,
  },
  {
    name: "Mars in Numbers",
    id: "Uk8yAy1cCnzHI8eOV2nj",
    linkTo: "/quizzes/marsInNumbers",
    thumbnailSrc: marsInNumbersThumb,
  },
  {
    name: "The Voyager Journeys",
    id: "y5NDhjiBOoTMOXtRGFKE",
    linkTo: "/quizzes/voyagerJourneys",
    thumbnailSrc: voyagerJourneysThumb,
  },
  {
    name: "The Lifespan of Civilizations",
    id: "nQkfXZdC4oqlc0A2dO4N",
    linkTo: "/quizzes/lifespanOfCivilizations",
    thumbnailSrc: lifespanOfCivilizationsThumb,
  },
  {
    name: "Neanderthals",
    id: "XoDQLj7f3b438UGrcfel",
    linkTo: "/quizzes/neanderthals",
    thumbnailSrc: neanderthalsThumb,
  },
  {
    name: "Modern Alchemy: Element 113",
    id: "XUeizNMXWjjiqybnNL20",
    linkTo: "/quizzes/modernAlchemy",
    thumbnailSrc: modernAlchemyThumb,
  },

  /********* OLDER but Evergreen ********/

  {
    id: "nsfFunding",
    name: "How much do we fund Science?",
    linkTo: "/quizzes/nsfFunding",
    thumbnailSrc: nsfMolecularhThumb,
  },
  {
    id: "goingViral",
    name: "Going Viral",
    linkTo: "/quizzes/goingViral",
    thumbnailSrc: goingViralThumb,
  },
  {
    id: "greatApproximations",
    name: "Math: Great Approximations",
    linkTo: "/quizzes/greatApproximations",
    thumbnailSrc: greatApproximationsThumb,
  },

  /********* Start of New 2.0 quiz content; pinned quizzes are above this line ********/

  {
    name: "Matters of the Heart",
    id: "Sf03leaXWwqeWqlEqXD4",
    linkTo: "/quizzes/heartyNumbers",
    thumbnailSrc: heartyNumbersThumb,
  },
  {
    name: "A Global Warning",
    id: "cv4PEYyjdkx7m14m7DhJ",
    linkTo: "/quizzes/aGlobalWarning",
    thumbnailSrc: aGlobalWarningThumb,
  },
  {
    name: "Urban Energy",
    id: "p07wEO8N2e2InNsAxJCn",
    linkTo: "/quizzes/urbanEnergy",
    thumbnailSrc: urbanEnergyThumb,
  },
  {
    name: "Bogotá: A City in Transit",
    id: "PlWYPEkDRLa0Aej90n6A",
    linkTo: "/quizzes/bogotaACityInTransit",
    thumbnailSrc: bogotaACityInTransitThumb,
  },
  {
    name: "Gold!",
    id: "le2li1KjDGxk89l2mgT5",
    linkTo: "/quizzes/goldQuiz",
    thumbnailSrc: goldQuizThumb,
  },
  {
    name: "What Color Is Fire?",
    id: "wqQBbH5Va3qroQWXkgw0",
    linkTo: "/quizzes/whatColorIsFire",
    thumbnailSrc: whatColorIsFireThumb,
  },
  {
    name: "Autism",
    id: "PRqbatb5NUk9xjr3R2VW",
    linkTo: "/quizzes/autism",
    thumbnailSrc: autismThumb,
  },
  {
    name: "Fighting Malaria",
    id: "3qNCHjznm1FRuWvjPYL2",
    linkTo: "/quizzes/fightingMalaria",
    thumbnailSrc: fightingMalariaThumb,
  },
  {
    name: "Octopus Dreams",
    id: "sXDMT3MVy6Yj8Z6w2Zp7",
    linkTo: "/quizzes/octopusDreams",
    thumbnailSrc: octopusDreamsThumb,
  },
  {
    name: "Weird Animals",
    id: "MIHfuoGGXSPYEuWzHElE",
    linkTo: "/quizzes/weirdAnimals",
    thumbnailSrc: weirdAnimalsThumb,
  },
  {
    name: "Famous Mistranslations",
    id: "hBUK2v8NA1N3bToEsxtH",
    linkTo: "/quizzes/famousMistranslations",
    thumbnailSrc: famousMistranslationsThumb,
  },
  {
    name: "Waste: by the Numbers",
    id: "roAPL2gD9k8y1i6XHSNa",
    linkTo: "/quizzes/wasteByTheNumbers",
    thumbnailSrc: wasteByTheNumbersThumb,
  },

  /********* Legacy quizzes below this line ********/
  {
    name: "Turkey Talk",
    id: "KDCYcMtHAlLWuup9mChy",
    linkTo: "/quizzes/turkeyTalk",
    thumbnailSrc: turkeyTalkThumb,
  },  
  {
    name: "Monstrous Origins",
    id: "83zAtDinCgMCHIMdsHpH",
    linkTo: "/quizzes/monstrousOrigins",
    thumbnailSrc: monstrousOriginsThumb,
  },
  {
    id: "policingAndGeorgeFloyd",
    name: "Policing and George Floyd",
    linkTo: "/quizzes/policingAndGeorgeFloyd",
    thumbnailSrc: policingThumb,
  },
  {
    id: "n95masks",
    name: "Do You Know Your N95 Masks?",
    linkTo: "/quizzes/n95masks",
    thumbnailSrc: n95Thumb,
  },
  {
    id: "currentevents",
    name: "Big Numbers in the News",
    linkTo: "/quizzes/currentEvents",
    thumbnailSrc: bigNumbersInTheNewsThumb,
  },
  {
    id: "everyBreathQuiz",
    name: "Every Breath You Take",
    linkTo: "/quizzes/everyBreathQuiz",
    thumbnailSrc: everyBreathQuizThumb,
  },
  {
    id: "isItScience",
    name: "What is Science?",
    linkTo: "/quizzes/isItScience",
    thumbnailSrc: whatIsScienceThumb,
  },
  {
    id: "fungiQuiz",
    name: "Is there Fungus Among Us?",
    linkTo: "/quizzes/fungiQuiz",
    thumbnailSrc: fungiQuizThumb,
  },
  {
    id: "mythologyTruthQuiz",
    name: "Mythological Truths",
    linkTo: "/quizzes/mythologyTruthQuiz",
    thumbnailSrc: mythologicalTruthsThumb,
  },
  {
    id: "yogaQuiz",
    name: "What is Yoga?",
    linkTo: "/quizzes/yogaQuiz",
    thumbnailSrc: yogaQuizThumb,
  },
  {
    id: "scienceQuiz",
    name: "Science Potpourri",
    linkTo: "/quizzes/scienceQuiz",
    thumbnailSrc: scienceQuizThumb,
  },
  {
    id: "gameOfThronesQuiz",
    name: "G.O.T. Quiz?",
    linkTo: "/quizzes/gameOfThronesQuiz",
    thumbnailSrc: gameOfThronesQuizThumb,
  },
  {
    id: "starWarsQuiz",
    name: "Star Wars Trivia",
    linkTo: "/quizzes/starWarsQuiz",
    thumbnailSrc: starWarsQuizThumb,
  },
  {
    id: "tolkienQuiz",
    name: "Tolkien: One quiz to rule them all",
    linkTo: "/quizzes/tolkienQuiz",
    thumbnailSrc: tolkienQuizThumb,
  },
  {
    id: "movieQuiz",
    name: "Hollywood Movies: Part 1",
    linkTo: "/quizzes/movieQuiz",
    thumbnailSrc: movieQuizThumb,
  },
  {
    id: "movieQuizTwo",
    name: "Hollywood Movies: Part 2",
    linkTo: "/quizzes/movieQuizTwo",
    thumbnailSrc: movieQuizTwoThumb,
  },
  {
    id: "movieQuizThree",
    name: "Hollywood Movies: Part 3",
    linkTo: "/quizzes/movieQuizThree",
    thumbnailSrc: movieQuizThreeThumb,
  },
  {
    id: "disneylandQuiz",
    name: "Disneyland",
    linkTo: "/quizzes/disneylandQuiz",
    thumbnailSrc: disneylandQuizThumb,
  },
  {
    id: "musicTrivia90",
    name: "90's Music Trivia",
    linkTo: "/quizzes/musicTrivia90",
    thumbnailSrc: musicTrivia90Thumb,
  },
  {
    id: "musicTrivia80",
    name: "80's Music Trivia",
    linkTo: "/quizzes/musicTrivia80",
    thumbnailSrc: musicTrivia80Thumb,
  },
  {
    id: "musicQuiz70",
    name: "70's Music Trivia",
    linkTo: "/quizzes/musicQuiz70",
    thumbnailSrc: musicQuiz70Thumb,
  },
  {
    id: "musicTrivia60",
    name: "60's Music Trivia",
    linkTo: "/quizzes/musicTrivia60",
    thumbnailSrc: musicTrivia60Thumb,
  },
  {
    id: "cartoonQuiz",
    name: "Saturday Morning Cartoons",
    linkTo: "/quizzes/cartoonQuiz",
    thumbnailSrc: saturdayMorningCartoonsThumb,
  },
];
