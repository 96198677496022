import React, {createContext, useContext, useReducer,useState} from 'react';

const StoreContext = createContext();
const QuizScoreContext = createContext();

function StoreProvider({reducer, initialState, children}) {
  const [quizScoreInfo, setQuizScoreInfo] = useState({});
  return (
    <StoreContext.Provider value={useReducer(reducer, initialState)}>
      <QuizScoreContext.Provider value={[quizScoreInfo, setQuizScoreInfo]}>
      {children}
      </QuizScoreContext.Provider>
    </StoreContext.Provider>
  );
}

function useStore() {
  return useContext(StoreContext);
}

export { StoreContext, StoreProvider, useStore, QuizScoreContext };