import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Paper } from "@material-ui/core";
import useWindowDimensions from "../../utils/useWindowDimensions";

const useStyles = makeStyles((theme) => ({
  questionBg: ({ windowHeight }) => ({
    background:
      "radial-gradient(circle, #07BBF4 0%, #08B8F5 0.5%, #3124FB 100%)",
    minHeight: windowHeight,
  }),
}));

export default function QuestionContainer(props) {
  const { height } = useWindowDimensions();
  const { children } = props;
  const classes = useStyles({
    windowHeight: height,
  });

  return (
    <Paper elevation={0}>
      <div className={classes.questionBg}>{children}</div>
    </Paper>
  );
}
