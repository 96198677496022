 const plantOrSpread = {
    name: 'To Plant or To Spread',
    id: 'Rm0ORxpW8rexfRF5XZ70',
    linkTo: "/quizzes/plantOrSpread",
    explainerOn: true,
    description: "Planting trees has recently become the byword of climate mitigation. But many ecologists say large plantations of new trees are not the most efficient answer, either economically or ecologically. So what, exactly, are they suggesting instead?",
    questions: [
        {
            //question 1//
            question: "How many trees did a study published in the journal Science claim would need to be planted to mitigate climate change?",
            choices: ['10,000', '1,000,000', '1,000,000,000', '1,000,000,000,000'],
            answerIdx: 3,    
            furtherInfo: {
                explanation: (
                    `The study found that if 1 trillion new trees were to be planted they would be able to mitigate climate change.`
                ),
                sources: [
                    {
                        source:'Alexandre, N. (2020, January 18). Reforestation is not necessarily about planting more trees. Climate | Al Jazeera',
                        url: 'https://www.aljazeera.com/opinions/2020/1/18/reforestation-is-not-necessarily-about-planting-more-trees/',
                    },
                ],
            },
        },
        {
            //question 2//
           question: "How many metric tons of carbon would the 1 trillion new trees store?",
            choices: ['205', '250,000', '205,000,000', '205,000,000,000'],
            answerIdx: 3,    
            furtherInfo: {
                explanation: (
                    `1 trillion trees could potentially store 205 billion metric tons of carbon; that’s 452 trillion pounds and 25% of our current atmospheric carbon.`
                ),
                sources: [
                    {
                        source:'Alexandre, N. (2020, January 18). Reforestation is not necessarily about planting more trees. Climate | Al Jazeera',
                        url: 'https://www.aljazeera.com/opinions/2020/1/18/reforestation-is-not-necessarily-about-planting-more-trees/',
                    },
                ],
            },
        },
        {
            //question 3//
            question: "Approximately how many acres would be needed globally to accommodate 1 trillion trees?",
            choices: ['220,000', '2,200,000', '22,000,000', '2,200,000,000'],
            answerIdx: 3,    
            furtherInfo: {
                explanation: (
                    `2.2 billion acres globally would be needed to accommodate 1 trillion new trees.`
                ),
                sources: [
                    {
                        source:'Alexandre, N. (2020, January 18). Reforestation is not necessarily about planting more trees. Climate | Al Jazeera',
                        url: 'https://www.aljazeera.com/opinions/2020/1/18/reforestation-is-not-necessarily-about-planting-more-trees/',
                    },
                ],
            },
        },
        {
            //question 4//
            question: "What would the average cost be, per acre, to reforest the 2.2 billion acre area in US dollars?",
            choices: ['$15', '$150', '$1,500', '$15,000'],
            answerIdx: 2,    
            furtherInfo: {
                explanation: (
                    `At approximately $1,500 per acre, it would cost $3.3 trillion to reach the target number of trees.`
                ),
                sources: [
                    {
                        source:'Alexandre, N. (2020, January 18). Reforestation is not necessarily about planting more trees. Climate | Al Jazeera',
                        url: 'https://www.aljazeera.com/opinions/2020/1/18/reforestation-is-not-necessarily-about-planting-more-trees/',
                    },
                ],
            },
        },
        {
            //question 5//
            question: "Currently, what is the annual gap between conservation spending and conservation funding needs in US dollars?",
            choices: ['$350,000', '$3,500,000', '$350,000,000', '$350,000,000,000'],
            answerIdx: 3,    
            furtherInfo: {
                explanation: (
                    `The annual gap between current conservation spending and conservation funding needs is $350 billion.`
                ),
                sources: [
                    {
                        source:'Alexandre, N. (2020, January 18). Reforestation is not necessarily about planting more trees. Climate | Al Jazeera',
                        url: 'https://www.aljazeera.com/opinions/2020/1/18/reforestation-is-not-necessarily-about-planting-more-trees/',
                    },
                ],
            },
        },
        {
            //question 6//
            question: "Natural regeneration is what percentage cheaper than planting new saplings?",
            choices: ['20%', '50%', '70%', '100%'],
            answerIdx: 2,    
            furtherInfo: {
                explanation: (
                    `Natural regeneration, a strategy for reforestation that requires minimal human interference simply by letting natural forces do the work, costs 70% less than planting initiatives.`
                ),
                sources: [
                    {
                        source:'Alexandre, N. (2020, January 18). Reforestation is not necessarily about planting more trees. Climate | Al Jazeera',
                        url: 'https://www.aljazeera.com/opinions/2020/1/18/reforestation-is-not-necessarily-about-planting-more-trees/',
                    },
                ],
            },
        },
        {
            //question 7//
            question: "A large driver of natural regeneration is the spread of seeds by natural means; for instance, birds can disperse up to _________ acorns in a month.",
            choices: ['750', '7,500', '75,000', '750,000'],
            answerIdx: 1,    
            furtherInfo: {
                explanation: (
                    `Without any human interference, birds such as jays are capable of dispersing up to 7,500 acorns within a month, all of which have the potential to become new trees.`
                ),
                sources: [
                    {
                        source:'BBC',
                        url: 'https://www.bbc.com/future/article/20210524-the-reason-wild-forests-beat-plantations',
                    },
                ],
            },
        },
        {
            //question 8//
            question: "Areas of natural regeneration can potentially store how many times more carbon than plantations?",
            choices: ['4x', '14x', '40x', '140x'],
            answerIdx: 2,    
            furtherInfo: {
                explanation: (
                    `Areas of natural regeneration have the potential to store 40 times more carbon than tree plantations.`
                ),
                sources: [
                    {
                        source:'BBC',
                        url: 'https://www.bbc.com/future/article/20210524-the-reason-wild-forests-beat-plantations',
                    },
                ],
            },
        },
        {
            //question 9//
            question: "Between 1996 and 2015, how many acres of Brazil’s Atlantic Forest regenerated naturally?",
            choices: ['7,400', '7,400,000', '7,400,000,000', '7,400,000,000,000'],
            answerIdx: 1,    
            furtherInfo: {
                explanation: (
                    `Almost by happenstance, due to thinning rural populations and strict enforcement of anti-deforestation laws, 7.4 million acres of Brazil’s Atlantic Forest regenerated without a single sapling being planted; proving that not only can natural regeneration work, but it can work on a large scale.`
                ),
                sources: [
                    {
                        source:'Alexandre, N. (2020, January 18). Reforestation is not necessarily about planting more trees. Climate | Al Jazeera',
                        url: 'https://www.aljazeera.com/opinions/2020/1/18/reforestation-is-not-necessarily-about-planting-more-trees/',
                    },
                ],
            },
        },
        {
            //question 10//
            question: "How many degraded acres of the Atlantic Forest did the International Institute of Sustainability find could be restored through assisted natural regeneration?",
            choices: ['53,400', '534,000', '5,340,000', '53,400,000'],
            answerIdx: 3,    
            furtherInfo: {
                explanation: (
                    `The International Institute of Sustainability found that 53.4 million acres of the Atlantic Forest could be restored through assisted natural regeneration efforts such as keeping fires from burning young saplings or dispersing seed mixes into degraded areas.`
                ),
                sources: [
                    {
                        source:'Alexandre, N. (2020, January 18). Reforestation is not necessarily about planting more trees. Climate | Al Jazeera',
                        url: 'https://www.aljazeera.com/opinions/2020/1/18/reforestation-is-not-necessarily-about-planting-more-trees/',
                    },
                ],
            },
        },
    ],

};

export default plantOrSpread;