 const marsInNumbers = {
    name: 'Mars in Numbers',
    id: 'Uk8yAy1cCnzHI8eOV2nj',
    linkTo: "/quizzes/marsInNumbers",
    explainerOn: true,
    description: 'Mars is everywhere these days, from new pictures coming in from NASA’s multiple rovers to the successful launch and landing of China’s first Mars rover, Zhurong. But how much do you know about the Red Planet?',
    questions: [
        {
            //question 1//
            question: "What is the average distance from the Sun to Mars in miles?",
            choices: ['142,000', '1,420,000', '14,200,000', '142,000,000'],
            answerIdx: 3,    
            furtherInfo: {
                explanation: (
                    `Mars’ average distance from the sun is 142,000,000 miles, which is 49,000,000 more than Earth’s average distance of 93,000,000 miles from the sun`
                ),
                sources: [
                    {
                        source: 'NASA',
                        url: 'https://mars.nasa.gov/all-about-mars/facts/',
                    },
                ],
            },
        },
        {
            //question 2//
            question: "At what rate of speed, in miles per second, does Mars orbit the Sun?",
            choices: ['1.5', '14.5', '145', '1,450'],
            answerIdx: 1,    
            furtherInfo: {
                explanation: (
                    `Mars orbits the sun at 14.5 miles per second, which scales up to an astonishing 52,200 miles per hour.`
                ),
                sources: [
                    {
                        source: 'NASA',
                        url: 'https://mars.nasa.gov/all-about-mars/facts/',
                    },
                ],
            },
        },
        {
            //question 3//
            question: "What is the tilt of Mars’ axis in degrees?",
            choices: ['5', '25', '45', '65'],
            answerIdx: 1,    
            furtherInfo: {
                explanation: (
                    `Mars’ axis has a tilt of 25 degrees.`
                ),
                sources: [
                    {
                        source: 'NASA',
                        url: 'https://mars.nasa.gov/all-about-mars/facts/',
                    },
                ],
            },
        },
        {
            //question 4//
            question: "What is the length of one year on Mars in Earth days?",
            choices: ['87', '365', '687', '6,870'],
            answerIdx: 2,    
            furtherInfo: {
                explanation: (
                    `One year on Mars lasts 687 Earth days.`
                ),
                sources: [
                    {
                        source: 'NASA',
                        url: 'https://mars.nasa.gov/all-about-mars/facts/',
                    },
                ],
            },
        },
        {
            //question 5//
            question: "What is the average temperature, in degrees Fahrenheit, on Mars?",
            choices: ['32', '0', '-32', '-81'],
            answerIdx: 3,    
            furtherInfo: {
                explanation: (
                    `The average temperature on Mars is -81 degrees Fahrenheit.`
                ),
                sources: [
                    {
                        source: 'NASA',
                        url: 'https://mars.nasa.gov/all-about-mars/facts/',
                    },
                ],
            },
        },
        {
            //question 6//
            question: "What is the diameter of Mars in miles?",
            choices: ['422', '4,220', '42,200', '420,000'],
            answerIdx: 1,    
            furtherInfo: {
                explanation: (
                    `Mars’ diameter is 4,220 miles, just over 3,700 miles smaller than Earth’s.`
                ),
                sources: [
                    {
                        source: 'NASA',
                        url: 'https://mars.nasa.gov/all-about-mars/facts/',
                    },
                ],
            },
        },
        {
            //question 7//
            question: "How many moons orbit Mars?",
            choices: ['2', '20', '200', '2,000'],
            answerIdx: 0,    
            furtherInfo: {
                explanation: (
                    `Mars is orbited by its two moons, Phobos and Deimos.`
                ),
                sources: [
                    {
                        source: 'NASA',
                        url: 'https://mars.nasa.gov/all-about-mars/facts/',
                    },
                ],
            },
        },
        {
            //question 8//
            question: "What is the approximate diameter of Mars’ largest moon, Phobos, in miles?",
            choices: ['14', '140', '1,400', '14,000'],
            answerIdx: 0,    
            furtherInfo: {
                explanation: (
                    `Phobos’ diameter is 14 miles.`
                ),
                sources: [
                    {
                        source: 'NASA',
                        url: 'https://mars.nasa.gov/all-about-mars/facts/',
                    },
                ],
            },
        },
        {
            //question 9//
            question: "What percentage of Mars’ atmosphere is made up of Carbon Dioxide?",
            choices: ['6%', '26%', '76%', '96%'],
            answerIdx: 3,    
            furtherInfo: {
                explanation: (
                    `Mars’ atmosphere is 96% Carbon Dioxide.`
                ),
                sources: [
                    {
                        source: 'NASA',
                        url: 'https://mars.nasa.gov/all-about-mars/facts/',
                    },
                ],
            },
        },
        {
            //question 10//
            question: "Approximately how much less gravity would you experience on Mars than on Earth?",
            choices: ['2.5% less', '22.5% less', '62.5% less', '92.5% less'],
            answerIdx: 2,    
            furtherInfo: {
                explanation: (
                    `You would experience 62.5% less gravity on Mars; 100 lbs on Earth would be 38 lbs on Mars.`
                ),
                sources: [
                    {
                        source: 'NASA',
                        url: 'https://mars.nasa.gov/all-about-mars/facts/',
                    },
                ],
            },
        },
    ],

};

export default marsInNumbers;