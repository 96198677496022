 const studentLoans = {
    name: 'Student Loans',
    id: 'okbFFbrBck6nF91EfDNl',
    linkTo: "/quizzes/studentLoans",
    explainerOn: true,
    description: 'For many students in America it is impossible to get through college without a student loan. But how much is that student loan going to cost you?',
    questions: [
        {
            //question 1//
            question: "Given an annual interest  rate of 6% on a student loan and 365 days in a year, what is the daily interest rate for a federal student loan?",
            choices: ['0.016%', '0.16%', '1.6%', '16'],
            answerIdx: 0,    
            furtherInfo: {
                explanation: (
                    `The daily interest rate on a student loan with an annual rate of 6% is 0.016%.`
                ),
                sources: [
                    {
                        source: 'Investopedia',
                        url: 'https://www.investopedia.com/how-to-calculate-student-loan-interest-4772208',
                    },
                ],
            },
        },
        {
            //question 2//
            question: "Using the same 6% annual interest rate and having a $30,000 principal balance on a student loan, how much interest will your federal student loan accrue each day assuming you never paid the principal ? (Don’t forget about the daily interest rate of 0.016%.)",
            choices: ['$0.04', '$0.49', '$4.93', '$49.30'],
            answerIdx: 2,    
            furtherInfo: {
                explanation: (
                    `At a rate of 6% annual interest on a $30,000 student loan, your federal student loan will accrue $4.93 every day, assuming you never paid the principle.`
                ),
                sources: [
                    {
                        source: 'Investopedia',
                        url: 'https://www.investopedia.com/how-to-calculate-student-loan-interest-4772208',
                    },
                ],
            },
        },
        {
            //question 3//
            question: "Using the same 6% annual interest rate and having a $30,000 principal balance on a federal student loan, how much interest will your student loan accrue over 30 days assuming you never paid the principal?",
            choices: ['$15.28', '$152.83', '$1,528.30', '$15,283'],
            answerIdx: 1,    
            furtherInfo: {
                explanation: (
                    `The same loan at the same rate will accrue $152.83 over 30 days, assuming you never pay the principle.`
                ),
                sources: [
                    {
                        source: 'Investopedia',
                        url: 'https://www.investopedia.com/how-to-calculate-student-loan-interest-4772208',
                    },
                ],
            },
        },
        {
            //question 4//
            question: "If you paid just the interest every month, how long would it take for you to pay off your federal student loan?",
            choices: ['5 years', '15 years', '30 years', 'You will never pay off your student loans because you are only paying the interest off each month'],
            answerIdx: 3,    
            furtherInfo: {
                explanation: (
                    `You will never pay off your student loan by paying the monthly interest because you are never lowering the principle.`
                ),
                sources: [
                    {
                        source: 'Nerd Wallet',
                        url: 'https://www.nerdwallet.com/article/loans/student-loans/student-loans-simple-compound-interest',
                    },
                ],
            },
        },
        {
            //question 5//
            question: "Unsubsidized student loans accrue interest immediately when it is disbursed. Using the same 6% annual interest rate and having a $30,000 principal balance on an unsubsidized federal student loan, how much interest will you accumulate in a typical 4 year college program?",
            choices: ['$71.97', '$719.78', '$7,197.80', '$71,978'],
            answerIdx: 2,    
            furtherInfo: {
                explanation: (
                    `In a typical 4-year college program, a $30,000 student loan at a 6% interest rate will accrue $7,197.80 of interest.`
                ),
                sources: [
                    {
                        source: 'Nerd Wallet',
                        url: 'https://www.nerdwallet.com/article/loans/student-loans/student-loans-simple-compound-interest',
                    },
                ],
            },
        },
        {
            //question 6//
            question: "For unsubsidized federal student loans, any interest accrued after graduation will be added to the principal of your student loan. Using the 4 year accrued interest calculated in the previous section ($7197.80), how much would the principal of your student loan increase to when it started at $30,000?",
            choices: ['$371.97', '$3,719.78', '$37,197.80', '$371,978'],
            answerIdx: 2,    
            furtherInfo: {
                explanation: (
                    `The principal of your student loan will increase to $37,197.80 at the end of your 4 year program.`
                ),
                sources: [
                    {
                        source: 'Nerd Wallet',
                        url: 'https://www.nerdwallet.com/article/loans/student-loans/student-loans-simple-compound-interest',
                    },
                ],
            },
        },
        {
            //question 7//
            question: "How much would the daily interest be for the previously calculated $37,197.80 federal student loan given a 6% annual interest rate and 365 days in a year.",
            choices: ['$6.11', '$61.10', '$611', '$6,110'],
            answerIdx: 0,    
            furtherInfo: {
                explanation: (
                    `At the new principal of $37,197.80 the daily interest on your student loan will increase to $6.11.`
                ),
                sources: [
                    {
                        source: 'Nerd Wallet',
                        url: 'https://www.nerdwallet.com/article/loans/student-loans/student-loans-simple-compound-interest',
                    },
                ],
            },
        },
        {
            //question 8//
            question: "Using the same 6% annual interest rate and having a $37,197.80 principal balance on a federal student loan, how much interest will your student loan accrue over 30 days assuming you never paid the principal?",
            choices: ['$183.30', '$1,833', '$18,330', '$183,300'],
            answerIdx: 0,    
            furtherInfo: {
                explanation: (
                    `Over 30 days your student loan will now accrue $183.30 in interest.`
                ),
                sources: [
                    {
                        source: 'Nerd Wallet',
                        url: 'https://www.nerdwallet.com/article/loans/student-loans/student-loans-simple-compound-interest',
                    },
                ],
            },
        },
        {
            //question 9//
            question: "Using the same 6% annual interest rate and having a $37,197.80 principal balance on a federal student loan, how much interest will your student loan accrue over 1 year (365 days) assuming you never paid the principal?",
            choices: ['$223.01', '$2,230.15', '$22,301.50', '$223,015'],
            answerIdx: 1,    
            furtherInfo: {
                explanation: (
                    `Over 1 year, your student loan will accrue $2,230.15 in interest.`
                ),
                sources: [
                    {
                        source: 'Nerd Wallet',
                        url: 'https://www.nerdwallet.com/article/loans/student-loans/student-loans-simple-compound-interest',
                    },
                ],
            },
        },
        {
            //question 10//
            question: "The 1 year interest accrued for a $30,000 principal is $1,799.45, assuming a 6% annual interest rate and that you never paid down the principal.  What is the difference between this 1 year interest accrued and the 1 year interest rate accrued for a $37,197.80 principal, which was $2,230.15?",
            choices: ['$430.70', '$4,307', '$43,070', '$430,700'],
            answerIdx: 0,    
            furtherInfo: {
                explanation: (
                    `The difference in interest is: $2,230.15 - $1,799.45 = $430.70`
                ),
                sources: [
                    {
                        source: 'Nerd Wallet',
                        url: 'https://www.nerdwallet.com/article/loans/student-loans/student-loans-simple-compound-interest',
                    },
                ],
            },
        },
    ],

};

export default studentLoans;
