import React, { useState, useEffect,useContext } from "react";
import quizList from "../quizList";
import QuizThumbnail from "./QuizThumbnail";
import "../assets/designs/quiziciestIntro.css";
// import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { NavbarData as Navbar } from "./Navbar";
import BottomNav from "./BottomNav";
// import FilterCard from "./FilterCard";
import useWindowDimensions from "../utils/useWindowDimensions";
import { QuizScoreContext } from "../contexts/store";
import useDeviceDetect from "../hooks/useDeviceDetect";
import FeedbackButton from "./FeedbackButton";

const QuizicistIntro = () => {

  const [selectedType, setSelectedType] = useState("Home");
  const [visibleFilter, setVisibleFilter] = useState(false);
  // eslint-disable-next-line 
  const [quizScoreInfo, setQuizScoreInfo] = useContext(QuizScoreContext)
  const { width } = useWindowDimensions();
  const {isMobile} = useDeviceDetect()

  useEffect(() => {
    setQuizScoreInfo({})
  }, 
  []); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <div>
      <FeedbackButton />
      <Navbar filter={true} />
      {width >= 1025 ? (
        <div className="top-selector">
          {/* The following array should contain the following catergories: "Home", "Math", "Health & Sciences", "Energy", "Entertainment" */}
          {[""].map((v) => (
            <span
              key={v}
              className="selector-items"
              onClick={() => setSelectedType(v)}
              style={{
                borderBottom: selectedType === v && "2px solid #085afb",
              }}
            >
              {v}
            </span>
          ))}
        </div>
      ) : (
        ""
      )}
      <div style={{ padding: "0 50px", position: "relative" }}>
        {width >= 1025 ? (
          <span
            className="selector-items"
            style={{ display: "flex" }}
            onClick={() => setVisibleFilter(!visibleFilter)}
          >
            {/* Categories <ArrowDropDownIcon /> */}
          </span>
        ) : (
          ""
        )}
        {/* <FilterCard
          visible={visibleFilter}
          setVisible={setVisibleFilter}
          style={{ left: "85px" }}
          categories={[
            "Home",
            "Math",
            "Health & Sciences",
            "Energy",
            "Entertainment",
          ]}
          options={[
            "Most Popular",
            "Highest Score",
            "Newest",
            "Most Challenging",
          ]}
        /> */}
      </div>
      <div className="intro-grid">
        {quizList.map((quiz) => (
          <QuizThumbnail
            key={quiz.id}
            title={quiz.name}
            thumbnailSrc={quiz.thumbnailSrc}
            linkTo={quiz.linkTo}
            challenge={quiz.challenge}
          />
        ))}
      </div>
      {(width <= 1025 && isMobile) ? <BottomNav /> : ""}
    </div>
  );
};

export default QuizicistIntro;
