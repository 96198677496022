import React, { useState, useEffect, useContext } from "react";
import { addQuizScoreForUser } from "../firestore/user";
import {
  updateTopScoreForQuizForUser,
  updateScoreRank,
} from "../firestore/quiz";
import { playSound } from "../utils/sound.js";
import Box from "@material-ui/core/Box";
import { Link, useHistory } from "react-router-dom";
import { sendFinishQuiz, sendShareQuiz } from "../utils/google_analytics";
import { getRankFromScore } from "../firestore/quiz";
import ShareIcon from "../assets/icons/Quizicist Icons/Share Icon-01.svg";
import ReplayIcon from "../assets/icons/Quizicist Icons/Replay-01.svg";
import RibbonIcon from "../assets/icons/Quizicist Icons/Ribbon-01.svg";
import "../assets/designs/outro.css";
import Navbar from "./Navbar";
import BottomNav from "./BottomNav";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import useWindowDimensions from "../utils/useWindowDimensions";
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  RedditIcon,
  RedditShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  LineIcon,
  LineShareButton,
} from "react-share";
import { QuizScoreContext } from "../contexts/store";
import OutroAuthPopup from "./OutroAuthPopup";
import useMergedUser from "../hooks/useMergedUser";
import useDeviceDetect from "../hooks/useDeviceDetect";
import FeedbackButton from "./FeedbackButton";

const Rank = ({ rank, challenge, user, setOpenLoginModal, history }) => {
  if (challenge) {
    return (
      <>
        <span className="score-box-head">Your Rank</span>
        <Link to={"/my-scores"} style={{ textDecoration: "none" }}>
          <span className="score-box-number scored">{challenge && rank}</span>
        </Link>
      </>
    );
  }

  return (
    <img
      className="outro-rank-ribbon"
      onClick={() => {
        if (!(user && user.authUser && user.authUser.uid)) {
          setOpenLoginModal(true);
        } else {
          history.push("/my-scores");
        }
      }}
      src={RibbonIcon}
      alt="ribbon"
    />
  );
};

const OutroData = (props) => {
  const user = useMergedUser();
  const history = useHistory();
  const [rank, setRank] = useState("-");
  // eslint-disable-next-line
  const [quizScoreInfo, setQuizScoreInfo] = useContext(QuizScoreContext);
  const {
    score,
    linkTo,
    firestore,
    quizId,
    challenge,
    name,
    ...outroProps
  } = quizScoreInfo;

  React.useEffect(() => {
    if (Object.keys(quizScoreInfo).length <= 0) {
      const h = history.location.pathname.replace("/outro", "")
      history.push(h)
    }
  }, [quizScoreInfo]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(
    () => {
      if (Object.keys(quizScoreInfo).length > 0) {
        const details = {
          ...quizScoreInfo,
          user: user,
        };
        setQuizScoreInfo(details);
      }
    },
    // eslint-disable-next-line
    [user]
  );

  useEffect(
    function sendScoreToFirestore() {
      if (!(user && user.authUser && user.authUser.uid)) {
        return;
      }
      // we check if user has already been to outro page so we dont save the save results again and agian
      if (quizScoreInfo.saveQuizScore) {
        const { uid } = user.authUser;
        addQuizScoreForUser(firestore, uid, {
          score,
          linkTo,
          quizId,
          quizName: name,
        })
          .then(() => {
            const { username: userUsername } = user.firestoreUser;
            const { uid: userUid, photoURL: userPhotoURL } = user.authUser;

            return updateTopScoreForQuizForUser(firestore, quizId, {
              userUsername,
              userUid,
              userPhotoURL,
              score,
            });
          })
          .then((updateResults) => {
            if (
              challenge &&
              updateResults.oldTopScore !== updateResults.topScore
            ) {
              updateScoreRank(
                firestore,
                quizId,
                score,
                updateResults.oldTopScore
              );
            }
            // once the score is saved we mark the quiz info not be save again
            setQuizScoreInfo((prevState) => ({
              ...prevState,
              saveQuizScore: false,
            }));
          })
          .catch((e) => {
            console.error("Could not send score to server");
          });
      }
    },
    // eslint-disable-next-line
    [firestore, user, challenge, name, quizId, score, linkTo]
  );

  useEffect(function getRankAfterPlayingQuiz() {
    if (!challenge) {
      return;
    }

    const getRank = async () => {
      setRank(await getRankFromScore(firestore, quizId, score));
    };
    getRank();
  }, [score, firestore, quizId, challenge]);

  return (
    <Outro
      user={user}
      score={score}
      linkTo={linkTo}
      rank={rank}
      quizId={quizId}
      username={user && user.firestoreUser && user.firestoreUser.username}
      photoURL={user && user.authUser && user.authUser.photoURL}
      name={name}
      challenge={challenge}
      quizScoreInfo={quizScoreInfo}
      setQuizScoreInfo={setQuizScoreInfo}
      {...outroProps}
    />
  );
};

function Outro(props) {
  const {
    user,
    score,
    linkTo,
    playEndSound,
    name,
    challenge,
    rank,
    username,
    photoURL,
    soundOn,
    quizScoreInfo,
    setQuizScoreInfo,
  } = props;
  const [sharePopupVisible, setSharePopupVisible] = useState(false);
  const [openLoginModal, setOpenLoginModal] = useState(false);
  const history = useHistory();
  const { width } = useWindowDimensions();
  const {isMobile} = useDeviceDetect();

  const shareUrl = `https://quizicist.com${linkTo}`;
  const title = `I scored ${score} points on "${name}" on Quizicist.\nCan you do better?`;


  // save quiz information
  useEffect(function registerQuizCompletion() {
    if(quizScoreInfo && Object.keys(quizScoreInfo).length > 0 &&  !quizScoreInfo.isVisited){
      sendFinishQuiz(name);
    }
  }, [quizScoreInfo, name]);

  React.useEffect(() => {
    if (
      quizScoreInfo &&
      Object.keys(quizScoreInfo).length > 0 &&
      quizScoreInfo.playOutroMusic
    ) {
      playSound(soundOn, playEndSound);
      setQuizScoreInfo((prevState) => ({
        ...prevState,
        playOutroMusic: false,
      }));
    }
  }, [playEndSound, soundOn, quizScoreInfo, setQuizScoreInfo]);

  const handleShareClick = () => {
    sendShareQuiz(name);
  };

  return (
    <div className="outro-page">
      {/* auth modal popup */}
      <OutroAuthPopup
        visible={openLoginModal}
        setVisible={setOpenLoginModal}
        text={
          <>
            Save your score! <br />
            Please log in or create an account.
          </>
        }
      />
      {/* share dialog  starts*/}
      <Dialog
        onClose={() => setSharePopupVisible(false)}
        aria-labelledby="simple-dialog-title"
        open={sharePopupVisible}
      >
        <DialogTitle id="simple-dialog-title">Share your score</DialogTitle>
        <div
          style={{
            height: "200px",
            display: "grid",
            gridGap: "10px",
            padding: "20px",
            width: width < 350 ? "250px" : "300px",
            gridTemplateColumns: "repeat(auto-fit, minmax(45px, 1fr))",
          }}
        >
          <TwitterShareButton url={shareUrl} title={title} onClick={handleShareClick}>
            <TwitterIcon size={46} round={true} />
          </TwitterShareButton>
          <FacebookShareButton url={shareUrl} title={title} onClick={handleShareClick}>
            <FacebookIcon size={46} round={true} />
          </FacebookShareButton>
          <LinkedinShareButton url={shareUrl} title={title} onClick={handleShareClick}>
            <LinkedinIcon size={46} round={true} />
          </LinkedinShareButton>
          <RedditShareButton url={shareUrl} title={title} onClick={handleShareClick}>
            <RedditIcon size={46} round={true} />
          </RedditShareButton>
          <LineShareButton url={shareUrl} title={title} onClick={handleShareClick}>
            <LineIcon size={46} round={true} />
          </LineShareButton>
          <WhatsappShareButton url={shareUrl} title={title} onClick={handleShareClick}>
            <WhatsappIcon size={46} round={true} />
          </WhatsappShareButton>
        </div>
      </Dialog>

      {/* share dialog ends */}
      <Navbar filter={false} username={username} photoURL={photoURL} />
      <FeedbackButton />
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <h1 className="outro-page-heading">{name}</h1>
        {!challenge && (
          <Box my={2}>
            {/* <Link to="/review"> */}
              <button className="outro-review-button" onClick={() => history.push("/review")}>
                Review Missed Questions
              </button>
            {/* </Link> */}
          </Box>
        )}
        <div className="score-rank-column">
          <div className="score-box">
            <button
              onClick={() => {
                if (!(user && user.authUser && user.authUser.uid)) {
                  setOpenLoginModal(true);
                } else {
                  history.push("/my-scores");
                }
              }}
              style={{
                backgroundColor: "transparent",
                border: "none",
                textAlign: "center",
                textDecoration: "none",
                display: "flex",
                flexDirection: "column",
                cursor: "pointer",
              }}
            >
              <span className="score-box-head">Score</span>
              <span className="score-box-number scored">
                {score && score.toLocaleString()}
              </span>
            </button>
            <button
              className="score-box-lastDiv"
              onClick={() => setSharePopupVisible(true)}
              style={{ cursor: "pointer" }}
            >
              <img src={ShareIcon} alt="" className="score-box-lastDiv-icon" />
              <span className="score-box-lastDiv-text">Share your score</span>
            </button>
          </div>
          <div className="rank-box">
            <Rank
              challenge={challenge}
              rank={rank}
              user={user}
              setOpenLoginModal={setOpenLoginModal}
              history={history}
            />
            <button
              className="score-box-lastDiv"
              onClick={props.onPlayAgainClick}
            >
              <img
                src={ReplayIcon}
                alt=""
                className="score-box-lastDiv-icon"
                style={{ cursor: "pointer" }}
              />
              <span
                className="score-box-lastDiv-text"
                style={{ cursor: "pointer" }}
              >
                Replay
              </span>
            </button>
          </div>
        </div>
        {/* TODO: uncomment AchievementUnlocked, below, when we're ready to roll out Achievements feature */}
        {/* <AchievementUnlocked className="achievement-hidden" achievementTitle="Played 1st Quiz" /> */}
      </Box>
      {(width <= 1024 && isMobile) ? <BottomNav /> : ""}
    </div>
  );
}
// TODO Percentile ain't ready for primetime
// <span className="score-box-head" style={{ fontSize: "16px" }}>
//   {( /* TODO: Calculate universal baseline Scores, per Quiz; compare user's score against this baseline */}th Percentile
// </span>

export { OutroData };
export default Outro;
