 const movieQuizTwo = {
    id: "movieQuizTwo",
    name: "Hollywood Movies: Part 2",
    linkTo: "/quizzes/movieQuizTwo",
    description: 'Back for the Sequel. Get ready for your close-up!',
    questions: [
        {
            question: "Which 2007 film has the hitman character Anton Chigurh?",
            choices: ['Hell Or High Water', 'No Country for Old Men', 'The Road'],
            answerIdx: 1,    
            furtherInfo: {
            },
        },
        {
            question: "Which 1992 film introduced the characters by colors; Mr. Brown, Mr. White. Mr. Blonde, Mr. Blue, Mr. Orange, and Mr. Pink?",
            choices: ['Pulp Fiction', 'True Romance', 'Reservoir Dogs'],
            answerIdx: 2,    
            furtherInfo: {
            },
        },
        {
            question: "What 1941 film contained a sled named “Rosebud”?",
            choices: ['The Masltese Falcon', 'Citizen Kane', 'On The Waterfront'],
            answerIdx: 1,    
            furtherInfo: {
            },
        },
        {
            question: '“2001: A Space Odyssey” was directed and produced by?',
            choices: ['Stanley Kubrick', 'Francis Ford Coppola', 'Martin Scorsese'],
            answerIdx: 0,    
            furtherInfo: {
            },
        },
        {
            question: "Oliver Stone directed what 1986 film about the Vietnam war?",
            choices: ['MASH', 'Apocalypse Now', 'Platoon'],
            answerIdx: 2,    
            furtherInfo: {
            },
        },
        {
            question: "The 1973 George Lucas film “American Graffiti” takes place in what city?",
            choices: ['Sacramento, California', 'Modesto, California', 'Marin, California'],
            answerIdx: 1,    
            furtherInfo: {
            },
        },
        {
            question: "Who directed the cult classic animated movie “The Secret Of NIMH”?",
            choices: ['Don Bluth', 'Steven Spielberg', 'Tim Burton'],
            answerIdx: 0,    
            furtherInfo: {
            },
        },
        {
            question: `Fill in the blank: “I love the smell of _________ .”`,
            choices: ['Money in the night.', 'Incense and peppermints.', 'Napalm in the morning.'],
            answerIdx: 2,    
            furtherInfo: {
            },
        },
        {
            question: `Fill in the blank: “I ate his liver with some _________ .”`,
            choices: ['Candy, and java beans.', 'Lava, and Candy', 'Fava beans and a nice Chianti'],
            answerIdx: 2,    
            furtherInfo: {
            },
        },
        {
            question: `Fill in the blank: “I feel the need. _________ .”`,
            choices: ['The need for speed.', 'The need for a nice haircut.', 'The need for a squirrel.'],
            answerIdx: 0,    
            furtherInfo: {
            },
        },
    ],

};

export default movieQuizTwo;
