 const movieQuiz = {
    id: 'movieQuiz',
    name: 'Hollywood Movies: Part 1',
    linkTo: "/quizzes/movieQuiz",
    description: 'Are you ready to contend for an Oscar?!',
    questions: [
        {
            question: "When was the first Academy Awards for movies?",
            choices: ['1940', '1929', '1914'],
            answerIdx: 1,    
            furtherInfo: {
            },
        },
        {
            question: "Which film received the best picture in 1935?",
            choices: ['Mutiny On the Bounty', "A Midsummer Night's Dream", 'Les Miserables'],
            answerIdx: 0,    
            furtherInfo: {
            },
        },
        {
            question: "Which film starring Jack Nicholson won best picture in 1975?",
            choices: ['Chinatown', "One Flew Over the Cuckoo's Nest", 'Easy Rider'],
            answerIdx: 1,    
            furtherInfo: {
            },
        },
        {
            question: "Which actor from Jurassic Park directed the film Ghandi?",
            choices: ['Sam Neil', 'Richard Attenborough', 'Steven Spielberg'],
            answerIdx: 1,    
            furtherInfo: {
            },
        },
        {
            question: `What 1997 film recorded the song "My Heart Will Go On"?`,
            choices: ['The Full Monty', 'As Good as It Gets', 'Titanic'],
            answerIdx: 2,    
            furtherInfo: {
            },
        },
        {
            question: `Fill in the blank of this 2004 film: "Finding _________ ."`,
            choices: ['Capote', 'Neverland', 'The Departed'],
            answerIdx: 1,    
            furtherInfo: {
            },
        },
        {
            question: `Fill in the blank of this 1998 film: "Life is _________ ."`,
            choices: ['In the Bedroom', 'Lost in Translation', 'Beautiful'],
            answerIdx: 2,    
            furtherInfo: {
            },
        },
        {
            question: `Fill in the blank of this 2007 film: " No Country for _________ ."`,
            choices: ['Old Men', 'Inglourious Basterds', 'Captain Phillips'],
            answerIdx: 0,    
            furtherInfo: {
            },
        },
        {
            question: `Fill in the blank of the 2014 film: "Birdman or _________ ."`,
            choices: ['The Unexpected Virtue of Ignorance', 'Batman', 'Call Me by Your Name'],
            answerIdx: 0,    
            furtherInfo: {
            },
        },
    ],

};

export default movieQuiz;
