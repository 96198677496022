const lifeOnTheInternet = {
    name: 'Life on the Internet in 2020',
    id: 'qfayEbEfC3wFCTWDuFbS',
    linkTo: "lifeOnTheInternet",
    explainerOn: true,
    subheading: `For many of us, 2020 was the year we lived our lives online; whether it was 2 am shopping binges or endless hours of Netflix, the Internet was there for us.`,
    description: `The year 2020 was unprecedented in many ways. When lockdown mandates kept us indoors we turned to one social space left to us: The Internet. We held meetings, went to school, celebrated birthdays and family holidays. We shopped and ordered food, and sang sea shanties. What did all that time spent on the Internet in 2020 look like in numbers?`,
    questions: [
        {
            //question 1//
            question: `We may have been in lockdown but we still had to eat. How many orders were made on Doordash each minute in 2020?`,
            choices: ['555', '1,555', '3,555', '5,555'],
            answerIdx: 0,
            furtherInfo: {
                explanation: (
                    `Food delivery services like Doordash were not invented during the 2020 pandemic, but they definitely came into the spotlight. In 2020, 555 meals on average were ordered on Doordash every minute.`
                ),
                sources: [
                    {
                        source: 'Visual Capitalist',
                        url: 'https://www.visualcapitalist.com/every-minute-internet-2020/?utm_source=Visual+Capitalist+Infographics+%28All%29&utm_campaign=8aeccf2e4e-EMAIL_COPY_01&utm_medium=email&utm_term=0_31b4d09e8a-8aeccf2e4e-45131207',
                    },
                ],
            },
        },
        {
            //question 2//
            question: `There wasn't a lot to do in 2020, so approximately how many combined hours of Netflix did users stream every minute?`,
            choices: ['4,040', '40,400', '404,000', '4,040,000'],
            answerIdx: 2,
            furtherInfo: {
                explanation: (
                    `Whether it was <i>Queen's Gambit</i> or <i>Tiger King</i>, we all watched <i>a lot</i> of Netflix; a combined average of 404,000 hours of content each minute in fact.`
                ),
                sources: [
                    {
                        source: 'Visual Capitalist',
                        url: 'https://www.visualcapitalist.com/every-minute-internet-2020/?utm_source=Visual+Capitalist+Infographics+%28All%29&utm_campaign=8aeccf2e4e-EMAIL_COPY_01&utm_medium=email&utm_term=0_31b4d09e8a-8aeccf2e4e-45131207',
                    },
                ],
            },
        },
        {
            //question 3//
            question: `Speaking of content, in 2020 users uploaded how many combined hours of content on Youtube each minute?`,
            choices: ['25', '50', '250', '500'],
            answerIdx: 3,
            furtherInfo: {
                explanation: (
                    `Where would we have been in 2020 without those faithful online content creators? Youtubers alone uploaded a combined 500 hours of content each minute of 2020.`
                ),
                sources: [
                    {
                        source: 'Visual Capitalist',
                        url: 'https://www.visualcapitalist.com/every-minute-internet-2020/?utm_source=Visual+Capitalist+Infographics+%28All%29&utm_campaign=8aeccf2e4e-EMAIL_COPY_01&utm_medium=email&utm_term=0_31b4d09e8a-8aeccf2e4e-45131207',
                    },
                ],
            },
        },
        {
            //question 4//
            question: `Zoom has become a household name by hosting how many meeting participants each minute?`,
            choices: ['20,800', '208,000', '2,800,000', '20,800,000'],
            answerIdx: 1,
            furtherInfo: {
                explanation: (
                    `Whether for business, school, or just a casual get together, we all became familiar with Zoom in 2020. A relative newcomer to online meeting space, in 2020 Zoom hosted about 208,000 participants in meetings each minute.`
                ),
                sources: [
                    {
                        source: 'Visual Capitalist',
                        url: 'https://www.visualcapitalist.com/every-minute-internet-2020/?utm_source=Visual+Capitalist+Infographics+%28All%29&utm_campaign=8aeccf2e4e-EMAIL_COPY_01&utm_medium=email&utm_term=0_31b4d09e8a-8aeccf2e4e-45131207',
                    },
                ],
            },
        },
        {
            //question 5//
            question: `Known for convenience and fast shipping, on average Amazon shipped how many packages per minute in 2020?`,
            choices: ['17', '67', '670', '6,700'],
            answerIdx: 3,
            furtherInfo: {
                explanation: (
                    `Offering everything from dish soap to tiaras, Amazon did a brisk business in 2020 and shipped an average of 6,700 packages every minute.`
                ),
                sources: [
                    {
                        source: 'Visual Capitalist',
                        url: 'https://www.visualcapitalist.com/every-minute-internet-2020/?utm_source=Visual+Capitalist+Infographics+%28All%29&utm_campaign=8aeccf2e4e-EMAIL_COPY_01&utm_medium=email&utm_term=0_31b4d09e8a-8aeccf2e4e-45131207',
                    },
                ],
            },
        },
        {
            //question 6//
            question: `It seemed like every week there was some new TikTok craze, leading to the mobile app being installed how many times per minute in 2020?`,
            choices: ['350', '3,000', '35,000', '300,000'],
            answerIdx: 1,
            furtherInfo: {
                explanation: (
                    `In 2020 TikTok was installed approximately 3,000 times every minute.`
                ),
                sources: [
                    {
                        source: 'Visual Capitalist',
                        url: 'https://www.visualcapitalist.com/every-minute-internet-2020/?utm_source=Visual+Capitalist+Infographics+%28All%29&utm_campaign=8aeccf2e4e-EMAIL_COPY_01&utm_medium=email&utm_term=0_31b4d09e8a-8aeccf2e4e-45131207',
                    },
                ],
            },
        },
        {
            //question 7//
            question: `Despite the inability of many to go out and socialize, Instagram users still posted an average of how many stories per minute in 2020?`,
            choices: ['350', '3,500', '35,000', '350,000'],
            answerIdx: 3,
            furtherInfo: {
                explanation: (
                    `You may have thought being stuck inside in the midst of a global pandemic meant there wasn't much to post about, but that didn't stop Instagram users from posting approximately 350,000 stories per minute.`
                ),
                sources: [
                    {
                        source: 'Visual Capitalist',
                        url: 'https://www.visualcapitalist.com/every-minute-internet-2020/?utm_source=Visual+Capitalist+Infographics+%28All%29&utm_campaign=8aeccf2e4e-EMAIL_COPY_01&utm_medium=email&utm_term=0_31b4d09e8a-8aeccf2e4e-45131207',
                    },
                ],
            },
        },
        {
            //question 8//
            question: `Digital wallets are becoming more and more commonplace and in 2020 Venmo users sent an average of how many dollars worth of payments per minute?`,
            choices: ['$2,400', '$24,000', '$240,000', '$2,400,000'],
            answerIdx: 2,
            furtherInfo: {
                explanation: (
                    `In 2020, Venmo saw approximately $240,000 dollars worth of cash transfers every minute.`
                ),
                sources: [
                    {
                        source: 'Visual Capitalist',
                        url: 'https://www.visualcapitalist.com/every-minute-internet-2020/?utm_source=Visual+Capitalist+Infographics+%28All%29&utm_campaign=8aeccf2e4e-EMAIL_COPY_01&utm_medium=email&utm_term=0_31b4d09e8a-8aeccf2e4e-45131207',
                    },
                ],
            },
        },
        {
            //question 9//
            question: `In 2020 alone Twitter gained approximately 170 million new users, which translates to how many new users each minute?`,
            choices: ['40', '80', '160', '320'],
            answerIdx: 3,
            furtherInfo: {
                explanation: (
                    `With wildfires, pandemics, deaths, protests, and elections, there was plenty to tweet about in 2020, which led to Twitter gaining about 320 new users every minute.`
                ),
                sources: [
                    {
                        source: 'Visual Capitalist',
                        url: 'https://www.visualcapitalist.com/every-minute-internet-2020/?utm_source=Visual+Capitalist+Infographics+%28All%29&utm_campaign=8aeccf2e4e-EMAIL_COPY_01&utm_medium=email&utm_term=0_31b4d09e8a-8aeccf2e4e-45131207',
                    },
                ],
            },
        },
        {
            //question 10//
            question: `2020 was many things, but it was not least the year of online shopping. On average, how many US dollars were spent each minute online in 2020?`,
            choices: ['$10,000', '$100,000', '$1,000,000', '$10,000,000'],
            answerIdx: 2,
            furtherInfo: {
                explanation: (
                    `Between birthdays and holidays, not to mention just being stuck inside and realizing how boring your blank walls are or how uncomfortable your furniture is, online shopping reached new heights in 2020. Every minute consumers spent 1 million USD online. That's $60 million in a single hour and $1.4 billion in a single day.`
                ),
                sources: [
                    {
                        source: 'Visual Capitalist',
                        url: 'https://www.visualcapitalist.com/every-minute-internet-2020/?utm_source=Visual+Capitalist+Infographics+%28All%29&utm_campaign=8aeccf2e4e-EMAIL_COPY_01&utm_medium=email&utm_term=0_31b4d09e8a-8aeccf2e4e-45131207',
                    },
                ],
            },
        },
    ],
};

export default lifeOnTheInternet;
