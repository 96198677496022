const currentEvents = {
    id: "currentevents",
    name: 'Big Numbers in the News',
    linkTo: "/quizzes/currentEvents",
    explainerOn: true,
    description: 'Test your understanding of some big numbers in our world today!',
    questions: [
        {
            title: "CO2 in oceans",
            question: "1.5 Trillion (1.5E12) metric tons of CO2 have been released into the atmosphere, 30% of which has been absorbed by our oceans. How many metric tons of CO2 are now within our oceans?",
            choices: ['450 Million', '4.5 Billion', '45 Billion', '450 Billion'],
            answerIdx: 3,
            furtherInfo: {
                explanation: (
`## Explanation

Find 30% of 1.5 Trillion by multiplying 1.5 Trillion by 3/10.

<b>Step 1</b>   
Write out the problem in scientific notation.   
<b>1.5E12 x 3E-1</b>

<b>Step 2</b>   
Multiply the numbers, and multiply the exponents using addition.    
(1.5 x 3)(E12 x E-1) = (4.5 x E11) = <b>4.5E11</b>

<b>Answer</b>    
<b>4.5E11</b>, or 450 billion metric tons.`
                ),
                sources: [ 
                    {
                        source: 'The Sixth Extinction, by Elizabeth Kolbert',
                        url: 'https://www.alternet.org/2015/05/book-excerpt-elizabeth-kolberts-sixth-extinction/'
                    }
                ]
            }
        },
		{
			question: "If California, with population of 40 million (4E7), wants to build a high speed train for $100 Billion (1E11), what's the cost per resident?",
            choices: ['$25', '$250', '$2,500', '$25,000'],
            answerIdx: 2,
            furtherInfo: {
                explanation: (
`## Explanation
Divide $100 billion by 40 million residents.

<b>Step 1</b>    
Write the problem in scientific notation.   
<b>$1E11 / 4E7</b>

<b>Step 2</b>     
Divide the numbers, and divide the exponents using subtraction. Then multiply the results.    
(1/4)(E11 / E7) = (1/4 x E4) = <b>0.25E4</b>

<b>Step 3</b>    
Use 1 E1 to move the decimal point one place to the right.       
0.25E4 = <b>2.5E3</b>

<b>Answer</b>    
<b>2.5E3</b>, or $2,500 per resident.`
                ),
                sources: [ 
                    {
                        source: 'The New York Times',
                        url: 'https://www.nytimes.com/2018/07/30/us/california-high-speed-rail.html'
                    }
                ]
            }
        },
        {
            question: "If the F-35 Fighter Jet Program costs U.S. taxpayers $1 Trillion (1E12) over time, with approx 140 Million (1.4E8) US taxpayers, how much is each taxpayer paying for the F-35s?",
            choices: ['$70', '$700', '$7,000', '$70,000'],
            answerIdx: 2,
            furtherInfo: {
                explanation: (
`## Explanation
$1 trillion divided by 140 million U.S. taxpayers.

<b>Step 1</b>   
Write the problem in scientific notation.   
<b>$1E12 / 1.4E8</b>

<b>Step 2</b>   
Divide the numbers, and divide the exponents using subtraction. Then multiply the results.    
(1 / 1.4)(E12 / E8)  =  (1 / 1.4)(E4)  =  ( (100 / 14) / 10 )(E4) = (7/10)(E4) = <b>0.7E4</b>

<b>Step 3</b>     
Use 1 E1 to move the decimal point one place to the right.    
0.7E4 = <b>7E3</b>

<b>Answer</b>    
<b>7E3</b>, or $7,000 per U.S. taxpayer.`
                ),
                sources: [ 
                    {
                        source: 'The New York Times',
                        url: 'https://www.nytimes.com/2019/08/21/magazine/f35-joint-strike-fighter-program.html'
                    }
                ]
            }
        },
        {
            question: "The Viaduct de Milau bridge in France cost $400 Million Dollars ($4E8) to build, while the Bay Bridge between Oakland and San Francisco cost $6.5 Billion ($6.5E9). How many times more expensive was the Bay Bridge?",
            choices: ['1.6X', '16X', '160X', '1600X'],
            answerIdx: 1,
            furtherInfo: {
                explanation: (
`## Explanation

Divide $6.5 billion by $400 million.

<b>Step 1</b>   
Write the problem in scientific notation.   
<b>$6.5E9 / $4E8</b>

<b>Step 2</b>   
Divide the numbers, and divide the exponents using subtraction. Then multiply the results.    
(6.5 / 4)(E9 / E8)  =  (1.6 x E1) = <b>1.6E1</b>

<b>Answer</b>   
<b>1.6E1</b>, or 16X more expensive.`
                ),
                // sources: [{}]
            }            
        },
        {
            question: "How much would it cost each Californian (population: 40 Million) to build a $57,000 apartment for each of the state's 130 thousand homeless?",
            choices: ['$18.50', '$185', '$1,850', '$18,500'],
            answerIdx: 1,
            furtherInfo: {
                explanation: (
`## Explanation

Multiply $57,000 by 130,000 homeless, then divide the result by 40 million residents.

<b>Step 1</b>   
Write the problem in scientific notation.     
<b>($5.7E4 x 1.3E5)/ 4E7</b>

<b>Step 2</b>   
Multiply the numbers, and multiply the exponents using addition.     
(5.7 x 1.3)(E4 x E5)/ 4E7  =  (7.4 x E9) / 4E7 = <b>(7.4E9 / 4E7)</b>   

<b>Step 3</b>   
Divide the numbers, and divide the exponents using subtraction. Then multiply the results.    
(7.4 / 4)(E9 / E7)  =  (1.85 x E2) = <b>1.85E2</b>

<b>Answer</b>    
<b>1.85E2</b>, or $185 per resident.`
                ),
                sources: [ 
                    {
                        source: 'U.S. Interagency Council on Homelessness',
                        url: 'https://www.usich.gov/homelessness-statistics/ca/'
                    }
                ]
            }
        }

	],
};

export default currentEvents;
