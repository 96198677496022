 const blm2020ProtestNumbers = {
    name: 'BLM 2020 Protest Numbers',
    id: 'mml0vZ7cwAFYPNUy3MeD',
    linkTo: "/quizzes/blm2020ProtestNumbers",
    explainerOn: true,
    description: 'On May 25, 2020, George Floyd, a Black man already subdued and unarmed, was murdered at the hands of a white Minneapolis police officer, Derek Chauvin. His death once again sparked accusations of systemic racism inherent in police departments across the United States and led to what may be the largest protest movement in United States history.',
    questions: [
        {
            question: "How many places in the United States held protests on June 6th, 2020, after the death of George Floyd?",
            choices: ['150', '550', '1,500', '15,000'],
            answerIdx: 1,    
            furtherInfo: {
                explanation: (
                    `There were 550 distinct protest sites in the United States on June 6th, 2020.`
                ),
                sources: [
                    {
                        source: 'Buchanan, L., Bui, Q., & Patel, J. K. (2020, July 3). Black Lives Matter May Be the Largest Movement in U.S. History. The New York Times.',
                        url: 'https://www.nytimes.com/interactive/2020/07/03/us/george-floyd-protests-crowd-size.html',
                    },
                ],
            },
        },
        {
            question: "Approximately how many showed up to protest in Philadelphia?",
            choices: ['500', '5,000', '10,000', '50,000'],
            answerIdx: 3,    
            furtherInfo: {
                explanation: (
                    `Approximately 50,000 protesters marched through Philadelphia on June 6th.`
                ),
                sources: [
                    {
                        source: 'Buchanan, L., Bui, Q., & Patel, J. K. (2020, July 3). Black Lives Matter May Be the Largest Movement in U.S. History. The New York Times.',
                        url: 'https://www.nytimes.com/interactive/2020/07/03/us/george-floyd-protests-crowd-size.html',
                    },
                ],
            },
        },
        {
            question: "Approximately how many protesters showed up on June 6th, 2020 at Chicago’s Union Park?",
            choices: ['200', '2,000', '20,000', '200,000'],
            answerIdx: 2,    
            furtherInfo: {
                explanation: (
                    `Approximately 20,000 protesters showed up at Union Park in Chicago.`
                ),
                sources: [
                    {
                        source: 'Buchanan, L., Bui, Q., & Patel, J. K. (2020, July 3). Black Lives Matter May Be the Largest Movement in U.S. History. The New York Times.',
                        url: 'https://www.nytimes.com/interactive/2020/07/03/us/george-floyd-protests-crowd-size.html',
                    },
                ],
            },
        },
        {
            question: "On June 6th, 2020, approximately how many protesters showed up on San Francisco’s iconic Golden Gate Bridge?",
            choices: ['500', '1,000', '10,000', '100,000'],
            answerIdx: 2,    
            furtherInfo: {
                explanation: (
                    `The Golden Gate Bridge hosted approximately 10,000 protesters.`
                ),
                sources: [
                    {
                        source: 'Buchanan, L., Bui, Q., & Patel, J. K. (2020, July 3). Black Lives Matter May Be the Largest Movement in U.S. History. The New York Times.',
                        url: 'https://www.nytimes.com/interactive/2020/07/03/us/george-floyd-protests-crowd-size.html',
                    },
                ],
            },
        },
        {
            question: "As many as _________ protesters took part in the initial Black Lives Matter protests in the weeks after George Floyd’s death.",
            choices: ['500,000', '1,000,000', '10,000,000', '26,000,000'],
            answerIdx: 3,    
            furtherInfo: {
                explanation: (
                    `In the weeks after George Floyd’s death, up to 26,000,000 people took part in protests across the United States.`
                ),
                sources: [
                    {
                        source: 'Buchanan, L., Bui, Q., & Patel, J. K. (2020, July 3). Black Lives Matter May Be the Largest Movement in U.S. History. The New York Times.',
                        url: 'https://www.nytimes.com/interactive/2020/07/03/us/george-floyd-protests-crowd-size.html',
                    },
                ],
            },
        },
        {
            question: "In contrast, how many protesters took part in the 2017 Women’s March?",
            choices: ['100,000', '500,000', '1,000,000', '5,000,000'],
            answerIdx: 3,    
            furtherInfo: {
                explanation: (
                    `In 2017 5,000,000 protesters took part in the Women’s March.`
                ),
                sources: [
                    {
                        source: 'Buchanan, L., Bui, Q., & Patel, J. K. (2020, July 3). Black Lives Matter May Be the Largest Movement in U.S. History. The New York Times.',
                        url: 'https://www.nytimes.com/interactive/2020/07/03/us/george-floyd-protests-crowd-size.html',
                    },
                ],
            },
        },
        {
            question: "What percentage of the 3,142 counties in the United States had protests?",
            choices: ['5%', '20%', '40%', '75%'],
            answerIdx: 2,    
            furtherInfo: {
                explanation: (
                    `Approximately 40% of United States counties, at least 1,360 out of 3,142 total counties, held Black Lives Matter Protests.`
                ),
                sources: [
                    {
                        source: 'WorldAtlas',
                        url: 'https://www.worldatlas.com/articles/how-many-counties-are-in-the-united-states.html',
                    },
                ],
            },
        },
        {
            question: "What percentage of the counties that had protests also had a majority white population?",
            choices: ['25%', '50%', '75%', '95%'],
            answerIdx: 3,    
            furtherInfo: {
                explanation: (
                    `1,292 counties, 95% of the total counties that had Black Lives Matter Protests, also had a majority white population.`
                ),
                sources: [
                    {
                        source: 'Buchanan, L., Bui, Q., & Patel, J. K. (2020, July 3). Black Lives Matter May Be the Largest Movement in U.S. History. The New York Times.',
                        url: 'https://www.nytimes.com/interactive/2020/07/03/us/george-floyd-protests-crowd-size.html',
                    },
                ],
            },
        },
        {
            question: "The income group which had the largest share of protesters was those earning in excess of _________ .",
            choices: ['$20,000', '$50,000', '$100,000', '$150,000'],
            answerIdx: 3,    
            furtherInfo: {
                explanation: (
                    `Most people who took part in these protests were in the $150,000 annual income range.`
                ),
                sources: [
                    {
                        source: 'Buchanan, L., Bui, Q., & Patel, J. K. (2020, July 3). Black Lives Matter May Be the Largest Movement in U.S. History. The New York Times.',
                        url: 'https://www.nytimes.com/interactive/2020/07/03/us/george-floyd-protests-crowd-size.html',
                    },
                ],
            },
        },
        {
            question: "Protests to unseat government leaders or secure independence typically succeed when they engage what percentage of the population?",
            choices: ['3.5%', '30%', '50%', '90%'],
            answerIdx: 0,    
            furtherInfo: {
                explanation: (
                    `Historically, protests to unseat the current government or achieve independence typically succeed when they engage 3.5% of the population.`
                ),
                sources: [
                    {
                        source: 'Buchanan, L., Bui, Q., & Patel, J. K. (2020, July 3). Black Lives Matter May Be the Largest Movement in U.S. History. The New York Times.',
                        url: 'https://www.nytimes.com/interactive/2020/07/03/us/george-floyd-protests-crowd-size.html',
                    },
                ],
            },
        },
    ],

};

export default blm2020ProtestNumbers;