import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Grid, Typography } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const useStyles = makeStyles((theme) => ({
  navButton: {
    color: '#fff',
  },
  boxContents: {
    paddingTop: theme.spacing(2),
  },
  figureText: {
    textAlign: "center",
  },
  figureItem: {
    marginBottom: 10,
  },
  figureBox: {
    color: '#fff',

  }
}));

export default function Figures(props) {
  const { figures, appearance } = props;

  const classes = useStyles(appearance);

  const theme = useTheme();
  const cols = useMediaQuery(theme.breakpoints.up("md")) ? 3 : 1;

  const items = [];
  for (let i = 0; i < figures.length; i += cols) {
    const itemFigures = figures.slice(i, i + cols);
    items.push(itemFigures);
  }

  return (
    <>
      <div className={classes.figureBox}>
        <div className={classes.boxContents}>
          <Grid container direction="row" justify="center" alignItems="center">
            {figures.map((figure, n) => {
              return (
                <Grid item xs={12} sm={4} md={3} key={n}>
                  <div className={classes.figureItem}>
                    <Typography variant="h6" className={classes.figureText}>
                      <FontAwesomeIcon icon={figure.icon} /> {figure.name}
                    </Typography>
                    <Typography variant="h5" className={classes.figureText}>
                      {figure.value}
                    </Typography>
                  </div>
                </Grid>
              );
            })}
          </Grid>
        </div>
      </div>
    </>
  );
}
