const n95Masks = {
    id: "n95masks",
    name: 'Do You Know Your N95 Masks?',
    linkTo: "/quizzes/n95masks",
    explainerOn: true,
    description: 'How fine is your knowledge filter for N95 masks?',
    questions: [
        { // Q-1
            question: "What is the meaning of \"95\" in N95 masks?",
            choices: [
                'Made for use in temperatures 95\u00B0 F (35\u00B0 C) or cooler', 
                'Suggested retail price of USD $0.95', 
                'Non permeable to air containing less than 95% nitrogen', 
                '95% efficiency at filtering particles'
            ],
            answerIdx: 3,
            furtherInfo: {
                explanation: (
`## Explanation

The <b>95</b> in "N95" means the mask has a <b>95% efficiency at filtering particles</b> 0.3 microns in size or larger.`
                ),

                sources: [ 
                    {
                        source: 'CDC',
                        url: 'https://www.cdc.gov/niosh/npptl/topics/respirators/disp_part/n95list1.html'
                    },                    
                    {
                        source: 'Honeywell',
                        url: 'https://www.honeywell.com/en-us/newsroom/news/2020/03/n95-masks-explained'
                    },
                ]
            }
        },
        { // Q-2
            question: "What is an N95 mask made out of?",
            choices: [
                'Coffee filter',
                'Steel wool',
                'Polypropylene fiber',
                'Fiberglass',
                'Hemp fiber'],
            answerIdx: 2,
            furtherInfo: {
                explanation: (
`## Explanation

Answer: <b>Polypropylene Fiber</b>.

The key material used in N95 masks is non-woven polypropylene fiber produced through a process called melt blowing.
`
                ),
                sources: [ 
                    {
                        source: 'Honeywell',
                        url: 'https://www.honeywell.com/en-us/newsroom/news/2020/03/n95-masks-explained'
                    },
                    {
                        source: 'NPR',
                        url: 'https://www.npr.org/sections/goatsandsoda/2020/03/16/814929294/covid-19-has-caused-a-shortage-of-face-masks-but-theyre-surprisingly-hard-to-mak'
                    }

                ]
            }
        },
        { // Q-3
            question: "What types of N95s are there?",
            choices: ['Civilian grade', 'Industrial grade', 'Surgical grade', 'Industrial and Surgical grade', 'All the Above'],
            answerIdx: 3,
            furtherInfo: {
                explanation: (
`## Explanation

Answer: <b>Industrial and Surgical grade</b>.
  
<b>Industrial N95</b> masks are designed to reduce exposure to hazardous airborne particles. 

<b>Surgical N95</b> masks must also meet FDA requirements of fluid resistance, as healthcare professionals can be exposed to infectious fluids.`
                ),
                sources: [ 
                    {
                        source: '3M',
                        url: 'https://multimedia.3m.com/mws/media/1794572O/surgical-n95-vs-standard-n95-which-to-consider.pdf'
                    },
                    {
                        source: 'FDA',
                        url: 'https://www.fda.gov/medical-devices/personal-protective-equipment-infection-control/n95-respirators-and-surgical-masks-face-masks'
                    }
                ]
            } 
        },
        { // Q-4
            question: "How many N95 masks were manufactured per month in the US before COVID-19?",
            choices: ['1 million', '5 million', '10 million', '50 million'],
            answerIdx: 3,
            furtherInfo: {
                explanation: (
`## Explanation

About <b>50 million</b> N95 masks have been made in the US each month by 3M and half a dozen smaller manufacturers. 

Before COVID-19, most of these N95s were made for industrial (instead of medical) use.`
                ),
                sources: [ 
                    {
                        source: 'Wall Street Journal',
                        url: 'https://www.wsj.com/articles/3m-ceo-on-n95-masks-demand-exceeds-our-production-capacity-11585842928'
                    },
                ]
            }            
        },
        { // Q-5
            question: "How many N95 respirators are needed each month by US healthcare workers treating COVID-19?",
            choices: ['3 million', '30 million', '300 million', '3 billion'],
            answerIdx: 2,
            furtherInfo: {
                explanation: (
`## Explanation

<b>300 million</b> (nearly one for each person in the US).

The US Department of Health and Human Services (HHS) estimates 300 million surgical grade N95 masks are needed each month to adequately protect healthcare workers from COVID-19.`
                ),
                sources: [ 
                    {
                        source: 'Wall Street Journal',
                        url: 'https://www.wsj.com/articles/3m-ceo-on-n95-masks-demand-exceeds-our-production-capacity-11585842928'
                    },
                ]
            } 
        },
        { // Q-6
            question: "How much did an N95 mask cost prior to COVID-19?",
            choices: [ '$0.50', '$1.00', '$2.00', '$5.00', '$10'],
            answerIdx: 1,
            furtherInfo: {
                explanation: (
`## Explanation

<b>$1.00</b>. Prior to COVID-19, N95 respirators were sold to hospitals for an average price of $1 per mask. 

Due to shortages and demand during the pandemic, distributors have been selling them for as high as <b>$7.50</b> each. 

The cost of manufacturing is estimated to be <b>$0.58</b> per mask.
`
                ),
                sources: [ 
                    {
                        source: 'Bloomberg',
                        url: 'https://www.bloomberg.com/news/articles/2020-03-24/for-new-york-58-cent-medical-masks-now-priced-at-7-50-each'
                    },
                    {
                        source: 'Journal of the American Medical Association (JAMA)',
                        url: 'https://jamanetwork.com/journals/jama/fullarticle/2749214'
                    },
                    {
                        source: 'Science Daily',
                        url: 'https://www.sciencedaily.com/releases/2019/09/190903134732.htm'
                    }
                ]
            } 
        },
        { // Q-7
            question: "How many sizes do N95s come in?",
            choices: ['One (1 size fits all)', 'Two (Small or Large)', 'Three (Small, Medium, or Large)', 'No sizing standard'],
            answerIdx: 3,
            furtherInfo: {
                explanation: (
`## Explanation

<b>No single standard for sizing</b>.

N95 mask sizes are not standardized across models.

Depending on the model, sizing can range from a single universal size to multiple options such as small, small/medium, medium, medium/large, large, etc. 

Because of this variability, it’s important that users be “fit tested” to identify the N95 model, style and size that provides the most effective fit. 
`
                ),
                sources: [ 
                    {
                        source: 'CDC',
                        url: 'https://www.cdc.gov/niosh/npptl/topics/respirators/disp_part/respsource3healthcare.html'
                    },
                ]
            } 
        },        { // Q-8
            question: "How do N95 masks protect from viruses like SARS-CoV-2 (which causes COVID-19)?",
            choices: ['A. They work like a sieve to filter out particles larger than 0.3 microns.', 'B. They capture particles as they bump into the microscopic fibers of the filter.',  'C. Both'],
            answerIdx: 2,
            furtherInfo: {
                explanation: (
`## Explanation

<b>C. Both</b>.

N95 masks protect wearers by stopping larger droplets from coughs, and by capturing smaller droplet particles onto the filter fibers. 

The novel coronavirus is about 0.1 microns, much smaller than the spacing between fibers; however, the static charge on the fiber attracts (and captures) viral particles onto it.
`
                ),
                sources: [ 
                    {
                        source: 'New York Times',
                        url: 'https://www.nytimes.com/2020/03/31/well/live/coronavirus-N95-mask-DIY-face-mask-health.html'
                    },
                    {
                        source: 'Risk Bites (on youtube)',
                        url: 'https://www.youtube.com/watch?v=CpbJFk311Lw'
                    },                    
                ]
            } 
        },
        { // Q-9
            question: "Can an N95 mask be disinfected and used more than once?",
            choices: ['Yes', 'No'],
            answerIdx: 0,
            furtherInfo: {
                explanation: (
`## Explanation

<b>Yes</b>. Although manufacturers recommend that N95s be used only once, the CDC and FDA have issued guidelines for their decontamination and reuse. 

This is due to the shortage of N95s and other protective equipment used by medical workers.

`
                ),
                sources: [
                    {
                        source: 'CDC',
                        url: 'https://www.cdc.gov/niosh/topics/hcwcontrols/recommendedguidanceextuse.html'
                    },
                    {
                        source: 'FDA',
                        url: 'https://www.fda.gov/media/136449/download'
                    },
                    {
                        source: 'N95DECON',
                        url: 'https://www.n95decon.org'
                    },
                ]
            } 
        },
        { // Q-10
            question: "Which of the following means can be used to disinfect an N95 mask for reuse?",
            choices: ['heat, alcohol, UV light, bleach', 'heat, UV light, hydrogen peroxide', 'alcohol, bleach, hydrogen peroxide', 'all the above'],
            answerIdx: 1,
            furtherInfo: {
                explanation: (
`## Explanation

<b>Answer: heat, UV light, hydrogen peroxide</b>.

Experts have determined that <b>heat</b> or <b>UV light</b> (specifically: Ultraviolet C wavelength), when used properly, can treat N95s for reuse. 

For hospitals, the FDA has approved emergency decontamination methods using vaporous <b>hydrogen peroxide</b>.

By contrast, <b>alcohol and bleach are not recommended</b> because they destroy the static charge on the mask fiber which it needs to be effective.

`
                ),
                sources: [ 
                    {
                        source: 'CDC',
                        url: 'https://www.cdc.gov/niosh/topics/hcwcontrols/recommendedguidanceextuse.html'
                    },
                    {
                        source: 'Battelle',
                        url: 'https://www.battelle.org/newsroom/news-details/battelle-deploys-decontamination-system-for-reusing-n95-masks'
                    },
                    {
                        source: 'NIH',
                        url: 'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC7153525/'
                    },
                    {
                        source: 'DIYPPE',
                        url: 'diyppe.org'
                    },
                    {
                        source: 'N95DECON',
                        url: 'https://www.n95decon.org'
                    }                                                                                
                ]
            } 
        },
    ],
};

export default n95Masks;
