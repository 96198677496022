const needForCaffeine = {
  name: 'The Need for Caffeine',
  id: 'GnRuEzvTbLZfjSLN0Zqq',
  linkTo: "/quizzes/needForCaffeine",
  explainerOn: true,
  subheading: `You've hit the 3 o'clock slump and are looking for a jolt of caffeine to perk you up. But what will you choose; coffee, tea, or a soft drink?`,
  description: `The world's most popular psychoactive substance, caffeine, plays a part in many of our lives. But how we take that caffeine varies, especially by region. When it comes to caffeine, can you tell which of these countries prefer coffee, tea, or soft drinks?`,
  questions: [
      {
          //question 1//
          question: `Which caffeine drinks are preferred in <b>The United States</b>?`,
          choices: ['Coffee', 'Tea', 'Soft Drinks'],
          answerIdx: 2,    
          furtherInfo: {
              explanation: (
                  `The United States prefers soft drinks.`
              ),
              sources: [
                  {
                      source: 'Visual Capitalist',
                      url: 'https://www.visualcapitalist.com/coffee-vs-tea-vs-soft-drinks-what-caffeine-drinks-do-countries-prefer/?utm_source=Visual+Capitalist+Infographics+%28All%29&utm_campaign=21d51753f2-EMAIL&utm_medium=email&utm_term=0_31b4d09e8a-21d51753f2-45131207',
                  },
              ],
        },
      },
      {
          //question 2//
          question: `Which caffeine drinks are preferred in <b>The United Kingdom</b>?`,
          choices: ['Coffee', 'Tea', 'Soft Drinks'],
          answerIdx: 1,    
          furtherInfo: {
              explanation: (
                  `The United Kingdom prefers tea.`
              ),
              sources: [
                  {
                      source: 'Visual Capitalist',
                      url: 'https://www.visualcapitalist.com/coffee-vs-tea-vs-soft-drinks-what-caffeine-drinks-do-countries-prefer/?utm_source=Visual+Capitalist+Infographics+%28All%29&utm_campaign=21d51753f2-EMAIL&utm_medium=email&utm_term=0_31b4d09e8a-21d51753f2-45131207',
                  },
              ],
        },
      },
      {
          //question 3//
          question: `Which caffeine drinks are preferred in <b>China</b>?`,
          choices: ['Coffee', 'Tea', 'Soft Drinks'],
          answerIdx: 1,    
          furtherInfo: {
              explanation: (
                  `China prefers tea.`
              ),
              sources: [
                  {
                      source: 'Visual Capitalist',
                      url: 'https://www.visualcapitalist.com/coffee-vs-tea-vs-soft-drinks-what-caffeine-drinks-do-countries-prefer/?utm_source=Visual+Capitalist+Infographics+%28All%29&utm_campaign=21d51753f2-EMAIL&utm_medium=email&utm_term=0_31b4d09e8a-21d51753f2-45131207',
                  },
              ],
        },
      },
      {
          //question 4//
          question: `Which caffeine drinks are preferred in <b>Mexico</b>?`,
          choices: ['Coffee', 'Tea', 'Soft Drinks'],
          answerIdx: 2,    
          furtherInfo: {
              explanation: (
                  `Mexico prefers soft drinks.`
              ),
              sources: [
                  {
                      source: 'Visual Capitalist',
                      url: 'https://www.visualcapitalist.com/coffee-vs-tea-vs-soft-drinks-what-caffeine-drinks-do-countries-prefer/?utm_source=Visual+Capitalist+Infographics+%28All%29&utm_campaign=21d51753f2-EMAIL&utm_medium=email&utm_term=0_31b4d09e8a-21d51753f2-45131207',
                  },
              ],
        },
      },
      {
          //question 5//
          question: `Which caffeine drinks are preferred in <b>Kenya</b>?`,
          choices: ['Coffee', 'Tea', 'Soft Drinks'],
          answerIdx: 1,    
          furtherInfo: {
              explanation: (
                  `Kenya prefers tea.`
              ),
              sources: [
                  {
                      source: 'Visual Capitalist',
                      url: 'https://www.visualcapitalist.com/coffee-vs-tea-vs-soft-drinks-what-caffeine-drinks-do-countries-prefer/?utm_source=Visual+Capitalist+Infographics+%28All%29&utm_campaign=21d51753f2-EMAIL&utm_medium=email&utm_term=0_31b4d09e8a-21d51753f2-45131207',
                  },
              ],
        },
      },
      {
          //question 6//
          question: `Which caffeine drinks are preferred in <b>Sweden</b>?`,
          choices: ['Coffee', 'Tea', 'Soft Drinks'],
          answerIdx: 0,    
          furtherInfo: {
              explanation: (
                  `Sweden prefers coffee.`
              ),
              sources: [
                  {
                      source: 'Visual Capitalist',
                      url: 'https://www.visualcapitalist.com/coffee-vs-tea-vs-soft-drinks-what-caffeine-drinks-do-countries-prefer/?utm_source=Visual+Capitalist+Infographics+%28All%29&utm_campaign=21d51753f2-EMAIL&utm_medium=email&utm_term=0_31b4d09e8a-21d51753f2-45131207',
                  },
              ],
        },
      },
      {
          //question 7//
          question: `Which caffeine drinks are preferred in <b>South Korea</b>?`,
          choices: ['Coffee', 'Tea', 'Soft Drinks'],
          answerIdx: 0,    
          furtherInfo: {
              explanation: (
                  `South Korea prefers coffee.`
              ),
              sources: [
                  {
                      source: 'Visual Capitalist',
                      url: 'https://www.visualcapitalist.com/coffee-vs-tea-vs-soft-drinks-what-caffeine-drinks-do-countries-prefer/?utm_source=Visual+Capitalist+Infographics+%28All%29&utm_campaign=21d51753f2-EMAIL&utm_medium=email&utm_term=0_31b4d09e8a-21d51753f2-45131207',
                  },
              ],
        },
      },
      {
          //question 8//
          question: `Which caffeine drinks are preferred in <b>Turkey</b>?`,
          choices: ['Coffee', 'Tea', 'Soft Drinks'],
          answerIdx: 1,    
          furtherInfo: {
              explanation: (
                  `Turkey prefers tea.`
              ),
              sources: [
                  {
                      source: 'Visual Capitalist',
                      url: 'https://www.visualcapitalist.com/coffee-vs-tea-vs-soft-drinks-what-caffeine-drinks-do-countries-prefer/?utm_source=Visual+Capitalist+Infographics+%28All%29&utm_campaign=21d51753f2-EMAIL&utm_medium=email&utm_term=0_31b4d09e8a-21d51753f2-45131207',
                  },
              ],
        },
      },
      {
          //question 9//
          question: `Which caffeine drinks are preferred in <b>Italy</b>?`,
          choices: ['Coffee', 'Tea', 'Soft Drinks'],
          answerIdx: 2,    
          furtherInfo: {
              explanation: (
                  `Italy prefers soft drinks.`
              ),
              sources: [
                  {
                      source: 'Visual Capitalist',
                      url: 'https://www.visualcapitalist.com/coffee-vs-tea-vs-soft-drinks-what-caffeine-drinks-do-countries-prefer/?utm_source=Visual+Capitalist+Infographics+%28All%29&utm_campaign=21d51753f2-EMAIL&utm_medium=email&utm_term=0_31b4d09e8a-21d51753f2-45131207',
                  },
              ],
        },
      },
      {
          //question 10//
          question: `Which caffeine drinks are preferred in <b>Canada</b>?`,
          choices: ['Coffee', 'Tea', 'Soft Drinks'],
          answerIdx: 0,
          furtherInfo: {
              explanation: (
                  `Canada prefers coffee.`
              ),
              sources: [
                  {
                      source: 'Visual Capitalist',
                      url: 'https://www.visualcapitalist.com/coffee-vs-tea-vs-soft-drinks-what-caffeine-drinks-do-countries-prefer/?utm_source=Visual+Capitalist+Infographics+%28All%29&utm_campaign=21d51753f2-EMAIL&utm_medium=email&utm_term=0_31b4d09e8a-21d51753f2-45131207',
                  },
              ],
          },
      },
  ],
};

export default needForCaffeine;
