const coffeeProduction = {
  name: 'Coffee Production',
  id: 'wotux2iFLb9LDk2jdtvN',
  linkTo: "/quizzes/coffeeProduction",
  explainerOn: true,
  subheading: `A morning jolt of caffeine, an afternoon pick me up, iced or hot or blended; <i>a lot</i> of us are drinking coffee. But where is all that coffee coming from?`,
  description: `In many parts of the world coffee is the drink of choice, whether as part of a morning routine, a jolt of caffeine to wake you up, or as a leisurely cup savored in a cafe with friends. For many of us, our day to day just wouldn't be the same without it. But do you know where the coffee you enjoy comes from?`,
  questions: [
      {
          //question 1//
          question: `In which country did the Arabica coffee plant originate?`,
          choices: ['Brazil', 'Ethiopia', 'Indonesia', 'Arabia'],
          answerIdx: 1,    
          furtherInfo: {
              explanation: (
                  `The Arabica coffee plant, which produces the type of coffee most widely sold around the world, originated in Ethiopia. If you enjoy your morning jolt of caffeine by way of coffee, you are most likely drinking coffee from Arabica coffee beans.`
              ),
              sources: [
                  {
                      source: 'Visual Capitalist',
                      url: 'https://www.visualcapitalist.com/worlds-top-coffee-producing-countries/?utm_source=Visual+Capitalist+Infographics+%28All%29&utm_campaign=ed61bc9fb0-Mailchimp_Oct_3_2021_SD_COPY_01&utm_medium=email&utm_term=0_31b4d09e8a-ed61bc9fb0-45131207',
                  },
              ],
        },
      },
      {
          //question 2//
          question: `Approximately how many cups of coffee are consumed each year, worldwide?`,
          choices: ['500,000', '50,000,000', '500,000,000', '500,000,000,000'],
          answerIdx: 3,    
          furtherInfo: {
              explanation: (
                  `Approximately 500 billion cups of coffee are consumed each year around the world.`
              ),
              sources: [
                  {
                      source: 'Visual Capitalist',
                      url: 'https://www.visualcapitalist.com/worlds-top-coffee-producing-countries/?utm_source=Visual+Capitalist+Infographics+%28All%29&utm_campaign=ed61bc9fb0-Mailchimp_Oct_3_2021_SD_COPY_01&utm_medium=email&utm_term=0_31b4d09e8a-ed61bc9fb0-45131207',
                  },
              ],
        },
      },
      {
          //question 3//
          question: `How many 60 kilogram (kg) bags of coffee were produced worldwide in 2020?`,
          choices: ['170,000', '1,700,000', '170,000,000', '17,000,000,000'],
          answerIdx: 2,    
          furtherInfo: {
              explanation: (
                  `In 2020 alone, 170 million 60 kg bags of coffee were produced. That's just over 130 lbs per bag, and over 5 billion pounds of coffee produced overall.`
              ),
              sources: [
                  {
                      source: 'Visual Capitalist',
                      url: 'https://www.visualcapitalist.com/worlds-top-coffee-producing-countries/?utm_source=Visual+Capitalist+Infographics+%28All%29&utm_campaign=ed61bc9fb0-Mailchimp_Oct_3_2021_SD_COPY_01&utm_medium=email&utm_term=0_31b4d09e8a-ed61bc9fb0-45131207',
                  },
              ],
        },
      },
      {
          //question 4//
          question: `What is the top coffee-producing country?`,
          choices: ['Brazil', 'Ethiopia', 'Vietnam', 'Columbia'],
          answerIdx: 0,    
          furtherInfo: {
              explanation: (
                  `Coffee, as we know it, may have originated in Ethiopia. But today, Brazil single-handedly produces 40% of the world's coffee.`
              ),
              sources: [
                  {
                      source: 'Visual Capitalist',
                      url: 'https://www.visualcapitalist.com/worlds-top-coffee-producing-countries/?utm_source=Visual+Capitalist+Infographics+%28All%29&utm_campaign=ed61bc9fb0-Mailchimp_Oct_3_2021_SD_COPY_01&utm_medium=email&utm_term=0_31b4d09e8a-ed61bc9fb0-45131207',
                  },
              ],
        },
      },
      {
          //question 5//
          question: `How many 60 kg bags of coffee does Brazil produce in a year?`,
          choices: ['63,400', '634,000', '63,400,000', '634,000,000'],
          answerIdx: 2,    
          furtherInfo: {
              explanation: (
                  `Brazil produces an astounding 63.4 million bags of coffee a year.`
              ),
              sources: [
                  {
                      source: 'Visual Capitalist',
                      url: 'https://www.visualcapitalist.com/worlds-top-coffee-producing-countries/?utm_source=Visual+Capitalist+Infographics+%28All%29&utm_campaign=ed61bc9fb0-Mailchimp_Oct_3_2021_SD_COPY_01&utm_medium=email&utm_term=0_31b4d09e8a-ed61bc9fb0-45131207',
                  },
              ],
        },
      },
      {
          //question 6//
          question: `Approximately how much land is dedicated to coffee bushes in Brazil, to make it the world's top producer of coffee?`,
          choices: ['2,700 sq km', '27,000 sq km', '2,700,000 sq km', '270,000,000 sq km'],
          answerIdx: 1,    
          furtherInfo: {
              explanation: (
                  `There are about 27,000 square kilometers of coffee plantations in Brazil alone.`
              ),
              sources: [
                  {
                      source: 'Visual Capitalist',
                      url: 'https://www.visualcapitalist.com/worlds-top-coffee-producing-countries/?utm_source=Visual+Capitalist+Infographics+%28All%29&utm_campaign=ed61bc9fb0-Mailchimp_Oct_3_2021_SD_COPY_01&utm_medium=email&utm_term=0_31b4d09e8a-ed61bc9fb0-45131207',
                  },
              ],
        },
      },
      {
          //question 7//
          question: `The top 5 coffee-producing countries produce what percent of the world's coffee?`,
          choices: ['90%', '75%', '50%', '25%'],
          answerIdx: 1,    
          furtherInfo: {
              explanation: (
                  `The top 5 coffee-producing countries produce 75% of the world's coffee. Brazil is responsible for 40%; the other 35% is grown in Vietnam, Columbia, Indonesia, and Ethiopia.`
              ),
              sources: [
                  {
                      source: 'Visual Capitalist',
                      url: 'https://www.visualcapitalist.com/worlds-top-coffee-producing-countries/?utm_source=Visual+Capitalist+Infographics+%28All%29&utm_campaign=ed61bc9fb0-Mailchimp_Oct_3_2021_SD_COPY_01&utm_medium=email&utm_term=0_31b4d09e8a-ed61bc9fb0-45131207',
                  },
              ],
        },
      },
      {
          //question 8//
          question: `How much is 75% of the world's annual 170 million bags of coffee?`,
          choices: ['12,750 bags', '750,000 bags', '2,270,000 bags', '127,500,000 bags'],
          answerIdx: 3,    
          furtherInfo: {
              explanation: (
                  `75% of the world's annual 170 million bags of coffee production works out to be 127.5 million bags of coffee. This is the amount produced by the top 5 coffee-producing countries.`
              ),
              sources: [
                  {
                      source: 'Visual Capitalist',
                      url: 'https://www.visualcapitalist.com/worlds-top-coffee-producing-countries/?utm_source=Visual+Capitalist+Infographics+%28All%29&utm_campaign=ed61bc9fb0-Mailchimp_Oct_3_2021_SD_COPY_01&utm_medium=email&utm_term=0_31b4d09e8a-ed61bc9fb0-45131207',
                  },
              ],
        },
      },
      {
          //question 9//
          question: `All of the top coffee-producing countries are located where?`,
          choices: ['The Bean Belt', 'South America', 'Africa', 'Malaysia'],
          answerIdx: 0,    
          furtherInfo: {
              explanation: (
                  `The top coffee-producing countries are all located in what is known as The Bean Belt, which is located between the Tropic of Cancer and the Tropic of Capricorn.`
              ),
              sources: [
                  {
                      source: 'Visual Capitalist',
                      url: 'https://www.visualcapitalist.com/worlds-top-coffee-producing-countries/?utm_source=Visual+Capitalist+Infographics+%28All%29&utm_campaign=ed61bc9fb0-Mailchimp_Oct_3_2021_SD_COPY_01&utm_medium=email&utm_term=0_31b4d09e8a-ed61bc9fb0-45131207',
                  },
              ],
        },
      },
      {
          //question 10//
          question: `One of the rarest, and most expensive, types of coffee, <i>Kopi Luwak</i>, comes from what country?`,
          choices: ['Canada', 'Brazil', 'Indonesia', 'Ethiopia'],
          answerIdx: 2,    
          furtherInfo: {
              explanation: (
                  `The rarest types of coffee come from Indonesia. This includes <i>Kopi Luwak</i>, the infamous coffee bean that has been eaten and defecated by the Asian palm civet and can cost anywhere from $35 to $100 a cup.`
              ),
              sources: [
                  {
                      source: 'Visual Capitalist',
                      url: 'https://www.visualcapitalist.com/worlds-top-coffee-producing-countries/?utm_source=Visual+Capitalist+Infographics+%28All%29&utm_campaign=ed61bc9fb0-Mailchimp_Oct_3_2021_SD_COPY_01&utm_medium=email&utm_term=0_31b4d09e8a-ed61bc9fb0-45131207',
                  },
              ],
          },
      },
  ],
};

export default coffeeProduction;
