 const nonViolentProtests = {
    name: 'The Numbers of Non-Violent Protest',
    id: 'HvmgHKirn2Uz0aoIlIpy',
    linkTo: "/quizzes/nonViolentProtests",
    explainerOn: true,
    description: 'Erica Chenoweth, a political scientist at Harvard University, has made a career of studying civil resistance. She studied hundreds of both violent and non-violent protest campaigns and the results may surprise you.',
    questions: [
        {
            //question 1//
            question: "How many protest campaigns, both violent and non-violent, did Chenoweth study?",
            choices: ['3', '32', '323', '3,230'],
            answerIdx: 2,    
            furtherInfo: {
                explanation: (
                    `Chenoweth studied 323 protest campaigns that took place between the years 1900 and 2006.`
                ),
                sources: [
                    {
                        source: "BBC. (n.d.). The '3.5% rule': How a small minority can change the world. BBC Future.",
                        url: 'https://www.bbc.com/future/article/20190513-it-only-takes-35-of-people-to-change-the-world',
                    },
                ],
            },
        },
        {
            //question 2//
            question: "Compared to the 26% success rate of violent protests, what percentage of non-violent protests were successful?",
            choices: ['5%', '13%', '53%', '93%'],
            answerIdx: 2,    
            furtherInfo: {
                explanation: (
                    ``
                ),
                sources: [
                    {
                        source: "BBC. (n.d.). The '3.5% rule': How a small minority can change the world. BBC Future.",
                        url: 'https://www.bbc.com/future/article/20190513-it-only-takes-35-of-people-to-change-the-world',
                    },
                ],
            },
        },
        {
            //question 3//
            question: "On average, non-violent protests attract 200,000 participants which is how many times more than the average 50,000 participants for violent protests?",
            choices: ['2x', '4x', '8x', '16x'],
            answerIdx: 1,    
            furtherInfo: {
                explanation: (
                    `At an average of 200,000 protesters, non-violent protests tend to attract 4 times as many participants than violent protests, which, counterintuitively, gives them a greater ability to disrupt the normal functioning of society.`
                ),
                sources: [
                    {
                        source: "BBC. (n.d.). The '3.5% rule': How a small minority can change the world. BBC Future.",
                        url: 'https://www.bbc.com/future/article/20190513-it-only-takes-35-of-people-to-change-the-world',
                    },
                ],
            },
        },
        {
            //question 4// 
            question: "At its peak, how many people took part in the People Power Movement in 1986 which successfully removed the Marcos Regime from the Philippines?",
            choices: ['20,000', '200,000', '2,000,000', '20,000,000'],
            answerIdx: 2,    
            furtherInfo: {
                explanation: (
                    `2 million Filipinos turned out in 1986 to protest the Marcos Regime, which folded on the fourth day.`
                ),
                sources: [
                    {
                        source: "BBC. (n.d.). The '3.5% rule': How a small minority can change the world. BBC Future.",
                        url: 'https://www.bbc.com/future/article/20190513-it-only-takes-35-of-people-to-change-the-world',
                    },
                ],
            },
        },
        {
            //question 5//
            question: "How many people took part in the successful Singing Revolution of the late 1980s to reestablish Estonian independence?",
            choices: ['7,000', '70,000', '700,000', '7,000,000'],
            answerIdx: 2,    
            furtherInfo: {
                explanation: (
                    `At its peak, the Singing Revolution had 700,000 Estonians taking part to protest Soviet occupation.`
                ),
                sources: [
                    {
                        source: 'International Center on Nonviolent Conflict',
                        url: 'https://www.nonviolent-conflict.org/estonias-singing-revolution-1986-1991/',
                    },
                ],
            },
        },
        {
            //question 6//
            question: "In 2003, the Rose Revolution of Georgia attracted how many protestors in its successful effort to oust Eduard Shevardnadze?",
            choices: ['10,000', '100,000', '1,000,000', '10,000,000'],
            answerIdx: 1,    
            furtherInfo: {
                explanation: (
                    `Over 100,000 Georgians marched as part of the Rose Revolution in which protestors stormed parliament with flowers in their hands.`
                ),
                sources: [
                    {
                        source: 'ABC News',
                        url: 'https://abcnews.go.com/International/wireStory/100000-protest-belarus-9th-straight-sunday-73418000',
                    },
                ],
            },
        },
        {
            //question 7//
            question: "According to Chenoweth, at what percentage of active engagement of the population is a non-violent protest guaranteed success?",
            choices: ['3.5%', '35%', '55%', '85%'],
            answerIdx: 0,    
            furtherInfo: {
                explanation: (
                    `Chenoweth’s research showed that while non-violent uprisings have a much greater rate of success than violent uprisings, the success of a non-violent protest campaign becomes inevitable when that campaign actively involves 3.5% of the population.`
                ),
                sources: [
                    {
                        source: "BBC. (n.d.). The '3.5% rule': How a small minority can change the world. BBC Future.",
                        url: 'https://www.bbc.com/future/article/20190513-it-only-takes-35-of-people-to-change-the-world',
                    },
                ],
            },
        },
        {
            //question 8//
            question: "What is 3.5% of the 70,000,000 population of the United Kingdom?",
            choices: ['2,000', '20,000', '200,000', '2,000,000'],
            answerIdx: 3,    
            furtherInfo: {
                explanation: (
                    `3.5% of the UK’s total population is 2 million, approximately twice the size of Birmingham, the UK’s second-largest city.`
                ),
                sources: [
                    {
                        source: 'World Population Review',
                        url: 'https://worldpopulationreview.com/countries/united-kingdom-population',
                    },
                ],
            },
        },
        {
            //question 9//
            question: "What is 3.5% of the 300,000,000 population of the United States?",
            choices: ['10,000', '100,000', '11,000,000', '110,000,000'],
            answerIdx: 2,    
            furtherInfo: {
                explanation: (
                    `3.5% of the current population of the United States is approximately 11 million people.`
                ),
                sources: [
                    {
                        source: 'World Population Review',
                        url: 'https://worldpopulationreview.com/countries/united-states-population',
                    },
                ],
            },
        },
        {
            //question 10//
            question: "What percentage of the United States’ population took part in the initial Black Lives Matter protests over the death of George Floyd?",
            choices: ['8.7%', '28.7%', '58.7%', '80.7%'],
            answerIdx: 0,    
            furtherInfo: {
                explanation: (
                    `26 million Americans, 8.7% of the total US population, reported participating in Black Lives Matter protests on and around June 6, 2020. Though self-reported numbers can be inaccurate, even 7 million, half the reported participation, make up 2.3% of the total population.`
                ),
                sources: [
                    {
                        source: 'New York Times',
                        url: 'https://www.nytimes.com/interactive/2020/07/03/us/george-floyd-protests-crowd-size.html',
                    },
                ],
            },
        },
    ],

};

export default nonViolentProtests;