const dd1SettingTheStage = {
  name: 'Drawdown 1: Setting the Stage',
  id: 'LIjHNU0GZI6v1F5pvszg',
  linkTo: "/quizzes/dd1-setting-the-stage",
  explainerOn: true,
  subheading: `The stage is set for climate change. What part will <i>you</i> play?`,
  description: "Since the publication of the book <i>Drawdown</i> in 2017, <i>Project Drawdown</i> has been known as the world’s leading authority on climate change solutions. In their recent lecture series, Climate Solutions 101, Dr. Jonathan Foley breaks down today’s climate challenge for the rest of us. We here at Quizicist have curated a series of quizzes that follow along with the lecture series. How much do you know about the state of climate change today?",
  questions: [
      {
          //question 1//
          question: "Approximately how many humans are currently on the planet?",
          choices: ['2.7 billion', '5 billion', '7.5 billion', '10 billion'],
          answerIdx: 2,    
          furtherInfo: {
              explanation: (
                  `There are approximately 7.5 billion people on the planet today.`
              ),
              sources: [
                  {
                      source: 'Project Drawdown',
                      url: 'https://drawdown.org/climate-solutions-101/unit-1-setting-the-stage',
                  },
              ],
          },
      },
      {
          //question 2//
         question: "Over half the global population now resides in cities.",
          choices: ['True', 'False'],
          answerIdx: 0,    
          furtherInfo: {
              explanation: (
                  `<b>This is true.</b> Humans are now a largely urban species, with over half our entire population living in cities for the first time in history.`
              ),
              sources: [
                  {
                      source: 'Project Drawdown',
                      url: 'https://drawdown.org/climate-solutions-101/unit-1-setting-the-stage',
                  },
              ],
          },
      },
      {
          //question 3//
          question: "Approximately how many humans are estimated to be on the planet by 2050?",
          choices: ['2 billion', '5 billion', '8 billion', '10 billion'],
          answerIdx: 3,    
          furtherInfo: {
              explanation: (
                  `By 2050 it is estimated that the human population will reach 10 billion.`
              ),
              sources: [
                  {
                      source: 'The Drawdown Review',
                      url: 'https://www.drawdown.org/drawdown-review',
                  },
              ],
          },
      },
      {
          //question 4//
          question: "What percent of the world's land has been converted to <b>agriculture</b>?",
          choices: ['20%', '40%', '60%', '80%'],
          answerIdx: 1,    
          furtherInfo: {
              explanation: (
                  `Approximately 40% of Earth's landmass has been converted to agricultural use. `
              ),
              sources: [
                  {
                      source: 'Project Drawdown',
                      url: 'https://drawdown.org/climate-solutions-101/unit-1-setting-the-stage',
                  },
              ],
          },
      },
      {
          //question 5//
          question: "What percent of <b>tropical forest cover</b> has been lost, to date?",
          choices: ['15%', '30%', '60%', '90%'],
          answerIdx: 1,    
          furtherInfo: {
              explanation: (
                  `To date, 30% of Earth's tropical forest cover has been lost.`
              ),
              sources: [
                  {
                      source: 'Project Drawdown',
                      url: 'https://drawdown.org/climate-solutions-101/unit-1-setting-the-stage',
                  },
              ],
          },
      },
      {
          //question 6//
          question: "What percent of the <b>water</b> we take out of nature is used for <i>agricultural purposes</i>?",
          choices: ['20%', '50%', '70%', '90%'],
          answerIdx: 2,    
          furtherInfo: {
              explanation: (
                  `70% of all the water we take out of nature is used for agriculture, mostly irrigation.`
              ),
              sources: [
                  {
                      source: 'Project Drawdown',
                      url: 'https://drawdown.org/climate-solutions-101/unit-1-setting-the-stage',
                  },
              ],
          },
      },
      {
          //question 7//
          question: "Since the 1800s, <b>atmospheric carbon levels</b> have increased by approximately what percent?",
          choices: ['12.5%', '25%', '50%', '100%'],
          answerIdx: 2,    
          furtherInfo: {
              explanation: (
                  `Atmospheric carbon has increased by approximately 50% since the 1800s, due in large part to increasing industrialization.`
              ),
              sources: [
                  {
                      source: 'Project Drawdown',
                      url: 'https://drawdown.org/climate-solutions-101/unit-1-setting-the-stage',
                  },
              ],
          },
      },
      {
          //question 8//
          question: "What do many scientists call the time period encompassed by the last 50 years?",
          choices: ['The Great Acceleration', 'The Singularity', 'The Anthropocene Epoch', 'The Great Deceleration'],
          answerIdx: 0,    
          furtherInfo: {
              explanation: (
                  `In the last 50 years we have changed the planet and how we, as a species, live -- more than we have in the previous 6 million years, combined. This has led many scientists to refer to this period as <i>The Great Acceleration</i>.`
              ),
              sources: [
                  {
                      source: 'The Drawdown Review',
                      url: 'https://www.drawdown.org/drawdown-review',
                  },
              ],
          },
      },
      {
          //question 9//
          question: "Almost half of consumption-related emissions are generated by what percentage of people globally?",
          choices: ['10%', '25%', '50%', '75%'],
          answerIdx: 0,    
          furtherInfo: {
              explanation: (
                  `Almost 50% of all consumption-related emissions are generated by just 10% of the global population.`
              ),
              sources: [
                  {
                      source: 'The Drawdown Review',
                      url: 'https://www.drawdown.org/drawdown-review',
                  },
              ],
          },
      },
      {
          //question 10//
          question: "Which countries are more likely to have high GHG (greenhouse gas) emissions?",
          choices: ['Low-income countries', 'Under-developed countries', 'High-income countries', 'Cold-climate countries'],
          answerIdx: 2,    
          furtherInfo: {
              explanation: (
                  `High-income countries are more likely to have high levels of greenhouse gas emissions.`
              ),
              sources: [
                  {
                      source: 'The Drawdown Review',
                      url: 'https://www.drawdown.org/drawdown-review',
                  },
              ],
          },
      },
  ],
};

export default dd1SettingTheStage;