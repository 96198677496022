import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const useStyles = makeStyles(theme => ({
  clockIcon: {
    marginRight: 10,
    color: 'white',
  },
  timer: {
    color: 'white',
    [theme.breakpoints.down("xs")]: {
      "& > *": {
          fontSize: 24,
        },
    },
  },
}));

const Timer = ({ secondsLeft }) => {
  const classes = useStyles();
  return (
    <div className={classes.timer}>
      <Typography variant="h3" component="span">
        <FontAwesomeIcon icon="clock" className={classes.clockIcon} />
        {secondsLeft}
      </Typography>
    </div>
  );
}

export default Timer;
