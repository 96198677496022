 const asteroidSurvival = {
    name: 'Could you survive the dinosaur-killing asteroid?',
    id: 'lp6nwTaDIacoq0kvjv87',
    linkTo: "/quizzes/asteroidSurvival",
    explainerOn: true,
    description: "As humans we like to think of ourselves as the apex of life on Earth. With our well developed brains and our opposable thumbs we have an undisputed place at the top. But, could we have survived the most famous mass-extinction event our planet has ever experienced?",
    questions: [
        {
            //question 1//
            question: "First off, how far back in time would you have to travel to find out?",
            choices: ['265 million years', '100 million years', '65 million years', '10 million years'],
            answerIdx: 2,    
            furtherInfo: {
                explanation: (
                    `To find out if you could survive the dinosaur-killing asteroid, you would first have to travel back in time 65 million years.`
                ),
                sources: [
                    {
                        source: 'Wired',
                        url: 'https://www.wired.com/story/how-to-survive-a-killer-asteroid/',
                    },
                ],
            },
        },
        {
            //question 2//
           question: "Fun! So, the asteroid you’re camped under unleashed a 100 million megaton blast. By comparison, the largest thermonuclear device ever detonated was a measly 50 megatons. How many 50 megaton bombs would you have to detonate at once to reach that same level of destruction?",
            choices: ['Why would you do that?', 'All right, 2,000', 'Wait, I mean 20,000', 'Let’s get crazy. 2 million!'],
            answerIdx: 3,    
            furtherInfo: {
                explanation: (
                    `You would have to detonate an insane 2 million 50 megaton thermonuclear bombs to reach the same level of destruction as the asteroid.`
                ),
                sources: [
                    {
                        source: 'Wired',
                        url: 'https://www.wired.com/story/how-to-survive-a-killer-asteroid/',
                    },
                ],
            },
        },
        {
            //question 3//
            question: "Just for grins, what is the name of this killer asteroid you’re awaiting?",
            choices: ['The K-2 Asteroid', 'The Chicxulub Asteroid', 'The Randolph-Corinsteen Asteroid', 'Thor'],
            answerIdx: 1,    
            furtherInfo: {
                explanation: (
                    `The killer asteroid widely believed to be responsible for the death of the dinosaurs is named The Chicxulub Asteroid.`
                ),
                sources: [
                    {
                        source: 'Wired',
                        url: 'https://www.wired.com/story/how-to-survive-a-killer-asteroid/',
                    },
                ],
            },
        },
        {
            //question 4//
            question: "Where did our killer asteroid strike the earth?",
            choices: ['In what is now Lyons, France', 'In what is now Cairo, Egypt', 'In what is now the Yucátan Peninsula, Mexico', 'In what is now Louisiana, U.S.A.'],
            answerIdx: 2,    
            furtherInfo: {
                explanation: (
                    `The Chicxulub Asteroid struck Earth in what is now the Yucatan Peninsula, Mexico.`
                ),
                sources: [
                    {
                        source: 'Wired',
                        url: 'https://www.wired.com/story/how-to-survive-a-killer-asteroid/',
                    },
                ],
            },
        },
        {
            //question 5//
            question: "How deadly was the asteroid’s impact?",
            choices: ['It killed every dinosaur except for certain species of maniraptora, and every mammal larger than a raccoon', 'It killed every dinosaur except for birds, and every mammal larger than a shrew', 'It killed every dinosaur, and every mammal larger than a shrew', 'It killed everything on earth except for mammals'],
            answerIdx: 0,    
            furtherInfo: {
                explanation: (
                    `The asteroid’s impact was so deadly it killed every dinosaur except for a certain species of maniraptora, and every mammal larger than a raccoon.`
                ),
                sources: [
                    {
                        source: 'Wired',
                        url: 'https://www.wired.com/story/how-to-survive-a-killer-asteroid/',
                    },
                ],
            },
        },
        {
            //question 6//
            question: "How big was the killer asteroid?",
            choices: ['The size of a city', 'The size of a small country', 'The size of a large U.S. state', '¼ the size of our moon'],
            answerIdx: 0,    
            furtherInfo: {
                explanation: (
                    `The Chicxulub Asteroid was the size of a city.`
                ),
                sources: [
                    {
                        source: 'Wired',
                        url: 'https://www.wired.com/story/how-to-survive-a-killer-asteroid/',
                    },
                ],
            },
        },
        {
            //question 7//
            question: "Even though the asteroid smashed into Mexico, the blast was so powerful that glass would shatter as far away as:",
            choices: ['Greenland', 'Africa', 'South America', 'Russia'],
            answerIdx: 1,    
            furtherInfo: {
                explanation: (
                    `Though it struck in Mexico, the blast generated by the impact was so powerful it would have shattered glass as far away as Africa.`
                ),
                sources: [
                    {
                        source: 'Wired',
                        url: 'https://www.wired.com/story/how-to-survive-a-killer-asteroid/',
                    },
                ],
            },
        },
        {
            //question 8//
            question: "According to Dr. Charles Bardeen’s model, what is the first step in surviving the Chicxulub Asteroid?",
            choices: ['Get underground', 'Get to high ground', 'Lie flat on the ground', 'Be on the opposite side of the world from the impact'],
            answerIdx: 3,    
            furtherInfo: {
                explanation: (
                    `The first step on Dr. Bardeen’s model for survival is to be on the opposite side of the world from the impact.`
                ),
                sources: [
                    {
                        source: 'Wired',
                        url: 'https://www.wired.com/story/how-to-survive-a-killer-asteroid/',
                    },
                ],
            },
        },
        {
            //question 9//
            question: "Next, you hear the sonic boom. Yes, even from the opposite side of the world. What must you immediately do to stay alive long enough to time travel home?",
            choices: ['Get to high ground and find underground shelter.', 'Get to low ground and find underground shelter.', 'Lie flat in a ditch with your arms over your head.', 'Lie down behind a sturdy tree or boulder.'],
            answerIdx: 0,    
            furtherInfo: {
                explanation: (
                    `If you want to be able to live long enough to time travel back home, Dr. Bardeen recommends you get to high ground immediately after you hear the sonic boom and find underground shelter, such as a cave.`
                ),
                sources: [
                    {
                        source: 'Wired',
                        url: 'https://www.wired.com/story/how-to-survive-a-killer-asteroid/',
                    },
                ],
            },
        },
        {
            //question 10//
            question: "True or False: The Chicxulub impact crater is still visible today.",
            choices: ['True', 'False'],
            answerIdx: 0,    
            furtherInfo: {
                explanation: (
                    `It’s true. Once you have successfully time traveled back home to the present day, you will still be able to see the impact crater from the Chicxulub Asteroid.`
                ),
                sources: [
                    {
                        source: 'Wired',
                        url: 'https://www.wired.com/story/how-to-survive-a-killer-asteroid/',
                    },
                ],
            },
        },
    ],

};

export default asteroidSurvival;