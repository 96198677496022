 const gameOfThronesQuiz = {
    name: 'G.O.T. Quiz?',
    id: "gameOfThronesQuiz",
    linkTo: "/quizzes/gameOfThronesQuiz",
    description: 'Think you know more than Jon Snow?',
    questions: [
        {
            question: "Which cast member appears in more episodes that any other member?",
            choices: ['Peter Dinklage', 'Emilia Clarke', 'Kit Harington'],
            answerIdx: 0,    
            furtherInfo: {
            },
        },
        {
            question: "Aidan Gillen plays which character on Game of Thrones?",
            choices: ['Bran Stark', 'Bronn', 'Petyr ‘Littlefinger’ Baelish'],
            answerIdx: 2,    
            furtherInfo: {
            },
        },
        {
            question: "The R.R. in George Martin’s name stands for?",
            choices: ['Robert Redding', 'Raymond Richard', 'Red Rose'],
            answerIdx: 1,    
            furtherInfo: {
            },
        },
        {
            question: "The novels of the Game of Thrones series are called?",
            choices: ['Winter is Coming', 'A Song of Ice and Fire', 'The King of the North'],
            answerIdx: 1,    
            furtherInfo: {
            },
        },
        {
            question: "Which actor played drums for the band TEXAS?",
            choices: ['Rory McCann', 'Iain Glen', 'Alfie Allen'],
            answerIdx: 0,    
            furtherInfo: {
            },
        },
        {
            question: "Which actor is a Worldwide DJ and musician?",
            choices: ['Jerome Flynn', 'Kristian Nairn', 'Conleth Hill'],
            answerIdx: 1,    
            furtherInfo: {
            },
        },
        {
            question: "What is Jon Snow’s real name?",
            choices: ['Tony Stark', 'Jon Stark', 'Aegon Targaryen'],
            answerIdx: 2,    
            furtherInfo: {
            },
        },
        {
            question: "Which actress plays Margaery Tyrell?",
            choices: ['Carice van Houten', 'Michelle Fairley', 'Natalie Dormer'],
            answerIdx: 2,    
            furtherInfo: {
            },
        },
        {
            question: "What is Davos Seaworth nickname on the show?",
            choices: ['Onion Knight', 'Onion Smuggler', 'Onion Stench'],
            answerIdx: 0,    
            furtherInfo: {
            },
        },
        {
            question: "Who became King of Westeros in the series finale?",
            choices: ['Jon Snow', 'Bran Stark', 'Bronn'],
            answerIdx: 1,    
            furtherInfo: {
            },
        },
    ],

};

export default gameOfThronesQuiz;
