 const musicTrivia80 = {
    id: "musicTrivia80",
    name: "80's Music Trivia",
    linkTo: "/quizzes/musicTrivia80",
    description: 'Are you Hip or Square?',
    questions: [
        {
            question: "Who was the first singer for Genesis?",
            choices: ['Phil Collins', 'Peter Gabriel', 'Ray Wilson'],
            answerIdx: 1,    
            furtherInfo: {
            },
        },
        {
            question: 'What artist recorded the song "Funkytown"?',
            choices: ['The Gap Band', 'Lionel Richie', 'Lipps, Inc.'],
            answerIdx: 2,    
            furtherInfo: {
            },
        },
        {
            question: 'What song did Eddie Rabbitt record about rain?',
            choices: ['Raindrops Falling On My Head', 'I Love a Rainy Night', 'Wish it Would Rain'],
            answerIdx: 1,    
            furtherInfo: {
            },
        },
        {
            question: 'Who chanted the magical words "Abracadabra"?',
            choices: ['Dexys Midnight Runners', 'KISS', 'The Steve Miller Band'],
            answerIdx: 2,
            furtherInfo: {
            },
        },
        {
            question: 'Who recorded the song "Time After Time"?',
            choices: ['Cyndi Lauper', 'Prince', 'Madonna'],
            answerIdx: 0,
            furtherInfo: {
            },
        },
        {
            question: 'Which super group recorded the song "We Are the World"?',
            choices: ['Huey Lewis and the News?', 'USA for Africa', 'Tears For Fears'],
            answerIdx: 1,
            furtherInfo: {
            },
        },
        {
            question: `Fill in the blank: "St. Elmo's Fire _________ ."`,
            choices: ['(On The Horizon)', '(In My Heart)', '(Man In Motion)'],
            answerIdx: 2,    
            furtherInfo: {
            },
        },
        {
            question: `Fill in the blank: " _________ Wants To Rule the World."`,
            choices: ['Bono', 'Everybody', 'Simple Minds'],
            answerIdx: 1,    
            furtherInfo: {
            },
        },
        {
            question: `Fill in the blank: " _________ for Nothing."`,
            choices: ['Jail Time', 'Money', 'Bankrupcy'],
            answerIdx: 1,    
            furtherInfo: {
            },
        },
        {
            question: `Fill in the blank: " _________ to Love."`,
            choices: ['Sledgehammer', 'Addicted', 'Maniac'],
            answerIdx: 1,    
            furtherInfo: {
            },
        },
    ],

};

export default musicTrivia80;
