import React from "react";
import PropTypes from "prop-types";
import Avatar from "@material-ui/core/Avatar";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  profilePic: {
    width: ({ size }) => theme.spacing(size),
    height: ({ size }) => theme.spacing(size),
    marginBottom: theme.spacing(1),
    backgroundColor: '#fff',
  },
}));

const ProfilePic = ({ src, alt = "ProfilePic", size = 20 }) => {
  const classes = useStyles({ size });

  return <Avatar className={classes.profilePic} src={src} alt={alt} />;
};

ProfilePic.propTypes = {
  src: PropTypes.string,
};

export default ProfilePic;
