const currencyExchange = {
    name: 'Currency Exchange Quiz',
    id: "currencyExchange",
    linkTo: "/quizzes/currencyExchange",
    explainerOn: true,
    randomize: true,
    quizLengthSpec: 8,
    subheading: `Are you the master of coin? Take this quiz to find out.`,
    description: `The global economy is no longer the sole purview of banks and corporations. Today, more than ever, private individuals are participating in international commerce. Whether you like to travel, or are an adventurous online shopper, knowing the current exchange rates has never been so relevant.`,
    questions: [
        { // Q-1
            question: "Kasumi is visiting Tokyo and wants to buy a SIM card for her cell phone. It costs ¥324 (Japanese yen). Approximately how much does she have to pay in USD (if $1 USD = ¥110)?",
            choices: ['$0.50', '$3', '$13', '$25'],
            answerIdx: 1,
            furtherInfo: {
                explanation: (
                    `Kasumi's ¥324 SIM card will cost her approximately $3 USD.`
                ),
                sources: [
                    {
                        source: "X-Rates",
                        url: 'https://www.x-rates.com/table/?from=USD&amount=1',
                    },
                ],
            },
        },
        { // Q-2
            question: "If an iPhone in the US costs $700 USD and an iPhone in Britain costs £500 (British pounds), which iPhone version is cheaper (if $1 USD = £0.70)?",
            choices: ['British version', 'American version', 'Same price'],
            answerIdx: 0,
            furtherInfo: {
                explanation: (
                    `The British iPhone version will cost you less than the American.`
                ),
                sources: [
                    {
                        source: "X-Rates",
                        url: 'https://www.x-rates.com/table/?from=USD&amount=1',
                    },
                ],
            },
        },
        { // Q-3
            question: "A bowl of chicken rice in Singapore costs S$5 SGD (Singapore dollars). How much is that in USD (if $1 USD = S$1.35 SGD)?",
            choices: ['$3.70 USD', '$5.70 USD', '$13.70 USD', '$15.70 USD'],
            answerIdx: 0,
            furtherInfo: {
                explanation: (
                    `A S$5 SGD bowl of chicken rice will cost approximately $3.70 USD`
                ),
                sources: [
                    {
                        source: "X-Rates",
                        url: 'https://www.x-rates.com/table/?from=USD&amount=1',
                    },
                ],
            },
        },
        { // Q-4
            question: "You're in Australia and are loading up on snacks. The total comes to $125 AUD (Australian Dollars), but your budget is limited to $80 USD. Do you have enough money to make the purchase (if $1 USD = $1.38 AUD)?",
            choices: ['No', 'Yes', 'Perhaps'],
            answerIdx: 0,
            furtherInfo: {
                explanation: (
                    `Since $80 USD is approximately $110 AUD, unfortunately you do not have enough to cover all your snacks.`
                ),
                sources: [
                    {
                        source: "X-Rates",
                        url: 'https://www.x-rates.com/table/?from=USD&amount=1',
                    },
                ],
            },
        },

        { // Q-5
            question: "Vacationing in Toronto, you decide on a trip to the aquarium. Tickets cost $40 CAD (Canadian Dollars) each. Approximately how much will you have to pay for your party of five, in USD (if $1 USD = $1.28 CAD)?",
            choices: ['$60 USD', '$130 USD', '$160 USD', '$230 USD'],
            answerIdx: 2,
            furtherInfo: {
                explanation: (
                    `At $40 CAD a ticket, you will have to pay approximately $160 USD to take all five of your party to the aquarium.`
                ),
                sources: [
                    {
                        source: "X-Rates",
                        url: 'https://www.x-rates.com/table/?from=USD&amount=1',
                    },
                ],
            },
        },
        { // Q-6
            question: "You're in Manila buying 2 drinks that cost $3 USD each. Approximately how many Philippine Pesos (₱) do you have to pay (if $1 USD = ₱50)?",
            choices: ['₱75', '₱150', '₱300', '₱600'],
            answerIdx: 2,
            furtherInfo: {
                explanation: (
                    `At $3 USD per drink, you will have to pay approximately ₱300 for two of them.`
                ),
                sources: [
                    {
                        source: "X-Rates",
                        url: 'https://www.x-rates.com/table/?from=USD&amount=1',
                    },
                ],
            },
        },
        { // Q-7
            question: "On your vacation in Cyprus, you forgot to bring your sunscreen. It costs €13 Euros for one bottle. How much is that in USD (if 1 USD = €0.84)?",
            choices: ['$15', '$30', '$90', '$120'],
            answerIdx: 0,
            furtherInfo: {
                explanation: (
                    `Your €13 sunscreen costs approximately $15 USD.`
                ),
                sources: [
                    {
                        source: "X-Rates",
                        url: 'https://www.x-rates.com/table/?from=USD&amount=1',
                    },
                ],
            },
        },
        { // Q-8
            question: "Godiva chocolate in Switzerland costs 10 Swiss francs. How much is that in USD (if $1 USD = 0.90 Swiss francs)?",
            choices: ['$5.50 USD', '$11 USD', '$22 USD', '$44 USD'],
            answerIdx: 1,
            furtherInfo: {
                explanation: (
                    `10 Swiss francs worth of chocolate in Switzerland will cost you about $11 USD.`
                ),
                sources: [
                    {
                        source: "X-Rates",
                        url: 'https://www.x-rates.com/table/?from=USD&amount=1',
                    },
                ],
            },
        },
        { // Q-9
            question: "A laptop worth $1,000 USD broke on a business trip in Canada, and you need to buy a new one ASAP. How much would a laptop the exact same value as your old one cost in Canadian dollars (CAD), in the Canadian store (if $1 USD = approx $1.30 CAD)?",
            choices: ['$260 CAD', '$1,000 CAD', '$1,260 CAD', '$1,560 CAD'],
            answerIdx: 2,
            furtherInfo: {
                explanation: (
                    `Your new Canadian laptop will cost you approximately $1,260 CAD.`
                ),
                sources: [
                    {
                        source: "X-Rates",
                        url: 'https://www.x-rates.com/table/?from=USD&amount=1',
                    },
                ],
            },
        },
        { // Q-10
            question: "Movie tickets in South Korea cost ₩15,000 (Korean won). Movie tickets in America cost $9 USD. Which movie experience is cheaper (if $1 USD = ₩1,160)?",
            choices: ['USA', 'South Korea', 'They are the same price'],
            answerIdx: 0,
            furtherInfo: {
                explanation: (
                    `Since ₩15,000 is approximately $13 USD, the $9 USD American movie experience is cheaper than the South Korean one.`
                ),
                sources: [
                    {
                        source: "X-Rates",
                        url: 'https://www.x-rates.com/table/?from=USD&amount=1',
                    },
                ],
            },
        },
        { // Q-11
            question: "A pack of origami paper costs ¥900 (Japanese Yen). How much is that in USD (if $1 USD = ￥110)?",
            choices: ['$4', '$8', '$16', '$32'],
            answerIdx: 1,
            furtherInfo: {
                explanation: (
                    `￥900 Japanese Yen is equal to approximately $8 USD.`
                ),
                sources: [
                    {
                        source: "X-Rates",
                        url: 'https://www.x-rates.com/table/?from=USD&amount=1',
                    },
                ],
            },
        },
        { // Q-12
            question: "Your really rich friend bought you ₿2 bitcoin (BTC) for your birthday. How much is that in USD (if $1 USD = ₿0.000025 BTC)?",
            choices: ['$2,000', '$8,000', '$40,000', '$80,000'],
            answerIdx: 3,
            furtherInfo: {
                explanation: (
                    `Your ₿2 BTC (bitcoin) cost your friend approximately $80,000 USD.`
                ),
                sources: [
                    {
                        source: "X-Rates",
                        url: 'https://www.x-rates.com/table/?from=USD&amount=1',
                    },
                ],
            },
        },
        { // Q-13
            question: "You've forgotten your sunscreen in Germany. Thankfully it's super cheap, only €2.99 Euros! How much is that in USD (if $1 USD = €0.84)?",
            choices: ['$0.50', '$1.50', '$3.50', '$5.50'],
            answerIdx: 2,
            furtherInfo: {
                explanation: (
                    `Your €2.99 German sunscreen costs approximately $3.50 USD.`
                ),
                sources: [
                    {
                        source: "X-Rates",
                        url: 'https://www.x-rates.com/table/?from=USD&amount=1',
                    },
                ],
            },
        },
        { // Q-14
            question: "You forgot to change your money back from Colombian pesos. You have exactly $36,370.10 COP (Colombian pesos) left. How much is that in USD (if $1 USD = $3,900 COP)?",
            choices: ['$4.50 USD', '$9 USD', '$18 USD', '$36 USD'],
            answerIdx: 1,
            furtherInfo: {
                explanation: (
                    `After converting your $36,370.10 COP back to USD you will have approximately $9 USD.`
                ),
                sources: [
                    {
                        source: "X-Rates",
                        url: 'https://www.x-rates.com/table/?from=USD&amount=1',
                    },
                ],
            },
        },

        { // Q-15
            question: "While in New Zealand, you're trying to buy a souvenir which costs $18 NZD (New Zealand dollars). How much is that in USD (if $1 USD = $1.44 NZD)?",
            choices: ['$6.25 USD', '$12.50 USD', '$25 USD', '$50 USD'],
            answerIdx: 1,
            furtherInfo: {
                explanation: (
                    `Your $18 NZD souvenir costs approximately $12.50 USD.`
                ),
                sources: [
                    {
                        source: "X-Rates",
                        url: 'https://www.x-rates.com/table/?from=USD&amount=1',
                    },
                ],
            },
        },

        { // Q-16
            question: "You're booking a hotel in Mexico; one room costs $2,050 MXN (Mexican pesos) per night. How much is that in USD (if $1 USD = approx $20 MXN)?",
            choices: ['$13 USD', '$23 USD', '$53 USD', '$103 USD'],
            answerIdx: 3,
            furtherInfo: {
                explanation: (
                    `Your $2,050 MXN per night hotel room costs approximately $103 USD per night.`
                ),
                sources: [
                    {
                        source: "X-Rates",
                        url: 'https://www.x-rates.com/table/?from=USD&amount=1',
                    },
                ],
            },
        },
        { // Q-17
            question: "Which is worth more: $10 USD or ₹800 Indian rupees (if $1 USD = ₹75 rupees)?",
            choices: ['$10 USD', '₹800 rupees', 'They are the same.'],
            answerIdx: 1,
            furtherInfo: {
                explanation: (
                    `Since $10 USD equals approximately ₹750 rupees, ₹800 rupees are worth more.`
                ),
                sources: [
                    {
                        source: "X-Rates",
                        url: 'https://www.x-rates.com/table/?from=USD&amount=1',
                    },
                ],
            },
        },
    ],
  
  };
  
  export default currencyExchange;