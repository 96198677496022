const bigNumberMath = {
	id: 'enotation',
	name: 'Using Scientific Notation',
	linkTo: "/quizzes/enotation",
	explainerOn: true,
	description: `About working with very large numbers, with Scientific (or, "E") Notation.`,
	questions: [
		{ // Q-1
			question: "How many digits are in 4.3E13 if you convert to its longer form?",
			writeInAnswer: 14,
			furtherInfo: {
				explanation: (
`Explanation

The number 4.3E13 is written in <b>Scientific Notation</b>. That means it is equivalent to 4.3 x 10^13, or 43,000,000,000,000.

43 trillion! That's a huge number, yet it's so short when written as: 4.3E13

Another way to think about scientific notation is that the right-hand side of the E tells you how many times to move the decimal
point to arrive at the original number.

For this reason, there's a direct relationship between the number to the right of E and how many digits are in a number
written in scientific notation. Here's how you find the number of digits:

4.3E13 has 1 digit left of the decimal point. So the number of digits is 1 + 13 (number to the right of E).

<b>Answer</b>     
<b>14</b>`
				)
			}
		},
		{ // Q-2
			question: "What are the two most significant digits of 7,937,125?",
			writeInAnswer: 79,
			furtherInfo: {
				explanation: (
`Explanation

Find the two most significant digits of 7,937,125.

(hint: <b>Significant Digits</b> are the digits with the greatest value)

For 7,937,125, they're the two left-most digits: <b>7 and 9</b>.

<b>Answer</b>     
<b>79</b>`
				)
			}
		}, 
		{ // Q-3
			question: "7,937,125 is close to 7.9E_____ (fill in the blank)",
			writeInAnswer: 6,
			furtherInfo: {
				explanation: (
`Explanation

Find the exponent of 7,937,125.

<b>Step 1</b>   
Find the most significant digit.   
<b>It's 7</b> 

<b>Step 2</b>   
Count how many digits are between it and the decimal point.  
<b>6 digits</b>

<b>Answer</b>: <b>6</b>, as in 7.9E<b>6</b>`
				)
			}

		}, 
		{ // Q-4
			question: "295,005 is close to 2.9E_____ (fill in the blank)",
			writeInAnswer: 5,
			furtherInfo: {
				explanation: (
`Explanation

If you write the number as 295,005.0, how many times do you need to move the decimal place to place it in front of the most significant digit?

Moving it left <b>5</b> times turns the number into 2.95005.

So the answer is 2.9E<b>5</b>`
				)
			}

		}, 
		{ // Q-5
			question: "What are the most significant digits of 7,937,125 x 295,005?",
			writeInAnswer: 23,
			correctRange: [22, 25],
			furtherInfo: {
				explanation: (
`Explanation

Use estimation to find the most significant digits of 7,937,125 x 295,005 without complex arithmetic.

<b>Step 1</b>    
Round both numbers to their most significant digit.   
(7,937,125 rounds to <b>8,000,000</b>, 295,005 rounds to <b>300,000</b>)

<b>Step 2</b>   
Write the problem in scientific notation.   
<b>8E6 x 3E5</b>

<b>Step 3</b>    
Multiply the numbers, and multiply the exponents using addition.   
(8 x 3)(E6 x E5)  =  (24 x E11) = <b>24E11</b>

<b>Answer</b>   
This gives us 24. The answer is actually <b>23</b>, but if you're close, you get credit!.`
				)
			}

		}, 
		{ // Q-6
			question: "How many digits are in 7,937,125 x 295,005?",
			writeInAnswer: 13,
			furtherInfo: {
				explanation: (
`Explanation

Find the number of digits in 7,937,125 x 295,005.

<b>Step 1</b>   
Round both numbers to their most significant digit.   
<b>8,000,000 x 300,000</b>

<b>Step 2</b>   
Write the problem in scientific notation.   
<b>8E6 x 3E5</b>

<b>Step 3</b>   
Multiply the numbers, and multiply the exponents using addition.   
(8 * 3 = 24)(E6 x E5)  =  (24 x E11) = <b>24E11</b>

<b>Step 4</b>     
Move the decimal point one place to the left, which adds 1 E1.   
<b>2.4E12</b>

<b>Answer</b>   
<b>13</b>, because in 2.4E_12, the number of decimal places is 12 + 1`
				)
			}

		},
		{ // Q-7
			question: "What is the most significant digit of 7,937,125 / 295,005?",
			writeInAnswer: 2,
			furtherInfo: {
				explanation: (
`Explanation

Find the most significant digit of 7,937,125 / 295,005.

<b>Step 1</b>   
Round both numbers to their most significant digit.   
<b>8,000,000 / 300,000</b>

<b>Step 2</b>   
Write the problem in scientific notation.   
<b>8E6 / 3E5</b>

<b>Step 3</b>   
Divide the numbers, and divide the exponents using subtraction. Then multiply the results.   
(8 / 3)(E6 / E5) = (2.67 x E1) = <b>2.67E1</b>

<b>Answer</b>   
The most significant digit: <b>2</b>`
				)
			}

		},
		{ // Q-8
			question: "How many <i>whole number</i> digits are in 7,937,125 / 295,005?",
			writeInAnswer: 2,
			furtherInfo: {
				explanation: (
`Explanation

Find the number of <i>whole number</i> digits in 7,937,125 / 295,005.

<b>Step 1</b>   
Round both numbers to their most significant digit.   
<b>8,000,000 / 300,000</b>

<b>Step 2</b>   
Write the problem in scientific notation.   
<b>8E6 / 3E5</b>

<b>Step 3</b>   
Divide the numbers, and divide the exponents using subtraction. Then multiply the results.   
(8 / 3)(E6 / E5) = (2.67 x E1) = <b>2.67E1</b>

<b>Answer</b>   
<b>E1 + 1</b>, which is <b>2</b> <i>whole number</i> digits.`
				)
			}

		}
	],

};

export default bigNumberMath;
