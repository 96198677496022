 const weirdAnimals = {
    name: 'Weird Animals',
    id: 'MIHfuoGGXSPYEuWzHElE',
    linkTo: "/quizzes/weirdAnimals",
    explainerOn: true,
    description: 'The world is full of strange and wonderful animals. How many can you identify?',
    questions: [
        { // 1
            question: "The axolotl is:",
            choices: [
                'A pterosaur (cousin of the dinosaurs), thought to live at the same time as the largest flying lizard ever to exist: Quetzalcoatlus.',
                'A relative of the tiger salamander, distinctive for retaining feathery gills that other salamanders lose in their adult form.',
                'A bony fish infamous for making loud thrumming sounds audible to people living nearby.',
                'That is not a real animal.' 
            ],
            answerIdx: 1,    
            furtherInfo: {
                explanation: "The axolotl is a relative of the tiger salamander, and is distinctive for retaining the feathery gills other salamanders lose in their adult form.",
                sources: [
                    {
                        source: 'Scientific American',
                        url: 'https://www.scientificamerican.com/article/biologys-beloved-amphibian-the-axolotl-is-racing-toward-extinction1/',
                    }
                ],
            },
        },
        { // 2
            question: "The <i>largest</i> land animal ever to exist is:",
            choices: [
                'Titanosaur, a sauropod dinosaur, weighing 69 tons and measuring 120 feet.',
                'Paraceratherium, a towering prehistoric rhino that weighed as much as five elephants.',
                'Apatosaurus, a Jurassic dinosaur, weighing 45 tons and measuring 75 feet.',
                'The human ego.'
            ],
            answerIdx: 0,    
            furtherInfo: {
                explanation: "The largest land animal to ever exist was the titanosaur; a sauropod dinosaur weighing 69 tons and measuring 120 feet.",
                sources: [
                    {
                        source: 'National Geographic',
                        url: 'https://www.nationalgeographic.com/science/article/largest-dinosaur-ever-titanosaur-fossil-patagotitan-science',
                    }
                ],
            },
        },
        { // 3
            question: "Which animal can strike prey with the velocity of a .22 rifle, delivering a force so powerful its attack generates temperatures almost as hot as the sun?",
            choices: [
                'Yeah, right.',
                'Mosasaurus, the apex predator at the end of the dinosaur age, known as that giant toothy horror in Jurassic World.',
                'The red panda.',
                'The mantis shrimp.'
            ],
            answerIdx: 3,    
            furtherInfo: {
                explanation: "The mantis shrimp can strike prey with the same velocity as a .22 rifle, a force so powerful its attack generates temperatures almost as hot as the sun.",
                sources: [
                    {
                        source: 'Fact Animal',
                        url: 'https://factanimal.com/mantis-shrimp/',
                    }
                ],
            },
        },
        { // 4
            question: "The blobfish is:",
            choices: [
                'An evolutionary nightmare: an ugly, jelly-like fish with a giant, human-like nose that well deserves the “World’s Ugliest Animal” title.',
                'Another name for a pufferfish.',
                'A fish that’s normal-looking when at home in the crushing depths.',
                'That is not a real animal.'
            ],
            answerIdx: 2,    
            furtherInfo: {
                explanation: "The blobfish, despite its disparaging name, is normal looking when at home in the crushing depths of the deep sea.",
                sources: [
                    {
                        source: 'Live Science',
                        url: 'https://www.livescience.com/64817-blobfish.html',
                    },
                    {
                        source: 'Earth Touch News',
                        url: 'https://www.earthtouchnews.com/wtf/wtf/blobfish-might-be-a-gooey-mess-out-of-water-but-check-out-a-living-one-video/'
                    }
                ],
            },
        },
        { // 5
            question: "The cookiecutter shark <i>is</i> a real animal.",
            choices: ['True', 'False'],
            answerIdx: 0,    
            furtherInfo: {
                explanation: "True. This shark is indeed real, despite its cookiecutter name.",
                sources: [
                    {
                        source: "Shark Research institute",
                        url: 'https://www.sharks.org/cookiecutter-shark-isistius-brasiliensis',
                    }
                ],
            },
        },
        { // 6
            question: "The paralophodon is:",
            choices: [
                'A genus of hadrosaur (duck-billed dinosaur) famous for the giant crest that arches back over its head.',
                'A ‘bone headed’ dinosaur thought to ram one another like goats, distinctive for its thick, dome-like skull formed by solid bone over a tiny brain.',
                'A prehistoric shark, like megalodon but smaller and distinctive for its odd, torpedo-shaped mouth.',
                'That is not a real animal.'
            ],
            answerIdx: 3,    
            furtherInfo: {
                explanation: "The paralophodon is <i>not</i> a real animal.",
                sources: [
                    {
                        source: 'Scientific American',
                        url: 'https://www.scientificamerican.com/article/biologys-beloved-amphibian-the-axolotl-is-racing-toward-extinction1/',
                    }
                ],
            },
        },
        { // 7
            question: "Which of the following is <i>not</i> a real animal?",
            choices: [
                'Terror bird',
                'Dire wolf',
                'Smilodon',
                'Homo Eustase'
            ],
            answerIdx: 3,    
            furtherInfo: {
                explanation: "Homo Eustase is also not a real animal.",
                sources: [
                    {}
                ],
            },
        },
        { // 8
            question: "The cockeyed squid is a deep sea squid with one eye twice as large as the other; its larger eye is better for watching the upper depths, while its smaller eye watches the lower depths.",
            choices: ['True', 'False'],
            answerIdx: 0,    
            furtherInfo: {
                explanation: "This is true! The cockeyed squid lives in the deep sea with one eye twice as large as the other.",
                sources: [
                    {
                        source: 'Monterey Bay Aquarium Research Institute',
                        url: 'https://www.mbari.org/the-curious-eyes-of-the-cockeyed-squid/',
                    }
                ],
            },
        },
        { // 9
            question: "The barreleye fish has large, green, tubular eyes that swivel around inside its transparent head.",
            choices: ['True', 'False'],
            answerIdx: 0,    
            furtherInfo: {
                explanation: "True. The barreleye fish has large, green, tubular eyes that swivel around inside its transparent head.",
                sources: [
                    {
                        source: 'Monterey Bay Aquarium Research Institute',
                        url: 'https://www.mbari.org/barreleye-fish-with-tubular-eyes-and-transparent-head/',
                    }
                ],
            },
        },
          { // 10
            question: "The armadillo whose fragile, flexible shell is not fully attached to its body or used for armor is called:",
            choices: ['The Bandis armadillo', 'The pink fairy armadillo', 'The silver brush armadillo', 'That is not a real animal'],
            answerIdx: 1,    
            furtherInfo: {
                explanation: "The pink fairy armadillo has a fragile, flexible shell not fully attached to its body or used for armor.",
                sources: [
                    {
                        source: 'WIRED',
                        url: 'https://www.wired.com/2014/01/absurd-creature-of-the-week-pink-fairy-armadillo-crawls-out-of-the-desert-and-into-our-hearts/',
                    }
                ],
            },
        },
    ],

};

export default weirdAnimals;