
import React from 'react';
import { makeStyles, ButtonBase } from '@material-ui/core';
import { Link } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  btn: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: 30,
    paddingRight: 30,
    marginBottom: theme.spacing(5),
    backgroundColor: ({ backgroundColor }) => backgroundColor || '#888',
    borderRadius: 50,
    color: '#fff',
    "&:hover":{
      backgroundColor:({color}) =>"rgb(240, 240, 240)",
      
      },
    "&:active": {
       backgroundColor:({color}) =>  "rgb(240, 240, 240)",
       boxShadow: "0 1px #666",
       transform: "translateY(4px)"
     },
    '& > *': {
    },
  },
  btnFocusVisible: {
    outline: "2px solid blue",
  },
  linkBtn: {
    textDecoration: 'none',
  }
}));

const LinkBtn = ({ to, children, btnCss }) => {
  const classes = useStyles();
  return (
    <Link to={to || ''} className={classes.linkBtn}>
      <Btn css={btnCss}>
        { children }
      </Btn>
    </Link>
  );
};

const Btn = ({ children, onClick, css }) => {
  const classes = useStyles(css);

  return (
    <ButtonBase
      onClick={onClick}
      className={classes.btn}
      focusRipple="true"
      focusVisibleClassName={classes.btnFocusVisible}
    >
      {children}
    </ButtonBase>
  );
};

export { LinkBtn };
export default Btn;
