 const bidensInfrastructurePlan = {
    name: "Biden's Infrastructure Plan",  
    id: 'ZhHvXwnSmgUw0A0gUmVc',
    linkTo: "/quizzes/bidensInfrastructurePlan",
    explainerOn: true,
    description: "President Biden has proposed an 8 year $2 trillion infrastructure plan, calling it a “once-in-a-generation investment in America” as phase one of a larger $4 trillion economic plan. The question is, where exactly would this $2 trillion be spent?",
    questions: [
        {
            //question 1//
            question: "Over the plan’s 8 year span, how much would be spent on roads and bridges?",
            choices: ['$11,500,000', '$115,000,000', '$11,500,000,000', '$115,000,000,000'],
            answerIdx: 3,    
            furtherInfo: {
                explanation: (
                    `Biden’s infrastructure plan will invest $115 billion in fixing and modernizing roads and bridges.`
                ),
                sources: [
                    {
                        source: 'NY Times: Biden Details $2 Trillion Plan to Rebuild Infrastructure and Reshape the Economy. (July 2021)',
                        url: 'https://www.nytimes.com/2021/03/31/business/economy/biden-infrastructure-plan.html',
                    },
                ],
            },
        },
        {
            //question 2//
           question: "How much will be spent on public transit?",
            choices: ['$85,000,000', '$850,000,000', '$85,000,000,000', '$850,000,000,000'],
            answerIdx: 2,    
            furtherInfo: {
                explanation: (
                    `$85 billion of the plan’s budget will go toward expanding and updating public transit.`
                ),
                sources: [
                    {
                        source: 'NY Times',
                        url: 'https://www.nytimes.com/2021/03/31/business/economy/biden-infrastructure-plan.html',
                    },
                ],
            },
        },
        {
            //question 3//
            question: "How much will be spent on expanding high-speed broadband internet access for underserved communities?",
            choices: ['$100,000,000,000', '$10,000,000,000', '$100,000,000', '$10,000,000'],
            answerIdx: 0,    
            furtherInfo: {
                explanation: (
                    `$100 billion will be used to expand access to high-speed broadband internet access, with a focus on rural communities currently without access.`
                ),
                sources: [
                    {
                        source: 'NY Times',
                        url: 'https://www.nytimes.com/interactive/2021/03/31/upshot/whats-in-bidens-infrastructure-plan.html?action=click&module=RelatedLinks&pgtype=Article',
                    },
                ],
            },
        },
        {
            //question 4//
            question: "How much will be spent on water infrastructure?",
            choices: ['$11,100,000', '$111,000,000', '$11,100,000,000', '$111,000,000,000'],
            answerIdx: 3,    
            furtherInfo: {
                explanation: (
                    `$111 billion will be spent on updating the current water infrastructure, with $45 billion earmarked specifically for removing lead pipes.`
                ),
                sources: [
                    {
                        source: 'NY Times',
                        url: 'https://www.nytimes.com/2021/03/31/business/economy/biden-infrastructure-plan.html',
                    },
                ],
            },
        },
        {
            //question 5//
            question: "How much will go toward affordable housing?",
            choices: ['$213,000,000,000', '$21,300,000,000', '$213,000,000', '$21,300,000'],
            answerIdx: 0,    
            furtherInfo: {
                explanation: (
                    `Biden’s plan will invest in affordable housing at an estimated cost of $213 billion.`
                ),
                sources: [
                    {
                        source: 'NY Times',
                        url: 'https://www.nytimes.com/interactive/2021/03/31/upshot/whats-in-bidens-infrastructure-plan.html?action=click&module=RelatedLinks&pgtype=Article',
                    },
                ],
            },
        },
        {
            //question 6//
            question: "How much will be spent on updating and modernizing the electric grid and clean energy?",
            choices: ['$17,400,000', '$174,000,000', '$17,400,000,000', '$174,000,000,000'],
            answerIdx: 3,    
            furtherInfo: {
                explanation: (
                    `A total of $174 billion will go toward updating and modernizing the electric grid as well as clean energy incentives.`
                ),
                sources: [
                    {
                        source: 'NY Times',
                        url: 'https://www.nytimes.com/2021/03/31/business/economy/biden-infrastructure-plan.html',
                    },
                ],
            },
        },
        {
            //question 7//
            question: "How many miles of roads does President Biden promise will be fixed through his plan’s funding?",
            choices: ['2,000', '20,000', '200,000', '2,000,000'],
            answerIdx: 1,    
            furtherInfo: {
                explanation: (
                    `President Biden has promised 20,000 miles of roads will be fixed with this plan.`
                ),
                sources: [
                    {
                        source: 'NY Times',
                        url: 'https://www.nytimes.com/2021/03/31/business/economy/biden-infrastructure-plan.html',
                    },
                ],
            },
        },
        {
            //question 8//
            question: "How many bridges will be fixed?",
            choices: ['100', '1,000', '10,000', '100,000'],
            answerIdx: 2,    
            furtherInfo: {
                explanation: (
                    `10,000 smaller bridges, mostly rural, are slated to be fixed as well as the 10 most economically important bridges in the country.`
                ),
                sources: [
                    {
                        source: 'NY Times',
                        url: 'https://www.nytimes.com/2021/03/31/business/economy/biden-infrastructure-plan.html',
                    },
                ],
            },
        },
        {
            //question 9//
            question: "At a population of approximately 330 million, if everyone were to pay the same amount to fund President Biden’s $2 trillion plan for infrastructure, about how much would each American pay over the 8 year period?",
            choices: ['$6,000', '$60,000', '$600,000', '$6,000,000'],
            answerIdx: 0,    
            furtherInfo: {
                explanation: (
                    `If the cost of Biden’s $2 trillion plan were spread evenly, each American would pay approximately $6,000. Over the 8 year spending period that would be about $760 a year.`
                ),
                sources: [
                    {
                        source: 'The United States Census Bureau',
                        url: 'https://www.census.gov/popclock/',
                    },
                ],
            },
        },
        {
            //question 10//
            question: "How does President Biden actually propose funding his plan?",
            choices: ['Implementing across the board wage cuts for elected officials', 'Raising taxes on the middle class', 'Raising the corporate tax rate from 21% to 28%', 'Cutting the Department of Defense budget'],
            answerIdx: 2,    
            furtherInfo: {
                explanation: (
                    `President plans to fund his $2 trillion plan largely by raising corporate tax rates, which he says will offset the spending costs over a 15 year period.`
                ),
                sources: [
                    {
                        source: 'NY Times',
                        url: 'https://www.nytimes.com/2021/03/31/business/economy/biden-infrastructure-plan.html',
                    },
                ],
            },
        },
    ],

};

export default bidensInfrastructurePlan;