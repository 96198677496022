import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { useStorage, useFirestore } from "reactfire";
import useNotificationSystem from "../../hooks/useNotificationSystem";
import { updatePhotoUrl as updateFirestorePhotoUrl } from "../../firestore/user";

import SelectAvatar from "./SelectAvatar";

// NOTE Be sure to sync file limit with CloudStorage rules
const FILE_LIMIT_MB = 10; // MB
const PROFILE_UPLOAD_ERROR = "Profile image could not be updated";

const useStyles = makeStyles((theme) => (
  {
    dropdown: {
      width: "90vw",
      paddingTop: theme.spacing(2),
      borderRadius: 9,
      backgroundColor: "#767bf7",
      color: "#fff",
      border: "1px solid #979797",
      textAlign: "center",
      [theme.breakpoints.up("md")]: {
        width: 600,
      },
    },
    header: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      marginBottom: theme.spacing(1),
      "& > *": {
        textAlign: "center",
      },
    },
    form: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      "& > *": {
        marginBottom: theme.spacing(2),
      },
    },
    upload: ({buttonDefault}) => Object.assign(
      {
        display: "flex",
        width: "90%",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
      },
      buttonDefault
    ),
    uploadInput: {
      height: 0.1,
      width: 0.1,
      opacity: 0,
    },
    uploadCaption: {
      fontSize: 12,
      textAlign: "center",
    },
    button: ({ buttonDefault } ) => Object.assign(
      {
        width: "90%",
        paddingTop: 10,
        paddingBottom: 10,
        fontSize: 16,
      },
      buttonDefault
    ),
    submitButton: ( { buttonDefault } ) => Object.assign(
      {
        width: "90%",
        fontSize: 16,
        cursor: "pointer",
      },
      buttonDefault
    ),
    closeButton: {
      border: 0,
      background: "none",
      color: "#fff",
      cursor: "pointer",
    },
}));

const RmPhotoBtn = ({ user, setPhotoURL, addNotification, onClose, classes }) => {
  const handleClickRmPhoto = (e) => {
    e.preventDefault();
    user
      .updateProfile({
        photoURL: "",
      })
      .then(() => {
        setPhotoURL("");
        addNotification({
          message: "Profile image removed",
          level: "success",
        });

        if (onClose) {
          onClose();
        }
      })
      .catch((error) => {
        addNotification({
          message: PROFILE_UPLOAD_ERROR,
          level: "error",
        });
      });
  };

  return (
    <button onClick={handleClickRmPhoto} className={classes.button}>
      Remove current photo
    </button>
  );
};

const Header = ({ classes, onClose }) => {
  return (
    <div className={classes.header}>
      <div></div>
      <button onClick={onClose} className={classes.closeButton}>
        X
      </button>
    </div>
  );
};

const EditPhoto = ({ photoURL, setPhotoURL, user, onClose }) => {
  const dropdownStyles = {
    buttonDefault: {
      borderColor: "#fff",
      borderRadius: 5,
      borderWidth: 1,
      borderStyle: "solid",
      paddingTop: 10,
      paddingBottom: 10,
      backgroundColor: "#464994", // https://www.color-hex.com/color/464994
      color: "#fff",
      cursor: "pointer",
      "&:active": {
        backgroundColor: "#585b9e",
      },
      "&:focus": {
        outline: "2px solid #fff",
      }
    }
  }
  const [isUsingAvatar, setIsUsingAvatar] = useState(false);
  const classes = useStyles(dropdownStyles);
  const storage = useStorage();
  const firestore = useFirestore();
  const profilePicUpload = useRef(null);
  const { addNotification } = useNotificationSystem();

  const handleChange = (e) => {
    if (isUsingAvatar) setIsUsingAvatar(false);
    setPhotoURL(URL.createObjectURL(e.target.files[0]));
  };

  const updateUserPhotoURL = (newPhotoURL) => {
    return user.updateProfile({
      photoURL: newPhotoURL,
    });
  };

  const isValidFileSize = (fileSize) => {
    const fileLimit = FILE_LIMIT_MB * 1024 * 1024; // Bytes
    return fileSize <= fileLimit;
  };

  const handleAvatarClick = (url) => {
    setIsUsingAvatar(true);
    setPhotoURL(url);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isUsingAvatar) {
      return updatePhotoUrlOnly();
    }

    const file = profilePicUpload.current.files[0];

    if (!file) {
      const error = `No avatar change detected`;
      addNotification({
        message: error,
        level: "error",
      });
      return;
    }

    if (!isValidFileSize(file.size)) {
      const error = `File is larger than ${FILE_LIMIT_MB} MB`;
      addNotification({
        message: error,
        level: "error",
      });
      return;
    }

    const storageRef = storage.ref();
    const profilePicRef = storageRef.child(`profilePics/${user.uid}`);

    let updatedPhotoUrl;
    profilePicRef
      .put(file)
      // couldn't use await for some reason since access token would not be correct
      .then(() => profilePicRef.getDownloadURL())
      .then((newPhotoURL) => {
        updatedPhotoUrl = newPhotoURL;
        return updateUserPhotoURL(newPhotoURL);
      })
      .then(() => {
        return updateFirestorePhotoUrl(firestore, user.uid, updatedPhotoUrl);
      })
      .then(() => {
        addNotification({
          message: "Profile image updated",
          level: "success",
        });

        if (onClose) {
          onClose();
        }
      })
      .catch((error) => {
        addNotification({
          message: PROFILE_UPLOAD_ERROR,
          level: "error",
        });
      });
  };

  const updatePhotoUrlOnly = async () => {
    await updateUserPhotoURL(photoURL);
    await updateFirestorePhotoUrl(firestore, user.uid, photoURL);

    addNotification({
      message: "Profile image updated",
      level: "success",
    });

    if (onClose) {
      onClose();
    }
  };

  const acceptedFileTypes = () => {
    const jpegFileType = "image/jpeg, .jpg, .jpeg, .jfif, .pjpeg, .pjp";
    const pngFileType = "image/png, .png";
    const webPFileType = "image/webp, .webp";

    return `${jpegFileType}, ${pngFileType}, ${webPFileType}`;
  };

  const handleCloseClick = () => {
    if (onClose) {
      onClose({ resetPhotoURL: true });
    }
  };

  return (
    <div className={classes.dropdown}>
      <Header classes={classes} onClose={handleCloseClick} />
      <form onSubmit={handleSubmit} className={classes.form}>
        <label className={classes.upload}>
          <input
            type="file"
            className={classes.uploadInput}
            onChange={handleChange}
            ref={profilePicUpload}
            accept={acceptedFileTypes()}
          />
          <span className={classes.uploadButton}>Change Profile Photo</span>
          <div className={classes.uploadCaption}>
            File must be less than {FILE_LIMIT_MB} Mb
          </div>
        </label>
        <div style={{ width: "100%" }}>
          <RmPhotoBtn
            user={user}
            setPhotoURL={setPhotoURL}
            addNotification={addNotification}
            onClose={onClose}
            classes={classes}
          />
        </div>
        <div>
          <div>- OR -</div>
          <div>Select an icon from the following</div>
        </div>
        <SelectAvatar storage={storage} onAvatarClick={handleAvatarClick} />
        <button type="submit" className={classes.submitButton}>
          SAVE CHANGES
        </button>
      </form>
    </div>
  );
};

EditPhoto.propTypes = {
  setPhotoURL: PropTypes.func,
  onClose: PropTypes.func,
  user: PropTypes.shape({
    uid: PropTypes.string,
  }).isRequired,
};

export default EditPhoto;
