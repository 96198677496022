import { useState, useEffect } from "react";
import { useAuth, useUser, useFirestore } from "reactfire";
import { getUserByUid } from "../firestore/user";

// Combining both auth user and firestore user data
// Why I had to write like this see
// https://github.com/FirebaseExtended/reactfire/discussions/202
function useMergedUser(setStateFn) {
  const auth = useAuth();
  const firestore = useFirestore();
  const authUser = useUser(auth).data;
  const [gettingUser, setGettingUser] = useState(false);
  const [mergedUser, setMergedUser] = useState({
    firestoreUser: {},
    authUser: {},
  });

  useEffect(function getUserFromFirestore() {
    if (!authUser || gettingUser) {
      return;
    }

    setGettingUser(true);
    getUserByUid(firestore, authUser.uid).then((doc) => {
      if (doc.exists) {
        const newMergedUser = { authUser, firestoreUser: doc.data() };

        if (setStateFn) {
          setStateFn(newMergedUser);
        }

        setMergedUser(newMergedUser);
      } else {
        setMergedUser({ authUser });
      }
    });
  }, [gettingUser, firestore, authUser, setStateFn]);

  return mergedUser;
}

export default useMergedUser;
