import React from "react";
import { StoreProvider } from "../../contexts/store";
import { mixpanelInit } from "../../utils/mixpanel";

function initialStoreState() {
  return {
    soundOn: true,
    mixpanel: mixpanelInit(),
    notificationSystem: null,
  };
}

function reducer(state, action) {
  switch (action.type) {
    case "toggleSound":
      return {
        ...state,
        soundOn: !state.soundOn,
      };
    case "setNotificationSystem":
      return {
        ...state,
        notificationSystem: action.payload,
      };
    default:
      return state;
  }
}

export default function RootStoreWrapper({ children }) {
  return (
    <StoreProvider initialState={initialStoreState()} reducer={reducer}>
      {children}
    </StoreProvider>
  );
}
