const dd4SupportingSinks = {
  name: 'Drawdown 4: Supporting Sinks',
  id: '9oEng1ABaTQyXFdRfnPA',
  linkTo: '/quizzes/dd4-supporting-sinks',
  explainerOn: true,
  subheading: `When it comes to climate change, it's Sink or Swim!`,
  description: "<b>Natural carbon sinks</b>, or natural processes that absorb and store atmospheric carbon, are our biggest ally in combating climate change. But they’re also in danger of being uprooted. Supporting and amplifying our natural carbon sinks is key if we want to reach drawdown.",
  questions: [
      {
          //question 1//
          question: "What percentage of greenhouse gas (GHG) emissions are absorbed by natural sinks?",
          choices: ['11%', '41%', '71%', '91%'],
          answerIdx: 1,    
          furtherInfo: {
              explanation: (
                  `Natural carbon sinks are already absorbing <b>41%</b> of GHG emissions, which still leaves 59% to build up in the atmosphere.`
              ),
              sources: [
                  {
                      source: 'Project Drawdown: Climate Solutions 101',
                      url: 'https://drawdown.org/climate-solutions-101/unit-4-supporting-sinks-and-improving-society',
                  },
              ],
          },
      },
      {
          //question 2//
         question: "<b>Land sinks</b> absorb what percentage of human-caused emissions?",
          choices: ['6%', '26%', '56%', '96%'],
          answerIdx: 1,    
          furtherInfo: {
              explanation: (
                  `Of the 41% of GHG emissions absorbed by natural carbon sinks, <i>land sinks</i> are responsible for <b>26%</b>.`
              ),
              sources: [
                  {
                      source: 'Project Drawdown: Climate Solutions 101',
                      url: 'https://drawdown.org/climate-solutions-101/unit-4-supporting-sinks-and-improving-society',
                  },
              ],
          },
      },
      {
          //question 3//
          question: "Coastal and ocean sinks absorb what percentage of emissions?",
          choices: ['17%', '27%', '57%', '77%'],
          answerIdx: 0,    
          furtherInfo: {
              explanation: (
                  `Of the 41% of GHG emissions absorbed by natural carbon sinks, coastal and ocean sinks are responsible for <b>17%</b>.`
              ),
              sources: [
                  {
                      source: 'Project Drawdown: Climate Solutions 101',
                      url: 'https://drawdown.org/climate-solutions-101/unit-4-supporting-sinks-and-improving-society',
                  },
              ],
          },
      },
      {
          //question 4//
          question: "Where do forests store sequestered carbon?",
          choices: ['In their biomass', 'In their soil', 'In the surrounding water', 'Both A and B'],
          answerIdx: 3,    
          furtherInfo: {
              explanation: (
                  `Forests store sequestered carbon in <i>both</i> their <b>biomass</b> (such as the bark of trees), and in the <b>soil</b>.`
              ),
              sources: [
                  {
                      source: 'Project Drawdown: Climate Solutions 101',
                      url: 'https://drawdown.org/climate-solutions-101/unit-4-supporting-sinks-and-improving-society',
                  },
              ],
          },
      },
      {
          //question 5//
          question: "Protecting ecosystems reduces carbon emissions.",
          choices: ['True', 'False'],
          answerIdx: 0,    
          furtherInfo: {
              explanation: (
                  `<b>This is true.</b> By protecting ecosystems, activities that cause carbon emissions are prevented and carbon sinks are preserved.`
              ),
              sources: [
                  {
                      source: 'Project Drawdown: Climate Solutions 101',
                      url: 'https://drawdown.org/climate-solutions-101/unit-4-supporting-sinks-and-improving-society',
                  },
              ],
          },
      },
      {
          //question 6//
          question: "The protection of <i>which ecosystem</i> has the largest effect on GHG emissions?",
          choices: ['Salt marshes', 'Forests', 'Peatland', 'Grasslands'],
          answerIdx: 2,    
          furtherInfo: {
              explanation: (
                  `For all that protecting forests and planting trees have become the bywords of climate solutions, studies show that the <b>protection of peatlands</b> has the <i>largest</i> effect on GHG emissions.`
              ),
              sources: [
                  {
                      source: 'Project Drawdown: Climate Solutions 101',
                      url: 'https://drawdown.org/climate-solutions-101/unit-4-supporting-sinks-and-improving-society',
                  },
              ],
          },
      },
      {
          //question 7//
          question: "Which coastal wetland ecosystems sequester large amounts of carbon?",
          choices: ['mangroves', 'salt marshes', 'seagrasses', 'all of the above'],
          answerIdx: 3,    
          furtherInfo: {
              explanation: (
                  `Mangroves, salt marshes, and seagrasses <i>all</i> have the capability of sequestering large amounts of carbon.`
              ),
              sources: [
                  {
                      source: 'Project Drawdown: Climate Solutions 101',
                      url: 'https://drawdown.org/climate-solutions-101/unit-4-supporting-sinks-and-improving-society',
                  },
              ],
          },
      },
      {
          //question 8//
          question: "Absorbing more carbon dioxide has caused the ocean to become more ______?",
          choices: ['acidic', 'basic', 'salty', 'buoyant'],
          answerIdx: 0,    
          furtherInfo: {
              explanation: (
                  `Absorbing more carbon dioxide has caused the world's oceans to become more <b>acidic</b>.`
              ),
              sources: [
                  {
                      source: 'Project Drawdown: Climate Solutions 101',
                      url: 'https://drawdown.org/climate-solutions-101/unit-4-supporting-sinks-and-improving-society',
                  },
              ],
          },
      },
      {
          //question 9//
          question: "What is the purpose of tree plantations on degraded land?",
          choices: ['To restore soil', 'To sequester carbon', 'To produce wood sustainably', 'All of the above'],
          answerIdx: 3,    
          furtherInfo: {
              explanation: (
                  `The purpose of putting tree plantations on degraded land is to restore the soil, sequester carbon, and produce wood sustainably.`
              ),
              sources: [
                  {
                      source: 'Project Drawdown: Climate Solutions 101',
                      url: 'https://drawdown.org/climate-solutions-101/unit-4-supporting-sinks-and-improving-society',
                  },
              ],
          },
      },
      {
          //question 10//
          question: "Carbon is the fundamental building block of all living things.",
          choices: ['True', 'False'],
          answerIdx: 0,    
          furtherInfo: {
              explanation: (
                  `<b>This is true.</b> All living things on Earth are carbon-based.`
              ),
              sources: [
                  {
                      source: 'Project Drawdown: Climate Solutions 101',
                      url: 'https://drawdown.org/climate-solutions-101/unit-4-supporting-sinks-and-improving-society',
                  },
              ],
          },
      },
  ],
};

export default dd4SupportingSinks;