 const isItScience = {
    id: "isItScience",
    name: "What is Science?",
    linkTo: "/quizzes/isItScience",
    description: 'Test your grasp of science basics!',
    questions: [
        {
            question: "Science can be described as:",
            choices: ['A constant refinement on how we understand the world around us', 'A journey of trial and error, half-seen truths, and outright mistakes', 'Seeing what everyone else has seen but thinking what nobody else has thought', 'Being a blind person looking for a black cat in a dark room', 'All of the above'],
            answerIdx: 4,    
            furtherInfo: {
            },
        },
        {
            question: "“Science” comes from the Latin word scientia, which means:",
            choices: ['Facts', 'Understanding', 'Knowledge', 'Truth'],
            answerIdx: 2,    
            furtherInfo: {
            },
        },
        {
            question: "Good science requires good evidence. An example of good evidence is:",
            choices: ['An expert’s opinion.', 'A shared experience with 100 eyewitnesses.', 'A personal anecdote, remembered flawlessly 20 years later.', 'Experimental results that can be independently verified and reproduced.'],
            answerIdx: 3,    
            furtherInfo: {
            },
        },
        {
            question: "A hypothesis is:",
            choices: ['An educated guess.', 'A conclusion.', 'A flaw in a scientist’s research methods.', 'A running joke in certain astronomy departments involving black holes and gin.'],
            answerIdx: 0,    
            furtherInfo: {
            },
        },
        {
            question: "The scientific method is:",
            choices: ['An established canon of great thinkers, whose ideas still direct our research today.', 'A procedure for making discoveries about the world through observation and experimentation to test a hypothesis.', 'Applicable only to technical disciplines', 'The strict coursework required for PhD candidates in the natural sciences'],
            answerIdx: 1,    
            furtherInfo: {
            },
        },
        {
            question: "Who is often referred to as the father of the scientific method?",
            choices: ['Kevin Bacon', 'Francis Bacon', 'Mary Bacon', 'Roger Bacon'],
            answerIdx: 1,    
            furtherInfo: {
            },
        },
        {
            question: " _________ focus/ focuses on finding evidence to confirm an established hypothesis, disregarding evidence to the contrary.",
            choices: ['Peer-reviewed articles', 'Cherry picking', 'A doctoral thesis', 'Astrology'],
            answerIdx: 1,    
            furtherInfo: {
            },
        },
        {
            question: "True or False? An experiment is a question which science poses to Nature, and a measurement is the recording of Nature's answer.",
            choices: ['True', 'False'],
            answerIdx: 0,    
            furtherInfo: {
            },
        },
        {
            question: "True or False? If a scientific theory is disproved, an alternative theory ISN’T automatically considered true. At the same time, proving one theory does not necessarily disprove a competing theory.",
            choices: ['True', 'False'],
            answerIdx: 0,    
            furtherInfo: {
            },
        },
        {
            question: "Good science encourages:",
            choices: ['Other scientists to poke holes in your idea, in hope to expose a flaw.', 'Admitting your mistakes, your potential conflicts of interest, and times when you don’t know the right answer.', 'Asking too many questions.', 'Putting truth ahead of everything, especially personal or group advantages', 'None of the above.', 'All of the above.'],
            answerIdx: 5,    
            furtherInfo: {
            },
        },
    ],

};

export default isItScience;
