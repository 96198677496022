const f35PriceTag = {
    id: "f35PriceTag",
    name: `How much is an F-35 jet?`,
    linkTo: "/quizzes/f35PriceTag",
    explainerOn: true,
    subheading: `An over-budget, behind schedule mistake, or the next phase of modern warfare? Is the F-35 fighter jet worth its trillion dollar price tag?`,
    description: `Over twenty years old, Lockheed-Martin's F-35 fighter jet program has consistently run over-budget and behind schedule. Still, the lure of cutting-edge technology and joint branch utility seems to have lured the U.S. military into continuing to fund its ever-increasing cost. But is the F-35 really worth it?`,
    questions: [
        { // Q-1
            question: "What is the projected lifetime cost of the F-35 fighter program? ",
            choices: ['$1.7 billion', '$17 billion', '$170 billion', '$1.7 trillion'],
            answerIdx: 3,
            furtherInfo: {
                explanation: (
                    `The lifetime cost of the F-35 fighter program is currently projected to be $1.7 trillion, making it the most expensive weapons program in U.S. history.`
                ),
                sources: [
                    {
                        source: "Forbes",
                        url: 'https://www.forbes.com/sites/sebastienroblin/2021/07/31/f-35a-jet-price-to-rise-but-its-sustainment-costs-that-could-bleed-air-force-budget-dry/',
                    },
                ],
            },
        },
        { // Q-2
            question: "In 1996, how much was Lockheed-Martin awarded by the Pentagon to develop the prototype that would go on to be known as the F-35?",
            choices: ['$78,000', '$780,000', '$7,800,000', '$78,000,000'],
            answerIdx: 3,
            furtherInfo: {
                explanation: (
                    `In 1996 the Pentagon awarded both Lockheed-Martin and Boeing $78 million to develop new prototypes, which were then test-flown five years later. Lockheed-Martin's prototype won out and the F-35 program was born.`
                ),
                sources: [
                    {
                        source: "The Nation",
                        url: 'https://www.thenation.com/article/archive/f35-fighter-jet-pentagon/',
                    },
                ],
            },
        },
        { // Q-3
            question: "How many years elapsed between the F-35's first flight and the first time it saw action?",
            choices: ['3', '13', '23', '33'],
            answerIdx: 1,
            furtherInfo: {
                explanation: (
                    `13 years elapsed between the F-35's first flight in 2006 and its first use in combat airstrikes in 2019, where two of the aircraft were reportedly used to take out an ISIS tunnel network and weapons cache in Iraq.`
                ),
                sources: [
                    {
                        source: "Defense News",
                        url: 'https://www.defensenews.com/air/2019/04/30/us-air-force-conducts-airstrikes-with-f-35-for-first-time-ever/',
                    },
                ],
            },
        },
        { // Q-4
            question: "The F-35 flew one combat sortie per plane, every three days, while deployed in the Middle East. What was an official quote (paraphrased) from a former Pentagon chief testing official about the new fighter?",
            choices: [
                `“The F-35 performed less than desirably”`,
                `“The fighter met all expectations and we're very proud.”`,
                `“If there were not other fighters up there, this plane would not survive.”`,
                `"The F-35 will revolutionize aerial combat."`,
            ],
            answerIdx: 2,
            furtherInfo: {
                explanation: (
                    `In 2018 the Marines deployed just six F-35s in the Middle East. Having flown approximately one combat sortie per F-35 every three days during this deployment, the Pentagon's former chief testing official concluded that if not for the protection of other planes, the F-35s would not survive contact with opposition forces.`
                ),
                sources: [
                    {
                        source: "The Nation",
                        url: 'https://www.thenation.com/article/archive/f35-fighter-jet-pentagon/',
                    },
                ],
            },
        },
        { // Q-5
            question: "How many F-35s is the US Military planning to order?",
            choices: ['250', '2,500', '25,000', '250,000'],
            answerIdx: 1,
            furtherInfo: {
                explanation: (
                    ` The U.S. military had plans to buy 2,500 F-35s by 2037, though notably the Air Force recently proposed a 10% cut of its planned F-35 purchases.`
                ),
                sources: [
                    {
                        source: "Air Force Magazine",
                        url: 'https://www.airforcemag.com/usaf-to-cut-f-35-buy-in-future-years-defense-plan/',
                    },
                ],
            },
        },
        { // Q-6
            question: 'After 17 years, what percent "ready" was the F-35?',
            choices: ['11%', '31%', '51%', '71%'],
            answerIdx: 0,
            furtherInfo: {
                explanation: (
                    `After 17 years of production and testing, the F-35 achieved a fully mission capable rate of just 11%. For context, the desired rate before going into full production is 80% or higher.`
                ),
                sources: [
                    {
                        source: "The Nation",
                        url: 'https://www.thenation.com/article/archive/f35-fighter-jet-pentagon/',
                    },
                ],
            },
        },
        { // Q-7
            question: "What alternatives to the F-35 were the US Military considering?",
            choices: [
                'Building older F-18s designed in the 1980s.',
                'Building older F-18s designed in the 1970s.',
                'Both of these.',
                'Neither of these.'
            ],
            answerIdx: 2,
            furtherInfo: {
                explanation: (
                    `As an alternative to the increasingly expensive and underperforming F-35s, the U.S. military has considered building older F-18s from designs dating back both to the 1980s and the 1970s.`
                ),
                sources: [
                    {
                        source: "The Nation",
                        url: 'https://www.thenation.com/article/archive/f35-fighter-jet-pentagon/',
                    },
                ],
            },
        },
        { // Q-8
            question: "The original target for the F-35 program was $38 million per plane. What is the actual cost per plane?",
            choices: ['$78 million', '$100 million', '$122 million', '$158 million'],
            answerIdx: 0,
            furtherInfo: {
                explanation: (
                    `In recent years, the F-35's unit cost <i>has</i> declined to $78 million per plane. However, this number has been disputed and it fails to account for each plane's sustainment costs, which are notoriously high.`
                ),
                sources: [
                    {
                        source: "Forbes",
                        url: 'https://www.forbes.com/sites/sebastienroblin/2021/07/31/f-35a-jet-price-to-rise-but-its-sustainment-costs-that-could-bleed-air-force-budget-dry/',
                    },
                ],
            },
        },
        { // Q-9
            question: "The F-35's agility in air-to-air combat, when compared to older planes like the F-16, is:",
            choices: ['Much better', 'Slightly better', 'About equal', 'Worse'],
            answerIdx: 3,
            furtherInfo: {
                explanation: (
                    `When the air-to-air combat agility of the F-35 is tested against older planes like the F-16, the F-35's performance rates as much worse.`
                ),
                sources: [
                    {
                        source: "The Nation",
                        url: 'https://www.thenation.com/article/archive/f35-fighter-jet-pentagon/',
                    },
                ],
            },
        },
        { // Q-10
            question: "If the projected $1.7 trillion lifetime cost of the F-35 program was spread equally across the approximately 145 million U.S. taxpayers, about how much would each taxpayer pay for the F-35?",
            choices: ['$1,000', '$3,000', '$6,000', '$12,000'],
            answerIdx: 3,
            furtherInfo: {
                explanation: (
                    `If each of the approximately 145 million U.S taxpayers payed an equal amount of the $1.7 trillion cost of the F-35 program, each taxpayer would pay about $12,000.`
                ),
                sources: [
                    {
                        source: "The Nation",
                        url: 'https://www.thenation.com/article/archive/f35-fighter-jet-pentagon/',
                    },
                ],
            },
        },
    ],
  
  };
  
  export default f35PriceTag;
