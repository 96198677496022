const environmentalRacism = {
    name: 'Air Pollution & Environmental Racism',
    id: 'Iw193r74ABANGSTOdkkU',
    linkTo: "/quizzes/environmentalRacism",
    explainerOn: true,
    description: 'Climate change and pollution are billed as global problems that affect everyone. However, studies are finding that some communities are more affected than others.',
    questions: [
        {
            //question 1//
            question: "Particulate Matter (PM) 2.5 micrometers in diameter could account for as many as _________ premature U.S. deaths per year.",
            choices: ['50,000', '100,000', '150,000', '200,000'],
            answerIdx: 3,    
            furtherInfo: {
                explanation: (
                    `PM 2.5 micrometers in diameter, which can become embedded in the lungs, could account for up to 200,000 premature deaths in the U.S. annually.`
                ),
                sources: [
                    {
                        source: "The Washington Post: 'Deadly air pollutant ‘disproportionately and systematically’ harms Americans of color, study finds'",
                        url: 'https://www.washingtonpost.com/climate-environment/2021/04/28/environmental-justice-pollution/?wpmk=1&wpisrc=al_news__alert-hse--alert-national&utm_source=alert&utm_medium=email&utm_campaign=wp_news_alert_revere&location=alert&pwapi_token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJjb29raWVuYW1lIjoid3BfY3J0aWQiLCJpc3MiOiJDYXJ0YSIsImNvb2tpZXZhbHVlIjoiNTk3ODkyNDlhZTdlOGE2ODE2ZWE5NGZhIiwidGFnIjoid3BfbmV3c19hbGVydF9yZXZlcmUiLCJ1cmwiOiJodHRwczovL3d3dy53YXNoaW5ndG9ucG9zdC5jb20vY2xpbWF0ZS1lbnZpcm9ubWVudC8yMDIxLzA0LzI4L2Vudmlyb25tZW50YWwtanVzdGljZS1wb2xsdXRpb24vP3dwbWs9MSZ3cGlzcmM9YWxfbmV3c19fYWxlcnQtaHNlLS1hbGVydC1uYXRpb25hbCZ1dG1fc291cmNlPWFsZXJ0JnV0bV9tZWRpdW09ZW1haWwmdXRtX2NhbXBhaWduPXdwX25ld3NfYWxlcnRfcmV2ZXJlJmxvY2F0aW9uPWFsZXJ0In0.A_QZ90toadriPn-oqJ1HjX-e3OtSVi_AxEk3fcDya00',
                    },
                ],
            },
        },
        {
            //question 2//
            question: "Americans facing the greatest exposure to PM 2.5 are _________ .",
            choices: ['Poor Hispanic and Middle Eastern Americans, and those who have recently immigrated', 'Black, Hispanic and Asian Americans of all income levels', 'Poor White and Eastern European Americans', 'Black, Pacific Islander, and Asian Americans of all income levels'],
            answerIdx: 1,    
            furtherInfo: {
                explanation: (
                    `Black, Hispanic, and Asian Americans of all income levels face the greatest exposure to PM 2.5.`
                ),
                sources: [
                    {
                        source: "The Washington Post: 'Deadly air pollutant ‘disproportionately and systematically’ harms Americans of color, study finds'",
                        url: 'https://www.washingtonpost.com/climate-environment/2021/04/28/environmental-justice-pollution/?wpmk=1&wpisrc=al_news__alert-hse--alert-national&utm_source=alert&utm_medium=email&utm_campaign=wp_news_alert_revere&location=alert&pwapi_token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJjb29raWVuYW1lIjoid3BfY3J0aWQiLCJpc3MiOiJDYXJ0YSIsImNvb2tpZXZhbHVlIjoiNTk3ODkyNDlhZTdlOGE2ODE2ZWE5NGZhIiwidGFnIjoid3BfbmV3c19hbGVydF9yZXZlcmUiLCJ1cmwiOiJodHRwczovL3d3dy53YXNoaW5ndG9ucG9zdC5jb20vY2xpbWF0ZS1lbnZpcm9ubWVudC8yMDIxLzA0LzI4L2Vudmlyb25tZW50YWwtanVzdGljZS1wb2xsdXRpb24vP3dwbWs9MSZ3cGlzcmM9YWxfbmV3c19fYWxlcnQtaHNlLS1hbGVydC1uYXRpb25hbCZ1dG1fc291cmNlPWFsZXJ0JnV0bV9tZWRpdW09ZW1haWwmdXRtX2NhbXBhaWduPXdwX25ld3NfYWxlcnRfcmV2ZXJlJmxvY2F0aW9uPWFsZXJ0In0.A_QZ90toadriPn-oqJ1HjX-e3OtSVi_AxEk3fcDya00',
                    },
                ],
            },
        },
        {
            //question 3//
            question: " _________ Americans are exposed to above average concentrations of all categories in the EPA’s emissions inventory.",
            choices: ['Black', 'Hispanic', 'Asian', 'None of the above'],
            answerIdx: 0,    
            furtherInfo: {
                explanation: (
                    `Black Americans are exposed to above average concentrations of every category in the EPA’s emissions inventory.`
                ),
                sources: [
                    {
                        source: "The Washington Post: 'Deadly air pollutant ‘disproportionately and systematically’ harms Americans of color, study finds'",
                        url: 'https://www.washingtonpost.com/climate-environment/2021/04/28/environmental-justice-pollution/?wpmk=1&wpisrc=al_news__alert-hse--alert-national&utm_source=alert&utm_medium=email&utm_campaign=wp_news_alert_revere&location=alert&pwapi_token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJjb29raWVuYW1lIjoid3BfY3J0aWQiLCJpc3MiOiJDYXJ0YSIsImNvb2tpZXZhbHVlIjoiNTk3ODkyNDlhZTdlOGE2ODE2ZWE5NGZhIiwidGFnIjoid3BfbmV3c19hbGVydF9yZXZlcmUiLCJ1cmwiOiJodHRwczovL3d3dy53YXNoaW5ndG9ucG9zdC5jb20vY2xpbWF0ZS1lbnZpcm9ubWVudC8yMDIxLzA0LzI4L2Vudmlyb25tZW50YWwtanVzdGljZS1wb2xsdXRpb24vP3dwbWs9MSZ3cGlzcmM9YWxfbmV3c19fYWxlcnQtaHNlLS1hbGVydC1uYXRpb25hbCZ1dG1fc291cmNlPWFsZXJ0JnV0bV9tZWRpdW09ZW1haWwmdXRtX2NhbXBhaWduPXdwX25ld3NfYWxlcnRfcmV2ZXJlJmxvY2F0aW9uPWFsZXJ0In0.A_QZ90toadriPn-oqJ1HjX-e3OtSVi_AxEk3fcDya00',
                    },
                ],
            },
        },
        {
            //question 4//
            question: "How much more PM 2.5 pollution are Black people exposed to than the average?",
            choices: ['11%', '21%', '31%', '41%'],
            answerIdx: 1,    
            furtherInfo: {
                explanation: (
                    `Black Americans are exposed to 21% more PM 2.5 pollution than average.`
                ),
                sources: [
                    {
                        source: "The Washington Post: 'Deadly air pollutant ‘disproportionately and systematically’ harms Americans of color, study finds'",
                        url: 'https://www.washingtonpost.com/climate-environment/2021/04/28/environmental-justice-pollution/?wpmk=1&wpisrc=al_news__alert-hse--alert-national&utm_source=alert&utm_medium=email&utm_campaign=wp_news_alert_revere&location=alert&pwapi_token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJjb29raWVuYW1lIjoid3BfY3J0aWQiLCJpc3MiOiJDYXJ0YSIsImNvb2tpZXZhbHVlIjoiNTk3ODkyNDlhZTdlOGE2ODE2ZWE5NGZhIiwidGFnIjoid3BfbmV3c19hbGVydF9yZXZlcmUiLCJ1cmwiOiJodHRwczovL3d3dy53YXNoaW5ndG9ucG9zdC5jb20vY2xpbWF0ZS1lbnZpcm9ubWVudC8yMDIxLzA0LzI4L2Vudmlyb25tZW50YWwtanVzdGljZS1wb2xsdXRpb24vP3dwbWs9MSZ3cGlzcmM9YWxfbmV3c19fYWxlcnQtaHNlLS1hbGVydC1uYXRpb25hbCZ1dG1fc291cmNlPWFsZXJ0JnV0bV9tZWRpdW09ZW1haWwmdXRtX2NhbXBhaWduPXdwX25ld3NfYWxlcnRfcmV2ZXJlJmxvY2F0aW9uPWFsZXJ0In0.A_QZ90toadriPn-oqJ1HjX-e3OtSVi_AxEk3fcDya00',
                    },
                ],
            },
        },
        {
            //question 5//
            question: "How much more PM 2.5 pollution are Asian Americans exposed to than the average?",
            choices: ['8%', '12%', '16%', '18%'],
            answerIdx: 3,    
            furtherInfo: {
                explanation: (
                    `Asian Americans are exposed to 18% more PM 2.5 pollution than average.`
                ),
                sources: [
                    {
                        source: "The Washington Post: 'Deadly air pollutant ‘disproportionately and systematically’ harms Americans of color, study finds'",
                        url: 'https://www.washingtonpost.com/climate-environment/2021/04/28/environmental-justice-pollution/?wpmk=1&wpisrc=al_news__alert-hse--alert-national&utm_source=alert&utm_medium=email&utm_campaign=wp_news_alert_revere&location=alert&pwapi_token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJjb29raWVuYW1lIjoid3BfY3J0aWQiLCJpc3MiOiJDYXJ0YSIsImNvb2tpZXZhbHVlIjoiNTk3ODkyNDlhZTdlOGE2ODE2ZWE5NGZhIiwidGFnIjoid3BfbmV3c19hbGVydF9yZXZlcmUiLCJ1cmwiOiJodHRwczovL3d3dy53YXNoaW5ndG9ucG9zdC5jb20vY2xpbWF0ZS1lbnZpcm9ubWVudC8yMDIxLzA0LzI4L2Vudmlyb25tZW50YWwtanVzdGljZS1wb2xsdXRpb24vP3dwbWs9MSZ3cGlzcmM9YWxfbmV3c19fYWxlcnQtaHNlLS1hbGVydC1uYXRpb25hbCZ1dG1fc291cmNlPWFsZXJ0JnV0bV9tZWRpdW09ZW1haWwmdXRtX2NhbXBhaWduPXdwX25ld3NfYWxlcnRfcmV2ZXJlJmxvY2F0aW9uPWFsZXJ0In0.A_QZ90toadriPn-oqJ1HjX-e3OtSVi_AxEk3fcDya00',
                    },
                ],
            },
        },
        {
            //question 6//
            question: "How much more PM 2.5 pollution are Hispanics exposed to than the average?",
            choices: ['11%', '21%', '31%', '41%'],
            answerIdx: 0,    
            furtherInfo: {
                explanation: (
                    `Hispanics are exposed to 11% more PM 2.5 pollution than average.`
                ),
                sources: [
                    {
                        source: "The Washington Post: 'Deadly air pollutant ‘disproportionately and systematically’ harms Americans of color, study finds'",
                        url: 'https://www.washingtonpost.com/climate-environment/2021/04/28/environmental-justice-pollution/?wpmk=1&wpisrc=al_news__alert-hse--alert-national&utm_source=alert&utm_medium=email&utm_campaign=wp_news_alert_revere&location=alert&pwapi_token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJjb29raWVuYW1lIjoid3BfY3J0aWQiLCJpc3MiOiJDYXJ0YSIsImNvb2tpZXZhbHVlIjoiNTk3ODkyNDlhZTdlOGE2ODE2ZWE5NGZhIiwidGFnIjoid3BfbmV3c19hbGVydF9yZXZlcmUiLCJ1cmwiOiJodHRwczovL3d3dy53YXNoaW5ndG9ucG9zdC5jb20vY2xpbWF0ZS1lbnZpcm9ubWVudC8yMDIxLzA0LzI4L2Vudmlyb25tZW50YWwtanVzdGljZS1wb2xsdXRpb24vP3dwbWs9MSZ3cGlzcmM9YWxfbmV3c19fYWxlcnQtaHNlLS1hbGVydC1uYXRpb25hbCZ1dG1fc291cmNlPWFsZXJ0JnV0bV9tZWRpdW09ZW1haWwmdXRtX2NhbXBhaWduPXdwX25ld3NfYWxlcnRfcmV2ZXJlJmxvY2F0aW9uPWFsZXJ0In0.A_QZ90toadriPn-oqJ1HjX-e3OtSVi_AxEk3fcDya00',
                    },
                ],
            },
        },
        {
            //question 7//
            question: "How much PM 2.5 pollution are White people exposed to compared to the average?",
            choices: ['8% less', '12% less', '16% less', '18% less'],
            answerIdx: 0,    
            furtherInfo: {
                explanation: (
                    `In contrast, white Americans are exposed to 8% less PM 2.5 pollution than average.`
                ),
                sources: [
                    {
                        source: "The Washington Post: 'Deadly air pollutant ‘disproportionately and systematically’ harms Americans of color, study finds'",
                        url: 'https://www.washingtonpost.com/climate-environment/2021/04/28/environmental-justice-pollution/?wpmk=1&wpisrc=al_news__alert-hse--alert-national&utm_source=alert&utm_medium=email&utm_campaign=wp_news_alert_revere&location=alert&pwapi_token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJjb29raWVuYW1lIjoid3BfY3J0aWQiLCJpc3MiOiJDYXJ0YSIsImNvb2tpZXZhbHVlIjoiNTk3ODkyNDlhZTdlOGE2ODE2ZWE5NGZhIiwidGFnIjoid3BfbmV3c19hbGVydF9yZXZlcmUiLCJ1cmwiOiJodHRwczovL3d3dy53YXNoaW5ndG9ucG9zdC5jb20vY2xpbWF0ZS1lbnZpcm9ubWVudC8yMDIxLzA0LzI4L2Vudmlyb25tZW50YWwtanVzdGljZS1wb2xsdXRpb24vP3dwbWs9MSZ3cGlzcmM9YWxfbmV3c19fYWxlcnQtaHNlLS1hbGVydC1uYXRpb25hbCZ1dG1fc291cmNlPWFsZXJ0JnV0bV9tZWRpdW09ZW1haWwmdXRtX2NhbXBhaWduPXdwX25ld3NfYWxlcnRfcmV2ZXJlJmxvY2F0aW9uPWFsZXJ0In0.A_QZ90toadriPn-oqJ1HjX-e3OtSVi_AxEk3fcDya00',
                    },
                ],
            },
        },
        {
            //question 8//
            question: "Black, Hispanic and Asian Americans are most often exposed to higher than usual levels of PM 2.5 through:",
            choices: ['Industry', 'Both light-duty vehicles and diesel-powered heavy trucks', 'Construction', 'Any or all of the above'],
            answerIdx: 3,    
            furtherInfo: {
                explanation: (
                    `Black, Hispanic, and Asian Americans are exposed to higher levels of PM 2.5 through all of the methods mentioned above.`
                ),
                sources: [
                    {
                        source: "The Washington Post: 'Deadly air pollutant ‘disproportionately and systematically’ harms Americans of color, study finds'",
                        url: 'https://www.washingtonpost.com/climate-environment/2021/04/28/environmental-justice-pollution/?wpmk=1&wpisrc=al_news__alert-hse--alert-national&utm_source=alert&utm_medium=email&utm_campaign=wp_news_alert_revere&location=alert&pwapi_token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJjb29raWVuYW1lIjoid3BfY3J0aWQiLCJpc3MiOiJDYXJ0YSIsImNvb2tpZXZhbHVlIjoiNTk3ODkyNDlhZTdlOGE2ODE2ZWE5NGZhIiwidGFnIjoid3BfbmV3c19hbGVydF9yZXZlcmUiLCJ1cmwiOiJodHRwczovL3d3dy53YXNoaW5ndG9ucG9zdC5jb20vY2xpbWF0ZS1lbnZpcm9ubWVudC8yMDIxLzA0LzI4L2Vudmlyb25tZW50YWwtanVzdGljZS1wb2xsdXRpb24vP3dwbWs9MSZ3cGlzcmM9YWxfbmV3c19fYWxlcnQtaHNlLS1hbGVydC1uYXRpb25hbCZ1dG1fc291cmNlPWFsZXJ0JnV0bV9tZWRpdW09ZW1haWwmdXRtX2NhbXBhaWduPXdwX25ld3NfYWxlcnRfcmV2ZXJlJmxvY2F0aW9uPWFsZXJ0In0.A_QZ90toadriPn-oqJ1HjX-e3OtSVi_AxEk3fcDya00',
                    },
                ],
            },
        },
        {
            //question 9//
            question: "Compared with White people, people of color are _____ more likely to live in a county with a failing grade for at least one pollutant, and _____ more likely to live in a county with a failing grade for three.",
            choices: ['61%, 300%', '21%, 90%', '11%, 51%', '21%, 11%'],
            answerIdx: 0,    
            furtherInfo: {
                explanation: (
                    `In the U.S., people of color are 61% more likely to live in a county with a failing grade for at least one pollutant, and 300% more likely to live in a county with a failing grade for three.`
                ),
                sources: [
                    {
                        source: "The Washington Post: 'Deadly air pollutant ‘disproportionately and systematically’ harms Americans of color, study finds'",
                        url: 'https://www.washingtonpost.com/climate-environment/2021/04/28/environmental-justice-pollution/?wpmk=1&wpisrc=al_news__alert-hse--alert-national&utm_source=alert&utm_medium=email&utm_campaign=wp_news_alert_revere&location=alert&pwapi_token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJjb29raWVuYW1lIjoid3BfY3J0aWQiLCJpc3MiOiJDYXJ0YSIsImNvb2tpZXZhbHVlIjoiNTk3ODkyNDlhZTdlOGE2ODE2ZWE5NGZhIiwidGFnIjoid3BfbmV3c19hbGVydF9yZXZlcmUiLCJ1cmwiOiJodHRwczovL3d3dy53YXNoaW5ndG9ucG9zdC5jb20vY2xpbWF0ZS1lbnZpcm9ubWVudC8yMDIxLzA0LzI4L2Vudmlyb25tZW50YWwtanVzdGljZS1wb2xsdXRpb24vP3dwbWs9MSZ3cGlzcmM9YWxfbmV3c19fYWxlcnQtaHNlLS1hbGVydC1uYXRpb25hbCZ1dG1fc291cmNlPWFsZXJ0JnV0bV9tZWRpdW09ZW1haWwmdXRtX2NhbXBhaWduPXdwX25ld3NfYWxlcnRfcmV2ZXJlJmxvY2F0aW9uPWFsZXJ0In0.A_QZ90toadriPn-oqJ1HjX-e3OtSVi_AxEk3fcDya00',
                    },
                ],
            },
        },
        {
            //question 10//
            question: "People of color make up 40% of the U.S. population, yet about 60% of those live within three miles of _________ .",
            choices: ['The 13 oil and gas refineries that release harmful levels of benzene, a cancer-causing pollutant.', 'The 50 most heavily used freeways, which means almost 10x the risk of asthma and severe allergies.', 'California regions severely endangered by toxic wildfire smoke.', 'A chemical refinery releasing harmful particulate pollution and ozone.'],
            answerIdx: 0,    
            furtherInfo: {
                explanation: (
                    `While people of color make up 40% of the total U.S. population, they make up 60% of the population of people residing near the 13 oil and gas refineries that release harmful levels of benzene, a cancer-causing pollutant.`
                ),
                sources: [
                    {
                        source: "The Washington Post: 'Deadly air pollutant ‘disproportionately and systematically’ harms Americans of color, study finds'",
                        url: 'https://www.washingtonpost.com/climate-environment/2021/04/28/environmental-justice-pollution/?wpmk=1&wpisrc=al_news__alert-hse--alert-national&utm_source=alert&utm_medium=email&utm_campaign=wp_news_alert_revere&location=alert&pwapi_token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJjb29raWVuYW1lIjoid3BfY3J0aWQiLCJpc3MiOiJDYXJ0YSIsImNvb2tpZXZhbHVlIjoiNTk3ODkyNDlhZTdlOGE2ODE2ZWE5NGZhIiwidGFnIjoid3BfbmV3c19hbGVydF9yZXZlcmUiLCJ1cmwiOiJodHRwczovL3d3dy53YXNoaW5ndG9ucG9zdC5jb20vY2xpbWF0ZS1lbnZpcm9ubWVudC8yMDIxLzA0LzI4L2Vudmlyb25tZW50YWwtanVzdGljZS1wb2xsdXRpb24vP3dwbWs9MSZ3cGlzcmM9YWxfbmV3c19fYWxlcnQtaHNlLS1hbGVydC1uYXRpb25hbCZ1dG1fc291cmNlPWFsZXJ0JnV0bV9tZWRpdW09ZW1haWwmdXRtX2NhbXBhaWduPXdwX25ld3NfYWxlcnRfcmV2ZXJlJmxvY2F0aW9uPWFsZXJ0In0.A_QZ90toadriPn-oqJ1HjX-e3OtSVi_AxEk3fcDya00',
                    },
                ],
            },
        },
    ],

};

export default environmentalRacism;