import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import ArrowBackIosSharpIcon from "@material-ui/icons/ArrowBackIosSharp";

const useStyles = makeStyles((theme) => ({
  button: {
    backgroundColor: "transparent",
    border: 0,
    padding: 0,
    cursor: "pointer",
    color:"rgba(255,255,255,1)",
    "& :hover":{
      color:"rgba(255,255,255,0.7)"
    },
  },
}));

export default ({ onClick, onKeyDown }) => {
  const classes = useStyles();

  return (
    <button
      className={classes.button}
      onClick={onClick} 
      onKeyDown={onKeyDown}
      aria-label="Back"
    >
      <ArrowBackIosSharpIcon />
    </button>
  );
};
