import React from "react";
import PropTypes from "prop-types";
import { makeStyles, ButtonBase } from "@material-ui/core";
import useWindowHeight from "../utils/useWindowDimensions";
import Typography from "@material-ui/core/Typography";
import ArrowBackIosSharpIcon from '@material-ui/icons/ArrowBackIosSharp';
// import BackButton from "./BackButton";
import Box from "@material-ui/core/Box";
import Modal from "@material-ui/core/Modal";
import ReactMarkdown from "react-markdown";
import SourceList from "./question/SourceList";
import { Link, useHistory } from "react-router-dom";
import FeedbackButton from "./FeedbackButton";

const useStyles = makeStyles((theme) => ({
  root: ({ appearance, windowHeight }) => ({
    background: "#3124FB",
    backgroundSize: "cover",
    minHeight: "100vh",
  }),
  thanks: {
    marginLeft: "15%",
    width: "65%",
    [theme.breakpoints.up("sm")]: {
      width: "75%",
    },
    [theme.breakpoints.up("md")]: {
      width: "85%",
    },
  },
  review: {
    color: "#fff",
    fontFamily: "Helvetica Neue",
    fontSize: "30px",
    fontWeight: "600",
    [theme.breakpoints.up("md")]: {
      fontSize: "32px",
    },
    marginBottom: "10px",
    padding: "0 20px",
  },
  score: {
    paddingBottom: theme.spacing(2),
    color: "#fff",
  },
  explanationButtton: {
    fontSize: "18px",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
      fontWeight: "600",
    },
      "&:hover":{
        color:  "#d7d7d7d7",
      },

  },
  feedbackButton: {
    alignSelf: "flex-end",
    padding: theme.spacing(2),
    backgroundColor: "#00e8d8",
    borderRadius: 50,
    marginRight: "5%",
    marginBottom: theme.spacing(5),
  },
  feedbackButtonText: {
    color: "#fff",
    textDecoration: "none",
    "& > *": {
      fontFamily: "Open Sans",
    },
  },
  submissionArea: {
    textAlign: "center",
  },
  homeButton: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: 30,
    paddingRight: 30,
    marginBottom: theme.spacing(3),
    borderBottom: "2px solid white",
    width: "100%",
  },
  homeButtonText: {
    color: "#FFFFFF",
    textDecoration: "none",
    "& > *": {
      fontFamily: "hotel-solid,sans-serif",
      fontWeight: "900",
    },
    [theme.breakpoints.down("xs")]: {
      "& > *": {
        fontWeight: "600",
        fontSize:"40px"
      },
    },
  },
  missedQuestionsContainer: {
    width: "100%",
    padding: "30px 50px",
    // border: "2px solid brown",
    display: "grid",
    gridGap: "30px",
    gridTemplateColumns: "repeat(auto-fit, minmax(400px, 1fr))",
    boxSizing: "border-box",
    [theme.breakpoints.down("xs")]: {
      padding: "10px 30px 50px 30px",
      gridTemplateColumns: "1fr",
    },
    "& *": {
      boxSizing: "border-box",
    },
  },
  missedQuestionWrapper: {
    border: "2px solid white",
    paddingTop: "15px",
    borderRadius: "50px",
    position: "relative",
    minHeight: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    overflow: "hidden",
    background: "#465cff",
  },
  missedQuestion: {
    padding: 10,
    width: "100%",
    "& > h3": {
      color: "blue",
      background: "white",
    },
  },
  missedQuestionContentWrapper: {
    padding: 20,
    paddingBottom: 0,
    borderRadius: "50px",
    marginBottom: "10px",
    "& h4": {
      color: "#fff",
      margin: 0,
      fontSize: 20,
    },
  },
  missedQuestionFooterWrapper: {
    background: "#fff",
    borderTopLeftRadius: "30px",
    borderTopRightRadius: "30px",
    border: "1px solid #fff",
    width: "100%",

    "& h3": {
      fontSize: "22px",
      // fontWeight: "600",
    },
    "& p": {
      fontWeight: 500,
      fontSize: "20px",
    },
    [theme.breakpoints.down("xs")]: {
      "& p": {
        fontWeight: 600,
        fontSize: "20px",
      },
    },
  },
  missedQuestionFooter: {
    padding: 20,
    "& h3": {
      color: "#001EFF",
    },
    "& p": {
      paddingLeft: 10,
      color: "#000",
    },
  },
  missedQuestionActions: {
    borderTop: "1px solid #7D7D7D",
    padding: 20,
    textAlign: "center",

    "& button": {
      background: "#fff",
      border: "none",
      color: "#000",
      padding: "5px 10px",
      fontSize: "20px",
    },
  },
  explanationBox: {
    background: "#fff",
    width: "100%",
    height: "100%",
    zIndex: "10",
  },
  closeBtn: {
    position: "absolute",
    right: "20px",
    fontSize: "30px",
    top: "20px",
    lineHeight: "0",
    cursor: "pointer",
    border: "none",
    backgroundColor: "transparent",
  },
  explainText: {
    fontSize: 18,
    padding: "0 20px",
    marginTop: "10px",
    background: "#fff",
    color: "#3d405b",
    borderRadius: "30px",
  },
  deepDiveQuestion: {
    fontSize: 18,
    padding: "20px",
    margin: "25px 0 10px 0",
    background: "#3124FB",
    color: "#fff",
    borderRadius: "30px",
  },
  paper: {
    position: "absolute",
    width: 600,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #fff",
    boxShadow: theme.shadows[5],
    borderRadius: "10px",
    padding: "15px 15px 30px 15px",
    height: "fit-content",
    [theme.breakpoints.down("xs")]: {
      width: "95%",
      padding: "10px",
      marginTop: "10px",
      height: "90vh",
      overflowY: "scroll",
    },
  },
  expandExplainText: {
    color: "#3d405b",
    margin: "0",
    fontWeight: "600",
    "& > p": {
      margin: 0,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "21px",
      "& > p": {
        margin: 0,
        fontWeight: "600",
      },
    },
  },
  expandExplainHead: {
    paddingTop: "20px",
    color: "#3124FB",
    fontWeight: "600",
  },
  deepDiveHeading: {
    fontSize: "24px",
    color: "#101eff",
    margin: "10px 0 20px 0",
    fontWeight: "600",
  },
  questionText: {
    fontSize: "20px",
    fontWeight: 500,
    fontFamily: "Helvetica Neue",
    letterSpacing: "0.5px",
    color: "#fff",
    width: "auto",
    lineHeight: "30px",
    padding: "10px 0",
    "& > span": {
      textAlign: "left",
      fontWeight: "500",
      fontSize: "20px",
    },
    "& > h4": {
      fontFamily: "Open Sans",
      textAlign: "center",
      fontWeight: "fontWeightMedium",
    },
    [theme.breakpoints.down("sm")]: {
      "& > span": {
        textAlign: "left",
        fontWeight: "500",
        fontSize: "23px",
      },
    },
  },
  backButton:{
    color:"#fff",
    backgroundColor: "transparent",
    border: 0,
    padding: 0,
    position:"absolute",
    left:"50px",
    top:"25px",
    zIndex:1,
    cursor:"pointer",
    "& :hover":{
      color:"rgba(255,255,255,0.7)"
    },
    [theme.breakpoints.down("xs")]: {
      fontSize:"25px",
      left:"15px",
      top:"32px",
    }
  },
  backArrow:{
    fontSize:"40px",
    [theme.breakpoints.down("xs")]: {
      fontSize:"25px",
    }
  },
  linkStyle:{
   fontSize:"18px",
   fontWeight:"700",
   color:"#0000FF",
    [theme.breakpoints.down("xs")]: {
      fontSize: "21px",
    },
  },
}));

function Review(props) {
  const { appearance, /*name*/ } = props;
  const history = useHistory()

  const classes = useStyles({
    appearance: appearance,
    windowHeight: useWindowHeight(),
  });

 const goBack = () => {
  //  console.log(props)
   history.goBack()
 }

  const getLastQuizQuestions = () => {
    try {
      return JSON.parse(localStorage.getItem("lastQuizQuestions"));
    } catch (e) {
      return [];
    }
  };

  const incorrectQuestions = getLastQuizQuestions().filter((c) => !c.correct);

  const IncorrectQuestion = ({ classes, question, appearance }) => {
    const [showExplanation, setShowExplanation] = React.useState(false);
    const correctAnswer = question.correctRange
      ? `Value should be between ${question.correctRange.join(" and ")}`
      : question.writeInAnswer || question.choices[question.answerIdx];
    return (
      <div className={classes.missedQuestionWrapper}>
        {showExplanation ? (
          <ExplanationContent
            question={question.question}
            classes={classes}
            appearance={appearance}
            explanation={question.furtherInfo.explanation}
            correctAnswer={correctAnswer}
            sources={question.furtherInfo.sources}
            showExplanation={showExplanation}
            onClose={(e) => setShowExplanation(false)}
          />
        ) : null}
        <div className={classes.missedQuestionContentWrapper}>
          <h4>Question</h4>
          <div className={classes.questionText}>
          <span dangerouslySetInnerHTML={{__html: question.question}}></span>
          </div>
        </div>
        <div className={classes.missedQuestionFooterWrapper}>
          <div className={classes.missedQuestionFooter}>
            <h3>Correct answer</h3>
            <p>{correctAnswer}</p>
          </div>
          <div className={classes.missedQuestionActions}>
            {question.furtherInfo && question.furtherInfo.explanation && (
              <button
                onClick={(e) => setShowExplanation(true)}
                className={classes.explanationButtton}
              >
                Dive Deeper
              </button>
            )}
          </div>
        </div>
      </div>
    );
  };

  const incorrectQuestionsComps = incorrectQuestions.map((question, index) => {
    return (
      <IncorrectQuestion
        key={index}
        question={question}
        classes={classes}
        appearance={appearance}
      />
    );
  });

  return (
    <div className={classes.root}>
      <FeedbackButton />
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <Box display="flex" justifyContent="center" width="100%" >
          <button className={classes.backButton} onClick={() => goBack()} aria-label="Back">
            <ArrowBackIosSharpIcon className={classes.backArrow}/>
          </button>
          <ButtonBase className={classes.homeButton}>
            <Link to="/quizzes" className={classes.homeButtonText}>
              <Typography variant="h2" component="p">
                QUIZICIST
              </Typography>
            </Link>
          </ButtonBase>
        </Box>

        <Box display="flex" justifyContent="center">
          <Typography variant="h2" component="p" className={classes.review}>
            Review Missed Questions
          </Typography>
        </Box>
        <div className={classes.missedQuestionsContainer}>
          {incorrectQuestionsComps}
        </div>

        {/* <ButtonBase className={classes.feedbackButton}>
          <a
            href="https://forms.gle/zW9CCAmShbMwF6dq6"
            target="_blank"
            rel="noopener noreferrer"
            className={classes.feedbackButtonText}
          >
            <Typography variant="h4" component="p">
              Feedback
            </Typography>
          </a>
        </ButtonBase> */}
        {/* <Btn onClick={onPlayAgainClick}>
          <Typography variant="h2" component="p">
            Play Again
          </Typography>
        </Btn> */}
      </Box>
    </div>
  );
}

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const ExplanationContent = ({
  explanation,
  question,
  sources,
  appearance,
  correctAnswer,
  classes,
  showExplanation,
  onClose,
}) => {
  const [modalStyle] = React.useState(getModalStyle);

  return (
    <Modal
      open={showExplanation}
      onClose={onClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div style={modalStyle} className={classes.paper}>
        <div className={classes.explanationBox}>
          <button onClick={onClose} className={classes.closeBtn} aria-label="Close">
            &times;
          </button>
          <div className={classes.deepDiveQuestion}>
            <Typography
              variant="h5"
              align="left"
              className={classes.sourceHeader}
            >
              Question:
            </Typography>
            <div className={classes.questionText}>
              {/* <ReactMarkdown source={question} /> */}
              <span dangerouslySetInnerHTML={{__html: question}}></span>
            </div>
          </div>
          <div className={classes.explainText}>
            <Typography
              variant="h5"
              align="left"
              className={classes.expandExplainHead}
            >
              Answer:
            </Typography>
            <span className={classes.expandExplainText}>{correctAnswer}</span>
            <Typography
              variant="h5"
              align="left"
              className={classes.expandExplainHead}
            >
              Explanation:
            </Typography>
               <ReactMarkdown
         parserOptions={{ pedantic: true }}
         escapeHtml={false}
         source={explanation}
         className={classes.expandExplainText}
         />

            {sources && (
              <div className={classes.expandExplainText}>
                <Typography
                  variant="h5"
                  align="left"
                  className={classes.expandExplainHead}
                >
                  Sources:
                </Typography>

                <SourceList
                  linkStyle={classes.linkStyle}
                  appearance={appearance}
                  sourceData={sources}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default Review;

navigator.propTypes = {
  appearance: PropTypes.object.isRequired,
};
