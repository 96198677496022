 const modernAlchemy = {
    name: 'Modern Alchemy: Element 113',
    id: 'XUeizNMXWjjiqybnNL20',
    linkTo: "/quizzes/modernAlchemy",
    explainerOn: true,
    description: 'They’re not trying to turn lead into gold or chase immortality with the mythical Philosopher’s Stone, but physicists are engaged in a new kind of transmutation. Namely, converting one element into another. One such project resulted in the superheavy Element 113.',
    questions: [
        {
            //question 1//
            question: "How many elements are currently on the Periodic Table?",
            choices: ['18', '118', '1,180', '11,800'],
            answerIdx: 1,    
            furtherInfo: {
                explanation: (
                    `The Periodic Table currently contains 118 elements.`
                ),
                sources: [
                    {
                        source: 'BBC. The modern alchemists racing to create a new element. BBC Future.',
                        url: 'https://www.bbc.com/future/article/20200204-the-modern-alchemists-racing-to-create-a-new-element',
                    },
                ],
            },
        },
        {
            //question 2//
            question: "Approximately how many elements have scientists posited may be able to exist?",
            choices: ['173', '1,730', '17,300', '173,000'],
            answerIdx: 0,    
            furtherInfo: {
                explanation: (
                    `Scientists have calculated that the Periodic Table may only be able to reach up to 173 before the nuclei become too unstable and begin to fall apart.`
                ),
                sources: [
                    {
                        source: 'BBC. The modern alchemists racing to create a new element. BBC Future.',
                        url: 'https://www.bbc.com/future/article/20200204-the-modern-alchemists-racing-to-create-a-new-element',
                    },
                ],
            },
        },
        {
            //question 3//
            question: "What is the highest number of protons present in elements naturally occurring on Earth?",
            choices: ['9', '92', '920', '9,200'],
            answerIdx: 1,    
            furtherInfo: {
                explanation: (
                    `The highest number of protons in any element naturally occurring on Earth is 92.`
                ),
                sources: [
                    {
                        source: 'BBC. The modern alchemists racing to create a new element. BBC Future.',
                        url: 'https://www.bbc.com/future/article/20200204-the-modern-alchemists-racing-to-create-a-new-element',
                    },
                ],
            },
        },
        {
            //question 4//
            question: "How large is the nucleus of an atom in centimeters?",
            choices: ['0.01', '0.0001', '0.0000001', '0.000000000001'],
            answerIdx: 3,    
            furtherInfo: {
                explanation: (
                    `The nucleus of an atom is one-trillionth of a centimeter.`
                ),
                sources: [
                    {
                        source: 'BBC. The modern alchemists racing to create a new element. BBC Future.',
                        url: 'https://www.bbc.com/future/article/20200204-the-modern-alchemists-racing-to-create-a-new-element',
                    },
                ],
            },
        },
        {
            //question 5//
            question: "Even if researchers do manage to make two nuclei collide, what is the chance of fusion?",
            choices: ['100:1', '100,000:1', '100,000,000:1', '100,000,000,000,000:1'],
            answerIdx: 3,    
            furtherInfo: {
                explanation: (
                    `When researchers are able to achieve a collision between two nuclei, the chance of fusion for those nuclei is only 100 trillion to 1.`
                ),
                sources: [
                    {
                        source: 'BBC. The modern alchemists racing to create a new element. BBC Future.',
                        url: 'https://www.bbc.com/future/article/20200204-the-modern-alchemists-racing-to-create-a-new-element',
                    },
                ],
            },
        },
        {
            //question 6//
            question: "When Element 113 was successfully synthesized, how long were its isotopes detectable in seconds?",
            choices: ['0.1', '0.01', '0.001', '0.0001'],
            answerIdx: 2,    
            furtherInfo: {
                explanation: (
                    `The isotopes of Element 113 were only detectable for 1 thousandth of a second.`
                ),
                sources: [
                    {
                        source: 'BBC. The modern alchemists racing to create a new element. BBC Future.',
                        url: 'https://www.bbc.com/future/article/20200204-the-modern-alchemists-racing-to-create-a-new-element',
                    },
                ],
            },
        },
        {
            //question 7//
            question: "At what fraction of the speed of light do these nuclei collide?",
            choices: ['0.1', '0.01', '0.001', '0.0001'],
            answerIdx: 0,    
            furtherInfo: {
                explanation: (
                    `When attempting to find a new element two nuclei are collided at one-tenth the speed of light.`
                ),
                sources: [
                    {
                        source: 'BBC. The modern alchemists racing to create a new element. BBC Future.',
                        url: 'https://www.bbc.com/future/article/20200204-the-modern-alchemists-racing-to-create-a-new-element',
                    },
                ],
            },
        },
        {
            //question 8//
            question: "Over a period of 9 years, how many collisions took place in order to successfully create Element 113 three times?",
            choices: ['4,000', '4,000,000', '4,000,000,000', '4,000,000,000,000'],
            answerIdx: 3,    
            furtherInfo: {
                explanation: (
                    `It took 4 trillion collisions over 9 years to create Element 113 just three times.`
                ),
                sources: [
                    {
                        source: 'BBC. The modern alchemists racing to create a new element. BBC Future.',
                        url: 'https://www.bbc.com/future/article/20200204-the-modern-alchemists-racing-to-create-a-new-element',
                    },
                ],
            },
        },
        {
            //question 9//
            question: "During the course of this project, head physicist Kosuke Morita regularly left how many yen as an offering when visiting a Shinto shrine?",
            choices: ['13', '113', '1,113', '11,300'],
            answerIdx: 1,    
            furtherInfo: {
                explanation: (
                    `A professed man of science, physicist Kosuke Morita left 113 yen at Shinto shrines regularly during this project.`
                ),
                sources: [
                    {
                        source: 'BBC. The modern alchemists racing to create a new element. BBC Future.',
                        url: 'https://www.bbc.com/future/article/20200204-the-modern-alchemists-racing-to-create-a-new-element',
                    },
                ],
            },
        },
        {
            //question 10//
            question: "What did Morita and his team decide to name Element 113?",
            choices: ['Oganesson', 'Gadolinium', 'Nihonium', 'Technetium'],
            answerIdx: 2,    
            furtherInfo: {
                explanation: (
                    `Morita’s team named Element 113, the first element discovered in Asia, Nihonium with the symbol Nh.`
                ),
                sources: [
                    {
                        source: 'BBC. The modern alchemists racing to create a new element. BBC Future.',
                        url: 'https://www.bbc.com/future/article/20200204-the-modern-alchemists-racing-to-create-a-new-element',
                    },
                ],
            },
        },
    ],

};

export default modernAlchemy;