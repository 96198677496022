const chemicalDisasters = {
  name: 'Chemical Disasters',
  id: 'SYgzNYxJWUczpdix1Yd8',
  linkTo: '/quizzes/chemical-disasters',
  explainerOn: true,
  subheading: `What can happen when chemicals get out of control in industrial settings?`,  
  description: "Of the wide array of possible industrial accidents, those involving chemicals are among the most harmful. Chemical disasters can last far beyond the initial incident, leaching chemicals into the environment and causing long-term effects to those caught in their radius.",
  questions: [
      {
          //question 1//
          question: "In 1921, how many metric tons of ammonium sulfate and ammonium nitrate fertilizer exploded at a plant in Oppau, Germany?",
          choices: ['4', '45', '450', '4,500'],
          answerIdx: 0,    
          furtherInfo: {
              explanation: (
                  `4,500 metric tons of ammonium sulfate and ammonium nitrate fertilizer exploded at a plant in Oppau, which is now a part of Ludwigshafen, Germany.`
              ),
              sources: [
                  {
                      source: 'World History Project',
                      url: 'https://worldhistoryproject.org/1921/9/21/oppau-explosion',
                  },
              ],
          },
      },
      {
          //question 2//
         question: "How many people died in the Oppau explosion?",
          choices: ['6', '60', '600', '6,000'],
          answerIdx: 2,    
          furtherInfo: {
              explanation: (
                  `As many as 600 people died in the Oppau explosion, and 2,000 more were injured.`
              ),
              sources: [
                  {
                      source: 'World History Project',
                      url: 'https://worldhistoryproject.org/1921/9/21/oppau-explosion',
                  },
              ],
          },
      },
      {
          //question 3//
          question: "In 1984, how many metric tons of methyl isocyanate gas was released into the air in Bhopal, India?",
          choices: ['2', '27', '270', '2,700'],
          answerIdx: 1,    
          furtherInfo: {
              explanation: (
                  `In 1984, 27 metric tons of methyl isocyanate gas, used in the production of pesticides at the local Union Carbide plant, were released into the air of Bhopal, India.`
              ),
              sources: [
                  {
                      source: 'The Bhopal Medical Appeal',
                      url: 'https://www.bhopal.org/continuing-disaster/the-bhopal-gas-disaster/union-carbides-disaster/',
                  },
              ],
          },
      },
      {
          //question 4//
          question: "To date, how many have died as a direct result of exposure to methyl isocyanate gas in the Bhopal disaster?",
          choices: ['25', '250', '2,500', '25,000'],
          answerIdx: 3,    
          furtherInfo: {
              explanation: (
                  `To date, 25,000 people have died as a direct result of their exposure to the methyl isocyanate gas released in Bhopal, India. 120,000 more continue to suffer ailments caused by their exposure.`
              ),
              sources: [
                  {
                      source: 'The Bhopal Medical Appeal',
                      url: 'https://www.bhopal.org/continuing-disaster/the-bhopal-gas-disaster/union-carbides-disaster/',
                  },
              ],
          },
      },
      {
          //question 5//
          question: "How many were killed in the series of explosions at the No.101 Petrochemical Plant in Jilin, China, in 2005?",
          choices: ['5', '50', '500', '5,000'],
          answerIdx: 0,    
          furtherInfo: {
              explanation: (
                  `5 were killed in the Jilin explosions, 70 more were injured, and over 10,000 residents of the city were evacuated and relocated.`
              ),
              sources: [
                  {
                      source: 'ChemEurope',
                      url: 'https://www.chemeurope.com/en/encyclopedia/2005_Jilin_chemical_plant_explosions.html',
                  },
              ],
          },
      },
      {
          //question 6//
          question: "How many metric tons of toxic chemicals were released into the Songhua River as a result of the 2005 Jilin explosions?",
          choices: ['9', '91', '910', '9,100'],
          answerIdx: 1,    
          furtherInfo: {
              explanation: (
                  `Approximately 91 metric tons of toxic chemicals, primarily benzene and nitrobenzene, were released into the Songhua River, creating an 80 km long toxic slick.`
              ),
              sources: [
                  {
                      source: 'ChemEurope',
                      url: 'https://www.chemeurope.com/en/encyclopedia/2005_Jilin_chemical_plant_explosions.html',
                  },
              ],
          },
      },
      {
          //question 7//
          question: "In 2013, about how many metric tons of ammonium nitrate exploded at a retail and distribution center for the West Fertilizer Company in Texas?",
          choices: ['45', '450', '4,500', '45,000'],
          answerIdx: 3,    
          furtherInfo: {
              explanation: (
                  `In 2013, about 45 metric tons of fertilizer grade ammonium nitrate exploded at a retail and distribution center for the West Fertilizer Company in West, Texas.`
              ),
              sources: [
                  {
                      source: 'CBS',
                      url: 'https://www.csb.gov/file.aspx?DocumentId=5983',
                  },
              ],
          },
      },
      {
          //question 8//
          question: "How many died as a result of the West, Texas fertilizer center explosion?",
          choices: ['5', '15', '150', '1,500'],
          answerIdx: 2,    
          furtherInfo: {
              explanation: (
                  `The West, Texas explosion killed 15 people, injured over 260, and damaged 150 off-site buildings.`
              ),
              sources: [
                  {
                      source: 'CBS',
                      url: 'https://www.csb.gov/file.aspx?DocumentId=5983',
                  },
              ],
          },
      },
      {
          //question 9//
          question: "In 2015, how many metric tons of ammonium nitrate exploded at a storage container station in Tianjin, China?",
          choices: ['8', '80', '800', '8,000'],
          answerIdx: 2,    
          furtherInfo: {
              explanation: (
                  `800 metric tons of ammonium nitrate exploded as the result of fire at a container storage station at the Port of Tianjin, which is equivalent to approximately 256 metric tons of TNT being detonated.`
              ),
              sources: [
                  {
                      source: 'Wikipedia',
                      url: 'https://en.wikipedia.org/wiki/2015_Tianjin_explosions#cite_note-Huang&Zhang2015-4',
                  },
              ],
          },
      },
      {
          //question 10//
          question: "How many died as a result of the 2015 Tianjin blasts?",
          choices: ['173', '1,730', '17,300', '173,000'],
          answerIdx: 0,    
          furtherInfo: {
              explanation: (
                  `173 people died as a result of the Tianjin blasts, 8 were reported missing, and 800 were injured.`
              ),
              sources: [
                  {
                      source: 'Wikipedia',
                      url: 'https://en.wikipedia.org/wiki/2015_Tianjin_explosions#cite_note-Huang&Zhang2015-4',
                  },
              ],
          },
      },
  ],
};

export default chemicalDisasters;