import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  sourceText: {
    fontSize: "20px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "18px",
    },
  },
}));

export default function SourceList({ appearance, sourceData,linkStyle }) {
  const classes = useStyles(appearance);
  return sourceData.map((sourceItem, idx) => {
    return (
      <span key={idx} className={classes.sourceText}>
        <a
          href={sourceItem.url}
          target="_blank"
          rel="noopener noreferrer"
          className={linkStyle}
        >
          {sourceItem.source}
        </a>
        {idx !== sourceData.length - 1 ? (
          <span>, </span>
        ) : (
          sourceData.length !== 1 && <span>.</span>
        )}
      </span>
    );
  });
}
