 const indiasSolarCanals = {
    name: "India's Solar Canals",
    id: 'ovSbS7apW13YNhWMxY9o',
    linkTo: "/quizzes/indiasSolarCanals",
    explainerOn: true,
    description: 'With growing concerns about climate change, there is an increasing demand for solar energy But this clean, renewable resource comes at a cost in land that can be difficult to pay. India, however, has found an innovative solution.',
    questions: [
        {
            //question 1//
            question: "India has a famously high population density with an average of _____ people per square kilometer.",
            choices: ['46', '464', '4,640', '46,400'],
            answerIdx: 1,    
            furtherInfo: {
                explanation: (
                    `India has one of the highest population densities in the world with an average of 464 people per square kilometer.`
                ),
                sources: [
                    {
                        source: "BBC. The 'solar canals' making smart use of India's space. BBC Future.",
                        url: 'https://www.bbc.com/future/article/20200803-the-solar-canals-revolutionising-indias-renewable-energy',
                    },
                ],
            },
        },
        {
            //question 2//
            question: "What percent of India’s power was generated by coal between 2018-2019?",
            choices: ['12%', '22%', '52%', '72%'],
            answerIdx: 3,    
            furtherInfo: {
                explanation: (
                    `Between the years 2018-2019 72% of India’s power was generated by coal.`
                ),
                sources: [
                    {
                        source: "BBC. The 'solar canals' making smart use of India's space. BBC Future.",
                        url: 'https://www.bbc.com/future/article/20200803-the-solar-canals-revolutionising-indias-renewable-energy',
                    },
                ],
            },
        },
        {
            //question 3//
            question: "How many sunny days does India see on average in a year?",
            choices: ['100', '150', '200', '300'],
            answerIdx: 3,    
            furtherInfo: {
                explanation: (
                    `On average, India sees about 300 sunny days a year, a full 136 more days of sunshine than Seattle, Washington’s annual average of 164 days of sunshine.`
                ),
                sources: [
                    {
                        source: "Current Results",
                        url: 'https://www.currentresults.com/Weather/Washington/annual-days-of-sunshine.php',
                    },
                ],
            },
        },
        {
            //question 4//
            question: "What was India’s solar capacity at the end of the first quarter of 2020 in gigawatts?",
            choices: ['7', '37', '370', '3,700'],
            answerIdx: 1,    
            furtherInfo: {
                explanation: (
                    `India’s solar capacity was at approximately 37GW by the end of the first quarter in 2020.`
                ),
                sources: [
                    {
                        source: "BBC. The 'solar canals' making smart use of India's space. BBC Future.",
                        url: 'https://www.bbc.com/future/article/20200803-the-solar-canals-revolutionising-indias-renewable-energy',
                    },
                ],
            },
        },
        {
            //question 5//
            question: "For scale, a single gigawatt is equal to approximately how much horsepower?",
            choices: ['130', '1,300', '13,000', '1,300,000'],
            answerIdx: 3,    
            furtherInfo: {
                explanation: (
                    `A single gigawatt is equivalent to 1.3 million horsepower or, to give another example, 110 million LED light bulbs.`
                ),
                sources: [
                    {
                        source: "US Department of Energy",
                        url: 'https://www.energy.gov/eere/articles/how-much-power-1-gigawatt',
                    },
                ],
            },
        },
        {
            //question 6//
            question: "In 2015, the first large-scale canal-top solar power plant was built in the Vadodara district of Gujarat State in India at a cost of how many US dollars?",
            choices: ['$183,000', '$1,830,000', '$18,300,000', '$183,000,000'],
            answerIdx: 2,    
            furtherInfo: {
                explanation: (
                    `It cost 18.3 million US dollars to install India’s first large-scale canal-top solar power plant.`
                ),
                sources: [
                    {
                        source: "BBC. The 'solar canals' making smart use of India's space. BBC Future.",
                        url: 'https://www.bbc.com/future/article/20200803-the-solar-canals-revolutionising-indias-renewable-energy',
                    },
                ],
            },
        },
        {
            //question 7//
            question: "How many kilometers of canals run through Gujarat overall?",
            choices: ['800', '8,000', '80,000', '800,000'],
            answerIdx: 2,    
            furtherInfo: {
                explanation: (
                    `80,000 kilometers of canals run through Gujarat, which is approximately 50,000 miles.`
                ),
                sources: [
                    {
                        source: "BBC. The 'solar canals' making smart use of India's space. BBC Future.",
                        url: 'https://www.bbc.com/future/article/20200803-the-solar-canals-revolutionising-indias-renewable-energy',
                    },
                ],
            },
        },
        {
            //question 8//
            question: "If 30% of Gujarat’s canals were converted to canal-top solar plants how many megawatts of power could be produced?",
            choices: ['1,800', '18,000', '180,000', '1,800,000'],
            answerIdx: 1,    
            furtherInfo: {
                explanation: (
                    `If just 30% of Gujarat’s canals were converted to canal-top solar plants 18,000MW could be produced, which would additionally save 90,000 acres of land that would otherwise need to be devoted to generating power.`
                ),
                sources: [
                    {
                        source: "BBC. The 'solar canals' making smart use of India's space. BBC Future.",
                        url: 'https://www.bbc.com/future/article/20200803-the-solar-canals-revolutionising-indias-renewable-energy',
                    },
                ],
            },
        },
        {
            //question 9//
            question: "The water flowing underneath the solar panels in canal-top solar plants cools the panels, increasing their efficiency by what percent?",
            choices: ['1%', '5%', '25%', '50%'],
            answerIdx: 1,    
            furtherInfo: {
                explanation: (
                    `Canal-top solar plants not only help reduce evaporation for the canal water, which is India’s main source of irrigation, but the water flowing underneath the solar panels cools them, thereby increasing the panels’ efficiency by up to 5%.`
                ),
                sources: [
                    {
                        source: "BBC. The 'solar canals' making smart use of India's space. BBC Future.",
                        url: 'https://www.bbc.com/future/article/20200803-the-solar-canals-revolutionising-indias-renewable-energy',
                    },
                ],
            },
        },
        {
            //question 10//
            question: "What is India’s target solar capacity in gigawatts by 2022?",
            choices: ['1', '10', '100', '1,000'],
            answerIdx: 2,    
            furtherInfo: {
                explanation: (
                    `Looking into the future, India is working to be at a solar capacity of 100GW by 2022.`
                ),
                sources: [
                    {
                        source: "BBC. The 'solar canals' making smart use of India's space. BBC Future.",
                        url: 'https://www.bbc.com/future/article/20200803-the-solar-canals-revolutionising-indias-renewable-energy',
                    },
                ],
            },
        },
    ],

};

export default indiasSolarCanals;