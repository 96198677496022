 const neanderthals = {
    name: 'Neanderthals',
    id: 'XoDQLj7f3b438UGrcfel',
    linkTo: "/quizzes/neanderthals",
    explainerOn: true,
    description: 'We are all familiar with the hulking, brutish image of our extinct cousins, the Neanderthals. With their protruding brows and stolid frames, it is easy for us to look back and congratulate ourselves as the more evolved branch of the family tree. But new evidence uncovered in Gorham’s cave in Gibraltar suggests that Neanderthals were more like us than we imagine.',
    questions: [
        {
            //question 1//
            question: "For how many years have modern humans existed?",
            choices: ['10,000', '100,000', '1,000,000', '10,000,000'],
            answerIdx: 1,    
            furtherInfo: {
                explanation: (
                    `Modern Humans, that’s you and me, have existed for approximately 100,000 years.`
                ),
                sources: [
                    {
                        source: 'BBC. How did the last Neanderthals live? BBC Future',
                        url: 'https://www.bbc.com/future/article/20200128-how-did-the-last-neanderthals-live',
                    },
                ],
            },
        },
        {
            //question 2//
            question: "How many years ago were three species of humans coexisting in Europe?",
            choices: ['4,000', '40,000', '400,000', '4,000,000'],
            answerIdx: 1,    
            furtherInfo: {
                explanation: (
                    `Just 40,000 years ago 3 distinct species of human were coexisting in Europe: Homo sapiens, Homo luzonensis, and Homo neanderthalensis.`
                ),
                sources: [
                    {
                        source: 'BBC News',
                        url: 'https://www.bbc.com/news/science-environment-47873072',
                    },
                ],
            },
        },
        {
            //question 3//
            question: "For how many years did Neanderthals exist before disappearing?",
            choices: ['3,000', '30,000', '300,000', '3,000,000'],
            answerIdx: 2,    
            furtherInfo: {
                explanation: (
                    `Neanderthals were an extremely resilient species that existed for approximately 300,000 years before dying out; that’s three times as long as modern humans have existed.`
                ),
                sources: [
                    {
                        source: 'BBC. How did the last Neanderthals live? BBC Future',
                        url: 'https://www.bbc.com/future/article/20200128-how-did-the-last-neanderthals-live',
                    },
                ],
            },
        },
        {
            //question 4//
            question: "How many years ago did Neanderthals disappear?",
            choices: ['2,800', '28,000', '280,000', '2,800,000'],
            answerIdx: 1,    
            furtherInfo: {
                explanation: (
                    `Neanderthals disappeared from the fossil record 28,000 years ago, their numbers most likely dwindling to extinction due to shifts in climate and changes in the prey animals available to them.`
                ),
                sources: [
                    {
                        source: 'BBC. How did the last Neanderthals live? BBC Future',
                        url: 'https://www.bbc.com/future/article/20200128-how-did-the-last-neanderthals-live',
                    },
                ],
            },
        },
        {
            //question 5//
            question: "Fossil evidence shows that Neanderthals occupied Gorham’s Cave in Gibraltar on and off for how many years?",
            choices: ['1,000', '10,000', '100,000', '1,000,000'],
            answerIdx: 2,    
            furtherInfo: {
                explanation: (
                    `The fossil evidence found inside Gorham’s Cave shows that Neanderthals occupied it on and off for approximately 100,000 years.`
                ),
                sources: [
                    {
                        source: 'BBC. How did the last Neanderthals live? BBC Future',
                        url: 'https://www.bbc.com/future/article/20200128-how-did-the-last-neanderthals-live',
                    },
                ],
            },
        },
        {
            //question 6//
            question: "What percentage of modern human DNA is identical to Neanderthal DNA?",
            choices: ['29.5%', '59.5%', '79.5%', '99.5%'],
            answerIdx: 3,    
            furtherInfo: {
                explanation: (
                    `Modern humans share 99.5% of our DNA with Neanderthals.`
                ),
                sources: [
                    {
                        source: 'BBC. How did the last Neanderthals live? BBC Future',
                        url: 'https://www.bbc.com/future/article/20200128-how-did-the-last-neanderthals-live',
                    },
                ],
            },
        },
        {
            //question 7//
            question: "Approximately what percentage of modern humans carry Neanderthal DNA?",
            choices: ['20%', '50%', '75%', '90%'],
            answerIdx: 0,    
            furtherInfo: {
                explanation: (
                    `At 20%, just under a quarter of the population of modern humans carry Neanderthal DNA, proving that we not only coexisted with Neanderthals but the two species were similar enough to interbreed.`
                ),
                sources: [
                    {
                        source: 'BBC. How did the last Neanderthals live? BBC Future',
                        url: 'https://www.bbc.com/future/article/20200128-how-did-the-last-neanderthals-live',
                    },
                ],
            },
        },
        {
            //question 8//
            question: "Fossils from how many bird species were found in Gorham’s Cave?",
            choices: ['15', '150', '1,500', '15,000'],
            answerIdx: 1,    
            furtherInfo: {
                explanation: (
                    `Fossils from 150 species of birds were found in Gorham’s Cave, many with cutting or teeth marks on them suggesting that these were not just birds that wandered into the cave to die but were being eaten, and possibly hunted, by the Neanderthals who lived there.`
                ),
                sources: [
                    {
                        source: 'BBC. How did the last Neanderthals live? BBC Future',
                        url: 'https://www.bbc.com/future/article/20200128-how-did-the-last-neanderthals-live',
                    },
                ],
            },
        },
        {
            //question 9//
            question: "How many strokes from a sharp stone tool did archaeologists find it took to make the deepest groove of the “Neanderthal hashtag”?",
            choices: ['6', '60', '600', '6,000'],
            answerIdx: 1,    
            furtherInfo: {
                explanation: (
                    `It took archaeologist 60 strokes from a sharp stone tool to replicate the depth of the largest groove of the “Neanderthal hashtag”, an artifact found carved into one of the walls of Gorham’s Cave and the first evidence of Neanderthal art.`
                ),
                sources: [
                    {
                        source: 'BBC. How did the last Neanderthals live? BBC Future',
                        url: 'https://www.bbc.com/future/article/20200128-how-did-the-last-neanderthals-live',
                    },
                ],
            },
        },
        {
            //question 10//
            question: "How old is the cave art attributed to Neanderthals found in the La Pasiega cave in Spain?",
            choices: ['640', '6,400', '64,000', '640,000'],
            answerIdx: 2,    
            furtherInfo: {
                explanation: (
                    `The cave art in the La Pasiega cave is dated 64,000 years old and attributed to Neanderthals. If the lines carved into the wall of Gorham’s Cave seem too nebulous to be considered art, the paintings at La Pasiega depict both animals and geometric shapes; an ability previously only attributed to modern humans.`
                ),
                sources: [
                    {
                        source: 'Science Magazine',
                        url: 'https://science.sciencemag.org/content/359/6378/912',
                    },
                ],
            },
        },
    ],

};

export default neanderthals;