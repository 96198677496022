 const whatColorIsFire = {
    name: 'What Color Is Fire?',
    id: 'wqQBbH5Va3qroQWXkgw0',
    linkTo: "/quizzes/whatColorIsFire",
    explainerOn: true,
    description: "You probably know that gas and wood fires range from dull red to ash blue, depending on their temperature. But did you also know that different materials burn in a rainbow of other colors? Take this quiz to prove your fire mastery.",
    questions: [
        { // 1
            question: "Which material makes <i>red</i> fire?",
            choices: ['Table salt', 'Borax laundry booster', 'Lithium from a lithium battery', 'Calcium chloride bleaching powder'],
            answerIdx: 2,    
            furtherInfo: {
                explanation: "Lithium from a lithium battery will make red fire.",
                sources: [
                    {
                        source: 'ThoughtCo.',
                        url: 'https://www.thoughtco.com/make-a-rainbow-of-colored-flames-606193',
                    },
                    {
                        source: 'Science Notes',
                        url: 'https://sciencenotes.org/how-to-make-colored-fire/'
                    }
                ],
            },
        },
        { // 2
            question: "Which material makes <i>blue</i> fire?",
            choices: [
                'Lithium from a lithium battery',
                'Potassium chloride (salt substitute)',
                'Copper sulfate',
                'Alcohol'
            ],
            answerIdx: 3,    
            furtherInfo: {
                explanation: "Alcohol creates blue fire.",
                sources: [
                    {
                        source: 'ThoughtCo.',
                        url: 'https://www.thoughtco.com/make-a-rainbow-of-colored-flames-606193',
                    },
                    {                        
                        source: 'Science Notes',
                        url: 'https://sciencenotes.org/how-to-make-colored-fire/'
                    }
                ],
            },
        },
        { // 3
            question: "Which material makes <i>yellow</i> fire?",
            choices: ['Magnesium sulfate (Epsom salts)', 'Table salt', 'Copper sulfate', 'Potassium chloride (salt substitute)'],
            answerIdx: 1,
            furtherInfo: {
                explanation: "Common table salt will create yellow fire.",
                sources: [
                    {
                        source: 'ThoughtCo.',
                        url: 'https://www.thoughtco.com/make-a-rainbow-of-colored-flames-606193',
                    },
                    {
                        source: 'Science Notes',
                        url: 'https://sciencenotes.org/how-to-make-colored-fire/'
                    }
                ],
            },
        },
        { // 4
            question: "Which material makes <i>violet</i> fire?",
            choices: ['Alcohol', 'Magnesium sulfate (Epsom salts)', 'Potassium chloride (salt substitute)', 'Borax laundry booster'],
            answerIdx: 2,    
            furtherInfo: {
                explanation: "Potassium chloride, a salt substitute, creates violet fire.",
                sources: [
                    {
                        source: 'ThoughtCo.',
                        url: 'https://www.thoughtco.com/make-a-rainbow-of-colored-flames-606193',
                    },
                    {                      
                        source: 'Science Notes',
                        url: 'https://sciencenotes.org/how-to-make-colored-fire/'
                    }
                ],
            },
        },
        { // 5
            question: "What material makes white fire?",
            choices: [
                'Strontium nitrate (contents of an emergency flare)',
                'Magnesium sulfate (Epsom salts)',
                'Potassium chloride (salt substitute)',
                'Copper sulfate'
            ],
            answerIdx: 1,
            furtherInfo: {
                explanation: "Magnesium sulfate, or Epsom salts, will give you white fire.",
                sources: [
                    {
                        source: 'ThoughtCo.',
                        url: 'https://www.thoughtco.com/make-a-rainbow-of-colored-flames-606193',
                    },
                    {                        
                        source: 'Science Notes',
                        url: 'https://sciencenotes.org/how-to-make-colored-fire/'
                    }
                ],
            },
        },
        { // 6
            question:'What material makes green fire?',
            choices: [
                'Potassium chloride (salt substitute)',
                'Borax laundry booster',
                'Calcium chloride bleaching powder',
                'Copper sulfate'
            ],
            answerIdx: 3,    
            furtherInfo: {
                explanation: "Copper sulfate will give you green fire.",
                sources: [
                    {
                        source: 'ThoughtCo.',
                        url: 'https://www.thoughtco.com/make-a-rainbow-of-colored-flames-606193',
                    },
                    {                        
                        source: 'Science Notes',
                        url: 'https://sciencenotes.org/how-to-make-colored-fire/'
                    }
                ],
            },
        },
        { // 7
            question: "What material makes orange fire?",
            choices: [
                'Calcium chloride bleaching powder',
                'Strontium nitrate (used in emergency flares)',
                'Table salt',
                'Magnesium sulfate (Epsom salts)'
            ],
            answerIdx: 0,
            furtherInfo: {
                explanation: "Calcium chloride bleaching powder will give you orange fire.",
                sources: [
                    {
                        source: 'ThoughtCo.',
                        url: 'https://www.thoughtco.com/make-a-rainbow-of-colored-flames-606193',
                    },
                    {                        
                        source: 'Science Notes',
                        url: 'https://sciencenotes.org/how-to-make-colored-fire/'
                    }
                ],
            },
        },
        { // 8
            question: 'What is a safe way to add a colorant chemical to your fireplace’s fire?',
            choices: [
                'Dissolve the materials in alcohol and then soak logs in the liquid.',
                'Dissolve the materials in water. Soak kindling like newspaper or pinecones in the liquid, let dry, and then add the treated kindling to your fire.',
                'All of the above.',
                'None of the above. You’ll suffocate on the toxic flames.'
            ],
            answerIdx: 2,    
            furtherInfo: {
                explanation: "Both dissolving the materials in alcohol then soaking the logs, or dissolving the materials in water and soaking the kindling are safe ways to add a colorant chemical to your fireplace’s fire.",
                sources: [
                    {
                        source: 'ThoughtCo.',
                        url: 'https://www.thoughtco.com/make-a-rainbow-of-colored-flames-606193',
                    },
                    {                      
                        source: 'Science Notes',
                        url: 'https://sciencenotes.org/how-to-make-colored-fire/'
                    }
                ],
            },
        },
        { // 9
            question: 'If you mix materials of equal parts that produce red, orange, yellow, green, blue, and violet flames together, what will you get?',
            choices: [
                'A rainbow fire',
                'Yellow fire',
                'White fire',
                'An explosion'
            ],
            answerIdx: 1,   
            furtherInfo: {
                explanation: "When it comes to fire, yellow tends to dominate all other colorants. If you want to mix colors involving yellow, reduce the amount of yellow colorant.",
                sources: [
                    {
                        source: 'ThoughtCo.',
                        url: 'https://www.thoughtco.com/make-a-rainbow-of-colored-flames-606193',
                    },
                    {                        
                        source: 'Science Notes',
                        url: 'https://sciencenotes.org/how-to-make-colored-fire/'
                    }
                ],
            },
        },
        { // 10
            question: 'What happens if you roast hotdogs, marshmallows, or other food over a chemically-colored fire?',
            choices: [
                'You’ll have chemically treated food that you shouldn’t eat.',
                'The food will turn a different color. However, you shouldn’t eat it due to chemical exposure.',
                'The food will turn a different color, and it’s ok to eat.',
                'The food will NOT turn a different color, and it’s ok to eat.'
            ],
            answerIdx: 0,    
            furtherInfo: {
                explanation: "If you roast hotdogs or marshmallows over a chemically-colored fire you will wind up with chemically treated food that you shouldn’t eat.",
                sources: [
                    {
                        source: 'ThoughtCo.',
                        url: 'https://www.thoughtco.com/make-a-rainbow-of-colored-flames-606193',
                    },
                    {                      
                        source: 'Science Notes',
                        url: 'https://sciencenotes.org/how-to-make-colored-fire/'
                    }
                ],
            },
        },
    ],

};

export default whatColorIsFire;