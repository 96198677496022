import { useStore } from "../contexts/store";

function useNotificationSystem() {
  const [{ notificationSystem }] = useStore();

  const addNotification = ({ message, level }) => {
    const notification = notificationSystem.current;
    notification.addNotification({
      message,
      level,
    });
  };

  return {
    addNotification,
  };
}

export default useNotificationSystem;
