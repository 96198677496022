import mixpanel from 'mixpanel-browser';

export function mixpanelInit(debug) {
  if (process.env.REACT_APP_MIXPANEL_TOKEN) {
    mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, {debug: debug, ignore_dnt: true});
    return mixpanel;
  }

  return null;
}

export function mixpanelTrack({mixpanel}, name, props) {
  if (mixpanel) {
    mixpanel.track(name, props);
  }
}