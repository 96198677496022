/*
 * quizzes collection
 *
 * I don't see any of users editing the quiz metadata unless we set up admin
 * roles in the future or we have user created quizzes.
 *
 * As of 02/2021, it's best to only allow a user to add his/her own scores to a quiz */

/**
 * Get a quiz given the id of the quiz
 * @param {Object} firestore - Firestore library loaded from firebase or Reactfire
 * @param {string} id - id of the quiz used for looking up in Firestore db
 * @returns {Promise}
 */
const getQuizById = (firestore, id) => {
  return getQuizRefById(firestore, id).get(); // promise
};

/**
 * Get a quiz reference given the id of the quiz
 * @param {Object} firestore - Firestore library loaded from firebase or Reactfire
 * @param {string} id - id of the quiz used for looking up in Firestore db
 * @returns {DocumentReference} - See https://firebase.google.com/docs/reference/js/firebase.firestore.DocumentReference
 */
const getQuizRefById = (firestore, id) => {
  return firestore.collection("quizzes").doc(id);
};

/**
 * Get a quiz reference given the id of the quiz
 * @param {Object} firestore - Firestore library loaded from firebase or Reactfire
 * @param {string} id - id of the quiz used for looking up in Firestore db
 * @param {string} userUid - The uid of the user of whom the score belongs to
 * @returns {DocumentReference} - See https://firebase.google.com/docs/reference/js/firebase.firestore.DocumentReference
 */
const getTopScoreRefForQuizForUser = (firestore, id, userUid) => {
  const quizRef = getQuizRefById(firestore, id);
  return quizRef.collection("topScores").doc(userUid);
};

/**
 * Get the top score for a user for a quiz in the topScores subcollection
 * @param {Object} firestore - Firestore library loaded from firebase or Reactfire
 * @param {string} id - id of the quiz used for looking up in Firestore db
 * @param {string} userUid - The uid of the user of whom the score belongs to
 * @returns {Promise}
 */
const getTopScoreForQuizForUser = (firestore, id, userUid) => {
  return getTopScoreRefForQuizForUser(firestore, id, userUid).get();
};

/**
 * Async function that updates a users top score for a particular quiz in the topScores subcollection
 * @param {Object} firestore - Firestore library loaded from firebase or Reactfire
 * @param {string} id - Id of the quiz for which the score is being updated
 * @param {Object} score - The score to be added/updated
 * @param {string} score.userUsername - The username of whom the score belongs to
 * @param {string} score.userUid - The uid of the user of whom the score belongs to
 * @param {string} score.userPhotoURL - The profile pic of the user
 * @param {number} score.score - The score earned by the user for the quiz
 * @returns {Object} updateResults
 * @returns {number} updateResults.topScore - current top score
 * @returns {number|undefined} updateResults.oldTopScore - old top score if it existed
 */
const updateTopScoreForQuizForUser = async (
  firestore,
  id,
  { userUsername, userUid, userPhotoURL, score }
) => {
  userPhotoURL = userPhotoURL || '';

  const updateResults = { topScore: score };
  const topScoreRef = getTopScoreRefForQuizForUser(firestore, id, userUid);

  const doc = await topScoreRef.get();
  if (doc.exists) {
    const data = doc.data();
    const currentTopScore = data.score;
    updateResults.oldTopScore = currentTopScore;

    if (score > currentTopScore) {
      topScoreRef.set(
        {
          userPhotoURL,
          userUsername,
          score,
          updatedAt: Date.now(),
        },
        { merge: true }
      );
    } else {
      updateResults.topScore = currentTopScore;
    }
  } else {
    topScoreRef.set({
      userUsername,
      userUid,
      userPhotoURL,
      score,
      createdAt: Date.now(),
      updatedAt: Date.now(),
    });
  }
  return updateResults;
};

/**
 * Get a scoreRank reference for a given score and quiz
 * @param {Object} firestore - Firestore library loaded from firebase or Reactfire
 * @param {string} quizId - id of the quiz used for looking up in Firestore db
 * @param {number} score - score for the quiz
 * @returns {DocumentReference} - See https://firebase.google.com/docs/reference/js/firebase.firestore.DocumentReference
 */
const getScoreRankRefForQuiz = (firestore, quizId, score) => {
  return getQuizRefById(firestore, quizId)
    .collection("scoreRanks")
    .doc(score.toString());
};

/**
 * Increment the scoreRank for a specific score
 * @param {Object} firestore - Firestore library loaded from firebase or Reactfire
 * @param {string} quizId - id of the quiz used for looking up in Firestore db
 * @param {number} newBestScore - new best score for the quiz
 */
const incrementScoreRank = (firestore, quizId, newBestScore) => {
  const scoreRankRef = getScoreRankRefForQuiz(firestore, quizId, newBestScore);
  scoreRankRef.get().then((doc) => {
    if (doc.exists) {
      const data = doc.data();
      const { numUsers } = data;

      scoreRankRef.update({
        numUsers: numUsers + 1,
      });
    } else {
      scoreRankRef.set({
        score: newBestScore,
        numUsers: 1,
      });
    }
  });
};

/**
 * Decrement the scoreRank for a specific score
 * @param {Object} firestore - Firestore library loaded from firebase or Reactfire
 * @param {string} quizId - id of the quiz used for looking up in Firestore db
 * @param {number} oldBestScore - old best score for the quiz
 */
const decrementScoreRank = (firestore, quizId, oldBestScore) => {
  const scoreRankRef = getScoreRankRefForQuiz(firestore, quizId, oldBestScore);
  scoreRankRef.get().then((doc) => {
    if (doc.exists) {
      const data = doc.data();
      const { numUsers } = data;

      if (numUsers === 0) {
        return;
      }

      scoreRankRef.update({
        numUsers: numUsers - 1,
      });
    }
  });
};

/**
 * Updates scoreRanks collection with new best score and old best score
 * @param {Object} firestore - Firestore library loaded from firebase or Reactfire
 * @param {string} quizId - id of the quiz used for looking up in Firestore db
 * @param {number} newScore - new best score for the quiz
 * @param {number} oldScore - old best score for the quiz
 */

const updateScoreRank = async (firestore, quizId, newScore, oldScore) => {
  if (oldScore) {
    await decrementScoreRank(firestore, quizId, oldScore);
  }

  incrementScoreRank(firestore, quizId, newScore);
}

/**
 * TODO: Might be good to move to cloud function if too much work on browser
 * Async function that returns the rank for a user given their score on a quiz
 * @param {Object} firestore - Firestore library loaded from firebase or Reactfire
 * @param {string} quizId - id of the quiz used for looking up in Firestore db
 * @param {number} score - score for the quiz
 * @returns {number}
 */
const getRankFromScore = async (firestore, quizId, score) => {
  let rank = 1;
  const querySnapshot = await getQuizRefById(firestore, quizId)
    .collection("scoreRanks")
    .where("score", ">", score)
    .get();

  querySnapshot.forEach((doc) => {
    rank += doc.data().numUsers;
  });

  return rank;
};

/**
 * TODO: Might be good to move to cloud function if too much work on browser
 * Async function that gets the top scorers for a quiz
 * @param {Object} firestore - Firestore library loaded from firebase or Reactfire
 * @param {string} quizId - id of the quiz used for looking up in Firestore db
 * @param {Object} options - options for function
 * @param {number} options.limit - number of top users to get
 * @returns {number}
 */
const getUsersWithTopScoresForQuiz = async (
  firestore,
  quizId,
  { limit } = { limit: 3 }
) => {
  const quizRef = getQuizRefById(firestore, quizId);
  const scoreRankQuerySnapshot = await quizRef
    .collection("scoreRanks")
    .orderBy("score", "desc")
    .limit(limit)
    .get();

  if (scoreRankQuerySnapshot.empty) {
    return [];
  }

  const minScore = scoreRankQuerySnapshot.docs[
    scoreRankQuerySnapshot.size - 1
  ].data().score;
  const topScoreQuerySnapshot = await quizRef
    .collection("topScores")
    .where("score", ">=", minScore)
    .orderBy("score", "desc")
    .limit(limit)
    .get();

  return topScoreQuerySnapshot.docs.map((doc) => doc.data());
};

/**
 * Function that gets score that is ranked above specified score
 * @param {Object} firestore - Firestore library loaded from firebase or Reactfire
 * @param {string} quizId - id of the quiz used for looking up in Firestore db
 * @param {number} score - baseline score
 * @returns {number|undefined}
 */
const getOneHigherScore = (firestore, quizId, score) => {
  return getScoresAroundScore(firestore, quizId, score, { higher: true });
};

/**
 * Function that gets score that is ranked below specified score
 * @param {Object} firestore - Firestore library loaded from firebase or Reactfire
 * @param {string} quizId - id of the quiz used for looking up in Firestore db
 * @param {number} score - baseline score
 * @returns {number|undefined}
 */
const getOneLowerScore = (firestore, quizId, score) => {
  return getScoresAroundScore(firestore, quizId, score, { higher: false });
};

/**
 * Async function that gets score that is ranked below or above specified score
 * @param {Object} firestore - Firestore library loaded from firebase or Reactfire
 * @param {string} quizId - id of the quiz used for looking up in Firestore db
 * @param {number} score - baseline score
 * @param {Object} options
 * @param {boolean} options.higher - get score that is higher than specified score
 * @returns {number|undefined}
 */
const getScoresAroundScore = async (
  firestore,
  quizId,
  score,
  { higher } = { higher: true }
) => {
  const quizRef = getQuizRefById(firestore, quizId);
  let queryOp, sortOrder;

  if (higher) {
    queryOp = ">";
    sortOrder = "asc";
  } else {
    queryOp = "<";
    sortOrder = "desc";
  }

  const scoreRankQuerySnapshot = await quizRef
    .collection("scoreRanks")
    .where("score", queryOp, score)
    .orderBy("score", sortOrder)
    .limit(1)
    .get();

  if (!scoreRankQuerySnapshot.empty) {
    return scoreRankQuerySnapshot.docs[0].data().score;
  }
};

/**
 * Get docs from "topScores" subcollection for a quiz given a specified score
 * @param {Object} firestore - Firestore library loaded from firebase or Reactfire
 * @param {string} id - id of the quiz used for looking up in Firestore db
 * @param {number} score - score for the quiz
 * @returns {Promise}
 */
const getTopScoresForScore = async (firestore, quizId, score, { limit }) => {
  const quizRef = getQuizRefById(firestore, quizId);
  const query = quizRef.collection("topScores").where("score", "==", score);
  if (limit) {
    return query.limit(limit).get();
  }
  return query.get();
};

export {
  updateTopScoreForQuizForUser,
  getTopScoreForQuizForUser,
  getQuizById,
  updateScoreRank,
  getUsersWithTopScoresForQuiz,
  getRankFromScore,
  getOneHigherScore,
  getOneLowerScore,
  getTopScoresForScore,
};
