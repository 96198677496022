 const hongKongZhuhaiMacauBridge = {
    name: 'Hong Kong-Zhuhai-Macau Bridge',
    id: 'PNdbK9fuhoeOBTVBARwc',
    linkTo: "/quizzes/hongKongZhuhaiMacauBridge",
    explainerOn: true,
    description: "A staggering feat of engineering, the Hong Kong-Zhuhai-Macau Bridge, which opened in 2018 is the world’s longest sea bridge. It spans the vast Pearl River Delta, linking the cities of Hong Kong, Zhuhai, and Macau. But what does this modern marvel look like in numbers?",
    questions: [
        {
            //question 1//
            question: "How much did the entirety of the Hong Kong-Zhuhai-Macau bridge project cost to construct in US dollars?",
            choices: ['$2,000,000', '$20,000,000', '$200,000,000', '$20,000,000,000'],
            answerIdx: 3,    
            furtherInfo: {
                explanation: (
                    `The entire cost of the massive sea bridge, including its two artificial islands and its stretch of undersea tunnel amounts to $20 billion.`
                ),
                sources: [
                    {
                        source: "BBC. (2018, October 23). World's longest sea crossing: Hong Kong-Zhuhai bridge opens. BBC News.",
                        url: 'https://www.bbc.com/news/world-asia-china-45937924',
                    },
                ],
            },
        },
        {
            //question 2//
           question: "The longest bridge in the world is another Chinese construction; the Danyan-Kushan Grand Bridge, which cost $8.5 million to build. Approximately how many times less did the Danyan-Kushan Grand Bridge cost compared to the Hong Kong-Zhuhai-Macau Bridge?",
            choices: ['2x', '23x', '235x', '2350x'],
            answerIdx: 2,    
            furtherInfo: {
                explanation: (
                    `The Hong Kong-Zhuhai-Macau Bridge cost approximately 235 times more to build than the Danyan-Kushan Grand Bridge`
                ),
                sources: [
                    {
                        source: "Live Science",
                        url: 'https://www.livescience.com/34448-worlds-longest-bridge.html',
                    },
                ],
            },
        },
        {
            //question 3//
            question: "How many years did it take for the bridge project to be completed?",
            choices: ['9', '19', '59', '90'],
            answerIdx: 0,    
            furtherInfo: {
                explanation: (
                    `The entirety of the bridge project took 9 years to complete.`
                ),
                sources: [
                    {
                        source: "BBC. (2018, October 23). World's longest sea crossing: Hong Kong-Zhuhai bridge opens. BBC News.",
                        url: 'https://www.bbc.com/news/world-asia-china-45937924',
                    },
                ],
            },
        },
        {
            //question 4//
            question: "How long is the bridge, in miles?",
            choices: ['3', '34', '340', '3,400'],
            answerIdx: 1,    
            furtherInfo: {
                explanation: (
                    `The bridge spans 34 miles, approximately 19 of which crosses the sea of the Pearl River Delta.`
                ),
                sources: [
                    {
                        source: "BBC. (2018, October 23). World's longest sea crossing: Hong Kong-Zhuhai bridge opens. BBC News.",
                        url: 'https://www.bbc.com/news/world-asia-china-45937924',
                    },
                ],
            },
        },
        {
            //question 5//
            question: "How much of the total length of the bridge dips underwater?",
            choices: ['4 mi', '14 mi', '40 mi', '400 mi'],
            answerIdx: 0,    
            furtherInfo: {
                explanation: (
                    `Four miles of the bridge are underwater to provide a passage for ships.`
                ),
                sources: [
                    {
                        source: "BBC. (2018, October 23). World's longest sea crossing: Hong Kong-Zhuhai bridge opens. BBC News.",
                        url: 'https://www.bbc.com/news/world-asia-china-45937924',
                    },
                ],
            },
        },
        {
            //question 6//
            question: "What is the depth of the bridge’s tunnel, in feet?",
            choices: ['14', '146', '1,460', '14,600'],
            answerIdx: 1,    
            furtherInfo: {
                explanation: (
                    `The maximum depth of the sea bridge’s tunnel is 146 feet.`
                ),
                sources: [
                    {
                        source: "BBC. (2018, October 23). World's longest sea crossing: Hong Kong-Zhuhai bridge opens. BBC News.",
                        url: 'https://www.bbc.com/news/world-asia-china-45937924',
                    },
                ],
            },
        },
        {
            //question 7//
            question: "How many metric tons of steel went into building the bridge?",
            choices: ['400', '4,000', '40,000', '400,000'],
            answerIdx: 3,    
            furtherInfo: {
                explanation: (
                    `400,000 metric tons of steel went into the construction of the sea bridge, which is equal to 881,849,000 pounds.`
                ),
                sources: [
                    {
                        source: "BBC. (2018, October 23). World's longest sea crossing: Hong Kong-Zhuhai bridge opens. BBC News.",
                        url: 'https://www.bbc.com/news/world-asia-china-45937924',
                    },
                ],
            },
        },
        {
            //question 8//
            question: "How many metric tons of concrete went into the bridge project?",
            choices: ['10,000', '100,000', '1,000,000', '10,000,000'],
            answerIdx: 2,    
            furtherInfo: {
                explanation: (
                    `1 million metric tons of concrete went into the bridge project, which is equal to 2,204,623,000 pounds.`
                ),
                sources: [
                    {
                        source: "BBC",
                        url: 'https://www.bbc.com/future/article/20200811-the-eco-friendly-alternatives-to-ocean-concrete',
                    },
                ],
            },
        },
        {
            //question 9//
            question: "Approximately how many people live in the area serviced by the bridge?",
            choices: ['68,000', '680,000', '6,800,000', '68,000,000'],
            answerIdx: 3,    
            furtherInfo: {
                explanation: (
                    `Approximately 68 million people live in the Pearl River Delta area serviced by the bridge.`
                ),
                sources: [
                    {
                        source: "BBC. (2018, October 23). World's longest sea crossing: Hong Kong-Zhuhai bridge opens. BBC News.",
                        url: 'https://www.bbc.com/news/world-asia-china-45937924',
                    },
                ],
            },
        },
        {
            //question 10//
            question: "The bridge cuts down travel time between Zhuhai and Hong Kong to _____ minutes from four hours.",
            choices: ['3', '30', '60', '90'],
            answerIdx: 1,    
            furtherInfo: {
                explanation: (
                    `The bridge cuts the travel time between Zhuhai and Hong Kong down to approximately 30 minutes but requires a permit to use.`
                ),
                sources: [
                    {
                        source: "BBC. (2018, October 23). World's longest sea crossing: Hong Kong-Zhuhai bridge opens. BBC News.",
                        url: 'https://www.bbc.com/news/world-asia-china-45937924',
                    },
                ],
            },
        },
    ],

};

export default hongKongZhuhaiMacauBridge;