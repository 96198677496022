 const tolkienQuiz = {
    id: "tolkienQuiz",
    name: "Tolkien: One quiz to rule them all",
    linkTo: "/quizzes/tolkienQuiz",
    description: 'Test your J.R.R. Tolkien Trivia',
    questions: [
        {
            question: "What is J.R.R. Tolkien's full name?",
            choices: ['John Ronald Reagan Tolkien', 'John Ronald Reuel Tolkien', 'Jimmy Rogers Rolan Tolkien'],
            answerIdx: 1,    
            furtherInfo: {
            },
        },
        {
            question: "What year did the Hobbit get published?",
            choices: ['1950', '1945', '1937'],
            answerIdx: 2,    
            furtherInfo: {
            },
        },
        {
            question: "When was The Fellowship of the Ring first published?",
            choices: ['1958', '1954', '1945'],
            answerIdx: 1,    
            furtherInfo: {
            },
        },
        {
            question: "Large Orcs are called?",
            choices: ['Nazgul', 'Goblins', 'Uruk-hai'],
            answerIdx: 2,    
            furtherInfo: {
            },
        },
        {
            question: "Who was killed by the Balrog?",
            choices: ['Boromir', 'Grima', 'Gandalf'],
            answerIdx: 2,    
            furtherInfo: {
            },
        },
        {
            question: "How many Academy Awards did “Lord of the Rings trilogy win?",
            choices: ['17', '25', '7'],
            answerIdx: 0,    
            furtherInfo: {
            },
        },
        {
            question: "How many VFX shots did Return of the King have?",
            choices: ['500', '1000', '1450'],
            answerIdx: 2,    
            furtherInfo: {
            },
        },
        {
            question: "The dragon's the Nazgul ride in “Return of the King” are called?",
            choices: ['Wyvern', 'Fell Beast', 'Drake'],
            answerIdx: 1,    
            furtherInfo: {
            },
        },
        {
            question: "What food does a Hobbit love the most?",
            choices: ['Bread', 'Strawberries', 'Mushrooms'],
            answerIdx: 2,    
            furtherInfo: {
            },
        },
        {
            question: "What is the name of the volcano in Mordor that will destroy the ring?",
            choices: ['Salron Springs', 'Barad Dur', 'Mountain of Doom'],
            answerIdx: 1,    
            furtherInfo: {
            },
        },
    ],

};

export default tolkienQuiz;
