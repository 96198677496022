 const goingViral = {
    id: 'goingViral',
    name: 'Going Viral',
    linkTo: "/quizzes/goingViral",
    explainerOn: true,
    description: 'How much do you know about the germs all around you?',
    questions: [
        {
            question: "Germs are microscopic organisms found everywhere on the planet. Most are harmless, some are helpful, but some cause diseases. Germs include which of the following?",
            choices: ['Bacteria', 'Virus', 'Fungi', 'Protozoa', 'All of the above'],
            answerIdx: 4,    
            furtherInfo: {
                explanation: (
                    `Bacteria, Viruses, Fungi, and Protozoa: All of these are considered germs.`
                ),
                sources: [
                    {
                        source: 'Kids Health',
                        url: 'https://kidshealth.org/en/parents/germs.html',
                    }
                ],
            },
        },
        {
            question: "Where are germs found?",
            choices: ['Air', 'Seawater and Lakes', 'Plants and Animals', 'Soil and Rocks', 'All of the above'],
            answerIdx: 4,    
            furtherInfo: {
                explanation: (
                    `Germs are found everywhere -- in our sky, water, ground, and in all other higher life forms.`
                ),
                sources: [
                    {
                        source: 'National Geographic',
                        url: 'https://www.nationalgeographic.com/science/2020/04/factors-allow-viruses-infect-humans-coronavirus/',
                    }
                ],
            },
        },
        {
            question: "By mass, which type of germ has the most weight?",
            choices: ['Bacteria', 'Virus', 'Fungi', 'Protozoa'],
            answerIdx: 0,    
            furtherInfo: {
                explanation: (
                    `Bacteria account for 70 gigatons of mass in the world. That’s the equivalent of 30 million Olympic sized swimming pools. In contrast, viruses take up 0.2 gigaton of mass. Humans only weigh 0.06 gigatons in total.`
                ),
                sources: [
                    {
                        source: 'Smithsonian',
                        url: 'https://www.smithsonianmag.com/smart-news/humans-make-110000th-earths-biomass-180969141/',
                    }
                ],
            },
        },
        {
            question: "What kind of germs are the smallest?",
            choices: ['Bacteria', 'Virus', 'Fungi', 'Protozoa'],
            answerIdx: 1,    
            furtherInfo: {
                explanation: (
                    `Viruses range from 20 to 400 nanometers (nm) in diameter (a nanometer is one billionth of a meter; that's 0.000000001, or 1E-9, of a meter). In comparison, bacteria are 500 nm to 5,000 nm in size. A human red blood cell is 6,000 to 8,000 nm across.`
                ),
                sources: [
                    {
                        source: 'Bionumbers',
                        url: 'http://book.bionumbers.org/how-big-are-viruses/',
                    }
                ],
            },
        },
        {
            question: "What is the number of viruses on the planet?",
            choices: ['1E6 (or 1,000,000)', '1E9 (1,000,000,000)', '1E16 (1 followed by 16 zeros)', '1E31 (1 followed by 31 zeros)'],
            answerIdx: 3,    
            furtherInfo: {
                explanation: (
                    `1E31 (that's 10,000,000,000,000,000,000,000,000,000,000) is the number of viruses on the planet. That includes viruses infecting plants and animals, and those found in the air, water and soil. That's more viruses than there are stars in the known universe!`
                ),
                sources: [
                    {
                        source: 'National Geographic',
                        url: 'https://www.nationalgeographic.com/science/2020/04/factors-allow-viruses-infect-humans-coronavirus/',
                    },
                    {
                        source: 'Bionumbers',
                        url: 'http://book.bionumbers.org/how-big-are-viruses/',
                    }
                ],
            },
        },
        {
            question: "How many categories of viruses are there?",
            choices: ['1', '7', '70', '700'],
            answerIdx: 1,    
            furtherInfo: {
                explanation: (
                    `Developed by Nobel Prize winner David Baltimore, there are seven categories of families of viruses. The categories depend on their genome types (DNA versus RNA), their strandedness, and how they replicate.`
                ),
                sources: [
                    {
                        source: 'Wikipedia',
                        url: 'https://en.wikipedia.org/wiki/Baltimore_classification',
                    },
                ],
            },
        },
        {
            question: "How many species of viruses are there?",
            choices: ['1E3 (or 1,000)', '1E5 (100,000)', '1E8 (100,000,000)', '1E12 (1,000,000,000,000)'],
            answerIdx: 2,    
            furtherInfo: {
                explanation: (
                    `Scientists estimate there are around 1e8 or 100 million (100,000,000) species of viruses across all species of vertebrates, invertebrates, plants, lichens, mushrooms, and brown algae on land and in the oceans.`
                ),
                sources: [
                    {
                        source: 'Virology',
                        url: 'https://www.virology.ws/2013/09/06/how-many-viruses-on-earth/',
                    },
                ],
            },
        },
        {
            question: "About how many species of viruses can infect humans?",
            choices: ['20', '200', '2,000', '20,000'],
            answerIdx: 1,    
            furtherInfo: {
                explanation: (
                    `There are around 220 species of viruses that can infect humans. About three to four new ones are discovered each year.`
                ),
                sources: [
                    {
                        source: 'National Institues of Health',
                        url: 'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3427559/',
                    },
                ],
            },
        },
    ],

};

export default goingViral;
