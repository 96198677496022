 const bidensChildcarePlan = {
    name: "Biden's Childcare Plan",
    id: 'VQmRP0hM0lgdfOxv0S9w',
    linkTo: "/quizzes/bidensChildcarePlan",
    explainerOn: true,
    description: 'President Biden has presented his new plan for sweeping changes, but what exactly are those changes? And who will be paying for them?.',
    questions: [
        {
            question: "How much is President Biden’s “American Families Plan,” focusing on children, expected to cost?",
            choices: ['$800 million', '$1.8 billion', '$180 billion', '$1.8 trillion'],
            answerIdx: 3,    
            furtherInfo: {
                explanation: (
                    `Biden’s “American Families Plan” is expected to cost a staggering $1.8 trillion.`
                ),
                sources: [
                    {
                        source: 'The Guardian Newspaper',
                        url: 'https://www.theguardian.com/us-news/2021/apr/28/joe-biden-american-families-plan-childcare-community-college',
                    }
                ],
            },
        },
        {
            question: "How is President Biden planning to fund his plan for national childcare and universal preschool?",
            choices: ['Close tax loopholes for the wealthy, raise the capital gains rates for millionaires and billionaires, and roll back Trump-era tax cuts.', 'Raise taxes on the middle and lower class, and defund the police.', 'Slash the military budget and withdraw U.S. troops from the Middle East.', 'Increase the national debt by taking out a loan from Russia.'],
            answerIdx: 0,    
            furtherInfo: {
                explanation: (
                    `President Biden’s plan is to be funded by closing tax loopholes for the wealthy, raising the capital gains rates for millionaires and billionaires, and rolling back Trump-era tax cuts.`
                ),
                sources: [
                    {
                        source: 'The Guardian Newspaper',
                        url: 'https://www.theguardian.com/us-news/2021/apr/28/joe-biden-american-families-plan-childcare-community-college',
                    }
                ],
            },
        },
        {
            question: "Besides caring for children and providing universal preschool, the American Families Plan will also provide which of the following?",
            choices: ['Tuition-free community college', 'Tax cuts for middle and lower income workers', 'Both A and B', 'Trick question. The plan is aimed at children.'],
            answerIdx: 2,    
            furtherInfo: {
                explanation: (
                    `Both A and B are correct. The American Families Plan aims to provide both tuition-free community college and tax cuts for middle and lower income workers.`
                ),
                sources: [
                    {
                        source: 'The Guardian Newspaper',
                        url: 'https://www.theguardian.com/us-news/2021/apr/28/joe-biden-american-families-plan-childcare-community-college',
                    }
                ],
            },
        },
        {
            question: "At present, the U.S. is the only wealthy nation that doesn’t have:",
            choices: ['A pro-socialist government', 'Paid maternity leave', 'Free college', 'Reptilian overlords'],
            answerIdx: 1,    
            furtherInfo: {
                explanation: (
                    `The U.S. is the only nation that does not have paid maternity leave.`
                ),
                sources: [
                    {
                        source: 'The Guardian Newspaper',
                        url: 'https://www.theguardian.com/us-news/2021/apr/28/joe-biden-american-families-plan-childcare-community-college',
                    }
                ],
            },
        },
        {
            question: "If the plan passes, what would be the cost breakdown with regard to education, childcare, and subsidizing paid family leave?",
            choices: ['$100 billion to fund education, $115 billion for childcare, $100 to subsidize paid family leave', '$350 billion to fund education, $350 billion for childcare, $350 billion to subsidize paid family leave', '$100 million to fund education, $150 billion for childcare, $150 billion to subsidize paid family leave', '$300 billion to fund education, $225 billion for childcare, $225 billion to subsidize paid family leave'],
            answerIdx: 3,    
            furtherInfo: {
                explanation: (
                    `If the American Families Plan passes, $300 million would fund education, $225 billion would go to fund childcare, and $225 billion would be used to subsidize paid family leave.`
                ),
                sources: [
                    {
                        source: 'The Guardian Newspaper',
                        url: 'https://www.theguardian.com/us-news/2021/apr/28/joe-biden-american-families-plan-childcare-community-college',
                    },
                ],
            },
        },
        {
            question: "The American Families Plan reflects what?",
            choices: ['Progressive values', 'Fascist values', 'Conservative values', 'Communist values'],
            answerIdx: 0,    
            furtherInfo: {
                explanation: (
                    `The American Families Plan reflects politically Progressive values.`
                ),
                sources: [
                    {
                        source: 'The Guardian Newspaper',
                        url: 'https://www.theguardian.com/us-news/2021/apr/28/joe-biden-american-families-plan-childcare-community-college',
                    },
                ],
            },
        },
        {
            question: "What is the plan hoping to accomplish?",
            choices: ['Improving literacy among children', 'Reduce child hunger and the breakdown of family structure', 'Boosting labor force participation and future economic competitiveness', 'Reduction to consumer and government spending on prescriptions'],
            answerIdx: 2,    
            furtherInfo: {
                explanation: (
                    `The American Families plan hopes to boost labor force participation and future economic competitiveness.`
                ),
                sources: [
                    {
                        source: 'The Guardian Newspaper',
                        url: 'https://www.theguardian.com/us-news/2021/apr/28/joe-biden-american-families-plan-childcare-community-college',
                    },
                ],
            },
        },
        {
            question: "President Biden’s plan is popular with which voters?",
            choices: ['Democrats', 'Republicans', 'Both Democrats and Republicans', 'Neither Democrats nor Republicans'],
            answerIdx: 2,    
            furtherInfo: {
                explanation: (
                    `President Biden’s plan is popular with both Democrats and Republicans.`
                ),
                sources: [
                    {
                        source: 'The Guardian Newspaper',
                        url: 'https://www.theguardian.com/us-news/2021/apr/28/joe-biden-american-families-plan-childcare-community-college',
                    },
                ],
            },
        },
        {
            question: "Some Republican lawmakers want to block the plan’s passage, and their main objection is to which aspect of the American Families Plan?",
            choices: ['Biden’s plan to raise taxes on the wealthy to fund American infrastructure, education and healthcare', 'Biden’s plan to provide universal preschool', 'Biden’s plan to cut taxes on middle and lower income workers', 'Biden’s stealing his plan ideas from Mitch McConnell'],
            answerIdx: 0,    
            furtherInfo: {
                explanation: (
                    `Republicn lawmakers looking to block the plan’s passage mainly object to Biden’s plan to raise taxes on the wealthy to fund American infrastructure, education, and healthcare.`
                ),
                sources: [
                    {
                        source: 'The Guardian Newspaper',
                        url: 'https://www.theguardian.com/us-news/2021/apr/28/joe-biden-american-families-plan-childcare-community-college',
                    },
                ],
            },
        },
        {
            question: "The American Families Plan will cost $1.8 trillion (1.8E12). If there are 330 million (3.3E8) people in the U.S., about how much would that cost each US American if everyone paid an equal share?",
            choices: ['$50', '$500', '$5,000', '$50,000'],
            answerIdx: 2,    
            furtherInfo: {
                explanation: (
                    `If each U.S. American taxpayer were to pay the same amount, The American Families Plan would cost each one $5,000.`
                ),
                sources: [
                    {
                        source: 'The Guardian Newspaper',
                        url: 'https://www.theguardian.com/us-news/2021/apr/28/joe-biden-american-families-plan-childcare-community-college',
                    },
                    {
                        source: 'The United States Census Bureau',
                        url: 'https://www.census.gov/library/stories/2019/12/happy-new-year-330-million-plus-people-in-united-states.html',
                    },
                ],
            },
        },
    ],

};

export default bidensChildcarePlan;