const theCostOfWar = {
  name: 'The Cost of War',
  id: 'YbuFrVLBZJDEe6eoQFbe',
  linkTo: "/quizzes/theCostOfWar",
  explainerOn: true,
  subheading: `How much has the War in Afghanistan cost us in dollars and lives?`,
  description: `The United States invaded Afghanistan in 2001. Its express intent was ousting the Taliban regime, believed to be harboring Osama bin Laden and other members of al-Quaeda linked to the 9/11 attacks. While bin Laden is dead, the Taliban is back in power, begging the question: was the war worth it? How much has the war in Afghanistan cost us, in dollars and in lives?`,
  questions: [
      {
          //question 1//
          question: `How many years did the United States have forces stationed in Afghanistan?`,
          choices: ['2', '12', '20', '200'],
          answerIdx: 2,    
          furtherInfo: {
              explanation: (
                  `The U.S. had forces in Afghanistan for 20 years. The U.S. first invaded Afghanistan in October 2001 in order to oust the Taliban, who were believed to be harboring Osama bin Laden and other members of al-Qaeda linked to the 9/11 attacks.`
              ),
              sources: [
                  {
                      source: 'BBC News',
                      url: 'https://www.bbc.com/news/world-47391821',
                  },
              ],
        },
      },
      {
          //question 2//
         question: `From October 2001 to September 2019, approximately how much did the U.S. Department of Defense spend on the war in Afghanistan?`,
          choices: ['$78,000,000', '$780,000,000', '$7,800,000,000', '$780,000,000,000'],
          answerIdx: 3,    
          furtherInfo: {
              explanation: (
                  `Between October 2001 and September 2019, the United States Department of Defense reported spending approximately $780 billion.`
              ),
              sources: [
                  {
                      source: 'BBC News',
                      url: 'https://www.bbc.com/news/world-47391821',
                  },
              ],
        },
      },
      {
          //question 3//
          question: `During the same time period, how much did the United States spend on reconstruction projects in Afghanistan?`,
          choices: ['$44,000,000', '$440,000,000', '$44,000,000,000', '$440,000,000,000'],
          answerIdx: 2,
          furtherInfo: {
              explanation: (
                  `In addition to the Department of Defense's $780 billion expenditure, the U.S. State Department and the U.S. Agency for International Development (USAID) together spent $44 billion on reconstruction projects in Afghanistan.`
              ),
              sources: [
                  {
                      source: 'BBC News',
                      url: 'https://www.bbc.com/news/world-47391821',
                  },
              ],
        },
      },
      {
          //question 4//
          question: `The United States' total reported spending in Afghanistan comes to approximately $822 billion. However, if money spent in Pakistan is included, the total comes up to how much?`,
          choices: ['$98,000,000', '$980,000,000', '$98,000,000,000', '$980,000,000,000'],
          answerIdx: 3,
          furtherInfo: {
              explanation: (
                  `The United States has used Pakistan as a base for its Afghan-related operations. When looking at the total spending in both Afghanistan and Pakistan, from 2001 to 2020, the total comes to <b>$980 billion</b>.`
              ),
              sources: [
                  {
                      source: 'BBC News',
                      url: 'https://www.bbc.com/news/world-47391821',
                  },
              ],
        },
      },
      {
          //question 5//
          question: `At its peak, what was the number of U.S. troops stationed in Afghanistan?`,
          choices: ['1,100', '10,000', '110,000', '1,100,000'],
          answerIdx: 2,    
          furtherInfo: {
              explanation: (
                  ` The number of U.S. troops stationed in Afghanistan peaked in 2011, at approximately 110,000.`
              ),
              sources: [
                  {
                      source: 'BBC News',
                      url: 'https://www.bbc.com/news/world-47391821',
                  },
              ],
        },
      },
      {
          //question 6//
          question: `How many troops were stationed in Afghanistan in 2020?`,
          choices: ['4,000', '40,000', '400,000', '4,000,000'],
          answerIdx: 0,    
          furtherInfo: {
              explanation: (
                  `By 2020, the number of U.S. troops in Afghanistan had fallen to just 4,000.`
              ),
              sources: [
                  {
                      source: 'BBC News',
                      url: 'https://www.bbc.com/news/world-47391821',
                  },
              ],
        },
      },
      {
          //question 7//
          question: `The NATO defense alliance officially ended its military mission in Afghanistan in December 2014. But after that point, it continued to keep a force of how many troops in Afghanistan?`,
          choices: ['1,300', '13,000', '130,000', '1,300,000'],
          answerIdx: 1,    
          furtherInfo: {
              explanation: (
                  `At the height of its military mission, NATO (North Atlantic Treaty Organization) maintained a force of over 130,000 troops in Afghanistan. After officially ending its military mission, in December 2014, NATO continued to maintain a force of <b>13,000</b> troops in Afghanistan.`
              ),
              sources: [
                  {
                      source: 'NATO',
                      url: 'https://www.nato.int/cps/en/natohq/topics_69366.htm',
                  },
                  {
                      source: 'BBC News',
                      url: 'https://www.bbc.com/news/world-47391821',
                  },
              ],
        },
      },
      {
          //question 8//
          question: `How many U.S. soldiers died in Afghanistan between 2001 and 2019?`,
          choices: ['23', '230', '2,300', '23,000'],
          answerIdx: 2,    
          furtherInfo: {
              explanation: (
                  `Between October 2001 -- when the U.S. invaded Afghanistan with the intent to oust the Taliban regime -- and September 2019, 2,300 U.S. troops lost their lives in Afghanistan.`
              ),
              sources: [
                  {
                      source: 'BBC News',
                      url: 'https://www.bbc.com/news/world-47391821',
                  },
              ],
        },
      },
      {
          //question 9//
          question: `In the same time period, how many members of the Afghan security forces died?`,
          choices: ['45', '450', '4,500', '45,000'],
          answerIdx: 3,    
          furtherInfo: {
              explanation: (
                  `45,000 members of the Afghan security forces died between October 2001 and September 2019.`
              ),
              sources: [
                  {
                      source: 'BBC News',
                      url: 'https://www.bbc.com/news/world-47391821',
                  },
              ],
        },
      },
      {
          //question 10//
          question: `Between 2001 and 2021, how many civilian lives were lost due to battle or weapons usage in Afghanistan?`,
          choices: ['76', '760', '7,600', '76,000'],
          answerIdx: 3,
          furtherInfo: {
              explanation: (
                  `Since 2001, approximately <strong>76,000 civilian lives</strong>, including journalists and aid workers, were lost in both Afghanistan and Pakistan as a direct result of the war in Afghanistan.`
              ),
              sources: [
                  {
                      source: 'BBC News',
                      url: 'https://www.bbc.com/news/world-asia-57767067',
                  },
              ],
          },
      },
  ],
};

export default theCostOfWar;