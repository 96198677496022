const dd3ReducingSources = {
  name: 'Drawdown 3: Reducing Sources',
  id: 'F7bMlbK4Xha1JLUof5bd',
  linkTo: "/quizzes/dd3-reducing-sources",
  explainerOn: true,
  subheading: `<b>Reduce</b>, Reuse, Recycle`,
  description: "Significantly reducing human sources of greenhouse gasses is over half the battle. That means one of the biggest pieces of any climate change solution has to be <strong>reducing the sources of greenhouse gasses</strong> that are being released into the atmosphere.",
  questions: [
      {
          //question 1//
          question: "Which sector produces the most greenhouse gas (GHG) emissions?",
          choices: ['Industry', 'Electricity Production', 'Food, Agriculture, and Land Use', 'Transportation'],
          answerIdx: 1,    
          furtherInfo: {
              explanation: (
                  `<strong>The production of electricity</strong> is responsible for approximately <b>25%</b> of all greenhouse gas emissions, making it the sector which produces the most greenhouse gasses.`
              ),
              sources: [
                  {
                      source: 'Project Drawdown',
                      url: 'https://drawdown.org/climate-solutions-101/unit-3-reducing-sources',
                  },
              ],
          },
      },
      {
          //question 2//
         question: "<b>Agriculture and forestry</b> activities account for <i>what percentage</i> of global GHG emissions?",
          choices: ['24%', '54%', '74%', '94%'],
          answerIdx: 3,    
          furtherInfo: {
              explanation: (
                  `Agriculture and forestry activities account for <b>24%</b> of global greenhouse gas emissions. At just under a quarter of the 55 gigatons of GHG emitted each year, that comes to approximately 13.2 billion metric tons.`
              ),
              sources: [
                  {
                      source: 'Project Drawdown',
                      url: 'https://drawdown.org/climate-solutions-101/unit-3-reducing-sources',
                  },
              ],
          },
      },
      {
          //question 3//
          question: "21% of the 55 gigatons of global GHG emissions come from the <b>industrial sector</b>, making it responsible for how many metric tons of GHGs emitted into the atmosphere each year?",
          choices: ['116,000', '11,600,000', '116,000,000', '11,600,000,000'],
          answerIdx: 0,    
          furtherInfo: {
              explanation: (
                  `At <b>21%</b> of all global GHG emissions, the industrial sector emits approximately 11.6 billion metric tons of greenhouse gasses each year.`
              ),
              sources: [
                  {
                      source: 'Project Drawdown',
                      url: 'https://drawdown.org/climate-solutions-101/unit-3-reducing-sources',
                  },
              ],
          },
      },
      {
          //question 4//
          question: "The <b>transportation</b> sector emits approximately 7.7 billion metric tons of GHGs each year, which is approximately what percent of total global emissions?",
          choices: ['7%', '14%', '28%', '56%'],
          answerIdx: 1,    
          furtherInfo: {
              explanation: (
                  `Globally, transportation is responsible for <b>14%</b> of all greenhouse gas emissions.`
              ),
              sources: [
                  {
                      source: 'Project Drawdown',
                      url: 'https://drawdown.org/climate-solutions-101/unit-3-reducing-sources',
                  },
              ],
          },
      },
      {
          //question 5//
          question: "A <i>kilowatt-hour</i> (kWh) is the unit used to measure electricity consumption, and refers to using 1,000 watts of electricity per hour.",
          choices: ['True', 'False'],
          answerIdx: 0,    
          furtherInfo: {
              explanation: (
                  `<b>This is true.</b> A kilowatt-hour (kWh) is what we use to measure electricity consumption, and refers to using 1,000 watts of electricity per hour.`
              ),
              sources: [
                  {
                      source: 'Project Drawdown',
                      url: 'https://drawdown.org/climate-solutions-101/unit-3-reducing-sources',
                  },
              ],
          },
      },
      {
          //question 6//
          question: "The average American household uses 1,000 kWh per month, which produces approximately <i>how many pounds</i> of CO₂ per year?",
          choices: ['16', '168', '1,680', '16,800'],
          answerIdx: 3,    
          furtherInfo: {
              explanation: (
                  `The average American electricity mix produces 1.4 pounds of CO₂ per kWh. So, at about 12,000 kWh per year, the average American household produces approximately 16,800 pounds of CO₂ every year (12,000 * 1.4 = 16,800).`
              ),
              sources: [
                  {
                      source: 'Project Drawdown',
                      url: 'https://drawdown.org/climate-solutions-101/unit-3-reducing-sources',
                  },
              ],
          },
      },
      {
          //question 7//
          question: "The overuse of <b>nitrogen fertilizers</b> creates <i>which</i> GHG?",
          choices: ['carbon dioxide', 'methane', 'nitrous oxide', 'fluorinated gasses'],
          answerIdx: 2,    
          furtherInfo: {
              explanation: (
                  `Overuse of nitrogen fertilizers emits <i>nitrous oxide</i>, an extremely potent greenhouse gas.`
              ),
              sources: [
                  {
                      source: 'Project Drawdown',
                      url: 'https://drawdown.org/climate-solutions-101/unit-3-reducing-sources',
                  },
              ],
          },
      },
      {
          //question 8//
          question: "Which GHG do flooded rice paddies produce?",
          choices: ['carbon dioxide', 'methane', 'nitrous oxide', 'fluorinated gasses'],
          answerIdx: 1,    
          furtherInfo: {
              explanation: (
                  `Flooded rice paddies produce <i>methane</i>, which is a greenhouse gas.`
              ),
              sources: [
                  {
                      source: 'Project Drawdown',
                      url: 'https://drawdown.org/climate-solutions-101/unit-3-reducing-sources',
                  },
              ],
          },
      },
      {
          //question 9//
          question: "The production of <b>cement</b>, alone, is responsible for <i>3%</i> of GHG emissions globally, which comes to approximately how many metric tons each year?",
          choices: ['3%', '13%', '26%', '52%'],
          answerIdx: 0,    
          furtherInfo: {
              explanation: (
                  `The production of cement is responsible for <b>3%</b> of global greenhouse gas emissions which is approximately 1.7 billion metric tons from the production of cement alone.`
              ),
              sources: [
                  {
                      source: 'Project Drawdown',
                      url: 'https://drawdown.org/climate-solutions-101/unit-3-reducing-sources',
                  },
              ],
          },
      },
      {
          //question 10//
          question: "Eliminating <b>fugitive emissions</b>, which are GHGs that leak into the environment accidentally, could eliminate <i>what percent</i> of GHGs immediately?",
          choices: ['6%', '12%', '24%', '48%'],
          answerIdx: 3,    
          furtherInfo: {
              explanation: (
                  `If <i>all</i> fugitive emissions were eliminated, that would immediately take <b>6%</b> of greenhouse gasses, that are being emitted, out of the climate change equation. If 6% sounds like a negligible amount to you, keep in mind that it equals approximately 3.3 billion metric tons of GHGs that weren't meant to be emitted in the first place.`
              ),
              sources: [
                  {
                      source: 'Project Drawdown',
                      url: 'https://drawdown.org/climate-solutions-101/unit-3-reducing-sources',
                  },
              ],
          },
      },
  ],
};

export default dd3ReducingSources;