import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const withStyles = makeStyles(theme => ({
  resultMsg: {
    fontFamily: 'hotel-solid,sans-serif',
    color: '#fff',
    fontSize: 48,
  },
}));

export default function Result(props) {
  const { correct } = props;

  const classes = withStyles();

  if (correct) {
    return <h1 className={classes.resultMsg}>CORRECT!</h1>
  }
  return <h1 className={classes.resultMsg}>NOT QUITE</h1>
}
