const psychedelicIndustry = { 
  name: "Psychedelic Industry", 
  id: 'bB5AyS37Bd8fSVCuXIzf', 
  linkTo: "/quizzes/psychedelicIndustry", 
  explainerOn: true, 
  subheading: `Magic mushrooms, shrooms, mushies, caps, pizza toppings, or alice; whatever you call them, psilocybin mushrooms are hitting the mainstream. Do you know why?`,
  description: `Few things have brought mental health into the spotlight like the Covid-19 pandemic. The widespread depression and anxiety brought on or exacerbated by the pandemic has called attention to the often large gap between the need for mental health care and the effectiveness of the available mental health care. It has also called attention to some of the more unconventional treatments now being trialed. The mental healthcare industry may be getting a psychedelic makeover spawning a whole new industry.`, 
  questions: [
      { // Q-1
          question: "Psilocybin use dates back to when?",
          choices: ['900 CE', '90 CE', '900 BCE', '9,000 BCE'],
          answerIdx: 3,
          furtherInfo: {
              explanation: (
                  `It is thought that the use of psilocybin mushrooms dates back to 9,000 BCE based on depictions in North African rock paintings.`
              ),
              sources: [
                  {
                      source: "Psilocybin Alpha",
                      url: 'https://psilocybinalpha.com/learn/history-of-psilocybin-research-and-investment',
                  },
              ],
          },
      },
      { // Q-2
          question: "When did the term 'magic mushroom' originate?",
          choices: ['1937', '1957', '1977', '1997'],
          answerIdx: 1,    
          furtherInfo: {
              explanation: (
                  `The term 'magic mushroom' was coined in the 1957 photo essay on the ritual use of psilocybin mushrooms in Mexico by mycologist R. Gordon Wasson titled <i>Seeking the Magic Mushroom</i>.`
              ),
              sources: [
                {
                  source: "Psilocybin Alpha",
                  url: 'https://psilocybinalpha.com/learn/history-of-psilocybin-research-and-investment',
                },
              ],
          },
      },
      { // Q-3 
        question: "How many species of psilocybin mushrooms are found around the world?",
        choices: ['18', '180', '1,800', '18,000'],
        answerIdx: 1,    
        furtherInfo: {
            explanation: (
                `There are approximately 180 species of psilocybin-containing mushrooms found worldwide. When eaten, the psilocybin in the mushrooms is converted in the body to the chemical psilocin, which has psychoactive properties.`
            ),
            sources: [
              {
                source: "Drug Science",
                url: 'https://www.drugscience.org.uk/drug-information/psilocybin/#1612864173630-e97cd1a9-30a7',
              },
            ],
        },
      },
      { // Q-4
        question: "What are most psychedelic drugs, like psilocybin, legally classified as in the United States?",
        choices: ['Schedule I', 'Highly Toxic', 'Schedule V', 'Hallucinogens'],
        answerIdx: 0,    
        furtherInfo: {
            explanation: (
                `Psilocybin, like most psychedelic drugs, is classified as a Schedule I substance in the United States. These substances are described as having no legitimate medical use and high risk of addiction. Other drugs in this category include heroin, methaqualone (Quaalude), and cannabis.`
            ),
            sources: [
              {
                source: "DEA",
                url: 'https://www.dea.gov/drug-information/drug-scheduling',
              },
            ],
        },
      },
      { // Q-5
        question: "In 2018 the Food and Drug Administration (FDA) of the United States granted psilocybin a Breakthrough Therapy Designation for use in treating what?",
        choices: ['bi-polar disorder', 'schizophrenia', 'treatment-resistant depression', 'PTSD'],
        answerIdx: 2,    
        furtherInfo: {
            explanation: (
                `In 2018 a psilocybin based therapy developed by Compass Pathways was granted a Breakthrough Therapy Designation for use in treating treatment-resistant depression.`
            ),
            sources: [
              {
                source: "Psilocybin Alpha",
                url: 'https://psilocybinalpha.com/learn/future-prospects-for-psilocybin-and-psychedelic-medicines',
              },
            ],
        },
      },
      { // Q-6
        question: "What city in the U.S. has decriminalized the use of psilocybin mushrooms?",
        choices: ['Oakland, CA', 'Denver, CO', 'Santa Cruz, CA', 'All of the above'],
        answerIdx: 3,    
        furtherInfo: {
            explanation: (
                `Oakland and Santa Cruz in California, as well as Denver, Colorado have all decriminalized the use of psilocybin mushrooms.`
            ),
            sources: [
              {
                source: "Psilocybin Alpha",
                url: 'https://psilocybinalpha.com/learn/future-prospects-for-psilocybin-and-psychedelic-medicines',
              },
            ],
        },
      },
      { // Q-7
        question: "What has the renewed interest in psilocybin been attributed to?",
        choices: ['Legalization of cannabis', 'Increasing mental health awareness', 'Pandemic-related depression', 'Influential lobbyists'],
        answerIdx: 0,    
        furtherInfo: {
            explanation: (
                `Renewed interest in developing the use of psilocybin has largely been attributed to the success of the legalization of cannabis, especially for medical uses.`
            ),
            sources: [
              {
                source: "Psilocybin Alpha",
                url: 'https://psilocybinalpha.com/learn/future-prospects-for-psilocybin-and-psychedelic-medicines',
              },
            ],
        },
      },
      { // Q-8
        question: "What are the largest players in the burgeoning psychedelic industry focused on?",
        choices: ['Creating demand', 'Branding', 'FDA approval', 'Legalization of psilocybin use'],
        answerIdx: 2,    
        furtherInfo: {
            explanation: (
                `For now, the largest players in the psychedelic industry are most focused on obtaining approval from the Food and Drug Administration in the United States and similar regulatory boards around the world.`
            ),
            sources: [
              {
                source: "Fortune",
                url: 'https://fortune.com/2021/09/04/psychedelic-industry-investment-growth-stocks-companies/',
              },
            ],
        },
      },
      { // Q-9
        question: "The psychedelic healthcare market is projected to be worth how much by 2027?",
        choices: ['$100,000', '$11,000,000', '$1,000,000,000', '$11,000,000,000'],
        answerIdx: 3,    
        furtherInfo: {
            explanation: (
                `Though still a relatively new industry, the future of the psychedelic healthcare market looks bright with a projected worth of $11 billion USD by 2027.`
            ),
            sources: [
              {
                source: "Fortune",
                url: 'https://fortune.com/2021/09/04/psychedelic-industry-investment-growth-stocks-companies/',
              },
            ],
        },
      },
      { // Q-10
        question: "How many clinical trials involving psilocybin, or psilocybin derivatives, are ongoing today?",
        choices: ['9', '18', '36', '72'],
        answerIdx: 1,    
        furtherInfo: {
            explanation: (
                `Today there are 18 ongoing clinical drug trials involving psilocybin, or psilocybin derivatives, focused on treating a variety of illnesses from acute anxiety to migraines.`
            ),
            sources: [
              {
                source: "Psilocybin Alpha",
                url: 'https://psilocybinalpha.com/data/psychedelic-drug-development-tracker',
              },
            ],
        },
      },
  ],

};

export default psychedelicIndustry;