const wellRoundedDivision4 = {
    id: 'wellRoundedDivision4',
    name: 'Well Rounded Division 4: Words / E-Notation',
    linkTo: "/quizzes/wellRoundedDivision4",
    description: "How fast can you divide big numbers? This one mixes Words and E-Notation.",
    halfOrderENumpad: true,
    timeLimit: 90,
    randomize: true,
    challenge: true,
    questions: 
    [
        {
            "question": "What is thirty thousand divided by 1E+03?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is thirty thousand divided by 1E+03?<br /><br />Think of it as: 3E04 / 1E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
            }
        },
        {
            "question": "What is one trillion divided by 3E+07?",
            "writeInAnswer": 30000,
            "furtherInfo": {
                "explanation": "What is one trillion divided by 3E+07?<br /><br />Think of it as: 1E12 / 3E07.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+04."
            }
        },
        {
            "question": "What is ten divided by 3E+00?",
            "writeInAnswer": 3,
            "furtherInfo": {
                "explanation": "What is ten divided by 3E+00?<br /><br />Think of it as: 1E01 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
            }
        },
        {
            "question": "What is one trillion divided by 3E+01?",
            "writeInAnswer": 30000000000,
            "furtherInfo": {
                "explanation": "What is one trillion divided by 3E+01?<br /><br />Think of it as: 1E12 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+10."
            }
        },
        {
            "question": "What is three trillion divided by 1E+00?",
            "writeInAnswer": 3000000000000,
            "furtherInfo": {
                "explanation": "What is three trillion divided by 1E+00?<br /><br />Think of it as: 3E12 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+12."
            }
        },
        {
            "question": "What is thirty billion divided by 3E+02?",
            "writeInAnswer": 100000000,
            "furtherInfo": {
                "explanation": "What is thirty billion divided by 3E+02?<br /><br />Think of it as: 3E10 / 3E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+08."
            }
        },
        {
            "question": "What is one thousand divided by 3E+00?",
            "writeInAnswer": 300,
            "furtherInfo": {
                "explanation": "What is one thousand divided by 3E+00?<br /><br />Think of it as: 1E03 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+02."
            }
        },
        {
            "question": "What is three hundred divided by 1E+00?",
            "writeInAnswer": 300,
            "furtherInfo": {
                "explanation": "What is three hundred divided by 1E+00?<br /><br />Think of it as: 3E02 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+02."
            }
        },
        {
            "question": "What is thirty trillion divided by 1E+04?",
            "writeInAnswer": 3000000000,
            "furtherInfo": {
                "explanation": "What is thirty trillion divided by 1E+04?<br /><br />Think of it as: 3E13 / 1E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+09."
            }
        },
        {
            "question": "What is ten thousand divided by 1E+01?",
            "writeInAnswer": 1000,
            "furtherInfo": {
                "explanation": "What is ten thousand divided by 1E+01?<br /><br />Think of it as: 1E04 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
            }
        },
        {
            "question": "What is one million divided by 3E+05?",
            "writeInAnswer": 3,
            "furtherInfo": {
                "explanation": "What is one million divided by 3E+05?<br /><br />Think of it as: 1E06 / 3E05.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
            }
        },
        {
            "question": "What is three thousand divided by 3E+02?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is three thousand divided by 3E+02?<br /><br />Think of it as: 3E03 / 3E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
            }
        },
        {
            "question": "What is three hundred divided by 1E+00?",
            "writeInAnswer": 300,
            "furtherInfo": {
                "explanation": "What is three hundred divided by 1E+00?<br /><br />Think of it as: 3E02 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+02."
            }
        },
        {
            "question": "What is thirty divided by 3E+00?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is thirty divided by 3E+00?<br /><br />Think of it as: 3E01 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
            }
        },
        {
            "question": "What is one hundred thousand divided by 3E+00?",
            "writeInAnswer": 30000,
            "furtherInfo": {
                "explanation": "What is one hundred thousand divided by 3E+00?<br /><br />Think of it as: 1E05 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+04."
            }
        },
        {
            "question": "What is three hundred divided by 3E+00?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is three hundred divided by 3E+00?<br /><br />Think of it as: 3E02 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
            }
        },
        {
            "question": "What is one hundred thousand divided by 3E+02?",
            "writeInAnswer": 300,
            "furtherInfo": {
                "explanation": "What is one hundred thousand divided by 3E+02?<br /><br />Think of it as: 1E05 / 3E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+02."
            }
        },
        {
            "question": "What is one billion divided by 1E+02?",
            "writeInAnswer": 10000000,
            "furtherInfo": {
                "explanation": "What is one billion divided by 1E+02?<br /><br />Think of it as: 1E09 / 1E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+07."
            }
        },
        {
            "question": "What is one billion divided by 1E+00?",
            "writeInAnswer": 1000000000,
            "furtherInfo": {
                "explanation": "What is one billion divided by 1E+00?<br /><br />Think of it as: 1E09 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+09."
            }
        },
        {
            "question": "What is thirty billion divided by 1E+05?",
            "writeInAnswer": 300000,
            "furtherInfo": {
                "explanation": "What is thirty billion divided by 1E+05?<br /><br />Think of it as: 3E10 / 1E05.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+05."
            }
        },
        {
            "question": "What is one trillion divided by 3E+08?",
            "writeInAnswer": 3000,
            "furtherInfo": {
                "explanation": "What is one trillion divided by 3E+08?<br /><br />Think of it as: 1E12 / 3E08.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+03."
            }
        },
        {
            "question": "What is three hundred thousand divided by 3E+01?",
            "writeInAnswer": 10000,
            "furtherInfo": {
                "explanation": "What is three hundred thousand divided by 3E+01?<br /><br />Think of it as: 3E05 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+04."
            }
        },
        {
            "question": "What is one billion divided by 1E+04?",
            "writeInAnswer": 100000,
            "furtherInfo": {
                "explanation": "What is one billion divided by 1E+04?<br /><br />Think of it as: 1E09 / 1E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+05."
            }
        },
        {
            "question": "What is ten million divided by 1E+02?",
            "writeInAnswer": 100000,
            "furtherInfo": {
                "explanation": "What is ten million divided by 1E+02?<br /><br />Think of it as: 1E07 / 1E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+05."
            }
        },
        {
            "question": "What is three billion divided by 1E+06?",
            "writeInAnswer": 3000,
            "furtherInfo": {
                "explanation": "What is three billion divided by 1E+06?<br /><br />Think of it as: 3E09 / 1E06.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+03."
            }
        },
        {
            "question": "What is ten billion divided by 1E+09?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is ten billion divided by 1E+09?<br /><br />Think of it as: 1E10 / 1E09.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
            }
        },
        {
            "question": "What is one hundred billion divided by 1E+04?",
            "writeInAnswer": 10000000,
            "furtherInfo": {
                "explanation": "What is one hundred billion divided by 1E+04?<br /><br />Think of it as: 1E11 / 1E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+07."
            }
        },
        {
            "question": "What is three million divided by 3E+03?",
            "writeInAnswer": 1000,
            "furtherInfo": {
                "explanation": "What is three million divided by 3E+03?<br /><br />Think of it as: 3E06 / 3E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
            }
        },
        {
            "question": "What is thirty thousand divided by 3E+01?",
            "writeInAnswer": 1000,
            "furtherInfo": {
                "explanation": "What is thirty thousand divided by 3E+01?<br /><br />Think of it as: 3E04 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
            }
        },
        {
            "question": "What is three trillion divided by 1E+06?",
            "writeInAnswer": 3000000,
            "furtherInfo": {
                "explanation": "What is three trillion divided by 1E+06?<br /><br />Think of it as: 3E12 / 1E06.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+06."
            }
        },
        {
            "question": "What is one hundred billion divided by 3E+04?",
            "writeInAnswer": 3000000,
            "furtherInfo": {
                "explanation": "What is one hundred billion divided by 3E+04?<br /><br />Think of it as: 1E11 / 3E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+06."
            }
        },
        {
            "question": "What is three million divided by 1E+04?",
            "writeInAnswer": 300,
            "furtherInfo": {
                "explanation": "What is three million divided by 1E+04?<br /><br />Think of it as: 3E06 / 1E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+02."
            }
        },
        {
            "question": "What is one hundred thousand divided by 3E+03?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is one hundred thousand divided by 3E+03?<br /><br />Think of it as: 1E05 / 3E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+01."
            }
        },
        {
            "question": "What is thirty million divided by 1E+01?",
            "writeInAnswer": 3000000,
            "furtherInfo": {
                "explanation": "What is thirty million divided by 1E+01?<br /><br />Think of it as: 3E07 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+06."
            }
        },
        {
            "question": "What is three thousand divided by 1E+02?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is three thousand divided by 1E+02?<br /><br />Think of it as: 3E03 / 1E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
            }
        },
        {
            "question": "What is three thousand divided by 1E+01?",
            "writeInAnswer": 300,
            "furtherInfo": {
                "explanation": "What is three thousand divided by 1E+01?<br /><br />Think of it as: 3E03 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+02."
            }
        },
        {
            "question": "What is one thousand divided by 3E+00?",
            "writeInAnswer": 300,
            "furtherInfo": {
                "explanation": "What is one thousand divided by 3E+00?<br /><br />Think of it as: 1E03 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+02."
            }
        },
        {
            "question": "What is three trillion divided by 1E+09?",
            "writeInAnswer": 3000,
            "furtherInfo": {
                "explanation": "What is three trillion divided by 1E+09?<br /><br />Think of it as: 3E12 / 1E09.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+03."
            }
        },
        {
            "question": "What is three hundred million divided by 1E+00?",
            "writeInAnswer": 300000000,
            "furtherInfo": {
                "explanation": "What is three hundred million divided by 1E+00?<br /><br />Think of it as: 3E08 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+08."
            }
        },
        {
            "question": "What is one hundred divided by 3E+00?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is one hundred divided by 3E+00?<br /><br />Think of it as: 1E02 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+01."
            }
        },
        {
            "question": "What is one hundred billion divided by 3E+04?",
            "writeInAnswer": 3000000,
            "furtherInfo": {
                "explanation": "What is one hundred billion divided by 3E+04?<br /><br />Think of it as: 1E11 / 3E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+06."
            }
        },
        {
            "question": "What is ten billion divided by 3E+03?",
            "writeInAnswer": 3000000,
            "furtherInfo": {
                "explanation": "What is ten billion divided by 3E+03?<br /><br />Think of it as: 1E10 / 3E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+06."
            }
        },
        {
            "question": "What is one thousand divided by 1E+01?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is one thousand divided by 1E+01?<br /><br />Think of it as: 1E03 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
            }
        },
        {
            "question": "What is one hundred billion divided by 3E+10?",
            "writeInAnswer": 3,
            "furtherInfo": {
                "explanation": "What is one hundred billion divided by 3E+10?<br /><br />Think of it as: 1E11 / 3E10.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
            }
        },
        {
            "question": "What is one hundred billion divided by 3E+06?",
            "writeInAnswer": 30000,
            "furtherInfo": {
                "explanation": "What is one hundred billion divided by 3E+06?<br /><br />Think of it as: 1E11 / 3E06.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+04."
            }
        },
        {
            "question": "What is ten divided by 3E+00?",
            "writeInAnswer": 3,
            "furtherInfo": {
                "explanation": "What is ten divided by 3E+00?<br /><br />Think of it as: 1E01 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
            }
        },
        {
            "question": "What is thirty trillion divided by 1E+06?",
            "writeInAnswer": 30000000,
            "furtherInfo": {
                "explanation": "What is thirty trillion divided by 1E+06?<br /><br />Think of it as: 3E13 / 1E06.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+07."
            }
        },
        {
            "question": "What is ten million divided by 3E+06?",
            "writeInAnswer": 3,
            "furtherInfo": {
                "explanation": "What is ten million divided by 3E+06?<br /><br />Think of it as: 1E07 / 3E06.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
            }
        },
        {
            "question": "What is one trillion divided by 1E+00?",
            "writeInAnswer": 1000000000000,
            "furtherInfo": {
                "explanation": "What is one trillion divided by 1E+00?<br /><br />Think of it as: 1E12 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+12."
            }
        },
        {
            "question": "What is ten million divided by 1E+02?",
            "writeInAnswer": 100000,
            "furtherInfo": {
                "explanation": "What is ten million divided by 1E+02?<br /><br />Think of it as: 1E07 / 1E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+05."
            }
        },
        {
            "question": "What is three hundred divided by 1E+00?",
            "writeInAnswer": 300,
            "furtherInfo": {
                "explanation": "What is three hundred divided by 1E+00?<br /><br />Think of it as: 3E02 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+02."
            }
        },
        {
            "question": "What is three hundred divided by 1E+01?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is three hundred divided by 1E+01?<br /><br />Think of it as: 3E02 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
            }
        },
        {
            "question": "What is thirty divided by 1E+00?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is thirty divided by 1E+00?<br /><br />Think of it as: 3E01 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
            }
        },
        {
            "question": "What is three hundred million divided by 1E+05?",
            "writeInAnswer": 3000,
            "furtherInfo": {
                "explanation": "What is three hundred million divided by 1E+05?<br /><br />Think of it as: 3E08 / 1E05.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+03."
            }
        },
        {
            "question": "What is thirty divided by 1E+00?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is thirty divided by 1E+00?<br /><br />Think of it as: 3E01 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
            }
        },
        {
            "question": "What is ten divided by 1E+00?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is ten divided by 1E+00?<br /><br />Think of it as: 1E01 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
            }
        },
        {
            "question": "What is three billion divided by 1E+02?",
            "writeInAnswer": 30000000,
            "furtherInfo": {
                "explanation": "What is three billion divided by 1E+02?<br /><br />Think of it as: 3E09 / 1E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+07."
            }
        },
        {
            "question": "What is ten billion divided by 3E+09?",
            "writeInAnswer": 3,
            "furtherInfo": {
                "explanation": "What is ten billion divided by 3E+09?<br /><br />Think of it as: 1E10 / 3E09.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
            }
        },
        {
            "question": "What is one trillion divided by 1E+07?",
            "writeInAnswer": 100000,
            "furtherInfo": {
                "explanation": "What is one trillion divided by 1E+07?<br /><br />Think of it as: 1E12 / 1E07.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+05."
            }
        },
        {
            "question": "What is ten divided by 1E+00?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is ten divided by 1E+00?<br /><br />Think of it as: 1E01 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
            }
        },
        {
            "question": "What is ten billion divided by 3E+03?",
            "writeInAnswer": 3000000,
            "furtherInfo": {
                "explanation": "What is ten billion divided by 3E+03?<br /><br />Think of it as: 1E10 / 3E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+06."
            }
        },
        {
            "question": "What is ten trillion divided by 3E+10?",
            "writeInAnswer": 300,
            "furtherInfo": {
                "explanation": "What is ten trillion divided by 3E+10?<br /><br />Think of it as: 1E13 / 3E10.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+02."
            }
        },
        {
            "question": "What is thirty trillion divided by 1E+03?",
            "writeInAnswer": 30000000000,
            "furtherInfo": {
                "explanation": "What is thirty trillion divided by 1E+03?<br /><br />Think of it as: 3E13 / 1E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+10."
            }
        },
        {
            "question": "What is one thousand divided by 3E+01?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is one thousand divided by 3E+01?<br /><br />Think of it as: 1E03 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+01."
            }
        },
        {
            "question": "What is one hundred thousand divided by 1E+04?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is one hundred thousand divided by 1E+04?<br /><br />Think of it as: 1E05 / 1E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
            }
        },
        {
            "question": "What is three hundred thousand divided by 3E+03?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is three hundred thousand divided by 3E+03?<br /><br />Think of it as: 3E05 / 3E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
            }
        },
        {
            "question": "What is three hundred thousand divided by 1E+03?",
            "writeInAnswer": 300,
            "furtherInfo": {
                "explanation": "What is three hundred thousand divided by 1E+03?<br /><br />Think of it as: 3E05 / 1E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+02."
            }
        },
        {
            "question": "What is three million divided by 3E+02?",
            "writeInAnswer": 10000,
            "furtherInfo": {
                "explanation": "What is three million divided by 3E+02?<br /><br />Think of it as: 3E06 / 3E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+04."
            }
        },
        {
            "question": "What is three hundred thousand divided by 1E+03?",
            "writeInAnswer": 300,
            "furtherInfo": {
                "explanation": "What is three hundred thousand divided by 1E+03?<br /><br />Think of it as: 3E05 / 1E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+02."
            }
        },
        {
            "question": "What is one hundred billion divided by 1E+09?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is one hundred billion divided by 1E+09?<br /><br />Think of it as: 1E11 / 1E09.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
            }
        },
        {
            "question": "What is ten divided by 3E+00?",
            "writeInAnswer": 3,
            "furtherInfo": {
                "explanation": "What is ten divided by 3E+00?<br /><br />Think of it as: 1E01 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
            }
        },
        {
            "question": "What is three billion divided by 3E+05?",
            "writeInAnswer": 10000,
            "furtherInfo": {
                "explanation": "What is three billion divided by 3E+05?<br /><br />Think of it as: 3E09 / 3E05.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+04."
            }
        },
        {
            "question": "What is three trillion divided by 1E+07?",
            "writeInAnswer": 300000,
            "furtherInfo": {
                "explanation": "What is three trillion divided by 1E+07?<br /><br />Think of it as: 3E12 / 1E07.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+05."
            }
        },
        {
            "question": "What is thirty million divided by 3E+01?",
            "writeInAnswer": 1000000,
            "furtherInfo": {
                "explanation": "What is thirty million divided by 3E+01?<br /><br />Think of it as: 3E07 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+06."
            }
        },
        {
            "question": "What is thirty trillion divided by 3E+11?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is thirty trillion divided by 3E+11?<br /><br />Think of it as: 3E13 / 3E11.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
            }
        },
        {
            "question": "What is three hundred divided by 3E+01?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is three hundred divided by 3E+01?<br /><br />Think of it as: 3E02 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
            }
        },
        {
            "question": "What is one million divided by 1E+03?",
            "writeInAnswer": 1000,
            "furtherInfo": {
                "explanation": "What is one million divided by 1E+03?<br /><br />Think of it as: 1E06 / 1E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
            }
        },
        {
            "question": "What is three million divided by 3E+02?",
            "writeInAnswer": 10000,
            "furtherInfo": {
                "explanation": "What is three million divided by 3E+02?<br /><br />Think of it as: 3E06 / 3E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+04."
            }
        },
        {
            "question": "What is three million divided by 3E+00?",
            "writeInAnswer": 1000000,
            "furtherInfo": {
                "explanation": "What is three million divided by 3E+00?<br /><br />Think of it as: 3E06 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+06."
            }
        },
        {
            "question": "What is thirty divided by 1E+00?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is thirty divided by 1E+00?<br /><br />Think of it as: 3E01 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
            }
        },
        {
            "question": "What is one million divided by 3E+03?",
            "writeInAnswer": 300,
            "furtherInfo": {
                "explanation": "What is one million divided by 3E+03?<br /><br />Think of it as: 1E06 / 3E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+02."
            }
        },
        {
            "question": "What is three hundred billion divided by 1E+00?",
            "writeInAnswer": 300000000000,
            "furtherInfo": {
                "explanation": "What is three hundred billion divided by 1E+00?<br /><br />Think of it as: 3E11 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+11."
            }
        },
        {
            "question": "What is ten thousand divided by 3E+00?",
            "writeInAnswer": 3000,
            "furtherInfo": {
                "explanation": "What is ten thousand divided by 3E+00?<br /><br />Think of it as: 1E04 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+03."
            }
        },
        {
            "question": "What is ten thousand divided by 3E+03?",
            "writeInAnswer": 3,
            "furtherInfo": {
                "explanation": "What is ten thousand divided by 3E+03?<br /><br />Think of it as: 1E04 / 3E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
            }
        },
        {
            "question": "What is three trillion divided by 3E+10?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is three trillion divided by 3E+10?<br /><br />Think of it as: 3E12 / 3E10.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
            }
        },
        {
            "question": "What is ten trillion divided by 1E+06?",
            "writeInAnswer": 10000000,
            "furtherInfo": {
                "explanation": "What is ten trillion divided by 1E+06?<br /><br />Think of it as: 1E13 / 1E06.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+07."
            }
        },
        {
            "question": "What is thirty billion divided by 1E+08?",
            "writeInAnswer": 300,
            "furtherInfo": {
                "explanation": "What is thirty billion divided by 1E+08?<br /><br />Think of it as: 3E10 / 1E08.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+02."
            }
        },
        {
            "question": "What is three thousand divided by 1E+01?",
            "writeInAnswer": 300,
            "furtherInfo": {
                "explanation": "What is three thousand divided by 1E+01?<br /><br />Think of it as: 3E03 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+02."
            }
        },
        {
            "question": "What is one hundred divided by 1E+00?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is one hundred divided by 1E+00?<br /><br />Think of it as: 1E02 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
            }
        },
        {
            "question": "What is thirty thousand divided by 1E+01?",
            "writeInAnswer": 3000,
            "furtherInfo": {
                "explanation": "What is thirty thousand divided by 1E+01?<br /><br />Think of it as: 3E04 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+03."
            }
        },
        {
            "question": "What is three million divided by 3E+05?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is three million divided by 3E+05?<br /><br />Think of it as: 3E06 / 3E05.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
            }
        },
        {
            "question": "What is three hundred million divided by 1E+07?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is three hundred million divided by 1E+07?<br /><br />Think of it as: 3E08 / 1E07.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
            }
        },
        {
            "question": "What is three million divided by 3E+05?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is three million divided by 3E+05?<br /><br />Think of it as: 3E06 / 3E05.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
            }
        },
        {
            "question": "What is one hundred divided by 1E+00?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is one hundred divided by 1E+00?<br /><br />Think of it as: 1E02 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
            }
        },
        {
            "question": "What is three trillion divided by 3E+01?",
            "writeInAnswer": 100000000000,
            "furtherInfo": {
                "explanation": "What is three trillion divided by 3E+01?<br /><br />Think of it as: 3E12 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+11."
            }
        },
        {
            "question": "What is three thousand divided by 1E+02?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is three thousand divided by 1E+02?<br /><br />Think of it as: 3E03 / 1E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
            }
        },
        {
            "question": "What is three hundred thousand divided by 3E+01?",
            "writeInAnswer": 10000,
            "furtherInfo": {
                "explanation": "What is three hundred thousand divided by 3E+01?<br /><br />Think of it as: 3E05 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+04."
            }
        },
        {
            "question": "What is thirty divided by 3E+00?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is thirty divided by 3E+00?<br /><br />Think of it as: 3E01 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
            }
        },
        {
            "question": "What is thirty divided by 1E+00?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is thirty divided by 1E+00?<br /><br />Think of it as: 3E01 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
            }
        },
        {
            "question": "What is ten divided by 3E+00?",
            "writeInAnswer": 3,
            "furtherInfo": {
                "explanation": "What is ten divided by 3E+00?<br /><br />Think of it as: 1E01 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
            }
        },
        {
            "question": "What is three hundred billion divided by 3E+04?",
            "writeInAnswer": 10000000,
            "furtherInfo": {
                "explanation": "What is three hundred billion divided by 3E+04?<br /><br />Think of it as: 3E11 / 3E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+07."
            }
        },
        {
            "question": "What is ten million divided by 1E+06?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is ten million divided by 1E+06?<br /><br />Think of it as: 1E07 / 1E06.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
            }
        },
        {
            "question": "What is thirty million divided by 3E+05?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is thirty million divided by 3E+05?<br /><br />Think of it as: 3E07 / 3E05.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
            }
        },
        {
            "question": "What is one trillion divided by 3E+01?",
            "writeInAnswer": 30000000000,
            "furtherInfo": {
                "explanation": "What is one trillion divided by 3E+01?<br /><br />Think of it as: 1E12 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+10."
            }
        },
        {
            "question": "What is one hundred thousand divided by 1E+04?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is one hundred thousand divided by 1E+04?<br /><br />Think of it as: 1E05 / 1E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
            }
        },
        {
            "question": "What is one hundred divided by 3E+01?",
            "writeInAnswer": 3,
            "furtherInfo": {
                "explanation": "What is one hundred divided by 3E+01?<br /><br />Think of it as: 1E02 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
            }
        },
        {
            "question": "What is one hundred thousand divided by 1E+01?",
            "writeInAnswer": 10000,
            "furtherInfo": {
                "explanation": "What is one hundred thousand divided by 1E+01?<br /><br />Think of it as: 1E05 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+04."
            }
        },
        {
            "question": "What is three billion divided by 3E+04?",
            "writeInAnswer": 100000,
            "furtherInfo": {
                "explanation": "What is three billion divided by 3E+04?<br /><br />Think of it as: 3E09 / 3E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+05."
            }
        },
        {
            "question": "What is three hundred divided by 3E+00?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is three hundred divided by 3E+00?<br /><br />Think of it as: 3E02 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
            }
        },
        {
            "question": "What is thirty thousand divided by 1E+03?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is thirty thousand divided by 1E+03?<br /><br />Think of it as: 3E04 / 1E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
            }
        },
        {
            "question": "What is one billion divided by 1E+08?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is one billion divided by 1E+08?<br /><br />Think of it as: 1E09 / 1E08.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
            }
        },
        {
            "question": "What is ten thousand divided by 3E+01?",
            "writeInAnswer": 300,
            "furtherInfo": {
                "explanation": "What is ten thousand divided by 3E+01?<br /><br />Think of it as: 1E04 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+02."
            }
        },
        {
            "question": "What is one million divided by 1E+04?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is one million divided by 1E+04?<br /><br />Think of it as: 1E06 / 1E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
            }
        },
        {
            "question": "What is one hundred divided by 3E+00?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is one hundred divided by 3E+00?<br /><br />Think of it as: 1E02 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+01."
            }
        },
        {
            "question": "What is one billion divided by 1E+02?",
            "writeInAnswer": 10000000,
            "furtherInfo": {
                "explanation": "What is one billion divided by 1E+02?<br /><br />Think of it as: 1E09 / 1E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+07."
            }
        },
        {
            "question": "What is ten thousand divided by 1E+02?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is ten thousand divided by 1E+02?<br /><br />Think of it as: 1E04 / 1E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
            }
        },
        {
            "question": "What is ten million divided by 3E+03?",
            "writeInAnswer": 3000,
            "furtherInfo": {
                "explanation": "What is ten million divided by 3E+03?<br /><br />Think of it as: 1E07 / 3E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+03."
            }
        },
        {
            "question": "What is one million divided by 1E+02?",
            "writeInAnswer": 10000,
            "furtherInfo": {
                "explanation": "What is one million divided by 1E+02?<br /><br />Think of it as: 1E06 / 1E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+04."
            }
        },
        {
            "question": "What is thirty thousand divided by 1E+01?",
            "writeInAnswer": 3000,
            "furtherInfo": {
                "explanation": "What is thirty thousand divided by 1E+01?<br /><br />Think of it as: 3E04 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+03."
            }
        },
        {
            "question": "What is thirty billion divided by 3E+07?",
            "writeInAnswer": 1000,
            "furtherInfo": {
                "explanation": "What is thirty billion divided by 3E+07?<br /><br />Think of it as: 3E10 / 3E07.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
            }
        },
        {
            "question": "What is three hundred billion divided by 1E+06?",
            "writeInAnswer": 300000,
            "furtherInfo": {
                "explanation": "What is three hundred billion divided by 1E+06?<br /><br />Think of it as: 3E11 / 1E06.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+05."
            }
        },
        {
            "question": "What is one hundred divided by 1E+01?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is one hundred divided by 1E+01?<br /><br />Think of it as: 1E02 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
            }
        },
        {
            "question": "What is one hundred million divided by 3E+00?",
            "writeInAnswer": 30000000,
            "furtherInfo": {
                "explanation": "What is one hundred million divided by 3E+00?<br /><br />Think of it as: 1E08 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+07."
            }
        },
        {
            "question": "What is ten million divided by 3E+00?",
            "writeInAnswer": 3000000,
            "furtherInfo": {
                "explanation": "What is ten million divided by 3E+00?<br /><br />Think of it as: 1E07 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+06."
            }
        },
        {
            "question": "What is three billion divided by 3E+00?",
            "writeInAnswer": 1000000000,
            "furtherInfo": {
                "explanation": "What is three billion divided by 3E+00?<br /><br />Think of it as: 3E09 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+09."
            }
        },
        {
            "question": "What is one hundred billion divided by 1E+00?",
            "writeInAnswer": 100000000000,
            "furtherInfo": {
                "explanation": "What is one hundred billion divided by 1E+00?<br /><br />Think of it as: 1E11 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+11."
            }
        },
        {
            "question": "What is ten divided by 1E+00?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is ten divided by 1E+00?<br /><br />Think of it as: 1E01 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
            }
        },
        {
            "question": "What is thirty divided by 1E+00?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is thirty divided by 1E+00?<br /><br />Think of it as: 3E01 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
            }
        },
        {
            "question": "What is one hundred thousand divided by 1E+01?",
            "writeInAnswer": 10000,
            "furtherInfo": {
                "explanation": "What is one hundred thousand divided by 1E+01?<br /><br />Think of it as: 1E05 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+04."
            }
        },
        {
            "question": "What is three thousand divided by 3E+01?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is three thousand divided by 3E+01?<br /><br />Think of it as: 3E03 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
            }
        },
        {
            "question": "What is one trillion divided by 1E+08?",
            "writeInAnswer": 10000,
            "furtherInfo": {
                "explanation": "What is one trillion divided by 1E+08?<br /><br />Think of it as: 1E12 / 1E08.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+04."
            }
        },
        {
            "question": "What is thirty thousand divided by 3E+03?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is thirty thousand divided by 3E+03?<br /><br />Think of it as: 3E04 / 3E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
            }
        },
        {
            "question": "What is three hundred billion divided by 1E+06?",
            "writeInAnswer": 300000,
            "furtherInfo": {
                "explanation": "What is three hundred billion divided by 1E+06?<br /><br />Think of it as: 3E11 / 1E06.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+05."
            }
        },
        {
            "question": "What is thirty thousand divided by 1E+01?",
            "writeInAnswer": 3000,
            "furtherInfo": {
                "explanation": "What is thirty thousand divided by 1E+01?<br /><br />Think of it as: 3E04 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+03."
            }
        },
        {
            "question": "What is ten million divided by 1E+00?",
            "writeInAnswer": 10000000,
            "furtherInfo": {
                "explanation": "What is ten million divided by 1E+00?<br /><br />Think of it as: 1E07 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+07."
            }
        },
        {
            "question": "What is ten thousand divided by 3E+03?",
            "writeInAnswer": 3,
            "furtherInfo": {
                "explanation": "What is ten thousand divided by 3E+03?<br /><br />Think of it as: 1E04 / 3E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
            }
        },
        {
            "question": "What is thirty trillion divided by 3E+04?",
            "writeInAnswer": 1000000000,
            "furtherInfo": {
                "explanation": "What is thirty trillion divided by 3E+04?<br /><br />Think of it as: 3E13 / 3E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+09."
            }
        },
        {
            "question": "What is one hundred billion divided by 3E+10?",
            "writeInAnswer": 3,
            "furtherInfo": {
                "explanation": "What is one hundred billion divided by 3E+10?<br /><br />Think of it as: 1E11 / 3E10.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
            }
        },
        {
            "question": "What is one hundred million divided by 3E+00?",
            "writeInAnswer": 30000000,
            "furtherInfo": {
                "explanation": "What is one hundred million divided by 3E+00?<br /><br />Think of it as: 1E08 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+07."
            }
        },
        {
            "question": "What is three hundred thousand divided by 3E+03?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is three hundred thousand divided by 3E+03?<br /><br />Think of it as: 3E05 / 3E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
            }
        },
        {
            "question": "What is ten trillion divided by 3E+00?",
            "writeInAnswer": 3000000000000,
            "furtherInfo": {
                "explanation": "What is ten trillion divided by 3E+00?<br /><br />Think of it as: 1E13 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+12."
            }
        },
        {
            "question": "What is thirty billion divided by 3E+04?",
            "writeInAnswer": 1000000,
            "furtherInfo": {
                "explanation": "What is thirty billion divided by 3E+04?<br /><br />Think of it as: 3E10 / 3E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+06."
            }
        },
        {
            "question": "What is ten trillion divided by 1E+10?",
            "writeInAnswer": 1000,
            "furtherInfo": {
                "explanation": "What is ten trillion divided by 1E+10?<br /><br />Think of it as: 1E13 / 1E10.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
            }
        },
        {
            "question": "What is thirty thousand divided by 1E+01?",
            "writeInAnswer": 3000,
            "furtherInfo": {
                "explanation": "What is thirty thousand divided by 1E+01?<br /><br />Think of it as: 3E04 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+03."
            }
        },
        {
            "question": "What is ten trillion divided by 3E+02?",
            "writeInAnswer": 30000000000,
            "furtherInfo": {
                "explanation": "What is ten trillion divided by 3E+02?<br /><br />Think of it as: 1E13 / 3E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+10."
            }
        },
        {
            "question": "What is one thousand divided by 3E+02?",
            "writeInAnswer": 3,
            "furtherInfo": {
                "explanation": "What is one thousand divided by 3E+02?<br /><br />Think of it as: 1E03 / 3E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
            }
        },
        {
            "question": "What is three trillion divided by 3E+07?",
            "writeInAnswer": 100000,
            "furtherInfo": {
                "explanation": "What is three trillion divided by 3E+07?<br /><br />Think of it as: 3E12 / 3E07.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+05."
            }
        },
        {
            "question": "What is thirty billion divided by 1E+04?",
            "writeInAnswer": 3000000,
            "furtherInfo": {
                "explanation": "What is thirty billion divided by 1E+04?<br /><br />Think of it as: 3E10 / 1E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+06."
            }
        },
        {
            "question": "What is one million divided by 1E+01?",
            "writeInAnswer": 100000,
            "furtherInfo": {
                "explanation": "What is one million divided by 1E+01?<br /><br />Think of it as: 1E06 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+05."
            }
        },
        {
            "question": "What is three trillion divided by 3E+09?",
            "writeInAnswer": 1000,
            "furtherInfo": {
                "explanation": "What is three trillion divided by 3E+09?<br /><br />Think of it as: 3E12 / 3E09.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
            }
        },
        {
            "question": "What is thirty thousand divided by 3E+03?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is thirty thousand divided by 3E+03?<br /><br />Think of it as: 3E04 / 3E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
            }
        },
        {
            "question": "What is one billion divided by 1E+05?",
            "writeInAnswer": 10000,
            "furtherInfo": {
                "explanation": "What is one billion divided by 1E+05?<br /><br />Think of it as: 1E09 / 1E05.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+04."
            }
        },
        {
            "question": "What is three thousand divided by 1E+01?",
            "writeInAnswer": 300,
            "furtherInfo": {
                "explanation": "What is three thousand divided by 1E+01?<br /><br />Think of it as: 3E03 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+02."
            }
        },
        {
            "question": "What is one million divided by 1E+05?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is one million divided by 1E+05?<br /><br />Think of it as: 1E06 / 1E05.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
            }
        },
        {
            "question": "What is three hundred million divided by 3E+01?",
            "writeInAnswer": 10000000,
            "furtherInfo": {
                "explanation": "What is three hundred million divided by 3E+01?<br /><br />Think of it as: 3E08 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+07."
            }
        },
        {
            "question": "What is ten trillion divided by 3E+02?",
            "writeInAnswer": 30000000000,
            "furtherInfo": {
                "explanation": "What is ten trillion divided by 3E+02?<br /><br />Think of it as: 1E13 / 3E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+10."
            }
        },
        {
            "question": "What is ten million divided by 3E+04?",
            "writeInAnswer": 300,
            "furtherInfo": {
                "explanation": "What is ten million divided by 3E+04?<br /><br />Think of it as: 1E07 / 3E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+02."
            }
        },
        {
            "question": "What is one hundred million divided by 1E+01?",
            "writeInAnswer": 10000000,
            "furtherInfo": {
                "explanation": "What is one hundred million divided by 1E+01?<br /><br />Think of it as: 1E08 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+07."
            }
        },
        {
            "question": "What is three hundred million divided by 3E+02?",
            "writeInAnswer": 1000000,
            "furtherInfo": {
                "explanation": "What is three hundred million divided by 3E+02?<br /><br />Think of it as: 3E08 / 3E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+06."
            }
        },
        {
            "question": "What is one thousand divided by 1E+00?",
            "writeInAnswer": 1000,
            "furtherInfo": {
                "explanation": "What is one thousand divided by 1E+00?<br /><br />Think of it as: 1E03 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
            }
        },
        {
            "question": "What is ten million divided by 3E+00?",
            "writeInAnswer": 3000000,
            "furtherInfo": {
                "explanation": "What is ten million divided by 3E+00?<br /><br />Think of it as: 1E07 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+06."
            }
        },
        {
            "question": "What is one trillion divided by 3E+00?",
            "writeInAnswer": 300000000000,
            "furtherInfo": {
                "explanation": "What is one trillion divided by 3E+00?<br /><br />Think of it as: 1E12 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+11."
            }
        },
        {
            "question": "What is three thousand divided by 1E+02?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is three thousand divided by 1E+02?<br /><br />Think of it as: 3E03 / 1E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
            }
        },
        {
            "question": "What is three million divided by 1E+01?",
            "writeInAnswer": 300000,
            "furtherInfo": {
                "explanation": "What is three million divided by 1E+01?<br /><br />Think of it as: 3E06 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+05."
            }
        },
        {
            "question": "What is one thousand divided by 1E+02?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is one thousand divided by 1E+02?<br /><br />Think of it as: 1E03 / 1E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
            }
        },
        {
            "question": "What is three hundred thousand divided by 3E+01?",
            "writeInAnswer": 10000,
            "furtherInfo": {
                "explanation": "What is three hundred thousand divided by 3E+01?<br /><br />Think of it as: 3E05 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+04."
            }
        },
        {
            "question": "What is ten trillion divided by 3E+10?",
            "writeInAnswer": 300,
            "furtherInfo": {
                "explanation": "What is ten trillion divided by 3E+10?<br /><br />Think of it as: 1E13 / 3E10.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+02."
            }
        },
        {
            "question": "What is one hundred billion divided by 1E+06?",
            "writeInAnswer": 100000,
            "furtherInfo": {
                "explanation": "What is one hundred billion divided by 1E+06?<br /><br />Think of it as: 1E11 / 1E06.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+05."
            }
        },
        {
            "question": "What is three hundred billion divided by 1E+08?",
            "writeInAnswer": 3000,
            "furtherInfo": {
                "explanation": "What is three hundred billion divided by 1E+08?<br /><br />Think of it as: 3E11 / 1E08.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+03."
            }
        },
        {
            "question": "What is thirty trillion divided by 3E+01?",
            "writeInAnswer": 1000000000000,
            "furtherInfo": {
                "explanation": "What is thirty trillion divided by 3E+01?<br /><br />Think of it as: 3E13 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+12."
            }
        },
        {
            "question": "What is one hundred thousand divided by 1E+03?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is one hundred thousand divided by 1E+03?<br /><br />Think of it as: 1E05 / 1E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
            }
        },
        {
            "question": "What is three million divided by 1E+03?",
            "writeInAnswer": 3000,
            "furtherInfo": {
                "explanation": "What is three million divided by 1E+03?<br /><br />Think of it as: 3E06 / 1E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+03."
            }
        },
        {
            "question": "What is thirty billion divided by 1E+00?",
            "writeInAnswer": 30000000000,
            "furtherInfo": {
                "explanation": "What is thirty billion divided by 1E+00?<br /><br />Think of it as: 3E10 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+10."
            }
        },
        {
            "question": "What is one thousand divided by 1E+01?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is one thousand divided by 1E+01?<br /><br />Think of it as: 1E03 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
            }
        },
        {
            "question": "What is one billion divided by 3E+07?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is one billion divided by 3E+07?<br /><br />Think of it as: 1E09 / 3E07.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+01."
            }
        },
        {
            "question": "What is thirty million divided by 1E+01?",
            "writeInAnswer": 3000000,
            "furtherInfo": {
                "explanation": "What is thirty million divided by 1E+01?<br /><br />Think of it as: 3E07 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+06."
            }
        },
        {
            "question": "What is thirty divided by 3E+00?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is thirty divided by 3E+00?<br /><br />Think of it as: 3E01 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
            }
        },
        {
            "question": "What is ten billion divided by 3E+02?",
            "writeInAnswer": 30000000,
            "furtherInfo": {
                "explanation": "What is ten billion divided by 3E+02?<br /><br />Think of it as: 1E10 / 3E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+07."
            }
        },
        {
            "question": "What is three hundred divided by 3E+01?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is three hundred divided by 3E+01?<br /><br />Think of it as: 3E02 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
            }
        },
        {
            "question": "What is ten thousand divided by 3E+02?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is ten thousand divided by 3E+02?<br /><br />Think of it as: 1E04 / 3E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+01."
            }
        },
        {
            "question": "What is thirty million divided by 3E+05?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is thirty million divided by 3E+05?<br /><br />Think of it as: 3E07 / 3E05.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
            }
        },
        {
            "question": "What is one thousand divided by 3E+00?",
            "writeInAnswer": 300,
            "furtherInfo": {
                "explanation": "What is one thousand divided by 3E+00?<br /><br />Think of it as: 1E03 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+02."
            }
        },
        {
            "question": "What is three trillion divided by 1E+11?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is three trillion divided by 1E+11?<br /><br />Think of it as: 3E12 / 1E11.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
            }
        },
        {
            "question": "What is one trillion divided by 1E+03?",
            "writeInAnswer": 1000000000,
            "furtherInfo": {
                "explanation": "What is one trillion divided by 1E+03?<br /><br />Think of it as: 1E12 / 1E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+09."
            }
        },
        {
            "question": "What is ten million divided by 3E+01?",
            "writeInAnswer": 300000,
            "furtherInfo": {
                "explanation": "What is ten million divided by 3E+01?<br /><br />Think of it as: 1E07 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+05."
            }
        },
        {
            "question": "What is ten divided by 3E+00?",
            "writeInAnswer": 3,
            "furtherInfo": {
                "explanation": "What is ten divided by 3E+00?<br /><br />Think of it as: 1E01 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
            }
        },
        {
            "question": "What is one hundred billion divided by 1E+02?",
            "writeInAnswer": 1000000000,
            "furtherInfo": {
                "explanation": "What is one hundred billion divided by 1E+02?<br /><br />Think of it as: 1E11 / 1E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+09."
            }
        },
        {
            "question": "What is ten million divided by 1E+06?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is ten million divided by 1E+06?<br /><br />Think of it as: 1E07 / 1E06.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
            }
        },
        {
            "question": "What is one thousand divided by 3E+02?",
            "writeInAnswer": 3,
            "furtherInfo": {
                "explanation": "What is one thousand divided by 3E+02?<br /><br />Think of it as: 1E03 / 3E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
            }
        },
        {
            "question": "What is three trillion divided by 3E+09?",
            "writeInAnswer": 1000,
            "furtherInfo": {
                "explanation": "What is three trillion divided by 3E+09?<br /><br />Think of it as: 3E12 / 3E09.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
            }
        },
        {
            "question": "What is thirty trillion divided by 1E+08?",
            "writeInAnswer": 300000,
            "furtherInfo": {
                "explanation": "What is thirty trillion divided by 1E+08?<br /><br />Think of it as: 3E13 / 1E08.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+05."
            }
        },
        {
            "question": "What is three million divided by 3E+02?",
            "writeInAnswer": 10000,
            "furtherInfo": {
                "explanation": "What is three million divided by 3E+02?<br /><br />Think of it as: 3E06 / 3E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+04."
            }
        },
        {
            "question": "What is one trillion divided by 3E+02?",
            "writeInAnswer": 3000000000,
            "furtherInfo": {
                "explanation": "What is one trillion divided by 3E+02?<br /><br />Think of it as: 1E12 / 3E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+09."
            }
        },
        {
            "question": "What is ten divided by 1E+00?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is ten divided by 1E+00?<br /><br />Think of it as: 1E01 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
            }
        },
        {
            "question": "What is thirty divided by 1E+00?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is thirty divided by 1E+00?<br /><br />Think of it as: 3E01 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
            }
        },
        {
            "question": "What is thirty trillion divided by 3E+08?",
            "writeInAnswer": 100000,
            "furtherInfo": {
                "explanation": "What is thirty trillion divided by 3E+08?<br /><br />Think of it as: 3E13 / 3E08.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+05."
            }
        },
        {
            "question": "What is one thousand divided by 1E+01?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is one thousand divided by 1E+01?<br /><br />Think of it as: 1E03 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
            }
        },
        {
            "question": "What is ten million divided by 1E+02?",
            "writeInAnswer": 100000,
            "furtherInfo": {
                "explanation": "What is ten million divided by 1E+02?<br /><br />Think of it as: 1E07 / 1E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+05."
            }
        },
        {
            "question": "What is three hundred million divided by 3E+03?",
            "writeInAnswer": 100000,
            "furtherInfo": {
                "explanation": "What is three hundred million divided by 3E+03?<br /><br />Think of it as: 3E08 / 3E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+05."
            }
        },
        {
            "question": "What is one hundred divided by 1E+01?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is one hundred divided by 1E+01?<br /><br />Think of it as: 1E02 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
            }
        },
        {
            "question": "What is thirty trillion divided by 3E+06?",
            "writeInAnswer": 10000000,
            "furtherInfo": {
                "explanation": "What is thirty trillion divided by 3E+06?<br /><br />Think of it as: 3E13 / 3E06.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+07."
            }
        },
        {
            "question": "What is one trillion divided by 3E+00?",
            "writeInAnswer": 300000000000,
            "furtherInfo": {
                "explanation": "What is one trillion divided by 3E+00?<br /><br />Think of it as: 1E12 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+11."
            }
        },
        {
            "question": "What is three hundred million divided by 3E+00?",
            "writeInAnswer": 100000000,
            "furtherInfo": {
                "explanation": "What is three hundred million divided by 3E+00?<br /><br />Think of it as: 3E08 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+08."
            }
        },
        {
            "question": "What is three million divided by 3E+03?",
            "writeInAnswer": 1000,
            "furtherInfo": {
                "explanation": "What is three million divided by 3E+03?<br /><br />Think of it as: 3E06 / 3E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
            }
        },
        {
            "question": "What is one hundred thousand divided by 1E+03?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is one hundred thousand divided by 1E+03?<br /><br />Think of it as: 1E05 / 1E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
            }
        },
        {
            "question": "What is ten million divided by 1E+06?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is ten million divided by 1E+06?<br /><br />Think of it as: 1E07 / 1E06.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
            }
        },
        {
            "question": "What is one hundred million divided by 3E+07?",
            "writeInAnswer": 3,
            "furtherInfo": {
                "explanation": "What is one hundred million divided by 3E+07?<br /><br />Think of it as: 1E08 / 3E07.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
            }
        },
        {
            "question": "What is one hundred thousand divided by 1E+01?",
            "writeInAnswer": 10000,
            "furtherInfo": {
                "explanation": "What is one hundred thousand divided by 1E+01?<br /><br />Think of it as: 1E05 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+04."
            }
        },
        {
            "question": "What is one trillion divided by 1E+05?",
            "writeInAnswer": 10000000,
            "furtherInfo": {
                "explanation": "What is one trillion divided by 1E+05?<br /><br />Think of it as: 1E12 / 1E05.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+07."
            }
        },
        {
            "question": "What is ten million divided by 3E+06?",
            "writeInAnswer": 3,
            "furtherInfo": {
                "explanation": "What is ten million divided by 3E+06?<br /><br />Think of it as: 1E07 / 3E06.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
            }
        },
        {
            "question": "What is ten million divided by 3E+04?",
            "writeInAnswer": 300,
            "furtherInfo": {
                "explanation": "What is ten million divided by 3E+04?<br /><br />Think of it as: 1E07 / 3E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+02."
            }
        },
        {
            "question": "What is three billion divided by 3E+02?",
            "writeInAnswer": 10000000,
            "furtherInfo": {
                "explanation": "What is three billion divided by 3E+02?<br /><br />Think of it as: 3E09 / 3E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+07."
            }
        },
        {
            "question": "What is three million divided by 1E+04?",
            "writeInAnswer": 300,
            "furtherInfo": {
                "explanation": "What is three million divided by 1E+04?<br /><br />Think of it as: 3E06 / 1E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+02."
            }
        },
        {
            "question": "What is ten trillion divided by 1E+05?",
            "writeInAnswer": 100000000,
            "furtherInfo": {
                "explanation": "What is ten trillion divided by 1E+05?<br /><br />Think of it as: 1E13 / 1E05.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+08."
            }
        },
        {
            "question": "What is one hundred divided by 1E+00?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is one hundred divided by 1E+00?<br /><br />Think of it as: 1E02 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
            }
        },
        {
            "question": "What is three billion divided by 3E+08?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is three billion divided by 3E+08?<br /><br />Think of it as: 3E09 / 3E08.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
            }
        },
        {
            "question": "What is one hundred thousand divided by 3E+02?",
            "writeInAnswer": 300,
            "furtherInfo": {
                "explanation": "What is one hundred thousand divided by 3E+02?<br /><br />Think of it as: 1E05 / 3E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+02."
            }
        },
        {
            "question": "What is one thousand divided by 1E+00?",
            "writeInAnswer": 1000,
            "furtherInfo": {
                "explanation": "What is one thousand divided by 1E+00?<br /><br />Think of it as: 1E03 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
            }
        },
        {
            "question": "What is one hundred million divided by 1E+02?",
            "writeInAnswer": 1000000,
            "furtherInfo": {
                "explanation": "What is one hundred million divided by 1E+02?<br /><br />Think of it as: 1E08 / 1E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+06."
            }
        },
        {
            "question": "What is thirty million divided by 3E+06?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is thirty million divided by 3E+06?<br /><br />Think of it as: 3E07 / 3E06.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
            }
        },
        {
            "question": "What is one hundred thousand divided by 3E+04?",
            "writeInAnswer": 3,
            "furtherInfo": {
                "explanation": "What is one hundred thousand divided by 3E+04?<br /><br />Think of it as: 1E05 / 3E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
            }
        },
        {
            "question": "What is one hundred thousand divided by 1E+03?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is one hundred thousand divided by 1E+03?<br /><br />Think of it as: 1E05 / 1E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
            }
        },
        {
            "question": "What is three trillion divided by 3E+09?",
            "writeInAnswer": 1000,
            "furtherInfo": {
                "explanation": "What is three trillion divided by 3E+09?<br /><br />Think of it as: 3E12 / 3E09.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
            }
        },
        {
            "question": "What is one trillion divided by 1E+00?",
            "writeInAnswer": 1000000000000,
            "furtherInfo": {
                "explanation": "What is one trillion divided by 1E+00?<br /><br />Think of it as: 1E12 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+12."
            }
        },
        {
            "question": "What is ten trillion divided by 1E+06?",
            "writeInAnswer": 10000000,
            "furtherInfo": {
                "explanation": "What is ten trillion divided by 1E+06?<br /><br />Think of it as: 1E13 / 1E06.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+07."
            }
        },
        {
            "question": "What is three million divided by 3E+02?",
            "writeInAnswer": 10000,
            "furtherInfo": {
                "explanation": "What is three million divided by 3E+02?<br /><br />Think of it as: 3E06 / 3E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+04."
            }
        },
        {
            "question": "What is thirty trillion divided by 3E+01?",
            "writeInAnswer": 1000000000000,
            "furtherInfo": {
                "explanation": "What is thirty trillion divided by 3E+01?<br /><br />Think of it as: 3E13 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+12."
            }
        },
        {
            "question": "What is thirty billion divided by 3E+04?",
            "writeInAnswer": 1000000,
            "furtherInfo": {
                "explanation": "What is thirty billion divided by 3E+04?<br /><br />Think of it as: 3E10 / 3E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+06."
            }
        },
        {
            "question": "What is three hundred thousand divided by 3E+00?",
            "writeInAnswer": 100000,
            "furtherInfo": {
                "explanation": "What is three hundred thousand divided by 3E+00?<br /><br />Think of it as: 3E05 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+05."
            }
        },
        {
            "question": "What is three hundred thousand divided by 1E+01?",
            "writeInAnswer": 30000,
            "furtherInfo": {
                "explanation": "What is three hundred thousand divided by 1E+01?<br /><br />Think of it as: 3E05 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+04."
            }
        },
        {
            "question": "What is one hundred billion divided by 3E+07?",
            "writeInAnswer": 3000,
            "furtherInfo": {
                "explanation": "What is one hundred billion divided by 3E+07?<br /><br />Think of it as: 1E11 / 3E07.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+03."
            }
        },
        {
            "question": "What is ten thousand divided by 3E+03?",
            "writeInAnswer": 3,
            "furtherInfo": {
                "explanation": "What is ten thousand divided by 3E+03?<br /><br />Think of it as: 1E04 / 3E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
            }
        },
        {
            "question": "What is one thousand divided by 1E+00?",
            "writeInAnswer": 1000,
            "furtherInfo": {
                "explanation": "What is one thousand divided by 1E+00?<br /><br />Think of it as: 1E03 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
            }
        },
        {
            "question": "What is three thousand divided by 1E+00?",
            "writeInAnswer": 3000,
            "furtherInfo": {
                "explanation": "What is three thousand divided by 1E+00?<br /><br />Think of it as: 3E03 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+03."
            }
        },
        {
            "question": "What is ten billion divided by 3E+08?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is ten billion divided by 3E+08?<br /><br />Think of it as: 1E10 / 3E08.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+01."
            }
        },
        {
            "question": "What is ten trillion divided by 3E+08?",
            "writeInAnswer": 30000,
            "furtherInfo": {
                "explanation": "What is ten trillion divided by 3E+08?<br /><br />Think of it as: 1E13 / 3E08.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+04."
            }
        },
        {
            "question": "What is thirty divided by 3E+00?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is thirty divided by 3E+00?<br /><br />Think of it as: 3E01 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
            }
        },
        {
            "question": "What is three hundred thousand divided by 3E+02?",
            "writeInAnswer": 1000,
            "furtherInfo": {
                "explanation": "What is three hundred thousand divided by 3E+02?<br /><br />Think of it as: 3E05 / 3E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
            }
        },
        {
            "question": "What is thirty billion divided by 3E+03?",
            "writeInAnswer": 10000000,
            "furtherInfo": {
                "explanation": "What is thirty billion divided by 3E+03?<br /><br />Think of it as: 3E10 / 3E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+07."
            }
        },
        {
            "question": "What is one hundred divided by 1E+01?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is one hundred divided by 1E+01?<br /><br />Think of it as: 1E02 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
            }
        },
        {
            "question": "What is three thousand divided by 1E+01?",
            "writeInAnswer": 300,
            "furtherInfo": {
                "explanation": "What is three thousand divided by 1E+01?<br /><br />Think of it as: 3E03 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+02."
            }
        },
        {
            "question": "What is three billion divided by 1E+03?",
            "writeInAnswer": 3000000,
            "furtherInfo": {
                "explanation": "What is three billion divided by 1E+03?<br /><br />Think of it as: 3E09 / 1E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+06."
            }
        },
        {
            "question": "What is ten trillion divided by 1E+11?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is ten trillion divided by 1E+11?<br /><br />Think of it as: 1E13 / 1E11.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
            }
        },
        {
            "question": "What is ten thousand divided by 3E+01?",
            "writeInAnswer": 300,
            "furtherInfo": {
                "explanation": "What is ten thousand divided by 3E+01?<br /><br />Think of it as: 1E04 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+02."
            }
        },
        {
            "question": "What is thirty million divided by 1E+05?",
            "writeInAnswer": 300,
            "furtherInfo": {
                "explanation": "What is thirty million divided by 1E+05?<br /><br />Think of it as: 3E07 / 1E05.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+02."
            }
        },
        {
            "question": "What is one trillion divided by 1E+09?",
            "writeInAnswer": 1000,
            "furtherInfo": {
                "explanation": "What is one trillion divided by 1E+09?<br /><br />Think of it as: 1E12 / 1E09.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
            }
        },
        {
            "question": "What is three hundred divided by 3E+00?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is three hundred divided by 3E+00?<br /><br />Think of it as: 3E02 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
            }
        },
        {
            "question": "What is thirty million divided by 1E+04?",
            "writeInAnswer": 3000,
            "furtherInfo": {
                "explanation": "What is thirty million divided by 1E+04?<br /><br />Think of it as: 3E07 / 1E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+03."
            }
        },
        {
            "question": "What is one hundred billion divided by 1E+03?",
            "writeInAnswer": 100000000,
            "furtherInfo": {
                "explanation": "What is one hundred billion divided by 1E+03?<br /><br />Think of it as: 1E11 / 1E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+08."
            }
        },
        {
            "question": "What is three hundred billion divided by 1E+03?",
            "writeInAnswer": 300000000,
            "furtherInfo": {
                "explanation": "What is three hundred billion divided by 1E+03?<br /><br />Think of it as: 3E11 / 1E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+08."
            }
        },
        {
            "question": "What is three trillion divided by 1E+03?",
            "writeInAnswer": 3000000000,
            "furtherInfo": {
                "explanation": "What is three trillion divided by 1E+03?<br /><br />Think of it as: 3E12 / 1E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+09."
            }
        },
        {
            "question": "What is thirty million divided by 1E+05?",
            "writeInAnswer": 300,
            "furtherInfo": {
                "explanation": "What is thirty million divided by 1E+05?<br /><br />Think of it as: 3E07 / 1E05.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+02."
            }
        },
        {
            "question": "What is one hundred million divided by 1E+05?",
            "writeInAnswer": 1000,
            "furtherInfo": {
                "explanation": "What is one hundred million divided by 1E+05?<br /><br />Think of it as: 1E08 / 1E05.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
            }
        },
        {
            "question": "What is ten divided by 3E+00?",
            "writeInAnswer": 3,
            "furtherInfo": {
                "explanation": "What is ten divided by 3E+00?<br /><br />Think of it as: 1E01 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
            }
        },
        {
            "question": "What is three thousand divided by 1E+01?",
            "writeInAnswer": 300,
            "furtherInfo": {
                "explanation": "What is three thousand divided by 1E+01?<br /><br />Think of it as: 3E03 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+02."
            }
        },
        {
            "question": "What is thirty million divided by 1E+01?",
            "writeInAnswer": 3000000,
            "furtherInfo": {
                "explanation": "What is thirty million divided by 1E+01?<br /><br />Think of it as: 3E07 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+06."
            }
        },
        {
            "question": "What is one million divided by 1E+04?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is one million divided by 1E+04?<br /><br />Think of it as: 1E06 / 1E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
            }
        },
        {
            "question": "What is one hundred million divided by 1E+05?",
            "writeInAnswer": 1000,
            "furtherInfo": {
                "explanation": "What is one hundred million divided by 1E+05?<br /><br />Think of it as: 1E08 / 1E05.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
            }
        },
        {
            "question": "What is ten thousand divided by 3E+01?",
            "writeInAnswer": 300,
            "furtherInfo": {
                "explanation": "What is ten thousand divided by 3E+01?<br /><br />Think of it as: 1E04 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+02."
            }
        },
        {
            "question": "What is ten million divided by 3E+00?",
            "writeInAnswer": 3000000,
            "furtherInfo": {
                "explanation": "What is ten million divided by 3E+00?<br /><br />Think of it as: 1E07 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+06."
            }
        },
        {
            "question": "What is one hundred million divided by 3E+07?",
            "writeInAnswer": 3,
            "furtherInfo": {
                "explanation": "What is one hundred million divided by 3E+07?<br /><br />Think of it as: 1E08 / 3E07.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
            }
        },
        {
            "question": "What is one hundred million divided by 3E+02?",
            "writeInAnswer": 300000,
            "furtherInfo": {
                "explanation": "What is one hundred million divided by 3E+02?<br /><br />Think of it as: 1E08 / 3E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+05."
            }
        },
        {
            "question": "What is one hundred divided by 3E+00?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is one hundred divided by 3E+00?<br /><br />Think of it as: 1E02 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+01."
            }
        },
        {
            "question": "What is one hundred billion divided by 1E+02?",
            "writeInAnswer": 1000000000,
            "furtherInfo": {
                "explanation": "What is one hundred billion divided by 1E+02?<br /><br />Think of it as: 1E11 / 1E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+09."
            }
        },
        {
            "question": "What is thirty divided by 3E+00?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is thirty divided by 3E+00?<br /><br />Think of it as: 3E01 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
            }
        },
        {
            "question": "What is one thousand divided by 1E+01?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is one thousand divided by 1E+01?<br /><br />Think of it as: 1E03 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
            }
        },
        {
            "question": "What is thirty divided by 3E+00?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is thirty divided by 3E+00?<br /><br />Think of it as: 3E01 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
            }
        },
        {
            "question": "What is one hundred divided by 3E+01?",
            "writeInAnswer": 3,
            "furtherInfo": {
                "explanation": "What is one hundred divided by 3E+01?<br /><br />Think of it as: 1E02 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
            }
        },
        {
            "question": "What is ten trillion divided by 3E+03?",
            "writeInAnswer": 3000000000,
            "furtherInfo": {
                "explanation": "What is ten trillion divided by 3E+03?<br /><br />Think of it as: 1E13 / 3E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+09."
            }
        },
        {
            "question": "What is one thousand divided by 3E+00?",
            "writeInAnswer": 300,
            "furtherInfo": {
                "explanation": "What is one thousand divided by 3E+00?<br /><br />Think of it as: 1E03 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+02."
            }
        },
        {
            "question": "What is one hundred divided by 3E+01?",
            "writeInAnswer": 3,
            "furtherInfo": {
                "explanation": "What is one hundred divided by 3E+01?<br /><br />Think of it as: 1E02 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
            }
        },
        {
            "question": "What is one billion divided by 3E+03?",
            "writeInAnswer": 300000,
            "furtherInfo": {
                "explanation": "What is one billion divided by 3E+03?<br /><br />Think of it as: 1E09 / 3E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+05."
            }
        },
        {
            "question": "What is three billion divided by 1E+03?",
            "writeInAnswer": 3000000,
            "furtherInfo": {
                "explanation": "What is three billion divided by 1E+03?<br /><br />Think of it as: 3E09 / 1E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+06."
            }
        },
        {
            "question": "What is one hundred billion divided by 3E+04?",
            "writeInAnswer": 3000000,
            "furtherInfo": {
                "explanation": "What is one hundred billion divided by 3E+04?<br /><br />Think of it as: 1E11 / 3E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+06."
            }
        },
        {
            "question": "What is three million divided by 3E+03?",
            "writeInAnswer": 1000,
            "furtherInfo": {
                "explanation": "What is three million divided by 3E+03?<br /><br />Think of it as: 3E06 / 3E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
            }
        },
        {
            "question": "What is three hundred thousand divided by 3E+02?",
            "writeInAnswer": 1000,
            "furtherInfo": {
                "explanation": "What is three hundred thousand divided by 3E+02?<br /><br />Think of it as: 3E05 / 3E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
            }
        },
        {
            "question": "What is one trillion divided by 3E+01?",
            "writeInAnswer": 30000000000,
            "furtherInfo": {
                "explanation": "What is one trillion divided by 3E+01?<br /><br />Think of it as: 1E12 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+10."
            }
        },
        {
            "question": "What is one thousand divided by 1E+01?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is one thousand divided by 1E+01?<br /><br />Think of it as: 1E03 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
            }
        },
        {
            "question": "What is thirty trillion divided by 1E+02?",
            "writeInAnswer": 300000000000,
            "furtherInfo": {
                "explanation": "What is thirty trillion divided by 1E+02?<br /><br />Think of it as: 3E13 / 1E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+11."
            }
        },
        {
            "question": "What is one hundred divided by 1E+00?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is one hundred divided by 1E+00?<br /><br />Think of it as: 1E02 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
            }
        },
        {
            "question": "What is one thousand divided by 1E+00?",
            "writeInAnswer": 1000,
            "furtherInfo": {
                "explanation": "What is one thousand divided by 1E+00?<br /><br />Think of it as: 1E03 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
            }
        },
        {
            "question": "What is ten thousand divided by 1E+01?",
            "writeInAnswer": 1000,
            "furtherInfo": {
                "explanation": "What is ten thousand divided by 1E+01?<br /><br />Think of it as: 1E04 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
            }
        },
        {
            "question": "What is one billion divided by 3E+05?",
            "writeInAnswer": 3000,
            "furtherInfo": {
                "explanation": "What is one billion divided by 3E+05?<br /><br />Think of it as: 1E09 / 3E05.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+03."
            }
        },
        {
            "question": "What is one hundred divided by 3E+01?",
            "writeInAnswer": 3,
            "furtherInfo": {
                "explanation": "What is one hundred divided by 3E+01?<br /><br />Think of it as: 1E02 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
            }
        },
        {
            "question": "What is three hundred divided by 1E+01?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is three hundred divided by 1E+01?<br /><br />Think of it as: 3E02 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
            }
        },
        {
            "question": "What is thirty thousand divided by 1E+03?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is thirty thousand divided by 1E+03?<br /><br />Think of it as: 3E04 / 1E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
            }
        },
        {
            "question": "What is three hundred thousand divided by 3E+02?",
            "writeInAnswer": 1000,
            "furtherInfo": {
                "explanation": "What is three hundred thousand divided by 3E+02?<br /><br />Think of it as: 3E05 / 3E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
            }
        },
        {
            "question": "What is three hundred divided by 1E+00?",
            "writeInAnswer": 300,
            "furtherInfo": {
                "explanation": "What is three hundred divided by 1E+00?<br /><br />Think of it as: 3E02 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+02."
            }
        },
        {
            "question": "What is thirty trillion divided by 3E+08?",
            "writeInAnswer": 100000,
            "furtherInfo": {
                "explanation": "What is thirty trillion divided by 3E+08?<br /><br />Think of it as: 3E13 / 3E08.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+05."
            }
        },
        {
            "question": "What is one hundred thousand divided by 1E+04?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is one hundred thousand divided by 1E+04?<br /><br />Think of it as: 1E05 / 1E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
            }
        },
        {
            "question": "What is three million divided by 1E+05?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is three million divided by 1E+05?<br /><br />Think of it as: 3E06 / 1E05.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
            }
        },
        {
            "question": "What is one hundred thousand divided by 3E+02?",
            "writeInAnswer": 300,
            "furtherInfo": {
                "explanation": "What is one hundred thousand divided by 3E+02?<br /><br />Think of it as: 1E05 / 3E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+02."
            }
        },
        {
            "question": "What is one thousand divided by 1E+02?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is one thousand divided by 1E+02?<br /><br />Think of it as: 1E03 / 1E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
            }
        },
        {
            "question": "What is thirty thousand divided by 1E+00?",
            "writeInAnswer": 30000,
            "furtherInfo": {
                "explanation": "What is thirty thousand divided by 1E+00?<br /><br />Think of it as: 3E04 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+04."
            }
        },
        {
            "question": "What is three hundred million divided by 1E+04?",
            "writeInAnswer": 30000,
            "furtherInfo": {
                "explanation": "What is three hundred million divided by 1E+04?<br /><br />Think of it as: 3E08 / 1E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+04."
            }
        },
        {
            "question": "What is one million divided by 1E+04?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is one million divided by 1E+04?<br /><br />Think of it as: 1E06 / 1E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
            }
        },
        {
            "question": "What is three million divided by 3E+00?",
            "writeInAnswer": 1000000,
            "furtherInfo": {
                "explanation": "What is three million divided by 3E+00?<br /><br />Think of it as: 3E06 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+06."
            }
        },
        {
            "question": "What is ten billion divided by 3E+03?",
            "writeInAnswer": 3000000,
            "furtherInfo": {
                "explanation": "What is ten billion divided by 3E+03?<br /><br />Think of it as: 1E10 / 3E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+06."
            }
        },
        {
            "question": "What is ten trillion divided by 3E+09?",
            "writeInAnswer": 3000,
            "furtherInfo": {
                "explanation": "What is ten trillion divided by 3E+09?<br /><br />Think of it as: 1E13 / 3E09.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+03."
            }
        },
        {
            "question": "What is one million divided by 1E+01?",
            "writeInAnswer": 100000,
            "furtherInfo": {
                "explanation": "What is one million divided by 1E+01?<br /><br />Think of it as: 1E06 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+05."
            }
        },
        {
            "question": "What is thirty billion divided by 1E+08?",
            "writeInAnswer": 300,
            "furtherInfo": {
                "explanation": "What is thirty billion divided by 1E+08?<br /><br />Think of it as: 3E10 / 1E08.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+02."
            }
        },
        {
            "question": "What is thirty million divided by 1E+05?",
            "writeInAnswer": 300,
            "furtherInfo": {
                "explanation": "What is thirty million divided by 1E+05?<br /><br />Think of it as: 3E07 / 1E05.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+02."
            }
        },
        {
            "question": "What is three hundred divided by 1E+00?",
            "writeInAnswer": 300,
            "furtherInfo": {
                "explanation": "What is three hundred divided by 1E+00?<br /><br />Think of it as: 3E02 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+02."
            }
        },
        {
            "question": "What is ten trillion divided by 1E+12?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is ten trillion divided by 1E+12?<br /><br />Think of it as: 1E13 / 1E12.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
            }
        },
        {
            "question": "What is one thousand divided by 3E+02?",
            "writeInAnswer": 3,
            "furtherInfo": {
                "explanation": "What is one thousand divided by 3E+02?<br /><br />Think of it as: 1E03 / 3E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
            }
        },
        {
            "question": "What is three hundred million divided by 1E+05?",
            "writeInAnswer": 3000,
            "furtherInfo": {
                "explanation": "What is three hundred million divided by 1E+05?<br /><br />Think of it as: 3E08 / 1E05.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+03."
            }
        },
        {
            "question": "What is thirty divided by 3E+00?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is thirty divided by 3E+00?<br /><br />Think of it as: 3E01 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
            }
        },
        {
            "question": "What is ten divided by 3E+00?",
            "writeInAnswer": 3,
            "furtherInfo": {
                "explanation": "What is ten divided by 3E+00?<br /><br />Think of it as: 1E01 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
            }
        },
        {
            "question": "What is three million divided by 3E+00?",
            "writeInAnswer": 1000000,
            "furtherInfo": {
                "explanation": "What is three million divided by 3E+00?<br /><br />Think of it as: 3E06 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+06."
            }
        },
        {
            "question": "What is ten million divided by 3E+00?",
            "writeInAnswer": 3000000,
            "furtherInfo": {
                "explanation": "What is ten million divided by 3E+00?<br /><br />Think of it as: 1E07 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+06."
            }
        },
        {
            "question": "What is three trillion divided by 1E+02?",
            "writeInAnswer": 30000000000,
            "furtherInfo": {
                "explanation": "What is three trillion divided by 1E+02?<br /><br />Think of it as: 3E12 / 1E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+10."
            }
        },
        {
            "question": "What is one hundred thousand divided by 1E+03?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is one hundred thousand divided by 1E+03?<br /><br />Think of it as: 1E05 / 1E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
            }
        },
        {
            "question": "What is one hundred thousand divided by 1E+02?",
            "writeInAnswer": 1000,
            "furtherInfo": {
                "explanation": "What is one hundred thousand divided by 1E+02?<br /><br />Think of it as: 1E05 / 1E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
            }
        },
        {
            "question": "What is one billion divided by 3E+08?",
            "writeInAnswer": 3,
            "furtherInfo": {
                "explanation": "What is one billion divided by 3E+08?<br /><br />Think of it as: 1E09 / 3E08.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
            }
        },
        {
            "question": "What is one hundred thousand divided by 1E+02?",
            "writeInAnswer": 1000,
            "furtherInfo": {
                "explanation": "What is one hundred thousand divided by 1E+02?<br /><br />Think of it as: 1E05 / 1E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
            }
        },
        {
            "question": "What is thirty billion divided by 3E+05?",
            "writeInAnswer": 100000,
            "furtherInfo": {
                "explanation": "What is thirty billion divided by 3E+05?<br /><br />Think of it as: 3E10 / 3E05.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+05."
            }
        },
        {
            "question": "What is ten trillion divided by 1E+06?",
            "writeInAnswer": 10000000,
            "furtherInfo": {
                "explanation": "What is ten trillion divided by 1E+06?<br /><br />Think of it as: 1E13 / 1E06.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+07."
            }
        },
        {
            "question": "What is ten divided by 1E+00?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is ten divided by 1E+00?<br /><br />Think of it as: 1E01 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
            }
        },
        {
            "question": "What is one hundred million divided by 1E+03?",
            "writeInAnswer": 100000,
            "furtherInfo": {
                "explanation": "What is one hundred million divided by 1E+03?<br /><br />Think of it as: 1E08 / 1E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+05."
            }
        },
        {
            "question": "What is one hundred thousand divided by 1E+04?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is one hundred thousand divided by 1E+04?<br /><br />Think of it as: 1E05 / 1E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
            }
        },
        {
            "question": "What is three thousand divided by 1E+00?",
            "writeInAnswer": 3000,
            "furtherInfo": {
                "explanation": "What is three thousand divided by 1E+00?<br /><br />Think of it as: 3E03 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+03."
            }
        },
        {
            "question": "What is ten trillion divided by 1E+08?",
            "writeInAnswer": 100000,
            "furtherInfo": {
                "explanation": "What is ten trillion divided by 1E+08?<br /><br />Think of it as: 1E13 / 1E08.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+05."
            }
        },
        {
            "question": "What is thirty million divided by 1E+02?",
            "writeInAnswer": 300000,
            "furtherInfo": {
                "explanation": "What is thirty million divided by 1E+02?<br /><br />Think of it as: 3E07 / 1E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+05."
            }
        },
        {
            "question": "What is three billion divided by 3E+07?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is three billion divided by 3E+07?<br /><br />Think of it as: 3E09 / 3E07.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
            }
        },
        {
            "question": "What is ten trillion divided by 1E+11?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is ten trillion divided by 1E+11?<br /><br />Think of it as: 1E13 / 1E11.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
            }
        },
        {
            "question": "What is three trillion divided by 3E+03?",
            "writeInAnswer": 1000000000,
            "furtherInfo": {
                "explanation": "What is three trillion divided by 3E+03?<br /><br />Think of it as: 3E12 / 3E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+09."
            }
        },
        {
            "question": "What is three hundred thousand divided by 3E+02?",
            "writeInAnswer": 1000,
            "furtherInfo": {
                "explanation": "What is three hundred thousand divided by 3E+02?<br /><br />Think of it as: 3E05 / 3E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
            }
        },
        {
            "question": "What is one million divided by 3E+02?",
            "writeInAnswer": 3000,
            "furtherInfo": {
                "explanation": "What is one million divided by 3E+02?<br /><br />Think of it as: 1E06 / 3E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+03."
            }
        },
        {
            "question": "What is one hundred billion divided by 1E+03?",
            "writeInAnswer": 100000000,
            "furtherInfo": {
                "explanation": "What is one hundred billion divided by 1E+03?<br /><br />Think of it as: 1E11 / 1E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+08."
            }
        },
        {
            "question": "What is one hundred billion divided by 3E+04?",
            "writeInAnswer": 3000000,
            "furtherInfo": {
                "explanation": "What is one hundred billion divided by 3E+04?<br /><br />Think of it as: 1E11 / 3E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+06."
            }
        },
        {
            "question": "What is three hundred divided by 3E+01?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is three hundred divided by 3E+01?<br /><br />Think of it as: 3E02 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
            }
        },
        {
            "question": "What is one thousand divided by 3E+00?",
            "writeInAnswer": 300,
            "furtherInfo": {
                "explanation": "What is one thousand divided by 3E+00?<br /><br />Think of it as: 1E03 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+02."
            }
        },
        {
            "question": "What is one hundred million divided by 3E+01?",
            "writeInAnswer": 3000000,
            "furtherInfo": {
                "explanation": "What is one hundred million divided by 3E+01?<br /><br />Think of it as: 1E08 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+06."
            }
        },
        {
            "question": "What is thirty divided by 1E+00?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is thirty divided by 1E+00?<br /><br />Think of it as: 3E01 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
            }
        },
        {
            "question": "What is three hundred divided by 3E+01?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is three hundred divided by 3E+01?<br /><br />Think of it as: 3E02 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
            }
        },
        {
            "question": "What is thirty divided by 3E+00?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is thirty divided by 3E+00?<br /><br />Think of it as: 3E01 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
            }
        },
        {
            "question": "What is three billion divided by 3E+02?",
            "writeInAnswer": 10000000,
            "furtherInfo": {
                "explanation": "What is three billion divided by 3E+02?<br /><br />Think of it as: 3E09 / 3E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+07."
            }
        },
        {
            "question": "What is ten thousand divided by 3E+01?",
            "writeInAnswer": 300,
            "furtherInfo": {
                "explanation": "What is ten thousand divided by 3E+01?<br /><br />Think of it as: 1E04 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+02."
            }
        },
        {
            "question": "What is one trillion divided by 1E+07?",
            "writeInAnswer": 100000,
            "furtherInfo": {
                "explanation": "What is one trillion divided by 1E+07?<br /><br />Think of it as: 1E12 / 1E07.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+05."
            }
        },
        {
            "question": "What is three hundred billion divided by 3E+04?",
            "writeInAnswer": 10000000,
            "furtherInfo": {
                "explanation": "What is three hundred billion divided by 3E+04?<br /><br />Think of it as: 3E11 / 3E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+07."
            }
        },
        {
            "question": "What is one million divided by 1E+03?",
            "writeInAnswer": 1000,
            "furtherInfo": {
                "explanation": "What is one million divided by 1E+03?<br /><br />Think of it as: 1E06 / 1E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
            }
        },
        {
            "question": "What is one thousand divided by 1E+00?",
            "writeInAnswer": 1000,
            "furtherInfo": {
                "explanation": "What is one thousand divided by 1E+00?<br /><br />Think of it as: 1E03 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
            }
        },
        {
            "question": "What is three hundred thousand divided by 1E+04?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is three hundred thousand divided by 1E+04?<br /><br />Think of it as: 3E05 / 1E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
            }
        },
        {
            "question": "What is three hundred billion divided by 3E+02?",
            "writeInAnswer": 1000000000,
            "furtherInfo": {
                "explanation": "What is three hundred billion divided by 3E+02?<br /><br />Think of it as: 3E11 / 3E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+09."
            }
        },
        {
            "question": "What is three million divided by 3E+02?",
            "writeInAnswer": 10000,
            "furtherInfo": {
                "explanation": "What is three million divided by 3E+02?<br /><br />Think of it as: 3E06 / 3E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+04."
            }
        },
        {
            "question": "What is three billion divided by 3E+00?",
            "writeInAnswer": 1000000000,
            "furtherInfo": {
                "explanation": "What is three billion divided by 3E+00?<br /><br />Think of it as: 3E09 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+09."
            }
        },
        {
            "question": "What is thirty billion divided by 1E+08?",
            "writeInAnswer": 300,
            "furtherInfo": {
                "explanation": "What is thirty billion divided by 1E+08?<br /><br />Think of it as: 3E10 / 1E08.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+02."
            }
        },
        {
            "question": "What is three hundred billion divided by 3E+03?",
            "writeInAnswer": 100000000,
            "furtherInfo": {
                "explanation": "What is three hundred billion divided by 3E+03?<br /><br />Think of it as: 3E11 / 3E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+08."
            }
        },
        {
            "question": "What is thirty trillion divided by 3E+04?",
            "writeInAnswer": 1000000000,
            "furtherInfo": {
                "explanation": "What is thirty trillion divided by 3E+04?<br /><br />Think of it as: 3E13 / 3E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+09."
            }
        },
        {
            "question": "What is three trillion divided by 1E+08?",
            "writeInAnswer": 30000,
            "furtherInfo": {
                "explanation": "What is three trillion divided by 1E+08?<br /><br />Think of it as: 3E12 / 1E08.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+04."
            }
        },
        {
            "question": "What is three billion divided by 3E+05?",
            "writeInAnswer": 10000,
            "furtherInfo": {
                "explanation": "What is three billion divided by 3E+05?<br /><br />Think of it as: 3E09 / 3E05.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+04."
            }
        },
        {
            "question": "What is ten million divided by 1E+06?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is ten million divided by 1E+06?<br /><br />Think of it as: 1E07 / 1E06.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
            }
        },
        {
            "question": "What is thirty trillion divided by 1E+03?",
            "writeInAnswer": 30000000000,
            "furtherInfo": {
                "explanation": "What is thirty trillion divided by 1E+03?<br /><br />Think of it as: 3E13 / 1E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+10."
            }
        },
        {
            "question": "What is thirty billion divided by 3E+04?",
            "writeInAnswer": 1000000,
            "furtherInfo": {
                "explanation": "What is thirty billion divided by 3E+04?<br /><br />Think of it as: 3E10 / 3E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+06."
            }
        },
        {
            "question": "What is one hundred thousand divided by 3E+01?",
            "writeInAnswer": 3000,
            "furtherInfo": {
                "explanation": "What is one hundred thousand divided by 3E+01?<br /><br />Think of it as: 1E05 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+03."
            }
        },
        {
            "question": "What is three hundred million divided by 3E+06?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is three hundred million divided by 3E+06?<br /><br />Think of it as: 3E08 / 3E06.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
            }
        },
        {
            "question": "What is thirty billion divided by 1E+09?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is thirty billion divided by 1E+09?<br /><br />Think of it as: 3E10 / 1E09.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
            }
        },
        {
            "question": "What is one hundred million divided by 1E+04?",
            "writeInAnswer": 10000,
            "furtherInfo": {
                "explanation": "What is one hundred million divided by 1E+04?<br /><br />Think of it as: 1E08 / 1E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+04."
            }
        },
        {
            "question": "What is one trillion divided by 1E+09?",
            "writeInAnswer": 1000,
            "furtherInfo": {
                "explanation": "What is one trillion divided by 1E+09?<br /><br />Think of it as: 1E12 / 1E09.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
            }
        },
        {
            "question": "What is thirty billion divided by 3E+05?",
            "writeInAnswer": 100000,
            "furtherInfo": {
                "explanation": "What is thirty billion divided by 3E+05?<br /><br />Think of it as: 3E10 / 3E05.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+05."
            }
        },
        {
            "question": "What is three billion divided by 1E+00?",
            "writeInAnswer": 3000000000,
            "furtherInfo": {
                "explanation": "What is three billion divided by 1E+00?<br /><br />Think of it as: 3E09 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+09."
            }
        },
        {
            "question": "What is one billion divided by 1E+08?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is one billion divided by 1E+08?<br /><br />Think of it as: 1E09 / 1E08.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
            }
        },
        {
            "question": "What is one thousand divided by 3E+00?",
            "writeInAnswer": 300,
            "furtherInfo": {
                "explanation": "What is one thousand divided by 3E+00?<br /><br />Think of it as: 1E03 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+02."
            }
        },
        {
            "question": "What is three million divided by 3E+05?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is three million divided by 3E+05?<br /><br />Think of it as: 3E06 / 3E05.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
            }
        },
        {
            "question": "What is one million divided by 1E+00?",
            "writeInAnswer": 1000000,
            "furtherInfo": {
                "explanation": "What is one million divided by 1E+00?<br /><br />Think of it as: 1E06 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+06."
            }
        },
        {
            "question": "What is one trillion divided by 1E+09?",
            "writeInAnswer": 1000,
            "furtherInfo": {
                "explanation": "What is one trillion divided by 1E+09?<br /><br />Think of it as: 1E12 / 1E09.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
            }
        },
        {
            "question": "What is three billion divided by 1E+01?",
            "writeInAnswer": 300000000,
            "furtherInfo": {
                "explanation": "What is three billion divided by 1E+01?<br /><br />Think of it as: 3E09 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+08."
            }
        },
        {
            "question": "What is three billion divided by 3E+07?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is three billion divided by 3E+07?<br /><br />Think of it as: 3E09 / 3E07.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
            }
        },
        {
            "question": "What is three trillion divided by 1E+01?",
            "writeInAnswer": 300000000000,
            "furtherInfo": {
                "explanation": "What is three trillion divided by 1E+01?<br /><br />Think of it as: 3E12 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+11."
            }
        },
        {
            "question": "What is one thousand divided by 3E+01?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is one thousand divided by 3E+01?<br /><br />Think of it as: 1E03 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+01."
            }
        },
        {
            "question": "What is ten thousand divided by 3E+02?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is ten thousand divided by 3E+02?<br /><br />Think of it as: 1E04 / 3E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+01."
            }
        },
        {
            "question": "What is ten million divided by 3E+00?",
            "writeInAnswer": 3000000,
            "furtherInfo": {
                "explanation": "What is ten million divided by 3E+00?<br /><br />Think of it as: 1E07 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+06."
            }
        },
        {
            "question": "What is one thousand divided by 1E+00?",
            "writeInAnswer": 1000,
            "furtherInfo": {
                "explanation": "What is one thousand divided by 1E+00?<br /><br />Think of it as: 1E03 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
            }
        },
        {
            "question": "What is thirty trillion divided by 1E+06?",
            "writeInAnswer": 30000000,
            "furtherInfo": {
                "explanation": "What is thirty trillion divided by 1E+06?<br /><br />Think of it as: 3E13 / 1E06.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+07."
            }
        },
        {
            "question": "What is one million divided by 3E+02?",
            "writeInAnswer": 3000,
            "furtherInfo": {
                "explanation": "What is one million divided by 3E+02?<br /><br />Think of it as: 1E06 / 3E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+03."
            }
        },
        {
            "question": "What is thirty divided by 1E+00?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is thirty divided by 1E+00?<br /><br />Think of it as: 3E01 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
            }
        },
        {
            "question": "What is ten trillion divided by 1E+00?",
            "writeInAnswer": 10000000000000,
            "furtherInfo": {
                "explanation": "What is ten trillion divided by 1E+00?<br /><br />Think of it as: 1E13 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+13."
            }
        },
        {
            "question": "What is one trillion divided by 3E+00?",
            "writeInAnswer": 300000000000,
            "furtherInfo": {
                "explanation": "What is one trillion divided by 3E+00?<br /><br />Think of it as: 1E12 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+11."
            }
        },
        {
            "question": "What is one trillion divided by 3E+04?",
            "writeInAnswer": 30000000,
            "furtherInfo": {
                "explanation": "What is one trillion divided by 3E+04?<br /><br />Think of it as: 1E12 / 3E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+07."
            }
        },
        {
            "question": "What is thirty billion divided by 3E+03?",
            "writeInAnswer": 10000000,
            "furtherInfo": {
                "explanation": "What is thirty billion divided by 3E+03?<br /><br />Think of it as: 3E10 / 3E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+07."
            }
        },
        {
            "question": "What is one hundred thousand divided by 1E+04?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is one hundred thousand divided by 1E+04?<br /><br />Think of it as: 1E05 / 1E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
            }
        },
        {
            "question": "What is thirty trillion divided by 1E+09?",
            "writeInAnswer": 30000,
            "furtherInfo": {
                "explanation": "What is thirty trillion divided by 1E+09?<br /><br />Think of it as: 3E13 / 1E09.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+04."
            }
        },
        {
            "question": "What is one hundred thousand divided by 1E+02?",
            "writeInAnswer": 1000,
            "furtherInfo": {
                "explanation": "What is one hundred thousand divided by 1E+02?<br /><br />Think of it as: 1E05 / 1E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
            }
        },
        {
            "question": "What is three trillion divided by 3E+06?",
            "writeInAnswer": 1000000,
            "furtherInfo": {
                "explanation": "What is three trillion divided by 3E+06?<br /><br />Think of it as: 3E12 / 3E06.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+06."
            }
        },
        {
            "question": "What is three hundred thousand divided by 1E+02?",
            "writeInAnswer": 3000,
            "furtherInfo": {
                "explanation": "What is three hundred thousand divided by 1E+02?<br /><br />Think of it as: 3E05 / 1E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+03."
            }
        },
        {
            "question": "What is thirty divided by 1E+00?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is thirty divided by 1E+00?<br /><br />Think of it as: 3E01 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
            }
        },
        {
            "question": "What is three hundred thousand divided by 3E+01?",
            "writeInAnswer": 10000,
            "furtherInfo": {
                "explanation": "What is three hundred thousand divided by 3E+01?<br /><br />Think of it as: 3E05 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+04."
            }
        },
        {
            "question": "What is thirty billion divided by 1E+05?",
            "writeInAnswer": 300000,
            "furtherInfo": {
                "explanation": "What is thirty billion divided by 1E+05?<br /><br />Think of it as: 3E10 / 1E05.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+05."
            }
        },
        {
            "question": "What is three hundred divided by 3E+00?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is three hundred divided by 3E+00?<br /><br />Think of it as: 3E02 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
            }
        },
        {
            "question": "What is three billion divided by 3E+00?",
            "writeInAnswer": 1000000000,
            "furtherInfo": {
                "explanation": "What is three billion divided by 3E+00?<br /><br />Think of it as: 3E09 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+09."
            }
        },
        {
            "question": "What is one hundred divided by 1E+01?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is one hundred divided by 1E+01?<br /><br />Think of it as: 1E02 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
            }
        },
        {
            "question": "What is thirty million divided by 1E+01?",
            "writeInAnswer": 3000000,
            "furtherInfo": {
                "explanation": "What is thirty million divided by 1E+01?<br /><br />Think of it as: 3E07 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+06."
            }
        },
        {
            "question": "What is one million divided by 3E+00?",
            "writeInAnswer": 300000,
            "furtherInfo": {
                "explanation": "What is one million divided by 3E+00?<br /><br />Think of it as: 1E06 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+05."
            }
        },
        {
            "question": "What is thirty divided by 1E+00?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is thirty divided by 1E+00?<br /><br />Think of it as: 3E01 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
            }
        },
        {
            "question": "What is one trillion divided by 1E+02?",
            "writeInAnswer": 10000000000,
            "furtherInfo": {
                "explanation": "What is one trillion divided by 1E+02?<br /><br />Think of it as: 1E12 / 1E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+10."
            }
        },
        {
            "question": "What is ten divided by 3E+00?",
            "writeInAnswer": 3,
            "furtherInfo": {
                "explanation": "What is ten divided by 3E+00?<br /><br />Think of it as: 1E01 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
            }
        },
        {
            "question": "What is one hundred million divided by 3E+06?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is one hundred million divided by 3E+06?<br /><br />Think of it as: 1E08 / 3E06.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+01."
            }
        },
        {
            "question": "What is ten thousand divided by 3E+01?",
            "writeInAnswer": 300,
            "furtherInfo": {
                "explanation": "What is ten thousand divided by 3E+01?<br /><br />Think of it as: 1E04 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+02."
            }
        },
        {
            "question": "What is thirty divided by 1E+00?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is thirty divided by 1E+00?<br /><br />Think of it as: 3E01 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
            }
        },
        {
            "question": "What is three hundred billion divided by 3E+02?",
            "writeInAnswer": 1000000000,
            "furtherInfo": {
                "explanation": "What is three hundred billion divided by 3E+02?<br /><br />Think of it as: 3E11 / 3E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+09."
            }
        },
        {
            "question": "What is one billion divided by 1E+06?",
            "writeInAnswer": 1000,
            "furtherInfo": {
                "explanation": "What is one billion divided by 1E+06?<br /><br />Think of it as: 1E09 / 1E06.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
            }
        },
        {
            "question": "What is three trillion divided by 3E+06?",
            "writeInAnswer": 1000000,
            "furtherInfo": {
                "explanation": "What is three trillion divided by 3E+06?<br /><br />Think of it as: 3E12 / 3E06.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+06."
            }
        },
        {
            "question": "What is three billion divided by 1E+04?",
            "writeInAnswer": 300000,
            "furtherInfo": {
                "explanation": "What is three billion divided by 1E+04?<br /><br />Think of it as: 3E09 / 1E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+05."
            }
        },
        {
            "question": "What is three trillion divided by 1E+08?",
            "writeInAnswer": 30000,
            "furtherInfo": {
                "explanation": "What is three trillion divided by 1E+08?<br /><br />Think of it as: 3E12 / 1E08.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+04."
            }
        },
        {
            "question": "What is three hundred billion divided by 1E+06?",
            "writeInAnswer": 300000,
            "furtherInfo": {
                "explanation": "What is three hundred billion divided by 1E+06?<br /><br />Think of it as: 3E11 / 1E06.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+05."
            }
        },
        {
            "question": "What is three hundred million divided by 3E+07?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is three hundred million divided by 3E+07?<br /><br />Think of it as: 3E08 / 3E07.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
            }
        },
        {
            "question": "What is ten divided by 3E+00?",
            "writeInAnswer": 3,
            "furtherInfo": {
                "explanation": "What is ten divided by 3E+00?<br /><br />Think of it as: 1E01 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
            }
        },
        {
            "question": "What is one hundred divided by 1E+00?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is one hundred divided by 1E+00?<br /><br />Think of it as: 1E02 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
            }
        },
        {
            "question": "What is three million divided by 1E+00?",
            "writeInAnswer": 3000000,
            "furtherInfo": {
                "explanation": "What is three million divided by 1E+00?<br /><br />Think of it as: 3E06 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+06."
            }
        },
        {
            "question": "What is one trillion divided by 1E+06?",
            "writeInAnswer": 1000000,
            "furtherInfo": {
                "explanation": "What is one trillion divided by 1E+06?<br /><br />Think of it as: 1E12 / 1E06.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+06."
            }
        },
        {
            "question": "What is three trillion divided by 3E+11?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is three trillion divided by 3E+11?<br /><br />Think of it as: 3E12 / 3E11.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
            }
        },
        {
            "question": "What is one billion divided by 1E+00?",
            "writeInAnswer": 1000000000,
            "furtherInfo": {
                "explanation": "What is one billion divided by 1E+00?<br /><br />Think of it as: 1E09 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+09."
            }
        },
        {
            "question": "What is ten trillion divided by 1E+05?",
            "writeInAnswer": 100000000,
            "furtherInfo": {
                "explanation": "What is ten trillion divided by 1E+05?<br /><br />Think of it as: 1E13 / 1E05.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+08."
            }
        },
        {
            "question": "What is ten billion divided by 3E+07?",
            "writeInAnswer": 300,
            "furtherInfo": {
                "explanation": "What is ten billion divided by 3E+07?<br /><br />Think of it as: 1E10 / 3E07.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+02."
            }
        },
        {
            "question": "What is thirty billion divided by 1E+03?",
            "writeInAnswer": 30000000,
            "furtherInfo": {
                "explanation": "What is thirty billion divided by 1E+03?<br /><br />Think of it as: 3E10 / 1E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+07."
            }
        },
        {
            "question": "What is one thousand divided by 3E+02?",
            "writeInAnswer": 3,
            "furtherInfo": {
                "explanation": "What is one thousand divided by 3E+02?<br /><br />Think of it as: 1E03 / 3E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
            }
        },
        {
            "question": "What is one million divided by 3E+03?",
            "writeInAnswer": 300,
            "furtherInfo": {
                "explanation": "What is one million divided by 3E+03?<br /><br />Think of it as: 1E06 / 3E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+02."
            }
        },
        {
            "question": "What is one million divided by 1E+01?",
            "writeInAnswer": 100000,
            "furtherInfo": {
                "explanation": "What is one million divided by 1E+01?<br /><br />Think of it as: 1E06 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+05."
            }
        },
        {
            "question": "What is one hundred thousand divided by 1E+04?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is one hundred thousand divided by 1E+04?<br /><br />Think of it as: 1E05 / 1E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
            }
        },
        {
            "question": "What is one hundred billion divided by 1E+05?",
            "writeInAnswer": 1000000,
            "furtherInfo": {
                "explanation": "What is one hundred billion divided by 1E+05?<br /><br />Think of it as: 1E11 / 1E05.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+06."
            }
        },
        {
            "question": "What is ten thousand divided by 3E+01?",
            "writeInAnswer": 300,
            "furtherInfo": {
                "explanation": "What is ten thousand divided by 3E+01?<br /><br />Think of it as: 1E04 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+02."
            }
        },
        {
            "question": "What is one hundred thousand divided by 3E+00?",
            "writeInAnswer": 30000,
            "furtherInfo": {
                "explanation": "What is one hundred thousand divided by 3E+00?<br /><br />Think of it as: 1E05 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+04."
            }
        },
        {
            "question": "What is three hundred billion divided by 1E+06?",
            "writeInAnswer": 300000,
            "furtherInfo": {
                "explanation": "What is three hundred billion divided by 1E+06?<br /><br />Think of it as: 3E11 / 1E06.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+05."
            }
        },
        {
            "question": "What is three hundred thousand divided by 1E+02?",
            "writeInAnswer": 3000,
            "furtherInfo": {
                "explanation": "What is three hundred thousand divided by 1E+02?<br /><br />Think of it as: 3E05 / 1E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+03."
            }
        },
        {
            "question": "What is one hundred billion divided by 3E+00?",
            "writeInAnswer": 30000000000,
            "furtherInfo": {
                "explanation": "What is one hundred billion divided by 3E+00?<br /><br />Think of it as: 1E11 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+10."
            }
        },
        {
            "question": "What is three trillion divided by 3E+05?",
            "writeInAnswer": 10000000,
            "furtherInfo": {
                "explanation": "What is three trillion divided by 3E+05?<br /><br />Think of it as: 3E12 / 3E05.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+07."
            }
        },
        {
            "question": "What is three hundred billion divided by 1E+06?",
            "writeInAnswer": 300000,
            "furtherInfo": {
                "explanation": "What is three hundred billion divided by 1E+06?<br /><br />Think of it as: 3E11 / 1E06.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+05."
            }
        },
        {
            "question": "What is one thousand divided by 3E+02?",
            "writeInAnswer": 3,
            "furtherInfo": {
                "explanation": "What is one thousand divided by 3E+02?<br /><br />Think of it as: 1E03 / 3E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
            }
        },
        {
            "question": "What is three hundred divided by 3E+00?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is three hundred divided by 3E+00?<br /><br />Think of it as: 3E02 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
            }
        },
        {
            "question": "What is three trillion divided by 3E+06?",
            "writeInAnswer": 1000000,
            "furtherInfo": {
                "explanation": "What is three trillion divided by 3E+06?<br /><br />Think of it as: 3E12 / 3E06.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+06."
            }
        },
        {
            "question": "What is one billion divided by 3E+03?",
            "writeInAnswer": 300000,
            "furtherInfo": {
                "explanation": "What is one billion divided by 3E+03?<br /><br />Think of it as: 1E09 / 3E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+05."
            }
        },
        {
            "question": "What is ten billion divided by 3E+04?",
            "writeInAnswer": 300000,
            "furtherInfo": {
                "explanation": "What is ten billion divided by 3E+04?<br /><br />Think of it as: 1E10 / 3E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+05."
            }
        },
        {
            "question": "What is thirty thousand divided by 3E+02?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is thirty thousand divided by 3E+02?<br /><br />Think of it as: 3E04 / 3E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
            }
        },
        {
            "question": "What is ten billion divided by 1E+06?",
            "writeInAnswer": 10000,
            "furtherInfo": {
                "explanation": "What is ten billion divided by 1E+06?<br /><br />Think of it as: 1E10 / 1E06.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+04."
            }
        },
        {
            "question": "What is one hundred million divided by 1E+03?",
            "writeInAnswer": 100000,
            "furtherInfo": {
                "explanation": "What is one hundred million divided by 1E+03?<br /><br />Think of it as: 1E08 / 1E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+05."
            }
        },
        {
            "question": "What is one thousand divided by 1E+01?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is one thousand divided by 1E+01?<br /><br />Think of it as: 1E03 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
            }
        },
        {
            "question": "What is one thousand divided by 3E+00?",
            "writeInAnswer": 300,
            "furtherInfo": {
                "explanation": "What is one thousand divided by 3E+00?<br /><br />Think of it as: 1E03 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+02."
            }
        },
        {
            "question": "What is three hundred million divided by 3E+00?",
            "writeInAnswer": 100000000,
            "furtherInfo": {
                "explanation": "What is three hundred million divided by 3E+00?<br /><br />Think of it as: 3E08 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+08."
            }
        },
        {
            "question": "What is three hundred divided by 1E+01?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is three hundred divided by 1E+01?<br /><br />Think of it as: 3E02 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
            }
        },
        {
            "question": "What is three trillion divided by 1E+10?",
            "writeInAnswer": 300,
            "furtherInfo": {
                "explanation": "What is three trillion divided by 1E+10?<br /><br />Think of it as: 3E12 / 1E10.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+02."
            }
        },
        {
            "question": "What is three billion divided by 3E+01?",
            "writeInAnswer": 100000000,
            "furtherInfo": {
                "explanation": "What is three billion divided by 3E+01?<br /><br />Think of it as: 3E09 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+08."
            }
        },
        {
            "question": "What is three billion divided by 1E+07?",
            "writeInAnswer": 300,
            "furtherInfo": {
                "explanation": "What is three billion divided by 1E+07?<br /><br />Think of it as: 3E09 / 1E07.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+02."
            }
        },
        {
            "question": "What is three billion divided by 1E+01?",
            "writeInAnswer": 300000000,
            "furtherInfo": {
                "explanation": "What is three billion divided by 1E+01?<br /><br />Think of it as: 3E09 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+08."
            }
        },
        {
            "question": "What is three thousand divided by 1E+02?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is three thousand divided by 1E+02?<br /><br />Think of it as: 3E03 / 1E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
            }
        },
        {
            "question": "What is three hundred million divided by 3E+00?",
            "writeInAnswer": 100000000,
            "furtherInfo": {
                "explanation": "What is three hundred million divided by 3E+00?<br /><br />Think of it as: 3E08 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+08."
            }
        },
        {
            "question": "What is three hundred thousand divided by 1E+03?",
            "writeInAnswer": 300,
            "furtherInfo": {
                "explanation": "What is three hundred thousand divided by 1E+03?<br /><br />Think of it as: 3E05 / 1E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+02."
            }
        },
        {
            "question": "What is one thousand divided by 3E+01?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is one thousand divided by 3E+01?<br /><br />Think of it as: 1E03 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+01."
            }
        },
        {
            "question": "What is one hundred divided by 1E+00?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is one hundred divided by 1E+00?<br /><br />Think of it as: 1E02 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
            }
        },
        {
            "question": "What is three hundred million divided by 1E+07?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is three hundred million divided by 1E+07?<br /><br />Think of it as: 3E08 / 1E07.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
            }
        },
        {
            "question": "What is ten trillion divided by 1E+09?",
            "writeInAnswer": 10000,
            "furtherInfo": {
                "explanation": "What is ten trillion divided by 1E+09?<br /><br />Think of it as: 1E13 / 1E09.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+04."
            }
        },
        {
            "question": "What is one hundred thousand divided by 3E+01?",
            "writeInAnswer": 3000,
            "furtherInfo": {
                "explanation": "What is one hundred thousand divided by 3E+01?<br /><br />Think of it as: 1E05 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+03."
            }
        },
        {
            "question": "What is one hundred divided by 1E+01?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is one hundred divided by 1E+01?<br /><br />Think of it as: 1E02 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
            }
        },
        {
            "question": "What is one hundred million divided by 3E+07?",
            "writeInAnswer": 3,
            "furtherInfo": {
                "explanation": "What is one hundred million divided by 3E+07?<br /><br />Think of it as: 1E08 / 3E07.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
            }
        },
        {
            "question": "What is three hundred divided by 1E+00?",
            "writeInAnswer": 300,
            "furtherInfo": {
                "explanation": "What is three hundred divided by 1E+00?<br /><br />Think of it as: 3E02 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+02."
            }
        },
        {
            "question": "What is one million divided by 3E+03?",
            "writeInAnswer": 300,
            "furtherInfo": {
                "explanation": "What is one million divided by 3E+03?<br /><br />Think of it as: 1E06 / 3E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+02."
            }
        },
        {
            "question": "What is one billion divided by 1E+06?",
            "writeInAnswer": 1000,
            "furtherInfo": {
                "explanation": "What is one billion divided by 1E+06?<br /><br />Think of it as: 1E09 / 1E06.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
            }
        },
        {
            "question": "What is one thousand divided by 1E+02?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is one thousand divided by 1E+02?<br /><br />Think of it as: 1E03 / 1E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
            }
        },
        {
            "question": "What is three thousand divided by 3E+01?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is three thousand divided by 3E+01?<br /><br />Think of it as: 3E03 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
            }
        },
        {
            "question": "What is three hundred million divided by 3E+06?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is three hundred million divided by 3E+06?<br /><br />Think of it as: 3E08 / 3E06.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
            }
        },
        {
            "question": "What is three million divided by 1E+03?",
            "writeInAnswer": 3000,
            "furtherInfo": {
                "explanation": "What is three million divided by 1E+03?<br /><br />Think of it as: 3E06 / 1E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+03."
            }
        },
        {
            "question": "What is three billion divided by 1E+05?",
            "writeInAnswer": 30000,
            "furtherInfo": {
                "explanation": "What is three billion divided by 1E+05?<br /><br />Think of it as: 3E09 / 1E05.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+04."
            }
        },
        {
            "question": "What is one million divided by 3E+03?",
            "writeInAnswer": 300,
            "furtherInfo": {
                "explanation": "What is one million divided by 3E+03?<br /><br />Think of it as: 1E06 / 3E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+02."
            }
        },
        {
            "question": "What is three hundred billion divided by 3E+01?",
            "writeInAnswer": 10000000000,
            "furtherInfo": {
                "explanation": "What is three hundred billion divided by 3E+01?<br /><br />Think of it as: 3E11 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+10."
            }
        },
        {
            "question": "What is ten million divided by 1E+02?",
            "writeInAnswer": 100000,
            "furtherInfo": {
                "explanation": "What is ten million divided by 1E+02?<br /><br />Think of it as: 1E07 / 1E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+05."
            }
        },
        {
            "question": "What is thirty billion divided by 1E+08?",
            "writeInAnswer": 300,
            "furtherInfo": {
                "explanation": "What is thirty billion divided by 1E+08?<br /><br />Think of it as: 3E10 / 1E08.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+02."
            }
        },
        {
            "question": "What is one hundred million divided by 1E+01?",
            "writeInAnswer": 10000000,
            "furtherInfo": {
                "explanation": "What is one hundred million divided by 1E+01?<br /><br />Think of it as: 1E08 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+07."
            }
        },
        {
            "question": "What is one million divided by 3E+05?",
            "writeInAnswer": 3,
            "furtherInfo": {
                "explanation": "What is one million divided by 3E+05?<br /><br />Think of it as: 1E06 / 3E05.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
            }
        },
        {
            "question": "What is three hundred million divided by 3E+07?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is three hundred million divided by 3E+07?<br /><br />Think of it as: 3E08 / 3E07.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
            }
        },
        {
            "question": "What is three thousand divided by 3E+01?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is three thousand divided by 3E+01?<br /><br />Think of it as: 3E03 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
            }
        },
        {
            "question": "What is ten divided by 3E+00?",
            "writeInAnswer": 3,
            "furtherInfo": {
                "explanation": "What is ten divided by 3E+00?<br /><br />Think of it as: 1E01 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
            }
        },
        {
            "question": "What is three hundred billion divided by 3E+01?",
            "writeInAnswer": 10000000000,
            "furtherInfo": {
                "explanation": "What is three hundred billion divided by 3E+01?<br /><br />Think of it as: 3E11 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+10."
            }
        },
        {
            "question": "What is three hundred billion divided by 1E+05?",
            "writeInAnswer": 3000000,
            "furtherInfo": {
                "explanation": "What is three hundred billion divided by 1E+05?<br /><br />Think of it as: 3E11 / 1E05.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+06."
            }
        },
        {
            "question": "What is one thousand divided by 3E+00?",
            "writeInAnswer": 300,
            "furtherInfo": {
                "explanation": "What is one thousand divided by 3E+00?<br /><br />Think of it as: 1E03 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+02."
            }
        },
        {
            "question": "What is three hundred thousand divided by 1E+02?",
            "writeInAnswer": 3000,
            "furtherInfo": {
                "explanation": "What is three hundred thousand divided by 1E+02?<br /><br />Think of it as: 3E05 / 1E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+03."
            }
        },
        {
            "question": "What is three hundred divided by 1E+00?",
            "writeInAnswer": 300,
            "furtherInfo": {
                "explanation": "What is three hundred divided by 1E+00?<br /><br />Think of it as: 3E02 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+02."
            }
        },
        {
            "question": "What is three hundred billion divided by 3E+00?",
            "writeInAnswer": 100000000000,
            "furtherInfo": {
                "explanation": "What is three hundred billion divided by 3E+00?<br /><br />Think of it as: 3E11 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+11."
            }
        },
        {
            "question": "What is one hundred divided by 3E+01?",
            "writeInAnswer": 3,
            "furtherInfo": {
                "explanation": "What is one hundred divided by 3E+01?<br /><br />Think of it as: 1E02 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
            }
        },
        {
            "question": "What is three thousand divided by 3E+01?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is three thousand divided by 3E+01?<br /><br />Think of it as: 3E03 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
            }
        },
        {
            "question": "What is three million divided by 3E+01?",
            "writeInAnswer": 100000,
            "furtherInfo": {
                "explanation": "What is three million divided by 3E+01?<br /><br />Think of it as: 3E06 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+05."
            }
        },
        {
            "question": "What is one hundred thousand divided by 1E+00?",
            "writeInAnswer": 100000,
            "furtherInfo": {
                "explanation": "What is one hundred thousand divided by 1E+00?<br /><br />Think of it as: 1E05 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+05."
            }
        },
        {
            "question": "What is thirty million divided by 3E+06?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is thirty million divided by 3E+06?<br /><br />Think of it as: 3E07 / 3E06.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
            }
        },
        {
            "question": "What is ten billion divided by 1E+06?",
            "writeInAnswer": 10000,
            "furtherInfo": {
                "explanation": "What is ten billion divided by 1E+06?<br /><br />Think of it as: 1E10 / 1E06.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+04."
            }
        },
        {
            "question": "What is thirty trillion divided by 1E+00?",
            "writeInAnswer": 30000000000000,
            "furtherInfo": {
                "explanation": "What is thirty trillion divided by 1E+00?<br /><br />Think of it as: 3E13 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+13."
            }
        },
        {
            "question": "What is one hundred thousand divided by 1E+00?",
            "writeInAnswer": 100000,
            "furtherInfo": {
                "explanation": "What is one hundred thousand divided by 1E+00?<br /><br />Think of it as: 1E05 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+05."
            }
        },
        {
            "question": "What is three hundred million divided by 1E+00?",
            "writeInAnswer": 300000000,
            "furtherInfo": {
                "explanation": "What is three hundred million divided by 1E+00?<br /><br />Think of it as: 3E08 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+08."
            }
        },
        {
            "question": "What is ten trillion divided by 3E+00?",
            "writeInAnswer": 3000000000000,
            "furtherInfo": {
                "explanation": "What is ten trillion divided by 3E+00?<br /><br />Think of it as: 1E13 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+12."
            }
        },
        {
            "question": "What is one hundred divided by 1E+01?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is one hundred divided by 1E+01?<br /><br />Think of it as: 1E02 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
            }
        },
        {
            "question": "What is three hundred million divided by 1E+01?",
            "writeInAnswer": 30000000,
            "furtherInfo": {
                "explanation": "What is three hundred million divided by 1E+01?<br /><br />Think of it as: 3E08 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+07."
            }
        },
        {
            "question": "What is one hundred divided by 3E+00?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is one hundred divided by 3E+00?<br /><br />Think of it as: 1E02 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+01."
            }
        },
        {
            "question": "What is thirty divided by 3E+00?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is thirty divided by 3E+00?<br /><br />Think of it as: 3E01 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
            }
        },
        {
            "question": "What is three hundred thousand divided by 1E+02?",
            "writeInAnswer": 3000,
            "furtherInfo": {
                "explanation": "What is three hundred thousand divided by 1E+02?<br /><br />Think of it as: 3E05 / 1E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+03."
            }
        },
        {
            "question": "What is ten trillion divided by 3E+08?",
            "writeInAnswer": 30000,
            "furtherInfo": {
                "explanation": "What is ten trillion divided by 3E+08?<br /><br />Think of it as: 1E13 / 3E08.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+04."
            }
        },
        {
            "question": "What is one trillion divided by 3E+07?",
            "writeInAnswer": 30000,
            "furtherInfo": {
                "explanation": "What is one trillion divided by 3E+07?<br /><br />Think of it as: 1E12 / 3E07.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+04."
            }
        },
        {
            "question": "What is thirty trillion divided by 1E+01?",
            "writeInAnswer": 3000000000000,
            "furtherInfo": {
                "explanation": "What is thirty trillion divided by 1E+01?<br /><br />Think of it as: 3E13 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+12."
            }
        },
        {
            "question": "What is one hundred billion divided by 3E+10?",
            "writeInAnswer": 3,
            "furtherInfo": {
                "explanation": "What is one hundred billion divided by 3E+10?<br /><br />Think of it as: 1E11 / 3E10.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
            }
        },
        {
            "question": "What is one hundred divided by 1E+00?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is one hundred divided by 1E+00?<br /><br />Think of it as: 1E02 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
            }
        },
        {
            "question": "What is ten thousand divided by 3E+01?",
            "writeInAnswer": 300,
            "furtherInfo": {
                "explanation": "What is ten thousand divided by 3E+01?<br /><br />Think of it as: 1E04 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+02."
            }
        },
        {
            "question": "What is three trillion divided by 3E+01?",
            "writeInAnswer": 100000000000,
            "furtherInfo": {
                "explanation": "What is three trillion divided by 3E+01?<br /><br />Think of it as: 3E12 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+11."
            }
        },
        {
            "question": "What is one hundred divided by 1E+00?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is one hundred divided by 1E+00?<br /><br />Think of it as: 1E02 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
            }
        },
        {
            "question": "What is one trillion divided by 1E+06?",
            "writeInAnswer": 1000000,
            "furtherInfo": {
                "explanation": "What is one trillion divided by 1E+06?<br /><br />Think of it as: 1E12 / 1E06.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+06."
            }
        },
        {
            "question": "What is thirty thousand divided by 3E+01?",
            "writeInAnswer": 1000,
            "furtherInfo": {
                "explanation": "What is thirty thousand divided by 3E+01?<br /><br />Think of it as: 3E04 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
            }
        },
        {
            "question": "What is ten divided by 3E+00?",
            "writeInAnswer": 3,
            "furtherInfo": {
                "explanation": "What is ten divided by 3E+00?<br /><br />Think of it as: 1E01 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
            }
        },
        {
            "question": "What is ten thousand divided by 3E+00?",
            "writeInAnswer": 3000,
            "furtherInfo": {
                "explanation": "What is ten thousand divided by 3E+00?<br /><br />Think of it as: 1E04 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+03."
            }
        },
        {
            "question": "What is one hundred million divided by 3E+01?",
            "writeInAnswer": 3000000,
            "furtherInfo": {
                "explanation": "What is one hundred million divided by 3E+01?<br /><br />Think of it as: 1E08 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+06."
            }
        },
        {
            "question": "What is one thousand divided by 1E+00?",
            "writeInAnswer": 1000,
            "furtherInfo": {
                "explanation": "What is one thousand divided by 1E+00?<br /><br />Think of it as: 1E03 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
            }
        },
        {
            "question": "What is ten million divided by 1E+02?",
            "writeInAnswer": 100000,
            "furtherInfo": {
                "explanation": "What is ten million divided by 1E+02?<br /><br />Think of it as: 1E07 / 1E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+05."
            }
        },
        {
            "question": "What is thirty thousand divided by 3E+01?",
            "writeInAnswer": 1000,
            "furtherInfo": {
                "explanation": "What is thirty thousand divided by 3E+01?<br /><br />Think of it as: 3E04 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
            }
        },
        {
            "question": "What is three hundred billion divided by 1E+07?",
            "writeInAnswer": 30000,
            "furtherInfo": {
                "explanation": "What is three hundred billion divided by 1E+07?<br /><br />Think of it as: 3E11 / 1E07.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+04."
            }
        },
        {
            "question": "What is three hundred billion divided by 1E+05?",
            "writeInAnswer": 3000000,
            "furtherInfo": {
                "explanation": "What is three hundred billion divided by 1E+05?<br /><br />Think of it as: 3E11 / 1E05.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+06."
            }
        },
        {
            "question": "What is one million divided by 3E+02?",
            "writeInAnswer": 3000,
            "furtherInfo": {
                "explanation": "What is one million divided by 3E+02?<br /><br />Think of it as: 1E06 / 3E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+03."
            }
        },
        {
            "question": "What is thirty trillion divided by 1E+05?",
            "writeInAnswer": 300000000,
            "furtherInfo": {
                "explanation": "What is thirty trillion divided by 1E+05?<br /><br />Think of it as: 3E13 / 1E05.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+08."
            }
        },
        {
            "question": "What is three billion divided by 1E+04?",
            "writeInAnswer": 300000,
            "furtherInfo": {
                "explanation": "What is three billion divided by 1E+04?<br /><br />Think of it as: 3E09 / 1E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+05."
            }
        },
        {
            "question": "What is one hundred thousand divided by 3E+01?",
            "writeInAnswer": 3000,
            "furtherInfo": {
                "explanation": "What is one hundred thousand divided by 3E+01?<br /><br />Think of it as: 1E05 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+03."
            }
        },
        {
            "question": "What is three million divided by 1E+02?",
            "writeInAnswer": 30000,
            "furtherInfo": {
                "explanation": "What is three million divided by 1E+02?<br /><br />Think of it as: 3E06 / 1E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+04."
            }
        },
        {
            "question": "What is one hundred divided by 3E+01?",
            "writeInAnswer": 3,
            "furtherInfo": {
                "explanation": "What is one hundred divided by 3E+01?<br /><br />Think of it as: 1E02 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
            }
        },
        {
            "question": "What is three billion divided by 3E+04?",
            "writeInAnswer": 100000,
            "furtherInfo": {
                "explanation": "What is three billion divided by 3E+04?<br /><br />Think of it as: 3E09 / 3E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+05."
            }
        },
        {
            "question": "What is thirty trillion divided by 3E+00?",
            "writeInAnswer": 10000000000000,
            "furtherInfo": {
                "explanation": "What is thirty trillion divided by 3E+00?<br /><br />Think of it as: 3E13 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+13."
            }
        },
        {
            "question": "What is three trillion divided by 1E+01?",
            "writeInAnswer": 300000000000,
            "furtherInfo": {
                "explanation": "What is three trillion divided by 1E+01?<br /><br />Think of it as: 3E12 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+11."
            }
        },
        {
            "question": "What is ten billion divided by 3E+01?",
            "writeInAnswer": 300000000,
            "furtherInfo": {
                "explanation": "What is ten billion divided by 3E+01?<br /><br />Think of it as: 1E10 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+08."
            }
        },
        {
            "question": "What is thirty divided by 1E+00?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is thirty divided by 1E+00?<br /><br />Think of it as: 3E01 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
            }
        },
        {
            "question": "What is one billion divided by 3E+01?",
            "writeInAnswer": 30000000,
            "furtherInfo": {
                "explanation": "What is one billion divided by 3E+01?<br /><br />Think of it as: 1E09 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+07."
            }
        },
        {
            "question": "What is ten thousand divided by 1E+00?",
            "writeInAnswer": 10000,
            "furtherInfo": {
                "explanation": "What is ten thousand divided by 1E+00?<br /><br />Think of it as: 1E04 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+04."
            }
        },
        {
            "question": "What is three billion divided by 3E+00?",
            "writeInAnswer": 1000000000,
            "furtherInfo": {
                "explanation": "What is three billion divided by 3E+00?<br /><br />Think of it as: 3E09 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+09."
            }
        },
        {
            "question": "What is ten billion divided by 3E+07?",
            "writeInAnswer": 300,
            "furtherInfo": {
                "explanation": "What is ten billion divided by 3E+07?<br /><br />Think of it as: 1E10 / 3E07.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+02."
            }
        },
        {
            "question": "What is three hundred million divided by 3E+05?",
            "writeInAnswer": 1000,
            "furtherInfo": {
                "explanation": "What is three hundred million divided by 3E+05?<br /><br />Think of it as: 3E08 / 3E05.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
            }
        },
        {
            "question": "What is ten divided by 3E+00?",
            "writeInAnswer": 3,
            "furtherInfo": {
                "explanation": "What is ten divided by 3E+00?<br /><br />Think of it as: 1E01 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
            }
        },
        {
            "question": "What is ten thousand divided by 1E+01?",
            "writeInAnswer": 1000,
            "furtherInfo": {
                "explanation": "What is ten thousand divided by 1E+01?<br /><br />Think of it as: 1E04 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
            }
        },
        {
            "question": "What is ten divided by 1E+00?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is ten divided by 1E+00?<br /><br />Think of it as: 1E01 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
            }
        },
        {
            "question": "What is ten trillion divided by 1E+12?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is ten trillion divided by 1E+12?<br /><br />Think of it as: 1E13 / 1E12.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
            }
        },
        {
            "question": "What is thirty thousand divided by 1E+01?",
            "writeInAnswer": 3000,
            "furtherInfo": {
                "explanation": "What is thirty thousand divided by 1E+01?<br /><br />Think of it as: 3E04 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+03."
            }
        },
        {
            "question": "What is three million divided by 1E+03?",
            "writeInAnswer": 3000,
            "furtherInfo": {
                "explanation": "What is three million divided by 1E+03?<br /><br />Think of it as: 3E06 / 1E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+03."
            }
        },
        {
            "question": "What is one hundred million divided by 3E+07?",
            "writeInAnswer": 3,
            "furtherInfo": {
                "explanation": "What is one hundred million divided by 3E+07?<br /><br />Think of it as: 1E08 / 3E07.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
            }
        },
        {
            "question": "What is one hundred thousand divided by 3E+02?",
            "writeInAnswer": 300,
            "furtherInfo": {
                "explanation": "What is one hundred thousand divided by 3E+02?<br /><br />Think of it as: 1E05 / 3E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+02."
            }
        },
        {
            "question": "What is one hundred divided by 1E+01?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is one hundred divided by 1E+01?<br /><br />Think of it as: 1E02 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
            }
        },
        {
            "question": "What is ten billion divided by 3E+07?",
            "writeInAnswer": 300,
            "furtherInfo": {
                "explanation": "What is ten billion divided by 3E+07?<br /><br />Think of it as: 1E10 / 3E07.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+02."
            }
        },
        {
            "question": "What is three hundred divided by 3E+00?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is three hundred divided by 3E+00?<br /><br />Think of it as: 3E02 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
            }
        },
        {
            "question": "What is thirty billion divided by 3E+03?",
            "writeInAnswer": 10000000,
            "furtherInfo": {
                "explanation": "What is thirty billion divided by 3E+03?<br /><br />Think of it as: 3E10 / 3E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+07."
            }
        },
        {
            "question": "What is three hundred divided by 3E+01?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is three hundred divided by 3E+01?<br /><br />Think of it as: 3E02 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
            }
        },
        {
            "question": "What is ten billion divided by 1E+03?",
            "writeInAnswer": 10000000,
            "furtherInfo": {
                "explanation": "What is ten billion divided by 1E+03?<br /><br />Think of it as: 1E10 / 1E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+07."
            }
        },
        {
            "question": "What is three hundred thousand divided by 3E+04?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is three hundred thousand divided by 3E+04?<br /><br />Think of it as: 3E05 / 3E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
            }
        },
        {
            "question": "What is three million divided by 1E+00?",
            "writeInAnswer": 3000000,
            "furtherInfo": {
                "explanation": "What is three million divided by 1E+00?<br /><br />Think of it as: 3E06 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+06."
            }
        },
        {
            "question": "What is one thousand divided by 1E+01?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is one thousand divided by 1E+01?<br /><br />Think of it as: 1E03 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
            }
        },
        {
            "question": "What is ten thousand divided by 3E+02?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is ten thousand divided by 3E+02?<br /><br />Think of it as: 1E04 / 3E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+01."
            }
        },
        {
            "question": "What is three trillion divided by 1E+04?",
            "writeInAnswer": 300000000,
            "furtherInfo": {
                "explanation": "What is three trillion divided by 1E+04?<br /><br />Think of it as: 3E12 / 1E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+08."
            }
        },
        {
            "question": "What is one billion divided by 1E+03?",
            "writeInAnswer": 1000000,
            "furtherInfo": {
                "explanation": "What is one billion divided by 1E+03?<br /><br />Think of it as: 1E09 / 1E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+06."
            }
        },
        {
            "question": "What is three hundred million divided by 3E+03?",
            "writeInAnswer": 100000,
            "furtherInfo": {
                "explanation": "What is three hundred million divided by 3E+03?<br /><br />Think of it as: 3E08 / 3E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+05."
            }
        },
        {
            "question": "What is three hundred million divided by 1E+04?",
            "writeInAnswer": 30000,
            "furtherInfo": {
                "explanation": "What is three hundred million divided by 1E+04?<br /><br />Think of it as: 3E08 / 1E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+04."
            }
        },
        {
            "question": "What is three hundred divided by 3E+00?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is three hundred divided by 3E+00?<br /><br />Think of it as: 3E02 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
            }
        },
        {
            "question": "What is one million divided by 3E+01?",
            "writeInAnswer": 30000,
            "furtherInfo": {
                "explanation": "What is one million divided by 3E+01?<br /><br />Think of it as: 1E06 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+04."
            }
        },
        {
            "question": "What is three hundred divided by 1E+01?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is three hundred divided by 1E+01?<br /><br />Think of it as: 3E02 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
            }
        },
        {
            "question": "What is ten billion divided by 1E+05?",
            "writeInAnswer": 100000,
            "furtherInfo": {
                "explanation": "What is ten billion divided by 1E+05?<br /><br />Think of it as: 1E10 / 1E05.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+05."
            }
        },
        {
            "question": "What is thirty billion divided by 1E+03?",
            "writeInAnswer": 30000000,
            "furtherInfo": {
                "explanation": "What is thirty billion divided by 1E+03?<br /><br />Think of it as: 3E10 / 1E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+07."
            }
        },
        {
            "question": "What is three hundred million divided by 1E+06?",
            "writeInAnswer": 300,
            "furtherInfo": {
                "explanation": "What is three hundred million divided by 1E+06?<br /><br />Think of it as: 3E08 / 1E06.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+02."
            }
        },
        {
            "question": "What is ten thousand divided by 1E+02?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is ten thousand divided by 1E+02?<br /><br />Think of it as: 1E04 / 1E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
            }
        },
        {
            "question": "What is three hundred billion divided by 1E+08?",
            "writeInAnswer": 3000,
            "furtherInfo": {
                "explanation": "What is three hundred billion divided by 1E+08?<br /><br />Think of it as: 3E11 / 1E08.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+03."
            }
        },
        {
            "question": "What is ten million divided by 3E+03?",
            "writeInAnswer": 3000,
            "furtherInfo": {
                "explanation": "What is ten million divided by 3E+03?<br /><br />Think of it as: 1E07 / 3E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+03."
            }
        },
        {
            "question": "What is thirty trillion divided by 1E+01?",
            "writeInAnswer": 3000000000000,
            "furtherInfo": {
                "explanation": "What is thirty trillion divided by 1E+01?<br /><br />Think of it as: 3E13 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+12."
            }
        },
        {
            "question": "What is ten billion divided by 1E+07?",
            "writeInAnswer": 1000,
            "furtherInfo": {
                "explanation": "What is ten billion divided by 1E+07?<br /><br />Think of it as: 1E10 / 1E07.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
            }
        },
        {
            "question": "What is ten thousand divided by 3E+02?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is ten thousand divided by 3E+02?<br /><br />Think of it as: 1E04 / 3E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+01."
            }
        },
        {
            "question": "What is three thousand divided by 3E+01?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is three thousand divided by 3E+01?<br /><br />Think of it as: 3E03 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
            }
        },
        {
            "question": "What is three hundred billion divided by 3E+07?",
            "writeInAnswer": 10000,
            "furtherInfo": {
                "explanation": "What is three hundred billion divided by 3E+07?<br /><br />Think of it as: 3E11 / 3E07.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+04."
            }
        },
        {
            "question": "What is ten million divided by 1E+02?",
            "writeInAnswer": 100000,
            "furtherInfo": {
                "explanation": "What is ten million divided by 1E+02?<br /><br />Think of it as: 1E07 / 1E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+05."
            }
        },
        {
            "question": "What is three hundred billion divided by 3E+02?",
            "writeInAnswer": 1000000000,
            "furtherInfo": {
                "explanation": "What is three hundred billion divided by 3E+02?<br /><br />Think of it as: 3E11 / 3E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+09."
            }
        },
        {
            "question": "What is thirty billion divided by 1E+04?",
            "writeInAnswer": 3000000,
            "furtherInfo": {
                "explanation": "What is thirty billion divided by 1E+04?<br /><br />Think of it as: 3E10 / 1E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+06."
            }
        },
        {
            "question": "What is one thousand divided by 1E+01?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is one thousand divided by 1E+01?<br /><br />Think of it as: 1E03 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
            }
        },
        {
            "question": "What is thirty billion divided by 1E+04?",
            "writeInAnswer": 3000000,
            "furtherInfo": {
                "explanation": "What is thirty billion divided by 1E+04?<br /><br />Think of it as: 3E10 / 1E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+06."
            }
        },
        {
            "question": "What is one trillion divided by 3E+10?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is one trillion divided by 3E+10?<br /><br />Think of it as: 1E12 / 3E10.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+01."
            }
        },
        {
            "question": "What is three billion divided by 3E+05?",
            "writeInAnswer": 10000,
            "furtherInfo": {
                "explanation": "What is three billion divided by 3E+05?<br /><br />Think of it as: 3E09 / 3E05.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+04."
            }
        },
        {
            "question": "What is one million divided by 3E+03?",
            "writeInAnswer": 300,
            "furtherInfo": {
                "explanation": "What is one million divided by 3E+03?<br /><br />Think of it as: 1E06 / 3E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+02."
            }
        },
        {
            "question": "What is ten divided by 3E+00?",
            "writeInAnswer": 3,
            "furtherInfo": {
                "explanation": "What is ten divided by 3E+00?<br /><br />Think of it as: 1E01 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
            }
        },
        {
            "question": "What is three million divided by 3E+04?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is three million divided by 3E+04?<br /><br />Think of it as: 3E06 / 3E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
            }
        },
        {
            "question": "What is ten million divided by 3E+04?",
            "writeInAnswer": 300,
            "furtherInfo": {
                "explanation": "What is ten million divided by 3E+04?<br /><br />Think of it as: 1E07 / 3E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+02."
            }
        },
        {
            "question": "What is three hundred divided by 1E+00?",
            "writeInAnswer": 300,
            "furtherInfo": {
                "explanation": "What is three hundred divided by 1E+00?<br /><br />Think of it as: 3E02 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+02."
            }
        },
        {
            "question": "What is three hundred thousand divided by 3E+02?",
            "writeInAnswer": 1000,
            "furtherInfo": {
                "explanation": "What is three hundred thousand divided by 3E+02?<br /><br />Think of it as: 3E05 / 3E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
            }
        },
        {
            "question": "What is three hundred billion divided by 3E+01?",
            "writeInAnswer": 10000000000,
            "furtherInfo": {
                "explanation": "What is three hundred billion divided by 3E+01?<br /><br />Think of it as: 3E11 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+10."
            }
        },
        {
            "question": "What is three hundred million divided by 3E+04?",
            "writeInAnswer": 10000,
            "furtherInfo": {
                "explanation": "What is three hundred million divided by 3E+04?<br /><br />Think of it as: 3E08 / 3E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+04."
            }
        },
        {
            "question": "What is one thousand divided by 1E+01?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is one thousand divided by 1E+01?<br /><br />Think of it as: 1E03 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
            }
        },
        {
            "question": "What is ten divided by 1E+00?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is ten divided by 1E+00?<br /><br />Think of it as: 1E01 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
            }
        },
        {
            "question": "What is thirty divided by 3E+00?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is thirty divided by 3E+00?<br /><br />Think of it as: 3E01 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
            }
        },
        {
            "question": "What is one hundred billion divided by 1E+02?",
            "writeInAnswer": 1000000000,
            "furtherInfo": {
                "explanation": "What is one hundred billion divided by 1E+02?<br /><br />Think of it as: 1E11 / 1E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+09."
            }
        },
        {
            "question": "What is thirty billion divided by 1E+06?",
            "writeInAnswer": 30000,
            "furtherInfo": {
                "explanation": "What is thirty billion divided by 1E+06?<br /><br />Think of it as: 3E10 / 1E06.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+04."
            }
        },
        {
            "question": "What is three million divided by 3E+04?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is three million divided by 3E+04?<br /><br />Think of it as: 3E06 / 3E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
            }
        },
        {
            "question": "What is three billion divided by 1E+01?",
            "writeInAnswer": 300000000,
            "furtherInfo": {
                "explanation": "What is three billion divided by 1E+01?<br /><br />Think of it as: 3E09 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+08."
            }
        },
        {
            "question": "What is thirty thousand divided by 3E+01?",
            "writeInAnswer": 1000,
            "furtherInfo": {
                "explanation": "What is thirty thousand divided by 3E+01?<br /><br />Think of it as: 3E04 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
            }
        },
        {
            "question": "What is one thousand divided by 1E+02?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is one thousand divided by 1E+02?<br /><br />Think of it as: 1E03 / 1E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
            }
        },
        {
            "question": "What is three hundred million divided by 3E+07?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is three hundred million divided by 3E+07?<br /><br />Think of it as: 3E08 / 3E07.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
            }
        },
        {
            "question": "What is one hundred million divided by 1E+04?",
            "writeInAnswer": 10000,
            "furtherInfo": {
                "explanation": "What is one hundred million divided by 1E+04?<br /><br />Think of it as: 1E08 / 1E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+04."
            }
        },
        {
            "question": "What is one hundred billion divided by 3E+10?",
            "writeInAnswer": 3,
            "furtherInfo": {
                "explanation": "What is one hundred billion divided by 3E+10?<br /><br />Think of it as: 1E11 / 3E10.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
            }
        },
        {
            "question": "What is three hundred billion divided by 3E+02?",
            "writeInAnswer": 1000000000,
            "furtherInfo": {
                "explanation": "What is three hundred billion divided by 3E+02?<br /><br />Think of it as: 3E11 / 3E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+09."
            }
        },
        {
            "question": "What is ten divided by 3E+00?",
            "writeInAnswer": 3,
            "furtherInfo": {
                "explanation": "What is ten divided by 3E+00?<br /><br />Think of it as: 1E01 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
            }
        },
        {
            "question": "What is thirty million divided by 3E+00?",
            "writeInAnswer": 10000000,
            "furtherInfo": {
                "explanation": "What is thirty million divided by 3E+00?<br /><br />Think of it as: 3E07 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+07."
            }
        },
        {
            "question": "What is ten divided by 1E+00?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is ten divided by 1E+00?<br /><br />Think of it as: 1E01 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
            }
        },
        {
            "question": "What is three hundred billion divided by 3E+01?",
            "writeInAnswer": 10000000000,
            "furtherInfo": {
                "explanation": "What is three hundred billion divided by 3E+01?<br /><br />Think of it as: 3E11 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+10."
            }
        },
        {
            "question": "What is three billion divided by 1E+05?",
            "writeInAnswer": 30000,
            "furtherInfo": {
                "explanation": "What is three billion divided by 1E+05?<br /><br />Think of it as: 3E09 / 1E05.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+04."
            }
        },
        {
            "question": "What is thirty trillion divided by 3E+06?",
            "writeInAnswer": 10000000,
            "furtherInfo": {
                "explanation": "What is thirty trillion divided by 3E+06?<br /><br />Think of it as: 3E13 / 3E06.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+07."
            }
        },
        {
            "question": "What is three million divided by 3E+03?",
            "writeInAnswer": 1000,
            "furtherInfo": {
                "explanation": "What is three million divided by 3E+03?<br /><br />Think of it as: 3E06 / 3E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
            }
        },
        {
            "question": "What is thirty billion divided by 1E+00?",
            "writeInAnswer": 30000000000,
            "furtherInfo": {
                "explanation": "What is thirty billion divided by 1E+00?<br /><br />Think of it as: 3E10 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+10."
            }
        },
        {
            "question": "What is one trillion divided by 3E+10?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is one trillion divided by 3E+10?<br /><br />Think of it as: 1E12 / 3E10.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+01."
            }
        },
        {
            "question": "What is three thousand divided by 3E+00?",
            "writeInAnswer": 1000,
            "furtherInfo": {
                "explanation": "What is three thousand divided by 3E+00?<br /><br />Think of it as: 3E03 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
            }
        },
        {
            "question": "What is three hundred billion divided by 1E+04?",
            "writeInAnswer": 30000000,
            "furtherInfo": {
                "explanation": "What is three hundred billion divided by 1E+04?<br /><br />Think of it as: 3E11 / 1E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+07."
            }
        },
        {
            "question": "What is thirty divided by 3E+00?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is thirty divided by 3E+00?<br /><br />Think of it as: 3E01 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
            }
        },
        {
            "question": "What is ten million divided by 3E+00?",
            "writeInAnswer": 3000000,
            "furtherInfo": {
                "explanation": "What is ten million divided by 3E+00?<br /><br />Think of it as: 1E07 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+06."
            }
        },
        {
            "question": "What is three trillion divided by 3E+11?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is three trillion divided by 3E+11?<br /><br />Think of it as: 3E12 / 3E11.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
            }
        },
        {
            "question": "What is thirty million divided by 1E+03?",
            "writeInAnswer": 30000,
            "furtherInfo": {
                "explanation": "What is thirty million divided by 1E+03?<br /><br />Think of it as: 3E07 / 1E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+04."
            }
        },
        {
            "question": "What is three million divided by 3E+02?",
            "writeInAnswer": 10000,
            "furtherInfo": {
                "explanation": "What is three million divided by 3E+02?<br /><br />Think of it as: 3E06 / 3E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+04."
            }
        },
        {
            "question": "What is ten divided by 1E+00?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is ten divided by 1E+00?<br /><br />Think of it as: 1E01 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
            }
        },
        {
            "question": "What is three hundred thousand divided by 1E+02?",
            "writeInAnswer": 3000,
            "furtherInfo": {
                "explanation": "What is three hundred thousand divided by 1E+02?<br /><br />Think of it as: 3E05 / 1E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+03."
            }
        },
        {
            "question": "What is three trillion divided by 1E+02?",
            "writeInAnswer": 30000000000,
            "furtherInfo": {
                "explanation": "What is three trillion divided by 1E+02?<br /><br />Think of it as: 3E12 / 1E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+10."
            }
        },
        {
            "question": "What is one hundred billion divided by 3E+02?",
            "writeInAnswer": 300000000,
            "furtherInfo": {
                "explanation": "What is one hundred billion divided by 3E+02?<br /><br />Think of it as: 1E11 / 3E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+08."
            }
        },
        {
            "question": "What is one thousand divided by 3E+01?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is one thousand divided by 3E+01?<br /><br />Think of it as: 1E03 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+01."
            }
        },
        {
            "question": "What is three thousand divided by 3E+02?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is three thousand divided by 3E+02?<br /><br />Think of it as: 3E03 / 3E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
            }
        },
        {
            "question": "What is ten thousand divided by 3E+02?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is ten thousand divided by 3E+02?<br /><br />Think of it as: 1E04 / 3E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+01."
            }
        },
        {
            "question": "What is one hundred million divided by 1E+06?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is one hundred million divided by 1E+06?<br /><br />Think of it as: 1E08 / 1E06.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
            }
        },
        {
            "question": "What is one hundred thousand divided by 1E+04?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is one hundred thousand divided by 1E+04?<br /><br />Think of it as: 1E05 / 1E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
            }
        },
        {
            "question": "What is one million divided by 1E+01?",
            "writeInAnswer": 100000,
            "furtherInfo": {
                "explanation": "What is one million divided by 1E+01?<br /><br />Think of it as: 1E06 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+05."
            }
        },
        {
            "question": "What is one hundred billion divided by 1E+02?",
            "writeInAnswer": 1000000000,
            "furtherInfo": {
                "explanation": "What is one hundred billion divided by 1E+02?<br /><br />Think of it as: 1E11 / 1E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+09."
            }
        },
        {
            "question": "What is three million divided by 3E+05?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is three million divided by 3E+05?<br /><br />Think of it as: 3E06 / 3E05.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
            }
        },
        {
            "question": "What is three million divided by 3E+00?",
            "writeInAnswer": 1000000,
            "furtherInfo": {
                "explanation": "What is three million divided by 3E+00?<br /><br />Think of it as: 3E06 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+06."
            }
        },
        {
            "question": "What is one hundred thousand divided by 1E+03?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is one hundred thousand divided by 1E+03?<br /><br />Think of it as: 1E05 / 1E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
            }
        },
        {
            "question": "What is thirty divided by 1E+00?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is thirty divided by 1E+00?<br /><br />Think of it as: 3E01 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
            }
        },
        {
            "question": "What is three trillion divided by 1E+09?",
            "writeInAnswer": 3000,
            "furtherInfo": {
                "explanation": "What is three trillion divided by 1E+09?<br /><br />Think of it as: 3E12 / 1E09.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+03."
            }
        },
        {
            "question": "What is one million divided by 3E+01?",
            "writeInAnswer": 30000,
            "furtherInfo": {
                "explanation": "What is one million divided by 3E+01?<br /><br />Think of it as: 1E06 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+04."
            }
        },
        {
            "question": "What is thirty divided by 3E+00?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is thirty divided by 3E+00?<br /><br />Think of it as: 3E01 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
            }
        },
        {
            "question": "What is one billion divided by 3E+00?",
            "writeInAnswer": 300000000,
            "furtherInfo": {
                "explanation": "What is one billion divided by 3E+00?<br /><br />Think of it as: 1E09 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+08."
            }
        },
        {
            "question": "What is one thousand divided by 1E+01?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is one thousand divided by 1E+01?<br /><br />Think of it as: 1E03 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
            }
        },
        {
            "question": "What is thirty million divided by 3E+06?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is thirty million divided by 3E+06?<br /><br />Think of it as: 3E07 / 3E06.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
            }
        },
        {
            "question": "What is three hundred thousand divided by 3E+02?",
            "writeInAnswer": 1000,
            "furtherInfo": {
                "explanation": "What is three hundred thousand divided by 3E+02?<br /><br />Think of it as: 3E05 / 3E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
            }
        },
        {
            "question": "What is thirty divided by 3E+00?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is thirty divided by 3E+00?<br /><br />Think of it as: 3E01 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
            }
        },
        {
            "question": "What is three hundred divided by 1E+01?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is three hundred divided by 1E+01?<br /><br />Think of it as: 3E02 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
            }
        },
        {
            "question": "What is ten million divided by 3E+00?",
            "writeInAnswer": 3000000,
            "furtherInfo": {
                "explanation": "What is ten million divided by 3E+00?<br /><br />Think of it as: 1E07 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+06."
            }
        },
        {
            "question": "What is one hundred million divided by 1E+05?",
            "writeInAnswer": 1000,
            "furtherInfo": {
                "explanation": "What is one hundred million divided by 1E+05?<br /><br />Think of it as: 1E08 / 1E05.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
            }
        },
        {
            "question": "What is three hundred million divided by 3E+01?",
            "writeInAnswer": 10000000,
            "furtherInfo": {
                "explanation": "What is three hundred million divided by 3E+01?<br /><br />Think of it as: 3E08 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+07."
            }
        },
        {
            "question": "What is one hundred divided by 1E+00?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is one hundred divided by 1E+00?<br /><br />Think of it as: 1E02 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
            }
        },
        {
            "question": "What is three hundred thousand divided by 3E+00?",
            "writeInAnswer": 100000,
            "furtherInfo": {
                "explanation": "What is three hundred thousand divided by 3E+00?<br /><br />Think of it as: 3E05 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+05."
            }
        },
        {
            "question": "What is one million divided by 1E+03?",
            "writeInAnswer": 1000,
            "furtherInfo": {
                "explanation": "What is one million divided by 1E+03?<br /><br />Think of it as: 1E06 / 1E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
            }
        },
        {
            "question": "What is ten trillion divided by 1E+03?",
            "writeInAnswer": 10000000000,
            "furtherInfo": {
                "explanation": "What is ten trillion divided by 1E+03?<br /><br />Think of it as: 1E13 / 1E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+10."
            }
        },
        {
            "question": "What is ten billion divided by 1E+06?",
            "writeInAnswer": 10000,
            "furtherInfo": {
                "explanation": "What is ten billion divided by 1E+06?<br /><br />Think of it as: 1E10 / 1E06.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+04."
            }
        },
        {
            "question": "What is one hundred thousand divided by 1E+01?",
            "writeInAnswer": 10000,
            "furtherInfo": {
                "explanation": "What is one hundred thousand divided by 1E+01?<br /><br />Think of it as: 1E05 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+04."
            }
        },
        {
            "question": "What is one hundred thousand divided by 1E+03?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is one hundred thousand divided by 1E+03?<br /><br />Think of it as: 1E05 / 1E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
            }
        },
        {
            "question": "What is three thousand divided by 3E+00?",
            "writeInAnswer": 1000,
            "furtherInfo": {
                "explanation": "What is three thousand divided by 3E+00?<br /><br />Think of it as: 3E03 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
            }
        },
        {
            "question": "What is ten divided by 3E+00?",
            "writeInAnswer": 3,
            "furtherInfo": {
                "explanation": "What is ten divided by 3E+00?<br /><br />Think of it as: 1E01 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
            }
        },
        {
            "question": "What is three million divided by 3E+03?",
            "writeInAnswer": 1000,
            "furtherInfo": {
                "explanation": "What is three million divided by 3E+03?<br /><br />Think of it as: 3E06 / 3E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
            }
        },
        {
            "question": "What is three million divided by 1E+00?",
            "writeInAnswer": 3000000,
            "furtherInfo": {
                "explanation": "What is three million divided by 1E+00?<br /><br />Think of it as: 3E06 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+06."
            }
        },
        {
            "question": "What is thirty thousand divided by 1E+00?",
            "writeInAnswer": 30000,
            "furtherInfo": {
                "explanation": "What is thirty thousand divided by 1E+00?<br /><br />Think of it as: 3E04 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+04."
            }
        },
        {
            "question": "What is three hundred divided by 3E+01?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is three hundred divided by 3E+01?<br /><br />Think of it as: 3E02 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
            }
        },
        {
            "question": "What is three trillion divided by 1E+01?",
            "writeInAnswer": 300000000000,
            "furtherInfo": {
                "explanation": "What is three trillion divided by 1E+01?<br /><br />Think of it as: 3E12 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+11."
            }
        },
        {
            "question": "What is three thousand divided by 3E+01?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is three thousand divided by 3E+01?<br /><br />Think of it as: 3E03 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
            }
        },
        {
            "question": "What is thirty divided by 3E+00?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is thirty divided by 3E+00?<br /><br />Think of it as: 3E01 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
            }
        },
        {
            "question": "What is one hundred billion divided by 3E+04?",
            "writeInAnswer": 3000000,
            "furtherInfo": {
                "explanation": "What is one hundred billion divided by 3E+04?<br /><br />Think of it as: 1E11 / 3E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+06."
            }
        },
        {
            "question": "What is one trillion divided by 3E+11?",
            "writeInAnswer": 3,
            "furtherInfo": {
                "explanation": "What is one trillion divided by 3E+11?<br /><br />Think of it as: 1E12 / 3E11.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
            }
        },
        {
            "question": "What is three thousand divided by 3E+02?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is three thousand divided by 3E+02?<br /><br />Think of it as: 3E03 / 3E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
            }
        },
        {
            "question": "What is three hundred divided by 1E+00?",
            "writeInAnswer": 300,
            "furtherInfo": {
                "explanation": "What is three hundred divided by 1E+00?<br /><br />Think of it as: 3E02 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+02."
            }
        },
        {
            "question": "What is ten thousand divided by 1E+01?",
            "writeInAnswer": 1000,
            "furtherInfo": {
                "explanation": "What is ten thousand divided by 1E+01?<br /><br />Think of it as: 1E04 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
            }
        },
        {
            "question": "What is one hundred thousand divided by 1E+00?",
            "writeInAnswer": 100000,
            "furtherInfo": {
                "explanation": "What is one hundred thousand divided by 1E+00?<br /><br />Think of it as: 1E05 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+05."
            }
        },
        {
            "question": "What is three hundred divided by 3E+01?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is three hundred divided by 3E+01?<br /><br />Think of it as: 3E02 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
            }
        },
        {
            "question": "What is thirty million divided by 3E+04?",
            "writeInAnswer": 1000,
            "furtherInfo": {
                "explanation": "What is thirty million divided by 3E+04?<br /><br />Think of it as: 3E07 / 3E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
            }
        },
        {
            "question": "What is ten million divided by 3E+02?",
            "writeInAnswer": 30000,
            "furtherInfo": {
                "explanation": "What is ten million divided by 3E+02?<br /><br />Think of it as: 1E07 / 3E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+04."
            }
        },
        {
            "question": "What is ten divided by 3E+00?",
            "writeInAnswer": 3,
            "furtherInfo": {
                "explanation": "What is ten divided by 3E+00?<br /><br />Think of it as: 1E01 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
            }
        },
        {
            "question": "What is thirty billion divided by 1E+05?",
            "writeInAnswer": 300000,
            "furtherInfo": {
                "explanation": "What is thirty billion divided by 1E+05?<br /><br />Think of it as: 3E10 / 1E05.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+05."
            }
        },
        {
            "question": "What is thirty trillion divided by 3E+00?",
            "writeInAnswer": 10000000000000,
            "furtherInfo": {
                "explanation": "What is thirty trillion divided by 3E+00?<br /><br />Think of it as: 3E13 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+13."
            }
        },
        {
            "question": "What is thirty divided by 1E+00?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is thirty divided by 1E+00?<br /><br />Think of it as: 3E01 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
            }
        },
        {
            "question": "What is one hundred divided by 3E+01?",
            "writeInAnswer": 3,
            "furtherInfo": {
                "explanation": "What is one hundred divided by 3E+01?<br /><br />Think of it as: 1E02 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
            }
        },
        {
            "question": "What is thirty divided by 1E+00?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is thirty divided by 1E+00?<br /><br />Think of it as: 3E01 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
            }
        },
        {
            "question": "What is ten billion divided by 1E+02?",
            "writeInAnswer": 100000000,
            "furtherInfo": {
                "explanation": "What is ten billion divided by 1E+02?<br /><br />Think of it as: 1E10 / 1E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+08."
            }
        },
        {
            "question": "What is three hundred million divided by 3E+01?",
            "writeInAnswer": 10000000,
            "furtherInfo": {
                "explanation": "What is three hundred million divided by 3E+01?<br /><br />Think of it as: 3E08 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+07."
            }
        },
        {
            "question": "What is ten trillion divided by 1E+12?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is ten trillion divided by 1E+12?<br /><br />Think of it as: 1E13 / 1E12.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
            }
        },
        {
            "question": "What is three hundred thousand divided by 3E+02?",
            "writeInAnswer": 1000,
            "furtherInfo": {
                "explanation": "What is three hundred thousand divided by 3E+02?<br /><br />Think of it as: 3E05 / 3E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
            }
        },
        {
            "question": "What is one hundred billion divided by 3E+03?",
            "writeInAnswer": 30000000,
            "furtherInfo": {
                "explanation": "What is one hundred billion divided by 3E+03?<br /><br />Think of it as: 1E11 / 3E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+07."
            }
        },
        {
            "question": "What is ten million divided by 1E+05?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is ten million divided by 1E+05?<br /><br />Think of it as: 1E07 / 1E05.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
            }
        },
        {
            "question": "What is one thousand divided by 3E+01?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is one thousand divided by 3E+01?<br /><br />Think of it as: 1E03 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+01."
            }
        },
        {
            "question": "What is one hundred thousand divided by 3E+04?",
            "writeInAnswer": 3,
            "furtherInfo": {
                "explanation": "What is one hundred thousand divided by 3E+04?<br /><br />Think of it as: 1E05 / 3E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
            }
        },
        {
            "question": "What is thirty thousand divided by 3E+03?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is thirty thousand divided by 3E+03?<br /><br />Think of it as: 3E04 / 3E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
            }
        },
        {
            "question": "What is ten million divided by 1E+03?",
            "writeInAnswer": 10000,
            "furtherInfo": {
                "explanation": "What is ten million divided by 1E+03?<br /><br />Think of it as: 1E07 / 1E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+04."
            }
        },
        {
            "question": "What is one hundred billion divided by 3E+01?",
            "writeInAnswer": 3000000000,
            "furtherInfo": {
                "explanation": "What is one hundred billion divided by 3E+01?<br /><br />Think of it as: 1E11 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+09."
            }
        },
        {
            "question": "What is one hundred million divided by 3E+04?",
            "writeInAnswer": 3000,
            "furtherInfo": {
                "explanation": "What is one hundred million divided by 3E+04?<br /><br />Think of it as: 1E08 / 3E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+03."
            }
        },
        {
            "question": "What is three hundred thousand divided by 1E+01?",
            "writeInAnswer": 30000,
            "furtherInfo": {
                "explanation": "What is three hundred thousand divided by 1E+01?<br /><br />Think of it as: 3E05 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+04."
            }
        },
        {
            "question": "What is three thousand divided by 1E+02?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is three thousand divided by 1E+02?<br /><br />Think of it as: 3E03 / 1E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
            }
        },
        {
            "question": "What is three hundred million divided by 3E+04?",
            "writeInAnswer": 10000,
            "furtherInfo": {
                "explanation": "What is three hundred million divided by 3E+04?<br /><br />Think of it as: 3E08 / 3E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+04."
            }
        },
        {
            "question": "What is thirty million divided by 1E+01?",
            "writeInAnswer": 3000000,
            "furtherInfo": {
                "explanation": "What is thirty million divided by 1E+01?<br /><br />Think of it as: 3E07 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+06."
            }
        },
        {
            "question": "What is thirty trillion divided by 3E+05?",
            "writeInAnswer": 100000000,
            "furtherInfo": {
                "explanation": "What is thirty trillion divided by 3E+05?<br /><br />Think of it as: 3E13 / 3E05.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+08."
            }
        },
        {
            "question": "What is three thousand divided by 1E+00?",
            "writeInAnswer": 3000,
            "furtherInfo": {
                "explanation": "What is three thousand divided by 1E+00?<br /><br />Think of it as: 3E03 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+03."
            }
        },
        {
            "question": "What is three billion divided by 3E+07?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is three billion divided by 3E+07?<br /><br />Think of it as: 3E09 / 3E07.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
            }
        },
        {
            "question": "What is three billion divided by 3E+01?",
            "writeInAnswer": 100000000,
            "furtherInfo": {
                "explanation": "What is three billion divided by 3E+01?<br /><br />Think of it as: 3E09 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+08."
            }
        },
        {
            "question": "What is one hundred million divided by 1E+05?",
            "writeInAnswer": 1000,
            "furtherInfo": {
                "explanation": "What is one hundred million divided by 1E+05?<br /><br />Think of it as: 1E08 / 1E05.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
            }
        },
        {
            "question": "What is three billion divided by 1E+01?",
            "writeInAnswer": 300000000,
            "furtherInfo": {
                "explanation": "What is three billion divided by 1E+01?<br /><br />Think of it as: 3E09 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+08."
            }
        },
        {
            "question": "What is three hundred divided by 1E+01?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is three hundred divided by 1E+01?<br /><br />Think of it as: 3E02 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
            }
        },
        {
            "question": "What is ten billion divided by 1E+00?",
            "writeInAnswer": 10000000000,
            "furtherInfo": {
                "explanation": "What is ten billion divided by 1E+00?<br /><br />Think of it as: 1E10 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+10."
            }
        },
        {
            "question": "What is one billion divided by 3E+05?",
            "writeInAnswer": 3000,
            "furtherInfo": {
                "explanation": "What is one billion divided by 3E+05?<br /><br />Think of it as: 1E09 / 3E05.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+03."
            }
        },
        {
            "question": "What is ten trillion divided by 1E+12?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is ten trillion divided by 1E+12?<br /><br />Think of it as: 1E13 / 1E12.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
            }
        },
        {
            "question": "What is thirty billion divided by 1E+00?",
            "writeInAnswer": 30000000000,
            "furtherInfo": {
                "explanation": "What is thirty billion divided by 1E+00?<br /><br />Think of it as: 3E10 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+10."
            }
        },
        {
            "question": "What is ten thousand divided by 3E+03?",
            "writeInAnswer": 3,
            "furtherInfo": {
                "explanation": "What is ten thousand divided by 3E+03?<br /><br />Think of it as: 1E04 / 3E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
            }
        },
        {
            "question": "What is ten million divided by 3E+06?",
            "writeInAnswer": 3,
            "furtherInfo": {
                "explanation": "What is ten million divided by 3E+06?<br /><br />Think of it as: 1E07 / 3E06.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
            }
        },
        {
            "question": "What is three hundred million divided by 3E+01?",
            "writeInAnswer": 10000000,
            "furtherInfo": {
                "explanation": "What is three hundred million divided by 3E+01?<br /><br />Think of it as: 3E08 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+07."
            }
        },
        {
            "question": "What is ten million divided by 1E+03?",
            "writeInAnswer": 10000,
            "furtherInfo": {
                "explanation": "What is ten million divided by 1E+03?<br /><br />Think of it as: 1E07 / 1E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+04."
            }
        },
        {
            "question": "What is thirty billion divided by 1E+02?",
            "writeInAnswer": 300000000,
            "furtherInfo": {
                "explanation": "What is thirty billion divided by 1E+02?<br /><br />Think of it as: 3E10 / 1E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+08."
            }
        },
        {
            "question": "What is one hundred billion divided by 1E+00?",
            "writeInAnswer": 100000000000,
            "furtherInfo": {
                "explanation": "What is one hundred billion divided by 1E+00?<br /><br />Think of it as: 1E11 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+11."
            }
        },
        {
            "question": "What is three million divided by 1E+05?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is three million divided by 1E+05?<br /><br />Think of it as: 3E06 / 1E05.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
            }
        },
        {
            "question": "What is three hundred billion divided by 3E+03?",
            "writeInAnswer": 100000000,
            "furtherInfo": {
                "explanation": "What is three hundred billion divided by 3E+03?<br /><br />Think of it as: 3E11 / 3E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+08."
            }
        },
        {
            "question": "What is one hundred thousand divided by 1E+02?",
            "writeInAnswer": 1000,
            "furtherInfo": {
                "explanation": "What is one hundred thousand divided by 1E+02?<br /><br />Think of it as: 1E05 / 1E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
            }
        },
        {
            "question": "What is one hundred divided by 3E+01?",
            "writeInAnswer": 3,
            "furtherInfo": {
                "explanation": "What is one hundred divided by 3E+01?<br /><br />Think of it as: 1E02 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
            }
        },
        {
            "question": "What is three million divided by 3E+02?",
            "writeInAnswer": 10000,
            "furtherInfo": {
                "explanation": "What is three million divided by 3E+02?<br /><br />Think of it as: 3E06 / 3E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+04."
            }
        },
        {
            "question": "What is one hundred billion divided by 1E+00?",
            "writeInAnswer": 100000000000,
            "furtherInfo": {
                "explanation": "What is one hundred billion divided by 1E+00?<br /><br />Think of it as: 1E11 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+11."
            }
        },
        {
            "question": "What is three billion divided by 1E+02?",
            "writeInAnswer": 30000000,
            "furtherInfo": {
                "explanation": "What is three billion divided by 1E+02?<br /><br />Think of it as: 3E09 / 1E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+07."
            }
        },
        {
            "question": "What is one hundred divided by 1E+00?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is one hundred divided by 1E+00?<br /><br />Think of it as: 1E02 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
            }
        },
        {
            "question": "What is three thousand divided by 3E+01?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is three thousand divided by 3E+01?<br /><br />Think of it as: 3E03 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
            }
        },
        {
            "question": "What is three trillion divided by 3E+04?",
            "writeInAnswer": 100000000,
            "furtherInfo": {
                "explanation": "What is three trillion divided by 3E+04?<br /><br />Think of it as: 3E12 / 3E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+08."
            }
        },
        {
            "question": "What is one trillion divided by 1E+10?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is one trillion divided by 1E+10?<br /><br />Think of it as: 1E12 / 1E10.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
            }
        },
        {
            "question": "What is ten billion divided by 3E+03?",
            "writeInAnswer": 3000000,
            "furtherInfo": {
                "explanation": "What is ten billion divided by 3E+03?<br /><br />Think of it as: 1E10 / 3E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+06."
            }
        },
        {
            "question": "What is three hundred billion divided by 1E+09?",
            "writeInAnswer": 300,
            "furtherInfo": {
                "explanation": "What is three hundred billion divided by 1E+09?<br /><br />Think of it as: 3E11 / 1E09.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+02."
            }
        },
        {
            "question": "What is one hundred million divided by 1E+07?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is one hundred million divided by 1E+07?<br /><br />Think of it as: 1E08 / 1E07.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
            }
        },
        {
            "question": "What is one billion divided by 1E+05?",
            "writeInAnswer": 10000,
            "furtherInfo": {
                "explanation": "What is one billion divided by 1E+05?<br /><br />Think of it as: 1E09 / 1E05.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+04."
            }
        },
        {
            "question": "What is one thousand divided by 3E+00?",
            "writeInAnswer": 300,
            "furtherInfo": {
                "explanation": "What is one thousand divided by 3E+00?<br /><br />Think of it as: 1E03 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+02."
            }
        },
        {
            "question": "What is three thousand divided by 1E+02?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is three thousand divided by 1E+02?<br /><br />Think of it as: 3E03 / 1E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
            }
        },
        {
            "question": "What is thirty divided by 1E+00?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is thirty divided by 1E+00?<br /><br />Think of it as: 3E01 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
            }
        },
        {
            "question": "What is three hundred billion divided by 1E+03?",
            "writeInAnswer": 300000000,
            "furtherInfo": {
                "explanation": "What is three hundred billion divided by 1E+03?<br /><br />Think of it as: 3E11 / 1E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+08."
            }
        },
        {
            "question": "What is thirty divided by 3E+00?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is thirty divided by 3E+00?<br /><br />Think of it as: 3E01 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
            }
        },
        {
            "question": "What is one hundred divided by 1E+01?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is one hundred divided by 1E+01?<br /><br />Think of it as: 1E02 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
            }
        },
        {
            "question": "What is one hundred billion divided by 1E+07?",
            "writeInAnswer": 10000,
            "furtherInfo": {
                "explanation": "What is one hundred billion divided by 1E+07?<br /><br />Think of it as: 1E11 / 1E07.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+04."
            }
        },
        {
            "question": "What is ten thousand divided by 3E+00?",
            "writeInAnswer": 3000,
            "furtherInfo": {
                "explanation": "What is ten thousand divided by 3E+00?<br /><br />Think of it as: 1E04 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+03."
            }
        },
        {
            "question": "What is one trillion divided by 3E+07?",
            "writeInAnswer": 30000,
            "furtherInfo": {
                "explanation": "What is one trillion divided by 3E+07?<br /><br />Think of it as: 1E12 / 3E07.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+04."
            }
        },
        {
            "question": "What is three hundred million divided by 1E+06?",
            "writeInAnswer": 300,
            "furtherInfo": {
                "explanation": "What is three hundred million divided by 1E+06?<br /><br />Think of it as: 3E08 / 1E06.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+02."
            }
        },
        {
            "question": "What is one million divided by 3E+01?",
            "writeInAnswer": 30000,
            "furtherInfo": {
                "explanation": "What is one million divided by 3E+01?<br /><br />Think of it as: 1E06 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+04."
            }
        },
        {
            "question": "What is thirty trillion divided by 3E+07?",
            "writeInAnswer": 1000000,
            "furtherInfo": {
                "explanation": "What is thirty trillion divided by 3E+07?<br /><br />Think of it as: 3E13 / 3E07.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+06."
            }
        },
        {
            "question": "What is ten million divided by 1E+03?",
            "writeInAnswer": 10000,
            "furtherInfo": {
                "explanation": "What is ten million divided by 1E+03?<br /><br />Think of it as: 1E07 / 1E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+04."
            }
        },
        {
            "question": "What is three hundred divided by 1E+01?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is three hundred divided by 1E+01?<br /><br />Think of it as: 3E02 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
            }
        },
        {
            "question": "What is ten billion divided by 3E+05?",
            "writeInAnswer": 30000,
            "furtherInfo": {
                "explanation": "What is ten billion divided by 3E+05?<br /><br />Think of it as: 1E10 / 3E05.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+04."
            }
        },
        {
            "question": "What is three thousand divided by 3E+02?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is three thousand divided by 3E+02?<br /><br />Think of it as: 3E03 / 3E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
            }
        },
        {
            "question": "What is three hundred thousand divided by 3E+02?",
            "writeInAnswer": 1000,
            "furtherInfo": {
                "explanation": "What is three hundred thousand divided by 3E+02?<br /><br />Think of it as: 3E05 / 3E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
            }
        },
        {
            "question": "What is thirty divided by 1E+00?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is thirty divided by 1E+00?<br /><br />Think of it as: 3E01 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
            }
        },
        {
            "question": "What is one hundred million divided by 3E+05?",
            "writeInAnswer": 300,
            "furtherInfo": {
                "explanation": "What is one hundred million divided by 3E+05?<br /><br />Think of it as: 1E08 / 3E05.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+02."
            }
        },
        {
            "question": "What is thirty billion divided by 1E+05?",
            "writeInAnswer": 300000,
            "furtherInfo": {
                "explanation": "What is thirty billion divided by 1E+05?<br /><br />Think of it as: 3E10 / 1E05.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+05."
            }
        },
        {
            "question": "What is three hundred billion divided by 3E+04?",
            "writeInAnswer": 10000000,
            "furtherInfo": {
                "explanation": "What is three hundred billion divided by 3E+04?<br /><br />Think of it as: 3E11 / 3E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+07."
            }
        },
        {
            "question": "What is one hundred billion divided by 1E+00?",
            "writeInAnswer": 100000000000,
            "furtherInfo": {
                "explanation": "What is one hundred billion divided by 1E+00?<br /><br />Think of it as: 1E11 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+11."
            }
        },
        {
            "question": "What is ten thousand divided by 1E+02?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is ten thousand divided by 1E+02?<br /><br />Think of it as: 1E04 / 1E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
            }
        },
        {
            "question": "What is one trillion divided by 3E+00?",
            "writeInAnswer": 300000000000,
            "furtherInfo": {
                "explanation": "What is one trillion divided by 3E+00?<br /><br />Think of it as: 1E12 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+11."
            }
        },
        {
            "question": "What is one million divided by 3E+00?",
            "writeInAnswer": 300000,
            "furtherInfo": {
                "explanation": "What is one million divided by 3E+00?<br /><br />Think of it as: 1E06 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+05."
            }
        },
        {
            "question": "What is three trillion divided by 3E+04?",
            "writeInAnswer": 100000000,
            "furtherInfo": {
                "explanation": "What is three trillion divided by 3E+04?<br /><br />Think of it as: 3E12 / 3E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+08."
            }
        },
        {
            "question": "What is one hundred billion divided by 1E+03?",
            "writeInAnswer": 100000000,
            "furtherInfo": {
                "explanation": "What is one hundred billion divided by 1E+03?<br /><br />Think of it as: 1E11 / 1E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+08."
            }
        },
        {
            "question": "What is three hundred million divided by 1E+04?",
            "writeInAnswer": 30000,
            "furtherInfo": {
                "explanation": "What is three hundred million divided by 1E+04?<br /><br />Think of it as: 3E08 / 1E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+04."
            }
        },
        {
            "question": "What is three hundred million divided by 1E+05?",
            "writeInAnswer": 3000,
            "furtherInfo": {
                "explanation": "What is three hundred million divided by 1E+05?<br /><br />Think of it as: 3E08 / 1E05.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+03."
            }
        },
        {
            "question": "What is three hundred thousand divided by 1E+04?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is three hundred thousand divided by 1E+04?<br /><br />Think of it as: 3E05 / 1E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
            }
        },
        {
            "question": "What is one billion divided by 3E+00?",
            "writeInAnswer": 300000000,
            "furtherInfo": {
                "explanation": "What is one billion divided by 3E+00?<br /><br />Think of it as: 1E09 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+08."
            }
        },
        {
            "question": "What is one hundred thousand divided by 1E+04?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is one hundred thousand divided by 1E+04?<br /><br />Think of it as: 1E05 / 1E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
            }
        },
        {
            "question": "What is three hundred million divided by 3E+06?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is three hundred million divided by 3E+06?<br /><br />Think of it as: 3E08 / 3E06.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
            }
        },
        {
            "question": "What is one hundred divided by 1E+00?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is one hundred divided by 1E+00?<br /><br />Think of it as: 1E02 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
            }
        },
        {
            "question": "What is three hundred million divided by 3E+04?",
            "writeInAnswer": 10000,
            "furtherInfo": {
                "explanation": "What is three hundred million divided by 3E+04?<br /><br />Think of it as: 3E08 / 3E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+04."
            }
        },
        {
            "question": "What is three billion divided by 1E+07?",
            "writeInAnswer": 300,
            "furtherInfo": {
                "explanation": "What is three billion divided by 1E+07?<br /><br />Think of it as: 3E09 / 1E07.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+02."
            }
        },
        {
            "question": "What is one hundred thousand divided by 1E+04?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is one hundred thousand divided by 1E+04?<br /><br />Think of it as: 1E05 / 1E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
            }
        },
        {
            "question": "What is one trillion divided by 3E+06?",
            "writeInAnswer": 300000,
            "furtherInfo": {
                "explanation": "What is one trillion divided by 3E+06?<br /><br />Think of it as: 1E12 / 3E06.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+05."
            }
        },
        {
            "question": "What is ten divided by 3E+00?",
            "writeInAnswer": 3,
            "furtherInfo": {
                "explanation": "What is ten divided by 3E+00?<br /><br />Think of it as: 1E01 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
            }
        },
        {
            "question": "What is thirty divided by 3E+00?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is thirty divided by 3E+00?<br /><br />Think of it as: 3E01 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
            }
        },
        {
            "question": "What is ten million divided by 3E+03?",
            "writeInAnswer": 3000,
            "furtherInfo": {
                "explanation": "What is ten million divided by 3E+03?<br /><br />Think of it as: 1E07 / 3E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+03."
            }
        },
        {
            "question": "What is ten thousand divided by 1E+01?",
            "writeInAnswer": 1000,
            "furtherInfo": {
                "explanation": "What is ten thousand divided by 1E+01?<br /><br />Think of it as: 1E04 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
            }
        },
        {
            "question": "What is one thousand divided by 3E+02?",
            "writeInAnswer": 3,
            "furtherInfo": {
                "explanation": "What is one thousand divided by 3E+02?<br /><br />Think of it as: 1E03 / 3E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
            }
        },
        {
            "question": "What is one billion divided by 1E+08?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is one billion divided by 1E+08?<br /><br />Think of it as: 1E09 / 1E08.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
            }
        },
        {
            "question": "What is one million divided by 3E+02?",
            "writeInAnswer": 3000,
            "furtherInfo": {
                "explanation": "What is one million divided by 3E+02?<br /><br />Think of it as: 1E06 / 3E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+03."
            }
        },
        {
            "question": "What is three thousand divided by 3E+01?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is three thousand divided by 3E+01?<br /><br />Think of it as: 3E03 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
            }
        },
        {
            "question": "What is ten trillion divided by 3E+11?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is ten trillion divided by 3E+11?<br /><br />Think of it as: 1E13 / 3E11.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+01."
            }
        },
        {
            "question": "What is one million divided by 3E+05?",
            "writeInAnswer": 3,
            "furtherInfo": {
                "explanation": "What is one million divided by 3E+05?<br /><br />Think of it as: 1E06 / 3E05.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
            }
        },
        {
            "question": "What is one hundred million divided by 1E+05?",
            "writeInAnswer": 1000,
            "furtherInfo": {
                "explanation": "What is one hundred million divided by 1E+05?<br /><br />Think of it as: 1E08 / 1E05.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
            }
        },
        {
            "question": "What is thirty thousand divided by 1E+00?",
            "writeInAnswer": 30000,
            "furtherInfo": {
                "explanation": "What is thirty thousand divided by 1E+00?<br /><br />Think of it as: 3E04 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+04."
            }
        },
        {
            "question": "What is three hundred billion divided by 1E+01?",
            "writeInAnswer": 30000000000,
            "furtherInfo": {
                "explanation": "What is three hundred billion divided by 1E+01?<br /><br />Think of it as: 3E11 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+10."
            }
        },
        {
            "question": "What is one hundred million divided by 3E+01?",
            "writeInAnswer": 3000000,
            "furtherInfo": {
                "explanation": "What is one hundred million divided by 3E+01?<br /><br />Think of it as: 1E08 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+06."
            }
        },
        {
            "question": "What is three hundred billion divided by 3E+10?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is three hundred billion divided by 3E+10?<br /><br />Think of it as: 3E11 / 3E10.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
            }
        },
        {
            "question": "What is ten billion divided by 1E+02?",
            "writeInAnswer": 100000000,
            "furtherInfo": {
                "explanation": "What is ten billion divided by 1E+02?<br /><br />Think of it as: 1E10 / 1E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+08."
            }
        },
        {
            "question": "What is thirty billion divided by 1E+05?",
            "writeInAnswer": 300000,
            "furtherInfo": {
                "explanation": "What is thirty billion divided by 1E+05?<br /><br />Think of it as: 3E10 / 1E05.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+05."
            }
        },
        {
            "question": "What is three trillion divided by 1E+02?",
            "writeInAnswer": 30000000000,
            "furtherInfo": {
                "explanation": "What is three trillion divided by 1E+02?<br /><br />Think of it as: 3E12 / 1E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+10."
            }
        },
        {
            "question": "What is ten billion divided by 1E+06?",
            "writeInAnswer": 10000,
            "furtherInfo": {
                "explanation": "What is ten billion divided by 1E+06?<br /><br />Think of it as: 1E10 / 1E06.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+04."
            }
        },
        {
            "question": "What is thirty divided by 3E+00?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is thirty divided by 3E+00?<br /><br />Think of it as: 3E01 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
            }
        },
        {
            "question": "What is three hundred thousand divided by 1E+04?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is three hundred thousand divided by 1E+04?<br /><br />Think of it as: 3E05 / 1E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
            }
        },
        {
            "question": "What is three hundred billion divided by 1E+05?",
            "writeInAnswer": 3000000,
            "furtherInfo": {
                "explanation": "What is three hundred billion divided by 1E+05?<br /><br />Think of it as: 3E11 / 1E05.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+06."
            }
        },
        {
            "question": "What is thirty trillion divided by 1E+11?",
            "writeInAnswer": 300,
            "furtherInfo": {
                "explanation": "What is thirty trillion divided by 1E+11?<br /><br />Think of it as: 3E13 / 1E11.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+02."
            }
        },
        {
            "question": "What is one hundred billion divided by 1E+01?",
            "writeInAnswer": 10000000000,
            "furtherInfo": {
                "explanation": "What is one hundred billion divided by 1E+01?<br /><br />Think of it as: 1E11 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+10."
            }
        },
        {
            "question": "What is three trillion divided by 3E+07?",
            "writeInAnswer": 100000,
            "furtherInfo": {
                "explanation": "What is three trillion divided by 3E+07?<br /><br />Think of it as: 3E12 / 3E07.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+05."
            }
        },
        {
            "question": "What is thirty billion divided by 1E+08?",
            "writeInAnswer": 300,
            "furtherInfo": {
                "explanation": "What is thirty billion divided by 1E+08?<br /><br />Think of it as: 3E10 / 1E08.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+02."
            }
        },
        {
            "question": "What is thirty divided by 1E+00?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is thirty divided by 1E+00?<br /><br />Think of it as: 3E01 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
            }
        },
        {
            "question": "What is three hundred divided by 3E+00?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is three hundred divided by 3E+00?<br /><br />Think of it as: 3E02 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
            }
        },
        {
            "question": "What is ten trillion divided by 3E+05?",
            "writeInAnswer": 30000000,
            "furtherInfo": {
                "explanation": "What is ten trillion divided by 3E+05?<br /><br />Think of it as: 1E13 / 3E05.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+07."
            }
        },
        {
            "question": "What is three million divided by 3E+05?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is three million divided by 3E+05?<br /><br />Think of it as: 3E06 / 3E05.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
            }
        },
        {
            "question": "What is ten divided by 1E+00?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is ten divided by 1E+00?<br /><br />Think of it as: 1E01 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
            }
        },
        {
            "question": "What is ten trillion divided by 1E+12?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is ten trillion divided by 1E+12?<br /><br />Think of it as: 1E13 / 1E12.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
            }
        },
        {
            "question": "What is three trillion divided by 3E+07?",
            "writeInAnswer": 100000,
            "furtherInfo": {
                "explanation": "What is three trillion divided by 3E+07?<br /><br />Think of it as: 3E12 / 3E07.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+05."
            }
        },
        {
            "question": "What is three hundred divided by 1E+01?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is three hundred divided by 1E+01?<br /><br />Think of it as: 3E02 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
            }
        },
        {
            "question": "What is three hundred divided by 3E+00?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is three hundred divided by 3E+00?<br /><br />Think of it as: 3E02 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
            }
        },
        {
            "question": "What is one million divided by 1E+04?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is one million divided by 1E+04?<br /><br />Think of it as: 1E06 / 1E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
            }
        },
        {
            "question": "What is thirty thousand divided by 3E+03?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is thirty thousand divided by 3E+03?<br /><br />Think of it as: 3E04 / 3E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
            }
        },
        {
            "question": "What is one hundred thousand divided by 3E+01?",
            "writeInAnswer": 3000,
            "furtherInfo": {
                "explanation": "What is one hundred thousand divided by 3E+01?<br /><br />Think of it as: 1E05 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+03."
            }
        },
        {
            "question": "What is one trillion divided by 3E+10?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is one trillion divided by 3E+10?<br /><br />Think of it as: 1E12 / 3E10.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+01."
            }
        },
        {
            "question": "What is ten thousand divided by 1E+01?",
            "writeInAnswer": 1000,
            "furtherInfo": {
                "explanation": "What is ten thousand divided by 1E+01?<br /><br />Think of it as: 1E04 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
            }
        },
        {
            "question": "What is one hundred thousand divided by 3E+02?",
            "writeInAnswer": 300,
            "furtherInfo": {
                "explanation": "What is one hundred thousand divided by 3E+02?<br /><br />Think of it as: 1E05 / 3E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+02."
            }
        },
        {
            "question": "What is three billion divided by 1E+07?",
            "writeInAnswer": 300,
            "furtherInfo": {
                "explanation": "What is three billion divided by 1E+07?<br /><br />Think of it as: 3E09 / 1E07.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+02."
            }
        },
        {
            "question": "What is thirty trillion divided by 1E+12?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is thirty trillion divided by 1E+12?<br /><br />Think of it as: 3E13 / 1E12.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
            }
        },
        {
            "question": "What is three trillion divided by 3E+08?",
            "writeInAnswer": 10000,
            "furtherInfo": {
                "explanation": "What is three trillion divided by 3E+08?<br /><br />Think of it as: 3E12 / 3E08.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+04."
            }
        },
        {
            "question": "What is ten billion divided by 1E+05?",
            "writeInAnswer": 100000,
            "furtherInfo": {
                "explanation": "What is ten billion divided by 1E+05?<br /><br />Think of it as: 1E10 / 1E05.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+05."
            }
        },
        {
            "question": "What is three billion divided by 1E+06?",
            "writeInAnswer": 3000,
            "furtherInfo": {
                "explanation": "What is three billion divided by 1E+06?<br /><br />Think of it as: 3E09 / 1E06.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+03."
            }
        },
        {
            "question": "What is ten million divided by 3E+00?",
            "writeInAnswer": 3000000,
            "furtherInfo": {
                "explanation": "What is ten million divided by 3E+00?<br /><br />Think of it as: 1E07 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+06."
            }
        },
        {
            "question": "What is ten million divided by 3E+01?",
            "writeInAnswer": 300000,
            "furtherInfo": {
                "explanation": "What is ten million divided by 3E+01?<br /><br />Think of it as: 1E07 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+05."
            }
        },
        {
            "question": "What is three billion divided by 3E+03?",
            "writeInAnswer": 1000000,
            "furtherInfo": {
                "explanation": "What is three billion divided by 3E+03?<br /><br />Think of it as: 3E09 / 3E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+06."
            }
        },
        {
            "question": "What is thirty million divided by 3E+00?",
            "writeInAnswer": 10000000,
            "furtherInfo": {
                "explanation": "What is thirty million divided by 3E+00?<br /><br />Think of it as: 3E07 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+07."
            }
        },
        {
            "question": "What is three hundred billion divided by 3E+10?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is three hundred billion divided by 3E+10?<br /><br />Think of it as: 3E11 / 3E10.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
            }
        },
        {
            "question": "What is one hundred million divided by 3E+00?",
            "writeInAnswer": 30000000,
            "furtherInfo": {
                "explanation": "What is one hundred million divided by 3E+00?<br /><br />Think of it as: 1E08 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+07."
            }
        },
        {
            "question": "What is ten thousand divided by 3E+01?",
            "writeInAnswer": 300,
            "furtherInfo": {
                "explanation": "What is ten thousand divided by 3E+01?<br /><br />Think of it as: 1E04 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+02."
            }
        },
        {
            "question": "What is three hundred million divided by 3E+03?",
            "writeInAnswer": 100000,
            "furtherInfo": {
                "explanation": "What is three hundred million divided by 3E+03?<br /><br />Think of it as: 3E08 / 3E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+05."
            }
        },
        {
            "question": "What is one hundred million divided by 1E+00?",
            "writeInAnswer": 100000000,
            "furtherInfo": {
                "explanation": "What is one hundred million divided by 1E+00?<br /><br />Think of it as: 1E08 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+08."
            }
        },
        {
            "question": "What is ten thousand divided by 3E+00?",
            "writeInAnswer": 3000,
            "furtherInfo": {
                "explanation": "What is ten thousand divided by 3E+00?<br /><br />Think of it as: 1E04 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+03."
            }
        },
        {
            "question": "What is one billion divided by 1E+07?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is one billion divided by 1E+07?<br /><br />Think of it as: 1E09 / 1E07.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
            }
        },
        {
            "question": "What is three billion divided by 1E+01?",
            "writeInAnswer": 300000000,
            "furtherInfo": {
                "explanation": "What is three billion divided by 1E+01?<br /><br />Think of it as: 3E09 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+08."
            }
        },
        {
            "question": "What is thirty divided by 1E+00?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is thirty divided by 1E+00?<br /><br />Think of it as: 3E01 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
            }
        },
        {
            "question": "What is ten trillion divided by 1E+09?",
            "writeInAnswer": 10000,
            "furtherInfo": {
                "explanation": "What is ten trillion divided by 1E+09?<br /><br />Think of it as: 1E13 / 1E09.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+04."
            }
        },
        {
            "question": "What is one thousand divided by 1E+01?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is one thousand divided by 1E+01?<br /><br />Think of it as: 1E03 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
            }
        },
        {
            "question": "What is one hundred divided by 3E+01?",
            "writeInAnswer": 3,
            "furtherInfo": {
                "explanation": "What is one hundred divided by 3E+01?<br /><br />Think of it as: 1E02 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
            }
        },
        {
            "question": "What is one trillion divided by 1E+07?",
            "writeInAnswer": 100000,
            "furtherInfo": {
                "explanation": "What is one trillion divided by 1E+07?<br /><br />Think of it as: 1E12 / 1E07.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+05."
            }
        },
        {
            "question": "What is thirty divided by 1E+00?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is thirty divided by 1E+00?<br /><br />Think of it as: 3E01 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
            }
        },
        {
            "question": "What is ten million divided by 1E+05?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is ten million divided by 1E+05?<br /><br />Think of it as: 1E07 / 1E05.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
            }
        },
        {
            "question": "What is three million divided by 1E+03?",
            "writeInAnswer": 3000,
            "furtherInfo": {
                "explanation": "What is three million divided by 1E+03?<br /><br />Think of it as: 3E06 / 1E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+03."
            }
        },
        {
            "question": "What is three hundred divided by 3E+00?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is three hundred divided by 3E+00?<br /><br />Think of it as: 3E02 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
            }
        },
        {
            "question": "What is ten divided by 3E+00?",
            "writeInAnswer": 3,
            "furtherInfo": {
                "explanation": "What is ten divided by 3E+00?<br /><br />Think of it as: 1E01 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
            }
        },
        {
            "question": "What is thirty billion divided by 3E+00?",
            "writeInAnswer": 10000000000,
            "furtherInfo": {
                "explanation": "What is thirty billion divided by 3E+00?<br /><br />Think of it as: 3E10 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+10."
            }
        },
        {
            "question": "What is three thousand divided by 3E+01?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is three thousand divided by 3E+01?<br /><br />Think of it as: 3E03 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
            }
        },
        {
            "question": "What is three hundred divided by 3E+00?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is three hundred divided by 3E+00?<br /><br />Think of it as: 3E02 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
            }
        },
        {
            "question": "What is thirty billion divided by 1E+01?",
            "writeInAnswer": 3000000000,
            "furtherInfo": {
                "explanation": "What is thirty billion divided by 1E+01?<br /><br />Think of it as: 3E10 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+09."
            }
        },
        {
            "question": "What is one hundred thousand divided by 3E+00?",
            "writeInAnswer": 30000,
            "furtherInfo": {
                "explanation": "What is one hundred thousand divided by 3E+00?<br /><br />Think of it as: 1E05 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+04."
            }
        },
        {
            "question": "What is one billion divided by 1E+02?",
            "writeInAnswer": 10000000,
            "furtherInfo": {
                "explanation": "What is one billion divided by 1E+02?<br /><br />Think of it as: 1E09 / 1E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+07."
            }
        },
        {
            "question": "What is three hundred billion divided by 3E+10?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is three hundred billion divided by 3E+10?<br /><br />Think of it as: 3E11 / 3E10.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
            }
        },
        {
            "question": "What is one hundred billion divided by 1E+02?",
            "writeInAnswer": 1000000000,
            "furtherInfo": {
                "explanation": "What is one hundred billion divided by 1E+02?<br /><br />Think of it as: 1E11 / 1E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+09."
            }
        },
        {
            "question": "What is one million divided by 1E+02?",
            "writeInAnswer": 10000,
            "furtherInfo": {
                "explanation": "What is one million divided by 1E+02?<br /><br />Think of it as: 1E06 / 1E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+04."
            }
        },
        {
            "question": "What is three billion divided by 1E+03?",
            "writeInAnswer": 3000000,
            "furtherInfo": {
                "explanation": "What is three billion divided by 1E+03?<br /><br />Think of it as: 3E09 / 1E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+06."
            }
        },
        {
            "question": "What is ten million divided by 1E+01?",
            "writeInAnswer": 1000000,
            "furtherInfo": {
                "explanation": "What is ten million divided by 1E+01?<br /><br />Think of it as: 1E07 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+06."
            }
        },
        {
            "question": "What is ten trillion divided by 3E+08?",
            "writeInAnswer": 30000,
            "furtherInfo": {
                "explanation": "What is ten trillion divided by 3E+08?<br /><br />Think of it as: 1E13 / 3E08.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+04."
            }
        },
        {
            "question": "What is one trillion divided by 1E+07?",
            "writeInAnswer": 100000,
            "furtherInfo": {
                "explanation": "What is one trillion divided by 1E+07?<br /><br />Think of it as: 1E12 / 1E07.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+05."
            }
        },
        {
            "question": "What is three hundred billion divided by 1E+06?",
            "writeInAnswer": 300000,
            "furtherInfo": {
                "explanation": "What is three hundred billion divided by 1E+06?<br /><br />Think of it as: 3E11 / 1E06.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+05."
            }
        },
        {
            "question": "What is three million divided by 1E+02?",
            "writeInAnswer": 30000,
            "furtherInfo": {
                "explanation": "What is three million divided by 1E+02?<br /><br />Think of it as: 3E06 / 1E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+04."
            }
        },
        {
            "question": "What is one billion divided by 3E+08?",
            "writeInAnswer": 3,
            "furtherInfo": {
                "explanation": "What is one billion divided by 3E+08?<br /><br />Think of it as: 1E09 / 3E08.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
            }
        },
        {
            "question": "What is three million divided by 1E+04?",
            "writeInAnswer": 300,
            "furtherInfo": {
                "explanation": "What is three million divided by 1E+04?<br /><br />Think of it as: 3E06 / 1E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+02."
            }
        },
        {
            "question": "What is one hundred divided by 3E+00?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is one hundred divided by 3E+00?<br /><br />Think of it as: 1E02 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+01."
            }
        },
        {
            "question": "What is one hundred billion divided by 1E+03?",
            "writeInAnswer": 100000000,
            "furtherInfo": {
                "explanation": "What is one hundred billion divided by 1E+03?<br /><br />Think of it as: 1E11 / 1E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+08."
            }
        },
        {
            "question": "What is one hundred thousand divided by 3E+01?",
            "writeInAnswer": 3000,
            "furtherInfo": {
                "explanation": "What is one hundred thousand divided by 3E+01?<br /><br />Think of it as: 1E05 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+03."
            }
        },
        {
            "question": "What is one hundred thousand divided by 1E+01?",
            "writeInAnswer": 10000,
            "furtherInfo": {
                "explanation": "What is one hundred thousand divided by 1E+01?<br /><br />Think of it as: 1E05 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+04."
            }
        },
        {
            "question": "What is thirty trillion divided by 1E+12?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is thirty trillion divided by 1E+12?<br /><br />Think of it as: 3E13 / 1E12.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
            }
        },
        {
            "question": "What is ten thousand divided by 1E+03?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is ten thousand divided by 1E+03?<br /><br />Think of it as: 1E04 / 1E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
            }
        },
        {
            "question": "What is ten billion divided by 1E+07?",
            "writeInAnswer": 1000,
            "furtherInfo": {
                "explanation": "What is ten billion divided by 1E+07?<br /><br />Think of it as: 1E10 / 1E07.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
            }
        },
        {
            "question": "What is three trillion divided by 1E+10?",
            "writeInAnswer": 300,
            "furtherInfo": {
                "explanation": "What is three trillion divided by 1E+10?<br /><br />Think of it as: 3E12 / 1E10.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+02."
            }
        },
        {
            "question": "What is three trillion divided by 1E+01?",
            "writeInAnswer": 300000000000,
            "furtherInfo": {
                "explanation": "What is three trillion divided by 1E+01?<br /><br />Think of it as: 3E12 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+11."
            }
        },
        {
            "question": "What is three trillion divided by 3E+01?",
            "writeInAnswer": 100000000000,
            "furtherInfo": {
                "explanation": "What is three trillion divided by 3E+01?<br /><br />Think of it as: 3E12 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+11."
            }
        },
        {
            "question": "What is one trillion divided by 1E+06?",
            "writeInAnswer": 1000000,
            "furtherInfo": {
                "explanation": "What is one trillion divided by 1E+06?<br /><br />Think of it as: 1E12 / 1E06.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+06."
            }
        },
        {
            "question": "What is ten million divided by 1E+02?",
            "writeInAnswer": 100000,
            "furtherInfo": {
                "explanation": "What is ten million divided by 1E+02?<br /><br />Think of it as: 1E07 / 1E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+05."
            }
        },
        {
            "question": "What is thirty divided by 3E+00?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is thirty divided by 3E+00?<br /><br />Think of it as: 3E01 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
            }
        },
        {
            "question": "What is one million divided by 1E+02?",
            "writeInAnswer": 10000,
            "furtherInfo": {
                "explanation": "What is one million divided by 1E+02?<br /><br />Think of it as: 1E06 / 1E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+04."
            }
        },
        {
            "question": "What is one trillion divided by 3E+09?",
            "writeInAnswer": 300,
            "furtherInfo": {
                "explanation": "What is one trillion divided by 3E+09?<br /><br />Think of it as: 1E12 / 3E09.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+02."
            }
        },
        {
            "question": "What is three trillion divided by 1E+01?",
            "writeInAnswer": 300000000000,
            "furtherInfo": {
                "explanation": "What is three trillion divided by 1E+01?<br /><br />Think of it as: 3E12 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+11."
            }
        },
        {
            "question": "What is three billion divided by 3E+03?",
            "writeInAnswer": 1000000,
            "furtherInfo": {
                "explanation": "What is three billion divided by 3E+03?<br /><br />Think of it as: 3E09 / 3E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+06."
            }
        },
        {
            "question": "What is ten billion divided by 3E+07?",
            "writeInAnswer": 300,
            "furtherInfo": {
                "explanation": "What is ten billion divided by 3E+07?<br /><br />Think of it as: 1E10 / 3E07.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+02."
            }
        },
        {
            "question": "What is one million divided by 1E+05?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is one million divided by 1E+05?<br /><br />Think of it as: 1E06 / 1E05.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
            }
        },
        {
            "question": "What is one hundred million divided by 1E+05?",
            "writeInAnswer": 1000,
            "furtherInfo": {
                "explanation": "What is one hundred million divided by 1E+05?<br /><br />Think of it as: 1E08 / 1E05.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
            }
        },
        {
            "question": "What is three thousand divided by 3E+00?",
            "writeInAnswer": 1000,
            "furtherInfo": {
                "explanation": "What is three thousand divided by 3E+00?<br /><br />Think of it as: 3E03 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
            }
        },
        {
            "question": "What is ten trillion divided by 3E+09?",
            "writeInAnswer": 3000,
            "furtherInfo": {
                "explanation": "What is ten trillion divided by 3E+09?<br /><br />Think of it as: 1E13 / 3E09.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+03."
            }
        },
        {
            "question": "What is three billion divided by 3E+00?",
            "writeInAnswer": 1000000000,
            "furtherInfo": {
                "explanation": "What is three billion divided by 3E+00?<br /><br />Think of it as: 3E09 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+09."
            }
        },
        {
            "question": "What is one trillion divided by 1E+02?",
            "writeInAnswer": 10000000000,
            "furtherInfo": {
                "explanation": "What is one trillion divided by 1E+02?<br /><br />Think of it as: 1E12 / 1E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+10."
            }
        },
        {
            "question": "What is three billion divided by 3E+07?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is three billion divided by 3E+07?<br /><br />Think of it as: 3E09 / 3E07.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
            }
        },
        {
            "question": "What is three hundred billion divided by 1E+08?",
            "writeInAnswer": 3000,
            "furtherInfo": {
                "explanation": "What is three hundred billion divided by 1E+08?<br /><br />Think of it as: 3E11 / 1E08.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+03."
            }
        },
        {
            "question": "What is one hundred million divided by 1E+07?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is one hundred million divided by 1E+07?<br /><br />Think of it as: 1E08 / 1E07.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
            }
        },
        {
            "question": "What is ten trillion divided by 3E+12?",
            "writeInAnswer": 3,
            "furtherInfo": {
                "explanation": "What is ten trillion divided by 3E+12?<br /><br />Think of it as: 1E13 / 3E12.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
            }
        },
        {
            "question": "What is one hundred thousand divided by 1E+00?",
            "writeInAnswer": 100000,
            "furtherInfo": {
                "explanation": "What is one hundred thousand divided by 1E+00?<br /><br />Think of it as: 1E05 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+05."
            }
        },
        {
            "question": "What is thirty million divided by 3E+06?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is thirty million divided by 3E+06?<br /><br />Think of it as: 3E07 / 3E06.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
            }
        },
        {
            "question": "What is three billion divided by 1E+06?",
            "writeInAnswer": 3000,
            "furtherInfo": {
                "explanation": "What is three billion divided by 1E+06?<br /><br />Think of it as: 3E09 / 1E06.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+03."
            }
        },
        {
            "question": "What is ten billion divided by 3E+01?",
            "writeInAnswer": 300000000,
            "furtherInfo": {
                "explanation": "What is ten billion divided by 3E+01?<br /><br />Think of it as: 1E10 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+08."
            }
        },
        {
            "question": "What is one billion divided by 3E+06?",
            "writeInAnswer": 300,
            "furtherInfo": {
                "explanation": "What is one billion divided by 3E+06?<br /><br />Think of it as: 1E09 / 3E06.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+02."
            }
        },
        {
            "question": "What is one hundred thousand divided by 3E+00?",
            "writeInAnswer": 30000,
            "furtherInfo": {
                "explanation": "What is one hundred thousand divided by 3E+00?<br /><br />Think of it as: 1E05 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+04."
            }
        },
        {
            "question": "What is one thousand divided by 3E+02?",
            "writeInAnswer": 3,
            "furtherInfo": {
                "explanation": "What is one thousand divided by 3E+02?<br /><br />Think of it as: 1E03 / 3E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
            }
        },
        {
            "question": "What is three hundred thousand divided by 3E+03?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is three hundred thousand divided by 3E+03?<br /><br />Think of it as: 3E05 / 3E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
            }
        },
        {
            "question": "What is thirty million divided by 1E+02?",
            "writeInAnswer": 300000,
            "furtherInfo": {
                "explanation": "What is thirty million divided by 1E+02?<br /><br />Think of it as: 3E07 / 1E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+05."
            }
        },
        {
            "question": "What is ten million divided by 1E+02?",
            "writeInAnswer": 100000,
            "furtherInfo": {
                "explanation": "What is ten million divided by 1E+02?<br /><br />Think of it as: 1E07 / 1E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+05."
            }
        },
        {
            "question": "What is three billion divided by 1E+04?",
            "writeInAnswer": 300000,
            "furtherInfo": {
                "explanation": "What is three billion divided by 1E+04?<br /><br />Think of it as: 3E09 / 1E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+05."
            }
        },
        {
            "question": "What is three hundred billion divided by 3E+04?",
            "writeInAnswer": 10000000,
            "furtherInfo": {
                "explanation": "What is three hundred billion divided by 3E+04?<br /><br />Think of it as: 3E11 / 3E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+07."
            }
        },
        {
            "question": "What is thirty trillion divided by 1E+09?",
            "writeInAnswer": 30000,
            "furtherInfo": {
                "explanation": "What is thirty trillion divided by 1E+09?<br /><br />Think of it as: 3E13 / 1E09.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+04."
            }
        },
        {
            "question": "What is thirty trillion divided by 3E+06?",
            "writeInAnswer": 10000000,
            "furtherInfo": {
                "explanation": "What is thirty trillion divided by 3E+06?<br /><br />Think of it as: 3E13 / 3E06.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+07."
            }
        },
        {
            "question": "What is ten billion divided by 3E+02?",
            "writeInAnswer": 30000000,
            "furtherInfo": {
                "explanation": "What is ten billion divided by 3E+02?<br /><br />Think of it as: 1E10 / 3E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+07."
            }
        },
        {
            "question": "What is three hundred billion divided by 1E+10?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is three hundred billion divided by 1E+10?<br /><br />Think of it as: 3E11 / 1E10.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
            }
        },
        {
            "question": "What is ten trillion divided by 1E+00?",
            "writeInAnswer": 10000000000000,
            "furtherInfo": {
                "explanation": "What is ten trillion divided by 1E+00?<br /><br />Think of it as: 1E13 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+13."
            }
        },
        {
            "question": "What is three hundred thousand divided by 1E+01?",
            "writeInAnswer": 30000,
            "furtherInfo": {
                "explanation": "What is three hundred thousand divided by 1E+01?<br /><br />Think of it as: 3E05 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+04."
            }
        },
        {
            "question": "What is three hundred thousand divided by 1E+03?",
            "writeInAnswer": 300,
            "furtherInfo": {
                "explanation": "What is three hundred thousand divided by 1E+03?<br /><br />Think of it as: 3E05 / 1E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+02."
            }
        },
        {
            "question": "What is thirty trillion divided by 1E+06?",
            "writeInAnswer": 30000000,
            "furtherInfo": {
                "explanation": "What is thirty trillion divided by 1E+06?<br /><br />Think of it as: 3E13 / 1E06.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+07."
            }
        },
        {
            "question": "What is three million divided by 3E+04?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is three million divided by 3E+04?<br /><br />Think of it as: 3E06 / 3E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
            }
        },
        {
            "question": "What is three hundred million divided by 3E+07?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is three hundred million divided by 3E+07?<br /><br />Think of it as: 3E08 / 3E07.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
            }
        },
        {
            "question": "What is three hundred divided by 1E+01?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is three hundred divided by 1E+01?<br /><br />Think of it as: 3E02 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
            }
        },
        {
            "question": "What is thirty divided by 3E+00?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is thirty divided by 3E+00?<br /><br />Think of it as: 3E01 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
            }
        },
        {
            "question": "What is thirty million divided by 3E+00?",
            "writeInAnswer": 10000000,
            "furtherInfo": {
                "explanation": "What is thirty million divided by 3E+00?<br /><br />Think of it as: 3E07 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+07."
            }
        },
        {
            "question": "What is ten thousand divided by 1E+00?",
            "writeInAnswer": 10000,
            "furtherInfo": {
                "explanation": "What is ten thousand divided by 1E+00?<br /><br />Think of it as: 1E04 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+04."
            }
        },
        {
            "question": "What is thirty million divided by 3E+02?",
            "writeInAnswer": 100000,
            "furtherInfo": {
                "explanation": "What is thirty million divided by 3E+02?<br /><br />Think of it as: 3E07 / 3E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+05."
            }
        },
        {
            "question": "What is thirty billion divided by 1E+07?",
            "writeInAnswer": 3000,
            "furtherInfo": {
                "explanation": "What is thirty billion divided by 1E+07?<br /><br />Think of it as: 3E10 / 1E07.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+03."
            }
        },
        {
            "question": "What is ten trillion divided by 3E+03?",
            "writeInAnswer": 3000000000,
            "furtherInfo": {
                "explanation": "What is ten trillion divided by 3E+03?<br /><br />Think of it as: 1E13 / 3E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+09."
            }
        },
        {
            "question": "What is one million divided by 3E+02?",
            "writeInAnswer": 3000,
            "furtherInfo": {
                "explanation": "What is one million divided by 3E+02?<br /><br />Think of it as: 1E06 / 3E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+03."
            }
        },
        {
            "question": "What is three thousand divided by 3E+00?",
            "writeInAnswer": 1000,
            "furtherInfo": {
                "explanation": "What is three thousand divided by 3E+00?<br /><br />Think of it as: 3E03 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
            }
        },
        {
            "question": "What is ten trillion divided by 1E+07?",
            "writeInAnswer": 1000000,
            "furtherInfo": {
                "explanation": "What is ten trillion divided by 1E+07?<br /><br />Think of it as: 1E13 / 1E07.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+06."
            }
        },
        {
            "question": "What is ten billion divided by 1E+05?",
            "writeInAnswer": 100000,
            "furtherInfo": {
                "explanation": "What is ten billion divided by 1E+05?<br /><br />Think of it as: 1E10 / 1E05.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+05."
            }
        },
        {
            "question": "What is thirty divided by 3E+00?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is thirty divided by 3E+00?<br /><br />Think of it as: 3E01 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
            }
        },
        {
            "question": "What is thirty thousand divided by 1E+03?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is thirty thousand divided by 1E+03?<br /><br />Think of it as: 3E04 / 1E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
            }
        },
        {
            "question": "What is thirty trillion divided by 3E+02?",
            "writeInAnswer": 100000000000,
            "furtherInfo": {
                "explanation": "What is thirty trillion divided by 3E+02?<br /><br />Think of it as: 3E13 / 3E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+11."
            }
        },
        {
            "question": "What is three hundred million divided by 1E+03?",
            "writeInAnswer": 300000,
            "furtherInfo": {
                "explanation": "What is three hundred million divided by 1E+03?<br /><br />Think of it as: 3E08 / 1E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+05."
            }
        },
        {
            "question": "What is thirty thousand divided by 3E+01?",
            "writeInAnswer": 1000,
            "furtherInfo": {
                "explanation": "What is thirty thousand divided by 3E+01?<br /><br />Think of it as: 3E04 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
            }
        },
        {
            "question": "What is one hundred billion divided by 3E+05?",
            "writeInAnswer": 300000,
            "furtherInfo": {
                "explanation": "What is one hundred billion divided by 3E+05?<br /><br />Think of it as: 1E11 / 3E05.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+05."
            }
        },
        {
            "question": "What is ten divided by 1E+00?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is ten divided by 1E+00?<br /><br />Think of it as: 1E01 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
            }
        },
        {
            "question": "What is one hundred million divided by 3E+03?",
            "writeInAnswer": 30000,
            "furtherInfo": {
                "explanation": "What is one hundred million divided by 3E+03?<br /><br />Think of it as: 1E08 / 3E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+04."
            }
        },
        {
            "question": "What is ten thousand divided by 1E+02?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is ten thousand divided by 1E+02?<br /><br />Think of it as: 1E04 / 1E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
            }
        },
        {
            "question": "What is one hundred million divided by 3E+01?",
            "writeInAnswer": 3000000,
            "furtherInfo": {
                "explanation": "What is one hundred million divided by 3E+01?<br /><br />Think of it as: 1E08 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+06."
            }
        },
        {
            "question": "What is three hundred million divided by 1E+02?",
            "writeInAnswer": 3000000,
            "furtherInfo": {
                "explanation": "What is three hundred million divided by 1E+02?<br /><br />Think of it as: 3E08 / 1E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+06."
            }
        },
        {
            "question": "What is one hundred billion divided by 1E+09?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is one hundred billion divided by 1E+09?<br /><br />Think of it as: 1E11 / 1E09.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
            }
        },
        {
            "question": "What is thirty million divided by 3E+06?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is thirty million divided by 3E+06?<br /><br />Think of it as: 3E07 / 3E06.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
            }
        },
        {
            "question": "What is three billion divided by 1E+08?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is three billion divided by 1E+08?<br /><br />Think of it as: 3E09 / 1E08.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
            }
        },
        {
            "question": "What is ten billion divided by 3E+06?",
            "writeInAnswer": 3000,
            "furtherInfo": {
                "explanation": "What is ten billion divided by 3E+06?<br /><br />Think of it as: 1E10 / 3E06.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+03."
            }
        },
        {
            "question": "What is three hundred million divided by 1E+02?",
            "writeInAnswer": 3000000,
            "furtherInfo": {
                "explanation": "What is three hundred million divided by 1E+02?<br /><br />Think of it as: 3E08 / 1E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+06."
            }
        },
        {
            "question": "What is ten billion divided by 1E+02?",
            "writeInAnswer": 100000000,
            "furtherInfo": {
                "explanation": "What is ten billion divided by 1E+02?<br /><br />Think of it as: 1E10 / 1E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+08."
            }
        },
        {
            "question": "What is ten divided by 3E+00?",
            "writeInAnswer": 3,
            "furtherInfo": {
                "explanation": "What is ten divided by 3E+00?<br /><br />Think of it as: 1E01 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
            }
        },
        {
            "question": "What is three hundred thousand divided by 1E+04?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is three hundred thousand divided by 1E+04?<br /><br />Think of it as: 3E05 / 1E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
            }
        },
        {
            "question": "What is ten trillion divided by 1E+01?",
            "writeInAnswer": 1000000000000,
            "furtherInfo": {
                "explanation": "What is ten trillion divided by 1E+01?<br /><br />Think of it as: 1E13 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+12."
            }
        },
        {
            "question": "What is thirty thousand divided by 3E+01?",
            "writeInAnswer": 1000,
            "furtherInfo": {
                "explanation": "What is thirty thousand divided by 3E+01?<br /><br />Think of it as: 3E04 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
            }
        },
        {
            "question": "What is one billion divided by 1E+08?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is one billion divided by 1E+08?<br /><br />Think of it as: 1E09 / 1E08.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
            }
        },
        {
            "question": "What is three thousand divided by 3E+00?",
            "writeInAnswer": 1000,
            "furtherInfo": {
                "explanation": "What is three thousand divided by 3E+00?<br /><br />Think of it as: 3E03 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
            }
        },
        {
            "question": "What is one million divided by 1E+02?",
            "writeInAnswer": 10000,
            "furtherInfo": {
                "explanation": "What is one million divided by 1E+02?<br /><br />Think of it as: 1E06 / 1E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+04."
            }
        },
        {
            "question": "What is one billion divided by 1E+05?",
            "writeInAnswer": 10000,
            "furtherInfo": {
                "explanation": "What is one billion divided by 1E+05?<br /><br />Think of it as: 1E09 / 1E05.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+04."
            }
        },
        {
            "question": "What is three trillion divided by 1E+08?",
            "writeInAnswer": 30000,
            "furtherInfo": {
                "explanation": "What is three trillion divided by 1E+08?<br /><br />Think of it as: 3E12 / 1E08.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+04."
            }
        },
        {
            "question": "What is ten trillion divided by 3E+09?",
            "writeInAnswer": 3000,
            "furtherInfo": {
                "explanation": "What is ten trillion divided by 3E+09?<br /><br />Think of it as: 1E13 / 3E09.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+03."
            }
        },
        {
            "question": "What is ten divided by 3E+00?",
            "writeInAnswer": 3,
            "furtherInfo": {
                "explanation": "What is ten divided by 3E+00?<br /><br />Think of it as: 1E01 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
            }
        },
        {
            "question": "What is one hundred billion divided by 3E+03?",
            "writeInAnswer": 30000000,
            "furtherInfo": {
                "explanation": "What is one hundred billion divided by 3E+03?<br /><br />Think of it as: 1E11 / 3E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+07."
            }
        },
        {
            "question": "What is one hundred thousand divided by 3E+01?",
            "writeInAnswer": 3000,
            "furtherInfo": {
                "explanation": "What is one hundred thousand divided by 3E+01?<br /><br />Think of it as: 1E05 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+03."
            }
        },
        {
            "question": "What is ten billion divided by 1E+05?",
            "writeInAnswer": 100000,
            "furtherInfo": {
                "explanation": "What is ten billion divided by 1E+05?<br /><br />Think of it as: 1E10 / 1E05.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+05."
            }
        },
        {
            "question": "What is one trillion divided by 3E+00?",
            "writeInAnswer": 300000000000,
            "furtherInfo": {
                "explanation": "What is one trillion divided by 3E+00?<br /><br />Think of it as: 1E12 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+11."
            }
        },
        {
            "question": "What is one hundred million divided by 3E+02?",
            "writeInAnswer": 300000,
            "furtherInfo": {
                "explanation": "What is one hundred million divided by 3E+02?<br /><br />Think of it as: 1E08 / 3E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+05."
            }
        },
        {
            "question": "What is ten trillion divided by 1E+11?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is ten trillion divided by 1E+11?<br /><br />Think of it as: 1E13 / 1E11.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
            }
        },
        {
            "question": "What is ten billion divided by 1E+08?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is ten billion divided by 1E+08?<br /><br />Think of it as: 1E10 / 1E08.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
            }
        },
        {
            "question": "What is one trillion divided by 3E+04?",
            "writeInAnswer": 30000000,
            "furtherInfo": {
                "explanation": "What is one trillion divided by 3E+04?<br /><br />Think of it as: 1E12 / 3E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+07."
            }
        },
        {
            "question": "What is three million divided by 3E+02?",
            "writeInAnswer": 10000,
            "furtherInfo": {
                "explanation": "What is three million divided by 3E+02?<br /><br />Think of it as: 3E06 / 3E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+04."
            }
        },
        {
            "question": "What is ten billion divided by 3E+03?",
            "writeInAnswer": 3000000,
            "furtherInfo": {
                "explanation": "What is ten billion divided by 3E+03?<br /><br />Think of it as: 1E10 / 3E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+06."
            }
        },
        {
            "question": "What is three hundred thousand divided by 1E+00?",
            "writeInAnswer": 300000,
            "furtherInfo": {
                "explanation": "What is three hundred thousand divided by 1E+00?<br /><br />Think of it as: 3E05 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+05."
            }
        },
        {
            "question": "What is three billion divided by 1E+06?",
            "writeInAnswer": 3000,
            "furtherInfo": {
                "explanation": "What is three billion divided by 1E+06?<br /><br />Think of it as: 3E09 / 1E06.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+03."
            }
        },
        {
            "question": "What is three hundred billion divided by 1E+05?",
            "writeInAnswer": 3000000,
            "furtherInfo": {
                "explanation": "What is three hundred billion divided by 1E+05?<br /><br />Think of it as: 3E11 / 1E05.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+06."
            }
        },
        {
            "question": "What is thirty trillion divided by 1E+06?",
            "writeInAnswer": 30000000,
            "furtherInfo": {
                "explanation": "What is thirty trillion divided by 1E+06?<br /><br />Think of it as: 3E13 / 1E06.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+07."
            }
        },
        {
            "question": "What is ten trillion divided by 1E+06?",
            "writeInAnswer": 10000000,
            "furtherInfo": {
                "explanation": "What is ten trillion divided by 1E+06?<br /><br />Think of it as: 1E13 / 1E06.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+07."
            }
        },
        {
            "question": "What is ten divided by 3E+00?",
            "writeInAnswer": 3,
            "furtherInfo": {
                "explanation": "What is ten divided by 3E+00?<br /><br />Think of it as: 1E01 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
            }
        },
        {
            "question": "What is three trillion divided by 1E+10?",
            "writeInAnswer": 300,
            "furtherInfo": {
                "explanation": "What is three trillion divided by 1E+10?<br /><br />Think of it as: 3E12 / 1E10.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+02."
            }
        },
        {
            "question": "What is one billion divided by 1E+03?",
            "writeInAnswer": 1000000,
            "furtherInfo": {
                "explanation": "What is one billion divided by 1E+03?<br /><br />Think of it as: 1E09 / 1E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+06."
            }
        },
        {
            "question": "What is one hundred billion divided by 3E+10?",
            "writeInAnswer": 3,
            "furtherInfo": {
                "explanation": "What is one hundred billion divided by 3E+10?<br /><br />Think of it as: 1E11 / 3E10.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
            }
        },
        {
            "question": "What is ten thousand divided by 3E+02?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is ten thousand divided by 3E+02?<br /><br />Think of it as: 1E04 / 3E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+01."
            }
        },
        {
            "question": "What is thirty thousand divided by 3E+00?",
            "writeInAnswer": 10000,
            "furtherInfo": {
                "explanation": "What is thirty thousand divided by 3E+00?<br /><br />Think of it as: 3E04 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+04."
            }
        },
        {
            "question": "What is three trillion divided by 3E+01?",
            "writeInAnswer": 100000000000,
            "furtherInfo": {
                "explanation": "What is three trillion divided by 3E+01?<br /><br />Think of it as: 3E12 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+11."
            }
        },
        {
            "question": "What is ten divided by 3E+00?",
            "writeInAnswer": 3,
            "furtherInfo": {
                "explanation": "What is ten divided by 3E+00?<br /><br />Think of it as: 1E01 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
            }
        },
        {
            "question": "What is one billion divided by 3E+07?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is one billion divided by 3E+07?<br /><br />Think of it as: 1E09 / 3E07.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+01."
            }
        },
        {
            "question": "What is thirty trillion divided by 1E+04?",
            "writeInAnswer": 3000000000,
            "furtherInfo": {
                "explanation": "What is thirty trillion divided by 1E+04?<br /><br />Think of it as: 3E13 / 1E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+09."
            }
        },
        {
            "question": "What is thirty divided by 3E+00?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is thirty divided by 3E+00?<br /><br />Think of it as: 3E01 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
            }
        },
        {
            "question": "What is three trillion divided by 1E+05?",
            "writeInAnswer": 30000000,
            "furtherInfo": {
                "explanation": "What is three trillion divided by 1E+05?<br /><br />Think of it as: 3E12 / 1E05.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+07."
            }
        },
        {
            "question": "What is thirty divided by 1E+00?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is thirty divided by 1E+00?<br /><br />Think of it as: 3E01 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
            }
        },
        {
            "question": "What is thirty thousand divided by 1E+01?",
            "writeInAnswer": 3000,
            "furtherInfo": {
                "explanation": "What is thirty thousand divided by 1E+01?<br /><br />Think of it as: 3E04 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+03."
            }
        },
        {
            "question": "What is three trillion divided by 1E+11?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is three trillion divided by 1E+11?<br /><br />Think of it as: 3E12 / 1E11.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
            }
        },
        {
            "question": "What is one thousand divided by 1E+01?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is one thousand divided by 1E+01?<br /><br />Think of it as: 1E03 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
            }
        },
        {
            "question": "What is thirty trillion divided by 3E+10?",
            "writeInAnswer": 1000,
            "furtherInfo": {
                "explanation": "What is thirty trillion divided by 3E+10?<br /><br />Think of it as: 3E13 / 3E10.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
            }
        },
        {
            "question": "What is thirty thousand divided by 3E+00?",
            "writeInAnswer": 10000,
            "furtherInfo": {
                "explanation": "What is thirty thousand divided by 3E+00?<br /><br />Think of it as: 3E04 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+04."
            }
        },
        {
            "question": "What is one hundred billion divided by 3E+06?",
            "writeInAnswer": 30000,
            "furtherInfo": {
                "explanation": "What is one hundred billion divided by 3E+06?<br /><br />Think of it as: 1E11 / 3E06.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+04."
            }
        },
        {
            "question": "What is thirty billion divided by 1E+03?",
            "writeInAnswer": 30000000,
            "furtherInfo": {
                "explanation": "What is thirty billion divided by 1E+03?<br /><br />Think of it as: 3E10 / 1E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+07."
            }
        },
        {
            "question": "What is thirty trillion divided by 3E+03?",
            "writeInAnswer": 10000000000,
            "furtherInfo": {
                "explanation": "What is thirty trillion divided by 3E+03?<br /><br />Think of it as: 3E13 / 3E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+10."
            }
        },
        {
            "question": "What is one thousand divided by 1E+02?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is one thousand divided by 1E+02?<br /><br />Think of it as: 1E03 / 1E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
            }
        },
        {
            "question": "What is one hundred billion divided by 3E+06?",
            "writeInAnswer": 30000,
            "furtherInfo": {
                "explanation": "What is one hundred billion divided by 3E+06?<br /><br />Think of it as: 1E11 / 3E06.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+04."
            }
        },
        {
            "question": "What is three trillion divided by 1E+00?",
            "writeInAnswer": 3000000000000,
            "furtherInfo": {
                "explanation": "What is three trillion divided by 1E+00?<br /><br />Think of it as: 3E12 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+12."
            }
        },
        {
            "question": "What is one hundred divided by 1E+00?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is one hundred divided by 1E+00?<br /><br />Think of it as: 1E02 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
            }
        },
        {
            "question": "What is three hundred million divided by 1E+03?",
            "writeInAnswer": 300000,
            "furtherInfo": {
                "explanation": "What is three hundred million divided by 1E+03?<br /><br />Think of it as: 3E08 / 1E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+05."
            }
        },
        {
            "question": "What is one hundred thousand divided by 1E+04?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is one hundred thousand divided by 1E+04?<br /><br />Think of it as: 1E05 / 1E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
            }
        },
        {
            "question": "What is one trillion divided by 3E+04?",
            "writeInAnswer": 30000000,
            "furtherInfo": {
                "explanation": "What is one trillion divided by 3E+04?<br /><br />Think of it as: 1E12 / 3E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+07."
            }
        },
        {
            "question": "What is one hundred divided by 1E+00?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is one hundred divided by 1E+00?<br /><br />Think of it as: 1E02 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
            }
        },
        {
            "question": "What is ten thousand divided by 3E+03?",
            "writeInAnswer": 3,
            "furtherInfo": {
                "explanation": "What is ten thousand divided by 3E+03?<br /><br />Think of it as: 1E04 / 3E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
            }
        },
        {
            "question": "What is ten million divided by 3E+03?",
            "writeInAnswer": 3000,
            "furtherInfo": {
                "explanation": "What is ten million divided by 3E+03?<br /><br />Think of it as: 1E07 / 3E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+03."
            }
        },
        {
            "question": "What is thirty billion divided by 3E+07?",
            "writeInAnswer": 1000,
            "furtherInfo": {
                "explanation": "What is thirty billion divided by 3E+07?<br /><br />Think of it as: 3E10 / 3E07.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
            }
        },
        {
            "question": "What is three hundred million divided by 1E+02?",
            "writeInAnswer": 3000000,
            "furtherInfo": {
                "explanation": "What is three hundred million divided by 1E+02?<br /><br />Think of it as: 3E08 / 1E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+06."
            }
        },
        {
            "question": "What is thirty divided by 1E+00?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is thirty divided by 1E+00?<br /><br />Think of it as: 3E01 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
            }
        },
        {
            "question": "What is three trillion divided by 3E+10?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is three trillion divided by 3E+10?<br /><br />Think of it as: 3E12 / 3E10.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
            }
        },
        {
            "question": "What is one million divided by 3E+02?",
            "writeInAnswer": 3000,
            "furtherInfo": {
                "explanation": "What is one million divided by 3E+02?<br /><br />Think of it as: 1E06 / 3E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+03."
            }
        },
        {
            "question": "What is one hundred divided by 1E+00?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is one hundred divided by 1E+00?<br /><br />Think of it as: 1E02 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
            }
        },
        {
            "question": "What is three trillion divided by 3E+10?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is three trillion divided by 3E+10?<br /><br />Think of it as: 3E12 / 3E10.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
            }
        },
        {
            "question": "What is one million divided by 3E+04?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is one million divided by 3E+04?<br /><br />Think of it as: 1E06 / 3E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+01."
            }
        },
        {
            "question": "What is one hundred divided by 1E+01?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is one hundred divided by 1E+01?<br /><br />Think of it as: 1E02 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
            }
        },
        {
            "question": "What is ten divided by 3E+00?",
            "writeInAnswer": 3,
            "furtherInfo": {
                "explanation": "What is ten divided by 3E+00?<br /><br />Think of it as: 1E01 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
            }
        },
        {
            "question": "What is three billion divided by 1E+00?",
            "writeInAnswer": 3000000000,
            "furtherInfo": {
                "explanation": "What is three billion divided by 1E+00?<br /><br />Think of it as: 3E09 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+09."
            }
        },
        {
            "question": "What is one hundred million divided by 1E+00?",
            "writeInAnswer": 100000000,
            "furtherInfo": {
                "explanation": "What is one hundred million divided by 1E+00?<br /><br />Think of it as: 1E08 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+08."
            }
        },
        {
            "question": "What is ten million divided by 3E+04?",
            "writeInAnswer": 300,
            "furtherInfo": {
                "explanation": "What is ten million divided by 3E+04?<br /><br />Think of it as: 1E07 / 3E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+02."
            }
        },
        {
            "question": "What is ten billion divided by 1E+08?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is ten billion divided by 1E+08?<br /><br />Think of it as: 1E10 / 1E08.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
            }
        },
        {
            "question": "What is ten million divided by 1E+00?",
            "writeInAnswer": 10000000,
            "furtherInfo": {
                "explanation": "What is ten million divided by 1E+00?<br /><br />Think of it as: 1E07 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+07."
            }
        },
        {
            "question": "What is thirty billion divided by 3E+01?",
            "writeInAnswer": 1000000000,
            "furtherInfo": {
                "explanation": "What is thirty billion divided by 3E+01?<br /><br />Think of it as: 3E10 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+09."
            }
        },
        {
            "question": "What is three hundred million divided by 3E+01?",
            "writeInAnswer": 10000000,
            "furtherInfo": {
                "explanation": "What is three hundred million divided by 3E+01?<br /><br />Think of it as: 3E08 / 3E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+07."
            }
        },
        {
            "question": "What is one hundred billion divided by 3E+00?",
            "writeInAnswer": 30000000000,
            "furtherInfo": {
                "explanation": "What is one hundred billion divided by 3E+00?<br /><br />Think of it as: 1E11 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+10."
            }
        },
        {
            "question": "What is one hundred divided by 1E+00?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is one hundred divided by 1E+00?<br /><br />Think of it as: 1E02 / 1E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
            }
        },
        {
            "question": "What is one hundred million divided by 1E+04?",
            "writeInAnswer": 10000,
            "furtherInfo": {
                "explanation": "What is one hundred million divided by 1E+04?<br /><br />Think of it as: 1E08 / 1E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+04."
            }
        },
        {
            "question": "What is ten thousand divided by 1E+03?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is ten thousand divided by 1E+03?<br /><br />Think of it as: 1E04 / 1E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+01."
            }
        },
        {
            "question": "What is thirty thousand divided by 1E+03?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is thirty thousand divided by 1E+03?<br /><br />Think of it as: 3E04 / 1E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+01."
            }
        },
        {
            "question": "What is three hundred billion divided by 3E+04?",
            "writeInAnswer": 10000000,
            "furtherInfo": {
                "explanation": "What is three hundred billion divided by 3E+04?<br /><br />Think of it as: 3E11 / 3E04.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+07."
            }
        },
        {
            "question": "What is one hundred million divided by 3E+00?",
            "writeInAnswer": 30000000,
            "furtherInfo": {
                "explanation": "What is one hundred million divided by 3E+00?<br /><br />Think of it as: 1E08 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+07."
            }
        },
        {
            "question": "What is ten thousand divided by 3E+03?",
            "writeInAnswer": 3,
            "furtherInfo": {
                "explanation": "What is ten thousand divided by 3E+03?<br /><br />Think of it as: 1E04 / 3E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+00."
            }
        },
        {
            "question": "What is three hundred billion divided by 1E+03?",
            "writeInAnswer": 300000000,
            "furtherInfo": {
                "explanation": "What is three hundred billion divided by 1E+03?<br /><br />Think of it as: 3E11 / 1E03.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 3E+08."
            }
        },
        {
            "question": "What is thirty thousand divided by 3E+02?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is thirty thousand divided by 3E+02?<br /><br />Think of it as: 3E04 / 3E02.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+02."
            }
        },
        {
            "question": "What is one hundred million divided by 1E+05?",
            "writeInAnswer": 1000,
            "furtherInfo": {
                "explanation": "What is one hundred million divided by 1E+05?<br /><br />Think of it as: 1E08 / 1E05.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+03."
            }
        },
        {
            "question": "What is one hundred billion divided by 1E+05?",
            "writeInAnswer": 1000000,
            "furtherInfo": {
                "explanation": "What is one hundred billion divided by 1E+05?<br /><br />Think of it as: 1E11 / 1E05.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+06."
            }
        },
        {
            "question": "What is one hundred divided by 3E+00?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is one hundred divided by 3E+00?<br /><br />Think of it as: 1E02 / 3E00.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's approximately 3E+01."
            }
        },
        {
            "question": "What is one billion divided by 1E+01?",
            "writeInAnswer": 100000000,
            "furtherInfo": {
                "explanation": "What is one billion divided by 1E+01?<br /><br />Think of it as: 1E09 / 1E01.<br /><br />Divide the numbers and subtract the exponents.<br /><br />That's 1E+08."
            }
        }
    ],
};

export default wellRoundedDivision4;