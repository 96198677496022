const boneQuiz = {
    name: 'Bone Quiz',
    id: 'bNB68UeKuys8bizP55nY',
    linkTo: "/quizzes/boneQuiz",
    explainerOn: true,
    subheading: `Is that the scapula or the tibia? The ulna or the incus? We have a bone to pick with you about how well you know your own body.`,
    description: `Your support in time of need, they provide structure to your life and keep you standing tall. I'm talking about your bones, of course. Unique even among other primates, our skeletal structure is part of what makes us human. It's no coincidence that when we say we know something well, we say we know it down to the bone.`,
    questions: [
        {
            //question 1//
            question: `How many bones are in the human body?`,
            choices: ['26', '206', '2,006', '2,600'],
            answerIdx: 1,    
            furtherInfo: {
                explanation: (
                    `The average adult human body has 206 bones, from the large femur in your thigh to the delicate phalanges in your fingers.`
                ),
                sources: [
                    {
                        source: 'Better Health Victoria',
                        url: 'https://www.betterhealth.vic.gov.au/health/ConditionsAndTreatments/bones',
                    },
                ],
            },
        },
        {
            //question 2//
            question: `How many types of bones are there in the human body?`,
            choices: ['5', '15', '25', '50'],
            answerIdx: 0,    
            furtherInfo: {
                explanation: (
                    `There are five types of bones in the human body: Flat Bones like the scapula and sternum, Long Bones like the femur and humerus, Short Bones like the carpals, and tarsals, Irregular Bones like the vertebrae and sacrum, and Sesamoid Bones like the patella.`
                ),
                sources: [
                    {
                        source: 'The Visible Body',
                        url: 'https://www.visiblebody.com/learn/skeleton/types-of-bones#:~:text=There%20are%20five%20types%20of,short%2C%20irregular%2C%20and%20sesamoid',
                    },
                ],
            },
        },
        {
            //question 3//
            question: `What are the three types of bone tissue in the human body?`,
            choices: ['Epithelial, Connective, Nervous', 'Distal, Tarsal, Medial', 'Basale, Spinosum, Granulosum', 'Compact, Cancellous, Subchondral'],
            answerIdx: 3,    
            furtherInfo: {
                explanation: (
                    `Human beings have three types of bone tissue: Compact Tissue which is the harder outermost layer, Cancellous Tissue which is the surprisingly spongy tissue inside our bones, and Subchondral Tissue which is smooth tissue found at the ends of our bones that is covered in cartilage.`
                ),
                sources: [
                    {
                        source: 'University of Rochester Medical Center',
                        url: 'https://www.urmc.rochester.edu/encyclopedia/content.aspx?contenttypeid=85&contentid=P00109',
                    },
                ],
            },
        },
        {
            //question 4//
            question: `How many bones will the average person fracture in their lifetime?`,
            choices: ['0', '1', '2', '4'],
            answerIdx: 2,    
            furtherInfo: {
                explanation: (
                    `The average person will break two bones in the course of their lifetime.`
                ),
                sources: [
                    {
                        source: 'Osteo-Pharma',
                        url: 'https://www.osteo-pharma.com/bone-fracture-facts/#:~:text=On%20average%2C%20every%20person%20will,the%20course%20of%20a%20lifetime.',
                    },
                ],
            },
        },
        {
            //question 5//
            question: `How many bones is a baby born with?`,
            choices: ['100', '156', '206', '300'],
            answerIdx: 3,    
            furtherInfo: {
                explanation: (
                    `A healthy human baby is born with 300 bones. As we age, our bones fuse together to form the 206 bones found in a human adult.`
                ),
                sources: [
                    {
                        source: 'Kids Health',
                        url: "https://kidshealth.org/en/kids/bones.html#:~:text=A%20baby's%20body%20has%20about,are%20partly%20made%20of%20cartilage.",
                    },
                ],
            },
        },
        {
            //question 6//
            question: `How many vertebrae does the cervical spine contain?`,
            choices: ['4', '7', '17', '27'],
            answerIdx: 1,    
            furtherInfo: {
                explanation: (
                    `The cervical spine, which is the part of our spine that runs between the shoulders and the base of the skull and is otherwise known as the neck, consists of seven vertebrae. The section of the spine that runs from the shoulders to the base of the abdomen is called the thoracic spine, which connects to the lowest portion of our spine known as the lumbar spine.`
                ),
                sources: [
                    {
                        source: 'Spine Health',
                        url: 'https://www.spine-health.com/conditions/spine-anatomy/cervical-spine-anatomy',
                    },
                ],
            },
        },
        {
            //question 7//
            question: `What is the smallest bone in the body? (Bonus question: where is it located?)`,
            choices: ['Metacarpal', 'Phalanges', 'Stapes', 'Malleus'],
            answerIdx: 2,    
            furtherInfo: {
                explanation: (
                    `The smallest bone in the human body is the stapes, which is located in the inner ear along with the malleus and the incus; all of which together allow us to hear sound.`
                ),
                sources: [
                    {
                        source: 'Healthline',
                        url: 'https://www.healthline.com/human-body-maps/stapes-bone#1',
                    },
                ],
            },
        },
        {
            //question 8//
            question: `Combined, the human hands and feet contain how many bones?`,
            choices: ['6', '26', '56', '106'],
            answerIdx: 3,    
            furtherInfo: {
                explanation: (
                    `The hands and feet combined contain 106 bones; that's over half the bones in our body.`
                ),
                sources: [
                    {
                        source: 'Live Science',
                        url: "https://www.livescience.com/44137-skeletal-system-surprising-facts.html#:~:text=The%20hands%20and%20feet%20contain%20over%20half%20of%20the%20body's%20bones.&text=Coming%20out%20on%20top%20are,bones%20in%20your%20entire%20body.",
                    },
                ],
            },
        },
        {
            //question 9//
            question: `What is the most commonly fractured bone in the body?`,
            choices: ['Clavicle', 'Incus', 'Humerus', 'Femur'],
            answerIdx: 0,    
            furtherInfo: {
                explanation: (
                    ` The most commonly fractured bone is the clavicle or 'collarbone'. It only takes 7 pounds of pressure to break!`
                ),
                sources: [
                    {
                        source: 'Reference.com',
                        url: 'https://www.reference.com/science/much-pressure-breaks-clavicle-4f27dab1ab8a3883',
                    },
                ],
            },
        },
        {
            //question 10//
            question: `Tendons connect one bone to another bone.`,
            choices: ['True', 'False'],
            answerIdx: 1,    
            furtherInfo: {
                explanation: (
                    `False! Tendons connect muscle to bone; <i>ligaments</i> connect bone to bone. For example, the Anterior Cruciate Ligament (ACL) in the knee connects the femur to the tibia. The patellar <i>tendon</i> connects the quadriceps muscle group to the tibial tuberosity on the anterior portion of the tibia.`
                ),
                sources: [
                    {
                        source: 'Encyclopedia Britannica',
                        url: 'https://www.britannica.com/science/tendon',
                    },
                ],
            },
        },
    ],
};

export default boneQuiz;