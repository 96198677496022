 const brainHealth = {
    name: 'Is Your Brain Healthy?',
    id: 'lFHyolRZRBpoKmBcAYP6',
    linkTo: "/quizzes/brainHealth",
    explainerOn: true,
    description: "The brain is responsible for coordinating all the functions of the human body, and is arguably our most important organ. A healthy brain is one of the keys to leading a healthy life. But how do you keep your brain healthy?",
    questions: [
        {
            //question 1//
            question: "According to the Global Council on Brain Health, what does the brain require to function seamlessly?",
            choices: ['Our ability to think and reason', 'Our ability to socialize, and interact successfully with others', 'Emotional regulation, so we can generate a sense of well-being for ourselves', 'All of the above'],
            answerIdx: 3,    
            furtherInfo: {
                explanation: (
                    `To function seamlessly the brain requires all of the above: our ability to think and reason, our ability to socialize, and our emotional regulation.`
                ),
                sources: [
                    {
                        source: 'New Scientist magazine',
                        url: 'https://www.newscientist.com/article/mg25033300-400-how-to-keep-your-brain-healthy-the-7-things-you-should-do-every-day/',
                    },
                ],
            },
        },
        {
            //question 2//
           question: "According to a study published in Nature, our lifelong cognitive abilities are determined by what percent lifestyle, and what percent DNA?",
            choices: ['75% lifestyle, 25% DNA', '50% lifestyle, 50% DNA', '25% lifestyle, 75% DNA', '10% lifestyle, 90% DNA'],
            answerIdx: 0,    
            furtherInfo: {
                explanation: (
                    `Our lifetime cognitive abilities are determined by 75% lifestyle and 25% DNA.`
                ),
                sources: [
                    {
                        source: 'New Scientist magazine',
                        url: 'https://www.newscientist.com/article/mg25033300-400-how-to-keep-your-brain-healthy-the-7-things-you-should-do-every-day/',
                    },
                ],
            },
        },
        {
            //question 3//
            question: "The human brain can produce new neurons until what age?",
            choices: ['18', '25', '50', '90'],
            answerIdx: 3,    
            furtherInfo: {
                explanation: (
                    `The human brain can keep producing new neurons up until age 90.`
                ),
                sources: [
                    {
                        source: 'New Scientist magazine',
                        url: 'https://www.newscientist.com/article/mg25033300-400-how-to-keep-your-brain-healthy-the-7-things-you-should-do-every-day/',
                    },
                ],
            },
        },
        {
            //question 4//
            question: "Gut bacteria is thought to be responsible for:",
            choices: ['Our body’s ability to produce vitamins A, B-12, and D.', '30% of our ability to fight disease, including Alzheimer’s and Parkinson’s.', '90% of the serotonin (a mood stabilizer) our bodies produce, as well as ALS, Alzheimer’s and Parkinson’s disease.', '50% of the lysine (an amino acid) our bodies produce, as well as early onset Alzheimer’s disease and ALS.'],
            answerIdx: 2,    
            furtherInfo: {
                explanation: (
                    `Gut bacteria is thought to be responsible for 90% of the serotonin our bodies produce, as well as ALS, Alzheimer’s, and Parkinson’s disease.`
                ),
                sources: [
                    {
                        source: 'New Scientist magazine',
                        url: 'https://www.newscientist.com/article/mg25033300-400-how-to-keep-your-brain-healthy-the-7-things-you-should-do-every-day/',
                    },
                ],
            },
        },
        {
            //question 5//
            question: "True or False: A high BMI, stress, erratic eating, lack of exercise, and jet lag can all interfere with our gut bacteria, which in turn affects our brain health.",
            choices: ['True', 'False'],
            answerIdx: 0,    
            furtherInfo: {
                explanation: (
                    `This is true. A high BMI, stress, erratic eating, lack of exercise, and jet lag can all interfere with gut bacteria and so our brain health.`
                ),
                sources: [
                    {
                        source: 'New Scientist magazine',
                        url: 'https://www.newscientist.com/article/mg25033300-400-how-to-keep-your-brain-healthy-the-7-things-you-should-do-every-day/',
                    },
                ],
            },
        },
        {
            //question 6//
            question: "True or False: Brain teasers, crossword puzzles, and computer games slow down cognitive decline by working out our cognitive skills, memory, and reasoning.",
            choices: ['True', 'False'],
            answerIdx: 1,    
            furtherInfo: {
                explanation: (
                    `This common claim is actually false. Brain teasers and similar activities do not actually slow cognitive decline.`
                ),
                sources: [
                    {
                        source: 'New Scientist magazine',
                        url: 'https://www.newscientist.com/article/mg25033300-400-how-to-keep-your-brain-healthy-the-7-things-you-should-do-every-day/',
                    },
                ],
            },
        },
        {
            //question 7//
            question: "True or False: Intermittent fasting helps create brain cells, especially in the learning and memory centers.",
            choices: ['True', 'False'],
            answerIdx: 0,    
            furtherInfo: {
                explanation: (
                    `True, intermittent fasting does help create brain cells, especially in the learning and memory centers.`
                ),
                sources: [
                    {
                        source: 'New Scientist magazine',
                        url: 'https://www.newscientist.com/article/mg25033300-400-how-to-keep-your-brain-healthy-the-7-things-you-should-do-every-day/',
                    },
                ],
            },
        },
        {
            //question 8//
            question: "Lack of sleep is a risk factor for cognitive decline. What is the recommended minimum number of hours an adult needs to sleep per day to stay healthy?",
            choices: ['6', '7', '8', '9'],
            answerIdx: 1,    
            furtherInfo: {
                explanation: (
                    `Adults need a minimum of 7 hours of sleep per day to stay healthy.`
                ),
                sources: [
                    {
                        source: 'New Scientist magazine',
                        url: 'https://www.newscientist.com/article/mg25033300-400-how-to-keep-your-brain-healthy-the-7-things-you-should-do-every-day/',
                    },
                ],
            },
        },
        {
            //question 9//
            question: "True or False: Maintaining social relationships, staying active, and having a sense of purpose reduce inflammation and improve cognitive function throughout one’s lifetime.",
            choices: ['True', 'False'],
            answerIdx: 0,    
            furtherInfo: {
                explanation: (
                    `This is true; maintaining social relationships, staying active, and having a sense of purpose all reduce inflammation and improve cognitive function.`
                ),
                sources: [
                    {
                        source: 'New Scientist magazine',
                        url: 'https://www.newscientist.com/article/mg25033300-400-how-to-keep-your-brain-healthy-the-7-things-you-should-do-every-day/',
                    },
                ],
            },
        },
        {
            //question 10//
            question: "True or False: Chewing gum improves both thinking and memory.",
            choices: ['True', 'False'],
            answerIdx: 0,    
            furtherInfo: {
                explanation: (
                    `Astonishingly, this is true; chewing gum does improve both thinking and memory.`
                ),
                sources: [
                    {
                        source: 'New Scientist magazine',
                        url: 'https://www.newscientist.com/article/mg25033300-400-how-to-keep-your-brain-healthy-the-7-things-you-should-do-every-day/',
                    },
                ],
            },
        },
    ],

};

export default brainHealth;