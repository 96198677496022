const engineeringClimateSolutions = {
  name: "Engineering Climate Solutions",
  id: 'xCFNkB7KzbSpJmHFPEt8',
  linkTo: "/quizzes/engineeringClimateSolutions",
  explainerOn: true,
  description: `Climate change is a vast and complex issue and as such it requires a variety of solutions to combat it. Thankfully, there are many scientists and engineers ready to tackle these solutions. However, the solutions are only one piece of the puzzle. Innovative solutions, and the policies to implement them must go hand in hand if we are to combat climate change.`,
  questions: [
      { // Q-1
          question: "When were all the processes for making solar panels production-ready?",
          choices: ['1985', '1995', '2005', '2015'],
          answerIdx: 1,    
          furtherInfo: {
              explanation: (
                  `The basic processes for making solar panels, such as making silicon wafers and applying metal grids to the solar cell surfaces, were ready by 1995.`
              ),
              sources: [
                  {
                      source: "IEEE: 'Engineers: You Can Disrupt Climate Change'",
                      url: 'https://spectrum.ieee.org/engineers-you-can-disrupt-climate-change',
                  },
              ],
          },
      },
      { // Q-2
          question: "When did the global market for solar panels really jump start?",
          choices: ['1985', '1995', '2005', '2015'],
          answerIdx: 2,    
          furtherInfo: {
              explanation: (
                  `Though all the processes for producing solar panels were ready in 1995, the global market for them did not really take off until a decade later, in 2005, when Germany started offering generous long-term contracts for solar-energy producers.`
              ),
              sources: [
                {
                  source: "IEEE: 'Engineers: You Can Disrupt Climate Change'",
                  url: 'https://spectrum.ieee.org/engineers-you-can-disrupt-climate-change',
                },
              ],
          },
      },
      { // Q-3 
          question: "Representing a difficult decarbonization problem, what percent of global carbon emissions is produced by air travel?",
          choices: ['2.5%', '25%', '50%', '75%'],
          answerIdx: 0,
          furtherInfo: {
              explanation: (
                  `Currently 2.5% of global carbon emissions are produced by air travel.`
              ),
              sources: [
                {
                  source: "IEEE: 'Engineers: You Can Disrupt Climate Change'",
                  url: 'https://spectrum.ieee.org/engineers-you-can-disrupt-climate-change',
                },
              ],
          },
      },
      { // Q-4
          question: "What is one solution currently being developed to decarbonize commercial air travel?",
          choices: ['electric powered planes', 'phasing out air travel', 'direct capturing airplane exhaust', 'hydrogen-based fuel'],
          answerIdx: 3,    
          furtherInfo: {
              explanation: (
                  `Airbus is currently working on a hydrogen-fueled plane scheduled to see commercial service in 2035.`
              ),
              sources: [
                {
                  source: "IEEE: 'Engineers: You Can Disrupt Climate Change'",
                  url: 'https://spectrum.ieee.org/engineers-you-can-disrupt-climate-change',
                },
              ],
          },
      },
      { // Q-5
          question: "Most of today’s hydrogen is actually bad for the environment.",
          choices: ['True', 'False'],
          answerIdx: 0,    
          furtherInfo: {
              explanation: (
                  `This is unfortunately true. Traditionally, hydrogen is made from fossil methane gas in a process that emits CO₂. However, if low-carbon electricity is used to power electrolysis to produce hydrogen, it becomes much more environmentally friendly.`
              ),
              sources: [
                {
                  source: "IEEE: 'Engineers: You Can Disrupt Climate Change'",
                  url: 'https://spectrum.ieee.org/engineers-you-can-disrupt-climate-change',
                },
              ],
          },
      },
      { // Q-6
          question: "What other breakthrough could clean hydrogen lead to?",
          choices: [
            'decarbonizing the production of plastics',
            'decarbonizing the production of metals',
            'decarbonizing the production of textiles',
            'decarbonizing the production of glass'
          ],
          answerIdx: 1,    
          furtherInfo: {
              explanation: (
                  `Thanks largely to the European Union’s carbon market, new climate-friendly methods are being piloted to remove the oxygen from metals using hydrogen and electric arc furnaces.`
              ),
              sources: [
                {
                  source: "IEEE: 'Engineers: You Can Disrupt Climate Change'",
                  url: 'https://spectrum.ieee.org/engineers-you-can-disrupt-climate-change',
                },
              ],
          },
      },
      { // Q-7
          question: "To supply the 1 zettajoule of electricity humans currently use each year,  with nuclear power alone, we would have to build 3 1-gigawatt nuclear plants every day between now and the year 2050.",
          choices: ['True', 'False'],
          answerIdx: 0,    
          furtherInfo: {
              explanation: (
                  `<b>This is true.</b> Today we use more electricity than ever before, and that demand will only keep growing. Obviously, building 3 nuclear power plants every day for 3 decades is not a feasible solution for carbon-free electricity. We will need many diverse solutions working in tandem to achieve this goal.`
              ),
              sources: [
                {
                  source: "IEEE: 'Engineers: You Can Disrupt Climate Change'",
                  url: 'https://spectrum.ieee.org/engineers-you-can-disrupt-climate-change',
                },
              ],
          },
      },
      { // Q-8
          question: "How many gigatonnes of CO₂ do the world’s oceans absorb and almost completely re-emit per year?",
          choices: ['20', '200', '2,000', '20,000'],
          answerIdx: 1,    
          furtherInfo: {
              explanation: (
                  `The world’s oceans absorb approximately 200 gigatonnes of CO₂ per year and re-emit almost all of it. For reference, 200 gigatonnes are equivalent to the weight of approximately 40 billion elephants.`
              ),
              sources: [
                {
                  source: "IEEE: 'Engineers: You Can Disrupt Climate Change'",
                  url: 'https://spectrum.ieee.org/engineers-you-can-disrupt-climate-change',
                },
                {
                  source: "Energy Education: University of Calgary",
                  url: 'https://energyeducation.ca/encyclopedia/Gigatonne',
                },
              ],
          },
      },
      { // Q-9
          question: "What percent of the oceans’ re-emission of CO₂ would we need to prevent per year, in order to meet the goal of sequestering 2,000 gigatonnes of atmospheric CO₂ over the next 100 years?",
          choices: ['1%', '10%', '20%', '50%'],
          answerIdx: 2,    
          furtherInfo: {
              explanation: (
                  `If 10% of the oceans’ re-emission was prevented for 100 years, we would meet the goal of sequestering 2,000 gigatonnes of atmospheric carbon.\n\n<b>200</b> (Gt of CO₂ absorbed by oceans) <b>x 0.1</b> (10%) <b>= 20</b> (Gt sequestered per year).\n\n<b>20</b> (Gt per year) <b>x 100</b> (years) <b>= 2,000</b> (Gt sequestered in total).`
              ),
              sources: [
                {
                  source: "IEEE: 'Engineers: You Can Disrupt Climate Change'",
                  url: 'https://spectrum.ieee.org/engineers-you-can-disrupt-climate-change',
                },
              ],
          },
      },
      { // Q-10
          question: "What is an example of altering radiative forcing (AKA reflecting sunlight back into space) to reduce climate change?",
          choices: [
            'shielding nuclear power plants',
            'reducing the formation of airplane contrails',
            'painting roofs white to reflect sunlight',
            'both C and B'
          ],
          answerIdx: 3,    
          furtherInfo: {
              explanation: (
                  `Altering radiative forcing, or reflecting sunlight back into space, may be a viable stopgap measure while other, long-term climate solutions are put into place. Reducing airplane contrails (trails of exhaust), which trap heat, and painting roofs and other surfaces white to reflect sunlight, are both examples of this strategy and could reduce our expected planetary warming by 3%.`
              ),
              sources: [
                {
                  source: "IEEE: 'Engineers: You Can Disrupt Climate Change'",
                  url: 'https://spectrum.ieee.org/engineers-you-can-disrupt-climate-change',
                },
              ],
          },
      },
  ],

};

export default engineeringClimateSolutions;