import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, Paper } from "@material-ui/core";
import BackspaceIcon from "@material-ui/icons/Backspace";
import KeyboardReturnIcon from "@material-ui/icons/KeyboardReturn";
import useSound from "use-sound";

import KeypressSound from "../../assets/sounds/keypress.mp3";
import BackspaceSound from "../../assets/sounds/backspace.mp3";

const btnSize = 85;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(5),
    backgroundColor: "#6a71fa",
    border: "5px solid #ffffff",
    borderRadius: "50px",
    maxWidth: "800px",
    margin: "0 auto",
  },
  inputArea: {
    backgroundColor: "#ffffff",
    borderRadius: 19,
    border: "1px solid #ffffff",
    marginBottom: theme.spacing(5),
  },
  inputText: {
    padding: theme.spacing(3),
  },
  centerBtn: {
    display: "flex",
    justifyContent: "center",
  },
  button: {
    width: btnSize,
    height: btnSize,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#ffffff",
    backgroundColor: "#526694",
    borderRadius: "50%",
    border: "4px solid #ffffff",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#8c9cc0",
    },
      
    [theme.breakpoints.down("xs")]: {
      width: btnSize-20,
      height: btnSize-30,
    },
  },
  redHighlight: {
    backgroundColor: "#cc0000",
    "&:hover": {
      backgroundColor: "#ff3333",
    },
  },
  blueHighlight: {
    backgroundColor: "#2929fa",
    "&:hover": {
      backgroundColor: "#8d8dfc",
    },
  },
  greenHighlight: {
    backgroundColor: "#0D7F00",
    "&:hover": {
      backgroundColor: "#17e500",
    },
  },
  buttonText: {
    padding: theme.spacing(3),
    
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(1),
    },
  },
}));

const stateMachine = {
  init(char) {
    if (char === "-") {
      return stateMachine.negativeNumber;
    }
    if (char === ".") {
      return stateMachine.decimal;
    }
    if (/^[0-9]$/.test(char)) {
      return stateMachine.wholeDigit;
    }
    return null;
  },
  negativeNumber(char) {
    if (/^[0-9]$/.test(char)) {
      return stateMachine.wholeDigit;
    }
    return null;
  },
  wholeDigit(char, end) {
    if (/^[0-9]$/.test(char)) {
      return stateMachine.wholeDigit;
    }
    if (char === ".") {
      return stateMachine.decimal;
    }
    if (/^e|E$/.test(char)) {
      return stateMachine.eNotation;
    }
    if (end) {
      return stateMachine.finished;
    }
    return null;
  },
  decimal(char) {
    if (/^[0-9]$/.test(char)) {
      return stateMachine.fractionalDigit;
    }
    return null;
  },
  fractionalDigit(char, end) {
    if (/^[0-9]$/.test(char)) {
      return stateMachine.fractionalDigit;
    }
    if (/^e|E$/.test(char)) {
      return stateMachine.eNotation;
    }
    if (end) {
      return stateMachine.finished;
    }
    return null;
  },
  eNotation(char) {
    if (char === "-") {
      return stateMachine.negativeE;
    }
    if (/^[0-9]$/.test(char)) {
      return stateMachine.eDigit;
    }
    return null;
  },
  negativeE(char) {
    if (/^[0-9]$/.test(char)) {
      return stateMachine.eDigit;
    }
    return null;
  },
  eDigit(char, end) {
    if (/^[0-9]$/.test(char)) {
      return stateMachine.eDigit;
    }
    if (end) {
      return stateMachine.finished;
    }
    return null;
  },
  finished() {
    return null;
  },
};
function NumpadBtn(props) {
  const { button, classes, onClick, xs } = props;
  const handleClick = onClick;

  return (
    <Grid item xs={xs || 3} className={classes.centerBtn}>
      <button
        onClick={handleClick}
        className={`${classes.button} ${
          button.highlight === "red"
            ? classes.redHighlight
            : button.highlight === "blue"
            ? classes.blueHighlight
            : button.highlight === "green"
            ? classes.greenHighlight
            : ""
        }`}
      >
        <Typography variant="h4" className={classes.buttonText}>
          {button.render ? button.render() : <div>{button.content}</div>}
        </Typography>
      </button>
    </Grid>
  );
}

NumpadBtn.propTypes = {
  xs: PropTypes.number,
  onClick: PropTypes.func,
  classes: PropTypes.shape({
    button: PropTypes.any,
    buttonText: PropTypes.any,
  }),
  button: PropTypes.shape({
    content: PropTypes.string,
  }),
};

export default function Numpad(props) {
  const { onFinish } = props;
  const [exp, setExp] = React.useState("");
  const [stateStack, setStateStack] = React.useState([stateMachine.init]);
  const classes = useStyles();
  const [playKeypressSound] = useSound(KeypressSound);
  const [playBackspaceSound] = useSound(BackspaceSound);

  const inputArea = React.createRef();
  useEffect(
    function focusInput() {
      inputArea.current.focus();
    },
    [inputArea]
  );

  function stateTransition(char, end) {
    let transitionFrom = stateStack[stateStack.length - 1];
    let nextState = transitionFrom(char, end);

    if (!nextState) {
      return null;
    }

    const newStack = stateStack;
    newStack.push(nextState);
    setStateStack(newStack);

    if (nextState !== stateMachine.finished) {
      const newExp = exp + char;
      setExp(newExp);
    }

    return nextState;
  }

  function finishInput() {
    if (stateTransition("", true) !== stateMachine.finished) {
      return;
    }

    onFinish(Number.parseFloat(exp));
  }

  function isFinished() {
    return stateStack[stateStack.length - 1] === stateMachine.finished;
  }

  // backspace pops the last state out of the stack and chops the char off the expression.
  function backspace() {
    // exp already empty / in init state, or input is finished
    if (stateStack.length <= 1 || isFinished()) {
      return null;
    }

    const newStack = stateStack.slice(0, -1);
    setStateStack(newStack);

    const newExp = exp.slice(0, -1);
    setExp(newExp);

    playBackspaceSound();
  }

  const numericButtons = [
    [
      // row 1
      {
        content: "7",
      },
      {
        content: "8",
      },
      {
        content: "9",
      },
      {
        // backspace button
        render: () => {
          return <BackspaceIcon />;
        },
        action: (event) => {
          backspace();
        },
        highlight: "red",
      },
    ],
    [
      // row 2
      {
        content: "4",
      },
      {
        content: "5",
      },
      {
        content: "6",
      },
      {
        // clear
        content: "C",
        action: (event) => {
          if (!isFinished()) {
            setExp("");
            setStateStack([stateMachine.init]);
            playBackspaceSound();
          }
        },
      },
    ],
    [
      // row 3
      {
        content: "1",
      },
      {
        content: "2",
      },
      {
        content: "3",
      },
      {
        // e scientific notation
        content: "E",
        highlight: "blue",
      },
    ],
    [
      // row 4
      {
        content: "-",
      },
      {
        content: "0",
      },
      {
        content: ".",
      },
      {
        // enter
        render: () => {
          return <KeyboardReturnIcon />;
        },
        action: (event) => {
          finishInput();
        },
        highlight: "green",
      },
    ],
  ];

  const defaultClick = (content) => (event) => {
    if (stateTransition(content) !== null) {
      playKeypressSound();
    }
  };

  function handleKeypress(event) {
    if (event.keyCode === 8) {
      // backspace key
      backspace();
    } else if (event.keyCode === 13) {
      // enter key
      finishInput();
    } else {
      if (stateTransition(event.key) !== null) {
        playKeypressSound();
      }
    }
  }

  function showExp() {
    if (exp.length > 0) {
      return <span>{exp}</span>;
    }
    return <span>&nbsp;</span>;
  }

  return (
    <Paper variant="outlined" className={classes.root}>
      <Grid container direction="row" justify="center">
        <Grid
          item
          xs={12}
          className={classes.inputArea}
          tabIndex={-1}
          onKeyDown={handleKeypress}
          ref={inputArea}
        >
          <Typography variant="h3" align="right" className={classes.inputText}>
            {showExp()}
          </Typography>
        </Grid>
      </Grid>
      <Grid container direction="row">
        <Grid item xs={12}>
          {numericButtons.map((row, rowIdx) => (
            <Grid container direction="row" key={rowIdx} spacing={3}>
              {row.map((button, buttonIdx) => (
                <NumpadBtn
                  key={buttonIdx}
                  button={button}
                  classes={classes}
                  onClick={
                    button.action ? button.action : defaultClick(button.content)
                  }
                />
              ))}
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Paper>
  );
}
