 const fightingMalaria = {
    name: 'Fighting Malaria',
    id: '3qNCHjznm1FRuWvjPYL2',
    linkTo: "/quizzes/fightingMalaria",
    explainerOn: true,
    description: "Many regard malaria as a scourge from the distant past. However, it remains one of the world's deadliest diseases. How much do you know about malaria and its toll on the world today?",
    questions: [
        { // 1
            question: "How many people get sick from malaria each year?",
            choices: ['25 million', '50 million', '100 million', '200 million'],
            answerIdx: 3,    
            furtherInfo: {
                explanation: "Each year over 200 million people get sick from malaria",
                sources: [
                    {
                        source: 'New Scientist',
                        url: 'https://www.newscientist.com/article/mg25033320-100-can-we-finally-wipe-out-malaria-with-a-vaccine-37-years-in-the-making/',
                    }
                ],
            },
        },
        { // 2
            question: "How many people die each year from malaria?",
            choices: [
                '10 thousand',
                '50 thousand',
                '400 thousand',
                '1 million'
            ],
            answerIdx: 2,    
            furtherInfo: {
                explanation: "Over 400 thousand people die from malaria every year",
                sources: [
                    {
                        source: 'New Scientist',
                        url: 'https://www.newscientist.com/article/mg25033320-100-can-we-finally-wipe-out-malaria-with-a-vaccine-37-years-in-the-making/',
                    }
                ],
            },
        },
        { // 3
            question: "Malaria is a virus spread to humans by mosquitoes.",
            choices: ['True', 'False'],
            answerIdx: 1,    
            furtherInfo: {
                explanation: "False. Although malaria is spread to humans by mosquitoes, the disease is caused by <i>parasites</i>, not a virus.",
                sources: [
                    {
                        source: 'New Scientist',
                        url: 'https://www.newscientist.com/article/mg25033320-100-can-we-finally-wipe-out-malaria-with-a-vaccine-37-years-in-the-making/',
                    }
                ],
            },
        },
        { // 4
            question: "How long have the parasites that cause malaria been around??",
            choices: ['10 thousand yrs','300 thousand yrs', '1 million yrs', '30 million yrs'],
            answerIdx: 3,    
            furtherInfo: {
                explanation: "The parasites that cause malaria have been around for over 30 million years.",
                sources: [
                    {
                        source: 'New Scientist',
                        url: 'https://www.newscientist.com/article/mg25033320-100-can-we-finally-wipe-out-malaria-with-a-vaccine-37-years-in-the-making/',
                    }
                ],
            },
        },
        { // 5
            question: "Malaria is commonly found in how many countries?",
            choices: [
                '27',
                '57',
                '87',
                '117'
            ],
            answerIdx: 2,    
            furtherInfo: {
                explanation: "Malaria is commonly found in 87 countries.",
                sources: [
                    {
                        source: 'New Scientist',
                        url: 'https://www.newscientist.com/article/mg25033320-100-can-we-finally-wipe-out-malaria-with-a-vaccine-37-years-in-the-making/',
                    }
                ],
            },
        },
        { // 6
            question:'Among the 87 countries where malaria is common, what fraction of them account for the majority of cases worldwide?',
            choices: [
                '1/6',
                '1/3',
                '1/2',
                '2/3',
            ],
            answerIdx: 1,    
            furtherInfo: {
                explanation: "The majority of malaria cases are found in just 29 (or 1/3) of the 87 countries where malaria is common.",
                sources: [
                    {
                        source: 'New Scientist',
                        url: 'https://www.newscientist.com/article/mg25033320-100-can-we-finally-wipe-out-malaria-with-a-vaccine-37-years-in-the-making/',
                    }
                ],
            },
        },
        { // 7
            question: "What percentage of malaria cases worldwide are concentrated in just 29 countries?",
            choices: [
                '65%',
                '75%',
                '85%',
                '95%'
            ],
            answerIdx: 3,    
            furtherInfo: {
                explanation: "95% of all cases of malaria worldwide are concentrated in just 29 countries",
                sources: [
                    {
                        source: 'New Scientist',
                        url: 'https://www.newscientist.com/article/mg25033320-100-can-we-finally-wipe-out-malaria-with-a-vaccine-37-years-in-the-making/',
                    }
                ],
            },
        },
        { // 8
            question: 'Which single country accounts for about one quarter of all worldwide malaria infections and deaths?',
            choices: [
                'Nigeria',
                'Malawi',
                'Ghana',
                'Sudan'
            ],
            answerIdx: 0,    
            furtherInfo: {
                explanation: "Nigeria alone accounts for approximately one quarter of worldwide malaria infections and deaths",
                sources: [
                    {
                        source: 'New Scientist',
                        url: 'https://www.newscientist.com/article/mg25033320-100-can-we-finally-wipe-out-malaria-with-a-vaccine-37-years-in-the-making/',
                    }
                ],
            },
        },
        { // 9
            question: 'How much does malaria cost Africa, per year, in treatment and lost productivity? (in $USD)',
            choices: [
                '$19 million',
                '$190 million',
                '1.2 billion',
                '$12 billion',
            ],
            answerIdx: 3,    
            furtherInfo: {
                explanation: "The World Bank estimates that malaria costs Africa $12 billion, annually, in treatment and lost productivity.", // To do
                sources: [
                    {
                        source: 'New Scientist',
                        url: 'https://www.newscientist.com/article/mg25033320-100-can-we-finally-wipe-out-malaria-with-a-vaccine-37-years-in-the-making/',
                    },
                    {
                      source: 'Relief Web',
                      url: 'https://reliefweb.int/report/zambia/world-bank-booster-program-malaria-control-africa-scaling-impact-sufi',
                    }
                ],
            },
        },        
        { // 10
            question: '21st century progress: From 2000 to 2019, the number of malaria deaths in at-risk populations dropped from 25 (per 100,000 people) down to how many?',
            choices: [
              '20',
              '15',
              '10',
              '5',
          ],
            answerIdx: 2,    
            furtherInfo: {
                explanation: "From 2009 to 2019, the number of annual malaria deaths in at-risk populations dropped from 25 (per 100,000 people) down to 10",
                sources: [
                    {
                        source: 'New Scientist',
                        url: 'https://www.newscientist.com/article/mg25033320-100-can-we-finally-wipe-out-malaria-with-a-vaccine-37-years-in-the-making/',
                    },
                    {
                        source: 'World Health Organization (WHO)',
                        url: 'https://www.who.int/teams/global-malaria-programme/reports/world-malaria-report-2020'
                    }
                ],
            },
        },
        { // 11
            question: 'According to WHO estimates, how many cases of malaria were averted, worldwide, from 2000 to 2019?',
            choices: [
                '15 million',
                '150 million',
                '550 million',
                '1.5 billion',
            ],
            answerIdx: 3,    
            furtherInfo: {
                explanation: "The WHO estimates that 1.5 billion cases of malaria were averted worldwide from 2000 to 2019.",
                sources: [
                    {
                        source: 'New Scientist',
                        url: 'https://www.newscientist.com/article/mg25033320-100-can-we-finally-wipe-out-malaria-with-a-vaccine-37-years-in-the-making/',
                    },
                    {
                        source: 'World Health Organization (WHO)',
                        url: 'https://www.who.int/teams/global-malaria-programme/reports/world-malaria-report-2020'
                    }
                ],
            },
        },
        { // 12
            question: 'According to the WHO, the most effective prevention against malaria is bed nets sprayed with insecticide. What is the cost per bed net, in $USD?',
            choices: [
                '$0.50',
                '$1.50',
                '$4.50',
                '$12.50',
            ],
            answerIdx: 2,    
            furtherInfo: {
                explanation: "The cost of a bed net sprayed with insecticide is $4.50.",
                sources: [
                    {
                        source: 'New Scientist',
                        url: 'https://www.newscientist.com/article/mg25033320-100-can-we-finally-wipe-out-malaria-with-a-vaccine-37-years-in-the-making/',
                    },
                    {
                      source: 'National Institutes of Health',
                      url: 'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3348006/',
                    },
                    {
                      source: 'WHO',
                      url: 'https://www.who.int/publications/10-year-review/malaria/en/index6.html',
                    }
                ],
            },
        },
    ],

};

export default fightingMalaria;