const interstellarTravelers = {
  name: "Interstellar Travelers",
  id: "Stl8cKemSV5E9IHpMIDY",
  linkTo: "/quizzes/interstellarTravelers",
  explainerOn: true,
  description:
    "They may not be little green men, but our solar system is visited by a variety of interstellar travelers. They may be comets like 21/Borisov or utterly inscrutable objects like Oumuamua, but one thing is for certain; these far-traveling voyagers will keep passing through our neighborhood.",
  questions: [
    {
      //question 1//
      question:
        "First spotted in October 2017, the interstellar object known as Oumuamua was traveling how many miles per hour?",
      choices: ["5,700", "57,000", "570,000", "5,700,000"],
      answerIdx: 1,
      furtherInfo: {
        explanation: `Oumuamua traveled at a rate of 57,000 miles per hour when first spotted by the Haleakala Observatory in Hawaii.`,
        sources: [
          {
            source:
              "BBC. The visitors from deep space baffling scientists. BBC Future.",
            url:
              "https://www.bbc.com/future/article/20210506-the-interstellar-voyagers-that-visited-our-sun",
          },
        ],
      },
    },
    {
      //question 2//
      question:
        "Oumuamua is thought to have originated from the region surrounding Vega, an alien star _________ miles from Earth.",
      choices: [
        "147,000",
        "147,000,000",
        "147,000,000,000",
        "147,000,000,000,000",
      ],
      answerIdx: 3,
      furtherInfo: {
        explanation: `Scientists believe Oumuamua originated near Vega, around 147 trillion miles from Earth.`,
        sources: [
          {
            source:
              "BBC. The visitors from deep space baffling scientists. BBC Future.",
            url:
              "https://www.bbc.com/future/article/20210506-the-interstellar-voyagers-that-visited-our-sun",
          },
        ],
      },
    },
    {
      //question 3//
      question:
        "Proxima Centauri, the nearest alien star to us, is how many light-years away?",
      choices: ["4.2", "14.2", "140.2", "1,400.2"],
      answerIdx: 0,
      furtherInfo: {
        explanation: `Proxima Centauri is our closest neighbor at a distance of 4.2 light-years.`,
        sources: [
          {
            source:
              "BBC. The visitors from deep space baffling scientists. BBC Future.",
            url:
              "https://www.bbc.com/future/article/20210506-the-interstellar-voyagers-that-visited-our-sun",
          },
        ],
      },
    },
    {
      //question 4//
      question: "For reference, 4.2 light-years are equal to how many miles?",
      choices: [
        "250,000",
        "25,000,000",
        "250,000,000,000",
        "25,000,000,000,000",
      ],
      answerIdx: 3,
      furtherInfo: {
        explanation: `4.2 light-years are equal to 25 trillion miles.`,
        sources: [
          {
            source:
              "BBC. The visitors from deep space baffling scientists. BBC Future.",
            url:
              "https://www.bbc.com/future/article/20210506-the-interstellar-voyagers-that-visited-our-sun",
          },
        ],
      },
    },
    {
      //question 5//
      question:
        "At our current level of technology, in what year would a spacecraft leaving Earth now reach Proxima Centauri?",
      choices: ["2075", "3075", "7501", "75100"],
      answerIdx: 3,
      furtherInfo: {
        explanation: `A spacecraft leaving Earth now would reach Proxima Centauri in the year 75100, a journey of over 73,000 years.`,
        sources: [
          {
            source:
              "BBC. The visitors from deep space baffling scientists. BBC Future.",
            url:
              "https://www.bbc.com/future/article/20210506-the-interstellar-voyagers-that-visited-our-sun",
          },
        ],
      },
    },
    {
      //question 6//
      question:
        "21/Borisov, which is recognized as the first interstellar comet ever found, is thought to have originated from the region around Ross 573, _________ miles from the Sun.",
      choices: [
        "629,000",
        "629,000,000",
        "629,000,000,000",
        "629,000,000,000,000",
      ],
      answerIdx: 3,
      furtherInfo: {
        explanation: `The comet 21/Borisov is thought to have originated from the region surrounding Ross 573, 629 trillion miles from the Sun.`,
        sources: [
          {
            source:
              "BBC. The visitors from deep space baffling scientists. BBC Future.",
            url:
              "https://www.bbc.com/future/article/20210506-the-interstellar-voyagers-that-visited-our-sun",
          },
        ],
      },
    },
    {
      //question 7//
      question:
        "Based on 21/Borisov’s speed and trajectory researchers suggest it was ejected into space how many years ago?",
      choices: ["90,000", "900,000", "9,000,000", "900,000,000"],
      answerIdx: 1,
      furtherInfo: {
        explanation: `Scientists have calculated that 21/Borisov was most likely ejected into space 900 thousand years ago.`,
        sources: [
          {
            source:
              "BBC. The visitors from deep space baffling scientists. BBC Future.",
            url:
              "https://www.bbc.com/future/article/20210506-the-interstellar-voyagers-that-visited-our-sun",
          },
        ],
      },
    },
    {
      //question 8//
      question:
        "The Osiris-Rex mission, launched in 2016, successfully traveled to the Bennu asteroid which is how many miles away?",
      choices: ["2,000", "200,000", "2,000,000", "200,000,000"],
      answerIdx: 3,
      furtherInfo: {
        explanation: `The Bennu asteroid is 200 million miles from Earth.`,
        sources: [
          {
            source:
              "BBC. The visitors from deep space baffling scientists. BBC Future.",
            url:
              "https://www.bbc.com/future/article/20210506-the-interstellar-voyagers-that-visited-our-sun",
          },
        ],
      },
    },
    {
      //question 9//
      question:
        "How many interstellar objects spanning at least 50 meters does research suggest are in our solar system at any given time?",
      choices: ["5", "50", "500", "5,000"],
      answerIdx: 1,
      furtherInfo: {
        explanation: `Research suggests there are 50 interstellar objects of at least 50 meters in our solar system at any given time.`,
        sources: [
          {
            source:
              "BBC. The visitors from deep space baffling scientists. BBC Future.",
            url:
              "https://www.bbc.com/future/article/20210506-the-interstellar-voyagers-that-visited-our-sun",
          },
        ],
      },
    },
    {
      //question 10//
      question:
        "The Vera Rubin Observatory, set to go live in 2022 or 2023, will be using the world’s largest digital camera to survey the night sky looking for objects at least _________ feet across.",
      choices: ["50", "500", "5,000", "50,000"],
      answerIdx: 1,
      furtherInfo: {
        explanation: `The Vera Rubin Observatory will detect objects at least 500 feet in length.`,
        sources: [
          {
            source:
              "BBC. The visitors from deep space baffling scientists. BBC Future.",
            url:
              "https://www.bbc.com/future/article/20210506-the-interstellar-voyagers-that-visited-our-sun",
          },
        ],
      },
    },
  ],
};

export default interstellarTravelers;
