import React, { useState } from "react";
import { useFirestore } from "reactfire";

import useMergedUser from "../../hooks/useMergedUser";
import useNotificationSystem from "../../hooks/useNotificationSystem.js";
import { updateUsername, isUniqueUsername } from "../../firestore/user";
import { isValidUsername, validTextCaption } from "../../utils/isValidUsername";

import Form from "./Form";
import AuthCheck from "../AuthCheckWrapper";
import ProfileContainer from "./Container";
import BackgroundContainer from "./BackgroundContainer";
import Header from "./Header";

export default () => {
  const [username, setUsername] = useState("");
  const firestore = useFirestore();
  const { addNotification } = useNotificationSystem();

  const { authUser, firestoreUser } = useMergedUser((loadedUser) => {
    if (loadedUser.firestoreUser.username) {
      setUsername(loadedUser.firestoreUser.username);
    }
  });

  const successNotif = () => {
    addNotification({
      message: "Username updated",
      level: "success",
    });
  };

  const errorNotif = () => {
    addNotification({
      message: "Names could not be updated",
      level: "error",
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (firestoreUser && firestoreUser.username === username) {
      return;
    }

    if (!isValidUsername(username)) {
      addNotification({
        message: "Invalid username",
        level: "error",
      });
      return;
    }

    const isUnique = await isUniqueUsername(firestore, username);
    if (!isUnique) {
      addNotification({
        message: "Username is not unique",
        level: "error",
      });
      return;
    } else {
      updateUsername(firestore, authUser.uid, username)
        .then(successNotif)
        .catch(errorNotif);
    }
  };

  const inputs = [
    {
      key: "username",
      label: "Username",
      type: "text",
      caption: validTextCaption(),
      value: username,
      onChange: (e) => setUsername(e.target.value),
    },
  ];

  return (
    <AuthCheck>
      <BackgroundContainer>
        <Header title="Name" />
        <ProfileContainer>
          <Form onSubmit={handleSubmit} inputs={inputs} />
        </ProfileContainer>
      </BackgroundContainer>
    </AuthCheck>
  );
};
