 const collegeTuition = {
    name: 'College Tuition Quiz',
    id: '2dI5XU8XLbpjIPftL1XR',
    linkTo: "/quizzes/collegeTuition",
    explainerOn: true,
    description: 'A college education is the dream of many Americans, both young and old. However, rising prices mean that for many it seems like a dream slipping beyond their reach. But what does the rising cost of college education look like in hard numbers?',
    questions: [
        {
            //question 1//
            question: "The average tuition, including fees, room and board, for a single year at a 4-year public institution in 2007-2008 was $15,739 (adjusted for inflation). Ten years later it was $20,050. How much did tuition increase?",
            choices: ['$431', '$4,311', '$43,110', '$431,100'],
            answerIdx: 1,    
            furtherInfo: {
                explanation: (
                    `The average price of college tuition for a 4-year public institution increased $4,311 between 2008 and 2018.<br /><b>(20,050 - 15,739 = $4,311)</b>`
                ),
                sources: [
                    {
                        source: 'National Center for Education Statistics',
                        url: 'https://nces.ed.gov/fastfacts/display.asp?id=76',
                    },
                ],
            },
        },
        {
            //question 2//
            question: "If tuition in 2007-2008 was $15,739, and in 2017-2018 it was $20,050, how large of a percentage increase did tuition undergo?",
            choices: ['27%', '57%', '77%', '97%'],
            answerIdx: 0,    
            furtherInfo: {
                explanation: (
                    `<b>Step One:</b><br/>Percentage increase = 1 - ($20,050 / $15,739), which is 0.273<br/><br/><b>Step Two:</b><br/>Move the decimal point two spots left to turn the decimal into a percentage of 100, so 0.273 = <b>27%</b> tuition increase.`
                ),
                sources: [
                    {
                        source: 'National Center for Education Statistics',
                        url: 'https://nces.ed.gov/fastfacts/display.asp?id=76',
                    },
                ],
            },
        },
        {
            //question 3//
            question: "The average tuition for a 4-year private institution, including fees, room and board, was $35,426 in 2007-2008. Ten years later it was $43,139. How much did tuition increase?",
            choices: ['$7,713,000', '$771,300', '$77,130', '$7,713'],
            answerIdx: 3,    
            furtherInfo: {
                explanation: (
                    `<b>$43,139 - $35,426 = $7,713.</b><br />Between 2008 and 2018, the average annual tuition at a private 4-year institution increased by <b>$7,713</b>.`
                ),
                sources: [
                    {
                        source: 'National Center for Education Statistics',
                        url: 'https://nces.ed.gov/fastfacts/display.asp?id=76',
                    },
                ],
            },
        },
        {
            //question 4//
            question: "Given that tuition in 2007-2008 was $35,426, and in 2017-2018 it was $43,139, how much of a percentage increase did tuition undergo?",
            choices: ['22%', '52%', '72%', '92%'],
            answerIdx: 0,    
            furtherInfo: {
                explanation: (
                    `<b>($43,139/$35,426) - 1 = 0.217, or 21.7%.</b><br />Therefore, we know the annual tuition cost at a 4-year private institution increased by about <b>22%</b> between 2008 and 2018.`
                ),
                sources: [
                    {
                        source: 'National Center for Education Statistics',
                        url: 'https://nces.ed.gov/fastfacts/display.asp?id=76',
                    },
                ],
            },
        },
        {
            //question 5//
            question: "In 1995-1996, the average tuition (including fees, room and board) for a 4-year public institution was $11,264. In 2007-2008 it was $15,739. How much did tuition increase over this period?",
            choices: ['$47', '$475', '$4,475', '$44,750'],
            answerIdx: 2,    
            furtherInfo: {
                explanation: (
                    `<b>$15,739 - $11,264 = $4,475</b>.<br />Between 1996 and 2008, the average annual tuition at a 4-year public institution increased by <b>$4,475</b>.`
                ),
                sources: [
                    {
                        source: 'National Center for Education Statistics',
                        url: 'https://nces.ed.gov/fastfacts/display.asp?id=76',
                    },
                ],
            },
        },
        {
            //question 6//
            question: "If in 1995-1996 tuition at a public institution was $11,264, and in 2007-2008 it was $15,739, how much of a percentage increase did public tuition undergo?",
            choices: ['10%', '40%', '70%', '90%'],
            answerIdx: 1,    
            furtherInfo: {
                explanation: (
                    `<b>(15,739/11,264) -1 = 0.397, or 39.7%</b><br />Therefore, we know that college tuition for public institutions increased by <b>40%</b> between 1996 and 2008.`
                ),
                sources: [
                    {
                        source: 'National Center for Education Statistics',
                        url: 'https://nces.ed.gov/fastfacts/display.asp?id=76',
                    },
                ],
            },
        },
        {
            //question 7//
            question: "In 1995-1996 private tuition (including fees, room and board) was $28,284. In 2007-2008 it was $35,426. How much did tuition increase during this decade?",
            choices: ['$7,142,000', '$714,200', '$71,420', '$7,142'],
            answerIdx: 3,    
            furtherInfo: {
                explanation: (
                    `<b>$35,426 - $28,284 = $7,142.</b><br />Between 1996 and 2008, the average cost of tuition for a private institution increased by <b>$7,142</b>.`
                ),
                sources: [
                    {
                        source: 'National Center for Education Statistics',
                        url: 'https://nces.ed.gov/fastfacts/display.asp?id=76',
                    },
                ],
            },
        },
        {
            //question 8//
            question: "In 1995-1996 private tuition was $28,284, and in 2007-2008 it was $35,426. How much of a percentage increase did private tuition undergo?",
            choices: ['25%', '55%', '75%', '95%'],
            answerIdx: 0,    
            furtherInfo: {
                explanation: (
                    `<b>($35,426 / $28,284) - 1 = 0.252, or 25.2%</b><br />Therefore, we know that between 1996 and 2008 tuition at a private institution increased by about <b>25%</b>.`
                ),
                sources: [
                    {
                        source: 'National Center for Education Statistics',
                        url: 'https://nces.ed.gov/fastfacts/display.asp?id=76',
                    },
                ],
            },
        },
        {
            //question 9//
            question: "In the decade from 2007-2008 to 2017-2018, tuition at a 4-year <i>public</i> institution increased by 27%. How much would tuition be in ten more years (2027-2028) if it was $20,050 in 2017-2018, and it continued the same rate of increase?",
            choices: ['$254', '$2,546', '$25,464', '$254,640'],
            answerIdx: 2,    
            furtherInfo: {
                explanation: (
                    `A 27% increase from $20,050 can be found via the following formula:<br />Increase = $20,050 + ($20,050 * 0.27), or $25,464`
                ),
                sources: [
                    {
                        source: 'National Center for Education Statistics',
                        url: 'https://nces.ed.gov/fastfacts/display.asp?id=76',
                    },
                ],
            },
        },
        {
            //question 10//
            question: "In the decade from 2007-2008 to 2017-2018, tuition at a 4-year <i>private</i> institution increased by 22%. How much would it cost in ten more years (in 2027-2028) if it was $43,139 in 2017-2018, and it continued the same rate of increase?",
            choices: ['$52', '$526', '$5,263', '$52,630'],
            answerIdx: 3,    
            furtherInfo: {
                explanation: (
                    `A 22% increase can be found via the following formula:<br />Increase = ($43,139 * 0.22) + 43,139, or 52,629.58.<br />Round this up to <b>$52,630</b>`
                ),
                sources: [
                    {
                        source: 'National Center for Education Statistics',
                        url: 'https://nces.ed.gov/fastfacts/display.asp?id=76',
                    },
                ],
            },
        },
    ],

};

export default collegeTuition;