import { createMuiTheme, responsiveFontSizes } from "@material-ui/core/styles";

import GothamBlack from "../fonts/Gotham-Black.otf";
import OpenSans from "../fonts/OpenSans-SemiBoldItalic.ttf";

function genFontFace(font) {
  return {
    fontFamily: font.family,
    fontStyle: "normal",
    fontDisplay: "swap",
    fontWeight: 400,
    src: `
      local('${font.family}'),
      local('${font.family}-Regular'),
      url(${font.path}) format('${font.format}')
    `,
    unicodeRange:
      "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
  };
}

const gothamBlack = {
  family: "Gotham",
  path: GothamBlack,
  format: "opentype",
};

const openSans = {
  family: "Open Sans",
  path: OpenSans,
  format: "truetype",
};

export function GenTheme(font) {
  const palette = {
    primary: {
      light: "#1934ff",
      main: "#001eff", // color shades from https://www.color-hex.com/color/001eff
      dark: "#0015b2",
      contrastText: "#fff",
    },
    secondary: {
      light: "#fff",
      main: "#fff", // color shades from https://www.color-hex.com/color/ffffff
      dark: "#e5e5e5",
      contrastText: "#001eff",
    },
  };

  if (!font) {
    return responsiveFontSizes(
      createMuiTheme({
        palette,
        overrides: {
          MuiCssBaseline: {
            "@global": {
              "@font-face": [genFontFace(gothamBlack), genFontFace(openSans)],
            },
          },
        },
      })
    );
  }

  return responsiveFontSizes(
    createMuiTheme({
      palette,
      typography: {
        fontFamily: font.family,
      },
      overrides: {
        MuiCssBaseline: {
          "@global": {
            "@font-face": [
              genFontFace(font),
              genFontFace(gothamBlack),
              genFontFace(openSans),
            ],
          },
        },
      },
    })
  );
}
