const wellRoundedDivision1 = {
    id: 'wellRoundedDivision1',
	name: 'Well Rounded Division 1: Numbers with commas',
    linkTo: "/quizzes/wellRoundedDivision1",
	description: "How fast can you divide big numbers? This one's got lots of commas and zer000,000s!",
    halfOrderENumpad: true,
    timeLimit: 90,
    randomize: true,
    challenge: true,
	questions: 
    [
        {
            "question": "What is 100 divided by 10?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is 100 divided by 10?<br /><br />Think of it as: 1E2 / 1E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E1, or 10."
            }
        },
        {
            "question": "What is 30,000,000,000 divided by 300,000?",
            "writeInAnswer": 100000,
            "furtherInfo": {
                "explanation": "What is 30,000,000,000 divided by 300,000?<br /><br />Think of it as: 3E10 / 3E5.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E5, or 100,000."
            }
        },
        {
            "question": "What is 30,000 divided by 1?",
            "writeInAnswer": 30000,
            "furtherInfo": {
                "explanation": "What is 30,000 divided by 1?<br /><br />Think of it as: 3E4 / 1E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E4, or 30,000."
            }
        },
        {
            "question": "What is 100,000,000,000 divided by 1,000,000?",
            "writeInAnswer": 100000,
            "furtherInfo": {
                "explanation": "What is 100,000,000,000 divided by 1,000,000?<br /><br />Think of it as: 1E11 / 1E6.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E5, or 100,000."
            }
        },
        {
            "question": "What is 3,000,000,000,000 divided by 1,000,000?",
            "writeInAnswer": 3000000,
            "furtherInfo": {
                "explanation": "What is 3,000,000,000,000 divided by 1,000,000?<br /><br />Think of it as: 3E12 / 1E6.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E6, or 3,000,000."
            }
        },
        {
            "question": "What is 10,000,000,000 divided by 1,000?",
            "writeInAnswer": 10000000,
            "furtherInfo": {
                "explanation": "What is 10,000,000,000 divided by 1,000?<br /><br />Think of it as: 1E10 / 1E3.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E7, or 10,000,000."
            }
        },
        {
            "question": "What is 30,000 divided by 10?",
            "writeInAnswer": 3000,
            "furtherInfo": {
                "explanation": "What is 30,000 divided by 10?<br /><br />Think of it as: 3E4 / 1E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E3, or 3,000."
            }
        },
        {
            "question": "What is 3,000,000 divided by 300,000?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is 3,000,000 divided by 300,000?<br /><br />Think of it as: 3E6 / 3E5.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E1, or 10."
            }
        },
        {
            "question": "What is 300,000,000 divided by 100,000?",
            "writeInAnswer": 3000,
            "furtherInfo": {
                "explanation": "What is 300,000,000 divided by 100,000?<br /><br />Think of it as: 3E8 / 1E5.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E3, or 3,000."
            }
        },
        {
            "question": "What is 30 divided by 1?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is 30 divided by 1?<br /><br />Think of it as: 3E1 / 1E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E1, or 30."
            }
        },
        {
            "question": "What is 3,000,000,000 divided by 100,000,000?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is 3,000,000,000 divided by 100,000,000?<br /><br />Think of it as: 3E9 / 1E8.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E1, or 30."
            }
        },
        {
            "question": "What is 3,000,000,000,000 divided by 30?",
            "writeInAnswer": 100000000000,
            "furtherInfo": {
                "explanation": "What is 3,000,000,000,000 divided by 30?<br /><br />Think of it as: 3E12 / 3E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E11, or 100,000,000,000."
            }
        },
        {
            "question": "What is 1,000,000,000,000 divided by 100,000,000,000?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is 1,000,000,000,000 divided by 100,000,000,000?<br /><br />Think of it as: 1E12 / 1E11.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E1, or 10."
            }
        },
        {
            "question": "What is 3,000,000,000,000 divided by 300,000,000,000?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is 3,000,000,000,000 divided by 300,000,000,000?<br /><br />Think of it as: 3E12 / 3E11.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E1, or 10."
            }
        },
        {
            "question": "What is 300,000,000,000 divided by 3,000?",
            "writeInAnswer": 100000000,
            "furtherInfo": {
                "explanation": "What is 300,000,000,000 divided by 3,000?<br /><br />Think of it as: 3E11 / 3E3.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E8, or 100,000,000."
            }
        },
        {
            "question": "What is 30 divided by 3?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is 30 divided by 3?<br /><br />Think of it as: 3E1 / 3E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E1, or 10."
            }
        },
        {
            "question": "What is 30,000,000 divided by 30?",
            "writeInAnswer": 1000000,
            "furtherInfo": {
                "explanation": "What is 30,000,000 divided by 30?<br /><br />Think of it as: 3E7 / 3E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E6, or 1,000,000."
            }
        },
        {
            "question": "What is 100 divided by 3?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is 100 divided by 3?<br /><br />Think of it as: 1E2 / 3E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E1, or 30."
            }
        },
        {
            "question": "What is 3,000,000,000 divided by 30,000,000?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is 3,000,000,000 divided by 30,000,000?<br /><br />Think of it as: 3E9 / 3E7.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E2, or 100."
            }
        },
        {
            "question": "What is 300,000,000 divided by 10,000,000?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is 300,000,000 divided by 10,000,000?<br /><br />Think of it as: 3E8 / 1E7.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E1, or 30."
            }
        },
        {
            "question": "What is 300 divided by 10?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is 300 divided by 10?<br /><br />Think of it as: 3E2 / 1E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E1, or 30."
            }
        },
        {
            "question": "What is 3,000 divided by 300?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is 3,000 divided by 300?<br /><br />Think of it as: 3E3 / 3E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E1, or 10."
            }
        },
        {
            "question": "What is 10,000,000 divided by 3?",
            "writeInAnswer": 3000000,
            "furtherInfo": {
                "explanation": "What is 10,000,000 divided by 3?<br /><br />Think of it as: 1E7 / 3E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E6, or 3,000,000."
            }
        },
        {
            "question": "What is 10,000 divided by 100?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is 10,000 divided by 100?<br /><br />Think of it as: 1E4 / 1E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E2, or 100."
            }
        },
        {
            "question": "What is 300,000 divided by 3,000?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is 300,000 divided by 3,000?<br /><br />Think of it as: 3E5 / 3E3.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E2, or 100."
            }
        },
        {
            "question": "What is 1,000,000 divided by 100?",
            "writeInAnswer": 10000,
            "furtherInfo": {
                "explanation": "What is 1,000,000 divided by 100?<br /><br />Think of it as: 1E6 / 1E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E4, or 10,000."
            }
        },
        {
            "question": "What is 100,000,000,000 divided by 10,000?",
            "writeInAnswer": 10000000,
            "furtherInfo": {
                "explanation": "What is 100,000,000,000 divided by 10,000?<br /><br />Think of it as: 1E11 / 1E4.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E7, or 10,000,000."
            }
        },
        {
            "question": "What is 30,000,000,000,000 divided by 30?",
            "writeInAnswer": 1000000000000,
            "furtherInfo": {
                "explanation": "What is 30,000,000,000,000 divided by 30?<br /><br />Think of it as: 3E13 / 3E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E12, or 1,000,000,000,000."
            }
        },
        {
            "question": "What is 10,000,000,000 divided by 3,000,000?",
            "writeInAnswer": 3000,
            "furtherInfo": {
                "explanation": "What is 10,000,000,000 divided by 3,000,000?<br /><br />Think of it as: 1E10 / 3E6.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E3, or 3,000."
            }
        },
        {
            "question": "What is 1,000,000,000 divided by 30,000?",
            "writeInAnswer": 30000,
            "furtherInfo": {
                "explanation": "What is 1,000,000,000 divided by 30,000?<br /><br />Think of it as: 1E9 / 3E4.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E4, or 30,000."
            }
        },
        {
            "question": "What is 300,000,000,000 divided by 30,000,000?",
            "writeInAnswer": 10000,
            "furtherInfo": {
                "explanation": "What is 300,000,000,000 divided by 30,000,000?<br /><br />Think of it as: 3E11 / 3E7.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E4, or 10,000."
            }
        },
        {
            "question": "What is 10,000,000,000 divided by 1,000?",
            "writeInAnswer": 10000000,
            "furtherInfo": {
                "explanation": "What is 10,000,000,000 divided by 1,000?<br /><br />Think of it as: 1E10 / 1E3.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E7, or 10,000,000."
            }
        },
        {
            "question": "What is 10,000,000,000 divided by 300,000?",
            "writeInAnswer": 30000,
            "furtherInfo": {
                "explanation": "What is 10,000,000,000 divided by 300,000?<br /><br />Think of it as: 1E10 / 3E5.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E4, or 30,000."
            }
        },
        {
            "question": "What is 300,000,000 divided by 30,000?",
            "writeInAnswer": 10000,
            "furtherInfo": {
                "explanation": "What is 300,000,000 divided by 30,000?<br /><br />Think of it as: 3E8 / 3E4.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E4, or 10,000."
            }
        },
        {
            "question": "What is 300,000,000 divided by 1,000?",
            "writeInAnswer": 300000,
            "furtherInfo": {
                "explanation": "What is 300,000,000 divided by 1,000?<br /><br />Think of it as: 3E8 / 1E3.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E5, or 300,000."
            }
        },
        {
            "question": "What is 3,000,000,000 divided by 10,000?",
            "writeInAnswer": 300000,
            "furtherInfo": {
                "explanation": "What is 3,000,000,000 divided by 10,000?<br /><br />Think of it as: 3E9 / 1E4.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E5, or 300,000."
            }
        },
        {
            "question": "What is 10 divided by 3?",
            "writeInAnswer": 3,
            "furtherInfo": {
                "explanation": "What is 10 divided by 3?<br /><br />Think of it as: 1E1 / 3E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E0, or 3."
            }
        },
        {
            "question": "What is 30,000,000 divided by 300?",
            "writeInAnswer": 100000,
            "furtherInfo": {
                "explanation": "What is 30,000,000 divided by 300?<br /><br />Think of it as: 3E7 / 3E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E5, or 100,000."
            }
        },
        {
            "question": "What is 100,000,000,000 divided by 3,000,000?",
            "writeInAnswer": 30000,
            "furtherInfo": {
                "explanation": "What is 100,000,000,000 divided by 3,000,000?<br /><br />Think of it as: 1E11 / 3E6.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E4, or 30,000."
            }
        },
        {
            "question": "What is 30,000 divided by 100?",
            "writeInAnswer": 300,
            "furtherInfo": {
                "explanation": "What is 30,000 divided by 100?<br /><br />Think of it as: 3E4 / 1E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E2, or 300."
            }
        },
        {
            "question": "What is 30,000,000,000,000 divided by 300,000?",
            "writeInAnswer": 100000000,
            "furtherInfo": {
                "explanation": "What is 30,000,000,000,000 divided by 300,000?<br /><br />Think of it as: 3E13 / 3E5.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E8, or 100,000,000."
            }
        },
        {
            "question": "What is 1,000,000,000 divided by 100,000?",
            "writeInAnswer": 10000,
            "furtherInfo": {
                "explanation": "What is 1,000,000,000 divided by 100,000?<br /><br />Think of it as: 1E9 / 1E5.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E4, or 10,000."
            }
        },
        {
            "question": "What is 300 divided by 3?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is 300 divided by 3?<br /><br />Think of it as: 3E2 / 3E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E2, or 100."
            }
        },
        {
            "question": "What is 300 divided by 3?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is 300 divided by 3?<br /><br />Think of it as: 3E2 / 3E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E2, or 100."
            }
        },
        {
            "question": "What is 1,000,000 divided by 1?",
            "writeInAnswer": 1000000,
            "furtherInfo": {
                "explanation": "What is 1,000,000 divided by 1?<br /><br />Think of it as: 1E6 / 1E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E6, or 1,000,000."
            }
        },
        {
            "question": "What is 10,000 divided by 300?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is 10,000 divided by 300?<br /><br />Think of it as: 1E4 / 3E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E1, or 30."
            }
        },
        {
            "question": "What is 30,000 divided by 3,000?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is 30,000 divided by 3,000?<br /><br />Think of it as: 3E4 / 3E3.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E1, or 10."
            }
        },
        {
            "question": "What is 10,000 divided by 30?",
            "writeInAnswer": 300,
            "furtherInfo": {
                "explanation": "What is 10,000 divided by 30?<br /><br />Think of it as: 1E4 / 3E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E2, or 300."
            }
        },
        {
            "question": "What is 1,000,000 divided by 100,000?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is 1,000,000 divided by 100,000?<br /><br />Think of it as: 1E6 / 1E5.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E1, or 10."
            }
        },
        {
            "question": "What is 10 divided by 1?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is 10 divided by 1?<br /><br />Think of it as: 1E1 / 1E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E1, or 10."
            }
        },
        {
            "question": "What is 30 divided by 1?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is 30 divided by 1?<br /><br />Think of it as: 3E1 / 1E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E1, or 30."
            }
        },
        {
            "question": "What is 3,000,000,000 divided by 3?",
            "writeInAnswer": 1000000000,
            "furtherInfo": {
                "explanation": "What is 3,000,000,000 divided by 3?<br /><br />Think of it as: 3E9 / 3E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E9, or 1,000,000,000."
            }
        },
        {
            "question": "What is 10,000 divided by 10?",
            "writeInAnswer": 1000,
            "furtherInfo": {
                "explanation": "What is 10,000 divided by 10?<br /><br />Think of it as: 1E4 / 1E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E3, or 1,000."
            }
        },
        {
            "question": "What is 30,000 divided by 100?",
            "writeInAnswer": 300,
            "furtherInfo": {
                "explanation": "What is 30,000 divided by 100?<br /><br />Think of it as: 3E4 / 1E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E2, or 300."
            }
        },
        {
            "question": "What is 300 divided by 3?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is 300 divided by 3?<br /><br />Think of it as: 3E2 / 3E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E2, or 100."
            }
        },
        {
            "question": "What is 30 divided by 3?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is 30 divided by 3?<br /><br />Think of it as: 3E1 / 3E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E1, or 10."
            }
        },
        {
            "question": "What is 10,000,000,000,000 divided by 300?",
            "writeInAnswer": 30000000000,
            "furtherInfo": {
                "explanation": "What is 10,000,000,000,000 divided by 300?<br /><br />Think of it as: 1E13 / 3E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E10, or 30,000,000,000."
            }
        },
        {
            "question": "What is 100,000,000 divided by 30,000,000?",
            "writeInAnswer": 3,
            "furtherInfo": {
                "explanation": "What is 100,000,000 divided by 30,000,000?<br /><br />Think of it as: 1E8 / 3E7.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E0, or 3."
            }
        },
        {
            "question": "What is 10 divided by 3?",
            "writeInAnswer": 3,
            "furtherInfo": {
                "explanation": "What is 10 divided by 3?<br /><br />Think of it as: 1E1 / 3E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E0, or 3."
            }
        },
        {
            "question": "What is 30,000 divided by 100?",
            "writeInAnswer": 300,
            "furtherInfo": {
                "explanation": "What is 30,000 divided by 100?<br /><br />Think of it as: 3E4 / 1E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E2, or 300."
            }
        },
        {
            "question": "What is 30,000 divided by 10?",
            "writeInAnswer": 3000,
            "furtherInfo": {
                "explanation": "What is 30,000 divided by 10?<br /><br />Think of it as: 3E4 / 1E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E3, or 3,000."
            }
        },
        {
            "question": "What is 30,000,000,000,000 divided by 30,000,000,000?",
            "writeInAnswer": 1000,
            "furtherInfo": {
                "explanation": "What is 30,000,000,000,000 divided by 30,000,000,000?<br /><br />Think of it as: 3E13 / 3E10.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E3, or 1,000."
            }
        },
        {
            "question": "What is 3,000 divided by 100?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is 3,000 divided by 100?<br /><br />Think of it as: 3E3 / 1E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E1, or 30."
            }
        },
        {
            "question": "What is 300 divided by 3?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is 300 divided by 3?<br /><br />Think of it as: 3E2 / 3E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E2, or 100."
            }
        },
        {
            "question": "What is 10,000,000,000 divided by 300?",
            "writeInAnswer": 30000000,
            "furtherInfo": {
                "explanation": "What is 10,000,000,000 divided by 300?<br /><br />Think of it as: 1E10 / 3E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E7, or 30,000,000."
            }
        },
        {
            "question": "What is 300 divided by 1?",
            "writeInAnswer": 300,
            "furtherInfo": {
                "explanation": "What is 300 divided by 1?<br /><br />Think of it as: 3E2 / 1E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E2, or 300."
            }
        },
        {
            "question": "What is 1,000,000,000 divided by 1,000?",
            "writeInAnswer": 1000000,
            "furtherInfo": {
                "explanation": "What is 1,000,000,000 divided by 1,000?<br /><br />Think of it as: 1E9 / 1E3.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E6, or 1,000,000."
            }
        },
        {
            "question": "What is 300 divided by 1?",
            "writeInAnswer": 300,
            "furtherInfo": {
                "explanation": "What is 300 divided by 1?<br /><br />Think of it as: 3E2 / 1E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E2, or 300."
            }
        },
        {
            "question": "What is 300,000,000,000 divided by 30?",
            "writeInAnswer": 10000000000,
            "furtherInfo": {
                "explanation": "What is 300,000,000,000 divided by 30?<br /><br />Think of it as: 3E11 / 3E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E10, or 10,000,000,000."
            }
        },
        {
            "question": "What is 30 divided by 3?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is 30 divided by 3?<br /><br />Think of it as: 3E1 / 3E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E1, or 10."
            }
        },
        {
            "question": "What is 3,000,000,000 divided by 300?",
            "writeInAnswer": 10000000,
            "furtherInfo": {
                "explanation": "What is 3,000,000,000 divided by 300?<br /><br />Think of it as: 3E9 / 3E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E7, or 10,000,000."
            }
        },
        {
            "question": "What is 3,000,000,000 divided by 10,000,000?",
            "writeInAnswer": 300,
            "furtherInfo": {
                "explanation": "What is 3,000,000,000 divided by 10,000,000?<br /><br />Think of it as: 3E9 / 1E7.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E2, or 300."
            }
        },
        {
            "question": "What is 30,000 divided by 10?",
            "writeInAnswer": 3000,
            "furtherInfo": {
                "explanation": "What is 30,000 divided by 10?<br /><br />Think of it as: 3E4 / 1E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E3, or 3,000."
            }
        },
        {
            "question": "What is 3,000,000,000 divided by 100,000?",
            "writeInAnswer": 30000,
            "furtherInfo": {
                "explanation": "What is 3,000,000,000 divided by 100,000?<br /><br />Think of it as: 3E9 / 1E5.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E4, or 30,000."
            }
        },
        {
            "question": "What is 100 divided by 1?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is 100 divided by 1?<br /><br />Think of it as: 1E2 / 1E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E2, or 100."
            }
        },
        {
            "question": "What is 100,000 divided by 100?",
            "writeInAnswer": 1000,
            "furtherInfo": {
                "explanation": "What is 100,000 divided by 100?<br /><br />Think of it as: 1E5 / 1E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E3, or 1,000."
            }
        },
        {
            "question": "What is 30,000,000,000 divided by 1,000,000,000?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is 30,000,000,000 divided by 1,000,000,000?<br /><br />Think of it as: 3E10 / 1E9.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E1, or 30."
            }
        },
        {
            "question": "What is 100 divided by 3?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is 100 divided by 3?<br /><br />Think of it as: 1E2 / 3E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E1, or 30."
            }
        },
        {
            "question": "What is 10,000,000,000 divided by 300?",
            "writeInAnswer": 30000000,
            "furtherInfo": {
                "explanation": "What is 10,000,000,000 divided by 300?<br /><br />Think of it as: 1E10 / 3E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E7, or 30,000,000."
            }
        },
        {
            "question": "What is 1,000,000,000 divided by 30?",
            "writeInAnswer": 30000000,
            "furtherInfo": {
                "explanation": "What is 1,000,000,000 divided by 30?<br /><br />Think of it as: 1E9 / 3E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E7, or 30,000,000."
            }
        },
        {
            "question": "What is 30,000 divided by 1,000?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is 30,000 divided by 1,000?<br /><br />Think of it as: 3E4 / 1E3.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E1, or 30."
            }
        },
        {
            "question": "What is 100,000,000,000 divided by 30?",
            "writeInAnswer": 3000000000,
            "furtherInfo": {
                "explanation": "What is 100,000,000,000 divided by 30?<br /><br />Think of it as: 1E11 / 3E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E9, or 3,000,000,000."
            }
        },
        {
            "question": "What is 1,000,000,000,000 divided by 100,000,000?",
            "writeInAnswer": 10000,
            "furtherInfo": {
                "explanation": "What is 1,000,000,000,000 divided by 100,000,000?<br /><br />Think of it as: 1E12 / 1E8.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E4, or 10,000."
            }
        },
        {
            "question": "What is 30,000 divided by 3?",
            "writeInAnswer": 10000,
            "furtherInfo": {
                "explanation": "What is 30,000 divided by 3?<br /><br />Think of it as: 3E4 / 3E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E4, or 10,000."
            }
        },
        {
            "question": "What is 100,000 divided by 3?",
            "writeInAnswer": 30000,
            "furtherInfo": {
                "explanation": "What is 100,000 divided by 3?<br /><br />Think of it as: 1E5 / 3E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E4, or 30,000."
            }
        },
        {
            "question": "What is 10,000,000,000,000 divided by 1,000?",
            "writeInAnswer": 10000000000,
            "furtherInfo": {
                "explanation": "What is 10,000,000,000,000 divided by 1,000?<br /><br />Think of it as: 1E13 / 1E3.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E10, or 10,000,000,000."
            }
        },
        {
            "question": "What is 10,000,000,000 divided by 3,000,000,000?",
            "writeInAnswer": 3,
            "furtherInfo": {
                "explanation": "What is 10,000,000,000 divided by 3,000,000,000?<br /><br />Think of it as: 1E10 / 3E9.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E0, or 3."
            }
        },
        {
            "question": "What is 100,000,000 divided by 300?",
            "writeInAnswer": 300000,
            "furtherInfo": {
                "explanation": "What is 100,000,000 divided by 300?<br /><br />Think of it as: 1E8 / 3E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E5, or 300,000."
            }
        },
        {
            "question": "What is 100,000,000 divided by 1?",
            "writeInAnswer": 100000000,
            "furtherInfo": {
                "explanation": "What is 100,000,000 divided by 1?<br /><br />Think of it as: 1E8 / 1E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E8, or 100,000,000."
            }
        },
        {
            "question": "What is 30,000 divided by 300?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is 30,000 divided by 300?<br /><br />Think of it as: 3E4 / 3E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E2, or 100."
            }
        },
        {
            "question": "What is 30 divided by 1?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is 30 divided by 1?<br /><br />Think of it as: 3E1 / 1E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E1, or 30."
            }
        },
        {
            "question": "What is 30,000,000,000,000 divided by 3?",
            "writeInAnswer": 10000000000000,
            "furtherInfo": {
                "explanation": "What is 30,000,000,000,000 divided by 3?<br /><br />Think of it as: 3E13 / 3E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E13, or 10,000,000,000,000."
            }
        },
        {
            "question": "What is 100,000,000 divided by 30,000,000?",
            "writeInAnswer": 3,
            "furtherInfo": {
                "explanation": "What is 100,000,000 divided by 30,000,000?<br /><br />Think of it as: 1E8 / 3E7.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E0, or 3."
            }
        },
        {
            "question": "What is 1,000,000,000,000 divided by 1,000,000?",
            "writeInAnswer": 1000000,
            "furtherInfo": {
                "explanation": "What is 1,000,000,000,000 divided by 1,000,000?<br /><br />Think of it as: 1E12 / 1E6.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E6, or 1,000,000."
            }
        },
        {
            "question": "What is 300,000,000,000 divided by 1?",
            "writeInAnswer": 300000000000,
            "furtherInfo": {
                "explanation": "What is 300,000,000,000 divided by 1?<br /><br />Think of it as: 3E11 / 1E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E11, or 300,000,000,000."
            }
        },
        {
            "question": "What is 10,000,000 divided by 10,000?",
            "writeInAnswer": 1000,
            "furtherInfo": {
                "explanation": "What is 10,000,000 divided by 10,000?<br /><br />Think of it as: 1E7 / 1E4.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E3, or 1,000."
            }
        },
        {
            "question": "What is 30,000,000,000,000 divided by 30?",
            "writeInAnswer": 1000000000000,
            "furtherInfo": {
                "explanation": "What is 30,000,000,000,000 divided by 30?<br /><br />Think of it as: 3E13 / 3E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E12, or 1,000,000,000,000."
            }
        },
        {
            "question": "What is 100,000,000,000 divided by 3,000,000,000?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is 100,000,000,000 divided by 3,000,000,000?<br /><br />Think of it as: 1E11 / 3E9.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E1, or 30."
            }
        },
        {
            "question": "What is 30,000,000 divided by 10,000?",
            "writeInAnswer": 3000,
            "furtherInfo": {
                "explanation": "What is 30,000,000 divided by 10,000?<br /><br />Think of it as: 3E7 / 1E4.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E3, or 3,000."
            }
        },
        {
            "question": "What is 100 divided by 10?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is 100 divided by 10?<br /><br />Think of it as: 1E2 / 1E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E1, or 10."
            }
        },
        {
            "question": "What is 30,000,000,000 divided by 1?",
            "writeInAnswer": 30000000000,
            "furtherInfo": {
                "explanation": "What is 30,000,000,000 divided by 1?<br /><br />Think of it as: 3E10 / 1E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E10, or 30,000,000,000."
            }
        },
        {
            "question": "What is 100 divided by 1?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is 100 divided by 1?<br /><br />Think of it as: 1E2 / 1E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E2, or 100."
            }
        },
        {
            "question": "What is 30,000,000 divided by 100,000?",
            "writeInAnswer": 300,
            "furtherInfo": {
                "explanation": "What is 30,000,000 divided by 100,000?<br /><br />Think of it as: 3E7 / 1E5.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E2, or 300."
            }
        },
        {
            "question": "What is 300 divided by 1?",
            "writeInAnswer": 300,
            "furtherInfo": {
                "explanation": "What is 300 divided by 1?<br /><br />Think of it as: 3E2 / 1E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E2, or 300."
            }
        },
        {
            "question": "What is 100,000,000 divided by 100,000?",
            "writeInAnswer": 1000,
            "furtherInfo": {
                "explanation": "What is 100,000,000 divided by 100,000?<br /><br />Think of it as: 1E8 / 1E5.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E3, or 1,000."
            }
        },
        {
            "question": "What is 30,000,000 divided by 100?",
            "writeInAnswer": 300000,
            "furtherInfo": {
                "explanation": "What is 30,000,000 divided by 100?<br /><br />Think of it as: 3E7 / 1E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E5, or 300,000."
            }
        },
        {
            "question": "What is 30 divided by 1?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is 30 divided by 1?<br /><br />Think of it as: 3E1 / 1E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E1, or 30."
            }
        },
        {
            "question": "What is 30,000,000,000,000 divided by 100,000,000?",
            "writeInAnswer": 300000,
            "furtherInfo": {
                "explanation": "What is 30,000,000,000,000 divided by 100,000,000?<br /><br />Think of it as: 3E13 / 1E8.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E5, or 300,000."
            }
        },
        {
            "question": "What is 30,000,000 divided by 300?",
            "writeInAnswer": 100000,
            "furtherInfo": {
                "explanation": "What is 30,000,000 divided by 300?<br /><br />Think of it as: 3E7 / 3E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E5, or 100,000."
            }
        },
        {
            "question": "What is 100,000,000,000 divided by 100,000?",
            "writeInAnswer": 1000000,
            "furtherInfo": {
                "explanation": "What is 100,000,000,000 divided by 100,000?<br /><br />Think of it as: 1E11 / 1E5.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E6, or 1,000,000."
            }
        },
        {
            "question": "What is 300,000,000 divided by 30,000?",
            "writeInAnswer": 10000,
            "furtherInfo": {
                "explanation": "What is 300,000,000 divided by 30,000?<br /><br />Think of it as: 3E8 / 3E4.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E4, or 10,000."
            }
        },
        {
            "question": "What is 10,000 divided by 100?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is 10,000 divided by 100?<br /><br />Think of it as: 1E4 / 1E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E2, or 100."
            }
        },
        {
            "question": "What is 3,000,000,000,000 divided by 300,000,000?",
            "writeInAnswer": 10000,
            "furtherInfo": {
                "explanation": "What is 3,000,000,000,000 divided by 300,000,000?<br /><br />Think of it as: 3E12 / 3E8.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E4, or 10,000."
            }
        },
        {
            "question": "What is 30,000,000,000,000 divided by 100?",
            "writeInAnswer": 300000000000,
            "furtherInfo": {
                "explanation": "What is 30,000,000,000,000 divided by 100?<br /><br />Think of it as: 3E13 / 1E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E11, or 300,000,000,000."
            }
        },
        {
            "question": "What is 10,000,000 divided by 10,000?",
            "writeInAnswer": 1000,
            "furtherInfo": {
                "explanation": "What is 10,000,000 divided by 10,000?<br /><br />Think of it as: 1E7 / 1E4.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E3, or 1,000."
            }
        },
        {
            "question": "What is 100,000,000,000 divided by 10,000,000?",
            "writeInAnswer": 10000,
            "furtherInfo": {
                "explanation": "What is 100,000,000,000 divided by 10,000,000?<br /><br />Think of it as: 1E11 / 1E7.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E4, or 10,000."
            }
        },
        {
            "question": "What is 30,000,000,000 divided by 30?",
            "writeInAnswer": 1000000000,
            "furtherInfo": {
                "explanation": "What is 30,000,000,000 divided by 30?<br /><br />Think of it as: 3E10 / 3E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E9, or 1,000,000,000."
            }
        },
        {
            "question": "What is 1,000,000,000,000 divided by 1,000,000,000?",
            "writeInAnswer": 1000,
            "furtherInfo": {
                "explanation": "What is 1,000,000,000,000 divided by 1,000,000,000?<br /><br />Think of it as: 1E12 / 1E9.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E3, or 1,000."
            }
        },
        {
            "question": "What is 100,000,000,000 divided by 30?",
            "writeInAnswer": 3000000000,
            "furtherInfo": {
                "explanation": "What is 100,000,000,000 divided by 30?<br /><br />Think of it as: 1E11 / 3E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E9, or 3,000,000,000."
            }
        },
        {
            "question": "What is 10,000,000,000,000 divided by 3,000,000?",
            "writeInAnswer": 3000000,
            "furtherInfo": {
                "explanation": "What is 10,000,000,000,000 divided by 3,000,000?<br /><br />Think of it as: 1E13 / 3E6.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E6, or 3,000,000."
            }
        },
        {
            "question": "What is 300,000,000 divided by 300,000?",
            "writeInAnswer": 1000,
            "furtherInfo": {
                "explanation": "What is 300,000,000 divided by 300,000?<br /><br />Think of it as: 3E8 / 3E5.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E3, or 1,000."
            }
        },
        {
            "question": "What is 10,000,000,000,000 divided by 30,000,000?",
            "writeInAnswer": 300000,
            "furtherInfo": {
                "explanation": "What is 10,000,000,000,000 divided by 30,000,000?<br /><br />Think of it as: 1E13 / 3E7.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E5, or 300,000."
            }
        },
        {
            "question": "What is 100,000,000,000 divided by 1,000,000,000?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is 100,000,000,000 divided by 1,000,000,000?<br /><br />Think of it as: 1E11 / 1E9.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E2, or 100."
            }
        },
        {
            "question": "What is 10,000,000,000,000 divided by 100,000,000?",
            "writeInAnswer": 100000,
            "furtherInfo": {
                "explanation": "What is 10,000,000,000,000 divided by 100,000,000?<br /><br />Think of it as: 1E13 / 1E8.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E5, or 100,000."
            }
        },
        {
            "question": "What is 1,000,000 divided by 1,000?",
            "writeInAnswer": 1000,
            "furtherInfo": {
                "explanation": "What is 1,000,000 divided by 1,000?<br /><br />Think of it as: 1E6 / 1E3.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E3, or 1,000."
            }
        },
        {
            "question": "What is 1,000,000,000 divided by 100,000,000?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is 1,000,000,000 divided by 100,000,000?<br /><br />Think of it as: 1E9 / 1E8.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E1, or 10."
            }
        },
        {
            "question": "What is 10,000,000,000 divided by 3,000,000?",
            "writeInAnswer": 3000,
            "furtherInfo": {
                "explanation": "What is 10,000,000,000 divided by 3,000,000?<br /><br />Think of it as: 1E10 / 3E6.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E3, or 3,000."
            }
        },
        {
            "question": "What is 300,000,000 divided by 100,000?",
            "writeInAnswer": 3000,
            "furtherInfo": {
                "explanation": "What is 300,000,000 divided by 100,000?<br /><br />Think of it as: 3E8 / 1E5.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E3, or 3,000."
            }
        },
        {
            "question": "What is 300,000,000 divided by 10?",
            "writeInAnswer": 30000000,
            "furtherInfo": {
                "explanation": "What is 300,000,000 divided by 10?<br /><br />Think of it as: 3E8 / 1E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E7, or 30,000,000."
            }
        },
        {
            "question": "What is 100 divided by 30?",
            "writeInAnswer": 3,
            "furtherInfo": {
                "explanation": "What is 100 divided by 30?<br /><br />Think of it as: 1E2 / 3E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E0, or 3."
            }
        },
        {
            "question": "What is 3,000,000,000,000 divided by 1,000?",
            "writeInAnswer": 3000000000,
            "furtherInfo": {
                "explanation": "What is 3,000,000,000,000 divided by 1,000?<br /><br />Think of it as: 3E12 / 1E3.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E9, or 3,000,000,000."
            }
        },
        {
            "question": "What is 300,000 divided by 30,000?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is 300,000 divided by 30,000?<br /><br />Think of it as: 3E5 / 3E4.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E1, or 10."
            }
        },
        {
            "question": "What is 300,000,000,000 divided by 10,000,000?",
            "writeInAnswer": 30000,
            "furtherInfo": {
                "explanation": "What is 300,000,000,000 divided by 10,000,000?<br /><br />Think of it as: 3E11 / 1E7.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E4, or 30,000."
            }
        },
        {
            "question": "What is 3,000,000,000 divided by 1,000,000?",
            "writeInAnswer": 3000,
            "furtherInfo": {
                "explanation": "What is 3,000,000,000 divided by 1,000,000?<br /><br />Think of it as: 3E9 / 1E6.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E3, or 3,000."
            }
        },
        {
            "question": "What is 10,000,000,000 divided by 10?",
            "writeInAnswer": 1000000000,
            "furtherInfo": {
                "explanation": "What is 10,000,000,000 divided by 10?<br /><br />Think of it as: 1E10 / 1E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E9, or 1,000,000,000."
            }
        },
        {
            "question": "What is 100,000,000,000 divided by 3,000?",
            "writeInAnswer": 30000000,
            "furtherInfo": {
                "explanation": "What is 100,000,000,000 divided by 3,000?<br /><br />Think of it as: 1E11 / 3E3.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E7, or 30,000,000."
            }
        },
        {
            "question": "What is 300,000 divided by 100?",
            "writeInAnswer": 3000,
            "furtherInfo": {
                "explanation": "What is 300,000 divided by 100?<br /><br />Think of it as: 3E5 / 1E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E3, or 3,000."
            }
        },
        {
            "question": "What is 300,000 divided by 30?",
            "writeInAnswer": 10000,
            "furtherInfo": {
                "explanation": "What is 300,000 divided by 30?<br /><br />Think of it as: 3E5 / 3E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E4, or 10,000."
            }
        },
        {
            "question": "What is 300,000 divided by 3?",
            "writeInAnswer": 100000,
            "furtherInfo": {
                "explanation": "What is 300,000 divided by 3?<br /><br />Think of it as: 3E5 / 3E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E5, or 100,000."
            }
        },
        {
            "question": "What is 10,000,000,000,000 divided by 1,000,000?",
            "writeInAnswer": 10000000,
            "furtherInfo": {
                "explanation": "What is 10,000,000,000,000 divided by 1,000,000?<br /><br />Think of it as: 1E13 / 1E6.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E7, or 10,000,000."
            }
        },
        {
            "question": "What is 300,000,000,000 divided by 300?",
            "writeInAnswer": 1000000000,
            "furtherInfo": {
                "explanation": "What is 300,000,000,000 divided by 300?<br /><br />Think of it as: 3E11 / 3E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E9, or 1,000,000,000."
            }
        },
        {
            "question": "What is 10,000,000,000,000 divided by 1,000,000,000,000?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is 10,000,000,000,000 divided by 1,000,000,000,000?<br /><br />Think of it as: 1E13 / 1E12.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E1, or 10."
            }
        },
        {
            "question": "What is 10,000,000,000 divided by 1,000,000?",
            "writeInAnswer": 10000,
            "furtherInfo": {
                "explanation": "What is 10,000,000,000 divided by 1,000,000?<br /><br />Think of it as: 1E10 / 1E6.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E4, or 10,000."
            }
        },
        {
            "question": "What is 10 divided by 3?",
            "writeInAnswer": 3,
            "furtherInfo": {
                "explanation": "What is 10 divided by 3?<br /><br />Think of it as: 1E1 / 3E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E0, or 3."
            }
        },
        {
            "question": "What is 3,000,000,000 divided by 100,000?",
            "writeInAnswer": 30000,
            "furtherInfo": {
                "explanation": "What is 3,000,000,000 divided by 100,000?<br /><br />Think of it as: 3E9 / 1E5.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E4, or 30,000."
            }
        },
        {
            "question": "What is 1,000,000,000 divided by 300,000?",
            "writeInAnswer": 3000,
            "furtherInfo": {
                "explanation": "What is 1,000,000,000 divided by 300,000?<br /><br />Think of it as: 1E9 / 3E5.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E3, or 3,000."
            }
        },
        {
            "question": "What is 100,000,000,000 divided by 3,000,000?",
            "writeInAnswer": 30000,
            "furtherInfo": {
                "explanation": "What is 100,000,000,000 divided by 3,000,000?<br /><br />Think of it as: 1E11 / 3E6.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E4, or 30,000."
            }
        },
        {
            "question": "What is 10 divided by 3?",
            "writeInAnswer": 3,
            "furtherInfo": {
                "explanation": "What is 10 divided by 3?<br /><br />Think of it as: 1E1 / 3E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E0, or 3."
            }
        },
        {
            "question": "What is 3,000 divided by 300?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is 3,000 divided by 300?<br /><br />Think of it as: 3E3 / 3E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E1, or 10."
            }
        },
        {
            "question": "What is 300 divided by 30?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is 300 divided by 30?<br /><br />Think of it as: 3E2 / 3E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E1, or 10."
            }
        },
        {
            "question": "What is 3,000,000 divided by 3?",
            "writeInAnswer": 1000000,
            "furtherInfo": {
                "explanation": "What is 3,000,000 divided by 3?<br /><br />Think of it as: 3E6 / 3E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E6, or 1,000,000."
            }
        },
        {
            "question": "What is 300 divided by 1?",
            "writeInAnswer": 300,
            "furtherInfo": {
                "explanation": "What is 300 divided by 1?<br /><br />Think of it as: 3E2 / 1E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E2, or 300."
            }
        },
        {
            "question": "What is 300,000,000 divided by 3,000?",
            "writeInAnswer": 100000,
            "furtherInfo": {
                "explanation": "What is 300,000,000 divided by 3,000?<br /><br />Think of it as: 3E8 / 3E3.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E5, or 100,000."
            }
        },
        {
            "question": "What is 3,000,000,000 divided by 10,000,000?",
            "writeInAnswer": 300,
            "furtherInfo": {
                "explanation": "What is 3,000,000,000 divided by 10,000,000?<br /><br />Think of it as: 3E9 / 1E7.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E2, or 300."
            }
        },
        {
            "question": "What is 1,000 divided by 10?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is 1,000 divided by 10?<br /><br />Think of it as: 1E3 / 1E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E2, or 100."
            }
        },
        {
            "question": "What is 3,000,000 divided by 3,000?",
            "writeInAnswer": 1000,
            "furtherInfo": {
                "explanation": "What is 3,000,000 divided by 3,000?<br /><br />Think of it as: 3E6 / 3E3.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E3, or 1,000."
            }
        },
        {
            "question": "What is 1,000,000 divided by 10?",
            "writeInAnswer": 100000,
            "furtherInfo": {
                "explanation": "What is 1,000,000 divided by 10?<br /><br />Think of it as: 1E6 / 1E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E5, or 100,000."
            }
        },
        {
            "question": "What is 30,000,000,000 divided by 1,000,000,000?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is 30,000,000,000 divided by 1,000,000,000?<br /><br />Think of it as: 3E10 / 1E9.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E1, or 30."
            }
        },
        {
            "question": "What is 10,000 divided by 1?",
            "writeInAnswer": 10000,
            "furtherInfo": {
                "explanation": "What is 10,000 divided by 1?<br /><br />Think of it as: 1E4 / 1E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E4, or 10,000."
            }
        },
        {
            "question": "What is 100,000,000 divided by 10,000,000?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is 100,000,000 divided by 10,000,000?<br /><br />Think of it as: 1E8 / 1E7.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E1, or 10."
            }
        },
        {
            "question": "What is 3,000,000,000,000 divided by 3?",
            "writeInAnswer": 1000000000000,
            "furtherInfo": {
                "explanation": "What is 3,000,000,000,000 divided by 3?<br /><br />Think of it as: 3E12 / 3E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E12, or 1,000,000,000,000."
            }
        },
        {
            "question": "What is 3,000,000,000 divided by 1,000,000?",
            "writeInAnswer": 3000,
            "furtherInfo": {
                "explanation": "What is 3,000,000,000 divided by 1,000,000?<br /><br />Think of it as: 3E9 / 1E6.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E3, or 3,000."
            }
        },
        {
            "question": "What is 3,000 divided by 10?",
            "writeInAnswer": 300,
            "furtherInfo": {
                "explanation": "What is 3,000 divided by 10?<br /><br />Think of it as: 3E3 / 1E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E2, or 300."
            }
        },
        {
            "question": "What is 3,000,000,000 divided by 3,000,000?",
            "writeInAnswer": 1000,
            "furtherInfo": {
                "explanation": "What is 3,000,000,000 divided by 3,000,000?<br /><br />Think of it as: 3E9 / 3E6.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E3, or 1,000."
            }
        },
        {
            "question": "What is 300 divided by 30?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is 300 divided by 30?<br /><br />Think of it as: 3E2 / 3E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E1, or 10."
            }
        },
        {
            "question": "What is 10,000,000,000 divided by 300?",
            "writeInAnswer": 30000000,
            "furtherInfo": {
                "explanation": "What is 10,000,000,000 divided by 300?<br /><br />Think of it as: 1E10 / 3E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E7, or 30,000,000."
            }
        },
        {
            "question": "What is 3,000,000,000,000 divided by 100,000,000?",
            "writeInAnswer": 30000,
            "furtherInfo": {
                "explanation": "What is 3,000,000,000,000 divided by 100,000,000?<br /><br />Think of it as: 3E12 / 1E8.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E4, or 30,000."
            }
        },
        {
            "question": "What is 10 divided by 1?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is 10 divided by 1?<br /><br />Think of it as: 1E1 / 1E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E1, or 10."
            }
        },
        {
            "question": "What is 3,000,000,000,000 divided by 300,000,000?",
            "writeInAnswer": 10000,
            "furtherInfo": {
                "explanation": "What is 3,000,000,000,000 divided by 300,000,000?<br /><br />Think of it as: 3E12 / 3E8.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E4, or 10,000."
            }
        },
        {
            "question": "What is 100,000 divided by 100?",
            "writeInAnswer": 1000,
            "furtherInfo": {
                "explanation": "What is 100,000 divided by 100?<br /><br />Think of it as: 1E5 / 1E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E3, or 1,000."
            }
        },
        {
            "question": "What is 10 divided by 3?",
            "writeInAnswer": 3,
            "furtherInfo": {
                "explanation": "What is 10 divided by 3?<br /><br />Think of it as: 1E1 / 3E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E0, or 3."
            }
        },
        {
            "question": "What is 1,000,000,000 divided by 100,000?",
            "writeInAnswer": 10000,
            "furtherInfo": {
                "explanation": "What is 1,000,000,000 divided by 100,000?<br /><br />Think of it as: 1E9 / 1E5.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E4, or 10,000."
            }
        },
        {
            "question": "What is 100,000,000 divided by 30,000,000?",
            "writeInAnswer": 3,
            "furtherInfo": {
                "explanation": "What is 100,000,000 divided by 30,000,000?<br /><br />Think of it as: 1E8 / 3E7.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E0, or 3."
            }
        },
        {
            "question": "What is 10,000,000,000,000 divided by 100,000,000?",
            "writeInAnswer": 100000,
            "furtherInfo": {
                "explanation": "What is 10,000,000,000,000 divided by 100,000,000?<br /><br />Think of it as: 1E13 / 1E8.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E5, or 100,000."
            }
        },
        {
            "question": "What is 30,000,000,000 divided by 3?",
            "writeInAnswer": 10000000000,
            "furtherInfo": {
                "explanation": "What is 30,000,000,000 divided by 3?<br /><br />Think of it as: 3E10 / 3E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E10, or 10,000,000,000."
            }
        },
        {
            "question": "What is 30,000,000,000 divided by 3,000,000,000?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is 30,000,000,000 divided by 3,000,000,000?<br /><br />Think of it as: 3E10 / 3E9.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E1, or 10."
            }
        },
        {
            "question": "What is 3,000,000 divided by 300?",
            "writeInAnswer": 10000,
            "furtherInfo": {
                "explanation": "What is 3,000,000 divided by 300?<br /><br />Think of it as: 3E6 / 3E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E4, or 10,000."
            }
        },
        {
            "question": "What is 1,000,000,000 divided by 10,000?",
            "writeInAnswer": 100000,
            "furtherInfo": {
                "explanation": "What is 1,000,000,000 divided by 10,000?<br /><br />Think of it as: 1E9 / 1E4.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E5, or 100,000."
            }
        },
        {
            "question": "What is 100,000 divided by 10?",
            "writeInAnswer": 10000,
            "furtherInfo": {
                "explanation": "What is 100,000 divided by 10?<br /><br />Think of it as: 1E5 / 1E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E4, or 10,000."
            }
        },
        {
            "question": "What is 10,000 divided by 100?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is 10,000 divided by 100?<br /><br />Think of it as: 1E4 / 1E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E2, or 100."
            }
        },
        {
            "question": "What is 100,000,000,000 divided by 100,000?",
            "writeInAnswer": 1000000,
            "furtherInfo": {
                "explanation": "What is 100,000,000,000 divided by 100,000?<br /><br />Think of it as: 1E11 / 1E5.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E6, or 1,000,000."
            }
        },
        {
            "question": "What is 10 divided by 1?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is 10 divided by 1?<br /><br />Think of it as: 1E1 / 1E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E1, or 10."
            }
        },
        {
            "question": "What is 30,000,000,000 divided by 30,000?",
            "writeInAnswer": 1000000,
            "furtherInfo": {
                "explanation": "What is 30,000,000,000 divided by 30,000?<br /><br />Think of it as: 3E10 / 3E4.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E6, or 1,000,000."
            }
        },
        {
            "question": "What is 1,000 divided by 100?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is 1,000 divided by 100?<br /><br />Think of it as: 1E3 / 1E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E1, or 10."
            }
        },
        {
            "question": "What is 3,000,000 divided by 1,000?",
            "writeInAnswer": 3000,
            "furtherInfo": {
                "explanation": "What is 3,000,000 divided by 1,000?<br /><br />Think of it as: 3E6 / 1E3.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E3, or 3,000."
            }
        },
        {
            "question": "What is 300 divided by 3?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is 300 divided by 3?<br /><br />Think of it as: 3E2 / 3E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E2, or 100."
            }
        },
        {
            "question": "What is 300,000,000,000 divided by 100,000?",
            "writeInAnswer": 3000000,
            "furtherInfo": {
                "explanation": "What is 300,000,000,000 divided by 100,000?<br /><br />Think of it as: 3E11 / 1E5.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E6, or 3,000,000."
            }
        },
        {
            "question": "What is 30,000,000,000 divided by 3,000,000,000?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is 30,000,000,000 divided by 3,000,000,000?<br /><br />Think of it as: 3E10 / 3E9.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E1, or 10."
            }
        },
        {
            "question": "What is 10 divided by 3?",
            "writeInAnswer": 3,
            "furtherInfo": {
                "explanation": "What is 10 divided by 3?<br /><br />Think of it as: 1E1 / 3E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E0, or 3."
            }
        },
        {
            "question": "What is 30,000,000,000 divided by 3,000?",
            "writeInAnswer": 10000000,
            "furtherInfo": {
                "explanation": "What is 30,000,000,000 divided by 3,000?<br /><br />Think of it as: 3E10 / 3E3.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E7, or 10,000,000."
            }
        },
        {
            "question": "What is 3,000,000 divided by 1?",
            "writeInAnswer": 3000000,
            "furtherInfo": {
                "explanation": "What is 3,000,000 divided by 1?<br /><br />Think of it as: 3E6 / 1E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E6, or 3,000,000."
            }
        },
        {
            "question": "What is 10,000,000,000,000 divided by 3,000?",
            "writeInAnswer": 3000000000,
            "furtherInfo": {
                "explanation": "What is 10,000,000,000,000 divided by 3,000?<br /><br />Think of it as: 1E13 / 3E3.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E9, or 3,000,000,000."
            }
        },
        {
            "question": "What is 1,000 divided by 100?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is 1,000 divided by 100?<br /><br />Think of it as: 1E3 / 1E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E1, or 10."
            }
        },
        {
            "question": "What is 10,000,000 divided by 10?",
            "writeInAnswer": 1000000,
            "furtherInfo": {
                "explanation": "What is 10,000,000 divided by 10?<br /><br />Think of it as: 1E7 / 1E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E6, or 1,000,000."
            }
        },
        {
            "question": "What is 30 divided by 3?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is 30 divided by 3?<br /><br />Think of it as: 3E1 / 3E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E1, or 10."
            }
        },
        {
            "question": "What is 100,000,000 divided by 10,000,000?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is 100,000,000 divided by 10,000,000?<br /><br />Think of it as: 1E8 / 1E7.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E1, or 10."
            }
        },
        {
            "question": "What is 1,000,000,000,000 divided by 100,000,000,000?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is 1,000,000,000,000 divided by 100,000,000,000?<br /><br />Think of it as: 1E12 / 1E11.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E1, or 10."
            }
        },
        {
            "question": "What is 3,000,000 divided by 10,000?",
            "writeInAnswer": 300,
            "furtherInfo": {
                "explanation": "What is 3,000,000 divided by 10,000?<br /><br />Think of it as: 3E6 / 1E4.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E2, or 300."
            }
        },
        {
            "question": "What is 1,000,000 divided by 300,000?",
            "writeInAnswer": 3,
            "furtherInfo": {
                "explanation": "What is 1,000,000 divided by 300,000?<br /><br />Think of it as: 1E6 / 3E5.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E0, or 3."
            }
        },
        {
            "question": "What is 300,000 divided by 300?",
            "writeInAnswer": 1000,
            "furtherInfo": {
                "explanation": "What is 300,000 divided by 300?<br /><br />Think of it as: 3E5 / 3E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E3, or 1,000."
            }
        },
        {
            "question": "What is 30,000 divided by 3?",
            "writeInAnswer": 10000,
            "furtherInfo": {
                "explanation": "What is 30,000 divided by 3?<br /><br />Think of it as: 3E4 / 3E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E4, or 10,000."
            }
        },
        {
            "question": "What is 100,000 divided by 1?",
            "writeInAnswer": 100000,
            "furtherInfo": {
                "explanation": "What is 100,000 divided by 1?<br /><br />Think of it as: 1E5 / 1E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E5, or 100,000."
            }
        },
        {
            "question": "What is 300,000,000,000 divided by 30?",
            "writeInAnswer": 10000000000,
            "furtherInfo": {
                "explanation": "What is 300,000,000,000 divided by 30?<br /><br />Think of it as: 3E11 / 3E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E10, or 10,000,000,000."
            }
        },
        {
            "question": "What is 300,000,000,000 divided by 3,000?",
            "writeInAnswer": 100000000,
            "furtherInfo": {
                "explanation": "What is 300,000,000,000 divided by 3,000?<br /><br />Think of it as: 3E11 / 3E3.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E8, or 100,000,000."
            }
        },
        {
            "question": "What is 1,000,000 divided by 1,000?",
            "writeInAnswer": 1000,
            "furtherInfo": {
                "explanation": "What is 1,000,000 divided by 1,000?<br /><br />Think of it as: 1E6 / 1E3.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E3, or 1,000."
            }
        },
        {
            "question": "What is 300,000,000,000 divided by 1,000,000?",
            "writeInAnswer": 300000,
            "furtherInfo": {
                "explanation": "What is 300,000,000,000 divided by 1,000,000?<br /><br />Think of it as: 3E11 / 1E6.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E5, or 300,000."
            }
        },
        {
            "question": "What is 10,000,000 divided by 300,000?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is 10,000,000 divided by 300,000?<br /><br />Think of it as: 1E7 / 3E5.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E1, or 30."
            }
        },
        {
            "question": "What is 10,000,000 divided by 30?",
            "writeInAnswer": 300000,
            "furtherInfo": {
                "explanation": "What is 10,000,000 divided by 30?<br /><br />Think of it as: 1E7 / 3E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E5, or 300,000."
            }
        },
        {
            "question": "What is 300,000 divided by 1?",
            "writeInAnswer": 300000,
            "furtherInfo": {
                "explanation": "What is 300,000 divided by 1?<br /><br />Think of it as: 3E5 / 1E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E5, or 300,000."
            }
        },
        {
            "question": "What is 3,000,000,000,000 divided by 300,000?",
            "writeInAnswer": 10000000,
            "furtherInfo": {
                "explanation": "What is 3,000,000,000,000 divided by 300,000?<br /><br />Think of it as: 3E12 / 3E5.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E7, or 10,000,000."
            }
        },
        {
            "question": "What is 1,000 divided by 3?",
            "writeInAnswer": 300,
            "furtherInfo": {
                "explanation": "What is 1,000 divided by 3?<br /><br />Think of it as: 1E3 / 3E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E2, or 300."
            }
        },
        {
            "question": "What is 30,000 divided by 30?",
            "writeInAnswer": 1000,
            "furtherInfo": {
                "explanation": "What is 30,000 divided by 30?<br /><br />Think of it as: 3E4 / 3E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E3, or 1,000."
            }
        },
        {
            "question": "What is 100,000 divided by 10?",
            "writeInAnswer": 10000,
            "furtherInfo": {
                "explanation": "What is 100,000 divided by 10?<br /><br />Think of it as: 1E5 / 1E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E4, or 10,000."
            }
        },
        {
            "question": "What is 300,000 divided by 1,000?",
            "writeInAnswer": 300,
            "furtherInfo": {
                "explanation": "What is 300,000 divided by 1,000?<br /><br />Think of it as: 3E5 / 1E3.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E2, or 300."
            }
        },
        {
            "question": "What is 30,000,000,000,000 divided by 3,000?",
            "writeInAnswer": 10000000000,
            "furtherInfo": {
                "explanation": "What is 30,000,000,000,000 divided by 3,000?<br /><br />Think of it as: 3E13 / 3E3.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E10, or 10,000,000,000."
            }
        },
        {
            "question": "What is 30 divided by 3?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is 30 divided by 3?<br /><br />Think of it as: 3E1 / 3E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E1, or 10."
            }
        },
        {
            "question": "What is 3,000,000 divided by 100?",
            "writeInAnswer": 30000,
            "furtherInfo": {
                "explanation": "What is 3,000,000 divided by 100?<br /><br />Think of it as: 3E6 / 1E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E4, or 30,000."
            }
        },
        {
            "question": "What is 100,000,000,000 divided by 10,000,000?",
            "writeInAnswer": 10000,
            "furtherInfo": {
                "explanation": "What is 100,000,000,000 divided by 10,000,000?<br /><br />Think of it as: 1E11 / 1E7.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E4, or 10,000."
            }
        },
        {
            "question": "What is 100,000 divided by 1,000?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is 100,000 divided by 1,000?<br /><br />Think of it as: 1E5 / 1E3.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E2, or 100."
            }
        },
        {
            "question": "What is 1,000,000 divided by 30,000?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is 1,000,000 divided by 30,000?<br /><br />Think of it as: 1E6 / 3E4.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E1, or 30."
            }
        },
        {
            "question": "What is 1,000 divided by 100?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is 1,000 divided by 100?<br /><br />Think of it as: 1E3 / 1E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E1, or 10."
            }
        },
        {
            "question": "What is 3,000,000,000,000 divided by 3,000?",
            "writeInAnswer": 1000000000,
            "furtherInfo": {
                "explanation": "What is 3,000,000,000,000 divided by 3,000?<br /><br />Think of it as: 3E12 / 3E3.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E9, or 1,000,000,000."
            }
        },
        {
            "question": "What is 10,000,000,000,000 divided by 300,000,000?",
            "writeInAnswer": 30000,
            "furtherInfo": {
                "explanation": "What is 10,000,000,000,000 divided by 300,000,000?<br /><br />Think of it as: 1E13 / 3E8.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E4, or 30,000."
            }
        },
        {
            "question": "What is 10,000 divided by 1,000?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is 10,000 divided by 1,000?<br /><br />Think of it as: 1E4 / 1E3.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E1, or 10."
            }
        },
        {
            "question": "What is 30,000,000 divided by 1,000,000?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is 30,000,000 divided by 1,000,000?<br /><br />Think of it as: 3E7 / 1E6.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E1, or 30."
            }
        },
        {
            "question": "What is 3,000,000,000,000 divided by 300?",
            "writeInAnswer": 10000000000,
            "furtherInfo": {
                "explanation": "What is 3,000,000,000,000 divided by 300?<br /><br />Think of it as: 3E12 / 3E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E10, or 10,000,000,000."
            }
        },
        {
            "question": "What is 100,000,000 divided by 100?",
            "writeInAnswer": 1000000,
            "furtherInfo": {
                "explanation": "What is 100,000,000 divided by 100?<br /><br />Think of it as: 1E8 / 1E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E6, or 1,000,000."
            }
        },
        {
            "question": "What is 100,000,000,000 divided by 100,000,000?",
            "writeInAnswer": 1000,
            "furtherInfo": {
                "explanation": "What is 100,000,000,000 divided by 100,000,000?<br /><br />Think of it as: 1E11 / 1E8.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E3, or 1,000."
            }
        },
        {
            "question": "What is 10 divided by 3?",
            "writeInAnswer": 3,
            "furtherInfo": {
                "explanation": "What is 10 divided by 3?<br /><br />Think of it as: 1E1 / 3E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E0, or 3."
            }
        },
        {
            "question": "What is 3,000,000,000 divided by 30,000,000?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is 3,000,000,000 divided by 30,000,000?<br /><br />Think of it as: 3E9 / 3E7.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E2, or 100."
            }
        },
        {
            "question": "What is 3,000 divided by 100?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is 3,000 divided by 100?<br /><br />Think of it as: 3E3 / 1E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E1, or 30."
            }
        },
        {
            "question": "What is 10,000,000,000,000 divided by 1,000,000?",
            "writeInAnswer": 10000000,
            "furtherInfo": {
                "explanation": "What is 10,000,000,000,000 divided by 1,000,000?<br /><br />Think of it as: 1E13 / 1E6.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E7, or 10,000,000."
            }
        },
        {
            "question": "What is 1,000,000,000,000 divided by 30,000,000,000?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is 1,000,000,000,000 divided by 30,000,000,000?<br /><br />Think of it as: 1E12 / 3E10.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E1, or 30."
            }
        },
        {
            "question": "What is 30 divided by 1?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is 30 divided by 1?<br /><br />Think of it as: 3E1 / 1E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E1, or 30."
            }
        },
        {
            "question": "What is 300,000,000 divided by 30,000?",
            "writeInAnswer": 10000,
            "furtherInfo": {
                "explanation": "What is 300,000,000 divided by 30,000?<br /><br />Think of it as: 3E8 / 3E4.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E4, or 10,000."
            }
        },
        {
            "question": "What is 1,000,000,000 divided by 30,000?",
            "writeInAnswer": 30000,
            "furtherInfo": {
                "explanation": "What is 1,000,000,000 divided by 30,000?<br /><br />Think of it as: 1E9 / 3E4.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E4, or 30,000."
            }
        },
        {
            "question": "What is 10,000 divided by 10?",
            "writeInAnswer": 1000,
            "furtherInfo": {
                "explanation": "What is 10,000 divided by 10?<br /><br />Think of it as: 1E4 / 1E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E3, or 1,000."
            }
        },
        {
            "question": "What is 300,000,000 divided by 3?",
            "writeInAnswer": 100000000,
            "furtherInfo": {
                "explanation": "What is 300,000,000 divided by 3?<br /><br />Think of it as: 3E8 / 3E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E8, or 100,000,000."
            }
        },
        {
            "question": "What is 10,000 divided by 300?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is 10,000 divided by 300?<br /><br />Think of it as: 1E4 / 3E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E1, or 30."
            }
        },
        {
            "question": "What is 1,000,000,000,000 divided by 300,000,000,000?",
            "writeInAnswer": 3,
            "furtherInfo": {
                "explanation": "What is 1,000,000,000,000 divided by 300,000,000,000?<br /><br />Think of it as: 1E12 / 3E11.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E0, or 3."
            }
        },
        {
            "question": "What is 30,000,000,000,000 divided by 3?",
            "writeInAnswer": 10000000000000,
            "furtherInfo": {
                "explanation": "What is 30,000,000,000,000 divided by 3?<br /><br />Think of it as: 3E13 / 3E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E13, or 10,000,000,000,000."
            }
        },
        {
            "question": "What is 10,000,000,000 divided by 3,000,000,000?",
            "writeInAnswer": 3,
            "furtherInfo": {
                "explanation": "What is 10,000,000,000 divided by 3,000,000,000?<br /><br />Think of it as: 1E10 / 3E9.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E0, or 3."
            }
        },
        {
            "question": "What is 100,000,000 divided by 100?",
            "writeInAnswer": 1000000,
            "furtherInfo": {
                "explanation": "What is 100,000,000 divided by 100?<br /><br />Think of it as: 1E8 / 1E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E6, or 1,000,000."
            }
        },
        {
            "question": "What is 10 divided by 1?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is 10 divided by 1?<br /><br />Think of it as: 1E1 / 1E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E1, or 10."
            }
        },
        {
            "question": "What is 1,000 divided by 10?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is 1,000 divided by 10?<br /><br />Think of it as: 1E3 / 1E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E2, or 100."
            }
        },
        {
            "question": "What is 3,000,000,000,000 divided by 300,000,000,000?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is 3,000,000,000,000 divided by 300,000,000,000?<br /><br />Think of it as: 3E12 / 3E11.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E1, or 10."
            }
        },
        {
            "question": "What is 100,000,000,000 divided by 10?",
            "writeInAnswer": 10000000000,
            "furtherInfo": {
                "explanation": "What is 100,000,000,000 divided by 10?<br /><br />Think of it as: 1E11 / 1E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E10, or 10,000,000,000."
            }
        },
        {
            "question": "What is 100,000,000,000 divided by 1,000,000,000?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is 100,000,000,000 divided by 1,000,000,000?<br /><br />Think of it as: 1E11 / 1E9.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E2, or 100."
            }
        },
        {
            "question": "What is 10,000,000,000,000 divided by 300,000,000?",
            "writeInAnswer": 30000,
            "furtherInfo": {
                "explanation": "What is 10,000,000,000,000 divided by 300,000,000?<br /><br />Think of it as: 1E13 / 3E8.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E4, or 30,000."
            }
        },
        {
            "question": "What is 10 divided by 1?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is 10 divided by 1?<br /><br />Think of it as: 1E1 / 1E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E1, or 10."
            }
        },
        {
            "question": "What is 30,000,000 divided by 1?",
            "writeInAnswer": 30000000,
            "furtherInfo": {
                "explanation": "What is 30,000,000 divided by 1?<br /><br />Think of it as: 3E7 / 1E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E7, or 30,000,000."
            }
        },
        {
            "question": "What is 10,000 divided by 30?",
            "writeInAnswer": 300,
            "furtherInfo": {
                "explanation": "What is 10,000 divided by 30?<br /><br />Think of it as: 1E4 / 3E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E2, or 300."
            }
        },
        {
            "question": "What is 3,000,000 divided by 1?",
            "writeInAnswer": 3000000,
            "furtherInfo": {
                "explanation": "What is 3,000,000 divided by 1?<br /><br />Think of it as: 3E6 / 1E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E6, or 3,000,000."
            }
        },
        {
            "question": "What is 300,000,000,000 divided by 3,000,000,000?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is 300,000,000,000 divided by 3,000,000,000?<br /><br />Think of it as: 3E11 / 3E9.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E2, or 100."
            }
        },
        {
            "question": "What is 30,000,000 divided by 10?",
            "writeInAnswer": 3000000,
            "furtherInfo": {
                "explanation": "What is 30,000,000 divided by 10?<br /><br />Think of it as: 3E7 / 1E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E6, or 3,000,000."
            }
        },
        {
            "question": "What is 1,000,000 divided by 300,000?",
            "writeInAnswer": 3,
            "furtherInfo": {
                "explanation": "What is 1,000,000 divided by 300,000?<br /><br />Think of it as: 1E6 / 3E5.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E0, or 3."
            }
        },
        {
            "question": "What is 100 divided by 30?",
            "writeInAnswer": 3,
            "furtherInfo": {
                "explanation": "What is 100 divided by 30?<br /><br />Think of it as: 1E2 / 3E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E0, or 3."
            }
        },
        {
            "question": "What is 300,000,000 divided by 1,000,000?",
            "writeInAnswer": 300,
            "furtherInfo": {
                "explanation": "What is 300,000,000 divided by 1,000,000?<br /><br />Think of it as: 3E8 / 1E6.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E2, or 300."
            }
        },
        {
            "question": "What is 1,000 divided by 300?",
            "writeInAnswer": 3,
            "furtherInfo": {
                "explanation": "What is 1,000 divided by 300?<br /><br />Think of it as: 1E3 / 3E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E0, or 3."
            }
        },
        {
            "question": "What is 300 divided by 1?",
            "writeInAnswer": 300,
            "furtherInfo": {
                "explanation": "What is 300 divided by 1?<br /><br />Think of it as: 3E2 / 1E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E2, or 300."
            }
        },
        {
            "question": "What is 300,000,000,000 divided by 10,000,000,000?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is 300,000,000,000 divided by 10,000,000,000?<br /><br />Think of it as: 3E11 / 1E10.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E1, or 30."
            }
        },
        {
            "question": "What is 3,000,000,000,000 divided by 3,000,000,000?",
            "writeInAnswer": 1000,
            "furtherInfo": {
                "explanation": "What is 3,000,000,000,000 divided by 3,000,000,000?<br /><br />Think of it as: 3E12 / 3E9.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E3, or 1,000."
            }
        },
        {
            "question": "What is 300,000,000 divided by 10,000?",
            "writeInAnswer": 30000,
            "furtherInfo": {
                "explanation": "What is 300,000,000 divided by 10,000?<br /><br />Think of it as: 3E8 / 1E4.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E4, or 30,000."
            }
        },
        {
            "question": "What is 30,000 divided by 30?",
            "writeInAnswer": 1000,
            "furtherInfo": {
                "explanation": "What is 30,000 divided by 30?<br /><br />Think of it as: 3E4 / 3E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E3, or 1,000."
            }
        },
        {
            "question": "What is 10,000 divided by 300?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is 10,000 divided by 300?<br /><br />Think of it as: 1E4 / 3E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E1, or 30."
            }
        },
        {
            "question": "What is 10,000,000,000,000 divided by 100?",
            "writeInAnswer": 100000000000,
            "furtherInfo": {
                "explanation": "What is 10,000,000,000,000 divided by 100?<br /><br />Think of it as: 1E13 / 1E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E11, or 100,000,000,000."
            }
        },
        {
            "question": "What is 30 divided by 1?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is 30 divided by 1?<br /><br />Think of it as: 3E1 / 1E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E1, or 30."
            }
        },
        {
            "question": "What is 300,000 divided by 100?",
            "writeInAnswer": 3000,
            "furtherInfo": {
                "explanation": "What is 300,000 divided by 100?<br /><br />Think of it as: 3E5 / 1E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E3, or 3,000."
            }
        },
        {
            "question": "What is 3,000,000 divided by 1,000?",
            "writeInAnswer": 3000,
            "furtherInfo": {
                "explanation": "What is 3,000,000 divided by 1,000?<br /><br />Think of it as: 3E6 / 1E3.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E3, or 3,000."
            }
        },
        {
            "question": "What is 300,000 divided by 30,000?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is 300,000 divided by 30,000?<br /><br />Think of it as: 3E5 / 3E4.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E1, or 10."
            }
        },
        {
            "question": "What is 30,000,000,000 divided by 1,000?",
            "writeInAnswer": 30000000,
            "furtherInfo": {
                "explanation": "What is 30,000,000,000 divided by 1,000?<br /><br />Think of it as: 3E10 / 1E3.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E7, or 30,000,000."
            }
        },
        {
            "question": "What is 300,000,000,000 divided by 1?",
            "writeInAnswer": 300000000000,
            "furtherInfo": {
                "explanation": "What is 300,000,000,000 divided by 1?<br /><br />Think of it as: 3E11 / 1E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E11, or 300,000,000,000."
            }
        },
        {
            "question": "What is 3,000,000 divided by 30,000?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is 3,000,000 divided by 30,000?<br /><br />Think of it as: 3E6 / 3E4.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E2, or 100."
            }
        },
        {
            "question": "What is 300,000,000,000 divided by 30?",
            "writeInAnswer": 10000000000,
            "furtherInfo": {
                "explanation": "What is 300,000,000,000 divided by 30?<br /><br />Think of it as: 3E11 / 3E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E10, or 10,000,000,000."
            }
        },
        {
            "question": "What is 10,000,000 divided by 30?",
            "writeInAnswer": 300000,
            "furtherInfo": {
                "explanation": "What is 10,000,000 divided by 30?<br /><br />Think of it as: 1E7 / 3E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E5, or 300,000."
            }
        },
        {
            "question": "What is 3,000 divided by 300?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is 3,000 divided by 300?<br /><br />Think of it as: 3E3 / 3E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E1, or 10."
            }
        },
        {
            "question": "What is 10,000,000 divided by 3,000?",
            "writeInAnswer": 3000,
            "furtherInfo": {
                "explanation": "What is 10,000,000 divided by 3,000?<br /><br />Think of it as: 1E7 / 3E3.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E3, or 3,000."
            }
        },
        {
            "question": "What is 100,000 divided by 100?",
            "writeInAnswer": 1000,
            "furtherInfo": {
                "explanation": "What is 100,000 divided by 100?<br /><br />Think of it as: 1E5 / 1E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E3, or 1,000."
            }
        },
        {
            "question": "What is 100,000,000,000 divided by 30,000,000,000?",
            "writeInAnswer": 3,
            "furtherInfo": {
                "explanation": "What is 100,000,000,000 divided by 30,000,000,000?<br /><br />Think of it as: 1E11 / 3E10.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E0, or 3."
            }
        },
        {
            "question": "What is 10 divided by 1?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is 10 divided by 1?<br /><br />Think of it as: 1E1 / 1E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E1, or 10."
            }
        },
        {
            "question": "What is 30,000 divided by 100?",
            "writeInAnswer": 300,
            "furtherInfo": {
                "explanation": "What is 30,000 divided by 100?<br /><br />Think of it as: 3E4 / 1E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E2, or 300."
            }
        },
        {
            "question": "What is 30,000 divided by 10?",
            "writeInAnswer": 3000,
            "furtherInfo": {
                "explanation": "What is 30,000 divided by 10?<br /><br />Think of it as: 3E4 / 1E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E3, or 3,000."
            }
        },
        {
            "question": "What is 300 divided by 30?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is 300 divided by 30?<br /><br />Think of it as: 3E2 / 3E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E1, or 10."
            }
        },
        {
            "question": "What is 3,000,000,000,000 divided by 3?",
            "writeInAnswer": 1000000000000,
            "furtherInfo": {
                "explanation": "What is 3,000,000,000,000 divided by 3?<br /><br />Think of it as: 3E12 / 3E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E12, or 1,000,000,000,000."
            }
        },
        {
            "question": "What is 30,000 divided by 3,000?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is 30,000 divided by 3,000?<br /><br />Think of it as: 3E4 / 3E3.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E1, or 10."
            }
        },
        {
            "question": "What is 30,000,000,000 divided by 10?",
            "writeInAnswer": 3000000000,
            "furtherInfo": {
                "explanation": "What is 30,000,000,000 divided by 10?<br /><br />Think of it as: 3E10 / 1E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E9, or 3,000,000,000."
            }
        },
        {
            "question": "What is 30,000 divided by 30?",
            "writeInAnswer": 1000,
            "furtherInfo": {
                "explanation": "What is 30,000 divided by 30?<br /><br />Think of it as: 3E4 / 3E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E3, or 1,000."
            }
        },
        {
            "question": "What is 1,000 divided by 3?",
            "writeInAnswer": 300,
            "furtherInfo": {
                "explanation": "What is 1,000 divided by 3?<br /><br />Think of it as: 1E3 / 3E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E2, or 300."
            }
        },
        {
            "question": "What is 1,000,000 divided by 3?",
            "writeInAnswer": 300000,
            "furtherInfo": {
                "explanation": "What is 1,000,000 divided by 3?<br /><br />Think of it as: 1E6 / 3E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E5, or 300,000."
            }
        },
        {
            "question": "What is 10,000,000 divided by 300?",
            "writeInAnswer": 30000,
            "furtherInfo": {
                "explanation": "What is 10,000,000 divided by 300?<br /><br />Think of it as: 1E7 / 3E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E4, or 30,000."
            }
        },
        {
            "question": "What is 100,000,000,000 divided by 30,000,000,000?",
            "writeInAnswer": 3,
            "furtherInfo": {
                "explanation": "What is 100,000,000,000 divided by 30,000,000,000?<br /><br />Think of it as: 1E11 / 3E10.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E0, or 3."
            }
        },
        {
            "question": "What is 3,000 divided by 10?",
            "writeInAnswer": 300,
            "furtherInfo": {
                "explanation": "What is 3,000 divided by 10?<br /><br />Think of it as: 3E3 / 1E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E2, or 300."
            }
        },
        {
            "question": "What is 300,000,000 divided by 100,000?",
            "writeInAnswer": 3000,
            "furtherInfo": {
                "explanation": "What is 300,000,000 divided by 100,000?<br /><br />Think of it as: 3E8 / 1E5.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E3, or 3,000."
            }
        },
        {
            "question": "What is 1,000,000,000,000 divided by 300,000?",
            "writeInAnswer": 3000000,
            "furtherInfo": {
                "explanation": "What is 1,000,000,000,000 divided by 300,000?<br /><br />Think of it as: 1E12 / 3E5.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E6, or 3,000,000."
            }
        },
        {
            "question": "What is 100,000,000 divided by 1?",
            "writeInAnswer": 100000000,
            "furtherInfo": {
                "explanation": "What is 100,000,000 divided by 1?<br /><br />Think of it as: 1E8 / 1E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E8, or 100,000,000."
            }
        },
        {
            "question": "What is 1,000,000 divided by 100,000?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is 1,000,000 divided by 100,000?<br /><br />Think of it as: 1E6 / 1E5.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E1, or 10."
            }
        },
        {
            "question": "What is 100 divided by 1?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is 100 divided by 1?<br /><br />Think of it as: 1E2 / 1E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E2, or 100."
            }
        },
        {
            "question": "What is 10,000,000,000,000 divided by 100?",
            "writeInAnswer": 100000000000,
            "furtherInfo": {
                "explanation": "What is 10,000,000,000,000 divided by 100?<br /><br />Think of it as: 1E13 / 1E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E11, or 100,000,000,000."
            }
        },
        {
            "question": "What is 300,000 divided by 1?",
            "writeInAnswer": 300000,
            "furtherInfo": {
                "explanation": "What is 300,000 divided by 1?<br /><br />Think of it as: 3E5 / 1E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E5, or 300,000."
            }
        },
        {
            "question": "What is 100 divided by 3?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is 100 divided by 3?<br /><br />Think of it as: 1E2 / 3E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E1, or 30."
            }
        },
        {
            "question": "What is 1,000,000 divided by 3,000?",
            "writeInAnswer": 300,
            "furtherInfo": {
                "explanation": "What is 1,000,000 divided by 3,000?<br /><br />Think of it as: 1E6 / 3E3.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E2, or 300."
            }
        },
        {
            "question": "What is 3,000,000 divided by 300?",
            "writeInAnswer": 10000,
            "furtherInfo": {
                "explanation": "What is 3,000,000 divided by 300?<br /><br />Think of it as: 3E6 / 3E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E4, or 10,000."
            }
        },
        {
            "question": "What is 3,000,000 divided by 100,000?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is 3,000,000 divided by 100,000?<br /><br />Think of it as: 3E6 / 1E5.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E1, or 30."
            }
        },
        {
            "question": "What is 300 divided by 3?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is 300 divided by 3?<br /><br />Think of it as: 3E2 / 3E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E2, or 100."
            }
        },
        {
            "question": "What is 1,000 divided by 3?",
            "writeInAnswer": 300,
            "furtherInfo": {
                "explanation": "What is 1,000 divided by 3?<br /><br />Think of it as: 1E3 / 3E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E2, or 300."
            }
        },
        {
            "question": "What is 1,000,000,000 divided by 100?",
            "writeInAnswer": 10000000,
            "furtherInfo": {
                "explanation": "What is 1,000,000,000 divided by 100?<br /><br />Think of it as: 1E9 / 1E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E7, or 10,000,000."
            }
        },
        {
            "question": "What is 30 divided by 1?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is 30 divided by 1?<br /><br />Think of it as: 3E1 / 1E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E1, or 30."
            }
        },
        {
            "question": "What is 300 divided by 30?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is 300 divided by 30?<br /><br />Think of it as: 3E2 / 3E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E1, or 10."
            }
        },
        {
            "question": "What is 30,000,000 divided by 30?",
            "writeInAnswer": 1000000,
            "furtherInfo": {
                "explanation": "What is 30,000,000 divided by 30?<br /><br />Think of it as: 3E7 / 3E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E6, or 1,000,000."
            }
        },
        {
            "question": "What is 3,000,000,000 divided by 30,000,000?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is 3,000,000,000 divided by 30,000,000?<br /><br />Think of it as: 3E9 / 3E7.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E2, or 100."
            }
        },
        {
            "question": "What is 3,000,000,000,000 divided by 10,000,000,000?",
            "writeInAnswer": 300,
            "furtherInfo": {
                "explanation": "What is 3,000,000,000,000 divided by 10,000,000,000?<br /><br />Think of it as: 3E12 / 1E10.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E2, or 300."
            }
        },
        {
            "question": "What is 300,000,000,000 divided by 1,000,000?",
            "writeInAnswer": 300000,
            "furtherInfo": {
                "explanation": "What is 300,000,000,000 divided by 1,000,000?<br /><br />Think of it as: 3E11 / 1E6.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E5, or 300,000."
            }
        },
        {
            "question": "What is 30,000 divided by 3,000?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is 30,000 divided by 3,000?<br /><br />Think of it as: 3E4 / 3E3.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E1, or 10."
            }
        },
        {
            "question": "What is 30 divided by 3?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is 30 divided by 3?<br /><br />Think of it as: 3E1 / 3E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E1, or 10."
            }
        },
        {
            "question": "What is 3,000,000,000,000 divided by 1,000,000?",
            "writeInAnswer": 3000000,
            "furtherInfo": {
                "explanation": "What is 3,000,000,000,000 divided by 1,000,000?<br /><br />Think of it as: 3E12 / 1E6.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E6, or 3,000,000."
            }
        },
        {
            "question": "What is 100,000,000,000 divided by 10?",
            "writeInAnswer": 10000000000,
            "furtherInfo": {
                "explanation": "What is 100,000,000,000 divided by 10?<br /><br />Think of it as: 1E11 / 1E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E10, or 10,000,000,000."
            }
        },
        {
            "question": "What is 10,000,000,000,000 divided by 3,000,000,000?",
            "writeInAnswer": 3000,
            "furtherInfo": {
                "explanation": "What is 10,000,000,000,000 divided by 3,000,000,000?<br /><br />Think of it as: 1E13 / 3E9.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E3, or 3,000."
            }
        },
        {
            "question": "What is 100,000,000,000 divided by 3,000,000?",
            "writeInAnswer": 30000,
            "furtherInfo": {
                "explanation": "What is 100,000,000,000 divided by 3,000,000?<br /><br />Think of it as: 1E11 / 3E6.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E4, or 30,000."
            }
        },
        {
            "question": "What is 1,000,000 divided by 100?",
            "writeInAnswer": 10000,
            "furtherInfo": {
                "explanation": "What is 1,000,000 divided by 100?<br /><br />Think of it as: 1E6 / 1E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E4, or 10,000."
            }
        },
        {
            "question": "What is 1,000,000 divided by 100?",
            "writeInAnswer": 10000,
            "furtherInfo": {
                "explanation": "What is 1,000,000 divided by 100?<br /><br />Think of it as: 1E6 / 1E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E4, or 10,000."
            }
        },
        {
            "question": "What is 300,000,000,000 divided by 10,000,000?",
            "writeInAnswer": 30000,
            "furtherInfo": {
                "explanation": "What is 300,000,000,000 divided by 10,000,000?<br /><br />Think of it as: 3E11 / 1E7.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E4, or 30,000."
            }
        },
        {
            "question": "What is 300 divided by 1?",
            "writeInAnswer": 300,
            "furtherInfo": {
                "explanation": "What is 300 divided by 1?<br /><br />Think of it as: 3E2 / 1E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E2, or 300."
            }
        },
        {
            "question": "What is 300,000 divided by 10?",
            "writeInAnswer": 30000,
            "furtherInfo": {
                "explanation": "What is 300,000 divided by 10?<br /><br />Think of it as: 3E5 / 1E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E4, or 30,000."
            }
        },
        {
            "question": "What is 10,000,000,000,000 divided by 10?",
            "writeInAnswer": 1000000000000,
            "furtherInfo": {
                "explanation": "What is 10,000,000,000,000 divided by 10?<br /><br />Think of it as: 1E13 / 1E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E12, or 1,000,000,000,000."
            }
        },
        {
            "question": "What is 10 divided by 1?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is 10 divided by 1?<br /><br />Think of it as: 1E1 / 1E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E1, or 10."
            }
        },
        {
            "question": "What is 300 divided by 30?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is 300 divided by 30?<br /><br />Think of it as: 3E2 / 3E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E1, or 10."
            }
        },
        {
            "question": "What is 100,000,000 divided by 30,000?",
            "writeInAnswer": 3000,
            "furtherInfo": {
                "explanation": "What is 100,000,000 divided by 30,000?<br /><br />Think of it as: 1E8 / 3E4.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E3, or 3,000."
            }
        },
        {
            "question": "What is 30,000 divided by 10?",
            "writeInAnswer": 3000,
            "furtherInfo": {
                "explanation": "What is 30,000 divided by 10?<br /><br />Think of it as: 3E4 / 1E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E3, or 3,000."
            }
        },
        {
            "question": "What is 10,000,000,000 divided by 3?",
            "writeInAnswer": 3000000000,
            "furtherInfo": {
                "explanation": "What is 10,000,000,000 divided by 3?<br /><br />Think of it as: 1E10 / 3E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E9, or 3,000,000,000."
            }
        },
        {
            "question": "What is 30,000,000,000 divided by 3,000?",
            "writeInAnswer": 10000000,
            "furtherInfo": {
                "explanation": "What is 30,000,000,000 divided by 3,000?<br /><br />Think of it as: 3E10 / 3E3.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E7, or 10,000,000."
            }
        },
        {
            "question": "What is 30,000,000,000 divided by 100,000?",
            "writeInAnswer": 300000,
            "furtherInfo": {
                "explanation": "What is 30,000,000,000 divided by 100,000?<br /><br />Think of it as: 3E10 / 1E5.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E5, or 300,000."
            }
        },
        {
            "question": "What is 300,000 divided by 30,000?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is 300,000 divided by 30,000?<br /><br />Think of it as: 3E5 / 3E4.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E1, or 10."
            }
        },
        {
            "question": "What is 10,000,000 divided by 300?",
            "writeInAnswer": 30000,
            "furtherInfo": {
                "explanation": "What is 10,000,000 divided by 300?<br /><br />Think of it as: 1E7 / 3E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E4, or 30,000."
            }
        },
        {
            "question": "What is 3,000,000,000 divided by 300,000,000?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is 3,000,000,000 divided by 300,000,000?<br /><br />Think of it as: 3E9 / 3E8.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E1, or 10."
            }
        },
        {
            "question": "What is 3,000,000,000,000 divided by 30,000,000,000?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is 3,000,000,000,000 divided by 30,000,000,000?<br /><br />Think of it as: 3E12 / 3E10.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E2, or 100."
            }
        },
        {
            "question": "What is 10,000,000 divided by 3?",
            "writeInAnswer": 3000000,
            "furtherInfo": {
                "explanation": "What is 10,000,000 divided by 3?<br /><br />Think of it as: 1E7 / 3E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E6, or 3,000,000."
            }
        },
        {
            "question": "What is 30,000,000,000 divided by 100?",
            "writeInAnswer": 300000000,
            "furtherInfo": {
                "explanation": "What is 30,000,000,000 divided by 100?<br /><br />Think of it as: 3E10 / 1E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E8, or 300,000,000."
            }
        },
        {
            "question": "What is 3,000,000 divided by 300?",
            "writeInAnswer": 10000,
            "furtherInfo": {
                "explanation": "What is 3,000,000 divided by 300?<br /><br />Think of it as: 3E6 / 3E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E4, or 10,000."
            }
        },
        {
            "question": "What is 100,000,000,000 divided by 1,000,000?",
            "writeInAnswer": 100000,
            "furtherInfo": {
                "explanation": "What is 100,000,000,000 divided by 1,000,000?<br /><br />Think of it as: 1E11 / 1E6.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E5, or 100,000."
            }
        },
        {
            "question": "What is 3,000,000,000,000 divided by 10,000,000,000?",
            "writeInAnswer": 300,
            "furtherInfo": {
                "explanation": "What is 3,000,000,000,000 divided by 10,000,000,000?<br /><br />Think of it as: 3E12 / 1E10.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E2, or 300."
            }
        },
        {
            "question": "What is 300 divided by 1?",
            "writeInAnswer": 300,
            "furtherInfo": {
                "explanation": "What is 300 divided by 1?<br /><br />Think of it as: 3E2 / 1E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E2, or 300."
            }
        },
        {
            "question": "What is 3,000,000,000,000 divided by 10,000?",
            "writeInAnswer": 300000000,
            "furtherInfo": {
                "explanation": "What is 3,000,000,000,000 divided by 10,000?<br /><br />Think of it as: 3E12 / 1E4.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E8, or 300,000,000."
            }
        },
        {
            "question": "What is 100,000,000 divided by 30,000?",
            "writeInAnswer": 3000,
            "furtherInfo": {
                "explanation": "What is 100,000,000 divided by 30,000?<br /><br />Think of it as: 1E8 / 3E4.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E3, or 3,000."
            }
        },
        {
            "question": "What is 30,000,000,000,000 divided by 1,000,000,000?",
            "writeInAnswer": 30000,
            "furtherInfo": {
                "explanation": "What is 30,000,000,000,000 divided by 1,000,000,000?<br /><br />Think of it as: 3E13 / 1E9.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E4, or 30,000."
            }
        },
        {
            "question": "What is 30,000,000,000,000 divided by 10?",
            "writeInAnswer": 3000000000000,
            "furtherInfo": {
                "explanation": "What is 30,000,000,000,000 divided by 10?<br /><br />Think of it as: 3E13 / 1E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E12, or 3,000,000,000,000."
            }
        },
        {
            "question": "What is 100,000,000,000 divided by 3,000,000?",
            "writeInAnswer": 30000,
            "furtherInfo": {
                "explanation": "What is 100,000,000,000 divided by 3,000,000?<br /><br />Think of it as: 1E11 / 3E6.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E4, or 30,000."
            }
        },
        {
            "question": "What is 100 divided by 3?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is 100 divided by 3?<br /><br />Think of it as: 1E2 / 3E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E1, or 30."
            }
        },
        {
            "question": "What is 10,000,000 divided by 10?",
            "writeInAnswer": 1000000,
            "furtherInfo": {
                "explanation": "What is 10,000,000 divided by 10?<br /><br />Think of it as: 1E7 / 1E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E6, or 1,000,000."
            }
        },
        {
            "question": "What is 10,000,000,000,000 divided by 10,000,000,000?",
            "writeInAnswer": 1000,
            "furtherInfo": {
                "explanation": "What is 10,000,000,000,000 divided by 10,000,000,000?<br /><br />Think of it as: 1E13 / 1E10.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E3, or 1,000."
            }
        },
        {
            "question": "What is 1,000,000,000,000 divided by 30,000,000?",
            "writeInAnswer": 30000,
            "furtherInfo": {
                "explanation": "What is 1,000,000,000,000 divided by 30,000,000?<br /><br />Think of it as: 1E12 / 3E7.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E4, or 30,000."
            }
        },
        {
            "question": "What is 100 divided by 3?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is 100 divided by 3?<br /><br />Think of it as: 1E2 / 3E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E1, or 30."
            }
        },
        {
            "question": "What is 3,000,000,000 divided by 300?",
            "writeInAnswer": 10000000,
            "furtherInfo": {
                "explanation": "What is 3,000,000,000 divided by 300?<br /><br />Think of it as: 3E9 / 3E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E7, or 10,000,000."
            }
        },
        {
            "question": "What is 1,000,000 divided by 300,000?",
            "writeInAnswer": 3,
            "furtherInfo": {
                "explanation": "What is 1,000,000 divided by 300,000?<br /><br />Think of it as: 1E6 / 3E5.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E0, or 3."
            }
        },
        {
            "question": "What is 100,000,000,000 divided by 30,000,000?",
            "writeInAnswer": 3000,
            "furtherInfo": {
                "explanation": "What is 100,000,000,000 divided by 30,000,000?<br /><br />Think of it as: 1E11 / 3E7.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E3, or 3,000."
            }
        },
        {
            "question": "What is 100,000,000,000 divided by 3,000?",
            "writeInAnswer": 30000000,
            "furtherInfo": {
                "explanation": "What is 100,000,000,000 divided by 3,000?<br /><br />Think of it as: 1E11 / 3E3.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E7, or 30,000,000."
            }
        },
        {
            "question": "What is 30,000,000,000,000 divided by 10,000,000,000?",
            "writeInAnswer": 3000,
            "furtherInfo": {
                "explanation": "What is 30,000,000,000,000 divided by 10,000,000,000?<br /><br />Think of it as: 3E13 / 1E10.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E3, or 3,000."
            }
        },
        {
            "question": "What is 3,000,000 divided by 30?",
            "writeInAnswer": 100000,
            "furtherInfo": {
                "explanation": "What is 3,000,000 divided by 30?<br /><br />Think of it as: 3E6 / 3E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E5, or 100,000."
            }
        },
        {
            "question": "What is 10,000,000,000 divided by 3?",
            "writeInAnswer": 3000000000,
            "furtherInfo": {
                "explanation": "What is 10,000,000,000 divided by 3?<br /><br />Think of it as: 1E10 / 3E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E9, or 3,000,000,000."
            }
        },
        {
            "question": "What is 3,000,000,000,000 divided by 300?",
            "writeInAnswer": 10000000000,
            "furtherInfo": {
                "explanation": "What is 3,000,000,000,000 divided by 300?<br /><br />Think of it as: 3E12 / 3E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E10, or 10,000,000,000."
            }
        },
        {
            "question": "What is 300 divided by 3?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is 300 divided by 3?<br /><br />Think of it as: 3E2 / 3E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E2, or 100."
            }
        },
        {
            "question": "What is 10 divided by 3?",
            "writeInAnswer": 3,
            "furtherInfo": {
                "explanation": "What is 10 divided by 3?<br /><br />Think of it as: 1E1 / 3E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E0, or 3."
            }
        },
        {
            "question": "What is 10 divided by 3?",
            "writeInAnswer": 3,
            "furtherInfo": {
                "explanation": "What is 10 divided by 3?<br /><br />Think of it as: 1E1 / 3E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E0, or 3."
            }
        },
        {
            "question": "What is 10,000,000,000 divided by 30,000?",
            "writeInAnswer": 300000,
            "furtherInfo": {
                "explanation": "What is 10,000,000,000 divided by 30,000?<br /><br />Think of it as: 1E10 / 3E4.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E5, or 300,000."
            }
        },
        {
            "question": "What is 10,000 divided by 30?",
            "writeInAnswer": 300,
            "furtherInfo": {
                "explanation": "What is 10,000 divided by 30?<br /><br />Think of it as: 1E4 / 3E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E2, or 300."
            }
        },
        {
            "question": "What is 10,000,000,000,000 divided by 1?",
            "writeInAnswer": 10000000000000,
            "furtherInfo": {
                "explanation": "What is 10,000,000,000,000 divided by 1?<br /><br />Think of it as: 1E13 / 1E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E13, or 10,000,000,000,000."
            }
        },
        {
            "question": "What is 30,000,000,000 divided by 30?",
            "writeInAnswer": 1000000000,
            "furtherInfo": {
                "explanation": "What is 30,000,000,000 divided by 30?<br /><br />Think of it as: 3E10 / 3E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E9, or 1,000,000,000."
            }
        },
        {
            "question": "What is 3,000,000 divided by 3,000?",
            "writeInAnswer": 1000,
            "furtherInfo": {
                "explanation": "What is 3,000,000 divided by 3,000?<br /><br />Think of it as: 3E6 / 3E3.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E3, or 1,000."
            }
        },
        {
            "question": "What is 10 divided by 1?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is 10 divided by 1?<br /><br />Think of it as: 1E1 / 1E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E1, or 10."
            }
        },
        {
            "question": "What is 300,000,000,000 divided by 3,000?",
            "writeInAnswer": 100000000,
            "furtherInfo": {
                "explanation": "What is 300,000,000,000 divided by 3,000?<br /><br />Think of it as: 3E11 / 3E3.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E8, or 100,000,000."
            }
        },
        {
            "question": "What is 30,000 divided by 300?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is 30,000 divided by 300?<br /><br />Think of it as: 3E4 / 3E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E2, or 100."
            }
        },
        {
            "question": "What is 300,000,000 divided by 100,000?",
            "writeInAnswer": 3000,
            "furtherInfo": {
                "explanation": "What is 300,000,000 divided by 100,000?<br /><br />Think of it as: 3E8 / 1E5.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E3, or 3,000."
            }
        },
        {
            "question": "What is 300 divided by 1?",
            "writeInAnswer": 300,
            "furtherInfo": {
                "explanation": "What is 300 divided by 1?<br /><br />Think of it as: 3E2 / 1E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E2, or 300."
            }
        },
        {
            "question": "What is 100 divided by 30?",
            "writeInAnswer": 3,
            "furtherInfo": {
                "explanation": "What is 100 divided by 30?<br /><br />Think of it as: 1E2 / 3E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E0, or 3."
            }
        },
        {
            "question": "What is 300 divided by 10?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is 300 divided by 10?<br /><br />Think of it as: 3E2 / 1E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E1, or 30."
            }
        },
        {
            "question": "What is 10,000 divided by 3,000?",
            "writeInAnswer": 3,
            "furtherInfo": {
                "explanation": "What is 10,000 divided by 3,000?<br /><br />Think of it as: 1E4 / 3E3.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E0, or 3."
            }
        },
        {
            "question": "What is 300,000 divided by 10?",
            "writeInAnswer": 30000,
            "furtherInfo": {
                "explanation": "What is 300,000 divided by 10?<br /><br />Think of it as: 3E5 / 1E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E4, or 30,000."
            }
        },
        {
            "question": "What is 30,000,000,000,000 divided by 30?",
            "writeInAnswer": 1000000000000,
            "furtherInfo": {
                "explanation": "What is 30,000,000,000,000 divided by 30?<br /><br />Think of it as: 3E13 / 3E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E12, or 1,000,000,000,000."
            }
        },
        {
            "question": "What is 10,000,000,000 divided by 1,000,000?",
            "writeInAnswer": 10000,
            "furtherInfo": {
                "explanation": "What is 10,000,000,000 divided by 1,000,000?<br /><br />Think of it as: 1E10 / 1E6.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E4, or 10,000."
            }
        },
        {
            "question": "What is 300 divided by 10?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is 300 divided by 10?<br /><br />Think of it as: 3E2 / 1E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E1, or 30."
            }
        },
        {
            "question": "What is 30,000,000,000,000 divided by 3?",
            "writeInAnswer": 10000000000000,
            "furtherInfo": {
                "explanation": "What is 30,000,000,000,000 divided by 3?<br /><br />Think of it as: 3E13 / 3E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E13, or 10,000,000,000,000."
            }
        },
        {
            "question": "What is 300,000,000,000 divided by 30,000,000?",
            "writeInAnswer": 10000,
            "furtherInfo": {
                "explanation": "What is 300,000,000,000 divided by 30,000,000?<br /><br />Think of it as: 3E11 / 3E7.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E4, or 10,000."
            }
        },
        {
            "question": "What is 30,000,000 divided by 100?",
            "writeInAnswer": 300000,
            "furtherInfo": {
                "explanation": "What is 30,000,000 divided by 100?<br /><br />Think of it as: 3E7 / 1E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E5, or 300,000."
            }
        },
        {
            "question": "What is 1,000 divided by 3?",
            "writeInAnswer": 300,
            "furtherInfo": {
                "explanation": "What is 1,000 divided by 3?<br /><br />Think of it as: 1E3 / 3E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E2, or 300."
            }
        },
        {
            "question": "What is 300,000,000 divided by 3,000,000?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is 300,000,000 divided by 3,000,000?<br /><br />Think of it as: 3E8 / 3E6.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E2, or 100."
            }
        },
        {
            "question": "What is 10,000,000,000 divided by 300,000?",
            "writeInAnswer": 30000,
            "furtherInfo": {
                "explanation": "What is 10,000,000,000 divided by 300,000?<br /><br />Think of it as: 1E10 / 3E5.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E4, or 30,000."
            }
        },
        {
            "question": "What is 300,000,000 divided by 1,000,000?",
            "writeInAnswer": 300,
            "furtherInfo": {
                "explanation": "What is 300,000,000 divided by 1,000,000?<br /><br />Think of it as: 3E8 / 1E6.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E2, or 300."
            }
        },
        {
            "question": "What is 10,000,000,000,000 divided by 1?",
            "writeInAnswer": 10000000000000,
            "furtherInfo": {
                "explanation": "What is 10,000,000,000,000 divided by 1?<br /><br />Think of it as: 1E13 / 1E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E13, or 10,000,000,000,000."
            }
        },
        {
            "question": "What is 1,000,000,000,000 divided by 30,000?",
            "writeInAnswer": 30000000,
            "furtherInfo": {
                "explanation": "What is 1,000,000,000,000 divided by 30,000?<br /><br />Think of it as: 1E12 / 3E4.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E7, or 30,000,000."
            }
        },
        {
            "question": "What is 300,000 divided by 3,000?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is 300,000 divided by 3,000?<br /><br />Think of it as: 3E5 / 3E3.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E2, or 100."
            }
        },
        {
            "question": "What is 10,000,000,000,000 divided by 10?",
            "writeInAnswer": 1000000000000,
            "furtherInfo": {
                "explanation": "What is 10,000,000,000,000 divided by 10?<br /><br />Think of it as: 1E13 / 1E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E12, or 1,000,000,000,000."
            }
        },
        {
            "question": "What is 100,000,000 divided by 300?",
            "writeInAnswer": 300000,
            "furtherInfo": {
                "explanation": "What is 100,000,000 divided by 300?<br /><br />Think of it as: 1E8 / 3E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E5, or 300,000."
            }
        },
        {
            "question": "What is 30 divided by 3?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is 30 divided by 3?<br /><br />Think of it as: 3E1 / 3E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E1, or 10."
            }
        },
        {
            "question": "What is 10,000,000,000 divided by 3,000?",
            "writeInAnswer": 3000000,
            "furtherInfo": {
                "explanation": "What is 10,000,000,000 divided by 3,000?<br /><br />Think of it as: 1E10 / 3E3.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E6, or 3,000,000."
            }
        },
        {
            "question": "What is 30,000,000 divided by 30?",
            "writeInAnswer": 1000000,
            "furtherInfo": {
                "explanation": "What is 30,000,000 divided by 30?<br /><br />Think of it as: 3E7 / 3E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E6, or 1,000,000."
            }
        },
        {
            "question": "What is 100,000 divided by 10,000?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is 100,000 divided by 10,000?<br /><br />Think of it as: 1E5 / 1E4.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E1, or 10."
            }
        },
        {
            "question": "What is 100 divided by 1?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is 100 divided by 1?<br /><br />Think of it as: 1E2 / 1E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E2, or 100."
            }
        },
        {
            "question": "What is 1,000,000,000,000 divided by 10,000?",
            "writeInAnswer": 100000000,
            "furtherInfo": {
                "explanation": "What is 1,000,000,000,000 divided by 10,000?<br /><br />Think of it as: 1E12 / 1E4.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E8, or 100,000,000."
            }
        },
        {
            "question": "What is 1,000 divided by 10?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is 1,000 divided by 10?<br /><br />Think of it as: 1E3 / 1E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E2, or 100."
            }
        },
        {
            "question": "What is 3,000,000,000,000 divided by 1,000,000,000?",
            "writeInAnswer": 3000,
            "furtherInfo": {
                "explanation": "What is 3,000,000,000,000 divided by 1,000,000,000?<br /><br />Think of it as: 3E12 / 1E9.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E3, or 3,000."
            }
        },
        {
            "question": "What is 30,000 divided by 1?",
            "writeInAnswer": 30000,
            "furtherInfo": {
                "explanation": "What is 30,000 divided by 1?<br /><br />Think of it as: 3E4 / 1E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E4, or 30,000."
            }
        },
        {
            "question": "What is 30,000,000,000,000 divided by 100,000?",
            "writeInAnswer": 300000000,
            "furtherInfo": {
                "explanation": "What is 30,000,000,000,000 divided by 100,000?<br /><br />Think of it as: 3E13 / 1E5.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E8, or 300,000,000."
            }
        },
        {
            "question": "What is 10,000,000 divided by 30?",
            "writeInAnswer": 300000,
            "furtherInfo": {
                "explanation": "What is 10,000,000 divided by 30?<br /><br />Think of it as: 1E7 / 3E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E5, or 300,000."
            }
        },
        {
            "question": "What is 10,000 divided by 3,000?",
            "writeInAnswer": 3,
            "furtherInfo": {
                "explanation": "What is 10,000 divided by 3,000?<br /><br />Think of it as: 1E4 / 3E3.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E0, or 3."
            }
        },
        {
            "question": "What is 3,000,000 divided by 10,000?",
            "writeInAnswer": 300,
            "furtherInfo": {
                "explanation": "What is 3,000,000 divided by 10,000?<br /><br />Think of it as: 3E6 / 1E4.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E2, or 300."
            }
        },
        {
            "question": "What is 3,000 divided by 10?",
            "writeInAnswer": 300,
            "furtherInfo": {
                "explanation": "What is 3,000 divided by 10?<br /><br />Think of it as: 3E3 / 1E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E2, or 300."
            }
        },
        {
            "question": "What is 30,000,000,000,000 divided by 30,000,000,000?",
            "writeInAnswer": 1000,
            "furtherInfo": {
                "explanation": "What is 30,000,000,000,000 divided by 30,000,000,000?<br /><br />Think of it as: 3E13 / 3E10.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E3, or 1,000."
            }
        },
        {
            "question": "What is 3,000 divided by 1?",
            "writeInAnswer": 3000,
            "furtherInfo": {
                "explanation": "What is 3,000 divided by 1?<br /><br />Think of it as: 3E3 / 1E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E3, or 3,000."
            }
        },
        {
            "question": "What is 3,000,000,000,000 divided by 100?",
            "writeInAnswer": 30000000000,
            "furtherInfo": {
                "explanation": "What is 3,000,000,000,000 divided by 100?<br /><br />Think of it as: 3E12 / 1E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E10, or 30,000,000,000."
            }
        },
        {
            "question": "What is 30,000,000,000 divided by 300?",
            "writeInAnswer": 100000000,
            "furtherInfo": {
                "explanation": "What is 30,000,000,000 divided by 300?<br /><br />Think of it as: 3E10 / 3E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E8, or 100,000,000."
            }
        },
        {
            "question": "What is 300,000,000 divided by 10,000?",
            "writeInAnswer": 30000,
            "furtherInfo": {
                "explanation": "What is 300,000,000 divided by 10,000?<br /><br />Think of it as: 3E8 / 1E4.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E4, or 30,000."
            }
        },
        {
            "question": "What is 30,000,000,000 divided by 30,000,000?",
            "writeInAnswer": 1000,
            "furtherInfo": {
                "explanation": "What is 30,000,000,000 divided by 30,000,000?<br /><br />Think of it as: 3E10 / 3E7.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E3, or 1,000."
            }
        },
        {
            "question": "What is 300,000,000,000 divided by 1,000,000?",
            "writeInAnswer": 300000,
            "furtherInfo": {
                "explanation": "What is 300,000,000,000 divided by 1,000,000?<br /><br />Think of it as: 3E11 / 1E6.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E5, or 300,000."
            }
        },
        {
            "question": "What is 3,000,000,000,000 divided by 3?",
            "writeInAnswer": 1000000000000,
            "furtherInfo": {
                "explanation": "What is 3,000,000,000,000 divided by 3?<br /><br />Think of it as: 3E12 / 3E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E12, or 1,000,000,000,000."
            }
        },
        {
            "question": "What is 30,000,000 divided by 100,000?",
            "writeInAnswer": 300,
            "furtherInfo": {
                "explanation": "What is 30,000,000 divided by 100,000?<br /><br />Think of it as: 3E7 / 1E5.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E2, or 300."
            }
        },
        {
            "question": "What is 30,000,000,000 divided by 3?",
            "writeInAnswer": 10000000000,
            "furtherInfo": {
                "explanation": "What is 30,000,000,000 divided by 3?<br /><br />Think of it as: 3E10 / 3E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E10, or 10,000,000,000."
            }
        },
        {
            "question": "What is 1,000,000 divided by 10?",
            "writeInAnswer": 100000,
            "furtherInfo": {
                "explanation": "What is 1,000,000 divided by 10?<br /><br />Think of it as: 1E6 / 1E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E5, or 100,000."
            }
        },
        {
            "question": "What is 3,000,000 divided by 1?",
            "writeInAnswer": 3000000,
            "furtherInfo": {
                "explanation": "What is 3,000,000 divided by 1?<br /><br />Think of it as: 3E6 / 1E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E6, or 3,000,000."
            }
        },
        {
            "question": "What is 10 divided by 3?",
            "writeInAnswer": 3,
            "furtherInfo": {
                "explanation": "What is 10 divided by 3?<br /><br />Think of it as: 1E1 / 3E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E0, or 3."
            }
        },
        {
            "question": "What is 100 divided by 10?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is 100 divided by 10?<br /><br />Think of it as: 1E2 / 1E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E1, or 10."
            }
        },
        {
            "question": "What is 100,000,000 divided by 3,000?",
            "writeInAnswer": 30000,
            "furtherInfo": {
                "explanation": "What is 100,000,000 divided by 3,000?<br /><br />Think of it as: 1E8 / 3E3.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E4, or 30,000."
            }
        },
        {
            "question": "What is 100,000 divided by 1?",
            "writeInAnswer": 100000,
            "furtherInfo": {
                "explanation": "What is 100,000 divided by 1?<br /><br />Think of it as: 1E5 / 1E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E5, or 100,000."
            }
        },
        {
            "question": "What is 10,000,000,000,000 divided by 300,000,000?",
            "writeInAnswer": 30000,
            "furtherInfo": {
                "explanation": "What is 10,000,000,000,000 divided by 300,000,000?<br /><br />Think of it as: 1E13 / 3E8.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E4, or 30,000."
            }
        },
        {
            "question": "What is 100,000 divided by 30,000?",
            "writeInAnswer": 3,
            "furtherInfo": {
                "explanation": "What is 100,000 divided by 30,000?<br /><br />Think of it as: 1E5 / 3E4.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E0, or 3."
            }
        },
        {
            "question": "What is 100,000,000,000 divided by 3,000?",
            "writeInAnswer": 30000000,
            "furtherInfo": {
                "explanation": "What is 100,000,000,000 divided by 3,000?<br /><br />Think of it as: 1E11 / 3E3.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E7, or 30,000,000."
            }
        },
        {
            "question": "What is 10 divided by 3?",
            "writeInAnswer": 3,
            "furtherInfo": {
                "explanation": "What is 10 divided by 3?<br /><br />Think of it as: 1E1 / 3E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E0, or 3."
            }
        },
        {
            "question": "What is 30,000 divided by 300?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is 30,000 divided by 300?<br /><br />Think of it as: 3E4 / 3E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E2, or 100."
            }
        },
        {
            "question": "What is 30,000,000 divided by 3?",
            "writeInAnswer": 10000000,
            "furtherInfo": {
                "explanation": "What is 30,000,000 divided by 3?<br /><br />Think of it as: 3E7 / 3E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E7, or 10,000,000."
            }
        },
        {
            "question": "What is 1,000 divided by 100?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is 1,000 divided by 100?<br /><br />Think of it as: 1E3 / 1E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E1, or 10."
            }
        },
        {
            "question": "What is 10,000,000 divided by 300?",
            "writeInAnswer": 30000,
            "furtherInfo": {
                "explanation": "What is 10,000,000 divided by 300?<br /><br />Think of it as: 1E7 / 3E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E4, or 30,000."
            }
        },
        {
            "question": "What is 100 divided by 30?",
            "writeInAnswer": 3,
            "furtherInfo": {
                "explanation": "What is 100 divided by 30?<br /><br />Think of it as: 1E2 / 3E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E0, or 3."
            }
        },
        {
            "question": "What is 300,000,000,000 divided by 10?",
            "writeInAnswer": 30000000000,
            "furtherInfo": {
                "explanation": "What is 300,000,000,000 divided by 10?<br /><br />Think of it as: 3E11 / 1E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E10, or 30,000,000,000."
            }
        },
        {
            "question": "What is 30,000,000,000 divided by 1,000,000,000?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is 30,000,000,000 divided by 1,000,000,000?<br /><br />Think of it as: 3E10 / 1E9.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E1, or 30."
            }
        },
        {
            "question": "What is 30,000,000,000 divided by 100,000,000?",
            "writeInAnswer": 300,
            "furtherInfo": {
                "explanation": "What is 30,000,000,000 divided by 100,000,000?<br /><br />Think of it as: 3E10 / 1E8.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E2, or 300."
            }
        },
        {
            "question": "What is 10,000 divided by 1?",
            "writeInAnswer": 10000,
            "furtherInfo": {
                "explanation": "What is 10,000 divided by 1?<br /><br />Think of it as: 1E4 / 1E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E4, or 10,000."
            }
        },
        {
            "question": "What is 10,000,000 divided by 10?",
            "writeInAnswer": 1000000,
            "furtherInfo": {
                "explanation": "What is 10,000,000 divided by 10?<br /><br />Think of it as: 1E7 / 1E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E6, or 1,000,000."
            }
        },
        {
            "question": "What is 300,000 divided by 1,000?",
            "writeInAnswer": 300,
            "furtherInfo": {
                "explanation": "What is 300,000 divided by 1,000?<br /><br />Think of it as: 3E5 / 1E3.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E2, or 300."
            }
        },
        {
            "question": "What is 100,000 divided by 100?",
            "writeInAnswer": 1000,
            "furtherInfo": {
                "explanation": "What is 100,000 divided by 100?<br /><br />Think of it as: 1E5 / 1E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E3, or 1,000."
            }
        },
        {
            "question": "What is 100,000,000 divided by 1,000?",
            "writeInAnswer": 100000,
            "furtherInfo": {
                "explanation": "What is 100,000,000 divided by 1,000?<br /><br />Think of it as: 1E8 / 1E3.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E5, or 100,000."
            }
        },
        {
            "question": "What is 300,000,000 divided by 30,000?",
            "writeInAnswer": 10000,
            "furtherInfo": {
                "explanation": "What is 300,000,000 divided by 30,000?<br /><br />Think of it as: 3E8 / 3E4.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E4, or 10,000."
            }
        },
        {
            "question": "What is 300,000,000 divided by 30,000?",
            "writeInAnswer": 10000,
            "furtherInfo": {
                "explanation": "What is 300,000,000 divided by 30,000?<br /><br />Think of it as: 3E8 / 3E4.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E4, or 10,000."
            }
        },
        {
            "question": "What is 100,000 divided by 30,000?",
            "writeInAnswer": 3,
            "furtherInfo": {
                "explanation": "What is 100,000 divided by 30,000?<br /><br />Think of it as: 1E5 / 3E4.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E0, or 3."
            }
        },
        {
            "question": "What is 1,000,000,000,000 divided by 300,000,000?",
            "writeInAnswer": 3000,
            "furtherInfo": {
                "explanation": "What is 1,000,000,000,000 divided by 300,000,000?<br /><br />Think of it as: 1E12 / 3E8.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E3, or 3,000."
            }
        },
        {
            "question": "What is 30,000,000 divided by 1?",
            "writeInAnswer": 30000000,
            "furtherInfo": {
                "explanation": "What is 30,000,000 divided by 1?<br /><br />Think of it as: 3E7 / 1E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E7, or 30,000,000."
            }
        },
        {
            "question": "What is 30,000 divided by 3,000?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is 30,000 divided by 3,000?<br /><br />Think of it as: 3E4 / 3E3.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E1, or 10."
            }
        },
        {
            "question": "What is 30,000,000,000 divided by 3?",
            "writeInAnswer": 10000000000,
            "furtherInfo": {
                "explanation": "What is 30,000,000,000 divided by 3?<br /><br />Think of it as: 3E10 / 3E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E10, or 10,000,000,000."
            }
        },
        {
            "question": "What is 1,000,000,000,000 divided by 30,000?",
            "writeInAnswer": 30000000,
            "furtherInfo": {
                "explanation": "What is 1,000,000,000,000 divided by 30,000?<br /><br />Think of it as: 1E12 / 3E4.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E7, or 30,000,000."
            }
        },
        {
            "question": "What is 3,000,000,000 divided by 3,000?",
            "writeInAnswer": 1000000,
            "furtherInfo": {
                "explanation": "What is 3,000,000,000 divided by 3,000?<br /><br />Think of it as: 3E9 / 3E3.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E6, or 1,000,000."
            }
        },
        {
            "question": "What is 3,000,000 divided by 30,000?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is 3,000,000 divided by 30,000?<br /><br />Think of it as: 3E6 / 3E4.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E2, or 100."
            }
        },
        {
            "question": "What is 100,000,000,000 divided by 10?",
            "writeInAnswer": 10000000000,
            "furtherInfo": {
                "explanation": "What is 100,000,000,000 divided by 10?<br /><br />Think of it as: 1E11 / 1E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E10, or 10,000,000,000."
            }
        },
        {
            "question": "What is 100,000,000,000 divided by 30?",
            "writeInAnswer": 3000000000,
            "furtherInfo": {
                "explanation": "What is 100,000,000,000 divided by 30?<br /><br />Think of it as: 1E11 / 3E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E9, or 3,000,000,000."
            }
        },
        {
            "question": "What is 3,000,000,000,000 divided by 30,000?",
            "writeInAnswer": 100000000,
            "furtherInfo": {
                "explanation": "What is 3,000,000,000,000 divided by 30,000?<br /><br />Think of it as: 3E12 / 3E4.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E8, or 100,000,000."
            }
        },
        {
            "question": "What is 10,000,000,000,000 divided by 3,000,000,000?",
            "writeInAnswer": 3000,
            "furtherInfo": {
                "explanation": "What is 10,000,000,000,000 divided by 3,000,000,000?<br /><br />Think of it as: 1E13 / 3E9.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E3, or 3,000."
            }
        },
        {
            "question": "What is 100,000 divided by 300?",
            "writeInAnswer": 300,
            "furtherInfo": {
                "explanation": "What is 100,000 divided by 300?<br /><br />Think of it as: 1E5 / 3E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E2, or 300."
            }
        },
        {
            "question": "What is 1,000,000,000,000 divided by 30,000?",
            "writeInAnswer": 30000000,
            "furtherInfo": {
                "explanation": "What is 1,000,000,000,000 divided by 30,000?<br /><br />Think of it as: 1E12 / 3E4.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E7, or 30,000,000."
            }
        },
        {
            "question": "What is 30,000,000,000,000 divided by 30,000?",
            "writeInAnswer": 1000000000,
            "furtherInfo": {
                "explanation": "What is 30,000,000,000,000 divided by 30,000?<br /><br />Think of it as: 3E13 / 3E4.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E9, or 1,000,000,000."
            }
        },
        {
            "question": "What is 3,000 divided by 1?",
            "writeInAnswer": 3000,
            "furtherInfo": {
                "explanation": "What is 3,000 divided by 1?<br /><br />Think of it as: 3E3 / 1E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E3, or 3,000."
            }
        },
        {
            "question": "What is 100,000 divided by 30?",
            "writeInAnswer": 3000,
            "furtherInfo": {
                "explanation": "What is 100,000 divided by 30?<br /><br />Think of it as: 1E5 / 3E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E3, or 3,000."
            }
        },
        {
            "question": "What is 100 divided by 10?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is 100 divided by 10?<br /><br />Think of it as: 1E2 / 1E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E1, or 10."
            }
        },
        {
            "question": "What is 3,000 divided by 1?",
            "writeInAnswer": 3000,
            "furtherInfo": {
                "explanation": "What is 3,000 divided by 1?<br /><br />Think of it as: 3E3 / 1E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E3, or 3,000."
            }
        },
        {
            "question": "What is 100,000,000,000 divided by 10,000,000?",
            "writeInAnswer": 10000,
            "furtherInfo": {
                "explanation": "What is 100,000,000,000 divided by 10,000,000?<br /><br />Think of it as: 1E11 / 1E7.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E4, or 10,000."
            }
        },
        {
            "question": "What is 3,000,000,000,000 divided by 100?",
            "writeInAnswer": 30000000000,
            "furtherInfo": {
                "explanation": "What is 3,000,000,000,000 divided by 100?<br /><br />Think of it as: 3E12 / 1E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E10, or 30,000,000,000."
            }
        },
        {
            "question": "What is 30,000 divided by 1?",
            "writeInAnswer": 30000,
            "furtherInfo": {
                "explanation": "What is 30,000 divided by 1?<br /><br />Think of it as: 3E4 / 1E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E4, or 30,000."
            }
        },
        {
            "question": "What is 30,000,000,000 divided by 3,000,000?",
            "writeInAnswer": 10000,
            "furtherInfo": {
                "explanation": "What is 30,000,000,000 divided by 3,000,000?<br /><br />Think of it as: 3E10 / 3E6.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E4, or 10,000."
            }
        },
        {
            "question": "What is 10,000,000,000 divided by 3?",
            "writeInAnswer": 3000000000,
            "furtherInfo": {
                "explanation": "What is 10,000,000,000 divided by 3?<br /><br />Think of it as: 1E10 / 3E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E9, or 3,000,000,000."
            }
        },
        {
            "question": "What is 100,000,000,000 divided by 1,000,000?",
            "writeInAnswer": 100000,
            "furtherInfo": {
                "explanation": "What is 100,000,000,000 divided by 1,000,000?<br /><br />Think of it as: 1E11 / 1E6.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E5, or 100,000."
            }
        },
        {
            "question": "What is 300,000,000 divided by 3?",
            "writeInAnswer": 100000000,
            "furtherInfo": {
                "explanation": "What is 300,000,000 divided by 3?<br /><br />Think of it as: 3E8 / 3E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E8, or 100,000,000."
            }
        },
        {
            "question": "What is 10,000,000 divided by 300,000?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is 10,000,000 divided by 300,000?<br /><br />Think of it as: 1E7 / 3E5.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E1, or 30."
            }
        },
        {
            "question": "What is 10,000,000,000,000 divided by 100,000,000?",
            "writeInAnswer": 100000,
            "furtherInfo": {
                "explanation": "What is 10,000,000,000,000 divided by 100,000,000?<br /><br />Think of it as: 1E13 / 1E8.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E5, or 100,000."
            }
        },
        {
            "question": "What is 10,000,000 divided by 30?",
            "writeInAnswer": 300000,
            "furtherInfo": {
                "explanation": "What is 10,000,000 divided by 30?<br /><br />Think of it as: 1E7 / 3E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E5, or 300,000."
            }
        },
        {
            "question": "What is 10 divided by 3?",
            "writeInAnswer": 3,
            "furtherInfo": {
                "explanation": "What is 10 divided by 3?<br /><br />Think of it as: 1E1 / 3E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E0, or 3."
            }
        },
        {
            "question": "What is 300 divided by 10?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is 300 divided by 10?<br /><br />Think of it as: 3E2 / 1E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E1, or 30."
            }
        },
        {
            "question": "What is 1,000,000 divided by 3?",
            "writeInAnswer": 300000,
            "furtherInfo": {
                "explanation": "What is 1,000,000 divided by 3?<br /><br />Think of it as: 1E6 / 3E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E5, or 300,000."
            }
        },
        {
            "question": "What is 100,000,000 divided by 100?",
            "writeInAnswer": 1000000,
            "furtherInfo": {
                "explanation": "What is 100,000,000 divided by 100?<br /><br />Think of it as: 1E8 / 1E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E6, or 1,000,000."
            }
        },
        {
            "question": "What is 1,000,000,000,000 divided by 10,000,000,000?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is 1,000,000,000,000 divided by 10,000,000,000?<br /><br />Think of it as: 1E12 / 1E10.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E2, or 100."
            }
        },
        {
            "question": "What is 30,000,000,000 divided by 30?",
            "writeInAnswer": 1000000000,
            "furtherInfo": {
                "explanation": "What is 30,000,000,000 divided by 30?<br /><br />Think of it as: 3E10 / 3E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E9, or 1,000,000,000."
            }
        },
        {
            "question": "What is 300,000 divided by 100?",
            "writeInAnswer": 3000,
            "furtherInfo": {
                "explanation": "What is 300,000 divided by 100?<br /><br />Think of it as: 3E5 / 1E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E3, or 3,000."
            }
        },
        {
            "question": "What is 3,000,000 divided by 300,000?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is 3,000,000 divided by 300,000?<br /><br />Think of it as: 3E6 / 3E5.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E1, or 10."
            }
        },
        {
            "question": "What is 1,000 divided by 100?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is 1,000 divided by 100?<br /><br />Think of it as: 1E3 / 1E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E1, or 10."
            }
        },
        {
            "question": "What is 30,000,000,000,000 divided by 30?",
            "writeInAnswer": 1000000000000,
            "furtherInfo": {
                "explanation": "What is 30,000,000,000,000 divided by 30?<br /><br />Think of it as: 3E13 / 3E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E12, or 1,000,000,000,000."
            }
        },
        {
            "question": "What is 10,000 divided by 3,000?",
            "writeInAnswer": 3,
            "furtherInfo": {
                "explanation": "What is 10,000 divided by 3,000?<br /><br />Think of it as: 1E4 / 3E3.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E0, or 3."
            }
        },
        {
            "question": "What is 30,000 divided by 1?",
            "writeInAnswer": 30000,
            "furtherInfo": {
                "explanation": "What is 30,000 divided by 1?<br /><br />Think of it as: 3E4 / 1E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E4, or 30,000."
            }
        },
        {
            "question": "What is 30,000,000,000 divided by 30,000,000?",
            "writeInAnswer": 1000,
            "furtherInfo": {
                "explanation": "What is 30,000,000,000 divided by 30,000,000?<br /><br />Think of it as: 3E10 / 3E7.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E3, or 1,000."
            }
        },
        {
            "question": "What is 3,000 divided by 30?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is 3,000 divided by 30?<br /><br />Think of it as: 3E3 / 3E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E2, or 100."
            }
        },
        {
            "question": "What is 30,000,000,000 divided by 300,000,000?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is 30,000,000,000 divided by 300,000,000?<br /><br />Think of it as: 3E10 / 3E8.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E2, or 100."
            }
        },
        {
            "question": "What is 30,000,000,000,000 divided by 10,000,000,000?",
            "writeInAnswer": 3000,
            "furtherInfo": {
                "explanation": "What is 30,000,000,000,000 divided by 10,000,000,000?<br /><br />Think of it as: 3E13 / 1E10.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E3, or 3,000."
            }
        },
        {
            "question": "What is 3,000,000,000 divided by 3?",
            "writeInAnswer": 1000000000,
            "furtherInfo": {
                "explanation": "What is 3,000,000,000 divided by 3?<br /><br />Think of it as: 3E9 / 3E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E9, or 1,000,000,000."
            }
        },
        {
            "question": "What is 10 divided by 1?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is 10 divided by 1?<br /><br />Think of it as: 1E1 / 1E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E1, or 10."
            }
        },
        {
            "question": "What is 100 divided by 10?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is 100 divided by 10?<br /><br />Think of it as: 1E2 / 1E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E1, or 10."
            }
        },
        {
            "question": "What is 1,000,000 divided by 3,000?",
            "writeInAnswer": 300,
            "furtherInfo": {
                "explanation": "What is 1,000,000 divided by 3,000?<br /><br />Think of it as: 1E6 / 3E3.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E2, or 300."
            }
        },
        {
            "question": "What is 1,000,000,000 divided by 100,000,000?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is 1,000,000,000 divided by 100,000,000?<br /><br />Think of it as: 1E9 / 1E8.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E1, or 10."
            }
        },
        {
            "question": "What is 3,000 divided by 100?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is 3,000 divided by 100?<br /><br />Think of it as: 3E3 / 1E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E1, or 30."
            }
        },
        {
            "question": "What is 30,000,000,000 divided by 1,000,000?",
            "writeInAnswer": 30000,
            "furtherInfo": {
                "explanation": "What is 30,000,000,000 divided by 1,000,000?<br /><br />Think of it as: 3E10 / 1E6.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E4, or 30,000."
            }
        },
        {
            "question": "What is 3,000,000 divided by 30?",
            "writeInAnswer": 100000,
            "furtherInfo": {
                "explanation": "What is 3,000,000 divided by 30?<br /><br />Think of it as: 3E6 / 3E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E5, or 100,000."
            }
        },
        {
            "question": "What is 300 divided by 10?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is 300 divided by 10?<br /><br />Think of it as: 3E2 / 1E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E1, or 30."
            }
        },
        {
            "question": "What is 100,000,000 divided by 1,000?",
            "writeInAnswer": 100000,
            "furtherInfo": {
                "explanation": "What is 100,000,000 divided by 1,000?<br /><br />Think of it as: 1E8 / 1E3.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E5, or 100,000."
            }
        },
        {
            "question": "What is 10,000,000 divided by 10,000?",
            "writeInAnswer": 1000,
            "furtherInfo": {
                "explanation": "What is 10,000,000 divided by 10,000?<br /><br />Think of it as: 1E7 / 1E4.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E3, or 1,000."
            }
        },
        {
            "question": "What is 3,000,000 divided by 30?",
            "writeInAnswer": 100000,
            "furtherInfo": {
                "explanation": "What is 3,000,000 divided by 30?<br /><br />Think of it as: 3E6 / 3E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E5, or 100,000."
            }
        },
        {
            "question": "What is 30,000,000,000,000 divided by 10?",
            "writeInAnswer": 3000000000000,
            "furtherInfo": {
                "explanation": "What is 30,000,000,000,000 divided by 10?<br /><br />Think of it as: 3E13 / 1E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E12, or 3,000,000,000,000."
            }
        },
        {
            "question": "What is 300 divided by 3?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is 300 divided by 3?<br /><br />Think of it as: 3E2 / 3E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E2, or 100."
            }
        },
        {
            "question": "What is 10,000 divided by 300?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is 10,000 divided by 300?<br /><br />Think of it as: 1E4 / 3E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E1, or 30."
            }
        },
        {
            "question": "What is 30,000,000,000,000 divided by 100,000,000?",
            "writeInAnswer": 300000,
            "furtherInfo": {
                "explanation": "What is 30,000,000,000,000 divided by 100,000,000?<br /><br />Think of it as: 3E13 / 1E8.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E5, or 300,000."
            }
        },
        {
            "question": "What is 300,000,000 divided by 300,000?",
            "writeInAnswer": 1000,
            "furtherInfo": {
                "explanation": "What is 300,000,000 divided by 300,000?<br /><br />Think of it as: 3E8 / 3E5.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E3, or 1,000."
            }
        },
        {
            "question": "What is 3,000 divided by 300?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is 3,000 divided by 300?<br /><br />Think of it as: 3E3 / 3E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E1, or 10."
            }
        },
        {
            "question": "What is 1,000,000,000,000 divided by 10?",
            "writeInAnswer": 100000000000,
            "furtherInfo": {
                "explanation": "What is 1,000,000,000,000 divided by 10?<br /><br />Think of it as: 1E12 / 1E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E11, or 100,000,000,000."
            }
        },
        {
            "question": "What is 1,000 divided by 300?",
            "writeInAnswer": 3,
            "furtherInfo": {
                "explanation": "What is 1,000 divided by 300?<br /><br />Think of it as: 1E3 / 3E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E0, or 3."
            }
        },
        {
            "question": "What is 100,000,000,000 divided by 30,000,000?",
            "writeInAnswer": 3000,
            "furtherInfo": {
                "explanation": "What is 100,000,000,000 divided by 30,000,000?<br /><br />Think of it as: 1E11 / 3E7.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E3, or 3,000."
            }
        },
        {
            "question": "What is 30 divided by 1?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is 30 divided by 1?<br /><br />Think of it as: 3E1 / 1E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E1, or 30."
            }
        },
        {
            "question": "What is 1,000 divided by 100?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is 1,000 divided by 100?<br /><br />Think of it as: 1E3 / 1E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E1, or 10."
            }
        },
        {
            "question": "What is 300,000,000,000 divided by 10,000,000,000?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is 300,000,000,000 divided by 10,000,000,000?<br /><br />Think of it as: 3E11 / 1E10.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E1, or 30."
            }
        },
        {
            "question": "What is 1,000,000,000,000 divided by 30?",
            "writeInAnswer": 30000000000,
            "furtherInfo": {
                "explanation": "What is 1,000,000,000,000 divided by 30?<br /><br />Think of it as: 1E12 / 3E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E10, or 30,000,000,000."
            }
        },
        {
            "question": "What is 10 divided by 3?",
            "writeInAnswer": 3,
            "furtherInfo": {
                "explanation": "What is 10 divided by 3?<br /><br />Think of it as: 1E1 / 3E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E0, or 3."
            }
        },
        {
            "question": "What is 3,000 divided by 3?",
            "writeInAnswer": 1000,
            "furtherInfo": {
                "explanation": "What is 3,000 divided by 3?<br /><br />Think of it as: 3E3 / 3E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E3, or 1,000."
            }
        },
        {
            "question": "What is 1,000,000,000 divided by 30?",
            "writeInAnswer": 30000000,
            "furtherInfo": {
                "explanation": "What is 1,000,000,000 divided by 30?<br /><br />Think of it as: 1E9 / 3E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E7, or 30,000,000."
            }
        },
        {
            "question": "What is 10,000,000 divided by 1?",
            "writeInAnswer": 10000000,
            "furtherInfo": {
                "explanation": "What is 10,000,000 divided by 1?<br /><br />Think of it as: 1E7 / 1E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E7, or 10,000,000."
            }
        },
        {
            "question": "What is 300,000,000 divided by 1?",
            "writeInAnswer": 300000000,
            "furtherInfo": {
                "explanation": "What is 300,000,000 divided by 1?<br /><br />Think of it as: 3E8 / 1E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E8, or 300,000,000."
            }
        },
        {
            "question": "What is 1,000 divided by 10?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is 1,000 divided by 10?<br /><br />Think of it as: 1E3 / 1E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E2, or 100."
            }
        },
        {
            "question": "What is 30,000,000,000,000 divided by 3?",
            "writeInAnswer": 10000000000000,
            "furtherInfo": {
                "explanation": "What is 30,000,000,000,000 divided by 3?<br /><br />Think of it as: 3E13 / 3E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E13, or 10,000,000,000,000."
            }
        },
        {
            "question": "What is 10,000,000,000,000 divided by 10,000,000?",
            "writeInAnswer": 1000000,
            "furtherInfo": {
                "explanation": "What is 10,000,000,000,000 divided by 10,000,000?<br /><br />Think of it as: 1E13 / 1E7.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E6, or 1,000,000."
            }
        },
        {
            "question": "What is 10,000,000 divided by 3?",
            "writeInAnswer": 3000000,
            "furtherInfo": {
                "explanation": "What is 10,000,000 divided by 3?<br /><br />Think of it as: 1E7 / 3E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E6, or 3,000,000."
            }
        },
        {
            "question": "What is 100,000,000 divided by 1,000?",
            "writeInAnswer": 100000,
            "furtherInfo": {
                "explanation": "What is 100,000,000 divided by 1,000?<br /><br />Think of it as: 1E8 / 1E3.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E5, or 100,000."
            }
        },
        {
            "question": "What is 1,000 divided by 100?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is 1,000 divided by 100?<br /><br />Think of it as: 1E3 / 1E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E1, or 10."
            }
        },
        {
            "question": "What is 300,000 divided by 300?",
            "writeInAnswer": 1000,
            "furtherInfo": {
                "explanation": "What is 300,000 divided by 300?<br /><br />Think of it as: 3E5 / 3E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E3, or 1,000."
            }
        },
        {
            "question": "What is 1,000 divided by 100?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is 1,000 divided by 100?<br /><br />Think of it as: 1E3 / 1E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E1, or 10."
            }
        },
        {
            "question": "What is 3,000,000 divided by 3,000?",
            "writeInAnswer": 1000,
            "furtherInfo": {
                "explanation": "What is 3,000,000 divided by 3,000?<br /><br />Think of it as: 3E6 / 3E3.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E3, or 1,000."
            }
        },
        {
            "question": "What is 10,000 divided by 10?",
            "writeInAnswer": 1000,
            "furtherInfo": {
                "explanation": "What is 10,000 divided by 10?<br /><br />Think of it as: 1E4 / 1E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E3, or 1,000."
            }
        },
        {
            "question": "What is 10,000 divided by 3,000?",
            "writeInAnswer": 3,
            "furtherInfo": {
                "explanation": "What is 10,000 divided by 3,000?<br /><br />Think of it as: 1E4 / 3E3.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E0, or 3."
            }
        },
        {
            "question": "What is 3,000,000,000,000 divided by 100,000,000,000?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is 3,000,000,000,000 divided by 100,000,000,000?<br /><br />Think of it as: 3E12 / 1E11.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E1, or 30."
            }
        },
        {
            "question": "What is 10,000,000,000,000 divided by 30?",
            "writeInAnswer": 300000000000,
            "furtherInfo": {
                "explanation": "What is 10,000,000,000,000 divided by 30?<br /><br />Think of it as: 1E13 / 3E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E11, or 300,000,000,000."
            }
        },
        {
            "question": "What is 10,000 divided by 100?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is 10,000 divided by 100?<br /><br />Think of it as: 1E4 / 1E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E2, or 100."
            }
        },
        {
            "question": "What is 10,000,000,000 divided by 300?",
            "writeInAnswer": 30000000,
            "furtherInfo": {
                "explanation": "What is 10,000,000,000 divided by 300?<br /><br />Think of it as: 1E10 / 3E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E7, or 30,000,000."
            }
        },
        {
            "question": "What is 30,000,000 divided by 3,000?",
            "writeInAnswer": 10000,
            "furtherInfo": {
                "explanation": "What is 30,000,000 divided by 3,000?<br /><br />Think of it as: 3E7 / 3E3.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E4, or 10,000."
            }
        },
        {
            "question": "What is 1,000 divided by 100?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is 1,000 divided by 100?<br /><br />Think of it as: 1E3 / 1E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E1, or 10."
            }
        },
        {
            "question": "What is 1,000,000,000 divided by 10,000?",
            "writeInAnswer": 100000,
            "furtherInfo": {
                "explanation": "What is 1,000,000,000 divided by 10,000?<br /><br />Think of it as: 1E9 / 1E4.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E5, or 100,000."
            }
        },
        {
            "question": "What is 30 divided by 1?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is 30 divided by 1?<br /><br />Think of it as: 3E1 / 1E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E1, or 30."
            }
        },
        {
            "question": "What is 3,000,000 divided by 300,000?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is 3,000,000 divided by 300,000?<br /><br />Think of it as: 3E6 / 3E5.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E1, or 10."
            }
        },
        {
            "question": "What is 3,000,000,000 divided by 300,000,000?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is 3,000,000,000 divided by 300,000,000?<br /><br />Think of it as: 3E9 / 3E8.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E1, or 10."
            }
        },
        {
            "question": "What is 3,000,000 divided by 300?",
            "writeInAnswer": 10000,
            "furtherInfo": {
                "explanation": "What is 3,000,000 divided by 300?<br /><br />Think of it as: 3E6 / 3E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E4, or 10,000."
            }
        },
        {
            "question": "What is 300,000,000,000 divided by 1?",
            "writeInAnswer": 300000000000,
            "furtherInfo": {
                "explanation": "What is 300,000,000,000 divided by 1?<br /><br />Think of it as: 3E11 / 1E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E11, or 300,000,000,000."
            }
        },
        {
            "question": "What is 100 divided by 10?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is 100 divided by 10?<br /><br />Think of it as: 1E2 / 1E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E1, or 10."
            }
        },
        {
            "question": "What is 1,000,000,000 divided by 300?",
            "writeInAnswer": 3000000,
            "furtherInfo": {
                "explanation": "What is 1,000,000,000 divided by 300?<br /><br />Think of it as: 1E9 / 3E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E6, or 3,000,000."
            }
        },
        {
            "question": "What is 30,000,000,000 divided by 1,000?",
            "writeInAnswer": 30000000,
            "furtherInfo": {
                "explanation": "What is 30,000,000,000 divided by 1,000?<br /><br />Think of it as: 3E10 / 1E3.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E7, or 30,000,000."
            }
        },
        {
            "question": "What is 300 divided by 1?",
            "writeInAnswer": 300,
            "furtherInfo": {
                "explanation": "What is 300 divided by 1?<br /><br />Think of it as: 3E2 / 1E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E2, or 300."
            }
        },
        {
            "question": "What is 100,000,000,000 divided by 10,000?",
            "writeInAnswer": 10000000,
            "furtherInfo": {
                "explanation": "What is 100,000,000,000 divided by 10,000?<br /><br />Think of it as: 1E11 / 1E4.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E7, or 10,000,000."
            }
        },
        {
            "question": "What is 3,000,000 divided by 1?",
            "writeInAnswer": 3000000,
            "furtherInfo": {
                "explanation": "What is 3,000,000 divided by 1?<br /><br />Think of it as: 3E6 / 1E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E6, or 3,000,000."
            }
        },
        {
            "question": "What is 3,000,000,000 divided by 300,000?",
            "writeInAnswer": 10000,
            "furtherInfo": {
                "explanation": "What is 3,000,000,000 divided by 300,000?<br /><br />Think of it as: 3E9 / 3E5.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E4, or 10,000."
            }
        },
        {
            "question": "What is 3,000,000,000 divided by 3,000?",
            "writeInAnswer": 1000000,
            "furtherInfo": {
                "explanation": "What is 3,000,000,000 divided by 3,000?<br /><br />Think of it as: 3E9 / 3E3.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E6, or 1,000,000."
            }
        },
        {
            "question": "What is 3,000,000,000 divided by 10,000?",
            "writeInAnswer": 300000,
            "furtherInfo": {
                "explanation": "What is 3,000,000,000 divided by 10,000?<br /><br />Think of it as: 3E9 / 1E4.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E5, or 300,000."
            }
        },
        {
            "question": "What is 300,000,000 divided by 300,000?",
            "writeInAnswer": 1000,
            "furtherInfo": {
                "explanation": "What is 300,000,000 divided by 300,000?<br /><br />Think of it as: 3E8 / 3E5.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E3, or 1,000."
            }
        },
        {
            "question": "What is 3,000,000 divided by 10,000?",
            "writeInAnswer": 300,
            "furtherInfo": {
                "explanation": "What is 3,000,000 divided by 10,000?<br /><br />Think of it as: 3E6 / 1E4.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E2, or 300."
            }
        },
        {
            "question": "What is 1,000,000,000 divided by 1?",
            "writeInAnswer": 1000000000,
            "furtherInfo": {
                "explanation": "What is 1,000,000,000 divided by 1?<br /><br />Think of it as: 1E9 / 1E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E9, or 1,000,000,000."
            }
        },
        {
            "question": "What is 3,000,000,000,000 divided by 300,000,000,000?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is 3,000,000,000,000 divided by 300,000,000,000?<br /><br />Think of it as: 3E12 / 3E11.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E1, or 10."
            }
        },
        {
            "question": "What is 1,000,000 divided by 3,000?",
            "writeInAnswer": 300,
            "furtherInfo": {
                "explanation": "What is 1,000,000 divided by 3,000?<br /><br />Think of it as: 1E6 / 3E3.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E2, or 300."
            }
        },
        {
            "question": "What is 30 divided by 1?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is 30 divided by 1?<br /><br />Think of it as: 3E1 / 1E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E1, or 30."
            }
        },
        {
            "question": "What is 3,000,000,000,000 divided by 100,000,000?",
            "writeInAnswer": 30000,
            "furtherInfo": {
                "explanation": "What is 3,000,000,000,000 divided by 100,000,000?<br /><br />Think of it as: 3E12 / 1E8.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E4, or 30,000."
            }
        },
        {
            "question": "What is 10,000,000 divided by 30,000?",
            "writeInAnswer": 300,
            "furtherInfo": {
                "explanation": "What is 10,000,000 divided by 30,000?<br /><br />Think of it as: 1E7 / 3E4.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E2, or 300."
            }
        },
        {
            "question": "What is 30,000,000,000,000 divided by 3,000,000,000?",
            "writeInAnswer": 10000,
            "furtherInfo": {
                "explanation": "What is 30,000,000,000,000 divided by 3,000,000,000?<br /><br />Think of it as: 3E13 / 3E9.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E4, or 10,000."
            }
        },
        {
            "question": "What is 3,000 divided by 300?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is 3,000 divided by 300?<br /><br />Think of it as: 3E3 / 3E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E1, or 10."
            }
        },
        {
            "question": "What is 3,000,000,000 divided by 300,000?",
            "writeInAnswer": 10000,
            "furtherInfo": {
                "explanation": "What is 3,000,000,000 divided by 300,000?<br /><br />Think of it as: 3E9 / 3E5.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E4, or 10,000."
            }
        },
        {
            "question": "What is 300 divided by 30?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is 300 divided by 30?<br /><br />Think of it as: 3E2 / 3E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E1, or 10."
            }
        },
        {
            "question": "What is 1,000 divided by 10?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is 1,000 divided by 10?<br /><br />Think of it as: 1E3 / 1E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E2, or 100."
            }
        },
        {
            "question": "What is 100,000,000 divided by 10,000,000?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is 100,000,000 divided by 10,000,000?<br /><br />Think of it as: 1E8 / 1E7.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E1, or 10."
            }
        },
        {
            "question": "What is 100,000,000,000 divided by 3?",
            "writeInAnswer": 30000000000,
            "furtherInfo": {
                "explanation": "What is 100,000,000,000 divided by 3?<br /><br />Think of it as: 1E11 / 3E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E10, or 30,000,000,000."
            }
        },
        {
            "question": "What is 300 divided by 1?",
            "writeInAnswer": 300,
            "furtherInfo": {
                "explanation": "What is 300 divided by 1?<br /><br />Think of it as: 3E2 / 1E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E2, or 300."
            }
        },
        {
            "question": "What is 1,000,000 divided by 100?",
            "writeInAnswer": 10000,
            "furtherInfo": {
                "explanation": "What is 1,000,000 divided by 100?<br /><br />Think of it as: 1E6 / 1E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E4, or 10,000."
            }
        },
        {
            "question": "What is 10,000,000 divided by 300,000?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is 10,000,000 divided by 300,000?<br /><br />Think of it as: 1E7 / 3E5.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E1, or 30."
            }
        },
        {
            "question": "What is 3,000,000,000,000 divided by 30,000?",
            "writeInAnswer": 100000000,
            "furtherInfo": {
                "explanation": "What is 3,000,000,000,000 divided by 30,000?<br /><br />Think of it as: 3E12 / 3E4.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E8, or 100,000,000."
            }
        },
        {
            "question": "What is 10,000,000,000,000 divided by 300?",
            "writeInAnswer": 30000000000,
            "furtherInfo": {
                "explanation": "What is 10,000,000,000,000 divided by 300?<br /><br />Think of it as: 1E13 / 3E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E10, or 30,000,000,000."
            }
        },
        {
            "question": "What is 10,000 divided by 100?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is 10,000 divided by 100?<br /><br />Think of it as: 1E4 / 1E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E2, or 100."
            }
        },
        {
            "question": "What is 3,000,000,000 divided by 300,000,000?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is 3,000,000,000 divided by 300,000,000?<br /><br />Think of it as: 3E9 / 3E8.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E1, or 10."
            }
        },
        {
            "question": "What is 1,000,000 divided by 300?",
            "writeInAnswer": 3000,
            "furtherInfo": {
                "explanation": "What is 1,000,000 divided by 300?<br /><br />Think of it as: 1E6 / 3E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E3, or 3,000."
            }
        },
        {
            "question": "What is 30,000,000,000 divided by 30,000,000?",
            "writeInAnswer": 1000,
            "furtherInfo": {
                "explanation": "What is 30,000,000,000 divided by 30,000,000?<br /><br />Think of it as: 3E10 / 3E7.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E3, or 1,000."
            }
        },
        {
            "question": "What is 10,000 divided by 300?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is 10,000 divided by 300?<br /><br />Think of it as: 1E4 / 3E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E1, or 30."
            }
        },
        {
            "question": "What is 3,000,000,000,000 divided by 100?",
            "writeInAnswer": 30000000000,
            "furtherInfo": {
                "explanation": "What is 3,000,000,000,000 divided by 100?<br /><br />Think of it as: 3E12 / 1E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E10, or 30,000,000,000."
            }
        },
        {
            "question": "What is 300,000 divided by 30?",
            "writeInAnswer": 10000,
            "furtherInfo": {
                "explanation": "What is 300,000 divided by 30?<br /><br />Think of it as: 3E5 / 3E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E4, or 10,000."
            }
        },
        {
            "question": "What is 300,000,000,000 divided by 10?",
            "writeInAnswer": 30000000000,
            "furtherInfo": {
                "explanation": "What is 300,000,000,000 divided by 10?<br /><br />Think of it as: 3E11 / 1E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E10, or 30,000,000,000."
            }
        },
        {
            "question": "What is 300,000,000 divided by 100?",
            "writeInAnswer": 3000000,
            "furtherInfo": {
                "explanation": "What is 300,000,000 divided by 100?<br /><br />Think of it as: 3E8 / 1E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E6, or 3,000,000."
            }
        },
        {
            "question": "What is 3,000,000,000 divided by 3?",
            "writeInAnswer": 1000000000,
            "furtherInfo": {
                "explanation": "What is 3,000,000,000 divided by 3?<br /><br />Think of it as: 3E9 / 3E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E9, or 1,000,000,000."
            }
        },
        {
            "question": "What is 100,000,000,000 divided by 100?",
            "writeInAnswer": 1000000000,
            "furtherInfo": {
                "explanation": "What is 100,000,000,000 divided by 100?<br /><br />Think of it as: 1E11 / 1E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E9, or 1,000,000,000."
            }
        },
        {
            "question": "What is 1,000,000,000 divided by 30,000?",
            "writeInAnswer": 30000,
            "furtherInfo": {
                "explanation": "What is 1,000,000,000 divided by 30,000?<br /><br />Think of it as: 1E9 / 3E4.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E4, or 30,000."
            }
        },
        {
            "question": "What is 10 divided by 3?",
            "writeInAnswer": 3,
            "furtherInfo": {
                "explanation": "What is 10 divided by 3?<br /><br />Think of it as: 1E1 / 3E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E0, or 3."
            }
        },
        {
            "question": "What is 100 divided by 1?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is 100 divided by 1?<br /><br />Think of it as: 1E2 / 1E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E2, or 100."
            }
        },
        {
            "question": "What is 1,000,000,000 divided by 100?",
            "writeInAnswer": 10000000,
            "furtherInfo": {
                "explanation": "What is 1,000,000,000 divided by 100?<br /><br />Think of it as: 1E9 / 1E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E7, or 10,000,000."
            }
        },
        {
            "question": "What is 1,000,000 divided by 30?",
            "writeInAnswer": 30000,
            "furtherInfo": {
                "explanation": "What is 1,000,000 divided by 30?<br /><br />Think of it as: 1E6 / 3E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E4, or 30,000."
            }
        },
        {
            "question": "What is 30,000,000,000,000 divided by 10,000,000,000?",
            "writeInAnswer": 3000,
            "furtherInfo": {
                "explanation": "What is 30,000,000,000,000 divided by 10,000,000,000?<br /><br />Think of it as: 3E13 / 1E10.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E3, or 3,000."
            }
        },
        {
            "question": "What is 30,000,000 divided by 300?",
            "writeInAnswer": 100000,
            "furtherInfo": {
                "explanation": "What is 30,000,000 divided by 300?<br /><br />Think of it as: 3E7 / 3E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E5, or 100,000."
            }
        },
        {
            "question": "What is 30,000 divided by 10?",
            "writeInAnswer": 3000,
            "furtherInfo": {
                "explanation": "What is 30,000 divided by 10?<br /><br />Think of it as: 3E4 / 1E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E3, or 3,000."
            }
        },
        {
            "question": "What is 30 divided by 3?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is 30 divided by 3?<br /><br />Think of it as: 3E1 / 3E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E1, or 10."
            }
        },
        {
            "question": "What is 10,000,000 divided by 10?",
            "writeInAnswer": 1000000,
            "furtherInfo": {
                "explanation": "What is 10,000,000 divided by 10?<br /><br />Think of it as: 1E7 / 1E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E6, or 1,000,000."
            }
        },
        {
            "question": "What is 300,000,000,000 divided by 1?",
            "writeInAnswer": 300000000000,
            "furtherInfo": {
                "explanation": "What is 300,000,000,000 divided by 1?<br /><br />Think of it as: 3E11 / 1E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E11, or 300,000,000,000."
            }
        },
        {
            "question": "What is 3,000,000 divided by 300,000?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is 3,000,000 divided by 300,000?<br /><br />Think of it as: 3E6 / 3E5.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E1, or 10."
            }
        },
        {
            "question": "What is 100 divided by 10?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is 100 divided by 10?<br /><br />Think of it as: 1E2 / 1E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E1, or 10."
            }
        },
        {
            "question": "What is 10,000,000 divided by 300?",
            "writeInAnswer": 30000,
            "furtherInfo": {
                "explanation": "What is 10,000,000 divided by 300?<br /><br />Think of it as: 1E7 / 3E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E4, or 30,000."
            }
        },
        {
            "question": "What is 10,000,000,000 divided by 300?",
            "writeInAnswer": 30000000,
            "furtherInfo": {
                "explanation": "What is 10,000,000,000 divided by 300?<br /><br />Think of it as: 1E10 / 3E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E7, or 30,000,000."
            }
        },
        {
            "question": "What is 3,000,000,000,000 divided by 100,000?",
            "writeInAnswer": 30000000,
            "furtherInfo": {
                "explanation": "What is 3,000,000,000,000 divided by 100,000?<br /><br />Think of it as: 3E12 / 1E5.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E7, or 30,000,000."
            }
        },
        {
            "question": "What is 100,000,000,000 divided by 10,000,000,000?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is 100,000,000,000 divided by 10,000,000,000?<br /><br />Think of it as: 1E11 / 1E10.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E1, or 10."
            }
        },
        {
            "question": "What is 300 divided by 1?",
            "writeInAnswer": 300,
            "furtherInfo": {
                "explanation": "What is 300 divided by 1?<br /><br />Think of it as: 3E2 / 1E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E2, or 300."
            }
        },
        {
            "question": "What is 1,000 divided by 3?",
            "writeInAnswer": 300,
            "furtherInfo": {
                "explanation": "What is 1,000 divided by 3?<br /><br />Think of it as: 1E3 / 3E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E2, or 300."
            }
        },
        {
            "question": "What is 30,000,000 divided by 30?",
            "writeInAnswer": 1000000,
            "furtherInfo": {
                "explanation": "What is 30,000,000 divided by 30?<br /><br />Think of it as: 3E7 / 3E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E6, or 1,000,000."
            }
        },
        {
            "question": "What is 100 divided by 30?",
            "writeInAnswer": 3,
            "furtherInfo": {
                "explanation": "What is 100 divided by 30?<br /><br />Think of it as: 1E2 / 3E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E0, or 3."
            }
        },
        {
            "question": "What is 3,000,000,000 divided by 100?",
            "writeInAnswer": 30000000,
            "furtherInfo": {
                "explanation": "What is 3,000,000,000 divided by 100?<br /><br />Think of it as: 3E9 / 1E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E7, or 30,000,000."
            }
        },
        {
            "question": "What is 100,000 divided by 300?",
            "writeInAnswer": 300,
            "furtherInfo": {
                "explanation": "What is 100,000 divided by 300?<br /><br />Think of it as: 1E5 / 3E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E2, or 300."
            }
        },
        {
            "question": "What is 1,000 divided by 30?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is 1,000 divided by 30?<br /><br />Think of it as: 1E3 / 3E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E1, or 30."
            }
        },
        {
            "question": "What is 300,000 divided by 1,000?",
            "writeInAnswer": 300,
            "furtherInfo": {
                "explanation": "What is 300,000 divided by 1,000?<br /><br />Think of it as: 3E5 / 1E3.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E2, or 300."
            }
        },
        {
            "question": "What is 1,000,000,000 divided by 10?",
            "writeInAnswer": 100000000,
            "furtherInfo": {
                "explanation": "What is 1,000,000,000 divided by 10?<br /><br />Think of it as: 1E9 / 1E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E8, or 100,000,000."
            }
        },
        {
            "question": "What is 1,000 divided by 300?",
            "writeInAnswer": 3,
            "furtherInfo": {
                "explanation": "What is 1,000 divided by 300?<br /><br />Think of it as: 1E3 / 3E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E0, or 3."
            }
        },
        {
            "question": "What is 3,000,000 divided by 1?",
            "writeInAnswer": 3000000,
            "furtherInfo": {
                "explanation": "What is 3,000,000 divided by 1?<br /><br />Think of it as: 3E6 / 1E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E6, or 3,000,000."
            }
        },
        {
            "question": "What is 100,000 divided by 1,000?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is 100,000 divided by 1,000?<br /><br />Think of it as: 1E5 / 1E3.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E2, or 100."
            }
        },
        {
            "question": "What is 1,000,000 divided by 1,000?",
            "writeInAnswer": 1000,
            "furtherInfo": {
                "explanation": "What is 1,000,000 divided by 1,000?<br /><br />Think of it as: 1E6 / 1E3.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E3, or 1,000."
            }
        },
        {
            "question": "What is 30,000,000,000,000 divided by 100,000?",
            "writeInAnswer": 300000000,
            "furtherInfo": {
                "explanation": "What is 30,000,000,000,000 divided by 100,000?<br /><br />Think of it as: 3E13 / 1E5.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E8, or 300,000,000."
            }
        },
        {
            "question": "What is 1,000,000,000 divided by 100,000?",
            "writeInAnswer": 10000,
            "furtherInfo": {
                "explanation": "What is 1,000,000,000 divided by 100,000?<br /><br />Think of it as: 1E9 / 1E5.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E4, or 10,000."
            }
        },
        {
            "question": "What is 100,000,000,000 divided by 3,000,000?",
            "writeInAnswer": 30000,
            "furtherInfo": {
                "explanation": "What is 100,000,000,000 divided by 3,000,000?<br /><br />Think of it as: 1E11 / 3E6.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E4, or 30,000."
            }
        },
        {
            "question": "What is 300,000 divided by 10,000?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is 300,000 divided by 10,000?<br /><br />Think of it as: 3E5 / 1E4.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E1, or 30."
            }
        },
        {
            "question": "What is 100,000,000,000 divided by 30,000,000,000?",
            "writeInAnswer": 3,
            "furtherInfo": {
                "explanation": "What is 100,000,000,000 divided by 30,000,000,000?<br /><br />Think of it as: 1E11 / 3E10.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E0, or 3."
            }
        },
        {
            "question": "What is 10 divided by 1?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is 10 divided by 1?<br /><br />Think of it as: 1E1 / 1E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E1, or 10."
            }
        },
        {
            "question": "What is 3,000 divided by 1?",
            "writeInAnswer": 3000,
            "furtherInfo": {
                "explanation": "What is 3,000 divided by 1?<br /><br />Think of it as: 3E3 / 1E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E3, or 3,000."
            }
        },
        {
            "question": "What is 1,000 divided by 1?",
            "writeInAnswer": 1000,
            "furtherInfo": {
                "explanation": "What is 1,000 divided by 1?<br /><br />Think of it as: 1E3 / 1E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E3, or 1,000."
            }
        },
        {
            "question": "What is 10,000 divided by 1?",
            "writeInAnswer": 10000,
            "furtherInfo": {
                "explanation": "What is 10,000 divided by 1?<br /><br />Think of it as: 1E4 / 1E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E4, or 10,000."
            }
        },
        {
            "question": "What is 100,000,000,000 divided by 100?",
            "writeInAnswer": 1000000000,
            "furtherInfo": {
                "explanation": "What is 100,000,000,000 divided by 100?<br /><br />Think of it as: 1E11 / 1E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E9, or 1,000,000,000."
            }
        },
        {
            "question": "What is 10 divided by 1?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is 10 divided by 1?<br /><br />Think of it as: 1E1 / 1E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E1, or 10."
            }
        },
        {
            "question": "What is 10,000,000,000,000 divided by 30,000?",
            "writeInAnswer": 300000000,
            "furtherInfo": {
                "explanation": "What is 10,000,000,000,000 divided by 30,000?<br /><br />Think of it as: 1E13 / 3E4.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E8, or 300,000,000."
            }
        },
        {
            "question": "What is 1,000,000,000 divided by 100,000,000?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is 1,000,000,000 divided by 100,000,000?<br /><br />Think of it as: 1E9 / 1E8.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E1, or 10."
            }
        },
        {
            "question": "What is 1,000,000 divided by 3?",
            "writeInAnswer": 300000,
            "furtherInfo": {
                "explanation": "What is 1,000,000 divided by 3?<br /><br />Think of it as: 1E6 / 3E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E5, or 300,000."
            }
        },
        {
            "question": "What is 10,000 divided by 30?",
            "writeInAnswer": 300,
            "furtherInfo": {
                "explanation": "What is 10,000 divided by 30?<br /><br />Think of it as: 1E4 / 3E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E2, or 300."
            }
        },
        {
            "question": "What is 10,000,000,000,000 divided by 100,000,000?",
            "writeInAnswer": 100000,
            "furtherInfo": {
                "explanation": "What is 10,000,000,000,000 divided by 100,000,000?<br /><br />Think of it as: 1E13 / 1E8.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E5, or 100,000."
            }
        },
        {
            "question": "What is 30,000,000 divided by 1,000,000?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is 30,000,000 divided by 1,000,000?<br /><br />Think of it as: 3E7 / 1E6.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E1, or 30."
            }
        },
        {
            "question": "What is 3,000,000,000 divided by 3,000,000?",
            "writeInAnswer": 1000,
            "furtherInfo": {
                "explanation": "What is 3,000,000,000 divided by 3,000,000?<br /><br />Think of it as: 3E9 / 3E6.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E3, or 1,000."
            }
        },
        {
            "question": "What is 30,000,000,000,000 divided by 3,000,000?",
            "writeInAnswer": 10000000,
            "furtherInfo": {
                "explanation": "What is 30,000,000,000,000 divided by 3,000,000?<br /><br />Think of it as: 3E13 / 3E6.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E7, or 10,000,000."
            }
        },
        {
            "question": "What is 100,000 divided by 3,000?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is 100,000 divided by 3,000?<br /><br />Think of it as: 1E5 / 3E3.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E1, or 30."
            }
        },
        {
            "question": "What is 30 divided by 3?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is 30 divided by 3?<br /><br />Think of it as: 3E1 / 3E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E1, or 10."
            }
        },
        {
            "question": "What is 30,000 divided by 1,000?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is 30,000 divided by 1,000?<br /><br />Think of it as: 3E4 / 1E3.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E1, or 30."
            }
        },
        {
            "question": "What is 1,000,000 divided by 30,000?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is 1,000,000 divided by 30,000?<br /><br />Think of it as: 1E6 / 3E4.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E1, or 30."
            }
        },
        {
            "question": "What is 10 divided by 1?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is 10 divided by 1?<br /><br />Think of it as: 1E1 / 1E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E1, or 10."
            }
        },
        {
            "question": "What is 10,000,000,000,000 divided by 10,000,000,000?",
            "writeInAnswer": 1000,
            "furtherInfo": {
                "explanation": "What is 10,000,000,000,000 divided by 10,000,000,000?<br /><br />Think of it as: 1E13 / 1E10.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E3, or 1,000."
            }
        },
        {
            "question": "What is 300,000,000,000 divided by 1,000,000,000?",
            "writeInAnswer": 300,
            "furtherInfo": {
                "explanation": "What is 300,000,000,000 divided by 1,000,000,000?<br /><br />Think of it as: 3E11 / 1E9.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E2, or 300."
            }
        },
        {
            "question": "What is 30,000,000,000 divided by 10,000?",
            "writeInAnswer": 3000000,
            "furtherInfo": {
                "explanation": "What is 30,000,000,000 divided by 10,000?<br /><br />Think of it as: 3E10 / 1E4.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E6, or 3,000,000."
            }
        },
        {
            "question": "What is 10 divided by 1?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is 10 divided by 1?<br /><br />Think of it as: 1E1 / 1E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E1, or 10."
            }
        },
        {
            "question": "What is 30,000,000,000 divided by 30,000?",
            "writeInAnswer": 1000000,
            "furtherInfo": {
                "explanation": "What is 30,000,000,000 divided by 30,000?<br /><br />Think of it as: 3E10 / 3E4.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E6, or 1,000,000."
            }
        },
        {
            "question": "What is 3,000 divided by 30?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is 3,000 divided by 30?<br /><br />Think of it as: 3E3 / 3E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E2, or 100."
            }
        },
        {
            "question": "What is 3,000,000 divided by 10?",
            "writeInAnswer": 300000,
            "furtherInfo": {
                "explanation": "What is 3,000,000 divided by 10?<br /><br />Think of it as: 3E6 / 1E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E5, or 300,000."
            }
        },
        {
            "question": "What is 3,000,000,000 divided by 30,000,000?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is 3,000,000,000 divided by 30,000,000?<br /><br />Think of it as: 3E9 / 3E7.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E2, or 100."
            }
        },
        {
            "question": "What is 100,000,000,000 divided by 3,000?",
            "writeInAnswer": 30000000,
            "furtherInfo": {
                "explanation": "What is 100,000,000,000 divided by 3,000?<br /><br />Think of it as: 1E11 / 3E3.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E7, or 30,000,000."
            }
        },
        {
            "question": "What is 30 divided by 1?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is 30 divided by 1?<br /><br />Think of it as: 3E1 / 1E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E1, or 30."
            }
        },
        {
            "question": "What is 1,000,000 divided by 1?",
            "writeInAnswer": 1000000,
            "furtherInfo": {
                "explanation": "What is 1,000,000 divided by 1?<br /><br />Think of it as: 1E6 / 1E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E6, or 1,000,000."
            }
        },
        {
            "question": "What is 300,000,000 divided by 300?",
            "writeInAnswer": 1000000,
            "furtherInfo": {
                "explanation": "What is 300,000,000 divided by 300?<br /><br />Think of it as: 3E8 / 3E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E6, or 1,000,000."
            }
        },
        {
            "question": "What is 1,000,000,000,000 divided by 30,000?",
            "writeInAnswer": 30000000,
            "furtherInfo": {
                "explanation": "What is 1,000,000,000,000 divided by 30,000?<br /><br />Think of it as: 1E12 / 3E4.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E7, or 30,000,000."
            }
        },
        {
            "question": "What is 100,000,000 divided by 1,000?",
            "writeInAnswer": 100000,
            "furtherInfo": {
                "explanation": "What is 100,000,000 divided by 1,000?<br /><br />Think of it as: 1E8 / 1E3.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E5, or 100,000."
            }
        },
        {
            "question": "What is 1,000,000,000,000 divided by 100?",
            "writeInAnswer": 10000000000,
            "furtherInfo": {
                "explanation": "What is 1,000,000,000,000 divided by 100?<br /><br />Think of it as: 1E12 / 1E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E10, or 10,000,000,000."
            }
        },
        {
            "question": "What is 300,000,000 divided by 30,000,000?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is 300,000,000 divided by 30,000,000?<br /><br />Think of it as: 3E8 / 3E7.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E1, or 10."
            }
        },
        {
            "question": "What is 1,000,000,000,000 divided by 100,000,000,000?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is 1,000,000,000,000 divided by 100,000,000,000?<br /><br />Think of it as: 1E12 / 1E11.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E1, or 10."
            }
        },
        {
            "question": "What is 1,000,000,000,000 divided by 3?",
            "writeInAnswer": 300000000000,
            "furtherInfo": {
                "explanation": "What is 1,000,000,000,000 divided by 3?<br /><br />Think of it as: 1E12 / 3E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E11, or 300,000,000,000."
            }
        },
        {
            "question": "What is 3,000,000 divided by 3,000?",
            "writeInAnswer": 1000,
            "furtherInfo": {
                "explanation": "What is 3,000,000 divided by 3,000?<br /><br />Think of it as: 3E6 / 3E3.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E3, or 1,000."
            }
        },
        {
            "question": "What is 3,000,000,000 divided by 3,000,000?",
            "writeInAnswer": 1000,
            "furtherInfo": {
                "explanation": "What is 3,000,000,000 divided by 3,000,000?<br /><br />Think of it as: 3E9 / 3E6.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E3, or 1,000."
            }
        },
        {
            "question": "What is 3,000 divided by 30?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is 3,000 divided by 30?<br /><br />Think of it as: 3E3 / 3E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E2, or 100."
            }
        },
        {
            "question": "What is 30,000 divided by 100?",
            "writeInAnswer": 300,
            "furtherInfo": {
                "explanation": "What is 30,000 divided by 100?<br /><br />Think of it as: 3E4 / 1E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E2, or 300."
            }
        },
        {
            "question": "What is 1,000,000,000 divided by 30,000,000?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is 1,000,000,000 divided by 30,000,000?<br /><br />Think of it as: 1E9 / 3E7.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E1, or 30."
            }
        },
        {
            "question": "What is 100,000,000 divided by 10,000?",
            "writeInAnswer": 10000,
            "furtherInfo": {
                "explanation": "What is 100,000,000 divided by 10,000?<br /><br />Think of it as: 1E8 / 1E4.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E4, or 10,000."
            }
        },
        {
            "question": "What is 100,000,000 divided by 30,000?",
            "writeInAnswer": 3000,
            "furtherInfo": {
                "explanation": "What is 100,000,000 divided by 30,000?<br /><br />Think of it as: 1E8 / 3E4.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E3, or 3,000."
            }
        },
        {
            "question": "What is 10,000,000 divided by 3?",
            "writeInAnswer": 3000000,
            "furtherInfo": {
                "explanation": "What is 10,000,000 divided by 3?<br /><br />Think of it as: 1E7 / 3E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E6, or 3,000,000."
            }
        },
        {
            "question": "What is 10 divided by 1?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is 10 divided by 1?<br /><br />Think of it as: 1E1 / 1E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E1, or 10."
            }
        },
        {
            "question": "What is 100,000 divided by 30,000?",
            "writeInAnswer": 3,
            "furtherInfo": {
                "explanation": "What is 100,000 divided by 30,000?<br /><br />Think of it as: 1E5 / 3E4.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E0, or 3."
            }
        },
        {
            "question": "What is 1,000,000 divided by 3?",
            "writeInAnswer": 300000,
            "furtherInfo": {
                "explanation": "What is 1,000,000 divided by 3?<br /><br />Think of it as: 1E6 / 3E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E5, or 300,000."
            }
        },
        {
            "question": "What is 3,000,000 divided by 30,000?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is 3,000,000 divided by 30,000?<br /><br />Think of it as: 3E6 / 3E4.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E2, or 100."
            }
        },
        {
            "question": "What is 10,000,000 divided by 100?",
            "writeInAnswer": 100000,
            "furtherInfo": {
                "explanation": "What is 10,000,000 divided by 100?<br /><br />Think of it as: 1E7 / 1E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E5, or 100,000."
            }
        },
        {
            "question": "What is 100 divided by 3?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is 100 divided by 3?<br /><br />Think of it as: 1E2 / 3E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E1, or 30."
            }
        },
        {
            "question": "What is 30 divided by 1?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is 30 divided by 1?<br /><br />Think of it as: 3E1 / 1E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E1, or 30."
            }
        },
        {
            "question": "What is 100,000,000 divided by 3,000?",
            "writeInAnswer": 30000,
            "furtherInfo": {
                "explanation": "What is 100,000,000 divided by 3,000?<br /><br />Think of it as: 1E8 / 3E3.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E4, or 30,000."
            }
        },
        {
            "question": "What is 3,000,000 divided by 3,000?",
            "writeInAnswer": 1000,
            "furtherInfo": {
                "explanation": "What is 3,000,000 divided by 3,000?<br /><br />Think of it as: 3E6 / 3E3.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E3, or 1,000."
            }
        },
        {
            "question": "What is 30,000 divided by 100?",
            "writeInAnswer": 300,
            "furtherInfo": {
                "explanation": "What is 30,000 divided by 100?<br /><br />Think of it as: 3E4 / 1E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E2, or 300."
            }
        },
        {
            "question": "What is 300 divided by 10?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is 300 divided by 10?<br /><br />Think of it as: 3E2 / 1E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E1, or 30."
            }
        },
        {
            "question": "What is 100,000,000 divided by 10?",
            "writeInAnswer": 10000000,
            "furtherInfo": {
                "explanation": "What is 100,000,000 divided by 10?<br /><br />Think of it as: 1E8 / 1E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E7, or 10,000,000."
            }
        },
        {
            "question": "What is 30,000,000,000 divided by 3,000,000,000?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is 30,000,000,000 divided by 3,000,000,000?<br /><br />Think of it as: 3E10 / 3E9.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E1, or 10."
            }
        },
        {
            "question": "What is 300,000,000,000 divided by 10,000?",
            "writeInAnswer": 30000000,
            "furtherInfo": {
                "explanation": "What is 300,000,000,000 divided by 10,000?<br /><br />Think of it as: 3E11 / 1E4.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E7, or 30,000,000."
            }
        },
        {
            "question": "What is 300 divided by 10?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is 300 divided by 10?<br /><br />Think of it as: 3E2 / 1E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E1, or 30."
            }
        },
        {
            "question": "What is 3,000 divided by 30?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is 3,000 divided by 30?<br /><br />Think of it as: 3E3 / 3E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E2, or 100."
            }
        },
        {
            "question": "What is 30,000 divided by 10?",
            "writeInAnswer": 3000,
            "furtherInfo": {
                "explanation": "What is 30,000 divided by 10?<br /><br />Think of it as: 3E4 / 1E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E3, or 3,000."
            }
        },
        {
            "question": "What is 300 divided by 10?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is 300 divided by 10?<br /><br />Think of it as: 3E2 / 1E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E1, or 30."
            }
        },
        {
            "question": "What is 30,000 divided by 30?",
            "writeInAnswer": 1000,
            "furtherInfo": {
                "explanation": "What is 30,000 divided by 30?<br /><br />Think of it as: 3E4 / 3E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E3, or 1,000."
            }
        },
        {
            "question": "What is 1,000,000,000 divided by 300?",
            "writeInAnswer": 3000000,
            "furtherInfo": {
                "explanation": "What is 1,000,000,000 divided by 300?<br /><br />Think of it as: 1E9 / 3E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E6, or 3,000,000."
            }
        },
        {
            "question": "What is 100,000,000 divided by 10,000?",
            "writeInAnswer": 10000,
            "furtherInfo": {
                "explanation": "What is 100,000,000 divided by 10,000?<br /><br />Think of it as: 1E8 / 1E4.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E4, or 10,000."
            }
        },
        {
            "question": "What is 3,000,000 divided by 1,000?",
            "writeInAnswer": 3000,
            "furtherInfo": {
                "explanation": "What is 3,000,000 divided by 1,000?<br /><br />Think of it as: 3E6 / 1E3.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E3, or 3,000."
            }
        },
        {
            "question": "What is 3,000 divided by 1?",
            "writeInAnswer": 3000,
            "furtherInfo": {
                "explanation": "What is 3,000 divided by 1?<br /><br />Think of it as: 3E3 / 1E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E3, or 3,000."
            }
        },
        {
            "question": "What is 30 divided by 1?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is 30 divided by 1?<br /><br />Think of it as: 3E1 / 1E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E1, or 30."
            }
        },
        {
            "question": "What is 30,000,000,000 divided by 10,000,000?",
            "writeInAnswer": 3000,
            "furtherInfo": {
                "explanation": "What is 30,000,000,000 divided by 10,000,000?<br /><br />Think of it as: 3E10 / 1E7.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E3, or 3,000."
            }
        },
        {
            "question": "What is 100,000 divided by 3?",
            "writeInAnswer": 30000,
            "furtherInfo": {
                "explanation": "What is 100,000 divided by 3?<br /><br />Think of it as: 1E5 / 3E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E4, or 30,000."
            }
        },
        {
            "question": "What is 300 divided by 10?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is 300 divided by 10?<br /><br />Think of it as: 3E2 / 1E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E1, or 30."
            }
        },
        {
            "question": "What is 300,000,000 divided by 300,000?",
            "writeInAnswer": 1000,
            "furtherInfo": {
                "explanation": "What is 300,000,000 divided by 300,000?<br /><br />Think of it as: 3E8 / 3E5.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E3, or 1,000."
            }
        },
        {
            "question": "What is 10,000,000 divided by 3,000?",
            "writeInAnswer": 3000,
            "furtherInfo": {
                "explanation": "What is 10,000,000 divided by 3,000?<br /><br />Think of it as: 1E7 / 3E3.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E3, or 3,000."
            }
        },
        {
            "question": "What is 300,000,000 divided by 3,000,000?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is 300,000,000 divided by 3,000,000?<br /><br />Think of it as: 3E8 / 3E6.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E2, or 100."
            }
        },
        {
            "question": "What is 30,000,000 divided by 10,000?",
            "writeInAnswer": 3000,
            "furtherInfo": {
                "explanation": "What is 30,000,000 divided by 10,000?<br /><br />Think of it as: 3E7 / 1E4.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E3, or 3,000."
            }
        },
        {
            "question": "What is 300,000,000,000 divided by 3,000?",
            "writeInAnswer": 100000000,
            "furtherInfo": {
                "explanation": "What is 300,000,000,000 divided by 3,000?<br /><br />Think of it as: 3E11 / 3E3.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E8, or 100,000,000."
            }
        },
        {
            "question": "What is 10,000 divided by 3?",
            "writeInAnswer": 3000,
            "furtherInfo": {
                "explanation": "What is 10,000 divided by 3?<br /><br />Think of it as: 1E4 / 3E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E3, or 3,000."
            }
        },
        {
            "question": "What is 100 divided by 1?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is 100 divided by 1?<br /><br />Think of it as: 1E2 / 1E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E2, or 100."
            }
        },
        {
            "question": "What is 10,000,000 divided by 1,000,000?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is 10,000,000 divided by 1,000,000?<br /><br />Think of it as: 1E7 / 1E6.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E1, or 10."
            }
        },
        {
            "question": "What is 30,000 divided by 3?",
            "writeInAnswer": 10000,
            "furtherInfo": {
                "explanation": "What is 30,000 divided by 3?<br /><br />Think of it as: 3E4 / 3E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E4, or 10,000."
            }
        },
        {
            "question": "What is 30,000 divided by 100?",
            "writeInAnswer": 300,
            "furtherInfo": {
                "explanation": "What is 30,000 divided by 100?<br /><br />Think of it as: 3E4 / 1E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E2, or 300."
            }
        },
        {
            "question": "What is 10 divided by 3?",
            "writeInAnswer": 3,
            "furtherInfo": {
                "explanation": "What is 10 divided by 3?<br /><br />Think of it as: 1E1 / 3E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E0, or 3."
            }
        },
        {
            "question": "What is 300,000,000 divided by 1?",
            "writeInAnswer": 300000000,
            "furtherInfo": {
                "explanation": "What is 300,000,000 divided by 1?<br /><br />Think of it as: 3E8 / 1E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E8, or 300,000,000."
            }
        },
        {
            "question": "What is 300 divided by 1?",
            "writeInAnswer": 300,
            "furtherInfo": {
                "explanation": "What is 300 divided by 1?<br /><br />Think of it as: 3E2 / 1E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E2, or 300."
            }
        },
        {
            "question": "What is 1,000,000 divided by 100?",
            "writeInAnswer": 10000,
            "furtherInfo": {
                "explanation": "What is 1,000,000 divided by 100?<br /><br />Think of it as: 1E6 / 1E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E4, or 10,000."
            }
        },
        {
            "question": "What is 3,000,000 divided by 300?",
            "writeInAnswer": 10000,
            "furtherInfo": {
                "explanation": "What is 3,000,000 divided by 300?<br /><br />Think of it as: 3E6 / 3E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E4, or 10,000."
            }
        },
        {
            "question": "What is 3,000,000,000 divided by 100,000,000?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is 3,000,000,000 divided by 100,000,000?<br /><br />Think of it as: 3E9 / 1E8.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E1, or 30."
            }
        },
        {
            "question": "What is 3,000,000,000 divided by 1,000?",
            "writeInAnswer": 3000000,
            "furtherInfo": {
                "explanation": "What is 3,000,000,000 divided by 1,000?<br /><br />Think of it as: 3E9 / 1E3.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E6, or 3,000,000."
            }
        },
        {
            "question": "What is 10,000,000 divided by 30?",
            "writeInAnswer": 300000,
            "furtherInfo": {
                "explanation": "What is 10,000,000 divided by 30?<br /><br />Think of it as: 1E7 / 3E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E5, or 300,000."
            }
        },
        {
            "question": "What is 30,000,000,000 divided by 1?",
            "writeInAnswer": 30000000000,
            "furtherInfo": {
                "explanation": "What is 30,000,000,000 divided by 1?<br /><br />Think of it as: 3E10 / 1E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E10, or 30,000,000,000."
            }
        },
        {
            "question": "What is 3,000,000,000 divided by 100,000?",
            "writeInAnswer": 30000,
            "furtherInfo": {
                "explanation": "What is 3,000,000,000 divided by 100,000?<br /><br />Think of it as: 3E9 / 1E5.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E4, or 30,000."
            }
        },
        {
            "question": "What is 30,000,000 divided by 300,000?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is 30,000,000 divided by 300,000?<br /><br />Think of it as: 3E7 / 3E5.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E2, or 100."
            }
        },
        {
            "question": "What is 100,000,000 divided by 300?",
            "writeInAnswer": 300000,
            "furtherInfo": {
                "explanation": "What is 100,000,000 divided by 300?<br /><br />Think of it as: 1E8 / 3E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E5, or 300,000."
            }
        },
        {
            "question": "What is 3,000,000,000 divided by 1,000,000?",
            "writeInAnswer": 3000,
            "furtherInfo": {
                "explanation": "What is 3,000,000,000 divided by 1,000,000?<br /><br />Think of it as: 3E9 / 1E6.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E3, or 3,000."
            }
        },
        {
            "question": "What is 3,000,000,000 divided by 300,000,000?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is 3,000,000,000 divided by 300,000,000?<br /><br />Think of it as: 3E9 / 3E8.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E1, or 10."
            }
        },
        {
            "question": "What is 1,000,000,000 divided by 10,000,000?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is 1,000,000,000 divided by 10,000,000?<br /><br />Think of it as: 1E9 / 1E7.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E2, or 100."
            }
        },
        {
            "question": "What is 3,000,000 divided by 3?",
            "writeInAnswer": 1000000,
            "furtherInfo": {
                "explanation": "What is 3,000,000 divided by 3?<br /><br />Think of it as: 3E6 / 3E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E6, or 1,000,000."
            }
        },
        {
            "question": "What is 10,000,000,000 divided by 300?",
            "writeInAnswer": 30000000,
            "furtherInfo": {
                "explanation": "What is 10,000,000,000 divided by 300?<br /><br />Think of it as: 1E10 / 3E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E7, or 30,000,000."
            }
        },
        {
            "question": "What is 300,000 divided by 10,000?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is 300,000 divided by 10,000?<br /><br />Think of it as: 3E5 / 1E4.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E1, or 30."
            }
        },
        {
            "question": "What is 100,000,000 divided by 30,000,000?",
            "writeInAnswer": 3,
            "furtherInfo": {
                "explanation": "What is 100,000,000 divided by 30,000,000?<br /><br />Think of it as: 1E8 / 3E7.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E0, or 3."
            }
        },
        {
            "question": "What is 10,000,000,000,000 divided by 3,000?",
            "writeInAnswer": 3000000000,
            "furtherInfo": {
                "explanation": "What is 10,000,000,000,000 divided by 3,000?<br /><br />Think of it as: 1E13 / 3E3.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E9, or 3,000,000,000."
            }
        },
        {
            "question": "What is 30,000,000,000 divided by 3,000,000?",
            "writeInAnswer": 10000,
            "furtherInfo": {
                "explanation": "What is 30,000,000,000 divided by 3,000,000?<br /><br />Think of it as: 3E10 / 3E6.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E4, or 10,000."
            }
        },
        {
            "question": "What is 300,000,000 divided by 10,000,000?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is 300,000,000 divided by 10,000,000?<br /><br />Think of it as: 3E8 / 1E7.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E1, or 30."
            }
        },
        {
            "question": "What is 100,000,000,000 divided by 3,000,000,000?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is 100,000,000,000 divided by 3,000,000,000?<br /><br />Think of it as: 1E11 / 3E9.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E1, or 30."
            }
        },
        {
            "question": "What is 3,000,000,000 divided by 3?",
            "writeInAnswer": 1000000000,
            "furtherInfo": {
                "explanation": "What is 3,000,000,000 divided by 3?<br /><br />Think of it as: 3E9 / 3E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E9, or 1,000,000,000."
            }
        },
        {
            "question": "What is 30,000 divided by 30?",
            "writeInAnswer": 1000,
            "furtherInfo": {
                "explanation": "What is 30,000 divided by 30?<br /><br />Think of it as: 3E4 / 3E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E3, or 1,000."
            }
        },
        {
            "question": "What is 30,000,000,000,000 divided by 1,000?",
            "writeInAnswer": 30000000000,
            "furtherInfo": {
                "explanation": "What is 30,000,000,000,000 divided by 1,000?<br /><br />Think of it as: 3E13 / 1E3.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E10, or 30,000,000,000."
            }
        },
        {
            "question": "What is 30,000,000 divided by 3?",
            "writeInAnswer": 10000000,
            "furtherInfo": {
                "explanation": "What is 30,000,000 divided by 3?<br /><br />Think of it as: 3E7 / 3E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E7, or 10,000,000."
            }
        },
        {
            "question": "What is 1,000,000,000,000 divided by 100,000?",
            "writeInAnswer": 10000000,
            "furtherInfo": {
                "explanation": "What is 1,000,000,000,000 divided by 100,000?<br /><br />Think of it as: 1E12 / 1E5.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E7, or 10,000,000."
            }
        },
        {
            "question": "What is 3,000,000,000,000 divided by 10,000,000?",
            "writeInAnswer": 300000,
            "furtherInfo": {
                "explanation": "What is 3,000,000,000,000 divided by 10,000,000?<br /><br />Think of it as: 3E12 / 1E7.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E5, or 300,000."
            }
        },
        {
            "question": "What is 10 divided by 1?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is 10 divided by 1?<br /><br />Think of it as: 1E1 / 1E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E1, or 10."
            }
        },
        {
            "question": "What is 30,000,000,000,000 divided by 100?",
            "writeInAnswer": 300000000000,
            "furtherInfo": {
                "explanation": "What is 30,000,000,000,000 divided by 100?<br /><br />Think of it as: 3E13 / 1E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E11, or 300,000,000,000."
            }
        },
        {
            "question": "What is 3,000,000,000,000 divided by 100,000?",
            "writeInAnswer": 30000000,
            "furtherInfo": {
                "explanation": "What is 3,000,000,000,000 divided by 100,000?<br /><br />Think of it as: 3E12 / 1E5.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E7, or 30,000,000."
            }
        },
        {
            "question": "What is 100,000,000 divided by 3,000,000?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is 100,000,000 divided by 3,000,000?<br /><br />Think of it as: 1E8 / 3E6.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E1, or 30."
            }
        },
        {
            "question": "What is 3,000,000,000 divided by 1,000,000?",
            "writeInAnswer": 3000,
            "furtherInfo": {
                "explanation": "What is 3,000,000,000 divided by 1,000,000?<br /><br />Think of it as: 3E9 / 1E6.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E3, or 3,000."
            }
        },
        {
            "question": "What is 300,000 divided by 30,000?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is 300,000 divided by 30,000?<br /><br />Think of it as: 3E5 / 3E4.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E1, or 10."
            }
        },
        {
            "question": "What is 1,000,000 divided by 100,000?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is 1,000,000 divided by 100,000?<br /><br />Think of it as: 1E6 / 1E5.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E1, or 10."
            }
        },
        {
            "question": "What is 10 divided by 3?",
            "writeInAnswer": 3,
            "furtherInfo": {
                "explanation": "What is 10 divided by 3?<br /><br />Think of it as: 1E1 / 3E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E0, or 3."
            }
        },
        {
            "question": "What is 300,000,000 divided by 300,000?",
            "writeInAnswer": 1000,
            "furtherInfo": {
                "explanation": "What is 300,000,000 divided by 300,000?<br /><br />Think of it as: 3E8 / 3E5.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E3, or 1,000."
            }
        },
        {
            "question": "What is 300,000,000 divided by 100?",
            "writeInAnswer": 3000000,
            "furtherInfo": {
                "explanation": "What is 300,000,000 divided by 100?<br /><br />Think of it as: 3E8 / 1E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E6, or 3,000,000."
            }
        },
        {
            "question": "What is 3,000,000,000 divided by 300?",
            "writeInAnswer": 10000000,
            "furtherInfo": {
                "explanation": "What is 3,000,000,000 divided by 300?<br /><br />Think of it as: 3E9 / 3E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E7, or 10,000,000."
            }
        },
        {
            "question": "What is 300,000,000,000 divided by 10,000,000?",
            "writeInAnswer": 30000,
            "furtherInfo": {
                "explanation": "What is 300,000,000,000 divided by 10,000,000?<br /><br />Think of it as: 3E11 / 1E7.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E4, or 30,000."
            }
        },
        {
            "question": "What is 1,000,000 divided by 300,000?",
            "writeInAnswer": 3,
            "furtherInfo": {
                "explanation": "What is 1,000,000 divided by 300,000?<br /><br />Think of it as: 1E6 / 3E5.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E0, or 3."
            }
        },
        {
            "question": "What is 100,000,000 divided by 30,000?",
            "writeInAnswer": 3000,
            "furtherInfo": {
                "explanation": "What is 100,000,000 divided by 30,000?<br /><br />Think of it as: 1E8 / 3E4.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E3, or 3,000."
            }
        },
        {
            "question": "What is 30,000,000,000,000 divided by 300,000,000?",
            "writeInAnswer": 100000,
            "furtherInfo": {
                "explanation": "What is 30,000,000,000,000 divided by 300,000,000?<br /><br />Think of it as: 3E13 / 3E8.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E5, or 100,000."
            }
        },
        {
            "question": "What is 30,000,000,000,000 divided by 3,000,000,000,000?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is 30,000,000,000,000 divided by 3,000,000,000,000?<br /><br />Think of it as: 3E13 / 3E12.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E1, or 10."
            }
        },
        {
            "question": "What is 30,000,000,000 divided by 1,000?",
            "writeInAnswer": 30000000,
            "furtherInfo": {
                "explanation": "What is 30,000,000,000 divided by 1,000?<br /><br />Think of it as: 3E10 / 1E3.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E7, or 30,000,000."
            }
        },
        {
            "question": "What is 300 divided by 1?",
            "writeInAnswer": 300,
            "furtherInfo": {
                "explanation": "What is 300 divided by 1?<br /><br />Think of it as: 3E2 / 1E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E2, or 300."
            }
        },
        {
            "question": "What is 10,000,000,000,000 divided by 100?",
            "writeInAnswer": 100000000000,
            "furtherInfo": {
                "explanation": "What is 10,000,000,000,000 divided by 100?<br /><br />Think of it as: 1E13 / 1E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E11, or 100,000,000,000."
            }
        },
        {
            "question": "What is 300,000,000 divided by 1,000,000?",
            "writeInAnswer": 300,
            "furtherInfo": {
                "explanation": "What is 300,000,000 divided by 1,000,000?<br /><br />Think of it as: 3E8 / 1E6.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E2, or 300."
            }
        },
        {
            "question": "What is 10,000 divided by 1,000?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is 10,000 divided by 1,000?<br /><br />Think of it as: 1E4 / 1E3.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E1, or 10."
            }
        },
        {
            "question": "What is 300,000,000 divided by 300?",
            "writeInAnswer": 1000000,
            "furtherInfo": {
                "explanation": "What is 300,000,000 divided by 300?<br /><br />Think of it as: 3E8 / 3E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E6, or 1,000,000."
            }
        },
        {
            "question": "What is 3,000,000,000 divided by 1,000?",
            "writeInAnswer": 3000000,
            "furtherInfo": {
                "explanation": "What is 3,000,000,000 divided by 1,000?<br /><br />Think of it as: 3E9 / 1E3.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E6, or 3,000,000."
            }
        },
        {
            "question": "What is 1,000,000,000 divided by 10,000,000?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is 1,000,000,000 divided by 10,000,000?<br /><br />Think of it as: 1E9 / 1E7.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E2, or 100."
            }
        },
        {
            "question": "What is 3,000,000,000,000 divided by 3,000,000?",
            "writeInAnswer": 1000000,
            "furtherInfo": {
                "explanation": "What is 3,000,000,000,000 divided by 3,000,000?<br /><br />Think of it as: 3E12 / 3E6.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E6, or 1,000,000."
            }
        },
        {
            "question": "What is 30 divided by 1?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is 30 divided by 1?<br /><br />Think of it as: 3E1 / 1E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E1, or 30."
            }
        },
        {
            "question": "What is 1,000,000,000 divided by 1?",
            "writeInAnswer": 1000000000,
            "furtherInfo": {
                "explanation": "What is 1,000,000,000 divided by 1?<br /><br />Think of it as: 1E9 / 1E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E9, or 1,000,000,000."
            }
        },
        {
            "question": "What is 10,000,000 divided by 10,000?",
            "writeInAnswer": 1000,
            "furtherInfo": {
                "explanation": "What is 10,000,000 divided by 10,000?<br /><br />Think of it as: 1E7 / 1E4.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E3, or 1,000."
            }
        },
        {
            "question": "What is 10,000,000,000,000 divided by 100,000,000,000?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is 10,000,000,000,000 divided by 100,000,000,000?<br /><br />Think of it as: 1E13 / 1E11.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E2, or 100."
            }
        },
        {
            "question": "What is 100,000 divided by 30,000?",
            "writeInAnswer": 3,
            "furtherInfo": {
                "explanation": "What is 100,000 divided by 30,000?<br /><br />Think of it as: 1E5 / 3E4.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E0, or 3."
            }
        },
        {
            "question": "What is 10,000,000 divided by 3,000,000?",
            "writeInAnswer": 3,
            "furtherInfo": {
                "explanation": "What is 10,000,000 divided by 3,000,000?<br /><br />Think of it as: 1E7 / 3E6.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E0, or 3."
            }
        },
        {
            "question": "What is 30,000,000,000 divided by 1,000,000,000?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is 30,000,000,000 divided by 1,000,000,000?<br /><br />Think of it as: 3E10 / 1E9.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E1, or 30."
            }
        },
        {
            "question": "What is 30,000 divided by 3?",
            "writeInAnswer": 10000,
            "furtherInfo": {
                "explanation": "What is 30,000 divided by 3?<br /><br />Think of it as: 3E4 / 3E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E4, or 10,000."
            }
        },
        {
            "question": "What is 3,000,000 divided by 30?",
            "writeInAnswer": 100000,
            "furtherInfo": {
                "explanation": "What is 3,000,000 divided by 30?<br /><br />Think of it as: 3E6 / 3E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E5, or 100,000."
            }
        },
        {
            "question": "What is 30,000 divided by 10?",
            "writeInAnswer": 3000,
            "furtherInfo": {
                "explanation": "What is 30,000 divided by 10?<br /><br />Think of it as: 3E4 / 1E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E3, or 3,000."
            }
        },
        {
            "question": "What is 300,000,000,000 divided by 3,000,000?",
            "writeInAnswer": 100000,
            "furtherInfo": {
                "explanation": "What is 300,000,000,000 divided by 3,000,000?<br /><br />Think of it as: 3E11 / 3E6.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E5, or 100,000."
            }
        },
        {
            "question": "What is 3,000,000,000 divided by 100,000,000?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is 3,000,000,000 divided by 100,000,000?<br /><br />Think of it as: 3E9 / 1E8.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E1, or 30."
            }
        },
        {
            "question": "What is 1,000,000,000,000 divided by 100,000,000,000?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is 1,000,000,000,000 divided by 100,000,000,000?<br /><br />Think of it as: 1E12 / 1E11.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E1, or 10."
            }
        },
        {
            "question": "What is 10,000,000 divided by 1?",
            "writeInAnswer": 10000000,
            "furtherInfo": {
                "explanation": "What is 10,000,000 divided by 1?<br /><br />Think of it as: 1E7 / 1E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E7, or 10,000,000."
            }
        },
        {
            "question": "What is 1,000,000 divided by 10?",
            "writeInAnswer": 100000,
            "furtherInfo": {
                "explanation": "What is 1,000,000 divided by 10?<br /><br />Think of it as: 1E6 / 1E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E5, or 100,000."
            }
        },
        {
            "question": "What is 1,000,000,000 divided by 100,000?",
            "writeInAnswer": 10000,
            "furtherInfo": {
                "explanation": "What is 1,000,000,000 divided by 100,000?<br /><br />Think of it as: 1E9 / 1E5.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E4, or 10,000."
            }
        },
        {
            "question": "What is 1,000,000,000 divided by 10,000?",
            "writeInAnswer": 100000,
            "furtherInfo": {
                "explanation": "What is 1,000,000,000 divided by 10,000?<br /><br />Think of it as: 1E9 / 1E4.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E5, or 100,000."
            }
        },
        {
            "question": "What is 300,000,000 divided by 100?",
            "writeInAnswer": 3000000,
            "furtherInfo": {
                "explanation": "What is 300,000,000 divided by 100?<br /><br />Think of it as: 3E8 / 1E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E6, or 3,000,000."
            }
        },
        {
            "question": "What is 100 divided by 30?",
            "writeInAnswer": 3,
            "furtherInfo": {
                "explanation": "What is 100 divided by 30?<br /><br />Think of it as: 1E2 / 3E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E0, or 3."
            }
        },
        {
            "question": "What is 3,000,000,000 divided by 1,000,000?",
            "writeInAnswer": 3000,
            "furtherInfo": {
                "explanation": "What is 3,000,000,000 divided by 1,000,000?<br /><br />Think of it as: 3E9 / 1E6.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E3, or 3,000."
            }
        },
        {
            "question": "What is 100 divided by 30?",
            "writeInAnswer": 3,
            "furtherInfo": {
                "explanation": "What is 100 divided by 30?<br /><br />Think of it as: 1E2 / 3E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E0, or 3."
            }
        },
        {
            "question": "What is 3,000,000 divided by 1,000?",
            "writeInAnswer": 3000,
            "furtherInfo": {
                "explanation": "What is 3,000,000 divided by 1,000?<br /><br />Think of it as: 3E6 / 1E3.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E3, or 3,000."
            }
        },
        {
            "question": "What is 10,000,000,000 divided by 10?",
            "writeInAnswer": 1000000000,
            "furtherInfo": {
                "explanation": "What is 10,000,000,000 divided by 10?<br /><br />Think of it as: 1E10 / 1E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E9, or 1,000,000,000."
            }
        },
        {
            "question": "What is 30,000,000,000 divided by 300?",
            "writeInAnswer": 100000000,
            "furtherInfo": {
                "explanation": "What is 30,000,000,000 divided by 300?<br /><br />Think of it as: 3E10 / 3E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E8, or 100,000,000."
            }
        },
        {
            "question": "What is 100 divided by 3?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is 100 divided by 3?<br /><br />Think of it as: 1E2 / 3E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E1, or 30."
            }
        },
        {
            "question": "What is 10,000,000,000,000 divided by 10,000,000?",
            "writeInAnswer": 1000000,
            "furtherInfo": {
                "explanation": "What is 10,000,000,000,000 divided by 10,000,000?<br /><br />Think of it as: 1E13 / 1E7.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E6, or 1,000,000."
            }
        },
        {
            "question": "What is 300,000,000 divided by 30?",
            "writeInAnswer": 10000000,
            "furtherInfo": {
                "explanation": "What is 300,000,000 divided by 30?<br /><br />Think of it as: 3E8 / 3E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E7, or 10,000,000."
            }
        },
        {
            "question": "What is 300 divided by 3?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is 300 divided by 3?<br /><br />Think of it as: 3E2 / 3E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E2, or 100."
            }
        },
        {
            "question": "What is 1,000,000,000 divided by 30,000?",
            "writeInAnswer": 30000,
            "furtherInfo": {
                "explanation": "What is 1,000,000,000 divided by 30,000?<br /><br />Think of it as: 1E9 / 3E4.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E4, or 30,000."
            }
        },
        {
            "question": "What is 3,000 divided by 3?",
            "writeInAnswer": 1000,
            "furtherInfo": {
                "explanation": "What is 3,000 divided by 3?<br /><br />Think of it as: 3E3 / 3E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E3, or 1,000."
            }
        },
        {
            "question": "What is 300,000 divided by 1,000?",
            "writeInAnswer": 300,
            "furtherInfo": {
                "explanation": "What is 300,000 divided by 1,000?<br /><br />Think of it as: 3E5 / 1E3.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E2, or 300."
            }
        },
        {
            "question": "What is 3,000,000,000,000 divided by 3,000,000,000?",
            "writeInAnswer": 1000,
            "furtherInfo": {
                "explanation": "What is 3,000,000,000,000 divided by 3,000,000,000?<br /><br />Think of it as: 3E12 / 3E9.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E3, or 1,000."
            }
        },
        {
            "question": "What is 10,000,000 divided by 30?",
            "writeInAnswer": 300000,
            "furtherInfo": {
                "explanation": "What is 10,000,000 divided by 30?<br /><br />Think of it as: 1E7 / 3E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E5, or 300,000."
            }
        },
        {
            "question": "What is 10,000,000,000 divided by 1,000,000,000?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is 10,000,000,000 divided by 1,000,000,000?<br /><br />Think of it as: 1E10 / 1E9.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E1, or 10."
            }
        },
        {
            "question": "What is 1,000,000,000 divided by 10?",
            "writeInAnswer": 100000000,
            "furtherInfo": {
                "explanation": "What is 1,000,000,000 divided by 10?<br /><br />Think of it as: 1E9 / 1E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E8, or 100,000,000."
            }
        },
        {
            "question": "What is 3,000,000 divided by 3,000?",
            "writeInAnswer": 1000,
            "furtherInfo": {
                "explanation": "What is 3,000,000 divided by 3,000?<br /><br />Think of it as: 3E6 / 3E3.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E3, or 1,000."
            }
        },
        {
            "question": "What is 3,000,000,000,000 divided by 100,000?",
            "writeInAnswer": 30000000,
            "furtherInfo": {
                "explanation": "What is 3,000,000,000,000 divided by 100,000?<br /><br />Think of it as: 3E12 / 1E5.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E7, or 30,000,000."
            }
        },
        {
            "question": "What is 100,000,000 divided by 1,000,000?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is 100,000,000 divided by 1,000,000?<br /><br />Think of it as: 1E8 / 1E6.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E2, or 100."
            }
        },
        {
            "question": "What is 300,000,000,000 divided by 1,000,000,000?",
            "writeInAnswer": 300,
            "furtherInfo": {
                "explanation": "What is 300,000,000,000 divided by 1,000,000,000?<br /><br />Think of it as: 3E11 / 1E9.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E2, or 300."
            }
        },
        {
            "question": "What is 30,000,000 divided by 100?",
            "writeInAnswer": 300000,
            "furtherInfo": {
                "explanation": "What is 30,000,000 divided by 100?<br /><br />Think of it as: 3E7 / 1E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E5, or 300,000."
            }
        },
        {
            "question": "What is 1,000 divided by 1?",
            "writeInAnswer": 1000,
            "furtherInfo": {
                "explanation": "What is 1,000 divided by 1?<br /><br />Think of it as: 1E3 / 1E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E3, or 1,000."
            }
        },
        {
            "question": "What is 3,000,000 divided by 100?",
            "writeInAnswer": 30000,
            "furtherInfo": {
                "explanation": "What is 3,000,000 divided by 100?<br /><br />Think of it as: 3E6 / 1E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E4, or 30,000."
            }
        },
        {
            "question": "What is 30 divided by 1?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is 30 divided by 1?<br /><br />Think of it as: 3E1 / 1E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E1, or 30."
            }
        },
        {
            "question": "What is 30,000,000 divided by 300?",
            "writeInAnswer": 100000,
            "furtherInfo": {
                "explanation": "What is 30,000,000 divided by 300?<br /><br />Think of it as: 3E7 / 3E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E5, or 100,000."
            }
        },
        {
            "question": "What is 100,000,000,000 divided by 3,000,000?",
            "writeInAnswer": 30000,
            "furtherInfo": {
                "explanation": "What is 100,000,000,000 divided by 3,000,000?<br /><br />Think of it as: 1E11 / 3E6.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E4, or 30,000."
            }
        },
        {
            "question": "What is 300 divided by 1?",
            "writeInAnswer": 300,
            "furtherInfo": {
                "explanation": "What is 300 divided by 1?<br /><br />Think of it as: 3E2 / 1E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E2, or 300."
            }
        },
        {
            "question": "What is 100 divided by 3?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is 100 divided by 3?<br /><br />Think of it as: 1E2 / 3E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E1, or 30."
            }
        },
        {
            "question": "What is 3,000,000,000 divided by 300,000?",
            "writeInAnswer": 10000,
            "furtherInfo": {
                "explanation": "What is 3,000,000,000 divided by 300,000?<br /><br />Think of it as: 3E9 / 3E5.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E4, or 10,000."
            }
        },
        {
            "question": "What is 1,000,000 divided by 3?",
            "writeInAnswer": 300000,
            "furtherInfo": {
                "explanation": "What is 1,000,000 divided by 3?<br /><br />Think of it as: 1E6 / 3E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E5, or 300,000."
            }
        },
        {
            "question": "What is 10,000 divided by 100?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is 10,000 divided by 100?<br /><br />Think of it as: 1E4 / 1E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E2, or 100."
            }
        },
        {
            "question": "What is 100,000,000,000 divided by 1,000,000?",
            "writeInAnswer": 100000,
            "furtherInfo": {
                "explanation": "What is 100,000,000,000 divided by 1,000,000?<br /><br />Think of it as: 1E11 / 1E6.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E5, or 100,000."
            }
        },
        {
            "question": "What is 10,000,000,000,000 divided by 100?",
            "writeInAnswer": 100000000000,
            "furtherInfo": {
                "explanation": "What is 10,000,000,000,000 divided by 100?<br /><br />Think of it as: 1E13 / 1E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E11, or 100,000,000,000."
            }
        },
        {
            "question": "What is 30,000,000,000 divided by 1,000?",
            "writeInAnswer": 30000000,
            "furtherInfo": {
                "explanation": "What is 30,000,000,000 divided by 1,000?<br /><br />Think of it as: 3E10 / 1E3.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E7, or 30,000,000."
            }
        },
        {
            "question": "What is 3,000,000,000 divided by 3,000,000?",
            "writeInAnswer": 1000,
            "furtherInfo": {
                "explanation": "What is 3,000,000,000 divided by 3,000,000?<br /><br />Think of it as: 3E9 / 3E6.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E3, or 1,000."
            }
        },
        {
            "question": "What is 100 divided by 30?",
            "writeInAnswer": 3,
            "furtherInfo": {
                "explanation": "What is 100 divided by 30?<br /><br />Think of it as: 1E2 / 3E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E0, or 3."
            }
        },
        {
            "question": "What is 300 divided by 10?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is 300 divided by 10?<br /><br />Think of it as: 3E2 / 1E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E1, or 30."
            }
        },
        {
            "question": "What is 10,000,000,000 divided by 30,000?",
            "writeInAnswer": 300000,
            "furtherInfo": {
                "explanation": "What is 10,000,000,000 divided by 30,000?<br /><br />Think of it as: 1E10 / 3E4.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E5, or 300,000."
            }
        },
        {
            "question": "What is 30,000 divided by 10?",
            "writeInAnswer": 3000,
            "furtherInfo": {
                "explanation": "What is 30,000 divided by 10?<br /><br />Think of it as: 3E4 / 1E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E3, or 3,000."
            }
        },
        {
            "question": "What is 10 divided by 1?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is 10 divided by 1?<br /><br />Think of it as: 1E1 / 1E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E1, or 10."
            }
        },
        {
            "question": "What is 10,000,000,000 divided by 100?",
            "writeInAnswer": 100000000,
            "furtherInfo": {
                "explanation": "What is 10,000,000,000 divided by 100?<br /><br />Think of it as: 1E10 / 1E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E8, or 100,000,000."
            }
        },
        {
            "question": "What is 100 divided by 10?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is 100 divided by 10?<br /><br />Think of it as: 1E2 / 1E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E1, or 10."
            }
        },
        {
            "question": "What is 100,000 divided by 100?",
            "writeInAnswer": 1000,
            "furtherInfo": {
                "explanation": "What is 100,000 divided by 100?<br /><br />Think of it as: 1E5 / 1E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E3, or 1,000."
            }
        },
        {
            "question": "What is 30,000,000,000 divided by 3?",
            "writeInAnswer": 10000000000,
            "furtherInfo": {
                "explanation": "What is 30,000,000,000 divided by 3?<br /><br />Think of it as: 3E10 / 3E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E10, or 10,000,000,000."
            }
        },
        {
            "question": "What is 1,000,000,000,000 divided by 30,000,000?",
            "writeInAnswer": 30000,
            "furtherInfo": {
                "explanation": "What is 1,000,000,000,000 divided by 30,000,000?<br /><br />Think of it as: 1E12 / 3E7.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E4, or 30,000."
            }
        },
        {
            "question": "What is 10,000,000 divided by 1,000?",
            "writeInAnswer": 10000,
            "furtherInfo": {
                "explanation": "What is 10,000,000 divided by 1,000?<br /><br />Think of it as: 1E7 / 1E3.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E4, or 10,000."
            }
        },
        {
            "question": "What is 10,000,000,000 divided by 300,000,000?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is 10,000,000,000 divided by 300,000,000?<br /><br />Think of it as: 1E10 / 3E8.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E1, or 30."
            }
        },
        {
            "question": "What is 1,000,000,000 divided by 3?",
            "writeInAnswer": 300000000,
            "furtherInfo": {
                "explanation": "What is 1,000,000,000 divided by 3?<br /><br />Think of it as: 1E9 / 3E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E8, or 300,000,000."
            }
        },
        {
            "question": "What is 300,000,000 divided by 300?",
            "writeInAnswer": 1000000,
            "furtherInfo": {
                "explanation": "What is 300,000,000 divided by 300?<br /><br />Think of it as: 3E8 / 3E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E6, or 1,000,000."
            }
        },
        {
            "question": "What is 30,000,000,000 divided by 300?",
            "writeInAnswer": 100000000,
            "furtherInfo": {
                "explanation": "What is 30,000,000,000 divided by 300?<br /><br />Think of it as: 3E10 / 3E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E8, or 100,000,000."
            }
        },
        {
            "question": "What is 100,000 divided by 30,000?",
            "writeInAnswer": 3,
            "furtherInfo": {
                "explanation": "What is 100,000 divided by 30,000?<br /><br />Think of it as: 1E5 / 3E4.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E0, or 3."
            }
        },
        {
            "question": "What is 300,000 divided by 300?",
            "writeInAnswer": 1000,
            "furtherInfo": {
                "explanation": "What is 300,000 divided by 300?<br /><br />Think of it as: 3E5 / 3E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E3, or 1,000."
            }
        },
        {
            "question": "What is 30,000,000,000,000 divided by 300?",
            "writeInAnswer": 100000000000,
            "furtherInfo": {
                "explanation": "What is 30,000,000,000,000 divided by 300?<br /><br />Think of it as: 3E13 / 3E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E11, or 100,000,000,000."
            }
        },
        {
            "question": "What is 10,000,000 divided by 3?",
            "writeInAnswer": 3000000,
            "furtherInfo": {
                "explanation": "What is 10,000,000 divided by 3?<br /><br />Think of it as: 1E7 / 3E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E6, or 3,000,000."
            }
        },
        {
            "question": "What is 100,000,000 divided by 3,000?",
            "writeInAnswer": 30000,
            "furtherInfo": {
                "explanation": "What is 100,000,000 divided by 3,000?<br /><br />Think of it as: 1E8 / 3E3.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E4, or 30,000."
            }
        },
        {
            "question": "What is 1,000,000 divided by 3,000?",
            "writeInAnswer": 300,
            "furtherInfo": {
                "explanation": "What is 1,000,000 divided by 3,000?<br /><br />Think of it as: 1E6 / 3E3.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E2, or 300."
            }
        },
        {
            "question": "What is 10,000,000,000 divided by 1,000?",
            "writeInAnswer": 10000000,
            "furtherInfo": {
                "explanation": "What is 10,000,000,000 divided by 1,000?<br /><br />Think of it as: 1E10 / 1E3.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E7, or 10,000,000."
            }
        },
        {
            "question": "What is 10,000,000 divided by 100?",
            "writeInAnswer": 100000,
            "furtherInfo": {
                "explanation": "What is 10,000,000 divided by 100?<br /><br />Think of it as: 1E7 / 1E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E5, or 100,000."
            }
        },
        {
            "question": "What is 100,000 divided by 100?",
            "writeInAnswer": 1000,
            "furtherInfo": {
                "explanation": "What is 100,000 divided by 100?<br /><br />Think of it as: 1E5 / 1E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E3, or 1,000."
            }
        },
        {
            "question": "What is 30,000,000 divided by 100?",
            "writeInAnswer": 300000,
            "furtherInfo": {
                "explanation": "What is 30,000,000 divided by 100?<br /><br />Think of it as: 3E7 / 1E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E5, or 300,000."
            }
        },
        {
            "question": "What is 100,000 divided by 10,000?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is 100,000 divided by 10,000?<br /><br />Think of it as: 1E5 / 1E4.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E1, or 10."
            }
        },
        {
            "question": "What is 30,000,000 divided by 3,000,000?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is 30,000,000 divided by 3,000,000?<br /><br />Think of it as: 3E7 / 3E6.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E1, or 10."
            }
        },
        {
            "question": "What is 100,000 divided by 10,000?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is 100,000 divided by 10,000?<br /><br />Think of it as: 1E5 / 1E4.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E1, or 10."
            }
        },
        {
            "question": "What is 10,000,000,000 divided by 300?",
            "writeInAnswer": 30000000,
            "furtherInfo": {
                "explanation": "What is 10,000,000,000 divided by 300?<br /><br />Think of it as: 1E10 / 3E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E7, or 30,000,000."
            }
        },
        {
            "question": "What is 3,000,000,000,000 divided by 100,000,000?",
            "writeInAnswer": 30000,
            "furtherInfo": {
                "explanation": "What is 3,000,000,000,000 divided by 100,000,000?<br /><br />Think of it as: 3E12 / 1E8.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E4, or 30,000."
            }
        },
        {
            "question": "What is 10,000 divided by 100?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is 10,000 divided by 100?<br /><br />Think of it as: 1E4 / 1E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E2, or 100."
            }
        },
        {
            "question": "What is 30,000,000 divided by 100?",
            "writeInAnswer": 300000,
            "furtherInfo": {
                "explanation": "What is 30,000,000 divided by 100?<br /><br />Think of it as: 3E7 / 1E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E5, or 300,000."
            }
        },
        {
            "question": "What is 1,000,000 divided by 300?",
            "writeInAnswer": 3000,
            "furtherInfo": {
                "explanation": "What is 1,000,000 divided by 300?<br /><br />Think of it as: 1E6 / 3E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E3, or 3,000."
            }
        },
        {
            "question": "What is 300,000,000 divided by 100,000?",
            "writeInAnswer": 3000,
            "furtherInfo": {
                "explanation": "What is 300,000,000 divided by 100,000?<br /><br />Think of it as: 3E8 / 1E5.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E3, or 3,000."
            }
        },
        {
            "question": "What is 100,000 divided by 3,000?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is 100,000 divided by 3,000?<br /><br />Think of it as: 1E5 / 3E3.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E1, or 30."
            }
        },
        {
            "question": "What is 3,000,000,000,000 divided by 30,000,000,000?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is 3,000,000,000,000 divided by 30,000,000,000?<br /><br />Think of it as: 3E12 / 3E10.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E2, or 100."
            }
        },
        {
            "question": "What is 100,000,000,000 divided by 3,000,000,000?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is 100,000,000,000 divided by 3,000,000,000?<br /><br />Think of it as: 1E11 / 3E9.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E1, or 30."
            }
        },
        {
            "question": "What is 300,000 divided by 1,000?",
            "writeInAnswer": 300,
            "furtherInfo": {
                "explanation": "What is 300,000 divided by 1,000?<br /><br />Think of it as: 3E5 / 1E3.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E2, or 300."
            }
        },
        {
            "question": "What is 30,000,000,000,000 divided by 300,000?",
            "writeInAnswer": 100000000,
            "furtherInfo": {
                "explanation": "What is 30,000,000,000,000 divided by 300,000?<br /><br />Think of it as: 3E13 / 3E5.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E8, or 100,000,000."
            }
        },
        {
            "question": "What is 10 divided by 3?",
            "writeInAnswer": 3,
            "furtherInfo": {
                "explanation": "What is 10 divided by 3?<br /><br />Think of it as: 1E1 / 3E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E0, or 3."
            }
        },
        {
            "question": "What is 10,000,000,000,000 divided by 10,000,000?",
            "writeInAnswer": 1000000,
            "furtherInfo": {
                "explanation": "What is 10,000,000,000,000 divided by 10,000,000?<br /><br />Think of it as: 1E13 / 1E7.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E6, or 1,000,000."
            }
        },
        {
            "question": "What is 3,000 divided by 100?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is 3,000 divided by 100?<br /><br />Think of it as: 3E3 / 1E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E1, or 30."
            }
        },
        {
            "question": "What is 1,000 divided by 100?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is 1,000 divided by 100?<br /><br />Think of it as: 1E3 / 1E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E1, or 10."
            }
        },
        {
            "question": "What is 30,000,000,000 divided by 3?",
            "writeInAnswer": 10000000000,
            "furtherInfo": {
                "explanation": "What is 30,000,000,000 divided by 3?<br /><br />Think of it as: 3E10 / 3E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E10, or 10,000,000,000."
            }
        },
        {
            "question": "What is 1,000,000 divided by 10,000?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is 1,000,000 divided by 10,000?<br /><br />Think of it as: 1E6 / 1E4.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E2, or 100."
            }
        },
        {
            "question": "What is 1,000,000,000 divided by 300,000,000?",
            "writeInAnswer": 3,
            "furtherInfo": {
                "explanation": "What is 1,000,000,000 divided by 300,000,000?<br /><br />Think of it as: 1E9 / 3E8.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E0, or 3."
            }
        },
        {
            "question": "What is 300,000,000,000 divided by 100,000?",
            "writeInAnswer": 3000000,
            "furtherInfo": {
                "explanation": "What is 300,000,000,000 divided by 100,000?<br /><br />Think of it as: 3E11 / 1E5.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E6, or 3,000,000."
            }
        },
        {
            "question": "What is 300,000,000,000 divided by 30,000,000,000?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is 300,000,000,000 divided by 30,000,000,000?<br /><br />Think of it as: 3E11 / 3E10.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E1, or 10."
            }
        },
        {
            "question": "What is 30,000,000 divided by 100?",
            "writeInAnswer": 300000,
            "furtherInfo": {
                "explanation": "What is 30,000,000 divided by 100?<br /><br />Think of it as: 3E7 / 1E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E5, or 300,000."
            }
        },
        {
            "question": "What is 30,000 divided by 100?",
            "writeInAnswer": 300,
            "furtherInfo": {
                "explanation": "What is 30,000 divided by 100?<br /><br />Think of it as: 3E4 / 1E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E2, or 300."
            }
        },
        {
            "question": "What is 300,000,000,000 divided by 1,000,000?",
            "writeInAnswer": 300000,
            "furtherInfo": {
                "explanation": "What is 300,000,000,000 divided by 1,000,000?<br /><br />Think of it as: 3E11 / 1E6.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E5, or 300,000."
            }
        },
        {
            "question": "What is 30,000,000,000,000 divided by 1,000,000,000?",
            "writeInAnswer": 30000,
            "furtherInfo": {
                "explanation": "What is 30,000,000,000,000 divided by 1,000,000,000?<br /><br />Think of it as: 3E13 / 1E9.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E4, or 30,000."
            }
        },
        {
            "question": "What is 1,000,000,000 divided by 10,000?",
            "writeInAnswer": 100000,
            "furtherInfo": {
                "explanation": "What is 1,000,000,000 divided by 10,000?<br /><br />Think of it as: 1E9 / 1E4.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E5, or 100,000."
            }
        },
        {
            "question": "What is 3,000,000 divided by 30,000?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is 3,000,000 divided by 30,000?<br /><br />Think of it as: 3E6 / 3E4.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E2, or 100."
            }
        },
        {
            "question": "What is 300,000 divided by 10,000?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is 300,000 divided by 10,000?<br /><br />Think of it as: 3E5 / 1E4.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E1, or 30."
            }
        },
        {
            "question": "What is 30,000 divided by 30?",
            "writeInAnswer": 1000,
            "furtherInfo": {
                "explanation": "What is 30,000 divided by 30?<br /><br />Think of it as: 3E4 / 3E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E3, or 1,000."
            }
        },
        {
            "question": "What is 10,000,000,000 divided by 10?",
            "writeInAnswer": 1000000000,
            "furtherInfo": {
                "explanation": "What is 10,000,000,000 divided by 10?<br /><br />Think of it as: 1E10 / 1E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E9, or 1,000,000,000."
            }
        },
        {
            "question": "What is 10 divided by 3?",
            "writeInAnswer": 3,
            "furtherInfo": {
                "explanation": "What is 10 divided by 3?<br /><br />Think of it as: 1E1 / 3E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E0, or 3."
            }
        },
        {
            "question": "What is 300,000,000,000 divided by 1,000,000,000?",
            "writeInAnswer": 300,
            "furtherInfo": {
                "explanation": "What is 300,000,000,000 divided by 1,000,000,000?<br /><br />Think of it as: 3E11 / 1E9.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E2, or 300."
            }
        },
        {
            "question": "What is 100 divided by 3?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is 100 divided by 3?<br /><br />Think of it as: 1E2 / 3E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E1, or 30."
            }
        },
        {
            "question": "What is 3,000 divided by 3?",
            "writeInAnswer": 1000,
            "furtherInfo": {
                "explanation": "What is 3,000 divided by 3?<br /><br />Think of it as: 3E3 / 3E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E3, or 1,000."
            }
        },
        {
            "question": "What is 300 divided by 30?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is 300 divided by 30?<br /><br />Think of it as: 3E2 / 3E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E1, or 10."
            }
        },
        {
            "question": "What is 3,000,000,000 divided by 10,000?",
            "writeInAnswer": 300000,
            "furtherInfo": {
                "explanation": "What is 3,000,000,000 divided by 10,000?<br /><br />Think of it as: 3E9 / 1E4.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E5, or 300,000."
            }
        },
        {
            "question": "What is 30,000,000 divided by 10,000?",
            "writeInAnswer": 3000,
            "furtherInfo": {
                "explanation": "What is 30,000,000 divided by 10,000?<br /><br />Think of it as: 3E7 / 1E4.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E3, or 3,000."
            }
        },
        {
            "question": "What is 100 divided by 3?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is 100 divided by 3?<br /><br />Think of it as: 1E2 / 3E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E1, or 30."
            }
        },
        {
            "question": "What is 30 divided by 3?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is 30 divided by 3?<br /><br />Think of it as: 3E1 / 3E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E1, or 10."
            }
        },
        {
            "question": "What is 30,000,000,000,000 divided by 1,000,000?",
            "writeInAnswer": 30000000,
            "furtherInfo": {
                "explanation": "What is 30,000,000,000,000 divided by 1,000,000?<br /><br />Think of it as: 3E13 / 1E6.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E7, or 30,000,000."
            }
        },
        {
            "question": "What is 10,000 divided by 1?",
            "writeInAnswer": 10000,
            "furtherInfo": {
                "explanation": "What is 10,000 divided by 1?<br /><br />Think of it as: 1E4 / 1E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E4, or 10,000."
            }
        },
        {
            "question": "What is 100,000,000,000 divided by 100?",
            "writeInAnswer": 1000000000,
            "furtherInfo": {
                "explanation": "What is 100,000,000,000 divided by 100?<br /><br />Think of it as: 1E11 / 1E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E9, or 1,000,000,000."
            }
        },
        {
            "question": "What is 3,000,000,000 divided by 1?",
            "writeInAnswer": 3000000000,
            "furtherInfo": {
                "explanation": "What is 3,000,000,000 divided by 1?<br /><br />Think of it as: 3E9 / 1E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E9, or 3,000,000,000."
            }
        },
        {
            "question": "What is 10,000,000,000 divided by 300,000,000?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is 10,000,000,000 divided by 300,000,000?<br /><br />Think of it as: 1E10 / 3E8.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E1, or 30."
            }
        },
        {
            "question": "What is 1,000,000,000 divided by 3,000?",
            "writeInAnswer": 300000,
            "furtherInfo": {
                "explanation": "What is 1,000,000,000 divided by 3,000?<br /><br />Think of it as: 1E9 / 3E3.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E5, or 300,000."
            }
        },
        {
            "question": "What is 1,000,000 divided by 1?",
            "writeInAnswer": 1000000,
            "furtherInfo": {
                "explanation": "What is 1,000,000 divided by 1?<br /><br />Think of it as: 1E6 / 1E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E6, or 1,000,000."
            }
        },
        {
            "question": "What is 3,000,000 divided by 1?",
            "writeInAnswer": 3000000,
            "furtherInfo": {
                "explanation": "What is 3,000,000 divided by 1?<br /><br />Think of it as: 3E6 / 1E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E6, or 3,000,000."
            }
        },
        {
            "question": "What is 100 divided by 30?",
            "writeInAnswer": 3,
            "furtherInfo": {
                "explanation": "What is 100 divided by 30?<br /><br />Think of it as: 1E2 / 3E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E0, or 3."
            }
        },
        {
            "question": "What is 100 divided by 3?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is 100 divided by 3?<br /><br />Think of it as: 1E2 / 3E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E1, or 30."
            }
        },
        {
            "question": "What is 1,000,000,000 divided by 30,000?",
            "writeInAnswer": 30000,
            "furtherInfo": {
                "explanation": "What is 1,000,000,000 divided by 30,000?<br /><br />Think of it as: 1E9 / 3E4.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E4, or 30,000."
            }
        },
        {
            "question": "What is 3,000 divided by 1?",
            "writeInAnswer": 3000,
            "furtherInfo": {
                "explanation": "What is 3,000 divided by 1?<br /><br />Think of it as: 3E3 / 1E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E3, or 3,000."
            }
        },
        {
            "question": "What is 300,000,000,000 divided by 3,000,000?",
            "writeInAnswer": 100000,
            "furtherInfo": {
                "explanation": "What is 300,000,000,000 divided by 3,000,000?<br /><br />Think of it as: 3E11 / 3E6.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E5, or 100,000."
            }
        },
        {
            "question": "What is 10,000 divided by 10?",
            "writeInAnswer": 1000,
            "furtherInfo": {
                "explanation": "What is 10,000 divided by 10?<br /><br />Think of it as: 1E4 / 1E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E3, or 1,000."
            }
        },
        {
            "question": "What is 10,000,000,000,000 divided by 100,000,000?",
            "writeInAnswer": 100000,
            "furtherInfo": {
                "explanation": "What is 10,000,000,000,000 divided by 100,000,000?<br /><br />Think of it as: 1E13 / 1E8.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E5, or 100,000."
            }
        },
        {
            "question": "What is 300,000,000,000 divided by 10,000,000?",
            "writeInAnswer": 30000,
            "furtherInfo": {
                "explanation": "What is 300,000,000,000 divided by 10,000,000?<br /><br />Think of it as: 3E11 / 1E7.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E4, or 30,000."
            }
        },
        {
            "question": "What is 10,000,000 divided by 3?",
            "writeInAnswer": 3000000,
            "furtherInfo": {
                "explanation": "What is 10,000,000 divided by 3?<br /><br />Think of it as: 1E7 / 3E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E6, or 3,000,000."
            }
        },
        {
            "question": "What is 30,000,000 divided by 3?",
            "writeInAnswer": 10000000,
            "furtherInfo": {
                "explanation": "What is 30,000,000 divided by 3?<br /><br />Think of it as: 3E7 / 3E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E7, or 10,000,000."
            }
        },
        {
            "question": "What is 300,000,000 divided by 300,000?",
            "writeInAnswer": 1000,
            "furtherInfo": {
                "explanation": "What is 300,000,000 divided by 300,000?<br /><br />Think of it as: 3E8 / 3E5.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E3, or 1,000."
            }
        },
        {
            "question": "What is 1,000,000,000,000 divided by 300,000?",
            "writeInAnswer": 3000000,
            "furtherInfo": {
                "explanation": "What is 1,000,000,000,000 divided by 300,000?<br /><br />Think of it as: 1E12 / 3E5.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E6, or 3,000,000."
            }
        },
        {
            "question": "What is 1,000,000,000 divided by 3,000?",
            "writeInAnswer": 300000,
            "furtherInfo": {
                "explanation": "What is 1,000,000,000 divided by 3,000?<br /><br />Think of it as: 1E9 / 3E3.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E5, or 300,000."
            }
        },
        {
            "question": "What is 100,000,000,000 divided by 30,000,000?",
            "writeInAnswer": 3000,
            "furtherInfo": {
                "explanation": "What is 100,000,000,000 divided by 30,000,000?<br /><br />Think of it as: 1E11 / 3E7.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E3, or 3,000."
            }
        },
        {
            "question": "What is 1,000,000,000,000 divided by 10?",
            "writeInAnswer": 100000000000,
            "furtherInfo": {
                "explanation": "What is 1,000,000,000,000 divided by 10?<br /><br />Think of it as: 1E12 / 1E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E11, or 100,000,000,000."
            }
        },
        {
            "question": "What is 300,000 divided by 1,000?",
            "writeInAnswer": 300,
            "furtherInfo": {
                "explanation": "What is 300,000 divided by 1,000?<br /><br />Think of it as: 3E5 / 1E3.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E2, or 300."
            }
        },
        {
            "question": "What is 300 divided by 3?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is 300 divided by 3?<br /><br />Think of it as: 3E2 / 3E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E2, or 100."
            }
        },
        {
            "question": "What is 3,000,000,000,000 divided by 100,000,000?",
            "writeInAnswer": 30000,
            "furtherInfo": {
                "explanation": "What is 3,000,000,000,000 divided by 100,000,000?<br /><br />Think of it as: 3E12 / 1E8.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E4, or 30,000."
            }
        },
        {
            "question": "What is 300,000 divided by 10,000?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is 300,000 divided by 10,000?<br /><br />Think of it as: 3E5 / 1E4.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E1, or 30."
            }
        },
        {
            "question": "What is 300,000,000,000 divided by 3?",
            "writeInAnswer": 100000000000,
            "furtherInfo": {
                "explanation": "What is 300,000,000,000 divided by 3?<br /><br />Think of it as: 3E11 / 3E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E11, or 100,000,000,000."
            }
        },
        {
            "question": "What is 30,000,000 divided by 3?",
            "writeInAnswer": 10000000,
            "furtherInfo": {
                "explanation": "What is 30,000,000 divided by 3?<br /><br />Think of it as: 3E7 / 3E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E7, or 10,000,000."
            }
        },
        {
            "question": "What is 30,000 divided by 30?",
            "writeInAnswer": 1000,
            "furtherInfo": {
                "explanation": "What is 30,000 divided by 30?<br /><br />Think of it as: 3E4 / 3E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E3, or 1,000."
            }
        },
        {
            "question": "What is 1,000 divided by 1?",
            "writeInAnswer": 1000,
            "furtherInfo": {
                "explanation": "What is 1,000 divided by 1?<br /><br />Think of it as: 1E3 / 1E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E3, or 1,000."
            }
        },
        {
            "question": "What is 30,000 divided by 10?",
            "writeInAnswer": 3000,
            "furtherInfo": {
                "explanation": "What is 30,000 divided by 10?<br /><br />Think of it as: 3E4 / 1E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E3, or 3,000."
            }
        },
        {
            "question": "What is 300 divided by 10?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is 300 divided by 10?<br /><br />Think of it as: 3E2 / 1E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E1, or 30."
            }
        },
        {
            "question": "What is 300,000,000,000 divided by 10,000,000,000?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is 300,000,000,000 divided by 10,000,000,000?<br /><br />Think of it as: 3E11 / 1E10.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E1, or 30."
            }
        },
        {
            "question": "What is 300,000,000,000 divided by 300?",
            "writeInAnswer": 1000000000,
            "furtherInfo": {
                "explanation": "What is 300,000,000,000 divided by 300?<br /><br />Think of it as: 3E11 / 3E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E9, or 1,000,000,000."
            }
        },
        {
            "question": "What is 300,000 divided by 100?",
            "writeInAnswer": 3000,
            "furtherInfo": {
                "explanation": "What is 300,000 divided by 100?<br /><br />Think of it as: 3E5 / 1E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E3, or 3,000."
            }
        },
        {
            "question": "What is 300,000,000,000 divided by 30,000?",
            "writeInAnswer": 10000000,
            "furtherInfo": {
                "explanation": "What is 300,000,000,000 divided by 30,000?<br /><br />Think of it as: 3E11 / 3E4.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E7, or 10,000,000."
            }
        },
        {
            "question": "What is 1,000,000,000,000 divided by 30,000,000,000?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is 1,000,000,000,000 divided by 30,000,000,000?<br /><br />Think of it as: 1E12 / 3E10.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E1, or 30."
            }
        },
        {
            "question": "What is 3,000,000 divided by 10?",
            "writeInAnswer": 300000,
            "furtherInfo": {
                "explanation": "What is 3,000,000 divided by 10?<br /><br />Think of it as: 3E6 / 1E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E5, or 300,000."
            }
        },
        {
            "question": "What is 10,000,000 divided by 300?",
            "writeInAnswer": 30000,
            "furtherInfo": {
                "explanation": "What is 10,000,000 divided by 300?<br /><br />Think of it as: 1E7 / 3E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E4, or 30,000."
            }
        },
        {
            "question": "What is 10,000 divided by 10?",
            "writeInAnswer": 1000,
            "furtherInfo": {
                "explanation": "What is 10,000 divided by 10?<br /><br />Think of it as: 1E4 / 1E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E3, or 1,000."
            }
        },
        {
            "question": "What is 100,000,000,000 divided by 10,000,000?",
            "writeInAnswer": 10000,
            "furtherInfo": {
                "explanation": "What is 100,000,000,000 divided by 10,000,000?<br /><br />Think of it as: 1E11 / 1E7.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E4, or 10,000."
            }
        },
        {
            "question": "What is 30 divided by 3?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is 30 divided by 3?<br /><br />Think of it as: 3E1 / 3E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E1, or 10."
            }
        },
        {
            "question": "What is 30,000 divided by 3,000?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is 30,000 divided by 3,000?<br /><br />Think of it as: 3E4 / 3E3.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E1, or 10."
            }
        },
        {
            "question": "What is 3,000,000,000,000 divided by 1,000?",
            "writeInAnswer": 3000000000,
            "furtherInfo": {
                "explanation": "What is 3,000,000,000,000 divided by 1,000?<br /><br />Think of it as: 3E12 / 1E3.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E9, or 3,000,000,000."
            }
        },
        {
            "question": "What is 1,000,000,000 divided by 30?",
            "writeInAnswer": 30000000,
            "furtherInfo": {
                "explanation": "What is 1,000,000,000 divided by 30?<br /><br />Think of it as: 1E9 / 3E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E7, or 30,000,000."
            }
        },
        {
            "question": "What is 300,000,000,000 divided by 100,000?",
            "writeInAnswer": 3000000,
            "furtherInfo": {
                "explanation": "What is 300,000,000,000 divided by 100,000?<br /><br />Think of it as: 3E11 / 1E5.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E6, or 3,000,000."
            }
        },
        {
            "question": "What is 1,000,000,000 divided by 1,000?",
            "writeInAnswer": 1000000,
            "furtherInfo": {
                "explanation": "What is 1,000,000,000 divided by 1,000?<br /><br />Think of it as: 1E9 / 1E3.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E6, or 1,000,000."
            }
        },
        {
            "question": "What is 1,000,000 divided by 10,000?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is 1,000,000 divided by 10,000?<br /><br />Think of it as: 1E6 / 1E4.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E2, or 100."
            }
        },
        {
            "question": "What is 3,000,000,000,000 divided by 30,000,000?",
            "writeInAnswer": 100000,
            "furtherInfo": {
                "explanation": "What is 3,000,000,000,000 divided by 30,000,000?<br /><br />Think of it as: 3E12 / 3E7.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E5, or 100,000."
            }
        },
        {
            "question": "What is 1,000,000,000,000 divided by 30?",
            "writeInAnswer": 30000000000,
            "furtherInfo": {
                "explanation": "What is 1,000,000,000,000 divided by 30?<br /><br />Think of it as: 1E12 / 3E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E10, or 30,000,000,000."
            }
        },
        {
            "question": "What is 300,000,000,000 divided by 1,000?",
            "writeInAnswer": 300000000,
            "furtherInfo": {
                "explanation": "What is 300,000,000,000 divided by 1,000?<br /><br />Think of it as: 3E11 / 1E3.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E8, or 300,000,000."
            }
        },
        {
            "question": "What is 30 divided by 3?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is 30 divided by 3?<br /><br />Think of it as: 3E1 / 3E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E1, or 10."
            }
        },
        {
            "question": "What is 10,000,000,000 divided by 10,000,000?",
            "writeInAnswer": 1000,
            "furtherInfo": {
                "explanation": "What is 10,000,000,000 divided by 10,000,000?<br /><br />Think of it as: 1E10 / 1E7.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E3, or 1,000."
            }
        },
        {
            "question": "What is 30,000,000,000 divided by 30,000?",
            "writeInAnswer": 1000000,
            "furtherInfo": {
                "explanation": "What is 30,000,000,000 divided by 30,000?<br /><br />Think of it as: 3E10 / 3E4.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E6, or 1,000,000."
            }
        },
        {
            "question": "What is 100,000,000,000 divided by 10,000,000?",
            "writeInAnswer": 10000,
            "furtherInfo": {
                "explanation": "What is 100,000,000,000 divided by 10,000,000?<br /><br />Think of it as: 1E11 / 1E7.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E4, or 10,000."
            }
        },
        {
            "question": "What is 300,000,000 divided by 10,000?",
            "writeInAnswer": 30000,
            "furtherInfo": {
                "explanation": "What is 300,000,000 divided by 10,000?<br /><br />Think of it as: 3E8 / 1E4.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E4, or 30,000."
            }
        },
        {
            "question": "What is 10,000,000 divided by 30?",
            "writeInAnswer": 300000,
            "furtherInfo": {
                "explanation": "What is 10,000,000 divided by 30?<br /><br />Think of it as: 1E7 / 3E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E5, or 300,000."
            }
        },
        {
            "question": "What is 30 divided by 1?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is 30 divided by 1?<br /><br />Think of it as: 3E1 / 1E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E1, or 30."
            }
        },
        {
            "question": "What is 3,000,000 divided by 300,000?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is 3,000,000 divided by 300,000?<br /><br />Think of it as: 3E6 / 3E5.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E1, or 10."
            }
        },
        {
            "question": "What is 300,000,000 divided by 30?",
            "writeInAnswer": 10000000,
            "furtherInfo": {
                "explanation": "What is 300,000,000 divided by 30?<br /><br />Think of it as: 3E8 / 3E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E7, or 10,000,000."
            }
        },
        {
            "question": "What is 1,000 divided by 30?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is 1,000 divided by 30?<br /><br />Think of it as: 1E3 / 3E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E1, or 30."
            }
        },
        {
            "question": "What is 100,000 divided by 10?",
            "writeInAnswer": 10000,
            "furtherInfo": {
                "explanation": "What is 100,000 divided by 10?<br /><br />Think of it as: 1E5 / 1E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E4, or 10,000."
            }
        },
        {
            "question": "What is 10,000,000,000 divided by 100,000,000?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is 10,000,000,000 divided by 100,000,000?<br /><br />Think of it as: 1E10 / 1E8.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E2, or 100."
            }
        },
        {
            "question": "What is 300,000,000 divided by 30?",
            "writeInAnswer": 10000000,
            "furtherInfo": {
                "explanation": "What is 300,000,000 divided by 30?<br /><br />Think of it as: 3E8 / 3E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E7, or 10,000,000."
            }
        },
        {
            "question": "What is 100,000,000,000 divided by 10?",
            "writeInAnswer": 10000000000,
            "furtherInfo": {
                "explanation": "What is 100,000,000,000 divided by 10?<br /><br />Think of it as: 1E11 / 1E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E10, or 10,000,000,000."
            }
        },
        {
            "question": "What is 3,000 divided by 30?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is 3,000 divided by 30?<br /><br />Think of it as: 3E3 / 3E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E2, or 100."
            }
        },
        {
            "question": "What is 30,000 divided by 3,000?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is 30,000 divided by 3,000?<br /><br />Think of it as: 3E4 / 3E3.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E1, or 10."
            }
        },
        {
            "question": "What is 1,000 divided by 100?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is 1,000 divided by 100?<br /><br />Think of it as: 1E3 / 1E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E1, or 10."
            }
        },
        {
            "question": "What is 30 divided by 1?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is 30 divided by 1?<br /><br />Think of it as: 3E1 / 1E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E1, or 30."
            }
        },
        {
            "question": "What is 10,000,000,000,000 divided by 1,000,000?",
            "writeInAnswer": 10000000,
            "furtherInfo": {
                "explanation": "What is 10,000,000,000,000 divided by 1,000,000?<br /><br />Think of it as: 1E13 / 1E6.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E7, or 10,000,000."
            }
        },
        {
            "question": "What is 3,000,000,000 divided by 1,000?",
            "writeInAnswer": 3000000,
            "furtherInfo": {
                "explanation": "What is 3,000,000,000 divided by 1,000?<br /><br />Think of it as: 3E9 / 1E3.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E6, or 3,000,000."
            }
        },
        {
            "question": "What is 30 divided by 3?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is 30 divided by 3?<br /><br />Think of it as: 3E1 / 3E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E1, or 10."
            }
        },
        {
            "question": "What is 1,000,000 divided by 10?",
            "writeInAnswer": 100000,
            "furtherInfo": {
                "explanation": "What is 1,000,000 divided by 10?<br /><br />Think of it as: 1E6 / 1E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E5, or 100,000."
            }
        },
        {
            "question": "What is 1,000,000,000 divided by 1?",
            "writeInAnswer": 1000000000,
            "furtherInfo": {
                "explanation": "What is 1,000,000,000 divided by 1?<br /><br />Think of it as: 1E9 / 1E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E9, or 1,000,000,000."
            }
        },
        {
            "question": "What is 300,000 divided by 100?",
            "writeInAnswer": 3000,
            "furtherInfo": {
                "explanation": "What is 300,000 divided by 100?<br /><br />Think of it as: 3E5 / 1E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E3, or 3,000."
            }
        },
        {
            "question": "What is 100 divided by 3?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is 100 divided by 3?<br /><br />Think of it as: 1E2 / 3E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E1, or 30."
            }
        },
        {
            "question": "What is 10,000,000,000,000 divided by 10,000,000?",
            "writeInAnswer": 1000000,
            "furtherInfo": {
                "explanation": "What is 10,000,000,000,000 divided by 10,000,000?<br /><br />Think of it as: 1E13 / 1E7.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E6, or 1,000,000."
            }
        },
        {
            "question": "What is 30 divided by 3?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is 30 divided by 3?<br /><br />Think of it as: 3E1 / 3E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E1, or 10."
            }
        },
        {
            "question": "What is 10,000 divided by 3?",
            "writeInAnswer": 3000,
            "furtherInfo": {
                "explanation": "What is 10,000 divided by 3?<br /><br />Think of it as: 1E4 / 3E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E3, or 3,000."
            }
        },
        {
            "question": "What is 300,000,000,000 divided by 10,000,000,000?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is 300,000,000,000 divided by 10,000,000,000?<br /><br />Think of it as: 3E11 / 1E10.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E1, or 30."
            }
        },
        {
            "question": "What is 30,000,000,000 divided by 300,000,000?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is 30,000,000,000 divided by 300,000,000?<br /><br />Think of it as: 3E10 / 3E8.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E2, or 100."
            }
        },
        {
            "question": "What is 1,000,000,000,000 divided by 300,000,000?",
            "writeInAnswer": 3000,
            "furtherInfo": {
                "explanation": "What is 1,000,000,000,000 divided by 300,000,000?<br /><br />Think of it as: 1E12 / 3E8.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E3, or 3,000."
            }
        },
        {
            "question": "What is 1,000 divided by 10?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is 1,000 divided by 10?<br /><br />Think of it as: 1E3 / 1E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E2, or 100."
            }
        },
        {
            "question": "What is 10,000,000,000,000 divided by 300,000,000?",
            "writeInAnswer": 30000,
            "furtherInfo": {
                "explanation": "What is 10,000,000,000,000 divided by 300,000,000?<br /><br />Think of it as: 1E13 / 3E8.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E4, or 30,000."
            }
        },
        {
            "question": "What is 3,000,000 divided by 300,000?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is 3,000,000 divided by 300,000?<br /><br />Think of it as: 3E6 / 3E5.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E1, or 10."
            }
        },
        {
            "question": "What is 1,000,000,000,000 divided by 30,000,000,000?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is 1,000,000,000,000 divided by 30,000,000,000?<br /><br />Think of it as: 1E12 / 3E10.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E1, or 30."
            }
        },
        {
            "question": "What is 3,000,000,000,000 divided by 300?",
            "writeInAnswer": 10000000000,
            "furtherInfo": {
                "explanation": "What is 3,000,000,000,000 divided by 300?<br /><br />Think of it as: 3E12 / 3E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E10, or 10,000,000,000."
            }
        },
        {
            "question": "What is 10,000,000,000,000 divided by 30,000?",
            "writeInAnswer": 300000000,
            "furtherInfo": {
                "explanation": "What is 10,000,000,000,000 divided by 30,000?<br /><br />Think of it as: 1E13 / 3E4.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E8, or 300,000,000."
            }
        },
        {
            "question": "What is 100 divided by 30?",
            "writeInAnswer": 3,
            "furtherInfo": {
                "explanation": "What is 100 divided by 30?<br /><br />Think of it as: 1E2 / 3E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E0, or 3."
            }
        },
        {
            "question": "What is 100,000,000 divided by 30,000,000?",
            "writeInAnswer": 3,
            "furtherInfo": {
                "explanation": "What is 100,000,000 divided by 30,000,000?<br /><br />Think of it as: 1E8 / 3E7.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E0, or 3."
            }
        },
        {
            "question": "What is 100,000,000,000 divided by 1,000?",
            "writeInAnswer": 100000000,
            "furtherInfo": {
                "explanation": "What is 100,000,000,000 divided by 1,000?<br /><br />Think of it as: 1E11 / 1E3.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E8, or 100,000,000."
            }
        },
        {
            "question": "What is 10 divided by 1?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is 10 divided by 1?<br /><br />Think of it as: 1E1 / 1E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E1, or 10."
            }
        },
        {
            "question": "What is 300,000,000 divided by 300?",
            "writeInAnswer": 1000000,
            "furtherInfo": {
                "explanation": "What is 300,000,000 divided by 300?<br /><br />Think of it as: 3E8 / 3E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E6, or 1,000,000."
            }
        },
        {
            "question": "What is 1,000 divided by 10?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is 1,000 divided by 10?<br /><br />Think of it as: 1E3 / 1E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E2, or 100."
            }
        },
        {
            "question": "What is 100,000,000 divided by 3,000,000?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is 100,000,000 divided by 3,000,000?<br /><br />Think of it as: 1E8 / 3E6.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E1, or 30."
            }
        },
        {
            "question": "What is 30 divided by 3?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is 30 divided by 3?<br /><br />Think of it as: 3E1 / 3E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E1, or 10."
            }
        },
        {
            "question": "What is 1,000 divided by 10?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is 1,000 divided by 10?<br /><br />Think of it as: 1E3 / 1E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E2, or 100."
            }
        },
        {
            "question": "What is 1,000,000,000,000 divided by 10?",
            "writeInAnswer": 100000000000,
            "furtherInfo": {
                "explanation": "What is 1,000,000,000,000 divided by 10?<br /><br />Think of it as: 1E12 / 1E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E11, or 100,000,000,000."
            }
        },
        {
            "question": "What is 300,000 divided by 30,000?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is 300,000 divided by 30,000?<br /><br />Think of it as: 3E5 / 3E4.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E1, or 10."
            }
        },
        {
            "question": "What is 100,000 divided by 1?",
            "writeInAnswer": 100000,
            "furtherInfo": {
                "explanation": "What is 100,000 divided by 1?<br /><br />Think of it as: 1E5 / 1E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E5, or 100,000."
            }
        },
        {
            "question": "What is 1,000,000 divided by 100?",
            "writeInAnswer": 10000,
            "furtherInfo": {
                "explanation": "What is 1,000,000 divided by 100?<br /><br />Think of it as: 1E6 / 1E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E4, or 10,000."
            }
        },
        {
            "question": "What is 300,000 divided by 3,000?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is 300,000 divided by 3,000?<br /><br />Think of it as: 3E5 / 3E3.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E2, or 100."
            }
        },
        {
            "question": "What is 1,000 divided by 300?",
            "writeInAnswer": 3,
            "furtherInfo": {
                "explanation": "What is 1,000 divided by 300?<br /><br />Think of it as: 1E3 / 3E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E0, or 3."
            }
        },
        {
            "question": "What is 10,000,000,000,000 divided by 30?",
            "writeInAnswer": 300000000000,
            "furtherInfo": {
                "explanation": "What is 10,000,000,000,000 divided by 30?<br /><br />Think of it as: 1E13 / 3E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E11, or 300,000,000,000."
            }
        },
        {
            "question": "What is 1,000 divided by 30?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is 1,000 divided by 30?<br /><br />Think of it as: 1E3 / 3E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E1, or 30."
            }
        },
        {
            "question": "What is 3,000 divided by 1?",
            "writeInAnswer": 3000,
            "furtherInfo": {
                "explanation": "What is 3,000 divided by 1?<br /><br />Think of it as: 3E3 / 1E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E3, or 3,000."
            }
        },
        {
            "question": "What is 1,000,000,000,000 divided by 10?",
            "writeInAnswer": 100000000000,
            "furtherInfo": {
                "explanation": "What is 1,000,000,000,000 divided by 10?<br /><br />Think of it as: 1E12 / 1E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E11, or 100,000,000,000."
            }
        },
        {
            "question": "What is 300,000,000 divided by 100,000?",
            "writeInAnswer": 3000,
            "furtherInfo": {
                "explanation": "What is 300,000,000 divided by 100,000?<br /><br />Think of it as: 3E8 / 1E5.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E3, or 3,000."
            }
        },
        {
            "question": "What is 3,000,000 divided by 30,000?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is 3,000,000 divided by 30,000?<br /><br />Think of it as: 3E6 / 3E4.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E2, or 100."
            }
        },
        {
            "question": "What is 30 divided by 3?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is 30 divided by 3?<br /><br />Think of it as: 3E1 / 3E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E1, or 10."
            }
        },
        {
            "question": "What is 1,000 divided by 100?",
            "writeInAnswer": 10,
            "furtherInfo": {
                "explanation": "What is 1,000 divided by 100?<br /><br />Think of it as: 1E3 / 1E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E1, or 10."
            }
        },
        {
            "question": "What is 10,000 divided by 3?",
            "writeInAnswer": 3000,
            "furtherInfo": {
                "explanation": "What is 10,000 divided by 3?<br /><br />Think of it as: 1E4 / 3E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E3, or 3,000."
            }
        },
        {
            "question": "What is 3,000,000,000 divided by 10,000?",
            "writeInAnswer": 300000,
            "furtherInfo": {
                "explanation": "What is 3,000,000,000 divided by 10,000?<br /><br />Think of it as: 3E9 / 1E4.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E5, or 300,000."
            }
        },
        {
            "question": "What is 30,000,000 divided by 10?",
            "writeInAnswer": 3000000,
            "furtherInfo": {
                "explanation": "What is 30,000,000 divided by 10?<br /><br />Think of it as: 3E7 / 1E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E6, or 3,000,000."
            }
        },
        {
            "question": "What is 30,000,000,000,000 divided by 1,000,000,000,000?",
            "writeInAnswer": 30,
            "furtherInfo": {
                "explanation": "What is 30,000,000,000,000 divided by 1,000,000,000,000?<br /><br />Think of it as: 3E13 / 1E12.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E1, or 30."
            }
        },
        {
            "question": "What is 100,000,000,000 divided by 1,000,000?",
            "writeInAnswer": 100000,
            "furtherInfo": {
                "explanation": "What is 100,000,000,000 divided by 1,000,000?<br /><br />Think of it as: 1E11 / 1E6.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E5, or 100,000."
            }
        },
        {
            "question": "What is 10,000,000,000 divided by 30?",
            "writeInAnswer": 300000000,
            "furtherInfo": {
                "explanation": "What is 10,000,000,000 divided by 30?<br /><br />Think of it as: 1E10 / 3E1.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E8, or 300,000,000."
            }
        },
        {
            "question": "What is 3,000,000,000,000 divided by 100,000?",
            "writeInAnswer": 30000000,
            "furtherInfo": {
                "explanation": "What is 3,000,000,000,000 divided by 100,000?<br /><br />Think of it as: 3E12 / 1E5.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E7, or 30,000,000."
            }
        },
        {
            "question": "What is 10,000 divided by 3,000?",
            "writeInAnswer": 3,
            "furtherInfo": {
                "explanation": "What is 10,000 divided by 3,000?<br /><br />Think of it as: 1E4 / 3E3.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E0, or 3."
            }
        },
        {
            "question": "What is 10,000,000,000 divided by 3,000?",
            "writeInAnswer": 3000000,
            "furtherInfo": {
                "explanation": "What is 10,000,000,000 divided by 3,000?<br /><br />Think of it as: 1E10 / 3E3.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E6, or 3,000,000."
            }
        },
        {
            "question": "What is 1,000,000,000,000 divided by 30,000?",
            "writeInAnswer": 30000000,
            "furtherInfo": {
                "explanation": "What is 1,000,000,000,000 divided by 30,000?<br /><br />Think of it as: 1E12 / 3E4.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E7, or 30,000,000."
            }
        },
        {
            "question": "What is 10,000 divided by 100?",
            "writeInAnswer": 100,
            "furtherInfo": {
                "explanation": "What is 10,000 divided by 100?<br /><br />Think of it as: 1E4 / 1E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E2, or 100."
            }
        },
        {
            "question": "What is 3,000,000 divided by 1?",
            "writeInAnswer": 3000000,
            "furtherInfo": {
                "explanation": "What is 3,000,000 divided by 1?<br /><br />Think of it as: 3E6 / 1E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E6, or 3,000,000."
            }
        },
        {
            "question": "What is 10,000,000,000,000 divided by 300,000,000?",
            "writeInAnswer": 30000,
            "furtherInfo": {
                "explanation": "What is 10,000,000,000,000 divided by 300,000,000?<br /><br />Think of it as: 1E13 / 3E8.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E4, or 30,000."
            }
        },
        {
            "question": "What is 300 divided by 1?",
            "writeInAnswer": 300,
            "furtherInfo": {
                "explanation": "What is 300 divided by 1?<br /><br />Think of it as: 3E2 / 1E0.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E2, or 300."
            }
        },
        {
            "question": "What is 30,000,000 divided by 1,000?",
            "writeInAnswer": 30000,
            "furtherInfo": {
                "explanation": "What is 30,000,000 divided by 1,000?<br /><br />Think of it as: 3E7 / 1E3.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E4, or 30,000."
            }
        },
        {
            "question": "What is 30,000,000 divided by 1,000?",
            "writeInAnswer": 30000,
            "furtherInfo": {
                "explanation": "What is 30,000,000 divided by 1,000?<br /><br />Think of it as: 3E7 / 1E3.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 3E4, or 30,000."
            }
        },
        {
            "question": "What is 10,000,000,000 divided by 30,000?",
            "writeInAnswer": 300000,
            "furtherInfo": {
                "explanation": "What is 10,000,000,000 divided by 30,000?<br /><br />Think of it as: 1E10 / 3E4.<br /><br />Divide the numbers, subtract the exponents.<br /><br />That's approximately 3E5, or 300,000."
            }
        },
        {
            "question": "What is 3,000,000,000,000 divided by 30,000,000?",
            "writeInAnswer": 100000,
            "furtherInfo": {
                "explanation": "What is 3,000,000,000,000 divided by 30,000,000?<br /><br />Think of it as: 3E12 / 3E7.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E5, or 100,000."
            }
        },
        {
            "question": "What is 300,000,000,000 divided by 300?",
            "writeInAnswer": 1000000000,
            "furtherInfo": {
                "explanation": "What is 300,000,000,000 divided by 300?<br /><br />Think of it as: 3E11 / 3E2.<br /><br />Divide the numbers, subtract the exponents.<br /><br />The answer is 1E9, or 1,000,000,000."
            }
        }
    ],
    
};

export default wellRoundedDivision1;