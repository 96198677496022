import React, { useState } from "react";
import "../assets/designs/navbar.css";
import {ReactComponent as Ribbon} from "../assets/icons/Quizicist Icons/Ribbon-01.svg";
import useWindowDimensions from "../utils/useWindowDimensions";
import Avatar from "@material-ui/core/Avatar";
import Popover from "@material-ui/core/Popover";
import Logo from "./Logo";
import SoundToggle from "./SoundToggle";
import { Link, useHistory } from "react-router-dom";
import { AuthCheck } from "reactfire";
import { useAuth } from "reactfire";
import BackButton from "./BackButton";
import useMergedUser from "../hooks/useMergedUser";
import LeaveQuizConfirmPopup from "./question/LeaveQuizConfirmPopup";
import useDeviceDetect from "../hooks/useDeviceDetect";

const popOverStyle = {
  width: "100%",
  top: "40px",
};

function NavbarData(props) {
  const auth = useAuth();
  const user = useMergedUser();
  const username = user.firestoreUser ? user.firestoreUser.username : "";
  const photoURL = auth.currentUser ? auth.currentUser.photoURL : "";

  return (
    <Navbar auth={auth} username={username} photoURL={photoURL} {...props} />
  );
}

// NOTE Use when the Navbar component is mounted/rendered a lot causing
// useMergedUser() resulting in Firestore being called every mount and render
// resulting in endless requests to our Firestore database
function NavbarNoUsername(props) {
  const auth = useAuth();
  const photoURL = auth.currentUser ? auth.currentUser.photoURL : "";

  return <Navbar auth={auth} photoURL={photoURL} {...props} />;
}

function Navbar(props) {
  const history = useHistory();
  const { auth, username, photoURL } = props;
  const { width } = useWindowDimensions();
  const {isMobile} = useDeviceDetect()
  // const [visibleFilter, setVisibleFilter] = useState(false);
  const [displayQuizLeavePopup, setDisplayQuizLeavePopup] = useState(false);
  const [displayAccountSetting, setDisplayAccountSetting] = useState(false);

  const goBack = () => {
    if (props.fromPreview) {
      history.push("/quizzes");
    } else {
      history.goBack();
    }
  };

  function signoutUser() {
    auth.signOut().then((res) => props.history.push("/"));
  }

  const userOptions = () => (
    <Popover
      style={popOverStyle}
      open={displayAccountSetting}
      // anchorEl={displayAccountSetting}
      onClose={() => setDisplayAccountSetting(false)}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "bottom",
        horizontal: "bottom",
      }}
    >
      <div className="navbar-signin-options-container">
        <span className="navbar-signin-options-container-username">
          {username}
        </span>
        <Link to="/profile" className="navbar-signin-options-btn">
          Account Settings
        </Link>
        <Link
          to="/"
          onClick={() => signoutUser()}
          className="navbar-signin-options-btn"
        >
          Sign Out
        </Link>
      </div>
    </Popover>
  );

  const muteBtn = (
    <span style={{ marginRight: 10 }}>
      <SoundToggle />
    </span>
  );

  const LoggedOutOptions = () => (
    <div className="nav-menu-options">
      {muteBtn}
      <span>
        <Link
          to={{pathname: '/signup', state: { prevPath: history.location.pathname }}}
          className="nav-text"
          style={{
            textDecoration: "none",
            marginRight: "10px",
          }}
        >
          {" "}
          Create New Account
        </Link>{" "}
        <Link
          to={{pathname: '/signin', state: { prevPath: history.location.pathname }}}
          className="nav-text"
          style={{
            textDecoration: "none",
            paddingLeft: "10px",
            borderLeft: "2px solid #fff",
          }}
        >
          {" "}
          Sign In
        </Link>
      </span>
    </div>
  );

  const avatarBtnOnKeyDown = (e) => {
    const enterBtn = 13;
    if (e.keycode === enterBtn) {
      setDisplayAccountSetting(!displayAccountSetting);
    }
  };

  const LoggedInOptions = () => (
    <AuthCheck fallback={<LoggedOutOptions />}>
      <div className="nav-menu-options">
        <span
          style={{
            display: "flex",
            alignItems: "center",
            position: "relative",
          }}
        >
          {muteBtn}
          <Link to="/my-scores" aria-label="My Scores">
            <Ribbon className="nav-ribbon" />
          </Link>

          <button
            className="navbar-profile-pic"
            aria-label="Account Dropdown"
            onClick={() => setDisplayAccountSetting(!displayAccountSetting)}
            onKeyDown={avatarBtnOnKeyDown}
          >
            <Avatar src={photoURL} alt="Profile Pic" />
            {userOptions()}
          </button>

          {/* {displayAccountSetting && userOptions()} */}
        </span>
      </div>
    </AuthCheck>
  );

  const backArrowOnKeydown = (e) => {
    const enter = 13;
    if (e.keycode === enter) {
      goBack();
    }
  };

  const backBtn = (
    <div className="nav-heading-backarrow">
      <BackButton onClick={() => goBack()} onKeyDown={backArrowOnKeydown} />
    </div>
  );

  return (
    <div className={props.className || "main-nav"}>
      {width >= 1025 ? (
        <div className="nav-menu">
          <span className="nav-heading">
          {props.showArrow && backBtn}
            <button
              onClick={() => {
                if(props.duringQuiz){
                  setDisplayQuizLeavePopup(true)
                 }else{
                   history.push("/quizzes")
                 }
              }}
              className="icon-style"
              aria-label="logo"
              style={{ textDecoration: "none",cursor:"pointer" }}
            >
              <Logo />
            </button>
         
          </span>

          <LoggedInOptions />
        </div>
      ) : (
        <div style={{ position: "relative" }}>
          <div className="nav-menu-smallscreen">
            <span style={{display:"flex",alignItems:"center"}}>
            {props.showArrow && backBtn}
            <button
              onClick={() => {
                if(props.duringQuiz){
                 setDisplayQuizLeavePopup(true)
                }else{
                  history.push("/quizzes")
                }
              }}
              className="icon-style"
              style={{ textDecoration: "none",cursor:"pointer" }}
            >
              <Logo />
            </button>
            </span>
            {!isMobile ?  <LoggedInOptions /> : <SoundToggle />}
            

            {/* Temporarily removed category menu from mobile */}

            {/* {props.filter && (
              <img
                src={Lock}
                alt=""
                height="100px"
                onClick={() => setVisibleFilter(!visibleFilter)}
              />
            )} */}

          {/* Temporarily removed categories from mobile */}

          {/* <FilterCard
            visible={visibleFilter}
            setVisible={setVisibleFilter}
            style={{ left: "none", right: "45px", top: "35px" }}
            categories={[
              "Home",
              "Math",
              "Health & Sciences",
              "Energy",
              "Entertainment",
            ]}
            options={[
              "Most Popular",
              "Highest Score",
              "Newest",
              "Most Challenging",
            ]}
          /> */}
        </div>
        </div>
      )}
      <LeaveQuizConfirmPopup visible={displayQuizLeavePopup} setVisible={setDisplayQuizLeavePopup}/>
    </div>
  );
}

export { NavbarData, NavbarNoUsername };
export default Navbar;
