const pandemicsByNumbers = {
  name: 'Pandemics by Numbers',
  id: "0rtKu1ynPNQv7KiRZdE7",
  linkTo: "/quizzes/pandemicsByNumbers",
  explainerOn: true,
  subheading: `How does COVID-19 stack up to the deadliest plagues in history?`,
  description: `In the ongoing struggle with COVID-19, it is easy to feel isolated, to lose perspective. But we are far from the only generation to be decimated by widespread illness. Here are the numbers on just some of the pandemics that came before COVID-19.`,
  questions: [
      {
          //question 1//
          question: `How many deaths are associated with the Plague of Justinian (541-542 C.E.)?`,
          choices: ['500,000',  '5,000,000', '50,000,000', '500,000,000'],
          answerIdx: 2,    
          furtherInfo: {
              explanation: (
                  `Named for the Byzantine Emperor Justinian, who happened to be in power when it hit, up to 50 million people died of the Plague of Justinian. It is thought to be the world's first episode of bubonic plague.`
              ),
              sources: [
                  {
                      source: 'The Washington Post',
                      url: 'https://www.washingtonpost.com/graphics/2020/local/retropolis/coronavirus-deadliest-pandemics/?itid=ap_michaels.%20rosenwald&itid=lk_interstitial_manual_7&itid=lk_inline_manual_40&itid=lk_inline_manual_28&itid=lk_inline_manual_60&itid=lk_inline_manual_46&itid=lk_inline_manual_36&itid=lk_inline_manual_43',
                  },
              ],
        },
      },
      {
          //question 2//
         question: `Approximately how many died of the Black Death (1347-1352)?`,
          choices: ['200,000', '2,000,000', '20,000,000', '200,000,000'],
          answerIdx: 3,    
          furtherInfo: {
              explanation: (
                  `Arguably the best-known epidemic, the Black Death claimed as many as 200 million lives; equivalent to wiping out about 65% of the current U.S. population. This epidemic was also caused by the bubonic plague, and is the largest epidemic in recorded history.`
              ),
              sources: [
                  {
                      source: 'The Washington Post',
                      url: 'https://www.washingtonpost.com/graphics/2020/local/retropolis/coronavirus-deadliest-pandemics/?itid=ap_michaels.%20rosenwald&itid=lk_interstitial_manual_7&itid=lk_inline_manual_40&itid=lk_inline_manual_28&itid=lk_inline_manual_60&itid=lk_inline_manual_46&itid=lk_inline_manual_36&itid=lk_inline_manual_43',
                  },
              ],
        },
      },
      {
          //question 3//
          question: `How many died of the New World smallpox epidemic (1520-unknown)?`,
          choices: ['5,500,000', '55,000,000', '550,000,000', '5,500,000,000'],
          answerIdx: 1,    
          furtherInfo: {
              explanation: (
                  `As many as 55 million Native Americans died in the New World smallpox epidemic. Carried by European explorers and colonists, smallpox, measles, and other viruses swept through Native populations, killing 80% of populations least affected, and completely annihilating those populations most affected.`
              ),
              sources: [
                  {
                      source: 'The Washington Post',
                      url: 'https://www.washingtonpost.com/graphics/2020/local/retropolis/coronavirus-deadliest-pandemics/?itid=ap_michaels.%20rosenwald&itid=lk_interstitial_manual_7&itid=lk_inline_manual_40&itid=lk_inline_manual_28&itid=lk_inline_manual_60&itid=lk_inline_manual_46&itid=lk_inline_manual_36&itid=lk_inline_manual_43',
                  },
              ],
        },
      },
      {
          //question 4//
          question: `The Cholera pandemics claimed how many lives (1817-1923)?`,
          choices: ['1,000,000', '10,000,000', '100,000,000', '1,000,000,000'],
          answerIdx: 0,    
          furtherInfo: {
              explanation: (
                  `The period between 1817 and 1923 saw several virulent outbreaks of cholera. Spread through contaminated water, these cholera outbreaks claimed 1 million lives.`
              ),
              sources: [
                  {
                      source: 'The Washington Post',
                      url: 'https://www.washingtonpost.com/graphics/2020/local/retropolis/coronavirus-deadliest-pandemics/?itid=ap_michaels.%20rosenwald&itid=lk_interstitial_manual_7&itid=lk_inline_manual_40&itid=lk_inline_manual_28&itid=lk_inline_manual_60&itid=lk_inline_manual_46&itid=lk_inline_manual_36&itid=lk_inline_manual_43',
                  },
              ],
        },
      },
      {
          //question 5//
          question: `How many died of Yellow Fever (late 1800s)?`,
          choices: ['150,000', '1,500,000', '15,000,000', '150,000,000'],
          answerIdx: 0,    
          furtherInfo: {
              explanation: (
                  `Yellow Fever, named for the distinct yellow shade of skin it gives those infected, claimed 150,000 lives.`
              ),
              sources: [
                  {
                      source: 'The Washington Post',
                      url: 'https://www.washingtonpost.com/graphics/2020/local/retropolis/coronavirus-deadliest-pandemics/?itid=ap_michaels.%20rosenwald&itid=lk_interstitial_manual_7&itid=lk_inline_manual_40&itid=lk_inline_manual_28&itid=lk_inline_manual_60&itid=lk_inline_manual_46&itid=lk_inline_manual_36&itid=lk_inline_manual_43',
                  },
              ],
        },
      },
      {
          //question 6//
          question: `The 1918 Flu, or as it was known then the Spanish Flu, killed how many people (1918-1920)?`,
          choices: ['500,000', '5,000,000', '50,000,000', '500,000,000'],
          answerIdx: 2,    
          furtherInfo: {
              explanation: (
                  `Caused by the H1N1 virus, the 1918 Flu took an estimated 50 million lives. It is estimated its rate of infection reached a staggering 500 million.`
              ),
              sources: [
                  {
                      source: 'The Washington Post',
                      url: 'https://www.washingtonpost.com/graphics/2020/local/retropolis/coronavirus-deadliest-pandemics/?itid=ap_michaels.%20rosenwald&itid=lk_interstitial_manual_7&itid=lk_inline_manual_40&itid=lk_inline_manual_28&itid=lk_inline_manual_60&itid=lk_inline_manual_46&itid=lk_inline_manual_36&itid=lk_inline_manual_43',
                  },
              ],
        },
      },
      {
          //question 7//
          question: `How many died of the Asian Flu (1957-1958)?`,
          choices: ['100,000', '1,000,000', '10,000,000', '100,000,000'],
          answerIdx: 1,    
          furtherInfo: {
              explanation: (
                  `Caused by the H2N2 virus, the Asian Flu took an estimated 1 million lives worldwide. Originating in China, the US death toll of 70,000 would have been much higher if Dr. Maurice Hillerman had not had the foresight to have samples of the virus shipped to the US so drugmakers could start working on a vaccine before the virus made it to our shores.`
              ),
              sources: [
                  {
                      source: 'The Washington Post',
                      url: 'https://www.washingtonpost.com/graphics/2020/local/retropolis/coronavirus-deadliest-pandemics/?itid=ap_michaels.%20rosenwald&itid=lk_interstitial_manual_7&itid=lk_inline_manual_40&itid=lk_inline_manual_28&itid=lk_inline_manual_60&itid=lk_inline_manual_46&itid=lk_inline_manual_36&itid=lk_inline_manual_43',
                  },
              ],
        },
      },
      {
          //question 8//
          question: `To date, how many lives has HIV/AIDS taken (1981-current)?`,
          choices: ['35,000', '350,000', '3,500,000', '35,000,000'],
          answerIdx: 3,    
          furtherInfo: {
              explanation: (
                  `Though modern breakthroughs have changed what it means to be HIV positive, to date HIV/AIDS has taken approximately 35 million lives.`
              ),
              sources: [
                  {
                      source: 'The Washington Post',
                      url: 'https://www.washingtonpost.com/graphics/2020/local/retropolis/coronavirus-deadliest-pandemics/?itid=ap_michaels.%20rosenwald&itid=lk_interstitial_manual_7&itid=lk_inline_manual_40&itid=lk_inline_manual_28&itid=lk_inline_manual_60&itid=lk_inline_manual_46&itid=lk_inline_manual_36&itid=lk_inline_manual_43',
                  },
              ],
        },
      },
      {
          //question 9//
          question: `How many died of Swine Flu (2009-unknown)?`,
          choices: ['200,000', '2,000,000', '20,000,000', '200,000,000'],
          answerIdx: 0,    
          furtherInfo: {
              explanation: (
                  `The most recent pandemic before COVID-19, Swine Flu infected 21% of the world’s population and took 200,000 lives.`
              ),
              sources: [
                  {
                      source: 'The Washington Post',
                      url: 'https://www.washingtonpost.com/graphics/2020/local/retropolis/coronavirus-deadliest-pandemics/?itid=ap_michaels.%20rosenwald&itid=lk_interstitial_manual_7&itid=lk_inline_manual_40&itid=lk_inline_manual_28&itid=lk_inline_manual_60&itid=lk_inline_manual_46&itid=lk_inline_manual_36&itid=lk_inline_manual_43',
                  },
              ],
        },
      },
      {
          //question 10//
          question: `Approximately how many have died of COVID-19 (2020-current)?`,
          choices: ['250,000', '2,500,000', '25,000,000', '250,000,000'],
          answerIdx: 1,    
          furtherInfo: {
              explanation: (
                  `Approximately 2.5 million people have died of COVID-19 globally, though we are unfortunately still in the midst of this pandemic as of the writing of this quiz and the numbers are still rising.`
              ),
              sources: [
                  {
                      source: 'The Washington Post',
                      url: 'https://www.washingtonpost.com/graphics/2020/local/retropolis/coronavirus-deadliest-pandemics/?itid=ap_michaels.%20rosenwald&itid=lk_interstitial_manual_7&itid=lk_inline_manual_40&itid=lk_inline_manual_28&itid=lk_inline_manual_60&itid=lk_inline_manual_46&itid=lk_inline_manual_36&itid=lk_inline_manual_43',
                  },
              ],
          },
      },
  ],
};

export default pandemicsByNumbers;