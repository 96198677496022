 const shanghaiTower = {
    name: 'The Shanghai Tower in Numbers',
    id: 'kAnH5yVDcPyYG4ZxmuEt',
    linkTo: "/quizzes/shanghaiTower",
    explainerOn: true,
    description: 'Shanghai is one of the largest cities in the world, with a population of 24,000,000. Crowning this grand metropolis is the Shanghai Tower. A marvel of design and engineering, what does the second tallest building in the world look like in numbers?',
    questions: [
        {
            //question 1//
            question: "How long are most commercial buildings designed to last without renovation?",
            choices: ['6 years', '60 years', '600 years', '6,000 years'],
            answerIdx: 1,    
            furtherInfo: {
                explanation: (
                    `Most commercial buildings are built to last 60 years before needing major repairs or renovations.`
                ),
                sources: [
                    {
                        source: 'BBC. How cities will fossilise. BBC Future',
                        url: 'https://www.bbc.com/future/article/20210505-how-cities-will-fossilise',
                    },
                ],
            },
        },
        {
            //question 2//
            question: "How many metric tons does the Shanghai Tower weigh?",
            choices: ['850', '8,500', '85,000', '850,000'],
            answerIdx: 3,    
            furtherInfo: {
                explanation: (
                    `The Shanghai Tower weighs 850,000 metric tons, which is equal to 1,873,929 pounds.`
                ),
                sources: [
                    {
                        source: 'BBC. How cities will fossilise. BBC Future',
                        url: 'https://www.bbc.com/future/article/20210505-how-cities-will-fossilise',
                    },
                ],
            },
        },
        {
            //question 3//
            question: "Approximately how tall is the steel framework of the Shanghai Tower?",
            choices: ['2,000 ft', '20,000 ft', '200,000 ft', '2,000,000 ft'],
            answerIdx: 0,    
            furtherInfo: {
                explanation: (
                    `The steel framework of the Shanghai Tower is approximately 2,000 feet tall, just 700 feet shorter than Burj Khalifa in Dubai, and dwarfing the Empire State building at its height of 1,454 feet.`
                ),
                sources: [
                    {
                        source: 'Business Insider',
                        url: 'https://www.businessinsider.com/the-tallest-buildings-in-the-world-2015-12#2-shanghai-tower-16',
                    },
                ],
            },
        },
        {
            //question 4//
            question: "Approximately how many panes of glass does the Shanghai Tower boast?",
            choices: ['200', '2,000', '20,000', '200,000'],
            answerIdx: 2,    
            furtherInfo: {
                explanation: (
                    `The Shanghai Tower has approximately 20,000 panes of glass.`
                ),
                sources: [
                    {
                        source: 'BBC. How cities will fossilise. BBC Future',
                        url: 'https://www.bbc.com/future/article/20210505-how-cities-will-fossilise',
                    },
                ],
            },
        },
        {
            //question 5//
            question: "How many cubic feet of concrete has gone into the construction of the Shanghai Tower?",
            choices: ['200,000', '2,000,000', '20,000,000', '2,000,000,000'],
            answerIdx: 1,    
            furtherInfo: {
                explanation: (
                    `Two million cubic feet of concrete have gone into the construction of the Shanghai Tower.`
                ),
                sources: [
                    {
                        source: 'BBC. How cities will fossilise. BBC Future',
                        url: 'https://www.bbc.com/future/article/20210505-how-cities-will-fossilise',
                    },
                ],
            },
        },
        {
            //question 6//
            question: "For how many vehicles does the Shanghai Tower’s underground parking structure have room?",
            choices: ['180', '1,800', '18,000', '180,000'],
            answerIdx: 1,    
            furtherInfo: {
                explanation: (
                    `The Shanghai Tower’s underground parking structure has room for 1,800 vehicles.`
                ),
                sources: [
                    {
                        source: 'BBC. How cities will fossilise. BBC Future',
                        url: 'https://www.bbc.com/future/article/20210505-how-cities-will-fossilise',
                    },
                ],
            },
        },
        {
            //question 7//
            question: "How many square feet does the concrete raft supporting the Shanghai Tower cover?",
            choices: ['970', '9,700', '97,000', '970,000'],
            answerIdx: 2,    
            furtherInfo: {
                explanation: (
                    `The massive concrete raft supporting the Shanghai Tower covers 97,000 square feet.`
                ),
                sources: [
                    {
                        source: 'BBC. How cities will fossilise. BBC Future',
                        url: 'https://www.bbc.com/future/article/20210505-how-cities-will-fossilise',
                    },
                ],
            },
        },
        {
            //question 8//
            question: "How many concrete and steel piles support the concrete raft?",
            choices: ['95', '955', '9,500', '95,000'],
            answerIdx: 1,    
            furtherInfo: {
                explanation: (
                    `The concrete raft is in turn supported by 955 concrete and steel piles, significantly more than the Burj Khalifa’s 192 piles.`
                ),
                sources: [
                    {
                        source: 'Burj Khalifa',
                        url: 'https://www.burjkhalifa.ae/en/the-tower/design-construction/',
                    },
                ],
            },
        },
        {
            //question 9//
            question: "How far are the supporting piles driven into the ground?",
            choices: ['280 ft', '2,800 ft', '28,000 ft', '280,000 ft'],
            answerIdx: 0,    
            furtherInfo: {
                explanation: (
                    `The steel piles are driven 280 feet into the ground.`
                ),
                sources: [
                    {
                        source: 'BBC. How cities will fossilise. BBC Future',
                        url: 'https://www.bbc.com/future/article/20210505-how-cities-will-fossilise',
                    },
                ],
            },
        },
        {
            //question 10//
            question: "How many miles of subway lines lie underground connecting the Tower with the rest of Shanghai?",
            choices: ['18', '186', '1,860', '18,600'],
            answerIdx: 1,    
            furtherInfo: {
                explanation: (
                    `186 miles of subway lines lay under Shanghai, which is over 200 miles less than New York City’s 443 miles of underground subway lines.`
                ),
                sources: [
                    {
                        source: 'NYC Subway',
                        url: 'https://www.nycsubway.org/wiki/Subway_FAQ:_Facts_and_Figures#Other_Track_Stats',
                    },
                ],
            },
        },
    ],

};

export default shanghaiTower;