const bigVsLittle = { 
  name: "Big Vs Little", 
  id: 'eRv8C2PpuUgoNj1Ns9Bl', 
  linkTo: "/quizzes/bigVsLittle",
  explainerOn: true, 
  subheading: `Big or little? Large or small? The Universe is a Big place with a lot Small things. Test your knowledge on the biggest and smallest things out there.`,
  description: `What's the tallest thing you can think of? What's the smallest? Chances are, we've got you beat. Can you name some of the largest, and smallest, things in the universe?`, 
  questions: [
      { // Q-1
          question: "What is the tallest building in the world today?",
          choices: ['The Empire State Building', 'The Eiffel Tower', 'Burj Khalifa', 'The Taj Mahal'],
          answerIdx: 2,
          furtherInfo: {
              explanation: (
                  `At over 800 meters, Burj Khalifa in Dubai is the tallest building in the world today.`
              ),
              sources: [
                  {
                      source: "Emporis",
                      url: 'https://www.emporis.com/statistics/worlds-tallest-buildings',
                  },
              ],
          },
      },
      { // Q-2
          question: "What is the smallest tree species on Earth?",
          choices: ['Dwarf Willow', 'Hawthorne', 'Birch', 'Pygmy Pine'],
          answerIdx: 0,    
          furtherInfo: {
              explanation: (
                  `The smallest species of tree on Earth is the Dwarf Willow. Its scientific name is <i>Salix herbacea</i> and it only grows to be 1 - 6 centimeters in height. The Dwarf Willow's small stature helps it survive the harsh arctic winds along the North Atlantic Coast it calls home.`
              ),
              sources: [
                {
                  source: "Science Info",
                  url: 'https://scienceinfo.net/the-smallest-tree-species-in-the-world.html',
                },
              ],
          },
      },
      { // Q-3 
        question: "What is the largest planet in the known universe?",
        choices: ['Jupiter', 'Alpha Centauri', 'Neptune', 'Tres-4'],
        answerIdx: 3,    
        furtherInfo: {
            explanation: (
                `While Jupiter is the largest planet in our solar system, Tres-4 is the largest planet in the known universe--at least that we've found so far. Tres-4 is almost twice as big as Jupiter! It was named for the Trans-Atlantic Exoplanet Survey that found it in 2006, and is 1,430 light years away in the Hercules constellation.`
            ),
            sources: [
              {
                source: "Jet Propulsion Laboratory NASA",
                url: 'https://www.jpl.nasa.gov/infographics/the-largest-known-planet',
              },
            ],
        },
      },
      { // Q-4
        question: "What is the smallest known particle?",
        choices: ['Atom', 'Electron', 'Nucleus', 'Proton'],
        answerIdx: 1,    
        furtherInfo: {
            explanation: (
                `Everything is made up of atoms, and atoms are made up of particles. The very smallest of these particles is the electron. Electrons beat out quarks for the title of smallest particle by having a radius that is one billionth of one billionth of a centimeter, or 0.00000000000000001 cm.`
            ),
            sources: [
              {
                source: "Science ABC",
                url: 'https://www.scienceabc.com/nature/universe/what-is-the-smallest-particle-we-know.html',
              },
            ],
        },
      },
      { // Q-5
        question: "What is the largest living organism on Earth?",
        choices: ['Giant Sequoia', 'Blue Whale', 'Honey Mushroom', 'Elephant'],
        answerIdx: 2,    
        furtherInfo: {
            explanation: (
                `The largest living organism on Earth isn't an animal, it's a fungus: the Honey Mushroom. Scientific name Armillaria otsoyae, one continuous instance of this huge fungus' underground system of filaments covers 3.7 square miles in Oregon.`
            ),
            sources: [
              {
                source: "Oregon Encyclopedia",
                url: 'https://www.oregonencyclopedia.org/articles/humongous-fungus-armillaria-ostoyae/',
              },
            ],
        },
      },
      { // Q-6
        question: "A T-Rex was huge, but what is the SMALLEST carnivore alive today?",
        choices:['Kestrel', 'Pygmy Shrew', 'Ocelot', 'Least Weasel'],
        answerIdx: 3,    
        furtherInfo: {
            explanation: (
                `When we think of carnivores we often think of wolves or tigers, or even the extinct T-Rex, but carnivores come in all sizes. The Least Weasel is the world's smallest carnivore. Least Weasels only grow to be 4 to 10 centimeters and weigh under 1 ounce.`
            ),
            sources: [
              {
                source: "World Analysis",
                url: 'https://worldanalysis.net/best-answer-what-is-the-smallest-carnivore/#What_is_the_smallest_carnivore',
              },
            ],
        },
      },
      { // Q-7
        question: "What is the largest star in the known universe?",
        choices: ['UY Scuti', 'Sol', 'Antares', 'Wolf 359'],
        answerIdx: 0,    
        furtherInfo: {
            explanation: (
                `We're fond of it, and life on Earth as we know it wouldn't exist without it, but our Sun is actually pretty average when it comes to stars. The largest star in the known universe today is one called UY Scuti. At roughly 1,700 times the width of our sun, UY Scuti is insanely huge. But there are still so many stars in the universe we haven't discovered yet, it may one day be considered nothing more than average as well.`
            ),
            sources: [
              {
                source: "Astronomy.com",
                url: 'https://astronomy.com/magazine/news/2020/09/the-most-extreme-stars-in-the-universe',
              },
            ],
        },
      },
      { // Q-8
        question: "What is the smallest cell in the human body?",
        choices: ['Skin Cell', 'Red Blood Cell', 'Cerebellar Granule Cell', 'White Blood Cell'],
        answerIdx: 2,    
        furtherInfo: {
            explanation: (
                `The smallest cells in the human body are the Cerebellar Granule Cells. These tiny cells are just 4 micrometers long. There are approximately <i>60 billion</i> in the human brain.`
            ),
            sources: [
              {
                source: "Byjus",
                url: 'https://byjus.com/questions/which-are-the-smallest-cells-in-the-human-body/',
              },
            ],
        },
      },
      { // Q-9
        question: "What is the largest mountain in the solar system?",
        choices: ['Everest', 'Olympus Mons', 'Mt. Rushmore', 'Nanda Parbat'],
        answerIdx: 1,    
        furtherInfo: {
            explanation: (
                `The largest mountain in our solar system is Olympus Mons, on Mars. This giant mountain, named for the home of the gods in Greek mythology, is found near the equator of Mars, is 16 miles tall, while Mt. Everest is only 5.4 miles tall. Olympus Mons stretches across 374 miles, and <i>is a volcano!</i>`
            ),
            sources: [
              {
                source: "Space.com",
                url: 'https://www.space.com/20133-olympus-mons-giant-mountain-of-mars.html',
              },
            ],
        },
      },
      { // Q-10
        question: "What is the smallest insect on Earth?",
        choices: ['Fairyfly', 'Mosquito', 'Flea', 'Gnat'],
        answerIdx: 0,    
        furtherInfo: {
            explanation: (
                `The smallest insect on Earth is the fairyfly. Its scientific name is <i>Dicopomorpha echmepterygis</i>, and they are only 0.005 inches long. Despite their common name, fairyflies aren't actually flies, they are a species of parasitic wasp.`
            ),
            sources: [
              {
                source: "University of Nebraska Lincoln ",
                url: 'https://entomology.unl.edu/scilit/smallest-insect',
              },
            ],
        },
      },
  ],

};

export default bigVsLittle;