import React, { useState } from "react";
import { useFirestore } from "reactfire";
import { createNewUserWithUsername } from "../../firestore/user";
import useNotificationSystem from "../../hooks/useNotificationSystem";
import { isValidUsername, validTextCaption } from "../../utils/isValidUsername";

import BackgroundContainer from "./BackgroundContainer";
import Header from "./Header";
import Form from "./Form";
import ProfileContainer from "./Container";

const SetUsername = ({ userUid,history }) => {
  const [username, setUsername] = useState("");
  const firestore = useFirestore();
  const { addNotification } = useNotificationSystem();

  if (!firestore) return null; // might be better to have a spinner in future

  const handleChange = (e) => setUsername(e.target.value);
  const handleSubmit = (e) => {
    e.preventDefault();

    if (!isValidUsername(username)) {
      addNotification({
        message: "Invalid username",
        level: "error",
      });
    } else {
      createNewUserWithUsername(firestore, userUid, username)
        .then(() => {
          // saving for later: localStorage.getItem("temporary-quiz-data") ? JSON.parse(localStorage.getItem("temporary-quiz-data")).linkTo : "/quizzes"
          // window.location.replace("/quizzes");
          history.goBack();
        })
        .catch(() => {
          addNotification({
            message: "Failed to set username",
            level: "error",
          });
        });
    }
  };

  const inputs = [
    {
      key: "username",
      label: "Username",
      type: "text",
      value: username,
      caption: validTextCaption(),
      onChange: (e) => handleChange(e),
    },
  ];

  return (
    <BackgroundContainer>
      <Header title="Set username" hideBackBtn={true} />
      <ProfileContainer>
        <Form onSubmit={handleSubmit} inputs={inputs} />
      </ProfileContainer>
    </BackgroundContainer>
  );
};

export default SetUsername;
