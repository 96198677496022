const architecturalScaling = {
    id: 'architecturalScaling',
    name: 'Architectural Scaling',
    linkTo: "/quizzes/architecturalScaling",
    explainerOn: true,
    randomize: true,
    quizLengthSpec: 8,
    description: `Whether you're thinking of remodeling, or you're just a fan of home design shows, you've probably come across architectural blueprints. Blueprints provide an accurate, scaled down plan of what any given structure looks like -- but they can be hard to interpret, for a layperson. How familiar are <b>you</b> with architectural scaling?`,
    questions: [
        { // Q-1
            question: " The 1:50 scaled length of a 2m door is 4cm:",
            choices: ['True', 'False'],
            answerIdx: 0,
            furtherInfo: {
                explanation: `This is true. A 2 meter door scaled by 1:50 would be 4 centimeters.`
            },
        },
        { // Q-2
            question: "If a window has a scaled width of 1 foot and an original width of 4 feet, then which scale ratio is used?",
            choices: ['1:2', '1:2.5', '1:4', '1:8'],
            answerIdx: 2,
            furtherInfo: {
                explanation: `The scale ratio used to scale a window with a width of 4 feet to 1 foot is 1:4.`
            },
        },
        { // Q-3
            question: "If a roof has a dimension of 10m x 5m, a 1:5 scaled dimension in mm will be:",
            choices: ['200mm x 100mm', '2,000mm x 500mm', '2,000mm x 1,000mm', '3,000mm x 1,000mm' ],
            answerIdx: 2,
            furtherInfo: {
                explanation: `Using a 1:5 scale ratio, a 10 x 5 meter roof becomes 2,000 x 1,000 millimeters.`
            },
        },
        { // Q-4
            question: "What is the original thickness of a wall if its scaled thickness is 0.3mm and the scale that's used is 1:5?",
            choices: ['1.3mm', '1.5mm', '1.6mm', '1.7mm'],
            answerIdx: 1,
            furtherInfo: {
                explanation: `If the scaled thickness of a wall is 0.3 millimeters when a 1:5 scale ratio is used, then the original thickness of the wall is 1.5 millimeters.`
            },
        },
        { // Q-5
            question: "A rectangular house is 40 x 50 ft. long. If the architect has scaled it down to 4 x 5 feet, what is the scale used?",
            choices: ['1:4', '1:5', '1:10', '1:100'],
            answerIdx: 2,
            furtherInfo: {
                explanation: `A 1:10 scale ratio is used to scale a 40 x 50 foot house to 4 x 5 feet.`
            },
        },
        { // Q-6
            question: ` If a pergola has an original height of 3m, scaled to 15cm, and an original width of 1m, what is its scaled width?`,
            choices: [`0.5 cm`, `5 cm`, `50 cm`, `500 cm`],
            answerIdx: 1,
            furtherInfo: {
                explanation: `Using a 1:20 scale ratio, the scaled width of the pergola would be 5 centimeters.`
            },
        },
        { // Q-7
            question: "Two halls have lengths of 100 ft. and 50 ft., respectively. If the scaled length of the 1st hall is 5 ft., then the scaled length of the 2nd hall is:",
            choices: ['1 ft', '2.5 ft', '10 ft', '25 ft'],
            answerIdx: 1,
            furtherInfo: {
                explanation: `Using a 1:20 scale ratio, the scaled length of the 2nd hall is 2.5 feet.`
            },
        },
        { // Q-8
            question: `The site of Carnegie Hall in New York covers approximately 28,000 sq. ft., what is the size of the site at 1:5 scale?`,
            choices: [`560 sq. ft.`, `5,600 sq. ft.`, `56,000 sq. ft.`, `560,000 sq. ft.`],
            answerIdx: 1,
            furtherInfo: {
                explanation: `Using a 1:5 scale ratio, the 28,000 square foot site of Carnegie Hall becomes 5,600 square feet.`,
                sources: [
                    {
                        source: "NYC Planning",
                        url: 'https://zola.planning.nyc.gov/l/lot/1/1009/1#16.57/40.765008/-73.977716',
                    },
                ],
            },
        },
        { // Q-9
            question: "Considering a scale of 2 inch = 1 ft, what will be the width of a 4.5 ft sofa on a scaled paper?",
            choices: ['3 inches', '6 inches', '9 inches', '12 inches'],
            answerIdx: 2,
            furtherInfo: {
                explanation: `2 inches = 1 foot represents a 1:6 scale ratio so a 4.5 foot sofa would become 9 inches when scaled.`
            },
        },
        { // Q-10
            question: "If a 15 ft. long backyard is scaled down to a length of 9 in., what scale ratio is being used?",
            choices: ['Scale of 1:5', 'Scale of 1:10', 'Scale of 1:20', 'Scale of 1:50'],
            answerIdx: 2,
            furtherInfo: {
                explanation: `A 1:20 scale ratio is used to scale 15 feet to 9 inches.`
            },
        },
        { // Q-11
            question: "The height of the main structure of the Eiffel Tower is 300m. A scale of 1:9 is used to scale its height down to 9m:",
            choices: ['True', 'False'],
            answerIdx: 1,
            furtherInfo: {
                explanation: `This is false. A scale ratio of 1:9 would bring the 300 meter height of the Eiffel Tower to 33.3 meters.`,
                sources: [
                    {
                        source: "World Atlas",
                        url: 'https://www.worldatlas.com/articles/how-tall-is-the-eiffel-tower.html',
                    },
                ],
            },
        },
        { // Q-12
            question: "If a wardrobe of 10 x 30 ft is scaled down to 1 x 3 ft, then which one of the scales was used:",
            choices: ['1:10', '1:15', '1:20', '1:30'],
            answerIdx: 0,
            furtherInfo: {
                explanation: `When scaling a 10 x 30 foot wardrobe down to 1 x 3 feet a 1:10 scale ratio is used.`
            },
        },
        { // Q-13
            question: "A square football ground has 2 halves of 1000 square meters (m²) each. The total scaled length of the field using 1:20 scale is:",
            choices: ['1m', '10m', '100m', '1,000m'],
            answerIdx: 2,
            furtherInfo: {
                explanation: `Using a 1:20 scale ratio, the 2,000 square meters of the entire football ground become 100 meters.`
            },
        },
        { // Q-14
            question: "If a refrigerator has a door that is 30 cm wide, using a scale ratio of 1:15 what is the scaled width of the door?",
            choices: ['20 mm', '2 cm', '3 cm', 'Both A and B'],
            answerIdx: 3,
            furtherInfo: {
                explanation: `Both A and B are correct; 20 millimeters are the same as 2 centimeters`
            },
        },
        { // Q-15 
            question: " If a lathe machine and its apron, 600 cm and 200 cm lengths respectively, are scaled down to 1:100 scale, then which is correct?",
            choices: [
                'Lathe 60 mm, apron 200 mm',
                'Lathe 6 cm, apron 2 cm',
                'Lathe 6 cm, apron 20 cm',
                'Both A and B'
            ],
            answerIdx: 1,
            furtherInfo: {
                explanation: `Using a scale ratio of 1:100 the lathe and its apron become 6 and 2 centimeters respectively.`
            },
        },
        { // Q-16
            question: " If a room is 175 cm long, then the 1:25 scaled length of the room is:",
            choices: ['7 cm', '12 cm', '18 cm', '25 cm'],
            answerIdx: 0,
            furtherInfo: {
                explanation: `Using a 1:25 scale ratio, a 175 centimeter long room scales down to 7 centimeters.`
            },
        },
        { // Q-17
            question: " A kitchen measures 70 cm in length. If its width is half of its length, then its 1:10 scaled-down width will be:",
            choices: ['3.5 cm', '5.5 cm', '7 cm', '14 cm'],
            answerIdx: 0,
            furtherInfo: {
                explanation: `Using a 1:10 scale ratio, the 35 centimeter width of the kitchen becomes 3.5 centimeters.`
            },
        },
        { // Q-18
            question: "A carpet has both a width and length of 100 cm. Scaled-down to 1:5000, what will be the total area of the carpet?",
            choices: ['2 cm²', '5 cm²', '10 cm²', '20 cm²'],
            answerIdx: 0,
            furtherInfo: {
                explanation: `Using a 1:5000 scale ratio, the total 10,000 cm² area of the carpet becomes 2 cm²`
            },
        },
    ],

};

export default architecturalScaling;
