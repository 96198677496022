const volcanicMines = {
  name: 'Volcanic Mines',
  id: 'YhGgIzGSnn7pcSF92V36',
  linkTo: "/quizzes/volcanicMines",
  explainerOn: true,
  description: `As we move toward a carbon net-zero energy future the demand for certain metals will increase dramatically; metals like copper, the mining of which is both energy-intensive and highly CO2 producing. However, stores of metal-rich brines below dormant volcanoes hold the key to not only increasing our supply of copper but decreasing the environmental impact of obtaining it.`,
  questions: [
      {
          //question 1//
          question: `What percent of the world’s copper is currently found in just five countries?`,
          choices: ['5%', '35%', '65%', '95%'],
          answerIdx: 2,    
          furtherInfo: {
              explanation: (
                  `65% of the world’s copper is found in just five countries; Chile, Peru, Australia, Mexico, and The United States.`
              ),
              sources: [
                  {
                      source: 'U.S. Geological Survey',
                      url: 'https://www.usgs.gov/faqs/how-much-copper-has-been-found-world?qt-news_science_products=0#qt-news_science_products',
                  },
              ],
        },
      },
      {
          //question 2//
          question: `What percent of the crushed rock produced by conventional copper mining winds up as waste?`,
          choices: ['29%', '59%', '79%', '99%'],
          answerIdx: 3,    
          furtherInfo: {
              explanation: (
                  `99% of all crushed rock produced by conventional open-pit copper mining is waste stored in heaps near the mine site. Depending on the composition of the rock these heaps can release harmful elements into the environment.`
              ),
              sources: [
                  {
                      source: 'Geological Survey of Sweden',
                      url: 'https://www.sgu.se/en/geointro/lecture-4-mining-waste/',
                  },
              ],
        },
      },
      {
          //question 3//
          question: `At what depth, in kilometers, do pockets of metal-rich volcanic brines reside?`,
          choices: ['2km', '12km', '120km', '1,200km'],
          answerIdx: 0,    
          furtherInfo: {
              explanation: (
                  `Volcanic brines reside at a depth of 2 kilometers, or 1.2 miles.`
              ),
              sources: [
                  {
                      source: 'University of Oxford',
                      url: 'https://www.ox.ac.uk/news/2021-06-29-oxford-scientists-show-how-green-mining-could-pave-way-net-zero-and-provide-metals',
                  },
              ],
        },
      },
      {
          //question 4//
          question: `How many parts per million of copper are volcanic brines currently estimated to have?`,
          choices: ['5', '50', '500', '5,000'],
          answerIdx: 3,    
          furtherInfo: {
              explanation: (
                  `Volcanic brines are currently estimated to contain approximately 5,000 parts per million of copper.`
              ),
              sources: [
                  {
                      source: 'The Royal Society Publishing',
                      url: 'https://royalsocietypublishing.org/doi/10.1098/rsos.202192',
                  },
              ],
        },
      },
      {
          //question 5//
          question: `What is copper used in that makes it so critical to a clean energy future?`,
          choices: ['Electric Vehicles', 'Batteries', 'Electricity Generation', 'All of the above'],
          answerIdx: 3,    
          furtherInfo: {
              explanation: (
                  `Copper is a vital part of all of the above, which makes it a critical component of a clean energy future.`
              ),
              sources: [
                  {
                      source: 'University of Oxford',
                      url: 'https://www.ox.ac.uk/news/2021-06-29-oxford-scientists-show-how-green-mining-could-pave-way-net-zero-and-provide-metals',
                  },
              ],
        },
      },
      {
          //question 6//
          question: `What is another highly sought-after metal present in volcanic brines?`,
          choices: ['Aluminium', 'Lithium', 'Iron', 'Tin'],
          answerIdx: 1,    
          furtherInfo: {
              explanation: (
                  `Lithium, another critical metal for clean energy efforts, is present in volcanic brines as well as gold, silver, and zinc.`
              ),
              sources: [
                  {
                      source: 'University of Oxford',
                      url: 'https://www.ox.ac.uk/news/2021-06-29-oxford-scientists-show-how-green-mining-could-pave-way-net-zero-and-provide-metals',
                  },
              ],
        },
      },
      {
          //question 7//
          question: `Mining volcanic brines would take place in conditions of how many degrees celsius?`,
          choices: ['4°C', '45°C', '450°C', '1,450°C'],
          answerIdx: 2,    
          furtherInfo: {
              explanation: (
                  `Mining would take place at temperatures of 450°C, which is 842°F.`
              ),
              sources: [
                  {
                      source: 'University of Oxford',
                      url: 'https://www.ox.ac.uk/news/2021-06-29-oxford-scientists-show-how-green-mining-could-pave-way-net-zero-and-provide-metals',
                  },
              ],
        },
      },
      {
          //question 8//
          question: `What is another problem posed by mining volcanic brines?`,
          choices: ['Corrosive fluids', 'Scaling', 'Short daylight hours', 'Both A and B'],
          answerIdx: 3,    
          furtherInfo: {
              explanation: (
                  `Efforts to mine volcanic brines will have to overcome the difficulties posed both by the corrosive nature of the fluids and the scaling (think limescale in a teapot) of its pipes.`
              ),
              sources: [
                  {
                      source: 'University of Oxford',
                      url: 'https://www.ox.ac.uk/news/2021-06-29-oxford-scientists-show-how-green-mining-could-pave-way-net-zero-and-provide-metals',
                  },
              ],
        },
      },
      {
          //question 9//
          question: `What is a common byproduct of this mining method?`,
          choices: ['Geothermal Energy', 'Potable Water', 'Volcanic Rock', 'Sulfur'],
          answerIdx: 0,    
          furtherInfo: {
              explanation: (
                  `A common byproduct of this mining method is geothermal energy, which if used to power operations could make the whole operation carbon net-zero.`
              ),
              sources: [
                  {
                      source: 'University of Oxford',
                      url: 'https://www.ox.ac.uk/news/2021-06-29-oxford-scientists-show-how-green-mining-could-pave-way-net-zero-and-provide-metals',
                  },
              ],
        },
      },
      {
          //question 10//
          question: `When testing the viability of mining volcanic brines, drill cores were taken from Japan, Italy, Montserrat, Indonesia, and ________.`,
          choices: ['Germany', 'Mexico', 'China', 'Iran'],
          answerIdx: 1,    
          furtherInfo: {
              explanation: (
                  `When testing the viability of mining volcanic brines, drill cores were taken from Japan, Italy, Montserrat, Indonesia, and Mexico.`
              ),
              sources: [
                  {
                      source: 'University of Oxford',
                      url: 'https://www.ox.ac.uk/news/2021-06-29-oxford-scientists-show-how-green-mining-could-pave-way-net-zero-and-provide-metals',
                  },
              ],
          },
      },
  ],
};

export default volcanicMines;
