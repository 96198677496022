import React, { useContext, useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import quizList from "../quizList";
import Question from "./question/Question.js";
import { ThemeProvider, CssBaseline } from "@material-ui/core";
import { PreviewData as Preview } from "./Preview";
import { Helmet } from "react-helmet";
import useSound from "use-sound";
import { playSound } from "../utils/sound.js";
import QuestionContainer from "./question/QuestionContainer.js";
import { QuizScoreContext, useStore } from "../contexts/store";
import { mixpanelTrack } from "../utils/mixpanel";
import { calculateScore } from "../utils/scoring";
import { useFirestore } from "reactfire";
import { useAuth } from "reactfire";
import sanitizeHtml from "sanitize-html";
import useMergedUser from "../hooks/useMergedUser";
import CorrectSound from "../assets/sounds/AnswerCorrect.mp3";
import IncorrectSound from "../assets/sounds/AnswerIncorrect.mp3";
import EndSound from "../assets/sounds/end.mp3";
import { useHistory } from "react-router-dom";

const shuffleAndTrim = (quizIn) => {
  if (!quizIn.randomize) {
    return quizIn.questions;
  }

  // shuffle question order
  let quizOut = { ...quizIn };
  let questionsOut = [...quizOut.questions];
  let temp, i, j;
  for (i = questionsOut.length - 1; i > 0; i--) {
    j = Math.floor(Math.random() * (i + 1));
    temp = questionsOut[i];
    questionsOut[i] = questionsOut[j];
    questionsOut[j] = temp;
  }

  // trim question set down to specified length
  if (quizIn.quizLengthSpec) {
    questionsOut = questionsOut.slice(0, quizIn.quizLengthSpec);
  }

  return questionsOut;
};

function Quiz({ quizData, theme }) {
  const auth = useAuth();
  const user = useMergedUser();
  const firestore = useFirestore();
  const history = useHistory();
  const { timeLimit, randomize } = quizData; // in seconds
  const initialQuestions = shuffleAndTrim(quizData);
  const [questions, setQuestions] = React.useState(initialQuestions);
  const quizLength = questions.length;
  const [timerIsActive, setTimerisActive] = React.useState(false);
  const [secondsLeft, setSecondsLeft] = React.useState(timeLimit || 0);
  const [activeStep, setActiveStep] = React.useState(-1);
  const [numCorrect, setNumCorrect] = React.useState(0);
  const [appState] = useStore();
  const [quizScoreInfo, setQuizScoreInfo] = useContext(QuizScoreContext);
  const { soundOn } = appState;
  const [playCorrectSound] = useSound(CorrectSound);
  const [playIncorrectSound] = useSound(IncorrectSound);
  const [playEndSound] = useSound(EndSound);

  const isChallengeQuiz = quizData.challenge;

  const quizTracker = {
    quiz: quizData.name,
  };

  useEffect(() => {
    if (Object.keys(quizScoreInfo).length > 0) {
      history.push(`${quizData.linkTo}/outro`);
    }
  }, [history, history.action, quizData.linkTo, quizScoreInfo]);
  // this updates the user in quiz information context  when user logins in after completeing the singin/signup process
  useEffect(
    () => {
      if (Object.keys(quizScoreInfo).length > 0) {
        const details = {
          ...quizScoreInfo,
          user: user,
        };
        setQuizScoreInfo(details);
      }
    },
    // eslint-disable-next-line
    [user]
  );

  useEffect(
    function setupTimerForQuiz() {
      let interval = null;

      if (timerIsActive) {
        interval = setInterval(() => {
          setSecondsLeft((seconds) => seconds - 1);
        }, 1000); // milliseconds
      } else {
        clearInterval(interval);
      }

      return () => clearInterval(interval);
    },
    [timerIsActive]
  );

  useEffect(
    () => {
      if (timerIsActive && secondsLeft === 0) {
        setActiveStep(quizLength);
        setTimerisActive(false);
        saveQuizData();
      }
    },
    // eslint-disable-next-line
    [secondsLeft, timerIsActive, quizLength]
  );

  useEffect(
    () => {
      if (activeStep === quizLength) {
        saveQuizData();
      }
    },
    // eslint-disable-next-line
    [activeStep]
  );

  const handleNewAnswer = (qIdx) => (isCorrect) => {
    if (isCorrect) {
      setNumCorrect(numCorrect + 1);
      playSound(soundOn, playCorrectSound);
    } else {
      playSound(soundOn, playIncorrectSound);
    }
    questions[qIdx].correct = isCorrect;
    mixpanelTrack(appState, "answer-question", {
      ...quizTracker,
      questionIdx: qIdx,
      correct: isCorrect,
    });
    return true;
  };

  const nextQuestion = () => {
    setActiveStep(activeStep + 1);

    if (
      (activeStep + 1 === 0 || activeStep + 1 < quizLength) &&
      secondsLeft > 0
    ) {
      setTimerisActive(true);
    } else if (activeStep + 1 === quizLength) {
      setActiveStep(quizLength);
      setTimerisActive(false);
    }

    mixpanelTrack(appState, "next-question", {
      ...quizTracker,
      advanceToIdx: activeStep + 1,
    });
  };

  const restartGame = () => {
    setQuizScoreInfo({});
    setActiveStep(-1);
    setNumCorrect(0);

    if (randomize) {
      setQuestions(shuffleAndTrim(quizData));
    }

    if (timeLimit) {
      setSecondsLeft(timeLimit);
    }
    history.push(quizData.linkTo);
  };

  const qstnComps = questions.map((question, idx) => {
    return (
      idx === activeStep && (
        <div key={idx}>
          <Question
            question={question}
            onSelect={handleNewAnswer(idx)}
            onNext={nextQuestion}
            explainerOn={quizData.explainerOn}
            appearance={quizData.appearance}
            seconds={quizData.timeLimit}
            totalAvailableTime={quizData.timeLimit}
            halfOrderENumpad={quizData.halfOrderENumpad}
            secondsLeft={secondsLeft}
            isChallengeQuiz={isChallengeQuiz}
            timerIsActive={timerIsActive}
            setTimerisActive={setTimerisActive}
            numCorrect={numCorrect}
            user={user}
            auth={auth}
            score={calculateScore(numCorrect, quizLength, isChallengeQuiz)}
            theme={theme}
            quizLength={quizLength}
            questions={questions}
            activeStep={activeStep}
            setNumCorrect={setNumCorrect}
          />
        </div>
      )
    );
  });

  const quiz = <QuestionContainer>{qstnComps}</QuestionContainer>;

  const isTimedQuiz = quizData.timeLimit;

  const saveQuizData = () => {
    const score = calculateScore(numCorrect, quizLength, isChallengeQuiz);

    const details = {
      quizLength: quizLength,
      score: score,
      name: quizData.name,
      linkTo: quizData.linkTo,
      playEndSound: playEndSound,
      showNumCorrectOnly: isTimedQuiz,
      soundOn: soundOn,
      challenge: isChallengeQuiz,
      onPlayAgainClick: restartGame,
      questions: questions,
      firestore: firestore,
      quizId: quizData.id,
      user: user,
      playOutroMusic: true,
      saveQuizScore: true,
      setActiveStep: setActiveStep,
    };
    localStorage.setItem("lastQuizQuestions", JSON.stringify(questions));
    setQuizScoreInfo(details);
    history.push(`${quizData.linkTo}/outro`);
  };

  const ogQuiz = quizList.filter((quizObj) => quizObj.id === quizData.id)[0];
  const ogQuizThumb = ogQuiz && ogQuiz.thumbnailSrc;

  const fallbackDescription =
    "See how well you can do on this quiz, and how you stack up to others. Put your brain, reasoning, and misconceptions to the test.";
  const metaDescriptionInput =
    quizData.subheading || quizData.description || fallbackDescription;

  function sanitizeHtmlFromText(dirty) {
    return sanitizeHtml(dirty, {
      allowedTags: ["p"],
      allowedAttributes: {},
    });
  }
  const metaDescription = sanitizeHtmlFromText(metaDescriptionInput);

  // When the quiz is over, store the questions in LS
  if (activeStep >= quizLength) {
    try {
      localStorage.setItem("lastQuizQuestions", JSON.stringify(questions));
    } catch (e) {}
  }

  return (
    <div>
      <Helmet>
        <title>{`Quizicist: ${quizData.name}`}</title>
        <meta name="description" content={metaDescription} />
        <meta property="og:title" content={quizData.name} />
        <meta property="og:type" content="website" />
        <meta property="og:description" content={metaDescription} />
        <meta
          property="og:url"
          content={`https://quizicist.com${quizData.linkTo}`}
        />
        <meta
          property="og:image"
          content={`https://quizicist.com${ogQuizThumb}`}
        />
        <meta property="og:image:type" content="image/jpeg" />
        <meta property="og:image:width" content="356" />
        <meta property="og:image:height" content="200" />
        <meta name="twitter:site" content="@Quizicists" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={quizData.name} />
        <meta name="twitter:description" content={metaDescription} />
        <meta
          name="twitter:image"
          content={`https://quizicist.com${ogQuizThumb}`}
        />
        <meta name="author" content="Quizicist" />
      </Helmet>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {/* {console.log("quizData", quizData)} */}
        <Paper elevation={0}>
          <div>
            {activeStep === -1 ? (
              <Preview
                description={quizData.description}
                numberOfQuestions={initialQuestions.length}
                subDescription={quizData.subDescription}
                onLaunch={nextQuestion}
                countdownTime={quizData.countdownTime}
                timeLimit={quizData.timeLimit}
                name={quizData.name}
                linkTo={quizData.linkTo}
                firestore={firestore}
                user={user}
                quizId={quizData.id}
                isChallengeQuiz={isChallengeQuiz}
              />
            ) : (
              activeStep < quizLength && quiz
            )}
          </div>
        </Paper>
      </ThemeProvider>
    </div>
  );
}

export default Quiz;
