import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  questionIndexText: {
    marginTop: "5px",
    color: "#fff",
    "& > *": {
      fontSize: 24,
    },
    [theme.breakpoints.down("xs")]: {
      "& > *": {
        fontSize: 18,
      },
    },
  },
}));

const QuestionIndex = ({ index, quizLength, variant = "h3" }) => {
  const classes = useStyles();
  return (
    <div className={classes.questionIndexText}>
      <Typography variant={variant} component="span">
        Question <b>{index}</b> of {quizLength}
      </Typography>
    </div>
  );
};

export default QuestionIndex;
