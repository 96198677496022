const monstrousOrigins = {
  name: 'Monstrous Origins',
  id: '83zAtDinCgMCHIMdsHpH',
  linkTo: "/quizzes/monstrousOrigins",
  explainerOn: true,
  subheading: `It's the perfect season for a monster movie marathon. But where did those movie monsters come from, and how well do you know their monstrous origins?`,
  description: `Most of us have seen a horror movie or two, and felt that unique thrill that comes from experiencing fear when we know that we are perfectly safe. Fear is not uniquely human, but creating monsters to embody our fears <i>is</i>. Next time you're watching a monster movie, ask yourself: What are its monstrous origins?`,
  questions: [
      {
          //question 1//
          question: "Frankenstein's Monster first appeared in an 1818 novel by whom?",
          choices: ['Mary Shelley', 'Percy Shelley', 'John Keats', 'George Byron'],
          answerIdx: 0,    
          furtherInfo: {
              explanation: (
                  `Though most of us probably think of something like Boris Karloff's shambling portrayal in the 1931 film <i>Frankenstein</i>, the tragic monster originated far earlier in Mary Shelley's 1818 novel of the same name.`
              ),
              sources: [
                  {
                      source: "Reader's Digest",
                      url: 'https://www.rd.com/list/origins-spookiest-halloween-creatures/',
                  },
              ],
          },
      },
      {
          //question 2//
          question: "First popularized by Bram Stoker in his 1897 novel, <i>Dracula</i>, vampires originally appeared in Slavic folklore to explain what?",
          choices: ['Frostbite', 'Unusual deaths', 'Infectious diseases', 'Anemia'], 
          answerIdx: 2,    
          furtherInfo: {
              explanation: (
                  `Though vampire folklore has been attributed to many things over the years, including porphyria, vampires in Slavic folklore existed as an explanation for the spread of infectious diseases like tuberculosis.`
              ),
              sources: [
                  {
                      source: "Reader's Digest",
                      url: 'https://www.rd.com/list/origins-spookiest-halloween-creatures/',
                  },
              ],
          },
      },
      {
          //question 3//
          question: "The myth of the werewolf is one of our oldest, first mentioned in Plato's <i>Republic</i> which dates back to what year?",
          choices: ['1380 CE', '380 CE', '130 CE', '380 BCE'], 
          answerIdx: 3,    
          furtherInfo: {
              explanation: (
                  `Though accounts of men turning into wolves can be traced as far back as 2,100 BCE and the <i>Epic of Gilgamesh</i>, werewolves as we know them date back to the ancient Greeks and Romans, and more specifically to the story of King Lycaon told in Plato's <i>Republic</i> which dates back to 380 BCE.`
              ),
              sources: [
                  {
                      source: 'The Conversation',
                      url: 'https://theconversation.com/the-ancient-origins-of-werewolves-104775',
                  },
              ],
          },
      },
      {
          //question 4//
          question: "Zombies are a fairly recent monster tale that originated in what country?",
          choices: ['The United States', 'Haiti', 'Jamaica', 'Nigeria'],
          answerIdx: 1,    
          furtherInfo: {
              explanation: (
                  `Drawing on the mix of African religions carried by the enslaved peoples forced to work the plantations, zombies originated in Haiti and are often understood to be a religious allegory for the brutality and dehumanization inherent in slavery.`
              ),
              sources: [
                  {
                      source: "Reader's Digest",
                      url: 'https://www.rd.com/list/origins-spookiest-halloween-creatures/',
                  },
              ],
          },
      },
      {
          //question 5//
          question: "Our modern image of the Grim Reaper dates back to what historic pandemic?",
          choices: ['The Black Death', 'Cholera outbreaks', 'The Spanish Flu', 'The Plague of Justinian'],
          answerIdx: 0,    
          furtherInfo: {
              explanation: (
                  `Our modern image of the Grim Reaper as a tall skeletal figure in a black robe wielding a scythe dates back to the time of the Black Death when images of the personification of Death as an indiscriminate reaper cutting down lives like wheat in a field became popular motifs.`
              ),
              sources: [
                  {
                      source: "Reader's Digest",
                      url: 'https://www.rd.com/list/origins-spookiest-halloween-creatures/',
                  },
              ],
          },
      },
      {
          //question 6//
          question: "Less than 30 years old, the Chupacabra most likely owes its origin to what 1995 movie?",
          choices: ['Shallow Grave', 'Strange Days', 'Species', '12 Monkeys'],
          answerIdx: 2,    
          furtherInfo: {
              explanation: (
                  `Translating literally to "goat-sucker", the chupacabra most likely owes its existence to the 1995 sci-fi horror film <i>Species</i>.`
              ),
              sources: [
                  {
                      source: 'Encyclopedia Britannica',
                      url: 'https://www.britannica.com/topic/chupacabra',
                  },
              ],
          },
      },
      {
          //question 7//
          question: "The  Wendigo has been making its way into pop culture, but it was originally a creature from the folklore of the Algonquian-speaking First Nations and served as a warning against what?",
          choices: ['Disease', 'Colonists', 'Fear', 'Selfishness'],
          answerIdx: 3,    
          furtherInfo: {
              explanation: (
                  `As a myth of the Algonquian-speaking First Nations, the monstrous, cannibalistic nature of the  Wendigo served as a warning against personal selfishness to the detriment of the community.`
              ),
              sources: [
                  {
                      source: 'The Canadian Encyclopedia',
                      url: 'https://www.thecanadianencyclopedia.ca/en/article/windigo',
                  },
              ],
          },
      },
      {
          //question 8//
          question: "Belief in ghosts of one form or another is a cultural universal, but there is an audio frequency that may be helping along this belief. What is the 'fear frequency'?",
          choices: ['8.9 Hz', '18.9 Hz', '28.9 Hz', '48.9 Hz'],
          answerIdx: 1,    
          furtherInfo: {
              explanation: (
                  `While belief in ghosts seems to have been around as long as we have, in 1998 British engineer Vic Tandy published his findings on what became known as the 'fear frequency': 18.9 Hz. Though we aren't aware of it, our eyes vibrate at a frequency very close to 18.9 Hz which means when things in our environment, say an old electric fan, vibrate at the same frequency it can cause us to see visual aberrations; like, say, an ominous but vague figure out of the corner of our eyes.`
              ),
              sources: [
                  {
                      source: 'Popular Science',
                      url: 'https://www.popsci.com/story/science/ghosts-real-science/',
                  },
              ],
          },
      },
      {
          //question 9//
          question: "The Headless Horseman is a Halloween staple and owes much of its popularity in the United States to <i>The Legend of Sleepy Hollow</i> by Washington Irving, but do you know who Irving was likely inspired by?",
          choices: ['Nathaniel Hawthorne', 'Sir Walter Scott', 'Ichabod Crane', 'Walt Whitman'],
          answerIdx: 1,    
          furtherInfo: {
              explanation: (
                  `The image of a sinister headless horseman can be traced back to the Middle Ages, but Washington Irving's 1820 tale <i>The Legend of Sleepy Hollow</i> was likely directly inspired by Sir Walter Scott's <i>The Chase</i> from 1796.`
              ),
              sources: [
                  {
                      source: 'The History Channel',
                      url: 'https://www.history.com/news/legend-sleepy-hollow-headless-horseman',
                  },
              ],
          },
      },
      {
          //question 10//
          question: "A thoroughly modern monster, Slenderman was created by a user on what website?",
          choices: ['Tumblr', 'Reddit', '4Chan', 'Something Awful'],
          answerIdx: 3,    
          furtherInfo: {
              explanation: (
                  `Slenderman is a prime example of how folklore is adapting to the modern age. This internet boogeyman was born June 8, 2009 as the creation of user Victor Surge on a Something Awful forum.`
              ),
              sources: [
                  {
                      source: 'YouTube',
                      url: 'https://youtu.be/JBVn4SzvImo',
                  },
              ],
          },
      },
  ],

};

export default monstrousOrigins;