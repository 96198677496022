import React, { useState } from "react";
import "../assets/designs/bottomNavbar.css";
import HomeIcon from "../assets/icons/Quizicist Icons/Home Icon-01.svg";
import Ribbon from "../assets/icons/Quizicist Icons/Ribbon-01.svg";
import Avatar from "@material-ui/core/Avatar";
import OutroAuthPopup from "./OutroAuthPopup";
import { Link } from "react-router-dom";
import { useUser, AuthCheck } from "reactfire";
function BottomNav() {
  const user = useUser();
  const [openLoginModal, setOpenLoginModal] = useState(false)
  const photoURL = user.data ? user.data.photoURL : '';


  const showModal = () => setOpenLoginModal(true);

  const LoggedOutNav = () => (
    <div className="bottom-navbar">
    {/* to allow users to move to login or signup */}
      <OutroAuthPopup 
        visible={openLoginModal} 
        setVisible={setOpenLoginModal} 
        text={"Please log in or create an account."}
      />
      <Link to="/quizzes" aria-label="Home">
        <img src={HomeIcon} alt="Home" />
      </Link>
      <div onClick={showModal} style={{cursor:"pointer"}}>
        <img src={Ribbon} alt="Achievements" />
      </div>
      <div onClick={showModal} style={{ cursor: "pointer" }}>
        <Avatar
          src={photoURL}
          alt="Profile Pic"
          style={{ marginBottom: "15px" }}
        />
      </div>
    </div>
  );

  return (
    <AuthCheck fallback={<LoggedOutNav />}>
      <div className="bottom-navbar">
        <Link to="/quizzes" aria-label="Home">
          <img src={HomeIcon} alt="Home" />
        </Link>
        <Link to={"/my-scores"} aria-label="My Scores">
          <img src={Ribbon} alt="Ribbon" />
        </Link>
        <Link to={"/profile"} aria-label="Profile">
          <Avatar src={photoURL} alt="Profile Pic" />
        </Link>
      </div>
    </AuthCheck>
  );
}

export default BottomNav;
