import React, { useState } from "react";

import SignIn from "./SignIn";
import SetUsername from "./profile/SetUsername";
import { useHistory } from "react-router-dom";

export default () => {
  const [showUsername, setShowUsername] = useState(false);
  const [userUid, setUserUid] = useState("");
  const history = useHistory()
  const handleSuccessfulSignUp = ({ user, additionalUserInfo }) => {
    if (additionalUserInfo.isNewUser) {
      setShowUsername(true);
      setUserUid(user.uid);
      // history.goBack()
      return false;
    }
    // history.goBack()
    return false;
  };
  const uiConfig = {
    // signInSuccessUrl: "/quizzes",
    callbacks: {
      signInSuccessWithAuthResult: handleSuccessfulSignUp,
    },
  };
  return showUsername ? (
    <SetUsername userUid={userUid} history={history}/>
  ) : (
    <SignIn signUp={true} showSignOut={true} uiConfig={uiConfig} />
  );
};
