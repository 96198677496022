const chinasUyghurCamps = {
  name: "China's Uyghur Camps",
  id: 'rHx0BZn2WID7VLGaXIzK',
  linkTo: "/quizzes/chinasUyghurCamps",
  explainerOn: true,
  subheading: `Who are the Uyghurs and why is China bent on eradicating them?`,
  description: `The term 'concentration camp' evokes images of World War II: Nazi incarceration sites from the 1930s and 1940s, a hallmark of a defeated regime in a bygone era. However, concentration camps have not been completely banished away into the pages of history; instead, they have re-emerged as modern fixtures in the Xinjiang region of China. What is the truth about China's Uyghur camps?`,
  questions: [
      {
          //question 1//
          question: `Who are the Uyghurs?`,
          choices: ['A Muslim ethnic group', 'An indigenous Chinese minority', 'A Chinese political group', 'A terrorist organization'],
          answerIdx: 0,    
          furtherInfo: {
              explanation: (
                  `The Uyghurs are a Muslim ethnic group living primarily in the Xinjiang Autonomous Region (XUAR) in the far northwestern region of China.`
              ),
              sources: [
                  {
                      source: 'Congressional Research Service',
                      url: 'https://crsreports.congress.gov/product/pdf/IF/IF10281',
                  },
              ],
        },
      },
      {
          //question 2//
          question: `When did China gain control of the Xinjiang region?`,
          choices: ['1929', '1949', '1969', '1989'],
          answerIdx: 1,    
          furtherInfo: {
              explanation: (
                  `China gained control of the Xinjiang region in 1949 and established the XUAR six years later in 1955. Prior to Chinese rule, the region saw two short-lived semi-autonomous East Turkestan Republics in the 1930s and 1940s.`
              ),
              sources: [
                  {
                      source: 'Congressional Research Service',
                      url: 'https://crsreports.congress.gov/product/pdf/IF/IF10281',
                  },
              ],
        },
      },
      {
          //question 3//
          question: `Uyghurs make up what percentage of the 24.8 million population of Xinjiang?`,
          choices: ['5%', '25%', '45%', '75%'],
          answerIdx: 2,    
          furtherInfo: {
              explanation: (
                  `In the 1990s Uyghurs made up the majority of the Xinjiang region, however today they make up 45% of the population due to the Chinese government's push to draw Han Chinese citizens into the area through economic incentives.`
              ),
              sources: [
                  {
                      source: 'Congressional Research Service',
                      url: 'https://crsreports.congress.gov/product/pdf/IF/IF10281',
                  },
              ],
        },
      },
      {
          //question 4//
          question: `What precipitated China's policies targeting the Uyghur population?`,
          choices: ['Uyghur demonstrations in 2009', 'Clashes between Uyghurs and Xinjiang security personnel between 2013 and 2015', '3 violent attacks in 2014 carried out by Uyghurs against Han Chinese citizens', 'All of the above'],
          answerIdx: 3,    
          furtherInfo: {
              explanation: (
                  `All of the above incidents of unrest and conflict between the Uyghur and Han Chinese populations of Xinjiang led to the institution of policies, such as large-scale arrests and intensive security measures targeting Uyghurs, meant to curb acts of terrorism. Though the Chinese government has been accused of using counterterrorism as a shield to carry out a forced assimilation agenda.`
              ),
              sources: [
                  {
                      source: 'Congressional Research Service',
                      url: 'https://crsreports.congress.gov/product/pdf/IF/IF10281',
                  },
              ],
        },
      },
      {
          //question 5//
          question: `What is <i>sinicization</i>?`,
          choices: ['The process of synchronizing surveillance apparatus', 'The act of invoking sinister consequences to induce compliance', 'The act of bringing those not of Chinese descent under the influence of Chinese culture', 'The process of becoming more cynical'],
          answerIdx: 2,    
          furtherInfo: {
              explanation: (
                  `Sinicization is the process of bringing those not of Chinese descent under the influence of Chinese culture. It is a process that can be incredibly invasive and in most cases seeks to erase the original culture by overwriting it with Chinese customs. Sinicization has notably happened in Korea, Japan, Vietnam, and a policy of promoting sinicization has been enacted over the Uyghur people since 2017.`
              ),
              sources: [
                  {
                      source: 'World Atlas',
                      url: 'https://www.worldatlas.com/articles/what-is-sinicization.html',
                  },
              ],
        },
      },
      {
          //question 6//
          question: `Approximately how many Uyghurs and members of other ethnic minority groups have been detained since 2017?`,
          choices: ['200,000', '2,000,000', '20,000,000', '200,000,000'],
          answerIdx: 1,    
          furtherInfo: {
              explanation: (
                  `Since 2017, as many as 2 million people have been detained by the Xinjiang authorities in 'reeducation' centers.`
              ),
              sources: [
                  {
                      source: 'Congressional Research Service',
                      url: 'https://crsreports.congress.gov/product/pdf/IF/IF10281',
                  },
              ],
        },
      },
      {
          //question 7//
          question: `What is an example of an offense for which people have been detained in Xinjiang reeducation centers?`,
          choices: ['Having more children than is allowed', 'Holding a passport without visiting a foreign country', 'Having a long beard', 'All of the above'],
          answerIdx: 3,    
          furtherInfo: {
              explanation: (
                  `Uyghurs and members of other Muslim ethnic minorities in the Xinjiang region can be detained for any number of reasons including violating the family planning policy by having more children than allowed, holding a passport <i>without</i> visiting a foreign country, and having a long beard.`
              ),
              sources: [
                  {
                      source: 'CNN',
                      url: 'https://www.cnn.com/interactive/2020/02/asia/xinjiang-china-karakax-document-intl-hnk/',
                  },
              ],
        },
      },
      {
          //question 8//
          question: `The Xinjiang camps are often referred to as schools or vocational training centers; what are those detained in the camps forced to study?`,
          choices: ['Marxism', 'Job skills', 'Shintoism', 'Modern Geography'],
          answerIdx: 0,    
          furtherInfo: {
              explanation: (
                  `Those detained in the Xinjiang camps are compelled to study Marxism as well as being forced to work in factories and renounce their religion. Though the government continues to claim that these facilities provide vocational training and are necessary to fight extremism in the region.`
              ),
              sources: [
                  {
                      source: 'NBC News',
                      url: 'https://www.nbcnews.com/news/world/new-details-torture-cover-ups-china-s-internment-camps-revealed-n1270014',
                  },
              ],
        },
      },
      {
          //question 9//
          question: `According to survivors interviewed by Amnesty International, what is a common punishment in the camps?`,
          choices: ['Sleep deprivation', 'Restraint in a tiger chair', 'Both A and B', 'Wage garnishment'],
          answerIdx: 2,    
          furtherInfo: {
              explanation: (
                  `According to survivors interviewed by Amnesty International, those detained in Xinjiang's camps are subject to a long list of depredations as well as both sleep deprivation and being locked into a device known as a tiger chair. A tiger chair, for those unfamiliar with it, is a torture device used to lock victims into increasingly painful stress positions. One account recounts a man being held immobile in a tiger chair for three days in front of his cellmates, who were explicitly forbidden from aiding him.`
              ),
              sources: [
                  {
                      source: 'NBC News',
                      url: 'https://www.nbcnews.com/news/world/new-details-torture-cover-ups-china-s-internment-camps-revealed-n1270014',
                  },
              ],
        },
      },
      {
          //question 10//
          question: `On January 19, 2021, the U. S. State Department announced it found that China committed crimes against humanity and genocide in Xinjiang.`,
          choices: ['True', 'False'],
          answerIdx: 0,    
          furtherInfo: {
              explanation: (
                  `This is true. The U.S. State Department found that China had committed crimes against humanity and genocide in Xinjiang and called on various juridical bodies to pursue accountability for these offenses.`
              ),
              sources: [
                  {
                      source: 'Congressional Research Service',
                      url: 'https://crsreports.congress.gov/product/pdf/IF/IF10281',
                  },
              ],
          },
      },
  ],
};

export default chinasUyghurCamps;
