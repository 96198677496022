 const californiasRedwoods = {
    name: "California's Redwoods",
    id: 'zvBPiEYt8KWMlMAJk4Nd',
    linkTo: "/quizzes/californiasRedwoods",
    explainerOn: true,
    description: "Redwoods are some of the world’s oldest and largest trees. Today, these natural monoliths have only two remaining natural habitats: China and California’s northern region extending into Oregon. How much do you know about these ancient trees?",
    questions: [
        {
            //question 1//
            question: "How many years have redwoods existed?",
            choices: ['240,000', '2,400,000', '24,000,000', '240,000,000'],
            answerIdx: 3,    
            furtherInfo: {
                explanation: (
                    `Redwoods have existed for 240 million years, first appearing just after the dinosaurs and long before humans, birds, spiders, and even flowers.`
                ),
                sources: [
                    {
                        source: 'Sempervirens Fund',
                        url: 'https://sempervirens.org/learn/redwood-facts/',
                    },
                ],
            },
        },
        {
            //question 2//
           question: "How many years ago did redwoods first appear in California?",
            choices: ['200,000', '20,000,000', '200,000,000', '2,000,000,000'],
            answerIdx: 1,    
            furtherInfo: {
                explanation: (
                    `Redwoods first appeared in California around 20 million years ago.`
                ),
                sources: [
                    {
                        source: 'Redwood Facts. (2021). Retrieved 16 July 2021',
                        url: 'https://www.visitredwoods.com/listing/redwood-facts/186/',
                    },
                ],
            },
        },
        {
            //question 3//
            question: "How many species of redwood trees are there?",
            choices: ['3', '13', '30', '300'],
            answerIdx: 0,    
            furtherInfo: {
                explanation: (
                    `There are only 3 species of redwood trees; Coast redwoods (<i>Sequoia sempervirens</i>) and Giant sequoias (<i>Sequoiadendron giganteum</i>) are both native to California, and dawn redwoods (<i>Metasequoia glyptostroboides</i>) which are native to Central China.`
                ),
                sources: [
                    {
                        source: 'Redwood Facts. (2021). Retrieved 16 July 2021',
                        url: 'https://www.visitredwoods.com/listing/redwood-facts/186/',
                    },
                ],
            },
        },
        {
            //question 4//
            question: "How many years can coast redwood trees live?",
            choices: ['20', '200', '2,000', '20,000'],
            answerIdx: 2,    
            furtherInfo: {
                explanation: (
                    `Coast redwoods can live up to 2,000 years, though their average lifespan tends to be 500-700 years.`
                ),
                sources: [
                    {
                        source: 'Redwood Facts. (2021). Retrieved 16 July 2021',
                        url: 'https://www.visitredwoods.com/listing/redwood-facts/186/',
                    },
                ],
            },
        },
        {
            //question 5//
            question: "How many years can giant sequoias live?",
            choices: ['3,200', '320', '32', '3'],
            answerIdx: 0,    
            furtherInfo: {
                explanation: (
                    `Longer lived than coast redwoods, giant sequoias can live up to 3,200 years.`
                ),
                sources: [
                    {
                        source: 'Redwood Facts. (2021). Retrieved 16 July 2021',
                        url: 'https://www.visitredwoods.com/listing/redwood-facts/186/',
                    },
                ],
            },
        },
        {
            //question 6//
            question: "How many feet tall is the California redwood Hyperion, the tallest tree in the world?",
            choices: ['38', '380', '3,800', '38,000'],
            answerIdx: 1,    
            furtherInfo: {
                explanation: (
                    `Hyperion, discovered in 2006, is the tallest tree in the world at 380 feet, which is nearly six stories taller than the Statue of Liberty.`
                ),
                sources: [
                    {
                        source: 'Redwood Facts. (2021). Retrieved 16 July 2021',
                        url: 'https://www.visitredwoods.com/listing/redwood-facts/186/',
                    },
                ],
            },
        },
        {
            //question 7//
            question: "How many feet out from their trunks do redwood roots extend?",
            choices: ['10', '100', '1,000', '10,000'],
            answerIdx: 1,    
            furtherInfo: {
                explanation: (
                    `The roots of redwood trees can extend up to 100 feet out from their trunks, entangling with the roots of other trees in their groves and allowing them to withstand strong winds despite the relatively shallow depth of their root systems (6-12ft).`
                ),
                sources: [
                    {
                        source: 'Sempervirens Fund',
                        url: 'https://sempervirens.org/learn/redwood-facts/',
                    },
                ],
            },
        },
        {
            //question 8//
            question: "True or False: Redwoods can generate rain.",
            choices: ['True', 'False'],
            answerIdx: 0,    
            furtherInfo: {
                explanation: (
                    `This is true. Redwoods require large amounts of moisture and have adapted to help form their own environment. Redwood leaves can not only draw moisture directly from fog, but also condense that fog into drops to rain down around them. They're able to release terpenes as well, which condense moisture in the air to form clouds and help cool the forest.`
                ),
                sources: [
                    {
                        source: 'Sempervirens Fund',
                        url: 'https://sempervirens.org/learn/redwood-facts/',
                    },
                ],
            },
        },
        {
            //question 9//
            question: "Redwoods can sequester how many times more carbon than the average tree:",
            choices: ['0.3x', '3x', '6x', '9x'],
            answerIdx: 1,    
            furtherInfo: {
                explanation: (
                    `Because they tend to be so much larger and longer lived than other trees, redwoods are able to capture and sequester 3x more carbon.`
                ),
                sources: [
                    {
                        source: 'Sempervirens Fund',
                        url: 'https://sempervirens.org/learn/redwood-facts/',
                    },
                ],
            },
        },
        {
            //question 10//
            question: "How many acres of redwood forest are currently protected?",
            choices: ['160', '1,600', '16,000', '160,000'],
            answerIdx: 3,    
            furtherInfo: {
                explanation: (
                    `Today 160,000 acres of redwood forest are protected by federal, state, county, and local parks.`
                ),
                sources: [
                    {
                        source: 'Redwood Facts. (2021). Retrieved 16 July 2021',
                        url: 'https://www.visitredwoods.com/listing/redwood-facts/186/',
                    },
                ],
            },
        },
    ],

};

export default californiasRedwoods;
