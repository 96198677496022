const dd5PuttingItAllTogether = {
  name: "Drawdown 5: Putting it All Together",
  id: "wQWOVJAry7K8g2OwCYfT",
  linkTo: "/quizzes/dd5-putting-it-all-together",
  explainerOn: true,
  subheading: `Put it all together, and what do you get? A clean and habitable planet.`,
  description:
    "No one solution is big enough on its own to tackle climate change. We need many solutions, all working together, to take on this challenge. <i>What</i> are those solutions, and <i>how</i> do we put them together?",
  questions: [
    {
      //question 1//
      question:
        "We <i>already</i> have <b>all</b> the solutions we need to tackle climate change.",
      choices: ["True", "False"],
      answerIdx: 0,
      furtherInfo: {
        explanation: `<b>This is true.</b> Though we need new technologies to make our solutions more efficient and cheaper, right now we have all the solutions we need to stop climate change.`,
        sources: [
          {
            source: "Project Drawdown: Climate Solutions 101",
            url:
              "https://drawdown.org/climate-solutions-101/unit-5-putting-it-all-together",
          },
        ],
      },
    },
    {
      //question 2//
      question: "It is not economical to tackle climate change right now.",
      choices: ["True", "False"],
      answerIdx: 1,
      furtherInfo: {
        explanation: `<b>This is false.</b> Tackling climate change is arguably <i>the best thing</i> we can do for the future state of the economy.`,
        sources: [
          {
            source: "Project Drawdown: Climate Solutions 101",
            url:
              "https://drawdown.org/climate-solutions-101/unit-5-putting-it-all-together",
          },
        ],
      },
    },
    {
      //question 3//
      question:
        "If we invest $28 trillion in climate change solutions initially, how much money will we make back?",
      choices: ["$145,000", "$145 million", "$145 billion", "$145 trillion"],
      answerIdx: 3,
      furtherInfo: {
        explanation: `If $28 trillion were invested today, we would make back <b>$145 trillion</b>.`,
        sources: [
          {
            source: "Project Drawdown: Climate Solutions 101",
            url:
              "https://drawdown.org/climate-solutions-101/unit-5-putting-it-all-together",
          },
        ],
      },
    },
    {
      //question 4//
      question:
        "What is the approximate <i>investment to return ratio</i> of a $28 trillion investment which yields $145 trillion in profit?",
      choices: ["1:1", "1:2", "1:5", "1:15"],
      answerIdx: 2,
      furtherInfo: {
        explanation: `The <i>investment to return ratio</i> for this is <b>1:5</b>. (145 / 28 = 5.2)`,
        sources: [
          {
            source: "Project Drawdown: Climate Solutions 101",
            url:
              "https://drawdown.org/climate-solutions-101/unit-5-putting-it-all-together",
          },
        ],
      },
    },
    {
      //question 5//
      question:
        "Of the solutions we have today, about how many would we need working in tandem to combat climate change?",
      choices: ["8", "80", "800", "8,000"],
      answerIdx: 1,
      furtherInfo: {
        explanation: `If we implement <b>just 80</b> of the climate solutions that already exist, they would be enough to stop climate change.`,
        sources: [
          {
            source: "Project Drawdown: Climate Solutions 101",
            url:
              "https://drawdown.org/climate-solutions-101/unit-5-putting-it-all-together",
          },
        ],
      },
    },
    {
      //question 6//
      question:
        "According to Project Drawdown’s ranked list of climate solutions, which single solution has the greatest potential impact?",
      choices: [
        "onshore wind turbines",
        "silvopasture",
        "plant-rich diets",
        "bamboo production",
      ],
      answerIdx: 0,
      furtherInfo: {
        explanation: `According to Project Drawdown’s ranking, first released in 2017, <b>onshore wind turbines</b> are the single solution with the greatest potential impact on our global emissions.`,
        sources: [
          {
            source: "Project Drawdown: Climate Solutions 101",
            url:
              "https://drawdown.org/climate-solutions-101/unit-5-putting-it-all-together",
          },
        ],
      },
    },
    {
      //question 7//
      question:
        "Climate solutions with “co-benefits” go beyond addressing greenhouse gasses (GHG).",
      choices: ["True", "False"],
      answerIdx: 0,
      furtherInfo: {
        explanation: `<b>This is true.</b> Climate solutions with co-benefits go beyond addressing GHGs and positively affect other sectors.`,
        sources: [
          {
            source: "Drawdown Review",
            url: "https://drawdown.org/drawdown-review",
          },
        ],
      },
    },
    {
      //question 8//
      question:
        "A climate solution that protects and restores an ecosystem is also a ______ solution.",
      choices: ["Health", "Biodiversity", "Social", "Economic"],
      answerIdx: 1,
      furtherInfo: {
        explanation: `A climate solution that protects and restores an ecosystem is also a <b>biodiversity solution</b>. This is an example of a <i>climate solution with a co-benefit</i>.`,
        sources: [
          {
            source: "Drawdown Review",
            url: "https://drawdown.org/drawdown-review",
          },
        ],
      },
    },
    {
      //question 9//
      question:
        "<i>Plant-rich diets</i> contribute to protecting land sinks by being better for the soil.",
      choices: ["True", "False"],
      answerIdx: 1,
      furtherInfo: {
        explanation: `<b>This is false.</b> Plant-rich diets contribute to protecting land sinks by reducing demand for meat, which reduces the need to clear forests for pasture land.`,
        sources: [
          {
            source: "Drawdown Review",
            url: "https://drawdown.org/drawdown-review",
          },
        ],
      },
    },
    {
      //question 10//
      question:
        "If we <i>aggressively</i> implement climate change solutions, what is the <i>earliest</i> we could reach drawdown?",
      choices: ["2030s", "2040s", "2050s", "2060s"],
      answerIdx: 1,
      furtherInfo: {
        explanation: `If we aggressively implement climate change solutions, we could reach drawdown by the <b>2040s</b>. If we are slightly less aggressive, we could see a drawdown by the 2060s.`,
        sources: [
          {
            source: "Project Drawdown: Climate Solutions 101",
            url:
              "https://drawdown.org/climate-solutions-101/unit-5-putting-it-all-together",
          },
        ],
      },
    },
  ],
};

export default dd5PuttingItAllTogether;
