import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    color: "#fff",
    [theme.breakpoints.up('lg')]: {
      margin: 'auto',
      width: 960,
    },
  },
}));

export default ({ children }) => {
  const classes = useStyles();

  return <div className={classes.container}>{children}</div>;
};
