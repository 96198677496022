import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Explainer from "./Explainer";
import MultipleChoice from "./MultipleChoice";
import Numpad from "./Numpad";
import Navbar from "../Navbar";
import BottomNav from "../BottomNav";
import useWindowDimensions from "../../utils/useWindowDimensions";
import HalfOrderENumpad from "./HalfOrderENumpad";
import QuestionBox from "./QuestionBox";
import Figures from "./Figures";
import Timer from "./Timer";
import Score from "./Score";
import Progress from "./Progress.js";
import TimerProgressBar from "./TimerProgressBar";
import QuestionIndex from "./QuestionIndex";
import useDeviceDetect from "../../hooks/useDeviceDetect";

const useStyles = makeStyles((theme) => ({
  breakpoints: {
    values: {
      md: 768,
    },
  },
  questionText: {
    fontSize: "24px",
    fontWeight: 500,
    fontFamily: "Helvetica Neue",
    letterSpacing: "0",
    width: "auto",
    margin: "20px 0",
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      margin: "20px 20px",
      textAlign: "center",
    },
    "& > span": {
      textAlign: "center",
      fontWeight: "normal",
      letterSpacing: "0.3px",
      fontSize: "30px",
      lineHeight: "50px",
      "& > b": {
        fontWeight: 900,
        fontSize: "32px",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "22px",
        lineHeight: "26px",
        fontWeight: "normal",

        "& > b": {
          fontWeight: 900,
          fontSize: "24px",
        },
      },
    },
    "& > h4": {
      fontFamily: "Open Sans",
      textAlign: "center",
      fontWeight: "fontWeightMedium",
    },
  },
  resultItem: {
    margin: theme.spacing(2),
  },
  resultImg: {
    width: "100%",
    height: "auto",
    objectFit: "contain",
  },
  empty: {
    paddingBottom: theme.spacing(20),
  },
  result: {
    padding: theme.spacing(3),
  },
  homeButton: {
    paddingTop: theme.spacing(1),
    paddingLeft: 30,
    paddingRight: 30,
    marginTop: theme.spacing(2),
  },
  homeButtonText: {
    color: "#FFFFFF",
    textDecoration: "none",
    "& > *": {
      fontFamily: "hotel-solid,sans-serif",
      fontWeight: "900",
    },
  },
  timerContainer: {
    paddingTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    color: "white",
    fontSize: "10px",
  },
  questionInfoBar: {
    [theme.breakpoints.up("xs")]: {
      margin: "8px 20px",
    },
    [theme.breakpoints.up("sm")]: {
      margin: "8px 60px",
    },
    [theme.breakpoints.up("md")]: {
      margin: "8px 120px",
    },
  },
  ChallengeQuizScoreText: {
    color: "#fff",
    [theme.breakpoints.down("xs")]: {
      "& > *": {
        fontSize: 24,
      },
    },
  },
  NonChallengeQuizscoreText: {
    color: "#fff",
    "& > *": {
      fontSize: 36,
    },
    [theme.breakpoints.down("xs")]: {
      "& > *": {
        fontSize: 24,
      },
    },
  },
}));

function Question(props) {
  const { width } = useWindowDimensions();
  const {
    question,
    onSelect,
    onNext,
    explainerOn,
    halfOrderENumpad,
    answerStyle = "vertical",
    secondsLeft,
    isChallengeQuiz,
    timerIsActive,
    totalAvailableTime,
    setTimerisActive,
    numCorrect,
    score,
    theme,
    questions,
    quizLength,
    activeStep,
    user,
    auth,
  } = props;
  const classes = useStyles();
  const [answerIdx, setAnswerIdx] = React.useState(-1);
  const [writeInAnswer, setWriteInAnswer] = React.useState(null);
  const {isMobile} = useDeviceDetect();

  function handleSelect(answer) {
    const correct = answer === question.answerIdx;
    if (answerIdx < 0) {
      setAnswerIdx(answer);
      onSelect(correct);

      if (!explainerOn || (isChallengeQuiz && correct)) {
        onNext();
      }
    }
  }

  function handleWriteIn(answer) {
    const correct = isCorrect(answer);
    setWriteInAnswer(answer);
    onSelect(correct);
    if (correct && isChallengeQuiz) {
      onNext();
    }
  }

  const handleNext = (event) => {
    onNext();
  };

  function isAnswered() {
    return writeInAnswer !== null || answerIdx >= 0;
  }

  const normaliseTimerProgressBar = (value) =>
    ((value - 0) * 100) / (totalAvailableTime - 0);

  function isCorrect(writeInAnswer, answerIdx) {
    if (question.writeInAnswer || question.writeInAnswer === 0) {
      if (writeInAnswer === question.writeInAnswer) {
        return true;
      }

      if (!question.correctRange) {
        return false;
      }

      const [min, max] = question.correctRange;
      return min <= writeInAnswer && writeInAnswer <= max;
    }

    return answerIdx === question.answerIdx;
  }

  return isAnswered() ? (
    <Explainer
      onNext={handleNext}
      correct={isCorrect(writeInAnswer, answerIdx)}
      explanation={question.furtherInfo.explanation}
      sources={question.furtherInfo.sources}
      timerIsActive={timerIsActive}
      setTimerisActive={setTimerisActive}
      secondsLeft={secondsLeft}
      numCorrect={numCorrect}
      score={score}
      theme={theme}
      choices={question.choices}
      answerIdx={answerIdx}
      correctAnswerIdx={question.answerIdx}
      question={question.question}
      isChallengeQuiz={isChallengeQuiz}
      activeStep={activeStep}
      quizLength={quizLength}
    />
  ) : (
    <>
      <Navbar
        className="question-nav"
        filter={true}
        auth={auth}
        username={user && user.firestoreUser && user.firestoreUser.username}
        photoURL={user && user.authUser && user.authUser.photoURL}
        duringQuiz={activeStep + 1 === 1 ? false : true}
      />
      <QuestionBox>
        <div className={classes.questionText}>
          <span dangerouslySetInnerHTML={{ __html: question.question }}></span>
        </div>
      </QuestionBox>
      <TimerProgressBar
        variant="determinate"
        aria-label="Timer progress bar"
        value={
          timerIsActive
            ? normaliseTimerProgressBar(totalAvailableTime - secondsLeft)
            : 0
        }
      />
      {timerIsActive ? (
        <Container className={classes.timerContainer}>
          <Box display="flex" justifyContent="space-between">
            <Timer color="white" secondsLeft={secondsLeft} />
            <Score score={score} className={classes.ChallengeQuizScoreText} />
          </Box>
        </Container>
      ) : null}
      {question.figures && <Figures figures={question.figures} />}
      {!isChallengeQuiz && (
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          className={classes.questionInfoBar}
        >
          <QuestionIndex index={activeStep + 1} quizLength={quizLength} />
          <Score score={score} className={classes.NonChallengeQuizscoreText} />
        </Box>
      )}
      {width > 700 && quizLength <= 15 && (
        <Progress questions={questions} idx={activeStep} />
      )}
      {question.writeInAnswer || question.writeInAnswer === 0 ? (
        <Container>
          {halfOrderENumpad ? (
            <HalfOrderENumpad onFinish={handleWriteIn} />
          ) : (
            <Numpad onFinish={handleWriteIn} />
          )}
        </Container>
      ) : (
        <MultipleChoice
          quizLength={quizLength}
          choices={question.choices}
          correctAnswerIdx={question.answerIdx}
          answerStyle={answerStyle}
          onAnswer={handleSelect}
        />
      )}
      {width <= 700 && quizLength <= 10 && (
        <Progress questions={questions} idx={activeStep} />
      )}
      {width <= 1025 && quizLength <= 12 && <div style={{ height: 70 }} />}
      {(width <= 1025 && isMobile) ? <BottomNav /> : ""}
    </>
  );
}

Question.propTypes = {
  user: PropTypes.shape({
    firestoreUser: PropTypes.shape({
      username: PropTypes.string,
      photoURL: PropTypes.string,
    }),
  }),
};

export default Question;
