import React from "react";

import SignIn from "./SignIn";

/*
 * Page for reauthentication purposes like when updating password, email, etc.
 */
export default ({ signInSuccessWithAuthResult }) => {
  const uiConfig = {
    callbacks: {
      signInSuccessWithAuthResult,
    },
  };
  return <SignIn uiConfig={uiConfig} isReauthenticate={true} />;
};
