// *** DO NOT EDIT! This file was automatically generated by <repo-root>/tools/thousandOrNot. View the README file in that directory for build instructions.

const tenThousandOrNot = {
  id: "tenThousandOrNot",
  name: 'Ten Thousand Or Not?',
  linkTo: "/quizzes/tenThousandOrNot",
  description: `How many times can you spot: what equals TEN THOUSAND, and what does NOT?`,
  timeLimit: 90, // seconds
  countdownTime: 3, // seconds
  randomize: true,
  challenge: true,
  explainerOn: true,
  questions: [
  
      
    {
      question: `
 Does this equal ten thousand?
<br />
<b> 500,000 / (10 * 5)
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
      
    {
      question: `
 Does this equal ten thousand?
<br />
<b> 250,000 / 25
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
      
    {
      question: `
 Does this equal ten thousand?
<br />
<b> 10<sup>6</sup> / 100
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
      
    {
      question: `
 Does this equal ten thousand?
<br />
<b> (700,000 – 100,000) / 60
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
      
    {
      question: `
 Does this equal ten thousand?
<br />
<b> 500 * 500 / 25
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
      
    {
      question: `
 Does this equal ten thousand?
<br />
<b> 9,500 / 0.95
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
      
    {
      question: `
 Does this equal ten thousand?
<br />
<b> (10,000 + 90,000) / 10
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
      
    {
      question: `
 Does this equal ten thousand?
<br />
<b> 10 * 10<sup>4</sup> / 10
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
      
    {
      question: `
 Does this equal ten thousand?
<br />
<b> 20,000 / 2
</b>`,

      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
      
    {
      question: `
 Does this equal ten thousand?
<br />
<b> (999,999 + 1) / 100
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
      
    {
      question: `
 Does this equal ten thousand?
<br />
<b> (100,000,000)<sup>(1/2)</sup>
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
      
    {
      question: `
 Does this equal ten thousand?
<br />
<b> 555,000 / 55.5
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
      
    {
      question: `
 Does this equal ten thousand?
<br />
<b> 87,560,000 / 8,756
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
      
    {
      question: `
 Does this equal ten thousand?
<br />
<b> 900 * 1,000 / 90
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
      
    {
      question: `
 Does this equal ten thousand?
<br />
<b> (1,600 * 600) / 96
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
      
    {
      question: `
 Does this equal ten thousand?
<br />
<b> 4,546,453 / 454.6453
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
      
    {
      question: `
 Does this equal ten thousand?
<br />
<b> 10<sup>3</sup> * 10<sup>1</sup>
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
      
    {
      question: `
 Does this equal ten thousand?
<br />
<b> 1 Million / 100
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
      
    {
      question: `
 Does this equal ten thousand?
<br />
<b> 1 Billion / 100,000
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
      
    {
      question: `
 Does this equal ten thousand?
<br />
<b> 1,000 Million / 100,000
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
      
    {
      question: `
 Does this equal ten thousand?
<br />
<b> 1 / 0.0001
</b>`,

      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
      
    {
      question: `
 Does this equal ten thousand?
<br />
<b> (25,000 - 4,000 - 1,000) / 2 * 10<sup>0</sup>
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
      
    {
      question: `
 Does this equal ten thousand?
<br />
<b> (1 Million – 0.5 Million) / 50
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
      
    {
      question: `
 Does this equal ten thousand?
<br />
<b> 10<sup>20</sup> / 10<sup>16</sup>
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
      
    {
      question: `
 Does this equal ten thousand?
<br />
<b> (10<sup>100</sup> * 10<sup>104</sup>) / 10<sup>200</sup>
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
      
    {
      question: `
 Does this equal ten thousand?
<br />
<b> 10<sup>5</sup> / 10 * 1
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
      
    {
      question: `
 Does this equal ten thousand?
<br />
<b> 19,999 – 9,999
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
      
    {
      question: `
 Does this equal ten thousand?
<br />
<b> (900,000 – 800,000) * 0.1
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
      
    {
      question: `
 Does this equal ten thousand?
<br />
<b> 100,005,463 / 10,000.5463
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
      
    {
      question: `
 Does this equal ten thousand?
<br />
<b> (60,000 * 2) / 12
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
      
    {
      question: `
 Does this equal ten thousand?
<br />
<b> (90 * 10<sup>3</sup>) – (800 * 10<sup>2</sup>)
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
      
    {
      question: `
 Does this equal ten thousand?
<br />
<b> (950 * 10<sup>4</sup>  – 9,000,000) / 5 * 0.1
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
      
    {
      question: `
 Does this equal ten thousand?
<br />
<b> (1 Million + 2 Million) / 300
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
      
    {
      question: `
 Does this equal ten thousand?
<br />
<b> 10<sup>(2 * 10)</sup> * 10<sup>(-16)</sup>
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
      
    {
      question: `
 Does this equal ten thousand?
<br />
<b> 9,000 + 999 + 1
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
      
    {
      question: `
 Does this equal ten thousand?
<br />
<b> 8,600 / 0.86
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
      
    {
      question: `
 Does this equal ten thousand?
<br />
<b> 2.5 / 0.00025
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
      
    {
      question: `
 Does this equal ten thousand?
<br />
<b> (Quarter of 500 * 1000) / 12.5
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
      
    {
      question: `
 Does this equal ten thousand?
<br />
<b> 10<sup>(-13)</sup> / 10<sup>(-17)</sup>
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
      
    {
      question: `
 Does this equal ten thousand?
<br />
<b> Seconds in 160 minutes + 400 seconds
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
      
    {
      question: `
 Does this equal ten thousand?
<br />
<b> (986 / 98.6) * 10<sup>3</sup>
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
      
    {
      question: `
 Does this equal ten thousand?
<br />
<b> (700,000 – 10,000) / 69
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
      
    {
      question: `
 Does this equal ten thousand?
<br />
<b> (540,000 – 1,000) / 53.9
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
      
    {
      question: `
 Does this equal ten thousand?
<br />
<b> (290 Million – 285 Million) / 500
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
      
    {
      question: `
 Does this equal ten thousand?
<br />
<b> 4,500,000 * 10<sup>(-1)</sup> / 45
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
      
    {
      question: `
 Does this equal ten thousand?
<br />
<b> 200 * 20 + 30 * 200
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
      
    {
      question: `
 Does this equal ten thousand?
<br />
<b> (750 + 750) * 10 / 1.5
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
      
    {
      question: `
 Does this equal ten thousand?
<br />
<b> 800 * 600 / 48
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
      
    {
      question: `
 Does this equal ten thousand?
<br />
<b> (1000 – 100) * 100 / 9
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
      
    {
      question: `
 Does this equal ten thousand?
<br />
<b> (43,000 / 43) * 10<sup>1</sup>
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
      
    {
      question: `
 Does this equal ten thousand?
<br />
<b> (45 * 1,000) / (45 * 100) * 1000
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
      
    {
      question: `
 Does this equal ten thousand?
<br />
<b> (100<sup>2</sup> + 10<sup>4</sup>) / 2
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
      
    {
      question: `
 Does this equal ten thousand?
<br />
<b> 7,600 + (2.4 * 10<sup>3</sup>)
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
      
    {
      question: `
 Does this equal ten thousand?
<br />
<b> 8,500 + 15,000 / 10<sup>1</sup>
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
      
    {
      question: `
 Does this equal ten thousand?
<br />
<b> 10 * 10 * 10 * 100 * 0.1
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
      
    {
      question: `
 Does this equal ten thousand?
<br />
<b> 90 * 10 / 0.09
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
      
    {
      question: `
 Does this equal ten thousand?
<br />
<b> 60 * 60 * 10<sup>2</sup> / 36
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
      
    {
      question: `
 Does this equal ten thousand?
<br />
<b> (169 * 10 / 13<sup>2</sup>) * 10<sup>3</sup>
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
      
    {
      question: `
 Does this equal ten thousand?
<br />
<b> (4000 / 10) * (10,000 / 400)
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
      
    {
      question: `
 Does this equal ten thousand?
<br />
<b> (700 – 600) * 100
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
      
    {
      question: `
 Does this equal ten thousand?
<br />
<b> (510 / 51) * 10<sup>3</sup>
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
      
    {
      question: `
 Does this equal ten thousand?
<br />
<b> ((Minutes in 6 hours) / 36) * 10<sup>(5-2)</sup>
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
      
    {
      question: `
 Does this equal ten thousand?
<br />
<b> 9 * 10,000 / 9
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
      
    {
      question: `
 Does this equal ten thousand?
<br />
<b> 56.60 / 0.005660
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
      
    {
      question: `
 Does this equal ten thousand?
<br />
<b> 4,000 * 30 / (6 * 2)
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
      
    {
      question: `
 Does this equal ten thousand?
<br />
<b> (96 / 12 * 1000) + 2 * 10<sup>3</sup>
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 0, // Yes
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
      
    {
      question: `
 Does this equal ten thousand?
<br />
<b> 9,860,000 / 968
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 1, // No
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
      
    {
      question: `
 Does this equal ten thousand?
<br />
<b> 10,286 / 10,283
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 1, // No
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
      
    {
      question: `
 Does this equal ten thousand?
<br />
<b> 100,000 – 10
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 1, // No
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
      
    {
      question: `
 Does this equal ten thousand?
<br />
<b> 1,000,000 – 100,000
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 1, // No
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
      
    {
      question: `
 Does this equal ten thousand?
<br />
<b> Seconds in 160 minutes + 600 seconds
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 1, // No
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
      
    {
      question: `
 Does this equal ten thousand?
<br />
<b> 1 billion – 999 million
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 1, // No
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
      
    {
      question: `
 Does this equal ten thousand?
<br />
<b> 0.5 / 0.0005
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 1, // No
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
      
    {
      question: `
 Does this equal ten thousand?
<br />
<b> 10<sup>2</sup> * 10<sup>3</sup>
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 1, // No
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
      
    {
      question: `
 Does this equal ten thousand?
<br />
<b> 10<sup>3</sup> / 10<sup>0</sup>
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 1, // No
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
      
    {
      question: `
 Does this equal ten thousand?
<br />
<b> 550,000 / 50
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 1, // No
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
      
    {
      question: `
 Does this equal ten thousand?
<br />
<b> 9,990,000 – 999
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 1, // No
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
      
    {
      question: `
 Does this equal ten thousand?
<br />
<b> 250,000 – 250
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 1, // No
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
      
    {
      question: `
 Does this equal ten thousand?
<br />
<b> 10<sup>1000</sup> - 10<sup>996</sup>
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 1, // No
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
      
    {
      question: `
 Does this equal ten thousand?
<br />
<b> 10 * 10 / 10 - 3
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 1, // No
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
      
    {
      question: `
 Does this equal ten thousand?
<br />
<b> 1,050,000 - 1,005,000
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 1, // No
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
      
    {
      question: `
 Does this equal ten thousand?
<br />
<b> 96.5 * 10<sup>3</sup> / 965
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 1, // No
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
      
    {
      question: `
 Does this equal ten thousand?
<br />
<b> 100<sup>2</sup> * 100<sup>2</sup>
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 1, // No
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
      
    {
      question: `
 Does this equal ten thousand?
<br />
<b> (235 – 135) * 10<sup>4</sup>
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 1, // No
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
      
    {
      question: `
 Does this equal ten thousand?
<br />
<b> 2.5 billion – 2.49 billion
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 1, // No
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
      
    {
      question: `
 Does this equal ten thousand?
<br />
<b> Seconds in 2 hours + 3000 seconds
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 1, // No
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
      
    {
      question: `
 Does this equal ten thousand?
<br />
<b> 10 * 10 * 10 * 10<sup>2</sup>
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 1, // No
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
      
    {
      question: `
 Does this equal ten thousand?
<br />
<b> (95 * 105)<sup>1/2</sup>
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 1, // No
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
      
    {
      question: `
 Does this equal ten thousand?
<br />
<b> 750 * 100 / 75
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 1, // No
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
      
    {
      question: `
 Does this equal ten thousand?
<br />
<b> 200 * 10<sup>3</sup> – 20 * 10<sup>2</sup>
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 1, // No
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
      
    {
      question: `
 Does this equal ten thousand?
<br />
<b> 999,999 / 999.999
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 1, // No
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
      
    {
      question: `
 Does this equal ten thousand?
<br />
<b> 1,050,099 – 50,099
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 1, // No
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
      
    {
      question: `
 Does this equal ten thousand?
<br />
<b> 10<sup>500</sup> * 10<sup>(-504)</sup>
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 1, // No
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
      
    {
      question: `
 Does this equal ten thousand?
<br />
<b> (10,000 / 500) * (50,000 / 1000)
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 1, // No
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
      
    {
      question: `
 Does this equal ten thousand?
<br />
<b> 600 * 500 / 300
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 1, // No
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
      
    {
      question: `
 Does this equal ten thousand?
<br />
<b> 16 * 6 * 10<sup>3</sup> – 9600
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 1, // No
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
      
    {
      question: `
 Does this equal ten thousand?
<br />
<b> 50,000,000 / 10,000
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 1, // No
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
      
    {
      question: `
 Does this equal ten thousand?
<br />
<b> 250,000 / 250
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 1, // No
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
      
    {
      question: `
 Does this equal ten thousand?
<br />
<b> 10<sup>3</sup> * 10<sup>2</sup>
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 1, // No
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
      
    {
      question: `
 Does this equal ten thousand?
<br />
<b> 20,000 – 2,000
</b>
`,
      choices: ['Yes', 'No'],
      answerIdx: 1, // No
      furtherInfo: {
        explanation: '',
        sources: [{}],
      },
    },
    
  
  ],
     
};

export default tenThousandOrNot;
