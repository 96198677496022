import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import registerIcons from "./utils/registerIcons";
import "../node_modules/react-vis/dist/style.css";
import RootStoreWrapper from "./components/utility/RootStoreWrapper";
import FirebaseWrapper from "./components/utility/FirebaseWrapper";

registerIcons();
ReactDOM.render(
  <FirebaseWrapper>
    <RootStoreWrapper>
      <App />
    </RootStoreWrapper>
  </FirebaseWrapper>,
  document.getElementById("root")
);
