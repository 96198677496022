import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import useLoadedAuthUser from "../../hooks/useLoadedAuthUser";
import useNotificationSystem from "../../hooks/useNotificationSystem.js";

import Form from "./Form";
import AuthCheck from "../AuthCheckWrapper";
import Reauthenticate from "./Reauthenticate";
import ProfileContainer from "./Container";
import Header from "./Header";
import BackgroundContainer from "./BackgroundContainer";

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    color: "#fff",
  },
}));

// Email updating requires re-authentication before updating
export default () => {
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const { addNotification } = useNotificationSystem();

  const user = useLoadedAuthUser((loadedUser) => {
    setEmail(loadedUser.email);
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    user
      .updateEmail(email)
      .then(() => {
        addNotification({
          message: "Email updated",
          level: "success",
        });
      })
      .catch((error) => {
        addNotification({
          message: "Email could not be updated",
          level: "error",
        });
      });
  };

  const inputs = [
    {
      key: "Email",
      label: "Update Email",
      type: "email",
      value: email,
      onChange: (e) => setEmail(e.target.value),
    },
  ];

  return (
    <AuthCheck>
      <Reauthenticate>
        <BackgroundContainer>
          <Header title="Email" />
          <ProfileContainer>
            <div className={classes.container}>
              <Form classes={classes} onSubmit={handleSubmit} inputs={inputs} />
            </div>
          </ProfileContainer>
        </BackgroundContainer>
      </Reauthenticate>
    </AuthCheck>
  );
};
