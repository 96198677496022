import React from "react";
import { Redirect, Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { useUser } from "reactfire";

import centeredContainer from "../cssObjects/centeredContainer";

import { ThemeProvider } from "@material-ui/core";
import Button from "./LandingPageButton";
import LogoImg from "../assets/icons/Quizicist Icons/BrainIcon.svg";
import BackgroundImage from "../assets/NumberBackground-01.svg";

import Logo from "./Logo";

const useStyles = makeStyles((theme) => ({
  container: Object.assign(
    {
      background: `no-repeat 0 0 url(${BackgroundImage}), radial-gradient(circle at 50% 0, #07BBF4 0%, #08B8F5 0.5%, #3124FB 100%)`,
      backgroundSize: "100% 48vh, auto",
    },
    centeredContainer
  ),
  buttonGroup: {
    display: "flex",
    flexDirection: "column",
    marginBottom: theme.spacing(2),
  },
  logoImg: {
    height: "20vh",
  },
  caption: {
    color: "#fff",
    marginBottom: theme.spacing(12),
    fontWeight: 600,
  },
  guestUser: {
    "& > *": {
      color: "#fff",
      textDecoration: "none",
      fontSize: 24,
      fontWeight: 600,
    },
    "& :hover": {
      color: "rgb(240, 240, 240)"
    }
  },
}));

const Landing = ({ theme }) => {
  const { data: user } = useUser();
  const classes = useStyles(
    Object.assign(theme, {
      windowHeight: "100vh",
    })
  );

  if (user) {
    return <Redirect to="/quizzes" />;
  }

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.container}>
        <img src={LogoImg} alt="logo" className={classes.logoImg} />
        <Logo />
        <div className={classes.caption}>Test Your Knowledge</div>
        <div className={classes.buttonGroup}>
          <Button theme={theme} to={"/signup"} color="secondary">
            New User
          </Button>
          <Button theme={theme} to={"/signin"}>
            Login
          </Button>
        </div>
        <div className={classes.guestUser}>
          <Link to={"/quizzes"}>Guest User</Link>
        </div>
      </div>
    </ThemeProvider>
  );
};

export default Landing;
