 const concreteCoastlines = {
    name: 'Concrete Coastlines',
    id: 'QFwuEeVGHU8yDt3IcKL0',
    linkTo: "/quizzes/concreteCoastlines",
    explainerOn: true,
    description: 'Concrete is everywhere, so ubiquitous most of us don’t even notice it anymore. Cheap and easy to acquire, concrete has become the building material of choice, especially when it comes to the ever-growing demand for durable seawalls for coastal cities. But what, exactly, does the use of all this concrete look like for the environment?',
    questions: [
        {
            //question 1//
            question: "How many metric tons of concrete are used per person in the world today?",
            choices: ['0.5', '3', '35', '350'],
            answerIdx: 1,    
            furtherInfo: {
                explanation: (
                    `For every person in the world, 3 metric tons of concrete are used every year. That’s just over 6,600 pounds of concrete used per person, per year in the world today.`
                ),
                sources: [
                    {
                        source: "BBC. The world's growing concrete coasts. BBC Future.",
                        url: 'https://www.bbc.com/future/article/20200811-the-eco-friendly-alternatives-to-ocean-concrete',
                    },
                ],
            },
        },
        {
            //question 2//
            question: "How many metric tons of CO2 does the concrete industry generate each year?",
            choices: ['2,000', '200,000', '2,000,000', '2,800,000,000'],
            answerIdx: 3,    
            furtherInfo: {
                explanation: (
                    `The concrete industry generates 2.8 billion metric tons of CO2 emissions each year, which accounts for 8% of humanity’s total carbon footprint.`
                ),
                sources: [
                    {
                        source: "BBC. The world's growing concrete coasts. BBC Future.",
                        url: 'https://www.bbc.com/future/article/20200811-the-eco-friendly-alternatives-to-ocean-concrete',
                    },
                ],
            },
        },
        {
            //question 3//
            question: "What percentage of the world’s coastal and marine infrastructure uses concrete?",
            choices: ['20%', '50%', '70%', '90%'],
            answerIdx: 2,    
            furtherInfo: {
                explanation: (
                    `70% of the world’s coastal and marine infrastructure uses concrete as a building material.`
                ),
                sources: [
                    {
                        source: "BBC. The world's growing concrete coasts. BBC Future.",
                        url: 'https://www.bbc.com/future/article/20200811-the-eco-friendly-alternatives-to-ocean-concrete',
                    },
                ],
            },
        },
        {
            //question 4//
            question: "How many miles of US coastline are effectively concrete?",
            choices: ['14', '140', '1,400', '14,000'],
            answerIdx: 3,    
            furtherInfo: {
                explanation: (
                    `14,000 miles of the US coastline are effectively concrete.`
                ),
                sources: [
                    {
                        source: "BBC. The world's growing concrete coasts. BBC Future.",
                        url: 'https://www.bbc.com/future/article/20200811-the-eco-friendly-alternatives-to-ocean-concrete',
                    },
                ],
            },
        },
        {
            //question 5//
            question: "What percentage of China’s coastline is effectively concrete?",
            choices: ['6%', '16%', '60%', '96%'],
            answerIdx: 2,    
            furtherInfo: {
                explanation: (
                    `China has encased 60% of its coastline in concrete.`
                ),
                sources: [
                    {
                        source: "BBC. The world's growing concrete coasts. BBC Future.",
                        url: 'https://www.bbc.com/future/article/20200811-the-eco-friendly-alternatives-to-ocean-concrete',
                    },
                ],
            },
        },
        {
            //question 6//
            question: "What is the pH level of seawater?",
            choices: ['0.8', '8', '18', '80'],
            answerIdx: 1,    
            furtherInfo: {
                explanation: (
                    `Seawater usually has a pH of 8`
                ),
                sources: [
                    {
                        source: "BBC. The world's growing concrete coasts. BBC Future.",
                        url: 'https://www.bbc.com/future/article/20200811-the-eco-friendly-alternatives-to-ocean-concrete',
                    },
                ],
            },
        },
        {
            //question 7//
            question: "What is the pH level of standard concrete?",
            choices: ['0.2', '2', '12', '120'],
            answerIdx: 2,    
            furtherInfo: {
                explanation: (
                    `Standard concrete has a pH of 12-13, making it hostile to most marine species.`
                ),
                sources: [
                    {
                        source: "BBC. The world's growing concrete coasts. BBC Future.",
                        url: 'https://www.bbc.com/future/article/20200811-the-eco-friendly-alternatives-to-ocean-concrete',
                    },
                ],
            },
        },
        {
            //question 8//
            question: "Eco-tiles, an alternative to concrete developed for use in marine and coastal building, are made of what percent of slag cement?",
            choices: ['0.7%', '7%', '17%', '70%'],
            answerIdx: 3,    
            furtherInfo: {
                explanation: (
                    `Eco-tiles are made with 70% slag cement, a by-product of the steel industry, giving it an overall lower carbon footprint than traditional concrete.`
                ),
                sources: [
                    {
                        source: "BBC. The world's growing concrete coasts. BBC Future.",
                        url: 'https://www.bbc.com/future/article/20200811-the-eco-friendly-alternatives-to-ocean-concrete',
                    },
                ],
            },
        },
        {
            //question 9//
            question: "While testing the use of eco-tiles in its western waters, Hong Kong saw the number of marine species present double to _________ .",
            choices: ['12', '120', '1,200', '12,000'],
            answerIdx: 0,    
            furtherInfo: {
                explanation: (
                    `Hong Kong saw the number of marine species present in its bay double to 12 where eco-tiles were used.`
                ),
                sources: [
                    {
                        source: "BBC. The world's growing concrete coasts. BBC Future.",
                        url: 'https://www.bbc.com/future/article/20200811-the-eco-friendly-alternatives-to-ocean-concrete',
                    },
                ],
            },
        },
        {
            //question 10//
            question: "How many hectares of land is Hong Kong devoting to a reclamation project using eco-tiles?",
            choices: ['13', '130', '1,300', '13,000'],
            answerIdx: 1,    
            furtherInfo: {
                explanation: (
                    `Hong Kong is devoting 130 hectares, just over 321 acres, to a reclamation project utilizing eco-tiles.`
                ),
                sources: [
                    {
                        source: "BBC. The world's growing concrete coasts. BBC Future.",
                        url: 'https://www.bbc.com/future/article/20200811-the-eco-friendly-alternatives-to-ocean-concrete',
                    },
                ],
            },
        },
    ],

};

export default concreteCoastlines;