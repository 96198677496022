 const revengeBedtimeProcrastination = {
    name: "Can you escape Revenge Bedtime Procrastination?'",
    id: 'HbGkvlZ7WznOtgh1J1QW',
    linkTo: "/quizzes/revengeBedtimeProcrastination",
    explainerOn: true,
    description: "It’s 2am. You really should be in bed. You have work tomorrow and it’s been a long day already. But, somehow, staying up another hour seems like the thing to do. It’s a strangely relatable scene for many people these days. Can you escape the Notorious R.B.P.?",
    questions: [
        {
            //question 1//
            question: "First, tell me: what is revenge bedtime procrastination?",
            choices: ['When you procrastinate going to bed because you’re busy plotting revenge.', 'When you convince someone to stay up late with you even if it’s a work night.', 'When you procrastinate going to bed because you want to regain some ‘lost’ personal time, and your only chance is late at night.', 'When you go to sleep earlier than usual because you don’t feel like doing anything else.'],
            answerIdx: 2,    
            furtherInfo: {
                explanation: (
                    `Revenge bedtime procrastination is when you procrastinate going to bed because you feel the need to regain some ‘lost’ personal time, and your only chance is late at night.`
                ),
                sources: [
                    {
                        source: 'Wired',
                        url: 'https://www.wired.com/story/sleep-procrastination-psychology-tips/',
                    },
                ],
            },
        },
        {
            //question 2//
           question: "People in _________ jobs that take up the majority of their day are at risk for RBP.",
            choices: ['Menial', 'High-stress', 'People-oriented', 'Physically demanding'],
            answerIdx: 1,    
            furtherInfo: {
                explanation: (
                    `People in high-stress jobs that take up the majority of their day are most at risk for revenge bedtime procrastination.`
                ),
                sources: [
                    {
                        source: 'Sleep Foundation',
                        url: 'https://www.sleepfoundation.org/sleep-hygiene/revenge-bedtime-procrastination',
                    },
                ],
            },
        },
        {
            //question 3//
            question: "What’s the revenge part got to do with it?",
            choices: ['‘Revenge bedtime procrastination’ is an English translation of a Chinese expression.', 'You’re subconsciously seeking revenge on yourself for not having fun during the day.', 'A TikTok influencer coined the ‘revenge’ part because she felt her attempts at reclaiming personal time were ‘getting revenge’ on her soon-to-be grumpy morning self.', 'Arya Stark made up the term. Go figure.'],
            answerIdx: 0,    
            furtherInfo: {
                explanation: (
                    `The ‘revenge’ in revenge bedtime procrastination is an English translation of a Chinese expression.`
                ),
                sources: [
                    {
                        source: 'Sleep Foundation',
                        url: 'https://www.sleepfoundation.org/sleep-hygiene/revenge-bedtime-procrastination',
                    },
                ],
            },
        },
        {
            //question 4//
            question: "If you’re staying up because you feel you don’t have enough personal time, a healthy solution is to:",
            choices: ['Stay up even later', 'Make yourself go to bed even if you’re not ready', 'Have a baby so you WILL be tired by bedtime', 'Schedule me-time during the day'],
            answerIdx: 3,    
            furtherInfo: {
                explanation: (
                    `If you find yourself in a high-stress job, or in a situation where there is a high demand on your time, one way to circumvent RBP is to schedule me-time during the day.`
                ),
                sources: [
                    {
                        source: 'Wired',
                        url: 'https://www.wired.com/story/sleep-procrastination-psychology-tips/',
                    },
                ],
            },
        },
        {
            //question 5//
            question: "Behavioral scientist Floor Kroese suggests two specific circumstances that also cause people to stay up in an attempt to ‘regain control’ over their time. One is when a person doesn’t want to quit doing whatever they’re doing ‘just to sleep.’ What’s the other?",
            choices: ['When you don’t like your bedtime routine', 'When you dread going to work in the morning', 'When you suffer nightmares', 'When you have small children'],
            answerIdx: 0,    
            furtherInfo: {
                explanation: (
                    `In addition to not wanting to stop what you’re doing ‘just to sleep’, you may also be staying up because you don’t like your bedtime routine.`
                ),
                sources: [
                    {
                        source: 'Wired',
                        url: 'https://www.wired.com/story/sleep-procrastination-psychology-tips/',
                    },
                ],
            },
        },
        {
            //question 6//
            question: "True or False: People who can’t self-regulate may prefer to shut out emotions rather than process them and, for these people, the time before bed is their only chance to process the day’s emotional backlog (such as frustration, anxiety, fear, and anger).",
            choices: ['True', 'False'],
            answerIdx: 0,    
            furtherInfo: {
                explanation: (
                    `This is true. People who find it difficult to process emotions during the day may find themselves staying up later as the time before bed is the only time they have to do so.`
                ),
                sources: [
                    {
                        source: 'Wired',
                        url: 'https://www.wired.com/story/sleep-procrastination-psychology-tips/',
                    },
                ],
            },
        },
        {
            //question 7//
            question: "Our ability to self-control is at its highest peak at the end of the day, so people suffering RBP are just really bad at self-control.",
            choices: ['True', 'False'],
            answerIdx: 1,    
            furtherInfo: {
                explanation: (
                    `This is false. Our self-control tends to be at its lowest toward the end of the day.`
                ),
                sources: [
                    {
                        source: 'Web MD',
                        url: 'https://www.webmd.com/sleep-disorders/revenge-bedtime-procrastination',
                    },
                ],
            },
        },
        {
            //question 8//
            question: "What is the “Power-Down Hour?”",
            choices: ['A sleep strategy to help people self-regulate by forbidding them to look at any devices putting off blue light (computer, phone) for a full hour before bedtime.', 'A sleep strategy to help people process their emotions by spending 20 minutes doing things that need to be done, 20 minutes doing personal hygiene, and 20 minutes relaxing.', 'A sleep strategy to help people process their emotions by spending 30 minutes meditating, and 30 minutes journaling about the day’s events.', 'Trick question: it’s a workout technique.'],
            answerIdx: 1,    
            furtherInfo: {
                explanation: (
                    `The Power-Down Hour is a sleep strategy designed to help people process their emotions by spending 20 minutes doing necessary tasks, 20 minutes on personal hygiene, and 20 minutes relaxing.`
                ),
                sources: [
                    {
                        source: 'Wired',
                        url: 'https://www.wired.com/story/sleep-procrastination-psychology-tips/',
                    },
                ],
            },
        },
        {
            //question 9//
            question: "Another tip for revenge bedtime procrastinators is to break up their bedtime routine. This means:",
            choices: ['Only going to bed when you’re actually sleepy.', 'Try going to bed, but if you’re not asleep in 15 minutes you have to get up and do something relaxing for a while.', 'Setting a timer, and after the timer goes off you must stop playing with your phone and brush your teeth. Then set another timer, and after that timer goes off you must dim the lights, etc.', 'Look over your routine for anything stimulating, such as if you like to play on your phone before bed, and remove that from your bedtime routine.'],
            answerIdx: 2,    
            furtherInfo: {
                explanation: (
                    `Another strategy for beating revenge bedtime procrastination is to break up your bedtime routine by setting a time. For example, after one timer goes off, you must put down your phone and brush your teeth. After another you must dim the lights.`
                ),
                sources: [
                    {
                        source: 'Wired',
                        url: 'https://www.wired.com/story/sleep-procrastination-psychology-tips/',
                    },
                ],
            },
        },
        {
            //question 10//
            question: "What percentage of people have been affected by sleeping problems during the pandemic?",
            choices: ['10%', '20%', '30%', '40%'],
            answerIdx: 3,    
            furtherInfo: {
                explanation: (
                    `Approximately 40% of people have been affected by sleeping problems during the pandemic.`
                ),
                sources: [
                    {
                        source: 'National Center for Biotechnology Information',
                        url: 'https://pubmed.ncbi.nlm.nih.gov/33108269/',
                    },
                ],
            },
        },
    ],

};

export default revengeBedtimeProcrastination;