import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";

const useStyles = makeStyles((theme) => ({
  question: {
    "& > *": {
      borderRadius: "50px",
      margin: theme.spacing(1),
      color: "#fff",
    },
  },
  questionArea: {
    display: "flex",
    justifyContent: "center",
    maxWidth: "none",
  },
}));

export default function QuestionBox(props) {
  const { appearance, children } = props;
  const classes = useStyles(appearance);

  return (
    <>
      <Container className={classes.questionArea}>
        <div className={classes.question}>{children}</div>
      </Container>
    </>
  );
}
