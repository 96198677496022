const dwarfPlanets = {
  name: "Dwarf Planets",
  id: 'Wim7reWVhwaERclAUut1',
  linkTo: "/quizzes/dwarfPlanets",
  explainerOn: true,
  subheading: `Can you name all eight planets? What about the dwarf planets? Come test your knowledge of the lesser-known members of our solar system.`,
  description: `If you’re like me, you can name all eight planets in our solar system … but probably only one dwarf planet. What are dwarf planets? How many share the solar system with us?`,
  questions: [
    { // Q-1
      question: "When did 'dwarf planet' become a recognized class of space object?",
      choices: ['2006', '1996', '1966', '1896'],
      answerIdx: 0,
      furtherInfo: {
        explanation: (
          `The International Astronomical Union (IAU) defined dwarf planets as a new class of space objects in 2006.`
        ),
        sources: [
          {
            source: "Visual Capitalist",
            url: 'https://www.visualcapitalist.com/a-visual-introduction-to-the-dwarf-planets-in-our-solar-system/?utm_source=Visual+Capitalist+Infographics+%28All%29&utm_campaign=1080fb2470-EMAIL&utm_medium=email&utm_term=0_31b4d09e8a-1080fb2470-45131207',
          },
        ],
      },
    },
    { // Q-2
      question: "How many dwarf planets are in our solar system?",
      choices: ['3', '12', '5', '10'],
      answerIdx: 2,
      furtherInfo: {
        explanation: (
          `The IAU officially recognized 5 dwarf planets in our solar system. However, 4 additional dwarf planets are widely acknowledged by the scientific community and are included in this quiz.`
        ),
        sources: [
          {
            source: "Visual Capitalist",
            url: 'https://www.visualcapitalist.com/a-visual-introduction-to-the-dwarf-planets-in-our-solar-system/?utm_source=Visual+Capitalist+Infographics+%28All%29&utm_campaign=1080fb2470-EMAIL&utm_medium=email&utm_term=0_31b4d09e8a-1080fb2470-45131207',
          },
        ],
      },
    },
    { // Q-3 
      question: "Is Pluto a dwarf planet?",
      choices: ['No', 'Yes', "It shouldn't be!", 'It is an asteroid.'],
      answerIdx: 1,
      furtherInfo: {
        explanation: (
          `Yes, Pluto is a dwarf planet. It was downgraded from major planet status in 2006 which prompted the IAU to define dwarf planets as a new class of objects.`
        ),
        sources: [
          {
            source: "Visual Capitalist",
            url: 'https://www.visualcapitalist.com/a-visual-introduction-to-the-dwarf-planets-in-our-solar-system/?utm_source=Visual+Capitalist+Infographics+%28All%29&utm_campaign=1080fb2470-EMAIL&utm_medium=email&utm_term=0_31b4d09e8a-1080fb2470-45131207',
          },
        ],
      },
    },
    { // Q-4
      question: "What makes a dwarf planet?",
      choices: ['It orbits the sun.', 'It has enough mass to form a nearly round shape.', 'It does not clear its orbital path.', 'All of the Above'],
      answerIdx: 3,
      furtherInfo: {
        explanation: (
          `The IAU defines a dwarf planet as a celestial body that orbits the sun, has enough mass to form a nearly round shape, has <i>not</i> cleared all other objects from around its orbit (meaning it’s not the dominant gravitational body in its area), and, crucially, is not a moon.`
        ),
        sources: [
          {
            source: "Visual Capitalist",
            url: 'https://www.visualcapitalist.com/a-visual-introduction-to-the-dwarf-planets-in-our-solar-system/?utm_source=Visual+Capitalist+Infographics+%28All%29&utm_campaign=1080fb2470-EMAIL&utm_medium=email&utm_term=0_31b4d09e8a-1080fb2470-45131207',
          },
        ],
      },
    },
    { // Q-5
      question: "Which of our dwarf planets has the most moons?",
      choices: ['Pluto', 'Eris', 'Sedna', 'Makemake'],
      answerIdx: 0,
      furtherInfo: {
        explanation: (
          `Though Pluto is no longer a major planet, it does have 5 moons; the most of any of our solar system's dwarf planets.`
        ),
        sources: [
          {
            source: "Visual Capitalist",
            url: 'https://www.visualcapitalist.com/a-visual-introduction-to-the-dwarf-planets-in-our-solar-system/?utm_source=Visual+Capitalist+Infographics+%28All%29&utm_campaign=1080fb2470-EMAIL&utm_medium=email&utm_term=0_31b4d09e8a-1080fb2470-45131207',
          },
        ],
      },
    },
    { // Q-6
      question: "Where do most of our dwarf planets reside?",
      choices: ['The Kuiper Belt', 'The Scattered Disc', 'The Asteroid Belt', 'Between the other planets.'],
      answerIdx: 0,
      furtherInfo: {
        explanation: (
          `Most of our dwarf planets reside in the Kuiper Belt; a massive region beyond the orbit of Neptune that contains many small celestial bodies.`
        ),
        sources: [
          {
            source: "Universe Today",
            url: 'https://www.universetoday.com/107598/what-is-the-kuiper-belt/',
          },
        ],
      },
    },
    { // Q-7
      question: "Ceres loses how much of its icy surface per second in steam?",
      choices: ['1.5kg', '3kg', '6kg', '12kg'],
      answerIdx: 2,
      furtherInfo: {
        explanation: (
          `Ceres loses 6 kilograms of its mass every second as portions of its icy surface warm up and vaporize. It also has ice volcanoes that erupt with steam and water instead of lava.`
        ),
        sources: [
          {
            source: "National Geographic",
            url: 'https://www.nationalgeographic.com/science/article/dwarf-planet-ceres-churns-briny-fluids-icy-volcanoes-nasa-dawn',
          },
        ],
      },
    },
    { // Q-8
      question: "What is unique about Haumea's appearance?",
      choices: ['It is oblong.', 'It is luminescent.', 'It is blue, like Earth.', 'It is NOT luminescent.'],
      answerIdx: 0,
      furtherInfo: {
        explanation: (
          `Due to its incredibly fast rotation (a day on Haumea lasts under 4 hours) its mass has been compressed into an oblong, egg-like shape.`
        ),
        sources: [
          {
            source: "Visual Capitalist",
            url: 'https://www.visualcapitalist.com/a-visual-introduction-to-the-dwarf-planets-in-our-solar-system/?utm_source=Visual+Capitalist+Infographics+%28All%29&utm_campaign=1080fb2470-EMAIL&utm_medium=email&utm_term=0_31b4d09e8a-1080fb2470-45131207',
          },
        ],
      },
    },
    { // Q-9
      question: "Which dwarf planet was once considered for the position of 10th planet in our solar system?",
      choices: ['Sedna', 'Pluto', 'Ceres', 'Eris'],
      answerIdx: 3,
      furtherInfo: {
        explanation: (
          `With 28% more mass than Pluto, Eris was once considered for the position of 10th planet.`
        ),
        sources: [
          {
            source: "Visual Capitalist",
            url: 'https://www.visualcapitalist.com/a-visual-introduction-to-the-dwarf-planets-in-our-solar-system/?utm_source=Visual+Capitalist+Infographics+%28All%29&utm_campaign=1080fb2470-EMAIL&utm_medium=email&utm_term=0_31b4d09e8a-1080fb2470-45131207',
          },
        ],
      },
    },
    { // Q-10
      question: "Which of these is <i>not</i> a dwarf planet?",
      choices: ['Gonggong', 'Quaoar', 'Gliese', 'Orcus'],
      answerIdx: 2,
      furtherInfo: {
        explanation: (
          `Gonggong, Quaoar, and Orcus are all dwarf planets, but Gliese is not. Gliese 832 is a star around which 2 possible Earth-like planets orbit.`
        ),
        sources: [
          {
            source: "Space.com",
            url: 'https://www.space.com/33952-five-nearest-earth-like-alien-planets.html',
          },
        ],
      },
    },
  ],

};

export default dwarfPlanets;