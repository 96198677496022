import { withStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";

const TimerProgressBar = withStyles((theme) => ({
  root: {
    overflow: "visible",
    height: 3,
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  bar: {
    height: 8,
    margin: "-2.5px 0",
    backgroundColor: "#fff",
  },
}))(LinearProgress);

export default TimerProgressBar;
