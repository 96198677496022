const theTaliban = {
  name: 'The Taliban',
  id: 'Px4lXFrYhRApiO9ufDq2',
  linkTo: "/quizzes/theTaliban",
  explainerOn: true,
  subheading: `Students or Extremists, who are the Taliban?`,
  description: `Everyone has heard of the Taliban. They are everywhere in the news as the foe of the United States, and the enemy of the people of Afghanistan. But where did they come from, and how does the Taliban see itself?`,
  questions: [
      {
          //question 1//
          question: `<em>Taliban</em> comes from the Pashto word meaning <i>what</i>?`,
          choices: ['Teachers', 'Students', 'Warriors', 'Defenders'],
          answerIdx: 1,    
          furtherInfo: {
              explanation: (
                  `A largely Pashtun organization, the Taliban takes its name from the Pashto word meaning <em>students</em>.`
              ),
              sources: [
                  {
                      source: 'BBC News',
                      url: 'https://www.bbc.com/news/world-south-asia-11451718',
                  },
              ],
        },
      },
      {
          //question 2//
         question: `When did the Taliban <em>first</em> appear?`,
          choices: ['1890s', '1980s', '1990s', '2000s'],
          answerIdx: 2,    
          furtherInfo: {
              explanation: (
                  `The Taliban initially appeared in the early 1990s, following the withdrawal of Soviet troops from Afghanistan.`
              ),
              sources: [
                  {
                      source: 'BBC News',
                      url: 'https://www.bbc.com/news/world-south-asia-11451718',
                  },
              ],
        },
      },
      {
          //question 3//
          question: `In what region did the Taliban first emerge?`,
          choices: ['Northern Afghanistan', 'Northern Uzbekistan', 'Northern Turkey', 'Northern Pakistan'],
          answerIdx: 3,    
          furtherInfo: {
              explanation: (
                  `The Taliban first emerged in Northern Pakistan, most likely forming in religious seminaries funded by Saudi Arabia which taught a hard-line form of Sunni Islam.`
              ),
              sources: [
                  {
                      source: 'BBC News',
                      url: 'https://www.bbc.com/news/world-south-asia-11451718',
                  },
              ],
        },
      },
      {
          //question 4//
          question: `When did the Taliban first come to power in Afghanistan?`,
          choices: ['1986', '1996', '2006', '2016'],
          answerIdx: 1,    
          furtherInfo: {
              explanation: (
                  `The Taliban first captured Kabul, the capital of Afghanistan, in 1996, overthrowing the regime of then-president Burhanuddin Rabbani. Rabbani had been a founding member of the Afghan mujahideen, which resisted Soviet occupation.`
              ),
              sources: [
                  {
                      source: 'BBC News',
                      url: 'https://www.bbc.com/news/world-south-asia-11451718',
                  },
              ],
        },
      },
      {
          //question 5//
          question: `The Taliban were initially welcomed in Afghanistan.`,
          choices: ['True', 'False'],
          answerIdx: 0,    
          furtherInfo: {
              explanation: (
                  `This is true. Tired of the previous regime's excesses and infighting, the Afghan people initially welcomed Taliban rule.`
              ),
              sources: [
                  {
                      source: 'BBC News',
                      url: 'https://www.bbc.com/news/world-south-asia-11451718',
                  },
              ],
        },
      },
      {
          //question 6//
          question: `Of the many policies that made Taliban rule unpopular, one was disapproving of girls going to school after what age?`,
          choices: ['5', '10', '13', '18'],
          answerIdx: 1,    
          furtherInfo: {
              explanation: (
                  `Though the Taliban were successful in rooting out corruption and lawlessness in Afghanistan, as well as making the roads safe, they also introduced a strict interpretation of Sharia law. They banned television and disapproved of girls ages 10 and over going to school.`
              ),
              sources: [
                  {
                      source: 'BBC News',
                      url: 'https://www.bbc.com/news/world-south-asia-11451718',
                  },
              ],
        },
      },
      {
          //question 7//
          question: `How many countries recognized Taliban rule while they were in power in Afghanistan?`,
          choices: ['53', '23', '13', '3'],
          answerIdx: 3,    
          furtherInfo: {
              explanation: (
                  `Only 3 countries recognized Taliban rule: Saudi Arabia, the United Arab Emirates (UAE), and Pakistan.`
              ),
              sources: [
                  {
                      source: 'BBC News',
                      url: 'https://www.bbc.com/news/world-south-asia-11451718',
                  },
              ],
        },
      },
      {
          //question 8//
          question: `The U.S. invaded Afghanistan in order to end Taliban rule. But <em>why</em>?`,
          choices: ['Reports of human rights abuses', 'The Taliban were believed to be harboring Osama bin Laden and other members of al-Qaeda', 'The Taliban were directly responsible for the 9/11 attacks', 'The U.S. needed Afghanistan as a base for operations in Iraq'],
          answerIdx: 1,    
          furtherInfo: {
              explanation: (
                  `The United States invaded Afghanistan in October 2001, following the 9/11 attacks, in order to oust the Taliban, who were believed to be harboring Osama bin Laden and other members of al-Qaeda.`
              ),
              sources: [
                  {
                      source: 'BBC News',
                      url: 'https://www.bbc.com/news/world-south-asia-11451718',
                  },
              ],
        },
      },
      {
          //question 9//
          question: `In 2018 the United States refused to enter into peace talks directly with the Taliban.`,
          choices: ['True', 'False'],
          answerIdx: 1,    
          furtherInfo: {
              explanation: (
                  `This is false. In 2018, the United States entered peace talks directly with the Taliban, which led to an agreement being reached in February 2020. This agreement committed the U.S. to withdrawal. It committed the Taliban to preventing attacks on U.S. troops and proceeding with national peace talks, among other things.`
              ),
              sources: [
                  {
                      source: 'BBC News',
                      url: 'https://www.bbc.com/news/world-south-asia-11451718',
                  },
              ],
        },
      },
      {
          //question 10//
          question: `The Taliban swept through Afghanistan to arrive at the capital of Kabul in how many days?`,
          choices: ['10', '20', '50', '90'],
          answerIdx: 0,    
          furtherInfo: {
              explanation: (
                  `The Taliban swept through Afghanistan in just 10 days.<br/><br/>In response to the U.S. withdrawing its troops from the area, the Taliban took their first provincial capital on August 6, 2021, and reached the gates of Kabul by August 15, 2021.`
              ),
              sources: [
                  {
                      source: 'BBC News',
                      url: 'https://www.bbc.com/news/world-south-asia-11451718',
                  },
              ],
          },
      },
  ],
};

export default theTaliban;