import { useState, useEffect } from "react";
import { useAuth, useUser } from "reactfire";

function useLoadedAuthUser(setStateFn) {
  const auth = useAuth();
  const { data: user } = useUser(auth);
  const [userLoaded, setUserLoaded] = useState(false);

  useEffect(function setUserLoadingStates() {
    if (user && !userLoaded) {
      if (setStateFn) {
        setStateFn(user);
      }
      setUserLoaded(true);
    }
  }, [user, userLoaded, setStateFn]);

  return user;
}

export default useLoadedAuthUser;
