import React from 'react'
import "../assets/designs/feedbackButton.css";

function FeedbackButton() {
    // const [scrollDir, setScrollDir] = useState(0);
    // useEffect(() => {
    //     const threshold = 0;
    //     let lastScrollY = window.pageYOffset;
    //     let ticking = false;
      
    //     const updateScrollDir = () => {
    //       const scrollY = window.pageYOffset;
      
    //       if (Math.abs(scrollY - lastScrollY) < threshold) {
    //         ticking = false;
    //         return;
    //       }
    //       setScrollDir(scrollY > lastScrollY ? 0 : 1);
    //       lastScrollY = scrollY > 0 ? scrollY : 0;
    //       ticking = false;
    //     };
      
    //     const onScroll = () => {
    //       if (!ticking) {
    //         window.requestAnimationFrame(updateScrollDir);
    //         ticking = true;
    //       }
    //     };
      
    //     window.addEventListener("scroll", onScroll);
      
    //     return () => window.removeEventListener("scroll", onScroll);
    //   }, [scrollDir]);
    return (
        // <div className={`feedback-button-container ${scrollDir === 0 ? "show-feedback-button" : "hide-feedback-button"}`}>
        <div className={`feedback-button-container show-feedback-button`}>
            <a href="https://form.asana.com/?k=eMP6ShmOE9Crn34IOy7b9A&d=366847836913442" target="_blank" rel="noopener noreferrer">Feedback</a>
        </div>
    )
}

export default FeedbackButton
